@charset "UTF-8";
/*
    ==========================
    TRANSITION & HELPER MIXINS
    ==========================
*/
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/Poppins-Regular.woff2) format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/Poppins-Medium.woff2) format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/Poppins-SemiBold.woff2) format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/Poppins-Bold.woff2) format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/fonts/Poppins-ExtraBold.woff2) format("woff2"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/Poppins-Black.woff2) format("woff2"); }

/* FONTS declear start */
/* FONTS declear end */
/* Site color declear start */
img {
  max-width: 100%;
  height: auto; }

/*======Responsive starts=======*/
/*======Responsive ends=======*/
body {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: #333; }
  body.home-page-spacer {
    padding-top: 72px; }
    @media screen and (min-width: 992px) {
      body.home-page-spacer {
        padding-top: 70px; } }
  body.no-padding {
    padding-top: 0; }
  @media (max-width: 768px) {
    body.o-fixedBody {
      position: fixed;
      top: 0;
      width: 100%;
      left: 0; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  color: #333;
  position: relative;
  font-weight: 600; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  color: #333;
  position: relative;
  font-weight: 600; }

h1,
.h1 {
  font-size: 34px; }
  @media (min-width: 992px) {
    h1,
    .h1 {
      font-size: 62px;
      line-height: 75px; } }

h2,
.h2 {
  font-size: 30px; }
  @media (min-width: 992px) {
    h2,
    .h2 {
      font-size: 48px; } }

h3,
.h3 {
  font-size: 26px; }

h4,
.h4 {
  font-size: 22px; }

h5,
.h5 {
  font-size: 20px; }

h6,
.h6 {
  font-size: 18px; }

p {
  line-height: 25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 0 25px;
  font-family: "Montserrat", sans-serif;
  color: #333; }

a,
input,
button,
svg {
  font-family: "Montserrat", sans-serif;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }

button,
input[type="submit"],
input[type="reset"] {
  cursor: pointer;
  background: none;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  border: none;
  text-transform: uppercase;
  width: 100%;
  position: relative;
  z-index: 2; }

a,
button,
textarea,
select {
  text-decoration: none; }
  a:hover, a:focus, a:active, a:focus,
  button:hover,
  button:focus,
  button:active,
  button:focus,
  textarea:hover,
  textarea:focus,
  textarea:active,
  textarea:focus,
  select:hover,
  select:focus,
  select:active,
  select:focus {
    text-decoration: none;
    outline: none; }

fieldset {
  border: 1px solid #dbd7d7;
  padding: 15px;
  margin: 0 0 25px; }
  fieldset legend {
    font-size: 14px;
    color: #333;
    width: auto;
    padding: 0 10px;
    border-bottom: none;
    float: none;
    margin: 0; }
  fieldset input[type="text"],
  fieldset input[type="tel"],
  fieldset input[type="url"],
  fieldset select,
  fieldset textarea {
    border: none;
    color: #797979;
    font-weight: 400;
    width: 100%;
    padding: 0 10px; }
  fieldset textarea {
    height: 150px; }

textarea {
  resize: none; }

select::-ms-expand {
  display: none; }

::placeholder {
  color: #797979;
  font-weight: 400;
  opacity: 1; }

:-ms-input-placeholder {
  color: #797979;
  font-weight: 400;
  opacity: 1; }

::-ms-input-placeholder {
  color: #797979;
  font-weight: 400;
  opacity: 1; }

ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0; }

.font-family-sour {
  font-family: "Montserrat", sans-serif; }

.d-block {
  display: block; }

.d-inline-block {
  display: inline-block; }

.white-color {
  color: #fff !important; }

.black-color {
  color: #333 !important; }

.white-bg-color {
  background-color: #fff; }

.grey-bg-color {
  background-color: #f5f5f5; }

.green-color {
  color: #14DF7D; }

.blue-color {
  color: #091B38 !important; }

.light-blue-color {
  color: #4A90E2; }

.pad-80 {
  padding: 50px 0; }
  @media (min-width: 992px) {
    .pad-80 {
      padding: 80px 0; } }

.padding-30 {
  padding: 30px 0; }

.pad-40 {
  padding: 40px 0; }

.padding-50 {
  padding: 50px 0; }

.pad-top-0 {
  padding-top: 0 !important; }

.pad-top-20 {
  padding-top: 20px; }

.pad-top-30 {
  padding-top: 30px; }

.pad-top-40 {
  padding-top: 40px; }

.pad-top-50 {
  padding-top: 50px; }

.pad-top-100 {
  padding-top: 100px; }

.pad-top-120 {
  padding-top: 120px; }

.pad-top-50 {
  padding-top: 50px; }

.pad-bot-10 {
  padding-bottom: 10px; }

.pad-bot-20 {
  padding-bottom: 20px; }

.pad-bot-30 {
  padding-bottom: 30px; }

.pad-bot-40 {
  padding-bottom: 40px; }

.pad-bot-50 {
  padding-bottom: 50px; }

.pad-bot-80 {
  padding-bottom: 40px; }

.pad-bot-150 {
  padding-bottom: 150px; }

.padding-all-60 {
  padding: 60px; }

.margin-bottom-0 {
  margin-bottom: 0; }

.center {
  text-align: center; }

.pattern-br:after {
  background: url(/images/common/rb-pt.png) no-repeat bottom right;
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 82px; }

.pattern-tl:after {
  background: url(/images/common/lt-pt.png) no-repeat bottom right;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 160px;
  height: 60px; }

.pattern-tr:after {
  background: url(/images/common/rt-pt.png) no-repeat bottom right;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 200px;
  height: 82px; }

.pattern-bl:after {
  background: url(/images/common/rb-pt.png) no-repeat bottom right;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 200px;
  height: 100px; }

.anchor-link {
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  display: inline-block;
  max-width: 325px;
  margin-top: 10px;
  text-decoration: underline;
  font-weight: 600; }
  @media (min-width: 992px) {
    .anchor-link {
      font-size: 14px; } }
  .anchor-link--white {
    color: #cbe3ff; }

.home-link {
  font-family: "Montserrat", sans-serif;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  display: block; }
  @media (min-width: 992px) {
    .home-link {
      font-size: 19px; } }
  .home-link:hover {
    color: #000; }

.n-gradient-green-blue {
  background: -moz-linear-gradient(-45deg, #037c71 0%, #042b4e 100%);
  background: -webkit-linear-gradient(-45deg, #037c71 0%, #042b4e 100%);
  background: linear-gradient(135deg, #037c71 0%, #042b4e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#037c71', endColorstr='#042b4e',GradientType=1 ); }

.gradient-green-blue {
  background: #00b35c;
  background: -moz-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, #00b35c), color-stop(47%, #0b8a8a), color-stop(100%, #004475));
  background: -webkit-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  background: -o-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  background: -ms-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b35c', endColorstr='#004475', GradientType=1 ); }

.mobile-off {
  display: none; }
  @media (min-width: 992px) {
    .mobile-off {
      display: block; } }

.desktop-off {
  display: block; }
  @media (min-width: 992px) {
    .desktop-off {
      display: none; } }

@media screen and (min-width: 1280px) {
  .container {
    max-width: 1170px; } }

.u-border-color--secondary {
  border-color: #495376 !important; }

.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2; }

.modal-close {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  right: -15px;
  top: -15px;
  background: #fff;
  border: 1px solid #fff;
  opacity: 1;
  width: 30px;
  height: 30px;
  border-radius: 50%; }

.left {
  text-align: left !important; }

.font-size-24 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500; }

.padding-top-20 {
  padding-top: 20px; }

.font-size-42 {
  font-size: 28px;
  line-height: 42px; }
  @media (min-width: 992px) {
    .font-size-42 {
      font-size: 42px;
      line-height: 52px; } }

.font-size-20 {
  font-size: 20px;
  line-height: 32px; }

.office-infrastructure .lets-talk {
  margin-top: 0; }

.office-infrastructure .o-skills-hero__schedule {
  padding-top: 150px !important; }

.new-contact .modal-backdrop {
  display: none; }

#cookiePopup {
  background: #fff;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  box-shadow: 0 0 10px #ccc;
  z-index: 9999;
  text-align: center;
  display: none; }

#cookiePopup img {
  display: inline-block;
  margin-right: 10px; }

#cookiePopup p {
  font-size: 13px;
  color: #000;
  width: 100%;
  margin: 0;
  font-weight: 500;
  padding-top: 20px; }
  @media (min-width: 992px) {
    #cookiePopup p {
      padding-top: 0; } }

#cookiePopup p a {
  text-decoration: underline;
  color: #000; }

#cookiePopup button {
  font-weight: 600;
  border-radius: 5px;
  color: #000;
  border: 1px solid #000;
  width: 90px;
  padding: 0;
  margin: 10px auto 10px;
  display: block; }
  @media (min-width: 992px) {
    #cookiePopup button {
      margin: 10px 0 10px 30px;
      display: inline-block; } }

.cookie-close {
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer; }

.index-bnr-logo {
  margin-bottom: 40px; }

.index-bnr-logo img {
  margin: 10px; }

.capitalize {
  text-transform: capitalize !important; }

.font-weight-700 {
  font-weight: 700 !important; }

.o-blogBttn {
  text-decoration: none;
  background: none;
  background-color: transparent;
  padding: 10px 35px;
  display: block;
  color: #333;
  position: relative;
  width: 90%;
  font-size: 12px;
  border: none;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333; }
  .o-blogBttn.o-submitBttn {
    display: inline-block;
    margin: 0 auto;
    width: auto; }
  .o-blogBttn:hover {
    color: #ff0085; }

.draw::before {
  left: 50%;
  top: -2px;
  transition-duration: 0.4s; }

.draw::after {
  left: 50%;
  bottom: -2px;
  transition-duration: 0.4s; }

.draw:hover::before, .draw:hover::after {
  left: 0; }

.draw::before, .draw::after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  transition: all 0.2s linear;
  background: #ff0085; }

.draw:hover::before, .draw:hover::after {
  width: 100%; }

.btn {
  font-size: 16px;
  font-weight: bold;
  max-width: 350px;
  padding: 12px 0px;
  display: block;
  width: 275px;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .btn {
      display: block;
      text-align: center;
      border-radius: 5px;
      padding: 20px;
      font-size: 18px;
      max-width: 300px;
      text-decoration: none;
      position: relative;
      font-weight: 600;
      margin: 0 auto;
      position: relative;
      text-align: center;
      width: auto; } }
  .btn-orange {
    color: #fff;
    border: 2px solid #FF5800;
    background-color: #FF5800;
    position: relative;
    margin: 0 auto; }
    .btn-orange:after {
      position: relative;
      content: '';
      background: url(/images/common/arrow-white.png) no-repeat;
      width: 20px;
      height: 14px;
      display: inline-block;
      margin-left: 15px;
      top: 50%;
      transform: translatey(-50%);
      position: absolute;
      display: none; }
    .btn-orange:hover {
      background-color: #fff;
      color: #FF5800; }
      .btn-orange:hover:after {
        background: url(/images/common/arrow-orange.png) no-repeat; }
  .btn:focus {
    box-shadow: none; }

.home-fi .btn {
  width: 270px; }
  @media (min-width: 992px) {
    .home-fi .btn {
      width: 350px; } }

a.black-btn {
  display: none; }
  @media (min-width: 992px) {
    a.black-btn {
      width: 134px;
      height: 46px;
      line-height: 42px !important;
      border: 2px solid #333333;
      border-radius: 5px;
      font-size: 18px !important;
      color: #333 !important;
      text-decoration: none;
      font-weight: 400 !important;
      transition: .3s all ease-in;
      display: block;
      background: transparent;
      padding: 0;
      text-transform: capitalize;
      text-decoration: none !important; }
      a.black-btn:after {
        display: none; }
      a.black-btn:hover {
        border: 2px solid #FF5800;
        color: #FF5800 !important; } }

.btn_wrapper.btn2 {
  position: relative;
  z-index: 1; }

.masonry .brick a.service-box-link {
  border: 0 !important;
  color: #4a90e2 !important;
  line-height: 20px !important;
  height: auto !important; }

.btn--transparent {
  background: transparent; }

@use "sass:map";
.main {
  margin: 0 auto;
  display: block;
  height: 100%;
  margin-top: 60px; }

.mainInner {
  display: table;
  height: 100%;
  width: 100%;
  text-align: center; }

.mainInner div {
  display: table-cell;
  vertical-align: middle;
  font-size: 3em;
  font-weight: bold;
  letter-spacing: 1.25px; }

#sidebarMenu {
  height: 100%;
  position: fixed;
  right: 0;
  width: 250px;
  transform: translateX(250px);
  transition: transform 250ms ease-in-out;
  background-color: #333;
  background: url(../images/choosing-img.jpg);
  background-position: 60% 0;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  bottom: 0;
  z-index: 9; }
  #sidebarMenu.active {
    width: 100%; }
  #sidebarMenu:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: rgba(0, 0, 0, 0.5);
    z-index: 2; }

.sidebarInner {
  padding-top: 50px;
  position: relative;
  z-index: 3; }

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {
  .sidebarInner {
    height: 280px;
    overflow: scroll; } }

.o-menuTitle {
  width: 100%;
  color: #fff;
  font-size: 10px;
  margin-bottom: 25px;
  letter-spacing: 2px; }

.sidebarMenuInner {
  margin: 0;
  padding: 0; }

.sidebarMenuInner li {
  list-style: none;
  color: #7B3E23;
  text-transform: uppercase;
  cursor: pointer; }

.sidebarMenuInner li a {
  color: #fff;
  text-transform: capitalize;
  cursor: pointer;
  text-decoration: none;
  padding: 15px 5px;
  display: inline-block;
  position: relative;
  transition: all 0.5s;
  font-size: 35px;
  line-height: normal;
  font-family: 'Prata', serif; }

@media screen and (max-width: 480px) {
  .sidebarMenuInner li a {
    font-size: 24px; } }

.sidebarMenuInner li a::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 50px;
  height: 1px;
  background-color: #a7a6a6;
  -webkit-transition: all .5s;
  transition: all .5s;
  transform: translateX(-50%); }

.sidebarMenuInner li a:hover {
  padding-left: 30px !important;
  transition: all 0.5s; }

.sidebarMenuInner li a:hover::after {
  width: 15px;
  transition: all 0.5s; }

.o-side-menu .sidebarMenuInner li.active a {
  color: #ff0085 !important; }

.o-side-menu input[type="checkbox"]:checked ~ #sidebarMenu {
  transform: translateX(0); }

.o-side-menu input[type="checkbox"] {
  transition: all 0.5s;
  box-sizing: border-box;
  display: none; }

.sidebarIconToggle {
  cursor: pointer;
  position: absolute;
  z-index: 99;
  top: 12px;
  right: 15px !important;
  margin: 0;
  padding: 0; }

.sidebarIconToggle span {
  color: #fff;
  text-transform: uppercase;
  margin: 5px -3px;
  display: inline-block;
  font-size: 11px; }

.spinner {
  transition: all 0.3s;
  height: 3px;
  margin-bottom: 5px;
  background-color: #333; }

.horizontal {
  transition: all 0.3s;
  position: relative; }

.diagonal.long {
  width: 37px; }

.horizontal.short {
  width: 24px; }

.o-side-menu input[type="checkbox"]:checked ~ .sidebarIconToggle > .horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0; }

.o-side-menu input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(135deg);
  margin-top: 0;
  background: #333;
  width: 25px; }

.o-side-menu input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(-135deg);
  background: #333;
  margin-top: -17px;
  width: 25px; }

.blackBG {
  z-index: 9;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  position: fixed; }

.o-fixedBody .sidebarIconToggle {
  position: fixed;
  top: 33px;
  transform: translateY(0); }

.o-menu-wrap {
  position: static; }

.o-header {
  background: #0D2642;
  position: fixed;
  z-index: 9999999;
  width: 100%;
  top: 0;
  padding: 10px 0; }
  @media screen and (min-width: 770px) {
    .o-header {
      border-bottom: 1px solid #e0e0e0;
      background: #fff; } }
  @media screen and (min-width: 992px) {
    .o-header {
      min-height: 50px;
      z-index: 999999;
      padding: 0; } }
  .o-header .navbar-brand {
    padding: 8px 0;
    margin-right: 0;
    font-size: 1.25rem; }
    @media screen and (min-width: 992px) {
      .o-header .navbar-brand.env-logo-padding-top-0 {
        padding: 6px 0 0; } }
  .o-header .navbar {
    padding: 0;
    position: static; }
    .o-header .navbar ul > li a {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 45px;
      display: block;
      display: block; }
      .o-header .navbar ul > li a.mobile-anchor-heading {
        font-size: 16px;
        margin-bottom: 8px; }
      @media screen and (min-width: 1px) {
        .o-header .navbar ul > li a {
          padding-left: 20px; } }
      @media screen and (min-width: 992px) {
        .o-header .navbar ul > li a {
          padding: 0 6px; } }
      @media screen and (min-width: 1200px) {
        .o-header .navbar ul > li a {
          padding: 0 11px; } }
      @media screen and (min-width: 1280px) {
        .o-header .navbar ul > li a {
          padding: 0 13px; } }
      @media screen and (min-width: 992px) {
        .o-header .navbar ul > li a {
          color: #333; } }
      .o-header .navbar ul > li a:hover {
        color: #fff; }
        @media screen and (min-width: 992px) {
          .o-header .navbar ul > li a:hover {
            color: #333; } }
      .o-header .navbar ul > li a.skill-brand-img span {
        margin-left: 30px;
        color: #cdcdcd; }
        @media screen and (min-width: 992px) {
          .o-header .navbar ul > li a.skill-brand-img span {
            margin-left: 0; } }
    .o-header .navbar ul > li.active a {
      color: #ff0085; }
    .o-header .navbar ul > li:first-child {
      padding-left: 0; }
    .o-header .navbar ul > li:last-child {
      padding-right: 0; }
    @media screen and (min-width: 992px) {
      .o-header .navbar .col-lg-2 .menu-holder-pan5-pan:nth-child(2) {
        margin-top: 25px; } }
  .o-header .dropdown {
    width: 100vw;
    position: absolute;
    height: 100vh;
    padding: 30px 50%;
    background: url(/images/home/banner-pattern.png) no-repeat 100% 100%;
    background-color: #333;
    color: #fff; }
  .o-header .menu-holder-pan3 {
    max-width: 1170px;
    margin: 0 auto;
    text-align: left; }
  .o-header .request-quote {
    color: #333;
    border: 2px solid #FF5800;
    display: block;
    text-align: center;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    font-size: 15px;
    max-width: 320px;
    text-decoration: none;
    position: relative;
    margin-left: 0;
    text-align: left;
    display: none;
    text-transform: uppercase !important; }
    @media screen and (min-width: 992px) {
      .o-header .request-quote {
        padding: 8px 10px;
        font-size: 14px !important; } }
    @media screen and (min-width: 1200px) {
      .o-header .request-quote {
        padding: 8px 20px;
        font-size: 15px !important; } }
    @media (min-width: 992px) {
      .o-header .request-quote {
        display: block;
        width: 125px;
        text-align: center; } }
  .o-header .hireus {
    color: #333;
    border: 2px solid #FF5800;
    background-color: transparent;
    display: block;
    text-align: center;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    padding: 8px 3px;
    font-size: 13px;
    max-width: 320px;
    text-decoration: none;
    position: relative;
    margin-left: 15px;
    text-align: center;
    top: 2px;
    text-transform: uppercase;
    display: block;
    width: 135px;
    text-align: center;
    font-weight: 700;
    top: 0;
    margin: 0;
    background-size: 15px; }
    @media screen and (min-width: 425px) {
      .o-header .hireus {
        padding: 8px 7px;
        font-size: 13px; } }
    @media screen and (min-width: 768px) {
      .o-header .hireus {
        margin: 0 0 0 15px; } }
    @media screen and (min-width: 992px) {
      .o-header .hireus {
        padding: 8px 5px;
        font-size: 14px;
        background-size: 15px;
        text-transform: none; } }
    @media screen and (min-width: 1200px) {
      .o-header .hireus {
        padding: 10px 6px 10px 6px;
        font-size: 15px; } }
    .o-header .hireus .btn-arrow {
      margin-left: 5px;
      width: 18px;
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-header .hireus .btn-arrow {
          display: inline; } }
      .o-header .hireus .btn-arrow path {
        fill: #333; }
    .o-header .hireus:hover {
      background-color: #FF5800;
      color: #fff; }
      .o-header .hireus:hover svg {
        transform: translate(5px, 0); }
      .o-header .hireus:hover path {
        fill: #fff; }
  .o-header.scrolled .hireus {
    background-color: #FF5800;
    color: #fff; }
    .o-header.scrolled .hireus .btn-arrow path {
      fill: #fff; }
    .o-header.scrolled .hireus:hover {
      background-color: transparent;
      color: #333; }
      .o-header.scrolled .hireus:hover .btn-arrow path {
        fill: #333; }

#navbar .collapse:not(.show) {
  right: -100%;
  display: block; }

.o-navigation-bar-new {
  padding: 0; }
  .o-navigation-bar-new #primaryNav {
    width: 100vw;
    position: fixed;
    top: 70px;
    right: 0;
    height: calc(100vh - 53px);
    overflow-y: scroll;
    background: #020a13;
    -webkit-transition: 0.4s all;
    -moz-transition: 0.4s all;
    -ms-transition: 0.4s all;
    transition: 0.4s all; }
    @media screen and (min-width: 992px) {
      .o-navigation-bar-new #primaryNav {
        overflow-y: visible;
        position: static;
        padding: 0;
        text-align: right;
        width: auto;
        height: auto;
        background: transparent;
        justify-content: flex-end; } }
    .o-navigation-bar-new #primaryNav .request-quote {
      line-height: 19px;
      display: inline-block;
      background-color: #a9cf00;
      color: #fff;
      margin-top: 12px;
      border-radius: 0;
      border: 0;
      width: 125px;
      padding: 10px 20px;
      font-weight: bold;
      text-align: center;
      border-radius: 5px;
      border: 1px solid transparent;
      font-weight: 500; }
      .o-navigation-bar-new #primaryNav .request-quote:hover {
        background-color: #fff;
        color: #fa5701;
        border: 0;
        border: 1px solid #ff5800; }
        .o-navigation-bar-new #primaryNav .request-quote:hover:after {
          background: url(/images/common/arrow-orange.png) 50% 50% no-repeat;
          content: "";
          right: 20px;
          display: none; }
      .o-navigation-bar-new #primaryNav .request-quote:after {
        background: url(/images/common/arrow-white.png) 50% 50% no-repeat;
        right: 15px;
        display: none; }
    .o-navigation-bar-new #primaryNav > ul {
      overflow-x: hidden; }
      @media screen and (min-width: 992px) {
        .o-navigation-bar-new #primaryNav > ul {
          overflow-x: visible; } }
      @media screen and (min-width: 767px) {
        .o-navigation-bar-new #primaryNav > ul > li::after {
          position: relative;
          content: "";
          background-color: transparent;
          display: none;
          bottom: 2px;
          width: 80%;
          height: 2px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          margin-bottom: -10px; } }
  @media screen and (min-width: 767px) and (min-width: 992px) {
    .o-navigation-bar-new #primaryNav > ul > li::after {
      background: #14df7d; } }
      .o-navigation-bar-new #primaryNav > ul > li.has-submenu {
        padding-right: 0; }
        .o-navigation-bar-new #primaryNav > ul > li.has-submenu > a {
          position: relative;
          line-height: 45px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: none !important; }
          @media screen and (min-width: 992px) {
            .o-navigation-bar-new #primaryNav > ul > li.has-submenu > a {
              padding-right: 9px;
              padding-bottom: 9px; } }
          @media screen and (min-width: 1200px) {
            .o-navigation-bar-new #primaryNav > ul > li.has-submenu > a {
              padding-right: 10px;
              padding-left: 10px;
              padding-bottom: 9px; } }
          @media (max-width: 768px) {
            .o-navigation-bar-new #primaryNav > ul > li.has-submenu > a {
              padding-right: 10px;
              padding-bottom: 10px; } }
          @media screen and (min-width: 992px) {
            .o-navigation-bar-new #primaryNav > ul > li.has-submenu > a {
              background: url(/images/common/nav-down-arrow.svg) 100% calc(50% - 5px) no-repeat; } }
          .o-navigation-bar-new #primaryNav > ul > li.has-submenu > a span {
            font-size: 28px;
            font-weight: 500;
            flex: 0 0 30px;
            height: 30px;
            width: 30px;
            color: #fff;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border: #fff 1px solid;
            line-height: 28px;
            text-align: center;
            margin-right: 15px; }
            @media screen and (min-width: 992px) {
              .o-navigation-bar-new #primaryNav > ul > li.has-submenu > a span {
                margin-right: 0;
                color: #333; } }
          .o-navigation-bar-new #primaryNav > ul > li.has-submenu > a.dropdown-toggle::after {
            display: none; }
        .o-navigation-bar-new #primaryNav > ul > li.has-submenu > span {
          position: relative;
          padding-right: 13px; }
          @media screen and (min-width: 992px) {
            .o-navigation-bar-new #primaryNav > ul > li.has-submenu > span {
              background: url(/images/common/nav-down-arrow.svg) 100% 50% no-repeat; } }
      .o-navigation-bar-new #primaryNav > ul > li.active span {
        line-height: 24px !important; }
      .o-navigation-bar-new #primaryNav > ul > li.active a {
        color: #fff; }
        .o-navigation-bar-new #primaryNav > ul > li.active a:hover {
          color: #fff; }
      .o-navigation-bar-new #primaryNav > ul > li:hover::after {
        display: block; }
      @media screen and (min-width: 992px) {
        .o-navigation-bar-new #primaryNav > ul > li:hover > ul {
          opacity: 1;
          visibility: visible;
          height: auto;
          display: block;
          border: 1px solid #d8d8d8; }
          .o-navigation-bar-new #primaryNav > ul > li:hover > ul.nav-padding-new-30 {
            padding: 20px 15px; } }
      .o-navigation-bar-new #primaryNav > ul > li > ul {
        width: 100vw;
        position: static;
        padding: 0;
        height: 0;
        display: none;
        overflow: hidden;
        background: #020a13;
        border-radius: 0;
        -webkit-transition: 0.4s all;
        -moz-transition: 0.4s all;
        -ms-transition: 0.4s all;
        transition: 0.4s all; }
        @media screen and (min-width: 992px) {
          .o-navigation-bar-new #primaryNav > ul > li > ul {
            position: absolute;
            left: 0;
            top: auto;
            opacity: 1;
            display: block; } }
        .o-navigation-bar-new #primaryNav > ul > li > ul.showNav {
          display: block;
          height: 100%; }
        .o-navigation-bar-new #primaryNav > ul > li > ul li.has-submenu {
          padding-right: 0; }
          .o-navigation-bar-new #primaryNav > ul > li > ul li.has-submenu > a,
          .o-navigation-bar-new #primaryNav > ul > li > ul li.has-submenu > span {
            position: relative;
            padding: 10px 0 10px 10px; }
            @media screen and (min-width: 992px) {
              .o-navigation-bar-new #primaryNav > ul > li > ul li.has-submenu > a,
              .o-navigation-bar-new #primaryNav > ul > li > ul li.has-submenu > span {
                background: url("images/common/nav-right-arrow.svg") 90% 50% no-repeat; } }
          .o-navigation-bar-new #primaryNav > ul > li > ul li.has-submenu:hover > a,
          .o-navigation-bar-new #primaryNav > ul > li > ul li.has-submenu:hover > span {
            background: url("images/common/nav-right-arrow.svg") 90% 50% no-repeat #dadada;
            color: #333;
            border-left: 4px solid #14df7d; }
        .o-navigation-bar-new #primaryNav > ul > li > ul li a {
          color: #fff;
          line-height: 1.2; }
        .o-navigation-bar-new #primaryNav > ul > li > ul li ul {
          left: 100%;
          width: 100%;
          position: absolute;
          background: #fff;
          -webkit-box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.37);
          -moz-box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.37);
          box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.37);
          padding: 0; }
          .o-navigation-bar-new #primaryNav > ul > li > ul li ul li a {
            padding: 10px 0 10px 10px;
            background-color: #fff;
            background-image: none; }
            .o-navigation-bar-new #primaryNav > ul > li > ul li ul li a:hover {
              background-color: #dadada;
              background-image: none;
              color: #333;
              border-left: 4px solid #14df7d; }
  .o-navigation-bar-new .navbar-nav {
    padding-top: 10px; }
  .o-navigation-bar-new .dropdown-menu {
    margin-top: 0;
    border: none;
    display: block;
    opacity: 0;
    height: 0; }
  .o-navigation-bar-new li ul li {
    font-size: 14px; }
    .o-navigation-bar-new li ul li a {
      border-left: 0;
      color: #fff;
      font-size: 14px; }
      .o-navigation-bar-new li ul li a:hover {
        background-color: transparent;
        color: #14df7e; }
        .o-navigation-bar-new li ul li a:hover span {
          background-color: transparent;
          color: #14df7e; }
    .o-navigation-bar-new li ul li span {
      border-left: 0;
      color: #cdcdcd;
      font-size: 14px;
      font-weight: 400; }

.o-navigation-bar #primaryNav li.has-submenu > a {
  background-position: 100% 41%;
  padding-bottom: 9px; }

.o-navigation-bar #primaryNav li.has-submenu > span {
  background-position: 100% 41%;
  padding-bottom: 9px; }

.o-navigation-bar #primaryNav .menu-holder-pan5-pan ul:before {
  display: none; }

.o-navigation-bar #primaryNav .menu-holder-pan5-pan ul li {
  width: 100%;
  padding: 0 0 0 5px;
  margin: 0; }

.o-navigation-bar #primaryNav .menu-holder-pan2-pan ul:before {
  display: none; }

.o-navigation-bar #primaryNav .menu-holder-pan2-pan ul li {
  width: 100%;
  padding: 0 0 0 5px;
  margin: 0; }

.o-navigation-bar img {
  margin-top: 15px;
  padding-top: 0;
  padding-left: 15px; }
  @media screen and (min-width: 992px) {
    .o-navigation-bar img {
      padding-left: 0px; } }

.o-navigation-bar.margin-top-0 img {
  margin-top: 6px; }

.menu-holder-pan3 .menu-heading {
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  margin-top: 10px;
  font-weight: 500;
  margin-bottom: 20px;
  position: relative;
  padding-left: 5px;
  display: block;
  white-space: nowrap; }
  .menu-holder-pan3 .menu-heading:before {
    content: "";
    width: 30px;
    height: 2px;
    background-color: #14df7e;
    position: absolute;
    left: 5px;
    bottom: -6px; }

.menu-holder-pan3 .menu-holder-pan5-pan {
  border-left: none;
  padding-left: 15px;
  height: inherit; }
  @media screen and (min-width: 992px) {
    .menu-holder-pan3 .menu-holder-pan5-pan {
      border-left: 1px solid #495376; } }

.menu-holder-pan3 .menu-holder-pan3-pan {
  border-left: none;
  padding-left: 15px;
  height: inherit; }
  @media screen and (min-width: 992px) {
    .menu-holder-pan3 .menu-holder-pan3-pan {
      border-left: 1px solid #495376; } }
  .menu-holder-pan3 .menu-holder-pan3-pan li {
    width: auto;
    padding-left: 5px; }
    .menu-holder-pan3 .menu-holder-pan3-pan li a:hover {
      background-color: transparent;
      color: #14df7e; }
      .menu-holder-pan3 .menu-holder-pan3-pan li a:hover span {
        background-color: transparent;
        color: #14df7e; }

.menu-holder-pan3 .menu-holder-pan2-pan {
  border-left: none;
  padding-left: 15px;
  height: inherit; }
  @media screen and (min-width: 992px) {
    .menu-holder-pan3 .menu-holder-pan2-pan {
      border-left: 1px solid #495376; } }
  .menu-holder-pan3 .menu-holder-pan2-pan li {
    width: auto;
    padding-left: 10px; }

.navbar-toggler {
  position: relative;
  padding: 0;
  width: 26px;
  height: 22px;
  border: 0;
  margin-right: 15px;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px; }
  .navbar-toggler:focus {
    border: none;
    box-shadow: none; }
  @media all and (max-width: 768px) {
    .navbar-toggler span {
      display: none; } }

#nav-icon4 {
  width: 26px;
  height: 18px;
  position: relative;
  margin: 0 auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  right: -3px;
  top: -4px; }

#nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out; }
  @media screen and (min-width: 770px) {
    #nav-icon4 span {
      background: #333; } }

#nav-icon4 span:nth-child(1) {
  top: 0px;
  transform-origin: left center; }

#nav-icon4 span:nth-child(2) {
  top: 8px;
  transform-origin: left center;
  width: 14px;
  right: 0;
  left: inherit; }

#nav-icon4 span:nth-child(3) {
  top: 16px;
  transform-origin: left center;
  width: 20px;
  right: 0;
  left: inherit; }

#nav-icon4.open span:nth-child(1) {
  transform: rotate(45deg);
  top: 1px;
  left: 4px; }

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0; }

#nav-icon4.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 19px;
  left: 4px;
  width: 26px; }

.mb-justyfy-center {
  align-items: center; }
  @media (min-width: 992px) {
    .mb-justyfy-center {
      align-items: inherit; } }

.high {
  padding: 50px 0; }
  @media (min-width: 992px) {
    .high {
      padding: 80px 0 50px !important; } }
  .high h2 {
    font-weight: 600; }
  .high p {
    font-family: "Montserrat", sans-serif !important; }

.web-app-service .service-wrap .services-pan a {
  width: 134px;
  height: 46px;
  line-height: 42px;
  border: 2px solid #333333;
  border-radius: 5px;
  font-size: 18px;
  line-height: 44px;
  color: #333;
  text-decoration: none;
  font-weight: 400;
  transition: .3s all ease-in;
  background: none;
  padding: 0;
  text-transform: capitalize; }
  .web-app-service .service-wrap .services-pan a:after {
    display: none; }
  .web-app-service .service-wrap .services-pan a:hover {
    border: 2px solid #FF5800;
    color: #FF5800; }

.home-build {
  padding: 50px 0 20px; }
  @media (min-width: 992px) {
    .home-build {
      padding: 80px 0 50px !important; } }

.web-app-service-mod h2 {
  margin-bottom: 30px; }

@media screen and (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    display: none !important;
    flex-basis: auto; }
    .navbar-expand-md .navbar-collapse.show {
      display: block !important; }
  .navbar-expand-md .navbar-toggler {
    display: block; } }

@media screen and (min-width: 992px) {
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-md .navbar-toggler {
    display: none; } }

@media screen and (min-width: 768px) {
  .navbar-expand-md .navbar-nav {
    flex-direction: column; } }

@media screen and (min-width: 992px) {
  .navbar-expand-md .navbar-nav {
    flex-direction: row; } }

a.hiring {
  display: none; }
  @media screen and (min-width: 992px) {
    a.hiring {
      padding: 3px 3px;
      margin-left: 5px;
      flex: 0 0 95px;
      font-size: 12px;
      margin-top: 6px;
      background-color: #4B8FE2;
      color: #fff;
      border-radius: 5px;
      font-weight: 500;
      position: relative;
      display: block; } }
  @media screen and (min-width: 1280px) {
    a.hiring {
      flex: auto;
      padding: 3px 7px;
      font-size: 13px;
      display: block; } }

span.hiring-arrow {
  position: absolute;
  left: -6px;
  bottom: 0px;
  width: 0;
  height: 0;
  border-width: 10px 10px 0 0;
  border-color: #4b8fe2 transparent transparent transparent;
  border-style: solid;
  transform: rotate(180deg); }

.o-header .navbar ul > li a {
  font-size: 14px; }
  @media screen and (min-width: 992px) {
    .o-header .navbar ul > li a {
      font-size: 13px; } }
  @media screen and (min-width: 1200px) {
    .o-header .navbar ul > li a {
      font-size: 14px; } }

.contact-book-btn:disabled {
  pointer-events: none;
  filter: grayscale(70%);
  color: #ccc !important; }

.bottomSendButton:disabled {
  pointer-events: none;
  filter: grayscale(70%);
  color: #ccc !important; }

.contactFormTrigger:disabled {
  pointer-events: none;
  filter: grayscale(70%);
  color: #ccc !important; }

.top-addvertisement-wrapper {
  background: #3CC065; }

.top-addvertisement {
  padding: 10px 15px;
  position: relative; }
  .top-addvertisement::before {
    content: '';
    position: absolute;
    background: url(/images/easter-offering/mcolor-egg.png) no-repeat 0 0;
    width: 50px;
    height: 32px;
    top: 0;
    background-size: 75%;
    display: none; }
    @media screen and (min-width: 992px) {
      .top-addvertisement::before {
        display: block;
        right: 10px; } }
    @media screen and (min-width: 1280px) {
      .top-addvertisement::before {
        right: 30px; } }
  .top-addvertisement::after {
    content: '';
    position: absolute;
    background: url(/images/easter-offering/mcolor-egg2.png) no-repeat 0 0;
    width: 50px;
    height: 32px;
    top: 0;
    background-size: 75%;
    display: none; }
    @media screen and (min-width: 992px) {
      .top-addvertisement::after {
        display: block;
        left: 10px; } }
    @media screen and (min-width: 1280px) {
      .top-addvertisement::after {
        left: 30px; } }
  .top-addvertisement p {
    margin-bottom: 0;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center; }
    .top-addvertisement p a {
      display: inline-block;
      position: relative;
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      padding: 0 18px 0 5px;
      margin-left: 5px;
      transition: .2s all ease-in-out;
      text-decoration: underline; }
      .top-addvertisement p a:hover {
        text-decoration: underline; }
      .top-addvertisement p a:after {
        content: ">>";
        position: absolute;
        top: 0px;
        right: 0; }

.o-banner {
  background-color: #091B38;
  padding: 45px 0 20px;
  text-align: center;
  position: relative; }
  @media (min-width: 992px) {
    .o-banner {
      text-align: left;
      padding: 85px 0 50px; } }
  .o-banner h1 {
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    padding-bottom: 20px; }
    @media (min-width: 992px) {
      .o-banner h1 {
        font-size: 62px;
        line-height: 75px; } }
    .o-banner h1 .subheading {
      font-size: 24px;
      padding-bottom: 20px;
      display: block;
      line-height: normal;
      text-transform: capitalize;
      font-weight: 600; }
      @media (min-width: 992px) {
        .o-banner h1 .subheading {
          font-size: 40px;
          line-height: 50px;
          font-weight: 500; } }
  .o-banner .btn {
    margin: 0 auto; }
    @media (min-width: 992px) {
      .o-banner .btn {
        margin: 0; } }

@media screen and (min-width: 992px) {
  .hm-page .o-banner {
    padding: 85px 0 80px; } }

.hm-page .o-banner h1 {
  font-size: 26px;
  line-height: 40px;
  color: #fff;
  padding: 25px 0 20px; }

.hm-page .o-banner h2 {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  padding-bottom: 20px;
  line-height: 44px; }
  @media (min-width: 992px) {
    .hm-page .o-banner h2 {
      font-size: 62px;
      line-height: 75px; } }
  .hm-page .o-banner h2 .subheading {
    font-size: 24px;
    padding-bottom: 20px;
    display: block;
    line-height: normal;
    text-transform: capitalize;
    font-weight: 600; }
    @media (min-width: 992px) {
      .hm-page .o-banner h2 .subheading {
        font-size: 40px;
        line-height: 50px;
        font-weight: 500;
        margin-top: 10px; } }

.bnr-icon {
  display: flex;
  justify-content: center;
  max-width: 360px;
  margin: 0 auto;
  height: 160px; }
  @media (min-width: 992px) {
    .bnr-icon {
      display: none; } }

.home-bnr-icon {
  flex-wrap: wrap;
  margin-bottom: 20px; }
  .home-bnr-icon li {
    flex: 0 0 33%;
    margin-bottom: 25px;
    font-size: 0;
    background-repeat: no-repeat; }
    .home-bnr-icon li:nth-child(1) {
      background-position: 0 -140px; }
    .home-bnr-icon li:nth-child(2) {
      background-position: -115px -140px; }
    .home-bnr-icon li:nth-child(3) {
      background-position: -244px -140px; }
    .home-bnr-icon li:nth-child(4) {
      background-position: -380px -145px; }
    .home-bnr-icon li:nth-child(5) {
      background-position: -500px -140px; }

.video-pan {
  display: none; }
  @media (min-width: 992px) {
    .video-pan {
      position: relative;
      overflow: hidden;
      display: block;
      height: 323px;
      width: 98%; }
      .video-pan .banner-logo {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1000; } }
  @media screen and (min-width: 1199px) {
    .video-pan {
      height: 400px;
      width: 100%; } }
  .video-pan video {
    width: 97%; }

.home-award-logo {
  display: none;
  margin-top: 10px; }
  @media screen and (min-width: 992px) {
    .home-award-logo {
      background-size: contain;
      display: block;
      width: 100%;
      height: 186px;
      background-repeat: no-repeat;
      opacity: .9;
      background-position: center center; } }

.banner-right {
  text-align: center;
  z-index: 9999; }
  @media (min-width: 992px) {
    .banner-right {
      text-align: left; } }
  .banner-right .anchor-link {
    text-align: center; }
    @media (min-width: 992px) {
      .banner-right .anchor-link {
        text-align: left; } }
  @media (min-width: 992px) {
    .banner-right .btn {
      margin: 0; } }

#myModal1 .modal-dialog {
  width: 100%;
  background: #000;
  margin: 0 auto;
  height: 100%;
  transform: translate(0, 0);
  max-width: 100% !important; }

.contact-modal {
  background: rgba(0, 0, 0, 0.4); }

.ytp-large-play-button {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 68px;
  height: 48px;
  margin-left: -34px;
  margin-top: -24px;
  transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  z-index: 63; }

#myModal1 .modal-body {
  padding: 80px 0; }

.modal-body {
  position: relative; }

#myModal1 button.close {
  z-index: 999;
  position: relative;
  right: 0;
  top: 0;
  background: #ffffff;
  opacity: 1;
  padding: 10px; }

div#myModal1 {
  z-index: 9999999; }

.break-line {
  display: block; }
  @media (min-width: 992px) {
    .break-line {
      display: inline; } }

.banner-right h1 br {
  display: none; }
  @media (min-width: 992px) {
    .banner-right h1 br {
      display: block; } }

.hm-about-section p strong {
  margin-top: 20px; }

ul.home-bnr-group-logo {
  display: none;
  flex-wrap: wrap;
  justify-content: center; }
  @media screen and (min-width: 992px) {
    ul.home-bnr-group-logo {
      display: flex; } }
  ul.home-bnr-group-logo li {
    padding: 15px 9px;
    opacity: .8; }
    @media screen and (min-width: 992px) {
      ul.home-bnr-group-logo li {
        flex: 0 0 25%;
        display: flex;
        justify-content: center; } }
    @media screen and (min-width: 1200px) {
      ul.home-bnr-group-logo li {
        padding: 15px 20px; } }
    ul.home-bnr-group-logo li:nth-child(5), ul.home-bnr-group-logo li:nth-child(6), ul.home-bnr-group-logo li:nth-child(7) {
      flex: 1; }

.o-clients {
  position: relative;
  padding: 50px 0 25px;
  text-align: center;
  position: relative;
  border-bottom: 1px solid #cae0e7;
  background-color: #fff; }
  .o-clients h2 {
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
    color: #333;
    font-size: 17px;
    line-height: 20px;
    margin: 0 0 20px 0;
    text-transform: none;
    vertical-align: middle; }
    @media (min-width: 992px) {
      .o-clients h2 {
        line-height: 25px;
        font-weight: bold; } }
  @media screen and (min-width: 992px) {
    .o-clients .zoovu-custom-width {
      flex: 0 0 138px; } }
  @media screen and (min-width: 768px) {
    .o-clients .client-logo-modify .client-logo-new {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden; } }
  @media screen and (min-width: 768px) {
    .o-clients .client-logo-modify .client-logo-new .client-logo-img {
      margin: 15px 15px; } }

.sk-fading-circle {
  margin: 100px auto;
  width: 80px;
  height: 80px;
  position: relative; }

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #27c070;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both; }

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg); }

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg); }

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg); }

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg); }

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg); }

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg); }

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg); }

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg); }

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s; }

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s; }

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

.why-hire-java .service-wrap {
  column-count: inherit; }
  @media screen and (min-width: 992px) {
    .why-hire-java .service-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; } }
  @media screen and (min-width: 992px) {
    .why-hire-java .service-wrap .services-pan {
      flex: 0 0 31.5%; } }

.client-logo-slide .owl-item {
  visibility: hidden; }
  .client-logo-slide .owl-item.active {
    visibility: visible; }

.client-logo-slide .owl-nav {
  display: none; }

.client-logo-slide .owl-dots .owl-dot {
  background: #9b9b9b;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%; }
  .client-logo-slide .owl-dots .owl-dot.active {
    background: #49c684; }

.client-logo-slide .owl-stage {
  display: flex;
  align-items: center; }
  @media screen and (min-width: 992px) {
    .client-logo-slide .owl-stage .owl-item:last-child {
      height: auto; } }
  @media screen and (min-width: 1200px) {
    .client-logo-slide .owl-stage .owl-item:last-child {
      height: 222px; } }

.client-logo-slide .client-logo-img {
  display: flex;
  justify-content: center; }
  .client-logo-slide .client-logo-img img {
    height: 100%;
    object-fit: contain; }
    @media screen and (min-width: 992px) {
      .client-logo-slide .client-logo-img img {
        height: inherit;
        object-fit: inherit; } }

.nw-client-slider .owl-item {
  height: 86px;
  align-items: center;
  justify-content: center;
  display: flex; }
  @media screen and (min-width: 992px) {
    .nw-client-slider .owl-item {
      height: auto; } }

/* Chrome version 29 and above */
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) and (min-width: 992px) {
  .client-logo-slide .owl-stage .owl-item:last-child {
    height: 198px; } }

.nw-client {
  padding: 20px 0 20px; }
  @media screen and (min-width: 992px) {
    .nw-client {
      padding: 50px 0 25px; } }
  .nw-client .client-logo-slide {
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .nw-client .client-logo-slide {
        margin-bottom: 0 0 1rem; } }
  .nw-client h2 {
    margin: 0; }
    @media screen and (min-width: 992px) {
      .nw-client h2 {
        margin: 0 0 20px 0; } }
  .nw-client .owl-dots {
    display: none; }

.splide__arrows {
  display: none; }

.dev-client {
  background: #F5F7F8 !important; }
  @media screen and (min-width: 992px) {
    .dev-client {
      padding: 60px 0; } }
  .dev-client h2 {
    font-size: 21px;
    line-height: 30px;
    font-weight: 500; }
    @media screen and (min-width: 992px) {
      .dev-client h2 {
        margin-bottom: 25px !important; } }
  .dev-client .client-logo-img {
    height: 96px;
    border: 1px solid #ECE9E9;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 10px; }
    @media screen and (min-width: 768px) {
      .dev-client .client-logo-img {
        margin: 3px 3px !important;
        padding: 0; } }
    @media screen and (min-width: 992px) {
      .dev-client .client-logo-img {
        flex: 1; } }
    .dev-client .client-logo-img img {
      height: 100%;
      object-fit: contain; }

.company-profile-client .dev-client {
  background: #fff !important;
  border-bottom: none !important; }

.certification {
  border-bottom: 1px solid #E2E2E2;
  background: #F2F5F7;
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .certification {
      padding: 0; } }
  @media screen and (min-width: 992px) {
    .certification .certification-left {
      display: flex;
      align-items: center; } }
  .certification .certification-left h4 {
    margin-bottom: 0;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .certification .certification-left h4 {
        text-align: left; } }
  .certification .certification-left svg {
    margin: 0 25px;
    display: none; }
    @media screen and (min-width: 992px) {
      .certification .certification-left svg {
        display: inline-block; } }
  @media screen and (min-width: 992px) {
    .certification .certification-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1; } }
  .certification .certification-items {
    align-items: center;
    justify-content: center;
    display: flex; }
    @media screen and (min-width: 992px) {
      .certification .certification-items {
        flex: 1; } }

.o-home-service {
  position: relative;
  padding-bottom: 20px; }
  .o-home-service .n-gradient-green-blue {
    min-height: 430px; }
  @media (min-width: 992px) {
    .o-home-service {
      padding-bottom: 50px; } }
  @media (min-width: 992px) {
    .o-home-service .btn_wrapper {
      padding-top: 0; } }
  .o-home-service .home-link.pad-top-20 {
    padding-bottom: 20px;
    padding-top: 10px; }
    @media (min-width: 992px) {
      .o-home-service .home-link.pad-top-20 {
        padding-top: 25px;
        padding-bottom: 35px; } }

.o-home-service_holder {
  width: 90%;
  position: relative;
  margin: 0 auto;
  margin-top: -410px; }
  .o-home-service_holder h3 {
    font-size: 20px;
    line-height: 29px;
    color: #fff;
    padding-top: 25px;
    padding-left: 10px;
    padding-right: 10px; }
    @media (min-width: 992px) {
      .o-home-service_holder h3 {
        font-size: 36px;
        line-height: 49px;
        padding: 50px 100px 30px; } }

.no-webp .o-home-service .white-box:after {
  background-image: url(/images/home/home-arrow.png); }

.no-webp .o-home-service .white-box::before {
  background-image: url(/images/home/home-sprite.png); }

.o-home-service .white-box {
  background-color: #fff;
  display: inline-block;
  height: 180px;
  padding: 30px 25px;
  width: 48%;
  margin: 0 4px 10px;
  overflow: hidden;
  border-bottom: 4px solid #14DF7D;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  position: relative;
  font-size: 16px;
  line-height: 18px;
  color: #333;
  font-weight: 500;
  vertical-align: top;
  box-shadow: 0 0 12px #a8a8a880;
  -webkit-box-shadow: 0 0 12px #a8a8a880;
  -moz-box-shadow: 0 0 12px #a8a8a880;
  position: relative; }
  @media (min-width: 992px) {
    .o-home-service .white-box {
      width: 225px;
      height: 225px;
      font-size: 20px;
      font-size: 18px;
      line-height: 24px; } }
  .o-home-service .white-box__blue-border {
    border-bottom: 4px solid #4A90E2; }
  .o-home-service .white-box:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, 0);
    background-image: url(/images/home/home-arrow.png);
    width: 22px;
    height: 22px;
    background-position: 0 0; }
  .o-home-service .white-box:nth-child(2):before {
    background-position: 0 0; }
  .o-home-service .white-box:nth-child(3):before {
    background-position: -80px 0; }
  .o-home-service .white-box:nth-child(4):before {
    background-position: -160px 0; }
  .o-home-service .white-box:nth-child(5):before {
    background-position: -240px 0; }
  .o-home-service .white-box .cmn-color {
    display: block;
    position: absolute;
    top: inherit;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    width: 100%;
    height: 0;
    transition: .3s all ease-out; }
  .o-home-service .white-box i {
    display: block;
    height: 45px;
    position: relative;
    z-index: 1;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .o-home-service .white-box i {
        margin-bottom: 33px;
        height: 55px; } }
    .o-home-service .white-box i svg {
      height: 100%; }
  @media (hover: hover) and (pointer: fine) {
    .o-home-service .white-box:hover .cmn-color {
      height: 100%; }
    .o-home-service .white-box:hover .box-txt {
      z-index: 9;
      color: #fff;
      position: relative; }
    .o-home-service .white-box:hover:after {
      background-image: url(/images/home/home-arrow-hover.png); }
    .o-home-service .white-box:hover:before {
      z-index: 9;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 0 0; }
    .o-home-service .white-box:hover i svg path,
    .o-home-service .white-box:hover i svg polygon,
    .o-home-service .white-box:hover i svg rect {
      fill: #fff;
      stroke: #fff; } }

.o-home-service .home-link {
  font-size: 16px; }
  @media (min-width: 992px) {
    .o-home-service .home-link {
      font-size: 19px; } }
  .o-home-service .home-link:hover {
    color: #333;
    text-decoration: underline; }

.o-home-service .white-box__choose-01 .cmn-color {
  background: #14df7d; }

@media (hover: hover) and (pointer: fine) {
  .o-home-service .white-box__choose-01:hover:before {
    background-image: url(/images/home/h-service-icon1.png); } }

@media (hover: hover) and (pointer: fine) and (min-width: 992px) {
  .o-home-service .white-box__choose-01:hover .cmn-color {
    background: #14df7d; } }

.o-home-service .white-box__choose-02 {
  border-bottom: 4px solid #4a90e2; }
  @media (min-width: 992px) {
    .o-home-service .white-box__choose-02 {
      border-bottom: 4px solid #00af93; } }
  .o-home-service .white-box__choose-02:before {
    background-image: url(/images/home/b-h-service-icon2.png) !important;
    background-repeat: no-repeat;
    background-position: center center !important; }
  .o-home-service .white-box__choose-02 .cmn-color {
    background: #4a90e2; }
  @media (hover: hover) and (pointer: fine) {
    .o-home-service .white-box__choose-02:hover:before {
      background-image: url(/images/home/h-service-icon2.png) !important;
      background-size: 75%;
      background-position: center center; }
    .o-home-service .white-box__choose-02 .cmn-color {
      background: #00af93; } }

.o-home-service .white-box__choose-03 {
  border-bottom: 4px solid #4a90e2; }
  @media (min-width: 992px) {
    .o-home-service .white-box__choose-03 {
      border-bottom: 4px solid #0095b5; } }
  .o-home-service .white-box__choose-03 .cmn-color {
    background: #14df7d; }
  @media (hover: hover) and (pointer: fine) {
    .o-home-service .white-box__choose-03:hover:before {
      background-image: url(/images/home/h-service-icon3.png);
      background-size: 80%;
      background-position: center center; }
    .o-home-service .white-box__choose-03:hover .cmn-color {
      background: #0095b5; } }

.o-home-service .white-box__choose-04 {
  border-bottom: 4px solid #14df7d; }
  @media (min-width: 992px) {
    .o-home-service .white-box__choose-04 {
      border-bottom: 4px solid #4a90e2; } }
  .o-home-service .white-box__choose-04 .cmn-color {
    background: #4a90e2; }
  @media (hover: hover) and (pointer: fine) {
    .o-home-service .white-box__choose-04:hover:before {
      background-image: url(/images/home/h-service-icon4.png);
      background-size: 75%;
      background-position: center center; }
    .o-home-service .white-box__choose-04:hover .cmn-color {
      background: #4a90e2; } }

.srv-a-box-wrap {
  padding: 0 15px; }
  @media (min-width: 992px) {
    .srv-a-box-wrap {
      padding: 0; } }
  .srv-a-box-wrap a:nth-child(2n+1) {
    margin-left: 0; }
    @media (min-width: 992px) {
      .srv-a-box-wrap a:nth-child(2n+1) {
        margin-left: 4px; } }
  .srv-a-box-wrap a:nth-child(2n) {
    margin-right: 0; }
    @media (min-width: 992px) {
      .srv-a-box-wrap a:nth-child(2n) {
        margin-right: 4px; } }

.o-home-technology {
  position: relative;
  padding: 45px 0 20px; }
  @media (min-width: 992px) {
    .o-home-technology {
      padding: 70px 0 50px; } }
  .o-home-technology h2 {
    font-size: 30px; }
    @media (min-width: 992px) {
      .o-home-technology h2 {
        font-size: 48px; } }
  .o-home-technology .home-link {
    margin-top: 0; }
    @media (min-width: 992px) {
      .o-home-technology .home-link {
        margin-top: 15px;
        padding-bottom: 20px; } }
  .o-home-technology .box-wrapper {
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .o-home-technology .box-wrapper {
        margin-bottom: 0; } }
    .o-home-technology .box-wrapper .box {
      overflow: hidden;
      display: inline-block;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      margin: 0 4px 4px;
      position: relative;
      padding: 30px 10px 30px;
      font-size: 20px;
      color: #333;
      font-weight: 500;
      position: relative;
      width: 48%;
      height: 180px;
      font-size: 18px;
      box-shadow: 0 0 12px #a8a8a880;
      -webkit-box-shadow: 0 0 12px #a8a8a880;
      -moz-box-shadow: 0 0 12px #a8a8a880; }
      @media (min-width: 992px) {
        .o-home-technology .box-wrapper .box {
          width: 170px;
          height: 190px;
          margin: 0 7px 14px; } }
      .o-home-technology .box-wrapper .box i {
        display: block;
        height: 50px;
        margin-bottom: 20px;
        position: relative;
        z-index: 1; }
        .o-home-technology .box-wrapper .box i svg {
          height: 100%; }
      .o-home-technology .box-wrapper .box:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 20px;
        transform: translate(-50%, 0);
        background-image: url(/images/home/home-arrow.png);
        width: 22px;
        height: 22px;
        background-position: 0; }
      .o-home-technology .box-wrapper .box:nth-child(1) {
        border-bottom: 4px solid #00b05c; }
        @media (hover: hover) and (pointer: fine) {
          .o-home-technology .box-wrapper .box:nth-child(1):hover {
            color: #fff; } }
      .o-home-technology .box-wrapper .box:nth-child(2) {
        border-bottom: 4px solid #0095b5; }
        @media (min-width: 992px) {
          .o-home-technology .box-wrapper .box:nth-child(2) {
            border-bottom: 4px solid #00af93; } }
        @media (hover: hover) and (pointer: fine) {
          .o-home-technology .box-wrapper .box:nth-child(2):hover {
            color: #fff; } }
      .o-home-technology .box-wrapper .box:nth-child(3) {
        border-bottom: 4px solid #34bbea; }
        @media (min-width: 992px) {
          .o-home-technology .box-wrapper .box:nth-child(3) {
            border-bottom: 4px solid #0095b5; } }
        @media (hover: hover) and (pointer: fine) {
          .o-home-technology .box-wrapper .box:nth-child(3):hover {
            color: #fff; } }
      .o-home-technology .box-wrapper .box:nth-child(4) {
        border-bottom: 4px solid #00af93; }
        @media (min-width: 992px) {
          .o-home-technology .box-wrapper .box:nth-child(4) {
            border-bottom: 4px solid #007fab; } }
        @media (hover: hover) and (pointer: fine) {
          .o-home-technology .box-wrapper .box:nth-child(4):hover {
            color: #fff; } }
      .o-home-technology .box-wrapper .box:nth-child(5) {
        border-bottom: 4px solid #018d46; }
        @media (min-width: 992px) {
          .o-home-technology .box-wrapper .box:nth-child(5) {
            border-bottom: 4px solid #014d95; } }
        @media (hover: hover) and (pointer: fine) {
          .o-home-technology .box-wrapper .box:nth-child(5):hover {
            color: #fff; } }
      .o-home-technology .box-wrapper .box:nth-child(6) {
        border-bottom: 4px solid #014d95; }
        @media (min-width: 992px) {
          .o-home-technology .box-wrapper .box:nth-child(6) {
            border-bottom: 4px solid #15336B; } }
        @media (hover: hover) and (pointer: fine) {
          .o-home-technology .box-wrapper .box:nth-child(6):hover {
            color: #fff; } }
      @media (hover: hover) and (pointer: fine) {
        .o-home-technology .box-wrapper .box:hover:after {
          background-image: url(/images/home/home-arrow-hover.png); }
        .o-home-technology .box-wrapper .box:hover i svg path,
        .o-home-technology .box-wrapper .box:hover i svg polygon,
        .o-home-technology .box-wrapper .box:hover i svg rect {
          fill: #fff;
          stroke: #fff; } }
      .o-home-technology .box-wrapper .box .cmn-color {
        display: block;
        position: absolute;
        top: inherit;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        width: 100%;
        height: 0;
        transition: .3s all ease-out; }
      @media (hover: hover) and (pointer: fine) {
        .o-home-technology .box-wrapper .box:hover .cmn-color {
          height: 100%; }
        .o-home-technology .box-wrapper .box:hover .box-txt {
          z-index: 9;
          color: #fff;
          position: relative; }
        .o-home-technology .box-wrapper .box:hover:after {
          background-image: url(/images/home/home-arrow-hover.png); }
        .o-home-technology .box-wrapper .box:hover:before {
          z-index: 9; } }
      .o-home-technology .box-wrapper .box:nth-child(2n+1) {
        margin-left: 0; }
        @media (min-width: 992px) {
          .o-home-technology .box-wrapper .box:nth-child(2n+1) {
            margin-left: 7px; } }
      .o-home-technology .box-wrapper .box:nth-child(2n) {
        margin-right: 0; }
        @media (min-width: 992px) {
          .o-home-technology .box-wrapper .box:nth-child(2n) {
            margin-right: 7px; } }
  .o-home-technology .pad-bot-40 {
    padding-bottom: 10px; }
    @media (min-width: 992px) {
      .o-home-technology .pad-bot-40 {
        padding-bottom: 30px; } }
  .o-home-technology .btn_wrapper.padding-top-40 {
    padding-top: 0; }
    @media (min-width: 992px) {
      .o-home-technology .btn_wrapper.padding-top-40 {
        padding-top: 15px; } }

@media (hover: hover) and (pointer: fine) {
  .o-home-technology .box-wrapper .box:hover {
    border-bottom: 0; } }

.o-home-technology .box-wrapper .box:nth-child(1) .cmn-color {
  background: #00b05c; }

.o-home-technology .box-wrapper .box:nth-child(2) .cmn-color {
  background: #00af93; }

.o-home-technology .box-wrapper .box:nth-child(3) .cmn-color {
  background: #0095b5; }

.o-home-technology .box-wrapper .box:nth-child(4) .cmn-color {
  background: #007fab; }

.o-home-technology .box-wrapper .box:nth-child(5) .cmn-color {
  background: #014d95; }

.o-home-technology .box-wrapper .box:nth-child(6) .cmn-color {
  background: #15336b; }

.box-bg {
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 188px;
  margin-bottom: 25px;
  position: relative;
  display: block; }
  .box-bg img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

.logo-thumb h4 {
  font-size: 30px;
  margin-bottom: 35px;
  margin-top: 0; }

.logo-thumb a:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 75%;
  transform: translate(-50%, 0);
  background-image: url(images/home/home-arrow.png);
  width: 22px;
  height: 22px; }

.home-fi {
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  overflow: hidden; }
  @media (min-width: 992px) {
    .home-fi {
      background-size: cover; } }
  .home-fi:before {
    display: block;
    content: '';
    position: absolute;
    background-image: url(/images/home/remotedev-img-mobile.svg);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 65%;
    height: 100%;
    top: 0;
    right: 0; }
    @media (min-width: 992px) {
      .home-fi:before {
        display: none; } }
  .home-fi h2 {
    font-size: 16px;
    padding: 0px 0 10px 0;
    text-align: center;
    margin-top: 40px;
    width: 200px;
    line-height: 40px;
    background: url(/images/home/remotedev-heading-bg.png) no-repeat 0 0;
    color: #fff;
    background-size: 100%;
    font-weight: 600; }
    @media (min-width: 992px) {
      .home-fi h2 {
        width: 230px;
        line-height: 47px;
        font-size: 20px;
        padding: 0 0 15px 20px;
        text-align: left; } }
  .home-fi h3 {
    font-size: 22px;
    line-height: 29px;
    color: #fff;
    padding: 20px 47% 20px 0;
    font-weight: 700; }
    @media screen and (min-width: 768px) {
      .home-fi h3 {
        font-weight: 600;
        padding: 20px 0 20px 0; } }
    @media screen and (min-width: 992px) {
      .home-fi h3 {
        padding: 20px 0 20px 0;
        font-size: 37px;
        line-height: 50px; } }
    @media screen and (min-width: 1280px) {
      .home-fi h3 {
        font-size: 40px; } }
    .home-fi h3 span.text-green {
      color: #14DF7D; }
  .home-fi .btn {
    margin: 0; }
  .home-fi .btn-orange:after {
    margin-left: 5px; }
    @media (min-width: 992px) {
      .home-fi .btn-orange:after {
        margin-left: 15px; } }
  .home-fi .story-bg {
    background-image: url(/images/home/remotedev-bg.jpg) !important;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover; }
    .home-fi .story-bg:before {
      display: block;
      content: '';
      position: absolute;
      background-image: url(/images/home/remotedev-img-mobile.svg);
      background-position: right center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 65%;
      height: 100%;
      top: 0;
      right: 0; }
      @media screen and (min-width: 992px) {
        .home-fi .story-bg:before {
          display: none; } }
  .home-fi .whitepaper-bg {
    background-image: url(/images/home/feature-bg-new.jpg) !important;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover; }
    .home-fi .whitepaper-bg:before {
      display: block;
      content: '';
      position: absolute;
      background-image: url(/images/home/remotedev-white-img-mobile.png);
      background-position: right center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 65%;
      height: 100%;
      top: 0;
      right: 0; }
      @media screen and (min-width: 992px) {
        .home-fi .whitepaper-bg:before {
          display: none; } }
  .home-fi .owl-dots {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    text-align: center; }
    .home-fi .owl-dots .owl-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #9b9b9b;
      margin: 0 5px; }
      .home-fi .owl-dots .owl-dot.active {
        background: #49c684; }
  .home-fi .featured-book-img {
    position: relative;
    top: 0;
    height: 100%;
    width: auto; }
    @media screen and (min-width: 992px) {
      .home-fi .featured-book-img {
        right: -5px;
        position: absolute; } }
    @media screen and (min-width: 1280px) {
      .home-fi .featured-book-img {
        right: -15px; } }
    .home-fi .featured-book-img img {
      height: 100%;
      width: auto;
      object-fit: cover; }
    @media screen and (min-width: 992px) {
      .home-fi .featured-book-img-white {
        right: -25px; } }
    @media screen and (min-width: 1280px) {
      .home-fi .featured-book-img-white {
        right: -35px; } }
  .home-fi .row {
    position: relative; }

.no-webp .book-img-left {
  background-size: cover;
  background: none; }
  @media (min-width: 992px) {
    .no-webp .book-img-left {
      background-image: none; } }

.webp .book-img-left {
  background-size: cover;
  background: none; }
  @media (min-width: 992px) {
    .webp .book-img-left {
      background-image: none; } }

.book-img-left {
  padding-bottom: 40px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  padding-left: 15px; }
  @media screen and (min-width: 992px) {
    .book-img-left {
      padding-bottom: 40px; } }
  @media screen and (min-width: 1200px) {
    .book-img-left {
      background-image: none;
      padding-top: 10px;
      padding-bottom: 47px;
      padding-left: 15px; } }
  .book-img-left-mod h3 {
    padding-right: 44%; }
    @media screen and (min-width: 992px) {
      .book-img-left-mod h3 {
        padding-right: 0; } }

.book-img {
  display: none; }
  @media (min-width: 992px) {
    .book-img {
      position: relative;
      display: block; } }
  .book-img img {
    top: 0;
    position: absolute;
    left: -15px; }

.hire-expert-home {
  padding: 40px 0; }
  @media screen and (min-width: 992px) {
    .hire-expert-home {
      padding: 65px 0; } }
  .hire-expert-home h2 {
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .hire-expert-home h2 {
        font-size: 42px;
        line-height: 52px; } }
  .hire-expert-home h3 {
    font-size: 22px;
    line-height: 34px;
    font-weight: 500; }

.o-why-choose-cn {
  background: none;
  padding-top: 50px; }
  @media (min-width: 992px) {
    .o-why-choose-cn {
      background: url(/images/home/why-choose-cn.jpg) no-repeat 50% 50%;
      color: #fff;
      padding-top: 0;
      background-size: cover; } }
  .o-why-choose-cn_left {
    padding: 30px 15px 40px 25px;
    border-radius: 5px;
    margin: 0 12px;
    background-color: #1D2942;
    width: auto;
    flex: 1;
    position: relative; }
    @media (min-width: 992px) {
      .o-why-choose-cn_left {
        width: 40%;
        float: left;
        min-height: 200px;
        padding: 60px 25px 65px 40px;
        margin: 0 0 0 15px;
        border-radius: 5px 0 0 5px;
        flex: inherit; } }
    .o-why-choose-cn_left:before {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 25px;
      margin: 0 auto;
      display: block;
      width: 15px;
      height: 15px;
      transform: rotate(45deg);
      background: #1d2942; }
      @media (min-width: 992px) {
        .o-why-choose-cn_left:before {
          display: none; } }
    .o-why-choose-cn_left h2,
    .o-why-choose-cn_left h3 {
      font-size: 24px;
      line-height: 30px;
      text-align: left;
      text-transform: uppercase;
      padding: 0 0 0 0; }
      @media screen and (min-width: 992px) {
        .o-why-choose-cn_left h2,
        .o-why-choose-cn_left h3 {
          font-size: 48px;
          line-height: 60px; } }
      @media screen and (min-width: 1200px) {
        .o-why-choose-cn_left h2,
        .o-why-choose-cn_left h3 {
          font-size: 56px;
          padding: 0 30px 0 15px; } }
      .o-why-choose-cn_left h2 span,
      .o-why-choose-cn_left h3 span {
        font-size: 20px;
        text-transform: capitalize;
        line-height: 24px;
        display: block;
        padding-bottom: 5px; }
        @media (min-width: 992px) {
          .o-why-choose-cn_left h2 span,
          .o-why-choose-cn_left h3 span {
            font-size: 34px;
            padding-bottom: 30px;
            display: block;
            line-height: 14px; } }
      .o-why-choose-cn_left h2:before,
      .o-why-choose-cn_left h3:before {
        right: inherit;
        left: 0;
        bottom: -15px;
        width: 90px;
        height: 2px;
        content: "";
        position: absolute;
        background-color: #14DF7D; }
        @media (min-width: 992px) {
          .o-why-choose-cn_left h2:before,
          .o-why-choose-cn_left h3:before {
            right: inherit;
            left: 20px;
            bottom: -25px;
            width: 175px; } }
  .o-why-choose-cn .o-home-cn-details_box1 {
    width: auto;
    flex: 1;
    margin-left: 5px;
    margin-right: 15px;
    margin-bottom: 0; }
  .o-why-choose-cn_right {
    display: none; }
    @media (min-width: 992px) {
      .o-why-choose-cn_right {
        display: block;
        width: 58%;
        float: left;
        background-color: #075091;
        padding: 65px 135px 65px 100px; }
        .o-why-choose-cn_right h3,
        .o-why-choose-cn_right p {
          color: #fff;
          font-weight: 600; } }
    @media (min-width: 992px) and (min-width: 992px) {
      .o-why-choose-cn_right h3,
      .o-why-choose-cn_right p {
        font-size: 24px;
        line-height: 40px;
        padding-bottom: 0;
        margin-bottom: 0; } }
    @media (min-width: 992px) {
        .o-why-choose-cn_right .btn {
          margin: 0; }
        .o-why-choose-cn_right .anchor-link {
          text-align: left;
          color: #fff; } }

.o-home-cn-details_details-box {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  margin-bottom: 1%;
  margin-right: 1%;
  float: left;
  position: relative;
  overflow: hidden; }
  .o-home-cn-details_details-box a {
    display: block;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    position: relative; }
    @media (min-width: 992px) {
      .o-home-cn-details_details-box a {
        min-height: 225px; } }
    .o-home-cn-details_details-box a:hover {
      transform: scale(1.1); }

.o-home-cn-details_box1 {
  width: 100%;
  border: 1px solid #ccc;
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .o-home-cn-details_box1 {
      width: 40%;
      margin-bottom: 1%; } }
  .o-home-cn-details_box1 a {
    text-align: center;
    padding: 100px 0 20px 0; }
    @media (min-width: 992px) {
      .o-home-cn-details_box1 a {
        padding: 70px 0 0 160px;
        min-height: 225px;
        text-align: left; } }
    .o-home-cn-details_box1 a:before {
      position: absolute;
      content: "";
      background: url(/images/home/details-icon-1.png) no-repeat 50% 50%;
      width: 74px;
      height: 76px;
      transform: translate(0, -50%);
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 15px;
      transform: translate(0); }
      @media (min-width: 992px) {
        .o-home-cn-details_box1 a:before {
          left: 50px;
          top: 65px;
          content: "";
          width: 74px;
          height: 76px;
          margin: 0; } }
    .o-home-cn-details_box1 a h4 {
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .o-home-cn-details_box1 a h4 {
          font-family: "Montserrat", sans-serif; } }

.o-home-cn-details_box2 {
  min-height: 155px;
  width: 100%;
  background: url(/images/home/details-bg-1.jpg) no-repeat;
  margin-bottom: 10px;
  background-size: cover !important; }
  @media (min-width: 992px) {
    .o-home-cn-details_box2 {
      width: 38%;
      min-height: 225px;
      margin-bottom: 1%; } }
  .o-home-cn-details_box2 a {
    padding: 100px 0 20px 0;
    color: #fff;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .o-home-cn-details_box2 a {
        font-family: "Montserrat", sans-serif;
        padding-top: 140px;
        font-size: 16px; } }
    .o-home-cn-details_box2 a span {
      width: 40px;
      height: 40px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: url(/images/home/play-button.png) no-repeat 50% 50%;
      background-size: 40px; }
      @media (min-width: 992px) {
        .o-home-cn-details_box2 a span {
          width: 55px;
          height: 55px;
          top: 47%;
          background-size: 100%; } }
      .o-home-cn-details_box2 a span:hover {
        background: url(/images/home/play-button-hover.png) no-repeat 50% 50%; }

.o-home-cn-details_box3 {
  width: 48%;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #009C52; }
  @media (min-width: 992px) {
    .o-home-cn-details_box3 {
      width: 19%;
      min-height: 225px;
      margin-bottom: 15px; } }
  .o-home-cn-details_box3 a {
    text-align: left;
    padding: 80px 15px 20px 15px;
    color: #fff; }
    @media (min-width: 992px) {
      .o-home-cn-details_box3 a {
        padding: 80px 0 0 50px; } }
    .o-home-cn-details_box3 a:before {
      position: absolute;
      content: "";
      background: url(/images/home/details-icon-3.png) no-repeat 50% 50%;
      width: 74px;
      height: 76px;
      transform: translate(0);
      left: 5px;
      right: inherit;
      top: 10px;
      margin: 0;
      background-size: 62%; }
      @media (min-width: 992px) {
        .o-home-cn-details_box3 a:before {
          width: 38px;
          height: 38px;
          left: 50px;
          top: 40px;
          background-size: 100%; } }
    .o-home-cn-details_box3 a h4 {
      font-family: "Montserrat", sans-serif; }

.o-home-cn-details_box4 {
  width: 48%;
  margin-right: 0;
  margin-bottom: 25px;
  background-color: #009C52; }
  @media (min-width: 992px) {
    .o-home-cn-details_box4 {
      width: 19%;
      min-height: 225px;
      margin-right: 10px; } }
  .o-home-cn-details_box4 a {
    text-align: left;
    padding: 83px 15px 20px 15px; }
    @media (min-width: 992px) {
      .o-home-cn-details_box4 a {
        padding: 90px 0 0 30px; } }
    .o-home-cn-details_box4 a:before {
      position: absolute;
      content: "";
      background: url(/images/home/details-icon-4.png) no-repeat 50% 50%;
      width: 74px;
      height: 76px;
      transform: translate(0);
      left: 15px;
      right: inherit;
      top: 20px;
      margin: 0;
      background-size: 73%; }
      @media (min-width: 992px) {
        .o-home-cn-details_box4 a:before {
          left: 30px;
          top: 23px;
          background-size: 100%; } }

.o-home-cn-details_box5 {
  width: 100%;
  background: url(/images/home/details-bg-2.jpg) no-repeat;
  text-align: center;
  margin-bottom: 10px;
  background-size: cover !important; }
  @media (min-width: 992px) {
    .o-home-cn-details_box5 {
      width: 38%;
      min-height: 225px;
      margin-bottom: 1%; } }
  .o-home-cn-details_box5 a {
    padding: 90px 0 30px 0; }
    @media (min-width: 992px) {
      .o-home-cn-details_box5 a {
        padding: 85px 0 0 110px; } }
    .o-home-cn-details_box5 a:before {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 40px;
      transform: translate(0);
      content: "";
      background: url(/images/home/details-icon-8.png) no-repeat 0 0;
      width: 41px;
      height: 41px; }
      @media (min-width: 992px) {
        .o-home-cn-details_box5 a:before {
          width: 41px;
          height: 41px;
          left: 45px;
          top: 93px;
          margin: 0; } }
    .o-home-cn-details_box5 a h5 {
      font-size: 18px; }
      @media (min-width: 992px) {
        .o-home-cn-details_box5 a h5 {
          text-align: left;
          font-size: 20px; } }
      .o-home-cn-details_box5 a h5 span {
        display: block; }
        @media (min-width: 992px) {
          .o-home-cn-details_box5 a h5 span {
            display: inline; } }

.o-home-cn-details_box6 {
  background-color: #4A90E2;
  width: 49%;
  margin-right: 0; }
  @media (min-width: 992px) {
    .o-home-cn-details_box6 {
      width: 20%;
      min-height: 225px;
      margin-right: 1%; } }
  .o-home-cn-details_box6 a {
    text-align: left;
    padding: 80px 15px 20px 15px; }
    @media (min-width: 992px) {
      .o-home-cn-details_box6 a {
        padding: 90px 0 0 50px; } }
    .o-home-cn-details_box6 a:before {
      content: "";
      background: url(/images/home/details-icon-6.png) no-repeat 0 0;
      width: 56px;
      height: 53px;
      left: 15px;
      right: inherit;
      top: 25px;
      position: absolute; }
      @media (min-width: 992px) {
        .o-home-cn-details_box6 a:before {
          left: 50px;
          top: 40px; } }

.o-home-cn-details_box7 {
  width: 49%;
  border: 1px solid #ccc;
  margin-right: 10px; }
  @media (min-width: 992px) {
    .o-home-cn-details_box7 {
      width: 19%;
      min-height: 225px;
      margin-right: 1%; } }
  .o-home-cn-details_box7 a {
    text-align: left;
    padding: 92px 15px 20px 15px; }
    @media (min-width: 992px) {
      .o-home-cn-details_box7 a {
        padding: 95px 15px 20px 35px; } }
    .o-home-cn-details_box7 a:before {
      position: absolute;
      left: 15px;
      right: inherit;
      top: 25px;
      content: "";
      background: url(/images/home/details-icon-7.png) no-repeat 0 0;
      width: 140px;
      height: 65px; }
      @media (min-width: 992px) {
        .o-home-cn-details_box7 a:before {
          left: 35px; } }
  .o-home-cn-details_box7 h3 {
    margin-top: 7px; }
    @media (min-width: 992px) {
      .o-home-cn-details_box7 h3 {
        margin-top: 0; } }

.o-home-cn-details_details-box h4 {
  font-size: 15px;
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .o-home-cn-details_details-box h4 {
      font-family: "Montserrat", sans-serif;
      font-size: 22px; } }

@media (min-width: 992px) {
  .o-home-cn-details {
    padding-top: 50px; } }

.o-home-cn-details .btn-center-block {
  padding-top: 15px; }
  @media (min-width: 992px) {
    .o-home-cn-details .btn-center-block {
      padding-top: 15px; } }

.why-details {
  margin-bottom: 10px; }
  @media (min-width: 992px) {
    .why-details {
      margin-bottom: 32px; } }
  .why-details ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (min-width: 992px) {
      .why-details ul {
        justify-content: center; } }
    .why-details ul li {
      flex: 1;
      max-width: inherit;
      margin: 6px 0;
      flex: 0 0 48%;
      max-width: 48%;
      padding: 17px 10px 20px 10px;
      border-radius: 5px;
      height: 150px; }
      @media screen and (min-width: 992px) {
        .why-details ul li {
          flex: 0 0 18.4%;
          max-width: 18.4%;
          padding: 30px 15px 0 15px;
          height: 225px;
          margin: 3px 3px; } }
      @media screen and (min-width: 1200px) {
        .why-details ul li {
          flex: 0 0 19.4%;
          max-width: 19.4%;
          padding: 30px 25px 0 35px; } }
      .why-details ul li a svg, .why-details ul li a img {
        height: 35px;
        width: auto; }
        @media (min-width: 992px) {
          .why-details ul li a svg, .why-details ul li a img {
            height: 50px;
            width: auto; } }
      .why-details ul li a i {
        display: block;
        height: 45px;
        width: auto; }
        @media (min-width: 992px) {
          .why-details ul li a i {
            height: 65px;
            width: auto; } }
      .why-details ul li svg, .why-details ul li img {
        height: 35px;
        width: auto; }
        @media (min-width: 992px) {
          .why-details ul li svg, .why-details ul li img {
            height: 50px;
            width: auto; } }
      .why-details ul li i {
        display: block;
        height: 45px;
        width: auto; }
        @media (min-width: 992px) {
          .why-details ul li i {
            height: 65px;
            width: auto; } }
      .why-details ul li:nth-child(1) {
        border: 1px solid #49C684;
        order: 1; }
        @media (min-width: 992px) {
          .why-details ul li:nth-child(1) {
            order: inherit; } }
      .why-details ul li:nth-child(2) {
        background: #4A90E2;
        order: 2; }
        @media (min-width: 992px) {
          .why-details ul li:nth-child(2) {
            order: inherit; } }
      .why-details ul li:nth-child(3) {
        background: #009C52;
        order: 3; }
        @media (min-width: 992px) {
          .why-details ul li:nth-child(3) {
            order: inherit; } }
      .why-details ul li:nth-child(4) {
        border: 1px solid #4A90E2;
        order: 4; }
        @media (min-width: 992px) {
          .why-details ul li:nth-child(4) {
            order: inherit; } }
        .why-details ul li:nth-child(4) a svg {
          height: 30px;
          position: relative;
          top: 5px; }
          @media (min-width: 992px) {
            .why-details ul li:nth-child(4) a svg {
              height: 40px;
              top: 8px; } }
      .why-details ul li:nth-child(5) {
        background: #4A90E2;
        order: 6; }
        @media (min-width: 992px) {
          .why-details ul li:nth-child(5) {
            order: inherit; } }
        .why-details ul li:nth-child(5) svg {
          position: relative;
          top: 5px; }
          @media (min-width: 992px) {
            .why-details ul li:nth-child(5) svg {
              top: 0; } }
      .why-details ul li:nth-child(6) {
        flex: 1;
        max-width: inherit;
        background: #fff;
        padding-top: 25px;
        order: 9;
        border: 1px solid #4A90E2; }
        @media screen and (min-width: 992px) {
          .why-details ul li:nth-child(6) {
            order: 7;
            flex: 0 0 37.2%;
            max-width: 37.2%;
            padding-top: 25px;
            padding-left: 30px; } }
        @media screen and (min-width: 1200px) {
          .why-details ul li:nth-child(6) {
            flex: 0 0 39.3%;
            max-width: 39.3%; } }
        .why-details ul li:nth-child(6) i {
          height: auto; }
        .why-details ul li:nth-child(6) a {
          display: flex;
          align-items: center; }
          .why-details ul li:nth-child(6) a svg, .why-details ul li:nth-child(6) a img {
            width: auto;
            height: 85px;
            padding-right: 20px; }
            @media (min-width: 992px) {
              .why-details ul li:nth-child(6) a svg, .why-details ul li:nth-child(6) a img {
                width: 220px;
                height: auto;
                margin-right: 20px; } }
          .why-details ul li:nth-child(6) a div:nth-child(1) {
            flex-shrink: 0; }
            @media screen and (min-width: 520px) {
              .why-details ul li:nth-child(6) a div:nth-child(1) {
                flex-shrink: inherit; } }
        .why-details ul li:nth-child(6) h5 {
          padding: 0;
          color: #333; }
        .why-details ul li:nth-child(6) ul.inner-icon li {
          flex: 25px !important;
          max-width: 25px !important; }
          @media (min-width: 992px) {
            .why-details ul li:nth-child(6) ul.inner-icon li {
              flex: 35px !important;
              max-width: 35px !important; } }
          .why-details ul li:nth-child(6) ul.inner-icon li svg {
            width: 20px; }
            @media (min-width: 992px) {
              .why-details ul li:nth-child(6) ul.inner-icon li svg {
                width: 30px; } }
        .why-details ul li:nth-child(6) h4 {
          font-size: 22px;
          line-height: 26px;
          color: #333; }
          @media (min-width: 992px) {
            .why-details ul li:nth-child(6) h4 {
              font-size: 30px;
              line-height: 40px; } }
      .why-details ul li:nth-child(7) {
        border: 1px solid #49C684;
        order: 5; }
        @media (min-width: 992px) {
          .why-details ul li:nth-child(7) {
            order: 9; } }
        .why-details ul li:nth-child(7) a svg {
          height: 40px; }
          @media (min-width: 992px) {
            .why-details ul li:nth-child(7) a svg {
              height: 65px; } }
      .why-details ul li:nth-child(8) {
        background: #1D2942;
        order: 7; }
        @media (min-width: 992px) {
          .why-details ul li:nth-child(8) {
            order: 8; } }
        .why-details ul li:nth-child(8) a svg {
          height: 40px;
          position: relative;
          top: 2px; }
          @media (min-width: 992px) {
            .why-details ul li:nth-child(8) a svg {
              height: 65px;
              top: 0; } }
      .why-details ul li:nth-child(9) {
        order: 8;
        border: 1px solid #009C52;
        background: #009C52; }
        @media (min-width: 992px) {
          .why-details ul li:nth-child(9) {
            order: 6; } }
        .why-details ul li:nth-child(9) a svg {
          height: 40px; }
          @media (min-width: 992px) {
            .why-details ul li:nth-child(9) a svg {
              height: 65px; } }
        .why-details ul li:nth-child(9) h4 {
          font-size: 15px; }
          @media screen and (min-width: 992px) {
            .why-details ul li:nth-child(9) h4 {
              font-size: 18px; } }
          @media screen and (min-width: 1200px) {
            .why-details ul li:nth-child(9) h4 {
              font-size: 23px; } }
      .why-details ul li h4 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        font-family: "Montserrat", sans-serif;
        margin-bottom: 2px;
        color: #fff; }
        @media (min-width: 992px) {
          .why-details ul li h4 {
            font-size: 36px;
            line-height: 36px;
            padding: 5px 0 5px; } }
        .why-details ul li h4.black-text {
          color: #333; }
      .why-details ul li h5 {
        margin-bottom: 0;
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
        font-weight: 500;
        color: #fff; }
        @media (min-width: 992px) {
          .why-details ul li h5 {
            font-size: 18px;
            font-family: "Montserrat", sans-serif;
            font-weight: 600; } }
        .why-details ul li h5.black-text {
          color: #333; }
      .why-details ul li ul {
        justify-content: flex-start; }
        @media (min-width: 992px) {
          .why-details ul li ul {
            position: relative;
            top: -8px; } }
        .why-details ul li ul li {
          border: none !important;
          padding: 0 !important;
          background: none !important;
          height: auto !important;
          flex: 35px !important;
          max-width: 35px !important;
          margin: 3px !important; }
          .why-details ul li ul li a svg {
            margin: 0 !important; }
      .why-details ul li .inner-icon li svg {
        margin: 0 !important; }

.fact-section .why-details ul li:nth-child(1) {
  order: 1; }
  @media (min-width: 992px) {
    .fact-section .why-details ul li:nth-child(1) {
      order: inherit; } }

.fact-section .why-details ul li:nth-child(2) {
  order: 2; }
  @media (min-width: 992px) {
    .fact-section .why-details ul li:nth-child(2) {
      order: inherit; } }

.fact-section .why-details ul li:nth-child(3) {
  background: transparent;
  border: 1px solid #4a90e2;
  order: 4; }
  @media (min-width: 992px) {
    .fact-section .why-details ul li:nth-child(3) {
      order: inherit; } }

.fact-section .why-details ul li:nth-child(4) {
  border: 0;
  background: #009c52;
  order: 3; }
  @media (min-width: 992px) {
    .fact-section .why-details ul li:nth-child(4) {
      order: inherit; } }
  .fact-section .why-details ul li:nth-child(4) svg {
    position: relative;
    top: 5px; }
    @media (min-width: 992px) {
      .fact-section .why-details ul li:nth-child(4) svg {
        top: 9px; } }

.fact-section .why-details ul li:nth-child(5) {
  order: 6; }
  @media (min-width: 992px) {
    .fact-section .why-details ul li:nth-child(5) {
      order: inherit; } }
  .fact-section .why-details ul li:nth-child(5) svg {
    top: 3px;
    position: relative; }
    @media (min-width: 992px) {
      .fact-section .why-details ul li:nth-child(5) svg {
        top: 0; } }

@media (min-width: 992px) {
  .fact-section .why-details ul li:nth-child(6) {
    order: 9; } }

@media (min-width: 992px) {
  .fact-section .why-details ul li:nth-child(8) {
    order: 7; } }

@media (min-width: 992px) {
  .fact-section .why-details ul li:nth-child(7) {
    order: 6; } }

.fact-section .why-details ul li:nth-child(9) {
  background: #009C52; }
  @media (min-width: 992px) {
    .fact-section .why-details ul li:nth-child(9) {
      order: 8; } }

@media (min-width: 992px) {
  .top-dev .o-skills-hero {
    padding-bottom: 80px !important; } }

.o-case-studies-slider {
  position: relative;
  padding: 45px 0 25px; }
  @media (min-width: 992px) {
    .o-case-studies-slider {
      padding: 70px 0 55px; } }
  .o-case-studies-slider .case-studies-slider {
    width: 100%;
    padding: 0; }
    .o-case-studies-slider .case-studies-slider .slick-prev {
      background: url(/images/common/previous-arrow.png) no-repeat 0 0;
      width: 21px;
      height: 42px;
      text-indent: -10000px;
      position: absolute;
      top: 50%;
      z-index: 999;
      display: none; }
      .o-case-studies-slider .case-studies-slider .slick-prev:disabled {
        display: none; }
      .o-case-studies-slider .case-studies-slider .slick-prev svg {
        display: none; }
    .o-case-studies-slider .case-studies-slider .slick-next {
      background: url(/images/common/next-arrow.png) no-repeat 0 0;
      width: 21px;
      height: 42px;
      text-indent: -10000px;
      position: absolute;
      top: 50%;
      display: none; }
      .o-case-studies-slider .case-studies-slider .slick-next:disabled {
        display: none; }
      .o-case-studies-slider .case-studies-slider .slick-next svg {
        display: none; }
    .o-case-studies-slider .case-studies-slider.slick-initialized {
      visibility: visible; }
  .o-case-studies-slider .cs-slider {
    overflow: hidden; }
    @media (min-width: 992px) {
      .o-case-studies-slider .cs-slider {
        background-color: #fff; } }
    .o-case-studies-slider .cs-slider .cs-slider-text {
      padding: 20px 20px 20px;
      flex: 0 0 100%;
      background-color: #fff;
      box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.1); }
      @media (min-width: 992px) {
        .o-case-studies-slider .cs-slider .cs-slider-text {
          padding: 50px;
          flex: 0 0 50%;
          height: 498px;
          box-shadow: none; } }
      .o-case-studies-slider .cs-slider .cs-slider-text h4 {
        position: relative;
        font-size: 22px;
        line-height: 28px;
        color: #333;
        margin-bottom: 20px;
        padding-bottom: 10px;
        font-family: "Montserrat", sans-serif;
        display: none; }
        @media (min-width: 992px) {
          .o-case-studies-slider .cs-slider .cs-slider-text h4 {
            font-family: "Montserrat", sans-serif;
            color: #27c070; } }
        .o-case-studies-slider .cs-slider .cs-slider-text h4:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 50px;
          height: 2px;
          background-color: #27c070; }
          @media (min-width: 992px) {
            .o-case-studies-slider .cs-slider .cs-slider-text h4:before {
              background-color: #14DF7D;
              height: 4px; } }
      .o-case-studies-slider .cs-slider .cs-slider-text h5, .o-case-studies-slider .cs-slider .cs-slider-text h3 {
        font-size: 20px;
        font-weight: 600; }
        @media (min-width: 992px) {
          .o-case-studies-slider .cs-slider .cs-slider-text h5, .o-case-studies-slider .cs-slider .cs-slider-text h3 {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 20px; } }
      .o-case-studies-slider .cs-slider .cs-slider-text .cs-tech {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-family: "Montserrat", sans-serif;
        margin-bottom: 5px; }
        @media (min-width: 992px) {
          .o-case-studies-slider .cs-slider .cs-slider-text .cs-tech {
            font-family: "Montserrat", sans-serif;
            font-size: 19px;
            line-height: 30px;
            margin-bottom: 25px; } }
        .o-case-studies-slider .cs-slider .cs-slider-text .cs-tech span {
          color: #333;
          font-weight: 600; }
          @media (min-width: 992px) {
            .o-case-studies-slider .cs-slider .cs-slider-text .cs-tech span {
              color: #14DF7D; } }
      .o-case-studies-slider .cs-slider .cs-slider-text p {
        font-size: 16px;
        line-height: 23px;
        font-family: "Montserrat", sans-serif; }
        @media (min-width: 992px) {
          .o-case-studies-slider .cs-slider .cs-slider-text p {
            font-size: 19px;
            line-height: 30px;
            font-family: "Montserrat", sans-serif; } }
      .o-case-studies-slider .cs-slider .cs-slider-text a {
        text-transform: uppercase;
        color: #27c070;
        font-size: 15px;
        font-weight: bold;
        font-family: "Montserrat", sans-serif;
        background: url(/images/common/arrow-green.png) no-repeat 100% 50%;
        padding-right: 30px; }
        @media (min-width: 992px) {
          .o-case-studies-slider .cs-slider .cs-slider-text a {
            color: #27c070;
            display: inline-block; } }
    .o-case-studies-slider .cs-slider .cs-slider-img {
      background-repeat: no-repeat;
      background-size: cover; }
      @media (min-width: 992px) {
        .o-case-studies-slider .cs-slider .cs-slider-img {
          flex: 1; } }
  @media (min-width: 992px) {
    .o-case-studies-slider .splide .cs-slider {
      background: none; } }
  @media (min-width: 992px) {
    .o-case-studies-slider .splide .cs-slider .cs-slider-img picture {
      display: inline-block;
      height: 100%; } }
  @media (min-width: 992px) {
    .o-case-studies-slider .splide .cs-slider .cs-slider-img picture img {
      height: 100%;
      object-fit: cover; } }
  @media (min-width: 992px) {
    .o-case-studies-slider .splide .cs-slider .cs-slider-img img {
      height: 100%;
      object-fit: cover; } }
  .o-case-studies-slider .splide .splide__track:active {
    cursor: grabbing; }
  @media (min-width: 992px) {
    .o-case-studies-slider .splide .splide__track {
      background-color: #fff;
      box-shadow: 4px 4px 30px -2px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 992px) {
    .o-case-studies-slider .splide .splide__pagination {
      margin-top: 30px !important; } }
  .o-case-studies-slider .px-4 {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin: auto; }
    @media (min-width: 992px) {
      .o-case-studies-slider .px-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important; } }
  .o-case-studies-slider .btn {
    max-width: 195px;
    padding: 20px 0; }

.o-case-studies-slider a.btn.btn-orange.c-btn.non-cta {
  background: none;
  color: #333;
  font-weight: 500;
  border: 0;
  font-size: 16px;
  text-decoration: underline;
  width: auto;
  margin-top: 0;
  padding-top: 0;
  text-transform: initial; }
  @media (min-width: 992px) {
    .o-case-studies-slider a.btn.btn-orange.c-btn.non-cta {
      font-size: 18px;
      margin-top: 0; } }
  .o-case-studies-slider a.btn.btn-orange.c-btn.non-cta:after {
    display: none; }

@media (min-width: 992px) {
  .cs-slider {
    flex-direction: row-reverse;
    display: flex !important; } }

.cs-slider img {
  object-fit: cover; }

.grt-slider-box.slick-slide {
  height: auto; }

.case-studies-slider .owl-stage-outer {
  box-shadow: 4px 4px 30px -2px rgba(0, 0, 0, 0.2);
  background: #fff; }

.case-studies-slider .owl-dots {
  text-align: center;
  margin-bottom: 15px;
  margin-top: 30px; }
  .case-studies-slider .owl-dots .owl-dot {
    width: 7px;
    height: 7px;
    display: inline-block;
    vertical-align: middle;
    background: #9b9b9b;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer; }
    .case-studies-slider .owl-dots .owl-dot.active {
      background: #49c684;
      width: 10px;
      height: 10px; }

.hm-case-study .owl-stage-outer {
  height: auto; }
  @media screen and (min-width: 992px) {
    .hm-case-study .owl-stage-outer {
      height: 498px; } }

@media screen and (min-width: 992px) {
  .cs-height {
    height: 800px; } }

.cs-cont.invisible {
  display: none !important; }

.stack-list li {
  background-color: #fff;
  display: inline-block;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  margin: 0 5px 20px;
  position: relative;
  padding: 120px 10px 0;
  font-size: 20px;
  color: #333;
  font-weight: 500;
  vertical-align: top;
  z-index: 9;
  position: relative;
  width: 44%;
  font-size: 18px;
  padding: 100px 10px 0;
  align-items: flex-start;
  padding: 13px 15px;
  height: 79px;
  position: relative;
  box-shadow: 0 0 12px #a8a8a880;
  -webkit-box-shadow: 0 0 12px #a8a8a880;
  -moz-box-shadow: 0 0 12px #a8a8a880; }
  .stack-list li:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, 0);
    background-image: url(/images/home/home-arrow.png);
    width: 22px;
    height: 22px; }

.no-webp .o-industrial-experience__listed-items ul li:before {
  background-image: url(/images/common/common-sprite.png); }

.webp .o-industrial-experience__listed-items ul li:before {
  background-image: url(/images/webp/common/common-sprite.webp); }

.o-industrial-experience {
  text-align: center;
  position: relative;
  display: none; }
  @media (min-width: 992px) {
    .o-industrial-experience {
      display: block; } }
  .o-industrial-experience .subheading {
    font-size: 20px;
    line-height: 32px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500; }
    @media (min-width: 992px) {
      .o-industrial-experience .subheading {
        font-size: 30px;
        line-height: 36px;
        color: #333;
        margin-bottom: 20px;
        margin: 15px 0 0; } }
  .o-industrial-experience__listed-items ul li {
    display: inline-block;
    padding: 15px;
    vertical-align: top;
    position: relative;
    margin-bottom: 35px;
    width: 40%; }
    @media (min-width: 992px) {
      .o-industrial-experience__listed-items ul li {
        width: 24%; } }
    .o-industrial-experience__listed-items ul li:before {
      content: '';
      position: absolute;
      left: 0px;
      width: 50px;
      height: 50px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      display: none; }
    .o-industrial-experience__listed-items ul li img {
      margin-bottom: 20px; }
    .o-industrial-experience__listed-items ul li:nth-child(1):before {
      background-position: 0 0; }
    .o-industrial-experience__listed-items ul li:nth-child(2):before {
      background-position: -60px 0; }
    .o-industrial-experience__listed-items ul li:nth-child(3):before {
      background-position: -120px 0; }
    .o-industrial-experience__listed-items ul li:nth-child(4):before {
      background-position: -180px 0; }
    .o-industrial-experience__listed-items ul li:nth-child(5):before {
      background-position: -240px 0; }
    .o-industrial-experience__listed-items ul li:nth-child(6):before {
      background-position: -300px 0; }
    .o-industrial-experience__listed-items ul li:nth-child(7):before {
      background-position: 0px -60px; }
    .o-industrial-experience__listed-items ul li:nth-child(8):before {
      background-position: -60px -60px; }
    .o-industrial-experience__listed-items ul li:nth-child(9):before {
      background-position: -120px -60px; }
    .o-industrial-experience__listed-items ul li:nth-child(10):before {
      background-position: -180px -60px; }
    .o-industrial-experience__listed-items ul li:nth-child(11):before {
      background-position: -240px -60px; }
    .o-industrial-experience__listed-items ul li:nth-child(12):before {
      background-position: -300px -60px; }
    .o-industrial-experience__listed-items ul li p {
      font-size: 15px;
      color: #333;
      margin-bottom: 0;
      font-weight: 500;
      font-family: "Montserrat", sans-serif !important; }
      @media (min-width: 992px) {
        .o-industrial-experience__listed-items ul li p {
          font-size: 19px; } }
      .o-industrial-experience__listed-items ul li p a {
        color: #333; }

.hm-page .o-industrial-experience__listed-items ul li p {
  font-family: "Montserrat", sans-serif !important; }

.industry-banner .header_right_pan .skill-page-mod li {
  background: none;
  border: none;
  border-radius: 0;
  margin: 0; }
  .industry-banner .header_right_pan .skill-page-mod li img {
    width: auto; }

.real-google-reviews {
  position: relative;
  background: #fbfbfb;
  padding: 50px 0 45px; }
  @media (min-width: 992px) {
    .real-google-reviews {
      padding: 80px 0 50px; } }
  .real-google-reviews .review-top {
    padding-bottom: 5px; }
    .real-google-reviews .review-top h2 {
      font-size: 30px;
      margin-top: 15px; }
      @media (min-width: 992px) {
        .real-google-reviews .review-top h2 {
          font-size: 36px;
          margin-top: 0; } }
    .real-google-reviews .review-top h3 {
      font-size: 16px;
      line-height: 26px;
      font-weight: 500;
      max-width: 530px;
      margin: 0 auto 20px; }
      @media (min-width: 992px) {
        .real-google-reviews .review-top h3 {
          font-size: 20px;
          line-height: 32px; } }
      .real-google-reviews .review-top h3.review-new-sub-heading {
        max-width: 100%; }
    @media (min-width: 992px) {
      .real-google-reviews .review-top {
        padding-bottom: 0; } }
  .real-google-reviews h5.pad-top-50 {
    padding-top: 0; }
    @media (min-width: 992px) {
      .real-google-reviews h5.pad-top-50 {
        padding-top: 50px; } }
  .real-google-reviews ul.slick-dots {
    margin-top: 0; }
    @media (min-width: 992px) {
      .real-google-reviews ul.slick-dots {
        margin-top: 30px; } }

.new-review-box {
  background-color: #fff;
  border: 2px solid #c8c7cc;
  position: relative;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 30px 30px 30px 30px;
  text-align: center; }
  @media (min-width: 992px) {
    .new-review-box {
      text-align: left;
      padding: 50px 30px 30px 30px; } }
  .new-review-box h3 {
    margin-bottom: 0;
    font-size: 22px;
    line-height: 26px;
    margin-top: 20px;
    font-weight: 500; }
  .new-review-box h3 {
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
    max-width: 530px;
    margin: 0 auto 20px; }

.new-review-box {
  background-color: #fff;
  border: 2px solid #c8c7cc;
  position: relative;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 35px 15px 30px 15px;
  text-align: center; }
  @media (min-width: 992px) {
    .new-review-box {
      text-align: left;
      padding: 35px 30px 30px 30px; } }
  .new-review-box h3 {
    margin-bottom: 0;
    font-size: 22px;
    line-height: 26px;
    margin-top: 10px;
    font-weight: 500; }
    @media (min-width: 992px) {
      .new-review-box h3 {
        margin-top: 20px; } }
  .new-review-box .clint-details {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 10px; }
    @media (min-width: 992px) {
      .new-review-box .clint-details {
        font-size: 14px; } }
    .new-review-box .clint-details strong {
      color: #333; }
  .new-review-box h4 {
    color: #4A90E2;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .new-review-box h4 {
        font-family: "Montserrat", sans-serif;
        font-size: 23px;
        line-height: 32px; } }
  .new-review-box i {
    display: block;
    width: 70px;
    height: 70px;
    overflow: hidden;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -40px;
    border: 5px solid #fbfbfb; }
    @media (min-width: 992px) {
      .new-review-box i {
        left: 25px;
        right: inherit; } }
    .new-review-box i img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      left: 0;
      top: 0; }

.i-new-review-box {
  padding: 45px 30px 30px 30px; }
  @media (min-width: 992px) {
    .i-new-review-box {
      margin-top: 40px; } }

.review-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0; }
  @media (min-width: 992px) {
    .review-icon {
      width: 80%;
      margin: 20px auto 0; } }
  .review-icon li {
    flex: 1;
    display: inline-block;
    margin: 20px 10px 0 !important;
    font-size: 0;
    line-height: 0; }
    @media (min-width: 992px) {
      .review-icon li {
        margin: 0 20px 30px !important; } }
    .review-icon li a {
      display: block;
      height: auto; }
    .review-icon li:nth-child(1) a svg {
      overflow: inherit; }
    .review-icon li:nth-child(4) {
      display: none; }
      @media (min-width: 992px) {
        .review-icon li:nth-child(4) {
          display: block; } }
  .review-icon:nth-child(1) {
    background-position: 0 -200px;
    width: 139px; }
  .review-icon:nth-child(2) {
    background-position: -160px -200px;
    width: 139px; }

.no-webp .grt-slider-box:before {
  background-image: url(/images/common/common-sprite.png); }

.webp .grt-slider-box:before {
  background-image: url(/images/webp/common/common-sprite.webp); }

.grt-slider-box:before {
  position: absolute;
  left: 0;
  right: 0;
  top: -30px;
  content: "";
  width: 73px;
  height: 73px;
  background-color: #ccc;
  z-index: 1;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: 0 0; }
  @media (min-width: 992px) {
    .grt-slider-box:before {
      left: 50px;
      right: inherit;
      margin: 0; } }

.star li {
  display: inline-block;
  padding: 0 2px; }

.grt-slider .slick-list {
  padding-top: 10px; }
  @media (min-width: 992px) {
    .grt-slider .slick-list {
      padding-top: 0; } }

.grt-slider .splide__pagination {
  position: static;
  transform: translate(0); }
  .grt-slider .splide__pagination .splide__pagination__page.is-active {
    transform: scale(1.4);
    background: #27c070; }

.client-img {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -40px; }
  @media (min-width: 992px) {
    .client-img {
      left: 25px;
      right: inherit;
      margin: 0; } }

.grt-slider-box:before {
  position: absolute;
  left: 0;
  top: -30px;
  content: "";
  width: 73px;
  height: 73px;
  background-color: #fff;
  z-index: 1;
  background-position: 0 0;
  background-repeat: no-repeat;
  display: none; }
  @media (min-width: 992px) {
    .grt-slider-box:before {
      left: 50px; } }

.grt-slider-box:nth-child(1):before {
  background-position: 0 -680px; }

.grt-slider-box:nth-child(2):before {
  background-position: -107px -680px; }

.grt-slider-box:nth-child(3):before {
  background-position: -214px -680px; }

.grt-slider-box:nth-child(4):before {
  background-position: -107px -680px; }

.grt-slider-box:nth-child(5):before {
  background-position: -213px -680px; }

.grt-slider-box:after {
  position: absolute;
  right: 50px;
  bottom: 6px;
  content: "";
  width: 41px;
  height: 31px;
  z-index: 1;
  background: url(/images/common/quote.png) no-repeat 0 0;
  display: none; }
  @media (min-width: 992px) {
    .grt-slider-box:after {
      display: block; } }

.col-md-4.grt-slider-box {
  position: relative;
  padding: 0 15px;
  margin: 34px 0 0; }

.grt-slider .splide__list {
  width: auto; }
  .grt-slider .splide__list .grt-slider-box {
    display: flex; }

.grt-slider.is-active .splide__list .grt-slider-box {
  display: block; }

.grt-slider.is-active .splide__list .grt-slider-mobile-box {
  margin: 40px 0 0; }

.grt-slider .old-review-pagination {
  display: block !important;
  text-align: center;
  margin: 0 0 20px; }

.new-grt-slider .owl-dots {
  text-align: center;
  margin-bottom: 30px; }
  .new-grt-slider .owl-dots .owl-dot {
    width: 7px;
    height: 7px;
    display: inline-block;
    vertical-align: middle;
    background: #9b9b9b;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer; }
    .new-grt-slider .owl-dots .owl-dot.active {
      background: #49c684;
      width: 10px;
      height: 10px; }

.trust-pilot-widget-wrapper .trustpilot-widget-mod {
  top: 32px;
  left: 10px; }

.trust-pilot-widget-wrapper-industry .trustpilot-widget-mod {
  top: 17px;
  left: 10px; }

.bottom-trustpilot-widget-wrapper {
  /* Example of more specific styling */ }
  .bottom-trustpilot-widget-wrapper .span#reviews-summary {
    color: #fff; }
  .bottom-trustpilot-widget-wrapper .custom-trustpilot {
    background-color: #f0f0f0;
    /* Light gray background */
    color: #333;
    /* Dark text color */ }
  .bottom-trustpilot-widget-wrapper .custom-trustpilot a {
    color: #0073e6;
    /* Link color */ }

.no-webp .o-bottom-form .form-text ul li {
  background-image: url(/images/common/common-sprite.png); }

.webp .o-bottom-form .form-text ul li {
  background-image: url(/images/webp/common/common-sprite.webp); }

.o-bottom-form {
  background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  position: relative; }
  @media (min-width: 992px) {
    .o-bottom-form {
      overflow: hidden; } }
  .o-bottom-form .container {
    position: relative;
    z-index: 9; }
  .o-bottom-form .form-text {
    background-color: #1D2942;
    width: 100%;
    float: none;
    color: #fff;
    text-align: left;
    padding: 40px 30% 15px 35px;
    position: relative; }
    @media (min-width: 992px) {
      .o-bottom-form .form-text {
        width: 50%;
        float: left;
        padding: 70px 60px;
        margin-top: 0; } }
    .o-bottom-form .form-text:before {
      content: '';
      background: #1D2942;
      width: 25px;
      height: 25px;
      transform: rotate(-45deg);
      position: absolute;
      bottom: -12px;
      left: 35px; }
      @media (min-width: 992px) {
        .o-bottom-form .form-text:before {
          display: none; } }
    .o-bottom-form .form-text:after {
      content: '';
      position: absolute;
      top: 45px;
      right: 5px;
      background: url(/images/common/b-form-envelop.webp) no-repeat center center/90%;
      width: 159px;
      height: 159px; }
      @media (min-width: 992px) {
        .o-bottom-form .form-text:after {
          display: none; } }
    .o-bottom-form .form-text h2 {
      font-size: 24px;
      line-height: 36px;
      font-weight: 700;
      color: #fff; }
      @media (min-width: 992px) {
        .o-bottom-form .form-text h2 {
          font-size: 36px;
          line-height: 53px; } }
    .o-bottom-form .form-text .form-bot-text {
      display: none; }
      @media (min-width: 992px) {
        .o-bottom-form .form-text .form-bot-text {
          display: block; } }
    .o-bottom-form .form-text ul {
      display: none; }
      @media (min-width: 992px) {
        .o-bottom-form .form-text ul {
          display: block; } }
      .o-bottom-form .form-text ul li {
        display: inline-block;
        text-align: center;
        padding-top: 70px;
        width: 72px;
        background-repeat: no-repeat;
        background-position: 50% 0; }
        .o-bottom-form .form-text ul li.am-1 {
          background-position: 0 -570px; }
        .o-bottom-form .form-text ul li.am-2 {
          background-position: -73px -570px; }
        .o-bottom-form .form-text ul li.am-3 {
          background-position: -149px -570px; }
        .o-bottom-form .form-text ul li.am-4 {
          background-position: -228px -570px; }
        .o-bottom-form .form-text ul li.am-5 {
          background-image: url(/images/common/ray.png);
          background-size: 100%; }
    .o-bottom-form .form-text p {
      color: #fff;
      font-family: "Montserrat", sans-serif;
      font-size: 13px; }
    .o-bottom-form .form-text p.sub-heading {
      font-size: 16px;
      line-height: 23px;
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .o-bottom-form .form-text p.sub-heading {
          font-size: 19px;
          line-height: 32px;
          font-family: "Montserrat", sans-serif; } }
    .o-bottom-form .form-text p.subheading-1 {
      font-size: 24px;
      margin: 0 0 10px 0;
      font-family: "Montserrat", sans-serif; }
    .o-bottom-form .form-text .form-bot-text {
      border-top: 1px solid #364462;
      margin-top: 60px;
      padding-top: 60px; }
  .o-bottom-form .form-panel {
    width: 100%;
    float: none;
    padding: 20px 20px 40px;
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-bottom-form .form-panel {
        width: 50%;
        float: left;
        padding: 60px; } }

.common-input {
  padding: 20px 20px 20px 40px;
  color: #333;
  width: 100%;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 16px;
  border: 0;
  border-bottom: 1px solid #333;
  background-color: transparent; }

.u-name {
  background: url(/images/common/form-icon-1.png) 0 50% no-repeat; }

.u-email {
  background: url(/images/common/form-icon-2.png) 0 50% no-repeat; }

.u-phone {
  background: url(/images/common/form-icon-3.png) 0 50% no-repeat; }

.u-company {
  background: url(/images/common/form-icon-4.png) 0 50% no-repeat; }

.u-message {
  background: url(/images/common/form-icon-5.png) 0 20px no-repeat; }

.attach {
  background: url(/images/common/form-icon-6.png) 0 20px no-repeat;
  padding: 20px 20px 20px 40px; }

.webp .hm-page .mb-w-tr-pt:before {
  display: none; }
  @media (min-width: 992px) {
    .webp .hm-page .mb-w-tr-pt:before {
      display: block; } }

#bottom-form ::-webkit-input-placeholder {
  color: #3b3b3b;
  opacity: 1;
  font-weight: 400; }

.form-left {
  width: 100%;
  float: left;
  padding: 30px;
  margin-top: 0;
  background-color: #1d2942;
  color: #fff; }
  @media (min-width: 992px) {
    .form-left {
      width: 50%;
      padding: 70px 60px 46px; } }

.form-left ul {
  padding-bottom: 0;
  margin-bottom: 0; }
  @media screen and (min-width: 768px) {
    .form-left ul {
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid #515151; } }

.form-left ul li {
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px; }
  @media (min-width: 992px) {
    .form-left ul li {
      margin-bottom: 0; } }
  .form-left ul li .bottom-form-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden; }
    @media screen and (min-width: 768px) {
      .form-left ul li .bottom-form-img {
        width: 45px;
        height: 45px; } }
    @media screen and (min-width: 992px) {
      .form-left ul li .bottom-form-img {
        width: 80px;
        height: 80px; } }

.form-left ul li span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  padding-top: 10px; }
  @media screen and (min-width: 768px) {
    .form-left ul li span {
      font-size: 12px; } }
  @media screen and (min-width: 992px) {
    .form-left ul li span {
      font-size: 15px; } }

.form-left ul li img {
  filter: grayscale(100%);
  width: 100%; }

.form-left p {
  font-size: 16px; }
  @media screen and (min-width: 992px) {
    .form-left p {
      font-size: 14px; } }

.form-bottom-sec {
  display: inline-block;
  width: 58%;
  font-size: 12px;
  line-height: 18px;
  vertical-align: top;
  margin-bottom: 32px; }
  @media screen and (min-width: 992px) {
    .form-bottom-sec {
      font-size: 14px;
      line-height: 20px; } }
  @media screen and (min-width: 1200px) {
    .form-bottom-sec {
      font-size: 16px;
      line-height: 24px; } }

.form-bottom-sec:nth-child(1) {
  width: 40%; }

.form-bottom-sec:nth-child(3) {
  width: 40%; }

.form-bottom-head {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  display: block;
  vertical-align: top;
  margin-bottom: 5px;
  color: #fff; }
  @media screen and (min-width: 992px) {
    .form-bottom-head {
      font-size: 20px;
      line-height: 32px; } }
  @media screen and (min-width: 1200px) {
    .form-bottom-head {
      font-size: 26px;
      line-height: 32px; } }

.form-icon {
  margin-right: 15px;
  margin-bottom: 10px;
  width: 54px;
  display: inline-block;
  vertical-align: top; }
  @media screen and (min-width: 768px) {
    .form-icon {
      width: 40px;
      margin-right: 10px; } }
  @media screen and (min-width: 992px) {
    .form-icon {
      width: 35px;
      margin-bottom: 0;
      text-align: center;
      margin-right: 20px; } }
  @media screen and (min-width: 1200px) {
    .form-icon {
      width: 54px; } }

.form-txt-pan {
  display: inline-block;
  width: 100%;
  color: #B9C6D0;
  font-weight: 500; }
  @media screen and (min-width: 420px) {
    .form-txt-pan {
      width: 65%; } }
  @media (min-width: 992px) {
    .form-txt-pan {
      width: 70%; } }

.form-bottom-sec:nth-child(1) .form-txt-pan,
.form-bottom-sec:nth-child(3) .form-txt-pan {
  width: 60%;
  display: block; }
  @media screen and (min-width: 420px) {
    .form-bottom-sec:nth-child(1) .form-txt-pan,
    .form-bottom-sec:nth-child(3) .form-txt-pan {
      width: 40%;
      display: inherit; } }
  @media screen and (min-width: 1200px) {
    .form-bottom-sec:nth-child(1) .form-txt-pan,
    .form-bottom-sec:nth-child(3) .form-txt-pan {
      width: 55%; } }

.o-bottom-form h2 {
  padding: 40px 30px 20px; }
  @media (min-width: 992px) {
    .o-bottom-form h2 {
      padding: 0; } }

.form-sub-heading {
  font-size: 18px;
  line-height: 24px;
  padding: 0 30px;
  font-weight: 600; }
  @media (min-width: 992px) {
    .form-sub-heading {
      font-size: 30px;
      line-height: 40px; } }
  .form-sub-heading br {
    display: none; }
    @media (min-width: 992px) {
      .form-sub-heading br {
        display: block; } }

.confidential-text-wrap {
  margin-top: -10px; }
  .confidential-text-wrap ul {
    display: flex;
    justify-content: space-evenly; }
    .confidential-text-wrap ul li {
      color: #ffffff;
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
      width: 50%;
      position: relative;
      padding: 0px 7px;
      justify-content: flex-end; }
      .confidential-text-wrap ul li:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: #6eb9c8;
        right: 0px;
        top: 0px; }
      .confidential-text-wrap ul li:last-child {
        justify-content: flex-start; }
        .confidential-text-wrap ul li:last-child:after {
          display: none; }
      .confidential-text-wrap ul li img {
        width: 12px;
        height: 12px;
        margin-right: 6px; }
  .confidential-text-wrap__inverse {
    margin-top: 15px;
    text-align: center; }
    .confidential-text-wrap__inverse ul {
      display: inline-flex;
      margin-top: 5px; }
      .confidential-text-wrap__inverse ul li {
        color: #000000;
        font-size: 15px;
        width: auto;
        padding: 2px 10px; }
        @media screen and (min-width: 992px) {
          .confidential-text-wrap__inverse ul li {
            padding: 2px 10px;
            font-size: 14px; } }
        .confidential-text-wrap__inverse ul li:first-child {
          padding-left: 0; }
          @media screen and (min-width: 992px) {
            .confidential-text-wrap__inverse ul li:first-child {
              padding: 0px 10px; } }
        .confidential-text-wrap__inverse ul li:last-child {
          padding-right: 0; }
          @media screen and (min-width: 992px) {
            .confidential-text-wrap__inverse ul li:last-child {
              padding: 0px 10px; } }
        .confidential-text-wrap__inverse ul li:after {
          background-color: #D8D8D8; }

.o-contact-form__attachement-text {
  font-size: 16px; }
  @media screen and (min-width: 992px) {
    .o-contact-form__attachement-text {
      font-size: 13px; } }

.o-awards {
  color: #333;
  position: relative;
  padding: 70px 0 50px;
  display: none;
  background-color: #fff;
  position: relative;
  text-align: center; }
  @media (min-width: 992px) {
    .o-awards {
      display: block;
      padding: 70px 0 15px; } }
  .o-awards .awards-list li {
    display: inline-block;
    padding: 20px 20px;
    vertical-align: middle; }
    .o-awards .awards-list li a {
      display: block; }
  .o-awards .awards-list--pos {
    position: relative;
    left: -5px; }

.no-webp .o-footer .social-footer-divider--social-pan li a {
  background-image: url("/images/common/common-sprite.png"); }

.webp .o-footer .social-footer-divider--social-pan li a {
  background-image: url("/images/webp/common/common-sprite.webp"); }

.no-webp .o-footer .social-footer-divider--social-pan li.social-behance a {
  background-image: url("/images/common/behance-sprite.jpg");
  background-repeat: no-repeat; }

.webp .o-footer .social-footer-divider--social-pan li.social-behance a {
  background-image: url("/images/webp/common/behance-sprite.webp");
  background-repeat: no-repeat; }

.o-footer {
  background-color: #fff;
  padding: 40px 0;
  text-align: center; }
  @media (min-width: 992px) {
    .o-footer {
      padding: 0 0 40px; } }
  .o-footer .social-footer-divider {
    position: relative;
    border-top: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    margin-bottom: 50px; }
    @media (min-width: 992px) {
      .o-footer .social-footer-divider {
        margin-bottom: 40px;
        border-bottom: 1px solid #ccc;
        border-top: 0; } }
    .o-footer .social-footer-divider--social-pan {
      padding: 30px 0 30px; }
      @media (min-width: 992px) {
        .o-footer .social-footer-divider--social-pan {
          padding: 40px 0; } }
      .o-footer .social-footer-divider--social-pan li {
        display: inline-block;
        margin: 0 5px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background-size: 100%; }
        .o-footer .social-footer-divider--social-pan li a {
          line-height: 44px;
          width: 44px;
          height: 44px;
          display: block;
          cursor: pointer;
          border: 1px solid #E7E7E7;
          border-radius: 50%;
          text-indent: -10000px; }
          .o-footer .social-footer-divider--social-pan li a .display-vissable {
            display: inline-block; }
          .o-footer .social-footer-divider--social-pan li a .display-hidden {
            display: none; }
          .o-footer .social-footer-divider--social-pan li a:hover .display-vissable {
            display: none; }
          .o-footer .social-footer-divider--social-pan li a:hover .display-hidden {
            display: inline-block; }
        .o-footer .social-footer-divider--social-pan li.social-1 a {
          background-position: -4px -342px; }
          .o-footer .social-footer-divider--social-pan li.social-1 a:hover {
            background-position: -59px -342px; }
        .o-footer .social-footer-divider--social-pan li.social-2 a {
          background-position: -112px -342px; }
          .o-footer .social-footer-divider--social-pan li.social-2 a:hover {
            background-position: -168px -342px; }
        .o-footer .social-footer-divider--social-pan li.new-twitter-icon a, .o-footer .social-footer-divider--social-pan li.social-2 a {
          background-image: url(/images/common/new-twitter-icon-group.png);
          background-position: 10px 11px;
          background-repeat: no-repeat; }
          .o-footer .social-footer-divider--social-pan li.new-twitter-icon a:hover, .o-footer .social-footer-divider--social-pan li.social-2 a:hover {
            background-position: -32px 11px; }
        .o-footer .social-footer-divider--social-pan li.social-3 a {
          background-position: -222px -342px; }
          .o-footer .social-footer-divider--social-pan li.social-3 a:hover {
            background-position: -277px -342px; }
        .o-footer .social-footer-divider--social-pan li.social-4 a {
          background-position: -5px -377px; }
          .o-footer .social-footer-divider--social-pan li.social-4 a:hover {
            background-position: -56px -377px; }
        .o-footer .social-footer-divider--social-pan li.social-5 a {
          background-position: -112px -376px; }
          .o-footer .social-footer-divider--social-pan li.social-5 a:hover {
            background-position: -166px -376px; }
        .o-footer .social-footer-divider--social-pan li.social-6 a {
          background-position: -222px -376px; }
          .o-footer .social-footer-divider--social-pan li.social-6 a:hover {
            background-position: -274px -376px; }
        .o-footer .social-footer-divider--social-pan li.social-behance a {
          background-position: 8px 12px; }
          .o-footer .social-footer-divider--social-pan li.social-behance a:hover {
            background-position: -55px 12px; }
  .o-footer--copyright {
    padding: 40px 20px 5px; }
    @media (min-width: 992px) {
      .o-footer--copyright {
        text-align: left;
        padding: 0 20px; } }
    .o-footer--copyright ul {
      overflow: hidden; }
      .o-footer--copyright ul li {
        display: inline-block;
        border-left: 1px solid #DFDFDF;
        padding: 0 10px;
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500; }
        @media (min-width: 992px) {
          .o-footer--copyright ul li {
            font-size: 12px; } }
        .o-footer--copyright ul li:first-child {
          border-left: 0;
          padding: 0 10px 0 0; }
        .o-footer--copyright ul li a {
          color: #333; }
          .o-footer--copyright ul li a:hover {
            text-decoration: underline; }
    .o-footer--copyright p {
      margin-top: 20px;
      text-align: center;
      font-size: 15px;
      font-family: "Montserrat",sans-serif;
      font-weight: 500; }
      @media (min-width: 992px) {
        .o-footer--copyright p {
          margin: 0;
          font-size: 12px;
          line-height: 20px;
          text-align: left; } }
      .o-footer--copyright p span {
        display: block; }
        @media (min-width: 992px) {
          .o-footer--copyright p span {
            display: inline-block; } }
  .o-footer .dmca-badge {
    margin-top: 2px; }

.new-footer {
  background: #091B38;
  padding: 35px 0 40px;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .new-footer {
      padding: 50px 0 50px; } }
  .new-footer__small--mobile .bottom-col-wrap__left-border {
    border-top: 1px solid #194b83;
    border-left: none;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 40px; }
    @media screen and (min-width: 768px) {
      .new-footer__small--mobile .bottom-col-wrap__left-border {
        border-top: none;
        border-left: 1px solid #194b83;
        padding-left: 20px;
        padding-right: 0;
        margin-top: 0; } }
  @media screen and (min-width: 768px) {
    .new-footer {
      text-align: left;
      padding: 40px 0 50px; } }
  .new-footer h6 {
    font-size: 16px;
    line-height: 23px;
    color: #E4ECF5;
    font-weight: 500;
    display: block;
    margin-bottom: 0; }
    @media screen and (min-width: 768px) {
      .new-footer h6 {
        margin-bottom: 0.5rem; } }
  .new-footer h5 {
    font-size: 16px;
    line-height: 23px;
    color: #E4ECF5;
    font-weight: 500;
    display: block;
    margin-bottom: 0; }
    @media screen and (min-width: 768px) {
      .new-footer h5 {
        margin-bottom: 0.5rem; } }
  .new-footer-ul {
    vertical-align: text-top;
    margin-bottom: 0;
    padding: 15px 15px 10px; }
    @media screen and (min-width: 768px) {
      .new-footer-ul {
        margin-top: 25px;
        padding: 0;
        margin-bottom: 10px; } }
    .new-footer-ul li {
      padding: 5px 0; }
      @media screen and (min-width: 768px) {
        .new-footer-ul li {
          padding: 0; } }
      .new-footer-ul li a {
        color: #AEC7DA;
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
        line-height: 25px; }
        @media screen and (min-width: 768px) {
          .new-footer-ul li a {
            font-size: 13px; } }
        .new-footer-ul li a:hover {
          color: #2dbe70; }
    @media screen and (min-width: 768px) {
      .new-footer-ul__link {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        color: #fff; } }
    .new-footer-ul__link li a {
      color: #fff; }
    @media (min-width: 992px) {
      .new-footer-ul-mr {
        margin-right: 50px; } }
    .new-footer-ul:first-child {
      margin-bottom: 0; }
      @media screen and (min-width: 768px) {
        .new-footer-ul:first-child {
          margin-bottom: 25px; } }
  @media screen and (min-width: 768px) {
    .new-footer__small {
      padding: 25px 0; } }

.col-wrap p {
  color: #AEC7DA;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 27px;
  margin-top: 25px; }
  @media screen and (min-width: 992px) {
    .col-wrap p {
      font-size: 13px;
      line-height: 25px;
      margin-top: 35px; } }

.bottom-col-wrap__left-border {
  border-left: 1px solid #194B83;
  padding-left: 20px; }

.bottom-col-wrap ul {
  margin-bottom: 10px;
  margin-top: 13px; }
  .bottom-col-wrap ul li {
    display: inline-block; }
    .bottom-col-wrap ul li a {
      position: relative;
      padding: 0 10px; }
      .bottom-col-wrap ul li a:before {
        content: '';
        position: absolute;
        background: #aec7da;
        height: 12px;
        width: 1px;
        top: 3px;
        right: -2px; }
    .bottom-col-wrap ul li:first-child a {
      padding-left: 0; }
    .bottom-col-wrap ul li:last-child a:before {
      display: none; }

.bottom-col-wrap p {
  color: #AEC7DA;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  line-height: 26px;
  margin-bottom: 0; }
  @media screen and (min-width: 992px) {
    .bottom-col-wrap p {
      font-size: 13px;
      line-height: 25px; } }

.new-social-links {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
  margin-top: 35px;
  justify-content: space-evenly; }
  @media screen and (min-width: 768px) {
    .new-social-links {
      margin-bottom: 25px; } }
  @media (min-width: 992px) {
    .new-social-links {
      justify-content: space-between;
      margin-bottom: 0;
      max-width: 86%; } }
  .new-social-links li:first-child {
    padding-left: 0; }
  .new-social-links li:last-child {
    padding-right: 0; }
  .new-social-links li a:hover svg path, .new-social-links li a:hover svg circle {
    stroke: #2dbe70; }
  .new-social-links li a svg {
    transition: .3s all ease-in-out; }
  .new-social-links li a:hover img {
    filter: brightness(0) saturate(100%) invert(61%) sepia(10%) saturate(2494%) hue-rotate(95deg) brightness(97%) contrast(96%);
    transition: .5 all ease-out; }
  .new-social-links li a img {
    transition: .5 all ease-in; }

.dmca {
  text-align: center;
  padding-top: 40px; }
  @media (min-width: 992px) {
    .dmca {
      text-align: right; } }

.footer-btm-line {
  margin: 20px 0 40px;
  padding: 0;
  background: #979797;
  height: 1px;
  opacity: .3; }
  @media (min-width: 992px) {
    .footer-btm-line {
      margin: 60px 0 40px; } }

@media screen and (min-width: 992px) {
  .new-footer-ul-mod {
    margin-top: 15px; } }

.ol-text {
  margin-top: 25px; }

.footer-address-box {
  background: #122A52;
  padding: 40px 40px 40px;
  border-radius: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%; }
  @media screen and (min-width: 992px) {
    .footer-address-box {
      margin-bottom: 75px; } }
  .footer-address-box-top {
    align-items: center;
    margin-bottom: 10px;
    min-height: 35px; }
    @media screen and (min-width: 768px) {
      .footer-address-box-top {
        display: flex; } }
    .footer-address-box-top img {
      width: 23px;
      height: 23px;
      margin: 0 auto 15px;
      display: block; }
      @media screen and (min-width: 768px) {
        .footer-address-box-top img {
          display: inherit;
          margin-right: 10px;
          margin-bottom: 0; } }
    .footer-address-box-top h4 {
      flex: 1;
      font-size: 16px;
      text-transform: uppercase;
      color: #fff;
      margin-bottom: 0; }
      .footer-address-box-top h4 span {
        display: block;
        font-size: 13px;
        color: #AEC7DA;
        text-transform: capitalize; }
  .footer-address-box p {
    font-size: 13px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 20px; }
    .footer-address-box p a {
      color: #AEC7DA; }
  .footer-address-box h5 {
    font-size: 16px;
    margin-bottom: 0; }
    .footer-address-box h5 a {
      color: #36E079; }

.ft-menu {
  background: #223a63;
  padding: 10px 15px; }
  @media screen and (min-width: 768px) {
    .ft-menu {
      background: none;
      padding: 0;
      margin-bottom: 0; } }
  .ft-menu:before {
    display: block;
    content: '+';
    width: 18px;
    height: 15px;
    position: absolute;
    top: 10px;
    right: 8px;
    font-size: 26px; }
    @media screen and (min-width: 768px) {
      .ft-menu:before {
        display: none; } }

.ft-menu.ft-menu-mod:before {
  display: block;
  content: '-';
  width: 18px;
  height: 15px;
  position: absolute;
  top: 9px;
  right: 5px; }
  @media screen and (min-width: 768px) {
    .ft-menu.ft-menu-mod:before {
      display: none; } }

.cd {
  display: none; }
  @media screen and (min-width: 768px) {
    .cd {
      display: block; } }

.ft-menu-bg {
  background-color: #122a52;
  text-align: left;
  padding: 0; }
  @media screen and (min-width: 768px) {
    .ft-menu-bg {
      background: none;
      padding: 0;
      text-align: inherit;
      margin-bottom: 0;
      border-radius: 0; } }
  .ft-menu-bg ul {
    position: relative; }
    .ft-menu-bg ul:before {
      content: '';
      position: absolute;
      background: #223a63;
      height: 15px;
      width: 15px;
      top: -7px;
      left: 18px;
      transform: rotate(45deg); }
      @media screen and (min-width: 768px) {
        .ft-menu-bg ul:before {
          display: none; } }

.ft-menu-bg-one {
  margin-bottom: 1px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .ft-menu-bg-one {
      margin-bottom: 0; } }

.ft-menu-bg-two {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow: hidden; }

/*--------------New Footer Location------------------*/
.new-location-heading {
  color: #fff;
  font-size: 16px;
  margin-bottom: 0; }

.new-location-area-row {
  margin-bottom: 35px; }
  @media screen and (min-width: 992px) {
    .new-location-area-row {
      margin-bottom: 58px; } }
  .new-location-area-row .padding-25 {
    padding: 25px 30px; }
  @media screen and (min-width: 992px) {
    .new-location-area-row .footer-location-table {
      display: flex; } }
  .new-location-area-row .footer-location-table .new-location-border-divider {
    border-bottom: 1px solid #244375;
    border-right: 0; }
    @media screen and (min-width: 992px) {
      .new-location-area-row .footer-location-table .new-location-border-divider {
        border-right: 1px solid #244375;
        border-bottom: 0; } }
  .new-location-area-row .footer-location-table .footer-location {
    text-align: center;
    border: 1px solid #244375;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 20px; }
    @media screen and (min-width: 992px) {
      .new-location-area-row .footer-location-table .footer-location {
        margin-top: 20px; } }
  .new-location-area-row .footer-location-table .location-top-heading {
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0;
    padding: 20px;
    border-bottom: 1px solid #244375;
    font-size: 18px; }
    @media screen and (min-width: 992px) {
      .new-location-area-row .footer-location-table .location-top-heading {
        font-size: 16px; } }
  .new-location-area-row .footer-location-table .footer-location-body p {
    color: #AEC7DA;
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .new-location-area-row .footer-location-table .footer-location-body p {
        font-size: 13px;
        line-height: 22px; } }
    .new-location-area-row .footer-location-table .footer-location-body p.margin-bottom-15 {
      margin-bottom: 15px; }
  .new-location-area-row .footer-location-table .footer-location-body .location-heading-area {
    color: #fff;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600; }
    @media screen and (min-width: 992px) {
      .new-location-area-row .footer-location-table .footer-location-body .location-heading-area {
        font-size: 16px; } }
  .new-location-area-row .footer-location-table .footer-location-body .location-icon {
    margin-bottom: 20px;
    min-height: 64px; }
  .new-location-area-row .footer-location-table .new-location-tel {
    color: #00B54A;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid transparent; }
    .new-location-area-row .footer-location-table .new-location-tel:hover {
      border-bottom: 1px solid #00B54A; }

.footer-divider-role {
  color: #4166A1;
  margin: 10px 0 30px; }
  @media screen and (min-width: 992px) {
    .footer-divider-role {
      margin: 25px 0 30px; } }

/*----------------Contact Page New Location----------------------*/
.contact-new-location {
  background-color: #F1F1F1;
  border-top: #979797;
  position: relative;
  z-index: 0; }
  .contact-new-location .contact-new-location-box-spacing-right {
    padding-right: 40px; }
  .contact-new-location .contact-location-spacing-new {
    padding: 40px 15px;
    position: relative;
    z-index: 99; }
    @media screen and (min-width: 992px) {
      .contact-new-location .contact-location-spacing-new {
        padding: 90px 15px; } }
  .contact-new-location .contact-new-location-top-heading {
    font-size: 28px;
    line-height: 28px;
    color: #333333;
    font-weight: 600; }
  .contact-new-location .contact-new-location-country {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .contact-new-location .contact-new-location-country {
        margin-top: 50px; } }
    .contact-new-location .contact-new-location-country .contact-country-flag-icon {
      margin-right: 12px; }
    .contact-new-location .contact-new-location-country .contact-new-location-heading {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 700;
      line-height: 18px; }
  .contact-new-location .contact-new-location-box.contact-new-location-box-spacing {
    margin-left: 48px; }
  .contact-new-location .contact-new-location-box.contact-new-location-box-spacing-mobile {
    margin-left: 48px; }
    @media screen and (min-width: 992px) {
      .contact-new-location .contact-new-location-box.contact-new-location-box-spacing-mobile {
        margin-left: 0; } }
    .contact-new-location .contact-new-location-box.contact-new-location-box-spacing-mobile.margin-top-30 {
      margin-top: 30px; }
      @media screen and (min-width: 992px) {
        .contact-new-location .contact-new-location-box.contact-new-location-box-spacing-mobile.margin-top-30 {
          margin-top: 0; } }
  .contact-new-location .contact-new-location-box .contact-new-location-area {
    font-size: 16px;
    font-weight: 600;
    color: #292929;
    line-height: 16px;
    margin-bottom: 12px;
    margin-top: 15px; }
  .contact-new-location .contact-new-location-box p {
    font-size: 13px;
    line-height: 22px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 0; }
    .contact-new-location .contact-new-location-box p.margin-bottom-20 {
      margin-bottom: 15px; }
      @media screen and (min-width: 992px) {
        .contact-new-location .contact-new-location-box p.margin-bottom-20 {
          margin-bottom: 20px; } }
  .contact-new-location .contact-new-location-box .contact-new-location-tel {
    font-size: 16px;
    font-weight: 700;
    color: #00C04C;
    line-height: 16px;
    border-bottom: 1px solid transparent; }
    .contact-new-location .contact-new-location-box .contact-new-location-tel:hover {
      border-bottom: 1px solid #00B54A; }
  @media screen and (min-width: 1921px) {
    .contact-new-location .contact-main-office-img-container {
      padding-right: 0; } }
  .contact-new-location .new-location-main-office-building-img {
    position: absolute;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    top: 0;
    bottom: 0;
    object-fit: cover;
    object-position: left center;
    left: 0;
    z-index: 0;
    background: #ededed;
    opacity: 0.2; }
    @media screen and (min-width: 992px) {
      .contact-new-location .new-location-main-office-building-img {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50vw;
        object-fit: cover;
        object-position: center bottom;
        max-height: 100%;
        height: 100%;
        left: auto;
        opacity: 1; } }
    @media screen and (min-width: 1921px) {
      .contact-new-location .new-location-main-office-building-img {
        position: relative; } }

.new-contact-location .new-location-heading,
.new-contact-location .new-location-area-row {
  display: none; }

.footer-location {
  width: 100%; }

@media screen and (min-width: 992px) {
  .col-md-4-text .col-wrap {
    padding-right: 25px; } }

@media screen and (min-width: 1280px) {
  .col-md-4-text .col-wrap {
    padding-right: 35px; } }

.quickform-holder {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 99999999;
  padding: 0;
  width: 95%;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden; }
  @media (min-width: 992px) {
    .quickform-holder {
      width: 754px;
      bottom: 0;
      left: inherit;
      margin: 0;
      top: inherit;
      height: auto;
      overflow: initial;
      right: 3%;
      z-index: 999999; } }
  .quickform-holder .big-girl {
    position: absolute;
    left: -100%;
    top: 60px;
    z-index: 1;
    width: 245px;
    display: none; }
    @media (min-width: 992px) {
      .quickform-holder .big-girl {
        left: 7%;
        top: 20px; } }
  .quickform-holder .small-girl {
    float: right; }
  .quickform-holder .qi-btn-holder {
    text-align: right;
    height: 68px; }
    @media (min-width: 992px) {
      .quickform-holder .qi-btn-holder {
        height: 102px; } }
    .quickform-holder .qi-btn-holder .qi-btn {
      display: none;
      vertical-align: top;
      border-radius: 4px;
      background: #3be06e;
      padding: 0;
      position: relative;
      width: 60px;
      margin-bottom: 10px;
      text-align: left;
      text-transform: capitalize;
      box-shadow: 0px 0px 5px 0px rgba(51, 51, 51, 0.5);
      border-radius: 50%;
      height: 60px;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1383c8+0,00cdd2+100 */
      background: #1383c8;
      /* Old browsers */
      background: -moz-linear-gradient(-45deg, #1383c8 0%, #00cdd2 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(-45deg, #1383c8 0%, #00cdd2 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, #1383c8 0%, #00cdd2 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1383c8', endColorstr='#00cdd2',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */
      bottom: 0; }
      @media (min-width: 992px) {
        .quickform-holder .qi-btn-holder .qi-btn {
          width: 45%;
          height: auto;
          border-radius: 4px;
          padding: 10px;
          display: inline-block; } }
      .quickform-holder .qi-btn-holder .qi-btn i {
        width: 30px;
        height: 25px;
        background: url(/images/common/icon-plus.png) no-repeat 50% 50%;
        border-radius: 0;
        position: absolute;
        right: 20px;
        top: 15px;
        display: none; }
        @media (min-width: 992px) {
          .quickform-holder .qi-btn-holder .qi-btn i {
            display: inline-block; } }
      .quickform-holder .qi-btn-holder .qi-btn.plus {
        display: inline-block; }
        .quickform-holder .qi-btn-holder .qi-btn.plus i {
          background: url(/images/common/slide-form-minus-white.svg) no-repeat; }
          @media screen and (min-width: 992px) {
            .quickform-holder .qi-btn-holder .qi-btn.plus i {
              background: url(/images/common/slide-form-minus-black.svg) no-repeat; } }
      .quickform-holder .qi-btn-holder .qi-btn span {
        padding-top: 7px;
        padding-left: 10px;
        vertical-align: top;
        font-weight: 500;
        font-size: 20px;
        width: 230px;
        line-height: 26px;
        display: none;
        font-family: "Montserrat", sans-serif; }
        @media (min-width: 992px) {
          .quickform-holder .qi-btn-holder .qi-btn span {
            display: inline-block; } }
      .quickform-holder .qi-btn-holder .qi-btn.plus {
        position: relative;
        top: 26px;
        z-index: 100;
        background: transparent;
        box-shadow: none; }
        .quickform-holder .qi-btn-holder .qi-btn.plus i {
          display: block;
          position: relative;
          right: -25px;
          top: 43px;
          opacity: .8; }
          @media (min-width: 992px) {
            .quickform-holder .qi-btn-holder .qi-btn.plus i {
              right: 15px;
              top: 100px;
              position: absolute; } }
        .quickform-holder .qi-btn-holder .qi-btn.plus img {
          display: none; }
        .quickform-holder .qi-btn-holder .qi-btn.plus span {
          display: none; }
        .quickform-holder .qi-btn-holder .qi-btn.plus:after {
          display: none; }
      @media (min-width: 992px) {
        .quickform-holder .qi-btn-holder .qi-btn:after {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #2fc9d2;
          position: absolute;
          content: "";
          right: 20px;
          bottom: -9px;
          display: none; } }
  .quickform-holder .quickform {
    overflow: auto;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 0px rgba(51, 51, 51, 0.5);
    position: relative;
    bottom: 0;
    display: block; }
    @media (min-width: 992px) {
      .quickform-holder .quickform {
        display: flex; } }
    .quickform-holder .quickform .s-f-left {
      background: #183059; }
      @media (min-width: 992px) {
        .quickform-holder .quickform .s-f-left {
          float: left;
          width: 50%; } }
      .quickform-holder .quickform .s-f-left .calender {
        padding: 40px 20px 20px 20px; }
        @media (min-width: 992px) {
          .quickform-holder .quickform .s-f-left .calender {
            background: url(/images/common/s-f-left-date.png) no-repeat 0 0;
            padding: 163px 20px 39px 20px; } }
        .quickform-holder .quickform .s-f-left .calender .girl {
          position: absolute;
          left: 70px;
          top: 0;
          height: 388px; }
        .quickform-holder .quickform .s-f-left .calender .text {
          z-index: 1;
          position: relative; }
        .quickform-holder .quickform .s-f-left .calender .text1 {
          z-index: 1;
          position: relative;
          margin: 0 auto;
          display: block;
          padding: 25px 0 7px; }
    .quickform-holder .quickform .s-f-right {
      float: none;
      width: 100%;
      background-color: #fff;
      position: relative;
      padding-bottom: 15px; }
      @media (min-width: 992px) {
        .quickform-holder .quickform .s-f-right {
          float: right;
          width: 50%; } }
      .quickform-holder .quickform .s-f-right h6 {
        color: #325153;
        font-size: 32px;
        line-height: 40px;
        font-weight: 500;
        background: url(/images/common/s-f-right-arrow.png) no-repeat 200px 31px #fff;
        padding: 20px 0 20px 20px;
        background-size: 31px; }
      .quickform-holder .quickform .s-f-right h2 {
        color: #325153;
        font-size: 32px;
        line-height: 40px;
        font-weight: 500;
        background: url(/images/common/s-f-right-arrow.png) no-repeat 200px 31px #fff;
        padding: 20px 0 5px 30px;
        background-size: 22px; }
      .quickform-holder .quickform .s-f-right .s-f-right-bottom {
        background-color: #DBF1F3;
        overflow: hidden;
        padding: 20px 20px 6px 20px;
        display: none; }
        @media (min-width: 992px) {
          .quickform-holder .quickform .s-f-right .s-f-right-bottom {
            display: block; } }
        .quickform-holder .quickform .s-f-right .s-f-right-bottom ul li {
          float: left;
          width: 41%;
          min-height: 70px;
          margin-bottom: 7px;
          font-size: 13px;
          line-height: 14px;
          margin-left: 20px; }
          .quickform-holder .quickform .s-f-right .s-f-right-bottom ul li img {
            display: block;
            margin-bottom: 5px; }

.tranparent-bg {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10000000;
  left: 0;
  top: 0px;
  display: none; }
  @media screen and (min-width: 992px) {
    .tranparent-bg {
      z-index: 10000; } }

.consultation {
  background: rgba(7, 22, 35, 0.9);
  padding: 16px 20px;
  text-align: center;
  position: relative;
  z-index: 99;
  max-width: 339px;
  margin: 0 auto;
  z-index: 99; }
  @media screen and (min-width: 992px) {
    .consultation {
      margin: 165px auto 0; } }
  .consultation h5 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .consultation h5 {
        font-size: 26px;
        line-height: 35px; } }
    .consultation h5 span {
      color: #27EB99; }

.sliding-form-icon {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0px 0 30px; }
  @media screen and (min-width: 992px) {
    .sliding-form-icon {
      padding: 25px 0px 0 30px; } }
  .sliding-form-icon li {
    display: flex;
    align-items: center;
    flex: 0 0 50%;
    margin-bottom: 15px;
    padding-right: 15px; }
    @media screen and (min-width: 992px) {
      .sliding-form-icon li {
        margin-bottom: 25px; } }
    .sliding-form-icon li i {
      flex: 0 0 31px;
      margin-right: 15px; }
    .sliding-form-icon li h6 {
      font-size: 13px;
      line-height: 17px;
      color: #fff;
      margin-bottom: 0; }

.form-modal-z-index {
  z-index: 9999999; }

.o-skills-hero {
  position: relative;
  overflow: hidden;
  padding: 100px 0 60px; }
  @media (min-width: 992px) {
    .o-skills-hero {
      padding: 115px 20px 45px !important; } }
  .o-skills-hero h1 {
    color: #fff;
    font-size: 32px; }
    @media (min-width: 992px) {
      .o-skills-hero h1 {
        font-size: 48px; } }
  .o-skills-hero h2 {
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    padding: 0px 0 20px;
    margin-top: 20px !important; }
    @media (min-width: 992px) {
      .o-skills-hero h2 {
        padding: 10px 0 20px;
        font-size: 28px;
        line-height: 38px;
        margin-top: 0 !important; } }
    .o-skills-hero h2 span {
      display: block; }
      @media (min-width: 992px) {
        .o-skills-hero h2 span {
          display: inline; } }
  .o-skills-hero__schdule h1 {
    font-size: 30px;
    text-transform: inherit !important; }
    @media (min-width: 992px) {
      .o-skills-hero__schdule h1 {
        font-size: 47px;
        line-height: 60px; } }
  .o-skills-hero__schdule h2 {
    font-size: 20px;
    font-weight: 500 !important; }
    @media (min-width: 992px) {
      .o-skills-hero__schdule h2 {
        font-size: 25px;
        line-height: 35px; } }
  .o-skills-hero__schdule .header_right_pan h2 {
    font-size: 18px; }
    @media (min-width: 992px) {
      .o-skills-hero__schdule .header_right_pan h2 {
        font-size: 24px !important;
        line-height: 35px; } }
  .o-skills-hero__schdule .header_right_pan h3 {
    font-size: 18px; }
    @media (min-width: 992px) {
      .o-skills-hero__schdule .header_right_pan h3 {
        font-size: 21px !important;
        line-height: 35px;
        padding-bottom: 0;
        margin-top: 20px; } }
  .o-skills-hero__schdule .header_right_pan .or {
    background-color: #091B38;
    width: 30px;
    height: 30px;
    font-size: 11px;
    line-height: 30px;
    color: #fff;
    border-radius: 50%;
    margin: 10px auto 0; }
  .o-skills-hero p {
    color: #93fff1;
    font-family: "Montserrat", sans-serif; }

.header_left_pan {
  text-align: center; }
  @media (min-width: 992px) {
    .header_left_pan {
      text-align: left; } }
  .header_left_pan .award-logo {
    margin: 0 auto;
    padding: 0 0 20px 0; }
  .header_left_pan p {
    display: none; }
    @media (min-width: 992px) {
      .header_left_pan p {
        font-size: 20px;
        line-height: 33px;
        display: block;
        font-weight: 600; } }
  .header_left_pan ul {
    display: none; }
    @media (min-width: 992px) {
      .header_left_pan ul {
        display: block; } }
    .header_left_pan ul li {
      font-size: 17px;
      padding-left: 30px;
      padding-bottom: 10px;
      position: relative;
      color: #fff;
      font-weight: 500; }
      .header_left_pan ul li:before {
        content: '';
        background: url(/images/common/tick.png) no-repeat 0 0;
        width: 18px;
        height: 18px;
        position: absolute;
        left: 0;
        top: 0;
        background-size: 100%; }
        @media (min-width: 992px) {
          .header_left_pan ul li:before {
            top: 4px; } }

.new-award div {
  display: inline-block;
  margin: 0 20px 30px; }

.schedule-box {
  padding: 40px 14px 15px;
  position: relative; }
  @media (min-width: 992px) {
    .schedule-box {
      padding: 40px 30px 15px 30px; } }
  .schedule-box p {
    color: #fff; }
  .schedule-box h3 {
    font-size: 20px;
    padding-bottom: 10px; }
  .schedule-box :before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    background: url(/images/common/skills-lets-talk.png) no-repeat 0 0; }

.sub-text {
  font-size: 19px;
  border-bottom: 2px dotted #14DF7D;
  padding: 20px 0; }

.WordPress .o-skills-hero,
.Swift .o-skills-hero,
.PHP .o-skills-hero,
.Laravel .o-skills-hero,
.CodeIgniter .o-skills-hero,
.Symfony .o-skills-hero,
.Python .o-skills-hero,
.Django .o-skills-hero,
.NodeJS .o-skills-hero,
.dotnet .o-skills-hero,
.Angular .o-skills-hero,
.ReactJs .o-skills-hero,
.VueJS .o-skills-hero,
.MEAN .o-skills-hero,
.MERN .o-skills-hero,
.Magento .o-skills-hero,
.WooCommerce .o-skills-hero,
.Shopify .o-skills-hero,
.Drupal .o-skills-hero,
.iOS .o-skills-hero,
.Android .o-skills-hero,
.React-Native .o-skills-hero,
.React .o-skills-hero,
.hybrid .o-skills-hero,
.Flutter .o-skills-hero,
.Ionic .o-skills-hero,
.Flask .o-skills-hero,
.front-end-development .o-skills-hero,
.Backend-Development .o-skills-hero {
  padding-bottom: 20px;
  padding-top: 100px; }
  @media (min-width: 992px) {
    .WordPress .o-skills-hero,
    .Swift .o-skills-hero,
    .PHP .o-skills-hero,
    .Laravel .o-skills-hero,
    .CodeIgniter .o-skills-hero,
    .Symfony .o-skills-hero,
    .Python .o-skills-hero,
    .Django .o-skills-hero,
    .NodeJS .o-skills-hero,
    .dotnet .o-skills-hero,
    .Angular .o-skills-hero,
    .ReactJs .o-skills-hero,
    .VueJS .o-skills-hero,
    .MEAN .o-skills-hero,
    .MERN .o-skills-hero,
    .Magento .o-skills-hero,
    .WooCommerce .o-skills-hero,
    .Shopify .o-skills-hero,
    .Drupal .o-skills-hero,
    .iOS .o-skills-hero,
    .Android .o-skills-hero,
    .React-Native .o-skills-hero,
    .React .o-skills-hero,
    .hybrid .o-skills-hero,
    .Flutter .o-skills-hero,
    .Ionic .o-skills-hero,
    .Flask .o-skills-hero,
    .front-end-development .o-skills-hero,
    .Backend-Development .o-skills-hero {
      padding: 65px 20px 0 !important; } }
  @media (min-width: 992px) {
    .WordPress .o-skills-hero h1,
    .Swift .o-skills-hero h1,
    .PHP .o-skills-hero h1,
    .Laravel .o-skills-hero h1,
    .CodeIgniter .o-skills-hero h1,
    .Symfony .o-skills-hero h1,
    .Python .o-skills-hero h1,
    .Django .o-skills-hero h1,
    .NodeJS .o-skills-hero h1,
    .dotnet .o-skills-hero h1,
    .Angular .o-skills-hero h1,
    .ReactJs .o-skills-hero h1,
    .VueJS .o-skills-hero h1,
    .MEAN .o-skills-hero h1,
    .MERN .o-skills-hero h1,
    .Magento .o-skills-hero h1,
    .WooCommerce .o-skills-hero h1,
    .Shopify .o-skills-hero h1,
    .Drupal .o-skills-hero h1,
    .iOS .o-skills-hero h1,
    .Android .o-skills-hero h1,
    .React-Native .o-skills-hero h1,
    .React .o-skills-hero h1,
    .hybrid .o-skills-hero h1,
    .Flutter .o-skills-hero h1,
    .Ionic .o-skills-hero h1,
    .Flask .o-skills-hero h1,
    .front-end-development .o-skills-hero h1,
    .Backend-Development .o-skills-hero h1 {
      margin-bottom: 15px !important; } }
  .WordPress .o-skills-hero h2,
  .Swift .o-skills-hero h2,
  .PHP .o-skills-hero h2,
  .Laravel .o-skills-hero h2,
  .CodeIgniter .o-skills-hero h2,
  .Symfony .o-skills-hero h2,
  .Python .o-skills-hero h2,
  .Django .o-skills-hero h2,
  .NodeJS .o-skills-hero h2,
  .dotnet .o-skills-hero h2,
  .Angular .o-skills-hero h2,
  .ReactJs .o-skills-hero h2,
  .VueJS .o-skills-hero h2,
  .MEAN .o-skills-hero h2,
  .MERN .o-skills-hero h2,
  .Magento .o-skills-hero h2,
  .WooCommerce .o-skills-hero h2,
  .Shopify .o-skills-hero h2,
  .Drupal .o-skills-hero h2,
  .iOS .o-skills-hero h2,
  .Android .o-skills-hero h2,
  .React-Native .o-skills-hero h2,
  .React .o-skills-hero h2,
  .hybrid .o-skills-hero h2,
  .Flutter .o-skills-hero h2,
  .Ionic .o-skills-hero h2,
  .Flask .o-skills-hero h2,
  .front-end-development .o-skills-hero h2,
  .Backend-Development .o-skills-hero h2 {
    font-size: 24px;
    line-height: 30px;
    margin-top: 15px;
    font-weight: 600; }
    @media screen and (min-width: 992px) {
      .WordPress .o-skills-hero h2,
      .Swift .o-skills-hero h2,
      .PHP .o-skills-hero h2,
      .Laravel .o-skills-hero h2,
      .CodeIgniter .o-skills-hero h2,
      .Symfony .o-skills-hero h2,
      .Python .o-skills-hero h2,
      .Django .o-skills-hero h2,
      .NodeJS .o-skills-hero h2,
      .dotnet .o-skills-hero h2,
      .Angular .o-skills-hero h2,
      .ReactJs .o-skills-hero h2,
      .VueJS .o-skills-hero h2,
      .MEAN .o-skills-hero h2,
      .MERN .o-skills-hero h2,
      .Magento .o-skills-hero h2,
      .WooCommerce .o-skills-hero h2,
      .Shopify .o-skills-hero h2,
      .Drupal .o-skills-hero h2,
      .iOS .o-skills-hero h2,
      .Android .o-skills-hero h2,
      .React-Native .o-skills-hero h2,
      .React .o-skills-hero h2,
      .hybrid .o-skills-hero h2,
      .Flutter .o-skills-hero h2,
      .Ionic .o-skills-hero h2,
      .Flask .o-skills-hero h2,
      .front-end-development .o-skills-hero h2,
      .Backend-Development .o-skills-hero h2 {
        margin-top: 0;
        font-size: 20px;
        line-height: 32px; } }
    @media screen and (min-width: 1200px) {
      .WordPress .o-skills-hero h2,
      .Swift .o-skills-hero h2,
      .PHP .o-skills-hero h2,
      .Laravel .o-skills-hero h2,
      .CodeIgniter .o-skills-hero h2,
      .Symfony .o-skills-hero h2,
      .Python .o-skills-hero h2,
      .Django .o-skills-hero h2,
      .NodeJS .o-skills-hero h2,
      .dotnet .o-skills-hero h2,
      .Angular .o-skills-hero h2,
      .ReactJs .o-skills-hero h2,
      .VueJS .o-skills-hero h2,
      .MEAN .o-skills-hero h2,
      .MERN .o-skills-hero h2,
      .Magento .o-skills-hero h2,
      .WooCommerce .o-skills-hero h2,
      .Shopify .o-skills-hero h2,
      .Drupal .o-skills-hero h2,
      .iOS .o-skills-hero h2,
      .Android .o-skills-hero h2,
      .React-Native .o-skills-hero h2,
      .React .o-skills-hero h2,
      .hybrid .o-skills-hero h2,
      .Flutter .o-skills-hero h2,
      .Ionic .o-skills-hero h2,
      .Flask .o-skills-hero h2,
      .front-end-development .o-skills-hero h2,
      .Backend-Development .o-skills-hero h2 {
        font-size: 28px;
        line-height: 38px; } }
  @media (min-width: 992px) {
    .WordPress .o-skills-hero .header_left_pan,
    .Swift .o-skills-hero .header_left_pan,
    .PHP .o-skills-hero .header_left_pan,
    .Laravel .o-skills-hero .header_left_pan,
    .CodeIgniter .o-skills-hero .header_left_pan,
    .Symfony .o-skills-hero .header_left_pan,
    .Python .o-skills-hero .header_left_pan,
    .Django .o-skills-hero .header_left_pan,
    .NodeJS .o-skills-hero .header_left_pan,
    .dotnet .o-skills-hero .header_left_pan,
    .Angular .o-skills-hero .header_left_pan,
    .ReactJs .o-skills-hero .header_left_pan,
    .VueJS .o-skills-hero .header_left_pan,
    .MEAN .o-skills-hero .header_left_pan,
    .MERN .o-skills-hero .header_left_pan,
    .Magento .o-skills-hero .header_left_pan,
    .WooCommerce .o-skills-hero .header_left_pan,
    .Shopify .o-skills-hero .header_left_pan,
    .Drupal .o-skills-hero .header_left_pan,
    .iOS .o-skills-hero .header_left_pan,
    .Android .o-skills-hero .header_left_pan,
    .React-Native .o-skills-hero .header_left_pan,
    .React .o-skills-hero .header_left_pan,
    .hybrid .o-skills-hero .header_left_pan,
    .Flutter .o-skills-hero .header_left_pan,
    .Ionic .o-skills-hero .header_left_pan,
    .Flask .o-skills-hero .header_left_pan,
    .front-end-development .o-skills-hero .header_left_pan,
    .Backend-Development .o-skills-hero .header_left_pan {
      padding-right: 20px; } }
  .WordPress .o-skills-hero .header_left_pan img,
  .Swift .o-skills-hero .header_left_pan img,
  .PHP .o-skills-hero .header_left_pan img,
  .Laravel .o-skills-hero .header_left_pan img,
  .CodeIgniter .o-skills-hero .header_left_pan img,
  .Symfony .o-skills-hero .header_left_pan img,
  .Python .o-skills-hero .header_left_pan img,
  .Django .o-skills-hero .header_left_pan img,
  .NodeJS .o-skills-hero .header_left_pan img,
  .dotnet .o-skills-hero .header_left_pan img,
  .Angular .o-skills-hero .header_left_pan img,
  .ReactJs .o-skills-hero .header_left_pan img,
  .VueJS .o-skills-hero .header_left_pan img,
  .MEAN .o-skills-hero .header_left_pan img,
  .MERN .o-skills-hero .header_left_pan img,
  .Magento .o-skills-hero .header_left_pan img,
  .WooCommerce .o-skills-hero .header_left_pan img,
  .Shopify .o-skills-hero .header_left_pan img,
  .Drupal .o-skills-hero .header_left_pan img,
  .iOS .o-skills-hero .header_left_pan img,
  .Android .o-skills-hero .header_left_pan img,
  .React-Native .o-skills-hero .header_left_pan img,
  .React .o-skills-hero .header_left_pan img,
  .hybrid .o-skills-hero .header_left_pan img,
  .Flutter .o-skills-hero .header_left_pan img,
  .Ionic .o-skills-hero .header_left_pan img,
  .Flask .o-skills-hero .header_left_pan img,
  .front-end-development .o-skills-hero .header_left_pan img,
  .Backend-Development .o-skills-hero .header_left_pan img {
    padding: 0 0 20px;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .WordPress .o-skills-hero .header_left_pan img,
      .Swift .o-skills-hero .header_left_pan img,
      .PHP .o-skills-hero .header_left_pan img,
      .Laravel .o-skills-hero .header_left_pan img,
      .CodeIgniter .o-skills-hero .header_left_pan img,
      .Symfony .o-skills-hero .header_left_pan img,
      .Python .o-skills-hero .header_left_pan img,
      .Django .o-skills-hero .header_left_pan img,
      .NodeJS .o-skills-hero .header_left_pan img,
      .dotnet .o-skills-hero .header_left_pan img,
      .Angular .o-skills-hero .header_left_pan img,
      .ReactJs .o-skills-hero .header_left_pan img,
      .VueJS .o-skills-hero .header_left_pan img,
      .MEAN .o-skills-hero .header_left_pan img,
      .MERN .o-skills-hero .header_left_pan img,
      .Magento .o-skills-hero .header_left_pan img,
      .WooCommerce .o-skills-hero .header_left_pan img,
      .Shopify .o-skills-hero .header_left_pan img,
      .Drupal .o-skills-hero .header_left_pan img,
      .iOS .o-skills-hero .header_left_pan img,
      .Android .o-skills-hero .header_left_pan img,
      .React-Native .o-skills-hero .header_left_pan img,
      .React .o-skills-hero .header_left_pan img,
      .hybrid .o-skills-hero .header_left_pan img,
      .Flutter .o-skills-hero .header_left_pan img,
      .Ionic .o-skills-hero .header_left_pan img,
      .Flask .o-skills-hero .header_left_pan img,
      .front-end-development .o-skills-hero .header_left_pan img,
      .Backend-Development .o-skills-hero .header_left_pan img {
        padding: 40px 0 20px;
        margin-bottom: 0; } }
  @media (min-width: 992px) {
    .WordPress .o-skills-hero .header_left_pan h2,
    .Swift .o-skills-hero .header_left_pan h2,
    .PHP .o-skills-hero .header_left_pan h2,
    .Laravel .o-skills-hero .header_left_pan h2,
    .CodeIgniter .o-skills-hero .header_left_pan h2,
    .Symfony .o-skills-hero .header_left_pan h2,
    .Python .o-skills-hero .header_left_pan h2,
    .Django .o-skills-hero .header_left_pan h2,
    .NodeJS .o-skills-hero .header_left_pan h2,
    .dotnet .o-skills-hero .header_left_pan h2,
    .Angular .o-skills-hero .header_left_pan h2,
    .ReactJs .o-skills-hero .header_left_pan h2,
    .VueJS .o-skills-hero .header_left_pan h2,
    .MEAN .o-skills-hero .header_left_pan h2,
    .MERN .o-skills-hero .header_left_pan h2,
    .Magento .o-skills-hero .header_left_pan h2,
    .WooCommerce .o-skills-hero .header_left_pan h2,
    .Shopify .o-skills-hero .header_left_pan h2,
    .Drupal .o-skills-hero .header_left_pan h2,
    .iOS .o-skills-hero .header_left_pan h2,
    .Android .o-skills-hero .header_left_pan h2,
    .React-Native .o-skills-hero .header_left_pan h2,
    .React .o-skills-hero .header_left_pan h2,
    .hybrid .o-skills-hero .header_left_pan h2,
    .Flutter .o-skills-hero .header_left_pan h2,
    .Ionic .o-skills-hero .header_left_pan h2,
    .Flask .o-skills-hero .header_left_pan h2,
    .front-end-development .o-skills-hero .header_left_pan h2,
    .Backend-Development .o-skills-hero .header_left_pan h2 {
      font-size: 24px;
      line-height: 35px;
      font-weight: 600 !important; } }
  .WordPress .o-skills-hero .header_left_pan .skill-page-mod img,
  .Swift .o-skills-hero .header_left_pan .skill-page-mod img,
  .PHP .o-skills-hero .header_left_pan .skill-page-mod img,
  .Laravel .o-skills-hero .header_left_pan .skill-page-mod img,
  .CodeIgniter .o-skills-hero .header_left_pan .skill-page-mod img,
  .Symfony .o-skills-hero .header_left_pan .skill-page-mod img,
  .Python .o-skills-hero .header_left_pan .skill-page-mod img,
  .Django .o-skills-hero .header_left_pan .skill-page-mod img,
  .NodeJS .o-skills-hero .header_left_pan .skill-page-mod img,
  .dotnet .o-skills-hero .header_left_pan .skill-page-mod img,
  .Angular .o-skills-hero .header_left_pan .skill-page-mod img,
  .ReactJs .o-skills-hero .header_left_pan .skill-page-mod img,
  .VueJS .o-skills-hero .header_left_pan .skill-page-mod img,
  .MEAN .o-skills-hero .header_left_pan .skill-page-mod img,
  .MERN .o-skills-hero .header_left_pan .skill-page-mod img,
  .Magento .o-skills-hero .header_left_pan .skill-page-mod img,
  .WooCommerce .o-skills-hero .header_left_pan .skill-page-mod img,
  .Shopify .o-skills-hero .header_left_pan .skill-page-mod img,
  .Drupal .o-skills-hero .header_left_pan .skill-page-mod img,
  .iOS .o-skills-hero .header_left_pan .skill-page-mod img,
  .Android .o-skills-hero .header_left_pan .skill-page-mod img,
  .React-Native .o-skills-hero .header_left_pan .skill-page-mod img,
  .React .o-skills-hero .header_left_pan .skill-page-mod img,
  .hybrid .o-skills-hero .header_left_pan .skill-page-mod img,
  .Flutter .o-skills-hero .header_left_pan .skill-page-mod img,
  .Ionic .o-skills-hero .header_left_pan .skill-page-mod img,
  .Flask .o-skills-hero .header_left_pan .skill-page-mod img,
  .front-end-development .o-skills-hero .header_left_pan .skill-page-mod img,
  .Backend-Development .o-skills-hero .header_left_pan .skill-page-mod img {
    padding: 0;
    margin: 0; }
    @media screen and (min-width: 992px) {
      .WordPress .o-skills-hero .header_left_pan .skill-page-mod img,
      .Swift .o-skills-hero .header_left_pan .skill-page-mod img,
      .PHP .o-skills-hero .header_left_pan .skill-page-mod img,
      .Laravel .o-skills-hero .header_left_pan .skill-page-mod img,
      .CodeIgniter .o-skills-hero .header_left_pan .skill-page-mod img,
      .Symfony .o-skills-hero .header_left_pan .skill-page-mod img,
      .Python .o-skills-hero .header_left_pan .skill-page-mod img,
      .Django .o-skills-hero .header_left_pan .skill-page-mod img,
      .NodeJS .o-skills-hero .header_left_pan .skill-page-mod img,
      .dotnet .o-skills-hero .header_left_pan .skill-page-mod img,
      .Angular .o-skills-hero .header_left_pan .skill-page-mod img,
      .ReactJs .o-skills-hero .header_left_pan .skill-page-mod img,
      .VueJS .o-skills-hero .header_left_pan .skill-page-mod img,
      .MEAN .o-skills-hero .header_left_pan .skill-page-mod img,
      .MERN .o-skills-hero .header_left_pan .skill-page-mod img,
      .Magento .o-skills-hero .header_left_pan .skill-page-mod img,
      .WooCommerce .o-skills-hero .header_left_pan .skill-page-mod img,
      .Shopify .o-skills-hero .header_left_pan .skill-page-mod img,
      .Drupal .o-skills-hero .header_left_pan .skill-page-mod img,
      .iOS .o-skills-hero .header_left_pan .skill-page-mod img,
      .Android .o-skills-hero .header_left_pan .skill-page-mod img,
      .React-Native .o-skills-hero .header_left_pan .skill-page-mod img,
      .React .o-skills-hero .header_left_pan .skill-page-mod img,
      .hybrid .o-skills-hero .header_left_pan .skill-page-mod img,
      .Flutter .o-skills-hero .header_left_pan .skill-page-mod img,
      .Ionic .o-skills-hero .header_left_pan .skill-page-mod img,
      .Flask .o-skills-hero .header_left_pan .skill-page-mod img,
      .front-end-development .o-skills-hero .header_left_pan .skill-page-mod img,
      .Backend-Development .o-skills-hero .header_left_pan .skill-page-mod img {
        padding: 20px 0 20px;
        margin-bottom: 0; } }
  .WordPress .o-skills-hero .green-head-ribbon,
  .Swift .o-skills-hero .green-head-ribbon,
  .PHP .o-skills-hero .green-head-ribbon,
  .Laravel .o-skills-hero .green-head-ribbon,
  .CodeIgniter .o-skills-hero .green-head-ribbon,
  .Symfony .o-skills-hero .green-head-ribbon,
  .Python .o-skills-hero .green-head-ribbon,
  .Django .o-skills-hero .green-head-ribbon,
  .NodeJS .o-skills-hero .green-head-ribbon,
  .dotnet .o-skills-hero .green-head-ribbon,
  .Angular .o-skills-hero .green-head-ribbon,
  .ReactJs .o-skills-hero .green-head-ribbon,
  .VueJS .o-skills-hero .green-head-ribbon,
  .MEAN .o-skills-hero .green-head-ribbon,
  .MERN .o-skills-hero .green-head-ribbon,
  .Magento .o-skills-hero .green-head-ribbon,
  .WooCommerce .o-skills-hero .green-head-ribbon,
  .Shopify .o-skills-hero .green-head-ribbon,
  .Drupal .o-skills-hero .green-head-ribbon,
  .iOS .o-skills-hero .green-head-ribbon,
  .Android .o-skills-hero .green-head-ribbon,
  .React-Native .o-skills-hero .green-head-ribbon,
  .React .o-skills-hero .green-head-ribbon,
  .hybrid .o-skills-hero .green-head-ribbon,
  .Flutter .o-skills-hero .green-head-ribbon,
  .Ionic .o-skills-hero .green-head-ribbon,
  .Flask .o-skills-hero .green-head-ribbon,
  .front-end-development .o-skills-hero .green-head-ribbon,
  .Backend-Development .o-skills-hero .green-head-ribbon {
    margin-top: 40px;
    margin-bottom: 10px; }

.WordPress .skill-service,
.Swift .skill-service,
.PHP .skill-service,
.Laravel .skill-service,
.CodeIgniter .skill-service,
.Symfony .skill-service,
.Python .skill-service,
.Django .skill-service,
.NodeJS .skill-service,
.dotnet .skill-service,
.Angular .skill-service,
.ReactJs .skill-service,
.VueJS .skill-service,
.MEAN .skill-service,
.MERN .skill-service,
.Magento .skill-service,
.WooCommerce .skill-service,
.Shopify .skill-service,
.Drupal .skill-service,
.iOS .skill-service,
.Android .skill-service,
.React-Native .skill-service,
.React .skill-service,
.hybrid .skill-service,
.Flutter .skill-service,
.Ionic .skill-service,
.Flask .skill-service,
.front-end-development .skill-service,
.Backend-Development .skill-service {
  border-bottom: 1px solid #dedede; }
  @media (min-width: 992px) {
    .WordPress .skill-service,
    .Swift .skill-service,
    .PHP .skill-service,
    .Laravel .skill-service,
    .CodeIgniter .skill-service,
    .Symfony .skill-service,
    .Python .skill-service,
    .Django .skill-service,
    .NodeJS .skill-service,
    .dotnet .skill-service,
    .Angular .skill-service,
    .ReactJs .skill-service,
    .VueJS .skill-service,
    .MEAN .skill-service,
    .MERN .skill-service,
    .Magento .skill-service,
    .WooCommerce .skill-service,
    .Shopify .skill-service,
    .Drupal .skill-service,
    .iOS .skill-service,
    .Android .skill-service,
    .React-Native .skill-service,
    .React .skill-service,
    .hybrid .skill-service,
    .Flutter .skill-service,
    .Ionic .skill-service,
    .Flask .skill-service,
    .front-end-development .skill-service,
    .Backend-Development .skill-service {
      border-top: none; } }
  .WordPress .skill-service h2,
  .Swift .skill-service h2,
  .PHP .skill-service h2,
  .Laravel .skill-service h2,
  .CodeIgniter .skill-service h2,
  .Symfony .skill-service h2,
  .Python .skill-service h2,
  .Django .skill-service h2,
  .NodeJS .skill-service h2,
  .dotnet .skill-service h2,
  .Angular .skill-service h2,
  .ReactJs .skill-service h2,
  .VueJS .skill-service h2,
  .MEAN .skill-service h2,
  .MERN .skill-service h2,
  .Magento .skill-service h2,
  .WooCommerce .skill-service h2,
  .Shopify .skill-service h2,
  .Drupal .skill-service h2,
  .iOS .skill-service h2,
  .Android .skill-service h2,
  .React-Native .skill-service h2,
  .React .skill-service h2,
  .hybrid .skill-service h2,
  .Flutter .skill-service h2,
  .Ionic .skill-service h2,
  .Flask .skill-service h2,
  .front-end-development .skill-service h2,
  .Backend-Development .skill-service h2 {
    color: #333; }
  .WordPress .skill-service h3,
  .Swift .skill-service h3,
  .PHP .skill-service h3,
  .Laravel .skill-service h3,
  .CodeIgniter .skill-service h3,
  .Symfony .skill-service h3,
  .Python .skill-service h3,
  .Django .skill-service h3,
  .NodeJS .skill-service h3,
  .dotnet .skill-service h3,
  .Angular .skill-service h3,
  .ReactJs .skill-service h3,
  .VueJS .skill-service h3,
  .MEAN .skill-service h3,
  .MERN .skill-service h3,
  .Magento .skill-service h3,
  .WooCommerce .skill-service h3,
  .Shopify .skill-service h3,
  .Drupal .skill-service h3,
  .iOS .skill-service h3,
  .Android .skill-service h3,
  .React-Native .skill-service h3,
  .React .skill-service h3,
  .hybrid .skill-service h3,
  .Flutter .skill-service h3,
  .Ionic .skill-service h3,
  .Flask .skill-service h3,
  .front-end-development .skill-service h3,
  .Backend-Development .skill-service h3 {
    color: #333; }

.WordPress .services-pan i,
.Swift .services-pan i,
.PHP .services-pan i,
.Laravel .services-pan i,
.CodeIgniter .services-pan i,
.Symfony .services-pan i,
.Python .services-pan i,
.Django .services-pan i,
.NodeJS .services-pan i,
.dotnet .services-pan i,
.Angular .services-pan i,
.ReactJs .services-pan i,
.VueJS .services-pan i,
.MEAN .services-pan i,
.MERN .services-pan i,
.Magento .services-pan i,
.WooCommerce .services-pan i,
.Shopify .services-pan i,
.Drupal .services-pan i,
.iOS .services-pan i,
.Android .services-pan i,
.React-Native .services-pan i,
.React .services-pan i,
.hybrid .services-pan i,
.Flutter .services-pan i,
.Ionic .services-pan i,
.Flask .services-pan i,
.front-end-development .services-pan i,
.Backend-Development .services-pan i {
  display: block;
  height: 60px; }
  .WordPress .services-pan i img,
  .Swift .services-pan i img,
  .PHP .services-pan i img,
  .Laravel .services-pan i img,
  .CodeIgniter .services-pan i img,
  .Symfony .services-pan i img,
  .Python .services-pan i img,
  .Django .services-pan i img,
  .NodeJS .services-pan i img,
  .dotnet .services-pan i img,
  .Angular .services-pan i img,
  .ReactJs .services-pan i img,
  .VueJS .services-pan i img,
  .MEAN .services-pan i img,
  .MERN .services-pan i img,
  .Magento .services-pan i img,
  .WooCommerce .services-pan i img,
  .Shopify .services-pan i img,
  .Drupal .services-pan i img,
  .iOS .services-pan i img,
  .Android .services-pan i img,
  .React-Native .services-pan i img,
  .React .services-pan i img,
  .hybrid .services-pan i img,
  .Flutter .services-pan i img,
  .Ionic .services-pan i img,
  .Flask .services-pan i img,
  .front-end-development .services-pan i img,
  .Backend-Development .services-pan i img {
    height: 100%; }

.WordPress .services-pan h3,
.Swift .services-pan h3,
.PHP .services-pan h3,
.Laravel .services-pan h3,
.CodeIgniter .services-pan h3,
.Symfony .services-pan h3,
.Python .services-pan h3,
.Django .services-pan h3,
.NodeJS .services-pan h3,
.dotnet .services-pan h3,
.Angular .services-pan h3,
.ReactJs .services-pan h3,
.VueJS .services-pan h3,
.MEAN .services-pan h3,
.MERN .services-pan h3,
.Magento .services-pan h3,
.WooCommerce .services-pan h3,
.Shopify .services-pan h3,
.Drupal .services-pan h3,
.iOS .services-pan h3,
.Android .services-pan h3,
.React-Native .services-pan h3,
.React .services-pan h3,
.hybrid .services-pan h3,
.Flutter .services-pan h3,
.Ionic .services-pan h3,
.Flask .services-pan h3,
.front-end-development .services-pan h3,
.Backend-Development .services-pan h3 {
  font-size: 20px; }
  @media (min-width: 992px) {
    .WordPress .services-pan h3,
    .Swift .services-pan h3,
    .PHP .services-pan h3,
    .Laravel .services-pan h3,
    .CodeIgniter .services-pan h3,
    .Symfony .services-pan h3,
    .Python .services-pan h3,
    .Django .services-pan h3,
    .NodeJS .services-pan h3,
    .dotnet .services-pan h3,
    .Angular .services-pan h3,
    .ReactJs .services-pan h3,
    .VueJS .services-pan h3,
    .MEAN .services-pan h3,
    .MERN .services-pan h3,
    .Magento .services-pan h3,
    .WooCommerce .services-pan h3,
    .Shopify .services-pan h3,
    .Drupal .services-pan h3,
    .iOS .services-pan h3,
    .Android .services-pan h3,
    .React-Native .services-pan h3,
    .React .services-pan h3,
    .hybrid .services-pan h3,
    .Flutter .services-pan h3,
    .Ionic .services-pan h3,
    .Flask .services-pan h3,
    .front-end-development .services-pan h3,
    .Backend-Development .services-pan h3 {
      font-size: 24px; } }

.WordPress .skill-why ul.slick-dots,
.Swift .skill-why ul.slick-dots,
.PHP .skill-why ul.slick-dots,
.Laravel .skill-why ul.slick-dots,
.CodeIgniter .skill-why ul.slick-dots,
.Symfony .skill-why ul.slick-dots,
.Python .skill-why ul.slick-dots,
.Django .skill-why ul.slick-dots,
.NodeJS .skill-why ul.slick-dots,
.dotnet .skill-why ul.slick-dots,
.Angular .skill-why ul.slick-dots,
.ReactJs .skill-why ul.slick-dots,
.VueJS .skill-why ul.slick-dots,
.MEAN .skill-why ul.slick-dots,
.MERN .skill-why ul.slick-dots,
.Magento .skill-why ul.slick-dots,
.WooCommerce .skill-why ul.slick-dots,
.Shopify .skill-why ul.slick-dots,
.Drupal .skill-why ul.slick-dots,
.iOS .skill-why ul.slick-dots,
.Android .skill-why ul.slick-dots,
.React-Native .skill-why ul.slick-dots,
.React .skill-why ul.slick-dots,
.hybrid .skill-why ul.slick-dots,
.Flutter .skill-why ul.slick-dots,
.Ionic .skill-why ul.slick-dots,
.Flask .skill-why ul.slick-dots,
.front-end-development .skill-why ul.slick-dots,
.Backend-Development .skill-why ul.slick-dots {
  margin-top: 0; }

.WordPress .skill-frame h2,
.Swift .skill-frame h2,
.PHP .skill-frame h2,
.Laravel .skill-frame h2,
.CodeIgniter .skill-frame h2,
.Symfony .skill-frame h2,
.Python .skill-frame h2,
.Django .skill-frame h2,
.NodeJS .skill-frame h2,
.dotnet .skill-frame h2,
.Angular .skill-frame h2,
.ReactJs .skill-frame h2,
.VueJS .skill-frame h2,
.MEAN .skill-frame h2,
.MERN .skill-frame h2,
.Magento .skill-frame h2,
.WooCommerce .skill-frame h2,
.Shopify .skill-frame h2,
.Drupal .skill-frame h2,
.iOS .skill-frame h2,
.Android .skill-frame h2,
.React-Native .skill-frame h2,
.React .skill-frame h2,
.hybrid .skill-frame h2,
.Flutter .skill-frame h2,
.Ionic .skill-frame h2,
.Flask .skill-frame h2,
.front-end-development .skill-frame h2,
.Backend-Development .skill-frame h2 {
  font-size: 24px; }
  @media (min-width: 992px) {
    .WordPress .skill-frame h2,
    .Swift .skill-frame h2,
    .PHP .skill-frame h2,
    .Laravel .skill-frame h2,
    .CodeIgniter .skill-frame h2,
    .Symfony .skill-frame h2,
    .Python .skill-frame h2,
    .Django .skill-frame h2,
    .NodeJS .skill-frame h2,
    .dotnet .skill-frame h2,
    .Angular .skill-frame h2,
    .ReactJs .skill-frame h2,
    .VueJS .skill-frame h2,
    .MEAN .skill-frame h2,
    .MERN .skill-frame h2,
    .Magento .skill-frame h2,
    .WooCommerce .skill-frame h2,
    .Shopify .skill-frame h2,
    .Drupal .skill-frame h2,
    .iOS .skill-frame h2,
    .Android .skill-frame h2,
    .React-Native .skill-frame h2,
    .React .skill-frame h2,
    .hybrid .skill-frame h2,
    .Flutter .skill-frame h2,
    .Ionic .skill-frame h2,
    .Flask .skill-frame h2,
    .front-end-development .skill-frame h2,
    .Backend-Development .skill-frame h2 {
      font-size: 30px; } }

.WordPress .custom-software-development h2,
.Swift .custom-software-development h2,
.PHP .custom-software-development h2,
.Laravel .custom-software-development h2,
.CodeIgniter .custom-software-development h2,
.Symfony .custom-software-development h2,
.Python .custom-software-development h2,
.Django .custom-software-development h2,
.NodeJS .custom-software-development h2,
.dotnet .custom-software-development h2,
.Angular .custom-software-development h2,
.ReactJs .custom-software-development h2,
.VueJS .custom-software-development h2,
.MEAN .custom-software-development h2,
.MERN .custom-software-development h2,
.Magento .custom-software-development h2,
.WooCommerce .custom-software-development h2,
.Shopify .custom-software-development h2,
.Drupal .custom-software-development h2,
.iOS .custom-software-development h2,
.Android .custom-software-development h2,
.React-Native .custom-software-development h2,
.React .custom-software-development h2,
.hybrid .custom-software-development h2,
.Flutter .custom-software-development h2,
.Ionic .custom-software-development h2,
.Flask .custom-software-development h2,
.front-end-development .custom-software-development h2,
.Backend-Development .custom-software-development h2 {
  font-size: 30px; }
  @media (min-width: 992px) {
    .WordPress .custom-software-development h2,
    .Swift .custom-software-development h2,
    .PHP .custom-software-development h2,
    .Laravel .custom-software-development h2,
    .CodeIgniter .custom-software-development h2,
    .Symfony .custom-software-development h2,
    .Python .custom-software-development h2,
    .Django .custom-software-development h2,
    .NodeJS .custom-software-development h2,
    .dotnet .custom-software-development h2,
    .Angular .custom-software-development h2,
    .ReactJs .custom-software-development h2,
    .VueJS .custom-software-development h2,
    .MEAN .custom-software-development h2,
    .MERN .custom-software-development h2,
    .Magento .custom-software-development h2,
    .WooCommerce .custom-software-development h2,
    .Shopify .custom-software-development h2,
    .Drupal .custom-software-development h2,
    .iOS .custom-software-development h2,
    .Android .custom-software-development h2,
    .React-Native .custom-software-development h2,
    .React .custom-software-development h2,
    .hybrid .custom-software-development h2,
    .Flutter .custom-software-development h2,
    .Ionic .custom-software-development h2,
    .Flask .custom-software-development h2,
    .front-end-development .custom-software-development h2,
    .Backend-Development .custom-software-development h2 {
      font-size: 48px;
      line-height: 61px; } }

.WordPress .custom-software-development .cmn-heading-para,
.Swift .custom-software-development .cmn-heading-para,
.PHP .custom-software-development .cmn-heading-para,
.Laravel .custom-software-development .cmn-heading-para,
.CodeIgniter .custom-software-development .cmn-heading-para,
.Symfony .custom-software-development .cmn-heading-para,
.Python .custom-software-development .cmn-heading-para,
.Django .custom-software-development .cmn-heading-para,
.NodeJS .custom-software-development .cmn-heading-para,
.dotnet .custom-software-development .cmn-heading-para,
.Angular .custom-software-development .cmn-heading-para,
.ReactJs .custom-software-development .cmn-heading-para,
.VueJS .custom-software-development .cmn-heading-para,
.MEAN .custom-software-development .cmn-heading-para,
.MERN .custom-software-development .cmn-heading-para,
.Magento .custom-software-development .cmn-heading-para,
.WooCommerce .custom-software-development .cmn-heading-para,
.Shopify .custom-software-development .cmn-heading-para,
.Drupal .custom-software-development .cmn-heading-para,
.iOS .custom-software-development .cmn-heading-para,
.Android .custom-software-development .cmn-heading-para,
.React-Native .custom-software-development .cmn-heading-para,
.React .custom-software-development .cmn-heading-para,
.hybrid .custom-software-development .cmn-heading-para,
.Flutter .custom-software-development .cmn-heading-para,
.Ionic .custom-software-development .cmn-heading-para,
.Flask .custom-software-development .cmn-heading-para,
.front-end-development .custom-software-development .cmn-heading-para,
.Backend-Development .custom-software-development .cmn-heading-para {
  margin-bottom: 0; }
  @media (min-width: 992px) {
    .WordPress .custom-software-development .cmn-heading-para,
    .Swift .custom-software-development .cmn-heading-para,
    .PHP .custom-software-development .cmn-heading-para,
    .Laravel .custom-software-development .cmn-heading-para,
    .CodeIgniter .custom-software-development .cmn-heading-para,
    .Symfony .custom-software-development .cmn-heading-para,
    .Python .custom-software-development .cmn-heading-para,
    .Django .custom-software-development .cmn-heading-para,
    .NodeJS .custom-software-development .cmn-heading-para,
    .dotnet .custom-software-development .cmn-heading-para,
    .Angular .custom-software-development .cmn-heading-para,
    .ReactJs .custom-software-development .cmn-heading-para,
    .VueJS .custom-software-development .cmn-heading-para,
    .MEAN .custom-software-development .cmn-heading-para,
    .MERN .custom-software-development .cmn-heading-para,
    .Magento .custom-software-development .cmn-heading-para,
    .WooCommerce .custom-software-development .cmn-heading-para,
    .Shopify .custom-software-development .cmn-heading-para,
    .Drupal .custom-software-development .cmn-heading-para,
    .iOS .custom-software-development .cmn-heading-para,
    .Android .custom-software-development .cmn-heading-para,
    .React-Native .custom-software-development .cmn-heading-para,
    .React .custom-software-development .cmn-heading-para,
    .hybrid .custom-software-development .cmn-heading-para,
    .Flutter .custom-software-development .cmn-heading-para,
    .Ionic .custom-software-development .cmn-heading-para,
    .Flask .custom-software-development .cmn-heading-para,
    .front-end-development .custom-software-development .cmn-heading-para,
    .Backend-Development .custom-software-development .cmn-heading-para {
      margin-bottom: 20px; } }

.WordPress ul.skill-page-mod li:nth-child(3),
.Swift ul.skill-page-mod li:nth-child(3),
.PHP ul.skill-page-mod li:nth-child(3),
.Laravel ul.skill-page-mod li:nth-child(3),
.CodeIgniter ul.skill-page-mod li:nth-child(3),
.Symfony ul.skill-page-mod li:nth-child(3),
.Python ul.skill-page-mod li:nth-child(3),
.Django ul.skill-page-mod li:nth-child(3),
.NodeJS ul.skill-page-mod li:nth-child(3),
.dotnet ul.skill-page-mod li:nth-child(3),
.Angular ul.skill-page-mod li:nth-child(3),
.ReactJs ul.skill-page-mod li:nth-child(3),
.VueJS ul.skill-page-mod li:nth-child(3),
.MEAN ul.skill-page-mod li:nth-child(3),
.MERN ul.skill-page-mod li:nth-child(3),
.Magento ul.skill-page-mod li:nth-child(3),
.WooCommerce ul.skill-page-mod li:nth-child(3),
.Shopify ul.skill-page-mod li:nth-child(3),
.Drupal ul.skill-page-mod li:nth-child(3),
.iOS ul.skill-page-mod li:nth-child(3),
.Android ul.skill-page-mod li:nth-child(3),
.React-Native ul.skill-page-mod li:nth-child(3),
.React ul.skill-page-mod li:nth-child(3),
.hybrid ul.skill-page-mod li:nth-child(3),
.Flutter ul.skill-page-mod li:nth-child(3),
.Ionic ul.skill-page-mod li:nth-child(3),
.Flask ul.skill-page-mod li:nth-child(3),
.front-end-development ul.skill-page-mod li:nth-child(3),
.Backend-Development ul.skill-page-mod li:nth-child(3) {
  flex: 1; }

.WordPress .skill-why h2 {
  font-size: 24px;
  line-height: 32px; }
  @media (min-width: 992px) {
    .WordPress .skill-why h2 {
      font-size: 48px;
      line-height: 61px; } }

.Native .skill-why h2 {
  font-size: 24px;
  line-height: 32px; }
  @media (min-width: 992px) {
    .Native .skill-why h2 {
      font-size: 48px;
      line-height: 61px; } }

.iOS .skill-why h2 {
  font-size: 24px;
  line-height: 32px; }
  @media (min-width: 992px) {
    .iOS .skill-why h2 {
      font-size: 48px;
      line-height: 61px; } }

.iOS .mean-intro {
  min-height: auto !important; }
  @media (min-width: 992px) {
    .iOS .mean-intro {
      min-height: 606px !important; } }

.iOS .o-skills-benefits {
  background-color: #fff !important; }

.Android .skill-why h2 {
  font-size: 24px;
  line-height: 32px; }
  @media (min-width: 992px) {
    .Android .skill-why h2 {
      font-size: 48px;
      line-height: 61px; } }

.MEAN .skill-what:after, .MERN .skill-what:after {
  display: none; }
  @media (min-width: 992px) {
    .MEAN .skill-what:after, .MERN .skill-what:after {
      display: block; } }

.MEAN .skill-what h2, .MERN .skill-what h2 {
  font-size: 24px;
  line-height: 32px; }
  @media (min-width: 992px) {
    .MEAN .skill-what h2, .MERN .skill-what h2 {
      font-size: 48px;
      line-height: 61px; } }

.MEAN .skill-service, .MERN .skill-service {
  background: #f5f5f5 !important; }
  @media (min-width: 992px) {
    .MEAN .skill-service, .MERN .skill-service {
      background: linear-gradient(285deg, #07b260 0, #00517f 20%, #023e66 75%, #051b39 100%) !important; } }
  .MEAN .skill-service h2,
  .MEAN .skill-service h3, .MERN .skill-service h2,
  .MERN .skill-service h3 {
    color: #333 !important; }
    @media (min-width: 992px) {
      .MEAN .skill-service h2,
      .MEAN .skill-service h3, .MERN .skill-service h2,
      .MERN .skill-service h3 {
        color: #fff !important; } }

.MEAN .skill-why h2, .MERN .skill-why h2 {
  font-size: 24px;
  line-height: 32px; }
  @media (min-width: 992px) {
    .MEAN .skill-why h2, .MERN .skill-why h2 {
      font-size: 48px;
      line-height: 61px; } }

.mean-stack .skill-what:after {
  display: none; }
  @media (min-width: 992px) {
    .mean-stack .skill-what:after {
      display: block; } }

.mean-stack .skill-what h2 {
  font-size: 24px; }
  @media (min-width: 992px) {
    .mean-stack .skill-what h2 {
      font-size: 48px;
      line-height: 61px; } }

.MERN .o-skills-benefits {
  background-color: #fff !important; }
  @media (min-width: 992px) {
    .MERN .o-skills-benefits {
      background-color: #f5f5f5 !important; } }

.Magento .skill-why h2 {
  font-size: 24px;
  line-height: 32px; }
  @media (min-width: 992px) {
    .Magento .skill-why h2 {
      font-size: 48px;
      line-height: 61px; } }

.Magento .skill-service h2 {
  font-size: 24px;
  line-height: 32px; }
  @media (min-width: 992px) {
    .Magento .skill-service h2 {
      font-size: 48px;
      line-height: 61px; } }

.WooCommerce .skill-why h2 {
  font-size: 24px;
  line-height: 32px; }
  @media (min-width: 992px) {
    .WooCommerce .skill-why h2 {
      font-size: 48px;
      line-height: 61px; } }

.WooCommerce .skill-service h2 {
  font-size: 24px;
  line-height: 32px; }
  @media (min-width: 992px) {
    .WooCommerce .skill-service h2 {
      font-size: 48px;
      line-height: 61px; } }

.PHP .services-pan,
.NodeJS .services-pan,
.Laravel .services-pan,
.React-Native .services-pan,
.React .services-pan,
.symphony .services-pan,
.ReactJS .services-pan,
.WordPress .services-pan,
.WooCommerce .services-pan,
.dotnet .services-pan,
.Django .services-pan,
.Angular .services-pan,
.VueJS .services-pan,
.Python .services-pan,
.hybrid .services-pan,
.front-end-development .services-pan,
.Backend-Development .services-pan,
.CodeIgniter .services-pan,
.Yii .services-pan,
.Web-Application-Development .services-pan,
.Blockchain .services-pan {
  padding: 50px 15px 10px; }
  @media (min-width: 992px) {
    .PHP .services-pan,
    .NodeJS .services-pan,
    .Laravel .services-pan,
    .React-Native .services-pan,
    .React .services-pan,
    .symphony .services-pan,
    .ReactJS .services-pan,
    .WordPress .services-pan,
    .WooCommerce .services-pan,
    .dotnet .services-pan,
    .Django .services-pan,
    .Angular .services-pan,
    .VueJS .services-pan,
    .Python .services-pan,
    .hybrid .services-pan,
    .front-end-development .services-pan,
    .Backend-Development .services-pan,
    .CodeIgniter .services-pan,
    .Yii .services-pan,
    .Web-Application-Development .services-pan,
    .Blockchain .services-pan {
      padding: 50px 30px 50px; } }

.Symfony .services-pan, .iOS .services-pan, .Android .services-pan, .Magento .services-pan, .Ionic .services-pan, .MERN .services-pan, .mean-stack .services-pan, .Drupal .services-pan, .Flutter .services-pan, .Java .services-pan, .Shopify .services-pan, .Swift .services-pan, .Flask .services-pan {
  padding: 50px 15px 10px; }
  @media (min-width: 992px) {
    .Symfony .services-pan, .iOS .services-pan, .Android .services-pan, .Magento .services-pan, .Ionic .services-pan, .MERN .services-pan, .mean-stack .services-pan, .Drupal .services-pan, .Flutter .services-pan, .Java .services-pan, .Shopify .services-pan, .Swift .services-pan, .Flask .services-pan {
      padding: 50px 15px 50px; } }

.CakePHP .services-pan, .Joomla .services-pan, .ROR .services-pan, .Zend .services-pan, .Dedicated .services-pan {
  padding: 50px 15px 10px; }
  @media (min-width: 992px) {
    .CakePHP .services-pan, .Joomla .services-pan, .ROR .services-pan, .Zend .services-pan, .Dedicated .services-pan {
      padding: 50px 30px 50px; } }

.Java .services-pan {
  padding: 50px 15px 50px; }
  @media (min-width: 992px) {
    .Java .services-pan {
      padding: 50px 30px 50px; } }

@media (min-width: 992px) {
  .Flask .o-skills-hero {
    padding: 65px 20px 0 !important; } }

.Flask .schedule-box {
  margin-top: 0; }

@media (min-width: 992px) {
  .Magento .o-skills-hero {
    padding: 65px 20px 0 !important; } }

.Magento .o-skills-hero h1 {
  margin-bottom: 35px; }
  @media (min-width: 992px) {
    .Magento .o-skills-hero h1 {
      margin-bottom: 0; } }

@media (min-width: 992px) {
  .Magento .skill-service {
    padding: 75px 0 50px; } }

.Magento .skill-service h2 {
  line-height: 30px;
  margin-bottom: 5px; }
  @media (min-width: 992px) {
    .Magento .skill-service h2 {
      line-height: 40px;
      margin-bottom: .5rem; } }

@media (min-width: 992px) {
  .Magento .skill-why {
    padding: 75px 0 50px; } }

@media (min-width: 992px) {
  .Magento .o-engagement-model {
    padding: 65px 0 40px; } }

.Magento .schedule-box {
  margin-top: 0;
  height: 100%; }

.magento-section-1 {
  padding: 40px 0 20px !important; }
  @media (min-width: 992px) {
    .magento-section-1 {
      padding: 70px 0 50px !important; } }
  .magento-section-1 .cmn-heading-para {
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .magento-section-1 .cmn-heading-para {
        margin-bottom: 20px; } }

.o-engagement-model p {
  margin-bottom: 0;
  padding-bottom: 0; }
  @media (min-width: 992px) {
    .o-engagement-model p {
      margin-bottom: 20px;
      padding-bottom: 15px; } }

.schdule_h_img {
  vertical-align: top;
  margin-right: 15px; }

.schdule_h_text {
  width: 96%;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  font-weight: 500; }

.php-bnr {
  background: #000; }

.PHP .schedule-box, .WordPress .schedule-box, .eCommerce .schedule-box, .Mobile .schedule-box, .ROR .schedule-box {
  margin-top: 0; }

.Mobile .schedule-box {
  height: 100%; }

@media screen and (min-width: 992px) {
  .Mobile .o-skills-hero {
    padding: 65px 20px 0 !important; } }

@media screen and (min-width: 992px) {
  .Mobile .header_left_pan {
    padding-top: 50px; } }

.Dedicated ul.skill-page-mod li:nth-child(3), .Web-Application-Development ul.skill-page-mod li:nth-child(3), .Custom-Software-Development ul.skill-page-mod li:nth-child(3), .eCommerce ul.skill-page-mod li:nth-child(3), .Mobile ul.skill-page-mod li:nth-child(3), .cmn-logo-fix ul.skill-page-mod li:nth-child(3) {
  flex: 1; }

.blue-heading {
  background: #08426f;
  padding: 10px;
  background-color: #08426f;
  color: #fff;
  margin-top: 0;
  text-align: center;
  font-size: 12px;
  font-family: 'Montserrat',sans-serif;
  font-weight: 600;
  z-index: 9999999;
  width: 100%;
  margin-bottom: 0; }
  @media screen and (min-width: 992px) {
    .blue-heading {
      font-size: 17px; } }

@media screen and (min-width: 992px) {
  .Joomla .cmn-service-bnr-mod {
    padding: 65px 20px 0 !important; } }

@media screen and (min-width: 992px) {
  .Joomla .header_left_pan {
    padding-top: 50px; } }

@media screen and (min-width: 992px) {
  .ROR .o-skills-hero {
    padding: 65px 20px 0 !important; } }

@media screen and (min-width: 992px) {
  .ROR .header_left_pan {
    padding-top: 50px; } }

.Magento .skill-service .black-text {
  color: #333 !important; }

.CMS .schedule-box {
  margin-top: 0; }

.schdule-min-height {
  height: 100%; }

.why-us-page.big-data {
  padding-top: 88px; }
  @media (min-width: 992px) {
    .why-us-page.big-data {
      padding-top: 80px; } }

.why-us__why-us-blocks {
  text-align: left;
  position: relative;
  padding-left: 80px; }
  @media (min-width: 992px) {
    .why-us__why-us-blocks {
      padding-left: 85px;
      text-align: left;
      max-height: inherit; } }
  .why-us__why-us-blocks h3 {
    font-size: 20px; }
    @media (min-width: 992px) {
      .why-us__why-us-blocks h3 {
        font-size: 26px; } }
  .why-us__why-us-blocks p {
    font-size: 16px;
    line-height: 24px;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .why-us__why-us-blocks p {
        font-size: 19px;
        line-height: 32px;
        font-family: "Montserrat", sans-serif; } }
  .why-us__why-us-blocks:before {
    top: 5px; }
    @media (min-width: 992px) {
      .why-us__why-us-blocks:before {
        top: 0; } }

.skill-why h2 {
  font-size: 24px;
  line-height: 30px; }
  @media (min-width: 992px) {
    .skill-why h2 {
      font-size: 48px;
      line-height: 61px; } }

.php-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 80px;
  height: 75px;
  background-image: url("/images/php/php-sprite.png");
  background-repeat: no-repeat; }

.php-why-01:before {
  background-position: 0 0; }

.php-why-02:before {
  background-position: -85px 0; }

.php-why-03:before {
  background-position: -170px 0; }

.php-why-04:before {
  background-position: -255px 0; }

.php-why-05:before {
  background-position: -340px 0; }

.php-why-06:before {
  background-position: -430px 0; }

.laravel-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 80px;
  height: 75px;
  background-image: url("/images/laravel/laravel-sprite.png");
  background-repeat: no-repeat; }

.laravel-why-01:before {
  background-position: 0 0; }

.laravel-why-02:before {
  background-position: -85px 0; }

.laravel-why-03:before {
  background-position: -170px 0; }

.laravel-why-04:before {
  background-position: -255px 0; }

.laravel-why-05:before {
  background-position: -340px 0; }

.laravel-why-06:before {
  background-position: -430px 0; }

.codeigniter-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 80px;
  height: 75px;
  background-image: url("/images/codeigniter/codeigniter-sprite.png");
  background-repeat: no-repeat; }

.codeigniter-why-01:before {
  background-position: 0 0; }

.codeigniter-why-02:before {
  background-position: -85px 0; }

.codeigniter-why-03:before {
  background-position: -170px 0; }

.codeigniter-why-04:before {
  background-position: -255px 0; }

.codeigniter-why-05:before {
  background-position: -340px 0; }

.codeigniter-why-06:before {
  background-position: -430px 0; }

.symfony-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 80px;
  height: 75px;
  background-image: url("/images/symfony/symfony-sprite.png");
  background-repeat: no-repeat; }

.symfony-why-01:before {
  background-position: 0 0; }

.symfony-why-02:before {
  background-position: -85px 0; }

.symfony-why-03:before {
  background-position: -170px 0; }

.symfony-why-04:before {
  background-position: -255px 0; }

.symfony-why-05:before {
  background-position: -340px 0; }

.symfony-why-06:before {
  background-position: -430px 0; }

.python-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 63px;
  height: 63px;
  background-image: url("/images/python/python-sprite.png");
  background-repeat: no-repeat; }

.python-why-01:before {
  background-position: 0 0; }

.python-why-02:before {
  background-position: -66px 0; }

.python-why-03:before {
  background-position: -145px 0; }

.python-why-04:before {
  background-position: -218px 0; }

.python-why-05:before {
  background-position: -290px 0; }

.python-why-06:before {
  background-position: -360px 0; }

.django-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 80px;
  height: 75px;
  background-image: url("/images/django/django-sprite.png");
  background-repeat: no-repeat; }

.django-why-01:before {
  background-position: -335px 0; }

.django-why-02:before {
  background-position: -255px 0; }

.django-why-03:before {
  background-position: -85px 0; }

.django-why-04:before {
  background-position: -170px 0; }

.django-why-05:before {
  background-position: 0 0; }

.nodejs-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 63px;
  height: 60px;
  background-image: url("/images/node/nodejs-sprite.png");
  background-repeat: no-repeat; }

.nodejs-why-01:before {
  background-position: 0 0; }

.nodejs-why-02:before {
  background-position: -72px 0; }

.nodejs-why-03:before {
  background-position: -146px 0; }

.nodejs-why-04:before {
  background-position: -215px 0; }

.nodejs-why-05:before {
  background-position: -295px 0; }

.nodejs-why-06:before {
  background-position: -368px 0; }

.nodejs-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 63px;
  height: 60px;
  background-image: url("/images/node/nodejs-sprite.png");
  background-repeat: no-repeat; }

.nodejs-why-01:before {
  background-position: 0 0; }

.nodejs-why-02:before {
  background-position: -72px 0; }

.nodejs-why-03:before {
  background-position: -146px 0; }

.nodejs-why-04:before {
  background-position: -215px 0; }

.nodejs-why-05:before {
  background-position: -295px 0; }

.nodejs-why-06:before {
  background-position: -368px 0; }

.dotnet-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 64px;
  height: 63px;
  background-image: url("/images/wordpress/wordpress-sprite.png");
  background-repeat: no-repeat; }

.dotnet-why-01:before {
  background-position: 0 0; }

.dotnet-why-02:before {
  background-position: -75px 0; }

.dotnet-why-03:before {
  background-position: -150px 0; }

.dotnet-why-04:before {
  background-position: -225px 0; }

.dotnet-why-05:before {
  background-position: -300px 0; }

.dotnet-why-06:before {
  background-position: -375px 0; }

.wordpress-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 64px;
  height: 63px;
  background-image: url("/images/wordpress/wordpress-sprite.png");
  background-repeat: no-repeat; }

.dotnet-why-01:before {
  background-position: 0 0; }

.dotnet-why-02:before {
  background-position: -75px 0; }

.dotnet-why-03:before {
  background-position: -150px 0; }

.dotnet-why-04:before {
  background-position: -225px 0; }

.dotnet-why-05:before {
  background-position: -300px 0; }

.dotnet-why-06:before {
  background-position: -375px 0; }

.angular-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 63px;
  height: 63px;
  background-image: url("/images/angular/angularjs-sprite.png");
  background-repeat: no-repeat; }

.angular-why-01:before {
  background-position: 0 0; }

.angular-why-02:before {
  background-position: -66px 0; }

.angular-why-03:before {
  background-position: -145px 0; }

.angular-why-04:before {
  background-position: -218px 0; }

.angular-why-05:before {
  background-position: -290px 0; }

.angular-why-06:before {
  background-position: -360px 0; }

.reactjs-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 80px;
  height: 75px;
  background-image: url("/images/react/react-sprite.png");
  background-repeat: no-repeat; }

.reactjs-why-01:before {
  background-position: 0 0; }

.reactjs-why-05:before {
  background-position: -335px 0; }

.reactjs-why-03:before {
  background-position: -165px 0; }

.reactjs-why-04:before {
  background-position: -245px 0; }

.vuejs-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 79px;
  height: 63px;
  background-image: url("/images/vue/vuejs-sprite.png");
  background-repeat: no-repeat; }

.vuejs-why-01:before {
  background-position: 0 0; }

.vuejs-why-02:before {
  background-position: -89px 0; }

.vuejs-why-03:before {
  background-position: -170px 0; }

.vuejs-why-04:before {
  background-position: -267px 0; }

.vuejs-why-05:before {
  background-position: -356px 0; }

.vuejs-why-06:before {
  background-position: 0 -73px; }

.vuejs-why-06:before {
  background-position: -89px -73px; }

.meanstack-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 63px;
  height: 63px;
  background-image: url("/images/mean/meanstackjs-sprite.png");
  background-repeat: no-repeat; }

.meanstack-why-01:before {
  background-position: 0 0; }

.meanstack-why-02:before {
  background-position: -73px 0; }

.meanstack-why-03:before {
  background-position: -147px 0; }

.meanstack-why-04:before {
  background-position: -220px 0; }

.meanstack-why-05:before {
  background-position: -293px 0; }

.meanstack-why-06:before {
  background-position: -220px 0; }

.mernstack-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 63px;
  height: 63px;
  background-image: url("/images/mern/mernstackjs-sprite.png");
  background-repeat: no-repeat; }

.mernstack-why-01:before {
  background-position: 0 0; }

.mernstack-why-02:before {
  background-position: -73px 0; }

.mernstack-why-03:before {
  background-position: -147px 0; }

.mernstack-why-04:before {
  background-position: -220px 0; }

.mernstack-why-05:before {
  background-position: -293px 0; }

.mernstack-why-06:before {
  background-position: -365px 0; }

.woocommerce-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 70px;
  height: 63px;
  background-image: url("/images/woocommerce/woocommerce-sprite.png");
  background-repeat: no-repeat; }

.woocommerce-why-01:before {
  background-position: 0 0; }

.woocommerce-why-02:before {
  background-position: -81px 0; }

.woocommerce-why-03:before {
  background-position: -160px 0; }

.woocommerce-why-04:before {
  background-position: -240px 0; }

.woocommerce-why-05:before {
  background-position: -322px 0; }

.woocommerce-why-06:before {
  background-position: -395px 0; }

.woocommerce-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 70px;
  height: 63px;
  background-image: url("/images/woocommerce/woocommerce-sprite.png");
  background-repeat: no-repeat; }

.woocommerce-why-01:before {
  background-position: 0 0; }

.woocommerce-why-02:before {
  background-position: -81px 0; }

.woocommerce-why-03:before {
  background-position: -160px 0; }

.woocommerce-why-04:before {
  background-position: -240px 0; }

.woocommerce-why-05:before {
  background-position: -322px 0; }

.woocommerce-why-06:before {
  background-position: -395px 0; }

.shopify-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 83px;
  height: 71px;
  background-image: url("/images/shopify/shopify-sprite.png");
  background-repeat: no-repeat; }

.shopify-why-01:before {
  background-position: 0 0; }

.shopify-why-02:before {
  background-position: -93px 0; }

.shopify-why-03:before {
  background-position: -187px 0; }

.shopify-why-04:before {
  background-position: -281px 0; }

.shopify-why-05:before {
  background-position: -375px 0; }

.shopify-why-06:before {
  background-position: -463px 0; }

.wordpress-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 64px;
  height: 63px;
  background-image: url("/images/wordpress/wordpress-sprite.png");
  background-repeat: no-repeat;
  top: 5px; }
  @media (min-width: 992px) {
    .wordpress-blocks:before {
      top: 0; } }

.wordpress-why-01:before {
  background-position: 0 0; }

.wordpress-why-02:before {
  background-position: -75px 0; }

.wordpress-why-03:before {
  background-position: -150px 0; }

.wordpress-why-04:before {
  background-position: -225px 0; }

.wordpress-why-05:before {
  background-position: -300px 0; }

.wordpress-why-06:before {
  background-position: -375px 0; }

.drupal-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 64px;
  height: 63px;
  background-image: url("/images/drupal/drupal-sprite.png");
  background-repeat: no-repeat; }

.drupal-why-01:before {
  background-position: 0 0; }

.drupal-why-02:before {
  background-position: -148px 0; }

.drupal-why-03:before {
  background-position: -296px 0; }

.drupal-why-04:before {
  background-position: -74px 0; }

.drupal-why-05:before {
  background-position: -222px 0; }

.drupal-why-06:before {
  background-position: -370px 0; }

.ios-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 80px;
  height: 75px;
  background-image: url("/images/ios/ios-sprite.png");
  background-repeat: no-repeat; }

.ios-why-01:before {
  background-position: 0 0; }

.ios-why-02:before {
  background-position: -148px 0; }

.ios-why-03:before {
  background-position: -151px 0; }

.ios-why-04:before {
  background-position: -229px 0; }

.ios-why-05:before {
  background-position: -302px 0; }

.ios-why-06:before {
  background-position: 0 -75px; }

.android-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 75px;
  height: 67px;
  background-image: url("/images/android/android-sprite.png");
  background-repeat: no-repeat; }

.android-why-01:before {
  background-position: 0 0; }

.android-why-02:before {
  background-position: -85px 0; }

.android-why-03:before {
  background-position: -170px 0; }

.android-why-04:before {
  background-position: -255px 0; }

.android-why-05:before {
  background-position: 0 -77px; }

.android-why-06:before {
  background-position: -86px -77px; }

.android-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 75px;
  height: 67px;
  background-image: url("/images/android/android-sprite.png");
  background-repeat: no-repeat; }

.android-why-01:before {
  background-position: 0 0; }

.android-why-02:before {
  background-position: -85px 0; }

.android-why-03:before {
  background-position: -170px 0; }

.android-why-04:before {
  background-position: -255px 0; }

.android-why-05:before {
  background-position: 0 -77px; }

.android-why-06:before {
  background-position: -86px -77px; }

.reactnative-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 64px;
  height: 64px;
  background-image: url("/images/react-native/react-native-sprite.png");
  background-repeat: no-repeat; }

.reactnative-why-01:before {
  background-position: 0 0; }

.reactnative-why-02:before {
  background-position: -74px 0; }

.reactnative-why-03:before {
  background-position: -148px 0; }

.reactnative-why-04:before {
  background-position: -222px 0; }

.reactnative-why-05:before {
  background-position: -297px 0; }

.reactnative-why-06:before {
  background-position: 0 -74px; }

.reactnative-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 64px;
  height: 64px;
  background-image: url("/images/react-native/react-native-sprite.png");
  background-repeat: no-repeat; }

.reactnative-why-01:before {
  background-position: 0 0; }

.reactnative-why-02:before {
  background-position: -74px 0; }

.reactnative-why-03:before {
  background-position: -148px 0; }

.reactnative-why-04:before {
  background-position: -222px 0; }

.reactnative-why-05:before {
  background-position: -297px 0; }

.reactnative-why-06:before {
  background-position: 0 -74px; }

.flutter-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 64px;
  height: 64px;
  background-image: url("/images/flutter/flutter-sprite.png");
  background-repeat: no-repeat; }

.flutter-why-01:before {
  background-position: 0 0; }

.flutter-why-02:before {
  background-position: -74px 0; }

.flutter-why-03:before {
  background-position: -148px 0; }

.flutter-why-04:before {
  background-position: -222px 0; }

.flutter-why-05:before {
  background-position: -297px 0; }

.flutter-why-06:before {
  background-position: 0 -74px; }

.ionic-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 70px;
  height: 72px;
  background-image: url("/images/ionic/ionic-sprite.png");
  background-repeat: no-repeat; }

.ionic-why-01:before {
  background-position: 0 0; }

.ionic-why-02:before {
  background-position: -247px -74px; }

.ionic-why-03:before {
  background-position: -330px -74px; }

.ionic-why-04:before {
  background-position: 0 -148px; }

.ionic-why-05:before {
  background-position: -84px -148px; }

.ionic-why-06:before {
  background-position: -167px -148px; }

.ionic-why-07:before {
  background-position: -248px -147px; }

.swift-blocks:before {
  content: '';
  position: absolute;
  left: 0px;
  width: 64px;
  height: 64px;
  background-image: url("/images/swift/swift-sprite.png");
  background-repeat: no-repeat; }

.swift-why-01:before {
  background-position: 0 0; }

.swift-why-02:before {
  background-position: -66px 0; }

.swift-why-03:before {
  background-position: -149px 0; }

.swift-why-04:before {
  background-position: -222px 0; }

.swift-why-05:before {
  background-position: -296px 0; }

.swift-why-06:before {
  background-position: 0 -75px; }

.skill-service {
  padding: 40px 0 20px; }
  @media (min-width: 992px) {
    .skill-service {
      padding: 65px 0 50px; } }
  .skill-service h2 {
    margin-bottom: 5px;
    color: #333;
    font-size: 30px; }
    @media (min-width: 992px) {
      .skill-service h2 {
        margin-bottom: .5rem;
        color: #fff;
        font-size: 48px; } }
  .skill-service h3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #333; }
    @media (min-width: 992px) {
      .skill-service h3 {
        font-size: 26px;
        line-height: inherit;
        font-weight: 600;
        color: #fff; } }
    .skill-service h3.pad-bot-30 {
      padding-bottom: 25px; }
      @media (min-width: 992px) {
        .skill-service h3.pad-bot-30 {
          padding-bottom: 30px; } }
    .skill-service h3 br {
      display: none; }
      @media (min-width: 992px) {
        .skill-service h3 br {
          display: block; } }
  .skill-service .services-pan h3 {
    font-size: 20px;
    line-height: inherit;
    margin-bottom: 10px;
    font-weight: 600;
    color: #333; }
    @media (min-width: 992px) {
      .skill-service .services-pan h3 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px; } }
  .skill-service .services-pan p {
    font-size: 16px !important;
    line-height: 24px !important;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .skill-service .services-pan p {
        font-size: 19px !important;
        line-height: 32px !important;
        font-family: "Montserrat", sans-serif; } }
  .skill-service .btn_wrapper {
    margin-top: 10px; }
    @media (min-width: 992px) {
      .skill-service .btn_wrapper {
        margin-top: 20px; } }
  @media (min-width: 992px) {
    .skill-service.gradient-blue-green {
      background: linear-gradient(285deg, #07b260 0, #00517f 20%, #023e66 75%, #051b39 100%); } }

.magento-section-1 h2 {
  padding-bottom: 10px; }
  @media (min-width: 992px) {
    .magento-section-1 h2 {
      padding-bottom: 30px; } }

.why-skill-slider .slick-arrow {
  display: none !important; }

@media (min-width: 992px) {
  .masonry {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    transition: all .5s ease-in-out;
    column-gap: 30px;
    column-fill: initial; } }

.masonry .brick {
  margin-bottom: 20px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  border: 1px solid #D9D9D9; }
  @media screen and (min-width: 778px) {
    .masonry .brick {
      display: block; } }
  @media (min-width: 992px) {
    .masonry .brick {
      margin-bottom: 30px;
      display: inline-block; } }
  .masonry .brick p {
    font-size: 16px;
    line-height: 24px;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .masonry .brick p {
        font-family: "Montserrat", sans-serif;
        font-size: 19px;
        line-height: 32px; } }
  .masonry .brick a {
    width: 134px;
    height: 46px;
    line-height: 42px !important;
    border: 2px solid #333;
    border-radius: 5px;
    font-size: 18px !important;
    color: #333 !important;
    padding: 0;
    text-transform: none;
    color: #337ab7;
    display: block;
    margin: 0 auto 30px;
    font-weight: 400; }
    @media (min-width: 992px) {
      .masonry .brick a {
        margin: 0 auto; } }

.services-pan {
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  padding: 105px 30px 30px;
  text-align: center;
  /* django Dev*/ }

.mean-intro {
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid #E0E0E0;
  border-radius: 6px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1); }
  @media (min-width: 992px) {
    .mean-intro {
      min-height: 320px; } }
  .mean-intro__min-h-570 {
    min-height: 570px !important; }
  .mean-intro__technology {
    padding: 50px 15px;
    text-align: center; }
    @media (min-width: 992px) {
      .mean-intro__technology {
        padding: 50px; } }
    .mean-intro__technology p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .mean-intro__technology p {
          font-size: 20px;
          line-height: 32px;
          margin-bottom: 25px;
          font-family: "Montserrat", sans-serif; } }
    .mean-intro__technology h5 {
      font-size: 20px;
      margin: 30px 0;
      position: relative; }
      @media (min-width: 992px) {
        .mean-intro__technology h5 {
          font-size: 30px; } }
      .mean-intro__technology h5:after {
        content: '';
        position: absolute;
        bottom: -20px;
        width: 58px;
        height: 1px;
        background-color: #ccc;
        left: 43%;
        transform: translateY(-45%);
        -webkit-transform: translateY(-45%);
        -moz-transform: translateY(-45%);
        -ms-transform: translateY(-45%); }

.work-holder {
  margin-bottom: 20px; }
  .work-holder .work-pan {
    margin: 2%;
    min-height: 420px;
    display: inline-block;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 5px 0px #8f8f8f;
    -moz-box-shadow: 0px 0px 5px 0px #8f8f8f;
    box-shadow: 0px 0px 5px 0px #8f8f8f;
    vertical-align: top;
    background-color: #fff; }
    @media (min-width: 992px) {
      .work-holder .work-pan {
        width: 45%; } }
    .work-holder .work-pan .work-img-bg {
      padding: 20px 20px 0 20px; }
    .work-holder .work-pan .work-text {
      padding: 20px;
      text-align: left; }
      .work-holder .work-pan .work-text h3 {
        color: #333;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 10px; }
        @media (min-width: 992px) {
          .work-holder .work-pan .work-text h3 {
            font-size: 26px;
            line-height: 32px; } }
      .work-holder .work-pan .work-text p {
        color: #333;
        margin-bottom: 10px;
        font-size: 19px;
        line-height: 28px; }
      .work-holder .work-pan .work-text a {
        text-decoration: underline;
        color: #578EDC;
        font-size: 14px; }
  .work-holder .work-pan-auto {
    min-height: auto; }

.related-tab {
  -webkit-box-shadow: 0px 0px 18px 0px #bab8ba;
  -moz-box-shadow: 0px 0px 18px 0px #bab8ba;
  box-shadow: 0px 0px 18px 0px #bab8ba;
  margin-bottom: 50px;
  height: 400px;
  overflow: hidden;
  /* Style the tab */
  /* Style the buttons inside the tab */
  /* Change background color of buttons on hover */
  /* Create an active/current "tab button" class */
  /* Style the tab content */ }
  .related-tab .tab {
    float: left;
    width: 25%;
    height: 445px;
    overflow-y: scroll; }
  .related-tab .tab button {
    display: block;
    background-color: inherit;
    color: black;
    padding: 22px 16px 22px 80px;
    width: 100%;
    float: left;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 17px;
    position: relative;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500; }
    .related-tab .tab button span {
      width: 37px;
      height: 23px;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translate(0, -50%);
      background: url(/images/react/react-sprite.png) no-repeat 0 0; }
      .related-tab .tab button span.react-tab-1 {
        background-position: 0 -253px; }
      .related-tab .tab button span.react-tab-2 {
        background-position: -47px -253px; }
      .related-tab .tab button span.react-tab-3 {
        background-position: -94px -253px; }
      .related-tab .tab button span.react-tab-4 {
        background-position: -141px -253px; }
      .related-tab .tab button span.react-tab-5 {
        background-position: -188px -253px; }
      .related-tab .tab button span.react-tab-6 {
        background-position: -235px -253px; }
      .related-tab .tab button span.react-tab-7 {
        background-position: -282px -253px; }
      .related-tab .tab button span.react-tab-8 {
        background-position: -329px -253px; }
      .related-tab .tab button span.react-tab-9 {
        background-position: -376px -253px; }
      .related-tab .tab button span.react-tab-10 {
        background-position: 0 -286px; }
      .related-tab .tab button span.react-tab-11 {
        background-position: -47px -286px; }
      .related-tab .tab button span.react-tab-12 {
        background-position: -94px -286px; }
  .related-tab .tab button:hover {
    background-color: #fff; }
  .related-tab .tab button.active {
    background-color: #fff; }
  .related-tab .tabcontent {
    float: left;
    width: 75%;
    padding: 90px 50px 90px 26%;
    border-left: none;
    height: 445px;
    background-color: #fff;
    position: relative;
    text-align: left;
    display: block; }
    .related-tab .tabcontent:before {
      content: "";
      width: 246px;
      height: 174px;
      position: absolute;
      left: 50px;
      top: 50%;
      transform: translate(0, -50%); }
    .related-tab .tabcontent h5 {
      font-size: 24px;
      line-height: 32px; }
    .related-tab .tabcontent p {
      font-size: 19px;
      line-height: 32px; }
    .related-tab .tabcontent__react-1:before {
      background: url(/images/react/ts-b.png) no-repeat 50% 50%; }
    .related-tab .tabcontent__react-2:before {
      background: url(/images/react/es-b.png) no-repeat 50% 50%; }
    .related-tab .tabcontent__react-3:before {
      background: url(/images/react/redux-b.png) no-repeat 50% 50%; }
    .related-tab .tabcontent__react-4:before {
      background: url(/images/react/GraphQl-b.png) no-repeat 50% 50%; }
    .related-tab .tabcontent__react-5:before {
      background: url(/images/react/apollo-b.png) no-repeat 50% 50%; }
    .related-tab .tabcontent__react-6:before {
      background: url(/images/react/Jest-b.png) no-repeat 50% 50%; }
    .related-tab .tabcontent__react-7:before {
      background: url(/images/react/Jaasmine-b.png) no-repeat 50% 50%; }
    .related-tab .tabcontent__react-8:before {
      background: url(/images/react/Karma-b.png) no-repeat 50% 50%; }
    .related-tab .tabcontent__react-9:before {
      background: url(/images/react/next-b.png) no-repeat 50% 50%; }
    .related-tab .tabcontent__react-10:before {
      background: url(/images/react/webpack-b.png) no-repeat 50% 50%; }
    .related-tab .tabcontent__react-11:before {
      background: url(/images/react/babel-b.png) no-repeat 50% 50%; }
    .related-tab .tabcontent__react-12:before {
      background: url(/images/react/flux-b.png) no-repeat 50% 50%; }

.reasons-capital__blocks {
  position: relative; }

.our-work {
  padding: 45px 0 20px; }
  @media (min-width: 992px) {
    .our-work {
      padding: 80px 0; } }
  .our-work h2 {
    font-size: 30px;
    line-height: 34px; }
    @media (min-width: 992px) {
      .our-work h2 {
        font-size: 48px;
        line-height: 61px; } }

.magento-section-1 ul li {
  background: url(/images/magento/greentick.png) no-repeat 0 7px !important;
  padding: 0 0 10px 40px;
  font-size: 20px;
  line-height: 32px;
  font-family: "Montserrat", sans-serif; }

.skill-what .row {
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .skill-what .row {
      padding-bottom: 30px; } }

.skill-what h2 {
  padding-bottom: 10px; }
  @media (min-width: 992px) {
    .skill-what h2 {
      padding-bottom: 30px; } }

.skill-what h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  padding-bottom: 30px; }
  @media (min-width: 992px) {
    .skill-what h3 {
      font-size: 26px;
      line-height: inherit;
      font-weight: 600;
      padding-bottom: 40px; } }

.skill-what .font-size-20 {
  font-size: 16px;
  line-height: 24px;
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .skill-what .font-size-20 {
      font-size: 20px;
      line-height: 33px; } }

.blue-bnr-section-new-modify a.new-btn-new-modify {
  margin-top: 25px;
  max-width: 300px; }

.img-resize-php-logo {
  width: 140px; }

.d-trans-pro-wrapper-new .d-trans-pro-tab-btn.d-trans-pro-wrapper-new-modify {
  border: 1px solid transparent; }

.d-trans-pro-wrapper-new .d-trans-pro-content-spacing-new.d-trans-pro-content-spacing-new-laravel {
  margin-top: 100px; }

.d-trans-pro-wrapper .d-trans-pro-content-box.d-trans-pro-content-box3.d-trans-pro-content-spacing-new-magento {
  margin-top: 0; }
  @media screen and (min-width: 992px) {
    .d-trans-pro-wrapper .d-trans-pro-content-box.d-trans-pro-content-box3.d-trans-pro-content-spacing-new-magento {
      margin-top: 150px; } }

.d-trans-pro-wrapper .d-trans-pro-content-box.d-trans-pro-content-box4.d-trans-pro-content-spacing-new-magento {
  margin-top: 0; }
  @media screen and (min-width: 992px) {
    .d-trans-pro-wrapper .d-trans-pro-content-box.d-trans-pro-content-box4.d-trans-pro-content-spacing-new-magento {
      margin-top: 150px; } }

.d-trans-pro-wrapper .d-trans-pro-content-box.d-trans-pro-content-box5.d-trans-pro-content-spacing-new-shopify {
  margin-top: 0; }
  @media screen and (min-width: 992px) {
    .d-trans-pro-wrapper .d-trans-pro-content-box.d-trans-pro-content-box5.d-trans-pro-content-spacing-new-shopify {
      margin-top: 110px; } }

.d-trans-pro-wrapper .d-trans-pro-content-box.d-trans-pro-content-box4.d-trans-pro-content-spacing-new-shopify {
  margin-top: 0; }
  @media screen and (min-width: 992px) {
    .d-trans-pro-wrapper .d-trans-pro-content-box.d-trans-pro-content-box4.d-trans-pro-content-spacing-new-shopify {
      margin-top: 110px; } }

.d-trans-pro-wrapper .d-trans-pro-content-box.d-trans-pro-content-box3.d-trans-pro-content-spacing-new-shopify {
  margin-top: 0; }
  @media screen and (min-width: 992px) {
    .d-trans-pro-wrapper .d-trans-pro-content-box.d-trans-pro-content-box3.d-trans-pro-content-spacing-new-shopify {
      margin-top: 110px; } }

.o-team .team-holder {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(128, 126, 128, 0.3);
  -moz-box-shadow: 0px 0px 20px 0px rgba(128, 126, 128, 0.3);
  box-shadow: 0px 0px 20px 0px rgba(128, 126, 128, 0.3);
  padding: 30px 30px 10px;
  min-height: 100%;
  border-radius: 5px;
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .o-team .team-holder {
      min-height: 710px; } }
  .o-team .team-holder h5 {
    font-size: 24px;
    font-weight: 600; }
  .o-team .team-holder h3 {
    font-size: 24px;
    font-weight: 600;
    color: #05B281; }
  .o-team .team-holder p.department {
    font-size: 16px;
    padding: 5px 0;
    color: #333;
    font-weight: 600; }
  .o-team .team-holder p {
    font-size: 19px;
    line-height: 32px; }
  .o-team .team-holder__team-1 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-1 {
        background: url("/images/common/dimpi.png") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-2 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-2 {
        background: url("/images/common/sabir.png") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-3 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-3 {
        background: url("/images/common/tanuja.png") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-4 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-4 {
        background: url("/images/common/gourab.png") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-5 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-5 {
        background: url("/images/common/team-1.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-6 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-6 {
        background: url("/images/common/team-2.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-7 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-7 {
        background: url("/images/common/team-3.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-8 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-8 {
        background: url("/images/common/team-4.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-9 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-9 {
        background: url("/images/common/team-5.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-10 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-10 {
        background: url("/images/common/team-6.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-11 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-11 {
        background: url("/images/common/team-7.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-12 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-12 {
        background: url("/images/common/team-8.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-13 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-13 {
        background: url("/images/common/team-9.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-14 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-14 {
        background: url("/images/common/team-10.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-15 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-15 {
        background: url("/images/common/team-11.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-16 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-16 {
        background: url("/images/common/team-12.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-17 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-17 {
        background: url("/images/common/team-13.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-18 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-18 {
        background: url("/images/common/team-14.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-19 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-19 {
        background: url("/images/common/team-15.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-20 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-20 {
        background: url("/images/common/team-16.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-21 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-21 {
        background: url("/images/common/team-17.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-22 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-22 {
        background: url("/images/common/team-18.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-23 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-23 {
        background: url("/images/common/team-19.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-24 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-24 {
        background: url("/images/common/team-20.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-25 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-25 {
        background: url("/images/common/team-21.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-26 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-26 {
        background: url("/images/common/team-22.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-27 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-27 {
        background: url("/images/common/team-23.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-28 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-28 {
        background: url("/images/common/team-24.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-29 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-29 {
        background: url("/images/common/team-25.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-30 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-30 {
        background: url("/images/common/team-26.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-31 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-31 {
        background: url("/images/common/team-27.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-32 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-32 {
        background: url("/images/common/team-28.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-33 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-33 {
        background: url("/images/common/team-29.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-34 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-34 {
        background: url("/images/common/team-30.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-35 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-35 {
        background: url("/images/common/team-31.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-36 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-36 {
        background: url("/images/common/team-32.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-37 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-37 {
        background: url("/images/common/team-33.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-38 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-38 {
        background: url("/images/common/team-34.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-39 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-39 {
        background: url("/images/common/team-35.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-40 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-40 {
        background: url("/images/common/team-36.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-41 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-41 {
        background: url("/images/common/team-37.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-42 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-42 {
        background: url("/images/common/team-38.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-43 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-43 {
        background: url("/images/common/team-39.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-44 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-44 {
        background: url("/images/common/team-40.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-45 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-45 {
        background: url("/images/common/team-45.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-46 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-46 {
        background: url("/images/common/team-46.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-47 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-47 {
        background: url("/images/common/team-47.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-48 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-48 {
        background: url("/images/common/team-48.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-49 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-49 {
        background: url("/images/common/team-49.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-50 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-50 {
        background: url("/images/common/team-50.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-51 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-51 {
        background: url("/images/common/team-51.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-52 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-52 {
        background: url("/images/common/team-52.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-53 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-53 {
        background: url("/images/common/team-53.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-54 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-54 {
        background: url("/images/common/team-54.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-55 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-55 {
        background: url("/images/common/team-55.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-56 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-56 {
        background: url("/images/common/team-56.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-57 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-57 {
        background: url("/images/common/team-57.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-58 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-58 {
        background: url("/images/common/team-58.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-59 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-59 {
        background: url("/images/common/team-59.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-60 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-60 {
        background: url("/images/common/team-60.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-61 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-61 {
        background: url("/images/common/team-61.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-62 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-62 {
        background: url("/images/common/team-62.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-63 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-63 {
        background: url("/images/common/team-63.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-64 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-64 {
        background: url("/images/common/team-64.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-65 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-65 {
        background: url("/images/common/team-65.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-66 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-66 {
        background: url("/images/common/team-66.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-67 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-67 {
        background: url("/images/common/team-67.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-68 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-68 {
        background: url("/images/common/team-68.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-69 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-69 {
        background: url("/images/common/team-69.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-70 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-70 {
        background: url("/images/common/team-70.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-71 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-71 {
        background: url("/images/common/team-71.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-72 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-72 {
        background: url("/images/common/team-72.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-73 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-73 {
        background: url("/images/common/team-73.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-74 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-74 {
        background: url("/images/common/team-74.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-75 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-75 {
        background: url("/images/common/team-75.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-76 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-76 {
        background: url("/images/common/team-76.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-77 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-77 {
        background: url("/images/common/team-77.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-78 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-78 {
        background: url("/images/common/team-78.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-79 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-79 {
        background: url("/images/common/team-79.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-80 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-80 {
        background: url("/images/common/team-80.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-81 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-81 {
        background: url("/images/common/team-81.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-82 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-82 {
        background: url("/images/common/team-82.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-83 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-83 {
        background: url("/images/common/team-83.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-84 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-84 {
        background: url("/images/common/team-84.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-85 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-85 {
        background: url("/images/common/team-85.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-86 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-86 {
        background: url("/images/common/team-86.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-87 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-87 {
        background: url("/images/common/team-87.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-88 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-88 {
        background: url("/images/common/team-88.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-89 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-89 {
        background: url("/images/common/team-89.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-90 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-90 {
        background: url("/images/common/team-90.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-91 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-91 {
        background: url("/images/common/team-91.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-92 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-92 {
        background: url("/images/common/team-92.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-93 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-93 {
        background: url("/images/common/team-93.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-94 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-94 {
        background: url("/images/common/team-94.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-95 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-95 {
        background: url("/images/common/team-95.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-96 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-96 {
        background: url("/images/common/team-96.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-97 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-97 {
        background: url("/images/common/team-97.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-98 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-98 {
        background: url("/images/common/team-98.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-99 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-99 {
        background: url("/images/common/team-99.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-100 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-100 {
        background: url("/images/common/team-100.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-101 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-101 {
        background: url("/images/common/team-101.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-102 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-102 {
        background: url("/images/common/team-102.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-103 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-103 {
        background: url("/images/common/team-103.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-104 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-104 {
        background: url("/images/common/team-104.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-105 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-105 {
        background: url("/images/common/team-105.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-106 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-106 {
        background: url("/images/common/team-106.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-107 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-107 {
        background: url("/images/common/team-107.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-108 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-108 {
        background: url("/images/common/team-108.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-109 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-109 {
        background: url("/images/common/team-109.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-110 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-110 {
        background: url("/images/common/team-110.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-111 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-111 {
        background: url("/images/common/team-111.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-112 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-112 {
        background: url("/images/common/team-112.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-113 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-113 {
        background: url("/images/common/team-113.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-114 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-114 {
        background: url("/images/common/team-114.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-115 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-115 {
        background: url("/images/common/team-115.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-116 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-116 {
        background: url("/images/common/team-116.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-117 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-117 {
        background: url("/images/common/team-117.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-118 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-118 {
        background: url("/images/common/team-118.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-119 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-119 {
        background: url("/images/common/team-119.jpg") no-repeat 50% 100% #fff; } }
  .o-team .team-holder__team-120 {
    background-color: #fff; }
    @media (min-width: 992px) {
      .o-team .team-holder__team-120 {
        background: url("/images/common/team-120.jpg") no-repeat 50% 100% #fff; } }

.no-webp .o-skills-benefits__contentholder .content-panel:before {
  background-image: url(/images/common/common-sprite.png); }

.webp .o-skills-benefits__contentholder .content-panel:before {
  background-image: url(/images/webp/common/common-sprite.webp); }

.o-skills-benefits {
  /* backgrounds */
  /* ~./backgrounds */ }
  .o-skills-benefits .row .col {
    padding-left: 15px;
    padding-right: 15px;
    display: inline-block;
    margin-bottom: 20px; }
    @media screen and (min-width: 778px) {
      .o-skills-benefits .row .col {
        margin-bottom: 30px;
        flex: inherit; } }
    @media screen and (min-width: 992px) {
      .o-skills-benefits .row .col {
        flex: 0 0 auto; } }
  .o-skills-benefits__contentholder {
    display: none;
    min-height: 100%;
    padding: 125px 30px 30px;
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    display: block;
    vertical-align: top;
    border: 1px solid #D9D9D9;
    position: relative; }
    @media (min-width: 992px) {
      .o-skills-benefits__contentholder {
        padding: 125px 50px 25px;
        min-height: 440px;
        border: none; } }
    .o-skills-benefits__contentholder h4 {
      color: #333;
      font-weight: 600;
      padding: 0 0 25px 0;
      position: relative; }
    .o-skills-benefits__contentholder h3 {
      color: #333;
      font-weight: 600;
      padding: 0 0 5px 0;
      position: relative;
      font-size: 20px;
      line-height: inherit; }
      @media (min-width: 992px) {
        .o-skills-benefits__contentholder h3 {
          font-size: 26px;
          padding: 0 0 25px 0; } }
    .o-skills-benefits__contentholder p {
      font-size: 16px;
      line-height: 24px;
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .o-skills-benefits__contentholder p {
          font-size: 19px;
          line-height: 32px;
          font-family: "Montserrat", sans-serif; } }
    .o-skills-benefits__contentholder .content-panel:before {
      content: '';
      position: absolute;
      width: 76px;
      height: 72px;
      top: 50px;
      left: 0;
      right: 0;
      margin: 0 auto; }
      @media (min-width: 992px) {
        .o-skills-benefits__contentholder .content-panel:before {
          right: inherit;
          left: 50%;
          margin: 0;
          transform: translateX(-50%); } }
    .o-skills-benefits__contentholder__partners-01:before {
      content: "";
      background-position: 0 -120px; }
    .o-skills-benefits__contentholder__partners-02:before {
      content: "";
      background-position: -86px -120px; }
    .o-skills-benefits__contentholder__partners-03:before {
      content: "";
      background-position: -172px -120px; }
    .o-skills-benefits__contentholder__partners-04:before {
      content: "";
      background-position: -258px -120px; }
  .o-skills-benefits .bottom_pattern {
    padding: 40px 0;
    background-size: 50%;
    text-align: center; }
  .o-skills-benefits h5 {
    color: #fff;
    font-weight: 300;
    margin-bottom: 60px;
    font-size: 20px;
    line-height: 30px; }
  .o-skills-benefits--social h5 {
    font-size: 24px;
    line-height: 40px; }

.skill-why {
  padding: 40px 0 20px; }
  @media (min-width: 992px) {
    .skill-why {
      padding: 70px 0 50px; } }

.o-skills-benefits {
  padding: 40px 0 20px; }
  @media (min-width: 992px) {
    .o-skills-benefits {
      padding: 65px 0 50px; } }
  .o-skills-benefits h2 {
    font-size: 24px;
    line-height: inherit;
    padding-bottom: 20px; }
    @media (min-width: 992px) {
      .o-skills-benefits h2 {
        font-size: 48px;
        line-height: 61px;
        padding-bottom: 30px; } }
  .o-skills-benefits .btn_wrapper {
    margin-top: 15px; }
    @media (min-width: 992px) {
      .o-skills-benefits .btn_wrapper {
        margin-top: 20px; } }

.skill-frame {
  padding: 45px 0 20px; }
  @media (min-width: 992px) {
    .skill-frame {
      padding: 75px 0 50px; } }
  .skill-frame .experience-list ul li a {
    height: 100%;
    display: flex;
    padding: 13px 15px;
    align-items: center;
    position: relative;
    justify-content: center; }
    @media (min-width: 992px) {
      .skill-frame .experience-list ul li a {
        padding: 30px 15px;
        height: 115px; } }
  .skill-frame h2 {
    font-size: 30px;
    line-height: 30px;
    padding-bottom: 15px; }
    @media (min-width: 992px) {
      .skill-frame h2 {
        font-size: 30px;
        padding-bottom: 30px; } }

.prt-box-1 {
  padding-top: 145px; }
  @media (min-width: 992px) {
    .prt-box-1 {
      padding-top: 125px; } }

.prt-box-3 {
  padding-top: 145px; }
  @media (min-width: 992px) {
    .prt-box-3 {
      padding-top: 125px; } }

.prt-box-4 {
  padding-top: 145px; }
  @media (min-width: 992px) {
    .prt-box-4 {
      padding-top: 125px; } }

p.office-pra-text {
  color: #fff;
  font-size: 16px !important;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500; }
  @media (min-width: 992px) {
    p.office-pra-text {
      font-size: 21px !important;
      line-height: 36px;
      margin-bottom: 0;
      font-family: "Montserrat", sans-serif; } }
  p.office-pra-text a {
    color: #fff; }
    @media (min-width: 992px) {
      p.office-pra-text a {
        font-family: "Montserrat", sans-serif;
        text-decoration: underline; } }

.o-office-desk {
  color: #fff;
  position: relative;
  padding: 60px 0;
  overflow: hidden;
  text-align: center; }
  .o-office-desk__content {
    margin-bottom: 20px;
    text-align: left; }
    .o-office-desk__content p {
      font-size: 18px;
      line-height: 38px;
      color: #333;
      padding-top: 40px; }
    .o-office-desk__content .img-holder {
      position: relative;
      text-align: center; }
      .o-office-desk__content .img-holder img {
        z-index: 1;
        position: relative;
        width: 100%; }
      .o-office-desk__content .img-holder:after {
        position: absolute;
        content: "";
        left: 280px;
        top: -50px;
        width: 208px;
        height: 208px;
        background-color: #FFA56D;
        display: none; }
      .o-office-desk__content .img-holder--m-even {
        text-align: center; }
        .o-office-desk__content .img-holder--m-even:after {
          left: 100px;
          top: -50px;
          background-color: #B8E986; }

.o-office-design {
  color: #fff;
  position: relative;
  overflow: hidden;
  background: url(/images/office-and-infrastructure/office-design-bg.jpg) 0% 50% no-repeat;
  background-size: cover;
  text-align: center; }
  @media (min-width: 992px) {
    .o-office-design {
      padding: 70px 0 80px; } }
  .o-office-design h2 {
    color: #fff;
    font-size: 30px;
    text-transform: capitalize; }
    @media (min-width: 992px) {
      .o-office-design h2 {
        font-size: 42px; } }
  .o-office-design p {
    font-size: 18px;
    line-height: 28px;
    color: #fff; }
  .o-office-design--m-infrastructure {
    background: url(/images/office-and-infrastructure/infrastructure-bg.jpg) 50% 50% no-repeat;
    background-size: cover; }
    .o-office-design--m-infrastructure .text-content {
      background-color: rgba(0, 0, 0, 0.3);
      border-bottom: 3px solid #B8E986;
      padding: 30px;
      margin-bottom: 40px;
      font-size: 18px;
      line-height: 28px; }
    @media (min-width: 992px) {
      .o-office-design--m-infrastructure h2 {
        font-size: 48px;
        line-height: 61px;
        margin-bottom: 35px; } }

.o-office-video {
  color: #fff;
  position: relative;
  padding: 40px 0;
  overflow: hidden;
  background: url(/images/office-and-infrastructure/video-bg.jpg) 50% 50% no-repeat;
  background-size: cover;
  text-align: center; }
  .o-office-video iframe {
    border: 10px solid #D6D6D6; }

.o-open-office {
  position: relative;
  border-bottom: 1px solid #dedede;
  padding: 50px 0 50px 0 !important;
  overflow: hidden;
  text-align: center;
  background-size: 40%; }
  @media (min-width: 992px) {
    .o-open-office {
      padding: 70px 0 50px 0 !important;
      border-top: 0; } }
  .o-open-office p {
    font-size: 24px;
    font-weight: 600; }
  .o-open-office h3 {
    text-align: center;
    position: relative;
    padding-top: 0;
    padding-bottom: 40px;
    text-align: left;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    line-height: 42px; }
    @media screen and (min-width: 1200px) {
      .o-open-office h3 {
        font-size: 44px;
        line-height: 62px; } }
    .o-open-office h3:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background-color: #333;
      width: 80px;
      margin-bottom: 0; }
  .o-open-office--description {
    position: relative;
    margin-bottom: 30px; }
    .o-open-office--description img {
      height: auto; }
    .o-open-office--description span {
      background-color: #fff;
      position: relative;
      border-bottom: 6px solid #B8E986;
      color: #333;
      display: inline-block;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      padding: 30px 20px;
      text-align: left;
      min-height: auto;
      font-family: "Montserrat", sans-serif;
      position: absolute;
      max-width: 230px;
      bottom: 50px; }
      @media (min-width: 992px) {
        .o-open-office--description span {
          font-size: 18px;
          line-height: 28px;
          font-family: "Montserrat", sans-serif; } }
      .o-open-office--description span.m-left {
        left: 12px; }
      .o-open-office--description span.m-right {
        left: 0; }
        @media (min-width: 992px) {
          .o-open-office--description span.m-right {
            right: 12px;
            left: inherit; } }
      .o-open-office--description span.m-left-top {
        left: 3px;
        bottom: 20px; }
        @media (min-width: 992px) {
          .o-open-office--description span.m-left-top {
            left: 0; } }
      .o-open-office--description span.m-right-bot {
        left: 3px;
        bottom: 20px; }
        @media (min-width: 992px) {
          .o-open-office--description span.m-right-bot {
            right: 0;
            left: inherit; } }
      .o-open-office--description span.org-border {
        border-bottom: 6px solid #FF8438; }
  .o-open-office .btn_wrapper {
    padding-top: 5px; }
    @media (min-width: 992px) {
      .o-open-office .btn_wrapper {
        padding-top: 40px; } }

.description-box-img {
  position: relative;
  height: 500px !important; }

.o-security {
  position: relative;
  padding: 50px 0 20px !important;
  overflow: hidden;
  text-align: center;
  background-size: 40%; }
  @media (min-width: 992px) {
    .o-security {
      padding: 70px 0 50px 0 !important; } }
  .o-security .security-sub-heading {
    font-size: 24px;
    font-weight: 600;
    color: #333; }
  .o-security h2 {
    font-size: 30px;
    line-height: 38px;
    color: #333;
    margin-bottom: 20px;
    text-transform: capitalize; }
    @media (min-width: 992px) {
      .o-security h2 {
        font-size: 48px;
        line-height: 61px;
        margin-bottom: 40px; } }
  .o-security--m-greybg {
    background-color: #FAFAFA;
    padding: 40px 0; }
    .o-security--m-greybg h2 {
      margin-bottom: 30px; }
    .o-security--m-greybg p {
      font-size: 18px;
      color: #333; }
  .o-security .security-holder {
    margin-bottom: 40px; }
    .o-security .security-holder:last-child {
      margin-bottom: 0; }
    .o-security .security-holder:after {
      clear: both;
      content: ""; }
    .o-security .security-holder .security-holder-img {
      width: 100%; }
      .o-security .security-holder .security-holder-img img {
        width: 100%; }
    .o-security .security-holder .security-holder-txt {
      width: 100%;
      position: relative;
      text-align: left; }
      .o-security .security-holder .security-holder-txt .white-bg {
        background-color: #fff;
        z-index: 1;
        position: relative;
        padding: 20px 0; }
        .o-security .security-holder .security-holder-txt .white-bg--m-greybg {
          background-color: #FAFAFA; }
        .o-security .security-holder .security-holder-txt .white-bg h3 {
          font-size: 20px;
          font-weight: 500;
          padding-bottom: 40px;
          line-height: 40px; }
        .o-security .security-holder .security-holder-txt .white-bg p {
          font-size: 18px;
          font-weight: 300; }
        .o-security .security-holder .security-holder-txt .white-bg ul li {
          line-height: 35px;
          padding-bottom: 5px;
          padding-left: 30px;
          background: url(/images/office-and-infrastructure/list-bullet.png) 0 9px no-repeat; }
      .o-security .security-holder .security-holder-txt.left-text-content:before {
        position: absolute;
        content: "";
        right: -50px;
        top: -50px;
        width: 208px;
        height: 208px;
        background-color: #B8E986;
        display: none; }
      .o-security .security-holder .security-holder-txt.right-text-content:before {
        position: absolute;
        content: "";
        left: -50px;
        top: -50px;
        width: 208px;
        height: 208px;
        background-color: #FFA56D;
        display: none; }
    .o-security .security-holder .m-left {
      float: left; }
    .o-security .security-holder .m-right {
      float: right; }

.o-photo-gallery {
  position: relative;
  padding: 40px 0;
  text-align: center;
  position: relative;
  background-color: #fff;
  /* heading */
  /* ~./heading */
  /* list */
  /* ~./list */
  /* The Modal (background) */
  /* Modal Content */
  /* The Close Button */
  /* Next & previous buttons */
  /* Position the "next button" to the right */
  /* On hover, add a black background color with a little bit see-through */
  /* Number text (1/3 etc) */ }
  .o-photo-gallery h2 {
    margin: 0 0 50px; }
  .o-photo-gallery__list {
    position: relative;
    margin-top: 18px; }
    .o-photo-gallery__list .slick-dots li {
      width: 10px;
      height: 10px; }
      .o-photo-gallery__list .slick-dots li.slick-active {
        width: 15px;
        height: 15px; }
    .o-photo-gallery__list button.slick-prev {
      position: absolute;
      left: -40px;
      top: 40%;
      width: 21px;
      height: 34px;
      text-indent: -10000px;
      border-radius: 50%;
      z-index: 100;
      background: url(/images/office-and-infrastructure/left-arrow.png) no-repeat 0 0; }
    .o-photo-gallery__list button.slick-next {
      position: absolute;
      right: -40px;
      top: 40%;
      width: 21px;
      height: 34px;
      text-indent: -10000px;
      border-radius: 50%;
      z-index: 100;
      background: url(/images/office-and-infrastructure/right-arrow.png) no-repeat 0 0; }
    .o-photo-gallery__list .single-slide {
      float: left;
      position: relative;
      margin: 0 10px; }
      .o-photo-gallery__list .single-slide .gal-img-holder {
        background-color: #fff;
        -webkit-box-shadow: 0px 0px 5px 0px #707070;
        -moz-box-shadow: 0px 0px 5px 0px #707070;
        box-shadow: 0px 0px 5px 0px #707070;
        position: relative;
        margin-bottom: 20px; }
        .o-photo-gallery__list .single-slide .gal-img-holder span {
          font-size: 15px;
          color: #333;
          font-weight: 600;
          padding: 15px;
          display: block;
          text-align: left; }
        .o-photo-gallery__list .single-slide .gal-img-holder i {
          position: absolute;
          right: 25px;
          top: 25px; }
  .o-photo-gallery .modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); }
  .o-photo-gallery .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 3px;
    width: 65%; }
  .o-photo-gallery .close {
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 35px;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    width: 30px;
    height: 30px; }
  .o-photo-gallery .close:hover,
  .o-photo-gallery .close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer; }
  .o-photo-gallery .mySlides {
    display: none; }
    .o-photo-gallery .mySlides img {
      width: 100%; }
  .o-photo-gallery .cursor {
    cursor: pointer; }
  .o-photo-gallery .prev {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: normal;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none; }
  .o-photo-gallery .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: normal;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none; }
  .o-photo-gallery .next {
    right: 0;
    border-radius: 3px 0 0 3px; }
  .o-photo-gallery .prev:hover,
  .o-photo-gallery .next:hover {
    background-color: rgba(0, 0, 0, 0.8); }
  .o-photo-gallery .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0; }
  .o-photo-gallery img {
    /*margin-bottom: -4px;*/ }
  .o-photo-gallery .caption-container {
    text-align: center;
    background-color: #fff;
    padding: 20px 20px 15px 20px;
    color: white; }
    .o-photo-gallery .caption-container #caption {
      text-align: left;
      padding: 10px 0 0 0;
      margin: 0;
      font-weight: 500;
      float: left; }
    .o-photo-gallery .caption-container a {
      display: none; }
  .o-photo-gallery .demo {
    opacity: 0.6; }
  .o-photo-gallery .active,
  .o-photo-gallery .demo:hover {
    opacity: 1; }
  .o-photo-gallery img.hover-shadow {
    transition: 0.3s; }
  .o-photo-gallery .hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }
  .o-photo-gallery #video-gallery .mySlides {
    display: block !important; }

.company-video {
  background: url(/images/office-and-infrastructure/video-bg2.jpg) no-repeat 0 0/cover !important; }
  @media (min-width: 992px) {
    .company-video {
      padding: 80px 0 75px; } }

@media (min-width: 992px) {
  .vw-gl {
    padding: 70px 0 80px 0 !important; } }

@media (min-width: 992px) {
  .vw-gl .btn_wrapper {
    padding-top: 10px; } }

.pond-rout {
  padding: 45px 0 20px !important; }
  @media (min-width: 992px) {
    .pond-rout {
      padding: 70px 0 50px 0 !important; } }

.here-details {
  border-top: 1px solid #dedede; }
  @media (min-width: 992px) {
    .here-details {
      border-top: 0; } }
  .here-details .btn_wrapper-mod {
    margin-bottom: 50px !important; }
    @media (min-width: 992px) {
      .here-details .btn_wrapper-mod {
        margin-bottom: 0 !important; } }

.m-right-mod {
  left: 12px !important; }
  @media (min-width: 992px) {
    .m-right-mod {
      right: 12px !important;
      left: inherit !important; } }

.split-section {
  padding-bottom: 20px;
  padding-top: 50px !important; }
  @media (min-width: 992px) {
    .split-section {
      padding-bottom: 0;
      padding-top: 80px !important; } }
  .split-section .cstm-link-p {
    text-align: center;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .split-section .cstm-link-p {
        margin-bottom: 25px; } }

.compliant {
  font-size: 14px;
  line-height: 19px; }

.secur {
  padding: 50px 0 20px !important; }
  @media (min-width: 992px) {
    .secur {
      padding: 75px 0 50px 0 !important; } }

.career-bnr {
  background: #091a38 !important;
  padding-top: 119px !important;
  padding-bottom: 50px !important; }
  @media (min-width: 992px) {
    .career-bnr {
      background: #fff !important;
      padding: 135px 20px 45px !important; } }
  .career-bnr .o-skills-hero__background {
    background-image: url(/images/common/career-bnr-img.jpg); }
  .career-bnr-icon {
    display: flex;
    align-items: center;
    justify-content: space-evenly; }
    .career-bnr-icon-img:nth-child(2) {
      flex: 0 0 140px;
      max-width: 140px; }
  .career-bnr .font-family-mot {
    font-weight: 700; }
  .career-bnr h1 {
    font-size: 48px;
    line-height: 66px; }
  .career-bnr h2 {
    font-size: 30px;
    line-height: 26px;
    font-weight: 700;
    padding: 0;
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .career-bnr h2 {
        margin-bottom: 40px;
        line-height: 36px; } }
  .career-bnr .bnr-tag-line {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin-top: 30px;
    margin-bottom: 0;
    display: block; }
    @media (min-width: 992px) {
      .career-bnr .bnr-tag-line {
        font-size: 24px; } }
    @media (min-width: 992px) {
      .career-bnr .bnr-tag-line {
        margin-bottom: 30px; } }
    .career-bnr .bnr-tag-line strong {
      font-weight: 600;
      color: #49C684; }
    .career-bnr .bnr-tag-line a {
      color: #fff; }
  .career-bnr .btn_wrapper {
    padding-top: 25px;
    width: 340px !important;
    margin-bottom: 30px; }
    .career-bnr .btn_wrapper .btn {
      max-width: 385px; }

.position {
  padding: 40px 0 50px;
  background: #F5F5F5; }
  @media (min-width: 992px) {
    .position {
      padding: 70px 0 80px; } }
  .position h2 {
    text-align: center; }
  .position h3 {
    text-align: center;
    position: relative;
    font-size: 18px;
    padding-bottom: 10px;
    margin-bottom: 25px;
    margin-top: 30px; }
    @media (min-width: 992px) {
      .position h3 {
        font-size: 30px; } }
    .position h3:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      width: 60px;
      height: 2px;
      background: #2DBE70; }
  .position .backend-wrap-con {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .position .backend-wrap-con-box {
      position: relative;
      box-shadow: 0 0px 20px -2px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      background: #fff;
      flex: 0 0 100%;
      padding: 19px 15px 19px 0;
      margin: 10px 20px 20px;
      height: 103px; }
      @media (min-width: 992px) {
        .position .backend-wrap-con-box {
          display: flex;
          align-items: center;
          flex: 0 0 29%; } }
      .position .backend-wrap-con-box-img {
        height: 64px;
        width: 64px;
        position: relative;
        left: -6px;
        float: left; }
        @media (min-width: 992px) {
          .position .backend-wrap-con-box-img {
            height: 80px;
            width: 80px;
            left: -10px; } }
      .position .backend-wrap-con-box-text {
        flex: 1;
        padding-left: 85px;
        margin-top: 5px; }
        @media (min-width: 992px) {
          .position .backend-wrap-con-box-text {
            padding-left: 15px;
            margin-top: 0; } }
        .position .backend-wrap-con-box-text h4 {
          font-size: 16px;
          font-weight: 500; }
          @media screen and (min-width: 992px) {
            .position .backend-wrap-con-box-text h4 {
              font-size: 17px; } }
          @media screen and (min-width: 1200px) {
            .position .backend-wrap-con-box-text h4 {
              font-size: 19px; } }
        .position .backend-wrap-con-box-text span {
          font-size: 15px;
          line-height: 28px;
          font-family: "Montserrat",sans-serif;
          color: #333; }
          @media screen and (min-width: 992px) {
            .position .backend-wrap-con-box-text span {
              font-size: 15px; } }
          @media screen and (min-width: 1200px) {
            .position .backend-wrap-con-box-text span {
              font-size: 18px; } }
  .position .dont-see-text {
    display: block;
    text-align: center;
    margin: 20px 0 20px;
    font-size: 20px;
    font-weight: 400;
    font-family: "Montserrat",sans-serif; }
    @media (min-width: 992px) {
      .position .dont-see-text {
        font-size: 30px;
        margin: 40px 0 30px;
        font-weight: 700; } }
  @media all and (max-width: 767px) {
    .position .btn-orange {
      font-size: 14px; } }

.key-section {
  padding: 40px 0 47px; }
  @media (min-width: 992px) {
    .key-section {
      padding: 70px 0 77px; } }
  .key-section h2 {
    text-align: center;
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      .key-section h2 {
        margin-bottom: 0; } }
  .key-section p {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    font-family: "Montserrat",sans-serif;
    padding: 15px 0 25px; }
  .key-section .key-box {
    display: flex;
    align-items: flex-start; }
    .key-section .key-box-img {
      width: 70px; }
    .key-section .key-box-text {
      padding-left: 25px; }
      .key-section .key-box-text h3 {
        font-size: 30px;
        line-height: 24px;
        color: #2DBE70;
        font-weight: 700; }
        @media (min-width: 992px) {
          .key-section .key-box-text h3 {
            font-size: 30px;
            line-height: 24px;
            margin-bottom: 10px; } }
      .key-section .key-box-text p {
        text-align: left;
        font-size: 18px;
        line-height: 25px;
        padding: 0 0 5px;
        font-family: "Montserrat",sans-serif;
        font-weight: 600; }
        @media (min-width: 992px) {
          .key-section .key-box-text p {
            padding: 0 0 25px; } }
  .key-section ul.slick-dots {
    margin-top: 0; }

.perks-section {
  padding: 40px 0;
  background: #f5f5f5; }
  @media (min-width: 992px) {
    .perks-section {
      padding: 70px 0 77px; } }
  .perks-section ul.slick-dots {
    margin-top: 0; }
    @media (min-width: 992px) {
      .perks-section ul.slick-dots {
        margin-top: 30px; } }
  .perks-section h2 {
    text-align: center;
    font-size: 30px;
    line-height: 36px;
    font-weight: 600; }
    @media (min-width: 992px) {
      .perks-section h2 {
        font-size: 48px;
        line-height: 61px; } }
  .perks-section p {
    text-align: center;
    max-width: 1024px;
    margin: 0 auto 30px;
    font-size: 16px !important;
    line-height: 24px !important;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .perks-section p {
        font-size: 20px !important;
        line-height: 30px !important; } }
  .perks-section-wrap {
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 25px auto 0; }
    @media (min-width: 992px) {
      .perks-section-wrap {
        margin: 40px -15px 50px; } }
    .perks-section-wrap-box {
      padding: 28px 15px 28px 20px;
      flex: 0 0 100%;
      margin: 15px 0;
      border: none;
      border-bottom: 1px solid #D8D8D8;
      border-radius: 8px;
      background: #fff;
      display: flex;
      align-items: center; }
      @media (min-width: 992px) {
        .perks-section-wrap-box {
          flex: 0 0 22.3%;
          margin: 15px 15px; } }
      .perks-section-wrap-box span {
        display: flex;
        justify-content: center;
        width: 52px; }
        @media (min-width: 992px) {
          .perks-section-wrap-box span {
            display: inline-block; } }
      .perks-section-wrap-box h5 {
        flex: 1;
        padding-left: 15px;
        font-size: 18px;
        line-height: 22px;
        text-align: left;
        margin-bottom: 0; }
        @media (min-width: 992px) {
          .perks-section-wrap-box h5 {
            margin-bottom: 10px; } }

.working h2 {
  font-size: 24px; }
  @media (min-width: 992px) {
    .working h2 {
      font-size: 48px;
      line-height: 61px; } }

.working p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 28px;
  padding: 0; }
  @media (min-width: 992px) {
    .working p {
      font-size: 20px;
      line-height: 32px; } }

.working .services-pan {
  border-radius: 8px; }

.process-section {
  padding: 40px 0 47px; }
  @media (min-width: 992px) {
    .process-section {
      padding: 70px 0 77px; } }
  .process-section h2 {
    text-align: center;
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      .process-section h2 {
        margin-bottom: 5px; } }
  .process-section p {
    text-align: center;
    max-width: 735px;
    margin: 0 auto 50px;
    font-size: 20px;
    line-height: 30px; }
  .process-section .process-wrap {
    justify-content: center;
    display: flex;
    flex-direction: row-reverse;
    width: 340px;
    margin: 0 auto;
    position: relative; }
    @media screen and (min-width: 1200px) {
      .process-section .process-wrap {
        flex-direction: inherit;
        width: 100%;
        margin: inherit; } }
    .process-section .process-wrap-box {
      flex: 0 0 199px;
      position: relative;
      margin-bottom: 25px;
      text-align: center;
      width: 199px;
      height: 199px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 25px; }
      .process-section .process-wrap-box:before {
        content: '';
        position: absolute;
        background: url(/images/career/path1.png) no-repeat 0 0;
        width: 199px;
        height: 100px;
        top: 0;
        left: 0; }
      .process-section .process-wrap-box-img {
        width: 153px;
        height: 153px;
        border-radius: 50%;
        border: 5px solid #EAEAEA;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        position: relative; }
      .process-section .process-wrap-box h5 {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        position: absolute;
        left: 0;
        right: 0;
        top: 55%;
        padding: 0 75px;
        line-height: 20px; }
      .process-section .process-wrap-box:nth-child(3) h5 {
        top: 50%; }
      .process-section .process-wrap-box:nth-child(2) h5 {
        padding: 0 80px; }
      .process-section .process-wrap-box:last-child:after {
        display: none; }
  .process-section .top-process-name {
    display: flex;
    flex-wrap: wrap;
    position: inherit;
    flex: 1;
    padding-left: 15px;
    top: 10px; }
    @media screen and (min-width: 1200px) {
      .process-section .top-process-name {
        position: absolute; } }
    .process-section .top-process-name-box {
      display: flex;
      flex: 0 0 100%;
      align-items: center; }
      @media screen and (min-width: 1200px) {
        .process-section .top-process-name-box {
          flex: 1;
          align-items: inherit; } }
      .process-section .top-process-name-box h4 {
        font-size: 24px;
        line-height: 20px;
        font-weight: 500;
        padding-right: 10px;
        align-self: center;
        border-right: 1px solid #E2E2E2;
        margin-bottom: 0; }
        @media screen and (min-width: 1200px) {
          .process-section .top-process-name-box h4 {
            align-self: baseline;
            font-size: 30px;
            line-height: 26px;
            margin-bottom: 10px; } }
      .process-section .top-process-name-box:nth-child(1) h4 {
        color: #36E079; }
      .process-section .top-process-name-box:nth-child(2) {
        top: -8px;
        position: relative; }
        .process-section .top-process-name-box:nth-child(2) h4 {
          color: #28C89A; }
        @media screen and (min-width: 1200px) {
          .process-section .top-process-name-box:nth-child(2) {
            top: 0;
            position: static; } }
      .process-section .top-process-name-box:nth-child(3) {
        position: relative;
        top: -26px; }
        .process-section .top-process-name-box:nth-child(3) h4 {
          color: #2BBDC3; }
        @media screen and (min-width: 1200px) {
          .process-section .top-process-name-box:nth-child(3) {
            top: auto;
            left: 24px; } }
      .process-section .top-process-name-box:nth-child(4) {
        position: relative;
        top: -54px; }
        .process-section .top-process-name-box:nth-child(4) h4 {
          color: #1B89AF; }
        @media screen and (min-width: 1200px) {
          .process-section .top-process-name-box:nth-child(4) {
            position: relative;
            top: 0; } }
      .process-section .top-process-name-box:nth-child(5) {
        position: relative;
        top: -73px; }
        .process-section .top-process-name-box:nth-child(5) h4 {
          color: #036497; }
        @media screen and (min-width: 1200px) {
          .process-section .top-process-name-box:nth-child(5) {
            position: relative;
            top: auto;
            flex: 0 0 213px; } }
      .process-section .top-process-name-box:nth-child(6) {
        position: relative;
        top: -76px; }
        .process-section .top-process-name-box:nth-child(6) h4 {
          color: #2C4D82; }
        @media screen and (min-width: 1200px) {
          .process-section .top-process-name-box:nth-child(6) {
            position: relative;
            top: 0; } }
      .process-section .top-process-name-box h5 {
        font-size: 17px;
        color: #333333;
        line-height: 23px;
        padding-left: 15px;
        margin-bottom: 0; }
        @media screen and (min-width: 1200px) {
          .process-section .top-process-name-box h5 {
            margin-bottom: 10px; } }
      .process-section .top-process-name-box:nth-child(2n+1) {
        position: relative;
        bottom: 0; }
        @media screen and (min-width: 1200px) {
          .process-section .top-process-name-box:nth-child(2n+1) {
            bottom: -245px; } }
  .process-section .process-img {
    padding: 0;
    margin-bottom: 75px; }
    @media screen and (min-width: 1200px) {
      .process-section .process-img {
        padding: 50px 0; } }

.working {
  padding: 40px 0 47px;
  background: linear-gradient(172deg, #038170 2%, #00517f 36%, #023e66 56%, #051b39 100%) !important; }
  @media (min-width: 992px) {
    .working {
      padding: 70px 0 77px;
      background: linear-gradient(135deg, #07B260 0%, #00517F 40%, #023E66 60%, #051B39 100%) !important; } }
  .working p {
    margin-top: 10px;
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .working p {
        margin-top: 30px;
        margin-bottom: 40px; } }
  .working .masonry {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .working .masonry h3 {
      font-size: 20px !important;
      margin-bottom: 0; }
      @media (min-width: 992px) {
        .working .masonry h3 {
          font-size: 24px !important;
          margin-bottom: 0; } }
    .working .masonry p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
      margin-top: 15px; }
      @media (min-width: 992px) {
        .working .masonry p {
          font-size: 19px;
          line-height: 32px; } }
    .working .masonry .brick {
      flex: 0 0 100%;
      padding-top: 30px !important; }
      @media (min-width: 992px) {
        .working .masonry .brick {
          flex: 0 0 31%;
          padding: 40px 25px 30px !important; } }
      .working .masonry .brick p {
        padding: 0; }
      .working .masonry .brick i {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 65px; }
        .working .masonry .brick i img {
          height: 100%;
          width: auto; }
  .working span.opportunity-text {
    display: block;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 0;
    font-size: 20px;
    color: #fff;
    font-family: "Montserrat",sans-serif; }
    @media (min-width: 992px) {
      .working span.opportunity-text {
        margin-top: 30px; } }
    @media (min-width: 992px) {
      .working span.opportunity-text {
        font-size: 30px; } }

.hq {
  padding: 40px 0;
  background: #f5f5f5; }
  @media (min-width: 992px) {
    .hq {
      padding: 70px 0 50px; } }
  .hq ul.slick-dots {
    margin-top: 30px; }
    .hq ul.slick-dots li {
      width: 10px;
      height: 10px;
      margin: 0 6px; }
      .hq ul.slick-dots li.slick-active {
        background: #49C684;
        width: 10px;
        height: 10px; }
  .hq h2 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 36px; }
    @media (min-width: 992px) {
      .hq h2 {
        margin-bottom: 40px;
        font-size: 48px;
        line-height: 61px; } }
  .hq p {
    text-align: center;
    max-width: 945px;
    margin: 0 auto 50px;
    font-size: 16px;
    line-height: 28px;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .hq p {
        font-size: 20px;
        line-height: 30px; } }
  .hq-slider-all-img {
    display: flex !important;
    align-items: flex-end;
    flex-wrap: wrap; }
    @media (min-width: 992px) {
      .hq-slider-all-img {
        flex-wrap: nowrap; } }
    .hq-slider-all-img-box {
      margin: 0 5px;
      flex: 0 0 100%; }
      @media (min-width: 992px) {
        .hq-slider-all-img-box {
          margin: 5px;
          flex: inherit; } }
      .hq-slider-all-img-box a {
        position: relative;
        display: block; }
        .hq-slider-all-img-box a:before {
          content: '';
          position: absolute;
          background: url(/images/career/view-icon.png) no-repeat center center;
          width: 35px;
          height: 35px;
          top: 25px;
          right: 20px; }
      .hq-slider-all-img-box img {
        margin: 5px 0; }
    .hq-slider-all-img img {
      width: 100%; }

.any-q {
  display: block;
  text-align: center;
  font-size: 20px;
  font-family: "Montserrat",sans-serif; }
  @media (min-width: 992px) {
    .any-q {
      font-size: 30px; } }

.any-q-a {
  font-size: 16px;
  margin-top: 15px;
  font-family: "Montserrat",sans-serif;
  text-align: center; }
  .any-q-a a {
    color: #4A90E2; }

ul.slick-dots {
  margin-top: 30px; }
  ul.slick-dots li {
    width: 7px;
    height: 7px;
    margin: 0 5px;
    cursor: pointer; }
    ul.slick-dots li.slick-active {
      background: #49C684;
      width: 10px;
      height: 10px; }

.display-none-f {
  display: none !important; }
  @media (min-width: 992px) {
    .display-none-f {
      display: block !important; } }

.newest-nav {
  background: #fff !important; }

.request-quote {
  background-color: transparent !important;
  font-size: 15px !important;
  border: 2px solid #FF5800 !important;
  color: #333 !important;
  font-weight: 400 !important;
  text-transform: capitalize !important; }
  @media (min-width: 992px) {
    .request-quote {
      font-size: 15px !important; } }
  @media (max-width: 768px) {
    .request-quote {
      font-size: 14px !important; } }

.hire-btn {
  background-color: #FF5800 !important;
  font-size: 15px !important;
  border: 2px solid #FF5800 !important;
  color: #fff !important;
  font-weight: 400 !important;
  width: 125px;
  padding: 10px 20px;
  border-radius: 5px;
  margin-left: 5px; }
  @media all and (max-width: 767px) {
    .hire-btn {
      display: none; } }

.new-nav .o-navigation-bar #primaryNav li .dropdown-menu li a span {
  color: #fff !important; }

.o-navigation-bar-new #primaryNav li.has-submenu > a, .o-navigation-bar-new #primaryNav li.has-submenu > span {
  background: url(/images/common/nav-down-arrow.svg) 100% 40% no-repeat !important; }

.job-left h2.subheading, .job-left h1.subheading {
  color: #fff;
  padding-top: 20px;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;
  text-align: left; }
  @media screen and (min-width: 992px) {
    .job-left h2.subheading, .job-left h1.subheading {
      font-size: 28px;
      line-height: 40px; } }
  @media screen and (min-width: 1200px) {
    .job-left h2.subheading, .job-left h1.subheading {
      font-size: 36px;
      line-height: 50px; } }

.job-left h2.subheading span, .job-left h1.subheading span {
  color: #27C070;
  font-size: 24px;
  line-height: 32px;
  display: inline-block; }
  @media screen and (min-width: 992px) {
    .job-left h2.subheading span, .job-left h1.subheading span {
      display: inline-block;
      font-size: 36px;
      line-height: 50px; } }

.job-right {
  margin-top: 90px;
  position: relative;
  padding-bottom: 30px; }

.job-right .o-contact-form {
  box-shadow: -1px 1px 9px 0px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: -1px 1px 9px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: -1px 1px 9px 0px rgba(0, 0, 0, 0.18); }

.job-bnr {
  position: absolute !important;
  height: 460px !important; }
  @media screen and (min-width: 992px) {
    .job-bnr {
      height: 520px !important; } }
  @media screen and (min-width: 1200px) {
    .job-bnr {
      height: 460px !important; } }

.from-icon {
  position: absolute;
  left: 0;
  top: -110px;
  z-index: 1; }

.job-left span.bnr-tag-line {
  font-size: 16px !important;
  text-decoration: none !important;
  color: #fff !important;
  font-weight: bold;
  margin-top: 10px !important; }

.job-left span.bnr-tag-line strong {
  color: #27C070;
  font-weight: bold; }

.job-left h3 {
  font-size: 24px;
  padding-bottom: 20px;
  color: #fff; }
  @media (min-width: 992px) {
    .job-left h3 {
      color: #333; } }

.job-left ul li {
  padding-left: 35px;
  padding-bottom: 15px;
  font-size: 16px;
  line-height: 25px;
  position: relative;
  font-family: "Montserrat", sans-serif !important;
  color: #fff; }
  @media (min-width: 992px) {
    .job-left ul li {
      color: #333;
      font-size: 18px;
      line-height: 32px; } }

.job-left ul li:before {
  content: '';
  background-image: url(/images/common/green-arrow.png);
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  top: 3px;
  background-size: 71%;
  background-repeat: no-repeat; }

.job-left h2 {
  color: #fff;
  text-align: left; }
  @media (min-width: 992px) {
    .job-left h2 {
      padding-top: 80px;
      color: #333; } }

.job-left p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: normal;
  line-height: 25px;
  text-align: left; }
  @media (min-width: 992px) {
    .job-left p {
      color: #333;
      font-size: 18px;
      line-height: 32px; } }

.job-left-top {
  border-bottom: 1px solid #27C070;
  padding-bottom: 25px;
  margin-bottom: 25px;
  margin-top: 25px; }
  .job-left-top h2 {
    font-size: 24px; }
    @media screen and (min-width: 992px) {
      .job-left-top h2 {
        font-size: 30px; } }
  @media screen and (min-width: 992px) {
    .job-left-top {
      padding-bottom: 40px;
      margin-bottom: 40px;
      margin-top: 40px; } }

.job-right .o-contact-form h2 {
  font-size: 28px;
  text-transform: uppercase;
  color: #333;
  letter-spacing: 2.5px;
  text-shadow: 1px 1px 0px #333, -1px 1px 0px #333, 1px -1px 0px #333, -1px -1px 0px #333;
  text-align: center;
  padding: 0;
  line-height: 52px; }
  @media screen and (min-width: 992px) {
    .job-right .o-contact-form h2 {
      line-height: 30px;
      font-size: 40px; } }
  @media screen and (min-width: 1200px) {
    .job-right .o-contact-form h2 {
      line-height: 36px;
      font-size: 42px; } }

.job-right h2 span {
  background-color: #27C070;
  border-radius: 20px;
  color: #fff;
  padding: 0 20px; }

.job-right h4 {
  font-size: 18px;
  line-height: 28px;
  padding: 0;
  font-weight: 600;
  text-align: center; }
  @media (min-width: 992px) {
    .job-right h4 {
      padding: 0 50px; } }

.job-right h5 {
  font-size: 16px !important;
  line-height: 28px !important;
  padding: 20px 50px;
  font-weight: 600 !important;
  text-align: left;
  text-transform: uppercase;
  font-weight: 600;
  padding: 20px 20px 0 20px; }

.job-right input {
  width: 90%;
  margin: 0 20px 0 20px;
  border: 0;
  border-bottom: 1px solid #000 !important;
  padding: 30px 30px 30px 40px;
  font-size: 16px;
  color: #878787;
  font-weight: 600; }

.job-right input.input1 {
  background: url(/images/career/job-icon-1.png) 0 50% no-repeat !important; }

.job-right input.input2 {
  background: url(/images/career/job-icon-2.png) 0 50% no-repeat !important; }

.job-right input.input3 {
  background: url(/images/career/job-icon-3.png) 0 50% no-repeat !important; }

.job-right input.input4 {
  background: url(/images/career/job-icon-4.png) 0 50% no-repeat !important; }

.job-right .input5 {
  background: url(/images/career/job-icon-5.png) 0 50% no-repeat !important;
  width: 90%;
  margin: 0 20px 0 20px;
  border-bottom: 1px solid #ccc !important;
  padding: 30px 30px 30px 40px;
  font-size: 16px;
  color: #878787;
  font-weight: 600;
  display: inline-block; }
  .job-right .input5 select {
    width: 100%;
    border: 0; }
  @media (min-width: 992px) {
    .job-right .input5 {
      width: 38%; } }

.job-right .input6 {
  background: url(/images/career/job-icon-6.png) 0 50% no-repeat !important;
  width: 90%;
  margin: 0 20px 0 20px;
  border-bottom: 1px solid #ccc !important;
  padding: 30px 30px 30px 40px;
  font-size: 16px;
  color: #878787;
  font-weight: 600;
  display: inline-block; }
  .job-right .input6 select {
    width: 100%;
    border: 0; }
  @media (min-width: 992px) {
    .job-right .input6 {
      width: 38%; } }

.job-right input.input7 {
  background: url(/images/career/job-icon-7.png) 0 50% no-repeat !important; }

.job-right input.input8 {
  background: url(/images/career/job-icon-8.png) 0 50% no-repeat !important; }

.job-right input.input9 {
  background: url(/images/career/job-icon-9.png) 0 50% no-repeat !important; }

.job-right input.input10 {
  background: url(/images/career/job-icon-10.png) 0 50% no-repeat !important; }

.job-right .job-description-holder__attachement-text {
  text-align: left; }

.job-right label.attachement-button {
  border: 1px solid #ccc;
  padding: 12px 25px 12px 40px;
  border-radius: 7px;
  margin: 40px 10px 10px 40px;
  text-align: left;
  background: url(/images/career/job-icon-11.png) 10px 50% no-repeat !important; }

.job-right .job-description-holder__attachement-text span {
  font-size: 15px;
  padding-left: 40px; }

.o-job-form {
  overflow: inherit; }

.job-right button {
  display: block;
  text-align: center;
  border-radius: 5px;
  padding: 19px 0px;
  font-size: 18px;
  text-transform: uppercase;
  max-width: 100%;
  text-decoration: none;
  position: relative;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  color: #fff;
  border: 2px solid #FF5800;
  background-color: #FF5800;
  margin: 30px auto 40px;
  width: 100%; }
  @media (min-width: 992px) {
    .job-right button {
      width: 300px;
      max-width: 350px; } }

.job-right button:after {
  content: '';
  background: url(/images/common/arrow-white.png) no-repeat;
  width: 20px;
  height: 14px;
  display: inline-block;
  margin-left: 15px;
  z-index: -1; }

.job-bottom {
  padding: 50px 0; }
  @media (min-width: 992px) {
    .job-bottom {
      padding: 0 0 60px; } }

.job-bottom h3 {
  font-size: 24px;
  padding-bottom: 20px;
  padding-left: 10px;
  width: 100%; }

.job-bottom ul li {
  padding-left: 35px;
  padding-bottom: 15px;
  font-size: 16px;
  line-height: 25px;
  position: relative;
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .job-bottom ul li {
      font-size: 18px;
      line-height: 32px; } }

.job-bottom ul li:before {
  content: '';
  background-image: url(/images/common/green-arrow.png);
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  top: 3px;
  background-size: 71%;
  background-repeat: no-repeat; }

.Careers .position .btn_wrapper {
  width: 350px; }

.Careers .anchor-link {
  margin: 10px auto 0; }

.Careers .small-h1 h1 {
  margin: 30px 0 30px; }
  @media (min-width: 992px) {
    .Careers .small-h1 h1 {
      padding: 0;
      font-size: 55px;
      line-height: 70px;
      margin: 40px 0 40px; } }

@media (min-width: 992px) {
  .Careers .small-h1 h2 {
    margin-bottom: 40px;
    font-size: 30px; } }

.Careers .faq-section {
  padding: 15px 0 50px !important; }
  @media (min-width: 992px) {
    .Careers .faq-section {
      padding: 65px 0 75px !important; } }

.Careers .o-footer {
  padding: 0 0 40px; }

.Careers ul.skill-page-mod li:nth-child(3) {
  flex: 1; }

.job-heading-pan {
  background-color: #091a38;
  padding: 20px; }

.Job-Application .cn-form {
  padding: 80px 20px 20px 20px;
  position: relative;
  background: #fff; }

.job-bnr-icon-img {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px; }

.career-form {
  background: url(/images/career/form-bg.jpg) no-repeat center center;
  padding: 50px 0 !important; }
  @media (min-width: 992px) {
    .career-form {
      padding: 80px 0 !important; } }
  .career-form h2 {
    margin-bottom: 10px; }
  .career-form p {
    font-size: 17px;
    line-height: 28px;
    font-weight: 400;
    font-family: "Montserrat",sans-serif; }
    @media (min-width: 992px) {
      .career-form p {
        font-size: 20px;
        line-height: 30px; } }
  .career-form .form-text {
    position: relative;
    width: 100%;
    float: none;
    padding: 50px 60px;
    display: none; }
    @media (min-width: 992px) {
      .career-form .form-text {
        display: block; } }
    .career-form .form-text .form-bot-text {
      margin-top: 0;
      padding-top: 0;
      border-top: 0; }
  .career-form .form-bot-text .font-family-mot {
    font-size: 24px;
    font-weight: 500; }
  .career-form .form-bot-text .font-family-pro {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 15px; }
  .career-form .notice-period {
    background: url(/images/career/form-icon6.png) no-repeat center left; }
  .career-form .position-icon {
    background: url(/images/career/form-icon9.png) no-repeat center left; }
  .career-form .u-submit {
    padding: 15px 0px; }
  .career-form .form-panel {
    padding: 0;
    width: 100%;
    float: none; }
    .career-form .form-panel h4 {
      position: relative;
      font-size: 28px !important;
      margin-bottom: 15px; }
      @media (min-width: 992px) {
        .career-form .form-panel h4 {
          font-size: 36px !important; } }
      .career-form .form-panel h4:before {
        content: '';
        position: absolute;
        left: 68px;
        top: -2px;
        width: 100px;
        background: url(/images/career/shape.png) no-repeat 0 0;
        height: 55px;
        background-size: 80%; }
        @media (min-width: 992px) {
          .career-form .form-panel h4:before {
            left: 91px;
            top: 0;
            width: 120px; } }
      .career-form .form-panel h4 span {
        z-index: 99;
        position: relative;
        color: #fff; }
    .career-form .form-panel form {
      padding: 53px 15px !important;
      position: relative; }
      @media (min-width: 992px) {
        .career-form .form-panel form {
          padding: 53px 60px !important; } }
      .career-form .form-panel form:before {
        content: '';
        height: 5px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0; }
      .career-form .form-panel form:after {
        content: '';
        height: 5px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0; }
  .career-form .am-1 {
    background: url(/images/career/hr-img1.png) 50% 0 no-repeat !important; }
  .career-form .am-2 {
    background: url(/images/career/hr-img3.png) 50% 0 no-repeat !important; }
  .career-form .am-3 {
    background: url(/images/career/hr-img2.png) 50% 0 no-repeat !important; }
  .career-form .am-4 {
    background: url(/images/career/hr-img5.png) 50% 0 no-repeat !important; }
  .career-form .am-5 {
    background: url(/images/career/hr-img4.png) 50% 0 no-repeat !important; }
  .career-form .am-6 {
    background: url(/images/career/hr-img6.png) 50% 0 no-repeat !important; }
  .career-form ul li {
    padding-top: 63px !important; }
    .career-form ul li span {
      font-size: 13px; }
  .career-form .extra-wrap {
    max-width: 574px;
    margin: 0 auto; }

.mobile-off {
  display: none; }
  @media (min-width: 992px) {
    .mobile-off {
      display: block; } }

.mobile-on {
  display: block; }
  @media (min-width: 992px) {
    .mobile-on {
      display: none; } }

.o-contact-form--white-bg {
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); }

.form-control {
  border: 0 !important;
  display: inline-block !important;
  background: transparent !important;
  box-shadow: none !important;
  height: 34px; }

.slick-dots {
  margin-bottom: 30px;
  text-align: center; }

.slick-dots li {
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-indent: -10000px;
  width: 5px;
  height: 5px;
  background-color: #9B9B9B;
  margin: 0 10px;
  vertical-align: middle; }

.quickform .o-contact-form--white-bg {
  box-shadow: none; }
  @media (min-width: 992px) {
    .quickform .o-contact-form--white-bg {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); } }

@media screen and (min-width: 992px) {
  .career-bnr ul.skill-page-mod li:nth-child(4) {
    flex: 0 0 120px; } }

.career-bnr ul.skill-page-mod {
  display: none; }
  @media screen and (min-width: 992px) {
    .career-bnr ul.skill-page-mod {
      display: flex; } }

.p-skills-icon {
  background: url(/images/career/form-icon9.png) no-repeat center left;
  width: 85%;
  margin: 0 auto;
  border-bottom: 1px solid #000 !important;
  min-height: 64px; }

.s-skills-icon {
  background: url(/images/career/form-icon9.png) no-repeat center left;
  width: 85%;
  margin: 0 auto;
  border-bottom: 1px solid #000 !important;
  min-height: 64px; }

#primary-skills,
#secondary-skills {
  height: 0px;
  padding: 20px 0 0 40px;
  overflow: hidden; }

.select2-selection--multiple {
  border: 0 !important;
  border-radius: 0 !important;
  padding: 20px 20px 20px 0 !important;
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif !important; }

.select2-container .select2-search--inline .select2-search__field {
  font-family: "Montserrat", sans-serif !important; }

.select2-results__option {
  font-family: "Montserrat", sans-serif !important; }

.job-inner-right .select2-container {
  width: 85% !important;
  text-align: left; }

.job-inner-right input {
  width: 85%; }

.job-inner-right .select2-selection--multiple {
  border-bottom: 0 !important; }

.job-inner-right .select2-selection__choice:after {
  display: none; }

.job-inner-right .notice-period {
  background: url(/images/career/form-icon6.png) no-repeat center left; }

.job-inner-right .position-icon {
  background: url(/images/career/form-icon9.png) no-repeat center left; }

input.years-of-experience {
  background: url(/images/career/job-icon-5.png) 0 50% no-repeat !important; }

.job-inner-right .attach {
  background: url(/images/common/form-icon-6.png) 40px 20px no-repeat;
  font-family: "Montserrat",sans-serif;
  padding: 20px 50px 20px 84px;
  text-align: left; }

.job-inner-right #attachedFiles li input {
  width: 80% !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important; }

.job-inner-right #attachedFiles li button {
  display: inline-block !important;
  border: 0 !important;
  margin: 0 10px 0 0 !important;
  padding: 0 !important; }

.job-inner-right #attachedFiles li button:after {
  display: none; }

.job-inner-right .modal .close {
  width: auto !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important; }

.job-inner-right .modal p {
  color: #000 !important;
  text-align: left !important; }

.job-inner-right .modal input {
  border-bottom: 0 !important;
  padding: 0 !important;
  margin: 0 !important; }

.job-inner-right .select2-selection__choice__remove {
  width: auto !important;
  margin: 0; }

.job-inner-right .select2-selection__choice__remove:after {
  display: none; }

[data-tip] {
  position: relative; }

[data-tip]:before {
  content: '';
  /* hides the tooltip when not hovered */
  display: none;
  content: '';
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #1a1a1a;
  position: absolute;
  top: 50px;
  left: 105px;
  z-index: 8;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0; }

[data-tip]:after {
  display: none;
  content: attr(data-tip);
  position: absolute;
  top: 55px;
  left: 40px;
  padding: 15px 20px;
  background: #1a1a1a;
  color: #fff;
  z-index: 9;
  font-size: 1em;
  height: 60px;
  line-height: 18px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  /*white-space:nowrap;
	word-wrap:normal;*/
  width: 85%; }

[data-tip]:hover:before,
[data-tip]:hover:after {
  display: block; }

#django-o-contact-form .alert-danger {
  margin: 0 40px; }

.career-form .select2-container .select2-search--inline .select2-search__field {
  height: 20px; }

.select2-container .select2-search--inline .select2-search__field {
  height: 20px; }

/*Widget css*/
.career-widget-section #freshteam-widget {
  background: none;
  min-height: 170px; }
  .career-widget-section #freshteam-widget .content {
    width: 100%;
    position: relative; }
  .career-widget-section #freshteam-widget .advanced-search {
    background: none;
    padding: 0 0 20px; }
    .career-widget-section #freshteam-widget .advanced-search .search-fields {
      background: #fff;
      padding: 30px 30px 0;
      border: 1px solid #ccc;
      border-bottom: 0;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      margin-bottom: 0 !important; }
      .career-widget-section #freshteam-widget .advanced-search .search-fields .toggle-and-clear {
        flex: 0 0 100%; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .advanced-search .search-fields .toggle-and-clear {
            flex: 0 0 195px; } }
        .career-widget-section #freshteam-widget .advanced-search .search-fields .toggle-and-clear .clear-button {
          flex: 1;
          border-left: 0;
          position: relative; }
          .career-widget-section #freshteam-widget .advanced-search .search-fields .toggle-and-clear .clear-button:before {
            content: '';
            position: absolute;
            background: #ccc;
            height: 25px;
            width: 1px;
            top: 2px;
            left: 6px; }
          .career-widget-section #freshteam-widget .advanced-search .search-fields .toggle-and-clear .clear-button button {
            text-align: left; }
            @media screen and (min-width: 992px) {
              .career-widget-section #freshteam-widget .advanced-search .search-fields .toggle-and-clear .clear-button button {
                text-align: center; } }
    .career-widget-section #freshteam-widget .advanced-search .input-field {
      background: #fff;
      padding: 0 30px 30px;
      border: 1px solid #ccc;
      border-top: 0;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px; }
      .career-widget-section #freshteam-widget .advanced-search .input-field input::placeholder {
        opacity: 1;
        color: #333; }
    .career-widget-section #freshteam-widget .advanced-search h3 {
      font-size: 30px;
      text-align: center;
      font-weight: 600;
      font-family: Montserrat,sans-serif;
      width: 100%;
      margin-bottom: 25px !important; }
      @media screen and (min-width: 720px) {
        .career-widget-section #freshteam-widget .advanced-search h3 {
          margin-bottom: 25px; } }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .advanced-search h3 {
          font-size: 48px;
          line-height: 61px;
          margin-bottom: 50px; } }
      .career-widget-section #freshteam-widget .advanced-search h3:before {
        display: none; }
    .career-widget-section #freshteam-widget .advanced-search .select2-container--default {
      height: 50px;
      font-family: Montserrat,sans-serif; }
    .career-widget-section #freshteam-widget .advanced-search .input-field .form-control {
      height: 50px;
      border-bottom: 1px solid #ccc !important;
      border-radius: 0;
      font-family: Montserrat,sans-serif;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0;
      margin-top: 10px; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .advanced-search .input-field .form-control {
          font-size: 18px; } }
    .career-widget-section #freshteam-widget .advanced-search .input-field svg {
      left: 30px;
      width: 18px;
      height: 18px;
      top: 25px; }
    .career-widget-section #freshteam-widget .advanced-search .select2-container--default .select2-selection--multiple::before {
      margin-top: 0px; }
    .career-widget-section #freshteam-widget .advanced-search .select2-container--default .select2-selection--multiple .select2-selection__choice {
      margin-top: 2px;
      padding-left: 0;
      margin-left: 0; }
    .career-widget-section #freshteam-widget .advanced-search .select2-container--default .select2-selection--multiple .select2-selection__clear {
      padding-right: 15px; }
    .career-widget-section #freshteam-widget .advanced-search .title-and-clear {
      display: block; }
      @media screen and (min-width: 720px) {
        .career-widget-section #freshteam-widget .advanced-search .title-and-clear {
          display: flex; } }
  .career-widget-section #freshteam-widget .job-role-list .role-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    @media screen and (min-width: 720px) {
      .career-widget-section #freshteam-widget .job-role-list .role-title {
        margin-bottom: 30px; } }
    .career-widget-section #freshteam-widget .job-role-list .role-title h5 {
      flex: 1;
      text-align: left;
      position: relative;
      font-size: 22px;
      color: #333;
      font-weight: 600;
      font-family: Montserrat,sans-serif;
      margin-bottom: 0; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .job-role-list .role-title h5 {
          font-size: 30px;
          padding-bottom: 10px;
          margin-bottom: 0;
          margin-top: 5px; } }
      .career-widget-section #freshteam-widget .job-role-list .role-title h5:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 60px;
        height: 2px;
        background: #2dbe70;
        display: none; }
        @media screen and (min-width: 720px) {
          .career-widget-section #freshteam-widget .job-role-list .role-title h5:before {
            display: block; } }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .job-role-list .role-title h5:before {
            bottom: 0; } }
  .career-widget-section #freshteam-widget .job-role-list .role-count {
    margin-right: 0;
    align-self: center;
    background: none;
    color: #4b8fe2;
    font-weight: 600;
    border-radius: 5px;
    font-size: 15px;
    font-family: Montserrat,sans-serif; }
    @media screen and (min-width: 992px) {
      .career-widget-section #freshteam-widget .job-role-list .role-count {
        font-size: 16px; } }
  .career-widget-section #freshteam-widget .job-role-list .job-list .job-title {
    font-size: 20px;
    font-weight: 500;
    font-family: Montserrat,sans-serif; }
    @media screen and (min-width: 992px) {
      .career-widget-section #freshteam-widget .job-role-list .job-list .job-title {
        font-size: 24px; } }
  .career-widget-section #freshteam-widget .job-role-list .job-list .job-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: Montserrat,sans-serif; }
  .career-widget-section #freshteam-widget .job-role-list .job-list .location-info {
    font-family: Montserrat,sans-serif;
    font-size: 15px; }
    @media screen and (min-width: 992px) {
      .career-widget-section #freshteam-widget .job-role-list .job-list .location-info {
        font-size: 18px; } }
    .career-widget-section #freshteam-widget .job-role-list .job-list .location-info br {
      display: inline-block; }
    .career-widget-section #freshteam-widget .job-role-list .job-list .location-info .location-icon {
      color: #07B260; }
  .career-widget-section #freshteam-widget .job-role-list .job-list .job-location {
    display: flex;
    align-items: center;
    text-align: left; }
    @media screen and (min-width: 992px) {
      .career-widget-section #freshteam-widget .job-role-list .job-list .job-location {
        display: block;
        text-align: right;
        flex: 1; } }
  .career-widget-section #freshteam-widget .job-role-list .job-list li {
    transition: .3s all ease-in; }
  .career-widget-section #freshteam-widget .job-role-list .mobile-role-count {
    color: #4b8fe2; }
  .career-widget-section #freshteam-widget .job-role-list .no-jobs-found .not-found-title {
    font-family: Montserrat,sans-serif; }
  .career-widget-section #freshteam-widget .job-role-list .no-jobs-found p {
    font-family: Montserrat,sans-serif;
    font-size: 16px; }
    @media screen and (min-width: 992px) {
      .career-widget-section #freshteam-widget .job-role-list .no-jobs-found p {
        font-size: 18px; } }
  .career-widget-section #freshteam-widget .job-details-header {
    background: linear-gradient(138deg, #165b5e 0, #0f3a4a 17%, #091b38 50%);
    padding: 30px 15px; }
    @media screen and (min-width: 992px) {
      .career-widget-section #freshteam-widget .job-details-header {
        padding: 30px; } }
    .career-widget-section #freshteam-widget .job-details-header .col-xs-4 {
      text-align: left; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .job-details-header .col-xs-4 {
          text-align: right;
          flex: 1; } }
    .career-widget-section #freshteam-widget .job-details-header h1 {
      font-family: Montserrat,sans-serif;
      text-align: left !important; }
    .career-widget-section #freshteam-widget .job-details-header .btn-primary {
      background: #ff5800;
      color: #fff;
      font-weight: 500;
      border: 1px solid #ff5800;
      margin: 0 auto !important;
      width: auto;
      display: block; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .job-details-header .btn-primary {
          width: auto;
          margin: 0 !important;
          display: inline-block; } }
    .career-widget-section #freshteam-widget .job-details-header .text-color {
      display: flex;
      margin-top: 20px;
      font-family: Montserrat,sans-serif;
      margin-bottom: 20px; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .job-details-header .text-color {
          margin-bottom: 0; } }
      .career-widget-section #freshteam-widget .job-details-header .text-color div {
        margin-left: 20px; }
  .career-widget-section #freshteam-widget #freshteam-jobs-back {
    display: flex;
    align-items: center;
    margin-bottom: 15px; }
    @media screen and (min-width: 720px) {
      .career-widget-section #freshteam-widget #freshteam-jobs-back {
        margin-bottom: 20px; } }
    .career-widget-section #freshteam-widget #freshteam-jobs-back:hover {
      color: #2dbe70; }
      .career-widget-section #freshteam-widget #freshteam-jobs-back:hover svg {
        fill: #2dbe70; }
  .career-widget-section #freshteam-widget .icon-arrow-left {
    width: 15px;
    margin-right: 10px; }
  .career-widget-section #freshteam-widget .job-details-content {
    margin-top: 5px; }
    @media screen and (min-width: 992px) {
      .career-widget-section #freshteam-widget .job-details-content {
        margin-top: 25px; } }
    .career-widget-section #freshteam-widget .job-details-content p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 25px;
      font-family: Montserrat,sans-serif; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .job-details-content p {
          font-size: 18px;
          line-height: 27px; } }
      .career-widget-section #freshteam-widget .job-details-content p br {
        display: none; }
      .career-widget-section #freshteam-widget .job-details-content p strong {
        font-size: 20px;
        margin-bottom: 25px;
        display: block;
        margin-top: 20px;
        font-weight: 600;
        font-family: Montserrat,sans-serif; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .job-details-content p strong {
            font-size: 24px; } }
    .career-widget-section #freshteam-widget .job-details-content div {
      font-family: Montserrat,sans-serif;
      margin-bottom: 25px; }
      .career-widget-section #freshteam-widget .job-details-content div br {
        display: none; }
      .career-widget-section #freshteam-widget .job-details-content div strong {
        font-size: 20px;
        margin-bottom: 25px;
        display: block;
        margin-top: 20px;
        font-weight: 600;
        display: inline; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .job-details-content div strong {
            font-size: 24px; } }
    .career-widget-section #freshteam-widget .job-details-content ul li {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 15px;
      padding-left: 30px;
      position: relative;
      font-family: Montserrat,sans-serif; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .job-details-content ul li {
          font-size: 18px;
          line-height: 27px; } }
      .career-widget-section #freshteam-widget .job-details-content ul li:before {
        content: '';
        position: absolute;
        background: url(/images/common/green-arrow.png) no-repeat 0 0/contain;
        width: 20px;
        height: 20px;
        left: 0;
        top: 4px; }
  .career-widget-section #freshteam-widget .select2-container--default .select2-selection--multiple {
    border: 1px solid #ccc !important;
    border-radius: 8px !important;
    padding-left: 12px !important;
    background: #f7f7f7; }
  .career-widget-section #freshteam-widget .col-xs-8 {
    width: 100%; }
    @media screen and (min-width: 992px) {
      .career-widget-section #freshteam-widget .col-xs-8 {
        width: 66.66667%; } }

.career-widget-section .select2-selection--multiple {
  padding: 8px 20px 0px 0 !important; }

.career-widget-section .remote-toggle span {
  font-family: Montserrat,sans-serif;
  font-size: 15px; }

.career-widget-section #job-applicant-form .application-form {
  margin: 25px auto;
  background-color: #fff;
  padding: 25px; }

p.any-q-a {
  margin: 15px auto 25px; }

#freshteam-widget .search-fields .clear-button > button {
  color: #ff5800 !important;
  padding: 0; }

#freshteam-widget .job-list .job-location .location-icon {
  color: #4b8fe2 !important;
  flex: 1;
  text-align: right; }
  @media screen and (min-width: 720px) {
    #freshteam-widget .job-list .job-location .location-icon {
      display: inherit;
      align-items: inherit;
      flex: inherit;
      text-align: inherit; } }

#freshteam-widget .icon-arrow-right {
  fill: #4b8fe2 !important; }

#freshteam-widget .job-list > li:hover {
  background-color: #e5fff1 !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15); }

#freshteam-widget .clear-button-mobile {
  color: #ff5800 !important;
  position: absolute;
  z-index: 9999;
  bottom: 0;
  left: 140px;
  width: auto; }
  #freshteam-widget .clear-button-mobile:before {
    content: '';
    position: absolute;
    background: #ccc;
    height: 25px;
    width: 1px;
    top: 0;
    left: -4px; }
  @media screen and (min-width: 720px) {
    #freshteam-widget .clear-button-mobile {
      width: inherit; } }

#freshteam-widget .select2-container--default:not(.form-control-highlight) .select2-search__field {
  padding-left: 0 !important; }

#freshteam-widget .select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 3px 0 !important; }

#freshteam-widget #ft-input-close-icon.close-icon {
  width: auto;
  right: 35px !important;
  top: 18px; }

.career-widget-section #freshteam-widget {
  font-family: Montserrat,sans-serif !important; }
  .career-widget-section #freshteam-widget .application-form {
    padding: 5px 15px 25px; }
    @media screen and (min-width: 992px) {
      .career-widget-section #freshteam-widget .application-form {
        max-width: 980px;
        border: 1px solid #ebeaea;
        border-radius: 8px;
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
        margin-top: 70px;
        padding: 25px 35px 50px; } }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group {
      font-family: Montserrat,sans-serif !important; }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(4) {
        width: 100%; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(4) {
            width: 31.86%;
            margin-right: 15px;
            display: inline-block; } }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(5) {
        width: 100%; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(5) {
            width: 31.86%;
            margin-right: 15px;
            display: inline-block; } }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(6) {
        width: 100%; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(6) {
            width: 31.86%;
            display: inline-block; } }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(7) {
        width: 100%; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(7) {
            width: 31.86%;
            margin-right: 15px;
            display: inline-block; } }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(8) {
        width: 100%; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(8) {
            width: 31.86%;
            margin-right: 15px;
            display: inline-block; } }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(9) {
        width: 100%; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(9) {
            width: 31.86%;
            display: inline-block; } }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(10) {
        width: 100%; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(10) {
            margin-right: 15px; } }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(11) {
        width: 100%; }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(19) {
        width: 100%; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(19) {
            width: 31.86%;
            margin-right: 15px;
            display: inline-block; } }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(20) {
        width: 100%; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(20) {
            width: 31.86%;
            display: inline-block;
            margin-right: 15px; } }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(21) {
        width: 100%; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(21) {
            width: 31.86%;
            display: inline-block; } }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group:nth-child(22) {
        width: 100%; }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group input, .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group textarea, .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group select {
        border-bottom: 1px solid #ccc !important;
        border-radius: 0; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group input, .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group textarea, .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group select {
            border: 1px solid #ccc !important; } }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group {
          margin-bottom: 25px; } }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group label {
        font-size: 14px;
        line-height: 22px;
        padding-left: 0;
        margin-left: 0; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group label {
            font-size: 16px;
            line-height: inherit; } }
        .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group label.complex-field-label {
          font-size: 18px; }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group .link-label {
        padding-right: 50px; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .form-group .link-label {
            padding-right: 0; } }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .link-group {
      position: relative; }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .link-group .form-group {
        width: 100% !important;
        margin-right: 0 !important; }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .link-group button.btn-add {
        position: relative;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        color: #333;
        border-radius: 5px;
        z-index: 99;
        background: #dde9f7;
        transition: .3s all ease-in-out; }
        @media screen and (min-width: 992px) {
          .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .link-group button.btn-add {
            top: -41px;
            margin: 0 0 auto auto; } }
        .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .link-group button.btn-add:hover {
          background: #b4ceed; }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .employer-group .form-group:nth-child(1) {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .employer-group .form-group:nth-child(1) {
          width: 48.9%;
          display: inline-block;
          margin-right: 15px; } }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .employer-group .form-group:nth-child(2) {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .employer-group .form-group:nth-child(2) {
          width: 48.9%;
          display: inline-block; } }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .employer-group .form-group:nth-child(3) {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .employer-group .form-group:nth-child(3) {
          width: 48.9%;
          display: inline-block;
          margin-right: 15px; } }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .employer-group .form-group:nth-child(4) {
      width: 100%;
      margin-right: 0; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .employer-group .form-group:nth-child(4) {
          width: 48.9%;
          display: inline-block; } }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .employer-group .form-group:nth-child(5) {
      width: 100%;
      margin: 10px 0 20px; }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .employer-group .form-group:nth-child(6) {
      width: 100%; }
      .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .employer-group .form-group:nth-child(6) textarea {
        height: 70px; }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .employer-group .form-group .form-group {
      width: 100%;
      display: block;
      margin-bottom: 0;
      margin-right: 0; }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .employer-group label {
      background: #fbfbfb; }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .education-group .form-group:nth-child(1) {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .education-group .form-group:nth-child(1) {
          width: 31.86%;
          display: inline-block;
          margin-right: 15px; } }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .education-group .form-group:nth-child(2) {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .education-group .form-group:nth-child(2) {
          width: 31.86%;
          display: inline-block;
          margin-right: 15px; } }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .education-group .form-group:nth-child(3) {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .education-group .form-group:nth-child(3) {
          width: 31.86%;
          display: inline-block; } }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .education-group .form-group:nth-child(4) {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .education-group .form-group:nth-child(4) {
          width: 48.9%;
          display: inline-block; } }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .education-group .form-group:nth-child(5) {
      width: 100%;
      margin-right: 0; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .education-group .form-group:nth-child(5) {
          width: 48.9%;
          display: inline-block; } }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .education-group .form-group:nth-child(6) {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .education-group .form-group:nth-child(6) {
          margin-right: 0; } }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .education-group .form-group:nth-child(7) {
      width: 100%;
      margin-right: 0; }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .education-group .form-group .form-group {
      width: 100%;
      display: block;
      margin-bottom: 0; }
    .career-widget-section #freshteam-widget .application-form #freshhr_applicant_form .education-group label {
      background: #fbfbfb; }
    .career-widget-section #freshteam-widget .application-form h3 {
      font-size: 22px;
      font-family: Montserrat,sans-serif;
      color: #333;
      font-weight: 600; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .application-form h3 {
          font-size: 28px; } }
    .career-widget-section #freshteam-widget .application-form .g-recaptcha > div {
      width: 100% !important; }
      @media screen and (min-width: 992px) {
        .career-widget-section #freshteam-widget .application-form .g-recaptcha > div {
          width: 304px !important; } }
    .career-widget-section #freshteam-widget .application-form .g-recaptcha iframe {
      width: 100%; }
  .career-widget-section #freshteam-widget .btn-add {
    background: #4b8fe2;
    border: 1px solid #4b8fe2;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 200px;
    transition: .3s all ease-in-out; }
    @media screen and (min-width: 992px) {
      .career-widget-section #freshteam-widget .btn-add {
        width: auto;
        margin-left: 0;
        margin-right: 0;
        display: inherit; } }
    .career-widget-section #freshteam-widget .btn-add:hover {
      color: #eae7e7;
      background: #2374d7; }
    .career-widget-section #freshteam-widget .btn-add:focus {
      outline: 0;
      border: 1px solid #4b8fe2;
      color: #fff; }
  .career-widget-section #freshteam-widget #applicant_submit {
    margin: 0 auto;
    background: #ff5800;
    border: 1px solid #ff5800;
    font-family: Montserrat,sans-serif !important;
    font-weight: 500;
    display: block;
    border-radius: 5px; }
    @media screen and (min-width: 992px) {
      .career-widget-section #freshteam-widget #applicant_submit {
        display: inline-block; } }
  .career-widget-section #freshteam-widget .resume-upload .upload-file {
    font-size: 16px; }
  .career-widget-section #freshteam-widget .resume-upload #uploadValue {
    font-size: 15px;
    padding: 8px 10px;
    border-radius: 5px;
    text-transform: capitalize;
    font-weight: 500;
    color: #333; }
  .career-widget-section #freshteam-widget .resume-upload:hover #uploadValue {
    background: #4b8fe2;
    border-color: #4b8fe2;
    color: #fff; }
  .career-widget-section #freshteam-widget .complex-field {
    background: #fbfbfb;
    padding: 10px 10px 20px; }
    @media screen and (min-width: 992px) {
      .career-widget-section #freshteam-widget .complex-field {
        padding: 25px 25px 25px; } }
    .career-widget-section #freshteam-widget .complex-field-label {
      background: #fbfbfb; }
  .career-widget-section #freshteam-widget .vertical-align span {
    position: relative;
    top: -2px; }
  .career-widget-section #freshteam-widget .job-details-content .container-fluid {
    padding-left: 0;
    padding-right: 0; }
    @media screen and (min-width: 992px) {
      .career-widget-section #freshteam-widget .job-details-content .container-fluid {
        padding-left: 15px;
        padding-right: 15px; } }
  .career-widget-section #freshteam-widget .form-control {
    height: 42px; }
  .career-widget-section #freshteam-widget .btn-remove {
    background: #f5e0e0;
    transition: .3s all ease-in-out;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 200px; }
    @media screen and (min-width: 992px) {
      .career-widget-section #freshteam-widget .btn-remove {
        width: auto;
        margin-left: 0;
        margin-right: 0;
        display: inherit; } }
    .career-widget-section #freshteam-widget .btn-remove:hover {
      background: #e9b9b9; }

.padding-25-modify {
  padding-top: 25px; }
  @media screen and (min-width: 992px) {
    .padding-25-modify {
      padding-top: 0; } }

.job-left h1 {
  font-size: 24px; }
  @media screen and (min-width: 992px) {
    .job-left h1 {
      font-size: 24px; } }
  .job-left h1 span {
    font-size: 24px; }
    @media screen and (min-width: 992px) {
      .job-left h1 span {
        font-size: 24px; } }

.faq-pan-modify {
  position: relative; }

/*New bnr video section*/
.video-testimonial {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px; }
  @media screen and (min-width: 992px) {
    .video-testimonial {
      flex-wrap: nowrap;
      margin-bottom: 40px; } }
  .video-testimonial-wrapper {
    flex: 0 0 100%;
    padding: 15px 0;
    margin: 0 5px; }
    @media screen and (min-width: 992px) {
      .video-testimonial-wrapper {
        flex: 1;
        padding: 0 15px;
        margin: 0; } }
    .video-testimonial-wrapper h6 {
      font-size: 14px;
      color: #fff;
      font-weight: 600;
      text-align: center;
      margin-top: 15px; }
      @media screen and (min-width: 992px) {
        .video-testimonial-wrapper h6 {
          font-size: 18px;
          margin-top: 25px;
          text-align: left; } }
      .video-testimonial-wrapper h6 span {
        display: block;
        color: #36DF7D; }
  .video-testimonial .pl-0 {
    padding-left: 0; }
  .video-testimonial .pr-0 {
    padding-right: 0; }
  .video-testimonial-box {
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    border: 1px solid #C5C5C5; }
    @media screen and (min-width: 992px) {
      .video-testimonial-box {
        height: 170px; } }
    @media screen and (min-width: 1200px) {
      .video-testimonial-box {
        height: 208px; } }
    .video-testimonial-box .thumb-img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .video-testimonial-box .vid-play-btn {
      background: rgba(0, 0, 0, 0.32);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: .3s all ease-in-out; }
      .video-testimonial-box .vid-play-btn:before {
        content: '';
        position: absolute;
        background: url(/images/career/blue-play-btn.png) no-repeat center center;
        transition: .3s all ease-in-out;
        width: 62px;
        height: 62px; }
    .video-testimonial-box:hover .vid-play-btn {
      background: rgba(0, 0, 0, 0.16); }
      .video-testimonial-box:hover .vid-play-btn:before {
        transform: scale(1.1); }
  .video-testimonial ul.slick-dots {
    margin-top: 0; }

.testimonial-clip-modal {
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.95); }
  .testimonial-clip-modal .modal-header {
    border: 0;
    height: 0;
    padding: 0; }
    .testimonial-clip-modal .modal-header .btn-close {
      position: absolute;
      top: 0;
      right: 20px;
      background-color: #fff;
      opacity: 1;
      border-radius: 50%; }
      @media screen and (min-width: 992px) {
        .testimonial-clip-modal .modal-header .btn-close {
          top: -7px;
          right: 64px; } }
  .testimonial-clip-modal .modal-content {
    background: none;
    padding: 0;
    border: 0; }
    .testimonial-clip-modal .modal-content .modal-body {
      padding: 0; }
  .testimonial-clip-modal .modal-dialog {
    margin: 0 auto; }
    @media screen and (min-width: 992px) {
      .testimonial-clip-modal .modal-dialog {
        max-width: 990px !important; } }

.career-bnr .btn-center-block {
  width: 100% !important; }
  @media screen and (min-width: 992px) {
    .career-bnr .btn-center-block {
      width: 310px !important; } }

.owl-carousel .owl-dots {
  text-align: center;
  margin-bottom: 30px; }
  .owl-carousel .owl-dots .owl-dot {
    width: 7px;
    height: 7px;
    display: inline-block;
    vertical-align: middle;
    background: #9b9b9b;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer; }
    .owl-carousel .owl-dots .owl-dot.active {
      background: #49c684;
      width: 10px;
      height: 10px; }

.vid-testimonial-slider .owl-dots {
  margin-bottom: 30px; }

.o-terms-heading {
  padding: 140px 0 0;
  text-align: center; }
  .o-terms-heading h1 {
    position: relative;
    padding-bottom: 40px; }
    .o-terms-heading h1:before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
      width: 100px;
      height: 8px;
      background-color: #333; }

.o-terms p {
  font-size: 16px !important;
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .o-terms p {
      font-size: 17px !important;
      line-height: 30px;
      font-family: "Montserrat", sans-serif; } }

.privacy-policy {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #414446;
  font-size: 15px;
  line-height: 24px; }
  @media (min-width: 992px) {
    .privacy-policy {
      font-size: 16px;
      line-height: 28px; } }
  .privacy-policy .c-ticked-list {
    margin: 0px 0px 25px 0; }
    .privacy-policy .c-ticked-list li {
      position: relative;
      display: block;
      padding: 0 0 10px 35px;
      line-height: 30px;
      font-family: "Montserrat", sans-serif;
      font-size: 19px; }
      @media (min-width: 992px) {
        .privacy-policy .c-ticked-list li {
          padding-bottom: 15px;
          line-height: 24px;
          font-family: "Montserrat", sans-serif;
          font-size: 16px; } }
      .privacy-policy .c-ticked-list li:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 5px;
        left: 0px;
        background: url("/images/common/icon-bullet-dark.svg") 50% 50% no-repeat;
        background-size: contain; }
        @media (min-width: 1024px) {
          .privacy-policy .c-ticked-list li:before {
            top: 1px; } }
  .privacy-policy .common-bg-holder h5, .privacy-policy .common-bg-holder h6 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px; }
  .privacy-policy .common-bg-holder h5 {
    font-size: 24px;
    line-height: 32px; }
  .privacy-policy .common-bg-holder h6 {
    font-size: 22px;
    line-height: 34px; }
  .privacy-policy .common-bg-holder p {
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .privacy-policy .common-bg-holder p {
        margin-bottom: 25px; } }

.privacy-policy-mod .common-bg-holder {
  color: #333 !important; }
  .privacy-policy-mod .common-bg-holder span {
    font-size: 17px !important;
    line-height: 30px !important;
    color: #333 !important; }
    .privacy-policy-mod .common-bg-holder span span a {
      font-size: 15px !important;
      line-height: 24px !important; }
    .privacy-policy-mod .common-bg-holder span a span {
      font-size: 15px !important;
      line-height: 24px !important; }
  .privacy-policy-mod .common-bg-holder li {
    list-style: decimal;
    list-style-position: inside; }

.refund-policy h2 {
  text-transform: uppercase; }

.refund-policy h3 {
  text-transform: uppercase;
  margin: 40px 0 15px;
  color: #000;
  font-size: 32px; }
  .refund-policy h3.mb-25 {
    margin-bottom: 25px; }

.refund-policy ol li {
  color: #000;
  padding-bottom: 15px;
  font-size: 19px;
  line-height: 32px; }
  .refund-policy ol li:last-child {
    padding-bottom: 0; }

.refund-policy p {
  color: #000; }

.o-terms .refund-policy p {
  font-size: 20px !important; }

@media (min-width: 992px) {
  .cookie-policy-h1,
  .disclaimer-h1,
  .privacy-policy-h1,
  .refund-policy-h1,
  .terms-condition-h1 {
    font-size: 48px;
    line-height: 61px; } }

.o-skills-hero__case-studies__banner-image {
  padding: 50px 0 0; }
  @media screen and (min-width: 768px) {
    .o-skills-hero__case-studies__banner-image {
      padding: 0; } }

.o-skills-hero__case-studies__banner-content {
  text-align: center; }
  @media (min-width: 992px) {
    .o-skills-hero__case-studies__banner-content {
      text-align: left; } }
  .o-skills-hero__case-studies__banner-content h4 {
    color: #14DF7D;
    font-family: "Montserrat", sans-serif; }
  .o-skills-hero__case-studies__banner-content h2 {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: 700; }
  .o-skills-hero__case-studies__banner-content p {
    color: #14DF7D;
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    line-height: 43px;
    font-weight: 600;
    margin-bottom: 0;
    border-bottom: 1px solid #14DF7D;
    display: inline-block;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .o-skills-hero__case-studies__banner-content p {
        font-size: 30px;
        line-height: 43px;
        border-bottom: 0; } }
  .o-skills-hero__case-studies__banner-content h1 {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-transform: none; }
    @media screen and (min-width: 768px) {
      .o-skills-hero__case-studies__banner-content h1 {
        font-size: 36px;
        line-height: 46px; } }
    @media screen and (min-width: 992px) {
      .o-skills-hero__case-studies__banner-content h1 {
        font-size: 46px;
        line-height: 61px; } }

.o-skills-hero__case-studies__technology-div h2 {
  font-size: 24px; }

.o-skills-hero__case-studies__technology-div ul {
  display: block;
  text-align: center; }
  .o-skills-hero__case-studies__technology-div ul li {
    vertical-align: top;
    display: inline-block;
    margin: 0 6px;
    font-family: "Montserrat", sans-serif; }
    .o-skills-hero__case-studies__technology-div ul li span {
      display: block;
      width: 100px;
      height: 100px;
      text-align: center;
      padding: 20px 10px;
      border: 2px solid #14DF7D;
      border-radius: 50%;
      margin-bottom: 10px;
      color: #fff;
      font-size: 11px;
      font-family: "Montserrat", sans-serif;
      position: relative; }
      .o-skills-hero__case-studies__technology-div ul li span a {
        display: inline-block;
        width: 100%;
        height: 100%; }
      .o-skills-hero__case-studies__technology-div ul li span img {
        margin-bottom: 3px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        width: 42px; }
        .o-skills-hero__case-studies__technology-div ul li span img.wdth-25 {
          width: 25px; }
        .o-skills-hero__case-studies__technology-div ul li span img.wdth-15 {
          width: 15px; }
      .o-skills-hero__case-studies__technology-div ul li span .vertical-image-resize {
        height: 54px;
        width: auto; }
      @media screen and (min-width: 992px) {
        .o-skills-hero__case-studies__technology-div ul li span .transip {
          width: 70px;
          height: 70px; } }

.o-case-study__clients__the-client-content {
  padding: 0; }
  @media (min-width: 992px) {
    .o-case-study__clients__the-client-content {
      padding: 30px 0 0 0; } }
  .o-case-study__clients__the-client-content h3 {
    font-size: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 0px; }
  .o-case-study__clients__the-client-content h4 {
    font-size: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-bottom: 0px; }
  @media (min-width: 992px) {
    .o-case-study__clients__the-client-content p {
      font-size: 19px;
      line-height: 30px; } }
  .o-case-study__clients__the-client-content ul {
    margin-bottom: 15px;
    margin-top: 15px; }
    @media (min-width: 992px) {
      .o-case-study__clients__the-client-content ul {
        margin-top: 0; } }
    .o-case-study__clients__the-client-content ul li {
      padding-left: 35px;
      padding-bottom: 15px;
      font-size: 19px;
      line-height: 32px;
      position: relative;
      font-family: "Montserrat", sans-serif;
      position: relative; }
      .o-case-study__clients__the-client-content ul li:before {
        content: '';
        background-image: url(/images/common/green-arrow.png);
        width: 25px;
        height: 25px;
        position: absolute;
        left: 0;
        top: 3px;
        background-size: 100%; }
    .o-case-study__clients__the-client-content ul.number-style li::before {
      background-image: none !important;
      top: 0 !important; }
    .o-case-study__clients__the-client-content ul.number-style .number-style1::before {
      content: '1.'; }
    .o-case-study__clients__the-client-content ul.number-style .number-style2::before {
      content: '2.'; }
    .o-case-study__clients__the-client-content ul.number-style .number-style3::before {
      content: '3.'; }
    .o-case-study__clients__the-client-content ul.number-style .number-style4::before {
      content: '4.'; }
    .o-case-study__clients__the-client-content ul.number-style .number-style5::before {
      content: '5.'; }
    .o-case-study__clients__the-client-content ul.number-style .number-style6::before {
      content: '6.'; }
    .o-case-study__clients__the-client-content ul.number-style .number-style7::before {
      content: '7.'; }
    .o-case-study__clients__the-client-content ul.number-style .number-style8::before {
      content: '8.'; }
  .o-case-study__clients__the-client-content__services {
    padding: 60px 0 0 0; }
    .o-case-study__clients__the-client-content__services ul {
      display: flex; }
      .o-case-study__clients__the-client-content__services ul li {
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
        font-size: 30px !important;
        font-weight: 500;
        line-height: 25px;
        width: 170px;
        font-family: "Montserrat", sans-serif; }
        @media (min-width: 992px) {
          .o-case-study__clients__the-client-content__services ul li {
            flex: 0 0 25%; } }
        .o-case-study__clients__the-client-content__services ul li span {
          display: block;
          min-height: 60px;
          font-size: 16px; }
          .o-case-study__clients__the-client-content__services ul li span img {
            margin-bottom: 10px; }
          .o-case-study__clients__the-client-content__services ul li span:last-child {
            min-height: auto !important; }
        .o-case-study__clients__the-client-content__services ul li:before {
          display: none; }
        .o-case-study__clients__the-client-content__services ul li:after {
          content: '';
          border-right: 1px solid #d8d8d8;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0px; }
        .o-case-study__clients__the-client-content__services ul li:last-of-type:after {
          content: '';
          border-right: 1px solid transparent; }
        @media (min-width: 992px) {
          .o-case-study__clients__the-client-content__services ul li:first-child {
            flex: 0;
            padding-right: 50px !important; } }
  .o-case-study__clients__the-client-content .the-results {
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%); }
  .o-case-study__clients__the-client-content .results-content {
    background-color: #fff;
    color: #333; }
    .o-case-study__clients__the-client-content .results-content ul li {
      font-family: "Montserrat", sans-serif; }
      .o-case-study__clients__the-client-content .results-content ul li:before {
        width: 20px;
        height: 20px;
        top: 5px;
        background-size: 100%;
        content: '';
        background-image: url(/images/common/green-arrow.png);
        position: absolute;
        left: 0; }
    .o-case-study__clients__the-client-content .results-content h4 {
      margin: 0; }
  .o-case-study__clients__the-client-content__icon-header {
    position: relative; }
    .o-case-study__clients__the-client-content__icon-header img {
      position: absolute;
      max-width: 36px; }
    .o-case-study__clients__the-client-content__icon-header h4 {
      padding-left: 55px; }

.o-case-study__clients__the-results {
  position: relative;
  padding-top: 100px; }
  .o-case-study__clients__the-results__div {
    padding-top: 10px; }
    .o-case-study__clients__the-results__div h4 {
      color: #05B281;
      padding-bottom: 20px; }

.font-size-24-i {
  font-size: 24px !important;
  line-height: 32px !important; }

.padding-top-60 {
  padding-top: 60px; }

.o-case-studies-new {
  padding: 15px 0 20px; }
  @media (min-width: 992px) {
    .o-case-studies-new {
      padding: 65px 0 50px; } }
  @media (min-width: 992px) {
    .o-case-studies-new .btn_wrapper {
      margin-top: 0; } }
  @media (min-width: 992px) {
    .o-case-studies-new .filter {
      margin-bottom: 10px;
      text-align: right; } }
  .o-case-studies-new .filter span.flt-tech {
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #000; }
    @media (min-width: 992px) {
      .o-case-studies-new .filter span.flt-tech {
        margin-right: 0;
        margin-left: 15px;
        font-size: 16px; } }
  .o-case-studies-new .filter ul li {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif; }
    .o-case-studies-new .filter ul li label {
      cursor: pointer; }
  .o-case-studies-new .product {
    width: 90%;
    margin: 15px;
    text-align: center;
    border: 1px solid #EAEAEA;
    padding: 20px;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px 0px #00000030;
    background-color: #fff;
    overflow: hidden; }
    @media (min-width: 992px) {
      .o-case-studies-new .product {
        min-height: 430px; } }
    .o-case-studies-new .product a img {
      width: 100%; }
    .o-case-studies-new .product-portfolio {
      width: 100%;
      float: left;
      text-align: center;
      min-height: 340px;
      padding: 0;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
      border: 0;
      -webkit-border-bottom-right-radius: 5px;
      -webkit-border-bottom-left-radius: 5px;
      -moz-border-radius-bottomright: 5px;
      -moz-border-radius-bottomleft: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      background-color: #fff;
      /* mobile query for tablet landscape */ }
      .o-case-studies-new .product-portfolio h3 {
        padding: 10px 0;
        min-height: 70px;
        text-align: left;
        font-weight: 400; }
      .o-case-studies-new .product-portfolio p {
        font-weight: 500;
        text-align: left; }
      .o-case-studies-new .product-portfolio img {
        margin-left: 0;
        margin-right: 0; }
      .o-case-studies-new .product-portfolio .pp-thumb {
        margin-left: 0;
        margin-right: 0;
        width: 100%; }
      .o-case-studies-new .product-portfolio .p-button {
        border: 1px solid #A4BB66;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        display: block;
        margin-bottom: 10px;
        color: #333;
        font-size: 13px;
        font-weight: 500;
        margin-left: 20px;
        margin-right: 20px; }
        .o-case-studies-new .product-portfolio .p-button:hover {
          background-color: #A4BB66;
          color: #fff; }
      .o-case-studies-new .product-portfolio .p-link {
        margin-bottom: 10px;
        color: #333;
        font-size: 13px;
        font-weight: 300;
        background: url(/assets/images/portfolio/link-icon.png) 83% 6px no-repeat;
        margin-left: 0;
        margin-right: 0;
        text-align: left;
        width: 120px;
        border: 1px solid #ccc;
        display: inline-block;
        padding: 2px 0 2px 19px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        float: right; }
        .o-case-studies-new .product-portfolio .p-link:hover {
          color: #fff;
          background: url(/assets/images/portfolio/link-icon-hover.png) 83% 6px no-repeat #FF5800; }
      .o-case-studies-new .product-portfolio .bottom-casestudies {
        padding: 0 20px;
        text-align: left !important; }
        .o-case-studies-new .product-portfolio .bottom-casestudies .country-code {
          text-indent: 0 !important;
          font-size: 16px !important;
          font-weight: 500;
          line-height: 30px !important;
          display: inline-block !important; }
          .o-case-studies-new .product-portfolio .bottom-casestudies .country-code img {
            width: auto; }
        .o-case-studies-new .product-portfolio .bottom-casestudies img {
          border: none !important;
          padding: 0 !important;
          -webkit-border-radius: 0 !important;
          -moz-border-radius: 0 !important;
          border-radius: 0 !important;
          margin-left: 0 !important;
          margin-right: 15px !important;
          width: 20px; }
    .o-case-studies-new .product span {
      text-indent: -10000px;
      font-size: 0;
      line-height: 0;
      display: none; }
    .o-case-studies-new .product h3 {
      font-size: 18px;
      line-height: 28px;
      margin-top: 30px;
      margin-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      height: 80px;
      overflow: hidden; }
      .o-case-studies-new .product h3 a {
        color: #000; }
    .o-case-studies-new .product h2 {
      font-size: 17px;
      line-height: 23px;
      margin-top: 20px;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-left: 20px;
      padding-right: 20px;
      text-align: left;
      overflow: hidden; }
      @media (min-width: 992px) {
        .o-case-studies-new .product h2 {
          margin-top: 20px;
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 0px;
          padding-bottom: 15px; } }
      .o-case-studies-new .product h2 a {
        color: #000; }
    .o-case-studies-new .product p {
      font-size: 15px;
      line-height: 21px;
      margin-top: 10px;
      margin-left: 20px;
      margin-right: 20px;
      min-height: 85px;
      text-align: left;
      padding-bottom: 5px; }
      @media (min-width: 992px) {
        .o-case-studies-new .product p {
          font-size: 16px;
          line-height: 28px;
          padding-bottom: 20px;
          margin-top: 0px; } }
    .o-case-studies-new .product .bottom-casestudies {
      text-align: center;
      overflow: hidden;
      /* mobile query for tablet landscape */ }
      .o-case-studies-new .product .bottom-casestudies a.color-icon {
        width: 40px;
        height: 40px;
        text-align: center;
        display: inline-block;
        margin: 0 10px 0 0;
        border: 1px solid #ccc;
        padding: 5px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%; }
        .o-case-studies-new .product .bottom-casestudies a.color-icon img {
          width: 100%;
          height: auto;
          margin-left: 0;
          margin-right: 0; }
      .o-case-studies-new .product .bottom-casestudies .btn-casestudies {
        padding: 0 13px;
        float: none;
        display: block;
        margin: 0 auto 0;
        max-width: 200px;
        background-color: #fff;
        color: #FF5800;
        float: right;
        /* mobile query for tablet landscape */ }
        @media (min-width: 992px) {
          .o-case-studies-new .product .bottom-casestudies .btn-casestudies {
            padding: 0 15px; } }
        .o-case-studies-new .product .bottom-casestudies .btn-casestudies:hover {
          background-color: #FF5800;
          color: #fff;
          border: 2px solid #FF5800; }
      .o-case-studies-new .product .bottom-casestudies .btn-casestudies1 {
        padding: 13px 32px;
        float: left;
        display: block;
        margin: 10px auto 0;
        max-width: 200px;
        background-color: #fff;
        color: #333;
        background: url(/images/case-study-home/icon-pdf.svg) 0 50% no-repeat;
        text-transform: uppercase;
        font-family: "Montserrat",sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        /* mobile query for tablet landscape */ }
        .o-case-studies-new .product .bottom-casestudies .btn-casestudies1:after {
          content: "";
          position: absolute;
          left: 32px;
          bottom: 9px;
          width: 100px;
          background-color: #C3C3C3;
          height: 1px; }
        .o-case-studies-new .product .bottom-casestudies .btn-casestudies1:hover::after {
          background-color: #494646; }
  .o-case-studies-new .filter-height {
    height: 340px;
    overflow-y: scroll;
    margin: 0 0 50px 0;
    display: none;
    position: absolute;
    z-index: 9;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    margin-top: 25px;
    right: 0px;
    top: 2px;
    text-align: left;
    min-width: 215px; }
    .o-case-studies-new .filter-height ul li .case-study-label {
      position: relative;
      line-height: 100%;
      cursor: pointer; }
    .o-case-studies-new .filter-height ul li:last-child {
      padding-bottom: 0px; }
  .o-case-studies-new .tech-filter-height {
    z-index: 99; }
  .o-case-studies-new .filter-height ul li {
    padding: 0 0 12px;
    position: relative; }
  .o-case-studies-new .filter-height ul li img {
    margin-right: 10px;
    width: 20px; }
  .o-case-studies-new .filter-height ul li input {
    margin: 0 5px 0 0;
    cursor: pointer; }

.btn-casestudies {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  color: #fff;
  background: #FF5800;
  border-radius: 4px;
  font-size: 15px;
  line-height: 38px;
  text-transform: uppercase;
  border: 2px solid #FF5800;
  padding: 0 27px; }
  @media (min-width: 992px) {
    .btn-casestudies {
      font-size: 16px;
      line-height: 43px; } }
  .btn-casestudies:hover {
    color: #fff;
    border-color: #fff;
    background-color: #14DF7D; }

.drop-down-technologies {
  text-align: center;
  position: relative; }
  @media (min-width: 992px) {
    .drop-down-technologies {
      margin-right: 20px;
      text-align: right; } }
  .drop-down-technologies .selected a img.filter-icon {
    margin-top: -6px; }
  .drop-down-technologies .selected a img.icon-arrow {
    z-index: 10;
    position: relative;
    background-color: #fff; }
  .drop-down-technologies .selected a img.icon-up {
    padding: 10px 5px;
    transform: rotate(180deg);
    -wenkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg); }
    @media (min-width: 992px) {
      .drop-down-technologies .selected a img.icon-up {
        padding: 10px 15px; } }
  .drop-down-technologies .selected a img.icon-down {
    padding: 10px 5px;
    border: 1px solid 0;
    transform: rotate(0deg);
    -wenkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    border-bottom: none; }
    @media (min-width: 992px) {
      .drop-down-technologies .selected a img.icon-down {
        padding: 10px 15px; } }

.o-case-study-cta {
  padding: 20px 0 60px 0;
  text-align: center;
  /* media query for tablet landscape */ }

.paging {
  text-align: center;
  margin: 15px 0 30px 0; }
  @media (min-width: 992px) {
    .paging {
      margin: 30px 0 30px 0; } }
  .paging span {
    padding: 6px 12px;
    font-weight: bold;
    color: #000;
    margin: 5px; }
    .paging span.current {
      background: #FF5800;
      color: #fff; }
  .paging em {
    padding: 10px;
    font-weight: bold;
    color: #000;
    margin: 5px; }
  .paging a {
    color: #000;
    font-weight: bold;
    padding: 6px; }
  .paging ul {
    text-align: center; }
    .paging ul li {
      display: inline-block;
      vertical-align: middle;
      font-size: 15px;
      font-family: "Montserrat",sans-serif;
      font-weight: 500;
      border-radius: 5px;
      color: #000; }
      .paging ul li:first-child {
        border: 1px solid #333333;
        color: #373737;
        margin-right: 5px;
        position: relative;
        background: url(/images/common/left-icon.svg) no-repeat center center; }
        .paging ul li:first-child .flex-center {
          text-indent: 9999px; }
      .paging ul li .flex-center {
        padding: 6px 0;
        cursor: pointer;
        width: 32px;
        height: 32px; }
      .paging ul li .active-page {
        background: #FF5800;
        color: #fff;
        border-radius: 5px;
        height: 34px !important; }
      .paging ul li:last-child {
        border: 1px solid #333333;
        color: #373737;
        margin-left: 5px;
        position: relative;
        background: url(/images/common/right-icon.svg) no-repeat center center; }
        .paging ul li:last-child .flex-center {
          text-indent: 9999px; }
      .paging ul li:nth-child(2) {
        display: inline-block !important;
        position: relative; }
      .paging ul li:nth-last-child(2) {
        display: inline-block !important;
        position: relative; }
        .paging ul li:nth-last-child(2):before {
          content: "...";
          float: left;
          margin: 2px 0 0; }
    .paging ul.prevShep li:nth-child(2):before {
      display: none; }
    .paging ul.midShep li:nth-child(2):before {
      content: "...";
      float: right;
      margin: 2px 0 0; }
    .paging ul.lastShep li:nth-child(2):before {
      content: "...";
      float: right;
      margin: 2px 0 0; }
    .paging ul.lastShep li:nth-last-child(2):before {
      display: none; }
    .paging ul.eSepter li:nth-last-child(2):before {
      display: none; }

/* width */
.filter ::-webkit-scrollbar {
  width: 5px;
  background-color: #ccc; }

/* Track */
.filter ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #FCFCFC;
  border-radius: 10px; }

/* Handle */
.filter ::-webkit-scrollbar-thumb {
  border-radius: 25px;
  height: 30px;
  background-color: #4A4A4A;
  margin-top: 25px; }

.banner-image-over {
  display: none; }

.image-position-case {
  position: relative;
  width: 80px;
  float: left;
  height: 60px; }
  .image-position-case img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -wenkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%); }

.padding-top-90-mlnp {
  padding-top: 90px !important;
  height: 509px; }

.case-study-background-mlnp {
  height: 509px; }

.case-studies-banner {
  background-color: #cc2622db;
  padding: 0 15px !important; }
  .case-studies-banner .mlpn-left {
    padding-left: 0px; }
  .case-studies-banner__banner-content {
    padding-top: 50px; }
    .case-studies-banner__banner-content p {
      background-color: #9E2116;
      display: inline;
      padding: 10px 20px;
      font-size: 18px;
      color: #fff; }
    .case-studies-banner__banner-content h1 {
      font-size: 36px;
      padding: 5px 0 25px;
      line-height: 46px; }

.client-heading-mlnp {
  color: #494949;
  font-size: 55px !important;
  text-transform: uppercase !important;
  margin-bottom: 30px !important;
  margin-top: 35px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700; }

.client-description-mlnp {
  font-size: 17px !important; }

.challenge-bullets {
  display: block; }
  @media (min-width: 992px) {
    .challenge-bullets {
      display: flex; } }
  .challenge-bullets li {
    padding-left: 20px !important;
    padding-right: 10px;
    line-height: 28px !important; }
    .challenge-bullets li:before {
      content: '';
      background-color: #EC3E2E;
      background-image: none !important;
      top: 5px !important;
      width: 4px !important;
      height: 50px !important; }
      @media (min-width: 992px) {
        .challenge-bullets li:before {
          height: 75px !important; } }

.mlnp-watch-text {
  font-size: 24px;
  line-height: 36px;
  color: #fff;
  display: inline;
  position: relative;
  padding-top: 2px !important; }
  .mlnp-watch-text:before {
    content: '';
    position: absolute;
    width: 290px;
    height: 4px;
    background-color: #D0352B;
    left: 0;
    top: -8px; }

.video-mlnp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.video {
  height: 315px; }

.video-box {
  height: 315px;
  position: relative; }

.video-thumbnail {
  z-index: 300;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }

.video-frame {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }

.thumbnail-link .video-thumbnail {
  display: block; }

.thumbnail-link:hover .video-thumbnail {
  display: none; }

#video-container {
  position: relative;
  max-width: 40em;
  margin: auto;
  border: 1px solid #000;
  box-shadow: 0.25em 0.25em 0.25em rgba(0, 0, 0, 0.3); }

#video-container video {
  display: block;
  width: 100%;
  height: auto; }

#video-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  cursor: pointer; }

.hide {
  display: none; }

#starter {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0; }

.gradient-green-blue-mlnp {
  background: #00b35c;
  background: -moz-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, #00b35c), color-stop(47%, #0b8a8a), color-stop(100%, #004475));
  background: -webkit-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  background: -o-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  background: -ms-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  background: linear-gradient(135deg, #00b35c -22%, #0e5579 47%, #004475 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b35c', endColorstr='#004475', GradientType=1 ); }

p.service-txt {
  min-height: auto !important;
  margin-bottom: 0 !important;
  font-family: "Montserrat",sans-serif; }

.download-form {
  max-width: 625px;
  margin: 0 auto;
  padding: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden; }
  .download-form form {
    padding: 20px 40px 20px 40px;
    background-color: #fff; }
    @media (min-width: 992px) {
      .download-form form {
        padding: 20px 60px 40px; } }
  .download-form label {
    display: block;
    margin-bottom: 10px; }
  .download-form input {
    border-bottom: 1px solid #000;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 20px 10px 20px 50px;
    font-size: 16px;
    font-family: "Montserrat",sans-serif;
    font-weight: 500; }
  .download-form .cs-name {
    background: url(images/common/form-icon-1.png) 0 50% no-repeat; }
  .download-form .cs-email {
    background: url(images/common/form-icon-2.png) 0 50% no-repeat; }
  .download-form button {
    margin: 20px 0 0 0; }
  .download-form .popup-heading {
    width: 100%;
    height: 110px;
    padding: 30px 140px 30px 40px;
    position: relative; }
    @media (min-width: 992px) {
      .download-form .popup-heading {
        padding: 30px 110px 30px 60px; } }
    .download-form .popup-heading:after {
      background: url(images/case-study/popup-heading-bg.png) 100% 100% no-repeat;
      content: "";
      position: absolute;
      right: 30px;
      bottom: 0;
      width: 108px;
      height: 88px; }
    .download-form .popup-heading h2 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      color: #fff; }
      @media (min-width: 992px) {
        .download-form .popup-heading h2 {
          font-size: 30px;
          line-height: 47px; } }
    .download-form .popup-heading p {
      color: #fff;
      font-size: 19px;
      line-height: 30px; }
  .download-form .ohnohoney {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1; }

.popup-modal button.close {
  z-index: 999;
  position: relative;
  right: 23% !important;
  top: 42px !important;
  background: transparent !important;
  padding: 10px;
  opacity: 1; }

.cs-banner-pdf {
  padding: 7px 32px;
  float: none;
  margin: 30px auto;
  color: #fff;
  background: url(/images/case-study/icon-pdf-1.svg) 0 50% no-repeat;
  text-decoration: underline;
  font-family: "Montserrat",sans-serif;
  position: relative;
  text-decoration: none;
  font-size: 20px; }
  .case-studies-details .cs-banner-pdf {
    font-size: 16px;
    font-weight: 600;
    padding-right: 0; }
  @media (min-width: 992px) {
    .cs-banner-pdf {
      float: left;
      display: block;
      margin: 30px auto 0; } }
  .cs-banner-pdf:after {
    content: "";
    position: absolute;
    left: 34px;
    bottom: 0px;
    width: 288px;
    background-color: #14DF7D;
    height: 2px;
    display: none; }
    @media (min-width: 992px) {
      .cs-banner-pdf:after {
        display: block; } }
    .case-studies-details .cs-banner-pdf:after {
      display: block;
      width: auto;
      right: 0; }
  .cs-banner-pdf:hover {
    color: #fff; }
    .cs-banner-pdf:hover::after {
      background-color: #fff; }
  .cs-banner-pdf:focus {
    color: #fff; }

.case-study-download {
  padding: 30px 0;
  margin: 60px 0 0 0;
  text-align: center;
  background-color: #091B38; }
  @media (min-width: 992px) {
    .case-study-download {
      background: url(/images/case-study/download-bg.jpg) 50% 50% no-repeat #091B38;
      text-align: left; } }

.case-study-download img.download-icon {
  float: right;
  display: none; }
  @media (min-width: 992px) {
    .case-study-download img.download-icon {
      display: block; } }

.case-study-download h2 {
  color: #fff;
  font-size: 40px;
  line-height: 55px;
  font-weight: bold;
  padding: 15px 0 0;
  text-transform: uppercase; }

.cs-bot-banner-pdf {
  border: 2px solid #FF5800;
  color: #fff;
  background: url(/images/case-study/icon-down-arrow.png) 88% 50% no-repeat;
  font-size: 17px;
  line-height: 24px;
  font-family: "Montserrat",sans-serif;
  padding: 16px 60px 18px 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 40px 0 0 0;
  display: inline-block;
  background-color: #FF5800;
  font-weight: 500; }
  @media screen and (min-width: 992px) {
    .cs-bot-banner-pdf {
      padding: 16px 60px 18px 15px; } }
  @media screen and (min-width: 1200px) {
    .cs-bot-banner-pdf {
      padding: 16px 60px 18px 30px; } }

.cs-bot-banner-pdf:focus {
  color: #fff; }

.cs-bot-banner-pdf:hover {
  background-color: #FF5800;
  color: #fff;
  border: 2px solid #fff; }

.retention-rate {
  background: #10D08E;
  background: -moz-linear-gradient(-45deg, #10D08E 0%, #082653 100%);
  background: -webkit-linear-gradient(-45deg, #10D08E 0%, #082653 100%);
  background: linear-gradient(135deg, #10D08E 0%, #082653 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10D08E', endColorstr='#082653',GradientType=1 );
  padding: 45px 35px 130px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px; }

.hurry {
  width: 80%;
  position: absolute;
  left: 50%;
  top: -210px;
  transform: translate(-50%, -50%);
  padding: 40px 80px 60px;
  text-align: center;
  -webkit-box-shadow: 0px 0px 18px 0px #a6a6a6;
  -moz-box-shadow: 0px 0px 18px 0px #a6a6a6;
  box-shadow: 0px 0px 18px 0px #a6a6a6;
  background: #fff;
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; }

.hurry h3 {
  font-size: 36px;
  margin-bottom: 10px; }

.hurry p {
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;
  font-family: "Montserrat",sans-serif; }

.hurry .btn {
  padding: 12px 0 !important;
  max-width: 240px !important; }
  @media (min-width: 992px) {
    .hurry .btn {
      padding: 19px 0px !important;
      max-width: 350px !important; } }

.o-casestudies-thankyou {
  text-align: center;
  position: relative;
  padding: 170px 0 50px;
  overflow: hidden;
  background: url(images/casestudies-thank-you/thank-you-banner.jpg) 50% 50% no-repeat;
  margin-bottom: 290px; }

.o-casestudies-thankyou h1 {
  font-size: 40px;
  line-height: 44px;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px; }

.o-casestudies-thankyou p {
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  font-family: "Montserrat",sans-serif;
  font-weight: 500;
  padding-bottom: 40px; }

.o-casestudies-thankyou p a {
  text-decoration: underline;
  color: #fff !important; }

.case-thanks .btn {
  z-index: 0;
  color: #fff;
  border: 2px solid #FF5800;
  background-color: #FF5800;
  padding: 12px 0px;
  max-width: 240px; }

.case-thanks .btn:after {
  content: '';
  background: url(images/common/arrow-white.png) no-repeat;
  width: 20px;
  height: 14px;
  display: inline-block;
  margin-left: 15px;
  z-index: -1; }

.case-thanks .social-footer-divider--social-pan {
  border-top: 0 !important; }

.cheaper-better {
  border: 2px solid #15B96A;
  padding: 10px 35px 30px;
  margin-bottom: 30px;
  margin-top: 30px; }
  @media (min-width: 992px) {
    .cheaper-better {
      padding: 50px 35px; } }

.cheaper-better p {
  font-size: 24px;
  line-height: 30px;
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .cheaper-better p {
      font-size: 30px;
      line-height: 36px; } }

.cheaper-better p span {
  display: block;
  font-size: 110px;
  line-height: 120px;
  font-weight: 600; }

.retention-rate1 {
  background: #10D08E;
  background: -moz-linear-gradient(-45deg, #10D08E 0%, #082653 100%);
  background: -webkit-linear-gradient(-45deg, #10D08E 0%, #082653 100%);
  background: linear-gradient(135deg, #10D08E 0%, #082653 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10D08E', endColorstr='#082653',GradientType=1 );
  padding: 20px 35px 0;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .retention-rate1 {
      padding: 45px 35px 0; } }

.retention-rate1 h4 {
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  line-height: 30px;
  padding-bottom: 20px;
  text-align: center; }
  @media (min-width: 992px) {
    .retention-rate1 h4 {
      text-align: left;
      font-size: 28px;
      line-height: 36px; } }

.off-video-pan {
  border: 1px solid #D8D8D8;
  padding: 25px 30px;
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .off-video-pan {
      padding: 20px; } }

.off-video-pan .off-video {
  max-width: 100%;
  float: left; }
  @media (min-width: 992px) {
    .off-video-pan .off-video {
      max-width: 250px;
      margin-right: 10px; } }

.off-video-pan .off-video-txt {
  width: 45%;
  margin-top: 0;
  float: left;
  margin-left: 10px; }

.off-video-pan .off-video-txt h3 {
  font-size: 22px;
  margin-bottom: 5px;
  line-height: 28px; }

.off-video-pan .off-video-txt p {
  margin-bottom: 10px;
  font-family: "Montserrat",sans-serif; }

.off-video-pan .off-video-txt a {
  background-color: transparent !important;
  color: #000 !important;
  border-color: #FF5800 !important; }

.post-pan {
  border: 1px solid #D8D8D8;
  padding: 25px 20px 20px 20px; }
  @media (min-width: 992px) {
    .post-pan {
      padding: 10px 20px 30px 20px; } }

.post-pan h2 {
  font-size: 24px;
  margin-bottom: 30px;
  text-align: center; }
  @media (min-width: 992px) {
    .post-pan h2 {
      text-align: left;
      font-size: 30px; } }

.post-pan ul {
  padding-bottom: 20px; }

.post-pan ul li {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
  overflow: hidden;
  font-family: "Montserrat",sans-serif; }
  @media (min-width: 992px) {
    .post-pan ul li {
      font-size: 18px;
      line-height: 26px; } }

.post-pan ul li span {
  float: left;
  margin-right: 20px; }

.post-pan ul li a {
  color: #000;
  display: inline-block;
  width: 80%;
  padding-top: 10px; }

.post-pan .btn {
  max-width: 300px !important; }

.thank-right-top {
  overflow: hidden; }

.thank-right-top ul {
  overflow: hidden;
  margin-bottom: 20px; }
  .thank-right-top ul li {
    display: inline-block;
    width: 48%;
    vertical-align: top;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat",sans-serif; }
    .thank-right-top ul li span {
      display: inline-block;
      margin-right: 10px;
      width: 25px; }
    .thank-right-top ul li a {
      color: #000; }

.thank-right-top h3 {
  font-size: 28px;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  margin-bottom: 30px; }

.thank-right-top p {
  font-family: "Montserrat",sans-serif;
  font-size: 19px;
  font-weight: 600; }

.t-left {
  border: 1px solid #D8D8D8;
  padding: 30px;
  margin-bottom: 30px;
  float: left;
  width: 48%;
  min-height: 368px; }

.t-right {
  border: 1px solid #D8D8D8;
  padding: 30px;
  margin-bottom: 30px;
  float: right;
  width: 48%;
  min-height: 368px; }

@media (min-width: 992px) {
  .banner-top-position__center {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-60%);
    -webkit-transform: translateY(-60%);
    -moz-transform: translateY(-60%);
    -ms-transform: translateY(-60%); } }

@media (min-width: 992px) {
  .case-studies-details .banner-top-position__center {
    display: block;
    position: relative;
    top: inherit;
    transform: translateY(0); } }

.o-case-studies-new .masonry {
  column-count: inherit;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0 !important;
  justify-content: center; }
  @media screen and (min-width: 768px) {
    .o-case-studies-new .masonry {
      justify-content: space-between; } }
  @media screen and (min-width: 1280px) {
    .o-case-studies-new .masonry {
      justify-content: flex-start; } }

.o-case-studies-new .product {
  width: 100%;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  padding: 0; }
  @media (min-width: 992px) {
    .o-case-studies-new .product {
      margin: 15px 10px; } }
  @media screen and (min-width: 992px) {
    .o-case-studies-new .product {
      flex: 0 0  47%;
      width: 47%; } }
  @media screen and (min-width: 1280px) {
    .o-case-studies-new .product {
      flex: 0 0 31.55%;
      max-width: 31.55%;
      flex-grow: 1; } }

.o-case-studies-new .product .bottom-casestudies {
  text-align: left;
  border-top: 1px solid #EAEAEA;
  padding: 13px 20px 13px;
  margin-top: 15px;
  display: flex;
  align-items: center; }
  @media (min-width: 992px) {
    .o-case-studies-new .product .bottom-casestudies {
      margin-top: auto; } }

.o-case-studies-new .product .bottom-casestudies .btn-casestudies1 {
  padding: 13px 13px 13px 32px;
  float: left;
  display: block;
  margin: 0 auto 0;
  max-width: 200px;
  background-color: #fff;
  color: #333;
  background: url(/images/case-study-home/icon-pdf.svg) 0 50% no-repeat;
  text-transform: uppercase;
  font-family: "Montserrat",sans-serif;
  font-size: 15px;
  font-weight: 400;
  position: relative; }
  @media (min-width: 992px) {
    .o-case-studies-new .product .bottom-casestudies .btn-casestudies1 {
      padding: 13px 32px; } }

#myModal1 .modal-content {
  padding: 0 !important;
  background-color: transparent; }

#myModal1 .modal-body {
  padding: 0 !important; }

.download-form .popup-heading:after {
  background: url(/images/case-study/popup-heading-bg.png) 100% 100% no-repeat;
  content: "";
  position: absolute;
  right: 30px;
  bottom: 0;
  width: 108px;
  height: 88px; }

.download-form input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 20px 10px 20px 50px;
  font-size: 16px;
  font-family: "Montserrat",sans-serif;
  font-weight: 500;
  border: 0;
  border-bottom: 1px solid #000; }

.download-form .cs-name {
  background: url(/images/common/form-icon-1.png) 0 50% no-repeat; }

.download-form .cs-email {
  background: url(/images/common/form-icon-2.png) 0 50% no-repeat; }

.download-form .u-submit {
  display: block;
  text-align: center;
  border-radius: 5px;
  padding: 19px 0px;
  font-size: 18px;
  text-transform: uppercase;
  max-width: 350px;
  text-decoration: none;
  position: relative;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  color: #fff;
  border: 2px solid #FF5800;
  background-color: #FF5800;
  width: 250px;
  margin: 20px 0 0 0; }

.u-submit:after {
  content: '';
  background: url(/images/common/arrow-white.png) no-repeat;
  width: 20px;
  height: 14px;
  display: inline-block;
  margin-left: 15px;
  z-index: -1;
  display: none; }

.download-form p {
  font-family: "Montserrat",sans-serif;
  padding-top: 20px;
  font-size: 14px; }

#myModal1 button.close {
  z-index: 999;
  position: relative;
  right: -88% !important;
  top: 0 !important;
  background: 0 0 !important;
  padding: 10px;
  opacity: 1;
  width: 37px;
  text-align: center;
  background: transparent !important;
  float: right; }
  @media (min-width: 992px) {
    #myModal1 button.close {
      right: -50% !important;
      top: 5px !important;
      margin-left: 325px; } }

.o-thank-you__application {
  padding: 150px 0; }

.o-thank-you__top p {
  font-size: 14px;
  margin-bottom: 30px;
  font-family: "Montserrat",sans-serif; }

.o-thank-you__footer {
  text-align: center;
  padding: 50px 0;
  font-family: "Montserrat",sans-serif;
  font-weight: 300;
  color: #414446;
  font-size: 12px;
  line-height: 15px; }

.case-study-text {
  margin-top: 15px !important;
  display: inline-block !important; }

.mlnp-ch .row {
  flex-direction: row-reverse;
  padding: 60px 0; }

.mlnp-sol {
  padding: 60px 0; }
  .mlnp-sol .mlnp-sol-img {
    display: block;
    margin: 0 auto; }

.mlnp-res {
  padding-top: 65px; }
  .mlnp-res h3 {
    color: #fff; }
  .mlnp-res .padding-bottom-60.img-responsive.center-block {
    display: block;
    margin: 0 auto; }
  .mlnp-res h4 {
    line-height: 32px;
    padding-bottom: 20px;
    font-size: 24px !important;
    color: #333;
    margin-top: 50px !important; }

@media (min-width: 992px) {
  .faq-section .btn_wrapper {
    margin-top: 40px !important; } }

.case-study-bnr {
  background: #0a1b39;
  padding: 80px 0 0 0;
  min-height: 145px; }
  @media screen and (min-width: 992px) {
    .case-study-bnr {
      padding: 80px 0 0 0;
      min-height: 150px; } }
  @media screen and (min-width: 992px) {
    .case-study-bnr {
      min-height: 280px;
      padding: 130px 0 0 200px;
      background: url(/images/case-study/case-studies-banner.png) no-repeat 10% 100% #0a1b39; } }
  .case-study-bnr h1 {
    text-align: center;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    text-transform: uppercase;
    color: #36DF7D; }
    @media (min-width: 992px) {
      .case-study-bnr h1 {
        font-size: 62px; } }

@media (min-width: 992px) {
  .case-studies-class .o-case-studies-new {
    padding-top: 80px; } }

body.case-studies-details .o-skills-hero__case-studies__banner-content,
body.case-studies-details .o-skills-hero__case-studies__banner-content p,
body.case-studies-details .o-case-study__clients__the-client-content h3,
body.case-studies-details .o-case-study__clients__the-client-content p,
body.case-studies-details .o-case-study__clients__the-client-content h4,
body.case-studies-details .o-case-study__clients__the-client-content__services ul li,
body.case-studies-details .o-case-study__clients__the-client-content ul li {
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    body.case-studies-details .o-skills-hero__case-studies__banner-content,
    body.case-studies-details .o-skills-hero__case-studies__banner-content p,
    body.case-studies-details .o-case-study__clients__the-client-content h3,
    body.case-studies-details .o-case-study__clients__the-client-content p,
    body.case-studies-details .o-case-study__clients__the-client-content h4,
    body.case-studies-details .o-case-study__clients__the-client-content__services ul li,
    body.case-studies-details .o-case-study__clients__the-client-content ul li {
      font-family: "Montserrat", sans-serif; } }

body.case-studies-details .o-skills-hero {
  padding: 100px 0 50px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-skills-hero {
      padding: 100px 0 !important; }
      body.case-studies-details .o-skills-hero.padding-100 {
        padding: 120px 0 80px !important; }
        body.case-studies-details .o-skills-hero.padding-100 .o-skills-hero__case-studies__banner-image {
          padding-top: 30px !important; } }

body.case-studies-details .o-skills-hero__case-studies .container > .row {
  display: flex;
  align-items: center; }

body.case-studies-details .o-skills-hero__case-studies .col-sm-6.banner-top-position__center {
  position: relative;
  top: auto;
  transform: none; }
  @media screen and (min-width: 768px) {
    body.case-studies-details .o-skills-hero__case-studies .col-sm-6.banner-top-position__center {
      order: 2; } }

@media (min-width: 992px) {
  body.case-studies-details .o-skills-hero__case-studies__banner-image {
    padding-top: 0; } }

body.case-studies-details .cs-banner-pdf {
  margin: 15px auto 0; }
  @media (min-width: 992px) {
    body.case-studies-details .cs-banner-pdf {
      margin: 30px 0 0; } }

body.case-studies-details .o-case-study__clients .container {
  padding-left: 30px;
  padding-right: 30px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients .container {
      padding-left: 15px;
      padding-right: 15px; } }

body.case-studies-details .pad-80 {
  padding: 50px 0; }
  @media (min-width: 992px) {
    body.case-studies-details .pad-80 {
      padding: 80px 0; } }

body.case-studies-details .padding-80 {
  padding-top: 50px; }
  @media (min-width: 992px) {
    body.case-studies-details .padding-80 {
      padding-top: 80px; } }

body.case-studies-details .font-weight-600 {
  font-weight: 600 !important; }

body.case-studies-details .font-weight-700 {
  font-weight: 700 !important; }

body.case-studies-details .o-case-study__clients-first {
  text-align: center;
  padding-top: 50px; }
  @media screen and (min-width: 768px) {
    body.case-studies-details .o-case-study__clients-first {
      padding-top: 40px;
      text-align: left; } }
  body.case-studies-details .o-case-study__clients-first ul {
    text-align: left; }

body.case-studies-details .o-case-study__clients-title {
  font-size: 30px !important; }

body.case-studies-details .pad-80.o-case-study__clients-banner {
  padding-top: 0; }
  @media (min-width: 992px) {
    body.case-studies-details .pad-80.o-case-study__clients-banner {
      padding-top: 80px; } }

body.case-studies-details .o-case-study__clients-banner {
  padding-bottom: 50px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients-banner {
      padding-bottom: 80px; } }

body.case-studies-details .o-case-study__clients-banner .container {
  padding: 0; }

body.case-studies-details .o-case-study__clients-banner .o-case-study__clients__the-client-image img {
  width: 100%; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients-banner .o-case-study__clients__the-client-image img {
      width: auto; } }

body.case-studies-details .o-case-study__clients-banner .o-case-study__clients__the-client-content {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-top: 30px; }

body.case-studies-details .pad-bot-150 {
  padding-bottom: 105px; }
  @media (min-width: 992px) {
    body.case-studies-details .pad-bot-150 {
      padding-bottom: 150px; } }

body.case-studies-details .o-case-study__clients__the-client-content h3 {
  font-size: 24px;
  line-height: 30px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content h3 {
      font-size: 24px;
      line-height: 36px; } }

body.case-studies-details .o-case-study__clients__the-client-content h4 {
  font-size: 20px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content h4 {
      font-size: 30px; } }

body.case-studies-details .o-case-study__clients__the-client-content p,
body.case-studies-details .o-case-study__clients__the-client-content ul li {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content p,
    body.case-studies-details .o-case-study__clients__the-client-content ul li {
      font-size: 19px;
      line-height: 30px; } }

body.case-studies-details .o-case-study__clients__the-client-content p {
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content p {
      margin-bottom: 25px; } }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content p[class="font-weight-600"] {
      margin-bottom: 10px; } }

body.case-studies-details .o-case-study__clients__the-client-content > p:last-of-type {
  margin-bottom: 0; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content > p:last-of-type {
      margin-bottom: 25px; } }

body.case-studies-details .o-case-study__clients__the-client-content .font-size-24-i {
  font-size: 20px !important; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content .font-size-24-i {
      font-size: 24px !important; } }

body.case-studies-details .o-case-study__clients__the-client-content__services {
  padding-top: 0; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content__services {
      padding-top: 60px; } }

body.case-studies-details .o-case-study__clients__the-client-content__services ul {
  margin-bottom: 0; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content__services ul {
      margin-bottom: 15px; } }

body.case-studies-details .o-case-study__clients__the-client-content__services ul li {
  padding: 0 5px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content__services ul li {
      padding: 0 15px 15px; } }

body.case-studies-details .o-case-study__clients__the-client-content__services ul li:after {
  display: none; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content__services ul li:after {
      display: block; } }

body.case-studies-details .o-case-study__clients__the-client-content__services ul li span {
  font-size: 14px;
  line-height: normal; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content__services ul li span {
      font-size: 18px;
      line-height: 24px; } }

@media (min-width: 992px) {
  body.case-studies-details .o-case-study__clients__the-client-content__services + h3, body.case-studies-details .o-case-study__clients__the-client-content__services + h4 {
    margin-top: 20px; } }

body.case-studies-details .o-case-study__clients__the-client-image {
  text-align: center; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-image {
      text-align: left; } }

body.case-studies-details .padding-all-60 {
  padding: 30px; }
  @media (min-width: 992px) {
    body.case-studies-details .padding-all-60 {
      padding: 60px; } }

body.case-studies-details .o-case-study__clients__the-results {
  background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  padding-top: 55px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-results {
      padding-top: 100px; } }

body.case-studies-details .o-case-study__clients__the-results__div {
  padding-top: 0; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-results__div {
      padding-top: 10px; } }

body.case-studies-details .o-case-study__clients__the-client-content .the-results {
  top: -55px;
  max-width: 158px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content .the-results {
      top: -70px;
      max-width: none; } }

body.case-studies-details .o-case-study__clients__the-results .o-case-study__clients__the-client-content h3 {
  margin-bottom: 0; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-results .o-case-study__clients__the-client-content h3 {
      margin-bottom: 20px; } }

body.case-studies-details .o-case-study__clients__the-results__div h4,
body.case-studies-details .font-size-24-i {
  font-size: 20px;
  line-height: 28px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-results__div h4,
    body.case-studies-details .font-size-24-i {
      font-size: 30px;
      line-height: normal; } }

body.case-studies-details .o-case-study__clients__the-results__div p {
  font-size: 16px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-results__div p {
      font-size: 19px; } }

body.case-studies-details .results-content {
  padding-bottom: 5px; }
  @media (min-width: 992px) {
    body.case-studies-details .results-content {
      padding-bottom: 60px; } }

body.case-studies-details .case-study-download {
  margin-top: 50px;
  padding: 50px 0;
  background: url(/images/case-study/download-mob-bg.jpg) 50% 50% no-repeat #091b38;
  background-size: auto 100%; }
  @media (min-width: 992px) {
    body.case-studies-details .case-study-download {
      margin-top: 60px;
      padding: 30px 0;
      background: url(/images/case-study/download-bg.jpg) 50% 50% no-repeat #091b38;
      background-size: 100% auto; } }

body.case-studies-details .case-study-download img.download-icon {
  display: block;
  margin: 0 auto;
  float: none;
  max-width: 117px; }
  @media (min-width: 992px) {
    body.case-studies-details .case-study-download img.download-icon {
      max-width: 100%; } }

body.case-studies-details .cs-bot-banner-pdf {
  margin-top: 15px; }
  @media (min-width: 992px) {
    body.case-studies-details .cs-bot-banner-pdf {
      margin-top: 40px; } }

body.case-studies-details .case-studies-banner .cs-bot-banner-pdf {
  margin-top: 15px; }

body.case-studies-details .case-study-download h2 {
  font-size: 24px;
  line-height: 32px;
  max-width: 225px;
  margin: 0 auto; }
  @media (min-width: 992px) {
    body.case-studies-details .case-study-download h2 {
      font-size: 40px;
      line-height: 55px;
      max-width: none; } }

body.case-studies-thanks .hurry-section-cs .hurry .btn-orange {
  padding-right: 0 !important; }

body.case-studies-thanks .o-casestudies-thankyou {
  padding-bottom: 0;
  margin: 0; }
  @media (min-width: 992px) {
    body.case-studies-thanks .o-casestudies-thankyou {
      padding-bottom: 50px;
      margin-bottom: 290px; } }
  body.case-studies-thanks .o-casestudies-thankyou h1 {
    font-size: 30px;
    max-width: 225px;
    margin: 0 auto; }
    @media (min-width: 992px) {
      body.case-studies-thanks .o-casestudies-thankyou h1 {
        font-size: 40px;
        max-width: none; } }
  body.case-studies-thanks .o-casestudies-thankyou p {
    font-size: 18px; }
    @media (min-width: 992px) {
      body.case-studies-thanks .o-casestudies-thankyou p {
        font-size: 24px; } }

body.case-studies-thanks .survey-thank-pg {
  padding-top: 50px; }

body.case-studies-thanks .hurry {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  padding: 30px;
  transform: none; }
  @media (min-width: 992px) {
    body.case-studies-thanks .hurry {
      width: 70%;
      position: absolute;
      left: 50%;
      top: -200px;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      padding: 40px 80px 60px; } }
  body.case-studies-thanks .hurry h3 {
    font-size: 23px;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      body.case-studies-thanks .hurry h3 {
        font-size: 36px;
        margin-bottom: 10px; } }
  body.case-studies-thanks .hurry p {
    font-size: 16px;
    line-height: 28px; }
    @media (min-width: 992px) {
      body.case-studies-thanks .hurry p {
        font-size: 24px;
        line-height: 40px; } }
  body.case-studies-thanks .hurry .btn-center-block {
    width: auto; }
    @media (min-width: 992px) {
      body.case-studies-thanks .hurry .btn-center-block {
        width: 350px; } }
  body.case-studies-thanks .hurry .btn {
    width: auto;
    font-size: 16px; }
    @media (min-width: 992px) {
      body.case-studies-thanks .hurry .btn {
        font-size: 18px; } }

body.case-studies-thanks .t-left,
body.case-studies-thanks .t-right {
  width: 100%; }
  @media (min-width: 992px) {
    body.case-studies-thanks .t-left,
    body.case-studies-thanks .t-right {
      width: 48%; } }

body.case-studies-thanks .off-video-pan .off-video-txt {
  width: 100%; }
  @media (min-width: 992px) {
    body.case-studies-thanks .off-video-pan .off-video-txt {
      width: 45%; } }

body.case-studies-thanks .off-video {
  margin: 0 0 15px; }
  @media (min-width: 992px) {
    body.case-studies-thanks .off-video {
      margin-bottom: 0; } }

body.case-studies-thanks .post-pan ul li a {
  width: 68%; }
  @media (min-width: 992px) {
    body.case-studies-thanks .post-pan ul li a {
      width: 80%; } }

body.case-studies-thanks .thank-right-top ul li a {
  font-size: 13px; }
  @media (min-width: 992px) {
    body.case-studies-thanks .thank-right-top ul li a {
      font-size: 16px; } }

body.case-studies-thanks .btn {
  margin-left: auto !important;
  margin-right: auto !important; }
  @media (min-width: 992px) {
    body.case-studies-thanks .btn {
      margin-left: 0 !important; } }

body.case-studies-thanks .off-video-txt {
  margin-left: 0; }
  @media (min-width: 992px) {
    body.case-studies-thanks .off-video-txt {
      margin-left: 20px; } }

.case-studies-thanks .o-navigation-bar img {
  padding-top: 0; }
  @media (min-width: 992px) {
    .case-studies-thanks .o-navigation-bar img {
      padding-top: 15px; } }

.case-studies-thanks .o-casestudies-thankyou p {
  padding-bottom: 15px; }
  @media (min-width: 992px) {
    .case-studies-thanks .o-casestudies-thankyou p {
      padding-bottom: 40px; } }

.case-studies-thanks .t-right {
  min-height: inherit; }
  @media (min-width: 992px) {
    .case-studies-thanks .t-right {
      min-height: 368px; } }

.case-studies-thanks .o-footer p {
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .case-studies-thanks .o-footer p {
      font-family: "Montserrat", sans-serif; } }

.hurry-section-cs {
  margin-top: 45px; }
  @media (min-width: 992px) {
    .hurry-section-cs {
      margin-top: 0; } }

.o-skills-hero__case-studies .mobile-off {
  display: none !important; }
  @media (min-width: 992px) {
    .o-skills-hero__case-studies .mobile-off {
      display: inline-block !important; } }

.o-skills-hero__case-studies .desktop-off {
  display: inline-block !important; }
  @media (min-width: 992px) {
    .o-skills-hero__case-studies .desktop-off {
      display: none !important; } }

.o-case-study__clients__the-client-image picture img[src="images/case-study/the-solution.jpg"] {
  margin-bottom: 40px; }

.o-case-study__clients__the-client-image picture img[src="images/case-study/the-solution.png"] {
  margin-bottom: 60px; }

@media (min-width: 992px) {
  .ch-content {
    padding-left: 25px !important; } }

@media (min-width: 992px) {
  .sol-content,
  .cli-content {
    padding-left: 25px !important; } }

@media (min-width: 992px) {
  .sol {
    padding-top: 80px; } }

.small-img-text {
  margin-bottom: 20px;
  align-items: center;
  padding: 50px 30px 0; }
  @media (min-width: 992px) {
    .small-img-text {
      align-items: flex-start;
      padding: 0; } }
  .small-img-text h2 {
    max-width: 290px;
    padding-left: 20px;
    padding-top: 0;
    font-size: 28px;
    line-height: 36px; }
    @media (min-width: 992px) {
      .small-img-text h2 {
        font-size: 42px;
        line-height: 52px;
        padding-top: 10px; } }

.new-style h3 {
  font-size: 30px;
  line-height: 46px;
  font-weight: 500; }

.case-studies-details .gradient-green-blue {
  background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%); }

form#brandfilter {
  display: inline-block; }

.case-studies-class .process-section h2 {
  margin-bottom: 20px !important; }

.o-skills-hero__case-studies .row {
  flex-direction: column-reverse; }
  @media screen and (min-width: 768px) {
    .o-skills-hero__case-studies .row {
      flex-direction: inherit; } }

.case-study-bnr:after {
  background-size: 70%; }

.o-case-studies-slider .slick-track {
  background: #fff; }

.list-box {
  margin-bottom: 25px !important; }
  .list-box li {
    padding: 15px !important; }
    .list-box li:before {
      display: none; }
    .list-box li p {
      margin-bottom: 10px !important;
      font-size: 16px !important;
      padding-left: 30px; }
    .list-box li .title {
      position: relative;
      padding-left: 30px;
      margin-bottom: 20px !important;
      font-size: 19px !important; }
      .list-box li .title:before {
        width: 20px;
        height: 20px;
        top: 5px;
        background-size: 100%;
        content: '';
        background-image: url(/images/common/green-arrow.png);
        position: absolute;
        left: 0; }

.cs-table table {
  font-size: 14px;
  color: #333; }
  .cs-table table tr td {
    font-weight: 500; }

.cs-finance-img-wrp {
  text-align: left; }
  .cs-finance-img-wrp .l-15 {
    margin-left: 15px; }

.green-arrow {
  background: url(/images/case-study/green-right-arrow.png) no-repeat center center;
  width: 11px;
  height: 17px;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 9px;
  background-size: 8px; }

.dm-box {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid #05b281;
  margin-right: 5px;
  display: none; }

.table td {
  position: relative; }

@media (min-width: 992px) {
  .results-content .table {
    margin: 20px 0 30px; } }

.car-box {
  margin-bottom: 30px; }
  .car-box img {
    box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    border-width: 1px 1px 1px 1px;
    border-color: #ead6d6;
    border-style: solid;
    border-bottom-color: #e6e0e0; }

/* ANCHOR Service pages*/
.font-bold {
  font-weight: 700; }

/** AI & Chatbots **/
.ai-pattern-bl:before {
  background: url(images/ai-chatbots/bottom-left-pattern.png) no-repeat bottom left;
  bottom: 0px;
  height: auto; }

.ai-pattern-bl:after {
  display: none; }

.ai-chatbots {
  padding-bottom: 50px;
  background-color: #f8f8f8; }
  .ai-chatbots h2 {
    padding: 0 50px;
    margin-bottom: 50px;
    margin-bottom: 20px;
    font-size: 30px; }
    @media (min-width: 992px) {
      .ai-chatbots h2 {
        font-size: 48px; } }
  .ai-chatbots h3 {
    text-align: center;
    font-size: 36px;
    font-size: 28px;
    margin-bottom: 25px;
    margin-top: 30px; }
  .ai-chatbots .section-para {
    font-size: 19px;
    line-height: 29px;
    text-align: center;
    font-size: 16px;
    line-height: 28px; }
  .ai-chatbots .dc-white-pan {
    min-height: inherit;
    height: auto;
    min-height: inherit;
    box-shadow: 0px 0px 34px 0px #0000001c;
    height: auto; }
    @media (min-width: 992px) {
      .ai-chatbots .dc-white-pan {
        height: 180px; } }
    .ai-chatbots .dc-white-pan div span {
      position: absolute;
      width: 88px;
      height: 88px;
      left: 0; }
      .ai-chatbots .dc-white-pan div span img {
        margin-right: 20px; }
  .ai-chatbots .body-text-medium {
    font-size: 16px;
    line-height: 28px;
    color: #333333;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    margin-top: 15px;
    padding-left: 0;
    padding-top: 100px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .ai-chatbots .body-text-medium {
        font-size: 15px;
        line-height: 24px;
        padding-left: 100px;
        padding-top: 0;
        text-align: left; } }
    @media screen and (min-width: 1200px) {
      .ai-chatbots .body-text-medium {
        font-size: 18px;
        line-height: 28px; } }
  .ai-chatbots .correct-img {
    text-align: inherit;
    text-align: center; }
    .ai-chatbots .correct-img img {
      width: 100%;
      margin: 0;
      width: 300px;
      margin: 0 auto; }
  .ai-chatbots .number-section-bnt {
    text-align: center;
    margin-bottom: 25px; }
    .ai-chatbots .number-section-bnt a {
      width: 470px;
      height: 72px;
      margin: 0 15px 15px;
      font-size: 24px;
      width: 470px;
      display: inline-block;
      padding: 10px 50px 10px 15px;
      line-height: 46px;
      background: transparent;
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 13px;
      font-family: "Montserrat", sans-serif;
      border-radius: 6px;
      border: 3px solid #14DF7D;
      margin-bottom: 15px;
      transition: .3s all ease-in; }
      .ai-chatbots .number-section-bnt a:before {
        content: '';
        position: absolute;
        background: url(images/common/right-circle-icon.png) no-repeat right center;
        height: 35px;
        width: 35px;
        top: 15px;
        right: 10px;
        right: 30px; }
      .ai-chatbots .number-section-bnt a:hover {
        background: #fff;
        color: #FF5800;
        border: 3px solid #FF5800; }

/*CHeaper and better scss*/
.text-capitalize {
  text-transform: capitalize !important; }

.skill-mod {
  padding: 45px 0 47px;
  background-size: 30% auto;
  text-align: center; }
  @media (min-width: 992px) {
    .skill-mod {
      padding: 70px 0 75px; } }
  .skill-mod h2 {
    font-family: "Montserrat",sans-serif;
    color: #333;
    margin-bottom: 25px;
    font-size: 30px;
    line-height: 36px; }
    @media (min-width: 992px) {
      .skill-mod h2 {
        font-size: 48px;
        line-height: 61px; } }
  .skill-mod p {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #414446;
    font-size: 19px;
    line-height: 30px;
    padding-right: 80px;
    padding-left: 80px; }
  .skill-mod .holder {
    text-align: left;
    margin-bottom: 20px; }
    .skill-mod .holder .listing-heading {
      text-align: left;
      padding-bottom: 35px;
      display: flex;
      align-items: center; }
      .skill-mod .holder .listing-heading img.listing-heading-icon {
        display: inline-block;
        vertical-align: top;
        margin: 0; }
      .skill-mod .holder .listing-heading h3.listing-heading-txt {
        display: inline-block;
        margin: 0;
        padding-left: 30px; }
        @media (min-width: 992px) {
          .skill-mod .holder .listing-heading h3.listing-heading-txt {
            font-size: 36px;
            line-height: 49px; } }
    .skill-mod .holder ul {
      text-align: left;
      margin: 0px 0px 25px 0; }
      .skill-mod .holder ul li {
        padding: 0 0 5px 35px;
        line-height: 28px;
        position: relative;
        display: block;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        color: #414446;
        font-size: 17px; }
        @media (min-width: 992px) {
          .skill-mod .holder ul li {
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
            padding: 0 0 15px 35px; } }
        .skill-mod .holder ul li:before {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 2px;
          left: 0px;
          background: url(/images/common/icon-bullet-dark.svg) 50% 50% no-repeat;
          background-size: auto;
          background-size: contain; }
  .skill-mod h5 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 300;
    margin: 0px 0px 35px 0px;
    font-family: "Montserrat", sans-serif; }

img.cb-wecare-heading.cb-wecare-heading-img {
  width: 130px; }
  @media (min-width: 992px) {
    img.cb-wecare-heading.cb-wecare-heading-img {
      width: auto; } }

.tech-mod {
  padding: 45px 0 20px;
  background-size: 30% auto;
  text-align: center;
  /* media query for tablet potrait */ }
  @media (min-width: 992px) {
    .tech-mod {
      padding: 70px 0 50px; } }
  .tech-mod h2 {
    font-family: "Montserrat",sans-serif;
    color: #333;
    margin-bottom: 25px;
    text-align: center; }
  .tech-mod h5 {
    font-weight: 400;
    padding-bottom: 40px;
    font-size: 18px;
    line-height: 30px;
    text-align: center; }
  .tech-mod--no-background {
    background: none;
    /* media query for tablet landscape */
    padding: 100px 0 120px 0; }
  .tech-mod--inline-block {
    /* media query for tablet landscape */
    width: 49%;
    vertical-align: top;
    /* media query for small desktop and above */
    display: inline-block;
    float: none;
    width: 24%; }
  .tech-mod__blocks {
    border-radius: 6px;
    font-size: 18px;
    line-height: 26px;
    padding: 40px;
    margin-bottom: 30px;
    background-color: #fff;
    margin-top: 8px; }
    .tech-mod__blocks h5 {
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
      /* media query for tablet landscape */
      font-size: 24px;
      line-height: 32px; }
    .tech-mod__blocks h3 {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 20px;
      /* media query for tablet landscape */
      font-size: 24px;
      line-height: 32px; }
    .tech-mod__blocks span.technologies-sub-heaidng {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      /* media query for tablet landscape */
      font-size: 24px;
      line-height: 32px; }
    .tech-mod__blocks__icons .tech-icon {
      width: 132px;
      height: 132px;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 25px; }
      .tech-mod__blocks__icons .tech-icon img {
        display: block;
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%; }
      .tech-mod__blocks__icons .tech-icon:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        background-image: url("//assets/images/common/why-us-oval.svg"); }
    .tech-mod__blocks__lists {
      text-align: left;
      width: 147px;
      margin: 27px auto 0 auto; }
      .tech-mod__blocks__lists li {
        line-height: 30px;
        margin-bottom: 14px;
        position: relative;
        white-space: nowrap;
        height: 30px; }
        .tech-mod__blocks__lists li i {
          width: 30px;
          display: inline-block;
          margin-right: 20px; }
        .tech-mod__blocks__lists li a {
          color: #333;
          font-size: 14px;
          line-height: 30px;
          display: inline-block;
          vertical-align: top; }
          .tech-mod__blocks__lists li a:hover {
            color: #666666; }
        .tech-mod__blocks__lists li:last-child:after {
          border: none; }
        .tech-mod__blocks__lists li:after {
          position: absolute;
          content: "";
          left: 48px;
          bottom: -8px;
          width: 95px;
          height: 1px;
          border-bottom: 1px solid #E7E7E7; }
  .tech-mod__samefee h2 {
    margin-bottom: 40px;
    text-align: center; }
  .tech-mod__samefee h5 {
    font-weight: 600;
    padding-bottom: 40px;
    text-align: center; }
  .tech-mod__samefee .samefee-left-pan {
    background-color: #fff;
    text-align: center;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 10px 11px 45px 0px #e3e3e3;
    -moz-box-shadow: 10px 11px 45px 0px #e3e3e3;
    box-shadow: 10px 11px 45px 0px #e3e3e3;
    padding-top: 60px;
    padding-bottom: 60px;
    /* mobile first for tablet portrait */
    padding-top: 110px; }
    .tech-mod__samefee .samefee-left-pan h5 {
      font-size: 20px;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 10px;
      padding-bottom: 40px; }
    .tech-mod__samefee .samefee-left-pan img {
      margin-bottom: 35px; }
    .tech-mod__samefee .samefee-left-pan p {
      padding-left: 15px;
      padding-right: 15px;
      padding-left: 40px;
      padding-right: 40px;
      font-size: 18px;
      line-height: 32px;
      font-family: "Montserrat", sans-serif;
      font-weight: 300;
      color: #414446; }
  .tech-mod__samefee .samefee-mid-pan {
    padding-top: 5%;
    padding-bottom: 6%;
    /* mobile first for tablet portrait */
    padding-top: 20%; }
  .tech-mod__samefee .samefee-right-pan {
    background-color: #fff;
    text-align: center;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 10px 11px 45px 0px #e3e3e3;
    -moz-box-shadow: 10px 11px 45px 0px #e3e3e3;
    box-shadow: 10px 11px 45px 0px #e3e3e3;
    padding: 30px 0; }
    .tech-mod__samefee .samefee-right-pan h3 {
      background: url("images/common/cheaper-and-better-heading-bg.png") no-repeat 50% 0;
      margin-bottom: 20px;
      font-size: 30px;
      font-family: 'Caveat', cursive;
      font-weight: normal; }
    .tech-mod__samefee .samefee-right-pan .samefee-right-pan-holder {
      width: 100%;
      position: relative;
      display: inline-block;
      padding: 30px 20px;
      vertical-align: top;
      /* mobile first for tablet portrait */
      width: 32%;
      width: 33%; }
      .tech-mod__samefee .samefee-right-pan .samefee-right-pan-holder h5 {
        font-size: 20px;
        padding-bottom: 10px;
        padding-bottom: 40px; }
      .tech-mod__samefee .samefee-right-pan .samefee-right-pan-holder p {
        font-size: 18px;
        line-height: 32px;
        font-family: "Montserrat", sans-serif;
        font-weight: 300;
        color: #414446; }
      .tech-mod__samefee .samefee-right-pan .samefee-right-pan-holder img {
        margin-bottom: 35px; }
    .tech-mod__samefee .samefee-right-pan .samefee-right-pan-mid {
      border-left: 0;
      border-right: 0;
      border-top: 1px solid #E7E7E7;
      border-bottom: 1px solid #E7E7E7;
      /* mobile first for tablet portrait */
      border-left: 1px solid #E7E7E7;
      border-right: 1px solid #E7E7E7;
      border-top: 0;
      border-bottom: 0; }
      .tech-mod__samefee .samefee-right-pan .samefee-right-pan-mid:after {
        position: absolute;
        right: -17px;
        top: 24%;
        content: "";
        background: url("images/common/cheaper-and-better-icon-9.png") no-repeat 0 0;
        width: 34px;
        height: 34px;
        display: none;
        /* mobile first for tablet portrait */
        display: block; }
      .tech-mod__samefee .samefee-right-pan .samefee-right-pan-mid:before {
        position: absolute;
        left: -17px;
        top: 24%;
        content: "";
        background: url("images/common/cheaper-and-better-icon-9.png") no-repeat 0 0;
        width: 34px;
        height: 34px;
        display: none;
        /* mobile first for tablet portrait */
        display: block; }
  .tech-mod .js-tech-col {
    min-height: inherit;
    min-height: 735px;
    min-height: 608px; }

.margin-top-30-i {
  margin-top: 30px !important; }

.margin-top-50-i {
  margin-top: 50px !important; }

.cb-wecare .margin-top-50-i {
  margin-top: 30px !important; }
  @media (min-width: 992px) {
    .cb-wecare .margin-top-50-i {
      margin-top: 50px !important; } }

.o-apply-now {
  padding: 50px 0 50px;
  background: #00b35d;
  background: -moz-linear-gradient(-45deg, #00b35d 0%, #1db39b 16%, #28b2b3 24%, #004475 100%);
  background: -webkit-linear-gradient(-45deg, #00b35d 0%, #1db39b 16%, #28b2b3 24%, #004475 100%);
  background: linear-gradient(135deg, #00b35d 0%, #1db39b 16%, #28b2b3 24%, #004475 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b35d', endColorstr='#004475',GradientType=1 );
  position: relative;
  text-align: center; }
  @media (min-width: 992px) {
    .o-apply-now {
      padding: 70px 0 75px; } }
  .o-apply-now h5 {
    color: #fff;
    font-size: 36px;
    line-height: 48px;
    font-weight: 400;
    margin-bottom: 40px; }

.hiring-section .margin-top-50-i {
  margin-top: 5px !important; }
  @media (min-width: 992px) {
    .hiring-section .margin-top-50-i {
      margin-top: 50px !important; } }

.big-data {
  padding: 35px 0 20px; }
  @media (min-width: 992px) {
    .big-data {
      padding: 70px 0; } }
  .big-data p {
    font-family: "Montserrat",sans-serif; }
  .big-data .heighlight-txt {
    font-family: "Montserrat",sans-serif; }
    .big-data .heighlight-txt span {
      font-family: "Montserrat",sans-serif; }
  .big-data .right-banner-txt {
    width: 90%;
    margin: 0 auto;
    width: 58.33333333%;
    margin: 0; }

.font-weight-400 {
  font-weight: 400 !important; }

/*Work with page*/
.u-pad-top-30 {
  padding-top: 30px; }

.margin-left-0 {
  margin-left: 0 !important; }

.work-bnr {
  padding: 35px 0 65px 0; }
  .work-bnr .margin-25 {
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .work-bnr .margin-25 {
        margin-bottom: 25px; } }
  .work-bnr h1 {
    font-size: 20px;
    line-height: 28px;
    padding-top: 40px;
    font-weight: 400;
    padding-top: 50px;
    text-align: center; }
    @media (min-width: 992px) {
      .work-bnr h1 {
        text-align: left;
        font-size: 24px;
        line-height: 34px;
        padding-top: 95px; } }

.o-two-col-section-mod {
  background: url(/images/common/bottom-left-pt.png) left bottom no-repeat;
  background-size: 30% auto !important; }
  .o-two-col-section-mod h2 {
    text-align: center;
    font-size: 24px;
    line-height: 28px; }
    @media (min-width: 992px) {
      .o-two-col-section-mod h2 {
        font-size: 42px;
        line-height: 52px; } }
  .o-two-col-section-mod p {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #414446;
    font-size: 18px;
    line-height: 28px;
    margin-top: 30px; }

.o-two-col-section__key-traits {
  text-align: center;
  background-color: #F9F9F9;
  position: relative;
  text-align: center; }
  .o-two-col-section__key-traits h2 {
    margin-bottom: 30px;
    font-size: 30px; }
    @media (min-width: 992px) {
      .o-two-col-section__key-traits h2 {
        font-size: 48px; } }
  .o-two-col-section__key-traits p {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #414446;
    font-size: 18px;
    line-height: 30px;
    margin-top: 0; }
    @media (min-width: 992px) {
      .o-two-col-section__key-traits p {
        margin-top: 30px; } }
  .o-two-col-section__key-traits ul {
    height: 420px;
    height: auto;
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .o-two-col-section__key-traits ul {
        padding-top: 50px; } }
    .o-two-col-section__key-traits ul:after {
      clear: both;
      content: ""; }
    .o-two-col-section__key-traits ul li .style_prevu_kit {
      margin-bottom: 20px;
      width: 100%;
      overflow: hidden;
      display: inline-block;
      border: 0;
      height: auto;
      position: relative;
      transition: all 400ms ease-in;
      transform: scale(1);
      cursor: pointer;
      border-radius: 6px;
      box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.05);
      padding: 30px 20px;
      border: 1px solid #e0e0e0; }
      @media (min-width: 992px) {
        .o-two-col-section__key-traits ul li .style_prevu_kit {
          height: 310px;
          padding: 80px 0; } }
      .o-two-col-section__key-traits ul li .style_prevu_kit img {
        margin-bottom: 40px;
        margin-bottom: 0; }
      .o-two-col-section__key-traits ul li .style_prevu_kit h2 {
        font-size: 22px;
        line-height: 28px;
        text-transform: none;
        padding-top: 20px;
        display: none; }
        @media (min-width: 992px) {
          .o-two-col-section__key-traits ul li .style_prevu_kit h2 {
            display: block; } }
        .o-two-col-section__key-traits ul li .style_prevu_kit h2 br {
          display: none; }
          @media (min-width: 992px) {
            .o-two-col-section__key-traits ul li .style_prevu_kit h2 br {
              display: block; } }
      .o-two-col-section__key-traits ul li .style_prevu_kit .icon-text-hover {
        opacity: 1; }
        @media (min-width: 992px) {
          .o-two-col-section__key-traits ul li .style_prevu_kit .icon-text-hover {
            opacity: 0; } }
      .o-two-col-section__key-traits ul li .style_prevu_kit h3 {
        text-align: center;
        margin: 15px 0;
        font-size: 20px; }
        @media (min-width: 992px) {
          .o-two-col-section__key-traits ul li .style_prevu_kit h3 {
            margin-bottom: 50px;
            font-size: 22px;
            margin: 30px 0; } }
      @media (hover: hover) and (pointer: fine) {
        .o-two-col-section__key-traits ul li .style_prevu_kit:hover {
          box-shadow: 0px 0px 10px #ccc;
          z-index: 2;
          -webkit-transition: all 400ms ease-in;
          -webkit-transform: scale(1.4);
          -ms-transition: all 400ms ease-in;
          -ms-transform: scale(1.4);
          -moz-transition: all 400ms ease-in;
          -moz-transform: scale(1.4);
          transition: all 400ms ease-in;
          transform: scale(1.4);
          background-color: #094A79;
          color: #fff;
          padding: 30px 0; }
          .o-two-col-section__key-traits ul li .style_prevu_kit:hover .icon-text-hover {
            opacity: 1;
            padding: 20px;
            padding: 0; }
            .o-two-col-section__key-traits ul li .style_prevu_kit:hover .icon-text-hover h3 {
              font-size: 15px;
              line-height: 22px;
              color: #fff;
              text-align: left;
              margin-bottom: 50px;
              font-size: 18px;
              line-height: 28px;
              margin: 0;
              color: #333333;
              text-align: center;
              padding: 0; }
            .o-two-col-section__key-traits ul li .style_prevu_kit:hover .icon-text-hover p {
              color: #fff;
              font-size: 11px;
              line-height: 16px;
              margin-top: 15px;
              text-align: left;
              font-size: 12px;
              line-height: 18px;
              margin-top: 30px;
              color: #333333;
              text-align: center; }
          .o-two-col-section__key-traits ul li .style_prevu_kit:hover img, .o-two-col-section__key-traits ul li .style_prevu_kit:hover .o-two-col-section__key-traits ul li .style_prevu_kit:hover h2 {
            display: inline-block;
            display: none; }
          .o-two-col-section__key-traits ul li .style_prevu_kit:hover h2 {
            display: none; } }

.o-two-col-section__choosing-steps {
  background: url(/images/common/bottom-left-pt.png) bottom left no-repeat;
  background-size: 30% auto !important;
  padding-top: 80px;
  position: relative; }
  .o-two-col-section__choosing-steps h2 {
    margin-bottom: 30px;
    font-size: 30px; }
    @media (min-width: 992px) {
      .o-two-col-section__choosing-steps h2 {
        font-size: 45px;
        margin-bottom: 50px; } }
  .o-two-col-section__choosing-steps .steps-holder {
    padding: 10px 0;
    margin-bottom: 20px;
    clear: both;
    display: table;
    width: 100%; }
    @media (min-width: 992px) {
      .o-two-col-section__choosing-steps .steps-holder {
        padding: 10px;
        margin-bottom: 50px; } }
    .o-two-col-section__choosing-steps .steps-holder .steps-point {
      position: absolute;
      width: 20px;
      height: 20px;
      border: 3px solid #3E93DA;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      background-color: #fff;
      z-index: 1; }
    .o-two-col-section__choosing-steps .steps-holder .step-left-img {
      float: none;
      width: 100%;
      text-align: center;
      padding-bottom: 40px;
      padding-top: 0; }
      @media (min-width: 992px) {
        .o-two-col-section__choosing-steps .steps-holder .step-left-img {
          float: left;
          width: 33%;
          text-align: left;
          padding-bottom: 0;
          padding-top: 50px; } }
      .o-two-col-section__choosing-steps .steps-holder .step-left-img img {
        display: block;
        margin: 0;
        margin: 0 auto;
        display: inherit; }
    .o-two-col-section__choosing-steps .steps-holder .step-right-content {
      float: none;
      width: 100%;
      box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
      padding: 40px 15px;
      border-radius: 6px;
      border: 1px solid #E0E0E0; }
      @media (min-width: 992px) {
        .o-two-col-section__choosing-steps .steps-holder .step-right-content {
          float: right;
          width: 67%;
          padding: 40px; } }
      .o-two-col-section__choosing-steps .steps-holder .step-right-content span {
        font-size: 20px;
        font-weight: 500;
        color: #3E93DA;
        padding-bottom: 25px;
        text-align: center;
        display: block; }
        @media (min-width: 992px) {
          .o-two-col-section__choosing-steps .steps-holder .step-right-content span {
            text-align: left;
            font-family: "Montserrat", sans-serif; } }
      .o-two-col-section__choosing-steps .steps-holder .step-right-content h3 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 29px;
        text-transform: none;
        font-family: "Montserrat",sans-serif;
        font-weight: 600;
        color: #333; }
        @media (min-width: 992px) {
          .o-two-col-section__choosing-steps .steps-holder .step-right-content h3 {
            text-align: left;
            margin-bottom: 0;
            font-size: 24px; } }
      .o-two-col-section__choosing-steps .steps-holder .step-right-content p {
        padding-bottom: 20px;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #333;
        padding-bottom: 0 !important;
        text-align: center; }
        @media (min-width: 992px) {
          .o-two-col-section__choosing-steps .steps-holder .step-right-content p {
            font-size: 18px;
            line-height: 32px;
            text-align: left;
            font-family: "Montserrat", sans-serif; } }
      .o-two-col-section__choosing-steps .steps-holder .step-right-content ul li {
        padding-left: 20px;
        padding-bottom: 20px;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        line-height: 24px;
        color: #333;
        text-align: center;
        background: url(/images/work-with-the-top-developers/blue-tick.png) 0 8px no-repeat; }
        @media (min-width: 992px) {
          .o-two-col-section__choosing-steps .steps-holder .step-right-content ul li {
            font-size: 18px;
            line-height: 32px;
            text-align: left;
            font-family: "Montserrat", sans-serif; } }
        .o-two-col-section__choosing-steps .steps-holder .step-right-content ul li strong {
          font-weight: 600; }
  @media (min-width: 992px) {
    .o-two-col-section__choosing-steps .u-text-left {
      text-align: left; } }
  .o-two-col-section__choosing-steps .step-1 {
    position: relative; }
    .o-two-col-section__choosing-steps .step-1:before {
      border-bottom: 1px dashed #3E93DA;
      height: 1px;
      position: absolute;
      left: 320px;
      top: 200px;
      content: "";
      width: 61px;
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-two-col-section__choosing-steps .step-1:before {
          display: block; } }
    .o-two-col-section__choosing-steps .step-1:after {
      border-left: 1px dashed #3E93DA;
      height: 200px;
      position: absolute;
      left: 320px;
      top: 0;
      content: "";
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-two-col-section__choosing-steps .step-1:after {
          display: block; } }
    .o-two-col-section__choosing-steps .step-1 .steps-point {
      position: absolute;
      left: 310px;
      top: 0;
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-two-col-section__choosing-steps .step-1 .steps-point {
          display: block; } }
  .o-two-col-section__choosing-steps .step-2 {
    position: relative;
    padding: 10px 0;
    margin-bottom: 50px;
    clear: both;
    display: table;
    width: 100%; }
    @media (min-width: 992px) {
      .o-two-col-section__choosing-steps .step-2 {
        padding: 10px; } }
    .o-two-col-section__choosing-steps .step-2:before {
      border-bottom: 1px dashed #3E93DA;
      height: 1px;
      position: absolute;
      right: 320px;
      top: 150px;
      content: "";
      width: 61px;
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-two-col-section__choosing-steps .step-2:before {
          display: block; } }
    .o-two-col-section__choosing-steps .step-2:after {
      border-left: 1px dashed #3E93DA;
      height: 210px;
      position: absolute;
      right: 320px;
      top: -60px;
      content: "";
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-two-col-section__choosing-steps .step-2:after {
          display: block; } }
    .o-two-col-section__choosing-steps .step-2 .steps-point-2 {
      position: absolute;
      right: 310px;
      top: 40px;
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-two-col-section__choosing-steps .step-2 .steps-point-2 {
          display: block; } }
  .o-two-col-section__choosing-steps .step-3 {
    position: relative; }
    .o-two-col-section__choosing-steps .step-3:before {
      border-bottom: 1px dashed #3E93DA;
      height: 1px;
      position: absolute;
      left: 320px;
      top: 200px;
      content: "";
      width: 61px;
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-two-col-section__choosing-steps .step-3:before {
          display: block; } }
    .o-two-col-section__choosing-steps .step-3:after {
      border-left: 1px dashed #3E93DA;
      height: 260px;
      position: absolute;
      left: 320px;
      top: -60px;
      content: "";
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-two-col-section__choosing-steps .step-3:after {
          display: block; } }
    .o-two-col-section__choosing-steps .step-3 .steps-point-3 {
      position: absolute;
      left: 310px;
      top: 50px;
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-two-col-section__choosing-steps .step-3 .steps-point-3 {
          display: block; } }
  .o-two-col-section__choosing-steps .step-4 {
    position: relative;
    padding: 10px 0;
    margin-bottom: 20px;
    clear: both;
    display: table;
    width: 100%; }
    @media (min-width: 992px) {
      .o-two-col-section__choosing-steps .step-4 {
        padding: 10px;
        margin-bottom: 50px; } }
    .o-two-col-section__choosing-steps .step-4:before {
      border-bottom: 1px dashed #3E93DA;
      height: 1px;
      position: absolute;
      right: 320px;
      top: 150px;
      content: "";
      width: 61px;
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-two-col-section__choosing-steps .step-4:before {
          display: block; } }
    .o-two-col-section__choosing-steps .step-4:after {
      border-left: 1px dashed #3E93DA;
      height: 210px;
      position: absolute;
      right: 320px;
      top: -60px;
      content: "";
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-two-col-section__choosing-steps .step-4:after {
          display: block; } }
    .o-two-col-section__choosing-steps .step-4 .steps-point-4 {
      position: absolute;
      right: 310px;
      top: 50px;
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-two-col-section__choosing-steps .step-4 .steps-point-4 {
          display: block; } }
  .o-two-col-section__choosing-steps .step-5 {
    position: relative; }
    .o-two-col-section__choosing-steps .step-5:before {
      border-bottom: 1px dashed #3E93DA;
      height: 1px;
      position: absolute;
      left: 320px;
      top: 200px;
      content: "";
      width: 61px;
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-two-col-section__choosing-steps .step-5:before {
          display: block; } }
    .o-two-col-section__choosing-steps .step-5:after {
      border-left: 1px dashed #3E93DA;
      height: 260px;
      position: absolute;
      left: 320px;
      top: -60px;
      content: "";
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-two-col-section__choosing-steps .step-5:after {
          display: block; } }
    .o-two-col-section__choosing-steps .step-5 .steps-point-5 {
      position: absolute;
      left: 310px;
      top: 50px;
      display: none; }
      @media screen and (min-width: 1200px) {
        .o-two-col-section__choosing-steps .step-5 .steps-point-5 {
          display: block; } }

.o-two-col-section__why-choose-us-mod {
  background-size: 30% auto !important;
  background-color: #F9F9F9;
  text-align: center;
  padding: 80px 0 60px; }
  .o-two-col-section__why-choose-us-mod h2 {
    margin-bottom: 30px; }
  .o-two-col-section__why-choose-us-mod p {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    line-height: 28px; }
    @media (min-width: 992px) {
      .o-two-col-section__why-choose-us-mod p {
        padding: 0; } }

.u-align-right {
  text-align: center; }
  @media (min-width: 992px) {
    .u-align-right {
      text-align: right !important; } }

.u-pull-right {
  float: none !important; }
  @media (min-width: 992px) {
    .u-pull-right {
      float: right !important; } }

/*CN uses AI*/
.o-skills-hero--cn-uses-ai {
  padding: 35px 0;
  padding: 35px 0 0 0; }
  .o-skills-hero--cn-uses-ai .container {
    position: relative;
    padding-bottom: 0; }
    .o-skills-hero--cn-uses-ai .container .machine-img {
      position: absolute;
      right: 0;
      bottom: 0; }
    .o-skills-hero--cn-uses-ai .container .extra-padding {
      padding-bottom: 80px;
      padding-bottom: 0; }
  .o-skills-hero--cn-uses-ai .margin-25 {
    margin-bottom: 25px; }
  .o-skills-hero--cn-uses-ai h1 {
    font-size: 24px;
    line-height: 32px;
    padding-top: 0;
    text-align: center; }
    @media (min-width: 992px) {
      .o-skills-hero--cn-uses-ai h1 {
        font-size: 46px;
        line-height: 58px;
        text-align: left; } }
  .o-skills-hero--cn-uses-ai p {
    font-size: 16px;
    line-height: 24px;
    font-family: "Montserrat", sans-serif;
    margin: 0px 0px 25px 0px;
    padding: 0px 0px 0px 0px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-skills-hero--cn-uses-ai p {
        font-size: 20px;
        line-height: 32px;
        text-align: left;
        font-family: "Montserrat", sans-serif; } }
    @media screen and (min-width: 1200px) {
      .o-skills-hero--cn-uses-ai p {
        font-size: 22px;
        line-height: 32px; } }

.o-skill-intro--cn-uses-ai {
  background-size: auto;
  background-size: 30% auto;
  text-align: center;
  padding: 45px 0 20px; }
  @media (min-width: 992px) {
    .o-skill-intro--cn-uses-ai {
      padding: 70px 0 47px; } }
  .o-skill-intro--cn-uses-ai h3 {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 28px; }
    @media (min-width: 992px) {
      .o-skill-intro--cn-uses-ai h3 {
        font-size: 36px;
        line-height: 44px; } }
  .o-skill-intro--cn-uses-ai p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 28px; }
    @media (min-width: 992px) {
      .o-skill-intro--cn-uses-ai p {
        font-size: 18px;
        line-height: 30px;
        font-family: "Montserrat", sans-serif; } }

.o-skill-intro--puzzle {
  background-color: #F9FBFC;
  background-image: none;
  padding: 40px 0 20px;
  background-size: auto;
  background-size: 30% auto; }
  @media (min-width: 992px) {
    .o-skill-intro--puzzle {
      padding: 70px 0 50px; } }
  .o-skill-intro--puzzle p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 28px;
    text-align: center; }
    @media (min-width: 992px) {
      .o-skill-intro--puzzle p {
        font-size: 18px;
        line-height: 30px; } }
  .o-skill-intro--puzzle .font-20 {
    font-family: "Montserrat",sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: center; }
    @media (min-width: 992px) {
      .o-skill-intro--puzzle .font-20 {
        font-size: 20px;
        font-weight: 400;
        line-height: 35px; } }
  .o-skill-intro--puzzle img {
    text-align: center;
    margin: 40px auto;
    display: block; }

.project-management {
  background-size: auto;
  background-size: 30% auto !important;
  position: relative;
  text-align: center;
  padding: 30px 0 15px; }
  @media (min-width: 992px) {
    .project-management {
      padding: 70px 0 45px;
      background-size: 30% auto; } }
  .project-management img {
    margin: 0 0 20px; }
  .project-management h2 {
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
    margin: 0px 0px 20px 0px; }
    @media (min-width: 992px) {
      .project-management h2 {
        font-size: 42px;
        line-height: 52px;
        margin: 0px 0px 30px 0px; } }
  .project-management p {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    margin: 0px 0px 25px 0px; }
    @media (min-width: 992px) {
      .project-management p {
        font-family: "Montserrat", sans-serif; } }

.o-skill-intro--better {
  background-color: #F8F9FB;
  background-image: none;
  position: relative;
  padding: 40px 0 20px;
  text-align: center; }
  @media (min-width: 992px) {
    .o-skill-intro--better {
      padding: 70px 0 50px;
      background-size: auto;
      background-size: 30% auto; } }
  .o-skill-intro--better h2 {
    font-size: 24px;
    line-height: 30px;
    margin: 0px 0px 25px 0px;
    text-transform: capitalize; }
    @media (min-width: 992px) {
      .o-skill-intro--better h2 {
        font-size: 42px;
        line-height: 52px;
        margin: 0px 0px 50px 0px; } }
  .o-skill-intro--better p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin: 0px 0px 25px 0px; }
    @media (min-width: 992px) {
      .o-skill-intro--better p {
        font-size: 18px;
        line-height: 30px;
        font-family: "Montserrat", sans-serif; } }
  .o-skill-intro--better .white-bg {
    background-color: #fff;
    text-align: center;
    padding: 0 0 30px 0;
    width: 100%;
    margin: 0 0 20px;
    min-height: initial;
    flex: 0 0 100%;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1); }
    @media (min-width: 992px) {
      .o-skill-intro--better .white-bg {
        text-align: left;
        margin: 40px 15px 40px;
        min-height: 640px;
        padding: 0;
        width: inherit;
        flex: 1; } }
    .o-skill-intro--better .white-bg h3 {
      font-size: 18px;
      line-height: 22px;
      color: #222222;
      padding: 30px 35px 15px;
      position: relative;
      margin-bottom: 20px;
      text-align: left; }
      @media (min-width: 992px) {
        .o-skill-intro--better .white-bg h3 {
          padding: 30px 35px 20px; } }
      .o-skill-intro--better .white-bg h3:before {
        content: '';
        position: absolute;
        background-color: #27c070;
        height: 3px;
        width: 35px;
        bottom: 0;
        left: 35px;
        margin: 0 auto; }
        @media (min-width: 992px) {
          .o-skill-intro--better .white-bg h3:before {
            left: 15px;
            right: inherit;
            margin: 0;
            left: 35px; } }
    .o-skill-intro--better .white-bg p {
      padding: 0 35px;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      text-align: left; }
      @media (min-width: 992px) {
        .o-skill-intro--better .white-bg p {
          padding: 0 35px;
          font-family: "Montserrat", sans-serif; } }
    .o-skill-intro--better .white-bg img {
      width: 100%; }
  .o-skill-intro--better .btn_wrapper {
    margin-top: 30px; }
    @media (min-width: 992px) {
      .o-skill-intro--better .btn_wrapper {
        margin-top: 0; } }

.key-benefits:after {
  display: none !important; }

.key-benefits .main-span {
  font-weight: 400;
  line-height: 28px; }

.key-benefits .service-wrap .services-pan {
  padding-top: 40px !important;
  height: auto; }
  @media (min-width: 992px) {
    .key-benefits .service-wrap .services-pan {
      padding: 40px 20px 30px !important; } }
  .key-benefits .service-wrap .services-pan:before {
    display: none; }
  .key-benefits .service-wrap .services-pan p {
    font-size: 16px !important;
    line-height: 26px !important;
    font-family: "Montserrat", sans-serif !important; }
  @media screen and (min-width: 992px) {
    .key-benefits .service-wrap .services-pan {
      min-height: 515px !important; } }

.key-benefits .cmn-heading-para-w {
  margin-bottom: 15px; }
  @media (min-width: 992px) {
    .key-benefits .cmn-heading-para-w {
      margin-bottom: 20px; } }

.o-skill-intro--conclusion {
  position: relative;
  padding: 40px 0 20px;
  text-align: center; }
  @media (min-width: 992px) {
    .o-skill-intro--conclusion {
      padding: 70px 0 50px;
      background-size: auto;
      background-size: 30% auto; } }
  .o-skill-intro--conclusion h2 {
    font-size: 30px;
    line-height: 36px;
    text-transform: capitalize; }
    @media (min-width: 992px) {
      .o-skill-intro--conclusion h2 {
        margin: 0px 0px 25px 0px;
        font-size: 48px;
        line-height: 52px; } }
  .o-skill-intro--conclusion p {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px; }
    @media (min-width: 992px) {
      .o-skill-intro--conclusion p {
        font-family: "Montserrat", sans-serif; } }

.left-banner-txt-mod {
  padding-top: 40px; }

.bottom-right-pt {
  position: relative; }
  .bottom-right-pt:before {
    content: '';
    position: absolute;
    background: url(/images/work-with-the-top-developers/bottom-right-pt.png) bottom right;
    background-repeat: no-repeat;
    z-index: 9;
    width: 615px;
    height: 325px;
    bottom: 0;
    right: 0;
    display: none; }
    @media screen and (min-width: 992px) {
      .bottom-right-pt:before {
        display: block; } }

.bottom-left-pt {
  position: absolute;
  background: url(/images/common/bottom-left-pt.png) bottom left;
  background-repeat: no-repeat;
  z-index: 9;
  width: 615px;
  height: 325px;
  bottom: 0;
  left: 0;
  display: none;
  display: block; }

.padding-0-100 {
  padding: 0 !important; }
  @media (min-width: 992px) {
    .padding-0-100 {
      padding: 0 100px !important; } }

.margin-bottom-15 {
  margin-bottom: 20px; }

.index-bnr-logo {
  margin-bottom: 35px; }
  .index-bnr-logo svg, .index-bnr-logo img {
    margin: 10px 5px; }

.machine-img {
  z-index: 1;
  bottom: -15px;
  position: relative;
  max-width: 480px;
  width: auto;
  padding: 0 30px;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .machine-img {
      position: absolute;
      text-align: right;
      text-align: center;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      padding: 0 15px;
      max-width: none;
      width: 51%;
      right: 30px; } }

.custom-align {
  text-align: right;
  text-align: center;
  margin-bottom: 15px; }

.ex-padding-top {
  margin-top: 20px; }

.ex-padding-bottom2 {
  padding-bottom: 200px; }

/*office-and-infastructure*/
.description-box {
  padding-top: 15px; }
  @media (min-width: 992px) {
    .description-box {
      padding-top: 80px; } }
  .description-box p {
    font-size: 19px;
    line-height: 30px; }
  .description-box .description-box-img {
    height: 500px; }
    .description-box .description-box-img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .description-box .frame-text {
    padding: 30px 30px 5px 30px;
    max-height: 500px;
    max-height: 500px;
    border: 1px solid #f3f3f3; }
    @media (min-width: 992px) {
      .description-box .frame-text {
        padding: 40px 50px; } }
    .description-box .frame-text p {
      font-size: 13px;
      line-height: 22px;
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .description-box .frame-text p {
          font-family: "Montserrat", sans-serif;
          font-size: 16px;
          line-height: 30px; } }
  .description-box .frame-text2 {
    padding: 20px 15px;
    padding: 40px 40px 20px; }
  .description-box .o-open-office--description .description-box-img {
    position: relative; }
    .description-box .o-open-office--description .description-box-img span {
      bottom: 30px;
      padding: 20px 15px; }
      @media (min-width: 992px) {
        .description-box .o-open-office--description .description-box-img span {
          padding: 40px 40px; } }
    .description-box .o-open-office--description .description-box-img .m-left {
      left: 0;
      left: 0; }
    .description-box .o-open-office--description .description-box-img .m-right {
      right: 0;
      right: 0; }

@media (min-width: 992px) {
  .o-office-design2 {
    padding: 70px 0 80px; } }

.here-details p {
  font-family: 'Montserrat', sans-serif;
  line-height: 22px;
  margin-bottom: 15px;
  font-weight: 600; }
  @media (min-width: 992px) {
    .here-details p {
      margin: 0 0 25px 0;
      line-height: 28px; } }

.here-details .h-are-the {
  margin-bottom: 35px;
  font-size: 17px;
  font-weight: 500; }
  @media (min-width: 992px) {
    .here-details .h-are-the {
      margin: 0 0 25px 0;
      font-size: 19px;
      font-weight: 600; } }

.frame-text3 {
  padding: 15px;
  height: auto;
  margin-bottom: 15px;
  height: 500px;
  padding: 0;
  border: 1px solid #f3f3f3;
  position: relative; }
  .frame-text3 h3 {
    padding: 0 0 40px; }

.frame-text4 {
  padding: 15px;
  height: auto;
  margin-bottom: 15px;
  height: 543px;
  padding: 0;
  border: 1px solid #f3f3f3;
  position: relative; }
  .frame-text4 h3 {
    padding: 0 0 40px; }

.frame-text5 {
  height: auto;
  padding: 15px;
  margin-bottom: 15px;
  height: 512px;
  padding: 0;
  border: 1px solid #f3f3f3;
  position: relative; }
  .frame-text5 h3 {
    padding: 0 0 40px; }

.frame-text6 {
  height: auto;
  padding: 15px;
  margin-bottom: 15px;
  height: 503px;
  padding: 0;
  border: 1px solid #f3f3f3;
  position: relative; }
  .frame-text6 h3 {
    padding: 0 0 40px; }

.text-content-box {
  display: flex;
  align-items: center; }
  .text-content-box p {
    text-align: left;
    margin-bottom: 0; }
    .text-content-box p span {
      color: #3BE185; }
  .text-content-box img {
    margin-right: 25px;
    flex: 0 0 auto; }

.text-wrap {
  max-width: 90%;
  margin: 0 auto; }
  .text-wrap .side-padding {
    padding: 0 50px; }

.img-full img {
  width: 100%; }

.company-video {
  background: url(images/office-and-infrastructure/video-bg2.jpg) no-repeat 0 0/cover;
  padding: 50px 0; }
  @media (min-width: 992px) {
    .company-video {
      padding: 80px 0; } }

.hww-list {
  text-align: center;
  margin-bottom: 0;
  padding: 40px 0 0; }
  @media (min-width: 992px) {
    .hww-list {
      margin-bottom: 10px;
      padding: 40px 0; } }

.hww-list li {
  display: inline-block;
  margin: 0 0 25px;
  color: #fff;
  width: 100%;
  vertical-align: top;
  font-size: 15px;
  font-weight: 400;
  position: relative; }
  @media screen and (min-width: 768px) {
    .hww-list li {
      width: 110px;
      margin: 0 20px; } }
  @media screen and (min-width: 992px) {
    .hww-list li {
      width: 142px; } }

.hww-list li span {
  display: block;
  padding-top: 20px;
  font-family: 'Montserrat', sans-serif; }

.hww-list li:before {
  content: "";
  background: url(/images/daas/arrow.png) no-repeat 0 0;
  width: 53px;
  height: 30px;
  position: absolute;
  right: -50px;
  top: 40px;
  display: none; }
  @media (min-width: 992px) {
    .hww-list li:before {
      display: block; } }

.hww-list li:last-child:before {
  display: none; }

.hww-list-1 li {
  color: #fff;
  position: relative;
  padding: 0 0 20px 28px;
  font-size: 16px;
  line-height: 28px;
  position: relative;
  font-weight: 500; }
  @media (min-width: 992px) {
    .hww-list-1 li {
      font-size: 19px;
      line-height: 30px; } }

.hww-list-1 li:before {
  width: 5px;
  height: 5px;
  background-color: #27C070;
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%; }

.o-engagement-model__cards__top_daas1:before {
  background: url(images/daas/price-icon-1.png) no-repeat 0 0 !important;
  height: 62px !important;
  width: 64px !important; }

.o-engagement-model__cards__top_daas2:before {
  background: url(images/daas/price-icon-2.png) no-repeat 0 0 !important;
  height: 62px !important;
  width: 64px !important; }

.reasons-capital__blocks-wc1:before {
  background: url(images/daas/why-choose-1.png) no-repeat 0 0 !important; }

.reasons-capital__blocks-wc2:before {
  background: url(images/daas/why-choose-2.png) no-repeat 0 0 !important; }

.reasons-capital__blocks-wc3:before {
  background: url(images/daas/why-choose-3.png) no-repeat 0 0 !important; }

.reasons-capital__blocks-wc4:before {
  background: url(images/daas/why-choose-4.png) no-repeat 0 0 !important; }

.reasons-capital__blocks-wc5:before {
  background: url(images/daas/why-choose-5.png) no-repeat 0 0 !important; }

.panel1 {
  padding: 0 18px;
  background-color: white;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  display: none;
  font-size: 19px;
  line-height: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500; }

.panel1 p {
  font-size: 19px;
  line-height: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500; }

.panel1 ul {
  padding-bottom: 20px;
  padding-left: 20px; }

.panel1 ul li {
  list-style-type: disc;
  padding-bottom: 10px; }

.cheaper-batter-header p {
  font-size: 16px;
  line-height: 28px;
  color: #fff;
  text-align: center; }
  @media (min-width: 992px) {
    .cheaper-batter-header p {
      font-size: 22px;
      line-height: 32px;
      text-align: left; } }

.cheaper-batter-header-left {
  background: none !important;
  padding-left: 15px; }
  @media (min-width: 992px) {
    .cheaper-batter-header-left {
      background: url(/images/common/cheape-heading-bg.png) no-repeat 0 0 !important;
      padding-left: 40px; } }
  .cheaper-batter-header-left .c-b-heading {
    font-size: 19px;
    line-height: 23px;
    font-weight: bold;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 50px;
    text-transform: uppercase;
    letter-spacing: 0;
    padding-top: 90px;
    position: relative;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .cheaper-batter-header-left .c-b-heading {
        padding-top: 140px;
        font-size: 36px;
        line-height: 43px;
        letter-spacing: 6px;
        margin-bottom: 0; } }
    .cheaper-batter-header-left .c-b-heading:before {
      content: '';
      position: absolute;
      background: url(/images/mobile-all/cb-bnr-circle.png) no-repeat 0 0;
      width: 325px;
      height: 315px;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: block; }
      @media (min-width: 992px) {
        .cheaper-batter-header-left .c-b-heading:before {
          display: none; } }
    .cheaper-batter-header-left .c-b-heading span {
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 84px;
      line-height: 100px;
      letter-spacing: 0; }
      @media (min-width: 992px) {
        .cheaper-batter-header-left .c-b-heading span {
          font-size: 160px;
          line-height: 160px; } }

.cheaper-batter-header-right {
  padding-top: 20px;
  padding-left: 15px; }
  @media (min-width: 992px) {
    .cheaper-batter-header-right {
      padding-left: 80px;
      padding-top: 80px; } }

.cheaper-batter-header-right p {
  line-height: 26px; }
  @media (min-width: 992px) {
    .cheaper-batter-header-right p {
      line-height: 36px; } }

.new-cb-heading {
  margin-top: 30px;
  margin-bottom: 30px; }

.new-cb-heading span {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  width: 270px !important;
  padding: 10px 28px !important; }
  @media (min-width: 992px) {
    .new-cb-heading span {
      width: auto !important; } }

.new-cb-section {
  text-align: left; }

.new-cb-section-pan {
  display: inline-block;
  vertical-align: top;
  font-size: 19px;
  line-height: 29px;
  font-family: "Montserrat", sans-serif;
  flex: 0 0 100%; }
  @media (min-width: 992px) {
    .new-cb-section-pan {
      flex: auto; } }
  .new-cb-section-pan .new-cb-top {
    display: flex;
    flex-direction: row-reverse;
    align-items: center; }
    @media (min-width: 992px) {
      .new-cb-section-pan .new-cb-top {
        display: block; } }
  .new-cb-section-pan p {
    font-size: 16px;
    line-height: 24px;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .new-cb-section-pan p {
        font-size: 19px;
        line-height: 28px;
        font-family: "Montserrat", sans-serif; } }

.new-cb-section-pan h4 {
  height: auto;
  font-size: 20px;
  color: #333;
  font-weight: 500;
  position: relative;
  flex: 1;
  margin-bottom: 0;
  margin-left: 20px; }
  @media (min-width: 992px) {
    .new-cb-section-pan h4 {
      height: 70px;
      line-height: 24px;
      margin-bottom: .5rem;
      margin-left: 0; } }

.new-cb-section-pan h4 span {
  font-weight: 700; }
  @media (min-width: 992px) {
    .new-cb-section-pan h4 span {
      position: absolute;
      bottom: 0;
      font-weight: inherit; } }
  .new-cb-section-pan h4 span strong {
    display: block;
    font-weight: 700; }
    @media (min-width: 992px) {
      .new-cb-section-pan h4 span strong {
        display: block;
        font-weight: inherit; } }

.new-cb-section-pan img {
  margin: 30px 0;
  min-height: 55px; }

.new-cb-section-1 {
  border: 1px solid #8A8A8F;
  background-color: #FAFAFA;
  border-radius: 5px;
  box-shadow: -2px 5px 20px 0px rgba(0, 0, 0, 0.17);
  padding: 15px 35px;
  position: relative; }
  @media (min-width: 992px) {
    .new-cb-section-1 {
      padding: 68px 35px;
      width: 23%;
      min-height: 546px; } }

.new-cb-section-1:after {
  content: "";
  position: absolute;
  background: url(/images/mobile-all/icon-plus.png) no-repeat 0 0 !important;
  width: 39px;
  height: 39px;
  top: inherit;
  bottom: -20px;
  right: 0;
  left: 0;
  background-size: 100% !important;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .new-cb-section-1:after {
      top: 95px;
      bottom: inherit;
      right: -20px;
      left: inherit;
      margin: 0; } }

.new-cb-section-2 {
  border-bottom: 1px solid #E7E7E7;
  padding: 0 30px 0;
  position: relative; }
  @media (min-width: 992px) {
    .new-cb-section-2 {
      position: relative;
      padding: 50px 28px 0;
      width: 18%;
      min-height: 510px;
      border-right: 1px solid #E7E7E7;
      border-bottom: 0;
      margin: 20px 0; } }
  @media (min-width: 992px) {
    .new-cb-section-2-mod {
      padding: 50px 25px 0; } }

.new-cb-section-2:after {
  content: "";
  position: absolute;
  background: url(/images/mobile-all/icon-plus.png) no-repeat 0 0 !important;
  width: 30px;
  height: 30px;
  background-size: 100% !important;
  top: inherit;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .new-cb-section-2:after {
      top: 80px;
      right: -17px;
      left: inherit;
      bottom: inherit;
      margin: 0; } }

.last-child-cb {
  border: 0; }

.last-child-cb:after {
  display: none !important; }

.cb-wecare {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#07b260+0,00517f+29,051b39+100 */
  background: #07b260;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #07b260 0%, #00517f 29%, #051b39 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #07b260 0%, #00517f 29%, #051b39 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #07b260 0%, #00517f 29%, #051b39 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#07b260', endColorstr='#051b39',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }
  .cb-wecare-heading h2 {
    font-size: 24px;
    line-height: 32px;
    text-align: center; }
    @media (min-width: 992px) {
      .cb-wecare-heading h2 {
        font-size: 48px;
        line-height: 58px;
        text-align: left; } }

.cb-wecare-heading {
  text-align: left;
  color: #fff;
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .cb-wecare-heading {
      margin-bottom: 50px; } }

.cb-wecare-heading h2 {
  color: #fff;
  font-size: 24px;
  line-height: 32px;
  padding-bottom: 10px; }
  @media (min-width: 992px) {
    .cb-wecare-heading h2 {
      font-size: 48px;
      line-height: 58px;
      padding-bottom: 20px; } }

.cb-wecare-heading p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  color: #fff; }
  @media (min-width: 992px) {
    .cb-wecare-heading p {
      font-size: 19px;
      line-height: 30px; } }

.cb-wecare-heading p strong {
  font-weight: bold; }

.cb-wecare-box1 {
  background-color: #1D2942;
  padding: 50px 30px 10px;
  color: #fff;
  text-align: left; }

.cb-wecare-box1 h3, .cb-wecare-box2 h3 {
  color: #fff;
  margin-bottom: 30px; }

.cb-wecare-box1 p, .cb-wecare-box2 p {
  color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 26px;
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .cb-wecare-box1 p, .cb-wecare-box2 p {
      font-size: 19px;
      font-family: "Montserrat", sans-serif; } }

.cb-wecare-box2 {
  padding: 50px 30px 10px;
  color: #fff;
  text-align: left;
  border: 1px solid #fff;
  margin-top: 30px;
  margin-top: 50px; }

.cb-wecare-box1 a, .cb-wecare-box2 a {
  display: inline-block;
  text-align: left;
  border-radius: 5px;
  padding: 10px 50px 10px 20px;
  font-size: 16px;
  text-decoration: none;
  position: relative;
  font-family: "Montserrat",sans-serif;
  border: 2px solid #fff;
  color: #fff;
  width: auto;
  position: relative;
  margin-top: 20px; }
  @media (min-width: 992px) {
    .cb-wecare-box1 a, .cb-wecare-box2 a {
      display: inline-block;
      text-align: center;
      font-size: 18px; } }

.cb-wecare-box1 a:hover, .cb-wecare-box2 a:hover {
  border: 2px solid #fff;
  border: 2px solid #14df7d; }

.cb-wecare-box1 a:hover {
  border: 2px solid #14df7d; }

.cb-wecare-box1 a.c-btn:after,
.cb-wecare-box2 a.c-btn:after {
  content: '';
  background: url(/images/common/arrow-white.png) no-repeat;
  width: 20px;
  height: 14px;
  display: inline-block;
  margin-left: 15px;
  z-index: 0;
  position: absolute;
  right: 15px;
  top: 28px; }
  @media (min-width: 992px) {
    .cb-wecare-box1 a.c-btn:after,
    .cb-wecare-box2 a.c-btn:after {
      position: static;
      z-index: -1;
      top: 20px; } }

.services-pan__dotnet-service-01:before {
  background: url(images/dotnet/service-icon-1.png) no-repeat 0 0 !important; }

.services-pan__dotnet-service-02:before {
  background: url(images/dotnet/service-icon-2.png) no-repeat 0 0 !important; }

.services-pan__dotnet-service-03:before {
  background: url(images/dotnet/service-icon-3.png) no-repeat 0 0 !important; }

.services-pan__dotnet-service-04:before {
  background: url(images/dotnet/service-icon-4.png) no-repeat 0 0 !important; }

.services-pan__dotnet-service-05:before {
  background: url(images/dotnet/service-icon-5.png) no-repeat 0 0 !important; }

.services-pan__dotnet-service-06:before {
  background: url(images/dotnet/service-icon-6.png) no-repeat 0 0 !important; }

.services-pan__dotnet-service-07:before {
  background: url(images/dotnet/service-icon-7.png) no-repeat 0 0 !important; }

.services-pan__dotnet-service-08:before {
  background: url(images/dotnet/service-icon-8.png) no-repeat 0 0 !important; }

.dotnet-industry {
  position: relative; }
  .dotnet-industry:after {
    display: none !important; }
  .dotnet-industry .services-pan__dotnet-industry-01:before {
    background: url(images/dotnet/industry-icon-1.png) no-repeat 0 0 !important; }
  .dotnet-industry .services-pan__dotnet-industry-02:before {
    background: url(images/dotnet/industry-icon-2.png) no-repeat 0 0 !important; }

.whydotnet .fi-pan {
  border: 0;
  border-radius: 6px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  background-color: transparent; }
  .whydotnet .fi-pan img {
    margin-bottom: 20px; }

.whymagento .fi-pan {
  border: 0;
  border-radius: 6px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  background-color: transparent;
  width: 100%;
  text-align: center; }
  @media (min-width: 992px) {
    .whymagento .fi-pan {
      width: 25%;
      text-align: left; } }
  .whymagento .fi-pan img {
    margin-bottom: 20px; }
  .whymagento .fi-pan p {
    font-size: 16px;
    line-height: 23px;
    padding-top: 10px;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .whymagento .fi-pan p {
        font-size: 19px;
        line-height: 23px;
        font-family: "Montserrat", sans-serif; } }
  .whymagento .fi-pan h3 {
    font-size: 20px; }
    @media (min-width: 992px) {
      .whymagento .fi-pan h3 {
        font-size: 26px; } }

.hr-magento {
  font-size: 19px; }
  @media (min-width: 992px) {
    .hr-magento {
      font-size: 26px; } }
  .hr-magento br {
    display: none; }
    @media (min-width: 992px) {
      .hr-magento br {
        display: block; } }

.magento-page h1 {
  font-size: 55px;
  line-height: 62px; }

.magento-page p {
  font-size: 24px;
  line-height: 30px; }
  .magento-page p span {
    color: #2DBE70; }

.magento-section-1 ul li {
  background: url(/images/magento/greentick.png) no-repeat 0 5px !important;
  padding: 0 0 10px 40px;
  font-size: 17px;
  line-height: 28px;
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .magento-section-1 ul li {
      font-size: 20px;
      line-height: 32px;
      background: url(/images/magento/greentick.png) no-repeat 0 7px !important;
      font-family: "Montserrat", sans-serif; } }

.magento-section-1 h5 {
  padding-bottom: 15px; }
  @media (min-width: 992px) {
    .magento-section-1 h5 {
      padding-bottom: 30px; } }

.o-services-hero__title span {
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  color: #333;
  background: #14DF7D;
  display: inline-block;
  position: relative;
  font-size: 18px;
  line-height: 40px;
  padding: 0 20px;
  margin: 0 0 56px;
  padding: 0 30px;
  margin: 0 0 40px;
  font-size: 28px;
  line-height: 54px; }

.why-matters-top {
  display: flex;
  align-items: center; }
  .why-matters-top span {
    display: inline-block;
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50%;
    border: 1px solid #333; }
    @media (min-width: 992px) {
      .why-matters-top span {
        display: inline;
        width: auto;
        height: auto;
        border-radius: 0;
        border: none; } }
    .why-matters-top span .listing-heading-icon1 {
      width: 25px; }
      @media (min-width: 992px) {
        .why-matters-top span .listing-heading-icon1 {
          width: auto; } }
    .why-matters-top span .listing-heading-icon2 {
      width: 40px; }
      @media (min-width: 992px) {
        .why-matters-top span .listing-heading-icon2 {
          width: auto; } }
    .why-matters-top span .listing-heading-icon3 {
      width: 40px; }
      @media (min-width: 992px) {
        .why-matters-top span .listing-heading-icon3 {
          width: auto; } }

.why-matters .listing-heading {
  padding-bottom: 15px !important; }
  @media (min-width: 992px) {
    .why-matters .listing-heading {
      padding-bottom: 35px !important; } }

.why-matters-top h3 {
  font-size: 20px;
  line-height: 36px;
  flex: 1;
  padding-left: 15px !important; }
  @media (min-width: 992px) {
    .why-matters-top h3 {
      font-size: 26px;
      line-height: 1.2;
      padding-left: 30px !important; } }

.why-matters .c-ticked-list li {
  font-size: 14px !important;
  line-height: 24px !important; }
  @media (min-width: 992px) {
    .why-matters .c-ticked-list li {
      font-size: 17px !important;
      line-height: 28px !important; } }
  .why-matters .c-ticked-list li:before {
    background-size: 15px !important; }
    @media (min-width: 992px) {
      .why-matters .c-ticked-list li:before {
        background-size: auto !important; } }

.why-matters p.padding-0 {
  font-size: 16px !important;
  line-height: 28px !important;
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .why-matters p.padding-0 {
      font-size: 19px !important;
      line-height: 30px !important;
      font-family: "Montserrat", sans-serif; } }

.AI .key-benefits {
  padding: 45px 0 20px;
  background: #023e66; }
  @media (min-width: 992px) {
    .AI .key-benefits {
      padding: 70px 0 50px;
      background: linear-gradient(285deg, #07b260 0, #00517f 20%, #023e66 75%, #051b39 100%); } }
  .AI .key-benefits h2 {
    font-size: 30px;
    color: #fff; }
    @media (min-width: 992px) {
      .AI .key-benefits h2 {
        font-size: 48px;
        color: #fff; } }
  .AI .key-benefits .cmn-heading-para-w {
    color: #fff;
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .AI .key-benefits .cmn-heading-para-w {
        color: #fff;
        margin-bottom: 20px; } }
  .AI .key-benefits .services-pan h2 {
    font-size: 20px; }
    @media (min-width: 992px) {
      .AI .key-benefits .services-pan h2 {
        font-size: 26px; } }

.chat-app-group-icon {
  margin-bottom: 25px;
  display: block; }

.c-icon-desc-3-col .secure-web-application-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  margin-top: 50px; }
  @media screen and (min-width: 992px) {
    .c-icon-desc-3-col .secure-web-application-row {
      column-count: 3;
      column-gap: 1rem;
      display: block;
      text-align: left;
      margin-top: 50px; }
      .c-icon-desc-3-col .secure-web-application-row .secure-web-application-box {
        width: 100%;
        padding: 0;
        display: inline-block;
        margin: 0;
        font-size: 1em;
        box-sizing: border-box; } }
  .c-icon-desc-3-col .secure-web-application-row .secure-web-application-box {
    border: none; }
  .c-icon-desc-3-col .secure-web-application-row .secure-web-application-box-1 {
    padding: 35px 20px 0;
    position: relative;
    order: 1; }
    .c-icon-desc-3-col .secure-web-application-row .secure-web-application-box-1::before {
      content: "";
      background-color: #4A90E2;
      width: 120px;
      height: 5px;
      position: absolute;
      left: 20px;
      top: 0px; }
  .c-icon-desc-3-col .secure-web-application-row .secure-web-application-box-2 {
    padding: 35px 20px 0;
    position: relative;
    order: 4; }
    .c-icon-desc-3-col .secure-web-application-row .secure-web-application-box-2::before {
      content: "";
      background-color: #49C684;
      width: 120px;
      height: 5px;
      position: absolute;
      left: 20px;
      top: 0px; }
  .c-icon-desc-3-col .secure-web-application-row .secure-web-application-box-3 {
    padding: 35px 20px 0;
    position: relative;
    order: 2; }
    .c-icon-desc-3-col .secure-web-application-row .secure-web-application-box-3::before {
      content: "";
      background-color: #49C684;
      width: 120px;
      height: 5px;
      position: absolute;
      left: 20px;
      top: 0px; }
  .c-icon-desc-3-col .secure-web-application-row .secure-web-application-box-4 {
    padding: 35px 20px 0;
    position: relative;
    order: 5; }
    .c-icon-desc-3-col .secure-web-application-row .secure-web-application-box-4::before {
      content: "";
      background-color: #4A90E2;
      width: 120px;
      height: 5px;
      position: absolute;
      left: 20px;
      top: 0px; }
  .c-icon-desc-3-col .secure-web-application-row .secure-web-application-box-5 {
    padding: 35px 20px 0;
    position: relative;
    order: 7; }
    .c-icon-desc-3-col .secure-web-application-row .secure-web-application-box-5::before {
      content: "";
      background-color: #49C684;
      width: 120px;
      height: 5px;
      position: absolute;
      left: 20px;
      top: 0px; }
  .c-icon-desc-3-col .secure-web-application-row .secure-web-application-box-6 {
    padding: 35px 20px 0;
    position: relative;
    order: 3; }
    .c-icon-desc-3-col .secure-web-application-row .secure-web-application-box-6::before {
      content: "";
      background-color: #4A90E2;
      width: 120px;
      height: 5px;
      position: absolute;
      left: 20px;
      top: 0px; }
  .c-icon-desc-3-col .secure-web-application-row .secure-web-application-box-7 {
    padding: 35px 20px 0;
    position: relative;
    order: 6; }
    .c-icon-desc-3-col .secure-web-application-row .secure-web-application-box-7::before {
      content: "";
      background-color: #49C684;
      width: 120px;
      height: 5px;
      position: absolute;
      left: 20px;
      top: 0px; }

.c-icon-desc-3-col.c-icon-desc-3-col-new-mod {
  padding: 40px 0; }
  @media screen and (min-width: 992px) {
    .c-icon-desc-3-col.c-icon-desc-3-col-new-mod {
      padding: 70px 0 80px; } }

.project-discuss-section, .project-discuss-section-new {
  background: url(///images/web-application/Oval-copy-oval.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #204279; }
  .project-discuss-section .btn-orange, .project-discuss-section-new .btn-orange {
    max-width: 350px; }

.project-discuss-text {
  font-size: 18px;
  line-height: 30px; }

.project-discuss-section-new {
  background-color: #F7F8FA;
  background: url(///images/web-application/oval-copy-oval-copy-mask.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.dev-process.dev-process-new .spiral-col-box p {
  font-size: 16px;
  line-height: 24px; }

@media (min-width: 992px) {
  .dev-process.dev-process-new .spiral-col-box1 {
    margin-bottom: 90px; } }

@media (min-width: 992px) {
  .dev-process.dev-process-new .spiral-col-box2 {
    margin-bottom: 110px; } }

@media (min-width: 992px) {
  .dev-process.dev-process-new .spiral-col-box5 {
    margin-top: 90px; } }

.grid-layout {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .grid-layout {
      column-count: 2;
      column-gap: 1rem;
      display: block;
      text-align: left; }
      .grid-layout .grid-block {
        width: 100%;
        padding: 0.5em;
        display: flex;
        align-items: flex-start;
        margin: 0 0 1em;
        font-size: 1em;
        box-sizing: border-box; }
        .grid-layout .grid-block img {
          margin-right: 20px; } }
  .grid-layout .grid-block p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px; }
    .grid-layout .grid-block p strong {
      font-size: 20px; }
    @media screen and (min-width: 992px) {
      .grid-layout .grid-block p {
        font-size: 18px;
        line-height: 28px;
        margin-top: 0; }
        .grid-layout .grid-block p strong {
          font-size: 20px; } }
    .grid-layout .grid-block p a {
      display: block; }
      @media screen and (min-width: 992px) {
        .grid-layout .grid-block p a {
          display: inline;
          margin-left: 10px; } }
  .grid-layout .grid-block-1 {
    order: 1; }
  .grid-layout .grid-block-2 {
    order: 3; }
  .grid-layout .grid-block-3 {
    order: 5; }
  .grid-layout .grid-block-4 {
    order: 7; }
  .grid-layout .grid-block-5 {
    order: 9; }
  .grid-layout .grid-block-6 {
    order: 11; }
  .grid-layout .grid-block-7 {
    order: 2; }
  .grid-layout .grid-block-8 {
    order: 4; }
  .grid-layout .grid-block-9 {
    order: 6; }
  .grid-layout .grid-block-10 {
    order: 8; }
  .grid-layout .grid-block-11 {
    order: 10; }
  .grid-layout .grid-block-12 {
    order: 12; }

.o-we-love-intro {
  padding: 40px 0 80px;
  text-align: center;
  /* media query for tablet potrait */ }
  .o-we-love-intro h1 {
    margin-bottom: 30px;
    text-transform: none;
    line-height: 50px;
    /*mobile first for tablet landscape & small desktop*/
    font-size: 80px;
    line-height: 55px;
    letter-spacing: 2.5px;
    text-shadow: 1px 1px 0px #333, -1px 1px 0px #333, 1px -1px 0px #333, -1px -1px 0px #333; }
  .o-we-love-intro h2 {
    margin-bottom: 30px;
    text-transform: none;
    line-height: 50px;
    /*mobile first for tablet landscape & small desktop*/
    font-size: 80px;
    line-height: 55px; }
  .o-we-love-intro h2.love-subheading {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    /*mobile first for tablet landscape & small desktop*/
    font-size: 40px;
    line-height: 50px;
    font-weight: 600;
    margin-bottom: 10px; }
  .o-we-love-intro h3 {
    margin-bottom: 20px; }
  .o-we-love-intro h4 {
    margin-bottom: 20px; }
  .o-we-love-intro p strong {
    font-weight: 600; }
  .o-we-love-intro__other-text {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 0;
    font-weight: 400;
    font-family: "Montserrat",sans-serif; }
    @media (min-width: 992px) {
      .o-we-love-intro__other-text {
        margin-bottom: 20px; } }
  .o-we-love-intro__we-love-image {
    padding-bottom: 40px; }
  .o-we-love-intro .c-btn {
    margin-top: 20px; }

.cto-info-p-heading {
  font-size: 20px !important;
  line-height: 24px !important;
  font-weight: 500; }
  @media (min-width: 992px) {
    .cto-info-p-heading {
      font-size: 23px !important;
      line-height: 35px !important;
      font-weight: 400; } }

.cto-last-solid-info {
  padding: 0 60px;
  margin-bottom: 40px; }

.margin-top-bottom-10 {
  margin: 10px 0 !important; }

.gradient-blue-green .container {
  position: relative;
  z-index: 9; }

.o-services-hero__icons span {
  display: block;
  color: #fff;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  margin-top: 15px;
  font-weight: 600;
  font-size: 18px; }

.green-bg-headine {
  position: relative;
  font-size: 30px;
  padding: 7px 30px;
  font-family: "Montserrat", sans-serif;
  line-height: 24px;
  font-size: 18px; }
  .green-bg-headine:before {
    content: '';
    position: absolute;
    width: 41px;
    height: 19px;
    top: -17px;
    left: 0; }

.gradient-blue-green {
  position: relative;
  background: linear-gradient(285deg, #07B260 0%, #00517F 20%, #023E66 75%, #051B39 100%); }
  @media (min-width: 992px) {
    .gradient-blue-green {
      background: linear-gradient(285deg, #07B260 0%, #00517F 30%, #023E66 75%, #051B39 100%); } }

.o-services-hero__title span:before {
  content: '';
  position: absolute;
  width: 41px;
  height: 19px;
  top: -17px;
  left: 0;
  border: none; }

.o-industrial-experience-mod .data-science-tech {
  width: auto;
  margin: 0 10px 45px;
  margin: 0 auto;
  width: 96%; }
  .o-industrial-experience-mod .data-science-tech span {
    background-color: #349EE6;
    padding: 23px 20px; }
    .o-industrial-experience-mod .data-science-tech span:after {
      border-left: 10px solid #349EE6; }
  .o-industrial-experience-mod .data-science-tech img {
    position: relative;
    top: 5px; }

.data-science .o-skills-hero .container {
  padding-top: 90px; }

.data-science .dc-white-pan {
  min-height: inherit;
  min-height: 596px; }

.margin-top-50 {
  margin-top: 50px; }

.padding-bottom-40 {
  padding-bottom: 40px; }

@media (min-width: 992px) {
  .skill-service.gradient-blue-green {
    background: linear-gradient(285deg, #07b260 0, #00517f 20%, #023e66 75%, #051b39 100%); } }

.skill-service.gradient-blue-green:before {
  display: none; }
  @media (min-width: 992px) {
    .skill-service.gradient-blue-green:before {
      display: block; } }

.padding-top-100 {
  padding-top: 80px; }

.font-family-pro {
  font-family: "Montserrat", sans-serif; }

.padding-top-40 {
  padding-top: 40px; }

.padding-80 {
  padding-top: 40px; }

.padding-bottom-30 {
  padding-bottom: 30px; }

.white {
  color: #fff !important; }

h1 {
  font-size: 24px;
  line-height: 40px;
  font-weight: 700; }
  @media (min-width: 992px) {
    h1 {
      font-size: 62px;
      line-height: 75px; } }

h2 {
  font-size: 24px;
  line-height: 32px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700; }
  @media (min-width: 992px) {
    h2 {
      font-size: 48px;
      line-height: 58px; } }

.cmn-heading-para {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
  color: #333;
  font-weight: 500;
  text-align: center;
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .cmn-heading-para {
      font-size: 20px;
      line-height: 32px;
      font-family: "Montserrat", sans-serif; } }

.font-weight-600 {
  font-weight: 600; }

.margin-top-10 {
  margin-top: 10px; }

.margin-top-20 {
  margin-top: 20px; }

.margin-top-30 {
  margin-top: 30px; }

.margin-bottom-20 {
  margin-bottom: 20px; }

.cmn-bottom-border {
  border-bottom: 1px solid #dedede; }

.cmn-top-border {
  border-top: 1px solid #dedede; }

.mobile-off {
  display: none; }
  @media (min-width: 992px) {
    .mobile-off {
      display: block; } }

.bg-grey {
  background-color: #f5f5f5 !important; }

.font-size-30 {
  font-size: 28px; }

.btn-center-block {
  margin: 0px auto;
  width: 310px;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .btn-center-block {
      width: inherit; } }

p {
  margin: 0px 0px 25px 0px;
  padding: 0px 0px 0px 0px;
  line-height: 24px; }
  p .anchor-link {
    font-size: 13px;
    line-height: 19px;
    color: #cbe3ff;
    text-transform: none; }
    @media (min-width: 992px) {
      p .anchor-link {
        max-width: 340px;
        margin-top: 10px; } }

.o-home-service .anchor-link {
  margin-top: 0; }
  @media (min-width: 992px) {
    .o-home-service .anchor-link {
      margin-top: 15px; } }

.o-home-service .n-gradient-green-blue {
  background: linear-gradient(172deg, #037773 7%, #00517f 42%, #023e66 67%, #051b39 100%); }
  @media (min-width: 992px) {
    .o-home-service .n-gradient-green-blue {
      background: linear-gradient(350deg, #037c71 0, #042b4e 100%); } }

.wb-bnr {
  background: #165b5e;
  background: -moz-linear-gradient(138deg, #165b5e 0%, #0f3a4a 17%, #091b38 50%);
  background: -webkit-linear-gradient(138deg, #165b5e 0%, #0f3a4a 17%, #091b38 50%);
  background: linear-gradient(138deg, #165b5e 0%, #0f3a4a 17%, #091b38 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#165b5e",endColorstr="#091b38",GradientType=1); }

.o-skills-hero {
  padding-bottom: 15px;
  padding-top: 102px;
  position: relative;
  background: linear-gradient(138deg, #165b5e 0%, #0f3a4a 17%, #091b38 50%); }
  @media (min-width: 992px) {
    .o-skills-hero {
      padding-top: 100px; } }
  .o-skills-hero br {
    display: none; }
    @media (min-width: 992px) {
      .o-skills-hero br {
        display: block; } }
  .o-skills-hero .container {
    position: relative;
    z-index: 9; }

.wb-bnr {
  padding-top: 60px;
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .wb-bnr {
      padding-top: 100px;
      padding-bottom: 100px; } }

.small-h1 {
  text-align: center; }
  .small-h1 h1 {
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 25px;
    margin-top: 15px; }
    @media (min-width: 992px) {
      .small-h1 h1 {
        padding: 0 80px;
        font-size: 36px;
        line-height: 51px;
        margin-top: 0; } }
  .small-h1 h1.padding-0 {
    padding: 0; }
  .small-h1 h2 {
    font-size: 22px;
    color: #14e07e;
    margin-top: 25px;
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
    padding-bottom: 0; }
    @media (min-width: 992px) {
      .small-h1 h2 {
        font-size: 26px;
        margin-top: 25px;
        font-weight: 700;
        margin-bottom: 0;
        padding: 0 0 0; } }
  .small-h1 .bnr-ofr-text {
    color: #b7b7b7;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0;
    font-weight: bold;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .small-h1 .bnr-ofr-text {
        padding: 0 280px;
        line-height: 36px;
        font-size: 18px;
        font-weight: 600;
        margin-top: 10px; } }
    .small-h1 .bnr-ofr-text a {
      text-decoration: underline;
      color: #b7b7b7; }
    .small-h1 .bnr-ofr-text br {
      display: none; }
      @media (min-width: 992px) {
        .small-h1 .bnr-ofr-text br {
          display: block; } }

.o-skills-hero__schedule {
  font-family: "Montserrat", sans-serif;
  position: relative; }
  .o-skills-hero__schedule .container {
    position: relative;
    z-index: 9; }
  .o-skills-hero__schedule .header_right_pan {
    background-color: #1d2942a3;
    padding: 6px;
    margin-top: 20px; }
    .o-skills-hero__schedule .header_right_pan .regular.slider h3 {
      font-size: 20px;
      line-height: 28px; }
    .o-skills-hero__schedule .header_right_pan .holder {
      padding: 40px 50px 15px 50px; }
    .o-skills-hero__schedule .header_right_pan .sub-text {
      font-size: 19px;
      padding: 0 40px 20px; }
    .o-skills-hero__schedule .header_right_pan ul li {
      margin: 0 6px;
      width: 19%;
      background-color: #fff;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #fff; }
      .o-skills-hero__schedule .header_right_pan ul li img {
        width: 100%; }
    .o-skills-hero__schedule .header_right_pan .anchor-link:hover {
      color: #fff; }

.bnr-ofr-text br {
  display: block; }

.gray-text {
  color: #b7b7b7 !important; }

.green-head-ribbon {
  position: relative; }
  .green-head-ribbon span {
    font-family: "Montserrat", sans-serif !important;
    font-size: 20px;
    padding: 7px 35px;
    line-height: 24px;
    margin: 0 0 26px;
    width: auto; }
    @media (min-width: 992px) {
      .green-head-ribbon span {
        padding: 0 30px;
        margin: 0 0 40px;
        font-size: 28px;
        line-height: 54px;
        font-family: "Montserrat", sans-serif !important; } }
    .green-head-ribbon span:before {
      content: "";
      display: block;
      position: absolute;
      top: -18px;
      left: 0;
      border-color: transparent transparent transparent #148d52;
      width: 0;
      height: 0;
      border-style: dashed solid dashed solid;
      border-width: 0 0 18px 40px;
      transform: rotate(-180deg); }

.o-services-hero__title span:after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: auto;
  right: 0;
  border-color: transparent transparent transparent #148d52;
  width: 0;
  height: 0;
  border-style: dashed solid dashed solid;
  border-width: 0 0 18px 40px; }

@media (min-width: 992px) {
  .service-details {
    padding: 80px 0; } }

.service-details h2 {
  margin-bottom: 5px;
  font-size: 30px;
  line-height: 36px; }
  @media (min-width: 992px) {
    .service-details h2 {
      font-size: 48px;
      line-height: 61px;
      margin-bottom: 25px; } }

.service-details .cmn-heading-para {
  display: none; }
  @media (min-width: 992px) {
    .service-details .cmn-heading-para {
      display: block;
      font-size: 20px;
      line-height: 32px;
      color: #333;
      max-width: 1024px;
      margin: 0px auto;
      margin-bottom: 0;
      font-weight: 400;
      font-family: "Montserrat", sans-serif; } }

.service-details h3 {
  font-size: 20px;
  text-align: center; }
  @media (min-width: 992px) {
    .service-details h3 {
      font-size: 24px;
      line-height: 32px; } }

.service-details p {
  font-size: 16px !important;
  line-height: inherit !important;
  text-align: center; }
  @media (min-width: 992px) {
    .service-details p {
      font-size: 18px !important;
      line-height: 28px !important; } }

.service-details .service-wrap .brick.services-pan {
  display: inline-block;
  vertical-align: top;
  margin: 15px 0;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
  padding: 50px 15px 10px;
  position: relative; }
  @media (min-width: 992px) {
    .service-details .service-wrap .brick.services-pan {
      padding: 50px 30px 50px; } }
  @media (min-width: 992px) {
    .service-details .service-wrap .brick.services-pan a {
      width: 134px;
      height: 46px;
      line-height: 42px;
      border: 2px solid #333333;
      border-radius: 5px;
      font-size: 18px;
      color: #333;
      text-decoration: none;
      font-weight: 400;
      transition: 0.3s all ease-in;
      display: block;
      background: transparent;
      padding: 0;
      text-transform: capitalize; }
      .service-details .service-wrap .brick.services-pan a:after {
        display: none; } }
  .service-details .service-wrap .brick.services-pan a:hover {
    border-color: #ff5800;
    color: #ff5800; }

.service-details .service-wrap:before {
  transform: translate(0); }

/*services section*/
.web-app-service {
  padding-top: 40px;
  padding-bottom: 20px;
  position: relative; }
  @media (min-width: 992px) {
    .web-app-service {
      padding-top: 65px;
      padding-bottom: 45px; } }
  .web-app-service .service-wrap {
    text-align: center;
    margin-bottom: 35px; }
    .web-app-service .service-wrap .brick.services-pan {
      display: inline-block;
      vertical-align: top;
      margin: 15px;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      text-align: center;
      padding: 50px 15px 10px;
      position: relative; }
      @media screen and (min-width: 992px) {
        .web-app-service .service-wrap .brick.services-pan {
          width: 46%; } }
      @media screen and (min-width: 1200px) {
        .web-app-service .service-wrap .brick.services-pan {
          width: 30%;
          padding: 50px 30px 30px; } }
      .web-app-service .service-wrap .brick.services-pan:before {
        display: none; }
      .web-app-service .service-wrap .brick.services-pan h3 {
        font-size: 20px;
        position: relative; }
        @media (min-width: 992px) {
          .web-app-service .service-wrap .brick.services-pan h3 {
            font-size: 24px;
            line-height: 32px;
            padding-bottom: 0; } }
      .web-app-service .service-wrap .brick.services-pan p {
        font-size: 16px;
        line-height: 23px; }
        @media screen and (min-width: 992px) {
          .web-app-service .service-wrap .brick.services-pan p {
            font-size: 17px;
            line-height: 28px; } }
        @media screen and (min-width: 1280px) {
          .web-app-service .service-wrap .brick.services-pan p {
            font-size: 18px;
            line-height: 30px; } }
      .web-app-service .service-wrap .brick.services-pan a {
        margin-bottom: 30px;
        display: block; }
        @media (min-width: 992px) {
          .web-app-service .service-wrap .brick.services-pan a {
            margin-top: auto;
            margin-bottom: 0; } }
      .web-app-service .service-wrap .brick.services-pan i {
        display: block;
        height: 55px; }
        .web-app-service .service-wrap .brick.services-pan i img {
          height: 100%; }
    .web-app-service .service-wrap .brick.services-pan__php-service-01:before {
      background-position: 0 0; }
    .web-app-service .service-wrap .brick.services-pan__php-service-02:before {
      background-position: -62px 0; }
    .web-app-service .service-wrap .brick.services-pan__php-service-03:before {
      background-position: -123px 0; }
    .web-app-service .service-wrap .brick.services-pan__php-service-04:before {
      background-position: -183px 0; }
    .web-app-service .service-wrap .brick.services-pan__php-service-05:before {
      background-position: 0 -57px; }
    .web-app-service .service-wrap .brick.services-pan__php-service-06:before {
      background-position: -62px -55px; }
    .web-app-service .service-wrap .brick.services-pan__php-service-07:before {
      background-position: -123px -57px; }
    .web-app-service .service-wrap .brick.services-pan__php-service-08:before {
      background-position: -183px -57px; }

.lighter-blue {
  color: #4a90e2 !important; }

@media (min-width: 992px) {
  .o-industrial-experience {
    padding: 70px 0 50px; } }

.o-case-studies-slider h2 {
  font-size: 30px;
  line-height: 30px;
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .o-case-studies-slider h2 {
      font-size: 48px;
      line-height: 61px;
      padding-bottom: 30px; } }

.o-case-studies-slider .row {
  margin: 0; }
  @media (min-width: 992px) {
    .o-case-studies-slider .row {
      margin: 0 -15px; } }

.experience-list {
  padding: 0 15px; }
  .experience-list h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif; }
  .experience-list ul {
    width: 100%;
    margin: 0px auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .experience-list ul li {
      flex: 0 0 43%;
      margin: 10px 10px;
      background: #fff;
      border-radius: 5px;
      vertical-align: top;
      border: 1px solid #ededed;
      box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1); }
      @media (min-width: 992px) {
        .experience-list ul li {
          flex: 0 0 30%;
          border: 1px solid #c8c7cc; } }
      @media (min-width: 992px) {
        .experience-list ul li a {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 30px 15px;
          position: relative;
          height: 115px; } }
      .experience-list ul li a:hover {
        box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5); }
      .experience-list ul li a:before {
        content: "";
        width: 23px;
        height: 23px;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%);
        border: 1px solid #c8c7cc;
        border-radius: 50%;
        background: url(/images/common/arrow-black.png) no-repeat 50% 50%;
        display: none; }
        @media (min-width: 992px) {
          .experience-list ul li a:before {
            display: block; } }
  .experience-list .new-stack li a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 15px;
    height: auto;
    position: relative;
    width: auto;
    height: 100%; }
    @media (min-width: 992px) {
      .experience-list .new-stack li a {
        align-items: center; } }
    .experience-list .new-stack li a .php-logo {
      width: 48px; }
      @media (min-width: 992px) {
        .experience-list .new-stack li a .php-logo {
          width: inherit; } }
    .experience-list .new-stack li a:after {
      content: "";
      position: absolute;
      background: url(/images/mobile-all/circle-arrow.png) no-repeat 0 0;
      width: 21px;
      height: 21px;
      right: 0;
      left: 0;
      margin: 0 auto;
      bottom: 8px;
      display: none; }

.stack-section {
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .stack-section {
      padding: 70px 0 50px; } }
  .stack-section h2 {
    font-size: 30px;
    line-height: 43px;
    position: relative;
    padding-bottom: 15px; }
    @media (min-width: 992px) {
      .stack-section h2 {
        padding-bottom: 30px; } }
    .stack-section h2:before {
      content: "";
      background: #49c684;
      height: 2px;
      width: 50px;
      bottom: 15px;
      left: 0;
      right: 0;
      margin: 0 auto;
      position: absolute;
      display: none; }
      @media (min-width: 992px) {
        .stack-section h2:before {
          display: none; } }
  @media (min-width: 992px) {
    .stack-section .btn_wrapper {
      margin-top: 10px; } }

@media (min-width: 992px) {
  .price-wrap {
    display: flex; } }

.o-engagement-model {
  padding-top: 40px;
  padding-bottom: 45px;
  background: linear-gradient(166deg, #004475 20%, #0b8a8a 69%, #08967e 100%); }
  @media (min-width: 992px) {
    .o-engagement-model {
      padding: 70px 0 40px;
      background: linear-gradient(285deg, #07b260 0, #00517f 20%, #023e66 75%, #051b39 100%); } }
  .o-engagement-model h2 {
    color: #fff;
    margin: 0 0 25px;
    text-transform: capitalize;
    font-size: 24px;
    line-height: 32px;
    text-align: center; }
    @media (min-width: 992px) {
      .o-engagement-model h2 {
        font-size: 48px;
        line-height: 61px;
        margin: 0 0 40px; } }
  .o-engagement-model .o-engagement-model__panel--leftnew {
    width: 100%;
    position: relative; }
    @media (min-width: 992px) {
      .o-engagement-model .o-engagement-model__panel--leftnew {
        margin-left: 10px; } }
    .o-engagement-model .o-engagement-model__panel--leftnew .most-popular {
      position: absolute;
      height: 125px;
      width: 120px;
      top: -9px;
      left: -10px;
      display: block;
      background-size: cover; }
      @media (min-width: 992px) {
        .o-engagement-model .o-engagement-model__panel--leftnew .most-popular {
          height: 151px;
          width: 152px;
          top: -10px; } }
    .o-engagement-model .o-engagement-model__panel--leftnew .o-engagement-model__cards {
      padding: 25px 15px 15px;
      background: #fff;
      margin-bottom: 30px;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1); }
      @media screen and (min-width: 992px) {
        .o-engagement-model .o-engagement-model__panel--leftnew .o-engagement-model__cards {
          padding: 30px 30px 30px;
          min-height: 392px; } }
      @media screen and (min-width: 1200px) {
        .o-engagement-model .o-engagement-model__panel--leftnew .o-engagement-model__cards {
          min-height: 368px; } }
    .o-engagement-model .o-engagement-model__panel--leftnew .price-text {
      text-align: center;
      position: relative;
      padding-top: 10px;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: 500; }
      .o-engagement-model .o-engagement-model__panel--leftnew .price-text span {
        font-size: 32px;
        color: #ff5800;
        display: block;
        text-transform: none;
        padding-top: 15px;
        font-weight: 700; }
        @media (min-width: 992px) {
          .o-engagement-model .o-engagement-model__panel--leftnew .price-text span {
            font-size: 48px;
            font-weight: 600;
            padding-top: 25px; } }
        .o-engagement-model .o-engagement-model__panel--leftnew .price-text span strong {
          font-size: 20px;
          text-transform: capitalize; }
          @media (min-width: 992px) {
            .o-engagement-model .o-engagement-model__panel--leftnew .price-text span strong {
              font-size: 26px; } }
    .o-engagement-model .o-engagement-model__panel--leftnew .font-family-mot {
      font-size: 16px;
      line-height: 23px;
      font-family: "Montserrat", sans-serif;
      padding: 0 30px;
      font-weight: 500; }
      @media (min-width: 992px) {
        .o-engagement-model .o-engagement-model__panel--leftnew .font-family-mot {
          font-size: 18px;
          margin-top: 35px;
          padding: 0; } }
  .o-engagement-model .o-engagement-model__panel--rightnew {
    width: 100%; }
    @media (min-width: 992px) {
      .o-engagement-model .o-engagement-model__panel--rightnew {
        margin-right: 10px; } }
    .o-engagement-model .o-engagement-model__panel--rightnew .o-engagement-model__cards {
      padding: 25px 15px 15px;
      background: #fff;
      margin-bottom: 30px;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1); }
      @media screen and (min-width: 992px) {
        .o-engagement-model .o-engagement-model__panel--rightnew .o-engagement-model__cards {
          padding: 30px 30px 30px;
          min-height: 392px; } }
      @media screen and (min-width: 1200px) {
        .o-engagement-model .o-engagement-model__panel--rightnew .o-engagement-model__cards {
          min-height: 368px; } }
    .o-engagement-model .o-engagement-model__panel--rightnew .price-text {
      text-align: center;
      position: relative;
      padding-top: 10px;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: 500; }
      .o-engagement-model .o-engagement-model__panel--rightnew .price-text span {
        font-size: 32px;
        color: #ff5800;
        display: block;
        text-transform: none;
        padding-top: 15px;
        font-weight: 700; }
        @media (min-width: 992px) {
          .o-engagement-model .o-engagement-model__panel--rightnew .price-text span {
            font-size: 48px;
            padding-top: 25px;
            font-weight: 600; } }
        .o-engagement-model .o-engagement-model__panel--rightnew .price-text span strong {
          font-size: 20px;
          text-transform: capitalize; }
          @media (min-width: 992px) {
            .o-engagement-model .o-engagement-model__panel--rightnew .price-text span strong {
              font-size: 26px; } }
    .o-engagement-model .o-engagement-model__panel--rightnew .font-family-mot {
      font-size: 16px;
      line-height: 23px;
      font-family: "Montserrat", sans-serif;
      padding: 0 30px;
      font-weight: 500; }
      @media (min-width: 992px) {
        .o-engagement-model .o-engagement-model__panel--rightnew .font-family-mot {
          font-size: 18px;
          margin-top: 30px;
          padding: 0; } }
  .o-engagement-model .btn_wrapper {
    margin-top: 35px; }
    @media (min-width: 992px) {
      .o-engagement-model .btn_wrapper {
        margin-top: 15px; } }

/*pricing section*/
.o-engagement-model__cards__top {
  position: relative;
  text-align: center;
  padding-top: 15px; }
  @media (min-width: 992px) {
    .o-engagement-model__cards__top {
      padding-top: 10px; } }
  .o-engagement-model__cards__top:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 52px;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -60px;
    z-index: 0; }
  .o-engagement-model__cards__top__inner h3 {
    font-size: 20px;
    line-height: 24px; }
    @media screen and (min-width: 992px) {
      .o-engagement-model__cards__top__inner h3 {
        font-size: 26px; } }
    @media screen and (min-width: 1200px) {
      .o-engagement-model__cards__top__inner h3 {
        margin: 25px 0 35px 0;
        font-size: 32px; } }

.o-engagement-model__cards__01:before {
  background-position: -5px -200px; }

.o-engagement-model__cards__02:before {
  background-position: -63px -200px; }

.o-engagement-model__cards__bottom {
  margin-bottom: 25px; }

/*why section*/
.c-icon-desc-3-col-box {
  position: relative;
  text-align: center;
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .c-icon-desc-3-col-box {
      padding: 70px 0 50px; } }
  .c-icon-desc-3-col-box h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 0;
    padding-bottom: 0; }
    @media (min-width: 992px) {
      .c-icon-desc-3-col-box h2 {
        font-size: 48px;
        line-height: 61px;
        padding-bottom: 30px; } }

.c-icon-desc-3-col-ccn .row > div {
  padding: 0 15px 0 90px;
  text-align: left;
  position: relative;
  border-bottom: 1px solid #dedede;
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .c-icon-desc-3-col-ccn .row > div {
      padding: 0 40px 0 95px;
      margin: 0 0 40px;
      border-bottom: 0; } }
  .c-icon-desc-3-col-ccn .row > div p {
    text-align: left;
    font-size: 16px;
    line-height: 23px;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .c-icon-desc-3-col-ccn .row > div p {
        font-size: 19px;
        text-align: left;
        line-height: 30px;
        font-family: "Montserrat", sans-serif; } }
    .c-icon-desc-3-col-ccn .row > div p strong {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      padding-bottom: 5px;
      display: block;
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .c-icon-desc-3-col-ccn .row > div p strong {
          font-size: 24px; } }

/*faq section*/
.o-case-studies-new {
  padding-top: 40px; }
  @media (min-width: 992px) {
    .o-case-studies-new {
      padding: 80px 0; } }
  .o-case-studies-new h2 {
    font-family: Poppins;
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 15px;
    text-align: center;
    color: #333;
    font-weight: 600; }
    @media (min-width: 992px) {
      .o-case-studies-new h2 {
        font-size: 48px;
        line-height: 61px;
        padding-bottom: 30px; } }
  .o-case-studies-new .margin-bottom-20 {
    margin-bottom: 10px; }
  .o-case-studies-new .faq-pan {
    margin-bottom: 20px; }
  .o-case-studies-new .faq-holder {
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1); }
    .o-case-studies-new .faq-holder .accordion1 {
      font-size: 16px;
      line-height: 22px;
      padding: 15px 35px 15px 15px;
      color: #333;
      cursor: pointer;
      width: 100%;
      text-align: left;
      font-family: "Montserrat", sans-serif;
      font-weight: bold;
      position: relative;
      text-transform: none;
      display: flex;
      align-items: center;
      padding-right: 45px; }
      @media (min-width: 992px) {
        .o-case-studies-new .faq-holder .accordion1 {
          background-color: transparent;
          padding: 18px;
          border: none;
          outline: none;
          font-size: 19px;
          line-height: 25px;
          transition: 0.4s;
          padding-right: 18px; } }
      .o-case-studies-new .faq-holder .accordion1:after {
        content: "\002B";
        color: #27c070;
        font-weight: normal;
        float: right;
        margin-left: 5px;
        font-size: 30px;
        position: absolute;
        right: 18px; }
    .o-case-studies-new .faq-holder .active1:after {
      content: "\2212"; }
    .o-case-studies-new .faq-holder .panel1 {
      padding: 0 18px;
      background-color: white;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      display: none;
      font-size: 19px;
      line-height: 30px;
      font-family: "Montserrat", sans-serif;
      font-weight: 500; }
      .o-case-studies-new .faq-holder .panel1 p {
        font-size: 16px;
        line-height: 30px;
        font-family: "Montserrat", sans-serif; }
        @media (min-width: 992px) {
          .o-case-studies-new .faq-holder .panel1 p {
            font-size: 19px;
            line-height: 30px;
            font-family: "Montserrat", sans-serif;
            font-weight: 500; } }

/*Feature section*/
.insights-section {
  padding-top: 40px;
  padding-bottom: 25px; }
  @media (min-width: 992px) {
    .insights-section {
      padding: 75px 0; } }
  .insights-section h2 {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 10px;
    text-align: center;
    color: #333;
    font-weight: 600; }
    @media (min-width: 992px) {
      .insights-section h2 {
        font-size: 48px;
        line-height: 61px;
        padding-bottom: 15px; } }
  .insights-section .row {
    margin: 0; }
  .insights-section .fi-pan {
    margin: 15px 0;
    box-shadow: none;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    overflow: hidden;
    width: 100%;
    padding: 0; }
    @media (min-width: 992px) {
      .insights-section .fi-pan {
        display: inline-block;
        vertical-align: top;
        margin: 15px;
        box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
        width: 30%; } }
    .insights-section .fi-pan a {
      display: block;
      padding: 40px 25px;
      position: relative;
      min-height: inherit;
      border-bottom: 5px solid #4a90e2; }
      @media (min-width: 992px) {
        .insights-section .fi-pan a {
          min-height: 340px; } }
    .insights-section .fi-pan .odd {
      border-bottom: 5px solid #009c52; }
    .insights-section .fi-pan h3 {
      text-align: left;
      text-transform: none;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 20px;
      font-size: 20px;
      color: #333;
      font-family: "Montserrat", sans-serif;
      font-weight: 600; }
      @media (min-width: 992px) {
        .insights-section .fi-pan h3 {
          text-align: left;
          font-size: 24px;
          line-height: 30px; } }
    .insights-section .fi-pan p {
      font-size: 16px;
      line-height: 24px;
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .insights-section .fi-pan p {
          font-size: 19px !important;
          text-align: left;
          line-height: 30px;
          color: #333;
          font-weight: 500;
          font-family: "Montserrat", sans-serif; } }
    .insights-section .fi-pan span {
      text-transform: uppercase;
      color: #27c070;
      font-size: 15px;
      font-weight: bold;
      font-family: "Montserrat", sans-serif;
      background: url(/images/common/arrow-green.png) no-repeat 100% 50%;
      display: inline-block;
      padding-right: 30px; }
      @media (min-width: 992px) {
        .insights-section .fi-pan span {
          float: left; } }

/*Why section*/
.c-icon-desc-3-col-ccn .row {
  margin-left: 0;
  margin-right: 0; }
  @media (min-width: 992px) {
    .c-icon-desc-3-col-ccn .row {
      margin-left: -15px;
      margin-right: -15px; } }

.c-icon-desc-3-col-ccn .row > div div:before {
  content: "";
  position: absolute;
  left: 0;
  width: 70px;
  height: 70px;
  right: inherit;
  margin: 0;
  top: 0;
  background-position: center center;
  background-repeat: no-repeat; }
  @media (min-width: 992px) {
    .c-icon-desc-3-col-ccn .row > div div:before {
      left: 0;
      right: inherit;
      margin: 0 auto; } }

.reasons-capital__blocks__choose-01:before {
  background-image: url(/images/common/why-choose-cn-1.svg) !important; }

.reasons-capital__blocks__choose-02:before {
  background-image: url(/images/common/why-choose-cn-2.svg) !important; }

.reasons-capital__blocks__choose-03:before {
  background-image: url(/images/common/why-choose-cn-3.svg) !important;
  background-size: 95%; }

.reasons-capital__blocks__choose-04:before {
  background-image: url(/images/common/why-choose-cn-4.svg) !important; }

.reasons-capital__blocks__choose-05:before {
  background-image: url(/images/common/why-choose-cn-5.svg) !important; }

.reasons-capital__blocks__choose-06:before {
  background-image: url(/images/common/why-choose-cn-6.svg) !important; }

.o-bottom-form {
  padding: 0; }
  @media (min-width: 992px) {
    .o-bottom-form {
      padding-right: 15px;
      padding-left: 15px;
      padding: 80px 0 90px; } }
  .o-bottom-form .form-text h2 {
    padding-bottom: 20px; }
    .o-bottom-form .form-text h2:before {
      content: "";
      position: absolute;
      background: #2ebf72;
      width: 50px;
      height: 1px;
      bottom: 7px;
      left: 0; }
  .o-bottom-form .container {
    padding-left: 0;
    padding-right: 0; }
    @media (min-width: 992px) {
      .o-bottom-form .container {
        padding-left: 15px;
        padding-right: 15px; } }

.home-pattern-tl {
  position: relative; }

/*let's talk*/
.lets-talk {
  background: url(/images/common/schedule-talk-bg.jpg);
  background-size: cover;
  padding: 30px 0 45px;
  margin-top: 0; }
  @media (min-width: 992px) {
    .lets-talk {
      margin-top: 25px; } }
  .lets-talk h2 {
    color: #fff;
    padding-bottom: 5px;
    font-size: 22px;
    line-height: 30px; }
    @media (min-width: 992px) {
      .lets-talk h2 {
        font-size: 48px;
        line-height: 61px;
        padding-bottom: 25px; } }
    .lets-talk h2 span {
      display: block; }
      @media (min-width: 992px) {
        .lets-talk h2 span {
          display: inline; } }

/*Custom software services*/
.mobile-on-cs {
  display: block; }
  @media (min-width: 992px) {
    .mobile-on-cs {
      display: none !important; } }

.mobile-on-flex {
  display: flex !important; }
  @media (min-width: 992px) {
    .mobile-on-flex {
      display: none !important; } }

.mobile-off {
  display: none; }
  @media (min-width: 992px) {
    .mobile-off {
      display: block; } }

.mobile-off-flex {
  display: none; }
  @media (min-width: 992px) {
    .mobile-off-flex {
      display: flex; } }

@media (min-width: 992px) {
  .masonry.service-wrap {
    column-count: 3;
    column-gap: 27px;
    margin-bottom: 30px; } }

.custom-software-development {
  padding: 40px 0 20px; }
  @media (min-width: 992px) {
    .custom-software-development {
      padding: 70px 0 50px; } }
  .custom-software-development .service-wrap .brick.services-pan:before {
    display: none; }
  .custom-software-development .service-wrap .brick.services-pan__php-service-01:before {
    background-position: 0 0; }
  .custom-software-development .service-wrap .brick.services-pan__php-service-02:before {
    background-position: -64px 0; }
  .custom-software-development .service-wrap .brick.services-pan__php-service-03:before {
    background-position: -128px 0; }
  .custom-software-development .service-wrap .brick.services-pan__php-service-04:before {
    background-position: 0 -63px; }
  .custom-software-development .service-wrap .brick.services-pan__php-service-05:before {
    background-position: -64px -63px; }
  .custom-software-development .service-wrap .brick.services-pan__php-service-06:before {
    background-position: -128px -63px; }
  .custom-software-development .service-wrap .brick.services-pan__php-service-07:before {
    background-position: 0 -126px; }
  .custom-software-development .service-wrap .brick.services-pan__php-service-08:before {
    background-position: -65px -126px; }

.cs-bnr-icon {
  display: flex;
  justify-content: center;
  max-width: 360px;
  margin: 0 auto;
  padding: 20px 0; }
  @media (min-width: 992px) {
    .cs-bnr-icon {
      display: none; } }
  .cs-bnr-icon li {
    flex: 1;
    text-align: center; }
    .cs-bnr-icon li:nth-child(1) {
      flex: 0 0 64px; }

.mobile-talk-bx {
  background: linear-gradient(160deg, #11a984 0%, #0d7d88 17%, #0a4f8e 50%);
  border-radius: 7px;
  padding: 25px 10px;
  margin-bottom: 20px; }
  .mobile-talk-bx h2 {
    font-size: 32px;
    color: #fff;
    position: relative;
    display: inline-block;
    margin-top: 25px;
    font-weight: 900; }
    .mobile-talk-bx h2:before {
      content: "";
      position: absolute;
      background: url(/images/mobile-all/talk-bg.png) no-repeat 0 0;
      width: 100px;
      height: 55px;
      right: -25px;
      top: 15px; }
      @media (min-width: 992px) {
        .mobile-talk-bx h2:before {
          top: 0; } }
    .mobile-talk-bx h2 span {
      position: relative; }
  .mobile-talk-bx .bold-p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 20px 10px;
    margin-bottom: 0;
    border-bottom: 1px dashed #ccc;
    color: #fff;
    font-family: "Montserrat", sans-serif; }
  .mobile-talk-bx h3 {
    color: #fff;
    font-size: 24px;
    line-height: 30px;
    margin-top: 15px;
    font-weight: 700; }
    .mobile-talk-bx h3 span {
      color: #36e079;
      margin-top: 15px;
      font-weight: 700; }
  .mobile-talk-bx .btn_wrapper {
    width: 100%;
    padding-top: 40px; }

/*banner two logo*/
.new-two-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 20px 0; }
  @media (min-width: 992px) {
    .new-two-logo {
      padding: 30px 0 30px 0; } }
  .new-two-logo li {
    margin: 0 10px; }
    @media (min-width: 992px) {
      .new-two-logo li {
        margin: 0 30px; } }

.o-skills-hero__background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: none; }
  @media (min-width: 992px) {
    .o-skills-hero__background {
      display: block; } }

@media (min-width: 992px) {
  .case-studies-details .o-skills-hero__background {
    display: block; } }

/*Out source product*/
.outsource-product {
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .outsource-product {
      padding: 70px 0 45px; } }
  .outsource-product .service-wrap .brick.services-pan:before {
    display: none; }
  .outsource-product .service-wrap .brick.services-pan__php-service-01:before {
    background-position: 0 0; }
  .outsource-product .service-wrap .brick.services-pan__php-service-02:before {
    background-position: -73px 0; }
  .outsource-product .service-wrap .brick.services-pan__php-service-03:before {
    background-position: -146px 0; }
  .outsource-product .service-wrap .brick.services-pan__php-service-04:before {
    background-position: 0 -63px; }
  .outsource-product .service-wrap .brick.services-pan__php-service-05:before {
    background-position: -73px -63px; }
  .outsource-product .service-wrap .brick.services-pan__php-service-06:before {
    background-position: -146px -63px; }
  .outsource-product .service-wrap .brick.services-pan__php-service-07:before {
    background-position: 0 -126px; }
  .outsource-product .service-wrap .brick.services-pan__php-service-08:before {
    background-position: -65px -126px; }
  .outsource-product .btn_wrapper {
    margin-top: 15px; }
    @media (min-width: 992px) {
      .outsource-product .btn_wrapper {
        margin-top: 0; } }

/*testing QA*/
.testing-qa {
  padding: 40px 0 20px; }
  @media (min-width: 992px) {
    .testing-qa {
      padding: 70px 0 45px; } }
  .testing-qa .btn_wrapper {
    margin-top: 15px; }
    @media (min-width: 992px) {
      .testing-qa .btn_wrapper {
        margin-top: 0; } }
  .testing-qa .service-wrap .brick.services-pan:before {
    display: none; }
  .testing-qa .service-wrap .brick.services-pan i {
    display: block;
    height: 55px; }
    .testing-qa .service-wrap .brick.services-pan i img {
      height: 100%; }
  .testing-qa .service-wrap .brick.services-pan__php-service-01:before {
    background-position: 0 0; }
  .testing-qa .service-wrap .brick.services-pan__php-service-02:before {
    background-position: -63px 0; }
  .testing-qa .service-wrap .brick.services-pan__php-service-03:before {
    background-position: -126px 0; }
  .testing-qa .service-wrap .brick.services-pan__php-service-04:before {
    background-position: 0 -63px; }
  .testing-qa .service-wrap .brick.services-pan__php-service-05:before {
    background-position: -63px -63px; }
  .testing-qa .service-wrap .brick.services-pan__php-service-06:before {
    background-position: -126px -63px; }
  .testing-qa .service-wrap .brick.services-pan__php-service-07:before {
    background-position: 0 -126px; }
  .testing-qa .service-wrap .brick.services-pan__php-service-08:before {
    background-position: -63px -126px; }
  .testing-qa .service-wrap .brick.services-pan__php-service-09:before {
    background-position: -126px -126px; }

/*dedicated development team*/
.dedicated-teams {
  padding: 40px 0 20px; }
  @media (min-width: 992px) {
    .dedicated-teams {
      padding: 70px 0 50px; } }
  .dedicated-teams .btn_wrapper {
    margin-top: 15px; }
    @media (min-width: 992px) {
      .dedicated-teams .btn_wrapper {
        margin-top: 0; } }
  .dedicated-teams h2 {
    font-size: 24px;
    line-height: 32px; }
    @media (min-width: 992px) {
      .dedicated-teams h2 {
        font-size: 48px;
        line-height: 61px; } }
  .dedicated-teams .service-wrap .brick.services-pan:before {
    display: none; }
  .dedicated-teams .service-wrap .brick.services-pan__php-service-01:before {
    background-position: 0 0; }
  .dedicated-teams .service-wrap .brick.services-pan__php-service-02:before {
    background-position: -63px 0; }
  .dedicated-teams .service-wrap .brick.services-pan__php-service-03:before {
    background-position: -126px 0; }
  .dedicated-teams .service-wrap .brick.services-pan__php-service-04:before {
    background-position: 0 -63px; }
  .dedicated-teams .service-wrap .brick.services-pan__php-service-05:before {
    background-position: -63px -63px; }
  .dedicated-teams .service-wrap .brick.services-pan__php-service-06:before {
    background-position: -126px -63px; }
  .dedicated-teams .service-wrap .brick.services-pan__php-service-07:before {
    background-position: 0 -126px; }
  .dedicated-teams .service-wrap .brick.services-pan__php-service-08:before {
    background-position: -63px -126px; }
  .dedicated-teams .service-wrap .brick.services-pan__php-service-09:before {
    background-position: -126px -126px; }
  .dedicated-teams .masonry.service-wrap {
    margin-bottom: 0; }

/*devops*/
.devops {
  padding: 45px 0 20px; }
  @media (min-width: 992px) {
    .devops {
      padding: 70px 0 45px; } }
  .devops .btn_wrapper {
    margin-top: 15px; }
    @media (min-width: 992px) {
      .devops .btn_wrapper {
        margin-top: 0; } }
  .devops h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600; }
    @media (min-width: 992px) {
      .devops h2 {
        font-size: 48px;
        line-height: 61px; } }
  .devops .service-wrap .brick.services-pan:before {
    display: none; }
  .devops .service-wrap .brick.services-pan__php-service-01:before {
    background-position: 0 0; }
  .devops .service-wrap .brick.services-pan__php-service-02:before {
    background-position: -70px 0; }
  .devops .service-wrap .brick.services-pan__php-service-03:before {
    background-position: -140px 0; }
  .devops .service-wrap .brick.services-pan__php-service-04:before {
    background-position: 0 -63px; }
  .devops .service-wrap .brick.services-pan__php-service-05:before {
    background-position: -70px -63px; }
  .devops .service-wrap .brick.services-pan__php-service-06:before {
    background-position: -140px -63px; }
  .devops .service-wrap .brick.services-pan__php-service-07:before {
    background-position: 0 -126px; }
  .devops .service-wrap .brick.services-pan__php-service-08:before {
    background-position: -70px -126px; }

.devops-stack {
  padding: 40px 0 30px;
  background: #05547d;
  position: relative; }
  @media (min-width: 992px) {
    .devops-stack {
      padding: 70px 0 20px; } }
  .devops-stack:after {
    display: none; }
  .devops-stack .container {
    position: relative;
    z-index: 9; }
  .devops-stack h2 {
    font-size: 30px;
    line-height: 46px;
    color: #fff;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .devops-stack h2 {
        font-size: 48px;
        line-height: 61px;
        margin-bottom: 25px; } }
  .devops-stack p {
    color: #fff;
    font-size: 16px;
    line-height: 28px;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 0;
    padding-bottom: 15px; }
    @media (min-width: 992px) {
      .devops-stack p {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 0;
        font-weight: 500;
        padding-bottom: 30px; } }
  .devops-stack .service-wrap-mod .services-pan {
    display: inline-block;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    background: #fff;
    vertical-align: top;
    margin: 15px 0 15px;
    padding: 120px 30px 30px;
    padding-top: 40px;
    padding-bottom: 40px; }
    .devops-stack .service-wrap-mod .services-pan:before {
      display: none; }
    .devops-stack .service-wrap-mod .services-pan h3 {
      font-weight: 600;
      padding-right: 0;
      position: relative;
      padding-bottom: 30px;
      text-align: left;
      font-size: 22px; }
      @media (min-width: 992px) {
        .devops-stack .service-wrap-mod .services-pan h3 {
          padding-right: 25px; } }
      .devops-stack .service-wrap-mod .services-pan h3:before {
        content: "";
        position: absolute;
        background: #24c06d;
        height: 3px;
        bottom: 5px;
        left: 0;
        margin: 0;
        width: 35px; }
        @media (min-width: 992px) {
          .devops-stack .service-wrap-mod .services-pan h3:before {
            right: 0;
            margin: 0; } }
    .devops-stack .service-wrap-mod .services-pan p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
      font-weight: 500;
      color: #333;
      text-align: left; }
      @media (min-width: 992px) {
        .devops-stack .service-wrap-mod .services-pan p {
          font-size: 19px;
          line-height: 30px;
          margin-bottom: 0; } }
    .devops-stack .service-wrap-mod .services-pan h4 {
      font-size: 19px;
      line-height: 25px;
      text-align: left;
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .devops-stack .service-wrap-mod .services-pan h4 {
          font-size: 19px;
          line-height: 25px;
          font-family: "Montserrat", sans-serif; } }
    .devops-stack .service-wrap-mod .services-pan ul {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      position: relative; }
      .devops-stack .service-wrap-mod .services-pan ul li {
        display: flex;
        align-items: center;
        padding: 0 0 20px;
        flex: 0 0 100%; }
        @media (min-width: 992px) {
          .devops-stack .service-wrap-mod .services-pan ul li {
            flex: 0 0 50%;
            align-items: flex-start; } }
        @media (min-width: 992px) {
          .devops-stack .service-wrap-mod .services-pan ul li:nth-child(2n + 1) {
            border-right: 1px solid #dedede;
            padding-right: 10px; } }
        @media (min-width: 992px) {
          .devops-stack .service-wrap-mod .services-pan ul li:nth-child(2n) {
            padding-left: 10px; } }
        .devops-stack .service-wrap-mod .services-pan ul li:last-child {
          padding-bottom: 0; }
        .devops-stack .service-wrap-mod .services-pan ul li span {
          display: inline-block;
          border-radius: 50%;
          text-align: center;
          margin-right: 15px;
          flex: 0 0 30px; }
          @media (min-width: 992px) {
            .devops-stack .service-wrap-mod .services-pan ul li span {
              flex: initial; } }
        .devops-stack .service-wrap-mod .services-pan ul li h5 {
          color: #333;
          font-family: "Montserrat", sans-serif;
          margin-bottom: 0;
          font-size: 14px;
          line-height: 18px;
          flex: 1;
          text-align: left; }
          @media (min-width: 992px) {
            .devops-stack .service-wrap-mod .services-pan ul li h5 {
              font-family: "Montserrat", sans-serif;
              margin-bottom: 0.5rem;
              font-size: 14px;
              line-height: 18px; } }
  .devops-stack .bottom-left-pt {
    position: absolute;
    background: url(/images/common/bottom-left-pt.png) bottom left;
    background-repeat: no-repeat;
    z-index: 9;
    width: 615px;
    height: 325px;
    bottom: 0;
    left: 0;
    display: none; }
    @media (min-width: 992px) {
      .devops-stack .bottom-left-pt {
        display: block; } }

/*Ecommerce*/
.o-skills-hero__e-commerce {
  position: relative;
  background: linear-gradient(138deg, #165b5e 0, #0f3a4a 17%, #091b38 50%); }
  .o-skills-hero__e-commerce .container {
    position: relative;
    z-index: 9; }
  .o-skills-hero__e-commerce h1 {
    color: #fff;
    text-align: center;
    font-weight: 700; }
    @media (min-width: 992px) {
      .o-skills-hero__e-commerce h1 {
        font-size: 62px;
        line-height: 75px; } }
  .o-skills-hero__e-commerce .o-services-hero__icons {
    text-align: center;
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .o-skills-hero__e-commerce .o-services-hero__icons {
        margin: 30px 0 60px;
        display: block; } }
    .o-skills-hero__e-commerce .o-services-hero__icons a {
      display: inline-block;
      cursor: pointer;
      vertical-align: bottom;
      flex: 1;
      padding: 0;
      width: 30%; }
      @media (min-width: 992px) {
        .o-skills-hero__e-commerce .o-services-hero__icons a {
          width: auto; } }
      .o-skills-hero__e-commerce .o-services-hero__icons a:hover {
        transform: scale(0.9); }
      .o-skills-hero__e-commerce .o-services-hero__icons a img {
        margin: 0;
        height: 100px;
        width: 55%;
        height: auto; }
        @media (min-width: 992px) {
          .o-skills-hero__e-commerce .o-services-hero__icons a img {
            margin: 0 30px;
            width: auto; } }
    .o-skills-hero__e-commerce .o-services-hero__icons span {
      display: block;
      color: #fff;
      font-family: "Montserrat", sans-serif;
      margin-top: 15px;
      font-weight: 600;
      font-size: 13px;
      text-align: center; }
      @media (min-width: 992px) {
        .o-skills-hero__e-commerce .o-services-hero__icons span {
          font-size: 18px; } }

.correct-section {
  padding: 45px 0 45px;
  position: relative;
  background: #f5f5f5; }
  @media (min-width: 992px) {
    .correct-section {
      padding: 70px 0 75px; } }
  .correct-section h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
    text-align: center; }
    @media (min-width: 992px) {
      .correct-section h2 {
        font-size: 48px;
        line-height: 56px;
        margin-bottom: 30px; } }
  .correct-section p {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      .correct-section p {
        margin-bottom: 35px;
        font-size: 19px;
        line-height: 29px;
        font-family: "Montserrat", sans-serif;
        padding: 0 90px; } }
  .correct-section .correct-img {
    position: relative;
    text-align: center; }
    @media (min-width: 992px) {
      .correct-section .correct-img {
        text-align: left; } }
    .correct-section .correct-img img {
      width: 300px;
      margin: 0 auto; }
      @media (min-width: 992px) {
        .correct-section .correct-img img {
          position: absolute;
          top: 0;
          width: 100%;
          margin: 0; } }
  .correct-section .cstm-link-p {
    margin-bottom: 0; }

.number-section {
  padding-bottom: 10px;
  position: relative; }
  @media (min-width: 992px) {
    .number-section {
      padding-top: 70px;
      padding-bottom: 40px; } }
  .number-section h2 {
    font-size: 24px;
    line-height: 32px;
    color: #fff;
    padding-bottom: 30px; }
    @media (min-width: 992px) {
      .number-section h2 {
        padding: 0 50px;
        font-size: 48px;
        line-height: 61px;
        margin-bottom: 50px; } }
  .number-section .dc-white-pan {
    text-align: left;
    position: relative;
    border-radius: 6px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    height: auto;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1); }
    @media (min-width: 992px) {
      .number-section .dc-white-pan {
        height: 295px;
        padding: 30px; } }
    .number-section .dc-white-pan div {
      overflow: hidden;
      position: relative; }
      @media (min-width: 992px) {
        .number-section .dc-white-pan div {
          margin-bottom: 30px; } }
      .number-section .dc-white-pan div span {
        font-size: 36px;
        font-weight: 500;
        float: left;
        color: #14df7d;
        font-family: "Montserrat", sans-serif;
        line-height: 71px;
        vertical-align: middle; }
      .number-section .dc-white-pan div .body-text-medium {
        float: right;
        width: 78%;
        padding-top: 20px;
        font-size: 15px;
        line-height: 24px;
        font-weight: 500;
        font-family: "Montserrat", sans-serif; }
        @media screen and (min-width: 1200px) {
          .number-section .dc-white-pan div .body-text-medium {
            font-size: 18px;
            line-height: 30px;
            font-family: "Montserrat", sans-serif; } }
  .number-section h3 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 25px;
    margin-top: 0;
    text-align: center;
    width: 100%; }
    @media (min-width: 992px) {
      .number-section h3 {
        font-size: 48px;
        margin-top: 30px; } }
  .number-section .section-para {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    margin-bottom: 25px;
    padding: 0 15px; }
    @media (min-width: 992px) {
      .number-section .section-para {
        padding: 0 60px;
        font-size: 19px;
        line-height: 29px;
        font-family: "Montserrat", sans-serif; } }
  .number-section-bnt {
    text-align: center;
    margin-bottom: 25px; }
    .number-section-bnt a {
      display: inline-block;
      padding: 10px 50px 10px 15px;
      line-height: 46px;
      background: transparent;
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 13px;
      font-family: "Montserrat", sans-serif;
      border-radius: 6px;
      border: 3px solid #14df7d;
      margin-bottom: 15px;
      transition: 0.3s all ease-in;
      width: 280px; }
      @media (min-width: 992px) {
        .number-section-bnt a {
          width: 470px;
          height: 72px;
          margin: 0 15px 15px;
          font-size: 24px;
          font-weight: 500; } }
      .number-section-bnt a:before {
        content: "";
        position: absolute;
        background: url(/images/common/right-circle-icon.png) no-repeat right center;
        height: 35px;
        width: 35px;
        top: 15px;
        right: 10px; }
        @media (min-width: 992px) {
          .number-section-bnt a:before {
            right: 30px; } }
      .number-section-bnt a:hover {
        background: #fff;
        color: #ff5800;
        border: 3px solid #ff5800; }
  .number-section .row.padding-bottom-30 {
    padding-bottom: 0; }
    @media (min-width: 992px) {
      .number-section .row.padding-bottom-30 {
        padding-bottom: 30px; } }

@media (min-width: 992px) {
  #aes {
    padding: 70px 0 80px; } }

#aes .btn_wrapper a {
  margin: 20px auto 0;
  padding: 15px 0;
  width: 240px;
  max-width: none; }
  @media (min-width: 992px) {
    #aes .btn_wrapper a {
      margin: 0; } }

#solution-container .cstm-link-p {
  text-align: center !important; }
  @media (min-width: 992px) {
    #solution-container .cstm-link-p {
      text-align: left !important; } }

.solution-section {
  position: relative;
  padding: 40px 0 30px; }
  @media (min-width: 992px) {
    .solution-section {
      padding: 70px 0 45px; } }
  .solution-section:before {
    content: "";
    background: url(/images/e-commerce/top-right-pt.png) no-repeat top right;
    position: absolute;
    top: 0;
    right: 0;
    width: 615px;
    height: 326px;
    display: none; }
    @media (min-width: 992px) {
      .solution-section:before {
        display: block; } }
  .solution-section h2 {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 32px;
    text-align: center; }
    @media (min-width: 992px) {
      .solution-section h2 {
        font-size: 48px;
        line-height: 61px;
        margin-bottom: 25px;
        text-align: left; } }
  .solution-section p {
    font-size: 16px;
    line-height: 28px;
    color: #333;
    margin-bottom: 10px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    text-align: center; }
    @media (min-width: 992px) {
      .solution-section p {
        font-size: 20px;
        line-height: 32px;
        font-family: "Montserrat", sans-serif;
        text-align: left;
        margin-bottom: 20px; } }
  .solution-section .solution-left h3 {
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 28px;
    margin-top: 30px;
    text-align: center; }
    @media (min-width: 992px) {
      .solution-section .solution-left h3 {
        font-size: 24px;
        line-height: 32px;
        margin-top: 20px;
        text-align: left; } }
  .solution-section .solution-left .add-more {
    font-family: "Montserrat", sans-serif;
    color: #333;
    font-size: 16px;
    margin: 20px auto 30px;
    width: 150px;
    display: block; }
    @media (min-width: 992px) {
      .solution-section .solution-left .add-more {
        display: inline-block;
        width: auto;
        margin: 20px 0 30px; } }
  @media (min-width: 992px) {
    .solution-section .solution-left .anchor-link {
      text-align: left; } }
  .solution-section ul {
    padding-left: 15px; }
    @media (min-width: 992px) {
      .solution-section ul {
        padding-left: 0; } }
    .solution-section ul li {
      position: relative;
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      line-height: 28px;
      padding-left: 40px;
      margin-bottom: 10px; }
      @media (min-width: 992px) {
        .solution-section ul li {
          font-size: 19px;
          line-height: 24px;
          font-family: "Montserrat", sans-serif; } }
      .solution-section ul li:before {
        content: "";
        position: absolute;
        background: url(/images/e-commerce/black-circle-icon.png) no-repeat 0 2px;
        width: 30px;
        height: 30px;
        top: 0;
        left: 0; }
  .solution-section .btn_wrapper a {
    margin: 0 auto; }
    @media (min-width: 992px) {
      .solution-section .btn_wrapper a {
        margin: 0; } }
  .solution-section .body-img2 {
    position: relative; }
  .solution-section .solution-white-box  {
    background: #fff;
    padding: 30px 15px 30px 15px;
    border-radius: 6px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    margin-top: 15px;
    border: 1px solid #e0e0e0; }
    @media (min-width: 992px) {
      .solution-section .solution-white-box  {
        padding: 50px 25px 50px 40px;
        margin-bottom: 0;
        margin-top: 0; } }
    .solution-section .solution-white-box  span {
      width: auto;
      text-align: center;
      display: block;
      flex: 0 0 100%; }
      @media (min-width: 992px) {
        .solution-section .solution-white-box  span {
          display: inline;
          width: auto;
          text-align: left;
          width: auto;
          flex: inherit; } }
    .solution-section .solution-white-box  img {
      margin-right: 0;
      margin-bottom: 15px; }
      @media (min-width: 992px) {
        .solution-section .solution-white-box  img {
          margin-right: 30px;
          margin-bottom: 0; } }
    .solution-section .solution-white-box  h4 {
      font-size: 20px;
      text-align: center;
      flex: 0 0 100%; }
      @media (min-width: 992px) {
        .solution-section .solution-white-box  h4 {
          float: left;
          position: relative;
          top: 5px;
          font-weight: 600;
          font-size: 36px;
          line-height: 43px;
          text-align: left;
          flex: inherit; } }
    .solution-section .solution-white-box  p {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      text-align: center;
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .solution-section .solution-white-box  p {
          font-size: 19px;
          line-height: 29px;
          text-align: left;
          font-family: "Montserrat", sans-serif; } }
    .solution-section .solution-white-box  h5 {
      margin-bottom: 25px;
      font-size: 17px;
      line-height: 25px;
      font-weight: 600; }
      @media (min-width: 992px) {
        .solution-section .solution-white-box  h5 {
          font-size: 24px;
          line-height: 32px; } }
    .solution-section .solution-white-box  ul {
      padding-left: 15px; }
      @media (min-width: 992px) {
        .solution-section .solution-white-box  ul {
          margin-bottom: 40px;
          padding-left: 0; } }
      .solution-section .solution-white-box  ul li {
        position: relative;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        line-height: 24px;
        padding-left: 40px;
        margin-bottom: 10px; }
        @media (min-width: 992px) {
          .solution-section .solution-white-box  ul li {
            font-family: "Montserrat", sans-serif;
            font-size: 19px;
            line-height: 24px; } }
  @media (min-width: 992px) {
    .solution-section-right img {
      margin-bottom: 80px; } }

.bg-grey {
  background-color: #f5f5f5; }

.solution-white-box-head {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap; }
  @media (min-width: 992px) {
    .solution-white-box-head {
      height: 70px;
      flex-wrap: nowrap;
      margin-bottom: 40px; } }

.e-commerce-why {
  position: relative;
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .e-commerce-why {
      padding: 70px 0 50px; } }
  .e-commerce-why h2 {
    color: #333;
    margin-bottom: 5px; }
    @media (min-width: 992px) {
      .e-commerce-why h2 {
        font-size: 48px;
        line-height: 61px;
        margin-bottom: 25px;
        color: #333; } }
  .e-commerce-why .main-span {
    color: #333;
    text-align: center;
    display: block;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .e-commerce-why .main-span {
        font-size: 19px;
        line-height: 27px;
        margin-bottom: 20px;
        color: #fff; } }
  .e-commerce-why .service-wrap {
    text-align: center;
    margin-bottom: 15px; }
    .e-commerce-why .service-wrap .services-pan {
      position: relative;
      display: inline-block;
      vertical-align: top;
      margin: 15px 0 !important;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      text-align: center;
      background: #fff; }
      @media (min-width: 992px) {
        .e-commerce-why .service-wrap .services-pan {
          width: 30%;
          padding: 120px 25px 30px;
          margin: 17px 15px !important; } }
      .e-commerce-why .service-wrap .services-pan:before {
        display: none; }
      .e-commerce-why .service-wrap .services-pan img {
        display: block;
        margin: 0 auto; }
    .e-commerce-why .service-wrap .brick.services-pan__php-service-01:before {
      background-position: 0 0; }
    .e-commerce-why .service-wrap .brick.services-pan__php-service-02:before {
      background-position: -68px 0; }
    .e-commerce-why .service-wrap .brick.services-pan__php-service-03:before {
      background-position: -135px 0; }
    .e-commerce-why .service-wrap .brick.services-pan__php-service-04:before {
      background-position: 0 -71px; }
    .e-commerce-why .service-wrap .brick.services-pan__php-service-05:before {
      background-position: -68px -71px; }

/*cms*/
.cms-bnr {
  position: relative;
  background: linear-gradient(138deg, #165b5e 0, #0f3a4a 17%, #091b38 50%); }
  .cms-bnr .container {
    position: relative;
    z-index: 9; }
  .cms-bnr h1 {
    color: #fff;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px; }
    @media (min-width: 992px) {
      .cms-bnr h1 {
        font-size: 62px;
        line-height: 75px;
        font-weight: 700; } }
  .cms-bnr .o-services-hero__icons {
    margin: 0 0 40px; }
    @media (min-width: 992px) {
      .cms-bnr .o-services-hero__icons {
        margin: 0 0 60px; } }
    .cms-bnr .o-services-hero__icons a {
      display: inline-block;
      cursor: pointer;
      vertical-align: bottom;
      padding: 0 5px;
      width: 23%; }
      @media (min-width: 992px) {
        .cms-bnr .o-services-hero__icons a {
          padding: 0 30px;
          width: auto; } }
      .cms-bnr .o-services-hero__icons a:hover {
        transform: scale(0.9); }
      .cms-bnr .o-services-hero__icons a span {
        font-size: 13px; }
        @media (min-width: 992px) {
          .cms-bnr .o-services-hero__icons a span {
            font-size: 18px; } }
    .cms-bnr .o-services-hero__icons img {
      max-width: 65%; }
      @media (min-width: 992px) {
        .cms-bnr .o-services-hero__icons img {
          max-width: 100%; } }

.cms-team {
  padding: 40px 0 25px;
  text-align: center; }
  @media (min-width: 992px) {
    .cms-team {
      padding: 75px 0 50px;
      text-align: left; } }
  .cms-team h2 {
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .cms-team h2 {
        margin-bottom: 15px; } }
  .cms-team p {
    font-size: 16px;
    line-height: 28px;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .cms-team p {
        font-size: 19px;
        line-height: 30px;
        margin-bottom: 25px;
        font-family: "Montserrat", sans-serif; } }

.cms-dev-service {
  padding: 45px 0 20px; }
  @media (min-width: 992px) {
    .cms-dev-service {
      padding: 70px 0 45px; } }
  .cms-dev-service .service-wrap {
    text-align: center;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .cms-dev-service .service-wrap {
        margin-bottom: 35px; } }
    .cms-dev-service .service-wrap .brick.services-pan {
      display: inline-block;
      vertical-align: top;
      margin: 15px 0;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      text-align: center;
      padding: 50px 15px 35px;
      position: relative; }
      @media screen and (min-width: 768px) {
        .cms-dev-service .service-wrap .brick.services-pan {
          width: 45%;
          height: 680px;
          padding: 50px 30px 30px;
          margin: 15px; } }
      @media screen and (min-width: 992px) {
        .cms-dev-service .service-wrap .brick.services-pan {
          width: 46%; } }
      @media screen and (min-width: 1200px) {
        .cms-dev-service .service-wrap .brick.services-pan {
          width: 30%; } }
      .cms-dev-service .service-wrap .brick.services-pan:before {
        display: none; }
      .cms-dev-service .service-wrap .brick.services-pan i {
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: initial; }
        @media (min-width: 992px) {
          .cms-dev-service .service-wrap .brick.services-pan i {
            align-items: flex-end; } }
    .cms-dev-service .service-wrap .brick.services-pan__php-service-01:before {
      background-position: 0px -10px; }
    .cms-dev-service .service-wrap .brick.services-pan__php-service-02:before {
      background-position: -95px -5px; }
    .cms-dev-service .service-wrap .brick.services-pan__php-service-03:before {
      background-position: -195px 0px; }
    .cms-dev-service .service-wrap .brick.services-pan__php-service-04:before {
      background-position: -289px -11px; }
    .cms-dev-service .service-wrap .brick.services-pan__php-service-05:before {
      background-position: -382px -10px; }

.cms-why {
  padding: 50px 0; }
  @media (min-width: 992px) {
    .cms-why {
      padding: 70px 0 52px; } }
  .cms-why h2 {
    color: #fff;
    margin-bottom: 15px; }
  .cms-why p {
    color: #fff;
    font-size: 16px;
    line-height: 28px;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .cms-why p {
        padding: 0px 40px;
        font-size: 28px;
        line-height: 40px; } }
  .cms-why .cstm-link-p {
    line-height: 0; }
  .cms-why .service-wrap {
    text-align: center;
    margin-bottom: 35px; }
    .cms-why .service-wrap .brick.services-pan {
      display: inline-block;
      vertical-align: top;
      margin: 15px 0;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      text-align: center;
      padding: 5px 15px 30px;
      position: relative; }
      @media (min-width: 992px) {
        .cms-why .service-wrap .brick.services-pan {
          height: 410px;
          width: 46%;
          padding: 50px 30px 30px;
          margin: 15px; } }
      .cms-why .service-wrap .brick.services-pan:before {
        display: none; }
      .cms-why .service-wrap .brick.services-pan i {
        height: 55px;
        display: block; }
        .cms-why .service-wrap .brick.services-pan i img {
          height: 100%; }
    .cms-why .service-wrap .brick.services-pan__php-service-01:before {
      background-position: -10px -90px; }
    .cms-why .service-wrap .brick.services-pan__php-service-02:before {
      background-position: -114px -90px; }
    .cms-why .service-wrap .brick.services-pan__php-service-03:before {
      background-position: -220px -90px; }
    .cms-why .service-wrap .brick.services-pan__php-service-04:before {
      background-position: -325px -90px; }
    @media (min-width: 992px) {
      .cms-why .service-wrap .brick.services-pan.agencies-services {
        height: 415px; } }
    .cms-why .service-wrap .brick.services-pan.agencies-services:before {
      display: none; }
    .cms-why .service-wrap .brick.agencies-services.services-pan__php-service-01:before {
      background-position: 0 0; }
    .cms-why .service-wrap .brick.agencies-services.services-pan__php-service-02:before {
      background-position: -87px 0px; }
    .cms-why .service-wrap .brick.agencies-services.services-pan__php-service-03:before {
      background-position: -174px 0px; }
    .cms-why .service-wrap .brick.agencies-services.services-pan__php-service-04:before {
      background-position: -261px 0px; }
    .cms-why .service-wrap h3 {
      font-size: 20px;
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .cms-why .service-wrap h3 {
          font-size: 24px;
          font-family: "Montserrat", sans-serif; } }
    .cms-why .service-wrap p {
      color: #333;
      font-size: 16px;
      line-height: 28px;
      padding: 0;
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .cms-why .service-wrap p {
          font-size: 18px;
          line-height: 26px; } }

.dedicated-team {
  padding: 45px 0 20px;
  background-color: #f8f8f8; }
  .dedicated-team .row.margin-top-30 {
    margin-top: 10px; }
    @media (min-width: 992px) {
      .dedicated-team .row.margin-top-30 {
        margin-top: 30px; } }
  @media (min-width: 992px) {
    .dedicated-team .margin-top-30 {
      margin-top: 30px; } }
  @media (min-width: 992px) {
    .dedicated-team {
      padding: 70px 0 50px; } }
  .dedicated-team h2 {
    padding-bottom: 10px;
    font-weight: 700; }
    @media (min-width: 992px) {
      .dedicated-team h2 {
        padding-bottom: 30px; } }
  .dedicated-team .cms-blocks {
    position: relative; }
    @media (min-width: 992px) {
      .dedicated-team .cms-blocks {
        padding: 0 40px 0 70px; } }
    .dedicated-team .cms-blocks p {
      font-size: 16px;
      text-align: center;
      line-height: 28px;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 15px; }
      @media (min-width: 992px) {
        .dedicated-team .cms-blocks p {
          font-size: 19px;
          text-align: left;
          font-family: "Montserrat", sans-serif;
          margin-bottom: 25px; } }
    .dedicated-team .cms-blocks div:before {
      content: "";
      position: absolute;
      left: 0;
      background-image: url(/images/cms-developer/cms-team.png);
      width: 53px;
      height: 57px;
      display: none; }
      @media (min-width: 992px) {
        .dedicated-team .cms-blocks div:before {
          display: block; } }
    .dedicated-team .cms-blocks .cms-blocks__choose-01:before {
      background-position: 0px 0px; }
    .dedicated-team .cms-blocks .cms-blocks__choose-02:before {
      background-position: -185px 0px; }
    .dedicated-team .cms-blocks .cms-blocks__choose-03:before {
      background-position: -62px -67px; }
    .dedicated-team .cms-blocks .cms-blocks__choose-04:before {
      background-position: -63px 0px; }
    .dedicated-team .cms-blocks .cms-blocks__choose-05:before {
      background-position: -252px 0px; }
    .dedicated-team .cms-blocks .cms-blocks__choose-06:before {
      background-position: -126px -67px; }
    .dedicated-team .cms-blocks .cms-blocks__choose-07:before {
      background-position: -125px 0px; }
    .dedicated-team .cms-blocks .cms-blocks__choose-08:before {
      background-position: 0px -67px; }
    .dedicated-team .cms-blocks .cms-blocks__choose-09:before {
      background-position: -185px -67px; }

.o-services-hero__icons {
  margin: 0 0 40px; }
  @media (min-width: 992px) {
    .o-services-hero__icons {
      margin: 0 0 60px; } }
  .o-services-hero__icons a {
    display: inline-block;
    cursor: pointer;
    vertical-align: bottom;
    padding: 0 5px;
    width: 23%; }
    @media (min-width: 992px) {
      .o-services-hero__icons a {
        padding: 0 30px;
        width: auto; } }
    .o-services-hero__icons a:hover {
      transform: scale(0.9); }
    .o-services-hero__icons a span {
      font-size: 13px; }
      @media (min-width: 992px) {
        .o-services-hero__icons a span {
          font-size: 18px; } }

/*Mobile app*/
.o-skills-hero__mobile-app {
  padding-bottom: 20px; }
  .o-skills-hero__mobile-app h1 {
    text-align: center;
    padding-bottom: 0;
    font-size: 24px;
    line-height: 32px; }
    @media (min-width: 992px) {
      .o-skills-hero__mobile-app h1 {
        padding: 0 120px;
        padding-bottom: 30px;
        font-size: 62px;
        line-height: 75px; } }
  .o-skills-hero__mobile-app h2 {
    font-size: 20px;
    text-align: center;
    color: #fff;
    line-height: 30px;
    margin-bottom: 0;
    margin-top: 15px !important; }
    @media (min-width: 992px) {
      .o-skills-hero__mobile-app h2 {
        font-size: 26px;
        line-height: 36px;
        margin-top: 20px;
        margin-bottom: 35px; } }
  .o-skills-hero__mobile-app .o-services-hero__icons img {
    max-width: 60%; }
    @media (min-width: 992px) {
      .o-skills-hero__mobile-app .o-services-hero__icons img {
        max-width: 100%; } }

.beauty-section {
  padding: 40px 0 20px; }
  @media (min-width: 992px) {
    .beauty-section {
      padding: 70px 0 50px; } }
  .beauty-section h2 {
    margin-bottom: 15px;
    text-align: center;
    line-height: 38px; }
    @media (min-width: 992px) {
      .beauty-section h2 {
        padding: 0 210px;
        line-height: 61px; } }
  @media (min-width: 992px) {
    .beauty-section p.cmn-heading-para {
      padding: 0 40px;
      margin-bottom: 40px;
      font-family: "Montserrat", sans-serif; } }
  @media (min-width: 992px) {
    .beauty-section .beauty-list {
      padding: 0 50px; } }
  .beauty-section .beauty-list span {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    margin-bottom: 20px;
    padding-left: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500; }
    @media (min-width: 992px) {
      .beauty-section .beauty-list span {
        margin-bottom: 30px;
        font-size: 19px;
        line-height: 29px;
        font-family: "Montserrat", sans-serif;
        padding-left: 40px; } }
    .beauty-section .beauty-list span:before {
      content: "";
      position: absolute;
      background: url(/images/mobile-app/circle-tick.svg) no-repeat 0 0;
      top: 4px;
      left: 0;
      height: 35px;
      width: 35px; }
      @media (min-width: 992px) {
        .beauty-section .beauty-list span:before {
          background: url(/images/mobile-app/right-arrow.png) no-repeat 0 0;
          top: 0; } }
  .beauty-section .btn_wrapper {
    margin-top: 15px; }
    @media (min-width: 992px) {
      .beauty-section .btn_wrapper {
        margin-top: 0; } }

@media (min-width: 992px) {
  .scroll-section .js-mobileapp-feature-1-parallax-1 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    bottom: 0px;
    left: 0;
    background: url(/images/mobile-app/consumer-apps-bg.png) 100% 100% no-repeat #115687;
    background-size: 70%; } }

@media (min-width: 992px) {
  .scroll-section .js-mobileapp-feature-2-parallax-1 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    bottom: 0px;
    left: 0;
    background: url(/images/mobile-app/enterprise-apps-bg.png) 100% 100% no-repeat #43ae76;
    background-size: 84%; } }

.scroll-section .mobile-holder-clip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip: rect(auto, auto, auto, auto);
  display: none; }
  @media (min-width: 992px) {
    .scroll-section .mobile-holder-clip {
      display: block; } }
  .scroll-section .mobile-holder-clip .mobile-holder-phone {
    width: 33vw;
    height: 50vh;
    position: fixed;
    bottom: -20px;
    right: 60px; }
    .scroll-section .mobile-holder-clip .mobile-holder-phone .mobile-in-hand {
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: auto;
      display: none; }
      @media (min-width: 992px) {
        .scroll-section .mobile-holder-clip .mobile-holder-phone .mobile-in-hand {
          display: block; } }

@media (min-width: 992px) {
  .scroll-section .container {
    height: 100%; } }

.scroll-section .js-mobileapp-features-content-1 h2 {
  font-size: 24px;
  padding: 25px 0 10px 0;
  pointer-events: inherit; }
  @media (min-width: 992px) {
    .scroll-section .js-mobileapp-features-content-1 h2 {
      font-size: 34px;
      text-align: left;
      padding: 0;
      margin-bottom: 50px;
      pointer-events: none; } }

.scroll-section p {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 28px;
  position: relative; }
  @media (min-width: 992px) {
    .scroll-section p {
      font-size: 19px;
      line-height: 29px;
      font-family: "Montserrat", sans-serif; } }

.scroll-section .btn_wrapper {
  margin: 30px 0 0; }
  .scroll-section .btn_wrapper a {
    margin: 0; }

.scroll-section .anchor-link {
  margin: 10px 0 0;
  text-align: left; }
  @media (min-width: 992px) {
    .scroll-section .anchor-link {
      margin: 10px 0 0; } }

.js-mobileapp-features-content-2 h2 {
  padding: 25px 0 10px 0;
  font-size: 24px; }
  @media (min-width: 992px) {
    .js-mobileapp-features-content-2 h2 {
      padding: 0;
      font-size: 48px; } }

/*.o-mobile-app-features__content {
    display: none;
    @include responsive(desktop){
        display: block;
    }
}*/
.o-mobile-app-features {
  color: #fff;
  position: relative;
  padding: 120px 0 20px;
  overflow: hidden;
  pointer-events: inherit; }
  @media (min-width: 992px) {
    .o-mobile-app-features {
      padding: 70px 0 50px; } }

.o-mobile-app-features--blue {
  background: none #115687; }

.h3-24 {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600; }

.c-ticked-list--light li {
  position: relative;
  padding: 0 0 5px 30px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .c-ticked-list--light li {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      line-height: 29px;
      font-size: 19px;
      padding: 0 0 15px 35px; } }
  .c-ticked-list--light li:before {
    content: "";
    position: absolute;
    background: url(/images/mobile-app/circle-tick.svg) 50% 50% no-repeat;
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 4px;
    left: 0px; }
    @media (min-width: 992px) {
      .c-ticked-list--light li:before {
        background: url(/images/common/icon-bullet.svg) 50% 50% no-repeat;
        top: 5px; } }

.o-mobile-app-features--green {
  background: #43ae76; }

/* Mobile Tab */
.o-mobile-app-features-parent {
  position: relative; }

.o-mobile-app-features-tab {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between; }

.o-mobile-app-features-tab-btn {
  padding: 0.5rem 1rem;
  border: 1px solid #fff;
  border-radius: 3px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: none;
  margin-right: 10px; }
  .o-mobile-app-features-tab-btn:last-child {
    margin: 0; }
  .o-mobile-app-features-tab-btn:before, .o-mobile-app-features-tab-btn:after {
    content: "";
    border: 9px solid transparent;
    border-top-color: #fff;
    border-bottom: 0;
    position: absolute;
    left: 50%;
    bottom: -9px;
    transform: translate(-50%, 0);
    display: none; }
  .o-mobile-app-features-tab-btn:after {
    border: 8px solid transparent;
    border-top-color: #115687;
    border-bottom: 0;
    bottom: -7px;
    z-index: 1; }
  .o-mobile-app-features-tab-btn.active:before, .o-mobile-app-features-tab-btn.active:after {
    display: block; }
  .o-mobile-app-features-tab-btn--bg-blue {
    background: #115687; }
  .o-mobile-app-features-tab-btn--bg-green {
    background: #43ae76; }
    .o-mobile-app-features-tab-btn--bg-green:after {
      border-top-color: #43ae76; }

/* Mobile Tab */
.service-process {
  padding: 45px 0 20px; }
  @media (min-width: 992px) {
    .service-process {
      padding: 70px 0 50px; } }
  .service-process h2 {
    font-size: 30px;
    font-weight: 700;
    color: #333;
    padding-bottom: 20px; }
    @media (min-width: 992px) {
      .service-process h2 {
        padding-bottom: 30px;
        font-size: 48px;
        color: #333; } }
  .service-process .dc-white-pan {
    position: relative;
    height: auto;
    min-height: inherit;
    text-align: left;
    padding: 50px 30px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1); }
    @media (min-width: 992px) {
      .service-process .dc-white-pan {
        min-height: 495px;
        padding: 30px;
        border: none; } }
    .service-process .dc-white-pan:before {
      content: "";
      background: url(/images/mobile-app/process-group-icon.png) no-repeat 0 0;
      width: 66px;
      height: 65px;
      position: absolute;
      right: 10px;
      top: 30px; }
      @media (min-width: 992px) {
        .service-process .dc-white-pan:before {
          right: 25px; } }
    .service-process .dc-white-pan div {
      margin-bottom: 15px;
      overflow: hidden;
      position: relative; }
      .service-process .dc-white-pan div span {
        float: left;
        color: #14df7d;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        vertical-align: middle; }
        @media (min-width: 992px) {
          .service-process .dc-white-pan div span {
            font-size: 58px;
            line-height: 71px;
            font-weight: 300; } }
      .service-process .dc-white-pan div h3 {
        vertical-align: middle;
        font-size: 20px;
        line-height: 28px;
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translate(0, -50%); }
        @media (min-width: 992px) {
          .service-process .dc-white-pan div h3 {
            font-size: 22px;
            line-height: 32px;
            left: 90px; } }
    .service-process .dc-white-pan .body-text-medium {
      width: 100%;
      font-size: 16px;
      line-height: 28px;
      color: #333;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      text-align: left;
      margin-bottom: 0; }
      @media (min-width: 992px) {
        .service-process .dc-white-pan .body-text-medium {
          font-size: 19px;
          line-height: 32px;
          text-align: left;
          font-family: "Montserrat", sans-serif;
          margin-bottom: 25px; } }
  .service-process .process-box1:before {
    background-position: 0 0; }
  .service-process .process-box2:before {
    background-position: -67px 0; }
  .service-process .process-box3:before {
    background-position: -132px 0; }
  .service-process .process-box4:before {
    background-position: -198px 0; }
  .service-process .slick-dots {
    margin-top: 0; }

/*AI chatbot*/
.ai-chatbots-hero {
  padding-bottom: 0; }
  @media (min-width: 992px) {
    .ai-chatbots-hero {
      padding-bottom: 0; } }
  .ai-chatbots-hero .btn_wrapper {
    padding-top: 0; }
    @media (min-width: 992px) {
      .ai-chatbots-hero .btn_wrapper {
        padding-top: 40px; } }
  .ai-chatbots-hero .anchor-link {
    text-align: center;
    display: block;
    margin: 10px auto 0; }
    @media (min-width: 992px) {
      .ai-chatbots-hero .anchor-link {
        text-align: left;
        display: inline;
        margin-top: 10px; } }
  .ai-chatbots-hero .green-head-ribbon {
    text-align: center !important;
    padding: 0; }
    @media (min-width: 992px) {
      .ai-chatbots-hero .green-head-ribbon {
        text-align: left !important; } }
  .ai-chatbots-hero h1 {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    margin-top: 15px; }
    @media (min-width: 992px) {
      .ai-chatbots-hero h1 {
        padding-right: 80px;
        text-align: left;
        font-size: 62px;
        line-height: 75px;
        margin-top: 0; } }
  .ai-chatbots-hero h2 {
    color: #b7b7b7;
    margin-top: 20px;
    line-height: 26px;
    font-size: 18px;
    text-align: center; }
    @media (min-width: 992px) {
      .ai-chatbots-hero h2 {
        text-align: left;
        font-size: 24px;
        color: #14de7d; } }
  @media (min-width: 992px) {
    .ai-chatbots-hero .btn_wrapper a {
      margin: 0; } }
  @media (min-width: 992px) {
    .ai-chatbots-hero .anchor-link {
      text-align: left; } }
  .ai-chatbots-hero .chatbot-gif {
    position: static; }
    @media (min-width: 992px) {
      .ai-chatbots-hero .chatbot-gif {
        position: absolute;
        top: 200px; } }

.custom-para {
  padding: 45px 0 30px;
  position: relative; }
  @media (min-width: 992px) {
    .custom-para {
      padding: 70px 0 20px; } }
  .custom-para h2 {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 0; }
    @media (min-width: 992px) {
      .custom-para h2 {
        font-size: 36px;
        line-height: 53px;
        margin-bottom: 25px; } }
  .custom-para p {
    font-size: 16px;
    line-height: 28px;
    padding-bottom: 0;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .custom-para p {
        font-size: 19px;
        line-height: 30px;
        padding: 0 80px 0;
        padding-bottom: 30px;
        font-family: "Montserrat", sans-serif;
        margin-bottom: 25px; } }

.ai-chatbots {
  padding: 45px 0 20px;
  position: relative;
  background-color: #f8f8f8; }
  @media (min-width: 992px) {
    .ai-chatbots {
      padding: 70px 0 20px 50px; } }
  .ai-chatbots .margin-top-30 {
    margin-top: 0; }
    @media (min-width: 992px) {
      .ai-chatbots .margin-top-30 {
        margin-top: 30px; } }
  @media (min-width: 992px) {
    .ai-chatbots h2 {
      padding: 0 50px;
      margin-bottom: 50px; } }
  .ai-chatbots .dc-white-pan {
    background: #fff;
    box-shadow: 0px 0px 34px 0px #0000001c;
    border-radius: 5px;
    padding: 15px;
    text-align: left;
    position: relative;
    border: 1px solid #e0e0e0; }
    @media (min-width: 992px) {
      .ai-chatbots .dc-white-pan {
        padding: 30px; } }
    .ai-chatbots .dc-white-pan div {
      position: relative; }
      .ai-chatbots .dc-white-pan div span {
        position: absolute;
        width: 88px;
        height: 88px;
        left: 0;
        top: 0;
        right: 0;
        text-align: center;
        margin: 0 auto; }
        @media (min-width: 992px) {
          .ai-chatbots .dc-white-pan div span {
            top: 12%;
            right: inherit;
            margin: 0; } }
        .ai-chatbots .dc-white-pan div span img {
          margin: 0; }
          @media (min-width: 992px) {
            .ai-chatbots .dc-white-pan div span img {
              margin-right: 20px; } }
  @media (min-width: 992px) {
    .ai-chatbots .col-sm-offset-2 {
      margin: 30px auto; } }
  .ai-chatbots .col-sm-offset-2 h3 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 25px;
    margin-top: 10px;
    font-weight: 700; }
    @media (min-width: 992px) {
      .ai-chatbots .col-sm-offset-2 h3 {
        font-size: 36px;
        margin-top: 0;
        font-weight: 600; } }
  .ai-chatbots .col-sm-offset-2 .section-para {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 25px;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .ai-chatbots .col-sm-offset-2 .section-para {
        font-size: 19px;
        line-height: 29px;
        font-family: "Montserrat", sans-serif; } }
  .ai-chatbots .col-sm-offset-2 .btn_wrapper {
    margin-top: 40px; }

.build {
  padding: 50px 0 20px; }
  @media (min-width: 992px) {
    .build {
      padding: 70px 0 50px; } }
  .build__wrap {
    margin: 30px 0 0; }
    @media (min-width: 992px) {
      .build__wrap {
        margin: 30px 0; } }
    .build__wrap p {
      margin: 25px 0 0;
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      color: #fff;
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .build__wrap p {
          padding: 0 30px;
          font-family: "Montserrat", sans-serif;
          font-weight: 500;
          margin: 45px 0 0; } }
  .build h3 {
    margin: 40px 0;
    color: #fff;
    font-size: 24px;
    line-height: 32px; }
    @media (min-width: 992px) {
      .build h3 {
        padding: 0 80px;
        font-size: 36px;
        line-height: 49px;
        margin: 20px 0 40px; } }
  .build .chat-app-group {
    margin-bottom: 40px; }

.o-how-we-create-your-bot {
  position: relative;
  padding: 40px 0 25px;
  overflow: hidden;
  z-index: 106;
  text-align: center; }
  @media (min-width: 992px) {
    .o-how-we-create-your-bot {
      padding: 65px 0 0;
      background: url(/images/ai-chatbots/slider-bg.png) 100% 100% no-repeat;
      background-size: 55%; } }
  .o-how-we-create-your-bot .anchor-link {
    margin: 10px auto;
    display: block; }
    @media (min-width: 992px) {
      .o-how-we-create-your-bot .anchor-link {
        margin: 10px 0;
        display: inline; } }
  @media (min-width: 992px) {
    .o-how-we-create-your-bot p {
      padding: 0 85px; } }
  .o-how-we-create-your-bot .inner .slider-container {
    padding: 10px 0 0;
    margin: 0 auto;
    position: relative; }
    @media (min-width: 992px) {
      .o-how-we-create-your-bot .inner .slider-container {
        padding: 40px 0 0; } }
    .o-how-we-create-your-bot .inner .slider-container .left {
      position: relative; }
      @media (min-width: 992px) {
        .o-how-we-create-your-bot .inner .slider-container .left {
          max-width: none; } }
      .o-how-we-create-your-bot .inner .slider-container .left .icon-phone {
        max-width: 100%;
        display: block; }
        @media (min-width: 992px) {
          .o-how-we-create-your-bot .inner .slider-container .left .icon-phone {
            max-width: none; } }
      .o-how-we-create-your-bot .inner .slider-container .left .icon-phone {
        margin: 0 auto; }
        @media screen and (min-width: 992px) {
          .o-how-we-create-your-bot .inner .slider-container .left .icon-phone {
            max-width: 280px; } }
        @media (min-width: 992px) {
          .o-how-we-create-your-bot .inner .slider-container .left .icon-phone {
            max-width: 100%; } }
    .o-how-we-create-your-bot .inner .slider-container .numbers {
      position: absolute;
      top: 5px;
      width: 100px;
      height: 100px;
      background: #fff;
      font-weight: 700;
      font-size: 62px;
      line-height: 100px;
      color: #14df7d;
      text-align: center;
      border: 1px solid #e3e3e3;
      border-radius: 50%;
      right: 0; }
      @media (min-width: 992px) {
        .o-how-we-create-your-bot .inner .slider-container .numbers {
          right: 5px; } }
    .o-how-we-create-your-bot .inner .slider-container .icons {
      position: absolute;
      width: 150px;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      transform: translate(0); }
      @media (min-width: 992px) {
        .o-how-we-create-your-bot .inner .slider-container .icons {
          top: 40%;
          width: 280px; } }
      .o-how-we-create-your-bot .inner .slider-container .icons .slick-slide img {
        margin: 0 auto; }
    .o-how-we-create-your-bot .inner .slider-container .right {
      padding-top: 0;
      text-align: left;
      position: relative;
      left: 0; }
      @media (min-width: 992px) {
        .o-how-we-create-your-bot .inner .slider-container .right {
          left: 65px;
          padding-top: 50px; } }
      .o-how-we-create-your-bot .inner .slider-container .right .text-rotator h3 {
        margin-bottom: 15px;
        line-height: 34px;
        text-align: center;
        font-size: 20px;
        line-height: 30px;
        min-height: 150px;
        margin-top: 25px; }
        @media (min-width: 992px) {
          .o-how-we-create-your-bot .inner .slider-container .right .text-rotator h3 {
            width: 460px;
            font-size: 26px;
            line-height: 34px;
            min-height: 180px;
            text-align: left;
            margin-top: 0; } }
        .o-how-we-create-your-bot .inner .slider-container .right .text-rotator h3 strong {
          color: #14df7d; }
  @media (min-width: 992px) {
    .o-how-we-create-your-bot .btn_wrapper a {
      margin: 0; } }
  @media (min-width: 992px) {
    .o-how-we-create-your-bot .anchor-p {
      padding: 0;
      text-align: left; } }
  @media (min-width: 992px) {
    .o-how-we-create-your-bot .anchor-p .anchor-link {
      text-align: left; } }
  .o-how-we-create-your-bot .slick-arrow {
    display: none !important; }
  .o-how-we-create-your-bot .pagers {
    position: relative;
    display: block; }
    @media (min-width: 992px) {
      .o-how-we-create-your-bot .pagers {
        display: inline-block; } }
    .o-how-we-create-your-bot .pagers .slick-dots {
      margin-bottom: 30px;
      text-align: center;
      margin-top: 0; }
      @media (min-width: 992px) {
        .o-how-we-create-your-bot .pagers .slick-dots {
          text-align: left;
          margin-top: 30px; } }
      .o-how-we-create-your-bot .pagers .slick-dots li {
        display: inline-block;
        width: 7px;
        height: 7px;
        margin: 0 7px;
        border-radius: 50%;
        background: #9b9b9b;
        text-indent: -10000px;
        cursor: pointer;
        font-size: 0; }
        .o-how-we-create-your-bot .pagers .slick-dots li.slick-active {
          background: #49c684;
          width: 12px;
          height: 12px;
          position: relative;
          top: -1px; }
          @media (min-width: 992px) {
            .o-how-we-create-your-bot .pagers .slick-dots li.slick-active {
              top: 0; } }

.insight-slider .slick-dots {
  margin-bottom: 30px;
  text-align: center;
  margin-top: 0; }
  @media (min-width: 992px) {
    .insight-slider .slick-dots {
      text-align: left; } }
  .insight-slider .slick-dots li {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin: 0 7px;
    border-radius: 50%;
    background: #9b9b9b;
    text-indent: -10000px;
    cursor: pointer;
    font-size: 0;
    vertical-align: sub; }
    .insight-slider .slick-dots li.slick-active {
      background: #27c070;
      width: 12px;
      height: 12px;
      position: relative;
      top: -2px; }

.customer-want {
  padding: 40px 0 20px;
  background-color: #f5f5f5; }
  @media (min-width: 992px) {
    .customer-want {
      padding: 65px 0 50px; } }
  .customer-want h2 {
    margin-bottom: 25px; }
    @media (min-width: 992px) {
      .customer-want h2 {
        margin-bottom: 50px; } }
  .customer-want__box {
    position: relative;
    background: #fff;
    display: inline-block;
    vertical-align: top;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-bottom: 15px;
    padding: 120px 15px 30px; }
    @media (min-width: 992px) {
      .customer-want__box {
        padding: 150px 30px 30px;
        min-height: 419px;
        margin-bottom: 0; } }
    .customer-want__box p {
      font-size: 16px;
      line-height: 28px;
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .customer-want__box p {
          font-size: 18px;
          line-height: 28px;
          font-family: "Montserrat", sans-serif; } }
    .customer-want__box:before {
      content: "";
      position: absolute;
      background: url(/images/ai-chatbots/want-group-icon.png) no-repeat 0 0;
      top: 50px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 76px;
      height: 65px; }
  .customer-want .customer-want__box1:before {
    background-position: 0 0; }
  .customer-want .customer-want__box2:before {
    background-position: -76px 0; }
  .customer-want .customer-want__box3:before {
    background-position: -152px 0; }
  .customer-want .btn_wrapper {
    margin-top: 25px; }
    @media (min-width: 992px) {
      .customer-want .btn_wrapper {
        margin-top: 50px; } }

.service-details.daas-service .service-wrap .brick.services-pan {
  display: block; }
  @media (min-width: 992px) {
    .service-details.daas-service .service-wrap .brick.services-pan {
      display: inline-block; } }

/*daas*/
.daas-service {
  padding: 45px 0 20px;
  position: relative; }
  @media (min-width: 992px) {
    .daas-service {
      padding: 80px 0 45px; } }
  .daas-service h2 {
    font-size: 24px;
    line-height: 32px; }
    @media (min-width: 992px) {
      .daas-service h2 {
        font-size: 48px;
        line-height: 61px; } }
  .daas-service .service-wrap .brick.services-pan:before {
    display: none; }
  .daas-service .service-wrap .brick.services-pan__daas-service-01:before {
    background: url(/images/daas/daas-service-1.png) no-repeat; }
  .daas-service .service-wrap .brick.services-pan__daas-service-02:before {
    background: url(/images/daas/daas-service-2.png) no-repeat; }
  .daas-service .service-wrap .brick.services-pan__daas-service-03:before {
    background: url(/images/daas/daas-service-3.png) no-repeat; }
  .daas-service .service-wrap .brick.services-pan__daas-service-04:before {
    background: url(/images/daas/daas-service-4.png) no-repeat; }
  .daas-service .service-wrap .brick.services-pan__daas-service-05:before {
    background: url(/images/daas/daas-service-5.png) no-repeat; }
  .daas-service .service-wrap .brick.services-pan__daas-service-06:before {
    background: url(/images/daas/daas-service-6.png) no-repeat; }
  .daas-service .service-wrap .brick.services-pan__daas-service-07:before {
    background: url(/images/daas/daas-service-7.png) no-repeat; }
  .daas-service .service-wrap .brick.services-pan__daas-service-08:before {
    background: url(/images/daas/daas-service-8.png) no-repeat; }

/*Agencies*/
.o-two-col-section {
  padding: 50px 0;
  position: relative; }
  @media (min-width: 992px) {
    .o-two-col-section {
      padding: 100px 0 60px; } }
  .o-two-col-section h3 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px; }
    @media (min-width: 992px) {
      .o-two-col-section h3 {
        font-size: 36px;
        line-height: 49px; } }
  .o-two-col-section p {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .o-two-col-section p {
        font-size: 19px;
        line-height: 30px;
        padding-top: 20px; } }
  .o-two-col-section h4 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 50px; }

.o-two-col-section {
  padding: 45px 0 20px;
  background-size: 50% auto;
  /* media query for tablet potrait */ }
  @media (min-width: 992px) {
    .o-two-col-section {
      padding: 100px 0 60px; } }
  .o-two-col-section h3 {
    text-align: center;
    margin-bottom: 20px; }
    .o-two-col-section h3 br {
      display: none; }
  .o-two-col-section h4 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: 600; }
    @media (min-width: 992px) {
      .o-two-col-section h4 {
        font-size: 24px;
        margin-bottom: 50px; } }
  .o-two-col-section__left-panel {
    padding-bottom: 40px;
    text-align: center;
    overflow: hidden;
    padding: 0 30px;
    /* icons */
    /* ~./icons */ }
    @media (min-width: 992px) {
      .o-two-col-section__left-panel {
        text-align: left;
        padding: 0 15px; } }
    .o-two-col-section__left-panel figure {
      width: 60px;
      height: 70px;
      position: relative;
      float: none;
      display: inline-block;
      /* mobile first for phablets */
      font-size: 37px;
      line-height: 63px;
      margin: 0 auto 5px 0; }
      @media (min-width: 992px) {
        .o-two-col-section__left-panel figure {
          float: left;
          margin: 0 30px 25px 0; } }
      .o-two-col-section__left-panel figure img {
        display: block;
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .o-two-col-section__left-panel .content-section {
      width: 100%;
      text-align: center;
      /* mobile first for phablets */ }
      @media (min-width: 992px) {
        .o-two-col-section__left-panel .content-section {
          float: left;
          width: 85%;
          text-align: left; } }
      .o-two-col-section__left-panel .content-section p {
        font-size: 16px;
        line-height: 28px; }
        @media (min-width: 992px) {
          .o-two-col-section__left-panel .content-section p {
            font-size: 19px;
            line-height: 30px;
            text-align: left; } }
      .o-two-col-section__left-panel .content-section h5 {
        font-size: 20px;
        line-height: 28px; }
        @media (min-width: 992px) {
          .o-two-col-section__left-panel .content-section h5 {
            font-size: 24px;
            line-height: 32px; } }
  .o-two-col-section__right-panel {
    text-align: center;
    /* mobile first for tablet portrait */ }
    @media (min-width: 992px) {
      .o-two-col-section__right-panel {
        text-align: left;
        display: block;
        padding-top: 50px; } }
    .o-two-col-section__right-panel img {
      margin-bottom: 20px;
      width: 120px; }
      @media (min-width: 992px) {
        .o-two-col-section__right-panel img {
          margin-bottom: 50px;
          width: 100%; } }
    .o-two-col-section__right-panel p {
      background: url(/images/agencies/icon-quote.svg) no-repeat 0 0;
      padding: 20px 0 0 10px;
      font-size: 16px;
      line-height: 28px;
      background-size: 50px;
      /* mobile first for phablets */ }
      @media (min-width: 992px) {
        .o-two-col-section__right-panel p {
          font-size: 34px;
          line-height: 63px;
          text-align: left;
          background-size: auto; } }

.agencies-bnr h1 {
  text-align: center; }
  @media (min-width: 992px) {
    .agencies-bnr h1 {
      font-size: 32px;
      line-height: 42px;
      text-transform: capitalize;
      text-align: left; } }

.agencies-bnr h2 {
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  font-weight: 600; }
  @media (min-width: 992px) {
    .agencies-bnr h2 {
      font-size: 20px;
      line-height: 33px;
      font-weight: 600;
      text-align: left; } }

@media (min-width: 992px) {
  .agencies-bnr .btn_wrapper a {
    margin: 0; } }

@media (min-width: 992px) {
  .agencies-bnr .anchor-link {
    text-align: left; } }

/*CTO*/
.o-we-love-intro {
  text-align: center;
  padding: 40px 0 20px;
  /* media query for tablet potrait */ }
  @media (min-width: 992px) {
    .o-we-love-intro {
      padding: 40px 0 50px; } }
  @media (min-width: 992px) {
    .o-we-love-intro .col-md-offset-1 {
      margin: 0 auto; } }
  .o-we-love-intro h1 {
    margin-bottom: 30px;
    text-transform: none;
    padding-top: 50px;
    font-weight: 500;
    font-size: 36px;
    line-height: 50px;
    /*mobile first for tablet landscape & small desktop*/ }
    @media (min-width: 992px) {
      .o-we-love-intro h1 {
        font-size: 80px;
        line-height: 55px;
        letter-spacing: 2.5px;
        text-shadow: 1px 1px 0px #333, -1px 1px 0px #333, 1px -1px 0px #333, -1px -1px 0px #333;
        padding-top: 110px;
        font-weight: 700; } }
  .o-we-love-intro h2 {
    margin-bottom: 30px;
    text-transform: none;
    line-height: 50px;
    /*mobile first for tablet landscape & small desktop*/ }
    @media (min-width: 992px) {
      .o-we-love-intro h2 {
        font-size: 80px;
        line-height: 55px; } }
  .o-we-love-intro h2.love-subheading {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    /*mobile first for tablet landscape & small desktop*/ }
    @media (min-width: 992px) {
      .o-we-love-intro h2.love-subheading {
        font-size: 40px;
        line-height: 50px;
        font-weight: 600;
        margin-bottom: 20px; } }
  .o-we-love-intro h3 {
    margin-bottom: 20px; }
  .o-we-love-intro h4 {
    margin-bottom: 20px; }
  .o-we-love-intro p strong {
    font-weight: 600; }
  .o-we-love-intro__other-text {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 28px; }
    @media (min-width: 992px) {
      .o-we-love-intro__other-text {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 15px;
        font-weight: 400; } }
  .o-we-love-intro__we-love-image {
    padding-bottom: 20px; }
    @media (min-width: 992px) {
      .o-we-love-intro__we-love-image {
        padding-bottom: 25px; } }
  .o-we-love-intro .c-btn {
    margin-top: 20px; }
  .o-we-love-intro .o-contact-form__heart img {
    width: 40px; }
    @media (min-width: 992px) {
      .o-we-love-intro .o-contact-form__heart img {
        width: 65px; } }

.cto-info-p-heading {
  font-size: 23px;
  line-height: 35px; }

.cto-last-solid-info {
  padding: 0 60px;
  margin-bottom: 40px; }

.margin-top-bottom-10 {
  margin: 10px 0; }

.cmo-service .o-home-service_holder h3 {
  color: #fff !important; }

.what-get {
  padding-top: 50px;
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .what-get {
      padding-top: 70px;
      padding-bottom: 50px; } }
  @media (min-width: 992px) {
    .what-get .btn_wrapper {
      margin-top: 20px; } }
  .what-get h2 {
    font-size: 30px; }
    @media (min-width: 992px) {
      .what-get h2 {
        font-size: 48px; } }
  .what-get .cto-wrap {
    text-align: center;
    margin-bottom: 0;
    width: 100%; }
    @media (min-width: 992px) {
      .what-get .cto-wrap {
        margin-bottom: 35px; } }
    .what-get .cto-wrap h3 {
      font-size: 20px; }
      @media (min-width: 992px) {
        .what-get .cto-wrap h3 {
          font-size: 24px; } }
    .what-get .cto-wrap .cto-services {
      position: relative;
      height: auto;
      display: inline-block;
      vertical-align: top;
      margin: 15px 0;
      padding: 20px 0 0; }
      @media (min-width: 992px) {
        .what-get .cto-wrap .cto-services {
          width: 29%;
          padding: 30px 15px 0;
          margin: 15px; } }
      @media screen and (min-width: 1200px) {
        .what-get .cto-wrap .cto-services {
          width: 30%; } }
      .what-get .cto-wrap .cto-services p {
        font-size: 16px;
        line-height: 28px;
        color: #333;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        margin-bottom: 0; }
        @media (min-width: 992px) {
          .what-get .cto-wrap .cto-services p {
            font-size: 19px;
            line-height: 32px;
            font-family: "Montserrat", sans-serif;
            margin-bottom: 25px; } }
      .what-get .cto-wrap .cto-services:before {
        display: none; }
    .what-get .cto-wrap .cto-service-01:before {
      background-position: 0 0; }
    .what-get .cto-wrap .cto-service-02:before {
      background-position: -87px 0; }
    .what-get .cto-wrap .cto-service-03:before {
      background-position: -210px 0; }

.unlike-section {
  padding-top: 45px;
  padding-bottom: 20px;
  background: linear-gradient(285deg, #07b260 0%, #00517f 30%, #023e66 75%, #051b39 100%); }
  @media (min-width: 992px) {
    .unlike-section {
      padding-top: 70px;
      padding-bottom: 50px; } }
  .unlike-section h2 {
    color: #fff; }
    @media (min-width: 992px) {
      .unlike-section h2 {
        font-size: 48px;
        line-height: 61px; } }
  .unlike-section .cto-competitor {
    text-align: center; }
    .unlike-section .cto-competitor-service {
      position: relative;
      padding: 40px 15px 30px;
      display: inline-block;
      vertical-align: top;
      margin: 15px 0;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      background-color: #fff; }
      @media (min-width: 992px) {
        .unlike-section .cto-competitor-service {
          width: 30%;
          height: 500px;
          padding: 50px 25px 30px;
          margin: 15px; } }
      .unlike-section .cto-competitor-service p {
        font-size: 16px;
        line-height: 28px;
        font-family: "Montserrat", sans-serif;
        margin-bottom: 0; }
        @media (min-width: 992px) {
          .unlike-section .cto-competitor-service p {
            font-size: 15px;
            line-height: 24px;
            font-family: "Montserrat", sans-serif;
            margin-bottom: 25px; } }
        @media screen and (min-width: 1200px) {
          .unlike-section .cto-competitor-service p {
            font-size: 17px;
            line-height: 27px; } }
      .unlike-section .cto-competitor-service:before {
        display: none; }
    .unlike-section .cto-competitor-service-01:before {
      background-position: -315px 0px; }
    .unlike-section .cto-competitor-service-02:before {
      background-position: -420px 0px; }
    .unlike-section .cto-competitor-service-03:before {
      background-position: 14px -89px; }
    .unlike-section .cto-competitor h3 {
      font-size: 20px; }
      @media (min-width: 992px) {
        .unlike-section .cto-competitor h3 {
          font-size: 24px; } }
  .unlike-section .btn_wrapper {
    margin-top: 15px; }
    @media (min-width: 992px) {
      .unlike-section .btn_wrapper {
        margin-top: 30px; } }

/*CMO*/
.number-section-cmo {
  padding: 45px 0 20px; }
  @media (min-width: 992px) {
    .number-section-cmo {
      padding-top: 40px;
      padding-bottom: 50px; } }
  .number-section-cmo .col-md-offset-1 {
    margin: 0 auto; }
  .number-section-cmo .cto-last-solid-info {
    text-align: center;
    color: #fff;
    font-size: 24px;
    margin-bottom: 25px;
    margin-top: 30px;
    line-height: 32px;
    padding: 0; }
    @media (min-width: 992px) {
      .number-section-cmo .cto-last-solid-info {
        padding: 0 60px;
        font-size: 28px;
        line-height: 40px; } }
  .number-section-cmo .cmo-blocks {
    height: auto; }
    @media (min-width: 992px) {
      .number-section-cmo .cmo-blocks {
        height: 135px; } }
  .number-section-cmo .dc-white-pan div .body-text-medium {
    font-size: 16px;
    line-height: 28px;
    padding-top: 0;
    float: none;
    width: 100%;
    text-align: center; }
    @media (min-width: 992px) {
      .number-section-cmo .dc-white-pan div .body-text-medium {
        font-size: 19px;
        line-height: 30px;
        float: right;
        width: 78%;
        text-align: left; } }
  .number-section-cmo .dc-white-pan div span {
    float: none;
    display: block;
    text-align: center; }
    @media (min-width: 992px) {
      .number-section-cmo .dc-white-pan div span {
        float: left;
        text-align: left; } }

.cmo-services {
  padding-top: 45px;
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .cmo-services {
      padding-top: 70px;
      padding-bottom: 50px; } }
  .cmo-services h2 {
    font-size: 24px;
    line-height: 32px; }
    @media (min-width: 992px) {
      .cmo-services h2 {
        margin-bottom: 30px;
        font-size: 48px;
        line-height: 61px; } }
  @media (min-width: 992px) {
    .cmo-services-wrap {
      column-count: 3;
      column-gap: 27px;
      margin-bottom: 30px; } }
  .cmo-services-wrap .cmo-services-block {
    display: inline-block;
    vertical-align: top;
    margin: 15px 0;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
    padding: 30px 30px 10px;
    position: relative; }
    @media (min-width: 992px) {
      .cmo-services-wrap .cmo-services-block {
        padding: 50px 30px 15px; } }
    .cmo-services-wrap .cmo-services-block p {
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .cmo-services-wrap .cmo-services-block p {
          font-size: 19px;
          line-height: 30px;
          font-family: "Montserrat", sans-serif; } }
    .cmo-services-wrap .cmo-services-block h3 {
      font-size: 20px; }
      @media (min-width: 992px) {
        .cmo-services-wrap .cmo-services-block h3 {
          font-size: 26px; } }
    .cmo-services-wrap .cmo-services-block:before {
      display: none; }
    .cmo-services-wrap .cmo-services-block i {
      height: 65px;
      display: block; }
      .cmo-services-wrap .cmo-services-block i img {
        height: 100%; }
  .cmo-services-wrap .cmo-services-block-06:before {
    background-position: -461px 0;
    top: 15px; }
    @media (min-width: 992px) {
      .cmo-services-wrap .cmo-services-block-06:before {
        top: 35px; } }
  .cmo-services .btn_wrapper {
    margin-top: 15px; }
    @media (min-width: 992px) {
      .cmo-services .btn_wrapper {
        margin-top: 0; } }

.no-webp .tl-pt:before {
  background-image: url(/images/common/tl-pt.png); }

.webp .tl-pt:before {
  background-image: url(/images/webp/common/tl-pt.webp); }

.tl-pt {
  position: relative; }
  .tl-pt:before {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: top left;
    width: 200px;
    height: 80px;
    background-size: 75%;
    top: 0;
    left: 0; }
    @media (min-width: 992px) {
      .tl-pt:before {
        width: 517px;
        height: 206px;
        background-size: 100%; } }

.no-webp .br-pt:after {
  background-image: url(/images/common/mb-br-pt.png); }
  @media (min-width: 992px) {
    .no-webp .br-pt:after {
      background-image: url(/images/common/br-pt.png); } }

.webp .br-pt:after {
  background-image: url(/images/common/mb-br-pt.webp); }
  @media (min-width: 992px) {
    .webp .br-pt:after {
      background-image: url(/images/webp/common/br-pt.webp); } }

.br-pt {
  position: relative; }
  .br-pt:after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: bottom right;
    width: 160px;
    height: 80px;
    bottom: 0;
    right: 0;
    background-size: 100%; }
    @media (min-width: 992px) {
      .br-pt:after {
        width: 200px; } }
    @media (min-width: 992px) {
      .br-pt:after {
        width: 500px;
        height: 240px;
        background-size: 100%; } }

.br-pt.hm-bnr:after {
  background-position: bottom right; }
  @media (min-width: 992px) {
    .br-pt.hm-bnr:after {
      background-position: 160px 0; } }

.no-webp .tr-pt:before {
  background-image: url(/images/common/mb-tr-pt.png); }
  @media (min-width: 992px) {
    .no-webp .tr-pt:before {
      background-image: url(/images/common/tr-pt.png); } }

.webp .tr-pt:before {
  background-image: url(/images/common/mb-tr-pt.webp); }
  @media (min-width: 992px) {
    .webp .tr-pt:before {
      background-image: url(/images/webp/common/tr-pt.webp); } }

.tr-pt {
  position: relative; }
  .tr-pt:before {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 100%;
    height: 80px;
    width: 200px;
    top: 0;
    right: 0; }
    @media (min-width: 992px) {
      .tr-pt:before {
        display: block;
        background-size: 100%;
        width: 530px;
        height: 215px; } }

.no-webp .bl-pt:after {
  background-image: url(/images/common/tr-pt.png); }

.webp .bl-pt:after {
  background-image: url(/images/webp/common/tr-pt.webp); }

.bl-pt {
  position: relative; }
  .bl-pt:after {
    content: "";
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom left;
    height: 75px;
    width: 200px;
    bottom: 0;
    left: 0;
    transform: rotate(-180deg); }
    @media (min-width: 992px) {
      .bl-pt:after {
        display: block;
        background-size: 100%;
        width: 530px;
        height: 215px; } }

.no-webp .mb-tl-pt:after {
  background-image: url(/images/common/mb-tl-pt.png); }

.webp .mb-tl-pt:after {
  background-image: url(/images/common/mb-tl-pt.webp); }

.mb-tl-pt:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 100px;
  background-size: 100%;
  background-position: top left;
  background-repeat: no-repeat;
  transform: translate(0); }
  @media (min-width: 992px) {
    .mb-tl-pt:after {
      display: none; } }

.no-webp .mb-w-tr-pt:before {
  background-image: url(/images/common/mb-w-tr-pt.png); }

.webp .mb-w-tr-pt:before {
  background-image: url(/images/common/mb-w-tr-pt.webp); }

.mb-w-tr-pt:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  height: 100px;
  background-size: 100%;
  background-position: top right;
  background-repeat: no-repeat;
  z-index: 99; }
  @media (min-width: 992px) {
    .mb-w-tr-pt:before {
      display: none; } }

.cheaper-batter-header .btn_wrapper.btn-center-block.mobile-off {
  width: auto; }
  @media (min-width: 992px) {
    .cheaper-batter-header .btn_wrapper.btn-center-block.mobile-off {
      width: 330px; } }

@media (min-width: 992px) {
  .acnt-p {
    font-size: 16px; } }

@media (min-width: 992px) {
  .form-panel .u-submit {
    margin-top: 35px; } }

.img-cr-block {
  margin: 0 auto;
  display: block; }

.ai-chatbots-hero .container {
  padding-top: 100px; }

.new-srv-slider .col-sm-6,
.new-srv-slider .col-sm-6 {
  flex-wrap: wrap;
  flex: 50% !important;
  max-width: 50%; }

.o-apply-now .anchor-link {
  max-width: 340px; }

.cs-slider-img img {
  width: 100%; }

.ai-bot .insights-section .fi-pan a {
  min-height: 370px; }

.navbar-brand source {
  width: 80%; }
  @media (min-width: 992px) {
    .navbar-brand source {
      width: auto; } }

.navbar-brand img {
  width: 100%; }

.border-bottom-lightgreen {
  border-bottom: 1px solid #dedede; }

.o-home-cn-details.pad-bot-40 {
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .o-home-cn-details.pad-bot-40 {
      padding-bottom: 50px; } }

.cstm-link-p {
  margin-top: 0; }
  @media (min-width: 992px) {
    .cstm-link-p {
      margin-top: 5px; } }

.splide__pagination {
  position: static !important;
  transform: translate(0);
  margin-top: 10px !important; }
  @media (min-width: 992px) {
    .splide__pagination {
      margin-top: 20px !important; } }
  .splide__pagination .splide__pagination__page {
    margin: 3px 5px; }
  .splide__pagination .splide__pagination__page.is-active {
    transform: scale(1.4);
    background: #27c070; }

.o-case-studies-slider .splide__pagination {
  display: flex !important;
  margin-top: 30px; }

.o-case-studies-slider .btn_wrapper.pad-top-50 {
  padding-top: 20px; }
  @media (min-width: 992px) {
    .o-case-studies-slider .btn_wrapper.pad-top-50 {
      padding-top: 20px; } }

.crop-pad-top {
  margin-top: 10px; }
  @media (min-width: 992px) {
    .crop-pad-top {
      margin-top: 0; } }

.splide__pagination {
  width: 100%; }

.insights-section {
  padding: 45px 0 50px !important; }
  @media (min-width: 992px) {
    .insights-section {
      padding: 70px 0 80px !important; } }
  .insights-section .btn_wrapper {
    padding-top: 0; }
    @media (min-width: 992px) {
      .insights-section .btn_wrapper {
        padding-top: 40px; } }

.Web-Application-Development {
  font-family: "Montserrat", sans-serif !important; }
  .Web-Application-Development span,
  .Web-Application-Development p,
  .Web-Application-Development h1,
  .Web-Application-Development h2,
  .Web-Application-Development h3,
  .Web-Application-Development h4,
  .Web-Application-Development h5,
  .Web-Application-Development h6,
  .Web-Application-Development a {
    font-family: "Montserrat", sans-serif !important; }
  .Web-Application-Development .service-details h2 {
    font-size: 24px; }
    @media (min-width: 992px) {
      .Web-Application-Development .service-details h2 {
        font-size: 48px; } }
  .Web-Application-Development .c-icon-desc-3-col-box h2 {
    font-size: 24px;
    line-height: 32px; }
    @media (min-width: 992px) {
      .Web-Application-Development .c-icon-desc-3-col-box h2 {
        font-size: 48px;
        line-height: 61px; } }
  .Web-Application-Development .web-app-service .service-wrap {
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .Web-Application-Development .web-app-service .service-wrap {
        margin-bottom: 35px; } }

.Custom-Software-Development .c-icon-desc-3-col-box h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600; }
  @media (min-width: 992px) {
    .Custom-Software-Development .c-icon-desc-3-col-box h2 {
      font-size: 48px;
      line-height: 61px; } }

.Custom-Software-Development .service-details .btn_wrapper {
  margin-top: 15px; }
  @media (min-width: 992px) {
    .Custom-Software-Development .service-details .btn_wrapper {
      margin-top: 0; } }

.c-icon-desc-3-col-ccn .row .col-md-4:nth-child(6) {
  border: none; }

.mobile-container {
  padding: 0 30px !important; }
  @media (min-width: 992px) {
    .mobile-container {
      padding: 0 15px !important; } }

.splide__pagination__page {
  background: #666565; }

.pager_new {
  display: block; }
  @media (min-width: 992px) {
    .pager_new {
      display: none; } }

.lSSlideOuter {
  padding-bottom: 25px;
  position: relative; }

.fa-circle:before {
  font-family: "Font Awesome\ 5 Free"; }

.lSSlideOuter.custom-color .lSPager.lSGallery li i {
  color: #9b9b9b !important; }

.lSSlideOuter.custom-color .lSPager.lSGallery li.active i,
li.focus .dot_sliders {
  color: #27c070 !important; }

.lSSlideOuter.custom-color .lSPager.lSGallery {
  margin-top: 75px !important; }

.o-case-studies-slider .slick-dots {
  margin-bottom: 15px; }

.career-bnr {
  padding-top: 120px; }
  @media (min-width: 992px) {
    .career-bnr {
      padding-top: 80px; } }
  .career-bnr img {
    margin: 0 auto; }
    @media (min-width: 992px) {
      .career-bnr img {
        margin: 0 auto; } }
  .career-bnr.career-bnr-new {
    padding-top: 60px; }
    @media (min-width: 992px) {
      .career-bnr.career-bnr-new {
        padding-top: 80px; } }

.service-wrap .services-pan p {
  font-family: "Montserrat", sans-serif !important; }
  @media (min-width: 992px) {
    .service-wrap .services-pan p {
      font-family: "Montserrat", sans-serif !important; } }

.c-icon-desc-3-col-ccn .row > div p {
  font-family: "Montserrat", sans-serif !important; }
  @media (min-width: 992px) {
    .c-icon-desc-3-col-ccn .row > div p {
      font-family: "Montserrat", sans-serif !important; } }

.o-case-studies-slider .cs-slider .cs-slider-text h4,
.o-case-studies-slider .cs-slider .cs-slider-text .cs-tech,
.o-case-studies-slider .cs-slider .cs-slider-text p,
.o-case-studies-new .faq-holder .panel1 p,
.insights-section .fi-pan p,
.new-review-box h4,
.o-bottom-form .form-text p.sub-heading,
.o-bottom-form .form-text p,
.o-bottom-form .form-text ul li span {
  font-family: "Montserrat", sans-serif !important; }
  @media (min-width: 992px) {
    .o-case-studies-slider .cs-slider .cs-slider-text h4,
    .o-case-studies-slider .cs-slider .cs-slider-text .cs-tech,
    .o-case-studies-slider .cs-slider .cs-slider-text p,
    .o-case-studies-new .faq-holder .panel1 p,
    .insights-section .fi-pan p,
    .new-review-box h4,
    .o-bottom-form .form-text p.sub-heading,
    .o-bottom-form .form-text p,
    .o-bottom-form .form-text ul li span {
      font-family: "Montserrat", sans-serif !important; } }

.PHP .skill-frame {
  background-color: transparent !important; }
  @media (min-width: 992px) {
    .PHP .skill-frame {
      background-color: #f5f5f5 !important; } }

.faq-section {
  padding-top: 45px !important; }
  @media (min-width: 992px) {
    .faq-section {
      padding: 65px 0 50px !important; } }
  .faq-section .container {
    position: relative;
    z-index: 9; }

.hm-page h1,
.hm-page h2,
.hm-page h3,
.hm-page h4,
.hm-page h5,
.hm-page h6,
.hm-page a,
.hm-page p,
.hm-page li,
.hm-page span,
.hm-page small,
.hm-page strong {
  font-family: "Montserrat", sans-serif !important; }

.hm-page .o-case-studies-slider .cs-slider .cs-slider-text h4,
.hm-page .o-case-studies-slider .cs-slider .cs-slider-text .cs-tech,
.hm-page .o-case-studies-slider .cs-slider .cs-slider-text p,
.hm-page .o-case-studies-new .faq-holder .panel1 p,
.hm-page .insights-section .fi-pan p,
.hm-page .new-review-box h4,
.hm-page .o-bottom-form .form-text p.sub-heading,
.hm-page .o-bottom-form .form-text p,
.hm-page .o-bottom-form .form-text ul li span {
  font-family: "Montserrat", sans-serif !important; }

.Custom-Software-Development .btn_wrapper {
  padding-top: 0; }
  @media (min-width: 992px) {
    .Custom-Software-Development .btn_wrapper {
      padding-top: 0; } }

.Custom-Software-Development .wb-bnr {
  padding-bottom: 20px !important; }
  @media (min-width: 992px) {
    .Custom-Software-Development .wb-bnr {
      padding-bottom: 50px !important; } }

.Custom-Software-Development .service-details h2 {
  font-size: 24px; }
  @media (min-width: 992px) {
    .Custom-Software-Development .service-details h2 {
      font-size: 48px; } }

@media (min-width: 992px) {
  .Custom-Software-Development .o-industrial-experience .subheading {
    font-size: 20px;
    line-height: 32px; } }

.Work .cstm-link-p {
  text-align: center;
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .Work .cstm-link-p {
      text-align: inherit;
      margin-bottom: 0; } }

.Work .u-pad-top-30 {
  padding-top: 5px; }
  @media (min-width: 992px) {
    .Work .u-pad-top-30 {
      padding-top: 30px; } }

.Work .work-bnr h1 {
  padding-top: 25px; }
  @media (min-width: 992px) {
    .Work .work-bnr h1 {
      padding-top: 60px; } }

@media (min-width: 992px) {
  .Work .o-two-col-section {
    padding: 70px 0 50px; } }

@media (min-width: 992px) {
  .Work .o-two-col-section p {
    margin-top: 0; } }

@media (min-width: 992px) {
  .Work .key-section {
    padding: 70px 0 45px; } }

@media (min-width: 992px) {
  .Work .key-section h2 {
    margin-bottom: 10px; } }

.Work .key-section .kt-button-holder p {
  padding: 0;
  margin-bottom: 23px; }
  @media (min-width: 992px) {
    .Work .key-section .kt-button-holder p {
      padding: 0 0 0;
      margin-bottom: 30px; } }

.Work .key-traits {
  padding: 45px 0 5px; }
  @media (min-width: 992px) {
    .Work .key-traits {
      padding: 70px 0 50px; } }
  .Work .key-traits .style_prevu_kit p {
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .Work .key-traits .style_prevu_kit p {
        margin-bottom: 25px; } }

@media (min-width: 992px) {
  .Work .o-two-col-section__why-choose-us-mod {
    padding: 70px 0 70px; } }

@media (min-width: 992px) {
  .Work .o-two-col-section__why-choose-us-mod p {
    font-family: "Montserrat", sans-serif;
    padding-top: 0; } }

@media (min-width: 992px) {
  .Work .o-two-col-section__choosing-steps {
    padding: 70px 0 25px; } }

.eCommerce .o-skills-hero {
  padding-top: 100px;
  padding-bottom: 20px !important; }
  @media (min-width: 992px) {
    .eCommerce .o-skills-hero {
      padding-bottom: 0 !important;
      padding-top: 55px; } }
  .eCommerce .o-skills-hero h1 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .eCommerce .o-skills-hero h1 {
        font-size: 48px;
        line-height: 64px;
        margin-bottom: 30px; } }
  .eCommerce .o-skills-hero .mb-h1 {
    margin-bottom: 25px; }

.eCommerce .green-head-ribbon span:first-letter {
  text-transform: none; }

.eCommerce .schedule-box {
  height: 100%; }

.CMS .o-skills-hero {
  padding-top: 60px;
  padding-bottom: 20px !important; }
  @media (min-width: 992px) {
    .CMS .o-skills-hero {
      padding-top: 90px;
      padding-bottom: 50px !important; } }
  .CMS .o-skills-hero h1 {
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .CMS .o-skills-hero h1 {
        margin-bottom: 15px; } }

.CMS .service-wrap .services-pan p {
  margin-bottom: 0; }
  @media (min-width: 992px) {
    .CMS .service-wrap .services-pan p {
      margin-bottom: 25px; } }

.cmo-service {
  padding-top: 20px; }
  @media (min-width: 992px) {
    .cmo-service {
      padding-top: 25px; } }
  @media (min-width: 992px) {
    .cmo-service .home-link {
      padding: 20px 0 35px; } }
  .cmo-service h3 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .cmo-service h3 {
        font-size: 48px; } }
  .cmo-service .o-home-service_holder {
    margin-top: 0; }
  .cmo-service .home-link {
    padding-top: 10px; }
    @media (min-width: 992px) {
      .cmo-service .home-link {
        padding-top: 25px; } }
  .cmo-service .btn_wrapper {
    padding-top: 20px;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .cmo-service .btn_wrapper {
        padding-top: 0;
        margin-bottom: 0; } }
  @media (min-width: 992px) {
    .cmo-service .anchor-link {
      margin-top: 10px; } }

@media (min-width: 992px) {
  .Data .o-skills-hero {
    padding: 115px 20px 50px !important; } }

.Data .btn_wrapper {
  padding-top: 0; }
  @media (min-width: 992px) {
    .Data .btn_wrapper {
      padding-top: 15px; } }

.Data .c-icon-desc-3-col-box h2 {
  font-size: 24px; }
  @media (min-width: 992px) {
    .Data .c-icon-desc-3-col-box h2 {
      font-size: 48px; } }

.Magento .skill-service h2 {
  color: #fff !important;
  margin-bottom: 15px; }
  @media (min-width: 992px) {
    .Magento .skill-service h2 {
      color: #fff !important; } }

.DevOps .c-icon-desc-3-col-box h2 {
  font-size: 24px;
  line-height: 32px; }
  @media (min-width: 992px) {
    .DevOps .c-icon-desc-3-col-box h2 {
      font-size: 48px;
      line-height: 61px; } }

@media (min-width: 992px) {
  .DevOps .small-h1 h1 {
    margin-bottom: 15px; } }

.DevOps .small-h1 .bnr-ofr-text {
  line-height: 24px; }

.agencies-bnr {
  padding-bottom: 45px; }
  @media (min-width: 992px) {
    .agencies-bnr {
      padding: 115px 20px 50px !important; } }
  .agencies-bnr h1 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 0; }
    @media (min-width: 992px) {
      .agencies-bnr h1 {
        font-size: 32px;
        line-height: 42px;
        padding-bottom: 30px; } }

.agen-why {
  padding: 45px 0 20px; }
  @media (min-width: 992px) {
    .agen-why {
      padding: 70px 0 50px; } }
  .agen-why .service-wrap {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 992px) {
      .agen-why .service-wrap {
        margin-bottom: 35px; } }
    .agen-why .service-wrap .brick.services-pan {
      padding: 30px 15px 30px;
      width: 100%;
      height: auto !important; }
      @media (min-width: 992px) {
        .agen-why .service-wrap .brick.services-pan {
          padding: 50px 30px 30px;
          width: 46% !important; } }
      @media screen and (min-width: 1200px) {
        .agen-why .service-wrap .brick.services-pan {
          width: 47% !important; } }
      .agen-why .service-wrap .brick.services-pan p {
        margin-bottom: 0; }
        @media (min-width: 992px) {
          .agen-why .service-wrap .brick.services-pan p {
            margin-bottom: 25px;
            font-size: 19px;
            line-height: 30px; } }
      .agen-why .service-wrap .brick.services-pan i {
        height: 65px !important; }

.Testing .c-icon-desc-3-col-box h2,
.AI .c-icon-desc-3-col-box h2,
.Outsourced .c-icon-desc-3-col-box h2,
.CMS .c-icon-desc-3-col-box h2,
.eCommerce .c-icon-desc-3-col-box h2 {
  font-size: 24px;
  line-height: 32px; }
  @media (min-width: 992px) {
    .Testing .c-icon-desc-3-col-box h2,
    .AI .c-icon-desc-3-col-box h2,
    .Outsourced .c-icon-desc-3-col-box h2,
    .CMS .c-icon-desc-3-col-box h2,
    .eCommerce .c-icon-desc-3-col-box h2 {
      font-size: 48px;
      line-height: 61px; } }

ul.srv-bnr-logo svg {
  height: 100%;
  width: 100%; }

.cmn-service-bnr-mod {
  padding-top: 95px;
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .cmn-service-bnr-mod {
      padding: 115px 20px 0 !important; } }
  @media (min-width: 992px) {
    .cmn-service-bnr-mod h1 {
      font-size: 48px;
      line-height: 65px;
      margin-top: 0; } }
  @media (min-width: 992px) {
    .cmn-service-bnr-mod h2 {
      font-size: 24px;
      font-weight: 600 !important; } }
  .cmn-service-bnr-mod .mb-h1 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 25px;
    margin-top: 15px; }
  .cmn-service-bnr-mod .mb-h2 {
    font-size: 22px;
    line-height: 30px;
    padding: 0;
    margin-top: 25px !important; }
  .cmn-service-bnr-mod .mb-p {
    color: #b7b7b7;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0;
    font-weight: 700; }
    .cmn-service-bnr-mod .mb-p a {
      text-decoration: underline;
      color: #b7b7b7; }
  .cmn-service-bnr-mod .dd-mb-p {
    margin-bottom: 30px; }

.CMS .cmn-service-bnr-mod h1 {
  margin-top: 25px; }

.DevOps .o-skills-hero h1,
.Testing .o-skills-hero h1 {
  margin-top: 25px; }

.cms-can .service-wrap .services-pan i {
  width: auto;
  height: 75px !important;
  margin: 0 auto; }

@media (min-width: 992px) {
  .Outsourced .o-skills-hero {
    padding: 115px 20px 50px !important; } }

@media (min-width: 992px) {
  .outsource-product .service-wrap .brick.services-pan,
  .testing-qa .service-wrap .brick.services-pan {
    padding: 50px 30px 50px; } }

@media (min-width: 992px) {
  .cms-box {
    min-height: 435px; } }

.e-commerce-why .service-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .e-commerce-why .service-wrap .brick.services-pan {
    padding: 30px 25px 5px; }
    @media (min-width: 992px) {
      .e-commerce-why .service-wrap .brick.services-pan {
        padding: 50px 25px 30px; } }

.e-commerce-why .cstm-link-p a {
  color: #4a90e2 !important; }

@media (min-width: 992px) {
  .gradient-blue-green-2 {
    background: #07b260;
    background: -moz-linear-gradient(141deg, #07b260 0%, #00517f 37%, #023e66 100%);
    background: -webkit-linear-gradient(141deg, #07b260 0%, #00517f 37%, #023e66 100%);
    background: linear-gradient(141deg, #07b260 0%, #00517f 37%, #023e66 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#07b260",endColorstr="#023e66",GradientType=1); } }

#solution-container {
  padding: 40px 0 35px; }
  @media (min-width: 992px) {
    #solution-container {
      padding: 70px 0 50px; } }

.CMO .number-section .section-para {
  font-family: "Montserrat", sans-serif; }

@media (min-width: 992px) {
  .o-two-col-section-agen {
    padding: 70px 0 50px; } }

.o-two-col-section-agen .cstm-link-p {
  padding-top: 0; }

@media (min-width: 992px) {
  .o-two-col-section-agen .content-section p {
    padding-top: 5px; } }

.daas-cmn .row > div {
  padding: 0; }
  @media (min-width: 992px) {
    .daas-cmn .row > div {
      padding: 0 30px 0 15px;
      margin: 0 0 30px; } }
  .daas-cmn .row > div .daas-cmn-box {
    display: flex; }
    .daas-cmn .row > div .daas-cmn-box:before {
      display: none; }
    .daas-cmn .row > div .daas-cmn-box-text:before {
      display: none; }
    .daas-cmn .row > div .daas-cmn-box i {
      display: block;
      flex: 0 0 70px;
      margin-right: 20px; }
    .daas-cmn .row > div .daas-cmn-box h3 {
      font-size: 20px; }
      @media (min-width: 992px) {
        .daas-cmn .row > div .daas-cmn-box h3 {
          font-size: 26px; } }

.daas-how {
  padding-top: 45px; }
  @media (min-width: 992px) {
    .daas-how {
      padding: 65px 0 50px; } }
  .daas-how h2 {
    font-size: 30px;
    font-weight: 700;
    color: #fff; }
    @media (min-width: 992px) {
      .daas-how h2 {
        font-size: 48px; } }
  .daas-how .cstm-link-p a {
    color: #fff !important; }

.cto-wrap i {
  height: 70px;
  display: flex;
  justify-content: center; }

.first-letter-small:first-letter {
  text-transform: lowercase; }

.apart-p {
  font-size: 16px;
  line-height: 24px; }
  @media (min-width: 992px) {
    .apart-p {
      font-size: 19px;
      line-height: 32px; } }

.o-skills-hero__schdule .e-com-head {
  text-transform: none !important; }

.Mobile .btn-orange {
  text-transform: uppercase; }

.Mobile h2 span {
  display: inline; }

.cust-app {
  margin-top: 15px; }
  @media (min-width: 992px) {
    .cust-app {
      margin-top: 0; } }

/*New banner css*/
.header_right_pan {
  position: relative; }

.floating-line {
  background: #2c8b78;
  position: absolute;
  left: 15px;
  right: 15px;
  top: -50px;
  padding: 13px;
  color: #fff;
  font-weight: 700;
  font-size: 15px; }

ul.ul-grp-logo {
  margin-top: 30px !important; }

.o-skills-hero__schdule .schedule-box-top {
  padding: 10px 15px 0;
  background: url(/images/common/mid.png) repeat-y;
  background-position: 0 0;
  background-size: 100%; }
  .o-skills-hero__schdule .schedule-box-top-text img {
    width: 69px;
    height: 69px; }
  .o-skills-hero__schdule .schedule-box-top-text h2 {
    text-align: left;
    font-size: 24px !important;
    line-height: 35px;
    padding-top: 0;
    font-weight: 600 !important; }
    @media screen and (min-width: 992px) {
      .o-skills-hero__schdule .schedule-box-top-text h2 {
        font-size: 20px !important;
        line-height: 28px; } }
    @media screen and (min-width: 1200px) {
      .o-skills-hero__schdule .schedule-box-top-text h2 {
        font-size: 24px !important;
        line-height: 35px; } }
  .o-skills-hero__schdule .schedule-box-top .btn-orange {
    padding: 14px 15px;
    max-width: 340px !important;
    font-weight: 700; }

@media (min-width: 992px) {
  .green-head-ribbon-mod {
    margin-top: 20px; } }

.green-head-ribbon-mod span {
  width: 90%;
  font-family: "Montserrat", sans-serif !important; }
  @media (min-width: 992px) {
    .green-head-ribbon-mod span {
      width: 281px;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      padding: 15px;
      font-weight: 700; } }

.schedule-box-top-img {
  background: url(/images/common/top.png) no-repeat;
  background-position: 0 0;
  background-size: 100%;
  height: 29px; }

.schedule-box-bottom-img {
  background: url(/images/common/bottom.png) no-repeat;
  background-position: 0 0;
  background-size: 100%;
  height: 45px; }

.schedule-box-mod {
  height: 700px; }

ul.small-skill-page-mod {
  width: 80% !important;
  margin: 0 auto; }
  @media screen and (min-width: 992px) {
    ul.small-skill-page-mod {
      margin: 0; } }

@media (min-width: 992px) {
  .o-skills-hero-modified {
    padding: 115px 20px 0 !important; } }

.with-cn-h2 {
  margin-bottom: 25px; }

.staff-aug h1 {
  margin-bottom: 25px; }

.Analytics .web-app-service .service-wrap .brick.services-pan {
  height: auto; }

.Analytics .web-app-service .service-wrap .brick.services-pan a {
  position: static;
  transform: translate(0); }

.Graphic .web-app-service .service-wrap .brick.services-pan {
  height: auto; }

.Graphic .web-app-service .service-wrap .brick.services-pan a {
  position: static;
  transform: translate(0); }

.is-section h3 {
  margin-bottom: 30px; }

.services-pan__codeigniter {
  padding-top: 50px; }

.services-pan__codeigniter:before {
  display: none; }

.details-point {
  margin-bottom: 25px; }
  .details-point span {
    font-size: 16px;
    padding-left: 30px;
    background: url(/images/common/icon-bullet-dark.svg) 0 50% no-repeat;
    margin: 0 15px; }

.about-details-para-mod {
  width: 100% !important; }
  .about-details-para-mod h3 {
    font-size: 32px;
    margin-bottom: 30px; }

.ppc-service .service-wrap .brick.services-pan {
  height: auto; }
  .ppc-service .service-wrap .brick.services-pan a {
    position: static;
    transform: translate(0); }

.solution-left-mod ul ul {
  padding: 25px 0 5px; }

.solution-left-mod ul li:last-child {
  margin-bottom: 25px; }

.e-commerce-plus h2 {
  color: #333; }

.rm-bnr h1 {
  margin-bottom: 30px !important; }

.e-commerce-why-mod .cstm-link-p a {
  color: #4a90e2 !important; }

.expert-seo-p {
  font-size: 18px; }

.expert-seo h4 {
  margin-bottom: 30px; }

.schedule-box {
  margin-top: -50px; }

.hire-designer .schedule-box {
  margin-top: 0; }

.cms-bnr-award ul.srv-bnr-logo li {
  flex: 0 0 20%; }
  @media screen and (min-width: 992px) {
    .cms-bnr-award ul.srv-bnr-logo li {
      flex: 0 0 200px; } }

.Web-Application-Development .o-skills-hero h1,
.Custom-Software-Development .o-skills-hero h1,
.eCommerce .o-skills-hero h1,
.Dedicated .o-skills-hero h1,
.Analytics .o-skills-hero h1,
.hybrid .o-skills-hero h1,
.Mobile .o-skills-hero h1,
.hire-designer .o-skills-hero h1,
.Backend-Development .o-skills-hero h1,
.front-end-development .o-skills-hero h1 {
  margin-top: 25px; }

.Outsourced .o-skills-hero h1,
.CMS .o-skills-hero h1,
.Chatbots .o-skills-hero h1 {
  margin-top: 25px; }

@media screen and (min-width: 992px) {
  .service-pages .o-skills-hero {
    padding: 65px 20px 0 !important; } }

.service-pages .schedule-box {
  margin-top: 0;
  height: 100%; }

@media screen and (min-width: 992px) {
  .service-pages .header_left_pan img {
    margin-top: 40px; } }

@media screen and (min-width: 992px) {
  .service-pages .header_left_pan {
    padding-right: 20px; } }

.service-pages .header_left_pan .skill-page-mod img {
  margin-top: 0; }
  @media screen and (min-width: 992px) {
    .service-pages .header_left_pan .skill-page-mod img {
      padding: 20px 0 20px; } }

.panel1 ul li {
  font-family: Montserrat, sans-serif; }

.font-size-50 {
  font-size: 30px; }
  @media screen and (min-width: 992px) {
    .font-size-50 {
      font-size: 50px !important; } }

@media screen and (min-width: 992px) {
  .aprch-blockchain .masonry .brick {
    flex: 1; } }

@media screen and (min-width: 992px) {
  .ind-off-section .service-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; } }

@media screen and (min-width: 992px) {
  .ind-off-section .service-wrap .brick.services-pan {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px 30px 50px; } }

@media screen and (min-width: 992px) {
  .ind-off-section-com .service-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; } }

@media screen and (min-width: 992px) {
  .ind-off-section-com .service-wrap .brick.services-pan {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px 30px 50px;
    align-content: baseline; } }

.our-service-bnr-p {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 40px; }

@media screen and (min-width: 992px) {
  .our-service-bnr-h1 {
    padding-bottom: 10px !important; } }

@media screen and (min-width: 992px) {
  .our-service-bnr-a {
    margin-bottom: 70px; } }

.cms-dev-service a {
  display: none; }
  @media screen and (min-width: 992px) {
    .cms-dev-service a {
      display: block; } }

/*What we test*/
.what-test-section {
  padding: 50px 0 25px; }
  @media screen and (min-width: 992px) {
    .what-test-section {
      padding: 80px 0 70px; } }
  .what-test-section h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 30px;
    line-height: 36px; }
    @media screen and (min-width: 992px) {
      .what-test-section h2 {
        font-size: 48px;
        line-height: 61px;
        margin-bottom: 60px; } }
    .what-test-section h2 br {
      display: none; }
      @media screen and (min-width: 992px) {
        .what-test-section h2 br {
          display: block; } }
  .what-test-section .what-test-section__para {
    margin-bottom: 20px;
    font-weight: 500; }
    @media screen and (min-width: 992px) {
      .what-test-section .what-test-section__para {
        font-size: 21px;
        line-height: 31px;
        margin-bottom: 30px; } }
  .what-test-section ul {
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .what-test-section ul {
        margin-bottom: 70px; } }
    .what-test-section ul li {
      font-size: 18px;
      line-height: 40px;
      position: relative;
      padding-left: 30px; }
      .what-test-section ul li:before {
        content: "";
        position: absolute;
        background: url(/images/common/green-tick.svg);
        height: 19px;
        width: 19px;
        top: 10px;
        left: 0; }
      .what-test-section ul li a {
        color: #333;
        font-family: Poppins; }
  .what-test-section .last-ul {
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .what-test-section .last-ul {
        margin-bottom: 0; } }
  @media screen and (min-width: 992px) {
    .what-test-section .row {
      justify-content: space-between; } }
  @media screen and (min-width: 992px) {
    .what-test-section .row .col-lg-4 {
      flex: inherit;
      width: auto; } }

.what-test-section-mod .number-div {
  position: relative;
  flex: 0 0 90px; }
  .what-test-section-mod .number-div:before {
    content: "";
    position: absolute;
    background: #dfdfdf;
    width: 6px;
    height: 97%;
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto; }

.what-test-section-mod h2 {
  margin-bottom: 20px; }

.what-test-section-mod .h2-mb {
  margin-bottom: 50px; }

.what-test-section-mod .cmn-heading-para {
  max-width: 700px;
  margin: 0 auto 50px; }

.what-test-section-mod h3 {
  font-size: 28px;
  line-height: 26px;
  flex: 0 0 auto;
  padding-right: 20px;
  font-weight: 400; }
  @media screen and (min-width: 768px) {
    .what-test-section-mod h3 {
      font-size: 36px;
      line-height: 32px; } }

.what-test-section-mod h4 {
  font-size: 18px;
  margin-left: 15px;
  margin-bottom: 0; }
  @media screen and (min-width: 540px) {
    .what-test-section-mod h4 {
      font-size: 20px; } }
  @media screen and (min-width: 992px) {
    .what-test-section-mod h4 {
      font-size: 24px;
      margin-left: 0;
      margin-bottom: 0.5rem; } }

.what-test-section-mod p {
  font-size: 16px;
  line-height: 22px; }
  @media screen and (min-width: 768px) {
    .what-test-section-mod p {
      font-size: 18px;
      line-height: 28px; } }

.what-test-section-mod .spiral-col-left {
  flex: 1; }
  @media screen and (min-width: 992px) {
    .what-test-section-mod .spiral-col-left {
      flex: 0 0 38%;
      width: 38%; } }
  .what-test-section-mod .spiral-col-left-box {
    position: relative; }
    @media screen and (min-width: 992px) {
      .what-test-section-mod .spiral-col-left-box {
        margin-bottom: 100px; } }
    .what-test-section-mod .spiral-col-left-box .triangle-class {
      position: absolute;
      top: 35px;
      left: -14px; }
      @media screen and (min-width: 992px) {
        .what-test-section-mod .spiral-col-left-box .triangle-class {
          right: 0;
          top: 60px;
          left: inherit;
          right: 0; } }
      .what-test-section-mod .spiral-col-left-box .triangle-class:before {
        content: "";
        position: absolute;
        z-index: 9;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-right: 15px solid #fff;
        border-bottom: 10px solid transparent; }
        @media screen and (min-width: 992px) {
          .what-test-section-mod .spiral-col-left-box .triangle-class:before {
            border-top: 18px solid transparent;
            border-left: 28px solid #fff;
            border-bottom: 18px solid transparent;
            border-right: 0; } }
      .what-test-section-mod .spiral-col-left-box .triangle-class:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 11px solid transparent;
        border-right: 15px solid #e3e3e3;
        border-bottom: 11px solid transparent;
        top: -1px;
        left: -1px; }
        @media screen and (min-width: 992px) {
          .what-test-section-mod .spiral-col-left-box .triangle-class:after {
            border-top: 20px solid transparent;
            border-left: 30px solid #e3e3e3;
            border-bottom: 20px solid transparent;
            border-right: 0;
            top: -2px;
            left: inherit; } }

.what-test-section-mod .spiral-col-right {
  flex: 0 0 80%;
  width: 80%; }
  @media screen and (min-width: 992px) {
    .what-test-section-mod .spiral-col-right {
      flex: 0 0 38%;
      width: 38%; } }
  .what-test-section-mod .spiral-col-right-box {
    position: relative; }
    @media screen and (min-width: 992px) {
      .what-test-section-mod .spiral-col-right-box {
        margin-top: 120px; } }
    .what-test-section-mod .spiral-col-right-box .triangle-class {
      position: absolute;
      top: 35px;
      left: -14px; }
      @media screen and (min-width: 992px) {
        .what-test-section-mod .spiral-col-right-box .triangle-class {
          right: inherit;
          top: 60px;
          left: -28px; } }
      .what-test-section-mod .spiral-col-right-box .triangle-class:before {
        content: "";
        position: absolute;
        z-index: 9;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-right: 15px solid #fff;
        border-bottom: 10px solid transparent; }
        @media screen and (min-width: 992px) {
          .what-test-section-mod .spiral-col-right-box .triangle-class:before {
            border-top: 18px solid transparent;
            border-right: 28px solid #fff;
            border-bottom: 18px solid transparent; } }
      .what-test-section-mod .spiral-col-right-box .triangle-class:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 11px solid transparent;
        border-right: 16px solid #e3e3e3;
        border-bottom: 11px solid transparent;
        top: -1px;
        left: -2px; }
        @media screen and (min-width: 992px) {
          .what-test-section-mod .spiral-col-right-box .triangle-class:after {
            border-top: 20px solid transparent;
            border-right: 30px solid #e3e3e3;
            border-bottom: 20px solid transparent;
            top: -2px;
            left: -2px; } }

.what-test-section-mod .spiral-col-mid {
  flex: 0 0 22%;
  width: 22%;
  text-align: center;
  display: none; }
  .what-test-section-mod .spiral-col-mid .spiral-col-mid img {
    width: 200px; }
  @media screen and (min-width: 992px) {
    .what-test-section-mod .spiral-col-mid {
      display: block; } }

.what-test-section-mod .spiral-col-box {
  background: #fff;
  padding: 30px 25px 30px 25px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  position: relative; }
  @media screen and (min-width: 768px) {
    .what-test-section-mod .spiral-col-box {
      padding: 30px 30px 30px 35px; } }
  @media screen and (min-width: 992px) {
    .what-test-section-mod .spiral-col-box:before {
      display: block; } }
  .what-test-section-mod .spiral-col-box:after {
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    top: 20px;
    left: -82px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 21px; }
    @media screen and (min-width: 992px) {
      .what-test-section-mod .spiral-col-box:after {
        display: none; } }
  .what-test-section-mod .spiral-col-box p {
    margin-bottom: 0; }
    @media screen and (min-width: 768px) {
      .what-test-section-mod .spiral-col-box p {
        margin-bottom: 25px; } }
  .what-test-section-mod .spiral-col-box-head {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    @media screen and (min-width: 992px) {
      .what-test-section-mod .spiral-col-box-head {
        margin-bottom: 15px;
        align-items: flex-start; } }
    .what-test-section-mod .spiral-col-box-head img {
      width: 50px; }
  .what-test-section-mod .spiral-col-box1 {
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .what-test-section-mod .spiral-col-box1 {
        margin-bottom: 160px; } }
    .what-test-section-mod .spiral-col-box1:after {
      content: "01";
      background: #2c4d82; }
  @media screen and (min-width: 992px) {
    .what-test-section-mod .spiral-col-box1-mod {
      margin-bottom: 100px; } }
  .what-test-section-mod .spiral-col-box2 {
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .what-test-section-mod .spiral-col-box2 {
        margin-bottom: 160px; } }
    .what-test-section-mod .spiral-col-box2:after {
      content: "03";
      background: #1b89af; }
  .what-test-section-mod .spiral-col-box3 {
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .what-test-section-mod .spiral-col-box3 {
        margin-bottom: 160px; } }
    .what-test-section-mod .spiral-col-box3:after {
      content: "05";
      background: #28c89a; }
  .what-test-section-mod .spiral-col-box4 {
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .what-test-section-mod .spiral-col-box4 {
        margin-top: 230px; } }
    .what-test-section-mod .spiral-col-box4:after {
      content: "02";
      background: #036497; }
  .what-test-section-mod .spiral-col-box5 {
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .what-test-section-mod .spiral-col-box5 {
        margin-top: 100px; } }
    .what-test-section-mod .spiral-col-box5:after {
      content: "04";
      background: #2bbdc3; }
  @media screen and (min-width: 992px) {
    .what-test-section-mod .spiral-col-box5-mod {
      margin-top: 175px; } }
  .what-test-section-mod .spiral-col-box6 {
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .what-test-section-mod .spiral-col-box6 {
        margin-bottom: 40px;
        margin-top: 130px; } }
    .what-test-section-mod .spiral-col-box6:after {
      content: "06";
      background: #36e079; }
  .what-test-section-mod .spiral-col-box7 {
    margin-bottom: 30px; }
    .what-test-section-mod .spiral-col-box7:after {
      content: "07";
      background: #71F99B; }
  .what-test-section-mod .spiral-col-box .color-1 {
    color: #2c4d82; }
  .what-test-section-mod .spiral-col-box .color-2 {
    color: #1b89af; }
  .what-test-section-mod .spiral-col-box .color-3 {
    color: #28c89a; }
  .what-test-section-mod .spiral-col-box .color-4 {
    color: #036497; }
  .what-test-section-mod .spiral-col-box .color-5 {
    color: #2bbdc3; }
  .what-test-section-mod .spiral-col-box .color-6 {
    color: #36e079; }

.black-font-color {
  color: #333 !important; }

.cmn-logo-fix .o-skills-hero h1 {
  margin-top: 25px; }

@media screen and (min-width: 992px) {
  .home-ind-off-section .service-wrap .brick.services-pan {
    align-content: flex-start;
    padding: 50px 30px 100px; } }

@media screen and (min-width: 992px) {
  .home-ind-off-section .service-wrap .brick.services-pan a {
    position: absolute;
    bottom: 50px; } }

.home-ind-off-section .service-wrap .brick.services-pan h3 {
  flex: 0 0 100%; }

.template-bnr {
  padding-top: 80px;
  background: #0dad81;
  background: -moz-linear-gradient(108deg, #0dad81 0%, #005385 30%, #011b40 65%);
  background: -webkit-linear-gradient(108deg, #0dad81 0%, #005385 30%, #011b40 65%);
  background: linear-gradient(108deg, #0dad81 0%, #005385 30%, #011b40 65%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0dad81",endColorstr="#011b40",GradientType=1); }
  @media (min-width: 992px) {
    .template-bnr {
      padding-top: 100px; } }
  .template-bnr h1 {
    color: #fff;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px; }
    @media (min-width: 992px) {
      .template-bnr h1 {
        font-size: 62px;
        line-height: 75px;
        padding: 52px 0; } }

.logo-thumb {
  padding-top: 45px; }
  @media (min-width: 992px) {
    .logo-thumb {
      padding-top: 75px;
      padding-bottom: 45px; } }
  .logo-thumb a:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 75%;
    transform: translate(-50%, 0);
    background-image: url(/images/home/home-arrow.png);
    width: 22px;
    height: 22px; }
  .logo-thumb a:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.3); }
    .logo-thumb a:hover:before {
      background-image: url(/images/home/home-orange-arrow.png); }
  .logo-thumb_service a:before {
    top: 80%; }

@media (min-width: 992px) {
  .logo-thumb2, .logo-thumb3, .logo-thumb4, .logo-thumb5 {
    padding-top: 20px;
    padding-bottom: 45px; } }

.logo-thumb5 {
  padding-bottom: 25px; }
  @media (min-width: 992px) {
    .logo-thumb5 {
      padding-bottom: 60px; } }

.box-bg_service {
  height: 250px;
  display: inline-block;
  width: 100%;
  box-shadow: 0px 0px 35px rgba(102, 99, 99, 0.1);
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1); }
  .box-bg_service h6 {
    position: absolute;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -70%);
    width: 70%;
    font-size: 20px;
    line-height: 28px; }
  .box-bg_service img {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -30%); }

/* ANCHOR About pages*/
.padding-bottom-60 {
  padding-bottom: 60px; }

.o-home-hero .home-video {
  width: auto; }
  @media screen and (min-width: 992px) {
    .o-home-hero .home-video {
      height: 900px;
      width: auto; } }
  @media screen and (min-width: 1200px) {
    .o-home-hero .home-video {
      height: 1025px;
      width: 100%; } }

.about-bg {
  position: relative; }
  .about-bg:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(9, 27, 56, 0.8);
    transform: translate(-50%, 0);
    z-index: 1; }
  .about-bg .green-head-ribbon {
    background: none; }
  @media (min-width: 992px) {
    .about-bg h2 {
      font-size: 62px;
      line-height: 70px; } }
  .about-bg .play-video {
    padding-top: 0;
    padding-bottom: 0;
    color: #8abaf2;
    font-size: 0;
    font-family: "Montserrat", sans-serif;
    display: inline-block; }
    @media (min-width: 992px) {
      .about-bg .play-video {
        background: url(/images/common/play-button.svg) no-repeat 50% 0;
        width: inherit;
        height: inherit;
        background-size: auto;
        font-size: 14px;
        padding-top: 90px;
        padding-bottom: 30px; } }
  @media (min-width: 992px) {
    .about-bg ul.srv-bnr-logo li:nth-child(4) {
      width: 116px;
      flex: 0 0 120px; } }
  @media (min-width: 992px) {
    .about-bg ul.skill-page-mod {
      margin-bottom: 60px !important; } }

.about-details {
  padding: 45px 0 20px; }
  @media (min-width: 992px) {
    .about-details {
      padding: 70px 0 50px; } }
  .about-details-para {
    width: 100%;
    text-align: center; }
    @media (min-width: 992px) {
      .about-details-para {
        width: 77%;
        margin: 0 auto; } }
    .about-details-para p {
      color: #333;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 25px;
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .about-details-para p {
          font-family: "Montserrat", sans-serif;
          font-size: 19px;
          line-height: 30px; } }
      .about-details-para p strong {
        font-weight: 500;
        display: block; }
        @media (min-width: 992px) {
          .about-details-para p strong {
            font-size: 24px;
            line-height: 32px;
            font-weight: 600; } }
  @media (min-width: 992px) {
    .about-details .btn_wrapper {
      margin-top: 35px; } }

.market-section {
  padding: 50px 0;
  background-color: #f9f9f9; }
  @media (min-width: 992px) {
    .market-section {
      padding: 80px 0 20px; } }
  .market-section .market-wrapper {
    display: flex;
    align-items: flex-start; }
    @media (min-width: 992px) {
      .market-section .market-wrapper {
        width: 77%;
        margin: 0 auto 30px; } }
    .market-section .market-wrapper-icon {
      flex: 0 0 13%; }
    .market-section .market-wrapper-text {
      flex: 1; }
      @media (min-width: 992px) {
        .market-section .market-wrapper-text h3 {
          font-size: 30px;
          line-height: 30px;
          margin-bottom: 20px; } }
      .market-section .market-wrapper-text p {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 25px; }
        @media (min-width: 992px) {
          .market-section .market-wrapper-text p {
            font-size: 19px;
            line-height: 30px;
            font-weight: 500; } }

.o-awards,
.n-award {
  padding: 50px 0; }
  @media (min-width: 992px) {
    .o-awards,
    .n-award {
      text-align: center;
      padding: 75px 0 40px; } }
  @media (min-width: 992px) {
    .o-awards .n-award-wrapper,
    .n-award .n-award-wrapper {
      width: 80%;
      margin: 0 auto; } }
  .o-awards .n-award-wrapper ul li,
  .n-award .n-award-wrapper ul li {
    display: inline-block;
    margin: 0 15px 35px; }
  .o-awards h3,
  .n-award h3 {
    font-family: 'Poppins'; }

.n-award h2 {
  text-align: center; }

.n-award ul {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  justify-content: center; }
  @media (min-width: 992px) {
    .n-award ul {
      display: block; } }
  .n-award ul li {
    flex: 0 0 21%;
    margin: 0 5px 15px !important; }
    @media (min-width: 992px) {
      .n-award ul li {
        flex: auto;
        margin: 0 10px 15px !important; } }

.o-aboutus .slick-next {
  position: absolute;
  background: url(/images/mobile-all/small-right-icon.png) no-repeat 0 0;
  right: 44%;
  bottom: -10px;
  font-size: 0;
  width: 14px;
  height: 26px;
  background-size: 10px; }

.o-aboutus .slick-prev {
  position: absolute;
  background: url(/images/mobile-all/small-left-icon.png) no-repeat 0 0;
  right: 52%;
  bottom: -10px;
  font-size: 0;
  width: 14px;
  height: 26px;
  background-size: 10px; }

.o-aboutus .people-slider {
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .o-aboutus .people-slider {
      margin-bottom: 0; } }

.culture-slider ul.slick-dots {
  margin-top: 0; }

.culture-slider .slick-arrow {
  display: none !important; }

.new-srv-slider .slick-dots {
  margin-top: 0;
  margin-bottom: 30px;
  text-align: center; }
  @media (min-width: 992px) {
    .new-srv-slider .slick-dots {
      margin-top: 30px; } }
  .new-srv-slider .slick-dots li {
    display: inline-block;
    border-radius: 50%;
    text-indent: -10000px;
    width: 7px;
    height: 7px;
    margin: 0 6px;
    background-color: #9b9b9b;
    vertical-align: middle; }
    .new-srv-slider .slick-dots li.slick-active {
      width: 10px;
      height: 10px;
      background: #49c684; }

.fact-section {
  background: linear-gradient(197deg, #037872 0%, #00517f 30%, #051b39 85%);
  padding: 40px 0 50px; }
  @media (min-width: 992px) {
    .fact-section {
      padding: 60px 0 50px;
      background: linear-gradient(145deg, #07b260 0%, #00517f 25%, #051b39 100%); } }
  .fact-section h2 {
    text-align: center;
    color: #fff;
    font-size: 24px;
    line-height: 34px;
    position: relative;
    padding-bottom: 10px;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .fact-section h2 {
        line-height: 61px;
        font-size: 30px;
        padding-bottom: 15px; } }
  .fact-section .all-facts {
    margin-top: 20px; }
    .fact-section .all-facts ul {
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 30px; }
      @media (min-width: 992px) {
        .fact-section .all-facts ul {
          margin-bottom: 50px; } }
      .fact-section .all-facts ul li {
        list-style: none;
        width: inherit;
        max-width: 46%;
        flex: 0 0 46%;
        border: 1px solid #49c684;
        background-color: transparent;
        border-radius: 3px;
        padding: 25px 20px 15px 20px;
        line-height: 16px;
        margin: 5px 5px; }
        @media (min-width: 992px) {
          .fact-section .all-facts ul li {
            width: 100%;
            flex: auto;
            max-width: none;
            margin: 3px 2px;
            padding: 25px 20px 15px 30px; } }
        .fact-section .all-facts ul li img {
          width: 45px; }
          @media (min-width: 992px) {
            .fact-section .all-facts ul li img {
              width: auto; } }
        .fact-section .all-facts ul li h3 {
          font-weight: 600;
          color: #fff;
          font-size: 24px;
          line-height: 24px;
          margin-bottom: 0;
          margin-top: 5px; }
          @media (min-width: 992px) {
            .fact-section .all-facts ul li h3 {
              font-size: 36px;
              line-height: 24px;
              margin-bottom: 10px;
              margin-top: 15px; } }
        .fact-section .all-facts ul li small {
          color: #fff;
          font-size: 13px;
          line-height: 16px;
          font-family: "Montserrat", sans-serif; }
          @media (min-width: 992px) {
            .fact-section .all-facts ul li small {
              font-size: 20px;
              line-height: 26px;
              font-family: "Montserrat", sans-serif; } }
        .fact-section .all-facts ul li:nth-child(2) {
          background: #4a90e2;
          border-color: #4a90e2; }
        .fact-section .all-facts ul li:nth-child(3) {
          border-color: #4a90e2; }
        .fact-section .all-facts ul li:nth-child(4) {
          background: #009c52;
          border-color: #009c52; }
        .fact-section .all-facts ul li:nth-child(5) {
          background: #4a90e2;
          border-color: #4a90e2; }
        .fact-section .all-facts ul li:nth-child(6) {
          background: #091b38;
          border-color: #091b38; }
        .fact-section .all-facts ul li:nth-child(8) {
          background: #fff;
          border-color: #fff; }
          .fact-section .all-facts ul li:nth-child(8) small {
            font-size: 10px;
            line-height: 12px;
            color: #333; }
            @media (min-width: 992px) {
              .fact-section .all-facts ul li:nth-child(8) small {
                font-size: 18px; } }
          .fact-section .all-facts ul li:nth-child(8) h3 {
            color: #333;
            font-size: 15px;
            margin-bottom: 0;
            margin-top: 5px; }
            @media (min-width: 992px) {
              .fact-section .all-facts ul li:nth-child(8) h3 {
                font-size: 24px;
                margin-bottom: 10px; } }
        .fact-section .all-facts ul li:last-child {
          width: inherit;
          max-width: 95%;
          flex: 0 0 95%;
          padding: 30px 35px;
          border-color: #4a90e2; }
          @media (min-width: 992px) {
            .fact-section .all-facts ul li:last-child {
              width: 38%;
              flex: auto;
              max-width: none; } }
        .fact-section .all-facts ul li .ex-small {
          font-size: 16px; }
        .fact-section .all-facts ul li span {
          color: #fff;
          font-size: 13px;
          line-height: 18px;
          font-weight: 600;
          display: block;
          font-family: "Montserrat", sans-serif; }
          @media (min-width: 992px) {
            .fact-section .all-facts ul li span {
              font-size: 20px;
              line-height: 27px;
              font-family: "Montserrat", sans-serif; } }
        @media (min-width: 992px) {
          .fact-section .all-facts ul li {
            width: 18%; } }
  .fact-section:after {
    display: none; }
    @media (min-width: 992px) {
      .fact-section:after {
        display: block; } }

.pricings-section {
  background: #f5f5f5;
  padding: 45px 0 20px;
  border-bottom: 1px solid #dedede; }
  @media (min-width: 992px) {
    .pricings-section {
      padding: 70px 0 50px; } }
  .pricings-section h3 {
    margin-bottom: 25px;
    text-align: center; }
    @media (min-width: 992px) {
      .pricings-section h3 {
        font-size: 48px;
        line-height: 61px;
        margin-bottom: 40px; } }
  .pricings-section .pmb-holder {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 992px) {
      .pricings-section .pmb-holder {
        width: 65%;
        margin: 0 auto;
        flex-wrap: nowrap; } }
    .pricings-section .pmb-holder .pricing-modal-box {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin: 0 15px 25px 15px;
      background: #fff;
      box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.1);
      padding: 20px 30px;
      border-radius: 5px; }
      @media (min-width: 992px) {
        .pricings-section .pmb-holder .pricing-modal-box {
          margin: 0 15px 50px 15px; } }
      .pricings-section .pmb-holder .pricing-modal-box:nth-child(1) {
        margin-left: 0; }
      .pricings-section .pmb-holder .pricing-modal-box:nth-child(2) {
        margin-right: 0; }
      .pricings-section .pmb-holder .pricing-modal-box .pricing-modal-img {
        position: relative;
        width: 50%; }
        .pricings-section .pmb-holder .pricing-modal-box .pricing-modal-img .img-hold {
          position: relative;
          width: 101px; }
      .pricings-section .pmb-holder .pricing-modal-box .pricing-modal-text h4 {
        padding-left: 45px;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        color: #333;
        position: relative; }
        @media (min-width: 992px) {
          .pricings-section .pmb-holder .pricing-modal-box .pricing-modal-text h4 {
            font-size: 24px; } }
        .pricings-section .pmb-holder .pricing-modal-box .pricing-modal-text h4:before {
          content: "";
          position: absolute;
          background: #2dbe70;
          height: 1px;
          width: 20px;
          left: 10px;
          top: 27px; }
          @media (min-width: 992px) {
            .pricings-section .pmb-holder .pricing-modal-box .pricing-modal-text h4:before {
              width: 35px;
              left: 4px; } }

.pricings-section .pmb-holder .pricing-modal-box:nth-child(1) {
  margin-right: 0; }
  @media (min-width: 992px) {
    .pricings-section .pmb-holder .pricing-modal-box:nth-child(1) {
      margin-right: 15px; } }

.pricings-section .pmb-holder .pricing-modal-box:nth-child(2) {
  margin-left: 0; }
  @media (min-width: 992px) {
    .pricings-section .pmb-holder .pricing-modal-box:nth-child(2) {
      margin-left: 15px; } }

.about-client-section {
  padding: 20px 0 17px; }
  @media (min-width: 992px) {
    .about-client-section {
      padding: 5px 0 25px; } }
  .about-client-section h2 {
    padding-top: 64px;
    text-align: center; }
    @media (min-width: 992px) {
      .about-client-section h2 {
        font-size: 48px;
        line-height: 61px; } }
  .about-client-section .client-logo {
    background: #fff;
    text-align: center;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px; }
    .about-client-section .client-logo:before {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0;
      content: "";
      width: 800px;
      height: 2px;
      background-color: #ccc;
      transform: translate(0); }
    .about-client-section .client-logo li {
      list-style: none;
      padding: 33px 21px;
      display: inline-block; }
  .about-client-section .client-icon {
    padding-right: 0;
    margin-top: 15px; }
    @media (min-width: 992px) {
      .about-client-section .client-icon {
        padding-right: 50px;
        margin-top: 40px; } }
  .about-client-section .list-client {
    display: flex;
    margin-bottom: 0;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    align-items: center;
    padding: 0 30px; }
    @media screen and (min-width: 768px) {
      .about-client-section .list-client {
        justify-content: center; } }
    @media screen and (min-width: 992px) {
      .about-client-section .list-client {
        font-size: 17px;
        line-height: 25px;
        font-family: "Montserrat", sans-serif;
        align-items: inherit;
        margin-bottom: 50px;
        padding: 0; } }
    .about-client-section .list-client span {
      flex: 0 0 60px;
      display: inline-block;
      margin-right: 15px; }
      @media (min-width: 992px) {
        .about-client-section .list-client span {
          flex: 0 0 27%;
          margin-right: 0; } }
  .about-client-section .slick-dots {
    margin-top: 15px; }

.services {
  padding: 40px 0 20px;
  background: #f9f9f9; }
  @media (min-width: 992px) {
    .services {
      padding: 70px 0 30px; } }
  @media (min-width: 992px) {
    .services .btn_wrapper {
      margin-top: 20px; } }
  .services h2 {
    text-align: center;
    font-size: 30px;
    line-height: 40px; }
    @media (min-width: 992px) {
      .services h2 {
        font-size: 48px;
        line-height: 61px; } }
  .services p {
    font-size: 16px;
    line-height: 28px;
    color: #333;
    font-weight: 500;
    text-align: center;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .services p {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 40px;
        margin: 0 auto 40px;
        max-width: 700px; } }
  .services .col-sm-6 a,
  .services .col-sm-3 a {
    position: relative;
    display: block;
    background: #fff;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
    padding: 20px 10px 0;
    min-height: auto;
    height: 180px;
    margin-bottom: 10px;
    text-align: center;
    border-radius: 5px;
    position: relative;
    border: 1px solid #e0e0e0;
    border-radius: 6px; }
    @media (min-width: 992px) {
      .services .col-sm-6 a,
      .services .col-sm-3 a {
        height: auto;
        min-height: 233px;
        padding: 35px 20px 0;
        box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
        margin-bottom: 30px; } }
    .services .col-sm-6 a .srv-box,
    .services .col-sm-3 a .srv-box {
      position: relative;
      z-index: 9; }
      .services .col-sm-6 a .srv-box img,
      .services .col-sm-6 a .srv-box svg,
      .services .col-sm-3 a .srv-box img,
      .services .col-sm-3 a .srv-box svg {
        margin: 0 auto;
        margin-bottom: 15px;
        width: 45px;
        height: 50px; }
        @media (min-width: 992px) {
          .services .col-sm-6 a .srv-box img,
          .services .col-sm-6 a .srv-box svg,
          .services .col-sm-3 a .srv-box img,
          .services .col-sm-3 a .srv-box svg {
            margin-bottom: 30px; } }
      .services .col-sm-6 a .srv-box p,
      .services .col-sm-3 a .srv-box p {
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 40px;
        font-family: "Montserrat", sans-serif; }
        @media (min-width: 992px) {
          .services .col-sm-6 a .srv-box p,
          .services .col-sm-3 a .srv-box p {
            font-size: 19px;
            line-height: 24px; } }
    .services .col-sm-6 a .cmn-color,
    .services .col-sm-3 a .cmn-color {
      display: block;
      position: absolute;
      top: inherit;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      width: 100%;
      height: 0;
      transition: 0.4s all;
      opacity: 1; }
    @media (hover: hover) and (pointer: fine) {
      .services .col-sm-6 a:hover .cmn-color,
      .services .col-sm-3 a:hover .cmn-color {
        height: 100%;
        opacity: 1; }
      .services .col-sm-6 a:hover p,
      .services .col-sm-3 a:hover p {
        color: #fff; }
      .services .col-sm-6 a:hover path,
      .services .col-sm-6 a:hover polygon,
      .services .col-sm-6 a:hover rect,
      .services .col-sm-6 a:hover circle,
      .services .col-sm-3 a:hover path,
      .services .col-sm-3 a:hover polygon,
      .services .col-sm-3 a:hover rect,
      .services .col-sm-3 a:hover circle {
        fill: #fff; } }
  .services .row .col-sm-3:nth-child(2n + 1) a {
    border-bottom: 5px solid #49c684; }
    .services .row .col-sm-3:nth-child(2n + 1) a .cmn-color {
      background: #49c684; }
  .services .row .col-sm-3:nth-child(2n) a {
    border-bottom: 5px solid #4a90e2; }
    .services .row .col-sm-3:nth-child(2n) a .cmn-color {
      background: #4a90e2; }
  .services .row .col-sm-6 {
    flex: 1; }
    .services .row .col-sm-6:nth-child(2n + 1) {
      padding-right: 5px; }
      .services .row .col-sm-6:nth-child(2n + 1) a {
        border-bottom: 5px solid #49c684; }
        .services .row .col-sm-6:nth-child(2n + 1) a .cmn-color {
          background: #49c684; }
    .services .row .col-sm-6:nth-child(2n) {
      padding-left: 5px; }
      .services .row .col-sm-6:nth-child(2n) a {
        border-bottom: 5px solid #4a90e2; }
        .services .row .col-sm-6:nth-child(2n) a .cmn-color {
          background: #4a90e2; }
  .services .row .col-sm-6:nth-child(3) a {
    border-bottom: 5px solid #4a90e2; }
    @media (min-width: 992px) {
      .services .row .col-sm-6:nth-child(3) a {
        border-bottom: 5px solid #49c684; } }
  .services .row .col-sm-6:nth-child(4) a {
    border-bottom: 5px solid #49c684; }
    @media (min-width: 992px) {
      .services .row .col-sm-6:nth-child(4) a {
        border-bottom: 5px solid #4a90e2; } }

.ex-padding-srv svg {
  width: 40px; }

.tech {
  padding: 40px 0 20px; }
  @media (min-width: 992px) {
    .tech {
      padding: 70px 0 45px; } }
  .tech h2 {
    text-align: center;
    font-size: 30px;
    line-height: 40px; }
    @media (min-width: 992px) {
      .tech h2 {
        font-size: 48px;
        line-height: 61px; } }
  .tech span.tch-span {
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    color: #333;
    font-weight: 500;
    margin-bottom: 25px;
    display: block;
    width: 100%;
    margin-top: 10px; }
    @media (min-width: 992px) {
      .tech span.tch-span {
        font-size: 20px;
        line-height: 32px;
        margin-top: 35px; } }
  .tech ul {
    text-align: center;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .tech ul {
        margin-bottom: 30px; } }
    .tech ul li {
      background: #fff;
      box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.05);
      text-align: center;
      border: 1px solid #ededed;
      height: 70px;
      line-height: 70px;
      display: inline-block;
      border-radius: 5px;
      width: 46%;
      margin: 0 5px 10px; }
      @media (min-width: 992px) {
        .tech ul li {
          width: 13%;
          margin: 0 10px 20px; } }
      .tech ul li:nth-child(14) a img {
        width: 100px; }
      .tech ul li a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%; }
  .tech p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 25px; }
    @media (min-width: 992px) {
      .tech p {
        font-size: 19px;
        line-height: 30px; } }
  @media (min-width: 992px) {
    .tech .btn_wrapper {
      margin-top: 20px; } }

.culture {
  padding: 40px 0 20px; }
  @media (min-width: 992px) {
    .culture {
      padding: 70px 0 50px; } }
  .culture h2 {
    margin-bottom: 40px;
    font-size: 30px; }
    @media (min-width: 992px) {
      .culture h2 {
        font-size: 48px; } }
  .culture-box {
    margin-bottom: 20px;
    padding: 0; }
    @media (min-width: 992px) {
      .culture-box {
        margin-bottom: 40px;
        padding: 0 15px; } }
    .culture-box img {
      margin: 0 auto; }
    .culture-box h3 {
      font-size: 22px;
      margin: 15px 0 25px; }
      @media (min-width: 992px) {
        .culture-box h3 {
          margin: 15px 0 10px; } }
    .culture-box p {
      font-size: 16px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 20px;
      font-family: "Montserrat", sans-serif; }
      @media (min-width: 992px) {
        .culture-box p {
          font-family: "Montserrat", sans-serif;
          font-size: 19px;
          line-height: 30px;
          margin-bottom: 25px; } }
  .culture:after {
    display: none; }
    @media (min-width: 992px) {
      .culture:after {
        display: block; } }

.rw-rev {
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .rw-rev {
      flex-direction: row-reverse;
      margin-top: 70px;
      margin-bottom: 50px; } }
  @media (min-width: 992px) {
    .rw-rev .product-img {
      text-align: left !important; } }

.product {
  padding: 40px 0 20px; }
  @media (min-width: 992px) {
    .product {
      padding: 70px 0 50px; } }
  .product h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 40px;
    font-weight: 600; }
    @media (min-width: 992px) {
      .product h2 {
        font-size: 48px;
        line-height: 61px;
        margin-bottom: 50px; } }
  .product-wrapper .product-logo {
    padding: 0;
    margin: 5px auto 25px;
    display: flex;
    align-items: center;
    max-width: 360px;
    padding: 9px 15px;
    background: #fff; }
    @media (min-width: 992px) {
      .product-wrapper .product-logo {
        box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.1);
        padding: 9px 25px;
        height: 61px;
        max-width: 430px;
        margin: 5px 0 25px; } }
    .product-wrapper .product-logo a {
      flex: 0 0 120px; }
      @media (min-width: 992px) {
        .product-wrapper .product-logo a {
          flex: 1; } }
      .product-wrapper .product-logo a img {
        width: 120px; }
        @media (min-width: 992px) {
          .product-wrapper .product-logo a img {
            width: 155px; } }
    .product-wrapper .product-logo span {
      background: url(/images/mobile-all/globe-icon.png) no-repeat 10px 3px;
      margin-left: 25px;
      position: relative;
      background-size: 16px; }
      @media (min-width: 992px) {
        .product-wrapper .product-logo span {
          flex: 1;
          background-size: auto;
          background-position: 2px 4px; } }
      .product-wrapper .product-logo span:before {
        content: "";
        position: absolute;
        top: 2px;
        left: -8px;
        height: 20px;
        width: 1px;
        background: #d8d8d8; }
        @media (min-width: 992px) {
          .product-wrapper .product-logo span:before {
            left: -20px;
            top: -4px;
            height: 35px; } }
      .product-wrapper .product-logo span a {
        font-size: 12px;
        font-weight: 500;
        color: #333;
        padding-left: 35px; }
        @media (min-width: 992px) {
          .product-wrapper .product-logo span a {
            font-size: 18px; } }
  .product-wrapper p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 25px;
    margin-top: 20px;
    text-align: center;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .product-wrapper p {
        font-family: "Montserrat", sans-serif;
        font-size: 19px;
        line-height: 30px;
        margin-top: 0;
        margin-bottom: 25px;
        text-align: left; } }
  .product .product-img {
    text-align: center; }
    @media (min-width: 992px) {
      .product .product-img {
        text-align: right; } }
  @media (min-width: 992px) {
    .product .btn_wrapper {
      margin-top: 20px; } }
  .product.product-section-new {
    background: linear-gradient(152deg, #07b260 0%, #051b39 60%); }
    .product.product-section-new h2 {
      color: #fff; }
    .product.product-section-new p {
      color: #fff; }

.people {
  padding: 15px 0 20px; }
  @media (min-width: 992px) {
    .people {
      padding-top: 20px;
      padding-bottom: 50px; } }
  .people h2 {
    margin: 25px 0;
    text-align: center;
    font-size: 30px; }
    @media (min-width: 992px) {
      .people h2 {
        margin-top: 50px;
        margin-bottom: 15px;
        font-size: 48px; } }
  .people .leader-para {
    font-size: 16px;
    line-height: 24px;
    text-align: center; }
    @media (min-width: 992px) {
      .people .leader-para {
        font-size: 20px;
        line-height: 32px;
        max-width: 990px;
        margin: 0 auto 30px; } }
  .people .team {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    font-size: 14px;
    line-height: 26px;
    padding: 30px 0;
    margin-bottom: 0;
    transition: all 0.3s ease-out;
    min-height: auto;
    text-align: center;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .people .team {
        margin-bottom: 25px;
        min-height: 380px;
        font-size: 18px;
        font-family: "Montserrat", sans-serif; } }
    .people .team:hover {
      border: 1px solid #fff;
      box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.1); }
    .people .team-img-holder {
      width: 178px;
      height: 178px;
      margin: 0 auto 20px;
      position: relative; }
    .people .team figure {
      display: inline-block;
      border: 20px solid #f7f7f7;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 30px; }
      .people .team figure img {
        width: 158px; }
    .people .team span {
      font-size: 20px;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      display: block; }

.about-contact {
  padding: 50px 0;
  text-align: center; }
  @media (min-width: 992px) {
    .about-contact {
      padding-top: 70px;
      padding-bottom: 80px; } }
  .about-contact h2 {
    margin-bottom: 25px;
    font-size: 30px; }
    @media (min-width: 992px) {
      .about-contact h2 {
        font-size: 48px; } }

.work-slider .slick-prev {
  position: absolute;
  background: url(/images/common/left-icon.png) no-repeat 0 0;
  left: 10px;
  top: 15px;
  font-size: 0;
  width: 14px;
  height: 26px; }

.work-slider .slick-next {
  position: absolute;
  background: url(/images/common/right-icon.png) no-repeat 0 0;
  right: 0;
  top: 15px;
  font-size: 0;
  width: 14px;
  height: 26px; }

.about-us .o-clients {
  border-bottom: 0;
  padding: 42px 0; }
  @media (min-width: 992px) {
    .about-us .o-clients {
      padding: 65px 0 65px 25px; } }
  .about-us .o-clients h2 {
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .about-us .o-clients h2 {
        margin: 0 0 20px 0; } }
  .about-us .o-clients .o-clients__list {
    position: relative; }
    .about-us .o-clients .o-clients__list:before {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -35px;
      margin: 0 auto;
      background: #ccc;
      height: 1px;
      width: 140px; }
      @media (min-width: 992px) {
        .about-us .o-clients .o-clients__list:before {
          display: none; } }

.about-us #myModal1 button.close {
  z-index: 999;
  position: absolute;
  right: 0 !important;
  top: 0 !important;
  padding: 5px 0;
  opacity: 1;
  width: 35px;
  text-align: center;
  background: #fff !important;
  float: right; }

.about-us .o-skills-hero {
  padding-bottom: 50px !important;
  padding-top: 100px !important;
  position: relative; }
  @media (min-width: 992px) {
    .about-us .o-skills-hero {
      padding: 135px 20px 45px !important; } }
  .about-us .o-skills-hero h2 {
    padding-bottom: 0;
    margin-top: 5px !important;
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 10px; }
    @media screen and (min-width: 992px) {
      .about-us .o-skills-hero h2 {
        padding: 10px 0 20px;
        margin-top: 0 !important;
        font-size: 59px;
        line-height: 70px;
        margin-bottom: 20px; } }

.about-us ul.srv-bnr-logo li:nth-child(5), .about-us ul.srv-bnr-logo li:nth-child(6) {
  display: none; }
  @media (min-width: 992px) {
    .about-us ul.srv-bnr-logo li:nth-child(5), .about-us ul.srv-bnr-logo li:nth-child(6) {
      display: block; } }

.about-us ul.srv-bnr-logo li:nth-child(4) {
  display: block !important; }

.about-us ul.srv-bnr-logo li {
  flex: 0 0 33%;
  margin-bottom: 15px; }
  @media (min-width: 992px) {
    .about-us ul.srv-bnr-logo li {
      flex: 0 0 auto !important;
      margin-bottom: inherit; } }
  @media (min-width: 992px) {
    .about-us ul.srv-bnr-logo li:nth-child(1) {
      flex: 0 0 161px; } }
  @media screen and (min-width: 992px) {
    .about-us ul.srv-bnr-logo li:nth-child(2) {
      flex: 0 0 150px; } }
  @media screen and (min-width: 1200px) {
    .about-us ul.srv-bnr-logo li:nth-child(2) {
      flex: 0 0 170px; } }
  .about-us ul.srv-bnr-logo li:nth-child(3) {
    flex: 0 0 33%; }
    @media screen and (min-width: 992px) {
      .about-us ul.srv-bnr-logo li:nth-child(3) {
        flex: 0 0 150px; } }
    @media screen and (min-width: 1200px) {
      .about-us ul.srv-bnr-logo li:nth-child(3) {
        flex: 0 0 140px; } }
  @media (min-width: 992px) {
    .about-us ul.srv-bnr-logo li:nth-child(4) {
      flex: 0 0 115px; } }
  @media screen and (min-width: 992px) {
    .about-us ul.srv-bnr-logo li:nth-child(5) {
      flex: 0 0 150px; } }
  @media screen and (min-width: 1200px) {
    .about-us ul.srv-bnr-logo li:nth-child(5) {
      flex: 0 0 190px; } }
  @media screen and (min-width: 992px) {
    .about-us ul.srv-bnr-logo li:nth-child(6) {
      flex: 0 0 150px; } }
  @media screen and (min-width: 1200px) {
    .about-us ul.srv-bnr-logo li:nth-child(6) {
      flex: 0 0 175px; } }

.about-us .skill-page-mod.abt-bnr-logo {
  margin-bottom: 35px !important; }
  @media (min-width: 992px) {
    .about-us .skill-page-mod.abt-bnr-logo {
      margin-bottom: 65px !important; } }

.about-us .why-details ul li:nth-child(7) {
  border: 0;
  background: #091b38; }
  @media (min-width: 992px) {
    .about-us .why-details ul li:nth-child(7) {
      background: #1d2942; } }

.about-us .why-details ul li:nth-child(8) {
  border: 1px solid #4a90e2;
  background: none; }

.about-us .why-details {
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .about-us .why-details {
      margin-bottom: 45px; } }

.about-us .o-clients ul {
  margin-bottom: 0; }
  @media (min-width: 992px) {
    .about-us .o-clients ul {
      margin-bottom: 20px; } }

.about-us .new-service .slick-slide {
  padding: 15px 5px; }
  @media (min-width: 992px) {
    .about-us .new-service .slick-slide {
      padding: 0; } }

.about-us .new-service .cmn-heading-para {
  font-family: "Montserrat", sans-serif; }

ul.abt-bnr-logo {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 30px; }
  @media (min-width: 992px) {
    ul.abt-bnr-logo {
      justify-content: space-evenly;
      margin-bottom: 60px; } }
  ul.abt-bnr-logo svg {
    overflow: visible; }
  ul.abt-bnr-logo li {
    flex: 0 0 33%;
    padding: 0 5px; }
    @media (min-width: 992px) {
      ul.abt-bnr-logo li {
        flex: 1;
        padding: 0 10px; } }
    ul.abt-bnr-logo li a {
      height: 100px;
      display: block; }
      ul.abt-bnr-logo li a img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin: 0;
        padding: 0 !important; }

.w-clutch {
  width: 123px !important; }

ul.srv-bnr-logo {
  justify-content: center; }
  @media screen and (min-width: 992px) {
    ul.srv-bnr-logo {
      justify-content: space-around; } }
  ul.srv-bnr-logo li {
    flex: 0 0 25%; }
    @media (min-width: 992px) {
      ul.srv-bnr-logo li {
        flex: 0 0 200px; } }

.skill-page-mod {
  margin-bottom: 35px !important;
  align-items: center; }
  @media (min-width: 992px) {
    .skill-page-mod {
      margin-bottom: 35px !important; } }
  .skill-page-mod li a {
    height: auto !important; }

ul.skill-page-mod {
  margin-top: 0; }
  @media (min-width: 992px) {
    ul.skill-page-mod {
      margin-top: 10px;
      margin-bottom: 35px; } }
  @media (min-width: 992px) {
    ul.skill-page-mod li {
      flex: 1; } }
  ul.skill-page-mod li:nth-child(3) {
    flex: 0 0 65px; }
    @media (min-width: 992px) {
      ul.skill-page-mod li:nth-child(3) {
        flex: 0 0 100px; } }
  ul.skill-page-mod li:before {
    display: none; }
  ul.skill-page-mod.mobile-off {
    display: none; }
    @media (min-width: 992px) {
      ul.skill-page-mod.mobile-off {
        display: flex; } }

.pricings-section h4 {
  text-align: center;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 30px; }
  @media (min-width: 992px) {
    .pricings-section h4 {
      font-size: 36px;
      margin-bottom: 40px; } }

.graph {
  background: #fff;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
  border: 1px solid #e9e8ec;
  border-radius: 8px;
  text-align: center;
  padding: 25px 15px;
  margin-bottom: 35px; }
  @media (min-width: 992px) {
    .graph {
      padding: 40px 15px;
      margin-bottom: 50px;
      border: 1px solid #e1dfe6;
      width: 100%; } }
  .graph ul {
    margin-top: 5px;
    padding-left: 40px; }
    @media screen and (min-width: 768px) {
      .graph ul {
        max-width: 300px;
        margin: 5px auto; } }
    @media screen and (min-width: 992px) {
      .graph ul {
        margin-top: 35px;
        padding-left: 25px; } }
    .graph ul li {
      text-align: left;
      margin-bottom: 5px;
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      font-weight: 500;
      position: relative;
      padding-left: 20px; }
      @media (min-width: 992px) {
        .graph ul li {
          font-size: 17px;
          line-height: 24px;
          margin-bottom: 0;
          font-weight: 600;
          margin-bottom: 2px; } }
      .graph ul li:before {
        content: "";
        position: absolute;
        top: 8px;
        left: 0;
        background: #000;
        width: 7px;
        height: 7px;
        border-radius: 50%; }
        @media (min-width: 992px) {
          .graph ul li:before {
            width: 9px;
            height: 9px; } }
      .graph ul li.g-color1:before {
        background: #2dbe70; }
      .graph ul li.g-color2:before {
        background: #3775bd; }
      .graph ul li.g-color3:before {
        background: #d85f0e; }
      .graph ul li.g-color4:before {
        background: #d99f00; }
      .graph ul li.g-color5:before {
        background: #61a2bd; }
  .graph span svg {
    width: 175px; }
    @media (min-width: 992px) {
      .graph span svg {
        width: auto; } }
  .graph .graph-3 {
    padding-left: 0;
    max-width: 300px;
    margin: 5px auto 0; }
    @media (min-width: 992px) {
      .graph .graph-3 {
        padding-left: 25px;
        max-width: inherit;
        margin: 35px 0 0; } }
    .graph .graph-3 li {
      flex: 0 0 50%; }

.addr-section:after {
  display: none; }
  @media (min-width: 992px) {
    .addr-section:after {
      display: block; } }

.product-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse; }
  @media (min-width: 992px) {
    .product-wrapper {
      display: block; } }

@media (min-width: 992px) {
  .skill-pad-left {
    padding-left: 30px; } }

.client-heading {
  font-size: 30px;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  display: block; }
  @media (min-width: 992px) {
    .client-heading {
      font-size: 48px;
      margin-bottom: 15px; } }

.cstm-svg {
  margin: 15px 0; }
  @media (min-width: 992px) {
    .cstm-svg {
      width: 204px;
      height: 206px;
      margin: 0; } }

.world-map img {
  margin: 0 auto; }

.tech .home-link {
  width: 205px;
  margin: 0 auto; }

.o-home-technology .home-link {
  width: 210px;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .o-home-technology .home-link {
      margin: 10px auto 0; } }

.o-home-service .home-link {
  width: 210px;
  margin: 0 auto 0; }

.skill-page-mod-pro {
  width: 100%; }

.profile-our-story-text-content h2 {
  text-align: left;
  margin-bottom: 30px; }

.profile-our-story-text-content p {
  font-size: 18px;
  line-height: 30px; }

.service-heading-text-area h2 {
  margin-bottom: 15px; }

.service-heading-text-area p {
  font-size: 18px;
  text-align: center; }

.year-award-wrapper {
  display: flex;
  flex-wrap: wrap; }
  .year-award-wrapper h2 {
    width: 100%;
    text-align: center;
    font-weight: 600; }
    @media (min-width: 992px) {
      .year-award-wrapper h2 {
        width: 30%;
        text-align: left; } }
  .year-award-wrapper .year-award-content {
    width: 70%;
    position: relative; }
    .year-award-wrapper .year-award-content h3 {
      padding-bottom: 40px;
      margin: 0 15px; }
      .year-award-wrapper .year-award-content h3::after {
        content: "";
        position: absolute;
        width: 170px;
        height: 3px;
        background-color: #47c281;
        bottom: 20px;
        left: 0; }
      .year-award-wrapper .year-award-content h3.award-21::after {
        background-color: #4a90e2; }
      .year-award-wrapper .year-award-content h3.award-20::after {
        background-color: #000; }
    .year-award-wrapper .year-award-content .year-award-img-section {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px;
      align-items: center; }
      .year-award-wrapper .year-award-content .year-award-img-section .year-award-img {
        padding: 15px; }

.sequrity-matter-banner {
  background-color: #063d72;
  padding-top: 50px; }
  @media screen and (min-width: 992px) {
    .sequrity-matter-banner {
      padding-top: 0; } }
  .sequrity-matter-banner .sequrity-matter-content {
    position: relative;
    z-index: 999;
    margin: 25px 0 50px;
    text-align: center; }
    @media (min-width: 992px) {
      .sequrity-matter-banner .sequrity-matter-content {
        margin: 70px 50px;
        text-align: left; } }
    .sequrity-matter-banner .sequrity-matter-content h2 {
      color: #fff;
      font-weight: 500;
      line-height: 54px;
      margin-bottom: 15px; }
    .sequrity-matter-banner .sequrity-matter-content p {
      font-size: 18px;
      color: #fff;
      line-height: 30px; }
    .sequrity-matter-banner .sequrity-matter-content .btn {
      margin: 0 auto; }
      @media (min-width: 992px) {
        .sequrity-matter-banner .sequrity-matter-content .btn {
          margin: 0; } }
  .sequrity-matter-banner img {
    width: 180px;
    margin: 0 auto;
    display: block; }
    @media screen and (min-width: 992px) {
      .sequrity-matter-banner img {
        width: auto;
        margin: 0;
        display: inherit; } }

.office-infrastucture-img-wrapper {
  display: flex; }
  .office-infrastucture-img-wrapper .office-infrastucture-img {
    margin: 10px; }

.company-service-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 20px;
  justify-content: center;
  width: 100%; }
  @media screen and (min-width: 992px) {
    .company-service-wrap {
      margin: 55px auto;
      width: 80%; } }
  .company-service-wrap a {
    flex-basis: 100%;
    margin: 15px;
    background-color: #fff;
    border-radius: 5px;
    padding: 40px;
    text-align: center; }
    @media (min-width: 992px) {
      .company-service-wrap a {
        flex-basis: 30%; } }
    .company-service-wrap a.ecommerce-service-box {
      padding: 40px 5px; }
    .company-service-wrap a h4 {
      margin-bottom: 0;
      font-size: 18px; }
    .company-service-wrap a:nth-child(even) {
      border-top: 3px solid #4a90e2; }
    .company-service-wrap a:nth-child(odd) {
      border-top: 3px solid #49c684; }
  @media screen and (min-width: 992px) {
    .company-service-wrap .profile-service-box-img {
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center; } }

.profile-tabs {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  overflow: hidden;
  margin: 40px auto;
  border-radius: 5px;
  border: none; }
  @media (min-width: 992px) {
    .profile-tabs {
      width: 90%;
      border: 1px solid #ddd; } }
  .profile-tabs .tab-profile-header {
    display: none; }
    @media (min-width: 992px) {
      .profile-tabs .tab-profile-header {
        width: 25%;
        border-right: 1px solid #ddd;
        display: block; } }
    .profile-tabs .tab-profile-header ul li {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 25px 15px;
      border-left: 3px solid transparent;
      font-size: 16px;
      cursor: pointer;
      font-weight: 500;
      transition: 0.4s all ease-in-out; }
      .profile-tabs .tab-profile-header ul li.active {
        border-left: 3px solid #2dbe70;
        background-color: #ecf9f9; }
      @media screen and (min-width: 992px) {
        .profile-tabs .tab-profile-header ul li:hover {
          background-color: #ecf9f9;
          border-left: 3px solid #2dbe70; } }
  .profile-tabs .tab-profile-content {
    background-color: #fff;
    flex: 1;
    position: relative;
    width: 100%; }
    @media (min-width: 992px) {
      .profile-tabs .tab-profile-content {
        width: 75%;
        background-color: #f2f2f2; } }
    .profile-tabs .tab-profile-content div {
      position: relative;
      opacity: 1;
      top: auto;
      left: auto; }
      @media (min-width: 992px) {
        .profile-tabs .tab-profile-content div {
          position: absolute;
          top: -50vh;
          left: 100px;
          opacity: 0; } }
    .profile-tabs .tab-profile-content .tab-profile-content-area {
      width: 100%; }
      .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-wrap,
      .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-title {
        position: static;
        opacity: 1; }
      .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-title {
        display: block;
        background: #ecfaf9;
        padding: 15px 20px;
        margin-bottom: 10px; }
        @media (min-width: 992px) {
          .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-title {
            display: none;
            padding: 10px 20px; } }
        .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-title h2 {
          font-size: 16px;
          line-height: 21px;
          margin-bottom: 0;
          font-weight: 500;
          display: flex;
          align-items: center;
          padding-right: 20px; }
          .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-title h2::after {
            content: "\f107";
            font-family: "Font Awesome 5 Free";
            position: absolute;
            font-weight: 600;
            right: 0; }
      .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-wrap {
        display: none; }
      .profile-tabs .tab-profile-content .tab-profile-content-area ul {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px; }
        @media screen and (min-width: 992px) {
          .profile-tabs .tab-profile-content .tab-profile-content-area ul {
            margin-bottom: 0; } }
        .profile-tabs .tab-profile-content .tab-profile-content-area ul li {
          width: 33.33%; }
          @media screen and (min-width: 768px) {
            .profile-tabs .tab-profile-content .tab-profile-content-area ul li {
              width: 20%; } }
          @media screen and (min-width: 992px) {
            .profile-tabs .tab-profile-content .tab-profile-content-area ul li {
              width: 16.6%; } }
          .profile-tabs .tab-profile-content .tab-profile-content-area ul li a {
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background: #fff;
            padding: 15px 5px;
            border-radius: 5px;
            border: 1px solid #e1dfe6;
            max-width: 100%;
            margin: 10px;
            height: 75px;
            -o-object-fit: contain;
            object-fit: contain;
            -webkit-transition: all 0.4s linear;
            transition: all 0.4s linear; }
            @media screen and (min-width: 992px) {
              .profile-tabs .tab-profile-content .tab-profile-content-area ul li a {
                padding: 15px 20px; } }
            .profile-tabs .tab-profile-content .tab-profile-content-area ul li a img {
              width: 100%;
              margin: 0 auto;
              height: 100%;
              object-fit: contain; }
              @media screen and (min-width: 992px) {
                .profile-tabs .tab-profile-content .tab-profile-content-area ul li a img {
                  height: auto; } }
              .profile-tabs .tab-profile-content .tab-profile-content-area ul li a img.img-resize {
                width: 40%; }
              .profile-tabs .tab-profile-content .tab-profile-content-area ul li a img.img-resize-new {
                width: 50%; }
              .profile-tabs .tab-profile-content .tab-profile-content-area ul li a img.resize-img-new {
                width: 60%; }
              .profile-tabs .tab-profile-content .tab-profile-content-area ul li a img.resize-img {
                width: 100%; }
            .profile-tabs .tab-profile-content .tab-profile-content-area ul li a:hover {
              box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2); }
      .profile-tabs .tab-profile-content .tab-profile-content-area p {
        margin-bottom: 0;
        padding: 0 11px;
        font-size: 16px; }
      .profile-tabs .tab-profile-content .tab-profile-content-area.active {
        top: 0;
        left: 0;
        opacity: 1;
        padding: 0;
        background: #f2f2f2;
        margin-bottom: 10px; }
        @media (min-width: 992px) {
          .profile-tabs .tab-profile-content .tab-profile-content-area.active {
            padding: 20px; } }
        .profile-tabs .tab-profile-content .tab-profile-content-area.active .tab-profile-content-wrap {
          display: block; }
        .profile-tabs .tab-profile-content .tab-profile-content-area.active h2::after {
          transform: rotate(-180deg); }

.hq-slider-new {
  position: relative;
  z-index: 999; }
  .hq-slider-new ul {
    display: flex;
    flex-wrap: wrap; }
    .hq-slider-new ul li {
      padding: 10px; }
      .hq-slider-new ul li a {
        height: 100%;
        display: block; }
        .hq-slider-new ul li a img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .hq-slider-new ul li.hq-slider-small-img {
        width: 100%; }
        @media (min-width: 992px) {
          .hq-slider-new ul li.hq-slider-small-img {
            width: 25%; } }
      .hq-slider-new ul li.hq-slider-big-img {
        width: 100%; }
        @media (min-width: 992px) {
          .hq-slider-new ul li.hq-slider-big-img {
            width: 50%; } }
  .hq-slider-new .slick-dots {
    margin: 15px auto; }
    .hq-slider-new .slick-dots li {
      padding: 5px; }

.lets-talk {
  margin-top: 0; }

.client-logo-new {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  .client-logo-new .slick-dots {
    height: auto;
    margin-top: 0; }
    .client-logo-new .slick-dots li {
      flex: none; }
      .client-logo-new .slick-dots li:nth-child(1) {
        flex: none; }

.profile-our-story-img img {
  display: block;
  margin: 0 auto;
  width: 180px; }
  @media screen and (min-width: 992px) {
    .profile-our-story-img img {
      display: inherit;
      margin: 0;
      width: inherit; } }

.clientLogoSlider .slick-track {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto; }
  @media screen and (min-width: 992px) {
    .clientLogoSlider .slick-track {
      display: inherit;
      margin: inherit; } }

.clientLogoSlider .slick-slide {
  display: flex !important;
  justify-content: center; }
  @media screen and (min-width: 992px) {
    .clientLogoSlider .slick-slide {
      display: inherit !important; } }

.pointer-disable {
  pointer-events: none; }

.slbWrapOuter {
  height: calc(100vh - 64px);
  top: 64px; }

.pointer-disable-modify {
  pointer-events: none; }

.tab-profile-content-wrap .resize-img-new.resize-img-new-iso {
  width: 70% !important; }

.new-clientLogoSlider .owl-stage-outer {
  margin: 0; }
  .new-clientLogoSlider .owl-stage-outer .owl-stage {
    display: flex;
    justify-content: center;
    align-items: center; }
    .new-clientLogoSlider .owl-stage-outer .owl-stage .owl-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      opacity: 0;
      height: 100px; }
      .new-clientLogoSlider .owl-stage-outer .owl-stage .owl-item.active {
        display: flex;
        opacity: 1; }

.new-clientLogoSlider .owl-dots {
  text-align: center;
  margin-bottom: 15px;
  margin-top: 30px; }
  .new-clientLogoSlider .owl-dots .owl-dot {
    width: 7px;
    height: 7px;
    display: inline-block;
    vertical-align: middle;
    background: #9b9b9b;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer; }
    .new-clientLogoSlider .owl-dots .owl-dot.active {
      background: #49c684;
      width: 10px;
      height: 10px; }

@media screen and (min-width: 992px) {
  .clientLogoSlider-box {
    margin: 10px 25px; } }

.profile-redirection-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2; }

span.linkid-in {
  position: absolute;
  right: 12px;
  bottom: 40px; }

@media (min-width: 992px) {
  .o-awards--skill-salesforce {
    padding: 60px 0 40px; } }

.follow-pr {
  padding: 45px 0 20px; }
  @media (min-width: 992px) {
    .follow-pr {
      padding: 70px 0 50px; } }

.o-services-intro-2 .text-bg {
  border-radius: 8px;
  box-shadow: 0px 0px 27px 0px #e3e3e3;
  padding: 30px 30px 10px;
  margin-bottom: 25px;
  position: relative;
  text-align: left;
  max-width: none;
  border: 1px solid #E0E0E0;
  border-radius: 6px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1); }
  @media (min-width: 992px) {
    .o-services-intro-2 .text-bg {
      padding: 50px 60px 30px;
      margin-bottom: 60px;
      max-width: 515px; } }

.how-it-works-holder {
  text-align: center;
  margin: 20px 0 5px; }
  @media screen and (min-width: 992px) {
    .how-it-works-holder {
      background: none;
      text-align: left;
      margin: 50px 0; } }
  @media screen and (min-width: 1200px) {
    .how-it-works-holder {
      background: #fff url(/images/common/divider.png) 50% 0 repeat-y; }
      .how-it-works-holder.holder-bg-grey {
        background: transparent url(/images/common/w-divider.png) 50% 0 repeat-y; }
        .how-it-works-holder.holder-bg-grey .text-bg {
          background: #fff; } }
  @media (min-width: 992px) {
    .how-it-works-holder .custom-right {
      float: right; } }
  .how-it-works-holder .text-bg {
    position: relative; }
    .how-it-works-holder .text-bg:before {
      position: absolute;
      top: 50px;
      width: 30px;
      height: 30px;
      content: "";
      border-radius: 100%;
      background-color: #fff;
      display: none !important; }
      @media screen and (min-width: 992px) {
        .how-it-works-holder .text-bg:before {
          display: none !important; } }
      @media screen and (min-width: 1200px) {
        .how-it-works-holder .text-bg:before {
          display: block !important; } }
    .how-it-works-holder .text-bg:after {
      content: '';
      position: absolute;
      top: 20px;
      right: 20px;
      width: 90px;
      height: 90px;
      display: block;
      background-size: 100% !important; }
      @media (min-width: 992px) {
        .how-it-works-holder .text-bg:after {
          display: none; } }
  .how-it-works-holder .text-bg-1:after {
    background: url(/images/service/how-it-works-icon-1.png) no-repeat 0 0; }
  .how-it-works-holder .text-bg-2:after {
    background: url(/images/service/how-it-works-icon-2.png) no-repeat 0 0; }
  .how-it-works-holder .text-bg-3:after {
    background: url(/images/service/how-it-works-icon-3.png) no-repeat 0 0; }
  .how-it-works-holder .text-bg-4:after {
    background: url(/images/service/how-it-works-icon-4.png) no-repeat 0 0; }
  .how-it-works-holder .text-bg-4 h3 {
    width: 118px;
    margin-top: 10px; }
    @media (min-width: 992px) {
      .how-it-works-holder .text-bg-4 h3 {
        width: 100%;
        margin-top: 0; } }
  .how-it-works-holder .text-bg-5:after {
    background: url(/images/service/how-it-works-icon-5.png) no-repeat 0 0; }
  .how-it-works-holder .text-bg-5 h3 {
    width: 115px;
    margin-top: 10px; }
    @media (min-width: 992px) {
      .how-it-works-holder .text-bg-5 h3 {
        width: 100%;
        margin-top: 0; } }
  .how-it-works-holder .text-bg-1:before {
    left: -70px;
    display: block;
    border: 4px solid #3E93DA;
    top: 0; }
  .how-it-works-holder .text-bg-2:before {
    right: -70px;
    border: 4px solid #24C683; }
    @media (min-width: 992px) {
      .how-it-works-holder .text-bg-2:before {
        display: block; } }
  .how-it-works-holder .text-bg-3:before {
    left: -70px;
    display: block;
    border: 4px solid #FA8B00; }
  .how-it-works-holder .text-bg-4:before {
    right: -70px;
    border: 4px solid #5EACF9; }
    @media (min-width: 992px) {
      .how-it-works-holder .text-bg-4:before {
        display: block; } }
  .how-it-works-holder .text-bg-5:before {
    left: -70px;
    border: 4px solid #14df7d; }
    @media (min-width: 992px) {
      .how-it-works-holder .text-bg-5:before {
        display: block; } }
  .how-it-works-holder span {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
    display: inline-block; }
    @media (min-width: 992px) {
      .how-it-works-holder span {
        font-size: 36px; } }
  .how-it-works-holder span.blue {
    color: #5eacf9; }
  .how-it-works-holder span.green {
    color: #14DF7D; }
  .how-it-works-holder span.orange {
    color: #E76C00; }
  .how-it-works-holder span.light-blue {
    color: #5EACF9; }
  .how-it-works-holder h3 {
    font-size: 20px;
    width: 150px;
    line-height: 24px;
    margin-top: 10px; }
    @media (min-width: 992px) {
      .how-it-works-holder h3 {
        font-size: 24px;
        line-height: 32px;
        width: 100%;
        margin-top: 0; } }
  .how-it-works-holder p {
    font-size: 16px;
    line-height: 28px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500; }
    @media (min-width: 992px) {
      .how-it-works-holder p {
        font-size: 19px;
        line-height: 30px;
        font-family: "Montserrat", sans-serif;
        font-weight: 400; } }
    .how-it-works-holder p span.anchor-link {
      max-width: 100%;
      font-size: 13px;
      line-height: 22px;
      color: #333;
      text-decoration: none;
      text-align: left;
      margin-top: 20px; }
    .how-it-works-holder p a.anchor-link {
      text-align: left;
      display: block;
      max-width: 100%;
      margin-top: 0; }
      @media screen and (min-width: 992px) {
        .how-it-works-holder p a.anchor-link {
          display: inherit;
          max-width: 325px; } }
  @media (min-width: 992px) {
    .how-it-works-holder img {
      margin: 50px auto 0;
      display: block; } }
  .how-it-works-holder picture {
    display: none; }
    @media (min-width: 992px) {
      .how-it-works-holder picture {
        display: block; } }

@media screen and (min-width: 1200px) {
  .w-divider {
    background: url(/images/common/w-divider.png) 50% 0 repeat-y; } }

@media (min-width: 992px) {
  .how-we-work-bnr {
    padding-bottom: 300px !important; } }

.how-we-work-bnr h1 {
  font-size: 24px;
  line-height: 28px;
  text-transform: capitalize;
  padding-bottom: 0;
  margin-bottom: 0; }
  @media (min-width: 992px) {
    .how-we-work-bnr h1 {
      font-size: 48px;
      line-height: 56px;
      padding-bottom: 0;
      margin-bottom: .5rem; } }

.how-we-work-bnr h2 {
  font-size: 16px;
  line-height: 28px;
  color: #36E079;
  font-family: "Montserrat", sans-serif;
  margin-top: 20px !important; }
  @media (min-width: 992px) {
    .how-we-work-bnr h2 {
      font-size: 20px;
      line-height: 33px;
      margin-top: 25px !important; } }

.how-we-work-bnr .pm-img {
  display: flex;
  align-items: center;
  justify-content: space-evenly; }
  @media (min-width: 992px) {
    .how-we-work-bnr .pm-img {
      display: none; } }
  .how-we-work-bnr .pm-img li  {
    font-size: 16px;
    padding-left: 28px;
    padding-bottom: 15px;
    padding-left: 0; }
    .how-we-work-bnr .pm-img li :before {
      display: none; }
    .how-we-work-bnr .pm-img li  img {
      display: block;
      margin: 0 auto 10px;
      padding-bottom: 0;
      padding-top: 0; }
    .how-we-work-bnr .pm-img li  h5 {
      color: #fff;
      font-size: 15px;
      font-weight: 600 !important;
      line-height: 15px; }
    .how-we-work-bnr .pm-img li  small {
      font-size: 10px;
      color: #CBE3FF;
      font-weight: 500 !important; }

.how-we-work-bnr .header_left_pan ul li:before {
  display: none; }

@media (min-width: 992px) {
  .work-bnr h1 {
    text-align: left; } }

@media (min-width: 992px) {
  .work-bnr .btn_wrapper a {
    margin: 0; } }

@media (min-width: 992px) {
  .work-bnr .anchor-link {
    text-align: left; } }

.o-two-col-section__intro .col-md-10 {
  margin: 0 auto !important; }

@media (hover: hover) and (pointer: fine) {
  .o-two-col-section__key-traits ul li .style_prevu_kit:hover .icon-text-hover {
    text-align: left;
    color: #fff;
    padding: 20px; }
    .o-two-col-section__key-traits ul li .style_prevu_kit:hover .icon-text-hover h3 {
      text-align: left;
      color: #fff; }
    .o-two-col-section__key-traits ul li .style_prevu_kit:hover .icon-text-hover p {
      text-align: left;
      color: #fff;
      margin-top: 10px; } }

.o-two-col-section__choosing-steps h2 {
  text-align: center; }

.o-skill-intro--cn-uses-ai .col-md-10 {
  margin: 0 auto; }

.project-management .col-md-10 {
  margin: 0 auto; }

@media (min-width: 992px) {
  .u-align-left {
    text-align: left; } }

.o-skill-intro--conclusion .col-md-10 {
  margin: 0 auto; }

@media (min-width: 992px) {
  .o-skills-hero--cn-uses-ai .btn_wrapper a {
    margin: 0; } }

@media (min-width: 992px) {
  .o-skills-hero--cn-uses-ai .anchor-link {
    text-align: left; } }

.benefit-slider .slick-dots {
  text-align: center;
  margin: 15px 0 30px; }
  .benefit-slider .slick-dots li {
    display: inline-block;
    border-radius: 50%;
    text-indent: -10000px;
    width: 7px;
    height: 7px;
    background-color: #9B9B9B;
    vertical-align: middle;
    margin: 0 6px; }
    .benefit-slider .slick-dots li.slick-active {
      width: 10px;
      height: 10px;
      background: #49C684; }

.cheaper-batter-header {
  position: relative; }
  .cheaper-batter-header .o-services-hero__background-1 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%; }
  .cheaper-batter-header .container {
    position: relative;
    z-index: 1; }
  .cheaper-batter-header .c-b-heading {
    text-align: center; }

.why-matters .col-md-8 {
  margin: 0 auto; }

.why-matters .extra-h4 {
  font-weight: 400;
  margin: 0px 0px 15px 0px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 28px; }
  @media (min-width: 992px) {
    .why-matters .extra-h4 {
      font-weight: 300;
      font-size: 22px;
      line-height: 28px;
      font-family: "Montserrat", sans-serif;
      margin: 0px 0px 35px 0px; } }

.why-matters .padding-0 {
  padding: 0; }

.cb-wecare .row {
  padding: 0 15px; }
  @media (min-width: 992px) {
    .cb-wecare .row {
      padding: 0; } }
  .cb-wecare .row.cb-wecare-box1, .cb-wecare .row.cb-wecare-box2 {
    padding: 50px 15px 10px;
    border-radius: 5px;
    margin: 15px 0; }
    @media (min-width: 992px) {
      .cb-wecare .row.cb-wecare-box1, .cb-wecare .row.cb-wecare-box2 {
        margin: 0 -15px;
        border-radius: 0;
        padding: 50px 30px 10px; } }
  @media (min-width: 992px) {
    .cb-wecare .row.cb-wecare-box2 {
      margin-top: 50px; } }

.cb-wecare .cb-wecare-heading p {
  text-align: center;
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .cb-wecare .cb-wecare-heading p {
      text-align: left;
      font-family: "Montserrat", sans-serif; } }
  .cb-wecare .cb-wecare-heading p strong {
    font-weight: 400; }
    @media (min-width: 992px) {
      .cb-wecare .cb-wecare-heading p strong {
        font-weight: 700; } }

.cb-wecare .cb-wecare-heading-f-p {
  color: #fff;
  display: none; }
  @media (min-width: 992px) {
    .cb-wecare .cb-wecare-heading-f-p {
      display: block; } }

.cb-wecare .small-wrap-top {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  align-items: flex-start; }
  @media (min-width: 992px) {
    .cb-wecare .small-wrap-top {
      margin-bottom: 0;
      align-items: inherit; } }
  .cb-wecare .small-wrap-top-img {
    flex: 0 0 50px;
    max-width: 50px;
    display: flex;
    justify-content: center; }
    @media (min-width: 992px) {
      .cb-wecare .small-wrap-top-img {
        flex: 0 0 200px;
        max-width: 200px; } }
  .cb-wecare .small-wrap-top h3 {
    flex: 1;
    font-size: 20px;
    line-height: 26px;
    padding-left: 20px; }
    @media (min-width: 992px) {
      .cb-wecare .small-wrap-top h3 {
        font-size: 36px;
        line-height: 49px;
        padding-left: 0; } }

.cb-wecare .small-wrap-text {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: -42px; }
  .cb-wecare .small-wrap-text-left {
    flex: 0 0 200px;
    max-width: 200px; }
  .cb-wecare .small-wrap-text-right {
    flex: 1; }

.How-we-work .o-skills-hero {
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .How-we-work .o-skills-hero {
      padding-bottom: 300px !important;
      padding-top: 135px !important;
      padding-bottom: 50px; } }
  .How-we-work .o-skills-hero h2 {
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .How-we-work .o-skills-hero h2 {
        font-family: "Montserrat", sans-serif;
        margin-bottom: 40px; } }
  .How-we-work .o-skills-hero .cstm-link-p {
    display: block; }

.o-skill-intro--listing .btn_wrapper {
  margin-top: 0 !important; }
  @media (min-width: 992px) {
    .o-skill-intro--listing .btn_wrapper {
      margin-top: 50px !important; } }

.Office .o-skills-hero {
  padding: 100px 0 20px !important; }
  @media (min-width: 992px) {
    .Office .o-skills-hero {
      padding: 140px 20px 45px !important; } }
  .Office .o-skills-hero h1 {
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .Office .o-skills-hero h1 {
        margin-bottom: 25px; } }

.Office .green-head-ribbon span {
  width: 92%; }
  @media (min-width: 992px) {
    .Office .green-head-ribbon span {
      width: auto; } }

.how-it-works-holder-mod .text-bg-1:after {
  background: url(/images/common/3step-icon-1.svg) no-repeat 0 0; }
  @media screen and (min-width: 992px) {
    .how-it-works-holder-mod .text-bg-1:after {
      background: none; } }

.how-it-works-holder-mod .text-bg-2:after {
  background: url(/images/common/3step-icon-2.svg) no-repeat 0 0; }
  @media screen and (min-width: 992px) {
    .how-it-works-holder-mod .text-bg-2:after {
      background: none; } }

.how-it-works-holder-mod .text-bg-3:after {
  background: url(/images/common/3step-icon-3.svg) no-repeat 0 0; }
  @media screen and (min-width: 992px) {
    .how-it-works-holder-mod .text-bg-3:after {
      background: none; } }

.rm-bnr {
  padding-bottom: 60px !important; }

.how-it-works-holder-mod p {
  padding-top: 15px; }
  @media screen and (min-width: 992px) {
    .how-it-works-holder-mod p {
      padding-left: 50px; } }

.bg-grey-mod .how-it-works-holder {
  background-color: #f5f5f5; }

.bg-grey-mod .text-bg {
  background: #fff; }

/* ANCHOR Conatct us*/
/* Contact Form Modal */
.contact-modal .modal-content {
  padding: 30px;
  background-color: #fff; }
  .contact-modal .modal-content .modal-header button.close img {
    width: 20px; }
  .contact-modal .modal-content .modal-header figure {
    width: 70px;
    height: 70px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 25px; }
    .contact-modal .modal-content .modal-header figure img {
      display: block;
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50% !important; }
    .contact-modal .modal-content .modal-header figure:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      animation: spin, 10s, linear, 0s, infinite; }
  .contact-modal .modal-content .modal-header .close {
    position: absolute;
    right: 30px;
    top: 30px;
    background-color: transparent;
    width: auto; }
  .contact-modal .modal-content .modal-header h5 {
    font-size: 18px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: left; }
  .contact-modal .modal-content .modal-header p {
    font-size: 20px;
    line-height: 33px;
    font-weight: 600;
    font-family: "Montserrat",sans-serif; }
  .contact-modal .modal-content input.form-control {
    width: 100%; }
  .contact-modal .modal-content .btn {
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    padding: 10px 0px;
    font-size: 18px;
    text-transform: uppercase;
    max-width: 158px;
    text-decoration: none;
    position: relative;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #FFFFFF;
    border: 2px solid #FF5800;
    background-color: #FF5800;
    width: 250px; }

/* ~./Contact Form Modal */
#attachedFiles {
  margin-top: 20px; }
  #attachedFiles li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 8px 15px;
    border-radius: 30px;
    background: #fafafa; }
    #attachedFiles li input {
      line-height: 20px;
      width: 87%;
      font-size: 14px; }
    #attachedFiles li button {
      background: url("/images/common/icon-delete.svg") 0 0 no-repeat;
      width: 14px;
      height: 19px;
      margin-right: 10px;
      vertical-align: middle; }

.u-name {
  background: url(/images/common/form-icon-1.png) 0 50% no-repeat;
  border-bottom: 1px solid #ccc; }

.u-email {
  background: url(/images/common/form-icon-2.png) 0 50% no-repeat;
  border-bottom: 1px solid #ccc; }

.u-phone {
  background: url(/images/common/form-icon-3.png) 0 50% no-repeat;
  border-bottom: 1px solid #ccc; }

.u-company {
  background: url(/images/common/form-icon-4.png) 0 50% no-repeat;
  border-bottom: 1px solid #ccc; }

.u-message {
  background: url(/images/common/form-icon-5.png) 0 20px no-repeat;
  border-bottom: 1px solid #ccc; }

.attach {
  background: url(/images/common/attach-icon.svg) no-repeat;
  font-family: "Montserrat", sans-serif;
  padding: 10px 20px 0 40px;
  background-position: 0 15px; }
  @media (min-width: 992px) {
    .attach {
      background-position: 0 30px;
      padding: 20px 20px 0 40px; } }
  .attach .attach-inputfile {
    display: none; }
  .attach label {
    color: blue;
    cursor: pointer;
    text-decoration: underline; }
    .attach label:hover {
      text-decoration: none; }

.common-input {
  border-bottom: 1px solid #000;
  padding: 20px 20px 20px 40px;
  color: #000;
  width: 100%;
  margin-bottom: 5px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: 16px; }
  @media (min-width: 992px) {
    .common-input {
      padding: 20px 20px 20px 40px; } }

.u-submit {
  display: block;
  text-align: center;
  border-radius: 5px;
  padding: 10px 0px;
  font-size: 15px;
  text-transform: uppercase;
  max-width: 350px;
  text-decoration: none;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #fff;
  width: 250px;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .u-submit {
      padding: 19px 0px;
      font-size: 18px;
      width: 250px; } }
  .u-submit:after {
    content: '';
    background: url(/images/common/arrow-white.png) no-repeat;
    width: 20px;
    height: 14px;
    display: inline-block;
    margin-left: 15px;
    z-index: -1;
    display: none; }

/* placeholder color */
::-webkit-input-placeholder {
  font-weight: 300; }

::-moz-placeholder {
  font-weight: 300; }

:-ms-input-placeholder {
  font-weight: 300; }

:-moz-placeholder {
  font-weight: 300; }

label.error {
  color: red;
  text-align: left;
  display: block;
  font-weight: 300; }

.mm-slideout {
  transition: transform .4s ease;
  z-index: 100; }

body[data-page="contact-us"] .o-contact-form {
  /* media query for tablet landscape */ }
  body[data-page="contact-us"] .o-contact-form--mobile-top {
    background: url("images/contact-us/contact-form-top-border-mobile.png") 0 0 repeat-x; }
  @media (min-width: 992px) {
    body[data-page="contact-us"] .o-contact-form {
      position: relative;
      box-shadow: 0px, 10px, 35px, rgba(0, 0, 0, 0.1);
      padding: 0;
      background: transparent;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px; } }
  body[data-page="contact-us"] .o-contact-form--mobile {
    text-align: center;
    display: block;
    padding-top: 66px;
    padding: 60px 0 40px 0;
    /* media query for tablet landscape */ }
    @media (min-width: 992px) {
      body[data-page="contact-us"] .o-contact-form--mobile {
        padding: 76px 0 40px 0; } }
    body[data-page="contact-us"] .o-contact-form--mobile h2 {
      font-size: 36px;
      line-height: 54px;
      /* media query for tablet landscape */ }
      @media (min-width: 992px) {
        body[data-page="contact-us"] .o-contact-form--mobile h2 {
          font-size: 48px;
          line-height: 58px; } }
    body[data-page="contact-us"] .o-contact-form--mobile h3 {
      font-size: 16px;
      font-weight: 400;
      /* media query for tablet landscape */ }
      @media (min-width: 992px) {
        body[data-page="contact-us"] .o-contact-form--mobile h3 {
          font-size: 28px;
          line-height: 35px;
          font-weight: 500; } }
    body[data-page="contact-us"] .o-contact-form--mobile h5 {
      font-size: 16px;
      font-weight: 400;
      /* media query for tablet landscape */ }
      @media (min-width: 992px) {
        body[data-page="contact-us"] .o-contact-form--mobile h5 {
          font-size: 28px;
          line-height: 35px;
          font-weight: 500; } }
    body[data-page="contact-us"] .o-contact-form--mobile h6, body[data-page="contact-us"] .o-contact-form--mobile .contact-linkedin, body[data-page="contact-us"] .o-contact-form--mobile .attach, body[data-page="contact-us"] .o-contact-form--mobile .u-company {
      display: none;
      /* media query for tablet landscape */ }
      @media (min-width: 992px) {
        body[data-page="contact-us"] .o-contact-form--mobile h6, body[data-page="contact-us"] .o-contact-form--mobile .contact-linkedin, body[data-page="contact-us"] .o-contact-form--mobile .attach, body[data-page="contact-us"] .o-contact-form--mobile .u-company {
          display: block; } }
    body[data-page="contact-us"] .o-contact-form--mobile .group .choosecountry {
      display: none;
      /* media query for tablet landscape */ }
      @media (min-width: 992px) {
        body[data-page="contact-us"] .o-contact-form--mobile .group .choosecountry {
          display: block; } }
    body[data-page="contact-us"] .o-contact-form--mobile .group input[type='text'] {
      padding: 20px 20px 20px 0;
      background: url("/assets/images/common/icon-phone.svg") 0 50% no-repeat;
      /* media query for tablet landscape */ }
      @media (min-width: 992px) {
        body[data-page="contact-us"] .o-contact-form--mobile .group input[type='text'] {
          padding: 20px 20px 20px 40px;
          background: none; } }
  body[data-page="contact-us"] .o-contact-form--contact-form-footer {
    padding: 20px 0;
    margin-top: 30px;
    text-align: left;
    /*mobile first for tablet landscape & small desktop*/ }
    @media (min-width: 992px) {
      body[data-page="contact-us"] .o-contact-form--contact-form-footer {
        text-align: center; } }
    body[data-page="contact-us"] .o-contact-form--contact-form-footer ul li {
      margin: 0 15px; }
  body[data-page="contact-us"] .o-contact-form__attachement-text {
    padding-left: 18px; }

body[data-page="contact-us"] .contact-mobile {
  display: none; }

.o-contact-form {
  /*mobile first for tablet landscape & small desktop*/
  /* Contact Form Background  */
  /* ~./Contact Form Background */
  /* Heart Animation */
  /* ~./Heart Animation */
  /* Privacy Text  */
  /* ~./Privacy Text */
  /* Attachement Text  */
  /* ~./Attachement Text */
  /* Contact Form Footer  */
  /* ~./Contact Form Footer */
  /* PSD to HTML Place your order form */
  /* ~./ PSD to HTML Place your order form */ }
  @media (min-width: 992px) {
    .o-contact-form {
      background-size: 30%;
      color: #000;
      overflow: hidden;
      text-align: center;
      position: relative; } }
  .o-contact-form h2 {
    margin-bottom: 15px;
    /*mobile first for tablet landscape & small desktop*/ }
    @media (min-width: 992px) {
      .o-contact-form h2 {
        font-size: 60px;
        letter-spacing: 2.5px;
        text-shadow: 1px 1px 0px #333, -1px 1px 0px #333, 1px -1px 0px #333, -1px -1px 0px #333; } }
  @media (min-width: 992px) {
    .o-contact-form h3 {
      font-size: 28px;
      line-height: 35px;
      font-weight: 500; } }
  .o-contact-form h3 br {
    display: none;
    /* mobile first for small desktop */ }
    @media (min-width: 992px) {
      .o-contact-form h3 br {
        display: block; } }
  @media (min-width: 992px) {
    .o-contact-form h5 {
      font-size: 28px;
      line-height: 35px;
      font-weight: 500; } }
  .o-contact-form h5 br {
    display: none;
    /* mobile first for small desktop */ }
    @media (min-width: 992px) {
      .o-contact-form h5 br {
        display: block; } }
  .o-contact-form h4 {
    font-size: 18px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: left; }
  .o-contact-form h6 {
    font-size: 18px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: left; }
  .o-contact-form--white-bg {
    /*mobile first for tablet landscape & small desktop*/ }
    @media (min-width: 992px) {
      .o-contact-form--white-bg {
        background-color: #fff;
        box-shadow: 0px, 0px, 20px, rgba(0, 0, 0, 0.2);
        padding: 80px 0 0;
        border-radius: 3px;
        overflow: hidden;
        display: block; } }
  .o-contact-form .form-animate {
    transition: 0.1s, ease-out;
    animation: formanimation, 0.3s, ease-in-out, 0s, 2; }
  .o-contact-form__heart {
    display: inline-block;
    margin-left: -4px;
    line-height: 49px;
    vertical-align: top;
    animation: pulse, 1.5s, ease-out, 0s, infinite;
    padding-left: 10px;
    padding-right: 10px;
    /* mobile first for mobiles */ }
    @media (min-width: 992px) {
      .o-contact-form__heart {
        padding-left: 20px;
        padding-right: 20px; } }
    @media (min-width: 992px) {
      .o-contact-form__heart img {
        height: 58px; } }
  .o-contact-form__privacy {
    margin: 20px 0 20px 0;
    font-size: 15px;
    text-align: center; }
    .o-contact-form__privacy i {
      margin-right: 10px; }
  .o-contact-form__attachement-text a {
    color: #4492DC;
    text-decoration: underline; }
    .o-contact-form__attachement-text a:hover {
      text-decoration: none; }
  .o-contact-form__attachement-text label {
    color: #4492DC;
    text-decoration: underline;
    cursor: pointer; }
    .o-contact-form__attachement-text label:hover {
      text-decoration: none; }
  .o-contact-form--contact-form-footer {
    background-color: #f2f2f2;
    padding: 40px 0;
    margin-top: 60px; }
    .o-contact-form--contact-form-footer ul li {
      display: inline-block;
      font-size: 16px;
      color: #000;
      margin: 0 30px; }
      .o-contact-form--contact-form-footer ul li i {
        margin-right: 20px; }
      .o-contact-form--contact-form-footer ul li strong {
        font-weight: 700; }
    .o-contact-form--contact-form-footer a {
      color: #000; }
      .o-contact-form--contact-form-footer a:hover {
        text-decoration: underline; }
    .o-contact-form--contact-form-footer .o-clients {
      padding: 30px 0 0 0; }
  .o-contact-form--place-order .tagline {
    font-size: 12px; }
  .o-contact-form--place-order .attach span {
    vertical-align: top; }

.common-input.u-message {
  background: url(/images/common/email-icon.png) 0 20px no-repeat; }

.form-inside {
  padding-top: 0; }
  @media (min-width: 992px) {
    .form-inside {
      padding-top: 30px; } }
  .form-inside p {
    font-size: 16px;
    color: #333333;
    padding-bottom: 5px !important;
    line-height: 28px;
    margin-top: 15px; }
    @media (min-width: 992px) {
      .form-inside p {
        margin-top: 0;
        font-size: 18px;
        line-height: 30px; } }
    .form-inside p a {
      color: #4a90e2; }

.bnr-form .group input[type="text"] {
  border-bottom: 1px solid #C9C8C8;
  padding: 20px 20px 20px 40px;
  color: #333;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 400; }

.bnr-form input {
  border: none;
  border-bottom: 1px solid #000 !important; }
  @media (min-width: 992px) {
    .bnr-form input {
      border-bottom: 1px solid #C9C8C8; } }

.bnr-form .u-name {
  border-bottom: 1px solid #C9C8C8;
  padding: 20px 20px 20px 40px;
  color: #333;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 400; }

.bnr-form .u-email {
  padding: 20px 20px 20px 40px;
  color: #333;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 400; }

.bnr-form .u-company {
  padding: 20px 20px 20px 40px;
  color: #333;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 400; }

.bnr-form .u-message.bnr-msg {
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
  position: relative;
  font-weight: 400; }
  @media (min-width: 992px) {
    .bnr-form .u-message.bnr-msg {
      border-bottom: 1px solid #C9C8C8; } }

.bnr-form .u-message.bnr-msg textarea {
  background: url("/images/common/form-icon-5.png") 0 20px no-repeat;
  padding: 20px 20px 20px 40px;
  color: #333;
  min-height: 100px;
  width: 100%;
  margin-bottom: 30px;
  border: none; }

.bnr-form .attach {
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: left; }

.bnr-form .attach span {
  margin-left: 10px;
  display: inline-block; }

.bnr-form .o-contact-form__attachement-text {
  font-size: 16px;
  color: #878787; }

.bnr-form .attach span {
  margin-left: 10px;
  display: inline-block;
  font-size: 15px;
  margin-bottom: 5px;
  line-height: 20px;
  margin-top: 5px; }

.bnr-form .attach {
  padding: 20px 25px 5px 25px; }

.o-contact-form__privacy {
  margin: 20px 0 20px 0;
  font-size: 15px;
  text-align: center;
  font-family: "Montserrat",sans-serif;
  font-weight: 400;
  color: #414446 !important; }
  @media (min-width: 992px) {
    .o-contact-form__privacy {
      font-size: 17px; } }

.u-message span#requirements_feedback {
  position: absolute;
  right: 25px;
  bottom: 12px;
  font-size: 14px;
  color: #333;
  font-weight: 300; }

.small-heading {
  text-align: left;
  display: block;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 35px;
  padding-left: 15px;
  display: none; }
  @media (min-width: 992px) {
    .small-heading {
      padding: 0;
      display: block; } }

input, textarea {
  font-size: 16px; }

.s-btn-modifier {
  width: 265px; }
  @media (min-width: 992px) {
    .s-btn-modifier {
      width: 478px; } }

.cntct-pg-section {
  background: #fff;
  padding-top: 20px !important; }
  @media (min-width: 992px) {
    .cntct-pg-section {
      padding: 100px 20px 60px !important; } }

.new-contact .o-contact-us .col-md-6 {
  padding: 0; }
  @media (min-width: 992px) {
    .new-contact .o-contact-us .col-md-6 {
      padding: 0 15px; } }

.new-contact .u-submit.s-btn-modifier {
  max-width: 260px; }
  @media (min-width: 992px) {
    .new-contact .u-submit.s-btn-modifier {
      max-width: 350px; } }

.new-contact .o-footer {
  padding: 0 0 40px; }
  @media (min-width: 992px) {
    .new-contact .o-footer {
      padding: 0 0 40px; } }

.cn-form {
  position: relative;
  background: #fff; }
  .cn-form:before {
    content: '';
    position: absolute;
    background: url(/images/contact-us/dashed-line.png) 0 0 repeat;
    height: 5px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0; }
  .cn-form:after {
    content: '';
    position: absolute;
    background: url(/images/contact-us/dashed-line.png) 0 0 repeat;
    height: 5px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    display: none; }
    @media (min-width: 992px) {
      .cn-form:after {
        display: block; } }
  .cn-form .row .col-md-10 {
    margin: 0 auto; }

.o-contact-us {
  padding-top: 0 !important; }
  @media (min-width: 992px) {
    .o-contact-us {
      padding-top: 0 !important; } }

.contact-addr .office ul {
  background: #fff; }

.o-contact-form__contact-form {
  padding: 0 15px; }
  @media (min-width: 992px) {
    .o-contact-form__contact-form {
      padding: 0; } }

.opt-phn {
  position: relative; }
  .opt-phn input {
    background: transparent;
    width: 100%;
    font-size: 14px; }

.url-list label {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  cursor: default; }

.url-input {
  padding: 0;
  font-size: 16px;
  display: initial;
  display: initial; }

.contact-modal .modal-content button#cloudFileAdd {
  background-color: #FD5900;
  color: #ffffff;
  font-size: 17px;
  line-height: 16px;
  height: 42px;
  padding: 10px 20px;
  text-transform: uppercase;
  display: block !important;
  width: 150px;
  margin: 0 0 0 !important;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  font-weight: 600;
  text-align: left; }
  .contact-modal .modal-content button#cloudFileAdd:after {
    content: '';
    display: inline-block;
    width: 15px;
    height: 13px;
    background: url("/images/common/arrow-white.png") 50% 50% no-repeat;
    background-size: auto;
    background-size: 15px;
    margin-left: 10px;
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%); }

.contact-modal .modal-content button#cloudFileAdd,
.contact-modal .modal-content .cloudModalFooter button {
  background-color: #FD5900;
  color: #ffffff;
  font-size: 17px;
  line-height: 16px;
  height: 42px;
  padding: 10px 20px;
  text-transform: uppercase;
  display: block !important;
  width: 150px;
  margin: 0 0 0 !important;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  font-weight: 600;
  text-align: left; }
  .contact-modal .modal-content button#cloudFileAdd:after,
  .contact-modal .modal-content .cloudModalFooter button:after {
    content: '';
    display: inline-block;
    width: 18px;
    height: 13px;
    background: url("/images/common/arrow-white.png") 50% 50% no-repeat;
    background-size: auto;
    background-size: 15px;
    margin-left: 10px;
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%); }

.contact-h1 {
  padding-left: 0;
  padding-top: 0;
  position: relative;
  font-weight: 900;
  text-transform: none;
  padding-left: 40px;
  font-size: 42px !important;
  line-height: 61px;
  color: #333 !important;
  display: inline-block;
  letter-spacing: 2.5px;
  text-shadow: none;
  margin-bottom: 15px; }
  .contact-h1 .white-text {
    position: relative;
    color: #fff !important; }
  .contact-h1:before {
    content: '';
    position: absolute;
    height: 70px;
    width: 105px;
    right: inherit;
    left: 105px;
    top: 0; }
    @media (min-width: 992px) {
      .contact-h1:before {
        right: 0;
        top: 0;
        left: inherit; } }

.drop-p {
  font-size: 18px !important;
  line-height: 33px;
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: left;
  color: #333 !important;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  margin-bottom: 0; }

.new-contact .o-contact-us {
  /* media query for tablet landscape */ }
  @media (min-width: 992px) {
    .new-contact .o-contact-us {
      padding-top: 50px;
      padding-bottom: 20px; } }
  .new-contact .o-contact-us .subheading {
    font-weight: 600; }
  .new-contact .o-contact-us .office {
    margin-bottom: 30px; }
    .new-contact .o-contact-us .office .main-img {
      position: relative;
      vertical-align: top;
      margin-bottom: 20px; }
      .new-contact .o-contact-us .office .main-img img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
        @media (min-width: 992px) {
          .new-contact .o-contact-us .office .main-img img {
            height: auto; } }
      .new-contact .o-contact-us .office .main-img h3 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 24px;
        position: absolute;
        left: 50%;
        top: 56%;
        transform: translate(-50%, -50%);
        height: 48px;
        color: #fff;
        /*text-shadow: 2px 2px #3e3d3d;*/ }
      .new-contact .o-contact-us .office .main-img .location-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: absolute;
        top: 20px;
        right: 20px;
        margin: auto;
        z-index: 99;
        text-align: center;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.2); }
        .new-contact .o-contact-us .office .main-img .location-icon img {
          width: 16px;
          height: auto;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%); }
        .new-contact .o-contact-us .office .main-img .location-icon:hover {
          background: rgba(255, 88, 0, 0.2); }
    .new-contact .o-contact-us .office ul {
      padding-bottom: 30px; }
      .new-contact .o-contact-us .office ul li {
        color: #000;
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 0; }
        @media (min-width: 992px) {
          .new-contact .o-contact-us .office ul li {
            margin-bottom: 15px;
            font-size: 16px;
            margin: 0 0 25px 0; } }
        .new-contact .o-contact-us .office ul li img {
          margin-right: 10px;
          display: none;
          vertical-align: top;
          margin-top: 0; }
          @media (min-width: 992px) {
            .new-contact .o-contact-us .office ul li img {
              display: inline-block; } }
        .new-contact .o-contact-us .office ul li .mailicon {
          width: 15px;
          padding-top: 3px;
          opacity: 0.5; }
        .new-contact .o-contact-us .office ul li span {
          display: inline-block;
          width: 88%; }
        .new-contact .o-contact-us .office ul li a {
          color: #4492DC;
          cursor: pointer;
          font-family: "Montserrat", sans-serif; }
          @media (min-width: 992px) {
            .new-contact .o-contact-us .office ul li a {
              color: #333; } }
          .new-contact .o-contact-us .office ul li a.email {
            color: #4492DC; }
          .new-contact .o-contact-us .office ul li a:hover {
            text-decoration: underline; }

.new-contact .o-contact-form--white-bg input#phoneNumber {
  background: url(/images/common/icon-phone.png) 0 50% no-repeat; }

.new-contact .s-f-right input#phoneNumber {
  background-image: none; }

.new-contact .o-contact-form--white-bg input.pc-phone {
  padding-left: 40px !important; }

.new-contact .o-contact-us-new h2 {
  text-transform: none;
  padding-left: 40px;
  font-size: 42px !important;
  display: inline-block;
  padding-top: 30px; }

.new-contact .o-contact-us-new .subheading {
  font-size: 22px;
  line-height: 38px;
  padding-top: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  padding-right: 0;
  padding-bottom: 75px; }
  @media (min-width: 992px) {
    .new-contact .o-contact-us-new .subheading {
      padding-bottom: 120px;
      padding-right: 125px; } }
  .new-contact .o-contact-us-new .subheading strong {
    font-weight: 700;
    color: #27c070; }

.new-contact .o-contact-us-new .o-contact-form--white-bg {
  background: url(/images/contact-us/dashed-line.png) 0 0 repeat-x;
  padding: 30px 0 50px !important;
  position: relative;
  background-color: #fff;
  box-shadow: none;
  border-bottom: 1px solid #EBEBEB; }
  @media (min-width: 992px) {
    .new-contact .o-contact-us-new .o-contact-form--white-bg {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      border-bottom: 0;
      padding: 20px 0 40px !important; } }
  .new-contact .o-contact-us-new .o-contact-form--white-bg .form-text {
    position: relative;
    padding: 0 15px;
    text-align: left;
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .new-contact .o-contact-us-new .o-contact-form--white-bg .form-text {
        text-align: center;
        padding: 0 0 20px 0;
        margin-bottom: 25px; } }
    .new-contact .o-contact-us-new .o-contact-form--white-bg .form-text:after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      border-bottom: 1px solid #4A90E2;
      width: 200px;
      transform: translate(-50%, 0);
      width: 240px;
      display: none; }
      @media (min-width: 992px) {
        .new-contact .o-contact-us-new .o-contact-form--white-bg .form-text:after {
          display: block; } }

@media (min-width: 992px) {
  .new-contact .ywg {
    padding: 70px 0 20px; } }

.new-contact .ywg h2 {
  text-transform: uppercase;
  font-size: 40px; }

.new-contact .ywg ul li {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  margin: 0 9px 55px 9px;
  vertical-align: top;
  font-weight: 500;
  /* media query for tablet landscape */ }
  @media screen and (min-width: 992px) {
    .new-contact .ywg ul li {
      width: 22%;
      display: inline-block;
      text-align: center; } }
  @media screen and (min-width: 1200px) {
    .new-contact .ywg ul li {
      width: 23%; } }
  .new-contact .ywg ul li img {
    float: none;
    margin: 0 auto;
    display: block;
    width: 50px; }
    @media (min-width: 992px) {
      .new-contact .ywg ul li img {
        width: auto; } }

.new-contact .addr-section {
  padding: 50px 0 0; }
  @media (min-width: 992px) {
    .new-contact .addr-section {
      padding: 75px 0 50px; } }

.new-contact .contact-addr {
  padding-bottom: 0; }
  @media (min-width: 992px) {
    .new-contact .contact-addr {
      padding-top: 60px;
      padding-bottom: 40px; } }
  .new-contact .contact-addr h2 {
    text-transform: inherit;
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 35px;
    font-family: "Montserrat", sans-serif;
    position: relative;
    text-align: left; }
    @media (min-width: 992px) {
      .new-contact .contact-addr h2 {
        font-size: 48px;
        text-align: center;
        margin-bottom: 50px; } }
    .new-contact .contact-addr h2:before {
      content: '';
      position: absolute;
      background: #49C684;
      height: 2px;
      width: 50px;
      bottom: -15px;
      left: 0;
      display: block; }
      @media (min-width: 992px) {
        .new-contact .contact-addr h2:before {
          display: none;
          bottom: 0; } }
  .new-contact .contact-addr .office {
    box-shadow: none;
    overflow: hidden;
    margin-bottom: 40px;
    display: block;
    padding-bottom: 15px;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .new-contact .contact-addr .office {
        display: block;
        padding: 0;
        margin: 0 0 40px;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
        border-bottom: 0; } }
    .new-contact .contact-addr .office .main-img {
      margin-bottom: 0;
      width: 100%;
      height: auto;
      border-radius: 0;
      overflow: hidden; }
      @media (min-width: 992px) {
        .new-contact .contact-addr .office .main-img {
          width: auto;
          height: auto;
          border-radius: 0; } }
      .new-contact .contact-addr .office .main-img a {
        width: 100%;
        height: 100%;
        display: block; }
        .new-contact .contact-addr .office .main-img a img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .new-contact .contact-addr .office ul {
      padding-bottom: 10px;
      padding-top: 15px; }
      @media screen and (min-width: 992px) {
        .new-contact .contact-addr .office ul {
          padding: 15px 15px 10px; } }
      @media screen and (min-width: 1200px) {
        .new-contact .contact-addr .office ul {
          min-height: 241px; } }
      .new-contact .contact-addr .office ul li {
        padding-left: 0;
        font-weight: 500; }
    .new-contact .contact-addr .office .office-left {
      float: left;
      width: 100%;
      overflow: hidden;
      /* media query for tablet landscape */ }
      @media (min-width: 992px) {
        .new-contact .contact-addr .office .office-left {
          width: 32%;
          height: auto;
          border-radius: 0; } }
    .new-contact .contact-addr .office .office-right {
      float: right;
      width: 100%;
      /* media query for tablet landscape */
      margin-top: 20px; }
      @media (min-width: 992px) {
        .new-contact .contact-addr .office .office-right {
          width: 64%;
          padding: 30px 0 0 0;
          margin-top: 0; } }
      .new-contact .contact-addr .office .office-right .c-name {
        color: #000;
        font-weight: 500;
        font-size: 16px; }
      .new-contact .contact-addr .office .office-right p {
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 15px; }
      .new-contact .contact-addr .office .office-right .addr {
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
        line-height: 24px;
        font-weight: 500;
        float: left;
        width: 100%;
        background: none;
        padding-left: 0;
        /* media query for tablet landscape */
        margin-bottom: 0; }
        @media screen and (min-width: 992px) {
          .new-contact .contact-addr .office .office-right .addr {
            width: 46%;
            background: url(/images/contact-us/map.png) 0 5px no-repeat;
            padding-left: 40px;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 15px; } }
        @media screen and (min-width: 1200px) {
          .new-contact .contact-addr .office .office-right .addr {
            width: 54%;
            font-size: 17px;
            line-height: 27px; } }
      .new-contact .contact-addr .office .office-right .contact-new {
        float: none;
        /* media query for tablet landscape */ }
        @media (min-width: 992px) {
          .new-contact .contact-addr .office .office-right .contact-new {
            margin-right: 50px;
            float: right; } }
        .new-contact .contact-addr .office .office-right .contact-new ul {
          padding: 0; }
          .new-contact .contact-addr .office .office-right .contact-new ul li {
            list-style-type: none;
            padding-left: 0;
            vertical-align: top;
            font-size: 15px;
            /* media query for tablet landscape */ }
            @media (min-width: 992px) {
              .new-contact .contact-addr .office .office-right .contact-new ul li {
                font-size: 16px; } }

.new-contact .blue-bg {
  background-color: #132a49;
  background-repeat: no-repeat;
  background-position: 0 100%; }
  .new-contact .blue-bg h2 {
    color: #fff;
    text-transform: inherit;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    margin-bottom: 60px; }
    @media (min-width: 992px) {
      .new-contact .blue-bg h2 {
        font-size: 48px; } }
  .new-contact .blue-bg li span {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    margin-top: 0;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    line-height: 24px;
    padding-left: 20px; }
    @media (min-width: 992px) {
      .new-contact .blue-bg li span {
        font-size: 18px;
        margin-top: 25px;
        display: block;
        padding-left: 0; } }

.new-contact .office-right p {
  font-family: "Montserrat", sans-serif;
  font-size: 14px; }
  .new-contact .office-right p:before {
    display: none; }

.new-contact .office h4 {
  font-size: 15px;
  margin-bottom: 5px;
  line-height: 27px; }
  @media (min-width: 992px) {
    .new-contact .office h4 {
      font-size: 17px;
      margin-bottom: 15px; } }

.new-contact .office p {
  position: relative;
  padding-left: 0;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 0 0; }
  @media (min-width: 992px) {
    .new-contact .office p {
      padding-left: 35px;
      margin: 0 0 25px 0; } }
  .new-contact .office p:before {
    content: '';
    background: url(/images/contact-us/map.png) no-repeat 0 0;
    height: 50px;
    width: 30px;
    position: absolute;
    left: 0;
    top: 3px;
    background-size: 15px;
    display: none; }
    @media (min-width: 992px) {
      .new-contact .office p:before {
        display: block; } }
  .new-contact .office p br {
    display: none; }
    @media (min-width: 992px) {
      .new-contact .office p br {
        display: block; } }

.new-contact .office img {
  width: 22px;
  margin-right: 5px; }

.new-contact .office a:hover {
  text-decoration: none; }

.new-contact .o-contact-us-new h2 {
  padding-left: 0;
  padding-top: 0;
  position: relative;
  font-weight: 900;
  font-size: 40px; }
  .new-contact .o-contact-us-new h2:before {
    content: '';
    position: absolute;
    height: 70px;
    width: 105px;
    right: -6px;
    top: -7px; }
    @media (min-width: 992px) {
      .new-contact .o-contact-us-new h2:before {
        right: 0;
        top: 0; } }
  .new-contact .o-contact-us-new h2 span {
    position: relative; }
  .new-contact .o-contact-us-new h2 .white-text {
    color: #fff;
    text-shadow: none; }

.new-contact .o-contact-form {
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 0; }

.new-contact .o-skills-hero__background.cntct-bnr {
  display: none; }
  @media (min-width: 992px) {
    .new-contact .o-skills-hero__background.cntct-bnr {
      display: block; } }

.new-contact .gd-compny {
  text-align: center !important;
  color: #9B9B9B !important;
  font-size: 16px !important;
  text-transform: uppercase; }

@media (min-width: 992px) {
  .new-contact .o-contact-form__contact-form {
    text-align: center; } }

.new-contact .o-contact-us {
  position: relative;
  top: 0;
  z-index: 9; }
  @media (min-width: 992px) {
    .new-contact .o-contact-us {
      top: 0; } }

.new-contact .grp-logo {
  display: block;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .new-contact .grp-logo {
      display: inherit; } }

.new-contact .o-navigation-bar #primaryNav .request-quote {
  display: none !important; }

.logo-group {
  position: relative; }
  .logo-group:before {
    content: '';
    position: absolute;
    background: #f9f5f5;
    height: 100%;
    width: 1px;
    left: 50%; }
  .logo-group-sub {
    display: flex;
    border-bottom: 1px solid #f9f5f5;
    align-items: center; }
    .logo-group-sub-box {
      flex: 1;
      text-align: center;
      padding: 35px 15px; }
  .logo-group .no-border {
    border-bottom: 0; }

.cntct-bnr {
  position: absolute;
  height: 340px;
  display: none; }
  @media screen and (min-width: 992px) {
    .cntct-bnr {
      height: 390px;
      display: block; } }
  @media screen and (min-width: 1200px) {
    .cntct-bnr {
      height: 340px; } }

.contact-h1 {
  padding-left: 0;
  padding-top: 0;
  font-size: 36px !important;
  line-height: 61px;
  color: #333 !important;
  display: block;
  text-shadow: none;
  margin-bottom: 15px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  text-align: left; }
  @media (min-width: 992px) {
    .contact-h1 {
      display: inline-block;
      text-transform: capitalize;
      padding-top: 0;
      position: relative;
      font-weight: 900;
      font-size: 42px !important;
      letter-spacing: 2.5px; } }

.u-align-center {
  text-align: center; }

@media (min-width: 992px) {
  .cntct-pg-section {
    padding: 100px 20px 60px !important; } }

.u-submit.s-btn-modifier {
  display: block;
  text-align: center;
  border-radius: 5px;
  padding: 13px 0px;
  font-size: 14px;
  text-transform: uppercase;
  max-width: 350px;
  text-decoration: none;
  position: relative;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  color: #fff;
  border: 2px solid #FF5800;
  background-color: #FF5800;
  width: 100%;
  margin: 25px auto 0; }
  @media (min-width: 992px) {
    .u-submit.s-btn-modifier {
      width: 478px;
      font-size: 18px;
      padding: 19px 0px;
      margin: 0 auto; } }

.modal-dialog {
  max-width: 600px !important; }

.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg {
  padding: 0 20px 7px 20px;
  background-color: #fff; }

.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .un,
.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .ue,
.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .group {
  position: relative; }

.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .un:after,
.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .ue:after {
  border-bottom: 1px solid #C9C8C8;
  content: "";
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 5px; }

.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .un input,
.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .ue input,
.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .group input {
  padding: 10px 0;
  background: transparent;
  width: 100%;
  border: 0; }

.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .c-btn--lg {
  margin: 0;
  border-radius: 5px;
  display: block;
  font-weight: 400;
  background: url(/images/common/contact-form-button.png) no-repeat 0 0;
  border: 0;
  padding-left: 60px;
  padding-bottom: 10px;
  background-size: 100%;
  padding: 2px 0 6px 35px;
  line-height: 0;
  font-size: 15px;
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (min-width: 992px) {
    .quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .c-btn--lg {
      height: 80px;
      font-size: 18px; } }

.o-thank-you__top {
  max-width: 850px;
  margin: 0 auto;
  padding: 50px 0px 30px;
  border-bottom: 1px solid #E9E9E9;
  text-align: center; }

body[data-page="thank-you"] .o-contact-form .o-contact-form--white-bg {
  max-width: 790px;
  margin: 60px auto;
  text-align: left;
  display: block;
  padding: 40px; }

body[data-page="thank-you"] .o-contact-form .o-contact-form--white-bg .fieldset-content {
  margin-bottom: 20px;
  padding: 10px; }

body[data-page="thank-you"] .o-contact-form .o-contact-form--white-bg .fieldset-content label {
  display: block;
  text-align: left;
  font-family: "Montserrat",sans-serif;
  font-weight: 300;
  color: #414446;
  font-size: 16px;
  line-height: 28px; }

body[data-page="thank-you"] .o-contact-form .o-contact-form--white-bg .fieldset-content label input {
  margin-right: 10px; }

body[data-page="thank-you"] .attach {
  background-image: none;
  padding: 0; }

body[data-page="thank-you"] .o-contact-form__attachement-text {
  font-size: 16px;
  display: block;
  color: #878787; }

.o-thank-you__top h3 {
  margin-bottom: 10px;
  font-size: 36px;
  line-height: 44px; }

.o-contact-form h5 {
  font-size: 26px;
  line-height: 35px;
  font-weight: 500; }

body[data-page="thank-you"] .u-name {
  background: url(/images/common/form-icon-1.png) 0 50% no-repeat;
  border: 0;
  border-bottom: 1px solid #C9C8C8;
  padding: 20px 20px 20px 40px;
  color: #333;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 400; }

body[data-page="thank-you"] .u-email {
  background: url(/images/common/form-icon-2.png) 0 50% no-repeat;
  border: 0;
  border-bottom: 1px solid #C9C8C8;
  padding: 20px 20px 20px 40px;
  color: #333;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 400; }

body[data-page="thank-you"] .u-phone {
  background: url(/images/common/form-icon-3.png) 0 50% no-repeat;
  border: 0;
  border-bottom: 1px solid #C9C8C8;
  padding: 20px 20px 20px 40px;
  color: #333;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 400; }

body[data-page="thank-you"] .u-message textarea {
  background: url(/images/common/icon-message.svg) 0 20px no-repeat;
  padding: 20px 20px 20px 40px;
  color: #333;
  min-height: 130px;
  width: 100%;
  margin-bottom: 15px;
  border: 0; }

body[data-page="thank-you"] .group select {
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  height: 50px;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #C9C8C8; }

body[data-page="thank-you"] .c-btn {
  display: block;
  outline: none;
  cursor: pointer;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  color: #fff;
  background: #FF5800;
  border-radius: 4px;
  text-transform: uppercase;
  border: 2px solid #FF5800;
  line-height: 60px;
  font-size: 20px;
  padding: 0 24px;
  border-radius: 10px;
  width: auto;
  margin: 0 auto; }

input {
  outline: none; }

.mobile-off {
  display: none; }
  @media (min-width: 992px) {
    .mobile-off {
      display: block; } }

.cu-lets-talk {
  background: #091B38;
  padding: 96px 25px 45px;
  position: relative;
  text-align: left; }
  @media screen and (min-width: 992px) {
    .cu-lets-talk {
      padding: 45px 25px; } }
  .cu-lets-talk:before {
    content: '';
    position: absolute;
    top: 80px;
    right: 5px;
    background: url(/images/common/b-form-envelop.webp) no-repeat center center/100%;
    width: 159px;
    height: 159px; }
  .cu-lets-talk:after {
    content: '';
    background: #091B38;
    width: 25px;
    height: 25px;
    transform: rotate(-45deg);
    position: absolute;
    bottom: -12px;
    left: 35px;
    z-index: 9; }
  .cu-lets-talk h3 {
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    font-family: "Montserrat", sans-serif; }
  .cu-lets-talk p {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    padding-right: 40%;
    margin-bottom: 0; }
    .cu-lets-talk p a {
      color: #36E079; }

.row.bnr-form {
  padding: 0 25px; }
  @media (min-width: 992px) {
    .row.bnr-form {
      padding: 0; } }

.msgg-colo {
  padding: 0; }
  @media (min-width: 992px) {
    .msgg-colo {
      padding: 0 15px; } }

.mobile-off-inline {
  display: none; }
  @media (min-width: 992px) {
    .mobile-off-inline {
      display: inline-block; } }

.good-sec {
  padding: 30px 0 35px;
  border-bottom: 1px solid #dedede; }
  @media (min-width: 992px) {
    .good-sec {
      padding: 20px 0 35px; } }
  .good-sec h5 {
    text-align: center;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-bottom: 20px; }
  .good-sec ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 360px;
    margin: 0 auto; }
    .good-sec ul li {
      padding: 10px; }
  .good-sec ul li img {
    filter: grayscale(0.9); }

.ind-ofc {
  min-height: auto; }
  @media screen and (min-width: 992px) {
    .ind-ofc {
      height: auto; } }
  @media screen and (min-width: 1200px) {
    .ind-ofc {
      height: 282px; } }

.or-hd {
  font-size: 30px;
  margin-bottom: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700; }

.logo-group-sub-box img, .logo-group-sub-box svg {
  height: 45px;
  filter: grayscale(0.9); }

.logo-group-sub-box .st-logo {
  height: 35px; }

.fieldset-content label {
  cursor: pointer;
  margin-bottom: 4px; }
  .fieldset-content label input {
    position: relative;
    top: 1px; }

.attachment-list li {
  margin-right: 0px !important;
  width: 100%; }
  .attachment-list li input {
    border-radius: 0px;
    width: calc(100% - 25px) !important; }
  .attachment-list li label {
    white-space: nowrap;
    width: calc(100% - 28px);
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 17px; }

.contact-thankyou-attachment li {
  border-radius: 30px;
  background: #fafafa;
  margin-bottom: 10px;
  padding: 8px 15px; }

.attach-icon {
  display: inline-block;
  background: url("/images/common/icon-attachment.svg") 0 0 no-repeat;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  vertical-align: middle; }

.cloudModalFooter button {
  border: none !important; }

.disabledAttachButton button {
  pointer-events: none;
  background-color: #ebb79b !important;
  opacity: .9; }

.new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg {
  padding: 0 15px 7px 15px; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg input,
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg textarea {
    border-bottom: 1px solid #aaa !important; }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg input::placeholder,
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg textarea::placeholder {
      color: #000;
      opacity: 1; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .un:after {
    display: none; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .un .u-name {
    background: url(/images/common/form-icon-1.png) 0 50% no-repeat; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .ue:after {
    display: none; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .ue .u-email {
    background: url(/images/common/form-icon-2.png) 0 50% no-repeat; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .new-b-a-call {
    background-image: none;
    background-color: #FC591F;
    border-radius: 11px;
    font-size: 14px;
    font-weight: 700;
    height: auto;
    line-height: 14px;
    padding: 13px 0; }
    @media screen and (min-width: 992px) {
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .new-b-a-call {
        padding: 20px 0;
        font-size: 17px; } }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .cmn-left-pd-wrp input {
    padding: 12px 15px 12px 30px; }
    @media screen and (min-width: 1280px) {
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .cmn-left-pd-wrp input {
        padding: 15px 15px 15px 30px; } }
    @media screen and (min-width: 1400px) {
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .cmn-left-pd-wrp input {
        padding: 20px 15px 20px 30px; } }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .cmn-left-pd-wrp input.u-phone {
      background: url(/images/common/form-icon-3.png) 0 50% no-repeat; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .attach {
    background-position: 0 10px;
    padding: 0 20px 5px 30px;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 5px; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list .comp-attach,
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list .cloud-attach {
    margin-bottom: 10px; }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list .comp-attach .attachedFileUrl,
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list .cloud-attach .attachedFileUrl {
      height: auto; }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list .comp-attach .attachedFile,
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list .cloud-attach .attachedFile {
      border-bottom: none !important; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach,
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list {
    background: #f8f8f8;
    max-width: 100%;
    border-radius: 5px;
    position: relative;
    text-align: left;
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 30px;
    border: 1px solid #d1d1d1; }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach .removeAttachedFile2,
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list .removeAttachedFile2 {
      background: #f5f5f5;
      border: 0;
      margin-right: 0;
      padding: 0 0;
      position: absolute;
      right: 0;
      height: 100%;
      width: 50px;
      border-left: 1px solid #d1d1d1; }
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach .removeAttachedFile2 img,
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list .removeAttachedFile2 img {
        position: relative;
        top: 0;
        width: 15px; }
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach .removeAttachedFile2:hover,
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list .removeAttachedFile2:hover {
        background: #ff1c31; }
        .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach .removeAttachedFile2:hover img,
        .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list .removeAttachedFile2:hover img {
          filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(2410%) hue-rotate(122deg) brightness(117%) contrast(101%); }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach .attachedFileUrl,
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list .attachedFileUrl {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 84%; }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach span,
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list span {
      color: #333;
      font-weight: 400;
      font-size: 14px;
      flex: 0 0 60%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach i,
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list i {
      margin-right: 10px;
      background: #f5f5f5;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #d1d1d1;
      height: 100%; }
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.cloud-attach i img,
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list li.styled-list i img {
        width: 20px; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list .inp-wrapper {
    width: 0;
    height: 0;
    background: none;
    visibility: hidden;
    opacity: 0;
    padding: 0 !important;
    margin: 0 !important; }
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg .url-list .inp-wrapper .cloned {
      width: 0;
      height: 0;
      background: none;
      visibility: hidden;
      opacity: 0;
      padding: 0 !important;
      margin: 0 !important; }
  .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg textarea {
    padding: 12px 20px 15px 30px; }
    @media screen and (min-width: 1280px) {
      .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg textarea {
        padding: 15px 20px 20px 30px; } }
  @media screen and (min-width: 992px) {
    .new-quickform-holder .new-slide-form .o-contact-form .o-contact-form--white-bg {
      padding: 0 30px 7px 30px; } }

.new-quickform-holder .big-girl {
  width: 377px; }
  @media screen and (min-width: 992px) {
    .new-quickform-holder .big-girl {
      left: 0;
      top: 43px; } }

.new-quickform-holder .o-contact-form--white-bg {
  box-shadow: none; }

.new-quickform-holder .s-f-left {
  padding: 30px 0 15px; }
  @media screen and (min-width: 992px) {
    .new-quickform-holder .s-f-left {
      padding: 0; } }

.new-quickform-holder .add-computer-file-new .comp-file {
  position: absolute;
  right: -9999px;
  visibility: hidden;
  opacity: 0; }

.o-bottom-form .url-list,
.o-contact-form-contact-us .url-list {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .o-bottom-form .url-list li,
  .o-contact-form-contact-us .url-list li {
    text-align: left; }
  .o-bottom-form .url-list label,
  .o-contact-form-contact-us .url-list label {
    color: #000;
    font-size: 13px !important; }
  .o-bottom-form .url-list.add-height,
  .o-contact-form-contact-us .url-list.add-height {
    overflow-y: auto;
    scroll-behavior: smooth; }
    @media screen and (min-width: 992px) {
      .o-bottom-form .url-list.add-height,
      .o-contact-form-contact-us .url-list.add-height {
        height: 65px; } }
    @media screen and (min-width: 1280px) {
      .o-bottom-form .url-list.add-height,
      .o-contact-form-contact-us .url-list.add-height {
        height: 81px; } }
    @media screen and (min-width: 1400px) {
      .o-bottom-form .url-list.add-height,
      .o-contact-form-contact-us .url-list.add-height {
        height: 115px; } }
    @media screen and (min-width: 1800px) {
      .o-bottom-form .url-list.add-height,
      .o-contact-form-contact-us .url-list.add-height {
        height: 115px; } }
  .o-bottom-form .url-list::-webkit-scrollbar,
  .o-contact-form-contact-us .url-list::-webkit-scrollbar {
    width: 5px; }
  .o-bottom-form .url-list::-webkit-scrollbar-track,
  .o-contact-form-contact-us .url-list::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .o-bottom-form .url-list::-webkit-scrollbar-thumb,
  .o-contact-form-contact-us .url-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px; }
  .o-bottom-form .url-list::-webkit-scrollbar-thumb:hover,
  .o-contact-form-contact-us .url-list::-webkit-scrollbar-thumb:hover {
    background: #555; }

.o-bottom-form .attach,
.o-contact-form-contact-us .attach {
  background-position: 0 10px;
  padding: 0 20px 5px 30px;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 5px; }

.o-bottom-form .url-list .comp-attach,
.o-bottom-form .url-list .cloud-attach,
.o-contact-form-contact-us .url-list .comp-attach,
.o-contact-form-contact-us .url-list .cloud-attach {
  margin-bottom: 10px; }
  .o-bottom-form .url-list .comp-attach .attachedFileUrl,
  .o-bottom-form .url-list .cloud-attach .attachedFileUrl,
  .o-contact-form-contact-us .url-list .comp-attach .attachedFileUrl,
  .o-contact-form-contact-us .url-list .cloud-attach .attachedFileUrl {
    height: auto; }
  .o-bottom-form .url-list .comp-attach .attachedFile,
  .o-bottom-form .url-list .cloud-attach .attachedFile,
  .o-contact-form-contact-us .url-list .comp-attach .attachedFile,
  .o-contact-form-contact-us .url-list .cloud-attach .attachedFile {
    border-bottom: none !important; }

.o-bottom-form .url-list li.cloud-attach,
.o-bottom-form .url-list li.styled-list,
.o-contact-form-contact-us .url-list li.cloud-attach,
.o-contact-form-contact-us .url-list li.styled-list {
  background: #f8f8f8;
  max-width: 100%;
  border-radius: 5px;
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 35px;
  border: 1px solid #d1d1d1; }
  .o-bottom-form .url-list li.cloud-attach .removeAttachedFile2,
  .o-bottom-form .url-list li.styled-list .removeAttachedFile2,
  .o-contact-form-contact-us .url-list li.cloud-attach .removeAttachedFile2,
  .o-contact-form-contact-us .url-list li.styled-list .removeAttachedFile2 {
    background: #f5f5f5;
    border: 0;
    margin-right: 0;
    padding: 0 0;
    position: absolute;
    right: 0;
    height: 100%;
    width: 50px;
    border-left: 1px solid #d1d1d1; }
    .o-bottom-form .url-list li.cloud-attach .removeAttachedFile2 img,
    .o-bottom-form .url-list li.styled-list .removeAttachedFile2 img,
    .o-contact-form-contact-us .url-list li.cloud-attach .removeAttachedFile2 img,
    .o-contact-form-contact-us .url-list li.styled-list .removeAttachedFile2 img {
      position: relative;
      top: 0;
      width: 15px; }
    .o-bottom-form .url-list li.cloud-attach .removeAttachedFile2:hover,
    .o-bottom-form .url-list li.styled-list .removeAttachedFile2:hover,
    .o-contact-form-contact-us .url-list li.cloud-attach .removeAttachedFile2:hover,
    .o-contact-form-contact-us .url-list li.styled-list .removeAttachedFile2:hover {
      background: #ff1c31; }
      .o-bottom-form .url-list li.cloud-attach .removeAttachedFile2:hover img,
      .o-bottom-form .url-list li.styled-list .removeAttachedFile2:hover img,
      .o-contact-form-contact-us .url-list li.cloud-attach .removeAttachedFile2:hover img,
      .o-contact-form-contact-us .url-list li.styled-list .removeAttachedFile2:hover img {
        filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(2410%) hue-rotate(122deg) brightness(117%) contrast(101%); }
  .o-bottom-form .url-list li.cloud-attach .attachedFileUrl,
  .o-bottom-form .url-list li.styled-list .attachedFileUrl,
  .o-contact-form-contact-us .url-list li.cloud-attach .attachedFileUrl,
  .o-contact-form-contact-us .url-list li.styled-list .attachedFileUrl {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 84%; }
  .o-bottom-form .url-list li.cloud-attach span,
  .o-bottom-form .url-list li.styled-list span,
  .o-contact-form-contact-us .url-list li.cloud-attach span,
  .o-contact-form-contact-us .url-list li.styled-list span {
    color: #333;
    font-weight: 400;
    font-size: 14px;
    flex: 0 0 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .o-bottom-form .url-list li.cloud-attach i,
  .o-bottom-form .url-list li.styled-list i,
  .o-contact-form-contact-us .url-list li.cloud-attach i,
  .o-contact-form-contact-us .url-list li.styled-list i {
    margin-right: 10px;
    background: #f5f5f5;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #d1d1d1;
    height: 100%; }
    .o-bottom-form .url-list li.cloud-attach i img,
    .o-bottom-form .url-list li.styled-list i img,
    .o-contact-form-contact-us .url-list li.cloud-attach i img,
    .o-contact-form-contact-us .url-list li.styled-list i img {
      width: 20px; }

.o-bottom-form .url-list .inp-wrapper,
.o-contact-form-contact-us .url-list .inp-wrapper {
  width: 0;
  height: 0;
  background: none;
  visibility: hidden;
  opacity: 0;
  padding: 0 !important;
  margin: 0 !important; }
  .o-bottom-form .url-list .inp-wrapper .cloned,
  .o-contact-form-contact-us .url-list .inp-wrapper .cloned {
    width: 0;
    height: 0;
    background: none;
    visibility: hidden;
    opacity: 0;
    padding: 0 !important;
    margin: 0 !important; }

.o-contact-form-contact-us .url-list li.cloud-attach span {
  flex: 0 0 70%; }

.attachedFile {
  width: 0;
  height: 0;
  padding: 0;
  visibility: hidden;
  position: absolute;
  pointer-events: none;
  right: -999999px; }

/* ANCHOR Product */
.product-bnr {
  min-height: 520px;
  background-size: cover; }
  @media (min-width: 992px) {
    .product-bnr {
      background: none; } }

.o-skills-hero__product {
  text-align: left;
  padding-top: 95px !important; }
  .o-skills-hero__product .container {
    padding: 0 30px 0 30px !important; }
    @media (min-width: 992px) {
      .o-skills-hero__product .container {
        padding: 30px 15px 0 15px !important; } }
    .o-skills-hero__product .container .product_banner {
      padding-bottom: 80px; }
      @media (min-width: 992px) {
        .o-skills-hero__product .container .product_banner {
          background: url(/images/products/product-banner.png) no-repeat 0 0;
          padding-bottom: 125px;
          padding-top: 80px;
          padding-left: 40%;
          background-size: 34%; } }
      .o-skills-hero__product .container .product_banner h1 {
        color: #fff;
        font-size: 28px;
        line-height: 38px;
        padding-bottom: 10px; }
        @media (min-width: 992px) {
          .o-skills-hero__product .container .product_banner h1 {
            font-size: 55px;
            line-height: 60px;
            color: #14DF7D; } }
      .o-skills-hero__product .container .product_banner p {
        color: #49C684;
        font-family: "Montserrat",sans-serif;
        font-size: 18px;
        line-height: 27px;
        font-weight: 600; }
        @media (min-width: 992px) {
          .o-skills-hero__product .container .product_banner p {
            font-size: 32px;
            line-height: 48px;
            color: #fff; } }
  .o-skills-hero__product .o-skills-hero__case-studies__banner-content {
    text-align: left; }

.product-1-left h3 {
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: "Montserrat",sans-serif;
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .product-1-left h3 {
      font-size: 30px;
      line-height: 45px;
      margin-bottom: 80px; } }

.product-1-left p {
  font-size: 16px;
  line-height: 24px;
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .product-1-left p {
      font-family: "Montserrat", sans-serif;
      font-size: 19px;
      line-height: 30px; } }

@media (min-width: 992px) {
  .product-1-right ul {
    padding-left: 100px; } }

.product-1-right ul li {
  background: url(/images/products/green-tick.png) no-repeat 0 10px;
  padding: 0 0 10px 30px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500; }
  @media (min-width: 992px) {
    .product-1-right ul li {
      font-size: 19px;
      line-height: 28px;
      font-weight: 700; } }

.product-pan2 h3 {
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  font-family: "Montserrat",sans-serif;
  margin-bottom: 30px;
  padding-top: 15px; }
  @media (min-width: 992px) {
    .product-pan2 h3 {
      font-size: 30px;
      line-height: 45px;
      margin-bottom: 80px;
      padding-top: 50px; } }

.product-pan2 p {
  font-size: 16px;
  line-height: 24px;
  padding-top: 20px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0; }
  @media (min-width: 992px) {
    .product-pan2 p {
      font-size: 19px;
      line-height: 30px;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 25px; } }

.product-pan2 ul {
  padding-top: 20px; }
  .product-pan2 ul li {
    background: url(/images/products/green-tick.png) no-repeat 0 6px;
    padding: 0 0 10px 30px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .product-pan2 ul li {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        font-size: 19px;
        line-height: 28px;
        background-position: 0 10px; } }

.p-logo-sec {
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
  display: flex; }
  @media (min-width: 992px) {
    .p-logo-sec {
      display: block; } }
  .p-logo-sec li {
    display: inline-block;
    padding: 0 15px;
    vertical-align: middle;
    flex: 0 0 50%; }
    .p-logo-sec li:first-child {
      padding: 0 15px 0 0 !important;
      border-right: 1px solid #ccc;
      text-align: left;
      flex: 0 0 35%; }
      @media (min-width: 992px) {
        .p-logo-sec li:first-child {
          padding: 15px 20px 20px 0;
          padding: 5px 20px 5px 0 !important;
          flex: 0 0 50%;
          border-right: 1px solid #979797; } }
      .p-logo-sec li:first-child img {
        width: 100%; }
        @media (min-width: 992px) {
          .p-logo-sec li:first-child img {
            width: auto; } }
    .p-logo-sec li:last-child {
      font-family: "Montserrat",sans-serif;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      @media (min-width: 992px) {
        .p-logo-sec li:last-child {
          display: inline;
          flex: 0 0 50%;
          justify-content: flex-end; } }
      .p-logo-sec li:last-child img {
        margin-right: 10px;
        display: inline-block;
        flex: 0 0 15px;
        width: 15px;
        height: 15px; }
        @media (min-width: 992px) {
          .p-logo-sec li:last-child img {
            width: auto;
            height: auto; } }
    .p-logo-sec li a {
      display: inline-block;
      color: #333333;
      font-size: 12px; }
      @media (min-width: 992px) {
        .p-logo-sec li a {
          font-size: 18px; } }

.product-blue-banner {
  background: #091B38;
  padding-bottom: 50px; }
  @media screen and (min-width: 992px) {
    .product-blue-banner {
      padding-bottom: 30px; } }
  @media screen and (min-width: 1200px) {
    .product-blue-banner {
      padding-bottom: 0; } }

.product-blue-banner img {
  float: right; }

.product-blue-banner h2 {
  color: #fff;
  font-size: 24px;
  line-height: 37px;
  padding: 25px 0 0; }
  @media screen and (min-width: 992px) {
    .product-blue-banner h2 {
      font-size: 36px;
      line-height: 42px; } }
  @media screen and (min-width: 1200px) {
    .product-blue-banner h2 {
      font-size: 44px;
      line-height: 55px; } }

.product-blue-banner a {
  border: 2px solid #FF5800;
  background: url(/images/common/arrow-white.png) 86% 50% no-repeat;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-family: "Montserrat",sans-serif;
  padding: 16px 60px 18px 60px;
  border-radius: 5px;
  margin: 20px 0 0 0;
  display: inline-block;
  font-weight: 500;
  width: 310px;
  margin: 15px auto 0;
  text-align: center;
  text-transform: uppercase; }
  @media screen and (min-width: 992px) {
    .product-blue-banner a {
      width: inherit;
      margin: 15px 0 15px;
      padding: 16px 30px 18px 0px; } }
  @media screen and (min-width: 1200px) {
    .product-blue-banner a {
      padding: 16px 60px 18px 60px;
      margin: 15px 0 0; } }

.product-blue-banner a:focus {
  color: #fff; }

.product-blue-banner a:hover {
  background-color: #FF5800;
  color: #fff; }

.btn2 ul {
  margin-bottom: 50px;
  text-align: center; }
  @media (min-width: 992px) {
    .btn2 ul {
      margin-bottom: 75px; } }
  .btn2 ul li {
    display: block;
    margin: 0 5px; }
    @media (min-width: 992px) {
      .btn2 ul li {
        display: inline-block; } }

.btn2 ul li a.btn-whi {
  border: 2px solid #FF5800;
  background: url(/images/products/arrow-black.png) 75% 50% no-repeat #fff !important;
  color: #333;
  font-size: 17px;
  line-height: 24px;
  padding: 16px 60px 18px 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 20px 0 0 0;
  display: inline-block;
  font-weight: 600;
  width: 300px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .btn2 ul li a.btn-whi {
      max-width: 350px;
      text-decoration: none;
      position: relative;
      width: inherit;
      background-position: 83% 50% !important; } }

.btn2 ul li a.btn-ora {
  border: 2px solid #FF5800;
  background: url(/images/common/arrow-white.png) 93% 50% no-repeat #FF5800 !important;
  color: #fff;
  font-size: 17px;
  line-height: 24px;
  font-family: "Montserrat", sans-serif;
  padding: 16px 40px 18px 10px;
  border-radius: 5px;
  margin: 20px 0 0 0;
  display: inline-block;
  font-weight: 600;
  width: 300px;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .btn2 ul li a.btn-ora {
      padding: 16px 60px 18px 30px;
      max-width: 350px;
      text-decoration: none;
      position: relative;
      width: inherit;
      background-position: 89% 50% !important; } }

.product-pan1 {
  padding-top: 40px; }
  @media (min-width: 992px) {
    .product-pan1 {
      padding-top: 80px;
      padding-bottom: 5px; } }

.zip-alert-logo {
  margin-bottom: 15px; }
  @media (min-width: 992px) {
    .zip-alert-logo {
      margin-bottom: 30px; } }

.o-skills-hero__case-studies__banner-image {
  padding: 50px 0 0; }
  @media screen and (min-width: 768px) {
    .o-skills-hero__case-studies__banner-image {
      padding: 0; } }

.o-skills-hero__case-studies__banner-content {
  text-align: center; }
  @media (min-width: 992px) {
    .o-skills-hero__case-studies__banner-content {
      text-align: left; } }
  .o-skills-hero__case-studies__banner-content h4 {
    color: #14DF7D;
    font-family: "Montserrat", sans-serif; }
  .o-skills-hero__case-studies__banner-content h2 {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: 700; }
  .o-skills-hero__case-studies__banner-content p {
    color: #14DF7D;
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    line-height: 43px;
    font-weight: 600;
    margin-bottom: 0;
    border-bottom: 1px solid #14DF7D;
    display: inline-block;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .o-skills-hero__case-studies__banner-content p {
        font-size: 30px;
        line-height: 43px;
        border-bottom: 0; } }
  .o-skills-hero__case-studies__banner-content h1 {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-transform: none; }
    @media screen and (min-width: 768px) {
      .o-skills-hero__case-studies__banner-content h1 {
        font-size: 36px;
        line-height: 46px; } }
    @media screen and (min-width: 992px) {
      .o-skills-hero__case-studies__banner-content h1 {
        font-size: 46px;
        line-height: 61px; } }

.o-skills-hero__case-studies__technology-div h2 {
  font-size: 24px; }

.o-skills-hero__case-studies__technology-div ul {
  display: block;
  text-align: center; }
  .o-skills-hero__case-studies__technology-div ul li {
    vertical-align: top;
    display: inline-block;
    margin: 0 6px;
    font-family: "Montserrat", sans-serif; }
    .o-skills-hero__case-studies__technology-div ul li span {
      display: block;
      width: 100px;
      height: 100px;
      text-align: center;
      padding: 20px 10px;
      border: 2px solid #14DF7D;
      border-radius: 50%;
      margin-bottom: 10px;
      color: #fff;
      font-size: 11px;
      font-family: "Montserrat", sans-serif;
      position: relative; }
      .o-skills-hero__case-studies__technology-div ul li span a {
        display: inline-block;
        width: 100%;
        height: 100%; }
      .o-skills-hero__case-studies__technology-div ul li span img {
        margin-bottom: 3px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        width: 42px; }
        .o-skills-hero__case-studies__technology-div ul li span img.wdth-25 {
          width: 25px; }
        .o-skills-hero__case-studies__technology-div ul li span img.wdth-15 {
          width: 15px; }
      .o-skills-hero__case-studies__technology-div ul li span .vertical-image-resize {
        height: 54px;
        width: auto; }
      @media screen and (min-width: 992px) {
        .o-skills-hero__case-studies__technology-div ul li span .transip {
          width: 70px;
          height: 70px; } }

.o-case-study__clients__the-client-content {
  padding: 0; }
  @media (min-width: 992px) {
    .o-case-study__clients__the-client-content {
      padding: 30px 0 0 0; } }
  .o-case-study__clients__the-client-content h3 {
    font-size: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 0px; }
  .o-case-study__clients__the-client-content h4 {
    font-size: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-bottom: 0px; }
  @media (min-width: 992px) {
    .o-case-study__clients__the-client-content p {
      font-size: 19px;
      line-height: 30px; } }
  .o-case-study__clients__the-client-content ul {
    margin-bottom: 15px;
    margin-top: 15px; }
    @media (min-width: 992px) {
      .o-case-study__clients__the-client-content ul {
        margin-top: 0; } }
    .o-case-study__clients__the-client-content ul li {
      padding-left: 35px;
      padding-bottom: 15px;
      font-size: 19px;
      line-height: 32px;
      position: relative;
      font-family: "Montserrat", sans-serif;
      position: relative; }
      .o-case-study__clients__the-client-content ul li:before {
        content: '';
        background-image: url(/images/common/green-arrow.png);
        width: 25px;
        height: 25px;
        position: absolute;
        left: 0;
        top: 3px;
        background-size: 100%; }
    .o-case-study__clients__the-client-content ul.number-style li::before {
      background-image: none !important;
      top: 0 !important; }
    .o-case-study__clients__the-client-content ul.number-style .number-style1::before {
      content: '1.'; }
    .o-case-study__clients__the-client-content ul.number-style .number-style2::before {
      content: '2.'; }
    .o-case-study__clients__the-client-content ul.number-style .number-style3::before {
      content: '3.'; }
    .o-case-study__clients__the-client-content ul.number-style .number-style4::before {
      content: '4.'; }
    .o-case-study__clients__the-client-content ul.number-style .number-style5::before {
      content: '5.'; }
    .o-case-study__clients__the-client-content ul.number-style .number-style6::before {
      content: '6.'; }
    .o-case-study__clients__the-client-content ul.number-style .number-style7::before {
      content: '7.'; }
    .o-case-study__clients__the-client-content ul.number-style .number-style8::before {
      content: '8.'; }
  .o-case-study__clients__the-client-content__services {
    padding: 60px 0 0 0; }
    .o-case-study__clients__the-client-content__services ul {
      display: flex; }
      .o-case-study__clients__the-client-content__services ul li {
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
        font-size: 30px !important;
        font-weight: 500;
        line-height: 25px;
        width: 170px;
        font-family: "Montserrat", sans-serif; }
        @media (min-width: 992px) {
          .o-case-study__clients__the-client-content__services ul li {
            flex: 0 0 25%; } }
        .o-case-study__clients__the-client-content__services ul li span {
          display: block;
          min-height: 60px;
          font-size: 16px; }
          .o-case-study__clients__the-client-content__services ul li span img {
            margin-bottom: 10px; }
          .o-case-study__clients__the-client-content__services ul li span:last-child {
            min-height: auto !important; }
        .o-case-study__clients__the-client-content__services ul li:before {
          display: none; }
        .o-case-study__clients__the-client-content__services ul li:after {
          content: '';
          border-right: 1px solid #d8d8d8;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0px; }
        .o-case-study__clients__the-client-content__services ul li:last-of-type:after {
          content: '';
          border-right: 1px solid transparent; }
        @media (min-width: 992px) {
          .o-case-study__clients__the-client-content__services ul li:first-child {
            flex: 0;
            padding-right: 50px !important; } }
  .o-case-study__clients__the-client-content .the-results {
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%); }
  .o-case-study__clients__the-client-content .results-content {
    background-color: #fff;
    color: #333; }
    .o-case-study__clients__the-client-content .results-content ul li {
      font-family: "Montserrat", sans-serif; }
      .o-case-study__clients__the-client-content .results-content ul li:before {
        width: 20px;
        height: 20px;
        top: 5px;
        background-size: 100%;
        content: '';
        background-image: url(/images/common/green-arrow.png);
        position: absolute;
        left: 0; }
    .o-case-study__clients__the-client-content .results-content h4 {
      margin: 0; }
  .o-case-study__clients__the-client-content__icon-header {
    position: relative; }
    .o-case-study__clients__the-client-content__icon-header img {
      position: absolute;
      max-width: 36px; }
    .o-case-study__clients__the-client-content__icon-header h4 {
      padding-left: 55px; }

.o-case-study__clients__the-results {
  position: relative;
  padding-top: 100px; }
  .o-case-study__clients__the-results__div {
    padding-top: 10px; }
    .o-case-study__clients__the-results__div h4 {
      color: #05B281;
      padding-bottom: 20px; }

.font-size-24-i {
  font-size: 24px !important;
  line-height: 32px !important; }

.padding-top-60 {
  padding-top: 60px; }

.o-case-studies-new {
  padding: 15px 0 20px; }
  @media (min-width: 992px) {
    .o-case-studies-new {
      padding: 65px 0 50px; } }
  @media (min-width: 992px) {
    .o-case-studies-new .btn_wrapper {
      margin-top: 0; } }
  @media (min-width: 992px) {
    .o-case-studies-new .filter {
      margin-bottom: 10px;
      text-align: right; } }
  .o-case-studies-new .filter span.flt-tech {
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #000; }
    @media (min-width: 992px) {
      .o-case-studies-new .filter span.flt-tech {
        margin-right: 0;
        margin-left: 15px;
        font-size: 16px; } }
  .o-case-studies-new .filter ul li {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif; }
    .o-case-studies-new .filter ul li label {
      cursor: pointer; }
  .o-case-studies-new .product {
    width: 90%;
    margin: 15px;
    text-align: center;
    border: 1px solid #EAEAEA;
    padding: 20px;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px 0px #00000030;
    background-color: #fff;
    overflow: hidden; }
    @media (min-width: 992px) {
      .o-case-studies-new .product {
        min-height: 430px; } }
    .o-case-studies-new .product a img {
      width: 100%; }
    .o-case-studies-new .product-portfolio {
      width: 100%;
      float: left;
      text-align: center;
      min-height: 340px;
      padding: 0;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
      border: 0;
      -webkit-border-bottom-right-radius: 5px;
      -webkit-border-bottom-left-radius: 5px;
      -moz-border-radius-bottomright: 5px;
      -moz-border-radius-bottomleft: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      background-color: #fff;
      /* mobile query for tablet landscape */ }
      .o-case-studies-new .product-portfolio h3 {
        padding: 10px 0;
        min-height: 70px;
        text-align: left;
        font-weight: 400; }
      .o-case-studies-new .product-portfolio p {
        font-weight: 500;
        text-align: left; }
      .o-case-studies-new .product-portfolio img {
        margin-left: 0;
        margin-right: 0; }
      .o-case-studies-new .product-portfolio .pp-thumb {
        margin-left: 0;
        margin-right: 0;
        width: 100%; }
      .o-case-studies-new .product-portfolio .p-button {
        border: 1px solid #A4BB66;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        display: block;
        margin-bottom: 10px;
        color: #333;
        font-size: 13px;
        font-weight: 500;
        margin-left: 20px;
        margin-right: 20px; }
        .o-case-studies-new .product-portfolio .p-button:hover {
          background-color: #A4BB66;
          color: #fff; }
      .o-case-studies-new .product-portfolio .p-link {
        margin-bottom: 10px;
        color: #333;
        font-size: 13px;
        font-weight: 300;
        background: url(/assets/images/portfolio/link-icon.png) 83% 6px no-repeat;
        margin-left: 0;
        margin-right: 0;
        text-align: left;
        width: 120px;
        border: 1px solid #ccc;
        display: inline-block;
        padding: 2px 0 2px 19px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        float: right; }
        .o-case-studies-new .product-portfolio .p-link:hover {
          color: #fff;
          background: url(/assets/images/portfolio/link-icon-hover.png) 83% 6px no-repeat #FF5800; }
      .o-case-studies-new .product-portfolio .bottom-casestudies {
        padding: 0 20px;
        text-align: left !important; }
        .o-case-studies-new .product-portfolio .bottom-casestudies .country-code {
          text-indent: 0 !important;
          font-size: 16px !important;
          font-weight: 500;
          line-height: 30px !important;
          display: inline-block !important; }
          .o-case-studies-new .product-portfolio .bottom-casestudies .country-code img {
            width: auto; }
        .o-case-studies-new .product-portfolio .bottom-casestudies img {
          border: none !important;
          padding: 0 !important;
          -webkit-border-radius: 0 !important;
          -moz-border-radius: 0 !important;
          border-radius: 0 !important;
          margin-left: 0 !important;
          margin-right: 15px !important;
          width: 20px; }
    .o-case-studies-new .product span {
      text-indent: -10000px;
      font-size: 0;
      line-height: 0;
      display: none; }
    .o-case-studies-new .product h3 {
      font-size: 18px;
      line-height: 28px;
      margin-top: 30px;
      margin-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      height: 80px;
      overflow: hidden; }
      .o-case-studies-new .product h3 a {
        color: #000; }
    .o-case-studies-new .product h2 {
      font-size: 17px;
      line-height: 23px;
      margin-top: 20px;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-left: 20px;
      padding-right: 20px;
      text-align: left;
      overflow: hidden; }
      @media (min-width: 992px) {
        .o-case-studies-new .product h2 {
          margin-top: 20px;
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 0px;
          padding-bottom: 15px; } }
      .o-case-studies-new .product h2 a {
        color: #000; }
    .o-case-studies-new .product p {
      font-size: 15px;
      line-height: 21px;
      margin-top: 10px;
      margin-left: 20px;
      margin-right: 20px;
      min-height: 85px;
      text-align: left;
      padding-bottom: 5px; }
      @media (min-width: 992px) {
        .o-case-studies-new .product p {
          font-size: 16px;
          line-height: 28px;
          padding-bottom: 20px;
          margin-top: 0px; } }
    .o-case-studies-new .product .bottom-casestudies {
      text-align: center;
      overflow: hidden;
      /* mobile query for tablet landscape */ }
      .o-case-studies-new .product .bottom-casestudies a.color-icon {
        width: 40px;
        height: 40px;
        text-align: center;
        display: inline-block;
        margin: 0 10px 0 0;
        border: 1px solid #ccc;
        padding: 5px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%; }
        .o-case-studies-new .product .bottom-casestudies a.color-icon img {
          width: 100%;
          height: auto;
          margin-left: 0;
          margin-right: 0; }
      .o-case-studies-new .product .bottom-casestudies .btn-casestudies {
        padding: 0 13px;
        float: none;
        display: block;
        margin: 0 auto 0;
        max-width: 200px;
        background-color: #fff;
        color: #FF5800;
        float: right;
        /* mobile query for tablet landscape */ }
        @media (min-width: 992px) {
          .o-case-studies-new .product .bottom-casestudies .btn-casestudies {
            padding: 0 15px; } }
        .o-case-studies-new .product .bottom-casestudies .btn-casestudies:hover {
          background-color: #FF5800;
          color: #fff;
          border: 2px solid #FF5800; }
      .o-case-studies-new .product .bottom-casestudies .btn-casestudies1 {
        padding: 13px 32px;
        float: left;
        display: block;
        margin: 10px auto 0;
        max-width: 200px;
        background-color: #fff;
        color: #333;
        background: url(/images/case-study-home/icon-pdf.svg) 0 50% no-repeat;
        text-transform: uppercase;
        font-family: "Montserrat",sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        /* mobile query for tablet landscape */ }
        .o-case-studies-new .product .bottom-casestudies .btn-casestudies1:after {
          content: "";
          position: absolute;
          left: 32px;
          bottom: 9px;
          width: 100px;
          background-color: #C3C3C3;
          height: 1px; }
        .o-case-studies-new .product .bottom-casestudies .btn-casestudies1:hover::after {
          background-color: #494646; }
  .o-case-studies-new .filter-height {
    height: 340px;
    overflow-y: scroll;
    margin: 0 0 50px 0;
    display: none;
    position: absolute;
    z-index: 9;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    margin-top: 25px;
    right: 0px;
    top: 2px;
    text-align: left;
    min-width: 215px; }
    .o-case-studies-new .filter-height ul li .case-study-label {
      position: relative;
      line-height: 100%;
      cursor: pointer; }
    .o-case-studies-new .filter-height ul li:last-child {
      padding-bottom: 0px; }
  .o-case-studies-new .tech-filter-height {
    z-index: 99; }
  .o-case-studies-new .filter-height ul li {
    padding: 0 0 12px;
    position: relative; }
  .o-case-studies-new .filter-height ul li img {
    margin-right: 10px;
    width: 20px; }
  .o-case-studies-new .filter-height ul li input {
    margin: 0 5px 0 0;
    cursor: pointer; }

.btn-casestudies {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  color: #fff;
  background: #FF5800;
  border-radius: 4px;
  font-size: 15px;
  line-height: 38px;
  text-transform: uppercase;
  border: 2px solid #FF5800;
  padding: 0 27px; }
  @media (min-width: 992px) {
    .btn-casestudies {
      font-size: 16px;
      line-height: 43px; } }
  .btn-casestudies:hover {
    color: #fff;
    border-color: #fff;
    background-color: #14DF7D; }

.drop-down-technologies {
  text-align: center;
  position: relative; }
  @media (min-width: 992px) {
    .drop-down-technologies {
      margin-right: 20px;
      text-align: right; } }
  .drop-down-technologies .selected a img.filter-icon {
    margin-top: -6px; }
  .drop-down-technologies .selected a img.icon-arrow {
    z-index: 10;
    position: relative;
    background-color: #fff; }
  .drop-down-technologies .selected a img.icon-up {
    padding: 10px 5px;
    transform: rotate(180deg);
    -wenkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg); }
    @media (min-width: 992px) {
      .drop-down-technologies .selected a img.icon-up {
        padding: 10px 15px; } }
  .drop-down-technologies .selected a img.icon-down {
    padding: 10px 5px;
    border: 1px solid 0;
    transform: rotate(0deg);
    -wenkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    border-bottom: none; }
    @media (min-width: 992px) {
      .drop-down-technologies .selected a img.icon-down {
        padding: 10px 15px; } }

.o-case-study-cta {
  padding: 20px 0 60px 0;
  text-align: center;
  /* media query for tablet landscape */ }

.paging {
  text-align: center;
  margin: 15px 0 30px 0; }
  @media (min-width: 992px) {
    .paging {
      margin: 30px 0 30px 0; } }
  .paging span {
    padding: 6px 12px;
    font-weight: bold;
    color: #000;
    margin: 5px; }
    .paging span.current {
      background: #FF5800;
      color: #fff; }
  .paging em {
    padding: 10px;
    font-weight: bold;
    color: #000;
    margin: 5px; }
  .paging a {
    color: #000;
    font-weight: bold;
    padding: 6px; }
  .paging ul {
    text-align: center; }
    .paging ul li {
      display: inline-block;
      vertical-align: middle;
      font-size: 15px;
      font-family: "Montserrat",sans-serif;
      font-weight: 500;
      border-radius: 5px;
      color: #000; }
      .paging ul li:first-child {
        border: 1px solid #333333;
        color: #373737;
        margin-right: 5px;
        position: relative;
        background: url(/images/common/left-icon.svg) no-repeat center center; }
        .paging ul li:first-child .flex-center {
          text-indent: 9999px; }
      .paging ul li .flex-center {
        padding: 6px 0;
        cursor: pointer;
        width: 32px;
        height: 32px; }
      .paging ul li .active-page {
        background: #FF5800;
        color: #fff;
        border-radius: 5px;
        height: 34px !important; }
      .paging ul li:last-child {
        border: 1px solid #333333;
        color: #373737;
        margin-left: 5px;
        position: relative;
        background: url(/images/common/right-icon.svg) no-repeat center center; }
        .paging ul li:last-child .flex-center {
          text-indent: 9999px; }
      .paging ul li:nth-child(2) {
        display: inline-block !important;
        position: relative; }
      .paging ul li:nth-last-child(2) {
        display: inline-block !important;
        position: relative; }
        .paging ul li:nth-last-child(2):before {
          content: "...";
          float: left;
          margin: 2px 0 0; }
    .paging ul.prevShep li:nth-child(2):before {
      display: none; }
    .paging ul.midShep li:nth-child(2):before {
      content: "...";
      float: right;
      margin: 2px 0 0; }
    .paging ul.lastShep li:nth-child(2):before {
      content: "...";
      float: right;
      margin: 2px 0 0; }
    .paging ul.lastShep li:nth-last-child(2):before {
      display: none; }
    .paging ul.eSepter li:nth-last-child(2):before {
      display: none; }

/* width */
.filter ::-webkit-scrollbar {
  width: 5px;
  background-color: #ccc; }

/* Track */
.filter ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #FCFCFC;
  border-radius: 10px; }

/* Handle */
.filter ::-webkit-scrollbar-thumb {
  border-radius: 25px;
  height: 30px;
  background-color: #4A4A4A;
  margin-top: 25px; }

.banner-image-over {
  display: none; }

.image-position-case {
  position: relative;
  width: 80px;
  float: left;
  height: 60px; }
  .image-position-case img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -wenkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%); }

.padding-top-90-mlnp {
  padding-top: 90px !important;
  height: 509px; }

.case-study-background-mlnp {
  height: 509px; }

.case-studies-banner {
  background-color: #cc2622db;
  padding: 0 15px !important; }
  .case-studies-banner .mlpn-left {
    padding-left: 0px; }
  .case-studies-banner__banner-content {
    padding-top: 50px; }
    .case-studies-banner__banner-content p {
      background-color: #9E2116;
      display: inline;
      padding: 10px 20px;
      font-size: 18px;
      color: #fff; }
    .case-studies-banner__banner-content h1 {
      font-size: 36px;
      padding: 5px 0 25px;
      line-height: 46px; }

.client-heading-mlnp {
  color: #494949;
  font-size: 55px !important;
  text-transform: uppercase !important;
  margin-bottom: 30px !important;
  margin-top: 35px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700; }

.client-description-mlnp {
  font-size: 17px !important; }

.challenge-bullets {
  display: block; }
  @media (min-width: 992px) {
    .challenge-bullets {
      display: flex; } }
  .challenge-bullets li {
    padding-left: 20px !important;
    padding-right: 10px;
    line-height: 28px !important; }
    .challenge-bullets li:before {
      content: '';
      background-color: #EC3E2E;
      background-image: none !important;
      top: 5px !important;
      width: 4px !important;
      height: 50px !important; }
      @media (min-width: 992px) {
        .challenge-bullets li:before {
          height: 75px !important; } }

.mlnp-watch-text {
  font-size: 24px;
  line-height: 36px;
  color: #fff;
  display: inline;
  position: relative;
  padding-top: 2px !important; }
  .mlnp-watch-text:before {
    content: '';
    position: absolute;
    width: 290px;
    height: 4px;
    background-color: #D0352B;
    left: 0;
    top: -8px; }

.video-mlnp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.video {
  height: 315px; }

.video-box {
  height: 315px;
  position: relative; }

.video-thumbnail {
  z-index: 300;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }

.video-frame {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }

.thumbnail-link .video-thumbnail {
  display: block; }

.thumbnail-link:hover .video-thumbnail {
  display: none; }

#video-container {
  position: relative;
  max-width: 40em;
  margin: auto;
  border: 1px solid #000;
  box-shadow: 0.25em 0.25em 0.25em rgba(0, 0, 0, 0.3); }

#video-container video {
  display: block;
  width: 100%;
  height: auto; }

#video-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  cursor: pointer; }

.hide {
  display: none; }

#starter {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0; }

.gradient-green-blue-mlnp {
  background: #00b35c;
  background: -moz-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, #00b35c), color-stop(47%, #0b8a8a), color-stop(100%, #004475));
  background: -webkit-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  background: -o-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  background: -ms-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  background: linear-gradient(135deg, #00b35c -22%, #0e5579 47%, #004475 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b35c', endColorstr='#004475', GradientType=1 ); }

p.service-txt {
  min-height: auto !important;
  margin-bottom: 0 !important;
  font-family: "Montserrat",sans-serif; }

.download-form {
  max-width: 625px;
  margin: 0 auto;
  padding: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden; }
  .download-form form {
    padding: 20px 40px 20px 40px;
    background-color: #fff; }
    @media (min-width: 992px) {
      .download-form form {
        padding: 20px 60px 40px; } }
  .download-form label {
    display: block;
    margin-bottom: 10px; }
  .download-form input {
    border-bottom: 1px solid #000;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 20px 10px 20px 50px;
    font-size: 16px;
    font-family: "Montserrat",sans-serif;
    font-weight: 500; }
  .download-form .cs-name {
    background: url(images/common/form-icon-1.png) 0 50% no-repeat; }
  .download-form .cs-email {
    background: url(images/common/form-icon-2.png) 0 50% no-repeat; }
  .download-form button {
    margin: 20px 0 0 0; }
  .download-form .popup-heading {
    width: 100%;
    height: 110px;
    padding: 30px 140px 30px 40px;
    position: relative; }
    @media (min-width: 992px) {
      .download-form .popup-heading {
        padding: 30px 110px 30px 60px; } }
    .download-form .popup-heading:after {
      background: url(images/case-study/popup-heading-bg.png) 100% 100% no-repeat;
      content: "";
      position: absolute;
      right: 30px;
      bottom: 0;
      width: 108px;
      height: 88px; }
    .download-form .popup-heading h2 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      color: #fff; }
      @media (min-width: 992px) {
        .download-form .popup-heading h2 {
          font-size: 30px;
          line-height: 47px; } }
    .download-form .popup-heading p {
      color: #fff;
      font-size: 19px;
      line-height: 30px; }
  .download-form .ohnohoney {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1; }

.popup-modal button.close {
  z-index: 999;
  position: relative;
  right: 23% !important;
  top: 42px !important;
  background: transparent !important;
  padding: 10px;
  opacity: 1; }

.cs-banner-pdf {
  padding: 7px 32px;
  float: none;
  margin: 30px auto;
  color: #fff;
  background: url(/images/case-study/icon-pdf-1.svg) 0 50% no-repeat;
  text-decoration: underline;
  font-family: "Montserrat",sans-serif;
  position: relative;
  text-decoration: none;
  font-size: 20px; }
  .case-studies-details .cs-banner-pdf {
    font-size: 16px;
    font-weight: 600;
    padding-right: 0; }
  @media (min-width: 992px) {
    .cs-banner-pdf {
      float: left;
      display: block;
      margin: 30px auto 0; } }
  .cs-banner-pdf:after {
    content: "";
    position: absolute;
    left: 34px;
    bottom: 0px;
    width: 288px;
    background-color: #14DF7D;
    height: 2px;
    display: none; }
    @media (min-width: 992px) {
      .cs-banner-pdf:after {
        display: block; } }
    .case-studies-details .cs-banner-pdf:after {
      display: block;
      width: auto;
      right: 0; }
  .cs-banner-pdf:hover {
    color: #fff; }
    .cs-banner-pdf:hover::after {
      background-color: #fff; }
  .cs-banner-pdf:focus {
    color: #fff; }

.case-study-download {
  padding: 30px 0;
  margin: 60px 0 0 0;
  text-align: center;
  background-color: #091B38; }
  @media (min-width: 992px) {
    .case-study-download {
      background: url(/images/case-study/download-bg.jpg) 50% 50% no-repeat #091B38;
      text-align: left; } }

.case-study-download img.download-icon {
  float: right;
  display: none; }
  @media (min-width: 992px) {
    .case-study-download img.download-icon {
      display: block; } }

.case-study-download h2 {
  color: #fff;
  font-size: 40px;
  line-height: 55px;
  font-weight: bold;
  padding: 15px 0 0;
  text-transform: uppercase; }

.cs-bot-banner-pdf {
  border: 2px solid #FF5800;
  color: #fff;
  background: url(/images/case-study/icon-down-arrow.png) 88% 50% no-repeat;
  font-size: 17px;
  line-height: 24px;
  font-family: "Montserrat",sans-serif;
  padding: 16px 60px 18px 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 40px 0 0 0;
  display: inline-block;
  background-color: #FF5800;
  font-weight: 500; }
  @media screen and (min-width: 992px) {
    .cs-bot-banner-pdf {
      padding: 16px 60px 18px 15px; } }
  @media screen and (min-width: 1200px) {
    .cs-bot-banner-pdf {
      padding: 16px 60px 18px 30px; } }

.cs-bot-banner-pdf:focus {
  color: #fff; }

.cs-bot-banner-pdf:hover {
  background-color: #FF5800;
  color: #fff;
  border: 2px solid #fff; }

.retention-rate {
  background: #10D08E;
  background: -moz-linear-gradient(-45deg, #10D08E 0%, #082653 100%);
  background: -webkit-linear-gradient(-45deg, #10D08E 0%, #082653 100%);
  background: linear-gradient(135deg, #10D08E 0%, #082653 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10D08E', endColorstr='#082653',GradientType=1 );
  padding: 45px 35px 130px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px; }

.hurry {
  width: 80%;
  position: absolute;
  left: 50%;
  top: -210px;
  transform: translate(-50%, -50%);
  padding: 40px 80px 60px;
  text-align: center;
  -webkit-box-shadow: 0px 0px 18px 0px #a6a6a6;
  -moz-box-shadow: 0px 0px 18px 0px #a6a6a6;
  box-shadow: 0px 0px 18px 0px #a6a6a6;
  background: #fff;
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; }

.hurry h3 {
  font-size: 36px;
  margin-bottom: 10px; }

.hurry p {
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;
  font-family: "Montserrat",sans-serif; }

.hurry .btn {
  padding: 12px 0 !important;
  max-width: 240px !important; }
  @media (min-width: 992px) {
    .hurry .btn {
      padding: 19px 0px !important;
      max-width: 350px !important; } }

.o-casestudies-thankyou {
  text-align: center;
  position: relative;
  padding: 170px 0 50px;
  overflow: hidden;
  background: url(images/casestudies-thank-you/thank-you-banner.jpg) 50% 50% no-repeat;
  margin-bottom: 290px; }

.o-casestudies-thankyou h1 {
  font-size: 40px;
  line-height: 44px;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px; }

.o-casestudies-thankyou p {
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  font-family: "Montserrat",sans-serif;
  font-weight: 500;
  padding-bottom: 40px; }

.o-casestudies-thankyou p a {
  text-decoration: underline;
  color: #fff !important; }

.case-thanks .btn {
  z-index: 0;
  color: #fff;
  border: 2px solid #FF5800;
  background-color: #FF5800;
  padding: 12px 0px;
  max-width: 240px; }

.case-thanks .btn:after {
  content: '';
  background: url(images/common/arrow-white.png) no-repeat;
  width: 20px;
  height: 14px;
  display: inline-block;
  margin-left: 15px;
  z-index: -1; }

.case-thanks .social-footer-divider--social-pan {
  border-top: 0 !important; }

.cheaper-better {
  border: 2px solid #15B96A;
  padding: 10px 35px 30px;
  margin-bottom: 30px;
  margin-top: 30px; }
  @media (min-width: 992px) {
    .cheaper-better {
      padding: 50px 35px; } }

.cheaper-better p {
  font-size: 24px;
  line-height: 30px;
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .cheaper-better p {
      font-size: 30px;
      line-height: 36px; } }

.cheaper-better p span {
  display: block;
  font-size: 110px;
  line-height: 120px;
  font-weight: 600; }

.retention-rate1 {
  background: #10D08E;
  background: -moz-linear-gradient(-45deg, #10D08E 0%, #082653 100%);
  background: -webkit-linear-gradient(-45deg, #10D08E 0%, #082653 100%);
  background: linear-gradient(135deg, #10D08E 0%, #082653 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10D08E', endColorstr='#082653',GradientType=1 );
  padding: 20px 35px 0;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .retention-rate1 {
      padding: 45px 35px 0; } }

.retention-rate1 h4 {
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  line-height: 30px;
  padding-bottom: 20px;
  text-align: center; }
  @media (min-width: 992px) {
    .retention-rate1 h4 {
      text-align: left;
      font-size: 28px;
      line-height: 36px; } }

.off-video-pan {
  border: 1px solid #D8D8D8;
  padding: 25px 30px;
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .off-video-pan {
      padding: 20px; } }

.off-video-pan .off-video {
  max-width: 100%;
  float: left; }
  @media (min-width: 992px) {
    .off-video-pan .off-video {
      max-width: 250px;
      margin-right: 10px; } }

.off-video-pan .off-video-txt {
  width: 45%;
  margin-top: 0;
  float: left;
  margin-left: 10px; }

.off-video-pan .off-video-txt h3 {
  font-size: 22px;
  margin-bottom: 5px;
  line-height: 28px; }

.off-video-pan .off-video-txt p {
  margin-bottom: 10px;
  font-family: "Montserrat",sans-serif; }

.off-video-pan .off-video-txt a {
  background-color: transparent !important;
  color: #000 !important;
  border-color: #FF5800 !important; }

.post-pan {
  border: 1px solid #D8D8D8;
  padding: 25px 20px 20px 20px; }
  @media (min-width: 992px) {
    .post-pan {
      padding: 10px 20px 30px 20px; } }

.post-pan h2 {
  font-size: 24px;
  margin-bottom: 30px;
  text-align: center; }
  @media (min-width: 992px) {
    .post-pan h2 {
      text-align: left;
      font-size: 30px; } }

.post-pan ul {
  padding-bottom: 20px; }

.post-pan ul li {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
  overflow: hidden;
  font-family: "Montserrat",sans-serif; }
  @media (min-width: 992px) {
    .post-pan ul li {
      font-size: 18px;
      line-height: 26px; } }

.post-pan ul li span {
  float: left;
  margin-right: 20px; }

.post-pan ul li a {
  color: #000;
  display: inline-block;
  width: 80%;
  padding-top: 10px; }

.post-pan .btn {
  max-width: 300px !important; }

.thank-right-top {
  overflow: hidden; }

.thank-right-top ul {
  overflow: hidden;
  margin-bottom: 20px; }
  .thank-right-top ul li {
    display: inline-block;
    width: 48%;
    vertical-align: top;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat",sans-serif; }
    .thank-right-top ul li span {
      display: inline-block;
      margin-right: 10px;
      width: 25px; }
    .thank-right-top ul li a {
      color: #000; }

.thank-right-top h3 {
  font-size: 28px;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  margin-bottom: 30px; }

.thank-right-top p {
  font-family: "Montserrat",sans-serif;
  font-size: 19px;
  font-weight: 600; }

.t-left {
  border: 1px solid #D8D8D8;
  padding: 30px;
  margin-bottom: 30px;
  float: left;
  width: 48%;
  min-height: 368px; }

.t-right {
  border: 1px solid #D8D8D8;
  padding: 30px;
  margin-bottom: 30px;
  float: right;
  width: 48%;
  min-height: 368px; }

@media (min-width: 992px) {
  .banner-top-position__center {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-60%);
    -webkit-transform: translateY(-60%);
    -moz-transform: translateY(-60%);
    -ms-transform: translateY(-60%); } }

@media (min-width: 992px) {
  .case-studies-details .banner-top-position__center {
    display: block;
    position: relative;
    top: inherit;
    transform: translateY(0); } }

.o-case-studies-new .masonry {
  column-count: inherit;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0 !important;
  justify-content: center; }
  @media screen and (min-width: 768px) {
    .o-case-studies-new .masonry {
      justify-content: space-between; } }
  @media screen and (min-width: 1280px) {
    .o-case-studies-new .masonry {
      justify-content: flex-start; } }

.o-case-studies-new .product {
  width: 100%;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  padding: 0; }
  @media (min-width: 992px) {
    .o-case-studies-new .product {
      margin: 15px 10px; } }
  @media screen and (min-width: 992px) {
    .o-case-studies-new .product {
      flex: 0 0  47%;
      width: 47%; } }
  @media screen and (min-width: 1280px) {
    .o-case-studies-new .product {
      flex: 0 0 31.55%;
      max-width: 31.55%;
      flex-grow: 1; } }

.o-case-studies-new .product .bottom-casestudies {
  text-align: left;
  border-top: 1px solid #EAEAEA;
  padding: 13px 20px 13px;
  margin-top: 15px;
  display: flex;
  align-items: center; }
  @media (min-width: 992px) {
    .o-case-studies-new .product .bottom-casestudies {
      margin-top: auto; } }

.o-case-studies-new .product .bottom-casestudies .btn-casestudies1 {
  padding: 13px 13px 13px 32px;
  float: left;
  display: block;
  margin: 0 auto 0;
  max-width: 200px;
  background-color: #fff;
  color: #333;
  background: url(/images/case-study-home/icon-pdf.svg) 0 50% no-repeat;
  text-transform: uppercase;
  font-family: "Montserrat",sans-serif;
  font-size: 15px;
  font-weight: 400;
  position: relative; }
  @media (min-width: 992px) {
    .o-case-studies-new .product .bottom-casestudies .btn-casestudies1 {
      padding: 13px 32px; } }

#myModal1 .modal-content {
  padding: 0 !important;
  background-color: transparent; }

#myModal1 .modal-body {
  padding: 0 !important; }

.download-form .popup-heading:after {
  background: url(/images/case-study/popup-heading-bg.png) 100% 100% no-repeat;
  content: "";
  position: absolute;
  right: 30px;
  bottom: 0;
  width: 108px;
  height: 88px; }

.download-form input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 20px 10px 20px 50px;
  font-size: 16px;
  font-family: "Montserrat",sans-serif;
  font-weight: 500;
  border: 0;
  border-bottom: 1px solid #000; }

.download-form .cs-name {
  background: url(/images/common/form-icon-1.png) 0 50% no-repeat; }

.download-form .cs-email {
  background: url(/images/common/form-icon-2.png) 0 50% no-repeat; }

.download-form .u-submit {
  display: block;
  text-align: center;
  border-radius: 5px;
  padding: 19px 0px;
  font-size: 18px;
  text-transform: uppercase;
  max-width: 350px;
  text-decoration: none;
  position: relative;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  color: #fff;
  border: 2px solid #FF5800;
  background-color: #FF5800;
  width: 250px;
  margin: 20px 0 0 0; }

.u-submit:after {
  content: '';
  background: url(/images/common/arrow-white.png) no-repeat;
  width: 20px;
  height: 14px;
  display: inline-block;
  margin-left: 15px;
  z-index: -1;
  display: none; }

.download-form p {
  font-family: "Montserrat",sans-serif;
  padding-top: 20px;
  font-size: 14px; }

#myModal1 button.close {
  z-index: 999;
  position: relative;
  right: -88% !important;
  top: 0 !important;
  background: 0 0 !important;
  padding: 10px;
  opacity: 1;
  width: 37px;
  text-align: center;
  background: transparent !important;
  float: right; }
  @media (min-width: 992px) {
    #myModal1 button.close {
      right: -50% !important;
      top: 5px !important;
      margin-left: 325px; } }

.o-thank-you__application {
  padding: 150px 0; }

.o-thank-you__top p {
  font-size: 14px;
  margin-bottom: 30px;
  font-family: "Montserrat",sans-serif; }

.o-thank-you__footer {
  text-align: center;
  padding: 50px 0;
  font-family: "Montserrat",sans-serif;
  font-weight: 300;
  color: #414446;
  font-size: 12px;
  line-height: 15px; }

.case-study-text {
  margin-top: 15px !important;
  display: inline-block !important; }

.mlnp-ch .row {
  flex-direction: row-reverse;
  padding: 60px 0; }

.mlnp-sol {
  padding: 60px 0; }
  .mlnp-sol .mlnp-sol-img {
    display: block;
    margin: 0 auto; }

.mlnp-res {
  padding-top: 65px; }
  .mlnp-res h3 {
    color: #fff; }
  .mlnp-res .padding-bottom-60.img-responsive.center-block {
    display: block;
    margin: 0 auto; }
  .mlnp-res h4 {
    line-height: 32px;
    padding-bottom: 20px;
    font-size: 24px !important;
    color: #333;
    margin-top: 50px !important; }

@media (min-width: 992px) {
  .faq-section .btn_wrapper {
    margin-top: 40px !important; } }

.case-study-bnr {
  background: #0a1b39;
  padding: 80px 0 0 0;
  min-height: 145px; }
  @media screen and (min-width: 992px) {
    .case-study-bnr {
      padding: 80px 0 0 0;
      min-height: 150px; } }
  @media screen and (min-width: 992px) {
    .case-study-bnr {
      min-height: 280px;
      padding: 130px 0 0 200px;
      background: url(/images/case-study/case-studies-banner.png) no-repeat 10% 100% #0a1b39; } }
  .case-study-bnr h1 {
    text-align: center;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    text-transform: uppercase;
    color: #36DF7D; }
    @media (min-width: 992px) {
      .case-study-bnr h1 {
        font-size: 62px; } }

@media (min-width: 992px) {
  .case-studies-class .o-case-studies-new {
    padding-top: 80px; } }

body.case-studies-details .o-skills-hero__case-studies__banner-content,
body.case-studies-details .o-skills-hero__case-studies__banner-content p,
body.case-studies-details .o-case-study__clients__the-client-content h3,
body.case-studies-details .o-case-study__clients__the-client-content p,
body.case-studies-details .o-case-study__clients__the-client-content h4,
body.case-studies-details .o-case-study__clients__the-client-content__services ul li,
body.case-studies-details .o-case-study__clients__the-client-content ul li {
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    body.case-studies-details .o-skills-hero__case-studies__banner-content,
    body.case-studies-details .o-skills-hero__case-studies__banner-content p,
    body.case-studies-details .o-case-study__clients__the-client-content h3,
    body.case-studies-details .o-case-study__clients__the-client-content p,
    body.case-studies-details .o-case-study__clients__the-client-content h4,
    body.case-studies-details .o-case-study__clients__the-client-content__services ul li,
    body.case-studies-details .o-case-study__clients__the-client-content ul li {
      font-family: "Montserrat", sans-serif; } }

body.case-studies-details .o-skills-hero {
  padding: 100px 0 50px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-skills-hero {
      padding: 100px 0 !important; }
      body.case-studies-details .o-skills-hero.padding-100 {
        padding: 120px 0 80px !important; }
        body.case-studies-details .o-skills-hero.padding-100 .o-skills-hero__case-studies__banner-image {
          padding-top: 30px !important; } }

body.case-studies-details .o-skills-hero__case-studies .container > .row {
  display: flex;
  align-items: center; }

body.case-studies-details .o-skills-hero__case-studies .col-sm-6.banner-top-position__center {
  position: relative;
  top: auto;
  transform: none; }
  @media screen and (min-width: 768px) {
    body.case-studies-details .o-skills-hero__case-studies .col-sm-6.banner-top-position__center {
      order: 2; } }

@media (min-width: 992px) {
  body.case-studies-details .o-skills-hero__case-studies__banner-image {
    padding-top: 0; } }

body.case-studies-details .cs-banner-pdf {
  margin: 15px auto 0; }
  @media (min-width: 992px) {
    body.case-studies-details .cs-banner-pdf {
      margin: 30px 0 0; } }

body.case-studies-details .o-case-study__clients .container {
  padding-left: 30px;
  padding-right: 30px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients .container {
      padding-left: 15px;
      padding-right: 15px; } }

body.case-studies-details .pad-80 {
  padding: 50px 0; }
  @media (min-width: 992px) {
    body.case-studies-details .pad-80 {
      padding: 80px 0; } }

body.case-studies-details .padding-80 {
  padding-top: 50px; }
  @media (min-width: 992px) {
    body.case-studies-details .padding-80 {
      padding-top: 80px; } }

body.case-studies-details .font-weight-600 {
  font-weight: 600 !important; }

body.case-studies-details .font-weight-700 {
  font-weight: 700 !important; }

body.case-studies-details .o-case-study__clients-first {
  text-align: center;
  padding-top: 50px; }
  @media screen and (min-width: 768px) {
    body.case-studies-details .o-case-study__clients-first {
      padding-top: 40px;
      text-align: left; } }
  body.case-studies-details .o-case-study__clients-first ul {
    text-align: left; }

body.case-studies-details .o-case-study__clients-title {
  font-size: 30px !important; }

body.case-studies-details .pad-80.o-case-study__clients-banner {
  padding-top: 0; }
  @media (min-width: 992px) {
    body.case-studies-details .pad-80.o-case-study__clients-banner {
      padding-top: 80px; } }

body.case-studies-details .o-case-study__clients-banner {
  padding-bottom: 50px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients-banner {
      padding-bottom: 80px; } }

body.case-studies-details .o-case-study__clients-banner .container {
  padding: 0; }

body.case-studies-details .o-case-study__clients-banner .o-case-study__clients__the-client-image img {
  width: 100%; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients-banner .o-case-study__clients__the-client-image img {
      width: auto; } }

body.case-studies-details .o-case-study__clients-banner .o-case-study__clients__the-client-content {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-top: 30px; }

body.case-studies-details .pad-bot-150 {
  padding-bottom: 105px; }
  @media (min-width: 992px) {
    body.case-studies-details .pad-bot-150 {
      padding-bottom: 150px; } }

body.case-studies-details .o-case-study__clients__the-client-content h3 {
  font-size: 24px;
  line-height: 30px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content h3 {
      font-size: 24px;
      line-height: 36px; } }

body.case-studies-details .o-case-study__clients__the-client-content h4 {
  font-size: 20px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content h4 {
      font-size: 30px; } }

body.case-studies-details .o-case-study__clients__the-client-content p,
body.case-studies-details .o-case-study__clients__the-client-content ul li {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content p,
    body.case-studies-details .o-case-study__clients__the-client-content ul li {
      font-size: 19px;
      line-height: 30px; } }

body.case-studies-details .o-case-study__clients__the-client-content p {
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content p {
      margin-bottom: 25px; } }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content p[class="font-weight-600"] {
      margin-bottom: 10px; } }

body.case-studies-details .o-case-study__clients__the-client-content > p:last-of-type {
  margin-bottom: 0; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content > p:last-of-type {
      margin-bottom: 25px; } }

body.case-studies-details .o-case-study__clients__the-client-content .font-size-24-i {
  font-size: 20px !important; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content .font-size-24-i {
      font-size: 24px !important; } }

body.case-studies-details .o-case-study__clients__the-client-content__services {
  padding-top: 0; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content__services {
      padding-top: 60px; } }

body.case-studies-details .o-case-study__clients__the-client-content__services ul {
  margin-bottom: 0; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content__services ul {
      margin-bottom: 15px; } }

body.case-studies-details .o-case-study__clients__the-client-content__services ul li {
  padding: 0 5px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content__services ul li {
      padding: 0 15px 15px; } }

body.case-studies-details .o-case-study__clients__the-client-content__services ul li:after {
  display: none; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content__services ul li:after {
      display: block; } }

body.case-studies-details .o-case-study__clients__the-client-content__services ul li span {
  font-size: 14px;
  line-height: normal; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content__services ul li span {
      font-size: 18px;
      line-height: 24px; } }

@media (min-width: 992px) {
  body.case-studies-details .o-case-study__clients__the-client-content__services + h3, body.case-studies-details .o-case-study__clients__the-client-content__services + h4 {
    margin-top: 20px; } }

body.case-studies-details .o-case-study__clients__the-client-image {
  text-align: center; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-image {
      text-align: left; } }

body.case-studies-details .padding-all-60 {
  padding: 30px; }
  @media (min-width: 992px) {
    body.case-studies-details .padding-all-60 {
      padding: 60px; } }

body.case-studies-details .o-case-study__clients__the-results {
  background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  padding-top: 55px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-results {
      padding-top: 100px; } }

body.case-studies-details .o-case-study__clients__the-results__div {
  padding-top: 0; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-results__div {
      padding-top: 10px; } }

body.case-studies-details .o-case-study__clients__the-client-content .the-results {
  top: -55px;
  max-width: 158px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-client-content .the-results {
      top: -70px;
      max-width: none; } }

body.case-studies-details .o-case-study__clients__the-results .o-case-study__clients__the-client-content h3 {
  margin-bottom: 0; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-results .o-case-study__clients__the-client-content h3 {
      margin-bottom: 20px; } }

body.case-studies-details .o-case-study__clients__the-results__div h4,
body.case-studies-details .font-size-24-i {
  font-size: 20px;
  line-height: 28px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-results__div h4,
    body.case-studies-details .font-size-24-i {
      font-size: 30px;
      line-height: normal; } }

body.case-studies-details .o-case-study__clients__the-results__div p {
  font-size: 16px; }
  @media (min-width: 992px) {
    body.case-studies-details .o-case-study__clients__the-results__div p {
      font-size: 19px; } }

body.case-studies-details .results-content {
  padding-bottom: 5px; }
  @media (min-width: 992px) {
    body.case-studies-details .results-content {
      padding-bottom: 60px; } }

body.case-studies-details .case-study-download {
  margin-top: 50px;
  padding: 50px 0;
  background: url(/images/case-study/download-mob-bg.jpg) 50% 50% no-repeat #091b38;
  background-size: auto 100%; }
  @media (min-width: 992px) {
    body.case-studies-details .case-study-download {
      margin-top: 60px;
      padding: 30px 0;
      background: url(/images/case-study/download-bg.jpg) 50% 50% no-repeat #091b38;
      background-size: 100% auto; } }

body.case-studies-details .case-study-download img.download-icon {
  display: block;
  margin: 0 auto;
  float: none;
  max-width: 117px; }
  @media (min-width: 992px) {
    body.case-studies-details .case-study-download img.download-icon {
      max-width: 100%; } }

body.case-studies-details .cs-bot-banner-pdf {
  margin-top: 15px; }
  @media (min-width: 992px) {
    body.case-studies-details .cs-bot-banner-pdf {
      margin-top: 40px; } }

body.case-studies-details .case-studies-banner .cs-bot-banner-pdf {
  margin-top: 15px; }

body.case-studies-details .case-study-download h2 {
  font-size: 24px;
  line-height: 32px;
  max-width: 225px;
  margin: 0 auto; }
  @media (min-width: 992px) {
    body.case-studies-details .case-study-download h2 {
      font-size: 40px;
      line-height: 55px;
      max-width: none; } }

body.case-studies-thanks .hurry-section-cs .hurry .btn-orange {
  padding-right: 0 !important; }

body.case-studies-thanks .o-casestudies-thankyou {
  padding-bottom: 0;
  margin: 0; }
  @media (min-width: 992px) {
    body.case-studies-thanks .o-casestudies-thankyou {
      padding-bottom: 50px;
      margin-bottom: 290px; } }
  body.case-studies-thanks .o-casestudies-thankyou h1 {
    font-size: 30px;
    max-width: 225px;
    margin: 0 auto; }
    @media (min-width: 992px) {
      body.case-studies-thanks .o-casestudies-thankyou h1 {
        font-size: 40px;
        max-width: none; } }
  body.case-studies-thanks .o-casestudies-thankyou p {
    font-size: 18px; }
    @media (min-width: 992px) {
      body.case-studies-thanks .o-casestudies-thankyou p {
        font-size: 24px; } }

body.case-studies-thanks .survey-thank-pg {
  padding-top: 50px; }

body.case-studies-thanks .hurry {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  padding: 30px;
  transform: none; }
  @media (min-width: 992px) {
    body.case-studies-thanks .hurry {
      width: 70%;
      position: absolute;
      left: 50%;
      top: -200px;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      padding: 40px 80px 60px; } }
  body.case-studies-thanks .hurry h3 {
    font-size: 23px;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      body.case-studies-thanks .hurry h3 {
        font-size: 36px;
        margin-bottom: 10px; } }
  body.case-studies-thanks .hurry p {
    font-size: 16px;
    line-height: 28px; }
    @media (min-width: 992px) {
      body.case-studies-thanks .hurry p {
        font-size: 24px;
        line-height: 40px; } }
  body.case-studies-thanks .hurry .btn-center-block {
    width: auto; }
    @media (min-width: 992px) {
      body.case-studies-thanks .hurry .btn-center-block {
        width: 350px; } }
  body.case-studies-thanks .hurry .btn {
    width: auto;
    font-size: 16px; }
    @media (min-width: 992px) {
      body.case-studies-thanks .hurry .btn {
        font-size: 18px; } }

body.case-studies-thanks .t-left,
body.case-studies-thanks .t-right {
  width: 100%; }
  @media (min-width: 992px) {
    body.case-studies-thanks .t-left,
    body.case-studies-thanks .t-right {
      width: 48%; } }

body.case-studies-thanks .off-video-pan .off-video-txt {
  width: 100%; }
  @media (min-width: 992px) {
    body.case-studies-thanks .off-video-pan .off-video-txt {
      width: 45%; } }

body.case-studies-thanks .off-video {
  margin: 0 0 15px; }
  @media (min-width: 992px) {
    body.case-studies-thanks .off-video {
      margin-bottom: 0; } }

body.case-studies-thanks .post-pan ul li a {
  width: 68%; }
  @media (min-width: 992px) {
    body.case-studies-thanks .post-pan ul li a {
      width: 80%; } }

body.case-studies-thanks .thank-right-top ul li a {
  font-size: 13px; }
  @media (min-width: 992px) {
    body.case-studies-thanks .thank-right-top ul li a {
      font-size: 16px; } }

body.case-studies-thanks .btn {
  margin-left: auto !important;
  margin-right: auto !important; }
  @media (min-width: 992px) {
    body.case-studies-thanks .btn {
      margin-left: 0 !important; } }

body.case-studies-thanks .off-video-txt {
  margin-left: 0; }
  @media (min-width: 992px) {
    body.case-studies-thanks .off-video-txt {
      margin-left: 20px; } }

.case-studies-thanks .o-navigation-bar img {
  padding-top: 0; }
  @media (min-width: 992px) {
    .case-studies-thanks .o-navigation-bar img {
      padding-top: 15px; } }

.case-studies-thanks .o-casestudies-thankyou p {
  padding-bottom: 15px; }
  @media (min-width: 992px) {
    .case-studies-thanks .o-casestudies-thankyou p {
      padding-bottom: 40px; } }

.case-studies-thanks .t-right {
  min-height: inherit; }
  @media (min-width: 992px) {
    .case-studies-thanks .t-right {
      min-height: 368px; } }

.case-studies-thanks .o-footer p {
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .case-studies-thanks .o-footer p {
      font-family: "Montserrat", sans-serif; } }

.hurry-section-cs {
  margin-top: 45px; }
  @media (min-width: 992px) {
    .hurry-section-cs {
      margin-top: 0; } }

.o-skills-hero__case-studies .mobile-off {
  display: none !important; }
  @media (min-width: 992px) {
    .o-skills-hero__case-studies .mobile-off {
      display: inline-block !important; } }

.o-skills-hero__case-studies .desktop-off {
  display: inline-block !important; }
  @media (min-width: 992px) {
    .o-skills-hero__case-studies .desktop-off {
      display: none !important; } }

.o-case-study__clients__the-client-image picture img[src="images/case-study/the-solution.jpg"] {
  margin-bottom: 40px; }

.o-case-study__clients__the-client-image picture img[src="images/case-study/the-solution.png"] {
  margin-bottom: 60px; }

@media (min-width: 992px) {
  .ch-content {
    padding-left: 25px !important; } }

@media (min-width: 992px) {
  .sol-content,
  .cli-content {
    padding-left: 25px !important; } }

@media (min-width: 992px) {
  .sol {
    padding-top: 80px; } }

.small-img-text {
  margin-bottom: 20px;
  align-items: center;
  padding: 50px 30px 0; }
  @media (min-width: 992px) {
    .small-img-text {
      align-items: flex-start;
      padding: 0; } }
  .small-img-text h2 {
    max-width: 290px;
    padding-left: 20px;
    padding-top: 0;
    font-size: 28px;
    line-height: 36px; }
    @media (min-width: 992px) {
      .small-img-text h2 {
        font-size: 42px;
        line-height: 52px;
        padding-top: 10px; } }

.new-style h3 {
  font-size: 30px;
  line-height: 46px;
  font-weight: 500; }

.case-studies-details .gradient-green-blue {
  background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%); }

form#brandfilter {
  display: inline-block; }

.case-studies-class .process-section h2 {
  margin-bottom: 20px !important; }

.o-skills-hero__case-studies .row {
  flex-direction: column-reverse; }
  @media screen and (min-width: 768px) {
    .o-skills-hero__case-studies .row {
      flex-direction: inherit; } }

.case-study-bnr:after {
  background-size: 70%; }

.o-case-studies-slider .slick-track {
  background: #fff; }

.list-box {
  margin-bottom: 25px !important; }
  .list-box li {
    padding: 15px !important; }
    .list-box li:before {
      display: none; }
    .list-box li p {
      margin-bottom: 10px !important;
      font-size: 16px !important;
      padding-left: 30px; }
    .list-box li .title {
      position: relative;
      padding-left: 30px;
      margin-bottom: 20px !important;
      font-size: 19px !important; }
      .list-box li .title:before {
        width: 20px;
        height: 20px;
        top: 5px;
        background-size: 100%;
        content: '';
        background-image: url(/images/common/green-arrow.png);
        position: absolute;
        left: 0; }

.cs-table table {
  font-size: 14px;
  color: #333; }
  .cs-table table tr td {
    font-weight: 500; }

.cs-finance-img-wrp {
  text-align: left; }
  .cs-finance-img-wrp .l-15 {
    margin-left: 15px; }

.green-arrow {
  background: url(/images/case-study/green-right-arrow.png) no-repeat center center;
  width: 11px;
  height: 17px;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 9px;
  background-size: 8px; }

.dm-box {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid #05b281;
  margin-right: 5px;
  display: none; }

.table td {
  position: relative; }

@media (min-width: 992px) {
  .results-content .table {
    margin: 20px 0 30px; } }

.car-box {
  margin-bottom: 30px; }
  .car-box img {
    box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    border-width: 1px 1px 1px 1px;
    border-color: #ead6d6;
    border-style: solid;
    border-bottom-color: #e6e0e0; }

/* ANCHOR Thank you pages */
.survey-thank-pg {
  padding-top: 99px; }
  .survey-thank-pg .o-navigation-bar {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 110;
    width: 100%;
    padding: 20px 0; }
  .survey-thank-pg .o-skills-hero__thank-you-heading {
    text-align: center;
    text-align: center;
    background: #10D08E;
    background: -moz-linear-gradient(-45deg, #10D08E 0%, #082653 100%);
    background: -webkit-linear-gradient(-45deg, #10D08E 0%, #082653 100%);
    background: linear-gradient(135deg, #10D08E 0%, #082653 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10D08E', endColorstr='#082653',GradientType=1 );
    margin-bottom: 40px; }
    .survey-thank-pg .o-skills-hero__thank-you-heading h1.js-hero-title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0;
      margin-top: 20px; }
      @media (min-width: 992px) {
        .survey-thank-pg .o-skills-hero__thank-you-heading h1.js-hero-title {
          font-size: 40px;
          line-height: 44px; } }
    .survey-thank-pg .o-skills-hero__thank-you-heading p {
      font-size: 16px;
      line-height: 28px;
      color: #fff;
      font-weight: 500; }
  .survey-thank-pg .col-md-10 {
    margin: 0 auto; }
  .survey-thank-pg .hurry {
    box-shadow: 0px 0px 18px 0px #a6a6a6;
    background: #fff;
    text-align: center;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 40px; }
    @media (min-width: 992px) {
      .survey-thank-pg .hurry {
        width: 648px;
        padding: 40px 80px 60px; } }
    .survey-thank-pg .hurry:before {
      display: none; }
  .survey-thank-pg .o-photo-gallery .close {
    top: -15px;
    right: -13px;
    line-height: 29px; }

.hurry-section {
  position: relative; }
  .hurry-section h3 {
    font-size: 30px;
    margin-bottom: 10px; }
  .hurry-section p {
    font-size: 16px;
    line-height: 28px;
    color: #333;
    font-weight: 300; }
  .hurry-section .js-talk-to-us {
    font-size: 16px;
    line-height: 51px;
    padding: 0 24px;
    border-radius: 10px;
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
    color: #fff;
    background: #FF5800;
    border-radius: 4px;
    font-size: 16px;
    line-height: 43px;
    text-transform: uppercase;
    border: 2px solid #FF5800;
    padding: 0 27px; }
    @media (min-width: 992px) {
      .hurry-section .js-talk-to-us {
        line-height: 60px;
        font-size: 20px; } }
    .hurry-section .js-talk-to-us:after {
      content: '';
      display: inline-block;
      width: 7px;
      height: 13px;
      background: url(/images/common/icon-arrow-next-small.svg) 50% 50% no-repeat;
      background-size: 7px auto;
      margin-left: 15px; }

.j-center {
  justify-content: center; }

.fifty-section .cheaper-better {
  margin-top: 0; }
  .fifty-section .cheaper-better p span {
    color: #414446; }

.fifty-section .js-talk-to-us {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  color: #fff;
  background: #FF5800;
  border-radius: 4px;
  font-size: 16px;
  line-height: 43px;
  text-transform: uppercase;
  border: 2px solid #FF5800;
  padding: 0 27px; }
  .fifty-section .js-talk-to-us:after {
    content: '';
    display: inline-block;
    width: 7px;
    height: 13px;
    background: url(/images/common/icon-arrow-next-small.svg) 50% 50% no-repeat;
    background-size: 7px auto;
    margin-left: 15px; }

.fifty-section .retention-rate h4 {
  font-size: 26px;
  color: #fff;
  font-weight: normal;
  line-height: 30px;
  padding-bottom: 20px;
  font-weight: 600; }

.fifty-section .retention-rate .c-btn.js-talk-to-us.js-hero-button {
  background-color: transparent;
  color: #fff;
  border-color: #fff; }

.fifty-section .retention-rate img {
  position: absolute;
  left: 0;
  bottom: 0; }

.fifty-section .off-video-pan p {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 28px; }

.fifty-section .off-video-pan .off-video-txt a:after {
  background: url(/images/common/icon-arrow-next-o.svg) 50% 50% no-repeat; }

.fifty-section .post-pan {
  padding: 20px; }
  @media (min-width: 992px) {
    .fifty-section .post-pan {
      padding: 40px; } }

.c-bodytext-5 {
  font-size: 12px;
  line-height: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #414446; }

.survey-thank-pg-final .o-skills-hero__thank-you-heading {
  margin-bottom: 75px; }

.case-study-thank-pg .thank-right-top .btn, .case-study-thank-pg .cheaper-better .btn, .case-study-thank-pg .retention-rate1 .btn {
  display: block;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  z-index: 0;
  color: #fff;
  border: 2px solid #FF5800;
  background-color: #FF5800;
  padding: 12px 0px;
  max-width: 240px;
  margin: 0; }
  @media (min-width: 992px) {
    .case-study-thank-pg .thank-right-top .btn, .case-study-thank-pg .cheaper-better .btn, .case-study-thank-pg .retention-rate1 .btn {
      font-size: 18px; } }
  .case-study-thank-pg .thank-right-top .btn:after, .case-study-thank-pg .cheaper-better .btn:after, .case-study-thank-pg .retention-rate1 .btn:after {
    content: '';
    background: url(/images/common/arrow-white.png) no-repeat;
    width: 20px;
    height: 14px;
    display: inline-block;
    margin-left: 15px;
    z-index: -1; }

.case-study-thank-pg .off-video-pan .btn {
  background-color: transparent !important;
  color: #000 !important;
  border-color: #FF5800 !important;
  z-index: 0;
  border: 2px solid #FF5800;
  padding: 12px 0px;
  max-width: 240px;
  font-size: 16px; }
  @media (min-width: 992px) {
    .case-study-thank-pg .off-video-pan .btn {
      font-size: inherit; } }

.case-study-thank-pg .post-pan .btn {
  z-index: 0;
  color: #fff;
  border: 2px solid #FF5800;
  background-color: #FF5800;
  padding: 12px 0px;
  max-width: 300px !important;
  margin: 7px 0;
  font-size: 16px; }
  @media (min-width: 992px) {
    .case-study-thank-pg .post-pan .btn {
      font-size: inherit; } }
  .case-study-thank-pg .post-pan .btn::after {
    content: '';
    background: url(/images/common/arrow-white.png) no-repeat;
    width: 20px;
    height: 14px;
    display: inline-block;
    margin-left: 15px;
    z-index: -1; }

.o-casestudies-thankyou {
  text-align: center;
  position: relative;
  padding: 170px 0 50px;
  overflow: hidden;
  background: url(/images/common/thank-you-banner.jpeg) 50% 50% no-repeat;
  margin-bottom: 290px; }

.case-study-thank-pg .o-casestudies-thankyou {
  padding-top: 75px; }

.hurry-section-cs .hurry {
  width: 70%;
  position: absolute;
  left: 50%;
  top: -210px;
  transform: translate(-50%, -50%);
  padding: 40px 80px 60px;
  text-align: center;
  box-shadow: 0px 0px 18px 0px #a6a6a6;
  background: #fff;
  text-align: center; }
  .hurry-section-cs .hurry:before {
    display: none; }
  @media (min-width: 992px) {
    .hurry-section-cs .hurry .btn-orange {
      padding-right: 35px !important; } }
  .hurry-section-cs .hurry h3 {
    font-size: 36px;
    margin-bottom: 10px; }
  .hurry-section-cs .hurry p {
    font-size: 24px;
    line-height: 40px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif; }

.page-404 .o-hero-small {
  padding: 80px 0 0; }

.page-404 .o-hero-small .js-hero-sub-title {
  color: #333;
  font-size: 24px;
  font-weight: 700;
  margin: 10px 0 0 0;
  font-family: "Montserrat", sans-serif; }

.page-404 .o-hero-small__shadow {
  margin: 30px auto;
  text-align: center; }
  .page-404 .o-hero-small__shadow span {
    display: inline-block;
    width: 100px;
    height: 8px;
    background: #333; }

.page-404 .common-bg-holder {
  padding: 80px 0 60px; }
  .page-404 .common-bg-holder p {
    font-size: 16px;
    line-height: 28px;
    font-family: "Montserrat", sans-serif; }
  .page-404 .common-bg-holder .c-btn {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
    color: #fff;
    background: #FF5800;
    border-radius: 4px;
    font-size: 16px;
    line-height: 43px;
    text-transform: uppercase;
    border: 2px solid #FF5800;
    padding: 0 27px;
    line-height: 60px;
    font-size: 20px; }
    .page-404 .common-bg-holder .c-btn:after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 24px;
      background-size: 14px auto;
      content: '';
      display: inline-block;
      width: 7px;
      height: 13px;
      background: url(/images/common/icon-arrow-next-bg.svg) 50% 50% no-repeat;
      background-size: 7px auto;
      margin-left: 15px; }

.st-header {
  background-color: #F7F8FA;
  padding: 20px 0 0px; }
  @media (min-width: 992px) {
    .st-header {
      padding: 60px 0 35px; } }
  .st-header h1 {
    font-size: 24px;
    line-height: 30px; }
    @media (min-width: 992px) {
      .st-header h1 {
        font-size: 45px;
        margin-bottom: 20px; } }
  .st-header p {
    font-size: 16px;
    line-height: 24px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600; }
    @media (min-width: 992px) {
      .st-header p {
        font-size: 22px;
        line-height: 34px; } }
  .st-header img {
    float: left;
    margin-right: 20px;
    width: 90px; }
    @media (min-width: 992px) {
      .st-header img {
        width: auto; } }
  .st-header .download-guide-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px; }
    .st-header .download-guide-wrapper h1 {
      margin: 30px 0 40px; }
  .st-header .download-guide-icon img {
    margin-right: 0; }
  .st-header .s1000d-thank-dwnld-btn {
    font-family: 'poppins';
    background-color: #ff5800;
    border: 2px solid #ff5800;
    font-size: 18px;
    color: #fff;
    border-radius: 5px;
    font-weight: 600;
    text-transform: uppercase;
    width: 230px;
    height: 71px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s all ease-in-out; }
    .st-header .s1000d-thank-dwnld-btn svg {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      transition: .3s all ease-in-out; }
    .st-header .s1000d-thank-dwnld-btn:hover {
      background-color: transparent;
      color: #ff5800; }
      .st-header .s1000d-thank-dwnld-btn:hover svg {
        stroke: #ff5800; }
        .st-header .s1000d-thank-dwnld-btn:hover svg path {
          fill: #ff5800;
          stroke: #ff5800; }

.st-header-1 {
  background: url(/images/common/thankyou-bg.svg) 50% 50% no-repeat;
  text-align: center; }
  .st-header-1 p {
    font-size: 16px;
    line-height: 24px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600; }
    @media (min-width: 992px) {
      .st-header-1 p {
        font-size: 22px;
        line-height: 34px; } }

.s-thank .post-pan ul li a {
  width: 67%;
  padding-top: 0; }
  @media (min-width: 992px) {
    .s-thank .post-pan ul li a {
      width: 80%; } }

.survey-thank-pg-final .thank-you-final-banner {
  padding-top: 35px !important;
  padding-bottom: 5px !important;
  margin-bottom: 50px; }
  .survey-thank-pg-final .thank-you-final-banner .o-skills-hero__icon {
    max-width: 80px;
    margin: 0 auto; }
  .survey-thank-pg-final .thank-you-final-banner h1.js-hero-title {
    font-size: 32px;
    line-height: 32px; }

@media screen and (min-width: 992px) {
  .post-pan ul li {
    font-size: 17px; } }

.post-pan ul li a {
  padding-top: 10px; }
  @media screen and (min-width: 992px) {
    .post-pan ul li a {
      padding-top: 0; } }

/* ANCHOR Referral program pages */
body[data-page="referral-program"] .o-services-hero {
  background-image: none;
  text-align: left;
  background-color: #F1F4F8;
  padding: 0 0 50px;
  position: relative; }
  @media (min-width: 992px) {
    body[data-page="referral-program"] .o-services-hero {
      padding: 10px 0px 70px 0;
      background: url(/images/common/header-pattern-r.png) 100% 0 no-repeat #F1F4F8;
      background-size: 30%; } }
  body[data-page="referral-program"] .o-services-hero .logo {
    display: block;
    margin: 0 auto 30px; }
    @media (min-width: 992px) {
      body[data-page="referral-program"] .o-services-hero .logo {
        margin: 0 0 70px 0;
        display: inline; } }
  body[data-page="referral-program"] .o-services-hero p.med {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 28px;
    font-family: "Montserrat", sans-serif;
    text-align: center; }
    @media (min-width: 992px) {
      body[data-page="referral-program"] .o-services-hero p.med {
        text-align: left; } }
  body[data-page="referral-program"] .o-services-hero h1 {
    font-size: 38px;
    line-height: 50px;
    color: #333;
    margin-bottom: 20px;
    font-weight: 600;
    text-align: center; }
    @media (min-width: 992px) {
      body[data-page="referral-program"] .o-services-hero h1 {
        text-align: left; } }
  body[data-page="referral-program"] .o-services-hero p.r-text1 {
    font-size: 24px;
    font-weight: 500;
    color: #25c351;
    line-height: 34px;
    font-family: "Montserrat", sans-serif;
    text-align: center; }
    @media (min-width: 992px) {
      body[data-page="referral-program"] .o-services-hero p.r-text1 {
        text-align: left; } }
  body[data-page="referral-program"] .o-services-hero p.r-text2 {
    font-size: 12px;
    line-height: 17px;
    font-family: "Montserrat", sans-serif;
    text-align: center; }
    @media (min-width: 992px) {
      body[data-page="referral-program"] .o-services-hero p.r-text2 {
        text-align: left; } }

body[data-page="referral-program"] .o-services-hero__background-1 {
  background: url(/images/common/header-bottom-bg.png) 0 100% no-repeat;
  background-size: 40%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 150px; }

body[data-page="referral-program"] .container {
  padding: 40px 20px; }

body[data-page="referral-program"] .o-services-hero .refer-client-form {
  background: url(/images/contact-us/dashed-line.png) 0 0 repeat-x #fff;
  padding: 40px;
  border-radius: 10px;
  margin-top: 50px; }
  body[data-page="referral-program"] .o-services-hero .refer-client-form h2 {
    font-size: 28px;
    line-height: 32px;
    font-weight: 600;
    padding: 0;
    margin-bottom: 0;
    color: #333; }
  body[data-page="referral-program"] .o-services-hero .refer-client-form h3 {
    font-size: 16px;
    line-height: 32px;
    font-weight: 400; }
  body[data-page="referral-program"] .o-services-hero .refer-client-form input,
  body[data-page="referral-program"] .o-services-hero .refer-client-form textarea {
    outline: none; }
  body[data-page="referral-program"] .o-services-hero .refer-client-form .u-name {
    border-bottom: 1px solid #C9C8C8;
    padding: 20px 20px 20px 40px;
    color: #333;
    width: 100%;
    margin-bottom: 10px;
    font-weight: 400; }
  body[data-page="referral-program"] .o-services-hero .refer-client-form .u-email {
    border-bottom: 1px solid #C9C8C8;
    padding: 20px 20px 20px 40px;
    color: #333;
    width: 100%;
    margin-bottom: 10px;
    font-weight: 400; }
  body[data-page="referral-program"] .o-services-hero .refer-client-form .u-name {
    border-bottom: 1px solid #C9C8C8;
    padding: 20px 20px 20px 40px;
    color: #333;
    width: 100%;
    margin-bottom: 10px;
    font-weight: 400; }
  body[data-page="referral-program"] .o-services-hero .refer-client-form .phone {
    border-bottom: 1px solid #C9C8C8;
    padding: 20px 20px 35px 0;
    color: #333;
    width: 100%;
    margin-bottom: 0;
    font-weight: 400;
    position: relative; }
    body[data-page="referral-program"] .o-services-hero .refer-client-form .phone input {
      width: 75%;
      border-bottom: 0; }
    body[data-page="referral-program"] .o-services-hero .refer-client-form .phone #r-phoneNumber {
      position: absolute;
      top: 19px;
      left: 60px; }
  body[data-page="referral-program"] .o-services-hero .refer-client-form .terms {
    margin: 20px 0;
    display: block;
    font-size: 16px; }
    body[data-page="referral-program"] .o-services-hero .refer-client-form .terms input {
      margin-right: 10px; }
  body[data-page="referral-program"] .o-services-hero .refer-client-form .r-submit {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
    color: #fff;
    background: #FF5800;
    text-transform: uppercase;
    border: 2px solid #FF5800;
    padding: 0 27px;
    border-radius: 10px;
    margin-bottom: 20px;
    line-height: 60px;
    font-size: 20px;
    max-width: 250px; }
    body[data-page="referral-program"] .o-services-hero .refer-client-form .r-submit:after {
      content: '';
      display: inline-block;
      width: 7px;
      height: 13px;
      background: url(/images/common/icon-arrow-next-small.svg) 50% 50% no-repeat;
      background-size: 7px auto;
      margin-left: 15px; }
  body[data-page="referral-program"] .o-services-hero .refer-client-form input {
    margin-bottom: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0; }
  body[data-page="referral-program"] .o-services-hero .refer-client-form p {
    font-size: 13px;
    line-height: 18px;
    font-family: "Montserrat", sans-serif; }
    body[data-page="referral-program"] .o-services-hero .refer-client-form p i {
      float: left;
      padding: 0 10px 20px 0; }
  body[data-page="referral-program"] .o-services-hero .refer-client-form .niceCountryInputMenuFilter input {
    width: 100%;
    width: calc(100% - 10px);
    margin: 5px;
    padding: 5px; }
  body[data-page="referral-program"] .o-services-hero .refer-client-form .niceCountryInputMenuDropdownContent {
    border: 1px solid #a8a8a8;
    border-top: 0;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    top: 96px;
    background: #fff;
    width: 100%;
    z-index: 9; }
    body[data-page="referral-program"] .o-services-hero .refer-client-form .niceCountryInputMenuDropdownContent a {
      height: 25px;
      line-height: 25px;
      display: block;
      width: 100%;
      color: black !important;
      overflow: hidden;
      text-decoration: none;
      margin-bottom: 5px;
      font-weight: normal;
      padding: 0 10px; }
      body[data-page="referral-program"] .o-services-hero .refer-client-form .niceCountryInputMenuDropdownContent a:hover {
        background-color: gray !important;
        color: white !important;
        text-decoration: none; }
      body[data-page="referral-program"] .o-services-hero .refer-client-form .niceCountryInputMenuDropdownContent a img {
        margin-right: 10px; }
      body[data-page="referral-program"] .o-services-hero .refer-client-form .niceCountryInputMenuDropdownContent a span {
        font-weight: 300 !important; }
  body[data-page="referral-program"] .o-services-hero .refer-client-form .niceCountryInputMenu {
    background: white !important;
    color: black !important;
    border: 0;
    cursor: pointer;
    float: left;
    margin-right: 10px; }
    body[data-page="referral-program"] .o-services-hero .refer-client-form .niceCountryInputMenu .niceCountryInputMenuDefaultText {
      width: 35px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    body[data-page="referral-program"] .o-services-hero .refer-client-form .niceCountryInputMenu a {
      color: black !important; }
    body[data-page="referral-program"] .o-services-hero .refer-client-form .niceCountryInputMenu .niceCountryInputMenuCountryFlag {
      border: 1px solid #d3d3d3;
      width: 30px;
      height: auto;
      margin-left: 5px;
      margin-right: 5px; }
    body[data-page="referral-program"] .o-services-hero .refer-client-form .niceCountryInputMenu .niceCountryInputMenuDropdown {
      height: 25px;
      width: 21px;
      float: right;
      line-height: 25px;
      text-align: center;
      position: relative;
      right: 0;
      color: black; }

.o-what-you-get-from-us--hit {
  text-align: center;
  padding: 40px 0; }
  .o-what-you-get-from-us--hit h2 {
    font-size: 38px;
    line-height: 48px;
    font-weight: 600;
    color: #333;
    margin: 0px 0px 50px 0px;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .o-what-you-get-from-us--hit h2 {
        font-size: 48px;
        line-height: 58px; } }
  .o-what-you-get-from-us--hit h3 {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 44px; }
  .o-what-you-get-from-us--hit p {
    font-size: 16px;
    line-height: 28px;
    margin: 0px 0px 25px 0px;
    font-family: "Montserrat", sans-serif; }

.srvy-thanku-ref {
  margin-bottom: 50px; }

.recaptcha-style {
  color: red;
  font-size: 14px;
  margin-top: 5px; }

/* ANCHOR Csr pages */
/* Global Utility Style*/
.mx-auto,
.ml-auto {
  margin-left: auto; }

.mx-auto,
.mr-auto {
  margin-right: auto; }

@media (min-width: 992px) {
  .mr-lg-0 {
    margin-right: 0; } }

.w-75 {
  width: 75%; }

.align-items-center {
  align-items: center; }

.img-fluid {
  max-width: 100%;
  height: auto; }

@media (min-width: 992px) {
  .order-lg-2 {
    order: 2; } }

/** CSR Page **/
/* Section Global */
.o-section {
  padding-top: 50px;
  padding-bottom: 50px; }
  @media (min-width: 992px) {
    .o-section {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media (min-width: 992px) {
    .o-section--think-green {
      padding-top: 70px;
      padding-bottom: 80px; } }
  .o-section__header {
    text-align: center;
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      .o-section__header {
        margin-bottom: 50px; } }
  .o-section__desc {
    margin-bottom: 0;
    font-family: "Montserrat", sans-serif; }
  @media (max-width: 767px) {
    .o-section__container-mobile {
      padding-left: 30px;
      padding-right: 30px; } }

/* Hero */
.o-skills-hero--csr {
  background: #232b34;
  padding-bottom: 0; }

@media (min-width: 1800px) {
  .o-skills-hero--csr .o-skills-hero__background {
    background-size: cover; } }

.o-skills-hero--csr .o-skills-hero__background::after {
  content: "";
  position: absolute;
  z-index: 2;
  width: 70%;
  height: 100%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#232b34+0,232b34+50,7db9e8+99&1+0,1+50,0+100 */
  background: -moz-linear-gradient(left, #232b34 0%, #232b34 50%, rgba(125, 185, 232, 0.02) 99%, rgba(125, 185, 232, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #232b34 0%, #232b34 50%, rgba(125, 185, 232, 0.02) 99%, rgba(125, 185, 232, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #232b34 0%, #232b34 50%, rgba(125, 185, 232, 0.02) 99%, rgba(125, 185, 232, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#232b34', endColorstr='#007db9e8',GradientType=1 );
  /* IE6-9 */ }

@media (min-width: 992px) {
  .o-skills-hero--csr .o-skills-hero__container {
    padding-top: 140px;
    padding-bottom: 140px; } }

@media (min-width: 992px) {
  .o-skills-hero__heading {
    font-size: 55px;
    line-height: 70px; } }

.o-skills-hero__sub-heading {
  font-family: "Montserrat", sans-serif; }
  @media (min-width: 992px) {
    .o-skills-hero__sub-heading {
      font-size: 24px;
      line-height: 40px; } }

/* Think Green */
.o-efforts__item {
  margin-bottom: 50px; }
  @media (min-width: 768px) {
    .o-efforts__item {
      margin-bottom: 50px; } }
  @media (min-width: 992px) {
    .o-efforts__item {
      margin-bottom: 80px; } }
  .o-efforts__item:last-child {
    margin-bottom: 40px; }

.o-efforts__heading {
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .o-efforts__heading {
      margin-bottom: 20px; } }

.o-efforts__info {
  padding: 30px 30px 0;
  margin: -50px 30px 0;
  text-align: center; }
  @media (min-width: 768px) {
    .o-efforts__info {
      padding: 50px 50px 0; } }
  @media (min-width: 992px) {
    .o-efforts__info {
      padding: 30px 0 30px 30px;
      margin: 0 0 0 -180px;
      text-align: left;
      max-width: 535px; } }
  @media (min-width: 992px) {
    .o-efforts__info--right {
      padding: 30px 30px 30px 0;
      margin: 0 -180px 0 0; } }

.o-efforts__desc {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 2; }
  @media (min-width: 992px) {
    .o-efforts__desc {
      font-size: 18px; } }

/* Think Green */
.o-think-green__media-wrapper {
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .o-think-green__media-wrapper {
      margin-bottom: 0; } }

.o-think-green__list-item {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  padding-left: 36px; }
  @media (min-width: 992px) {
    .o-think-green__list-item {
      font-size: 18px; } }
  .o-think-green__list-item:not(:last-child) {
    margin-bottom: 20px; }
  .o-think-green__list-item::before {
    content: "";
    background-image: url("/images/csr/check_icon.svg");
    width: 21px;
    height: 13px;
    position: absolute;
    left: 0;
    top: 8px; }

@media (min-width: 992px) {
  .o-section--portfolio .o-section__heading {
    font-size: 46px;
    line-height: 56px; } }

.o-section--portfolio .o-section__desc {
  font-weight: 500;
  max-width: 90%;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .o-section--portfolio .o-section__desc {
      font-size: 24px;
      /* line-height: 40px; */
      max-width: 80%; } }

.o-portfolio {
  margin-left: -15px; }
  .o-portfolio__item {
    padding: 0 0 15px 15px;
    position: relative;
    cursor: pointer; }
  .o-portfolio__inner {
    position: relative; }
  .o-portfolio__media {
    margin: 0; }
  .o-portfolio__name {
    position: absolute;
    left: 35px;
    bottom: 30px;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    line-height: normal;
    font-weight: 600;
    color: #fff;
    margin: 0;
    padding-right: 60px;
    word-break: break-word;
    text-align: left; }
    @media (min-width: 1200px) {
      .o-portfolio__name {
        font-size: 24px;
        line-height: 32px;
        left: 50px;
        bottom: 35px;
        padding-right: 80px; } }
  .o-portfolio__icon {
    position: absolute;
    right: 20px;
    bottom: 35px; }
    @media (min-width: 1200px) {
      .o-portfolio__icon {
        right: 35px;
        bottom: 42px; } }
  .o-portfolio .slick-prev,
  .o-portfolio .slick-next {
    background-image: url(/images/node/prev.svg);
    text-indent: -9999px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    padding: 20px 30px;
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    background-size: 10px; }
    @media (min-width: 992px) {
      .o-portfolio .slick-prev,
      .o-portfolio .slick-next {
        background-size: 100%;
        left: -30px; } }
    .o-portfolio .slick-prev.slick-disabled,
    .o-portfolio .slick-next.slick-disabled {
      opacity: 0.3;
      cursor: default; }
  .o-portfolio .slick-next {
    background-image: url(/images/node/next.svg);
    left: auto;
    right: -45px; }
  .o-portfolio__right-pan {
    padding: 30px 15px;
    color: #333; }
    @media (min-width: 992px) {
      .o-portfolio__right-pan {
        padding: 60px; } }
  .o-portfolio__proj-name {
    font-size: 24px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    line-height: 32px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .o-portfolio__proj-name {
        font-size: 30px;
        padding-bottom: 20px;
        margin-bottom: 20px; } }
  .o-portfolio__proj-desc {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: normal; }
    @media screen and (min-width: 992px) {
      .o-portfolio__proj-desc {
        font-size: 14px; } }
    @media screen and (min-width: 1200px) {
      .o-portfolio__proj-desc {
        font-size: 18px;
        font-family: "Montserrat", sans-serif; } }
  .o-portfolio__link {
    font-family: "Montserrat", sans-serif;
    color: #4a90e2;
    font-size: 18px;
    font-weight: 600;
    text-decoration: underline; }
    @media (min-width: 992px) {
      .o-portfolio__link {
        font-family: "Montserrat", sans-serif; } }

/* Slick Lightbox Customization for Portfolio Node JS page*/
.slick-lightbox-on {
  overflow: hidden !important; }

.js-slider-modal {
  display: none; }

.slick-lightbox {
  z-index: 99999999 !important; }
  .slick-lightbox .slick-lightbox-inner {
    overflow-y: auto;
    display: flex;
    padding: 50px; }
    @media (min-width: 992px) {
      .slick-lightbox .slick-lightbox-inner {
        padding: 0; } }
  .slick-lightbox .slick-lightbox-inner-wrap {
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    margin: auto;
    max-width: 100%; }
    @media (min-width: 992px) {
      .slick-lightbox .slick-lightbox-inner-wrap {
        max-width: 940px; } }
    @media (min-width: 1200px) {
      .slick-lightbox .slick-lightbox-inner-wrap {
        max-width: 1170px; } }
  .slick-lightbox .slick-lightbox-slick-item {
    height: auto !important; }
  .slick-lightbox .slick-lightbox-slick-item-inner {
    max-width: 100%;
    max-height: none !important;
    margin-top: -20px; }
    @media (min-width: 992px) {
      .slick-lightbox .slick-lightbox-slick-item-inner {
        max-height: 600px !important;
        overflow: hidden; } }
  .slick-lightbox .slick-lightbox-slick-img {
    display: none !important; }
  .slick-lightbox .slick-lightbox-slick-caption {
    margin: 0;
    text-align: left;
    display: inherit; }
    @media (min-width: 992px) {
      .slick-lightbox .slick-lightbox-slick-caption {
        display: flex; } }
  .slick-lightbox .slick-slide img {
    margin: 0 auto;
    width: 100%;
    height: auto; }
  .slick-lightbox .slick-lightbox-close {
    background: url(/images/node/close.svg) no-repeat center #fff;
    text-indent: -9999px;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0; }
    @media (min-width: 992px) {
      .slick-lightbox .slick-lightbox-close {
        top: 10px;
        right: 10px; } }
  .slick-lightbox .slick-arrow {
    background-image: url(/images/node/prev.svg);
    text-indent: -9999px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #2dbe70;
    background-size: 13px auto;
    border: 1px solid #2dbe70;
    width: 45px;
    height: 45px;
    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translate(0, -50%); }
    .slick-lightbox .slick-arrow.slick-disabled {
      opacity: 0.3;
      cursor: default;
      border: 1px solid #333;
      background: #fff; }
    @media (min-width: 768px) {
      .slick-lightbox .slick-arrow {
        width: 50px;
        height: 50px; } }
    @media (min-width: 992px) {
      .slick-lightbox .slick-arrow {
        transform: none;
        top: auto;
        bottom: 0;
        border: 0;
        border-left: 1px solid #2dbe70;
        border-right: 1px solid #2dbe70; } }
  .slick-lightbox .slick-next {
    background-image: url(/images/node/next.svg);
    right: -45px !important; }
    @media (min-width: 992px) {
      .slick-lightbox .slick-next {
        right: 0 !important; } }
  .slick-lightbox .slick-prev {
    left: -45px !important; }
    @media (min-width: 992px) {
      .slick-lightbox .slick-prev {
        left: auto !important;
        right: 51px; } }

ul.freamwork-list {
  display: inherit;
  text-align: center; }
  @media (min-width: 992px) {
    ul.freamwork-list {
      display: flex;
      flex-wrap: wrap;
      text-align: left; } }
  ul.freamwork-list p {
    font-size: 18px;
    line-height: 28px; }
    ul.freamwork-list p strong {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600; }
  ul.freamwork-list li {
    margin-bottom: 20px;
    display: inherit; }
    @media (min-width: 992px) {
      ul.freamwork-list li {
        flex: 50%;
        display: flex;
        align-items: flex-start; } }
    @media (min-width: 992px) {
      ul.freamwork-list li img {
        max-width: 187px;
        margin-right: 20px; } }
    ul.freamwork-list li p {
      font-size: 16px;
      padding-bottom: 15px;
      font-family: "Montserrat", sans-serif;
      margin-top: 10px; }
      @media (min-width: 992px) {
        ul.freamwork-list li p {
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          padding-right: 20px;
          margin-top: 0; } }
      ul.freamwork-list li p a {
        display: block;
        font-family: "Montserrat", sans-serif;
        color: #333333;
        text-decoration: underline;
        margin-top: 10px;
        font-weight: 500; }
        ul.freamwork-list li p a:hover {
          text-decoration: none; }

.font-14 {
  font-size: 14px !important;
  line-height: 20px !important; }

.step-heading {
  display: block;
  vertical-align: top;
  margin-top: -5px;
  width: 150px !important; }
  @media (min-width: 992px) {
    .step-heading {
      display: inline-block;
      margin-left: 10px;
      width: 80% !important; } }

.why-dotnet li {
  vertical-align: top;
  margin-bottom: 30px;
  text-align: center; }
  @media (min-width: 992px) {
    .why-dotnet li {
      text-align: left; } }
  .why-dotnet li img {
    display: inline-block;
    margin-right: 0;
    vertical-align: top;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .why-dotnet li img {
        width: 5%;
        margin-bottom: 0;
        position: relative;
        top: 5px;
        margin-right: 20px; } }
  .why-dotnet li span {
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    vertical-align: top;
    font-family: "Montserrat", sans-serif;
    width: 100%;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .why-dotnet li span {
        width: 19%;
        margin-bottom: 0;
        margin-right: 30px; } }
  .why-dotnet li p {
    display: inline-block;
    font-size: 18px;
    line-height: 28px;
    vertical-align: top;
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .why-dotnet li p {
        width: 68%;
        margin-bottom: 25px; } }

.step-holder ol {
  padding-left: 20px; }
  .step-holder ol li {
    list-style-type: decimal;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
    font-family: "Montserrat", sans-serif; }
    @media (min-width: 992px) {
      .step-holder ol li {
        font-family: "Montserrat", sans-serif;
        font-size: 19px;
        line-height: 30px; } }

.new-step .how-it-works-holder span {
  line-height: 29px; }

.faq-holder {
  background-color: #fff; }
  .faq-holder .panel1 p.related-tech {
    font-family: "Montserrat", sans-serif !important;
    font-size: 14px !important;
    line-height: 20px !important; }

.o-case-studies-new .faq-holder .accordion1 span {
  font-size: 16px !important; }
  @media (min-width: 992px) {
    .o-case-studies-new .faq-holder .accordion1 span {
      font-size: 20px !important; } }

.technology .o-skills-hero h1 {
  font-size: 28px !important;
  line-height: 38px !important;
  color: #fff; }
  @media (min-width: 992px) {
    .technology .o-skills-hero h1 {
      font-size: 48px !important;
      line-height: 68px !important; } }

.tech-text {
  font-size: 19px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  padding-top: 20px; }

.lds-dual-ring {
  display: block;
  width: 80px;
  height: 80px;
  margin: 0 auto; }

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite; }

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.calendly-preloader {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .calendly-preloader .calendly-preloader-text {
    color: #fff;
    margin-bottom: 10px;
    display: inline-block;
    font-size: 16px; }

.s-thank .why-details h5 {
  font-family: "Montserrat", sans-serif; }

.o-efforts__item .col-md-4 {
  z-index: 9; }

.people-behind {
  padding: 50px 0; }
  @media (min-width: 992px) {
    .people-behind {
      padding: 80px 0 50px; } }

.o-aboutus--leadership-team .team {
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  font-size: 18px;
  line-height: 26px;
  padding: 40px 0;
  margin-bottom: 30px;
  transition: all 0.3s ease-out;
  position: relative; }
  @media screen and (min-width: 992px) {
    .o-aboutus--leadership-team .team {
      font-size: 16px;
      line-height: 24px; } }
  @media screen and (min-width: 1200px) {
    .o-aboutus--leadership-team .team {
      font-size: 18px;
      line-height: 26px; } }

.o-aboutus--leadership-team-new .team {
  background-color: #fff; }

.team {
  min-height: 380px; }

.o-aboutus--leadership-team-new .team .team-img-holder {
  width: 178px;
  height: 178px;
  margin: 0 auto 40px;
  position: relative; }

.o-aboutus--leadership-team .team figure {
  display: inline-block;
  border: 20px solid #f7f7f7;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 30px; }

.o-aboutus--leadership-team-new .team span {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  display: block; }
  @media screen and (min-width: 992px) {
    .o-aboutus--leadership-team-new .team span {
      font-size: 18px; } }
  @media screen and (min-width: 1200px) {
    .o-aboutus--leadership-team-new .team span {
      font-size: 20px; } }

.o-aboutus--leadership-team-new .team .team-img-holder a.linkid-in {
  position: absolute;
  right: 12px;
  bottom: 40px; }

.o-section__desc {
  font-size: 18px;
  line-height: 24px; }

.csr-leadership-team h2 {
  margin-bottom: 30px; }

/* ANCHOR Hybrid pages*/
.hybrid .hybrid-offer .o-skills-benefits__contentholder {
  padding: 50px 30px; }
  .hybrid .hybrid-offer .o-skills-benefits__contentholder .content-panel:before {
    display: none; }
  .hybrid .hybrid-offer .o-skills-benefits__contentholder .content-panel img {
    height: 60px; }
  .hybrid .hybrid-offer .o-skills-benefits__contentholder .content-panel h3 {
    padding: 20px 0 10px; }

.hybrid .t-list {
  text-align: center; }
  .hybrid .t-list li {
    display: inline-block;
    text-align: center;
    margin: 10px 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 19px; }
    .hybrid .t-list li img {
      display: block;
      margin: 0 auto 10px; }

.hybrid .hybrid-list {
  display: inline-block;
  margin-right: 20px;
  vertical-align: top; }
  .hybrid .hybrid-list.color-black li {
    color: #333; }
  .hybrid .hybrid-list li {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    text-align: left;
    background: url(/images/common/tick.png) no-repeat 0 0;
    padding-left: 30px;
    margin-bottom: 10px; }

.hybrid .hybrid-list-1 {
  width: 100%; }
  @media (min-width: 992px) {
    .hybrid .hybrid-list-1 {
      width: 15%; } }

.hybrid .hybrid-list-2 {
  width: 100%; }
  @media (min-width: 992px) {
    .hybrid .hybrid-list-2 {
      width: 20%; } }

.hybrid .hybrid-list-3 {
  width: 100%; }
  @media (min-width: 992px) {
    .hybrid .hybrid-list-3 {
      width: 30%; } }

.hybrid .hybrid-list-4 {
  width: 100%; }
  @media (min-width: 992px) {
    .hybrid .hybrid-list-4 {
      width: 20%; } }

.hybrid .masonry .body-text-medium {
  font-size: 19px;
  line-height: 32px; }

/* ANCHOR new-skill*/
@media (min-width: 992px) {
  .mid-border {
    background: url(/images/staff/mid-border.png) repeat-y 50% 50%; } }

.with-cn-list {
  padding: 0 20px; }
  .with-cn-list li {
    font-size: 15px;
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
    line-height: 21px;
    clear: both;
    margin-bottom: 20px;
    vertical-align: top; }
    .with-cn-list li span {
      float: left;
      vertical-align: top;
      width: 55px;
      margin-bottom: 30px; }

.with-cn {
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .with-cn {
      padding: 80px 0 50px; } }
  .with-cn h2 {
    line-height: 36px; }
    @media (min-width: 992px) {
      .with-cn h2 {
        line-height: 43px; } }
  .with-cn h3 {
    font-size: 20px;
    margin-bottom: 40px; }
  .with-cn .padding-40 {
    padding-bottom: 40px; }

.expertise-python-section {
  border-top: 2px solid #14DF7D; }
  @media (min-width: 992px) {
    .expertise-python-section {
      margin-bottom: 80px; } }

.expertise-python {
  border-bottom: 1px solid #9AA1AC;
  padding: 10px 0; }
  .expertise-python span {
    width: 38%;
    display: inline-block;
    font-size: 18px;
    line-height: 26px;
    font-family: "Montserrat",sans-serif;
    color: #333;
    font-weight: 600;
    vertical-align: middle; }
    @media (min-width: 992px) {
      .expertise-python span {
        font-size: 24px;
        line-height: 32px;
        vertical-align: top;
        padding-top: 10px; } }
  .expertise-python .python-img-holder {
    display: inline-block;
    width: 60%;
    vertical-align: middle; }
    @media (min-width: 992px) {
      .expertise-python .python-img-holder {
        vertical-align: inherit; } }
  .expertise-python img {
    display: inline-block;
    margin: 10px; }

.fc-acco.o-case-studies-new .faq-holder .accordion1 span {
  font-size: 16px !important;
  line-height: 28px; }
  @media (min-width: 992px) {
    .fc-acco.o-case-studies-new .faq-holder .accordion1 span {
      font-size: 20px !important;
      line-height: 32px; } }

.fc-acco .pad-80 {
  padding: 50px 0 10px; }
  @media (min-width: 992px) {
    .fc-acco .pad-80 {
      padding: 80px 0; } }

.fc-acco .faq-pan {
  margin-top: 20px; }
  @media (min-width: 992px) {
    .fc-acco .faq-pan {
      margin-top: 0;
      position: relative; } }

.faq-section {
  padding: 50px 0 10px; }
  @media (min-width: 992px) {
    .faq-section {
      padding: 80px 0; } }

.fc-acco .faq-holder {
  margin-bottom: 20px; }

.remote-dev .how-it-works-holder img {
  margin: 100px auto 0; }

.remote-dev .how-it-works-holder span {
  line-height: 36px; }

.o-engagement-model header {
  text-align: center;
  color: #fff; }
  .o-engagement-model header p {
    font-family: "Montserrat", sans-serif; }

.staff-chart {
  font-family: "Montserrat",sans-serif; }

.staff-chart .chart-head .chart-head-1:first-child {
  background-color: transparent; }

.staff-chart .chart-head .chart-head-1 {
  width: 19%;
  display: inline-block;
  background-color: #71E19D;
  padding: 10px 15px;
  vertical-align: top;
  font-weight: 600; }
  @media (min-width: 992px) {
    .staff-chart .chart-head .chart-head-1 {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px; } }

.staff-chart .chart-head .chart-head-1 img {
  display: inline-block;
  vertical-align: top; }

.staff-chart .chart-head .chart-head-1 span {
  display: inline-block; }

.staff-chart .chart-mid .chart-mid-1:first-child {
  border-bottom: none;
  background-color: #CBE3FF;
  font-size: 12px; }
  @media (min-width: 992px) {
    .staff-chart .chart-mid .chart-mid-1:first-child {
      border-bottom: 1px solid #474D5A; } }

.staff-chart .chart-mid .chart-mid-1:first-child span {
  font-size: 18px;
  padding-bottom: 5px;
  line-height: 20px; }

.staff-chart .chart-head .chart-head-1 span strong {
  display: block; }

.staff-chart .chart-mid .chart-mid-1 span {
  display: block; }

.staff-chart .chart-mid .chart-mid-1 {
  width: 100%;
  display: inline-block;
  background-color: #fff;
  padding: 10px 15px;
  vertical-align: top;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid #ccc; }
  @media (min-width: 992px) {
    .staff-chart .chart-mid .chart-mid-1 {
      width: 19%; } }
  @media (min-width: 992px) {
    .staff-chart .chart-mid .chart-mid-1 {
      min-height: 88px; } }

.mid-sub {
  padding: 10px 0 0; }
  .mid-sub span {
    font-size: 16px; }

.staff-chart .chart-mid .chart-mid-1 span.price {
  font-size: 18px;
  padding: 10px 0;
  display: inline-block; }
  @media (min-width: 992px) {
    .staff-chart .chart-mid .chart-mid-1 span.price {
      padding: 20px 0; } }

.gst {
  color: #CBE3FF;
  font-size: 14px;
  padding: 10px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0; }
  @media (min-width: 992px) {
    .gst {
      margin-bottom: 50px !important; } }

.staff-chart .chart-mid .staff-first-child {
  border-top-left-radius: 5px; }

@media (min-width: 992px) {
  .staff-chart .chart-mid .staff-first-child-bottom {
    border-bottom-left-radius: 5px; } }

.we-hire {
  padding: 50px 0 20px; }
  @media (min-width: 992px) {
    .we-hire {
      padding: 80px 0 50px; } }

@media (min-width: 992px) {
  .new-pricing .cmn-heading-para {
    max-width: 1024px;
    margin: 0 auto 20px; } }

.cs-new-p {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600; }

.cs-new-h3 {
  padding-bottom: 40px;
  font-size: 28px;
  line-height: 42px; }
  @media (min-width: 992px) {
    .cs-new-h3 {
      font-size: 36px;
      line-height: 49px; } }

.o-skills-hero__schdule .minus-schedule-box {
  margin-top: 0;
  height: 100%; }

.last-expertise {
  margin-bottom: 25px; }
  @media (min-width: 992px) {
    .last-expertise {
      margin-bottom: 0; } }

.tech-exp {
  padding: 45px 0 20px; }
  @media (min-width: 992px) {
    .tech-exp {
      padding: 65px 0 50px; } }

.fc-acco {
  padding: 45px 0 20px; }
  @media (min-width: 992px) {
    .fc-acco {
      padding: 65px 0 50px; } }

.skill-what .container {
  z-index: 9; }

/*Cheaper better*/
.cb-header-left {
  text-align: left !important;
  padding-top: 10px; }
  @media (min-width: 992px) {
    .cb-header-left {
      padding-top: 40px; } }

.cb-header-left h1 {
  font-size: 24px !important;
  line-height: 28px !important;
  text-align: center;
  font-weight: bold;
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .cb-header-left h1 {
      line-height: 58px !important;
      font-size: 48px !important;
      text-align: left; } }

.cb-header-left p {
  margin-bottom: 0;
  color: #2DBE70;
  font-size: 20px;
  font-weight: 700;
  font-family: "Montserrat",sans-serif; }

.cb-header-left ul {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0;
  justify-content: center;
  margin-top: 20px; }
  @media (min-width: 992px) {
    .cb-header-left ul {
      justify-content: inherit;
      margin-top: 0;
      padding-bottom: 40px; } }

.cb-header-left ul li {
  align-self: center;
  margin-right: 0;
  padding: 15px;
  flex: 0 0 25%; }
  @media screen and (min-width: 992px) {
    .cb-header-left ul li {
      margin-right: 20px;
      padding: 0;
      flex: 1; } }
  @media screen and (min-width: 1200px) {
    .cb-header-left ul li {
      flex: inherit; } }

.cb-header-right {
  text-align: left !important;
  padding-top: 40px; }

.cb-header-right ul {
  display: flex;
  flex-wrap: wrap; }

.cb-header-right ul li {
  width: 43%;
  color: #fff;
  padding: 20px;
  margin: 0 10px 20px;
  border-radius: 5px; }
  @media screen and (min-width: 992px) {
    .cb-header-right ul li {
      width: 165px; } }
  @media screen and (min-width: 1200px) {
    .cb-header-right ul li {
      width: 184px; } }

.cb-header-right ul li:nth-child(1) {
  background-color: #4A90E2;
  border: 1px solid #4A90E2; }

.cb-header-right ul li:nth-child(2) {
  border: 1px solid #4A90E2; }

.cb-header-right ul li:nth-child(3) {
  border: 1px solid #009363; }

.cb-header-right ul li:nth-child(4) {
  background-color: #009363;
  border: 1px solid #009363; }

.cb-header-right img {
  margin-bottom: 10px;
  min-height: 46px; }

.cb-header-right h4 {
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0; }
  @media (min-width: 992px) {
    .cb-header-right h4 {
      font-size: 24px;
      line-height: 29px; } }

.cb-header-right h5 {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 5px; }
  @media (min-width: 992px) {
    .cb-header-right h5 {
      font-size: 16px;
      line-height: 29px;
      margin-top: 0; } }

.or-circle-new {
  display: block;
  font-size: 12px;
  line-height: 15px;
  font-family: 'Montserrat';
  background: #ccc;
  padding: 11px 0;
  border-radius: 30px;
  font-weight: bold;
  width: 37px;
  height: 37px;
  text-align: center;
  margin: 10px auto; }
  @media (min-width: 992px) {
    .or-circle-new {
      display: inline-block;
      margin: 0 30px 0 auto; } }

.why-us-page {
  background: linear-gradient(135deg, #0e4173 0%, #051423 100%); }
  @media (min-width: 992px) {
    .why-us-page .btn_wrapper .btn-orange {
      margin: 0; } }
  @media (min-width: 992px) {
    .why-us-page .anchor-link {
      text-align: left;
      margin-top: 0; } }

/*Industry*/
.tech-list {
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden; }

.tech-list div {
  padding: 20px 10px;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat",sans-serif;
  color: #fff; }
  @media (min-width: 992px) {
    .tech-list div {
      float: left;
      width: 20%; } }

.tech-list div.tect-blue-bg {
  background-color: #4A90E2; }

.tech-list div.tect-green-bg {
  background-color: #49C684; }

.tech-list ul {
  padding-bottom: 25px; }
  @media (min-width: 992px) {
    .tech-list ul {
      padding: 20px 0 20px 20px;
      float: left; } }

.tech-list ul li {
  padding: 10px 0;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat",sans-serif; }
  @media (min-width: 992px) {
    .tech-list ul li {
      float: left;
      padding: 0 20px;
      border-right: 1px solid #DEDEDE; } }

.tech-list ul li:last-child {
  border-right: 0; }

.tech-list ul li a {
  font-weight: 500;
  color: #4A90E2;
  text-decoration: underline;
  transition: .2s all ease-in-out; }
  .tech-list ul li a:hover {
    color: #2275d7; }

.font-size-48 {
  font-size: 48px;
  line-height: 56px; }

.industry-box {
  padding: 40px;
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .industry-box {
      min-height: 500px; } }

.industry-box h3 {
  font-size: 22px;
  line-height: 28px;
  padding-top: 20px; }
  @media (min-width: 992px) {
    .industry-box h3 {
      font-size: 30px;
      line-height: 40px; } }

.f-software .industry-box {
  margin-bottom: 0; }
  @media (min-width: 992px) {
    .f-software .industry-box {
      margin-bottom: 30px; } }
  .f-software .industry-box p {
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .f-software .industry-box p {
        margin-bottom: 25px; } }

.f-software .padding-bottom-40 h3 {
  text-align: center; }

.f-software .col-md-6 {
  margin-bottom: 25px; }
  @media (min-width: 992px) {
    .f-software .col-md-6 {
      margin-bottom: 0; } }

.industry-banner {
  padding: 50px 0 20px !important; }
  @media (min-width: 992px) {
    .industry-banner {
      padding: 115px 20px 45px !important; } }
  .industry-banner h1 {
    font-size: 28px;
    line-height: 36px; }
    @media (min-width: 992px) {
      .industry-banner h1 {
        font-size: 48px;
        line-height: 56px; } }
  .industry-banner h2 {
    font-size: 18px;
    line-height: 26px; }
    @media (min-width: 992px) {
      .industry-banner h2 {
        font-size: 24px;
        line-height: 38px; } }
  .industry-banner p.green {
    font-size: 17px;
    color: #14DF7D !important;
    padding-top: 20px;
    text-align: center; }
    @media (min-width: 992px) {
      .industry-banner p.green {
        text-align: left; } }
  @media (min-width: 992px) {
    .industry-banner .btn_wrapper .btn-orange {
      margin: 0; } }
  @media (min-width: 992px) {
    .industry-banner .anchor-link {
      text-align: left;
      margin-top: 0; } }

.f-software {
  padding: 50px 0 20px; }
  @media (min-width: 992px) {
    .f-software {
      font-size: 48px;
      line-height: 61px; } }
  @media (min-width: 992px) {
    .f-software .cmn-heading-para {
      font-size: 20px !important;
      line-height: 32px !important; } }
  @media (min-width: 992px) {
    .f-software {
      padding: 70px 0 50px; } }

.robust {
  padding: 50px 0 20px; }
  @media (min-width: 992px) {
    .robust {
      padding: 70px 0 50px; } }
  .robust p strong {
    font-weight: 700; }
  .robust ul.freamwork-list {
    display: block; }
    @media (min-width: 992px) {
      .robust ul.freamwork-list {
        display: flex; } }

.industry-case {
  background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%); }
  .industry-case br {
    display: none; }
    @media (min-width: 992px) {
      .industry-case br {
        display: block; } }

.we-use h2 {
  padding-bottom: 30px; }
  @media (min-width: 992px) {
    .we-use h2 {
      padding-bottom: 30px; } }

.js-portfolio-slider .slick-arrow {
  display: none !important; }
  @media screen and (min-width: 1200px) {
    .js-portfolio-slider .slick-arrow {
      display: block !important; } }

.more-class:before {
  display: none; }

.VueJS .services-pan.more-class, .iOS .services-pan.more-class, .Android .services-pan.more-class, .Magento .services-pan.more-class, .Drupal .services-pan.more-class {
  padding: 0; }

.nw-wp-section {
  background: #08426f !important; }

.odd-grey-bg {
  background: #f1f1f1; }

.mern-stack-text-new {
  color: #333; }
  @media (min-width: 992px) {
    .mern-stack-text-new {
      color: #fff; } }

p .anchor-link-new {
  text-align: left;
  max-width: 100%; }

.slick-track .async-image-new {
  height: 350px;
  background-position: center center; }
  @media (min-width: 992px) {
    .slick-track .async-image-new {
      height: auto; } }

.hover-underline-new {
  font-weight: 500;
  border-bottom: 1px solid #fff; }
  .hover-underline-new:hover {
    border-bottom: 1px solid transparent; }

.staff-chart {
  font-family: "Montserrat",sans-serif; }
  .staff-chart .chart-head {
    font-size: 0; }
    .staff-chart .chart-head .chart-head-1 {
      width: 100%;
      display: none;
      background-color: #71E19D;
      padding: 10px 15px;
      vertical-align: top;
      font-weight: 600;
      margin-right: 4px; }
      @media (min-width: 992px) {
        .staff-chart .chart-head .chart-head-1 {
          width: 19%;
          display: inline-block;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px; } }
      .staff-chart .chart-head .chart-head-1:first-child {
        background-color: transparent; }
      .staff-chart .chart-head .chart-head-1 img {
        display: inline-block;
        vertical-align: top; }
      .staff-chart .chart-head .chart-head-1 span {
        display: inline-block; }
        @media (min-width: 992px) {
          .staff-chart .chart-head .chart-head-1 span {
            font-size: 16px;
            line-height: 32px; } }
        .staff-chart .chart-head .chart-head-1 span strong {
          display: block; }
  .staff-chart .chart-mid {
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      .staff-chart .chart-mid {
        margin-bottom: 0; } }
    .staff-chart .chart-mid .chart-mid-1 {
      width: 100%;
      display: inline-block;
      background-color: #fff;
      padding: 10px 35px 10px 15px;
      vertical-align: top;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid #ccc !important;
      margin-right: 4px; }
      @media (min-width: 992px) {
        .staff-chart .chart-mid .chart-mid-1 {
          width: 19%;
          min-height: 88px;
          padding: 10px 15px; } }
      .staff-chart .chart-mid .chart-mid-1 span.price {
        font-size: 15px;
        padding: 10px 0;
        display: inline-block; }
        @media (min-width: 992px) {
          .staff-chart .chart-mid .chart-mid-1 span.price {
            padding: 20px 0;
            font-size: 18px; } }
      .staff-chart .chart-mid .chart-mid-1 span {
        display: block; }
      .staff-chart .chart-mid .chart-mid-1:first-child {
        border-bottom: 1px solid 1px solid #ccc;
        background-color: #CBE3FF;
        font-size: 12px; }
        @media (min-width: 992px) {
          .staff-chart .chart-mid .chart-mid-1:first-child {
            border-top-right-radius: 0;
            border-top-right-radius: 0;
            border-bottom: 1px solid #474D5A; } }
        .staff-chart .chart-mid .chart-mid-1:first-child span {
          font-size: 16px;
          padding-bottom: 5px; }
          @media screen and (min-width: 992px) {
            .staff-chart .chart-mid .chart-mid-1:first-child span {
              font-size: 14px; } }
          @media screen and (min-width: 1200px) {
            .staff-chart .chart-mid .chart-mid-1:first-child span {
              font-size: 18px; } }
        .staff-chart .chart-mid .chart-mid-1:first-child span.price {
          font-size: 15px; }
          @media screen and (min-width: 992px) {
            .staff-chart .chart-mid .chart-mid-1:first-child span.price {
              font-size: 18px; } }
    .staff-chart .chart-mid .staff-first-child {
      margin-top: 25px; }
      @media (min-width: 992px) {
        .staff-chart .chart-mid .staff-first-child {
          border-top-left-radius: 5px;
          margin-top: 0; } }
    @media (min-width: 992px) {
      .staff-chart .chart-mid .staff-first-child-bottom {
        border-bottom-left-radius: 5px; } }
    .staff-chart .chart-mid .staff-last-child {
      border-bottom: 1px solid #474D5A; }
      @media (min-width: 992px) {
        .staff-chart .chart-mid .staff-last-child {
          border-bottom-right-radius: 5px; } }
    @media (min-width: 992px) {
      .staff-chart .chart-mid .last-line-min {
        min-height: 100px; } }
    @media screen and (min-width: 992px) {
      .staff-chart .chart-mid .chart-mid-wrapper {
        display: inline; } }
    .staff-chart .chart-mid .chart-mid-wrapper .chart-mid-1:first-child {
      background: #fff;
      border-radius: 0 !important; }
    .staff-chart .chart-mid .chart-mid-mod {
      display: flex;
      align-items: flex-start;
      padding: 15px 15px; }
      @media screen and (min-width: 992px) {
        .staff-chart .chart-mid .chart-mid-mod {
          display: inline-block;
          padding: 10px 15px; } }
      .staff-chart .chart-mid .chart-mid-mod .chart-mid-sub {
        margin-left: 7px; }
        @media screen and (min-width: 992px) {
          .staff-chart .chart-mid .chart-mid-mod .chart-mid-sub {
            margin-left: 0; } }
        .staff-chart .chart-mid .chart-mid-mod .chart-mid-sub .mid-sub {
          padding: 0 0 0; }
          @media screen and (min-width: 992px) {
            .staff-chart .chart-mid .chart-mid-mod .chart-mid-sub .mid-sub {
              padding: 10px 0 0; } }
        .staff-chart .chart-mid .chart-mid-mod .chart-mid-sub span.price {
          padding: 0 0 0; }
          @media screen and (min-width: 992px) {
            .staff-chart .chart-mid .chart-mid-mod .chart-mid-sub span.price {
              padding: 10px 0; } }
    .staff-chart .chart-mid .acco-head {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px; }
      @media screen and (min-width: 992px) {
        .staff-chart .chart-mid .acco-head {
          border-top-left-radius: 0; } }
  .staff-chart .chart-mid {
    font-size: 0;
    border-radius: 7px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: hidden; }
    .staff-chart .chart-mid:nth-child(2) .chart-mid-1:nth-child(1) {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px; }
      @media (min-width: 992px) {
        .staff-chart .chart-mid:nth-child(2) .chart-mid-1:nth-child(1) {
          border-top-right-radius: 0; } }
    @media (min-width: 992px) {
      .staff-chart .chart-mid {
        border-radius: 0; } }
    .staff-chart .chart-mid .acco-head {
      position: relative; }
      .staff-chart .chart-mid .acco-head:before {
        content: '\002B';
        position: absolute;
        top: 50%;
        transform: translate(-50%, 0);
        right: 5px;
        height: 100%;
        font-size: 28px;
        line-height: 0;
        color: #ff5800; }
        @media (min-width: 992px) {
          .staff-chart .chart-mid .acco-head:before {
            display: none; } }
      .staff-chart .chart-mid .acco-head.open:before {
        content: "\2212"; }

@media screen and (min-width: 992px) {
  .Dedicated .staff-chart .chart-mid .chart-mid-1 span.price {
    padding: 26px 0;
    font-size: 18px; } }

@media screen and (min-width: 1200px) {
  .Dedicated .staff-chart .chart-mid .chart-mid-1 span.price {
    padding: 20px 0; } }

ul.skill-page-mod {
  display: flex; }
  ul.skill-page-mod li {
    flex: 1;
    position: inherit !important;
    padding: 0 10px !important; }
    ul.skill-page-mod li:before {
      display: none; }
    ul.skill-page-mod li svg {
      height: 100%;
      width: 100%; }

.Careers ul.skill-page-mod {
  display: flex;
  flex-wrap: wrap; }
  @media screen and (min-width: 992px) {
    .Careers ul.skill-page-mod {
      flex-wrap: nowrap; } }
  .Careers ul.skill-page-mod li {
    flex: 0 0 30% !important;
    position: inherit !important;
    padding: 5px 10px !important; }
    @media screen and (min-width: 992px) {
      .Careers ul.skill-page-mod li {
        flex: 0 0 auto !important;
        padding: 0 10px !important; } }
    .Careers ul.skill-page-mod li:before {
      display: none; }
    .Careers ul.skill-page-mod li svg {
      height: 100%;
      width: 100%; }

ul.skill-page-mod li:nth-child(3) {
  flex: 0 0 80px; }

.schedule-box {
  padding: 40px 30px 20px 30px; }

.schedule-box-top {
  padding: 10px 15px 10px;
  background: url(/images/common/mid.png) repeat-y;
  background-position: 0 0;
  background-size: 100%; }

.schedule-box-top .btn-center-block {
  width: 340px;
  margin: 0 auto; }
  @media screen and (min-width: 992px) {
    .schedule-box-top .btn-center-block {
      width: auto; } }
  @media screen and (min-width: 1200px) {
    .schedule-box-top .btn-center-block {
      width: 340px; } }

.schedule-box-top .anchor-link {
  margin-top: 10px; }

.schedule-box-top .btn-orange {
  padding: 14px 15px;
  max-width: 340px !important;
  font-weight: 700; }

.schedule-box-top-text img {
  width: 69px;
  height: 69px; }

.schdule_h_img {
  vertical-align: top;
  margin-right: 15px; }

.schedule-box-top-text h2 {
  text-align: left;
  font-size: 20px;
  padding-top: 0;
  font-weight: 600 !important; }
  @media screen and (min-width: 992px) {
    .schedule-box-top-text h2 {
      font-size: 20px !important;
      line-height: 28px; } }
  @media screen and (min-width: 1200px) {
    .schedule-box-top-text h2 {
      font-size: 24px !important;
      line-height: 35px; } }

.schdule_h_text {
  font-size: 17px !important;
  line-height: 27px !important; }

.schedule-box-top-img {
  background: url(/images/common/top.png) no-repeat;
  background-position: 0 0;
  background-size: 100%;
  height: 29px; }

.schedule-box-bottom-img {
  background: url(/images/common/bottom.png) no-repeat;
  background-position: 0 0;
  background-size: 100%;
  height: 45px; }

.with-cn-list {
  padding: 0 20px; }
  .with-cn-list li {
    font-size: 15px;
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
    line-height: 21px;
    clear: both;
    margin-bottom: 20px;
    vertical-align: top;
    display: flex; }
    @media screen and (min-width: 992px) {
      .with-cn-list li {
        display: inline-block; } }
    .with-cn-list li span {
      float: left;
      vertical-align: top;
      flex: 0 0 60px;
      margin-bottom: 30px; }
      @media screen and (min-width: 992px) {
        .with-cn-list li span {
          width: 55px; } }
      .with-cn-list li span img {
        width: 65%; }
        @media screen and (min-width: 992px) {
          .with-cn-list li span img {
            width: auto; } }

@media (min-width: 992px) {
  .mid-border {
    background: url(/images/staff/mid-border.png) repeat-y 50% 50%; } }

.staff-technology .box {
  width: 235px; }

.staff-technology .box__home-technology-01 {
  border-bottom: 4px solid #00b05c; }

.staff-technology .box__home-technology-02 {
  border-bottom: 4px solid #00af93; }

.staff-technology .box__home-technology-03 {
  border-bottom: 4px solid #0095b5; }

.staff-technology .box__home-technology-04 {
  border-bottom: 4px solid #007fab; }

.staff-technology .box {
  padding: 110px 10px 0; }

.staff-technology .box__home-technology-01:before {
  background: url(/images/staff/icon-web.svg) repeat-y 50% 50% !important;
  width: 70px;
  height: 64px;
  top: 30px; }

.staff-technology .box__home-technology-02:before {
  background: url(/images/staff/icon-software.svg) repeat-y 50% 50% !important;
  width: 70px;
  height: 64px;
  top: 30px; }

.staff-technology .box__home-technology-03:before {
  background: url(/images/staff/icon-outsource.svg) repeat-y 50% 50% !important;
  width: 70px;
  height: 64px;
  top: 30px; }

.staff-technology .box__home-technology-04:before {
  background: url(/images/staff/icon-qa.svg) repeat-y 50% 50% !important;
  width: 70px;
  height: 64px;
  top: 30px; }

.gst {
  color: #CBE3FF;
  font-size: 14px;
  padding: 10px; }

.expertise-python-section {
  border-top: 2px solid #14DF7D; }

.expertise-python {
  border-bottom: 1px solid #9AA1AC;
  padding: 10px 0; }
  .expertise-python span {
    width: 38%;
    display: inline-block;
    font-size: 18px;
    line-height: 24px;
    font-family: "Montserrat",sans-serif;
    color: #333;
    font-weight: 600;
    vertical-align: top;
    padding-top: 10px; }
    @media (min-width: 992px) {
      .expertise-python span {
        font-size: 24px;
        line-height: 32px; } }
  .expertise-python .python-img-holder {
    display: inline-block;
    width: 60%; }
  .expertise-python img {
    display: inline-block;
    margin: 10px; }

.python-chart .last-line-min {
  min-height: auto !important; }

.case-studies-slider .slick-list {
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.24); }

.cs-slider div {
  padding-bottom: 0; }

ul.skill-page-mod-pro {
  width: 100%; }

.custom-software-development .service-wrap .services-pan__staff:before {
  width: 78px;
  height: 75px; }

.custom-software-development .service-wrap .services-pan__staff-service-01:before {
  background: url(/images/staff/dt-icon-1.svg) no-repeat 50% 50%; }

.services-pan__staff {
  padding-top: 30px; }
  @media (min-width: 992px) {
    .services-pan__staff {
      padding-top: 50px; } }

.sm-p-section .staff-chart .chart-mid .chart-mid-1:first-child span {
  font-size: 15px;
  display: flex;
  align-items: center;
  height: 67px;
  padding-bottom: 1px; }

.sm-p-section .staff-chart .chart-mid .chart-mid-1 span.price {
  font-size: 15px;
  padding: 22px 0; }

.pack-name {
  font-size: 18px; }

.chart-head-price {
  font-size: 20px; }

#price-tabs {
  text-align: center;
  margin-bottom: 50px;
  display: flex; }
  @media screen and (min-width: 992px) {
    #price-tabs {
      display: inherit; } }

#price-tabs li {
  list-style: none;
  margin-right: 5px;
  margin-bottom: 2px;
  border-radius: 3px;
  outline: none;
  display: inline-block;
  flex: 1;
  font-size: 11px; }
  @media screen and (min-width: 992px) {
    #price-tabs li {
      font-size: 13px; } }
  #price-tabs li:last-child {
    margin-right: 0; }
    @media screen and (min-width: 992px) {
      #price-tabs li:last-child {
        margin-right: 5px; } }

#price-tabs li a {
  cursor: pointer;
  color: #333;
  font-family: "Montserrat",sans-serif;
  font-weight: 700;
  display: block;
  border: 1px solid #FFF;
  color: #fff;
  border-radius: 10px;
  text-decoration: none;
  outline: none;
  line-height: 24px;
  text-align: center;
  padding: 0 6px; }
  @media screen and (min-width: 992px) {
    #price-tabs li a {
      padding: 0 30px;
      width: 98px;
      border-radius: 20px;
      line-height: 36px; } }

#price-tabs li a.inactive {
  color: #ffffff;
  background: #27C070;
  outline: none;
  position: relative;
  border: 1px solid #27C070; }

#price-tabs li a.inactive:before {
  background: url("/images/common/price-white-tick.png") no-repeat 20% 50% #27C070;
  content: "";
  position: absolute;
  left: 20%;
  top: 50%;
  z-index: 100000;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  display: none; }
  @media screen and (min-width: 992px) {
    #price-tabs li a.inactive:before {
      display: block; } }

#price-tabs li a:hover, #tabs li a.inactive:hover {
  color: #ffffff;
  background: #27C070;
  outline: none;
  border: 1px solid #27C070; }

.container1 {
  display: inline-block; }

.price-pan {
  text-align: center;
  font-size: 48px;
  color: #FF5800;
  font-family: "Montserrat",sans-serif;
  font-weight: 600; }

.price-pan strong {
  text-align: center;
  font-size: 26px;
  color: #FF5800; }

.new-price .h5 {
  margin-bottom: 30px;
  font-weight: 600; }

.sk-fading-circle {
  margin: 100px auto;
  width: 80px;
  height: 80px;
  position: relative; }

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #27c070;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both; }

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg); }

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg); }

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg); }

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg); }

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg); }

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg); }

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg); }

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg); }

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s; }

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s; }

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

.why-hire-java .service-wrap {
  column-count: inherit; }
  @media screen and (min-width: 992px) {
    .why-hire-java .service-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; } }
  @media screen and (min-width: 992px) {
    .why-hire-java .service-wrap .services-pan {
      flex: 0 0 31.5%; } }

.remote-3-developer {
  padding: 40px 0 30px; }
  @media screen and (min-width: 992px) {
    .remote-3-developer {
      padding: 80px 0; } }
  .remote-3-developer h2 {
    margin-bottom: 20px; }
  .remote-3-developer .rd-step img {
    margin: 5px 0 30px; }
    @media screen and (min-width: 992px) {
      .remote-3-developer .rd-step img {
        margin: 30px 0 60px; } }
  .remote-3-developer .rd-block {
    background: #fff;
    box-shadow: 0 0 15px 2px rgba(104, 98, 98, 0.1);
    padding: 45px 30px 45px;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    position: relative;
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .remote-3-developer .rd-block {
        margin-bottom: 70px; } }
    .remote-3-developer .rd-block .rd-img {
      height: 80px; }
    .remote-3-developer .rd-block h3 {
      font-size: 24px;
      font-weight: 500;
      margin: 15px 0 10px; }
    .remote-3-developer .rd-block p {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 0; }
    .remote-3-developer .rd-block span {
      position: absolute;
      top: 7px;
      right: 10px;
      color: #3E93DA;
      font-size: 36px;
      font-weight: 500;
      display: block; }

@media screen and (min-width: 992px) {
  .blockchain-tevh-stack .tech-list div {
    width: 25%; } }

#hosted {
  max-width: 100% !important; }

.office-infrastructure .o-skills-hero__schedule {
  background: url(/images/common/schedule-talk-bg.jpg) no-repeat 0 0/cover;
  padding-top: 100px !important;
  padding-bottom: 40px !important; }
  @media screen and (min-width: 992px) {
    .office-infrastructure .o-skills-hero__schedule {
      padding-bottom: 60px !important;
      padding-top: 150px !important; } }

.blog-section {
  padding: 50px 0 20px; }
  @media screen and (min-width: 992px) {
    .blog-section {
      padding: 75px 0 45px; } }
  .blog-section h2 {
    margin-bottom: 40px;
    font-size: 30px; }
    @media screen and (min-width: 992px) {
      .blog-section h2 {
        font-size: 48px;
        line-height: 61px; } }
  .blog-section .blog-box {
    background: #fff;
    display: block;
    margin-bottom: 40px; }
    .blog-section .blog-box img {
      width: 100%; }
    .blog-section .blog-box .blog-img {
      overflow: hidden;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px; }
    .blog-section .blog-box .blog-text {
      padding: 30px 30px;
      text-align: left;
      border: 1px solid #C8C7CC;
      border-top: 0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px; }
      @media screen and (min-width: 992px) {
        .blog-section .blog-box .blog-text {
          min-height: 180px; } }
      .blog-section .blog-box .blog-text h5 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        font-family: Montserrat,sans-serif; }
      .blog-section .blog-box .blog-text p {
        color: #4A90E2;
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 0;
        font-weight: 500; }

@media screen and (min-width: 992px) {
  .bor-rad-bl {
    border-bottom-left-radius: 5px !important; } }

@media screen and (min-width: 992px) {
  .bor-rad-tl {
    border-top-left-radius: 5px !important; } }

.beauty-section .o-clients {
  border: none;
  padding: 15px 0 0; }
  @media screen and (min-width: 768px) {
    .beauty-section .o-clients {
      padding: 15px 0 25px; } }
  .beauty-section .o-clients h3 {
    font-size: 24px;
    margin-bottom: 25px; }
  .beauty-section .o-clients ul {
    max-width: 100%;
    height: auto; }
    .beauty-section .o-clients ul li {
      margin: 15px 15px;
      flex: 0 0 39%; }
      @media screen and (min-width: 768px) {
        .beauty-section .o-clients ul li {
          flex: 0 0 120px;
          margin: 5px 15px; } }
      @media screen and (min-width: 992px) {
        .beauty-section .o-clients ul li {
          flex: 0 0 auto; } }
      .beauty-section .o-clients ul li:first-child {
        flex: 0 0 50px; }
        @media screen and (min-width: 992px) {
          .beauty-section .o-clients ul li:first-child {
            flex: 0 0 auto; } }
      .beauty-section .o-clients ul li:nth-child(2) {
        display: block; }
      .beauty-section .o-clients ul li img {
        width: 100%; }

@media screen and (min-width: 992px) {
  .pricing-btn .btn {
    max-width: 500px; } }

.pricing-btn .btn:after {
  right: 10px; }
  @media screen and (min-width: 992px) {
    .pricing-btn .btn:after {
      right: inherit; } }

.white.new-cta-style-fix {
  padding: 0;
  margin: 0;
  margin-top: 10px;
  text-align: center;
  background: none;
  border: none;
  font-size: 13px;
  line-height: 19px;
  text-transform: lowercase; }
  @media screen and (min-width: 992px) {
    .white.new-cta-style-fix {
      text-align: left; } }
  .white.new-cta-style-fix:hover {
    background: none; }

/* ANCHOR new free trial  */
.full-witdth-box {
  width: 100%;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 15px 0px #e3e3e3;
  -moz-box-shadow: 0px 0px 15px 0px #e3e3e3;
  box-shadow: 0px 0px 15px 0px #e3e3e3;
  margin-bottom: 20px;
  padding: 25px;
  overflow: hidden;
  text-align: left; }
  @media screen and (min-width: 768px) {
    .full-witdth-box {
      padding: 60px; } }

.full-witdth-box .u-pull-left {
  float: none; }
  @media screen and (min-width: 992px) {
    .full-witdth-box .u-pull-left {
      float: left; } }

.full-witdth-box .u-pull-left img {
  max-width: 233px;
  padding-bottom: 20px; }

.fb-right-pan {
  padding-left: 0;
  width: 100%; }
  @media screen and (min-width: 992px) {
    .fb-right-pan {
      padding-left: 60px;
      width: 62%; } }
  @media screen and (min-width: 1200px) {
    .fb-right-pan {
      padding-left: 60px;
      width: 70%; } }
  .fb-right-pan h3 {
    font-size: 24px;
    font-weight: 600; }

.fb-right-pan p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  font-family: "Montserrat",sans-serif; }
  @media screen and (min-width: 992px) {
    .fb-right-pan p {
      font-size: 18px;
      line-height: 28px; } }

.fb-right-pan h3 + p {
  font-weight: 500;
  font-size: 20px; }

.fb-right-pan ul li {
  background: url(/images/common/tick.png) no-repeat 0 2px;
  padding-left: 40px;
  font-size: 16px;
  line-height: 27px;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  background-size: 21px;
  margin-bottom: 6px; }

.pricing-box {
  width: 100%;
  background-color: #fff;
  display: inline-block;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 20px;
  vertical-align: top;
  margin: 0 10px 40px;
  min-height: 480px;
  position: relative; }
  @media screen and (min-width: 992px) {
    .pricing-box {
      width: 30%;
      min-height: 520px; } }

.pricing-box h2 {
  font-size: 30px;
  line-height: 37px;
  color: #333;
  padding: 10px 0;
  position: relative;
  margin-bottom: 0; }

.pricing-box p {
  font-weight: 500; }

.pricing-box h2:before {
  content: "";
  width: 60px;
  height: 1px;
  background-color: #ccc;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0); }

.pricing-box .price-text {
  text-align: center;
  position: relative;
  padding-top: 20px;
  font-family: "Montserrat",sans-serif;
  font-size: 16px; }

.pricing-box .price-text span {
  font-size: 30px;
  padding-top: 15px;
  color: #FF5800;
  display: block;
  text-transform: none;
  font-weight: 600; }

.pricing-box .price-text span strong {
  font-size: 20px; }

.pricing-box a {
  width: 228px;
  height: 46px;
  line-height: 42px;
  border: 2px solid #333333;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  text-decoration: none;
  font-weight: 500;
  transition: .3s all ease-in;
  margin: 0 auto;
  padding: 0 0 0 30px;
  display: inline-block;
  background: url(/images/common/arrow-black-new.png) no-repeat 85% 50%;
  text-align: left;
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translate(-50%, 0); }

.pricing-box a:hover {
  border: 2px solid #FF5800;
  color: #FF5800;
  background: url(/images/common/arrow-orange.png) no-repeat 85% 50%; }

.four-box {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 15px 0px #e3e3e3;
  -moz-box-shadow: 0px 0px 15px 0px #e3e3e3;
  box-shadow: 0px 0px 15px 0px #e3e3e3;
  padding: 30px 30px 5px 30px;
  margin-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .four-box {
      min-height: 286px;
      padding: 40px 30px 20px 30px; } }

.four-box p {
  font-family: "Montserrat",sans-serif;
  font-size: 16px;
  font-weight: 600; }

.four-box p span {
  font-family: "Montserrat",sans-serif;
  font-size: 20px;
  color: #4A90E2; }

.free-terms-list li {
  font-size: 17px;
  margin-bottom: 20px;
  line-height: 32px;
  clear: both; }

.free-terms-list li span {
  display: inline-block;
  font-family: "Montserrat",sans-serif;
  font-size: 20px;
  color: #2DBE70;
  line-height: 51px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 1px solid #2DBE70;
  width: 51px;
  height: 51px;
  text-align: center;
  margin-right: 20px;
  float: left;
  margin-bottom: 20px;
  padding: 0 14px; }
  @media screen and (min-width: 768px) {
    .free-terms-list li span {
      margin-right: 40px; } }

.free-fact-section .all-facts ul li:last-child {
  width: 20%; }

.free-fact-section .all-facts ul li:nth-child(3) {
  background: #009C52;
  border-color: #009C52; }

.free-fact-section .all-facts ul li:nth-child(4) {
  background: transparent;
  border-color: #4A90E2; }

.free-two-box {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 15px 0px #e3e3e3;
  -moz-box-shadow: 0px 0px 15px 0px #e3e3e3;
  box-shadow: 0px 0px 15px 0px #e3e3e3;
  padding: 50px;
  background-color: #fff;
  text-align: left; }

.free-two-box img {
  float: left; }

.free-two-box h3 {
  padding: 0 0 30px 80px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700; }
  @media screen and (min-width: 768px) {
    .free-two-box h3 {
      font-size: 30px;
      line-height: 40px; } }

.free-two-box ul li {
  font-size: 16px;
  line-height: 28px;
  background: url(/images/common/tick.png) no-repeat 0 4px;
  padding-bottom: 20px;
  padding-left: 40px;
  background-size: 18px; }

.free-two-box ul li.cross {
  font-size: 16px;
  line-height: 28px;
  background: url(/images/free-trial/red-cross.svg) no-repeat 0 4px;
  padding-bottom: 20px;
  padding-left: 40px; }

.free-trial .header_left_pan {
  text-align: center; }
  @media screen and (min-width: 992px) {
    .free-trial .header_left_pan {
      text-align: left; } }

.free-trial .header_left_pan h1 {
  font-size: 30px;
  line-height: 36px;
  margin-top: 30px; }
  @media screen and (min-width: 992px) {
    .free-trial .header_left_pan h1 {
      font-size: 48px;
      line-height: 54px;
      margin-top: 0; } }

.free-trial .header_left_pan .btn_wrapper {
  margin: 0 auto; }
  @media screen and (min-width: 992px) {
    .free-trial .header_left_pan .btn_wrapper {
      margin: 0; } }

.free-trial .header_left_pan p {
  display: block; }

.free-trial .fact-section h2 {
  font-size: 24px;
  line-height: 32px; }
  @media screen and (min-width: 992px) {
    .free-trial .fact-section h2 {
      font-size: 48px;
      line-height: 61px; } }

.free-trial .c-icon-desc-3-col-box h2 {
  padding-bottom: 30px; }

.hire-designer .font-size-30 {
  font-size: 30px;
  line-height: 40px; }

.MERN .skill-service .brick h3 {
  color: #333 !important; }

.free-trial-banner .container .row {
  flex-direction: column-reverse; }
  @media screen and (min-width: 992px) {
    .free-trial-banner .container .row {
      flex-direction: inherit; } }

.free-terms-list li {
  display: flex; }
  @media screen and (min-width: 992px) {
    .free-terms-list li {
      display: inherit; } }

.banner-graphics-img-modify {
  justify-content: center; }
  @media screen and (min-width: 992px) {
    .banner-graphics-img-modify {
      justify-content: flex-end; } }
  .banner-graphics-img-modify img {
    object-fit: contain; }

.free-trial-banner {
  padding-bottom: 40px; }
  @media (min-width: 767px) {
    .free-trial-banner {
      padding-bottom: 0px; } }
  @media screen and (min-width: 992px) {
    .free-trial-banner {
      background-image: url(/images/new-home-banner/banner-gradient.png) !important;
      background-repeat: no-repeat !important;
      background-position: 0 0 !important; } }
  .free-trial-banner:before {
    display: none; }
  @media (max-width: 767px) {
    .free-trial-banner__spacing {
      padding-top: 80px; } }
  @media (min-width: 992px) {
    .free-trial-banner__spacing:after {
      content: "";
      width: 453px;
      height: 199px;
      background-image: url(/images/new-home-banner/banner-gradient2.png);
      background-repeat: no-repeat;
      background-position: 0 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0; } }
  .free-trial-banner .col-60 {
    width: 100%;
    max-width: 100%; }
    @media screen and (min-width: 992px) {
      .free-trial-banner .col-60 {
        flex: 0 0 auto;
        width: 60%; } }
  .free-trial-banner .col-40 {
    width: 100%;
    max-width: 100%; }
    @media screen and (min-width: 992px) {
      .free-trial-banner .col-40 {
        flex: 0 0 auto;
        width: 40%; } }
  .free-trial-banner__heading {
    font-size: 30px;
    line-height: 42px;
    color: #fff;
    margin-bottom: 20px;
    margin-top: 40px;
    text-align: center; }
    @media (min-width: 992px) {
      .free-trial-banner__heading {
        text-align: left; } }
    @media (min-width: 1024px) {
      .free-trial-banner__heading {
        margin-top: 0px;
        font-size: 35px;
        line-height: 48px; } }
    @media (min-width: 1200px) {
      .free-trial-banner__heading {
        font-size: 45px;
        line-height: 58px; } }
  .free-trial-banner__two-section {
    display: flex;
    align-items: center;
    gap: 0px;
    position: relative;
    z-index: 1; }
    @media (min-width: 767px) {
      .free-trial-banner__two-section {
        justify-content: space-between; } }
    @media (min-width: 992px) {
      .free-trial-banner__two-section {
        left: -25px; } }
  .free-trial-banner__two-section-image {
    min-width: 180px;
    height: auto;
    display: none; }
    @media (min-width: 767px) {
      .free-trial-banner__two-section-image {
        min-width: 200px;
        display: block; } }
    @media (min-width: 991px) {
      .free-trial-banner__two-section-image {
        min-width: 200px; } }
    @media (min-width: 1024px) {
      .free-trial-banner__two-section-image {
        min-width: 230px; } }
  .free-trial-banner__two-section-subheading {
    font-size: 25px;
    line-height: 32px;
    color: #fff;
    margin: 0;
    text-align: center; }
    @media (min-width: 992px) {
      .free-trial-banner__two-section-subheading {
        text-align: left; } }
    @media (min-width: 1024px) {
      .free-trial-banner__two-section-subheading {
        font-size: 30px;
        line-height: 38px;
        max-width: 530px;
        width: 100%;
        min-height: 180px;
        height: auto;
        display: flex;
        align-items: center;
        background: url(/images/two-for-one/free-trial-banner-background.png) no-repeat center;
        background-size: 100% 100%;
        left: -20px;
        padding: 10px 10px 10px 20px; } }
  .free-trial-banner__formarea::before {
    display: none; }
    @media (min-width: 1024px) {
      .free-trial-banner__formarea::before {
        display: initial;
        z-index: 0; } }

.how-free-trial {
  padding: 30px 0px;
  background-color: #f6f6f6; }
  @media (min-width: 992px) {
    .how-free-trial {
      padding: 60px 0px; } }
  .how-free-trial__heading {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 35px;
    color: #262e36;
    text-align: center;
    margin-bottom: 20px; }
    @media (min-width: 767px) {
      .how-free-trial__heading {
        font-size: 40px;
        line-height: 45px; } }
    @media (min-width: 1024px) {
      .how-free-trial__heading {
        font-size: 51px;
        line-height: 55px; } }
  .how-free-trial__description {
    font-family: "Poppins";
    text-align: center;
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 20px; }
  .how-free-trial__description-br {
    display: none; }
    @media (min-width: 1200px) {
      .how-free-trial__description-br {
        display: block; } }
  .how-free-trial__subheading {
    font-family: "Poppins";
    font-size: 25px;
    line-height: 30px;
    color: #333;
    text-align: center; }
    .how-free-trial__subheading::after {
      position: absolute;
      content: "";
      width: 50px;
      height: 4px;
      background: #4492dc;
      background-size: 100%;
      bottom: -30px;
      left: 50%;
      transform: translate(-50%, -50%); }
  .how-free-trial__card-area {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 60px;
    gap: 20px; }
    @media (min-width: 767px) {
      .how-free-trial__card-area {
        grid-template-columns: repeat(2, 1fr); } }
    @media (min-width: 1024px) {
      .how-free-trial__card-area {
        grid-template-columns: repeat(4, 1fr); } }
  .how-free-trial__card {
    background-color: #fff;
    border: 1px solid #dddddd;
    padding: 20px 30px; }
  .how-free-trial__card-icon {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-bottom: 15px; }
  .how-free-trial__card-description {
    font-size: 17px;
    line-height: 27px;
    color: #333;
    font-weight: 400;
    font-family: "Poppins"; }

.who-can {
  padding: 30px 0px; }
  @media (min-width: 992px) {
    .who-can {
      padding: 60px 0px; } }
  .who-can__heading {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 35px;
    color: #262e36;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600; }
    @media (min-width: 767px) {
      .who-can__heading {
        font-size: 40px;
        line-height: 45px; } }
    @media (min-width: 1024px) {
      .who-can__heading {
        font-size: 51px;
        line-height: 55px; } }
  .who-can__description {
    font-family: "Poppins";
    text-align: center;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 20px; }
    @media (min-width: 767px) {
      .who-can__description {
        font-size: 21px;
        line-height: 30px; } }
  .who-can__description-br {
    display: none; }
    @media (min-width: 1200px) {
      .who-can__description-br {
        display: block; } }
  .who-can__card-area {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 50px; }
    @media (min-width: 767px) {
      .who-can__card-area {
        gap: 40px;
        grid-template-columns: repeat(2, 1fr); } }
  .who-can__big-card {
    position: relative;
    background-color: #fff;
    padding: 30px 25px;
    box-shadow: 0 0 15px 0 #e3e3e3; }
    .who-can__big-card--green {
      border-top: 6px solid #3cc065; }
    .who-can__big-card--red {
      border-top: 6px solid #ff4949; }
  .who-can__two-section {
    display: flex;
    gap: 20px; }
  .who-can__two-section-icon {
    width: 50px;
    height: 50px;
    object-fit: contain; }
  .who-can__two-section-heading {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: #333333;
    margin-bottom: 24px; }
    @media (min-width: 767px) {
      .who-can__two-section-heading {
        font-size: 30px;
        line-height: 40px; } }
  .who-can__list-li {
    font-size: 16px;
    line-height: 22px;
    margin-left: 35px;
    margin-bottom: 22px;
    font-family: "Poppins"; }
  .who-can__list-icon {
    font-size: 16px;
    line-height: 23px;
    margin-left: 25px; }
  .who-can__list-icon {
    position: absolute;
    left: 0px; }
  .who-can__subheading {
    font-family: "Poppins";
    font-size: 25px;
    line-height: 35px;
    font-weight: 600;
    text-align: center; }
  .who-can__subheading-br {
    display: none; }
    @media (min-width: 1200px) {
      .who-can__subheading-br {
        display: block; } }

.section-invite {
  background-color: #355bbf;
  padding: 30px 0px; }
  @media (min-width: 992px) {
    .section-invite {
      padding: 60px 0px; } }
  .section-invite__heading {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 15px; }
    @media (min-width: 767px) {
      .section-invite__heading {
        font-size: 41px;
        line-height: 51px; } }
    @media (min-width: 1024px) {
      .section-invite__heading {
        font-size: 51px;
        line-height: 56px; } }
  .section-invite__subheading {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 40px; }
    @media (min-width: 767px) {
      .section-invite__subheading {
        font-size: 25px;
        line-height: 34px;
        padding-right: 80px; } }
  .section-invite__list-area {
    margin-right: 0px;
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px; }
    @media (min-width: 767px) {
      .section-invite__list-area {
        display: flex;
        gap: 0px; } }
  .section-invite__asterisktext {
    margin: 0 0 40px;
    color: #fff;
    line-height: normal; }
  .section-invite__list-item {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    flex: 0 0 48%; }
    @media (min-width: 767px) {
      .section-invite__list-item {
        padding-left: 30px;
        padding-right: 15px;
        border-right: 1px solid #5b81e1;
        border-bottom: 1px solid #5b81e1;
        flex: 0 0 33%; } }
    @media (min-width: 992px) {
      .section-invite__list-item {
        flex: 0 0 25%; } }
    .section-invite__list-item:after {
      display: block;
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #355bbf;
      bottom: -7.5px;
      right: -7.5px;
      z-index: 1; }
    @media (min-width: 992px) {
      .section-invite__list-item:first-child {
        padding-left: 0; } }
    @media (min-width: 992px) {
      .section-invite__list-item:nth-child(5) {
        padding-left: 0; } }
    @media (min-width: 992px) {
      .section-invite__list-item:nth-child(9) {
        padding-left: 0; } }
    @media (min-width: 767px) {
      .section-invite__list-item:nth-child(3n+3) {
        border-right: none; } }
    @media (min-width: 992px) {
      .section-invite__list-item:nth-child(3n+3) {
        border-right: 1px solid #5b81e1; } }
    @media (min-width: 992px) {
      .section-invite__list-item:nth-child(4n+4) {
        border-right: none; } }
    @media (min-width: 767px) {
      .section-invite__list-item:nth-last-child(-n+2) {
        border-bottom: 0px; } }
    @media (min-width: 767px) {
      .section-invite__list-item:nth-last-child(-n+3) {
        border-bottom: 0px; } }
    @media (min-width: 992px) {
      .section-invite__list-item:nth-last-child(-n+4) {
        border-bottom: 0px; } }
    .section-invite__list-item:last-child {
      border-bottom: 0px; }
  .section-invite__item {
    display: flex;
    gap: 15px;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .section-invite__item {
        justify-content: flex-start; } }
  .section-invite__item-icon {
    flex: 0 0 auto; }
  .section-invite .owl-stage-outer:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 1px;
    height: 82%;
    background: #5b81e1; }
  .section-invite__item-title {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 0px; }
    @media (min-width: 767px) {
      .section-invite__item-title {
        font-size: 27px;
        line-height: 27px; } }
    .section-invite__item-title span {
      font-size: 14px;
      line-height: 18px;
      display: block; }
      @media (min-width: 767px) {
        .section-invite__item-title span {
          margin-top: 6px; } }

.terms-condition {
  background-color: #f7f7f7;
  padding: 30px 0px; }
  @media (min-width: 767px) {
    .terms-condition {
      padding: 60px 0px; } }
  .terms-condition__heading {
    font-family: "Poppins";
    font-size: 35px;
    line-height: 35px;
    color: #333;
    text-align: center; }
    @media (min-width: 767px) {
      .terms-condition__heading {
        font-size: 51px;
        line-height: 35px; } }
  .terms-condition__subheading {
    font-family: "Poppins";
    font-size: 25px;
    line-height: 35px;
    color: #333;
    text-align: center;
    margin-bottom: 30px; }
  .terms-condition__card {
    position: relative;
    background-color: #fff;
    padding: 20px;
    padding-top: 40px;
    display: block;
    align-items: initial;
    margin-bottom: 30px;
    box-shadow: 0 0 15px 0 #e3e3e3; }
    .terms-condition__card:last-child {
      margin-bottom: 0; }
    @media (min-width: 767px) {
      .terms-condition__card {
        padding-top: 0px;
        padding: 20px;
        align-items: center;
        display: flex;
        gap: 15px; } }
  .terms-condition__card-count {
    font-family: "Poppins";
    background-color: #fff;
    width: 49px;
    height: 49px;
    border-radius: 50px;
    border: 1px solid #3cc065;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600; }
    @media (max-width: 767px) {
      .terms-condition__card-count {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50%, -50%); } }
  .terms-condition__card-description {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 24px;
    color: #333;
    margin-bottom: 0px;
    text-align: center; }
    @media (min-width: 767px) {
      .terms-condition__card-description {
        text-align: initial; } }

.demand-technologies {
  padding: 30px 0px; }
  @media (min-width: 992px) {
    .demand-technologies {
      padding: 60px 0px; } }
  .demand-technologies__heading {
    font-family: "Poppins";
    font-size: 31px;
    line-height: 40px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    margin-bottom: 20px; }
    @media (min-width: 767px) {
      .demand-technologies__heading {
        font-size: 41px;
        line-height: 45px;
        margin-bottom: 40px; } }
    @media (min-width: 1024px) {
      .demand-technologies__heading {
        font-size: 51px;
        line-height: 55px; } }
  .demand-technologies__area {
    margin-bottom: 50px; }
  .demand-technologies__list {
    display: block;
    margin-top: 20px;
    margin-bottom: 30px; }
    @media (min-width: 767px) {
      .demand-technologies__list {
        display: flex;
        margin-bottom: 0px; } }
  .demand-technologies__tech-title {
    font-family: "Poppins";
    text-align: center;
    font-size: 21px;
    line-height: 30px;
    font-weight: 600;
    padding-bottom: 5px;
    margin-top: 30px;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    border-radius: 10px 10px 0 0; }
    @media (min-width: 767px) {
      .demand-technologies__tech-title {
        padding: 0;
        border-radius: 0px;
        max-width: 160px;
        margin-top: 0px;
        width: 266px;
        padding-bottom: 15px;
        text-align: initial; } }
    .demand-technologies__tech-title--blue {
      border: 2px solid #4492dc; }
      @media (min-width: 767px) {
        .demand-technologies__tech-title--blue {
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 5px solid #4492dc; } }
    .demand-technologies__tech-title--green {
      border: 2px solid #3cc065; }
      @media (min-width: 767px) {
        .demand-technologies__tech-title--green {
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 5px solid #3cc065; } }
  .demand-technologies__tech-lists {
    border-bottom: none;
    flex-grow: 1;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 0 0 10px 10px; }
    @media (min-width: 767px) {
      .demand-technologies__tech-lists {
        padding: 0;
        background: none;
        border-radius: 0;
        border-bottom: 1px solid #e1e1e1; } }
  .demand-technologies__tech-ul {
    display: flex;
    gap: 15px;
    padding-top: 5px;
    padding-top: 8px;
    flex-wrap: wrap;
    justify-content: center; }
    @media (min-width: 767px) {
      .demand-technologies__tech-ul {
        padding-top: 5px;
        flex-wrap: nowrap;
        justify-content: initial;
        gap: 0; } }
    @media (min-width: 992px) {
      .demand-technologies__tech-ul {
        gap: 15px; } }
  .demand-technologies__tech-list {
    font-family: "Poppins";
    border-right: 1px solid #e1e1e1;
    padding-right: 20px;
    font-size: 16px;
    font-weight: 600; }
    @media (min-width: 767px) {
      .demand-technologies__tech-list:first-child {
        padding-left: 20px; } }
    .demand-technologies__tech-list:last-child {
      border: none; }
  .demand-technologies__subtitle {
    font-family: "Poppins";
    font-size: 25px;
    line-height: 35px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 40px; }
  .demand-technologies__subtitle-br {
    display: none; }
    @media (min-width: 1200px) {
      .demand-technologies__subtitle-br {
        display: block; } }

/* ANCHOR Pricing*/
.x-page-logo {
  padding: 25px 0; }

.pricing-page {
  padding: 50px 0; }
  @media screen and (min-width: 992px) {
    .pricing-page {
      padding: 80px 0; } }
  @media screen and (min-width: 992px) {
    .pricing-page h2 {
      font-size: 56px; } }
  .pricing-page small {
    display: block;
    font-size: 30px;
    margin-bottom: 30px; }
  .pricing-page .p-page-box {
    padding: 50px 30px 50px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    background: #fff;
    width: 100%;
    text-align: center; }
    .pricing-page .p-page-box h3 {
      color: #333;
      font-size: 24px;
      text-transform: capitalize;
      font-weight: 400;
      margin: 15px 0; }
    .pricing-page .p-page-box h4 {
      color: #ff5800;
      font-size: 36px; }
      .pricing-page .p-page-box h4 small {
        font-size: 18px; }
    .pricing-page .p-page-box p {
      font-size: 16px;
      line-height: 28px;
      color: #414446; }
    .pricing-page .p-page-box ul li {
      font-size: 14px;
      line-height: 19px;
      padding-left: 15px;
      position: relative;
      margin-bottom: 10px;
      text-align: left; }
      .pricing-page .p-page-box ul li:before {
        content: '';
        position: absolute;
        left: 0;
        top: 5px;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: #ff6614; }
    .pricing-page .p-page-box a {
      display: inline-block;
      margin: 20px auto 0;
      border: 2px solid #333;
      border-radius: 5px;
      font-size: 18px;
      color: #333;
      padding: 6px 20px; }
  .pricing-page h6 {
    color: #ff6614;
    margin-top: 20px; }

.payment-section {
  padding: 50px 0; }
  @media screen and (min-width: 992px) {
    .payment-section {
      padding: 80px 0; } }
  .payment-section h2 {
    font-size: 56px;
    margin-bottom: 30px; }
  .payment-section .payment-box-wrap {
    padding: 25px 50px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    background: #fff;
    margin-bottom: 25px; }
    .payment-section .payment-box-wrap .payment-box-left {
      padding: 20px 0 0; }
      @media screen and (min-width: 992px) {
        .payment-section .payment-box-wrap .payment-box-left {
          flex: 0 0 25%;
          border-right: 1px solid #ccc; } }
    .payment-section .payment-box-wrap .payment-box-right {
      padding: 20px 0 0 30px; }
      @media screen and (min-width: 992px) {
        .payment-section .payment-box-wrap .payment-box-right {
          flex: 0 0 25%;
          border-left: 1px solid #ccc; } }
    @media screen and (min-width: 992px) {
      .payment-section .payment-box-wrap .payment-box-mid {
        padding: 20px 0 0 30px;
        flex: 1; } }
    .payment-section .payment-box-wrap h4 {
      font-size: 18px;
      font-weight: 500; }
    .payment-section .payment-box-wrap p {
      padding: 20px 30px; }

@media screen and (min-width: 1200px) {
  .UI-UX .o-engagement-model__cards__top__inner h3 {
    font-size: 28px; } }

.new-price-wrapper .o-engagement-model__cards .o-engagement-model__cards__top__inner {
  margin: 15px 0 5px 0; }
  @media (min-width: 992px) {
    .new-price-wrapper .o-engagement-model__cards .o-engagement-model__cards__top__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 74px;
      margin: 15px 0 5px 0; } }
  .new-price-wrapper .o-engagement-model__cards .o-engagement-model__cards__top__inner h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 0px; }
    @media (min-width: 992px) {
      .new-price-wrapper .o-engagement-model__cards .o-engagement-model__cards__top__inner h3 {
        font-size: 28px;
        line-height: 37px; } }
    .new-price-wrapper .o-engagement-model__cards .o-engagement-model__cards__top__inner h3 .container1 {
      color: #FF5800; }
    .new-price-wrapper .o-engagement-model__cards .o-engagement-model__cards__top__inner h3 .price-hour-tag {
      color: #FF5800;
      font-weight: 500; }

.new-price-wrapper .o-engagement-model__cards .o-engagement-model__cards__bottom {
  padding: 0px 0px;
  margin-bottom: 10px; }
  @media (min-width: 992px) {
    .new-price-wrapper .o-engagement-model__cards .o-engagement-model__cards__bottom {
      padding: 0; } }
  .new-price-wrapper .o-engagement-model__cards .o-engagement-model__cards__bottom .price-text {
    margin-bottom: 0px; }

@media screen and (min-width: 992px) {
  .new-price-wrapper .o-engagement-model__cards .time-material-side {
    margin-bottom: 35px; } }

.price-separate-btn {
  max-width: 370px;
  padding: 14px 10px; }

.box.review-item {
  margin-bottom: 30px;
  display: inline-block;
  border: 1px solid #c3c3c3;
  border-radius: 6px;
  padding: 30px 35px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  position: relative; }

.review-masonry {
  column-count: 3;
  margin: 2em 0;
  column-gap: 2em;
  -webkit-column-gap: 2em; }

.review-item p {
  color: #333333;
  font-size: 15px;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 20px; }

.review-item h3 {
  color: #333333;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  padding-right: 15px; }

.review-star {
  display: inline-block;
  background: url(/images/common/star-icon.svg) no-repeat 0 0;
  width: 88px;
  height: 14px;
  margin-top: 10px; }

.o-case-studies-top-selector .selector-dropdown {
  width: 100%;
  height: 42px;
  font-size: 14px;
  line-height: 42px;
  color: #000;
  font-weight: 500;
  margin-bottom: 10px;
  position: relative;
  margin-right: auto; }

.o-case-studies-top-selector__client-review .selector-dropdown .selector-toggle {
  position: relative;
  border: 1px solid #AEBECD !important;
  padding: 0 60px 0 20px;
  border-radius: 25px;
  min-width: 227px;
  text-align: left;
  line-height: 35px;
  cursor: pointer;
  display: block; }

.o-case-studies-top-selector__client-review .selector-dropdown .selector-toggle:before {
  content: "";
  background: url(/images/common/nav-down-arrow.svg) 100% 50% no-repeat;
  position: absolute;
  right: 20px;
  width: 10px;
  height: 10px;
  top: 16px; }

.o-case-studies-top-selector__client-review .selector-dropdown .dropdown-content {
  position: absolute;
  min-width: 219px;
  box-shadow: 0px 0 0 0px rgba(0, 0, 0, 0.2);
  padding: 12px 24px;
  z-index: 1;
  width: 100%;
  left: 4px;
  top: 30px;
  border-left: 1px solid #AEBECD;
  border-right: 1px solid #AEBECD;
  border-bottom: 1px solid #AEBECD;
  background: #fff;
  display: none; }

.o-case-studies-top-selector .selector-dropdown .dropdown-content ul li a {
  color: #333;
  display: block;
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  padding: 12px 0;
  text-transform: uppercase;
  max-width: 350px;
  text-decoration: none;
  position: relative;
  border-radius: 5px; }

.client-top-wrap {
  display: flex; }

.client-top-wrap .client-circle {
  display: inline-block;
  width: 65px;
  height: 65px;
  overflow: hidden;
  margin-right: 20px; }

.client-top-wrap .client-review-right {
  flex: 1; }

.client-top-wrap .client-review-right h3 {
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 5px; }

.client-top-wrap .client-review-right h6 {
  font-size: 14px;
  line-height: 14px;
  color: #333;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 5px; }

.client-top-wrap .client-review-right p {
  color: #8c8585;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500; }

.client-review-helper {
  padding-top: 50px; }

.box.review-item:before {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  background-image: url(/images/clients-reviews/quote-icon.png);
  background-repeat: no-repeat;
  right: 35px;
  bottom: 30px; }

.review-item .star {
  display: none; }

.client-review-bnr-icon-wrap img {
  margin: 0 25px; }

.teamquote-body {
  position: relative;
  /** This class will hide all the body be default **/
  /** This class will show the body if it has a parent with active       class
 **/
  /* #float-sec {
    
  } */ }
  .teamquote-body .quote-section h2 {
    font-size: 32px;
    margin-top: 0;
    text-align: center; }
  .teamquote-body .accordion-section .panel-body {
    display: none; }
  .teamquote-body .accordion-section .panel {
    border: solid 1px #D6D6D6;
    border-radius: 8px;
    margin-bottom: 10px;
    background: #fff;
    position: relative; }
  .teamquote-body .accordion-section .panel:before {
    content: '';
    position: absolute;
    background: url(/images/team-quote/plus.png) no-repeat 0 0;
    height: 20px;
    width: 20px;
    top: 17px;
    right: 20px;
    cursor: pointer; }
  .teamquote-body .accordion-section .panel.active:before {
    content: '';
    position: absolute;
    background: url(/images/team-quote/minus.png) no-repeat 0 0;
    height: 10px;
    width: 30px;
    top: 25px;
    right: 15px;
    cursor: pointer; }
  .teamquote-body .ttip {
    position: relative;
    display: inline-block; }
  .teamquote-body .ttip img {
    position: relative;
    top: -1px;
    left: 5px; }
  .teamquote-body .ttip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s; }
  .teamquote-body .ttip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent; }
  .teamquote-body .ttip:hover .tooltiptext {
    visibility: visible;
    opacity: 1; }
  .teamquote-body .quote-section-head h4 {
    font-size: 18px;
    margin-top: 0;
    line-height: inherit;
    text-align: center; }
  .teamquote-body .quote-select {
    width: 190px;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    background-color: transparent;
    position: relative; }
  .teamquote-body .quote-select:before {
    content: '';
    position: absolute;
    background-image: url(/images/team-quote/down-arrow.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    right: 20px;
    top: 18px;
    height: 15px;
    width: 15px; }
  .teamquote-body .quote-select select {
    -webkit-appearance: none;
    border: none;
    width: 100%;
    font-size: 14px;
    background: none; }
  .teamquote-body .quote-select select:focus {
    outline: none; }
  .teamquote-body .quote-section-head-right small {
    font-style: italic;
    font-size: 12px;
    margin-top: 10px;
    display: block; }
  .teamquote-body .accordion-head {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center; }
  .teamquote-body .accordion-head h3 {
    font-size: 24px;
    margin: 0; }
  .teamquote-body .accordion-head-span1, .teamquote-body .accordion-head-span2, .teamquote-body .accordion-head-span3, .teamquote-body .accordion-head-span4, .teamquote-body .accordion-head-span5, .teamquote-body .accordion-head-span6, .teamquote-body .accordion-head-span7 {
    flex: 1;
    text-align: center; }
  .teamquote-body .accordion-head-span1 {
    text-align: center; }
  .teamquote-body .accordion-head-span3 {
    max-width: 167px;
    flex: 0 0 167px;
    margin-right: 35px; }
  .teamquote-body .accordion-head-span4 {
    max-width: 122px;
    flex: 0 0 122px; }
  .teamquote-body .body-cell1, .teamquote-body .body-cell2, .teamquote-body .body-cell3, .teamquote-body .body-cell4, .teamquote-body .body-cell5, .teamquote-body .body-cell6, .teamquote-body .body-cell7 {
    flex: 1;
    text-align: center;
    margin-top: 20px; }
  .teamquote-body .body-cell1 {
    text-align: center;
    font-size: 16px;
    font-weight: 600; }
  .teamquote-body .panel-header {
    padding: 15px 25px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer; }
  .teamquote-body .panel-header span {
    font-size: 13px;
    font-weight: 500;
    color: #000;
    margin-left: 7px; }
  .teamquote-body .panel-body {
    border-top: 1px solid #e7e4e4;
    padding: 0 25px 20px; }
  .teamquote-body .body-cell3 .body-select-wrap {
    border-radius: 5px;
    border: 1px solid #6e6d6d;
    padding: 8px 20px;
    position: relative;
    flex: 1; }
  .teamquote-body .body-cell3 .body-select-wrap:before {
    content: '';
    position: absolute;
    background-image: url(/images/team-quote/down-arrow.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    right: 10px;
    top: 15px;
    height: 15px;
    width: 15px; }
  .teamquote-body .body-cell3 .body-select-wrap select {
    -webkit-appearance: none;
    border: none;
    width: 100%;
    font-size: 15px;
    background: none; }
  .teamquote-body .body-cell3 .body-select-wrap select:focus {
    outline: none; }
  .teamquote-body .body-cell4 .body-select-wrap {
    border-radius: 5px;
    border: 1px solid #6e6d6d;
    padding: 8px 20px;
    position: relative;
    flex: 1; }
  .teamquote-body .body-cell4 .body-select-wrap:before {
    content: '';
    position: absolute;
    background-image: url(/images/team-quote/down-arrow.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    right: 10px;
    top: 15px;
    height: 15px;
    width: 15px; }
  .teamquote-body .body-cell4 .body-select-wrap select {
    -webkit-appearance: none;
    border: none;
    width: 100%;
    font-size: 15px;
    background: none; }
  .teamquote-body .body-cell4 .body-select-wrap select:focus {
    outline: none; }
  .teamquote-body section.quote-section {
    position: relative;
    /* z-index: 1; */
    background: #f5f5f5;
    padding-top: 70px;
    padding-bottom: 80px; }
  .teamquote-body .floating-section {
    position: relative; }
  .teamquote-body .floating-section h3 {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 25px;
    margin-top: 30px; }
  .teamquote-body .developer-list {
    overflow-y: scroll;
    height: 120px; }
  .teamquote-body .developer-list::-webkit-scrollbar-track {
    border: 2px solid white;
    background-color: #b2bec3; }
  .teamquote-body .developer-list::-webkit-scrollbar {
    width: 5px;
    background-color: #dfe6e9; }
  .teamquote-body .developer-list::-webkit-scrollbar-thumb {
    background-color: #CCC;
    border-radius: 10px; }
  .teamquote-body .developer-list ul {
    padding-left: 0;
    min-height: 175px;
    padding-right: 30px; }
  .teamquote-body .developer-list ul li {
    display: flex;
    border-bottom: 1px solid #d5d1d1;
    padding: 15px 0; }
  .teamquote-body .developer-list ul li:first-child {
    padding-top: 0; }
  .teamquote-body .developer-list ul li:last-child {
    border-bottom: 0; }
  .teamquote-body .developer-list-name {
    font-size: 13px;
    font-weight: 500;
    flex: 0 0 55%; }
  .teamquote-body .developer-list-pricing {
    text-align: right;
    flex: 1; }
  .teamquote-body span.color {
    color: #3ed13e;
    font-weight: 600; }
  .teamquote-body .developer-list-pricing {
    text-align: right;
    flex: 1;
    color: #ccc;
    font-size: 13px;
    font-weight: 500; }
  .teamquote-body span.price {
    color: #333;
    font-weight: 600;
    font-size: 16px;
    margin-left: 20px; }
  .teamquote-body .list-total {
    display: flex;
    align-items: center;
    margin-top: 20px; }
  .teamquote-body .list-total p {
    font-size: 13px;
    font-weight: 500;
    flex: 0 0 50%;
    color: #ccc;
    margin-bottom: 0; }
  .teamquote-body .list-total span {
    text-align: right;
    flex: 1;
    color: #333;
    font-weight: 600;
    font-size: 16px;
    justify-content: flex-end; }
  .teamquote-body .right-head {
    align-items: flex-end;
    margin-bottom: 30px; }
  .teamquote-body .right-head p {
    margin: 0;
    color: #ada7a7;
    font-size: 13px;
    flex: 0 0 39%;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    margin-bottom: 15px; }
  .teamquote-body .right-head h4 {
    margin: 0;
    flex: 1;
    text-align: center;
    color: #000;
    font-size: 24px; }
  .teamquote-body .right-body {
    align-items: center;
    margin-bottom: 30px; }
  .teamquote-body .right-body p {
    margin: 0;
    font-size: 17px;
    color: #000;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px; }
  .teamquote-body .right-body h4 {
    margin: 0;
    flex: 1;
    text-align: center;
    font-size: 17px;
    color: #000; }
  .teamquote-body .right-foot {
    align-items: center;
    margin-bottom: 30px; }
  .teamquote-body .right-foot p {
    margin: 0;
    font-size: 24px;
    color: #3EC066;
    font-weight: 500;
    text-align: center;
    margin-bottom: 15px; }
  .teamquote-body .right-foot h4 {
    margin: 0;
    flex: 1;
    text-align: center;
    font-size: 24px;
    color: #3EC066;
    font-weight: 500; }
  .teamquote-body .floating-section-right button {
    width: 100%;
    padding: 24px 0;
    border-radius: 8px;
    background: #FF5800;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    border: none;
    letter-spacing: 0;
    font-weight: 500;
    cursor: pointer;
    position: relative; }
  .teamquote-body .floating-section-right button:before {
    content: '';
    position: absolute;
    background-image: url(/images/team-quote/right-arrow.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    right: 21%;
    top: 30px;
    height: 25px;
    width: 25px; }
  .teamquote-body input[type=range] {
    visibility: hidden;
    width: 0;
    font-size: 0; }
  .teamquote-body button.range {
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: white;
    font-size: 24px;
    border: 1px solid lightgrey;
    cursor: pointer;
    -webkit-appearance: none;
    margin: 0 10px; }
  .teamquote-body .float-sec-none {
    display: none; }
  .teamquote-body .floating-section {
    position: absolute;
    left: 0;
    right: 0;
    background: #fff;
    bottom: 0;
    box-shadow: 0 -12px 10px 0 rgba(0, 0, 0, 0.05); }
  .teamquote-body .floating-section-trigger {
    background: #fff;
    height: 35px;
    width: 70px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: absolute;
    top: -35px;
    right: 1%;
    cursor: pointer;
    box-shadow: 0 -6px 10px 0 rgba(0, 0, 0, 0.05); }
  .teamquote-body .floating-section-trigger.active::before {
    background: url(/images/team-quote/down-arrow-big.png) no-repeat 0 0;
    transform: rotate(0);
    top: 5px;
    top: 15px; }
  .teamquote-body .floating-section-trigger::before {
    content: '';
    position: absolute;
    background: url(/images/team-quote/down-arrow-big.png) no-repeat 0 0;
    left: 0;
    right: 0;
    text-align: center;
    width: 20px;
    height: 20px;
    margin: 0 auto;
    transform: rotate(-180deg);
    top: 5px; }
  .teamquote-body .d-m-none {
    display: none; }
  .teamquote-body .d-d-none {
    display: block; }
  .teamquote-body .accordion-section .active .panel-body {
    display: block;
    /* align-items: center; */ }
  .teamquote-body .ttip.d-d-none {
    margin-bottom: 15px; }
  .teamquote-body .minus, .teamquote-body .plus {
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 4px;
    padding: 8px 5px 8px 5px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 0; }
  .teamquote-body .number input {
    width: 45px;
    border-radius: 5px;
    border: 1px solid #6e6d6d;
    text-align: center;
    font-size: 15px;
    padding: 2px 0; }
  .teamquote-body .number .minus {
    position: relative;
    cursor: pointer; }
  .teamquote-body .number .minus:before {
    content: '';
    position: absolute;
    background: url(/images/team-quote/c-minus.png) no-repeat 0 0;
    height: 17px;
    width: 17px;
    top: 2px;
    left: -2px; }
  .teamquote-body .number .plus {
    position: relative;
    cursor: pointer; }
  .teamquote-body .number .plus:before {
    content: '';
    position: absolute;
    background: url(/images/team-quote/c-plus.png) no-repeat 0 0;
    height: 17px;
    width: 17px;
    top: 2px;
    left: 4px; }
  .teamquote-body .quote-section-head-right {
    text-align: center; }

@media only screen and (min-width: 768px) {
  .teamquote-body .quote-section-head {
    display: flex;
    align-items: flex-start; }
  .teamquote-body .quote-section-head-right {
    flex: 1;
    text-align: right; }
  .teamquote-body .quote-select {
    margin: auto 0 auto auto; }
  .teamquote-body .d-d-none {
    display: none; }
  .teamquote-body .d-m-none {
    display: block; }
  .teamquote-body .accordion-section .active .panel-body {
    display: flex;
    /* align-items: center; */ }
  .teamquote-body .body-cell3 {
    max-width: 165px;
    flex: 0 0 165px;
    margin-right: 35px;
    margin-left: 35px; }
  .teamquote-body .body-cell4 {
    max-width: 120px;
    flex: 0 0 120px; }
  .teamquote-body .body-cell1, .teamquote-body .body-cell2, .teamquote-body .body-cell3, .teamquote-body .body-cell4, .teamquote-body .body-cell5, .teamquote-body .body-cell6, .teamquote-body .body-cell7 {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0; }
  .teamquote-body .ttip.d-d-none {
    margin-bottom: 0; }
  .teamquote-body .body-cell1 {
    text-align: left;
    max-width: 330px;
    flex: 0 0 330px;
    justify-content: flex-start; }
  .teamquote-body .number {
    display: flex;
    align-items: center; }
  .teamquote-body .floating-section-wrap {
    display: flex;
    margin-bottom: 20px; }
  .teamquote-body .floating-section-left {
    flex: 0 0 50%;
    border-right: 1px solid #e7e4e4;
    padding-right: 30px; }
  .teamquote-body .floating-section-right {
    flex: 1;
    padding-left: 30px; }
  .teamquote-body .floating-section-right button {
    font-size: 18px;
    letter-spacing: 3px; }
  .teamquote-body .floating-section-right button:before {
    right: 23%; }
  .teamquote-body .developer-list {
    height: 160px; }
  .teamquote-body .right-head {
    display: flex; }
  .teamquote-body .right-head p {
    text-align: left;
    margin-bottom: 0; }
  .teamquote-body .right-head h4 {
    text-align: right; }
  .teamquote-body .right-body {
    display: flex; }
  .teamquote-body .right-body p {
    text-align: left;
    margin-bottom: 0; }
  .teamquote-body .right-body h4 {
    text-align: right; }
  .teamquote-body .right-foot {
    display: flex; }
  .teamquote-body .right-foot p {
    text-align: left;
    margin-bottom: 0; }
  .teamquote-body .right-foot h4 {
    text-align: right; }
  .teamquote-body .quote-section h2 {
    font-size: 42px;
    text-align: left; }
  .teamquote-body .quote-section-head h4 {
    text-align: left; }
  .teamquote-body .quote-section-head-right {
    text-align: right; }
  .teamquote-body .quote-select {
    margin: auto 0 auto auto; }
  .teamquote-body .accordion-head {
    display: flex;
    text-align: left;
    padding: 0 25px; }
  .teamquote-body .accordion-head-span1 {
    max-width: 330px;
    flex: 0 0 330px;
    text-align: left; }
  .teamquote-body .body-cell6 {
    background: #EEF9F1;
    font-weight: 600; }
  .teamquote-body .accordion-head-span6 .ttip {
    font-weight: 600; }
  .teamquote-body .panel-body {
    padding: 0 25px; }
  .teamquote-body .list-total span {
    padding-right: 35px; }
  .teamquote-body .accordion-head-span2.d-m-none {
    flex: 0 0 102px; }
  .teamquote-body .accordion-head-span3.d-m-none {
    margin: 0 35px; } }

.at-pan-1 {
  background-color: #fff;
  padding: 40px;
  border: 1px solid #C5C5C5;
  box-shadow: 0px 0px 34px 0px #0000001c;
  margin-bottom: 30px;
  border-radius: 10px;
  text-align: left;
  vertical-align: top; }
  @media (min-width: 992px) {
    .at-pan-1 {
      display: flex;
      align-items: flex-start; } }
  .at-pan-1 h3 {
    text-align: left; }
  .at-pan-1 p {
    text-align: left; }
  .at-pan-1 img {
    margin-right: 20px;
    margin-bottom: 20px; }
  .at-pan-1 ul li {
    width: 100%;
    background: url(/images/common/green-tick.svg) no-repeat 0 5px;
    padding: 0 0 10px 30px;
    font-size: 18px;
    margin-right: 20px; }
    @media (min-width: 992px) {
      .at-pan-1 ul li {
        width: 30%;
        float: left; } }

.testing-tool-pan {
  border-top: 1px solid #ccc;
  padding: 20px 0;
  font-size: 18px; }
  .testing-tool-pan p {
    font-weight: 500; }
  .testing-tool-pan ul {
    display: flex;
    flex-wrap: wrap; }
    .testing-tool-pan ul li {
      display: flex;
      margin-right: 20px;
      vertical-align: middle;
      font-size: 30px;
      align-self: center; }

.testing-process {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  overflow: hidden; }
  .testing-process .arrow_box:after, .testing-process .arrow_box:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .testing-process .tp-pan-left {
    display: flex;
    flex: 100%; }
    @media (min-width: 992px) {
      .testing-process .tp-pan-left {
        flex: 45%; } }
    .testing-process .tp-pan-left div {
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      padding: 20px 40px;
      background: #fff;
      border: 1px solid #E3E3E3;
      border-radius: 10px;
      min-height: 100px;
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      align-self: flex-start;
      margin-top: 20px; }
      @media (min-width: 992px) {
        .testing-process .tp-pan-left div {
          width: 80%;
          margin-top: 0; } }
      .testing-process .tp-pan-left div:after, .testing-process .tp-pan-left div:before {
        left: 100%;
        top: 30px;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none; }
      .testing-process .tp-pan-left div:after {
        border-color: rgba(136, 183, 213, 0);
        border-left-color: #fff;
        border-width: 15px;
        margin-top: -15px; }
      .testing-process .tp-pan-left div:before {
        border-color: rgba(194, 225, 245, 0);
        border-left-color: #E3E3E3;
        border-width: 16px;
        margin-top: -16px; }
      .testing-process .tp-pan-left div span {
        background-color: #036497;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        left: 10px;
        top: 10px;
        position: absolute;
        content: "";
        text-align: center;
        color: #fff;
        line-height: 30px;
        font-size: 12px; }
        @media (min-width: 992px) {
          .testing-process .tp-pan-left div span {
            left: 454px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 17px; } }
      .testing-process .tp-pan-left div img {
        margin-right: 10px;
        margin-left: 20px;
        width: 43px; }
        @media (min-width: 992px) {
          .testing-process .tp-pan-left div img {
            margin-right: 30px;
            margin-left: 0; } }
      .testing-process .tp-pan-left div p {
        text-align: left;
        flex: 1;
        margin-bottom: 0; }
  .testing-process .tp-pan-right {
    display: flex;
    flex: 100%;
    justify-content: flex-end; }
    @media (min-width: 992px) {
      .testing-process .tp-pan-right {
        flex: 45%; } }
    .testing-process .tp-pan-right div {
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      padding: 20px 40px;
      background-color: #fff;
      border: 1px solid #C5C5C5;
      border-radius: 10px;
      min-height: 100px;
      position: relative;
      margin-top: 20px;
      text-align: right;
      width: 100%;
      display: flex;
      align-items: center;
      align-self: flex-start; }
      @media (min-width: 992px) {
        .testing-process .tp-pan-right div {
          width: 80%;
          margin-top: 80px; } }
      .testing-process .tp-pan-right div:after, .testing-process .tp-pan-right div:before {
        right: 100%;
        top: 30px;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none; }
      .testing-process .tp-pan-right div:after {
        border-color: rgba(136, 183, 213, 0);
        border-right-color: #fff;
        border-width: 15px;
        margin-top: -15px; }
      .testing-process .tp-pan-right div:before {
        border-color: rgba(194, 225, 245, 0);
        border-right-color: #E3E3E3;
        border-width: 17px;
        margin-top: -17px; }
      .testing-process .tp-pan-right div span {
        background-color: #036497;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        left: 10px;
        top: 10px;
        position: absolute;
        content: "";
        text-align: center;
        color: #fff;
        line-height: 30px;
        font-size: 12px; }
        @media (min-width: 992px) {
          .testing-process .tp-pan-right div span {
            left: -114px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 17px; } }
      .testing-process .tp-pan-right div img {
        margin-right: 10px;
        margin-left: 20px;
        width: 43px; }
        @media (min-width: 992px) {
          .testing-process .tp-pan-right div img {
            margin-right: 30px;
            margin-left: 0; } }
      .testing-process .tp-pan-right div p {
        text-align: left;
        flex: 1;
        margin: 0; }
  .testing-process .tp-middle-line {
    background-color: #ccc;
    width: 4px;
    height: 58%;
    position: absolute;
    left: 50%;
    display: none; }
    @media (min-width: 992px) {
      .testing-process .tp-middle-line {
        display: block; } }

.software-test ul li {
  background: url(/images/common/green-tick.svg) no-repeat 0 5px;
  padding: 0 0 10px 30px;
  font-size: 18px;
  margin-right: 20px; }

/* ANCHOR common-heading-banner*/
.cmn-bnr-mod {
  padding-top: 102px !important; }
  @media screen and (min-width: 992px) {
    .cmn-bnr-mod {
      padding: 65px 20px 0 !important; } }
  .cmn-bnr-mod h1 {
    font-size: 30px !important;
    line-height: 40px !important;
    margin-bottom: 15px !important;
    margin-top: 0 !important; }
    @media screen and (min-width: 992px) {
      .cmn-bnr-mod h1 {
        font-size: 47px !important;
        line-height: 61px !important; } }
  .cmn-bnr-mod h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500 !important; }
    @media screen and (min-width: 992px) {
      .cmn-bnr-mod h2 {
        font-size: 24px;
        line-height: 36px !important;
        font-weight: 600 !important; } }
  @media screen and (min-width: 992px) {
    .cmn-bnr-mod .skill-page-mod li img {
      padding: 20px 0 20px; } }
  @media screen and (min-width: 992px) {
    .cmn-bnr-mod .header_left_pan {
      padding-right: 20px !important;
      padding-top: 50px; } }
  .cmn-bnr-mod .header_left_pan picture {
    display: inline-block; }
    .cmn-bnr-mod .header_left_pan picture img {
      padding-bottom: 10px !important;
      margin-bottom: 20px !important;
      padding-top: 0 !important;
      height: 65px;
      width: auto; }
      @media screen and (min-width: 992px) {
        .cmn-bnr-mod .header_left_pan picture img {
          margin-bottom: 0 !important;
          margin-top: 0 !important;
          padding-bottom: 25px !important;
          height: 85px; } }
  .cmn-bnr-mod .skill-page-mod li {
    display: flex;
    justify-content: center;
    flex: 1 !important; }
    @media screen and (min-width: 992px) {
      .cmn-bnr-mod .skill-page-mod li:first-child {
        padding-left: 0 !important; } }
  .cmn-bnr-mod .schedule-box {
    margin-top: 0;
    height: 100%; }
  .cmn-bnr-mod .schedule-box-top {
    padding-bottom: 0; }
  @media screen and (min-width: 1200px) {
    .cmn-bnr-mod .schedule-box-top-text h2 {
      font-size: 23px !important;
      line-height: 35px; } }
  .cmn-bnr-mod .header_right_pan {
    background: none;
    padding: 0 15px;
    margin-top: 0; }
    @media screen and (min-width: 992px) {
      .cmn-bnr-mod .header_right_pan h3 {
        font-size: 21px !important;
        line-height: 35px;
        padding-bottom: 0;
        font-weight: 600; } }

/* ANCHOR aws devops service*/
.tools-tech {
  padding: 45px 0 50px; }
  @media screen and (min-width: 992px) {
    .tools-tech {
      padding: 70px 0 55px; } }
  .tools-tech p {
    font-size: 18px; }
  .tools-tech .tnt-box {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    height: 138px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.05); }
    .tools-tech .tnt-box i {
      display: block;
      height: 55px; }
    .tools-tech .tnt-box span {
      display: block;
      font-size: 16px;
      line-height: 27px;
      margin-top: 20px; }
  .tools-tech .row {
    margin-bottom: 25px; }
  .tools-tech h2 {
    margin-bottom: 20px;
    font-weight: 600; }
    .tools-tech h2 br {
      display: none; }
      @media screen and (min-width: 992px) {
        .tools-tech h2 br {
          display: block; } }

.step-by-step .spiral-col-box {
  padding: 30px 15px 30px 15px; }
  @media screen and (min-width: 992px) {
    .step-by-step .spiral-col-box {
      padding: 30px 30px 30px 35px; } }

.step-by-step h4 {
  font-size: 16px; }
  @media screen and (min-width: 992px) {
    .step-by-step h4 {
      font-size: 24px; } }

.step-by-step .btn_wrapper {
  margin-top: 25px; }
  @media screen and (min-width: 992px) {
    .step-by-step .btn_wrapper {
      margin-top: 0; } }

.step-by-step .spiral-col-box3 {
  margin-bottom: 60px; }

.step-by-step h2 {
  font-weight: 600; }

/*New Mobile app design css*/
.tools-tech-section h2 {
  font-weight: 600;
  margin-bottom: 25px; }
  @media screen and (min-width: 992px) {
    .tools-tech-section h2 {
      margin-bottom: 15px; } }

.tools-tech-section .tech-list {
  border: 1px solid #E1DFE6; }
  @media screen and (min-width: 992px) {
    .tools-tech-section .tech-list {
      display: flex;
      align-items: center; } }
  @media screen and (min-width: 992px) {
    .tools-tech-section .tech-list div {
      flex: 0 0 25%;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center; } }
  .tools-tech-section .tech-list ul {
    padding-bottom: 0; }
    @media screen and (min-width: 992px) {
      .tools-tech-section .tech-list ul {
        padding: 15px 0 15px 20px; } }
    @media screen and (min-width: 992px) {
      .tools-tech-section .tech-list ul li {
        padding: 5px 15px;
        border: none;
        position: relative; } }
    .tools-tech-section .tech-list ul li:before {
      display: none; }
      @media screen and (min-width: 992px) {
        .tools-tech-section .tech-list ul li:before {
          display: block;
          content: '';
          position: absolute;
          background: #dedede;
          height: 24px;
          width: 1px;
          left: 0px;
          top: 6px; } }
    .tools-tech-section .tech-list ul li:first-child:before {
      display: none; }
    .tools-tech-section .tech-list ul li a {
      font-weight: 500;
      color: #4A90E2;
      text-decoration: underline;
      transition: .2s all ease-in-out; }
      .tools-tech-section .tech-list ul li a:hover {
        color: #2275d7; }
    @media screen and (min-width: 1200px) {
      .tools-tech-section .tech-list ul .lg-no-left-bar:before {
        display: none; } }
    .tools-tech-section .tech-list ul .md-no-left-bar:before {
      display: none; }
      @media screen and (min-width: 1200px) {
        .tools-tech-section .tech-list ul .md-no-left-bar:before {
          display: block; } }
    @media screen and (min-width: 1089px) {
      .tools-tech-section .tech-list ul .md-no-left-bar2:before {
        display: none; } }
    @media screen and (min-width: 1281px) {
      .tools-tech-section .tech-list ul .md-no-left-bar2:before {
        display: block; } }
    .tools-tech-section .tech-list ul .md-no-left-bar3:before {
      display: none; }
      @media screen and (min-width: 1089px) {
        .tools-tech-section .tech-list ul .md-no-left-bar3:before {
          display: block; } }
  @media screen and (min-width: 1200px) {
    .tools-tech-section .tech-list .text-brk {
      padding-right: 15px; } }
  @media screen and (min-width: 992px) {
    .tools-tech-section .tech-list .text-brk-lg {
      margin-right: 15px; } }
  @media screen and (min-width: 1200px) {
    .tools-tech-section .tech-list .text-brk-lg {
      margin-right: 0; } }
  .tools-tech-section .tech-list-inside {
    flex: 1 !important;
    display: block !important;
    align-self: auto !important;
    padding-left: 20px !important; }
    .tools-tech-section .tech-list-inside ul {
      padding: 0;
      display: block;
      width: 100%; }
      .tools-tech-section .tech-list-inside ul li {
        color: #333; }
        .tools-tech-section .tech-list-inside ul li span {
          display: block;
          margin-bottom: 20px; }
          @media screen and (min-width: 992px) {
            .tools-tech-section .tech-list-inside ul li span {
              display: inline;
              margin-top: 0; } }

.tools-tech-section .btn_wrapper {
  margin-top: 30px; }
  @media screen and (min-width: 992px) {
    .tools-tech-section .btn_wrapper {
      margin-top: 50px; } }

.dev-process h2 {
  margin-bottom: 30px;
  font-weight: 600; }
  @media screen and (min-width: 992px) {
    .dev-process h2 {
      margin-bottom: 75px; } }

.dev-process ul {
  margin-bottom: 0; }
  .dev-process ul li {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 24px;
    padding-left: 25px; }
    .dev-process ul li:before {
      background: url(/images/mobile-app/c-right-arrow.svg) no-repeat center center;
      top: 2px; }

@media screen and (min-width: 992px) {
  .dev-process .spiral-col-box1 {
    margin-bottom: 105px; } }

@media screen and (min-width: 992px) {
  .dev-process .spiral-col-box5 {
    margin-top: 180px; } }

@media screen and (min-width: 992px) {
  .dev-process .spiral-col-box6 {
    margin-top: 180px;
    margin-bottom: 60px; } }

@media screen and (min-width: 992px) {
  .process-mobile-app .spiral-col-mid {
    flex: 0 0 24%; } }

@media screen and (min-width: 992px) {
  .process-mobile-app .spiral-col-box1 {
    margin-bottom: 50px;
    margin-top: 30px; } }

@media screen and (min-width: 1200px) {
  .process-mobile-app .spiral-col-box1 {
    margin-bottom: 155px; } }

@media screen and (min-width: 992px) {
  .process-mobile-app .spiral-col-box4 {
    margin-top: 210px; } }

@media screen and (min-width: 1200px) {
  .process-mobile-app .spiral-col-box4 {
    margin-top: 230px; } }

@media screen and (min-width: 992px) {
  .process-mobile-app .spiral-col-box5 {
    margin-top: 60px; } }

@media screen and (min-width: 1200px) {
  .process-mobile-app .spiral-col-box5 {
    margin-top: 120px; } }

@media screen and (min-width: 992px) {
  .process-mobile-app .spiral-col-box6 {
    margin-top: 60px; } }

@media screen and (min-width: 1200px) {
  .process-mobile-app .spiral-col-box6 {
    margin-top: 110px; } }

@media screen and (min-width: 992px) {
  .process-mobile-app .spiral-col-box2 {
    margin-bottom: 15px; } }

@media screen and (min-width: 1200px) {
  .process-mobile-app .spiral-col-box2 {
    margin-bottom: 70px; } }

@media screen and (min-width: 992px) {
  .process-mobile-app .spiral-col-box3 {
    margin-bottom: 30px; } }

@media screen and (min-width: 1200px) {
  .process-mobile-app .spiral-col-box3 {
    margin-bottom: 110px; } }

.process-mobile-app .spiral-col-box7 .color-7 {
  color: #71F99B; }

@media screen and (min-width: 992px) {
  .process-6-in-grid .spiral-col-box p {
    margin-bottom: 0; } }

@media screen and (min-width: 992px) {
  .process-6-in-grid .spiral-col-mid {
    flex: 0 0 24%; } }

@media screen and (min-width: 992px) {
  .process-6-in-grid .spiral-col-box1 {
    margin-bottom: 20px;
    margin-top: 0; } }

@media screen and (min-width: 1200px) {
  .process-6-in-grid .spiral-col-box1 {
    margin-bottom: 75px;
    margin-top: 30px; } }

@media screen and (min-width: 992px) {
  .process-6-in-grid .spiral-col-box4 {
    margin-top: 210px; } }

@media screen and (min-width: 1200px) {
  .process-6-in-grid .spiral-col-box4 {
    margin-top: 230px; } }

@media screen and (min-width: 992px) {
  .process-6-in-grid .spiral-col-box5 {
    margin-top: 40px; } }

@media screen and (min-width: 1200px) {
  .process-6-in-grid .spiral-col-box5 {
    margin-top: 150px; } }

@media screen and (min-width: 992px) {
  .process-6-in-grid .spiral-col-box6 {
    margin-top: 20px; } }

@media screen and (min-width: 1200px) {
  .process-6-in-grid .spiral-col-box6 {
    margin-top: 120px; } }

@media screen and (min-width: 992px) {
  .process-6-in-grid .spiral-col-box2 {
    margin-bottom: 45px; } }

@media screen and (min-width: 1200px) {
  .process-6-in-grid .spiral-col-box2 {
    margin-bottom: 110px; } }

@media screen and (min-width: 992px) {
  .process-6-in-grid .spiral-col-box3 {
    margin-bottom: 30px; } }

@media screen and (min-width: 1200px) {
  .process-6-in-grid .spiral-col-box3 {
    margin-bottom: 110px; } }

.app-cost-section {
  padding: 50px 0 30px; }
  @media screen and (min-width: 992px) {
    .app-cost-section {
      padding: 70px 0 60px; } }
  .app-cost-section h2 {
    text-align: center;
    font-weight: 600;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .app-cost-section h2 {
        font-size: 48px;
        line-height: 61px; } }
  .app-cost-section p {
    font-size: 16px;
    text-align: center;
    font-weight: 500; }
    @media screen and (min-width: 992px) {
      .app-cost-section p {
        font-size: 18px; } }
  .app-cost-section .cost-tabs-new {
    font-family: 'Poppins'; }
    .app-cost-section .cost-tabs-new li a {
      font-family: 'Poppins'; }
  .app-cost-section #cost-tabs {
    display: flex;
    margin-top: 35px;
    margin-bottom: 25px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch; }
    @media screen and (min-width: 992px) {
      .app-cost-section #cost-tabs {
        overflow-x: inherit;
        margin-top: 50px;
        margin-bottom: 40px;
        align-items: center;
        justify-content: center; } }
    .app-cost-section #cost-tabs::-webkit-scrollbar {
      opacity: 0; }
    .app-cost-section #cost-tabs li {
      margin: 0 5px; }
      .app-cost-section #cost-tabs li a  {
        border-radius: 18px;
        border: 1px solid #333333;
        text-align: center;
        padding: 5px 20px;
        font-size: 16px;
        cursor: pointer;
        color: #333;
        display: inline-block; }
      .app-cost-section #cost-tabs li a.active {
        background: #27C070;
        border: 1px solid #27C070;
        color: #fff; }
  .app-cost-section .app-cost-box {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 14px 15px 1px rgba(0, 0, 0, 0.03);
    margin-bottom: 50px; }
    @media screen and (min-width: 992px) {
      .app-cost-section .app-cost-box {
        display: flex; } }
    @media screen and (min-width: 1200px) {
      .app-cost-section .app-cost-box {
        max-width: 970px;
        margin: 0 auto 50px;
        padding: 15px; } }
    .app-cost-section .app-cost-box-left {
      padding: 30px 15px; }
      @media screen and (min-width: 992px) {
        .app-cost-section .app-cost-box-left {
          padding: 40px 75px 30px 30px; } }
      .app-cost-section .app-cost-box-left p {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 0; }
        @media screen and (min-width: 992px) {
          .app-cost-section .app-cost-box-left p {
            text-align: left;
            font-size: 18px;
            line-height: 28px; } }
        .app-cost-section .app-cost-box-left p:nth-child(2) {
          margin-top: 15px; }
          @media screen and (min-width: 992px) {
            .app-cost-section .app-cost-box-left p:nth-child(2) {
              margin-top: 25px; } }
    .app-cost-section .app-cost-box-right {
      text-align: center;
      background: #FAFAFA;
      border-top: 5px solid #4A90E2;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      padding: 30px 15px; }
      @media screen and (min-width: 992px) {
        .app-cost-section .app-cost-box-right {
          flex: 0 0 38%;
          max-width: 38%;
          text-align: left; } }
      @media screen and (min-width: 992px) {
        .app-cost-section .app-cost-box-right {
          border-top: 0;
          border-left: 5px solid #4A90E2;
          border-top-right-radius: 8px;
          border-bottom-left-radius: 0;
          padding: 50px 50px 60px 40px; } }
      .app-cost-section .app-cost-box-right h3 {
        font-size: 22px;
        font-weight: 600;
        line-height: 20px; }
        @media screen and (min-width: 992px) {
          .app-cost-section .app-cost-box-right h3 {
            font-size: 30px;
            line-height: 28px; } }
      .app-cost-section .app-cost-box-right p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 0; }
        @media screen and (min-width: 992px) {
          .app-cost-section .app-cost-box-right p {
            text-align: left;
            font-size: 18px;
            line-height: 28px; } }
      .app-cost-section .app-cost-box-right-top {
        margin-bottom: 30px; }
        @media screen and (min-width: 992px) {
          .app-cost-section .app-cost-box-right-top {
            margin-bottom: 40px; } }

/* ANCHOR hyperledger skill page*/
.service-box-cmn .services-pan {
  padding: 50px 25px 30px; }
  @media screen and (min-width: 992px) {
    .service-box-cmn .services-pan {
      padding: 50px 25px 30px; } }

.expertise-section {
  padding: 40px 0 20px; }
  @media screen and (min-width: 992px) {
    .expertise-section {
      padding: 70px 0 70px; } }
  .expertise-section h2 {
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .expertise-section h2 {
        margin-bottom: 70px; } }
  .expertise-section .expertise-wrapper {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .expertise-section .expertise-wrapper {
        display: flex;
        text-align: inherit; } }
    @media screen and (min-width: 992px) {
      .expertise-section .expertise-wrapper-left {
        flex: 0 0 48%; } }
    @media screen and (min-width: 1200px) {
      .expertise-section .expertise-wrapper-left {
        flex: 0 0 49%; } }
    @media screen and (min-width: 992px) {
      .expertise-section .expertise-wrapper-right {
        flex: 0 0 48%;
        margin-left: 20px; } }
    @media screen and (min-width: 1200px) {
      .expertise-section .expertise-wrapper-right {
        flex: 0 0 49%; } }
    .expertise-section .expertise-wrapper .expertise-box {
      margin-bottom: 30px; }
      @media screen and (min-width: 992px) {
        .expertise-section .expertise-wrapper .expertise-box {
          flex: 0 0 50%;
          margin-bottom: 70px;
          display: flex; } }
      .expertise-section .expertise-wrapper .expertise-box-left {
        width: 170px;
        height: 143px;
        flex: 0 0 170px;
        border-radius: 8px;
        background: #fff;
        border: 1px solid #C5C5C5;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 25px 17px rgba(0, 0, 0, 0.05);
        margin: 0 auto 20px; }
        @media screen and (min-width: 992px) {
          .expertise-section .expertise-wrapper .expertise-box-left {
            margin: 0; } }
      .expertise-section .expertise-wrapper .expertise-box p {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 0; }
      .expertise-section .expertise-wrapper .expertise-box h3 {
        font-size: 20px;
        margin-bottom: 10px; }
        @media screen and (min-width: 992px) {
          .expertise-section .expertise-wrapper .expertise-box h3 {
            font-size: 24px; } }
      @media screen and (min-width: 992px) {
        .expertise-section .expertise-wrapper .expertise-box-right {
          margin-left: 20px; } }

.blk-dev-pr-section {
  padding: 40px 0 20px; }
  @media screen and (min-width: 992px) {
    .blk-dev-pr-section {
      padding: 70px 0 70px; } }
  @media screen and (min-width: 992px) {
    .blk-dev-pr-section h2 {
      margin-bottom: 50px; } }
  .blk-dev-pr-section .blk-dev-pr-left {
    margin-bottom: 20px;
    position: relative;
    margin-top: 25px; }
    @media screen and (min-width: 992px) {
      .blk-dev-pr-section .blk-dev-pr-left {
        margin-top: 0;
        margin-bottom: 60px; } }
    .blk-dev-pr-section .blk-dev-pr-left:before {
      content: '';
      height: 50px;
      width: 50px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: -73px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 21px; }
    .blk-dev-pr-section .blk-dev-pr-left-top {
      display: flex;
      align-items: center;
      margin-bottom: 20px; }
      .blk-dev-pr-section .blk-dev-pr-left-top img {
        width: 50px; }
        @media screen and (min-width: 992px) {
          .blk-dev-pr-section .blk-dev-pr-left-top img {
            width: inherit; } }
      .blk-dev-pr-section .blk-dev-pr-left-top h3 {
        margin-bottom: 0;
        width: 100%;
        padding-left: 20px;
        font-size: 18px; }
        @media screen and (min-width: 992px) {
          .blk-dev-pr-section .blk-dev-pr-left-top h3 {
            font-size: 24px;
            width: 70%; } }
  .blk-dev-pr-section .blk-dev-pr-left-c1::before {
    content: '01';
    background: #2C4D82; }
  @media screen and (min-width: 992px) {
    .blk-dev-pr-section .blk-dev-pr-left-c2 {
      margin-bottom: 90px; } }
  .blk-dev-pr-section .blk-dev-pr-left-c2::before {
    content: '02';
    background: #036497; }
  .blk-dev-pr-section .blk-dev-pr-left-c3::before {
    content: '03';
    background: #1B89AF; }
  .blk-dev-pr-section .blk-dev-pr-left-c4::before {
    content: '04';
    background: #2BBDC3; }
  .blk-dev-pr-section .blk-dev-pr-left-c5::before {
    content: '05';
    background: #28C89A; }
  .blk-dev-pr-section .blk-dev-pr-left-c6::before {
    content: '06';
    background: #3BE06E; }
  .blk-dev-pr-section ul li {
    font-size: 16px;
    line-height: 24px;
    position: relative;
    padding-left: 23px;
    margin-bottom: 15px; }
    .blk-dev-pr-section ul li:before {
      content: '';
      position: absolute;
      background: url(/images/ledger/point-circle.svg) no-repeat 0 0;
      width: 15px;
      height: 15px;
      top: 5px;
      left: 0; }
  .blk-dev-pr-section .blk-dev-pr-box {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    box-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.05);
    padding: 25px 15px 25px 15px;
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .blk-dev-pr-section .blk-dev-pr-box {
        padding: 40px 25px 35px 30px;
        margin-bottom: 0; } }
    .blk-dev-pr-section .blk-dev-pr-box h4 {
      margin-bottom: 15px;
      font-size: 20px; }
    .blk-dev-pr-section .blk-dev-pr-box ul li {
      margin-bottom: 7px; }
  @media screen and (min-width: 992px) {
    .blk-dev-pr-section .blk-dev-pr-box-hight1 {
      min-height: 320px; } }
  @media screen and (min-width: 1200px) {
    .blk-dev-pr-section .blk-dev-pr-box-hight1 {
      min-height: 300px; } }
  @media screen and (min-width: 992px) {
    .blk-dev-pr-section .blk-dev-pr-box-hight2 {
      min-height: 305px; } }
  @media screen and (min-width: 1200px) {
    .blk-dev-pr-section .blk-dev-pr-box-hight2 {
      min-height: 260px; } }
  @media screen and (min-width: 992px) {
    .blk-dev-pr-section .blk-dev-pr-box-hight3 {
      min-height: 305px; } }
  @media screen and (min-width: 992px) {
    .blk-dev-pr-section .blk-dev-pr-box-hight4 {
      min-height: 272px; } }
  @media screen and (min-width: 992px) {
    .blk-dev-pr-section .blk-dev-pr-box-hight5 {
      min-height: 315px; } }
  @media screen and (min-width: 1200px) {
    .blk-dev-pr-section .blk-dev-pr-box-hight5 {
      min-height: 290px; } }
  @media screen and (min-width: 992px) {
    .blk-dev-pr-section .blk-dev-pr-box-hight6 {
      min-height: 320px; } }
  @media screen and (min-width: 1200px) {
    .blk-dev-pr-section .blk-dev-pr-box-hight6 {
      min-height: 295px; } }
  .blk-dev-pr-section .blk-dev-pr-wrapper {
    padding-left: 75px;
    position: relative;
    margin-bottom: 25px;
    margin-top: 50px; }
    @media screen and (min-width: 992px) {
      .blk-dev-pr-section .blk-dev-pr-wrapper {
        margin-top: 0;
        margin-bottom: 50px; } }
    .blk-dev-pr-section .blk-dev-pr-wrapper:before {
      content: '';
      position: absolute;
      background: #DFDFDF;
      width: 6px;
      height: 86%;
      top: 0;
      left: 25px; }
      @media screen and (min-width: 992px) {
        .blk-dev-pr-section .blk-dev-pr-wrapper:before {
          height: 88%; } }

@media screen and (min-width: 992px) {
  .remote-dev h2 {
    padding: 0 20px; } }

.price-btn-mod {
  width: 100%; }
  @media screen and (min-width: 992px) {
    .price-btn-mod {
      width: inherit; } }

/*Job description*/
@media screen and (min-width: 992px) {
  .jd-pr-mngr {
    height: 660px !important; } }

@media screen and (min-width: 1200px) {
  .jd-pr-mngr {
    height: 575px !important; } }

@media screen and (min-width: 992px) {
  .jd-pr-mngr2 {
    height: 590px !important; } }

@media screen and (min-width: 1200px) {
  .jd-pr-mngr2 {
    height: 525px !important; } }

.intmdt {
  /*Progress bar css*/ }
  @media screen and (min-width: 992px) {
    .intmdt .o-skills-hero {
      padding: 100px 20px 45px !important; } }
  .intmdt .o-skills-hero h1 {
    margin: 25px 0 20px; }
    @media screen and (min-width: 992px) {
      .intmdt .o-skills-hero h1 {
        margin-bottom: 0.5rem;
        margin-top: 0;
        font-size: 38px;
        line-height: 38px; } }
  .intmdt .intermediate-loader {
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #091b38;
    height: 100vh; }
    .intmdt .intermediate-loader #intermediate-loader-p {
      margin-bottom: 0;
      line-height: inherit;
      font-size: 22px;
      color: #fff;
      font-weight: 500;
      margin-top: 15px; }
      @media screen and (min-width: 992px) {
        .intmdt .intermediate-loader #intermediate-loader-p {
          font-size: 30px;
          margin-top: 20px; } }
      @media screen and (min-width: 1800px) {
        .intmdt .intermediate-loader #intermediate-loader-p {
          font-size: 36px;
          margin-top: 30px; } }
  .intmdt h4.wait-text {
    font-size: 30px;
    line-height: 44px;
    color: #fff;
    animation: beat 1.2s infinite alternate;
    transform-origin: center; }
    @media screen and (min-width: 992px) {
      .intmdt h4.wait-text {
        font-size: 40px;
        line-height: inherit; } }

@keyframes beat {
  to {
    opacity: .8; } }

@media only screen and (max-width: 780px) {
  .calendly-inline-widget {
    min-height: 960px; } }

.container-ledger {
  max-width: 220px;
  margin: 30px auto 0;
  height: 15px;
  background-color: #cccccc2e;
  border: 1px solid #fff;
  color: #000;
  font-size: 13px;
  text-align: center;
  transition-delay: 3s;
  position: relative;
  overflow: hidden; }
  @media screen and (min-width: 992px) {
    .container-ledger {
      max-width: 280px;
      margin: 40px auto 10px;
      height: 15px; } }

#inner-container {
  width: 280px;
  height: 100%;
  margin-top: 0;
  background-color: #fff;
  border: 1px solid #fff; }

/* ANCHOR form section career page*/
.career-form {
  background-size: cover; }

.new-career-form .common-input {
  width: 100%; }
  @media screen and (min-width: 768px) {
    .new-career-form .common-input {
      width: 46%;
      display: inline-block;
      margin: 0 20px 0 0; } }

.new-career-form [data-tip] {
  width: 100%; }
  @media screen and (min-width: 768px) {
    .new-career-form [data-tip] {
      width: 46%;
      display: inline-block;
      margin: 0 20px 0 0; } }

.new-career-form input.input7 {
  background: url(/images/career/job-icon-7.png) 0 50% no-repeat !important;
  width: 100%; }

.new-career-form input.years-of-experience {
  background: url(/images/career/job-icon-5.png) 0 50% no-repeat !important;
  width: 100%; }

.new-career-form .p-skills-icon,
.new-career-form .s-skills-icon {
  background: url(/images/career/form-icon9.png) no-repeat 0 30px !important;
  padding-left: 30px; }

.new-career-form .form-panel h4:before {
  display: none; }

.new-career-form .p-skills-icon {
  width: 100%; }
  @media screen and (min-width: 768px) {
    .new-career-form .p-skills-icon {
      display: inline-block;
      width: 46%;
      margin-right: 20px; } }

.new-career-form .s-skills-icon {
  width: 100%; }
  @media screen and (min-width: 768px) {
    .new-career-form .s-skills-icon {
      display: inline-block;
      width: 46%; } }

.new-career-form .attach {
  background-position: 0 19px; }

.new-career-form #attachedFiles li {
  padding-top: 0 !important; }

.new-career-form .select2 ul li {
  padding-top: 0 !important; }

.new-career-form .select2 button {
  width: auto; }

.career-form .u-submit {
  margin-top: 20px; }

/*hire designer page css fixing and webflow page css fixing*/
.o-case-studies-new .faq-holder .accordion1 span.font-size-modify {
  font-size: 20px; }
  @media screen and (min-width: 992px) {
    .o-case-studies-new .faq-holder .accordion1 span.font-size-modify {
      font-size: 24px !important; } }

/* ANCHOR Digital Transformation page*/
.new-service-section {
  padding: 40px 0 10px; }
  @media screen and (min-width: 992px) {
    .new-service-section {
      padding: 70px 0 60px; } }
  .new-service-section h2 {
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .new-service-section h2 {
        margin-bottom: 70px; } }
  .new-service-section-wrapper {
    display: flex;
    flex-wrap: wrap; }
    @media screen and (min-width: 992px) {
      .new-service-section-wrapper {
        display: inherit;
        column-count: 2;
        column-gap: 30px; } }
  .new-service-section-box {
    margin-bottom: 35px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .new-service-section-box {
        margin-bottom: 50px;
        break-inside: avoid;
        page-break-inside: avoid;
        display: flex; } }
    .new-service-section-box i {
      display: block;
      width: 50px;
      margin: 0 auto 20px; }
      @media screen and (min-width: 992px) {
        .new-service-section-box i {
          display: inline-block;
          margin: 0; } }
    .new-service-section-box-right {
      flex: 1;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .new-service-section-box-right {
          padding-left: 20px;
          margin-left: 10px;
          text-align: left; } }
      .new-service-section-box-right h3 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 15px; }
        @media screen and (min-width: 992px) {
          .new-service-section-box-right h3 {
            font-size: 26px; } }
      .new-service-section-box-right p {
        font-size: 16px;
        margin-bottom: 0; }
        @media screen and (min-width: 992px) {
          .new-service-section-box-right p {
            line-height: 28px; } }
        @media screen and (min-width: 1200px) {
          .new-service-section-box-right p {
            font-size: 17px; } }
        @media screen and (min-width: 1280px) {
          .new-service-section-box-right p {
            font-size: 18px; } }
        .new-service-section-box-right p a {
          display: inline;
          margin: 0;
          font-size: 18px;
          width: inherit;
          position: inherit;
          color: #333;
          text-decoration: underline; }
          .new-service-section-box-right p a:before {
            display: none; }
        .new-service-section-box-right p .inner-link {
          color: #0d6efd; }
          .new-service-section-box-right p .inner-link:hover {
            color: #0143a3; }
      .new-service-section-box-right a {
        font-size: 16px;
        display: block;
        margin: 5px auto 0;
        position: relative;
        width: 107px;
        transition: .3s all ease-in-out; }
        .new-service-section-box-right a:before {
          content: "➞";
          position: absolute;
          right: -17px;
          top: 0;
          transition: .3s all ease-in-out; }
        @media screen and (min-width: 1200px) {
          .new-service-section-box-right a {
            font-size: 17px;
            margin-top: 5px;
            margin-left: 0;
            margin-right: 0; } }
        @media screen and (min-width: 1280px) {
          .new-service-section-box-right a {
            font-size: 18px; } }
        .new-service-section-box-right a:hover {
          text-decoration: underline;
          color: #0257d5; }
          .new-service-section-box-right a:hover:before {
            right: -22px; }
  .new-service-section .ns-order-1 {
    order: 1; }
  .new-service-section .ns-order-2 {
    order: 2; }
  .new-service-section .ns-order-3 {
    order: 3; }
  .new-service-section .ns-order-4 {
    order: 4; }
  .new-service-section .ns-order-5 {
    order: 5; }
  .new-service-section .ns-order-6 {
    order: 6; }
  .new-service-section .ns-order-7 {
    order: 7; }
  .new-service-section .ns-order-8 {
    order: 8; }
  .new-service-section .ns-order-9 {
    order: 9; }
  .new-service-section .ns-order-10 {
    order: 10; }
  .new-service-section .ns-order-11 {
    order: 11; }
  .new-service-section .ns-order-12 {
    order: 12; }
  .new-service-section .ns-order-13 {
    order: 13; }
  .new-service-section .ns-order-14 {
    order: 14; }
  .new-service-section .ns-order-15 {
    order: 15; }

.margin-top-60-modify {
  margin-top: 20px; }
  @media screen and (min-width: 992px) {
    .margin-top-60-modify {
      margin-top: 60px; } }

.blue-bnr-section {
  background-color: #04254a;
  padding: 40px 0 50px;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .blue-bnr-section {
      background-size: contain;
      padding: 60px 0 75px; } }
  .blue-bnr-section h2 {
    font-size: 28px;
    line-height: 38px;
    color: #fff; }
    @media screen and (min-width: 992px) {
      .blue-bnr-section h2 {
        font-size: 36px;
        line-height: 50px; } }
  .blue-bnr-section p {
    color: #fff;
    font-size: 21px;
    line-height: 28px;
    max-width: 720px;
    margin: 0 auto;
    margin-bottom: 20px;
    font-family: Poppins; }
    .blue-bnr-section p .anchor-link {
      font-size: 21px;
      line-height: 28px; }

.white-bnr-mod {
  background-color: #F7F8FA;
  background-image: url(/images/digital-transformation/grad-white-bg.svg); }
  .white-bnr-mod h2 {
    color: #333; }
  .white-bnr-mod p {
    color: #333; }

/*Transformation process*/
.d-trans-pro {
  padding: 40px 0 20px; }
  @media screen and (min-width: 992px) {
    .d-trans-pro {
      padding: 70px 0 60px; } }
  .d-trans-pro h2 {
    text-align: center;
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .d-trans-pro h2 {
        margin-bottom: 50px; } }
  .d-trans-pro-wrapper {
    display: flex;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .d-trans-pro-wrapper {
        flex-direction: row-reverse; } }
    .d-trans-pro-wrapper .d-trans-pro-tab {
      display: none; }
      @media screen and (min-width: 992px) {
        .d-trans-pro-wrapper .d-trans-pro-tab {
          display: block; } }
      .d-trans-pro-wrapper .d-trans-pro-tab-btn {
        position: relative;
        font-size: 18px;
        line-height: 27px;
        color: #333;
        margin-bottom: 35px;
        width: 352px;
        padding: 15px 30px;
        border: 1px solid #fff;
        cursor: pointer; }
        .d-trans-pro-wrapper .d-trans-pro-tab-btn.active {
          font-weight: 500;
          background: #fff;
          box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
          border: 1px solid #E3E3E3;
          border-radius: 8px; }
        .d-trans-pro-wrapper .d-trans-pro-tab-btn .triangle-class {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          top: 15px;
          bottom: 0;
          left: -13px; }
          .d-trans-pro-wrapper .d-trans-pro-tab-btn .triangle-class::before {
            content: '';
            position: absolute;
            z-index: 9;
            width: 0;
            height: 0;
            border-top: 13px solid transparent;
            border-right: 13px solid #fff;
            border-bottom: 13px solid transparent; }
          .d-trans-pro-wrapper .d-trans-pro-tab-btn .triangle-class::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-top: 15px solid transparent;
            border-right: 15px solid #E3E3E3;
            border-bottom: 15px solid transparent;
            left: -2px;
            top: -2px; }
        .d-trans-pro-wrapper .d-trans-pro-tab-btn.active .triangle-class {
          visibility: visible;
          opacity: 1; }
        .d-trans-pro-wrapper .d-trans-pro-tab-btn:before {
          position: absolute;
          top: 7px;
          left: -89px;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 17px;
          z-index: 9;
          display: none; }
          @media screen and (min-width: 992px) {
            .d-trans-pro-wrapper .d-trans-pro-tab-btn:before {
              display: flex;
              left: -71px; } }
          @media screen and (min-width: 1200px) {
            .d-trans-pro-wrapper .d-trans-pro-tab-btn:before {
              left: -96px; } }
      .d-trans-pro-wrapper .d-trans-pro-tab .step1:before {
        content: '01';
        background: #091B38; }
      .d-trans-pro-wrapper .d-trans-pro-tab .step2:before {
        content: '02';
        background: #025683; }
      .d-trans-pro-wrapper .d-trans-pro-tab .step3:before {
        content: '03';
        background: #036497; }
      .d-trans-pro-wrapper .d-trans-pro-tab .step4:before {
        content: '04';
        background: #1B89AF; }
      .d-trans-pro-wrapper .d-trans-pro-tab .step5:before {
        content: '05';
        background: #2BBDC3; }
      .d-trans-pro-wrapper .d-trans-pro-tab .step6:before {
        content: '06';
        background: #28C89A; }
      .d-trans-pro-wrapper .d-trans-pro-tab .step7:before {
        content: '07';
        background: #3BE06E; }
      .d-trans-pro-wrapper .d-trans-pro-tab .step8:before {
        content: '08';
        background: #4CE369; }
      .d-trans-pro-wrapper .d-trans-pro-tab .step9:before {
        content: '09';
        background: #75E34C; }
    .d-trans-pro-wrapper .d-trans-pro-content {
      align-self: flex-start; }
      .d-trans-pro-wrapper .d-trans-pro-content-box {
        background: #F7F8FA;
        border-radius: 8px;
        padding: 25px 15px 5px 20px;
        width: 100%;
        margin-bottom: 30px;
        position: relative; }
        @media screen and (min-width: 992px) {
          .d-trans-pro-wrapper .d-trans-pro-content-box {
            display: none;
            margin-bottom: 0;
            padding: 30px 30px 5px 30px;
            width: 400px; } }
        .d-trans-pro-wrapper .d-trans-pro-content-box::before {
          position: absolute;
          top: 7px;
          left: -53px;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 17px;
          z-index: 9;
          display: flex; }
          @media screen and (min-width: 992px) {
            .d-trans-pro-wrapper .d-trans-pro-content-box::before {
              display: none; } }
        .d-trans-pro-wrapper .d-trans-pro-content-box i {
          display: inline-block;
          height: 45px;
          width: 45px; }
          @media screen and (min-width: 992px) {
            .d-trans-pro-wrapper .d-trans-pro-content-box i {
              height: 60px;
              width: 60px; } }
          .d-trans-pro-wrapper .d-trans-pro-content-box i img {
            width: 100%;
            height: 100%;
            object-fit: contain; }
        .d-trans-pro-wrapper .d-trans-pro-content-box h3 {
          font-size: 20px;
          line-height: 30px;
          padding: 15px 0 0;
          font-weight: 500; }
          @media screen and (min-width: 992px) {
            .d-trans-pro-wrapper .d-trans-pro-content-box h3 {
              font-size: 26px;
              line-height: 36px; } }
        .d-trans-pro-wrapper .d-trans-pro-content-box p {
          font-size: 16px;
          line-height: 24px; }
          @media screen and (min-width: 992px) {
            .d-trans-pro-wrapper .d-trans-pro-content-box p {
              font-size: 18px;
              line-height: 27px; } }
        .d-trans-pro-wrapper .d-trans-pro-content-box1:before {
          content: '01';
          background: #091B38; }
        @media screen and (min-width: 992px) {
          .d-trans-pro-wrapper .d-trans-pro-content-box2 {
            margin-top: 80px; } }
        .d-trans-pro-wrapper .d-trans-pro-content-box2:before {
          content: '02';
          background: #025683; }
        @media screen and (min-width: 992px) {
          .d-trans-pro-wrapper .d-trans-pro-content-box3 {
            margin-top: 180px; } }
        .d-trans-pro-wrapper .d-trans-pro-content-box3:before {
          content: '03';
          background: #036497; }
        @media screen and (min-width: 992px) {
          .d-trans-pro-wrapper .d-trans-pro-content-box4 {
            margin-top: 280px; } }
        .d-trans-pro-wrapper .d-trans-pro-content-box4:before {
          content: '04';
          background: #1B89AF; }
        @media screen and (min-width: 992px) {
          .d-trans-pro-wrapper .d-trans-pro-content-box5 {
            margin-top: 390px; } }
        .d-trans-pro-wrapper .d-trans-pro-content-box5:before {
          content: '05';
          background: #2BBDC3; }
        @media screen and (min-width: 992px) {
          .d-trans-pro-wrapper .d-trans-pro-content-box6 {
            margin-top: 390px; } }
        .d-trans-pro-wrapper .d-trans-pro-content-box6:before {
          content: '06';
          background: #28C89A; }
        @media screen and (min-width: 992px) {
          .d-trans-pro-wrapper .d-trans-pro-content-box7 {
            margin-top: 390px; } }
        .d-trans-pro-wrapper .d-trans-pro-content-box7:before {
          content: '07';
          background: #3BE06E; }
        @media screen and (min-width: 992px) {
          .d-trans-pro-wrapper .d-trans-pro-content-box8 {
            margin-top: 422px; } }
        .d-trans-pro-wrapper .d-trans-pro-content-box8:before {
          content: '08';
          background: #3BE06E; }
        @media screen and (min-width: 992px) {
          .d-trans-pro-wrapper .d-trans-pro-content-box9 {
            margin-top: 532px; } }
        .d-trans-pro-wrapper .d-trans-pro-content-box9:before {
          content: '09';
          background: #75E34C; }
    .d-trans-pro-wrapper .d-trans-pro-steps {
      margin-right: 40px;
      position: relative; }
      @media screen and (min-width: 992px) {
        .d-trans-pro-wrapper .d-trans-pro-steps {
          flex: 0 0 100px;
          margin-right: 0px; } }
      @media screen and (min-width: 1200px) {
        .d-trans-pro-wrapper .d-trans-pro-steps {
          flex: 0 0 150px; } }
      .d-trans-pro-wrapper .d-trans-pro-steps:before {
        content: '';
        position: absolute;
        background: #DFDFDF;
        width: 4px;
        height: 98%;
        top: 7px;
        right: -10px; }
        @media screen and (min-width: 992px) {
          .d-trans-pro-wrapper .d-trans-pro-steps:before {
            height: 92%;
            right: 0;
            left: 50%;
            transform: translateX(-50%); } }

.new-btn {
  max-width: 386px;
  padding: 14px 20px; }
  .new-btn.new-btn-modify {
    max-width: 450px;
    margin-top: 30px; }

.block-chain-pb-mod {
  margin-bottom: 25px; }

@media screen and (min-width: 992px) {
  .blue-bnr-section-mod {
    padding: 60px 0 70px; }
    .blue-bnr-section-mod p {
      margin-bottom: 0; } }

.d-trans-pro-wrapper .d-trans-pro-tab-btn.d-trans-pro-wrapper-new-modify {
  border: 1px solid transparent; }
  .d-trans-pro-wrapper .d-trans-pro-tab-btn.d-trans-pro-wrapper-new-modify.active {
    border: 1px solid #e3e3e3; }

.d-trans-pro-wrapper .d-trans-pro-content-box-border-new {
  border: 1px solid #C6C6C6; }

@media screen and (min-width: 992px) {
  .web-dev-process .d-trans-pro-wrapper .d-trans-pro-tab-btn {
    font-size: 17px;
    padding: 15px 20px; } }

.service-benifit {
  background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  padding: 0 0 20px; }
  @media screen and (min-width: 767px) {
    .service-benifit {
      padding: 80px 0; } }
  .service-benifit .margin-bottom-25 {
    margin-bottom: 25px; }
  .service-benifit__light {
    background: #fff; }
    .service-benifit__light h2 {
      color: #333333; }
    .service-benifit__light .service-card {
      border: 1px solid #E1DFE6;
      box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.05);
      height: auto;
      padding: 40px 28px 40px !important; }
      .service-benifit__light .service-card .details {
        width: 80%; }
        @media screen and (min-width: 767px) {
          .service-benifit__light .service-card .details {
            width: 76%; } }
        @media screen and (min-width: 1024px) {
          .service-benifit__light .service-card .details {
            width: 82% !important; } }
    .service-benifit__light .btn {
      text-transform: capitalize; }
  .service-benifit--btnleft {
    margin: 15px 0 0;
    font-size: 15px;
    font-weight: 600;
    color: #333; }
    .service-benifit--btnleft:hover {
      color: #ff5800; }
      .service-benifit--btnleft:hover path {
        fill: #ff5800; }
  .service-benifit h2 {
    font-weight: 600;
    margin-bottom: 0;
    padding: 40px 40px 30px; }
    @media screen and (min-width: 767px) {
      .service-benifit h2 {
        margin-bottom: 50px;
        padding: 0; } }
  .service-benifit .service-card {
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 50px 34px;
    width: 100%;
    height: 100%; }
    .service-benifit .service-card .icon {
      width: 16%;
      margin-right: 10px; }
      @media screen and (min-width: 767px) {
        .service-benifit .service-card .icon {
          width: 20%; } }
      @media screen and (min-width: 1024px) {
        .service-benifit .service-card .icon {
          width: 15%; } }
      @media screen and (min-width: 1200px) {
        .service-benifit .service-card .icon {
          width: 16%;
          margin-right: 0; } }
    @media screen and (min-width: 992px) {
      .service-benifit .service-card .service-card-join {
        height: 110px; } }
    .service-benifit .service-card .details {
      width: 80%; }
      @media screen and (min-width: 767px) {
        .service-benifit .service-card .details {
          width: 76%; } }
      @media screen and (min-width: 1024px) {
        .service-benifit .service-card .details {
          width: 80%; } }
      .service-benifit .service-card .details h4 {
        font-size: 20px;
        line-height: 24px;
        color: #333;
        margin-bottom: 15px; }
        @media screen and (min-width: 767px) {
          .service-benifit .service-card .details h4 {
            font-size: 24px;
            line-height: 36px; } }
      .service-benifit .service-card .details p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        margin: 0; }
        @media screen and (min-width: 767px) {
          .service-benifit .service-card .details p {
            font-size: 18px;
            line-height: 28px; } }
  .service-benifit .btn_wrapper {
    margin: 0 auto; }
    @media screen and (min-width: 767px) {
      .service-benifit .btn_wrapper {
        margin-top: 35px; } }
  .service-benifit--django:before {
    display: none; }
  .service-benifit--django .heading {
    font-size: 30px;
    line-height: 35px;
    color: #262E36;
    font-family: Poppins;
    font-weight: 700;
    margin-bottom: 15px; }
    @media screen and (min-width: 767px) {
      .service-benifit--django .heading {
        font-size: 38px;
        line-height: 46px;
        margin-bottom: 35px; } }
    @media screen and (min-width: 992px) {
      .service-benifit--django .heading {
        font-size: 51px;
        line-height: 65px;
        margin-bottom: 55px; } }
  .service-benifit--django .sub-heading {
    font-weight: 500;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .service-benifit--django .sub-heading {
        font-size: 21px;
        line-height: 31px;
        margin-bottom: 30px; } }
  .service-benifit--django .custom-heading {
    font-size: 30px;
    line-height: 35px;
    color: #262E36;
    font-family: Poppins;
    font-weight: 700;
    margin-bottom: 15px; }
    @media screen and (min-width: 767px) {
      .service-benifit--django .custom-heading {
        font-size: 38px;
        line-height: 46px;
        margin-bottom: 35px; } }
    @media screen and (min-width: 992px) {
      .service-benifit--django .custom-heading {
        font-size: 48px;
        line-height: 61px;
        margin-bottom: 40px; } }
  .service-benifit--django .service-card {
    border-radius: 5px;
    border: 1px solid #DDDDDD;
    padding: 40px !important;
    flex-direction: column;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1); }
    .service-benifit--django .service-card .icon {
      width: 78%;
      margin-bottom: 25px; }
      @media screen and (min-width: 767px) {
        .service-benifit--django .service-card .icon {
          width: 66%; } }
      .service-benifit--django .service-card .icon img {
        width: 100%; }
      .service-benifit--django .service-card .icon-md {
        width: 70%; }
        @media screen and (min-width: 767px) {
          .service-benifit--django .service-card .icon-md {
            width: 51%; } }
      .service-benifit--django .service-card .icon-sm {
        width: 45%; }
        @media screen and (min-width: 767px) {
          .service-benifit--django .service-card .icon-sm {
            width: 33%; } }
      .service-benifit--django .service-card .icon-lg {
        width: 66%; }
        @media screen and (min-width: 767px) {
          .service-benifit--django .service-card .icon-lg {
            width: 47%; } }
      .service-benifit--django .service-card .icon-width {
        max-width: 135px;
        width: 100%; }
      .service-benifit--django .service-card .icon-widthm {
        max-width: 170px;
        width: 100%; }
      .service-benifit--django .service-card .icon-widthl {
        max-width: 195px;
        width: 100%; }
    .service-benifit--django .service-card .details {
      width: 100% !important; }
  .service-benifit--mobileapp .service-card .icon img {
    width: 70px; }
  .service-benifit--mobileapp .service-card .icon--large img {
    width: 170px; }
  .service-benifit--mobileapp .service-card .icon--low img {
    width: 55px; }
  .service-benifit--blockchain {
    background: #f5f5f5; }
    .service-benifit--blockchain .service-card {
      flex-direction: column;
      justify-content: space-between;
      align-items: center; }
    .service-benifit--blockchain .icon {
      width: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px; }
      .service-benifit--blockchain .icon img {
        width: auto !important; }
    .service-benifit--blockchain .details {
      width: 100% !important;
      text-align: center; }

.width-255 {
  max-width: 255px; }

.industry-serve {
  padding: 40px 10px 20px; }
  @media screen and (min-width: 767px) {
    .industry-serve {
      padding: 80px 0; } }
  .industry-serve h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
    text-align: center;
    font-weight: 600; }
    @media screen and (min-width: 1024px) {
      .industry-serve h2 {
        font-size: 48px;
        line-height: 58px;
        margin-bottom: 50px; } }
  .industry-serve .card {
    background: #fff;
    border: 1px solid #E1DFE6;
    border-radius: 10px;
    box-shadow: 5px 10px 20px 0px rgba(0, 0, 0, 0.05);
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-bottom: 25px; }
    @media screen and (min-width: 767px) {
      .industry-serve .card {
        height: 224px;
        padding: 30px; } }
    .industry-serve .card i {
      margin-bottom: 10px; }
      @media screen and (min-width: 767px) {
        .industry-serve .card i {
          margin-bottom: 20px; } }
    .industry-serve .card p {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      margin: 0;
      color: #333;
      text-align: center; }
  .industry-serve .btn_wrapper {
    margin-top: 0px; }
    @media screen and (min-width: 767px) {
      .industry-serve .btn_wrapper {
        margin-top: 35px; } }

.service-now-element {
  padding: 40px 0; }
  @media screen and (min-width: 767px) {
    .service-now-element {
      padding: 80px 0; } }
  .service-now-element .process-line {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 15px 30px;
    border-radius: 10px;
    border: 1px dashed #626262; }
    @media screen and (min-width: 1024px) {
      .service-now-element .process-line {
        border: none; } }
    @media screen and (min-width: 1024px) {
      .service-now-element .process-line {
        padding: 0;
        border: none; } }
    .service-now-element .process-line li {
      position: relative;
      max-width: 100%;
      width: 100%;
      height: auto;
      background: #fff;
      border: 1px solid #4492DC;
      border-radius: 10px;
      box-shadow: 5px 10px 20px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      text-align: center;
      padding: 15px;
      justify-content: center;
      font-size: 16px;
      line-height: 27px;
      color: #333; }
      @media screen and (min-width: 1024px) {
        .service-now-element .process-line li {
          max-width: 270px;
          width: 22%;
          height: 103px; } }
      .service-now-element .process-line li.ipad-view {
        display: none; }
        @media screen and (min-width: 992px) {
          .service-now-element .process-line li.ipad-view {
            display: flex; } }
      .service-now-element .process-line li:before {
        display: none;
        position: absolute;
        content: "";
        width: 67px;
        height: 63px;
        background: url(/images/service-now/arrow-line.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%; }
        @media screen and (min-width: 1024px) {
          .service-now-element .process-line li:before {
            display: block; } }
    .service-now-element .process-line.intake-process {
      justify-content: center;
      height: auto;
      margin-bottom: 120px; }
      @media screen and (min-width: 1024px) {
        .service-now-element .process-line.intake-process {
          height: 400px;
          margin-bottom: 0; } }
      .service-now-element .process-line.intake-process li {
        margin: 15px 0; }
        @media screen and (min-width: 1024px) {
          .service-now-element .process-line.intake-process li {
            margin: 0 15px; } }
        .service-now-element .process-line.intake-process li:nth-child(1) {
          position: relative;
          left: inherit;
          bottom: inherit; }
          @media screen and (min-width: 1024px) {
            .service-now-element .process-line.intake-process li:nth-child(1) {
              position: absolute;
              left: 0;
              bottom: 0; } }
          .service-now-element .process-line.intake-process li:nth-child(1):before {
            top: 0;
            right: -100px;
            transform: rotate(290deg); }
        .service-now-element .process-line.intake-process li:nth-child(2) {
          position: relative;
          left: inherit;
          top: inherit; }
          @media screen and (min-width: 1024px) {
            .service-now-element .process-line.intake-process li:nth-child(2) {
              position: absolute;
              left: 0;
              top: 35%; } }
          .service-now-element .process-line.intake-process li:nth-child(2):before {
            bottom: 0;
            right: -100px;
            transform: rotate(342deg); }
        .service-now-element .process-line.intake-process li:nth-child(3):before {
          right: 0;
          bottom: -80px;
          left: 60px;
          margin: auto; }
        .service-now-element .process-line.intake-process li:nth-child(4):before {
          right: 80px;
          bottom: -80px;
          left: 0;
          margin: auto;
          transform: rotate(269deg); }
        .service-now-element .process-line.intake-process li:nth-child(5) {
          position: relative;
          right: inherit;
          top: inherit; }
          @media screen and (min-width: 1024px) {
            .service-now-element .process-line.intake-process li:nth-child(5) {
              position: absolute;
              right: 0;
              top: 35%; } }
          .service-now-element .process-line.intake-process li:nth-child(5):before {
            top: 40px;
            left: -100px;
            transform: rotate(107deg); }
        .service-now-element .process-line.intake-process li:nth-child(6) {
          position: relative;
          right: inherit;
          bottom: inherit; }
          @media screen and (min-width: 1024px) {
            .service-now-element .process-line.intake-process li:nth-child(6) {
              position: absolute;
              right: 0;
              bottom: 0; } }
          .service-now-element .process-line.intake-process li:nth-child(6):before {
            bottom: 45%;
            left: -100px;
            transform: rotate(160deg); }
    .service-now-element .process-line.outgoing-process {
      justify-content: center;
      margin-top: 120px; }
      @media screen and (min-width: 1024px) {
        .service-now-element .process-line.outgoing-process {
          margin-top: -70px; } }
      .service-now-element .process-line.outgoing-process li {
        margin: 15px 0; }
        @media screen and (min-width: 1024px) {
          .service-now-element .process-line.outgoing-process li {
            margin: 0 15px; } }
        .service-now-element .process-line.outgoing-process li:nth-child(1):before {
          top: -80px;
          right: 0;
          left: 50px;
          margin: auto;
          transform: rotate(90deg); }
        .service-now-element .process-line.outgoing-process li:nth-child(2):before {
          top: -80px;
          right: 0;
          left: -50px;
          margin: auto; }
  .service-now-element picture {
    position: relative; }
    .service-now-element picture .service-logo {
      position: relative;
      display: block;
      margin: auto;
      top: inherit;
      max-width: 240px; }
      @media screen and (min-width: 1024px) {
        .service-now-element picture .service-logo {
          top: -34%; } }
      @media screen and (min-width: 1200px) {
        .service-now-element picture .service-logo {
          max-width: 100%; } }
    .service-now-element picture:before {
      display: block;
      position: absolute;
      content: "";
      width: 67px;
      height: 63px;
      background: url(/images/service-now/arrow-line.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      left: 0;
      right: 0;
      top: -90px;
      margin: auto;
      transform: rotate(48deg); }
      @media screen and (min-width: 1024px) {
        .service-now-element picture:before {
          display: none; } }
    .service-now-element picture:after {
      display: block;
      position: absolute;
      content: "";
      width: 67px;
      height: 63px;
      background: url(/images/service-now/arrow-line.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      left: 0;
      right: 0;
      bottom: -90px;
      margin: auto;
      transform: rotate(48deg); }
      @media screen and (min-width: 1024px) {
        .service-now-element picture:after {
          display: none; } }

.view-chart-area {
  background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  padding: 40px 0; }
  @media screen and (min-width: 767px) {
    .view-chart-area {
      padding: 80px 0; } }
  .view-chart-area .chart-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column; }
    @media screen and (min-width: 992px) {
      .view-chart-area .chart-wrapper {
        flex-direction: row; } }
    .view-chart-area .chart-wrapper .data-flow {
      width: 100%;
      padding: 20px 8px;
      border: 1px dashed #fff;
      border-radius: 10px; }
      @media screen and (min-width: 992px) {
        .view-chart-area .chart-wrapper .data-flow {
          width: 20%;
          padding: 0;
          border: none; } }
      .view-chart-area .chart-wrapper .data-flow h2 {
        color: #fff;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 15px; }
        @media screen and (min-width: 1181px) {
          .view-chart-area .chart-wrapper .data-flow h2 {
            font-size: 36px;
            line-height: 43px;
            margin-bottom: 30px; } }
      .view-chart-area .chart-wrapper .data-flow ul {
        position: relative;
        display: flex;
        min-height: auto;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap; }
        @media screen and (min-width: 992px) {
          .view-chart-area .chart-wrapper .data-flow ul {
            min-height: 475px;
            display: inline-flex;
            flex-direction: column; } }
        .view-chart-area .chart-wrapper .data-flow ul li {
          position: relative;
          max-width: 100%;
          width: 100%;
          height: 79px;
          display: block;
          border: 1px solid;
          box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 12px;
          color: #fff;
          font-size: 16px;
          line-height: 25px;
          margin-bottom: 20px;
          margin-left: 10px;
          margin-right: 10px;
          font-weight: 600; }
          @media screen and (min-width: 767px) {
            .view-chart-area .chart-wrapper .data-flow ul li {
              max-width: 170px; } }
          @media screen and (min-width: 992px) {
            .view-chart-area .chart-wrapper .data-flow ul li {
              width: 70%;
              margin: 0;
              margin-bottom: 20px; } }
          @media screen and (min-width: 1181px) {
            .view-chart-area .chart-wrapper .data-flow ul li {
              width: 100%; } }
          .view-chart-area .chart-wrapper .data-flow ul li:last-child {
            margin-bottom: 0; }
          .view-chart-area .chart-wrapper .data-flow ul li:after {
            display: none;
            position: absolute;
            content: "";
            width: 37px;
            height: 11px;
            background: url(/images/snowflake/line.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%; }
            @media screen and (min-width: 1024px) {
              .view-chart-area .chart-wrapper .data-flow ul li:after {
                display: block; } }
      .view-chart-area .chart-wrapper .data-flow__input {
        text-align: center;
        position: relative;
        margin-bottom: 100px; }
        .view-chart-area .chart-wrapper .data-flow__input:after {
          display: block;
          position: absolute;
          content: "";
          width: 37px;
          height: 11px;
          background: url(/images/snowflake/line.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          transform: rotate(90deg);
          left: 0;
          right: 0;
          margin: auto;
          bottom: -50px; }
          @media screen and (min-width: 992px) {
            .view-chart-area .chart-wrapper .data-flow__input:after {
              display: none; } }
        @media screen and (min-width: 992px) {
          .view-chart-area .chart-wrapper .data-flow__input {
            text-align: left;
            margin-bottom: 0; } }
        .view-chart-area .chart-wrapper .data-flow__input li {
          border-color: #82FFA9 !important; }
          .view-chart-area .chart-wrapper .data-flow__input li:after {
            right: -40%;
            top: 50%;
            transform: translateY(-50%); }
            @media screen and (min-width: 992px) {
              .view-chart-area .chart-wrapper .data-flow__input li:after {
                right: -40%; } }
            @media screen and (min-width: 1200px) {
              .view-chart-area .chart-wrapper .data-flow__input li:after {
                right: -30%; } }
      .view-chart-area .chart-wrapper .data-flow__output {
        text-align: center;
        position: relative; }
        .view-chart-area .chart-wrapper .data-flow__output:after {
          display: block;
          position: absolute;
          content: "";
          width: 37px;
          height: 11px;
          background: url(/images/snowflake/line.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          transform: rotate(90deg);
          left: 0;
          right: 0;
          margin: auto;
          top: -50px; }
          @media screen and (min-width: 992px) {
            .view-chart-area .chart-wrapper .data-flow__output:after {
              display: none; } }
        @media screen and (min-width: 992px) {
          .view-chart-area .chart-wrapper .data-flow__output {
            text-align: right;
            margin-top: 0; } }
        .view-chart-area .chart-wrapper .data-flow__output ul {
          align-items: flex-end; }
          .view-chart-area .chart-wrapper .data-flow__output ul li {
            border-color: #4492DC !important; }
            .view-chart-area .chart-wrapper .data-flow__output ul li:after {
              left: -40%;
              top: 50%;
              transform: translateY(-50%); }
              @media screen and (min-width: 992px) {
                .view-chart-area .chart-wrapper .data-flow__output ul li:after {
                  right: -40%; } }
              @media screen and (min-width: 1200px) {
                .view-chart-area .chart-wrapper .data-flow__output ul li:after {
                  right: -30%; } }
    .view-chart-area .chart-wrapper .stramtext {
      position: relative;
      width: 100%; }
      @media screen and (min-width: 992px) {
        .view-chart-area .chart-wrapper .stramtext {
          width: 10%; } }
      .view-chart-area .chart-wrapper .stramtext:after {
        display: none;
        position: absolute;
        content: "";
        width: 37px;
        height: 111px;
        background: url(/images/snowflake/line-group.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        top: 45%;
        right: 0; }
        @media screen and (min-width: 1024px) {
          .view-chart-area .chart-wrapper .stramtext:after {
            display: block; } }
      .view-chart-area .chart-wrapper .stramtext h4 {
        position: relative;
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        line-height: 59px;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 30px;
        text-align: center;
        padding: 0 20px; }
        .view-chart-area .chart-wrapper .stramtext h4:after {
          display: block;
          position: absolute;
          content: "";
          width: 37px;
          height: 11px;
          background: url(/images/snowflake/line.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          transform: rotate(90deg);
          left: 0;
          right: 0;
          margin: auto;
          bottom: -50px; }
          @media screen and (min-width: 992px) {
            .view-chart-area .chart-wrapper .stramtext h4:after {
              display: none; } }
        @media screen and (min-width: 992px) {
          .view-chart-area .chart-wrapper .stramtext h4 {
            transform: rotate(270deg);
            margin: 0;
            position: absolute;
            width: 256px;
            left: -120px;
            right: 0;
            text-align: center;
            margin: auto;
            top: 49%; } }
        @media screen and (min-width: 1200px) {
          .view-chart-area .chart-wrapper .stramtext h4 {
            left: -97px; } }
    .view-chart-area .chart-wrapper .showflake-main {
      width: 100%;
      position: relative;
      margin-bottom: 100px; }
      @media screen and (min-width: 992px) {
        .view-chart-area .chart-wrapper .showflake-main {
          width: 45%;
          margin-bottom: 0; } }
      @media screen and (min-width: 1024px) {
        .view-chart-area .chart-wrapper .showflake-main {
          height: 567px;
          overflow: hidden; } }
      @media screen and (min-width: 1181px) {
        .view-chart-area .chart-wrapper .showflake-main {
          height: auto; } }
      .view-chart-area .chart-wrapper .showflake-main .snow-block {
        background: rgba(255, 255, 255, 0.05);
        border-radius: 30px;
        padding: 20px; }
        @media screen and (min-width: 767px) {
          .view-chart-area .chart-wrapper .showflake-main .snow-block {
            padding: 40px; } }
        .view-chart-area .chart-wrapper .showflake-main .snow-block.showfirst {
          padding: 20px;
          margin-top: 80px;
          margin-bottom: 22px; }
          @media screen and (min-width: 767px) {
            .view-chart-area .chart-wrapper .showflake-main .snow-block.showfirst {
              padding: 40px 40px 30px; } }
          @media screen and (min-width: 992px) {
            .view-chart-area .chart-wrapper .showflake-main .snow-block.showfirst {
              margin-top: 20px; } }
          @media screen and (min-width: 1181px) {
            .view-chart-area .chart-wrapper .showflake-main .snow-block.showfirst {
              margin-top: 80px; } }
          .view-chart-area .chart-wrapper .showflake-main .snow-block.showfirst ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center; }
            @media screen and (min-width: 992px) {
              .view-chart-area .chart-wrapper .showflake-main .snow-block.showfirst ul {
                justify-content: space-between; } }
            .view-chart-area .chart-wrapper .showflake-main .snow-block.showfirst ul li {
              max-width: 45%;
              width: 100%;
              height: auto;
              border-radius: 10px;
              background: #fff;
              padding: 10px;
              box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.05);
              margin-bottom: 10px;
              margin-left: 5px;
              margin-right: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column; }
              @media screen and (min-width: 767px) {
                .view-chart-area .chart-wrapper .showflake-main .snow-block.showfirst ul li {
                  max-width: 133px;
                  width: 32%;
                  height: 113px;
                  margin-left: 10px;
                  margin-right: 10px;
                  margin-bottom: 20px; } }
              @media screen and (min-width: 992px) {
                .view-chart-area .chart-wrapper .showflake-main .snow-block.showfirst ul li {
                  margin-left: 0;
                  margin-right: 0; } }
              .view-chart-area .chart-wrapper .showflake-main .snow-block.showfirst ul li p {
                font-size: 15px;
                line-height: 21px;
                font-weight: 500;
                color: #333;
                margin: 10px 0 0;
                text-align: center; }
          .view-chart-area .chart-wrapper .showflake-main .snow-block.showfirst .tag-logo {
            display: block;
            margin: 16px auto 0;
            text-align: center;
            width: 70%;
            height: auto; }
            @media screen and (min-width: 1200px) {
              .view-chart-area .chart-wrapper .showflake-main .snow-block.showfirst .tag-logo {
                width: auto;
                height: 70px;
                overflow: hidden; } }
        .view-chart-area .chart-wrapper .showflake-main .snow-block.showsecond ul {
          text-align: center;
          height: auto; }
          @media screen and (min-width: 1200px) {
            .view-chart-area .chart-wrapper .showflake-main .snow-block.showsecond ul {
              height: 37px;
              overflow: hidden; } }
          .view-chart-area .chart-wrapper .showflake-main .snow-block.showsecond ul li {
            display: inline-block;
            width: 35%;
            margin: 0 7px; }
            @media screen and (min-width: 767px) {
              .view-chart-area .chart-wrapper .showflake-main .snow-block.showsecond ul li {
                width: 29%;
                margin: 0 5px; } }
            @media screen and (min-width: 1200px) {
              .view-chart-area .chart-wrapper .showflake-main .snow-block.showsecond ul li {
                width: auto;
                margin: 0 15px; } }
            .view-chart-area .chart-wrapper .showflake-main .snow-block.showsecond ul li:nth-child(2) {
              width: 14%; }
              @media screen and (min-width: 767px) {
                .view-chart-area .chart-wrapper .showflake-main .snow-block.showsecond ul li:nth-child(2) {
                  width: 29%; } }
              @media screen and (min-width: 1200px) {
                .view-chart-area .chart-wrapper .showflake-main .snow-block.showsecond ul li:nth-child(2) {
                  width: auto;
                  margin: 0 15px; } }

.snowflake-process .d-trans-pro-content-box {
  box-shadow: none !important;
  background: #f7f8fa !important; }

@media screen and (min-width: 992px) {
  .snowflake-process .d-trans-pro-steps:before {
    height: 84% !important; } }

.service-benifit-ecom h2.heading {
  margin-bottom: 20px; }

.service-benifit-ecom p {
  font-family: "Poppins";
  color: #333;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .service-benifit-ecom p {
      font-size: 21px;
      line-height: 31px;
      margin-bottom: 30px; } }

/* ANCHOR Dotnet generic page*/
.dotnet-generic-section h2 {
  margin-bottom: 15px; }

.new-expertise-section {
  padding: 40px 0 20px; }
  @media screen and (min-width: 992px) {
    .new-expertise-section {
      padding: 70px 0 80px; } }
  .new-expertise-section h2 {
    text-align: center;
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .new-expertise-section h2 {
        argin-bottom: 60px; } }
  .new-expertise-section-box {
    background: #fff;
    padding: 40px 20px 30px 25px;
    border-radius: 8px;
    border: 1px solid #C5C5C5;
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.05);
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .new-expertise-section-box {
        margin-bottom: 0; } }
    .new-expertise-section-box i {
      background: #F5F5F5;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90px;
      width: 90px;
      margin-bottom: 20px; }
    .new-expertise-section-box h3 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500; }
    .new-expertise-section-box ul {
      margin-top: 25px; }
      .new-expertise-section-box ul li {
        position: relative;
        padding: 0 0 10px 25px;
        font-size: 18px;
        line-height: 27px; }
        .new-expertise-section-box ul li:before {
          content: '';
          position: absolute;
          left: 0;
          top: 5px;
          background: url(/images/dotnet/green-tick.svg) no-repeat 0 0;
          height: 16px;
          width: 16px; }

.new-tech-stack .tech-list {
  border: 1px solid #E1DFE6;
  box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.05); }

/* ANCHOR New hire top 1% dev*/
@media screen and (min-width: 992px) {
  .hire-top-one {
    padding: 70px 0 0; } }

.hire-top-one .max-width-1024-center {
  padding-top: 10px;
  margin-bottom: 0; }
  @media screen and (min-width: 992px) {
    .hire-top-one .max-width-1024-center {
      max-width: 900px;
      margin: 0 auto;
      font-size: 19px; } }

.hire-top-one .text-bg {
  box-shadow: none;
  border: 0;
  padding: 30px 0 10px;
  margin-bottom: 0; }
  @media screen and (min-width: 992px) {
    .hire-top-one .text-bg {
      padding: 50px 40px 30px;
      padding-bottom: 0; } }

.hire-top-one h3 {
  width: 100% !important; }
  @media screen and (min-width: 992px) {
    .hire-top-one h3 {
      margin-bottom: 20px; } }

.hire-top-one .text-bg:after {
  display: none; }

.hire-top-one .text-bg:before {
  display: none !important; }

.hire-top-one .how-it-works-holder {
  background: none;
  position: relative;
  padding-left: 50px; }
  .hire-top-one .how-it-works-holder::before {
    content: '';
    position: absolute;
    background: #f5f5f5;
    left: 10px;
    top: 0;
    border-radius: 5px;
    width: 4px;
    height: 98%; }
    @media screen and (min-width: 992px) {
      .hire-top-one .how-it-works-holder::before {
        right: 0;
        margin: 0 auto;
        left: 0; } }
  .hire-top-one .how-it-works-holder .col-lg-5 {
    position: relative; }
  .hire-top-one .how-it-works-holder .blue-c {
    position: absolute;
    border: 8px solid #fff;
    left: -58px; }
    @media screen and (min-width: 992px) {
      .hire-top-one .how-it-works-holder .blue-c {
        top: 40px; } }
    .hire-top-one .how-it-works-holder .blue-c span {
      width: 50px;
      height: 50px;
      background: #1B7AAF;
      color: #fff;
      border-radius: 50%;
      border: 5px solid #fff;
      box-shadow: 0px 0px 0px 2px #cde1fb;
      font-size: 20px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center; }
  @media screen and (min-width: 992px) {
    .hire-top-one .how-it-works-holder .blue-c1 {
      right: -113px;
      left: inherit; } }
  @media screen and (min-width: 1200px) {
    .hire-top-one .how-it-works-holder .blue-c1 {
      right: -127px; } }
  @media screen and (min-width: 1280px) {
    .hire-top-one .how-it-works-holder .blue-c1 {
      right: -130px; } }
  @media screen and (min-width: 992px) {
    .hire-top-one .how-it-works-holder .blue-c2 {
      left: -113px; } }
  @media screen and (min-width: 1200px) {
    .hire-top-one .how-it-works-holder .blue-c2 {
      left: -127px; } }
  @media screen and (min-width: 1280px) {
    .hire-top-one .how-it-works-holder .blue-c2 {
      left: -130px; } }
  @media screen and (min-width: 992px) {
    .hire-top-one .how-it-works-holder .blue-c3 {
      right: -113px;
      left: inherit; } }
  @media screen and (min-width: 1200px) {
    .hire-top-one .how-it-works-holder .blue-c3 {
      right: -127px; } }
  @media screen and (min-width: 1280px) {
    .hire-top-one .how-it-works-holder .blue-c3 {
      right: -130px; } }
  @media screen and (min-width: 992px) {
    .hire-top-one .how-it-works-holder .blue-c4 {
      left: -113px; } }
  @media screen and (min-width: 1200px) {
    .hire-top-one .how-it-works-holder .blue-c4 {
      left: -127px; } }
  @media screen and (min-width: 1280px) {
    .hire-top-one .how-it-works-holder .blue-c4 {
      left: -130px; } }
  @media screen and (min-width: 992px) {
    .hire-top-one .how-it-works-holder .blue-c5 {
      right: -113px;
      left: inherit; } }
  @media screen and (min-width: 1200px) {
    .hire-top-one .how-it-works-holder .blue-c5 {
      right: -127px; } }
  @media screen and (min-width: 1280px) {
    .hire-top-one .how-it-works-holder .blue-c5 {
      right: -130px; } }
  @media screen and (min-width: 992px) {
    .hire-top-one .how-it-works-holder {
      padding-left: 0;
      margin: 25px 0 50px; } }
  @media screen and (min-width: 992px) {
    .hire-top-one .how-it-works-holder img {
      width: 100%; } }

.hire-top-one p {
  font-size: 16px; }
  @media screen and (min-width: 992px) {
    .hire-top-one p {
      font-size: 18px;
      line-height: 27px; } }
  .hire-top-one p strong {
    font-weight: 600; }

/* ANCHOR Api Development page*/
.bg-lightgray {
  background-color: #f9f9f9; }

.a-dev-pro h2 {
  margin-bottom: 15px; }

.a-dev-pro p.d-trans-pro-para {
  font-size: 16px;
  text-align: center;
  margin-bottom: 40px; }
  @media screen and (min-width: 992px) {
    .a-dev-pro p.d-trans-pro-para {
      margin-bottom: 70px;
      font-size: 24px; } }

@media screen and (min-width: 992px) {
  .a-dev-pro .d-trans-pro-wrapper .d-trans-pro-steps:before {
    height: 91%; } }

@media screen and (min-width: 992px) {
  .a-dev-pro .d-trans-pro-wrapper .d-trans-pro-content-box4 {
    margin-top: 215px; } }

@media screen and (min-width: 992px) {
  .a-dev-pro .d-trans-pro-wrapper .d-trans-pro-content-box5 {
    margin-top: 360px; } }

@media screen and (min-width: 992px) {
  .a-dev-pro .d-trans-pro-wrapper .d-trans-pro-content-box6 {
    margin-top: 305px; } }

@media screen and (min-width: 992px) {
  .new-tech-stack {
    padding: 70px 0 70px; } }

.new-tech-stack .tech-list ul li {
  font-weight: 500; }
  .new-tech-stack .tech-list ul li a {
    font-weight: 500;
    color: #4A90E2;
    text-decoration: underline;
    transition: .2s all ease-in-out; }
    .new-tech-stack .tech-list ul li a:hover {
      color: #2275d7; }

.new-tech-stack-para {
  font-size: 16px;
  line-height: 24px; }
  @media screen and (min-width: 992px) {
    .new-tech-stack-para {
      font-size: 18px;
      line-height: 28px;
      max-width: 790px;
      margin: 0 auto 50px; } }

.new-tech-stack h2 {
  padding-bottom: 0;
  margin-bottom: 5px; }
  @media screen and (min-width: 992px) {
    .new-tech-stack h2 {
      margin-bottom: 15px; } }
  .new-tech-stack h2.without-para-heading {
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .new-tech-stack h2.without-para-heading {
        margin-bottom: 35px; } }

.o-key-diff {
  padding: 50px 0 20px; }
  @media screen and (min-width: 992px) {
    .o-key-diff {
      padding: 70px 0 70px; } }
  .o-key-diff h2 {
    text-align: center;
    margin-bottom: 15px; }
  .o-key-diff-para {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .o-key-diff-para {
        font-size: 20px;
        line-height: 36px;
        max-width: 1024px;
        margin: 0 auto 50px; } }
  @media screen and (min-width: 992px) {
    .o-key-diff-left {
      padding-right: 20px;
      padding-top: 30px; } }
  .o-key-diff-left h3 {
    font-size: 22px;
    line-height: 34px;
    position: relative;
    padding-bottom: 10px;
    text-align: center;
    font-weight: 500; }
    @media screen and (min-width: 992px) {
      .o-key-diff-left h3 {
        font-size: 30px;
        line-height: 40px;
        padding-bottom: 0;
        text-align: left; } }
    .o-key-diff-left h3:before {
      content: '';
      position: absolute;
      bottom: -40px;
      left: 0;
      background: url(/images/api-development/l-right-arrow.svg) no-repeat 0 0;
      width: 70px;
      height: 14px;
      display: none; }
      @media screen and (min-width: 992px) {
        .o-key-diff-left h3:before {
          display: block; } }
  .o-key-diff-box {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    border-left-width: 8px;
    padding: 20px 15px;
    margin-bottom: 30px;
    width: 100%; }
    @media screen and (min-width: 992px) {
      .o-key-diff-box {
        padding: 30px; } }
    .o-key-diff-box ul {
      list-style: disc;
      margin-top: 10px;
      padding-left: 20px; }
      .o-key-diff-box ul li {
        font-size: 16px;
        line-height: 27px;
        margin-bottom: 8px; }
    .o-key-diff-box p {
      font-size: 16px;
      line-height: 27px;
      margin-bottom: 0; }
    .o-key-diff-box h4 {
      font-size: 20px;
      font-weight: 500; }
      @media screen and (min-width: 992px) {
        .o-key-diff-box h4 {
          font-size: 24px; } }
  .o-key-diff .bord-col-1 {
    border-left-color: #4A90E2; }
  .o-key-diff .bord-col-2 {
    border-left-color: #49C684; }
  .o-key-diff .bord-col-3 {
    border-left-color: #4A90E2; }
    @media screen and (min-width: 992px) {
      .o-key-diff .bord-col-3 {
        border-left-color: #49C684; } }
  .o-key-diff .bord-col-4 {
    border-left-color: #49C684; }
    @media screen and (min-width: 992px) {
      .o-key-diff .bord-col-4 {
        border-left-color: #4A90E2; } }
  @media screen and (min-width: 992px) {
    .o-key-diff .col-lg-4 {
      display: flex; } }

.type-of-dev {
  padding: 50px 0 0; }
  @media screen and (min-width: 992px) {
    .type-of-dev {
      padding: 70px 0 0; } }
  .type-of-dev h2 {
    text-align: center;
    margin-bottom: 15px; }
  .type-of-dev-para {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .type-of-dev-para {
        font-size: 20px;
        line-height: 36px;
        max-width: 960px;
        margin: 0 auto 50px; } }
  .type-of-dev-box {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    border-bottom-width: 4px;
    padding: 20px 15px;
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .type-of-dev-box {
        padding: 30px; } }
    .type-of-dev-box p {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 27px; }
    .type-of-dev-box h3 {
      font-size: 20px;
      font-weight: 500; }
      @media screen and (min-width: 992px) {
        .type-of-dev-box h3 {
          font-size: 24px; } }
    .type-of-dev-box-color-1 {
      border-bottom-color: #4A90E2; }
    .type-of-dev-box-color-2 {
      border-bottom-color: #49C684; }
  @media screen and (min-width: 992px) {
    .type-of-dev .col-lg-3 {
      display: flex; } }

.new-service-section-api-dev .ns-order-3 {
  order: 4; }

.new-service-section-api-dev .ns-order-4 {
  order: 3; }

.new-service-section-api-dev .ns-order-5 {
  order: 6; }

.new-service-section-api-dev .ns-order-6 {
  order: 5; }

.no-bnr-mod {
  background: #fff;
  padding-top: 20px; }
  @media screen and (min-width: 992px) {
    .no-bnr-mod {
      padding-top: 40px; } }

/*smart-contract page*/
.smart-contract-use-case .o-key-diff-box {
  background: #fff; }

/*Rtecard*/
.ratecard-section {
  padding: 90px 0 40px;
  min-height: 400px; }
  @media screen and (min-width: 992px) {
    .ratecard-section {
      padding: 120px 0 80px;
      min-height: 600px; } }
  .ratecard-section h2 {
    font-size: 30px; }
    @media screen and (min-width: 992px) {
      .ratecard-section h2 {
        font-size: 48px;
        line-height: 61px; } }

/* ANCHOR Dotnet Core Skill*/
.dotnet-expertise {
  border: 1px solid #C5C5C5;
  padding: 26px 18px;
  border-radius: 5px;
  margin-bottom: 30px;
  min-height: 102px; }
  @media (min-width: 992px) {
    .dotnet-expertise {
      min-height: 127px; } }
  .dotnet-expertise p {
    position: relative;
    margin-bottom: 0;
    font-size: 16px;
    padding-left: 27px; }
    .dotnet-expertise p::before {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      background: url(/images/dotnet/green-tick.svg) no-repeat 0 0;
      height: 16px;
      width: 16px; }

/* ANCHOR New Navigation*/
.navbar-toggler {
  display: block; }
  @media screen and (min-width: 992px) {
    .navbar-toggler {
      display: none; } }

.new-nav__wrapper {
  position: fixed;
  right: -100%;
  height: calc(100vh - 72px);
  top: 72px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.4s all ease;
  background: #24cd7b;
  background: linear-gradient(280deg, #24cd7b 0%, #355fd5 70%);
  padding: 15px 15px 0; }
  @media screen and (min-width: 992px) {
    .new-nav__wrapper {
      display: block;
      position: static;
      left: inherit;
      right: inherit;
      width: auto;
      height: auto;
      transition: none;
      padding: 0;
      background: none;
      overflow: visible; } }
  .new-nav__wrapper.show {
    position: absolute;
    width: 100vw;
    right: 0; }

.new-nav__list {
  position: relative;
  display: block; }
  @media screen and (min-width: 992px) {
    .new-nav__list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 35px; } }

.new-nav__child-wrapper {
  display: none;
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px; }
  @media screen and (min-width: 992px) {
    .new-nav__child-wrapper {
      position: absolute;
      top: 81px;
      left: 0;
      width: 334px;
      transition: 0.3s all ease;
      transform: translate(0px, 10px);
      visibility: hidden;
      opacity: 0;
      display: block;
      background: #fff;
      box-shadow: 1px 0 13px 7px rgba(0, 0, 0, 0.1);
      margin-top: 0;
      padding-left: 0;
      padding-right: 0; } }
  .new-nav__child-wrapper.showNav {
    display: block; }
  @media screen and (min-width: 992px) {
    .new-nav__child-wrapper:before {
      content: "";
      position: absolute;
      height: 10px;
      top: -10px;
      left: 0;
      right: 0;
      width: 100%; } }

.new-nav__items {
  padding: 10px 0;
  margin: 0;
  position: relative; }
  @media screen and (min-width: 992px) {
    .new-nav__items {
      margin: 0 10px;
      padding: 24px 0; } }
  .new-nav__items:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    background: #29ad95;
    width: 100%;
    height: 4px;
    visibility: hidden;
    opacity: 0;
    transform: scale(1.2);
    transition: 0.3s all ease-in-out; }
  @media screen and (min-width: 992px) {
    .new-nav__items:hover .new-nav__child-wrapper {
      visibility: visible;
      opacity: 1;
      transform: translate(0); } }
  @media screen and (min-width: 992px) {
    .new-nav__items:hover:before {
      transform: scale(1);
      visibility: visible;
      opacity: 1; } }
  .new-nav__items:hover .new-nav__links {
    color: #fff; }
    @media screen and (min-width: 992px) {
      .new-nav__items:hover .new-nav__links {
        color: #35b15c; } }
  .new-nav__items:hover .new-nav__arrow path {
    stroke: #35b15c; }
  @media screen and (min-width: 992px) {
    .new-nav__items--left-gap {
      padding-left: 12px;
      margin-right: 10px; } }
  @media screen and (min-width: 1200px) {
    .new-nav__items--left-gap {
      padding-left: 20px; } }
  @media screen and (min-width: 992px) {
    .new-nav__items--left-gap::before {
      left: 11px;
      width: 79px; } }
  @media screen and (min-width: 1200px) {
    .new-nav__items--left-gap::before {
      left: 11px;
      width: 75px; } }
  .new-nav__items--left-gap:hover:before {
    display: none; }
  @media screen and (min-width: 992px) {
    .new-nav__items--left-gap:hover .new-nav__links--left-bar {
      background-size: 100% 100%; } }

.new-nav__links {
  font-family: "Poppins";
  font-size: 21px;
  font-weight: 600;
  color: #fff;
  transition: 0.2s all ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media screen and (min-width: 992px) {
    .new-nav__links {
      color: #333;
      font-weight: 500;
      font-size: 13px;
      position: static;
      display: inherit; } }
  @media screen and (min-width: 1200px) {
    .new-nav__links {
      font-size: 16px; } }
  .new-nav__links span {
    font-size: 28px;
    font-weight: 300;
    flex: 0 0 30px;
    height: 30px;
    width: 30px;
    color: #fff;
    border-radius: 50%;
    border: #fff 1px solid;
    line-height: 30px;
    text-align: center;
    margin-right: 0; }

.new-nav__arrow {
  margin-left: 5px; }

.new-nav__child-items {
  position: relative; }
  @media screen and (min-width: 992px) {
    .new-nav__child-items {
      padding: 0; } }
  @media screen and (min-width: 992px) {
    .new-nav__child-items:hover {
      background: #24cd7b;
      background: linear-gradient(120deg, #24cd7b 0%, #355fd5 60%); } }
  .new-nav__child-items:hover .new-nav__child-links {
    color: #fff; }
    @media screen and (min-width: 992px) {
      .new-nav__child-items:hover .new-nav__child-links:after {
        border: none; } }
  .new-nav__child-items:hover path {
    stroke: #fff; }
  @media screen and (min-width: 992px) {
    .new-nav__child-items:hover .new-nav__child-wrapper-second {
      visibility: visible;
      opacity: 1;
      transform: translate(0); } }
  .new-nav__child-items svg {
    position: absolute;
    display: none; }
    @media screen and (min-width: 992px) {
      .new-nav__child-items svg {
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        display: block; } }
    @media screen and (min-width: 992px) {
      .new-nav__child-items svg.before-link {
        position: relative;
        top: -1px;
        right: 0;
        transform: none;
        display: inline-block;
        margin-right: 5px; } }
  .new-nav__child-items:last-child .new-nav__child-links:after {
    display: block; }
    @media screen and (min-width: 992px) {
      .new-nav__child-items:last-child .new-nav__child-links:after {
        display: none; } }
  .new-nav__child-items--top-space {
    margin-top: 15px; }
    @media screen and (min-width: 992px) {
      .new-nav__child-items--top-space {
        margin-top: 0; } }

.new-nav__child-links {
  font-family: "Poppins" !important;
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  position: relative;
  padding: 5px 0 5px; }
  @media screen and (min-width: 992px) {
    .new-nav__child-links {
      color: #333;
      font-size: 16px;
      font-weight: 600;
      padding: 16px 30px;
      transition: none; } }
  .new-nav__child-links:after {
    content: '';
    position: absolute;
    top: 13px;
    left: -11px;
    background: url(/images/common/white-right-bracket.svg) no-repeat 0 0;
    width: 6px;
    height: 10px; }
    @media screen and (min-width: 992px) {
      .new-nav__child-links:after {
        content: "";
        position: absolute;
        top: inherit;
        bottom: -1px;
        left: 0;
        right: 0;
        width: 82%;
        height: 1px;
        margin: 0 auto;
        border-bottom: 1px dashed #d9d9d9;
        background: none; } }
  .new-nav__child-links--head {
    margin-bottom: 10px;
    font-weight: 500; }
    @media screen and (min-width: 992px) {
      .new-nav__child-links--head {
        margin-bottom: 0;
        font-weight: 600; } }
    .new-nav__child-links--head:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      background: #14df7d;
      width: 35px;
      height: 2px;
      display: block; }
      @media screen and (min-width: 992px) {
        .new-nav__child-links--head:before {
          display: none; } }

.new-nav__child-wrapper-second {
  background: none;
  padding: 0;
  position: relative; }
  @media screen and (min-width: 992px) {
    .new-nav__child-wrapper-second {
      position: absolute;
      top: 0;
      right: -303px;
      width: 303px;
      transition: 0.3s all ease;
      transform: translate(-30px, 0);
      visibility: hidden;
      opacity: 0;
      background: #355fd5;
      padding: 10px 0; } }
  .new-nav__child-wrapper-second--seperator {
    padding-bottom: 15px; }
    @media screen and (min-width: 992px) {
      .new-nav__child-wrapper-second--seperator {
        padding: 10px 0;
        margin-bottom: 0; } }
    .new-nav__child-wrapper-second--seperator:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: #fff;
      display: none; }
      @media screen and (min-width: 992px) {
        .new-nav__child-wrapper-second--seperator:before {
          display: none; } }
  .new-nav__child-wrapper-second:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 90%;
    background: #fff;
    display: block; }
    @media screen and (min-width: 992px) {
      .new-nav__child-wrapper-second:after {
        display: none; } }
  .new-nav__child-wrapper-second--tree1:after {
    height: 78%; }
  .new-nav__child-wrapper-second--tree2:after {
    height: 74%; }

.new-nav__child-items-second {
  padding: 0 0 0 20px;
  position: relative; }
  @media screen and (min-width: 992px) {
    .new-nav__child-items-second {
      padding: 1px 15px; } }
  @media screen and (min-width: 992px) {
    .new-nav__child-items-second:hover .new-nav__child-links-second {
      color: #fff;
      background: #26459b; } }
  .new-nav__child-items-second:before {
    content: '';
    position: absolute;
    top: 15px;
    left: 0;
    width: 15px;
    height: 1px;
    background: #fff;
    display: block; }
    @media screen and (min-width: 992px) {
      .new-nav__child-items-second:before {
        display: none; } }

.new-nav__child-links-second {
  font-family: "Poppins" !important;
  display: block;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  position: relative;
  background: none;
  border-radius: 4px;
  padding: 5px 0;
  transition: 0.2s all linear; }
  @media screen and (min-width: 992px) {
    .new-nav__child-links-second {
      padding: 8px 15px;
      font-weight: 500; } }

.overflow-y-hidden {
  overflow-y: hidden; }

@keyframes animate {
  0% {
    background-position: 400%; }
  100% {
    background-position: 0%; } }

/* ANCHOR Data Engineering-services*/
.data-engineering-service-wrap {
  display: flex;
  flex-wrap: wrap; }
  .data-engineering-service-wrap .data-engineering-service-box {
    width: 30%; }

.service-section-heading-new {
  margin-bottom: 25px; }
  @media screen and (min-width: 992px) {
    .service-section-heading-new {
      margin-bottom: 40px; } }
  .service-section-heading-new h2 {
    margin-bottom: 20px; }
  .service-section-heading-new p {
    font-size: 16px;
    line-height: 25px;
    font-weight: 500; }
  .service-section-heading-new--industries-we-serve p {
    font-size: 18px; }
    @media all and (min-width: 992px) {
      .service-section-heading-new--industries-we-serve p {
        font-size: 21px; } }

.d-trans-pro-tab-btn-new p {
  margin-bottom: 0; }

.industries-we-serve-content {
  display: block;
  width: 80%;
  margin: 0 auto; }
  @media screen and (min-width: 992px) {
    .industries-we-serve-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around; } }
  @media screen and (min-width: 992px) {
    .industries-we-serve-content ul {
      margin-bottom: 0; } }
  @media screen and (min-width: 992px) {
    .industries-we-serve-content.ux-ui-serve-content-new ul {
      margin-bottom: 50px; } }

.d-trans-pro-wrapper-new {
  margin-bottom: 50px; }
  .d-trans-pro-wrapper-new .d-trans-pro-content-spacing-new {
    margin-top: 0; }
    @media screen and (min-width: 992px) {
      .d-trans-pro-wrapper-new .d-trans-pro-content-spacing-new {
        margin-top: 150px; } }
  .d-trans-pro-wrapper-new .d-trans-pro-tab-btn-new {
    width: 400px; }
    .d-trans-pro-wrapper-new .d-trans-pro-tab-btn-new.d-trans-pro-wrapper-analysis-new {
      margin-bottom: 0; }
    .d-trans-pro-wrapper-new .d-trans-pro-tab-btn-new .d-trans-pro-steps::before {
      content: "";
      height: 98%; }
      @media screen and (min-width: 992px) {
        .d-trans-pro-wrapper-new .d-trans-pro-tab-btn-new .d-trans-pro-steps::before {
          height: 88%; } }

@media screen and (min-width: 992px) {
  .new-service-section-wrapper {
    display: inherit; } }

.o-key-diff-new h2 {
  margin-bottom: 40px; }
  @media screen and (min-width: 992px) {
    .o-key-diff-new h2 {
      margin-bottom: 60px; } }

.d-new-process h2 {
  margin-bottom: 62px; }

.d-new-process__ul {
  width: 100%; }
  @media screen and (min-width: 992px) {
    .d-new-process__ul {
      max-width: 960px; } }
  @media screen and (min-width: 1280px) {
    .d-new-process__ul {
      max-width: 1140px; } }

.d-new-process__item {
  max-width: 440px;
  border-radius: 10px;
  background-color: #fff;
  padding: 36px 30px 18px 34px;
  position: relative;
  width: 100%; }
  @media screen and (min-width: 992px) {
    .d-new-process__item {
      width: 39.3%; } }
  .d-new-process__item:after {
    position: absolute;
    content: "";
    right: -18px;
    top: 30%;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 17px 0 17px 18px;
    border-color: transparent transparent transparent #FFFFFF;
    transform: rotate(0deg);
    display: none; }
    @media screen and (min-width: 992px) {
      .d-new-process__item:after {
        display: block; } }
  .d-new-process__item .count {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #ECECEC;
    color: #4492DC;
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -20px;
    left: 34px;
    background: #fff; }
  .d-new-process__item h4 {
    font-size: 22px;
    line-height: 40px;
    color: #333;
    margin-bottom: 5px; }
  .d-new-process__item p {
    font-size: 15px;
    color: #333;
    line-height: 24px; }
  .d-new-process__item .process-image {
    width: 84px;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: inherit;
    right: inherit;
    top: inherit; }
    @media screen and (min-width: 992px) {
      .d-new-process__item .process-image {
        position: absolute;
        border: 3px solid #33DF67;
        right: -39%;
        background: #fff;
        top: 16%;
        border-radius: 50%; } }
    .d-new-process__item .process-image:after {
      position: absolute;
      content: "";
      width: 16px;
      height: 67px;
      background: url(/images/generative-ai/arrow1.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto 100%;
      left: 0;
      right: 0;
      bottom: -105%;
      margin: auto;
      display: none; }
      @media screen and (min-width: 992px) {
        .d-new-process__item .process-image:after {
          display: block; } }

.d-new-process__list {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px; }
  @media screen and (min-width: 992px) {
    .d-new-process__list {
      justify-content: flex-start;
      margin-bottom: 0; } }
  @media screen and (min-width: 992px) {
    .d-new-process__list:nth-child(even) {
      justify-content: flex-end; } }
  @media screen and (min-width: 992px) {
    .d-new-process__list:nth-child(even) .d-new-process__item .count {
      left: inherit;
      right: 25px; } }
  .d-new-process__list:nth-child(even) .d-new-process__item:after {
    position: absolute;
    content: "";
    right: inherit;
    left: -18px;
    top: 30%;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 17px 18px 17px 0;
    border-color: transparent #FFFFFF transparent transparent;
    transform: rotate(0deg);
    display: none; }
    @media screen and (min-width: 992px) {
      .d-new-process__list:nth-child(even) .d-new-process__item:after {
        display: block; } }
  @media screen and (min-width: 992px) {
    .d-new-process__list:nth-child(even) .d-new-process__item .process-image {
      left: -39%;
      right: inherit; } }
  .d-new-process__list:nth-child(2) .d-new-process__item .process-image {
    border-color: #37DC72; }
    .d-new-process__list:nth-child(2) .d-new-process__item .process-image:after {
      background: url(/images/generative-ai/arrow2.svg); }
  .d-new-process__list:nth-child(3) .d-new-process__item .process-image {
    border-color: #3CD38A; }
    .d-new-process__list:nth-child(3) .d-new-process__item .process-image:after {
      background: url(/images/generative-ai/arrow3.svg); }
  .d-new-process__list:nth-child(4) .d-new-process__item .process-image {
    border-color: #41C9A1; }
    .d-new-process__list:nth-child(4) .d-new-process__item .process-image:after {
      background: url(/images/generative-ai/arrow4.svg); }
  .d-new-process__list:nth-child(5) .d-new-process__item .process-image {
    border-color: #46C0B9; }
    .d-new-process__list:nth-child(5) .d-new-process__item .process-image:after {
      background: url(/images/generative-ai/arrow5.svg); }
  .d-new-process__list:last-child .d-new-process__item .process-image {
    border-color: #4BB6D0; }
    .d-new-process__list:last-child .d-new-process__item .process-image:after {
      display: none; }

.technology-stack-sub-tittle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 28px;
  margin-top: 40px;
  text-align: left; }
  @media screen and (min-width: 992px) {
    .technology-stack-sub-tittle {
      font-size: 24px; } }

.blue-background {
  background-color: #204379; }

.service-case-study {
  padding: 90px 0; }
  .service-case-study .tittle {
    color: #fff;
    font-weight: 700;
    text-align: center; }
  .service-case-study .sub-tittle {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    max-width: 1060px;
    margin: 0 auto 62px; }
  .service-case-study .service-area {
    background: #fff;
    padding: 20px 40px 60px;
    border-radius: 10px;
    width: 100%;
    height: 100%; }
  .service-case-study .service-tittle {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 20px; }
    .service-case-study .service-tittle i {
      margin-right: 10px;
      width: 45px;
      height: 45px; }
    .service-case-study .service-tittle h3 {
      margin: 0;
      line-height: 40px; }
  .service-case-study .service-list {
    margin-top: 22px; }
  .service-case-study .service-item {
    margin-bottom: 15px;
    font-size: 18px;
    color: #333;
    line-height: 27px;
    background: url(/images/common/checkmark-icon-green.svg);
    background-position: left 4px;
    background-repeat: no-repeat;
    background-size: 17px;
    padding-left: 30px;
    width: 100%; }

.gradient-blue-bottom-green {
  background: linear-gradient(324deg, #1F906D 0, #0A4A75 15%, #042242 60%, #042242 100%); }

.ai-workflow {
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  backdrop-filter: blur(5px);
  padding: 77px 60px 70px;
  max-width: 1177px;
  margin: auto; }
  .ai-workflow__first {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    align-items: center;
    flex-direction: column; }
    @media screen and (min-width: 992px) {
      .ai-workflow__first {
        flex-direction: row; } }
  .ai-workflow__userblock {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .ai-workflow__userblock {
        flex-direction: row;
        margin-bottom: 0;
        margin-right: 15px; } }
    @media screen and (min-width: 1177px) {
      .ai-workflow__userblock {
        margin-right: 0; } }
  .ai-workflow__iconblock {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    @media screen and (min-width: 992px) {
      .ai-workflow__iconblock {
        margin-right: 10px; } }
    @media screen and (min-width: 1177px) {
      .ai-workflow__iconblock {
        margin-right: 25px; } }
  .ai-workflow__icontitle {
    color: #fff;
    font-size: 22px;
    line-height: 40px;
    font-weight: 700; }
  .ai-workflow__text {
    font-size: 13px;
    line-height: 15px;
    color: #6D99CE;
    padding-bottom: 0 !important;
    margin: 0 !important;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .ai-workflow__text {
        text-align: left; } }
    .ai-workflow__text--witharrow {
      position: relative;
      padding-bottom: 26px !important; }
      .ai-workflow__text--witharrow:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 8px;
        background: url(/images/generative-ai/user-line.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% auto;
        left: 0;
        bottom: 0;
        display: none; }
        @media screen and (min-width: 992px) {
          .ai-workflow__text--witharrow:after {
            display: block; } }
  .ai-workflow__icon {
    width: 73px;
    height: 73px;
    border-radius: 50%;
    background: #4492DC;
    display: flex;
    align-items: center;
    justify-content: center; }
  .ai-workflow__arrowtext {
    max-width: 155px; }
  .ai-workflow__pointblock {
    display: inline-flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 0 15px 15px;
    max-width: 248px;
    background: rgba(0, 0, 0, 0.1);
    border: 1px dashed #fff; }
    @media screen and (min-width: 1177px) {
      .ai-workflow__pointblock {
        padding: 0 30px 30px; } }
  .ai-workflow__smallpoint {
    border-radius: 10px;
    background-color: #4492DC;
    color: #000;
    text-align: center;
    width: 100%;
    position: relative;
    margin-top: -18px;
    margin-bottom: 40px; }
    .ai-workflow__smallpoint p {
      font-size: 16px;
      line-height: 40px;
      font-weight: 400;
      margin: 0;
      padding: 0;
      color: #000; }
      .ai-workflow__smallpoint p span {
        font-weight: 600; }
  .ai-workflow__largepoint {
    border-radius: 10px;
    color: #000;
    width: 100%;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 25px; }
    .ai-workflow__largepoint i {
      margin-right: 15px; }
    .ai-workflow__largepoint h6 {
      font-size: 18px;
      line-height: 40px;
      font-weight: 700;
      margin: 0;
      color: #000; }
    .ai-workflow__largepoint--green {
      background-color: #3CC065; }
    .ai-workflow__largepoint--white {
      background-color: #FFFFFF; }
  .ai-workflow__arrowpointarea {
    display: flex;
    margin: 14px 0 18px;
    align-items: center;
    justify-content: center; }
    .ai-workflow__arrowpointarea .ai-workflow__text {
      width: 45%; }
  .ai-workflow__arrowpoint {
    width: 55%;
    height: 67px;
    background: url(/images/generative-ai/compare-line.svg) no-repeat;
    background-position: 85% center;
    background-size: 8px 100%; }
  .ai-workflow__releventblock {
    max-width: 200px;
    margin: 30px auto; }
    @media screen and (min-width: 992px) {
      .ai-workflow__releventblock {
        margin: 0 15px; } }
    @media screen and (min-width: 1177px) {
      .ai-workflow__releventblock {
        margin: 0; } }
    .ai-workflow__releventblock p {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .ai-workflow__releventblock p {
          width: 89%; } }
    .ai-workflow__releventblock h6 {
      color: #fff;
      text-align: center;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600; }
  .ai-workflow__releventicon {
    text-align: center;
    position: relative;
    margin: 14px 0 7px; }
    .ai-workflow__releventicon:before {
      position: absolute;
      content: "";
      width: 62px;
      height: 6px;
      background: url(/images/generative-ai/relevent-arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% auto;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: none; }
      @media screen and (min-width: 992px) {
        .ai-workflow__releventicon:before {
          display: block; } }
    .ai-workflow__releventicon:after {
      position: absolute;
      content: "";
      width: 62px;
      height: 6px;
      background: url(/images/generative-ai/relevent-arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% auto;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: none; }
      @media screen and (min-width: 992px) {
        .ai-workflow__releventicon:after {
          display: block; } }
  .ai-workflow__second {
    display: flex;
    justify-content: center;
    margin-top: 30px; }
    @media screen and (min-width: 992px) {
      .ai-workflow__second {
        justify-content: flex-end;
        margin-top: 16px; } }
  @media screen and (min-width: 992px) {
    .ai-workflow__taskoutput {
      margin-right: 95px; } }
  @media screen and (min-width: 1177px) {
    .ai-workflow__taskoutput {
      margin-right: 125px; } }
  .ai-workflow__taskoutput .ai-workflow__iconblock {
    position: relative;
    margin-right: 0; }
    @media screen and (min-width: 992px) {
      .ai-workflow__taskoutput .ai-workflow__iconblock {
        left: 10px; } }
  .ai-workflow__taskoutput .ai-workflow__arrowtext {
    max-width: 205px; }
  .ai-workflow__taskoutput .ai-workflow__text--witharrow {
    text-align: center; }
    @media screen and (min-width: 992px) {
      .ai-workflow__taskoutput .ai-workflow__text--witharrow {
        text-align: right;
        padding: 20px 19px 20px 0 !important; } }
    .ai-workflow__taskoutput .ai-workflow__text--witharrow:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: url(/images/generative-ai/task-output-arrow.svg);
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 100% 100%;
      right: 0;
      top: 0;
      display: none; }
      @media screen and (min-width: 992px) {
        .ai-workflow__taskoutput .ai-workflow__text--witharrow:after {
          display: block; } }

/* ANCHOR React.js Development*/
.react-js-development-process .heading-para {
  font-size: 16px;
  line-height: 24px; }
  @media screen and (min-width: 992px) {
    .react-js-development-process .heading-para {
      font-size: 18px;
      line-height: 24px; } }

.looking-service {
  padding: 15px 0 50px; }
  @media screen and (min-width: 992px) {
    .looking-service {
      padding: 65px 0 80px; } }
  .looking-service .heading-top {
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .looking-service .heading-top {
        margin-bottom: 40px; } }
    .looking-service .heading-top p {
      font-size: 16px;
      line-height: 24px;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .looking-service .heading-top p {
          font-size: 22px;
          line-height: 38px; } }
  .looking-service h2 {
    margin-top: 25px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .looking-service h2 {
        margin-top: 0; } }
  .looking-service .other-ser-box {
    padding: 35px 15px 30px 15px;
    background: #fff;
    border: 1px solid #E1DFE6;
    border-radius: 5px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
    text-align: center;
    margin-bottom: 15px;
    min-height: 200px;
    display: block;
    position: relative;
    transition: .4s all ease-in-out; }
    .looking-service .other-ser-box:hover {
      box-shadow: 0px -3px 25px 1px rgba(0, 0, 0, 0.2); }
    @media screen and (min-width: 992px) {
      .looking-service .other-ser-box {
        min-height: 300px; } }
    .looking-service .other-ser-box-img {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .looking-service .other-ser-box-img {
          height: 103px; } }
    .looking-service .other-ser-box h4 {
      margin-top: 20px;
      font-size: 18px; }
      @media screen and (min-width: 992px) {
        .looking-service .other-ser-box h4 {
          font-size: 22px;
          line-height: 25px;
          font-weight: 500;
          min-height: 54px;
          display: flex;
          align-items: center;
          justify-content: center; } }
    .looking-service .other-ser-box.ml-0 {
      margin-left: 0; }
    .looking-service .other-ser-box span {
      display: block;
      margin-top: 20px;
      left: 0;
      right: 0;
      bottom: 35px; }
      @media screen and (min-width: 992px) {
        .looking-service .other-ser-box span {
          position: absolute; } }
  .looking-service .other-ser-right {
    text-align: center; }
    @media screen and (min-width: 992px) {
      .looking-service .other-ser-right {
        padding-left: 50px;
        text-align: left; } }
    @media screen and (min-width: 1200px) {
      .looking-service .other-ser-right {
        padding-left: 100px; } }
  .looking-service .new-btn {
    margin-top: 20px; }
    @media screen and (min-width: 992px) {
      .looking-service .new-btn {
        width: 335px;
        margin-top: 40px; } }

.react-js-development-process .d-trans-pro-wrapper .d-trans-pro-content-box2,
.react-js-development-process .d-trans-pro-wrapper .d-trans-pro-content-box3,
.react-js-development-process .d-trans-pro-wrapper .d-trans-pro-content-box4,
.react-js-development-process .d-trans-pro-wrapper .d-trans-pro-content-box5 {
  margin-top: 0; }

@media screen and (min-width: 992px) {
  .react-js-development-process .d-trans-pro-wrapper .d-trans-pro-steps::before {
    height: 85%; } }

@media screen and (min-width: 992px) {
  .react-js-development-process .d-trans-pro-wrapper {
    margin-top: 50px; } }

.react-js-development-process .heading-para {
  text-align: center; }
  @media screen and (min-width: 992px) {
    .react-js-development-process .heading-para {
      font-size: 18px;
      line-height: 27px;
      max-width: 950px;
      margin: 0 auto; } }

@media screen and (min-width: 992px) {
  .react-js-development-process h2 {
    margin-bottom: 15px; } }

.rj-dev-stack {
  padding: 40px 0 50px; }
  @media screen and (min-width: 992px) {
    .rj-dev-stack {
      padding: 65px 0 30px; } }
  .rj-dev-stack-top {
    text-align: center;
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .rj-dev-stack-top {
        margin-bottom: 25px; } }
    .rj-dev-stack-top p {
      font-size: 16px;
      line-height: 24px; }
      @media screen and (min-width: 992px) {
        .rj-dev-stack-top p {
          font-size: 18px;
          line-height: 28px;
          margin: 0 auto;
          max-width: 940px; } }
  .rj-dev-stack .wrapper {
    border-radius: 5px;
    border: 1px solid #E1DFE6;
    box-shadow: 3px 7px 13px 4px rgba(0, 0, 0, 0.05); }
    @media screen and (min-width: 992px) {
      .rj-dev-stack .wrapper {
        margin: 40px auto 50px;
        max-width: 970px;
        min-height: 419px;
        background: #fff; } }
  .rj-dev-stack a {
    text-decoration: none; }
  .rj-dev-stack .accordion {
    width: 100%;
    position: relative;
    text-align: center;
    font-size: 0; }
    @media screen and (min-width: 992px) {
      .rj-dev-stack .accordion {
        display: flex; } }
  @media screen and (min-width: 992px) {
    .rj-dev-stack .top-left-b-r {
      border-top-left-radius: 5px;
      overflow: hidden; } }
  .rj-dev-stack .accordion-title {
    vertical-align: top;
    background: #f6f6f6;
    position: relative; }
    @media screen and (min-width: 992px) {
      .rj-dev-stack .accordion-title {
        flex: 1;
        display: inline-block; } }
    @media screen and (min-width: 992px) {
      .rj-dev-stack .accordion-title:hover a {
        background: #fff; }
        .rj-dev-stack .accordion-title:hover a:before {
          content: '';
          position: absolute;
          background: #3CC065;
          height: 3px;
          width: 100%;
          top: inherit;
          bottom: 0;
          left: 0;
          z-index: 9; } }
  .rj-dev-stack .accordion-title a {
    display: block;
    padding: 15px;
    font-size: 80%;
    font-size: 19px;
    color: #333;
    background: #fff;
    font-weight: 500;
    position: relative;
    text-align: left;
    border-bottom: 1px solid #E1DFE6;
    cursor: pointer; }
    @media screen and (min-width: 992px) {
      .rj-dev-stack .accordion-title a {
        min-height: 82px;
        padding: 16px 16px 8px;
        border-right: 1px solid #E1DFE6;
        background: #F6F6F6;
        border-bottom: 0;
        text-align: center;
        font-size: 15px;
        line-height: 15px; } }
    .rj-dev-stack .accordion-title a span {
      margin: 0 5px; }
      @media screen and (min-width: 992px) {
        .rj-dev-stack .accordion-title a span {
          display: block;
          width: 100%;
          margin: 0; } }
    .rj-dev-stack .accordion-title a:before {
      content: '+';
      position: absolute;
      top: 10px;
      right: 15px;
      display: block;
      font-size: 24px;
      font-weight: 500;
      color: #dbbb7c; }
      @media screen and (min-width: 992px) {
        .rj-dev-stack .accordion-title a:before {
          display: none; } }
    .rj-dev-stack .accordion-title a.no-border {
      border-right: 0; }
  .rj-dev-stack .accordion-title.active {
    position: relative; }
    .rj-dev-stack .accordion-title.active::before {
      content: '';
      position: absolute;
      background: #3CC065;
      height: 3px;
      width: 100%;
      bottom: 0;
      left: 0;
      z-index: 9;
      display: none; }
      @media screen and (min-width: 992px) {
        .rj-dev-stack .accordion-title.active::before {
          display: block; } }
  .rj-dev-stack .accordion-title.active a {
    background-color: #fff;
    font-weight: 500; }
    @media screen and (min-width: 992px) {
      .rj-dev-stack .accordion-title.active a {
        font-weight: 600; } }
    .rj-dev-stack .accordion-title.active a:before {
      content: '-';
      position: absolute;
      top: 0;
      right: 15px;
      left: inherit;
      display: block;
      font-size: 34px;
      font-weight: 500;
      color: #dbbb7c;
      width: auto;
      background: none; }
      @media screen and (min-width: 992px) {
        .rj-dev-stack .accordion-title.active a:before {
          display: none; } }
  .rj-dev-stack .accordion-content {
    width: 100%;
    margin-top: 0;
    padding: 20px 20px;
    text-align: center;
    border-bottom: 1px solid #e1dfe6;
    background: #fff; }
    @media screen and (min-width: 992px) {
      .rj-dev-stack .accordion-content {
        padding: 45px 30px 45px 50px;
        border-top: 1px solid #e1dfe6;
        border-bottom: 0;
        margin-bottom: 0;
        position: absolute;
        top: 82px;
        left: 0;
        text-align: left;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        background: none; } }
    .rj-dev-stack .accordion-content h3 {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 15px;
      font-weight: 600; }
      @media screen and (min-width: 992px) {
        .rj-dev-stack .accordion-content h3 {
          font-size: 26px;
          line-height: 36px; } }
    .rj-dev-stack .accordion-content p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
      margin-bottom: 15px; }
      @media screen and (min-width: 992px) {
        .rj-dev-stack .accordion-content p {
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 25px; } }
  @media screen and (min-width: 992px) {
    .rj-dev-stack .fs-0 {
      visibility: hidden; } }
  .rj-dev-stack .accordion-content-img img {
    width: 200px; }
    @media screen and (min-width: 992px) {
      .rj-dev-stack .accordion-content-img img {
        width: inherit; } }

/* ANCHOR IoT Service*/
@media screen and (min-width: 992px) {
  .iot-how-we-work .d-trans-pro-wrapper .d-trans-pro-steps:before {
    height: 90%; } }

@media screen and (min-width: 992px) {
  .iot-how-we-work .d-trans-pro-wrapper .d-trans-pro-content-box4,
  .iot-how-we-work .d-trans-pro-wrapper .d-trans-pro-content-box5,
  .iot-how-we-work .d-trans-pro-wrapper .d-trans-pro-content-box6,
  .iot-how-we-work .d-trans-pro-wrapper .d-trans-pro-content-box7 {
    margin-top: 220px; } }

.leverage-section {
  padding: 50px 0 50px;
  background: linear-gradient(150deg, #038070 0%, #00517f 45%, #023e66 64%, #051b39 100%); }
  @media screen and (min-width: 992px) {
    .leverage-section {
      padding: 70px 0 70px; } }
  .leverage-section h2 {
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .leverage-section h2 {
        margin-bottom: 50px; } }
  .leverage-section .o-key-diff-left h3:before {
    background-image: url(/images/iot/green-right-arrow.svg); }
  .leverage-section .o-key-diff-box {
    width: 100%;
    background: #fff; }
    @media screen and (min-width: 992px) {
      .leverage-section .o-key-diff-box ul {
        margin-top: 10px;
        padding-right: 15px; } }
    .leverage-section .o-key-diff-box ul li {
      font-size: 16px;
      line-height: 27px;
      padding: 0 0 5px 25px;
      position: relative; }
      .leverage-section .o-key-diff-box ul li:before {
        content: '';
        position: absolute;
        left: 0;
        top: 6px;
        background: url(/images/dotnet/green-tick.svg) no-repeat 0 0;
        height: 16px;
        width: 16px; }
  .leverage-section .lts-discuss {
    text-align: center; }
    @media screen and (min-width: 992px) {
      .leverage-section .lts-discuss {
        margin-top: 50px; } }
    .leverage-section .lts-discuss h2 {
      font-size: 28px;
      line-height: 38px; }
      @media screen and (min-width: 992px) {
        .leverage-section .lts-discuss h2 {
          font-size: 36px;
          line-height: 50px;
          margin-bottom: 5px; } }
    .leverage-section .lts-discuss p {
      font-size: 18px;
      line-height: 28px;
      max-width: 680px;
      margin: 0 auto;
      margin-bottom: 20px; }

.iot-case {
  padding: 40px 0 50px; }
  @media screen and (min-width: 992px) {
    .iot-case {
      padding: 70px 0 70px; } }
  .iot-case-top {
    margin-bottom: 25px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .iot-case-top {
        margin-bottom: 50px; } }
    .iot-case-top h2 {
      text-align: center; }
    .iot-case-top p {
      font-size: 20px;
      margin: 15px 0 0; }
  @media screen and (min-width: 992px) {
    .iot-case-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 805px;
      margin: 0 auto; } }
  .iot-case-wrapper-box {
    margin-bottom: 20px;
    background: #fff;
    padding: 20px 15px;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    border-bottom-width: 8px;
    box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.05); }
    @media screen and (min-width: 992px) {
      .iot-case-wrapper-box {
        width: 46%;
        margin: 0 15px 30px; } }
    @media screen and (min-width: 992px) {
      .iot-case-wrapper-box {
        padding: 35px 35px 30px 40px;
        min-height: 435px; } }
    .iot-case-wrapper-box h3 {
      font-size: 20px;
      margin-top: 15px;
      margin-bottom: 10px;
      font-weight: 500; }
      @media screen and (min-width: 992px) {
        .iot-case-wrapper-box h3 {
          font-size: 24px; } }
    .iot-case-wrapper-box ul li {
      font-size: 16px;
      line-height: 27px;
      padding: 0 0 10px 25px;
      position: relative; }
      @media screen and (min-width: 992px) {
        .iot-case-wrapper-box ul li {
          font-size: 20px;
          line-height: 30px; } }
      .iot-case-wrapper-box ul li:before {
        content: '';
        position: absolute;
        left: 0;
        top: 7px;
        background: url(/images/dotnet/green-tick.svg) no-repeat 0 0;
        height: 16px;
        width: 16px; }
  .iot-case-wrapper .border-color1 {
    border-bottom-color: #4A90E2; }
  .iot-case-wrapper .border-color2 {
    border-bottom-color: #49C684; }
  .iot-case-wrapper .border-color3 {
    border-bottom-color: #4A90E2; }
    @media screen and (min-width: 992px) {
      .iot-case-wrapper .border-color3 {
        border-bottom-color: #49C684; } }
  .iot-case-wrapper .border-color4 {
    border-bottom-color: #49C684; }
    @media screen and (min-width: 992px) {
      .iot-case-wrapper .border-color4 {
        border-bottom-color: #4A90E2; } }

@media screen and (min-width: 992px) {
  .tt-section {
    padding: 70px 0 50px; } }

.tt-section .profile-tabs {
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  overflow: hidden;
  margin: 40px auto;
  border-radius: 5px;
  border: none; }
  @media (min-width: 992px) {
    .tt-section .profile-tabs {
      width: 93%;
      border: 1px solid #ddd; } }
  .tt-section .profile-tabs .tab-profile-header {
    display: none; }
    @media (min-width: 992px) {
      .tt-section .profile-tabs .tab-profile-header {
        width: 28%;
        border-right: 1px solid #ddd;
        display: block; } }
    .tt-section .profile-tabs .tab-profile-header ul li {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 25px 15px;
      border-left: 3px solid transparent;
      font-size: 16px;
      cursor: pointer;
      font-weight: 500;
      transition: .4s all ease-in-out; }
      @media screen and (min-width: 992px) {
        .tt-section .profile-tabs .tab-profile-header ul li {
          font-size: 18px; } }
      .tt-section .profile-tabs .tab-profile-header ul li.active {
        border-left: 3px solid #2DBE70;
        background-color: #ECF9F9; }
      @media screen and (min-width: 992px) {
        .tt-section .profile-tabs .tab-profile-header ul li:hover {
          background-color: #ECF9F9;
          border-left: 3px solid #2DBE70; } }
  .tt-section .profile-tabs .tab-profile-content {
    background-color: #fff;
    flex: 1;
    position: relative;
    width: 100%; }
    @media (min-width: 992px) {
      .tt-section .profile-tabs .tab-profile-content {
        width: 75%;
        background-color: #f2f2f2; } }
    .tt-section .profile-tabs .tab-profile-content div {
      position: relative;
      opacity: 1;
      top: auto;
      left: auto; }
      @media (min-width: 992px) {
        .tt-section .profile-tabs .tab-profile-content div {
          position: absolute;
          top: -50vh;
          left: 100px;
          opacity: 0; } }
    .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area {
      width: 100%; }
      .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-wrap,
      .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-title {
        position: static;
        opacity: 1; }
      .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-title {
        display: block;
        background: #ecfaf9;
        padding: 15px 20px;
        margin-bottom: 10px; }
        @media (min-width: 992px) {
          .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-title {
            display: none;
            padding: 10px 20px; } }
        .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-title h3 {
          font-size: 16px;
          line-height: 21px;
          margin-bottom: 0;
          font-weight: 500;
          display: flex;
          align-items: center;
          padding-right: 15px; }
          .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-title h3::after {
            content: "\f107";
            font-family: 'Font Awesome 5 Free';
            position: absolute;
            font-weight: 600;
            right: 0; }
      .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-wrap {
        display: none; }
      .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area ul {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px; }
        @media screen and (min-width: 992px) {
          .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area ul {
            margin-bottom: 0; } }
        .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area ul li {
          width: 33.33%; }
          @media screen and (min-width: 768px) {
            .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area ul li {
              width: 20%; } }
          @media screen and (min-width: 992px) {
            .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area ul li {
              width: 16.6%; } }
          .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area ul li a {
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background: #fff;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid #e1dfe6;
            max-width: 100%;
            margin: 10px;
            height: 75px;
            -webkit-transition: all .4s linear;
            transition: all .4s linear; }
            .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area ul li a img {
              width: 100%;
              margin: 0 auto;
              height: 100%;
              object-fit: contain; }
            .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area ul li a:hover {
              box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2); }
      .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area p {
        margin-bottom: 0;
        padding: 0 11px;
        font-size: 16px; }
      .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area.active {
        top: 0;
        left: 0;
        opacity: 1;
        padding: 0;
        background: #f2f2f2;
        margin-bottom: 10px; }
        @media (min-width: 992px) {
          .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area.active {
            padding: 20px; } }
        .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area.active .tab-profile-content-wrap {
          display: block; }
        .tt-section .profile-tabs .tab-profile-content .tab-profile-content-area.active h3::after {
          transform: rotate(-180deg); }

/* ANCHOR Legacy Software Modernization Service*/
.legacy-software-blue-banner p.legacy-software-blue-banner-text {
  max-width: 100%; }

.legacy-software-blue-banner .legacy-software-new-btn {
  max-width: 386px; }
  @media screen and (min-width: 992px) {
    .legacy-software-blue-banner .legacy-software-new-btn {
      max-width: 46%; } }

.d-trans-pro-content-box.d-trans-pro-content-legal-spacing-new {
  margin-top: 0; }
  @media screen and (min-width: 992px) {
    .d-trans-pro-content-box.d-trans-pro-content-legal-spacing-new {
      margin-top: 100px; } }

.d-trans-pro-content-box.d-trans-pro-content-legal-spacing-new-modify {
  margin-top: 0; }
  @media screen and (min-width: 992px) {
    .d-trans-pro-content-box.d-trans-pro-content-legal-spacing-new-modify {
      margin-top: 70px; } }

.legal-software-banner-cta-box {
  margin-bottom: 30px; }
  @media screen and (min-width: 992px) {
    .legal-software-banner-cta-box {
      margin-bottom: 65px; } }

.profile-our-story-text-content h2 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .profile-our-story-text-content h2 {
      text-align: left;
      margin-top: 0; } }

.profile-our-story-text-content p {
  text-align: center; }
  @media screen and (min-width: 992px) {
    .profile-our-story-text-content p {
      text-align: left; } }

.legacy-software-case-study-modify .legacy-software-case-study-cta {
  margin-top: 60px; }
  @media screen and (min-width: 992px) {
    .legacy-software-case-study-modify .legacy-software-case-study-cta {
      margin-top: 0; } }

.d-trans-pro-wrapper-new {
  margin-bottom: 0; }
  @media screen and (min-width: 992px) {
    .d-trans-pro-wrapper-new {
      margin-bottom: 50px; } }
  @media screen and (min-width: 992px) {
    .d-trans-pro-wrapper-new.d-trans-pro-wrapper-new-modify-cl {
      margin-bottom: 0; } }

.other-ser-box-img-modify {
  display: flex;
  align-items: center;
  justify-content: center; }

.margin-top-20 {
  margin-top: 20px; }

.position-up {
  position: relative;
  z-index: 99; }

.new-service-section-new-modify {
  padding: 40px 0 10px; }
  @media screen and (min-width: 992px) {
    .new-service-section-new-modify {
      padding: 70px 0; } }

/* ANCHOR Product Engineering*/
.methodologies {
  background: linear-gradient(150deg, #038070 0%, #00517f 45%, #023e66 64%, #051b39 100%);
  padding: 50px 0 30px; }
  @media screen and (min-width: 992px) {
    .methodologies {
      padding: 60px 0 80px; } }
  @media screen and (min-width: 992px) {
    .methodologies .section-heading-top {
      margin-bottom: 40px; } }
  .methodologies .section-heading-top p {
    font-size: 16px;
    line-height: 24px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .methodologies .section-heading-top p {
        font-size: 18px;
        line-height: 28px;
        max-width: 815px;
        margin: 0 auto; } }
  .methodologies-box {
    text-align: center;
    background: #fff;
    padding: 30px 20px 5px;
    border-radius: 8px;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .methodologies-box {
        padding: 50px 20px 30px;
        margin-bottom: 0; } }
    .methodologies-box p {
      font-size: 16px;
      line-height: 24px; }
      @media screen and (min-width: 992px) {
        .methodologies-box p {
          font-size: 18px;
          line-height: 28px; } }

.industries-serve .industries-para {
  font-size: 16px;
  line-height: 24px; }
  @media screen and (min-width: 992px) {
    .industries-serve .industries-para {
      font-size: 18px;
      line-height: 28px; } }

@media screen and (min-width: 992px) {
  .break-column-mod {
    break-after: column; } }

.why-web-development {
  background: linear-gradient(150deg, #00B35D 0, #00517f 45%, #023e66 64%, #051b39 100%); }
  .why-web-development .methodologies-box i {
    height: 50px;
    display: flex;
    justify-content: center; }
    .why-web-development .methodologies-box i img {
      display: flex;
      align-items: flex-start; }
  .why-web-development .section-heading-top p {
    font-size: 22px;
    line-height: 38px;
    max-width: 93%; }

/* ANCHOR Design Studio*/
.emerging {
  padding: 50px 0 30px; }
  @media screen and (min-width: 992px) {
    .emerging {
      padding: 60px 0 80px; } }
  .emerging h2 {
    text-align: center;
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .emerging h2 {
        margin-bottom: 40px; } }
  .emerging-box {
    text-align: center;
    background: #fff;
    padding: 30px 20px 5px;
    border-radius: 8px;
    margin-bottom: 20px;
    border: 1px solid #D9D9D9; }
    @media screen and (min-width: 992px) {
      .emerging-box {
        padding: 50px 20px 30px;
        margin-bottom: 25px; } }
    .emerging-box h3 {
      font-weight: 500;
      margin-bottom: 15px; }
    .emerging-box p {
      font-size: 16px;
      line-height: 24px; }
      @media screen and (min-width: 992px) {
        .emerging-box p {
          font-size: 18px;
          line-height: 28px; } }
    .emerging-box .emerge-img {
      margin-bottom: 20px; }
      @media screen and (min-width: 992px) {
        .emerging-box .emerge-img {
          height: 66px;
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          justify-content: center; } }

@media screen and (min-width: 992px) {
  .design-studio-uiux .o-key-diff-box {
    min-height: 230px; } }

@media screen and (min-width: 992px) {
  .design-studio-uiux .o-key-diff-left h3 {
    font-size: 22px; } }

@media screen and (min-width: 1280px) {
  .design-studio-uiux .o-key-diff-left h3 {
    font-size: 30px;
    line-height: 40px; } }

.design-studio-uiux .col-lg-4 {
  display: block; }

.sample-work {
  padding: 50px 0 30px; }
  @media screen and (min-width: 992px) {
    .sample-work {
      padding: 60px 0 80px; } }
  .sample-work h2 {
    text-align: center;
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .sample-work h2 {
        margin-bottom: 40px; } }
  .sample-work-box {
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .sample-work-box {
        margin-bottom: 0; } }
    .sample-work-box-wrapper {
      position: relative; }
      .sample-work-box-wrapper .sample-work-box-img {
        width: 100%;
        height: 247px;
        border-radius: 6px;
        overflow: hidden; }
        .sample-work-box-wrapper .sample-work-box-img img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .sample-work-box-wrapper .play-icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        cursor: pointer;
        transition: .3s all ease-in-out; }
        .sample-work-box-wrapper .play-icon:hover {
          background: rgba(0, 0, 0, 0.25); }
          .sample-work-box-wrapper .play-icon:hover img {
            transform: scale(1.05); }
        .sample-work-box-wrapper .play-icon img {
          transition: .3s all ease-in-out; }
    .sample-work-box h5 {
      margin-top: 15px;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .sample-work-box h5 {
          text-align: left;
          margin-top: 30px; } }
  .sample-work .btn_wrapper {
    margin-top: 0; }
    @media screen and (min-width: 992px) {
      .sample-work .btn_wrapper {
        margin-top: 60px; } }
  .sample-work .slick-slide {
    margin: 0 15px; }
  .sample-work ul.slick-dots {
    margin-top: 0; }
    @media screen and (min-width: 992px) {
      .sample-work ul.slick-dots {
        margin-top: 30px;
        margin-bottom: 0; } }
  @media screen and (min-width: 992px) {
    .sample-work .splide__pagination {
      margin-top: 35px; } }

.sample-work-clip {
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.95); }
  .sample-work-clip video {
    width: 100%;
    margin-top: 10px;
    border-radius: 12px; }
  .sample-work-clip .modal-content {
    border: 0;
    border-radius: 0;
    background-color: transparent; }
  .sample-work-clip .modal-header {
    padding: 0;
    border-bottom: 0; }
    .sample-work-clip .modal-header .btn-close {
      padding: 0;
      margin: 0;
      position: absolute;
      right: 7px;
      top: 0;
      width: 26px;
      height: 26px;
      background-color: #fff;
      opacity: 1;
      border-radius: 50%;
      display: flex;
      align-items: center;
      background-position: 7px center;
      border: 1px solid #5B5B5B;
      background-image: url(/images/design-studio/modal-close-icon.svg); }
      @media screen and (min-width: 992px) {
        .sample-work-clip .modal-header .btn-close {
          right: -12px;
          top: -10px; } }
  .sample-work-clip .modal-body {
    padding: 0;
    font-size: 18px;
    font-weight: 600; }
    @media screen and (min-width: 992px) {
      .sample-work-clip .modal-body {
        font-size: 24px;
        background: #fff;
        padding: 10px 10px 0 10px;
        border-radius: 12px; } }
    @media screen and (min-width: 1280px) {
      .sample-work-clip .modal-body {
        padding: 10px 12px 0 12px; } }
    .sample-work-clip .modal-body #videoTitle {
      display: none; }
      @media screen and (min-width: 992px) {
        .sample-work-clip .modal-body #videoTitle {
          display: block; } }
  .sample-work-clip .modal-dialog {
    margin: 0 auto; }
    @media screen and (min-width: 992px) {
      .sample-work-clip .modal-dialog {
        max-width: 800px !important; } }
    .sample-work-clip .modal-dialog iframe {
      height: 470px; }
      @media screen and (min-width: 992px) {
        .sample-work-clip .modal-dialog iframe {
          border-radius: 12px;
          margin-top: 10px;
          height: 435px; } }
      @media screen and (min-width: 1280px) {
        .sample-work-clip .modal-dialog iframe {
          height: 463px; } }
      @media screen and (min-width: 1500px) {
        .sample-work-clip .modal-dialog iframe {
          height: 520px; } }
      @media screen and (min-width: 1700px) {
        .sample-work-clip .modal-dialog iframe {
          height: 615px; } }
      @media screen and (min-width: 1800px) {
        .sample-work-clip .modal-dialog iframe {
          height: 665px; } }
      .sample-work-clip .modal-dialog iframe .vp-spin {
        display: none !important; }
    @media screen and (min-width: 1280px) {
      .sample-work-clip .modal-dialog {
        max-width: 845px !important; } }
    @media screen and (min-width: 1500px) {
      .sample-work-clip .modal-dialog {
        max-width: 945px !important; } }
    @media screen and (min-width: 1700px) {
      .sample-work-clip .modal-dialog {
        max-width: 1115px !important; } }
    @media screen and (min-width: 1800px) {
      .sample-work-clip .modal-dialog {
        max-width: 1200px !important; } }
  .sample-work-clip .modal-dialog-centered {
    min-height: calc(100% - .5rem); }

.new-tech-stack-heading-top {
  margin-bottom: 25px; }
  @media screen and (min-width: 992px) {
    .new-tech-stack-heading-top {
      margin-bottom: 40px; } }

.sample-work-slider {
  flex-wrap: nowrap; }
  .sample-work-slider .col-lg-4 {
    width: 100%; }
  .sample-work-slider .owl-dots {
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .sample-work-slider .owl-dots {
        margin-bottom: 0;
        margin-top: 30px; } }

/* ANCHOR Rate Card*/
.ratecard {
  background: #091B38; }

.logo-section {
  padding: 25px 0;
  background: #fff; }

.rate-card-form-section {
  padding: 30px 0; }
  @media only screen and (min-width: 992px) {
    .rate-card-form-section {
      padding: 80px 0; } }
  @media only screen and (min-width: 992px) {
    .rate-card-form-section .rate-card-form-wrapper {
      max-width: 860px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      border-radius: 10px;
      overflow: hidden; } }
  .rate-card-form-section .rate-card-form-wrapper .rate-card-form-left {
    flex: 0 0 50%;
    position: relative; }
    @media only screen and (min-width: 992px) {
      .rate-card-form-section .rate-card-form-wrapper .rate-card-form-left {
        overflow: hidden;
        padding: 50px 40px 50px 50px;
        background: linear-gradient(172deg, #057568 0%, #03476d 43%, #0f274e 100%); } }
    .rate-card-form-section .rate-card-form-wrapper .rate-card-form-left h3 {
      margin-bottom: 10px;
      color: #fff;
      font-size: 26px;
      line-height: 35px;
      font-weight: 700; }
      @media only screen and (min-width: 992px) {
        .rate-card-form-section .rate-card-form-wrapper .rate-card-form-left h3 {
          font-size: 28px;
          line-height: 45px; } }
    .rate-card-form-section .rate-card-form-wrapper .rate-card-form-left p {
      color: #fff;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      width: 50%; }
      @media only screen and (min-width: 992px) {
        .rate-card-form-section .rate-card-form-wrapper .rate-card-form-left p {
          font-size: 18px;
          line-height: 27px;
          width: 100%; } }
      .rate-card-form-section .rate-card-form-wrapper .rate-card-form-left p span {
        color: #2DBE70; }
    .rate-card-form-section .rate-card-form-wrapper .rate-card-form-left:before {
      content: '';
      position: absolute;
      background: url(/images/rate-card/download-img.svg) no-repeat;
      width: 170px;
      height: 170px;
      bottom: -40px;
      right: -15px;
      background-size: contain; }
      @media only screen and (min-width: 992px) {
        .rate-card-form-section .rate-card-form-wrapper .rate-card-form-left:before {
          width: 204px;
          height: 204px;
          left: 40px;
          right: inherit;
          bottom: 70px; } }
  .rate-card-form-section .rate-card-form-wrapper .rate-card-form-right {
    flex: 0 0 50%;
    background: #091B38;
    border: 0; }
    @media only screen and (min-width: 992px) {
      .rate-card-form-section .rate-card-form-wrapper .rate-card-form-right {
        padding: 50px 50px 70px;
        overflow: hidden;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border: 1px solid #284678;
        border-left: 0; } }
    .rate-card-form-section .rate-card-form-wrapper .rate-card-form-right form input {
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid #fff;
      padding: 15px 15px 15px 35px;
      margin-bottom: 10px;
      width: 100%;
      color: #fff; }
      .rate-card-form-section .rate-card-form-wrapper .rate-card-form-right form input::placeholder {
        opacity: 1;
        color: #fff;
        font-weight: 400; }
    .rate-card-form-section .rate-card-form-wrapper .rate-card-form-right form .user {
      background: url(/images/rate-card/user-icon.svg) no-repeat 0 50%; }
    .rate-card-form-section .rate-card-form-wrapper .rate-card-form-right form .email {
      background: url(/images/rate-card/email-icon.svg) no-repeat 0 50%; }
    .rate-card-form-section .rate-card-form-wrapper .rate-card-form-right form .mobile {
      background: url(/images/rate-card/mobile-icon.svg) no-repeat 0 50%; }
    .rate-card-form-section .rate-card-form-wrapper .rate-card-form-right form .btn-orange {
      width: 100%;
      max-width: inherit;
      padding: 13px;
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 15px; }
      @media only screen and (min-width: 992px) {
        .rate-card-form-section .rate-card-form-wrapper .rate-card-form-right form .btn-orange {
          margin-top: 25px;
          margin-bottom: 20px; } }
    .rate-card-form-section .rate-card-form-wrapper .rate-card-form-right p {
      color: #fff;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0;
      margin-top: 5px; }
      .rate-card-form-section .rate-card-form-wrapper .rate-card-form-right p a {
        color: #4A90E2; }

/* ANCHOR Vue.js Development*/
.grey-mod-class .d-trans-pro-wrapper .d-trans-pro-content-box {
  background: #fff;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05); }

.grey-mod-class .d-trans-pro-wrapper .d-trans-pro-tab-btn {
  border: 1px solid #f5f5f5; }
  .grey-mod-class .d-trans-pro-wrapper .d-trans-pro-tab-btn.active {
    border-color: #e3e3e3; }

@media screen and (min-width: 992px) {
  .grey-mod-class .heading-para {
    max-width: 875px; } }

.blue-bnr-section-mod .btn_wrapper {
  margin-top: 25px; }

.bnr-contact-mod h2.big-h2 {
  padding-bottom: 0;
  margin-bottom: 0; }

.bnr-contact-mod .bnr-contact {
  display: inline-block;
  margin-bottom: 25px;
  margin-top: 15px;
  font-size: 16px;
  line-height: 24px;
  width: 155px;
  text-transform: uppercase;
  color: #55C77C;
  position: relative;
  font-weight: 600; }
  @media screen and (min-width: 992px) {
    .bnr-contact-mod .bnr-contact {
      font-size: 19px;
      line-height: 30px; } }
  .bnr-contact-mod .bnr-contact::before {
    content: '';
    position: absolute;
    background: url(/images/vuejs-development/green-arrow.svg) no-repeat;
    top: 4px;
    right: 0;
    width: 20px;
    height: 14px; }
    @media screen and (min-width: 992px) {
      .bnr-contact-mod .bnr-contact::before {
        top: 7px; } }

/*Nodejs development page process section*/
@media screen and (min-width: 992px) {
  .node-js-development-process .d-trans-pro-wrapper .d-trans-pro-steps:before {
    height: 90%; } }

@media screen and (min-width: 992px) {
  .node-js-development-process .d-trans-pro-wrapper .d-trans-pro-content-box4,
  .node-js-development-process .d-trans-pro-wrapper .d-trans-pro-content-box5,
  .node-js-development-process .d-trans-pro-wrapper .d-trans-pro-content-box6 {
    margin-top: 180px; } }

/*ios development page process section*/
@media screen and (min-width: 992px) {
  .ios-app-dev-process .d-trans-pro-wrapper .d-trans-pro-steps:before {
    height: 90%; } }

@media screen and (min-width: 992px) {
  .ios-app-dev-process .d-trans-pro-wrapper .d-trans-pro-content-box4,
  .ios-app-dev-process .d-trans-pro-wrapper .d-trans-pro-content-box5,
  .ios-app-dev-process .d-trans-pro-wrapper .d-trans-pro-content-box6 {
    margin-top: 180px; } }

/* ANCHOR Machine Learning Development*/
.o-key-diff-modify h2 {
  margin-bottom: 20px; }

.o-key-diff-modify .o-key-diff-box .ml-cases-img {
  min-height: 65px; }

.o-key-diff-modify .o-key-diff-box .skillsz-link {
  color: #000;
  text-decoration: underline; }
  .o-key-diff-modify .o-key-diff-box .skillsz-link:hover {
    text-decoration: none; }

.ml-framwork-box-area {
  display: flex;
  align-items: stretch; }

.margin-top-50-modify {
  margin-top: 30px; }
  @media screen and (min-width: 992px) {
    .margin-top-50-modify {
      margin-top: 40px; } }

.gradient-bg-new {
  background: linear-gradient(135deg, #07B260 0%, #051B39 40%); }
  .gradient-bg-new.ml-framwork h2, .gradient-bg-new.ml-framwork p {
    color: #fff; }
  .gradient-bg-new .ml-framwork-box {
    background-color: #fff;
    border: 1px solid #b2b2b2;
    border-radius: 10px;
    padding: 20px 20px 20px 10px;
    display: flex;
    margin: 15px 0; }
    .gradient-bg-new .ml-framwork-box .heading-area {
      position: relative; }
      .gradient-bg-new .ml-framwork-box .heading-area::after {
        content: "";
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0; }
      .gradient-bg-new .ml-framwork-box .heading-area h4 {
        color: #fff;
        font-weight: 500;
        text-align: center; }
    .gradient-bg-new .ml-framwork-box .ml-framwork-box-tick-icon {
      width: 18px;
      height: 18px;
      margin-right: 8px; }
    .gradient-bg-new .ml-framwork-box .text-area {
      width: 90%; }
      .gradient-bg-new .ml-framwork-box .text-area .ml-framwork-box-heading {
        font-size: 20px;
        margin-bottom: 10px;
        line-height: 20px; }
      .gradient-bg-new .ml-framwork-box .text-area p {
        color: #000;
        font-size: 16px;
        margin: 0; }

.heading-area-main {
  display: block;
  position: relative;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 10px; }
  @media screen and (min-width: 992px) {
    .heading-area-main {
      display: flex;
      text-align: left;
      margin-bottom: 0;
      padding-bottom: 0; } }
  .heading-area-main::after {
    content: "";
    position: absolute;
    top: auto;
    transform: translateX(50%);
    right: 50%;
    border-top: 10px solid #07B260;
    border-bottom: none;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    width: 10px;
    height: 10px;
    bottom: -9px; }
    @media screen and (min-width: 992px) {
      .heading-area-main::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #07B260;
        width: 10px;
        height: 10px;
        bottom: auto; } }
  .heading-area-main::before {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    top: auto;
    bottom: 0;
    right: 50%;
    background-color: #07B260;
    transform: translateX(50%); }
    @media screen and (min-width: 992px) {
      .heading-area-main::before {
        content: "";
        position: absolute;
        height: 80%;
        width: 2px;
        top: 50%;
        transform: translateY(-50%);
        right: 28px;
        background-color: #07B260;
        bottom: auto; } }
  .heading-area-main h4 {
    color: #fff; }

/* ANCHOR AR-VR Development*/
@media screen and (min-width: 992px) {
  .ar-vr-process .d-trans-pro-wrapper .d-trans-pro-content-box4,
  .ar-vr-process .d-trans-pro-wrapper .d-trans-pro-content-box5,
  .ar-vr-process .d-trans-pro-wrapper .d-trans-pro-content-box6 {
    margin-top: 150px; } }

@media screen and (min-width: 992px) {
  .ar-vr-tech .o-key-diff-box {
    padding: 30px 5px 30px 20px; } }

@media screen and (min-width: 992px) {
  .ar-vr-tech .o-key-diff-box ul {
    padding-right: 10px;
    flex: 1; } }

.ar-vr-tech .o-key-diff-box ul li {
  font-size: 15px;
  padding: 0 0 8px 25px; }

.ar-vr-tech .o-key-diff-box .pr-0 {
  padding-right: 0; }

@media screen and (min-width: 992px) {
  .ar-vr-tech .o-key-diff-left {
    padding-top: 20px; } }

@media screen and (min-width: 992px) {
  .ar-vr-tech .o-key-diff-left h3 {
    font-size: 27px;
    line-height: 38px; } }

/* ANCHOR Cloud Computing Development*/
.d-trans-pro-content-box .process-content-order-list li {
  font-size: 18px;
  margin-bottom: 12px;
  position: relative;
  padding-left: 30px; }
  .d-trans-pro-content-box .process-content-order-list li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 6px;
    background: url(/images/dotnet/green-tick.svg) no-repeat 0 0;
    height: 16px;
    width: 16px; }

.cloude-computing-tech-stack .tech-stack-oreder-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 2; }
  .cloude-computing-tech-stack .tech-stack-oreder-list li {
    width: 100%; }
    @media screen and (min-width: 480px) {
      .cloude-computing-tech-stack .tech-stack-oreder-list li {
        width: 50%; } }
    @media screen and (min-width: 992px) {
      .cloude-computing-tech-stack .tech-stack-oreder-list li {
        width: 25%; } }
    .cloude-computing-tech-stack .tech-stack-oreder-list li .tech-stack-oreder-list-iteam {
      display: flex;
      align-items: flex-start;
      background-color: #fff;
      border-radius: 5px;
      padding: 15px;
      margin: 10px;
      font-weight: 500;
      color: #000;
      font-size: 16px;
      position: relative;
      padding-left: 35px; }
      @media screen and (min-width: 992px) {
        .cloude-computing-tech-stack .tech-stack-oreder-list li .tech-stack-oreder-list-iteam {
          min-height: 78px; } }
      .cloude-computing-tech-stack .tech-stack-oreder-list li .tech-stack-oreder-list-iteam::before {
        content: "";
        position: absolute;
        left: 12px;
        top: 18px;
        background: url(/images/dotnet/green-tick.svg) no-repeat 0 0;
        height: 16px;
        width: 16px; }

.cloude-case-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media screen and (min-width: 992px) {
    .cloude-case-wrapper {
      flex-wrap: nowrap; } }
  .cloude-case-wrapper .iot-case-wrapper-box {
    border-bottom-width: 1px;
    box-shadow: unset;
    width: 100%; }
    @media screen and (min-width: 992px) {
      .cloude-case-wrapper .iot-case-wrapper-box {
        width: 30.33%; } }

.clode-computing-deployment-models-row {
  width: 100%;
  margin: auto; }
  @media screen and (min-width: 992px) {
    .clode-computing-deployment-models-row {
      width: 90%; } }
  .clode-computing-deployment-models-row .clode-computing-deployment-models-box {
    display: flex;
    flex-wrap: wrap;
    padding: 20px; }
    @media screen and (min-width: 992px) {
      .clode-computing-deployment-models-row .clode-computing-deployment-models-box {
        padding: 30px 30px 30px 20px;
        flex-wrap: nowrap; } }
    .clode-computing-deployment-models-row .clode-computing-deployment-models-box .ml-cases-img {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px; }
      @media screen and (min-width: 992px) {
        .clode-computing-deployment-models-row .clode-computing-deployment-models-box .ml-cases-img {
          width: 22%;
          margin-right: 30px;
          margin-bottom: 0; } }
      .clode-computing-deployment-models-row .clode-computing-deployment-models-box .ml-cases-img img {
        width: 70px; }
        @media screen and (min-width: 992px) {
          .clode-computing-deployment-models-row .clode-computing-deployment-models-box .ml-cases-img img {
            width: 100%; } }
    .clode-computing-deployment-models-row .clode-computing-deployment-models-box .clode-computing-right-side-text {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .clode-computing-deployment-models-row .clode-computing-deployment-models-box .clode-computing-right-side-text {
          width: 70%; } }
  .clode-computing-deployment-models-row--cloud-engineering .clode-computing-deployment-models-box {
    border-left-width: 6px; }
    @media screen and (min-width: 992px) {
      .clode-computing-deployment-models-row--cloud-engineering .clode-computing-deployment-models-box .ml-cases-img {
        width: 15%; } }

@media screen and (min-width: 992px) {
  .cloud-computing-development-process-section {
    height: 800px; } }

.cloud-computing-development-process-section .cloud-computing-development-process .d-trans-pro-steps.d-trans-pro-steps-new::before {
  height: 98%; }
  @media screen and (min-width: 992px) {
    .cloud-computing-development-process-section .cloud-computing-development-process .d-trans-pro-steps.d-trans-pro-steps-new::before {
      height: 40%; } }

.cloud-computing-development-process-section .cloud-computing-development-process .cloud-computing-development-process-box {
  margin-top: 0; }

.cloud-computing-service-solution {
  position: relative;
  z-index: 2; }

.robust-new-modify {
  padding-bottom: 50px; }
  @media screen and (min-width: 992px) {
    .robust-new-modify {
      padding-bottom: 70px; } }

.margin-bottom-30 {
  margin-bottom: 15px; }
  @media screen and (min-width: 992px) {
    .margin-bottom-30 {
      margin-bottom: 30px; } }

/* ANCHOR CodeIgniter Development*/
@media screen and (min-width: 992px) {
  .codeigniter-dev .d-trans-pro-wrapper .d-trans-pro-content-box2,
  .codeigniter-dev .d-trans-pro-wrapper .d-trans-pro-content-box3,
  .codeigniter-dev .d-trans-pro-wrapper .d-trans-pro-content-box4 {
    margin-top: 0; } }

@media screen and (min-width: 992px) {
  .codeigniter-dev .d-trans-pro-wrapper .d-trans-pro-steps:before {
    height: 86%; } }

.hr-btn {
  margin-top: 20px; }
  @media screen and (min-width: 992px) {
    .hr-btn {
      margin-top: 30px; } }

/* ANCHOR New Engagement Model*/
.fixed-engagement-model.new-fixed-engagement-model {
  background: linear-gradient(311deg, #06a564 0%, #00517f 41%, #023e66 72%, #051b39 100%);
  padding: 40px 0 50px; }
  @media screen and (min-width: 992px) {
    .fixed-engagement-model.new-fixed-engagement-model {
      padding: 65px 0 40px; } }
  .fixed-engagement-model.new-fixed-engagement-model .heading-top {
    margin-bottom: 30px;
    position: relative; }
    .fixed-engagement-model.new-fixed-engagement-model .heading-top p {
      font-size: 18px;
      line-height: 30px;
      font-weight: 500;
      margin: 0 auto;
      max-width: 1000px;
      color: #fff; }
      @media screen and (min-width: 992px) {
        .fixed-engagement-model.new-fixed-engagement-model .heading-top p {
          font-size: 22px;
          line-height: 34px; } }
    .fixed-engagement-model.new-fixed-engagement-model .heading-top h3 {
      color: #fff;
      font-size: 18px;
      line-height: 26px;
      display: inline-block; }
      @media screen and (min-width: 992px) {
        .fixed-engagement-model.new-fixed-engagement-model .heading-top h3 {
          font-size: 24px;
          line-height: 48px;
          vertical-align: top; } }
      @media screen and (min-width: 1200px) {
        .fixed-engagement-model.new-fixed-engagement-model .heading-top h3 {
          font-size: 30px;
          line-height: 56px; } }
      .fixed-engagement-model.new-fixed-engagement-model .heading-top h3 span {
        font-weight: 700;
        color: #71E19D;
        font-size: 30px;
        line-height: 30px; }
        @media screen and (min-width: 992px) {
          .fixed-engagement-model.new-fixed-engagement-model .heading-top h3 span {
            font-size: 36px;
            line-height: 56px; } }
      .fixed-engagement-model.new-fixed-engagement-model .heading-top h3 strong {
        font-weight: 700;
        color: #71E19D;
        font-size: 30px;
        line-height: 30px; }
        @media screen and (min-width: 992px) {
          .fixed-engagement-model.new-fixed-engagement-model .heading-top h3 strong {
            font-size: 36px;
            line-height: 56px; } }
      .fixed-engagement-model.new-fixed-engagement-model .heading-top h3.m-extra-padding {
        padding: 0 48px; }
        @media screen and (min-width: 992px) {
          .fixed-engagement-model.new-fixed-engagement-model .heading-top h3.m-extra-padding {
            padding: 0; } }
        .fixed-engagement-model.new-fixed-engagement-model .heading-top h3.m-extra-padding span {
          white-space: nowrap; }
          @media screen and (min-width: 992px) {
            .fixed-engagement-model.new-fixed-engagement-model .heading-top h3.m-extra-padding span {
              white-space: inherit; } }
    .fixed-engagement-model.new-fixed-engagement-model .heading-top .list-unstyled {
      display: inline-block; }
    .fixed-engagement-model.new-fixed-engagement-model .heading-top .slct-cntr {
      position: absolute;
      left: 20px;
      top: 19px;
      font-size: 15px;
      line-height: 17px;
      font-weight: 500;
      color: #fff;
      text-align: left; }
  @media screen and (min-width: 992px) {
    .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement {
      max-width: 970px;
      margin: 0 auto 50px; } }
  @media screen and (min-width: 992px) {
    .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row {
      display: flex;
      flex-wrap: wrap; } }
  .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row h5 {
    color: #333;
    font-size: 20px;
    font-weight: 600 !important;
    position: relative;
    margin-bottom: 0; }
    .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row h5 strong {
      font-weight: 600 !important; }
    @media screen and (min-width: 992px) {
      .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row h5 {
        font-weight: 700; } }
  .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row-left {
    flex: 0 0 41%;
    display: flex;
    align-items: flex-start;
    background: #0160A9;
    padding: 20px 10px 20px 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
    @media screen and (min-width: 992px) {
      .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row-left {
        margin-right: 5px; } }
    .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row-left i {
      width: 42px; }
    .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row-left h5 {
      font-size: 16px;
      flex: 1; }
      @media screen and (min-width: 992px) {
        .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row-left h5 {
          font-size: 20px; } }
    .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row-left-content {
      flex: 1; }
      .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row-left-content h5 {
        color: #fff;
        font-size: 18px;
        font-weight: 600; }
        @media screen and (min-width: 992px) {
          .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row-left-content h5 {
            margin-bottom: 5px; } }
      .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row-left-content p {
        color: #fff;
        font-size: 13px;
        line-height: 17px;
        font-weight: 500;
        margin-bottom: 0; }
  .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right {
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right {
        flex: 1;
        display: flex;
        margin-bottom: 0; } }
    .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border-bottom: 1px solid #DADBDE;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col {
          margin: 0 5px; } }
      .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col span {
        font-size: 16px;
        padding: 0 10px;
        font-weight: 500; }
        @media screen and (min-width: 992px) {
          .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col span {
            font-size: 15px;
            padding: 10px 25px; } }
        @media screen and (min-width: 1200px) {
          .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col span {
            font-size: 16px; } }
        .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col span img {
          margin-left: 5px;
          position: relative;
          top: -1px;
          width: 20px; }
        .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col span.has-pointer {
          cursor: pointer; }
    .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right .col-content-box {
      background: #fff;
      border-bottom: 1px solid #ecedee;
      padding: 15px; }
      .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right .col-content-box-top {
        display: flex;
        align-items: center; }
        .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right .col-content-box-top h5 {
          color: #333;
          font-size: 16px;
          line-height: 22px;
          font-weight: 400;
          flex: 0 0 55%;
          padding-right: 30px;
          margin-bottom: 0;
          position: relative;
          cursor: pointer; }
          .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right .col-content-box-top h5:before {
            content: '';
            position: absolute;
            background: url(/images/new-engagement-model/circle-down.svg) no-repeat center center;
            width: 28px;
            height: 28px;
            right: 0px;
            top: 50%;
            transform: translate(0, -50%); }
          .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right .col-content-box-top h5.active:before {
            background: url(/images/new-engagement-model/circle-up.svg) no-repeat center center; }
        .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right .col-content-box-top span {
          color: #333;
          font-size: 16px;
          font-weight: 700;
          flex: 1;
          padding-left: 25px; }
      .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right .col-content-box .col-content-description {
        display: none;
        margin-top: 10px; }
        .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row .fixed-engagement-right .col-content-box .col-content-description p {
          margin-bottom: 0;
          font-size: 14px;
          line-height: 20px; }
  .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row-top .fixed-engagement-right .fixed-engagement-col {
    background: #71E19D;
    border-bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-height: 65px; }
    @media screen and (min-width: 992px) {
      .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row-top .fixed-engagement-right .fixed-engagement-col {
        min-height: 85px; } }
    .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row-top .fixed-engagement-right .fixed-engagement-col.has-tooltip {
      position: relative; }
  @media screen and (min-width: 992px) {
    .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row-top .fixed-engagement-right h5 {
      padding: 9px 0;
      line-height: 25px;
      text-align: center; } }
  @media screen and (min-width: 992px) {
    .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row-top .fixed-engagement-right h5 img {
      position: relative;
      top: -2px; } }
  .fixed-engagement-model.new-fixed-engagement-model .fixed-engagement-row-top .fixed-engagement-row-left {
    background: none;
    border-bottom: none; }
  .fixed-engagement-model.new-fixed-engagement-model .border-bottom-0 {
    border-bottom: none; }
  .fixed-engagement-model.new-fixed-engagement-model .border-bottom-left-radius {
    border-bottom-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomleft: 10px; }
  .fixed-engagement-model.new-fixed-engagement-model .border-bottom-right-radius {
    border-bottom-right-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-radius-bottomright: 10px; }
  .fixed-engagement-model.new-fixed-engagement-model .border-top-left-radius {
    border-top-left-radius: 10px;
    -webkit-border-top-left-radius: 10px;
    -moz-border-radius-topleft: 10px; }
  .fixed-engagement-model.new-fixed-engagement-model .slct-box {
    position: relative;
    display: inline-block;
    vertical-align: middle; }
    @media screen and (min-width: 992px) {
      .fixed-engagement-model.new-fixed-engagement-model .slct-box {
        margin-left: 10px; } }
  .fixed-engagement-model.new-fixed-engagement-model #price-tabs {
    margin-bottom: 0;
    position: absolute;
    width: 100%;
    background: #fff;
    z-index: 9999;
    padding: 0;
    display: none; }
    .fixed-engagement-model.new-fixed-engagement-model #price-tabs li {
      width: 100%;
      display: block;
      border-bottom: 1px solid #e5e5e5;
      border-radius: 0;
      margin-bottom: 0;
      padding: 0; }
      .fixed-engagement-model.new-fixed-engagement-model #price-tabs li a {
        background: 0 0;
        border: none;
        font-size: 14px;
        font-weight: 500;
        padding: 5px 15px;
        height: auto;
        width: auto;
        text-align: left;
        color: #333;
        line-height: 26px;
        border-radius: 0;
        display: block; }
        .fixed-engagement-model.new-fixed-engagement-model #price-tabs li a:before {
          display: none; }
      .fixed-engagement-model.new-fixed-engagement-model #price-tabs li:last-child {
        border: none; }
      .fixed-engagement-model.new-fixed-engagement-model #price-tabs li:hover a {
        background: #ccc;
        color: #333; }
    .fixed-engagement-model.new-fixed-engagement-model #price-tabs.ae-show {
      display: block; }
  .fixed-engagement-model.new-fixed-engagement-model .accordion_body {
    display: none; }
  .fixed-engagement-model.new-fixed-engagement-model .dropdown-wrapper .ae-select {
    padding: 8px;
    position: relative;
    height: 56px;
    width: 240px;
    border: 2px #FF772F solid;
    position: relative;
    border-radius: 29px; }
    .fixed-engagement-model.new-fixed-engagement-model .dropdown-wrapper .ae-select-content {
      font-size: 20px;
      color: #fff;
      font-weight: 600;
      width: 100%;
      display: block;
      text-align: right;
      padding-right: 35px;
      position: relative;
      text-transform: uppercase;
      top: 3px; }
      .fixed-engagement-model.new-fixed-engagement-model .dropdown-wrapper .ae-select-content:before {
        content: '';
        background: url(/images/new-engagement-model/bottom-arrow.svg) no-repeat center center;
        position: absolute;
        width: 14px;
        height: 8px;
        top: 13px;
        right: 12px; }
    .fixed-engagement-model.new-fixed-engagement-model .dropdown-wrapper .ae-select .ae-select-content.active:before {
      transform: rotate(180deg);
      top: 12px;
      right: 12px; }
  .fixed-engagement-model.new-fixed-engagement-model .dropdown-wrapper .ae-select.chosen {
    color: #333; }
  .fixed-engagement-model.new-fixed-engagement-model .dropdown-wrapper .ae-select .down-icon, .fixed-engagement-model.new-fixed-engagement-model .ae-select .up-icon {
    position: absolute;
    right: 8px;
    top: 7px; }
  .fixed-engagement-model.new-fixed-engagement-model .dropdown-wrapper .ae-dropdown .select-menu {
    box-shadow: none;
    border-radius: 0px; }
  .fixed-engagement-model.new-fixed-engagement-model .dropdown-wrapper .ae-dropdown .ae-select, .fixed-engagement-model.new-fixed-engagement-model .dropdown-wrapper .ae-dropdown .select-menu > li {
    cursor: pointer; }
  .fixed-engagement-model.new-fixed-engagement-model .dropdown-wrapper .ae-dropdown .select-menu > li > a:focus, .fixed-engagement-model.new-fixed-engagement-model .dropdown-wrapper .ae-dropdown .select-menu > li > a:hover {
    background: none; }
  .fixed-engagement-model.new-fixed-engagement-model .dropdown-wrapper .ae-disabled {
    pointer-events: none; }
  .fixed-engagement-model.new-fixed-engagement-model .ae-hide {
    display: none; }
  .fixed-engagement-model.new-fixed-engagement-model ul.select-menu {
    list-style-type: none; }
  .fixed-engagement-model.new-fixed-engagement-model ul.select-menu {
    margin: 0px;
    padding: 5px;
    border: 1px solid #ccc; }
  .fixed-engagement-model.new-fixed-engagement-model ul.select-menu li {
    padding: 5px 0px; }
  @media screen and (min-width: 992px) {
    .fixed-engagement-model.new-fixed-engagement-model .shr-rqst-btn a {
      max-width: 370px; } }
  .fixed-engagement-model.new-fixed-engagement-model .hide {
    display: none; }

.fixed-engagement-model.new-fixed-engagement-model.fixed-engagement-model-dev .fixed-engagement-row-left {
  flex: 0 0 35%; }
  .fixed-engagement-model.new-fixed-engagement-model.fixed-engagement-model-dev .fixed-engagement-row-left.fixed-engagement-row-left-new-bg {
    background: #091B3A;
    border-top-right-radius: 10px;
    align-items: center;
    display: flex; }
    .fixed-engagement-model.new-fixed-engagement-model.fixed-engagement-model-dev .fixed-engagement-row-left.fixed-engagement-row-left-new-bg h5 {
      color: #fff; }

.ui-tooltip, .arrow:after {
  background: #fff9cf;
  border: 1px solid #e3d58f; }

.ui-tooltip {
  box-shadow: 0px 10px 27px -9px rgba(0, 0, 0, 0.75) !important;
  background: #fff9cf !important;
  border: 1px solid #e3d58f !important;
  color: #333;
  text-align: left;
  padding: 5px 15px !important;
  border-radius: 6px !important;
  max-width: 260px;
  min-width: 380px;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Montserrat", sans-serif !important; }

.arrow {
  width: 70px;
  height: 16px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  margin-left: -35px;
  bottom: -16px; }

.arrow.top {
  top: -16px;
  bottom: auto; }

.arrow.left {
  left: 20%; }

.arrow:after {
  content: "";
  position: absolute;
  left: 20px;
  top: -20px;
  width: 25px;
  height: 25px;
  box-shadow: 6px 5px 9px -9px black;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.arrow.top:after {
  bottom: -20px;
  top: auto; }

.border-right-radious {
  border-bottom-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-bottomright: 10px; }

/* ANCHOR Django Develoment*/
.django-tech-stack {
  padding: 40px 0 50px; }
  @media screen and (min-width: 992px) {
    .django-tech-stack {
      padding: 70px 0 80px; } }
  .django-tech-stack h2 {
    font-weight: 600; }
    @media screen and (min-width: 992px) {
      .django-tech-stack h2 {
        margin-bottom: 20px; } }
  .django-tech-stack-wrapper ul {
    display: flex;
    flex-wrap: wrap; }
    .django-tech-stack-wrapper ul li {
      text-align: center;
      padding: 0 10px;
      margin-top: 40px; }
      @media screen and (min-width: 992px) {
        .django-tech-stack-wrapper ul li {
          width: 12.5%; } }
      .django-tech-stack-wrapper ul li span {
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
        display: block; }
      .django-tech-stack-wrapper ul li i {
        display: inline-block;
        height: 80px;
        display: flex;
        align-items: center;
        width: 100px;
        margin: 0 auto; }
        @media screen and (min-width: 992px) {
          .django-tech-stack-wrapper ul li i {
            height: 60px;
            width: 80px; } }
        .django-tech-stack-wrapper ul li i img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
        .django-tech-stack-wrapper ul li i.stack-img-mod {
          width: 100px; }
        .django-tech-stack-wrapper ul li i.open-api {
          position: relative;
          left: 5px; }
    .django-tech-stack-wrapper ul .slick-dots {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0; }
      .django-tech-stack-wrapper ul .slick-dots li {
        padding: 0;
        margin-top: 0; }

/*For contact us page*/
/*For contact us page*/
.django-tech-stack-slider .owl-dots {
  margin-bottom: 0; }

.tech-stack-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

/* ANCHOR Mean Stack Develoment*/
.new-tech-mean-stack {
  padding: 40px 0 50px; }
  @media screen and (min-width: 992px) {
    .new-tech-mean-stack {
      padding: 70px 0 80px; } }
  .new-tech-mean-stack h2 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 24px; }
    @media screen and (min-width: 992px) {
      .new-tech-mean-stack h2 {
        margin-bottom: 40px;
        font-weight: 600;
        font-size: 48px; } }
  .new-tech-mean-stack .new-tech-mean-stack-ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .new-tech-mean-stack .new-tech-mean-stack-ul {
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center; } }
    .new-tech-mean-stack .new-tech-mean-stack-ul li {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .new-tech-mean-stack .new-tech-mean-stack-ul li {
          width: 20%; } }
      .new-tech-mean-stack .new-tech-mean-stack-ul li .new-tech-mean-stack-anchor {
        display: block;
        margin: 30px auto 0; }
        @media screen and (min-width: 992px) {
          .new-tech-mean-stack .new-tech-mean-stack-ul li .new-tech-mean-stack-anchor {
            margin: 0 25px; } }

.font-weignt-400 {
  font-weight: 400; }

.blue-bnr-section h2.font-size-resize-new {
  font-size: 26px; }
  @media screen and (min-width: 992px) {
    .blue-bnr-section h2.font-size-resize-new {
      font-size: 30px; } }

.font-weight-500 {
  font-weight: 500;
  margin-bottom: 22px; }

/* ANCHOR dApp Skills*/
.dapp-blockchn.o-key-diff .bord-col-1 {
  border-bottom-color: #4a90e2; }

.dapp-blockchn.o-key-diff .bord-col-2 {
  border-bottom-color: #49c684; }

.dapp-blockchn.o-key-diff .o-key-diff-box {
  border-left: none;
  border-bottom-width: 8px;
  background: #fff; }
  @media screen and (min-width: 992px) {
    .dapp-blockchn.o-key-diff .o-key-diff-box {
      padding: 30px 15px 30px 25px; } }
  .dapp-blockchn.o-key-diff .o-key-diff-box img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); }
  .dapp-blockchn.o-key-diff .o-key-diff-box h4 {
    margin-top: 15px; }
    @media screen and (min-width: 992px) {
      .dapp-blockchn.o-key-diff .o-key-diff-box h4 {
        font-size: 22px; } }

.dapp-blockchn.o-key-diff .col-lg-3 {
  display: flex; }

.dapp-tech-stack .profile-tabs {
  overflow: inherit;
  display: block;
  width: 100%;
  border: none;
  margin-bottom: 0; }
  .dapp-tech-stack .profile-tabs .tab-profile-header {
    width: 100%;
    border-right: none; }
    .dapp-tech-stack .profile-tabs .tab-profile-header ul {
      display: flex; }
      .dapp-tech-stack .profile-tabs .tab-profile-header ul li {
        flex: 1;
        display: flex;
        justify-content: center;
        color: #969696;
        padding: 0px 15px 5px;
        border: none; }
        @media screen and (min-width: 992px) {
          .dapp-tech-stack .profile-tabs .tab-profile-header ul li {
            font-size: 26px; } }
        .dapp-tech-stack .profile-tabs .tab-profile-header ul li.active {
          background: none;
          border-left: none;
          border-left-width: 0;
          color: #333333; }
          .dapp-tech-stack .profile-tabs .tab-profile-header ul li.active span::before {
            height: 5px; }
        .dapp-tech-stack .profile-tabs .tab-profile-header ul li span {
          position: relative;
          padding-bottom: 10px; }
          .dapp-tech-stack .profile-tabs .tab-profile-header ul li span:before {
            content: '';
            position: absolute;
            background: #FF5800;
            height: 0;
            width: 148px;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto; }
        .dapp-tech-stack .profile-tabs .tab-profile-header ul li:hover {
          background: none;
          border-left: none;
          color: #333333;
          border-left-width: 0; }
  .dapp-tech-stack .profile-tabs .tab-profile-content {
    background: none;
    width: 100%; }
    .dapp-tech-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul {
      justify-content: center; }
      .dapp-tech-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul li {
        margin-top: 15px; }
        @media screen and (min-width: 992px) {
          .dapp-tech-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul li {
            width: 20%;
            margin-top: 30px; } }
        .dapp-tech-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul li a {
          background: none;
          padding: 0;
          border: none; }
          .dapp-tech-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul li a:hover {
            background: none;
            border: none;
            box-shadow: none; }
          .dapp-tech-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul li a img {
            height: 100%; }
        .dapp-tech-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul li span {
          display: block;
          margin: 25px auto 0;
          text-align: center;
          font-size: 18px;
          font-weight: 500; }
    .dapp-tech-stack .profile-tabs .tab-profile-content .tab-profile-content-area.active {
      background: none; }
    .dapp-tech-stack .profile-tabs .tab-profile-content div {
      position: inherit; }

/* ANCHOR Hybrid Development*/
@media screen and (min-width: 992px) {
  .hybrid-tech-use.o-key-diff-modify h2 {
    margin-bottom: 50px; } }

.hybrid-tech-use .o-key-diff-box {
  border-left-width: 5px; }
  @media screen and (min-width: 992px) {
    .hybrid-tech-use .o-key-diff-box {
      padding: 35px 50px 35px 35px; } }
  .hybrid-tech-use .o-key-diff-box img {
    margin-bottom: 15px; }

@media screen and (min-width: 992px) {
  .hybrid-dev-process .d-trans-pro-wrapper .d-trans-pro-content-box3,
  .hybrid-dev-process .d-trans-pro-wrapper .d-trans-pro-content-box4,
  .hybrid-dev-process .d-trans-pro-wrapper .d-trans-pro-content-box2 {
    margin-top: 30px; } }

@media screen and (min-width: 992px) {
  .hybrid-dev-process .d-trans-pro-wrapper .d-trans-pro-steps:before {
    height: 80%; } }

/*Flutter development*/
@media screen and (min-width: 992px) {
  .flutter-dev-process .d-trans-pro-wrapper .d-trans-pro-content-box3,
  .flutter-dev-process .d-trans-pro-wrapper .d-trans-pro-content-box4,
  .flutter-dev-process .d-trans-pro-wrapper .d-trans-pro-content-box2 {
    margin-top: 30px; } }

@media screen and (min-width: 992px) {
  .flutter-dev-process .d-trans-pro-wrapper .d-trans-pro-content-box5,
  .flutter-dev-process .d-trans-pro-wrapper .d-trans-pro-content-box6 {
    margin-top: 120px; } }

@media screen and (min-width: 992px) {
  .flutter-dev-process .d-trans-pro-wrapper .d-trans-pro-steps:before {
    height: 90%; } }

@media screen and (min-width: 992px) {
  .sd-process .d-trans-pro-wrapper .d-trans-pro-steps:before {
    height: 86%; } }

/* ANCHOR MVP Development*/
.mvp-texh-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul li a img.icon-small {
  width: 30px; }

.mvp-texh-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul li a img.icon-medium {
  width: 40px; }

.mvp-texh-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul li a img.icon-c-sharp {
  width: 52px; }

.mvp-texh-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul li a img.icon-azure-sql-database {
  width: 52px; }

.mvp-texh-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul.list-wise {
  margin-left: 10px; }
  .mvp-texh-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul.list-wise li {
    width: 100%;
    font-size: 16px;
    position: relative;
    padding: 10px 10px 10px 15px;
    margin: 0;
    color: #333; }
    @media screen and (min-width: 992px) {
      .mvp-texh-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul.list-wise li {
        width: 50%; } }
    .mvp-texh-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul.list-wise li:before {
      content: '';
      position: absolute;
      background: #005eb8;
      height: 8px;
      width: 8px;
      top: 18px;
      left: 0; }
    .mvp-texh-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul.list-wise li a {
      text-decoration: underline;
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      height: auto;
      color: #333; }
      .mvp-texh-stack .profile-tabs .tab-profile-content .tab-profile-content-area ul.list-wise li a:hover {
        box-shadow: none;
        color: #005eb8; }

.mvp-texh-stack .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-wrap h4 {
  padding-left: 10px;
  margin-bottom: 15px;
  font-size: 18px;
  display: none; }
  @media screen and (min-width: 992px) {
    .mvp-texh-stack .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-wrap h4 {
      display: block; } }

.mvp-texh-stack .profile-tabs .tab-profile-content .tab-profile-content-area .tab-profile-content-wrap span {
  padding-left: 10px; }

.mvp-benefit-development .heading-top {
  margin-bottom: 40px; }
  .mvp-benefit-development .heading-top h2 {
    margin-bottom: 15px; }
  .mvp-benefit-development .heading-top p {
    font-size: 19px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .mvp-benefit-development .heading-top p {
        font-size: 22px;
        line-height: 38px; } }

.mvp-key-role .heading-top {
  margin-bottom: 40px; }
  .mvp-key-role .heading-top h2 {
    margin-bottom: 15px;
    padding-bottom: 0; }
  .mvp-key-role .heading-top p {
    font-size: 19px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .mvp-key-role .heading-top p {
        font-size: 22px;
        line-height: 38px; } }

@media screen and (min-width: 992px) {
  .mv-dev-process .d-trans-pro-wrapper .d-trans-pro-content-box5 {
    margin-top: 170px; } }

/* ANCHOR AWS DevOps Develoment*/
.aws-devops-tech-stack {
  padding: 40px 0 50px;
  background-color: #F4F4F4; }
  @media screen and (min-width: 992px) {
    .aws-devops-tech-stack {
      padding: 70px 0 80px;
      background-color: #F4F4F4; } }
  .aws-devops-tech-stack h2 {
    font-weight: 600; }
    @media screen and (min-width: 992px) {
      .aws-devops-tech-stack h2 {
        margin-bottom: 20px; } }
  .aws-devops-tech-stack-wrapper .aws-devops-tech-stack-slider {
    display: flex;
    flex-wrap: wrap; }
    .aws-devops-tech-stack-wrapper .aws-devops-tech-stack-slider .splide_slidemod {
      text-align: center;
      padding: 0 10px;
      margin-top: 40px; }
      @media screen and (min-width: 992px) {
        .aws-devops-tech-stack-wrapper .aws-devops-tech-stack-slider .splide_slidemod {
          width: 25%; } }
      .aws-devops-tech-stack-wrapper .aws-devops-tech-stack-slider .splide_slidemod .aws-devops-container {
        background-color: #ffffff;
        border-radius: 5px;
        padding: 25px 15px;
        margin-bottom: 15px;
        -webkit-box-shadow: 5px 10px 20px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 5px 10px 20px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 5px 10px 20px 0px rgba(0, 0, 0, 0.05); }
        .aws-devops-tech-stack-wrapper .aws-devops-tech-stack-slider .splide_slidemod .aws-devops-container span {
          font-size: 14px;
          font-weight: 500;
          margin-top: 10px;
          display: block; }
        .aws-devops-tech-stack-wrapper .aws-devops-tech-stack-slider .splide_slidemod .aws-devops-container i {
          display: inline-block;
          height: 80px;
          display: flex;
          align-items: center;
          width: 100px;
          margin: 0 auto; }
          @media screen and (min-width: 992px) {
            .aws-devops-tech-stack-wrapper .aws-devops-tech-stack-slider .splide_slidemod .aws-devops-container i {
              height: 85px;
              width: 185px; } }
          .aws-devops-tech-stack-wrapper .aws-devops-tech-stack-slider .splide_slidemod .aws-devops-container i img {
            width: 100%;
            height: 100%;
            object-fit: contain; }
          .aws-devops-tech-stack-wrapper .aws-devops-tech-stack-slider .splide_slidemod .aws-devops-container i.stack-img-mod {
            width: 100px; }
          .aws-devops-tech-stack-wrapper .aws-devops-tech-stack-slider .splide_slidemod .aws-devops-container i.open-api {
            position: relative;
            left: 5px; }
    .aws-devops-tech-stack-wrapper .aws-devops-tech-stack-slider .slick-dots {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0; }
      .aws-devops-tech-stack-wrapper .aws-devops-tech-stack-slider .slick-dots li {
        padding: 0;
        margin-top: 0; }

.aws-devops-tech-stack-slider .owl-dots {
  margin-bottom: 0; }

/* ANCHOR -Engagement Model*/
.engagement-model-page-bnr {
  padding-bottom: 50px; }
  @media screen and (min-width: 992px) {
    .engagement-model-page-bnr {
      padding: 130px 20px 75px !important; } }
  .engagement-model-page-bnr h1 {
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .engagement-model-page-bnr h1 {
        font-size: 55px;
        line-height: 64px;
        margin-bottom: 40px; } }
  .engagement-model-page-bnr .header_right_pan {
    text-align: left;
    padding-top: 20px; }
    @media screen and (min-width: 992px) {
      .engagement-model-page-bnr .header_right_pan {
        padding-top: 40px; } }
    .engagement-model-page-bnr .header_right_pan p {
      color: #E9F4F5;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .engagement-model-page-bnr .header_right_pan p {
          font-size: 24px;
          line-height: 40px;
          text-align: left; } }
    @media screen and (min-width: 992px) {
      .engagement-model-page-bnr .header_right_pan .btn-orange {
        margin: 0; } }
    @media screen and (min-width: 992px) {
      .engagement-model-page-bnr .header_right_pan .anchor-link {
        text-align: left;
        display: block; } }

.page-engagement-specific .heading-top {
  margin-bottom: 45px; }
  .page-engagement-specific .heading-top h2 {
    text-align: center;
    margin-bottom: 20px; }
  .page-engagement-specific .heading-top p {
    font-size: 19px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .page-engagement-specific .heading-top p {
        font-size: 22px; } }

.page-engagement-specific .iot-case-wrapper {
  max-width: 100%; }
  .page-engagement-specific .iot-case-wrapper-box {
    width: 100%;
    text-align: center;
    margin: 0;
    min-height: auto;
    margin-bottom: 30px;
    border-bottom-width: 5px; }
    .page-engagement-specific .iot-case-wrapper-box h3 {
      font-size: 26px;
      font-weight: 600;
      margin-top: 30px; }
    .page-engagement-specific .iot-case-wrapper-box p {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 15px; }
    .page-engagement-specific .iot-case-wrapper-box a {
      font-size: 16px;
      text-align: center;
      border: 1px solid #D2D2D2;
      border-radius: 5px;
      color: #333333;
      display: block;
      margin: 0 auto;
      width: 162px;
      padding: 11px 0;
      font-weight: 500;
      transition: .3s all ease-in-out; }
      .page-engagement-specific .iot-case-wrapper-box a:hover {
        background: #ff5800;
        border-color: #ff5800;
        color: #fff; }

@media screen and (min-width: 992px) {
  .engagement-models .page-engagement-specific .heading-top {
    margin-bottom: 40px; } }

.engagement-models .page-engagement-specific .heading-top h2 {
  margin-bottom: 10px; }
  @media screen and (min-width: 992px) {
    .engagement-models .page-engagement-specific .heading-top h2 {
      font-size: 48px;
      line-height: 68px;
      font-weight: 600; } }

.engagement-models .page-engagement-specific .iot-case-wrapper-box {
  border-bottom-width: 5px;
  display: block; }
  .engagement-models .page-engagement-specific .iot-case-wrapper-box h3 {
    margin-left: 0;
    margin-top: 20px;
    margin-bottom: 10px; }
    @media screen and (min-width: 992px) {
      .engagement-models .page-engagement-specific .iot-case-wrapper-box h3 {
        font-size: 26px;
        line-height: 36px;
        font-weight: 600; } }
  .engagement-models .page-engagement-specific .iot-case-wrapper-box p {
    margin-bottom: 15px;
    display: block; }
    @media screen and (min-width: 992px) {
      .engagement-models .page-engagement-specific .iot-case-wrapper-box p {
        font-size: 18px;
        line-height: 28px; } }

.message-section {
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .message-section {
      padding: 80px 0; } }
  @media screen and (min-width: 992px) {
    .message-section__wrapper {
      display: flex;
      flex-wrap: wrap;
      max-width: 970px;
      margin: 0 auto; } }
  .message-section__wrapper h2 {
    text-align: center;
    margin-bottom: 40px; }
    @media screen and (min-width: 992px) {
      .message-section__wrapper h2 {
        font-size: 30px;
        line-height: 48px;
        font-weight: 500;
        flex: 100%; } }
  .message-section__left {
    flex: 173px;
    max-width: 173px;
    margin: 0 auto; }
    @media screen and (min-width: 992px) {
      .message-section__left {
        margin-right: 30px; } }
  .message-section__img {
    width: 100%;
    height: 173px;
    overflow: hidden;
    border-radius: 100%;
    border: 2px solid #B1B1B1; }
  .message-section__information {
    flex: 1;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .message-section__information {
        text-align: left; } }
    .message-section__information h3 {
      font-size: 17px;
      line-height: 23px;
      font-weight: 500;
      margin-bottom: 15px;
      margin-top: 15px; }
      @media screen and (min-width: 992px) {
        .message-section__information h3 {
          font-size: 19px;
          line-height: 20px;
          margin-bottom: 20px;
          margin-top: 0; } }
    .message-section__information h4 {
      font-size: 24px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 15px; }
    .message-section__information h5 {
      font-size: 19px;
      line-height: 24px;
      font-weight: 600; }
    .message-section__information h6 {
      display: block;
      font-size: 15px;
      line-height: 16px;
      font-weight: 500; }

.engagement-why {
  background: linear-gradient(150deg, #051b39 0, #023e66 45%, #00517f 64%, #038070 100%) !important; }
  .engagement-why .owl-stage {
    display: flex; }
    .engagement-why .owl-stage .owl-item {
      display: flex; }
  @media screen and (min-width: 992px) {
    .engagement-why .details {
      margin-left: 15px;
      width: inherit;
      flex: 1; } }
  @media screen and (min-width: 992px) {
    .engagement-why .details h4 {
      font-size: 22px;
      line-height: 32px; } }
  @media screen and (min-width: 992px) {
    .engagement-why .details p {
      font-size: 16px;
      line-height: 27px; } }

/* ANCHOR -Project Based*/
@media screen and (min-width: 992px) {
  .project-based-banner {
    padding-bottom: 0 !important; } }

.project-based-banner .paroject-based-banner-graphic {
  text-align: center; }
  @media screen and (min-width: 992px) {
    .project-based-banner .paroject-based-banner-graphic {
      position: absolute;
      bottom: 0;
      right: 0; } }
  .project-based-banner .paroject-based-banner-graphic img {
    width: 90%; }

@media screen and (min-width: 992px) {
  .project-based-banner .paroject-based-banner-graphic-mod {
    position: absolute;
    bottom: 0;
    right: inherit;
    left: -65px; } }

.project-based-banner .paroject-based-banner-graphic-mod img {
  width: 100%; }

.project-based-banner h1 {
  margin-top: 15px; }
  @media screen and (min-width: 992px) {
    .project-based-banner h1 {
      font-size: 32px;
      line-height: 44px;
      margin-top: 0; } }
  @media screen and (min-width: 1089px) {
    .project-based-banner h1 {
      font-size: 48px;
      line-height: 60px;
      margin-bottom: 10px; } }

.project-based-banner p {
  font-weight: 500;
  font-size: 16px; }
  @media screen and (min-width: 992px) {
    .project-based-banner p {
      font-size: 20px; } }

@media screen and (min-width: 992px) {
  .project-based-banner .project-based-banner-right-p {
    padding-right: 170px; } }

.project-based-banner .btn-orange {
  margin: 0 auto; }
  @media screen and (min-width: 992px) {
    .project-based-banner .btn-orange {
      margin: 0; } }

.project-based-banner .anchor-link {
  margin-bottom: 35px; }
  @media screen and (min-width: 992px) {
    .project-based-banner .anchor-link {
      margin-bottom: 70px; } }

.project-based-banner .header_left_pan p {
  display: block; }

.project-based-banner .row {
  flex-direction: column-reverse; }
  @media screen and (min-width: 992px) {
    .project-based-banner .row {
      flex-direction: row; } }

.project-tailored .heading-top {
  margin-bottom: 35px; }
  .project-tailored .heading-top h2 {
    text-align: center;
    margin-bottom: 20px; }
  .project-tailored .heading-top p {
    font-size: 20px;
    text-align: center;
    line-height: 36px; }

@media screen and (min-width: 992px) {
  .project-tailored .tailored-box {
    padding: 0 60px; } }

.project-tailored .tailored-box-gray {
  border-radius: 15px;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media screen and (min-width: 992px) {
    .project-tailored .tailored-box-gray {
      margin: 70px 0;
      background: #F7F7F7; } }
  @media screen and (min-width: 992px) {
    .project-tailored .tailored-box-gray .row {
      flex-direction: row-reverse; } }

@media screen and (min-width: 992px) {
  .project-tailored .tailored-box-sequence1 {
    margin-top: 0; } }

@media screen and (min-width: 992px) {
  .project-tailored .tailored-box-sequence1 .row {
    flex-direction: row; } }

@media screen and (min-width: 992px) {
  .project-tailored .tailored-box-sequence2 .row {
    flex-direction: row-reverse; } }

.project-tailored .tailored-box-text h4 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px; }
  @media screen and (min-width: 992px) {
    .project-tailored .tailored-box-text h4 {
      margin-top: 0; } }

.project-tailored .tailored-box-text p {
  font-size: 16px;
  line-height: 27px;
  color: #333; }

.project-tailored .tailored-box-text h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px; }

.project-tailored .tailored-box-text ul li {
  font-size: 16px;
  line-height: 25px;
  color: #333;
  position: relative;
  padding-left: 30px;
  padding-bottom: 15px; }
  .project-tailored .tailored-box-text ul li:before {
    content: '';
    position: absolute;
    background: url(/images/project-based/circle-tick.svg) no-repeat 0 0;
    top: 4px;
    left: 0;
    width: 18px;
    height: 18px; }
  .project-tailored .tailored-box-text ul li:last-child {
    padding-bottom: 0; }

.project-tailored .tailored-box .row {
  align-items: center; }
  .project-tailored .tailored-box .row img {
    margin: 0 auto;
    display: block; }
    @media screen and (min-width: 992px) {
      .project-tailored .tailored-box .row img {
        margin: 0;
        display: inline-block; } }

.pros-cons {
  padding: 40px 0 50px; }
  @media screen and (min-width: 992px) {
    .pros-cons {
      padding: 75px 0 80px; } }
  .pros-cons .heading-top {
    margin-bottom: 40px; }
    .pros-cons .heading-top h2 {
      text-align: center;
      margin-bottom: 20px; }
    .pros-cons .heading-top p {
      font-size: 20px;
      text-align: center;
      line-height: 36px; }
  .pros-cons-slab {
    border-radius: 5px;
    background: #fff;
    margin-top: 30px;
    padding: 10px 30px 30px; }
    @media screen and (min-width: 992px) {
      .pros-cons-slab {
        display: flex; } }
    @media screen and (min-width: 992px) {
      .pros-cons-slab {
        padding: 30px; } }
    .pros-cons-slab-box {
      flex: 1;
      border-width: 1px;
      border-style: solid;
      border-color: #E3E3E3;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1); }
      .pros-cons-slab-box h6 {
        color: #333333;
        font-size: 18px;
        font-weight: 700;
        position: relative;
        padding-bottom: 5px;
        margin-bottom: 15px; }
        .pros-cons-slab-box h6:before {
          content: '';
          position: absolute;
          background: #333;
          bottom: 0;
          left: 0;
          width: 25px;
          height: 2px; }
      .pros-cons-slab-box li {
        font-size: 16px;
        position: relative;
        padding-left: 10px;
        padding-bottom: 10px; }
        .pros-cons-slab-box li:before {
          content: '•';
          position: absolute;
          top: 0;
          left: 0; }
      .pros-cons-slab-box-title {
        width: 100%;
        border: none;
        box-shadow: none;
        text-align: center; }
        @media screen and (min-width: 992px) {
          .pros-cons-slab-box-title {
            width: 200px;
            flex: 0 0 200px;
            text-align: left;
            padding-top: 40px; } }
        .pros-cons-slab-box-title h4 {
          margin-top: 15px;
          text-align: center; }
          @media screen and (min-width: 992px) {
            .pros-cons-slab-box-title h4 {
              text-align: left; } }
      .pros-cons-slab-box-gray {
        margin: 25px 0;
        background: #F7F7F7;
        border-color: #C7C7C7;
        box-shadow: none; }
        @media screen and (min-width: 992px) {
          .pros-cons-slab-box-gray {
            margin: 0 30px; } }
        .pros-cons-slab-box-gray li:before {
          content: '-'; }
      .pros-cons-slab-box-green {
        background: #30B058;
        border-color: #30B058;
        color: #fff;
        box-shadow: none; }
        .pros-cons-slab-box-green h6 {
          color: #fff; }
          .pros-cons-slab-box-green h6:before {
            background: #fff; }
        .pros-cons-slab-box-green li:before {
          content: '+'; }

.fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover {
  background: #fff; }
  .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .heading-top p {
    color: #333; }
  @media screen and (min-width: 992px) {
    .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement {
      max-width: 870px; } }
  .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-left {
    background: #091B3A; }
    @media screen and (min-width: 992px) {
      .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-left h5 {
        font-size: 20px; } }
  .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-left-head {
    background: #E8EAED;
    display: flex;
    align-items: center; }
    @media screen and (min-width: 992px) {
      .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-left-head {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px; } }
    .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-left-head h5 {
      color: #333;
      font-weight: 600; }
  .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-top .fixed-engagement-right h5 {
    color: #333; }
    @media screen and (min-width: 992px) {
      .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-top .fixed-engagement-right h5 {
        font-size: 20px; } }
    .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-top .fixed-engagement-right h5 small {
      font-size: 14px;
      line-height: 24px; }
  .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-top .fixed-engagement-right .fixed-engagement-col {
    background: #71E19D;
    min-height: 65px; }
    @media screen and (min-width: 992px) {
      .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-top .fixed-engagement-right .fixed-engagement-col {
        min-height: 85px; } }
    .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-top .fixed-engagement-right .fixed-engagement-col ul li {
      font-size: 18px;
      line-height: 28px;
      font-weight: 500; }
  .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col {
    border-left: 1px solid #DADBDE;
    border-right: 1px solid #DADBDE; }
    .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col ul {
      padding: 27px 30px 27px 20px; }
      .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col ul li {
        text-align: left;
        padding: 3px 0 3px 12px;
        position: relative;
        font-weight: 500; }
        @media screen and (min-width: 992px) {
          .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col ul li {
            font-size: 18px;
            line-height: 28px; } }
        .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col ul li:before {
          content: '';
          background: url(/images/dedicated-team-based/right-arrow.svg) no-repeat 0 0;
          width: 7px;
          height: 11px;
          position: absolute;
          top: 11px;
          left: 0; }
    .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col-ul {
      align-items: flex-start; }
  .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .col-content-box-top-ul ul {
    padding-left: 5px; }
    .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .col-content-box-top-ul ul li {
      text-align: left;
      padding: 3px 0 3px 12px;
      position: relative;
      font-size: 13px;
      line-height: 21px;
      font-weight: 500; }
      .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .col-content-box-top-ul ul li:before {
        content: '';
        background: url(/images/dedicated-team-based/right-arrow.svg) no-repeat 0 0;
        width: 7px;
        height: 11px;
        position: absolute;
        top: 11px;
        left: 0; }
  .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .click-block {
    pointer-events: none; }
    .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .click-block .col-content-box-top-ul h5:before {
      display: none; }

.new-engagement-model-page {
  background: url(/images/engagement-model/new-graphic/engagement-new-graphic.jpg) no-repeat 0 0/cover;
  position: relative; }
  .new-engagement-model-page__left {
    position: relative;
    z-index: 99; }
  @media screen and (min-width: 992px) {
    .new-engagement-model-page {
      padding: 130px 20px 45px !important; } }
  @media screen and (min-width: 992px) {
    .new-engagement-model-page h1 {
      font-size: 45px;
      margin-bottom: 30px; } }
  .new-engagement-model-page .new-btn {
    max-width: 300px; }

.circle-with-hand {
  text-align: center; }
  @media screen and (min-width: 992px) {
    .circle-with-hand {
      position: absolute;
      top: -45px;
      text-align: right;
      right: -50px; } }
  .circle-with-hand img {
    max-width: 90%; }

@media screen and (min-width: 992px) {
  .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .heading-top-compare-model {
    margin-bottom: 45px; } }

.fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .new-compare-engagement {
  background: #F6F6F6;
  border: 1px solid #E3DFDF;
  border-radius: 10px; }
  @media screen and (min-width: 992px) {
    .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .new-compare-engagement {
      max-width: 100%;
      padding: 30px; } }

.fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-left {
  margin-right: 0;
  background: #1D3D70;
  flex: 0 0 23%;
  align-items: center; }
  .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-left h5 {
    font-size: 17px;
    line-height: 27px;
    margin-bottom: 0;
    position: relative;
    padding-left: 25px; }
    .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-left h5::before {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      background: url(/images/engagement-model/new-graphic/white-tick.svg) no-repeat center center;
      width: 20px;
      height: 20px; }

.fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-left-head {
  margin-right: 0;
  background: none;
  padding-left: 0; }
  .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-left-head h5 {
    font-size: 28px;
    line-height: 41px;
    font-weight: 700 !important;
    color: #1D3D70;
    position: relative;
    padding-left: 0; }
    @media screen and (min-width: 992px) {
      .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row-left-head h5::before {
        content: '';
        position: absolute;
        top: inherit;
        bottom: -20px;
        left: 0;
        background: #3CC065;
        width: 91px;
        height: 4px;
        border-radius: 20px; } }

.fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right {
  border-top: 1px solid rgba(29, 61, 112, 0.3);
  border-left: 1px solid rgba(29, 61, 112, 0.3);
  border-right: 1px solid rgba(29, 61, 112, 0.3); }
  .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col {
    margin: 0;
    padding: 25px 25px;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #fff;
    display: block;
    text-align: left; }
    .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col i {
      display: flex;
      height: 50px;
      align-items: center; }
    .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col h5 {
      font-size: 21px;
      line-height: 27px;
      font-weight: 700 !important;
      text-align: left; }
    .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col p {
      font-size: 16px;
      line-height: 27px;
      font-weight: 600; }
    .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col span {
      padding: 0;
      font-size: 16px;
      line-height: 27px;
      font-weight: 500; }
    .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col-border-right {
      border-right: 1px solid rgba(29, 61, 112, 0.3); }
  .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col-right-head {
    padding: 10px 25px 5px; }
    .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col-right-head .box-head-bar {
      position: relative; }
      .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col-right-head .box-head-bar::before {
        content: '';
        position: absolute;
        top: -31px;
        left: 0;
        width: 100%;
        height: 12px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        z-index: 99; }
      .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col-right-head .box-head-bar-green::before {
        background: #3CC065; }
      .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col-right-head .box-head-bar-blue::before {
        background: #4B699B; }
      .fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col-right-head .box-head-bar:after {
        content: '';
        position: absolute;
        top: -30px;
        right: -4px;
        background: url(/images/engagement-model/new-graphic/triangle.png) no-repeat center center;
        width: 6px;
        height: 6px;
        background-size: 100%; }

.fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row:first-child .fixed-engagement-right {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding-top: 15px;
  background: #fff; }

.fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row:last-child .fixed-engagement-right .fixed-engagement-col {
  border-bottom: 1px solid rgba(29, 61, 112, 0.3); }

.fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row:last-child .fixed-engagement-row-left {
  border-bottom-left-radius: 15px; }

.fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover .fixed-engagement-row:nth-child(2) .fixed-engagement-row-left {
  border-top-left-radius: 15px; }

.fixed-engagement-model.new-fixed-engagement-model .m-compare-model .fixed-engagement-row .fixed-engagement-right .col-content-box-top h5 {
  flex: 0 0 100%; }

.fixed-engagement-model.new-fixed-engagement-model .m-compare-model .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col {
  padding: 10px 25px 25px; }
  .fixed-engagement-model.new-fixed-engagement-model .m-compare-model .fixed-engagement-row .fixed-engagement-right .fixed-engagement-col p {
    margin-bottom: 0; }

/* ANCHOR -MWC*/
.mwc-banner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: left;
  padding-bottom: 50px !important; }
  @media screen and (min-width: 992px) {
    .mwc-banner.o-skills-hero {
      padding-bottom: 60px !important;
      padding-top: 115px !important; } }
  .mwc-banner .header_left_pan {
    text-align: left; }
    @media screen and (min-width: 992px) {
      .mwc-banner .header_left_pan {
        text-align: left; } }
    .mwc-banner .header_left_pan .mwc-logo {
      width: 133px; }
      @media screen and (min-width: 992px) {
        .mwc-banner .header_left_pan .mwc-logo {
          width: auto; } }
    .mwc-banner .header_left_pan p {
      display: block !important; }
  .mwc-banner h1 {
    font-size: 24px;
    line-height: 34px;
    margin-top: 15px;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .mwc-banner h1 {
        font-size: 42px;
        line-height: 52px;
        margin-top: 20px;
        margin-bottom: 15px; } }
    .mwc-banner h1 .spcl-text {
      color: #36E079; }
      @media screen and (min-width: 992px) {
        .mwc-banner h1 .spcl-text {
          color: #3CF0A9;
          display: block; } }
  .mwc-banner p {
    font-size: 18px;
    line-height: 26px; }
    @media screen and (min-width: 992px) {
      .mwc-banner p {
        font-size: 22px;
        line-height: 33px;
        font-weight: 400;
        padding-right: 30px; } }
  .mwc-banner .banner-label-shape {
    position: relative;
    margin-bottom: 20px;
    pointer-events: none; }
    @media screen and (min-width: 992px) {
      .mwc-banner .banner-label-shape {
        margin: 35px 0 25px; } }
    .mwc-banner .banner-label-shape-text {
      position: absolute;
      top: 13px;
      left: 0;
      padding-left: 15px; }
      @media screen and (min-width: 360px) {
        .mwc-banner .banner-label-shape-text {
          top: 11px; } }
      @media screen and (min-width: 992px) {
        .mwc-banner .banner-label-shape-text {
          padding-left: 20px; } }
      .mwc-banner .banner-label-shape-text span {
        font-size: 19px;
        font-weight: 700; }
        @media screen and (min-width: 360px) {
          .mwc-banner .banner-label-shape-text span {
            font-size: 22px; } }
        @media screen and (min-width: 992px) {
          .mwc-banner .banner-label-shape-text span {
            font-size: 24px; } }
        .mwc-banner .banner-label-shape-text span.white {
          display: block;
          padding-bottom: 23px; }
          @media screen and (min-width: 360px) {
            .mwc-banner .banner-label-shape-text span.white {
              padding-bottom: 20px; } }
          @media screen and (min-width: 992px) {
            .mwc-banner .banner-label-shape-text span.white {
              margin-right: 50px;
              display: inline;
              padding-bottom: 0; } }
  @media screen and (min-width: 992px) {
    .mwc-banner .btn-orange {
      margin: 0; } }

.page-engagement-specific {
  padding: 50px 0 20px; }
  @media screen and (min-width: 992px) {
    .page-engagement-specific {
      padding: 70px 0 70px; } }
  .page-engagement-specific .heading-top {
    margin-bottom: 30px; }
    .page-engagement-specific .heading-top h2 {
      font-size: 24px;
      line-height: 35px; }
      @media screen and (min-width: 992px) {
        .page-engagement-specific .heading-top h2 {
          font-size: 36px;
          line-height: 50px;
          font-weight: 700;
          margin-bottom: 40px; } }
      .page-engagement-specific .heading-top h2.not-convinced-h2 {
        margin-bottom: 5px; }
    .page-engagement-specific .heading-top p {
      font-size: 22px;
      line-height: 30px; }
    @media screen and (min-width: 992px) {
      .page-engagement-specific .heading-top {
        margin-bottom: 4px; } }
  .page-engagement-specific .iot-case-wrapper-box {
    text-align: left;
    display: flex;
    align-items: center;
    border-bottom-width: 1px; }
    @media screen and (min-width: 992px) {
      .page-engagement-specific .iot-case-wrapper-box {
        padding: 35px 30px 30px 30px;
        display: block; } }
    .page-engagement-specific .iot-case-wrapper-box i {
      display: block;
      width: 58px; }
      @media screen and (min-width: 992px) {
        .page-engagement-specific .iot-case-wrapper-box i {
          height: 60px; } }
    .page-engagement-specific .iot-case-wrapper-box h3 {
      font-size: 20px;
      margin-top: 0;
      margin-left: 20px;
      margin-bottom: 0; }
      @media screen and (min-width: 992px) {
        .page-engagement-specific .iot-case-wrapper-box h3 {
          font-size: 24px;
          margin-top: 20px;
          margin-left: 0;
          margin-bottom: 10px;
          font-weight: 700; } }
    .page-engagement-specific .iot-case-wrapper-box p {
      display: none; }
      @media screen and (min-width: 992px) {
        .page-engagement-specific .iot-case-wrapper-box p {
          font-size: 16px;
          line-height: 27px;
          margin-bottom: 0;
          display: block; } }

.mid-banner-sec1 {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 50px 0; }
  @media screen and (min-width: 992px) {
    .mid-banner-sec1 {
      padding: 80px 0; } }
  .mid-banner-sec1 h2 {
    text-align: center;
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .mid-banner-sec1 h2 {
        font-size: 36px;
        line-height: 44px; } }
    .mid-banner-sec1 h2 span {
      color: #3CF0A9; }
  .mid-banner-sec1 p {
    text-align: center;
    font-size: 18px;
    line-height: 27px;
    color: #fff;
    margin-top: 15px; }
    @media screen and (min-width: 992px) {
      .mid-banner-sec1 p {
        margin: 30px 0 40px;
        font-size: 22px;
        line-height: 30px; } }

.not-convinced .heading-top {
  margin-bottom: 15px; }
  @media screen and (min-width: 992px) {
    .not-convinced .heading-top {
      margin-bottom: 45px; } }
  .not-convinced .heading-top p {
    font-size: 18px;
    line-height: 27px;
    margin-top: 15px; }
    @media screen and (min-width: 992px) {
      .not-convinced .heading-top p {
        font-size: 22px;
        line-height: 30px;
        margin-top: 0;
        margin-bottom: 25px; } }

.not-convinced .iot-case-wrapper-box {
  position: relative;
  border: 1px solid #D2D2D2;
  display: block;
  padding: 30px 20px 30px 30px; }
  @media screen and (min-width: 992px) {
    .not-convinced .iot-case-wrapper-box {
      padding: 30px 25px 30px 25px; } }
  .not-convinced .iot-case-wrapper-box h3 {
    margin-left: 0;
    margin-bottom: 10px;
    word-break: break-word;
    font-weight: 700; }
    @media screen and (min-width: 992px) {
      .not-convinced .iot-case-wrapper-box h3 {
        font-size: 22px;
        line-height: 27px;
        margin-top: 0; } }
  .not-convinced .iot-case-wrapper-box p {
    display: block;
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .not-convinced .iot-case-wrapper-box p {
        font-size: 14px;
        line-height: 23px; } }
  .not-convinced .iot-case-wrapper-box .non-btn {
    background: none;
    font-size: 14px;
    padding: 0;
    width: auto;
    display: inline;
    color: #4F8DE9;
    border: none;
    position: relative;
    margin-top: 5px;
    display: inline-block; }
    .not-convinced .iot-case-wrapper-box .non-btn:hover {
      text-decoration: underline;
      background: none;
      border: none;
      color: #2270e3; }
    .not-convinced .iot-case-wrapper-box .non-btn:before {
      content: '>>';
      position: absolute;
      top: 0;
      right: -20px; }
  .not-convinced .iot-case-wrapper-box .numbr {
    position: absolute;
    top: -25px;
    left: 25px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: #fff;
    font-weight: 600;
    background: #4F8DE9; }
    @media screen and (min-width: 992px) {
      .not-convinced .iot-case-wrapper-box .numbr {
        left: 25px; } }

.mid-banner-sec2 {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  @media screen and (min-width: 992px) {
    .mid-banner-sec2 {
      padding: 90px 0 100px; } }
  .mid-banner-sec2 h2 {
    font-size: 24px;
    line-height: 32px; }
    @media screen and (min-width: 992px) {
      .mid-banner-sec2 h2 {
        font-size: 36px;
        line-height: 50px; } }
  .mid-banner-sec2 p {
    font-size: 18px;
    line-height: 27px; }
    @media screen and (min-width: 992px) {
      .mid-banner-sec2 p {
        line-height: 28px; } }

.u-date {
  background: url(/images/common/date-icon.svg) no-repeat 0 50%, url(/images/common/bottom-arrow.svg) no-repeat 100% 50%;
  appearance: none;
  color: #3B3B3B; }

.mwc-o-bottom-form .form-left {
  padding: 30px 30px 10px; }
  @media screen and (min-width: 992px) {
    .mwc-o-bottom-form .form-left {
      padding: 70px 60px 46px; } }
  .mwc-o-bottom-form .form-left h3 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 32px; }
    @media screen and (min-width: 992px) {
      .mwc-o-bottom-form .form-left h3 {
        font-size: 36px;
        line-height: 46px; } }
  .mwc-o-bottom-form .form-left p {
    font-weight: 500; }
    @media screen and (min-width: 992px) {
      .mwc-o-bottom-form .form-left p {
        font-size: 16px;
        line-height: 25px; } }

.mwc-o-bottom-form .btwn-line {
  margin: 40px 0;
  width: 100%;
  height: 1px;
  background: #515151; }

.mwc-o-bottom-form .common-input::placeholder {
  color: #3B3B3B;
  opacity: 1;
  font-weight: 400; }

.mwc .top-addvertisement {
  display: none; }

.mwc .o-navigation-bar-new #primaryNav {
  top: 50px; }
  @media screen and (min-width: 992px) {
    .mwc .o-navigation-bar-new #primaryNav {
      top: 0; } }

/* ANCHOR -Easter Offering*/
.easter-offering-banner {
  background: #D9EDE3;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 50px !important;
  position: relative; }
  .easter-offering-banner::before {
    content: '';
    position: absolute;
    background: url(/images/easter-offering/banner-hanging-egg.png) no-repeat 0 0;
    width: 370px;
    height: 230px;
    top: 65px;
    right: 0px;
    z-index: 99;
    background-size: contain;
    display: none; }
    @media screen and (min-width: 992px) {
      .easter-offering-banner::before {
        display: block; } }
  .easter-offering-banner h1 {
    color: #000;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .easter-offering-banner h1 {
        font-size: 45px;
        line-height: 52px;
        margin-bottom: 20px;
        padding-top: 30px;
        text-align: left; } }
  .easter-offering-banner .project-based-banner-right-p {
    color: #000;
    text-align: center;
    font-size: 16px; }
    @media screen and (min-width: 992px) {
      .easter-offering-banner .project-based-banner-right-p {
        font-size: 24px;
        line-height: 35px;
        text-align: left; } }
    .easter-offering-banner .project-based-banner-right-p .under-line {
      text-decoration: underline; }
  @media screen and (min-width: 992px) {
    .easter-offering-banner .new-btn {
      margin: 0;
      max-width: 300px; } }
  @media screen and (min-width: 992px) {
    .easter-offering-banner .paroject-based-banner-graphic-mod {
      padding-top: 90px; } }
  .easter-offering-banner .cstm-link-p {
    text-align: center; }
    @media screen and (min-width: 992px) {
      .easter-offering-banner .cstm-link-p {
        text-align: left; } }
  .easter-offering-banner-pt::before {
    content: '';
    position: absolute;
    background: url(/images/easter-offering/bg-pattern-lft.png) no-repeat 0 0;
    width: 222px;
    height: 55px;
    bottom: 0;
    left: 0;
    background-size: 40%; }
    @media screen and (min-width: 992px) {
      .easter-offering-banner-pt::before {
        height: 140px;
        background-size: 100%; } }
  .easter-offering-banner-pt::after {
    content: '';
    position: absolute;
    background: url(/images/easter-offering/bg-pattern-rt.png) no-repeat 0 0;
    width: 105px;
    height: 216px;
    bottom: 0;
    right: 0;
    display: none; }
    @media screen and (min-width: 992px) {
      .easter-offering-banner-pt::after {
        display: block; } }

.bottom-terms-text {
  background: #28345A;
  padding: 13px 0; }
  .bottom-terms-text p {
    color: #fff;
    margin-bottom: 0;
    font-size: 16px; }
    .bottom-terms-text p .light-text {
      font-weight: 300; }
    .bottom-terms-text p img {
      margin-right: 5px; }

.egstra-support {
  padding: 40px 0 15px; }
  @media screen and (min-width: 992px) {
    .egstra-support {
      padding: 65px 0 50px; } }
  .egstra-support h2 {
    font-weight: 600; }
    @media screen and (min-width: 992px) {
      .egstra-support h2 {
        font-size: 36px;
        line-height: 45px; } }
  @media screen and (min-width: 992px) {
    .egstra-support-top {
      font-size: 30px;
      line-height: 50px;
      font-weight: 500; } }
  .egstra-support-blue {
    color: #4A90E2; }
  @media screen and (min-width: 992px) {
    .egstra-support p {
      font-size: 22px;
      line-height: 35px;
      margin-top: 25px; } }
  .egstra-support .cstm-link-p {
    margin-top: 0; }

.easter-why-choose {
  padding: 40px 0 20px; }
  @media screen and (min-width: 992px) {
    .easter-why-choose {
      padding: 70px 0 70px; } }
  @media screen and (min-width: 992px) {
    .easter-why-choose .heading-top .cmn-heading-para {
      max-width: 960px;
      margin: 0 auto;
      font-size: 22px;
      line-height: 35px; } }
  .easter-why-choose h3 {
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .easter-why-choose h3 {
        font-size: 24px;
        line-height: 50px;
        font-weight: 700;
        margin-bottom: 25px; } }
  .easter-why-choose .o-key-diff-box {
    border-left-width: 5px;
    background: #fff; }
    @media screen and (min-width: 992px) {
      .easter-why-choose .o-key-diff-box {
        padding: 30px 25px; } }
    .easter-why-choose .o-key-diff-box h4 {
      margin-top: 15px; }
      @media screen and (min-width: 992px) {
        .easter-why-choose .o-key-diff-box h4 {
          font-size: 22px;
          font-weight: 700;
          margin-top: 25px; } }
  .easter-why-choose .ml-cases-img i {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: flex-end; }
  .easter-why-choose .cmn-heading-para {
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .easter-why-choose .cmn-heading-para {
        margin-bottom: 20px; } }

.easter-how-does-work {
  background: #28345A;
  padding: 40px 0 20px; }
  @media screen and (min-width: 992px) {
    .easter-how-does-work {
      padding: 75px 0 45px; } }
  .easter-how-does-work p {
    color: #fff; }
    @media screen and (min-width: 992px) {
      .easter-how-does-work p {
        font-size: 22px;
        line-height: 35px;
        padding-left: 50px; } }
  .easter-how-does-work .btn_wrapper {
    margin: 0;
    margin-top: 25px; }
    @media screen and (min-width: 992px) {
      .easter-how-does-work .btn_wrapper {
        padding-left: 50px; } }
    .easter-how-does-work .btn_wrapper a {
      margin: 0; }
  .easter-how-does-work .cstm-link-p {
    text-align: left; }
  .easter-how-does-work .bordered-box {
    position: relative; }
    @media screen and (min-width: 992px) {
      .easter-how-does-work .bordered-box {
        padding: 70px 40px 0;
        height: 100%; } }
    .easter-how-does-work .bordered-box::before {
      content: '';
      position: absolute;
      border: 6px solid #67BC93;
      top: 0;
      left: 0;
      display: none; }
      @media screen and (min-width: 992px) {
        .easter-how-does-work .bordered-box::before {
          width: 451px;
          height: 369px; } }
      @media screen and (min-width: 992px) {
        .easter-how-does-work .bordered-box::before {
          display: block; } }
    .easter-how-does-work .bordered-box:after {
      content: '';
      position: absolute;
      background: url(/images/easter-offering/mid-egg-img.png) no-repeat 0 0;
      width: 280px;
      height: 350px;
      bottom: 45px;
      right: 30px;
      display: none; }
      @media screen and (min-width: 992px) {
        .easter-how-does-work .bordered-box:after {
          display: block; } }
    .easter-how-does-work .bordered-box h2 {
      font-weight: 500; }
      @media screen and (min-width: 992px) {
        .easter-how-does-work .bordered-box h2 {
          font-size: 36px;
          line-height: 50px;
          margin-bottom: 0; } }

.easter-bottom-form-section {
  background: #AFD0C1; }
  .easter-bottom-form-section h3 {
    font-weight: 700;
    position: relative; }
    .easter-bottom-form-section h3::before {
      content: '';
      background: #2B3446;
      height: 2px;
      width: 90%;
      position: absolute;
      bottom: 25px;
      left: 0;
      display: none; }
      @media screen and (min-width: 992px) {
        .easter-bottom-form-section h3::before {
          display: block; } }
    @media screen and (min-width: 992px) {
      .easter-bottom-form-section h3 {
        font-size: 36px;
        line-height: 46px;
        padding-bottom: 60px; } }
    .easter-bottom-form-section h3 span {
      color: #FFE175; }
  .easter-bottom-form-section .form-left {
    position: relative; }
    @media screen and (min-width: 992px) {
      .easter-bottom-form-section .form-left {
        padding: 70px 45px 46px; } }
    .easter-bottom-form-section .form-left:before {
      content: '';
      position: absolute;
      width: 206px;
      height: 151px;
      background: url(/images/easter-offering/form-egg-img.png) no-repeat 0 0;
      bottom: 0;
      left: 30px;
      height: 50px;
      background-size: 33%; }
      @media screen and (min-width: 992px) {
        .easter-bottom-form-section .form-left:before {
          height: 150px;
          background-size: 100%; } }

.white-bnr-support-setcion {
  background-color: #F0F3F8; }
  .white-bnr-support-setcion h2 {
    font-weight: 500; }
    @media screen and (min-width: 992px) {
      .white-bnr-support-setcion h2 {
        font-size: 30px;
        line-height: 37px; } }
    .white-bnr-support-setcion h2 .text-blue {
      color: #4A90E2; }
  .white-bnr-support-setcion p {
    max-width: 100%; }
    @media screen and (min-width: 992px) {
      .white-bnr-support-setcion p {
        font-size: 22px;
        line-height: 32px;
        margin-top: 20px; } }
    .white-bnr-support-setcion p small {
      font-size: 16px; }
  .white-bnr-support-setcion .cstm-link-p {
    margin-top: 0; }

.easter-offering .top-addvertisement {
  display: none; }

.easter-offering .o-skills-hero {
  padding-top: 102px; }
  @media screen and (min-width: 992px) {
    .easter-offering .o-skills-hero {
      padding: 115px 20px 45px !important; } }

/* ANCHOR -Offshore web development*/
@media screen and (min-width: 992px) {
  .choose-offshore h2 {
    margin-bottom: 30px; } }

.choose-offshore .col-lg-3 {
  margin-bottom: 30px; }

.choose-offshore .o-key-diff-box {
  border: none;
  margin: 20px 0 0 0;
  position: relative; }
  .choose-offshore .o-key-diff-box .choose-num {
    display: inline-block;
    left: 25px;
    top: -30px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #4F8DE9;
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .choose-offshore .o-key-diff-box .choose-num {
        position: absolute; } }
  .choose-offshore .o-key-diff-box h4 {
    font-size: 20px;
    line-height: 27px;
    font-weight: 600; }

.fixed-engagement-model-dev .profile-tabs {
  border-radius: 15px;
  padding: 0 0 0;
  background: none; }
  .fixed-engagement-model-dev .profile-tabs h6 {
    font-size: 14px;
    color: #CBE3FF;
    font-weight: 300;
    position: relative;
    top: -15px; }
    @media screen and (min-width: 992px) {
      .fixed-engagement-model-dev .profile-tabs h6 {
        padding: 30px 0 20px; } }
  .fixed-engagement-model-dev .profile-tabs .tab-profile-content div {
    opacity: 1; }
  .fixed-engagement-model-dev .profile-tabs .tab-profile-header {
    display: none; }
    @media screen and (min-width: 992px) {
      .fixed-engagement-model-dev .profile-tabs .tab-profile-header {
        display: block; } }
    .fixed-engagement-model-dev .profile-tabs .tab-profile-header ul {
      position: relative; }
      @media screen and (min-width: 1200px) {
        .fixed-engagement-model-dev .profile-tabs .tab-profile-header ul {
          max-width: 825px;
          margin: 0 auto; } }
      .fixed-engagement-model-dev .profile-tabs .tab-profile-header ul::before {
        content: '';
        position: absolute;
        background: #4F8DE9;
        opacity: .6;
        width: 100%;
        height: 3px;
        bottom: 0; }
      .fixed-engagement-model-dev .profile-tabs .tab-profile-header ul li {
        padding: 0;
        align-items: flex-start; }
        .fixed-engagement-model-dev .profile-tabs .tab-profile-header ul li img {
          position: relative;
          top: 5px; }
        .fixed-engagement-model-dev .profile-tabs .tab-profile-header ul li span {
          display: inline-block;
          width: 100%;
          color: #61F38E;
          font-size: 20px; }
          @media screen and (min-width: 992px) {
            .fixed-engagement-model-dev .profile-tabs .tab-profile-header ul li span {
              padding: 0 15px 20px 60px; } }
          .fixed-engagement-model-dev .profile-tabs .tab-profile-header ul li span:before {
            background: #3CC065;
            width: 100%;
            bottom: -1px; }
          .fixed-engagement-model-dev .profile-tabs .tab-profile-header ul li span::after {
            content: '';
            position: absolute;
            top: 5px;
            left: 20px;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: contain;
            width: 30px;
            height: 50px;
            display: none; }
            @media screen and (min-width: 992px) {
              .fixed-engagement-model-dev .profile-tabs .tab-profile-header ul li span::after {
                display: block; } }
          .fixed-engagement-model-dev .profile-tabs .tab-profile-header ul li span.flag1:after {
            background-image: url(images/offshore-web-development/flag1.png); }
          .fixed-engagement-model-dev .profile-tabs .tab-profile-header ul li span.flag2:after {
            background-image: url(images/offshore-web-development/flag2.png); }
          .fixed-engagement-model-dev .profile-tabs .tab-profile-header ul li span.flag3:after {
            background-image: url(images/offshore-web-development/flag3.png); }
          .fixed-engagement-model-dev .profile-tabs .tab-profile-header ul li span.flag4:after {
            background-image: url(images/offshore-web-development/flag4.png); }
          .fixed-engagement-model-dev .profile-tabs .tab-profile-header ul li span small {
            display: block;
            color: #fff;
            font-size: 14px; }
  @media screen and (min-width: 992px) {
    .fixed-engagement-model-dev .profile-tabs {
      padding: 30px 20px 0;
      background: rgba(0, 0, 0, 0.1); } }

.fixed-engagement-model-dev.dapp-tech-stack {
  padding: 40px 0 20px; }
  @media screen and (min-width: 992px) {
    .fixed-engagement-model-dev.dapp-tech-stack {
      padding: 65px 0 40px; } }
  .fixed-engagement-model-dev.dapp-tech-stack .fixed-engagement {
    max-width: 100%;
    margin-bottom: 0; }
  .fixed-engagement-model-dev.dapp-tech-stack .tab-profile-content-area {
    margin-top: 0; }
    .fixed-engagement-model-dev.dapp-tech-stack .tab-profile-content-area.active {
      padding-bottom: 0; }
      @media screen and (min-width: 992px) {
        .fixed-engagement-model-dev.dapp-tech-stack .tab-profile-content-area.active {
          margin-top: 30px; } }
      @media screen and (min-width: 1200px) {
        .fixed-engagement-model-dev.dapp-tech-stack .tab-profile-content-area.active {
          padding: 20px 0 0; } }
  @media screen and (min-width: 992px) {
    .fixed-engagement-model-dev.dapp-tech-stack .fixed-engagement-row-left {
      padding: 32px 10px 32px 25px; } }
  .fixed-engagement-model-dev.dapp-tech-stack .fixed-engagement-row-top .fixed-engagement-right {
    margin-bottom: 0; }
    .fixed-engagement-model-dev.dapp-tech-stack .fixed-engagement-row-top .fixed-engagement-right .fixed-engagement-col h5 {
      font-size: 16px;
      padding: 10px 12px; }
      @media screen and (min-width: 992px) {
        .fixed-engagement-model-dev.dapp-tech-stack .fixed-engagement-row-top .fixed-engagement-right .fixed-engagement-col h5 {
          font-size: 18px; } }
    .fixed-engagement-model-dev.dapp-tech-stack .fixed-engagement-row-top .fixed-engagement-right .col-content-box {
      pointer-events: none; }
      .fixed-engagement-model-dev.dapp-tech-stack .fixed-engagement-row-top .fixed-engagement-right .col-content-box-top h5::before {
        background-image: none;
        background-color: #333;
        width: 15px;
        height: 1px; }

.fixed-engagement-model.new-fixed-engagement-model.fixed-engagement-model-dev.dapp-tech-stack .fixed-engagement-row-left.fixed-engagement-row-left-new-bg {
  background: rgba(203, 227, 255, 0.1); }
  .fixed-engagement-model.new-fixed-engagement-model.fixed-engagement-model-dev.dapp-tech-stack .fixed-engagement-row-left.fixed-engagement-row-left-new-bg h5 {
    color: #fff;
    font-size: 20px;
    font-weight: 500; }

.fixed-engagement-model.new-fixed-engagement-model.fixed-engagement-model-dev.dapp-tech-stack .fixed-engagement-row-left {
  flex: 0 0 19%;
  background: #CBE3FF;
  border-bottom: 1px solid #474D5A; }
  .fixed-engagement-model.new-fixed-engagement-model.fixed-engagement-model-dev.dapp-tech-stack .fixed-engagement-row-left h5 {
    color: #333333;
    font-weight: 500;
    margin-bottom: 0; }

.pricing-new-table {
  background: #fff; }
  .pricing-new-table tr th {
    border-bottom: 1px solid #ccc;
    padding: 15px;
    background: rgba(0, 0, 0, 0.1); }
    .pricing-new-table tr th:first-child {
      background: #CBE3FF; }
  .pricing-new-table tr td {
    padding: 15px;
    vertical-align: middle;
    border-right: 1px solid #ccc; }
    .pricing-new-table tr td:last-child {
      border-right: 0; }
  .pricing-new-table thead tr th {
    border-bottom: 1px solid #ccc !important;
    border-right: 1px solid #ccc; }
    .pricing-new-table thead tr th:last-child {
      border-right: 0; }
    .pricing-new-table thead tr th:first-child {
      background: rgba(0, 0, 0, 0.1); }

.no-border-mod .o-key-diff-box {
  border-width: 0; }
  @media screen and (min-width: 992px) {
    .no-border-mod .o-key-diff-box {
      border-left-width: 5px;
      border-top-width: 1px;
      border-right-width: 1px;
      border-bottom-width: 1px; } }

/* width */
.table-responsive::-webkit-scrollbar {
  width: 1px; }

/* Track */
.table-responsive::-webkit-scrollbar-track {
  background: transparent; }

/* Handle */
.table-responsive::-webkit-scrollbar-thumb {
  background: transparent; }

/* Handle on hover */
.table-responsive::-webkit-scrollbar-thumb:hover {
  background: transparent; }

.th-flex {
  display: flex;
  align-items: center; }
  .th-flex i {
    display: inline-block;
    height: 27px;
    width: 27px;
    margin-right: 5px;
    position: relative;
    top: 2px; }
    .th-flex i img {
      height: 100%;
      width: 100%; }

.role-class {
  position: relative;
  top: -3px; }

.experts-mod .o-key-diff-box {
  background: #fff; }
  @media screen and (min-width: 992px) {
    .experts-mod .o-key-diff-box {
      padding: 35px 85px 35px 35px; } }
  @media screen and (min-width: 992px) {
    .experts-mod .o-key-diff-box p {
      font-size: 24px;
      line-height: 40px; } }

@media screen and (min-width: 992px) {
  .hire-web-developers-ser .new-service-section-wrapper {
    margin-bottom: 30px; } }

/* ANCHOR -Sitemap*/
.sitemap-body {
  padding: 70px 0 20px; }
  @media screen and (min-width: 992px) {
    .sitemap-body {
      padding: 90px 0 60px; } }
  .sitemap-body h2 {
    font-size: 24px;
    line-height: 40px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .sitemap-body h2 {
        margin-bottom: 30px;
        font-size: 30px; } }
  .sitemap-body h3 {
    position: relative;
    padding-left: 35px;
    font-size: 20px; }
    @media screen and (min-width: 992px) {
      .sitemap-body h3 {
        font-size: 24px; } }
    .sitemap-body h3::before {
      content: '';
      background-image: url(/images/common/green-arrow.png);
      width: 25px;
      height: 25px;
      position: absolute;
      left: 0;
      top: 0;
      background-size: 100%; }
      @media screen and (min-width: 992px) {
        .sitemap-body h3::before {
          top: 1px; } }
  .sitemap-body h1 {
    font-size: 24px; }
    @media screen and (min-width: 992px) {
      .sitemap-body h1 {
        font-size: 30px; } }
  .sitemap-body h1, .sitemap-body h2, .sitemap-body h3 {
    font-weight: 600; }
    .sitemap-body h1 a, .sitemap-body h2 a, .sitemap-body h3 a {
      color: #2dbe70;
      transition: .3s all ease-in-out; }
      .sitemap-body h1 a:hover, .sitemap-body h2 a:hover, .sitemap-body h3 a:hover {
        color: #ff5800; }
    @media screen and (min-width: 992px) {
      .sitemap-body h1.industry-mb-mod, .sitemap-body h2.industry-mb-mod, .sitemap-body h3.industry-mb-mod {
        margin-bottom: 15px; } }

.sitemap-page {
  vertical-align: top; }
  @media screen and (min-width: 992px) {
    .sitemap-page.sitemap-page-3 {
      width: 33%; } }
  @media screen and (min-width: 992px) {
    .sitemap-page.sitemap-page-2 {
      width: 49.5%; } }
  .sitemap-page li {
    position: relative;
    padding: 6px 0 6px 20px; }
    @media screen and (min-width: 992px) {
      .sitemap-page li {
        padding: 10px 0 10px 30px; } }
    .sitemap-page li a {
      font-size: 16px;
      transition: .3s all ease-in-out; }
      @media screen and (min-width: 992px) {
        .sitemap-page li a {
          font-size: 18px; } }
      .sitemap-page li a:hover {
        color: #2dbe70; }
    .sitemap-page li::before {
      border: 4px solid #ecebf9;
      content: '';
      position: absolute;
      left: 0;
      top: 10px;
      background: #4842a8;
      width: 16px;
      height: 16px;
      border-radius: 50%; }
      @media screen and (min-width: 992px) {
        .sitemap-page li::before {
          top: 15px; } }

.sitemap-page-tech {
  display: flex;
  flex-wrap: wrap; }
  .sitemap-page-tech li {
    flex: 0 0 33%; }

.sitemap-page-tech2 {
  display: flex;
  flex-wrap: wrap; }
  .sitemap-page-tech2 li {
    flex: 0 0 50%; }

.sitemap-wrap {
  margin-bottom: 20px;
  padding-left: 15px; }
  .sitemap-wrap.pl-0 {
    padding-left: 0; }
  @media screen and (min-width: 992px) {
    .sitemap-wrap {
      margin-bottom: 40px;
      padding-left: 25px; } }

/* ANCHOR -Button*/
.btn-v2 {
  max-width: 409px;
  height: 62px;
  margin: 0 auto;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins' !important;
  font-size: 19px;
  font-weight: 600;
  border-radius: 5px;
  text-transform: capitalize; }
  .btn-v2--transparent {
    background-color: transparent; }
  .btn-v2--transparent-black {
    background-color: transparent;
    color: #333;
    border-color: #333; }
    .btn-v2--transparent-black:hover {
      background: #333;
      color: #fff;
      transition: .3s all ease-in-out; }
      .btn-v2--transparent-black:hover path {
        fill: #fff;
        transition: .3s all ease-in-out; }
  .btn-v2 .btn-arrow {
    margin-left: 10px; }

.btn-orange {
  font-family: 'Poppins' !important;
  background: #FF5800;
  color: #fff;
  transition: .3s all ease-in-out; }
  .btn-orange:hover {
    background: #fff;
    color: #FF5800; }
    .btn-orange:hover path {
      fill: #FF5800; }

.btn-wrapper-v2--pair {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }
  .btn-wrapper-v2--pair .btn-v2 {
    flex: 0 0 360px;
    max-width: 360px;
    margin: 15px auto 0; }
    @media screen and (min-width: 770px) {
      .btn-wrapper-v2--pair .btn-v2 {
        max-width: 49%; } }
    @media screen and (min-width: 992px) {
      .btn-wrapper-v2--pair .btn-v2 {
        margin: 50px 5px 20px;
        flex: 0 0 370px;
        max-width: 370px; } }
  .btn-wrapper-v2--pair .btn-v3 {
    flex: 0 0 360px;
    max-width: 360px;
    margin: 15px auto 0;
    text-transform: capitalize; }
    @media screen and (min-width: 770px) {
      .btn-wrapper-v2--pair .btn-v3 {
        max-width: 49%; } }
    @media screen and (min-width: 992px) {
      .btn-wrapper-v2--pair .btn-v3 {
        margin: 50px 5px 20px;
        flex: 0 0 410px;
        max-width: 410px; } }

.cmn-btn-transition {
  transition: .3s all ease-in-out; }
  .cmn-btn-transition svg {
    transition: .3s all ease-in-out; }
  .cmn-btn-transition path {
    transition: .3s all ease-in-out; }
  .cmn-btn-transition:hover svg {
    transform: translate(15px, 0); }

.btn-arrow {
  margin-left: 10px; }

.white-hover:hover path {
  fill: #FF5800; }

.btn--transparent {
  background: transparent; }

.btn-center {
  margin: 0 auto !important; }

/* ANCHOR -Home Skill*/
/*Pool Setion*/
.pool-section {
  padding: 25px 0 40px;
  border-bottom: 1px solid #e0e0e0; }
  @media screen and (min-width: 992px) {
    .pool-section {
      padding: 60px 0;
      border-bottom: 0; } }
  .pool-section h2 {
    font-family: "Poppins" !important;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    position: relative;
    padding-bottom: 15px; }
    @media screen and (min-width: 992px) {
      .pool-section h2 {
        font-size: 48px;
        line-height: 61px;
        padding-bottom: 0; } }
    .pool-section h2::before {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #3cc065;
      width: 83px;
      height: 7px;
      margin: 0 auto; }
      @media screen and (min-width: 992px) {
        .pool-section h2::before {
          display: none; } }
  .pool-section h3 {
    font-family: "Poppins" !important;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #35b15c; }
    @media screen and (min-width: 992px) {
      .pool-section h3 {
        margin-bottom: 40px;
        font-size: 25px;
        line-height: 34px; } }
    .pool-section h3 .heading-underline {
      position: relative;
      display: inline-block; }
      .pool-section h3 .heading-underline:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #35b15c;
        height: 1px;
        width: 100%; }
  .pool-section h4 {
    font-family: "Poppins" !important;
    text-align: center;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500; }
    @media screen and (min-width: 992px) {
      .pool-section h4 {
        font-size: 17px;
        line-height: 30px;
        text-align: left;
        margin-top: 0; } }
    @media screen and (min-width: 1280px) {
      .pool-section h4 {
        font-size: 18px;
        line-height: 30px; } }
  .pool-section .p-box {
    display: flex;
    flex-wrap: wrap; }
  .pool-section .pool-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .pool-section .pool-box.mb-40 {
      margin-bottom: 45px; }
    .pool-section .pool-box .owl-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 0 0;
      position: relative;
      gap: 0 10px;
      height: 45px; }
      @media screen and (min-width: 992px) {
        .pool-section .pool-box .owl-dots {
          top: 35px;
          padding: 30px 0;
          height: 73px; } }
      .pool-section .pool-box .owl-dots .owl-dot {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #e1e1e1; }
        .pool-section .pool-box .owl-dots .owl-dot.active {
          width: 13px;
          height: 13px;
          background: #ff5800; }
    .pool-section .pool-box .splide__pagination__page {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: #e1e1e1; }
      .pool-section .pool-box .splide__pagination__page.is-active {
        width: 13px;
        height: 13px;
        background: #ff5800;
        transform: scale(1); }
    .pool-section .pool-box-list {
      background: #d6e3f0;
      border-radius: 10px;
      border: 1px solid #d6e3f0;
      padding: 2px;
      flex: auto;
      text-align: center;
      background: #fff;
      margin: 7px 3px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;
      height: 77px;
      transition: 0.3s all ease-in-out; }
      @media screen and (min-width: 992px) {
        .pool-section .pool-box-list {
          flex: inherit;
          padding: 2px;
          overflow: hidden;
          border: none;
          border: none;
          height: inherit;
          transform: translateZ(0); } }
      .pool-section .pool-box-list:before {
        display: none; }
        @media screen and (min-width: 992px) {
          .pool-section .pool-box-list:before {
            display: block;
            content: "";
            position: absolute;
            top: -106px;
            right: 0;
            bottom: 0;
            left: -100px;
            background: #d6e3f0;
            width: 560px;
            height: 500px;
            transition: 0.5s all ease-in-out; } }
      .pool-section .pool-box-list img {
        display: block;
        height: 100%;
        object-fit: contain; }
      .pool-section .pool-box-list small {
        font-family: "Poppins" !important;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        margin-left: 15px; }
      .pool-section .pool-box-list i {
        display: inline-block;
        width: auto; }
      .pool-section .pool-box-list .wd-50 {
        width: 45px; }
      .pool-section .pool-box-list .rltv-3 {
        position: relative;
        top: -3px; }
      .pool-section .pool-box-list a {
        background: #fff;
        color: #333333;
        border-radius: 10px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        position: relative;
        z-index: 99; }
        @media screen and (min-width: 992px) {
          .pool-section .pool-box-list a {
            padding: 17px 23px; } }
      .pool-section .pool-box-list:hover {
        border-color: #508ec8; }
        .pool-section .pool-box-list:hover:before {
          animation: rotation2 3s linear infinite;
          background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
          background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
          background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
  .pool-section .btn_wrapper {
    margin-top: 15px; }
    @media screen and (min-width: 992px) {
      .pool-section .btn_wrapper {
        width: 180px;
        margin: 20px 0 0; } }
    .pool-section .btn_wrapper .btn {
      font-family: "Poppins" !important;
      margin: 0 auto;
      font-weight: 500; }
      @media screen and (min-width: 992px) {
        .pool-section .btn_wrapper .btn {
          margin: 0;
          padding: 8px;
          font-size: 15px; } }
  @media screen and (min-width: 992px) {
    .pool-section .col-lg-8 {
      width: 69%; } }
  @media screen and (min-width: 1280px) {
    .pool-section .col-lg-8 {
      width: 66%; } }
  @media screen and (min-width: 992px) {
    .pool-section .col-lg-4 {
      width: 31%; } }
  @media screen and (min-width: 1280px) {
    .pool-section .col-lg-4 {
      width: 34%; } }
  .pool-section .pool-slider {
    position: relative; }
    .pool-section .pool-slider .owl-prev {
      position: absolute;
      left: -30px;
      top: 32px;
      width: auto; }
      .pool-section .pool-slider .owl-prev span {
        font-family: "Poppins" !important;
        display: inline-block;
        background: #fff;
        border: 1px solid #ccc;
        color: #3cc065;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: relative;
        font-size: 30px;
        line-height: 26px; }
    .pool-section .pool-slider .owl-next {
      position: absolute;
      right: -30px;
      top: 32px;
      width: auto; }
      .pool-section .pool-slider .owl-next span {
        font-family: "Poppins" !important;
        display: inline-block;
        background: #fff;
        border: 1px solid #ccc;
        color: #3cc065;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: relative;
        font-size: 30px;
        line-height: 26px; }
  .pool-section .btn-wrapper-v2--pair .btn-v2 {
    margin-bottom: 0;
    margin-top: 44px; }
  .pool-section--subpage h2 {
    margin-bottom: 40px; }
  .pool-section--subpage .pool-box-list {
    margin: 7px; }
  .pool-section--cloud-engineering .pool-box-list i {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; }
    .pool-section--cloud-engineering .pool-box-list i img {
      width: 100%;
      object-fit: fill; }
  .pool-section .splide__pagination {
    position: absolute;
    bottom: -25px; }

.o-platform-work {
  border-bottom: 1px solid #dedede; }
  .o-platform-work__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-platform-work__spacing {
        padding: 60px 0; } }
    .o-platform-work__spacing--inner {
      border-top: 1px solid #cae0e7; }
  .o-platform-work__title {
    font-family: Poppins;
    font-size: 30px;
    line-height: 35px;
    color: #262e36;
    text-align: center;
    font-weight: 700; }
    @media screen and (min-width: 767px) {
      .o-platform-work__title {
        font-size: 38px;
        line-height: 45px; } }
    @media screen and (min-width: 992px) {
      .o-platform-work__title {
        font-size: 48px;
        line-height: 58px; } }
    .o-platform-work__title--inner {
      margin-bottom: 20px; }
  .o-platform-work__subtitle {
    font-family: Poppins;
    color: #35b15c;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    font-size: 25px;
    line-height: 34px;
    margin-bottom: 50px; }
  .o-platform-work__box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d6e3f0;
    border-radius: 10px;
    height: 100%;
    background-color: #fff;
    transition: 0.3s all ease-in-out; }
    .o-platform-work__box--noanchor {
      padding: 30px; }
      @media screen and (min-width: 767px) {
        .o-platform-work__box--noanchor {
          padding: 20px 10px; } }
    .o-platform-work__box:hover {
      box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1); }
  .o-platform-work__anchor {
    display: block;
    text-align: center;
    width: 100%;
    padding: 30px; }
    @media screen and (min-width: 767px) {
      .o-platform-work__anchor {
        padding: 20px 10px; } }
  .o-platform-work__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .o-platform-work__icon img {
      max-height: 100%; }
  .o-platform-work__text {
    font-family: Poppins;
    color: #333333;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin: 0; }
  .o-platform-work .btn-wrapper-v2--pair .btn-v2 {
    margin: 20px 5px 0; }

.bg-gray {
  background-color: #f9f9f9; }

.mb-30 {
  margin-bottom: 30px; }

/* ANCHOR -New Bottom Form*/
.new-bottom-form {
  padding: 35px 0 40px;
  background: #052c58 !important; }
  @media screen and (min-width: 992px) {
    .new-bottom-form {
      background-color: #052c58 !important;
      background-repeat: no-repeat !important;
      background-position: 0 0;
      padding: 0 0 40px; } }
  .new-bottom-form .container {
    padding: 0 15px; }
  .new-bottom-form__wrapper {
    position: relative; }
    @media screen and (min-width: 992px) {
      .new-bottom-form__wrapper {
        display: flex; } }
  .new-bottom-form__left {
    position: relative;
    z-index: 99;
    margin-right: 0; }
    @media screen and (min-width: 992px) {
      .new-bottom-form__left {
        padding-top: 60px;
        flex: 0 0 58%;
        margin-right: 45px; } }
    @media screen and (min-width: 1180px) {
      .new-bottom-form__left {
        margin-right: 90px; } }
    .new-bottom-form__left .attachment-list {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .new-bottom-form__left .attachment-list {
          width: 99%;
          display: flex;
          flex-wrap: wrap;
          gap: 0 10px; } }
      @media screen and (min-width: 992px) {
        .new-bottom-form__left .attachment-list li {
          flex: 0 0 49%;
          max-width: 49% !important; } }
  .new-bottom-form__right {
    position: relative;
    display: inline-block; }
    @media screen and (min-width: 992px) {
      .new-bottom-form__right {
        display: inherit;
        flex: 1; } }
  .new-bottom-form h2 {
    font-family: "Poppins";
    font-size: 31px;
    line-height: 38px;
    font-weight: 700;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .new-bottom-form h2 {
        text-align: left; } }
  .new-bottom-form .form-sub-heading {
    font-family: "Poppins";
    padding: 0;
    color: #fff;
    font-size: 25px;
    line-height: 30px;
    font-weight: 600;
    margin: 0 0 25px; }
    @media screen and (min-width: 992px) {
      .new-bottom-form .form-sub-heading {
        color: #75a3f0;
        margin: 15px 0 25px; } }
  .new-bottom-form__strong {
    color: #bad2ed; }
  .new-bottom-form .o-contact-form__contact-form {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .new-bottom-form .form-panel {
    width: 100%;
    padding: 0;
    background: none; }
  .new-bottom-form input {
    font-family: "Poppins";
    background: none;
    border: 1px solid #98bcf8;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    padding: 15px 15px;
    margin-bottom: 15px;
    color: #fff;
    flex: 0 0 100%; }
    @media screen and (min-width: 992px) {
      .new-bottom-form input {
        flex: 0 0 48.5%;
        margin-bottom: 20px;
        padding: 20px 15px; } }
    .new-bottom-form input::placeholder {
      font-size: 17px;
      font-weight: 500 !important;
      color: #fff; }
    .new-bottom-form input[type="number"] {
      -moz-appearance: textfield;
      -webkit-appearance: none;
      appearance: none; }
    .new-bottom-form input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .new-bottom-form input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
  .new-bottom-form textarea {
    font-family: "Poppins";
    flex: 0 0 100%;
    border: 1px solid #98bcf8;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    background: none !important;
    padding: 25px 15px;
    height: 120px;
    margin-bottom: 15px;
    color: #fff; }
    @media screen and (min-width: 992px) {
      .new-bottom-form textarea {
        flex: 0 0 100%;
        height: 150px; } }
    .new-bottom-form textarea::placeholder {
      font-size: 17px;
      font-weight: 500 !important;
      color: #fff; }
  .new-bottom-form .attach {
    background-position: 0 15px;
    flex: 0 0 100%; }
    @media screen and (min-width: 767px) {
      .new-bottom-form .attach {
        background-position: 0 0;
        padding: 0px 20px 0 40px;
        margin-bottom: 20px;
        margin-top: 10px; } }
  .new-bottom-form .o-contact-form__attachement-text {
    font-family: "Poppins";
    color: #75a3f0;
    font-size: 16px; }
  .new-bottom-form label {
    color: #fff; }
  .new-bottom-form__btn-wrapper {
    margin-top: 15px;
    margin-bottom: 0;
    flex-direction: column-reverse;
    width: 100%;
    display: flex; }
    @media screen and (min-width: 992px) {
      .new-bottom-form__btn-wrapper {
        margin-top: 10px;
        margin-bottom: 50px;
        flex: 0 0 100%;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;
        width: inherit; } }
    .new-bottom-form__btn-wrapper button {
      font-family: "Poppins";
      margin: 0 auto !important;
      padding: 19px 0;
      font-size: 17px;
      font-weight: 600;
      width: 360px;
      text-transform: capitalize; }
      @media screen and (min-width: 992px) {
        .new-bottom-form__btn-wrapper button {
          width: 280px;
          margin: 0 !important; } }
  .new-bottom-form .confidential-text-wrap {
    flex: 1;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .new-bottom-form .confidential-text-wrap {
        text-align: left;
        margin-top: 0;
        margin-bottom: 0; } }
    .new-bottom-form .confidential-text-wrap ul li {
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      padding: 0 15px; }
      .new-bottom-form .confidential-text-wrap ul li:first-child {
        padding-left: 0; }
      .new-bottom-form .confidential-text-wrap ul li::after {
        background: #215894;
        height: 16px;
        top: 4px; }
      .new-bottom-form .confidential-text-wrap ul li img {
        width: 17px;
        height: 17px; }
  .new-bottom-form__whats-next {
    padding-top: 35px;
    border-top: 1px solid #215894;
    margin-top: 45px; }
    @media screen and (min-width: 992px) {
      .new-bottom-form__whats-next {
        margin-top: 0; } }
    .new-bottom-form__whats-next h3 {
      font-family: "Poppins";
      font-size: 31px;
      font-weight: 700;
      color: #fff; }
    .new-bottom-form__whats-next p {
      font-family: "Poppins";
      font-size: 21px;
      font-weight: 600;
      color: #4492dc; }
  .new-bottom-form .form-right {
    flex: 0 0 60%; }
  .new-bottom-form .form-left {
    flex: 0 0 100%;
    width: 100%;
    padding: 0;
    position: relative;
    background-color: transparent; }
  .new-bottom-form__email {
    text-align: center;
    background: #135195;
    padding: 60px 40px 60px; }
    .new-bottom-form__email img {
      margin-bottom: 25px; }
    .new-bottom-form__email h4 {
      font-family: "Poppins";
      font-size: 22px;
      line-height: 30px;
      font-weight: 600;
      color: #bad2ed; }
      .new-bottom-form__email h4 a {
        font-weight: 700;
        color: #f3f6f9;
        position: relative; }
        .new-bottom-form__email h4 a:before {
          content: "";
          background: #5289c7;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px; }
  .new-bottom-form__trust {
    background: #04254a;
    padding: 50px 40px 40px; }
    .new-bottom-form__trust h5 {
      font-family: "Poppins";
      font-size: 22px;
      line-height: 28px;
      font-weight: 700;
      color: #f3f6f9;
      margin-bottom: 25px; }
      .new-bottom-form__trust h5 span {
        color: #bad2ed;
        font-weight: 400; }
    .new-bottom-form__trust ul {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 0;
      padding-bottom: 20px;
      margin-bottom: 20px;
      gap: 15px; }
      .new-bottom-form__trust ul li {
        flex: 0 0 31%;
        margin-right: 0;
        display: flex;
        align-items: center;
        justify-content: center; }
        @media screen and (min-width: 992px) {
          .new-bottom-form__trust ul li {
            flex: 0 0 47%;
            justify-content: flex-start; } }
        .new-bottom-form__trust ul li img {
          filter: none; }
        @media screen and (min-width: 992px) {
          .new-bottom-form__trust ul li:nth-of-type(odd) {
            justify-content: flex-start; } }
        @media screen and (min-width: 992px) {
          .new-bottom-form__trust ul li:nth-of-type(even) {
            justify-content: flex-end; } }
        .new-bottom-form__trust ul li:nth-child(3) {
          margin-top: 15px; }
        .new-bottom-form__trust ul li:nth-child(4) {
          margin-top: 15px; }
          @media screen and (min-width: 992px) {
            .new-bottom-form__trust ul li:nth-child(4) {
              align-items: flex-start; } }
        .new-bottom-form__trust ul li:nth-child(2) {
          justify-content: center; }
        .new-bottom-form__trust ul li:nth-child(6) {
          justify-content: center; }
    .new-bottom-form__trust h6 {
      font-family: "Poppins";
      color: #75a3f0;
      font-size: 15px;
      font-weight: 500; }

.skill-bottom-form-custom-dev h2 {
  font-weight: 500; }

.skill-bottom-form__strong {
  color: #cddefa; }

/* ANCHOR -New Banner Area*/
.new-banner-area {
  position: relative;
  background: #081C36;
  width: 100%;
  overflow: hidden; }
  @media screen and (min-width: 992px) {
    .new-banner-area {
      overflow: visible; } }
  .new-banner-area:before {
    position: absolute;
    content: "";
    width: 410px;
    height: 245px;
    background: url(/images/two-for-one/banner-top-gradient.png) no-repeat top left;
    background-size: 100%;
    top: 65px;
    left: 0; }
  .new-banner-area:after {
    position: absolute;
    content: "";
    width: 500px;
    height: 160px;
    background: url(/images/two-for-one/banner-bottom-blue.svg) no-repeat bottom center;
    background-size: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }
  .new-banner-area__spacing {
    padding: 115px 20px 0; }
  .new-banner-area__lefttop-area {
    margin-top: 35px; }
  .new-banner-area__contentarea {
    max-width: 554px; }
    @media screen and (min-width: 992px) {
      .new-banner-area__contentarea {
        padding-top: 40px; } }
  .new-banner-area__subheading {
    font-family: 'Poppins';
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    color: #84A5F9;
    margin-bottom: 35px;
    width: 50%; }
    @media screen and (min-width: 370px) {
      .new-banner-area__subheading {
        width: 60%; } }
    @media screen and (min-width: 992px) {
      .new-banner-area__subheading {
        font-size: 40px;
        line-height: 46px;
        width: 100%;
        margin-bottom: 15px; } }
  .new-banner-area__heading {
    font-family: 'Poppins';
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 15px;
    max-width: 400px;
    width: 55%; }
    @media screen and (min-width: 992px) {
      .new-banner-area__heading {
        font-size: 38px;
        line-height: 51px;
        margin-bottom: 12px;
        width: 100%; } }
  .new-banner-area__headingbold {
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    color: #3CC065;
    margin-bottom: 15px;
    max-width: 389px; }
    @media screen and (min-width: 992px) {
      .new-banner-area__headingbold {
        font-size: 27px;
        line-height: 36px;
        margin-bottom: 26px; } }
  .new-banner-area__headingcontent {
    font-family: 'Poppins';
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
    color: #fff; }
    @media screen and (min-width: 992px) {
      .new-banner-area__headingcontent {
        font-size: 23px;
        line-height: 33px; } }
  .new-banner-area__twologo {
    max-width: 170px;
    position: absolute;
    right: -20px;
    top: -55px; }
    @media screen and (min-width: 420px) {
      .new-banner-area__twologo {
        max-width: 200px; } }
    @media screen and (min-width: 992px) {
      .new-banner-area__twologo {
        right: -35px;
        top: 50px; } }
    @media screen and (min-width: 1180px) {
      .new-banner-area__twologo {
        max-width: 320px;
        right: -60px;
        top: 0; } }
  .new-banner-area__formarea {
    position: relative;
    text-align: center;
    background: none !important;
    top: 7px; }
    @media screen and (min-width: 560px) {
      .new-banner-area__formarea {
        top: inherit; } }
    @media screen and (min-width: 1025px) {
      .new-banner-area__formarea {
        text-align: right; } }
    .new-banner-area__formarea:before {
      position: absolute;
      content: "";
      width: 750px;
      height: 528px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background: url(/images/two-for-one/new-banner-form-before.png) no-repeat bottom center;
      background-size: 100%; }
      @media screen and (min-width: 1024px) {
        .new-banner-area__formarea:before {
          left: -35%;
          right: inherit; } }
  .new-banner-area__formpart {
    position: relative;
    max-width: 460px;
    text-align: left;
    background-color: #fff;
    padding: 30px 20px;
    display: inline-block;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
    z-index: 1111; }
    @media screen and (min-width: 992px) {
      .new-banner-area__formpart {
        padding: 35px 27px; } }
    .new-banner-area__formpart:before {
      content: '';
      position: absolute;
      background: url(/images/contact-us/dashed-line.png) 0 0 repeat;
      height: 5px;
      width: 100%;
      top: 0;
      left: 0;
      right: 0; }
  .new-banner-area__formwrapperaction {
    background: none !important;
    padding: 0; }
    .new-banner-area__formwrapperaction #django-o-contact-form1 {
      padding: 0;
      display: block; }
      .new-banner-area__formwrapperaction #django-o-contact-form1 .new-bottom-form__btn-wrapper {
        margin: 0 !important;
        display: flex !important;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column; }
    .new-banner-area__formwrapperaction .new-bottom-form__wrapper {
      position: relative;
      max-width: 100%;
      text-align: left;
      background-color: #fff;
      display: inline-block; }
    .new-banner-area__formwrapperaction .attach {
      margin: 5px 0 20px !important;
      background-position: 0 9px;
      padding: 0 0 0 35px; }
      .new-banner-area__formwrapperaction .attach .o-contact-form__attachement-text {
        color: #333 !important;
        font-size: 15px !important;
        font-weight: 400 !important; }
        .new-banner-area__formwrapperaction .attach .o-contact-form__attachement-text label {
          color: #3cc065 !important;
          font-weight: 500 !important; }
    .new-banner-area__formwrapperaction .new-banner-area__confidential {
      margin: 30px 0 0 !important;
      text-align: center;
      width: 100%; }
    .new-banner-area__formwrapperaction .confidential-text-wrap ul {
      justify-content: center !important;
      align-items: center; }
      .new-banner-area__formwrapperaction .confidential-text-wrap ul li {
        width: auto;
        padding: 0 10px 4px !important; }
  .new-banner-area__formtittle {
    font-family: 'Poppins';
    font-size: 30px;
    line-height: 30px;
    color: #333;
    font-weight: 700;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .new-banner-area__formtittle {
        font-size: 40px;
        line-height: 40px; } }
  .new-banner-area__forminput {
    font-family: 'Poppins' !important;
    width: 100%;
    border: 1px solid #DBDBDB !important;
    color: #333 !important;
    line-height: 52px !important;
    font-size: 14px !important;
    margin-bottom: 12px !important;
    padding: 0 19px !important;
    font-weight: 400 !important; }
    @media screen and (min-width: 992px) {
      .new-banner-area__forminput {
        font-size: 15px !important; } }
    .new-banner-area__forminput::-webkit-input-placeholder {
      font-family: 'Poppins' !important;
      color: #333 !important; }
    .new-banner-area__forminput::-moz-placeholder {
      font-family: 'Poppins' !important;
      color: #333 !important; }
    .new-banner-area__forminput:-ms-input-placeholder {
      font-family: 'Poppins' !important;
      color: #333 !important; }
    .new-banner-area__forminput:-moz-placeholder {
      font-family: 'Poppins' !important;
      color: #333 !important; }
    .new-banner-area__forminput--textarea {
      resize: none !important;
      height: 120px !important;
      padding: 0 19px !important; }
      .new-banner-area__forminput--textarea::-webkit-input-placeholder {
        font-family: 'Poppins' !important;
        color: #333 !important; }
      .new-banner-area__forminput--textarea::-moz-placeholder {
        font-family: 'Poppins' !important;
        color: #333 !important; }
      .new-banner-area__forminput--textarea:-ms-input-placeholder {
        font-family: 'Poppins' !important;
        color: #333 !important; }
      .new-banner-area__forminput--textarea:-moz-placeholder {
        font-family: 'Poppins' !important;
        color: #333 !important; }
  .new-banner-area__confidential-list {
    font-family: 'Poppins';
    color: #333 !important;
    font-size: 14px !important;
    padding: 0 7px !important;
    line-height: 15px !important; }
    @media screen and (min-width: 992px) {
      .new-banner-area__confidential-list {
        font-size: 16px !important;
        line-height: 17px !important; } }
    .new-banner-area__confidential-list:after {
      background-color: #E1E1E1 !important; }
  .new-banner-area__btn {
    font-family: 'Poppins' !important;
    margin: 0 !important;
    max-width: 100% !important;
    width: 100% !important;
    font-size: 17px !important;
    font-weight: 600 !important;
    padding: 17px 25px !important;
    text-transform: capitalize !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    border-radius: 5px; }
    @media screen and (min-width: 767px) {
      .new-banner-area__btn {
        max-width: 255px !important; } }
    .new-banner-area__btn svg {
      margin-left: 10px; }
    .new-banner-area__btn:hover g {
      fill: #ff5800; }

/* ANCHOR -Offer Details*/
.offer-details {
  position: relative;
  background: #f9f9f9; }
  .offer-details__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .offer-details__spacing {
        padding: 60px 0; } }
  .offer-details__tittle {
    font-family: 'Poppins';
    font-size: 28px;
    line-height: 30px;
    color: #333;
    margin-bottom: 32px;
    font-weight: 700;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .offer-details__tittle {
        font-size: 34px;
        line-height: 35px; } }
  .offer-details__list {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .offer-details__margin-bottom {
    margin-bottom: 25px; }
  .offer-details__item {
    position: relative;
    background-color: #fff;
    border: 1px solid #DDDDDD;
    padding: 30px;
    border-radius: 5px;
    max-width: 370px;
    margin: auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    flex-direction: column-reverse;
    border-bottom: 4px solid #62dbbe; }
    @media screen and (min-width: 992px) {
      .offer-details__item {
        align-items: flex-start;
        box-shadow: 0px 0 18px 0 rgba(0, 0, 0, 0.1); } }
  .offer-details__itemcount {
    background-color: #36E079;
    position: absolute;
    width: 34px;
    height: 34px;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0; }
  .offer-details__itemtext {
    width: 100%;
    margin: 0;
    font-family: 'Poppins';
    font-size: 19px;
    line-height: 30px;
    color: #333;
    font-weight: 500;
    text-align: center;
    margin-top: 20px; }
    @media screen and (min-width: 1024px) {
      .offer-details__itemtext {
        margin-top: 0;
        font-size: 21px;
        line-height: 31px;
        text-align: left;
        margin-top: 20px; } }
  .offer-details__btn {
    margin: 47px auto 0;
    max-width: 100%;
    width: 100%;
    font-family: 'Poppins';
    font-size: 17px;
    font-weight: 600;
    padding: 17px 25px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 767px) {
      .offer-details__btn {
        max-width: 287px; } }
    .offer-details__btn svg {
      margin-left: 10px; }
    .offer-details__btn:hover g {
      fill: #ff5800; }

.note-text {
  font-family: 'Poppins';
  background-color: #F4F4F4;
  font-size: 18px;
  line-height: 26px;
  color: #333;
  text-align: center;
  padding: 24px 15px; }

/* ANCHOR -Offer Benefits*/
.offer-benefits {
  position: relative;
  background: #fff; }
  .offer-benefits__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .offer-benefits__spacing {
        padding: 60px 0; } }
  .offer-benefits__tittle {
    font-family: 'Poppins';
    font-size: 28px;
    line-height: 30px;
    color: #333;
    margin-bottom: 15px;
    font-weight: bold;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .offer-benefits__tittle {
        font-size: 34px;
        line-height: 35px;
        margin-bottom: 45px; } }
  .offer-benefits__list {
    margin: 46px 0 67px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap; }
  .offer-benefits__item {
    position: relative;
    background-color: #fff;
    border: 1px solid #DDDDDD;
    box-shadow: 0px 0px 18px 0 rgba(0, 0, 0, 0.1);
    padding: 40px 36px 40px;
    border-radius: 5px;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    max-width: 362px;
    width: 100%;
    text-align: center;
    height: 318px; }
    @media screen and (min-width: 1181px) {
      .offer-benefits__item:nth-child(3n+3) {
        margin-right: 0; } }
    @media screen and (min-width: 1025px) {
      .offer-benefits__item {
        margin-right: 30px;
        margin-left: inherit;
        box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.1);
        width: 30%; } }
  .offer-benefits__itemtittle {
    font-family: 'Poppins';
    font-size: 19px;
    line-height: 23px;
    font-weight: 700;
    color: #333;
    display: block;
    margin: 20px 0 10px;
    width: 90%; }
    @media screen and (min-width: 992px) {
      .offer-benefits__itemtittle {
        font-size: 21px;
        line-height: 25px; } }
  .offer-benefits__itemtext {
    font-family: 'Poppins';
    margin-top: auto;
    font-size: 16px;
    line-height: 27px;
    color: #333;
    margin-bottom: 0;
    font-weight: 400; }
  .offer-benefits__btn {
    font-family: 'Poppins';
    margin: 20px auto 0;
    width: 100%;
    max-width: 100%;
    font-size: 17px;
    font-weight: 600;
    padding: 17px 20px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 767px) {
      .offer-benefits__btn {
        width: 397px;
        margin: 0 auto;
        padding: 17px 25px; } }
    .offer-benefits__btn svg {
      margin-left: 10px; }
    .offer-benefits__btn:hover g {
      fill: #ff5800; }

/* ANCHOR -Excellence Meets*/
.excellence-meets {
  position: relative;
  overflow: hidden;
  padding: 25px 0 0; }
  @media screen and (min-width: 992px) {
    .excellence-meets {
      padding: 0; } }
  .excellence-meets--toppadding {
    padding-top: 30px; }
    @media screen and (min-width: 992px) {
      .excellence-meets--toppadding {
        padding-top: 60px; } }
  .excellence-meets__tittle {
    font-family: 'Poppins';
    font-size: 22px;
    color: #333;
    line-height: 30px;
    margin-bottom: 25px;
    font-weight: 700;
    text-align: center;
    position: relative; }
    @media screen and (min-width: 992px) {
      .excellence-meets__tittle {
        font-size: 22px;
        line-height: 30px;
        text-align: left;
        margin-bottom: 16px;
        padding-bottom: 15px; } }
    .excellence-meets__tittle:after {
      content: "";
      width: 52px;
      height: 3px;
      position: absolute;
      background-color: #3CC065;
      position: absolute;
      left: 0;
      bottom: 0;
      display: none; }
      @media screen and (min-width: 992px) {
        .excellence-meets__tittle:after {
          display: block; } }
  .excellence-meets__subtittle {
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 24px;
    color: #333;
    margin-bottom: 25px;
    font-weight: 500;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .excellence-meets__subtittle {
        text-align: left;
        font-size: 18px;
        line-height: 26px; } }
  .excellence-meets__clientlists-mb {
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .excellence-meets__clientlists-mb {
        margin-bottom: 0; } }
    .excellence-meets__clientlists-mb .owl-dots {
      top: 30px;
      height: 73px; }
  .excellence-meets__clientlist {
    display: inherit; }
    @media screen and (min-width: 768px) {
      .excellence-meets__clientlist {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }
    @media screen and (min-width: 992px) {
      .excellence-meets__clientlist {
        justify-content: flex-start; } }
    .excellence-meets__clientlist .owl-stage {
      display: flex;
      align-items: center; }
  .excellence-meets__clientitem {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .excellence-meets__clientitem {
        flex: 0 0 47%;
        display: flex;
        justify-content: flex-start;
        margin: 0 5px 20px !important; } }
  .excellence-meets .owl-stage {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 10px; }
    @media screen and (min-width: 992px) {
      .excellence-meets .owl-stage {
        gap: 0; } }
  .excellence-meets__clienttext {
    font-family: 'Poppins';
    font-size: 15px;
    line-height: 26px;
    color: #333;
    font-weight: 500;
    margin-top: 0;
    display: block;
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    top: -20px; }
    @media screen and (min-width: 770px) {
      .excellence-meets__clienttext {
        top: inherit; } }
    @media screen and (min-width: 992px) {
      .excellence-meets__clienttext {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 0;
        text-align: left;
        position: static;
        margin-top: 20px;
        margin-bottom: 30px;
        top: -55px; } }
  .excellence-meets__meetsarea {
    position: relative;
    background-color: #355BBF;
    width: 100%;
    height: 100%;
    padding: 45px 20px 25px; }
    @media screen and (min-width: 992px) {
      .excellence-meets__meetsarea {
        padding: 40px 0 40px 40px; } }
    .excellence-meets__meetsarea:before {
      position: absolute;
      content: "";
      width: 1000%;
      height: 100%;
      background-color: #355BBF;
      right: -1000%;
      top: 0;
      bottom: 0;
      display: none; }
      @media screen and (min-width: 992px) {
        .excellence-meets__meetsarea:before {
          display: block; } }
    .excellence-meets__meetsarea:after {
      position: absolute;
      content: "";
      width: 1000%;
      height: 2px;
      background-color: #355BBF;
      bottom: -1px;
      left: 0;
      display: none; }
      @media screen and (min-width: 992px) {
        .excellence-meets__meetsarea:after {
          display: block; } }
    .excellence-meets__meetsarea .splide__pagination {
      bottom: -15px !important; }
    .excellence-meets__meetsarea .owl-stage-outer {
      position: relative; }
      .excellence-meets__meetsarea .owl-stage-outer::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: -16px;
        margin: 0 auto;
        width: 1px;
        height: 100%;
        background: #5B81E1; }
    .excellence-meets__meetsarea .owl-dots {
      top: 10px;
      height: 73px; }
  .excellence-meets .splide__pagination {
    position: absolute;
    bottom: -55px; }
  .excellence-meets .splide__pagination__page {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #e1e1e1; }
    .excellence-meets .splide__pagination__page.is-active {
      width: 13px;
      height: 13px;
      background: #ff5800;
      transform: scale(1); }
  .excellence-meets__meetslist .splide__track {
    position: relative; }
    .excellence-meets__meetslist .splide__track::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: -16px;
      margin: 0 auto;
      width: 1px;
      height: 100%;
      background: #5B81E1; }
  .excellence-meets__meetstittle {
    font-family: 'Poppins';
    font-size: 34px;
    line-height: 36px;
    color: #84A5F9;
    font-weight: 900;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .excellence-meets__meetstittle {
        font-size: 60px;
        line-height: 66px; } }
    .excellence-meets__meetstittle svg {
      margin-top: -33px;
      width: 40px;
      position: relative;
      top: 2px;
      right: -6px; }
      @media screen and (min-width: 992px) {
        .excellence-meets__meetstittle svg {
          width: inherit;
          position: static; } }
  .excellence-meets__meetslist {
    display: flex;
    flex-wrap: wrap; }
    .excellence-meets__meetslist .splide__pagination {
      margin-top: 15px; }
    .excellence-meets__meetslist .splide__pagination__page {
      background: #9b9b9b; }
  .excellence-meets__meetsitem {
    position: relative;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px; }
    @media screen and (min-width: 770px) {
      .excellence-meets__meetsitem {
        width: 45%;
        margin: 10px;
        justify-content: flex-start; } }
    @media screen and (min-width: 992px) {
      .excellence-meets__meetsitem {
        width: 33.33%;
        padding-bottom: 30px;
        align-items: flex-start;
        flex-direction: row;
        border-right: 1px solid #5B81E1;
        border-bottom: 1px solid #5B81E1;
        padding-bottom: 15px;
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        margin: 0; } }
    .excellence-meets__meetsitem:after {
      display: none; }
      @media screen and (min-width: 992px) {
        .excellence-meets__meetsitem:after {
          display: block;
          position: absolute;
          content: "";
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #355BBF;
          bottom: -7.5px;
          right: -7.5px;
          z-index: 1; } }
    .excellence-meets__meetsitem:nth-child(2n+2) {
      border-right: none; }
      .excellence-meets__meetsitem:nth-child(2n+2):after {
        display: none; }
        @media screen and (min-width: 992px) {
          .excellence-meets__meetsitem:nth-child(2n+2):after {
            display: block; } }
      @media screen and (min-width: 992px) {
        .excellence-meets__meetsitem:nth-child(2n+2) {
          border-right: 1px solid #5B81E1; } }
    @media screen and (min-width: 992px) {
      .excellence-meets__meetsitem:nth-child(3n+3) {
        border-right: none;
        padding-left: 10px; }
        .excellence-meets__meetsitem:nth-child(3n+3):after {
          display: none; } }
    @media screen and (min-width: 992px) {
      .excellence-meets__meetsitem:nth-last-child(-n+3) {
        border-bottom: none;
        padding-bottom: 5px; }
        .excellence-meets__meetsitem:nth-last-child(-n+3):after {
          display: none; } }
    .excellence-meets__meetsitem:nth-last-child(-n+2) {
      border-bottom: none; }
      @media screen and (min-width: 992px) {
        .excellence-meets__meetsitem:nth-last-child(-n+2) {
          padding-bottom: 5px; } }
    .excellence-meets__meetsitem:nth-child(5) {
      justify-content: center; }
      @media screen and (min-width: 770px) {
        .excellence-meets__meetsitem:nth-child(5) {
          justify-content: flex-start; } }
      @media screen and (min-width: 992px) {
        .excellence-meets__meetsitem:nth-child(5) {
          justify-content: center; } }
    .excellence-meets__meetsitem img {
      margin-right: 10px; }
      @media screen and (min-width: 992px) {
        .excellence-meets__meetsitem img {
          margin-right: 0; } }
  .excellence-meets__listcontent {
    margin-top: 5px;
    margin-left: 0;
    max-width: 100%;
    text-align: left; }
    @media screen and (min-width: 992px) {
      .excellence-meets__listcontent {
        margin-top: 0;
        margin-left: 15px;
        max-width: 162px; } }
  .excellence-meets__contenttittle {
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    color: #fff;
    margin: 0; }
    @media screen and (min-width: 992px) {
      .excellence-meets__contenttittle {
        font-size: 25px;
        line-height: 26px; } }
  .excellence-meets__contenttext {
    font-family: 'Poppins';
    color: #fff;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    margin: 0; }
  .excellence-meets__officetittle {
    margin: 30px 0 15px;
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #84A5F9;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .excellence-meets__officetittle {
        margin: 28px 0 15px;
        text-align: left; } }
  .excellence-meets__officelist {
    width: 100%;
    display: flex;
    padding: 6px 8px;
    border-radius: 5px;
    border: 1px solid #84A5F9; }
  .excellence-meets__officeitem {
    height: 120px;
    border-radius: 5px;
    overflow: hidden; }
  .excellence-meets__officeitems {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%; }
    .excellence-meets__officeitems:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.1); }
    .excellence-meets__officeitems img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
    .excellence-meets__officeitems:hover:before {
      background-color: rgba(0, 0, 0, 0.3); }
    .excellence-meets__officeitems:hover:after {
      position: absolute;
      content: "";
      width: 30px;
      height: 30px;
      background: url(/images/two-for-one/pop-up-icon.svg) no-repeat center;
      background-size: 100%;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin: auto; }
  .excellence-meets .office-wrapper {
    padding: 6px 8px;
    border-radius: 5px;
    border: 1px solid #84a5f9; }
  .excellence-meets__officebuttonarea {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  .excellence-meets__officebtn {
    font-family: 'Poppins';
    margin: 0 0 15px 0;
    max-width: 100%;
    width: 100%;
    font-size: 17px;
    font-weight: 600;
    padding: 17px 25px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .excellence-meets__officebtn {
        max-width: 215px;
        margin: 0 13px 0 0; } }
    .excellence-meets__officebtn svg {
      margin-left: 10px; }
    .excellence-meets__officebtn:hover g {
      fill: #ff5800; }
  .excellence-meets__takebtn {
    margin: 0;
    max-width: 100%;
    width: 100%;
    border: 2px solid #fff;
    color: #fff;
    font-family: 'Poppins';
    font-size: 17px;
    font-weight: 600;
    padding: 17px 25px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .excellence-meets__takebtn {
        max-width: 307px; } }
    .excellence-meets__takebtn img {
      max-width: 20px;
      margin-right: 10px; }
      @media screen and (min-width: 992px) {
        .excellence-meets__takebtn img {
          max-width: 30px;
          margin-right: 15px; } }
    .excellence-meets__takebtn svg {
      margin-left: 10px; }
    .excellence-meets__takebtn:hover {
      background-color: #fff;
      color: #ff5800; }
      .excellence-meets__takebtn:hover g {
        fill: #ff5800; }
  .excellence-meets .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0 25px;
    position: relative;
    gap: 0 10px;
    height: 45px;
    margin: 0; }
    @media screen and (min-width: 992px) {
      .excellence-meets .owl-dots {
        padding: 30px 0;
        top: 35px;
        height: 73px; } }
    .excellence-meets .owl-dots .owl-dot {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: #E1E1E1; }
      .excellence-meets .owl-dots .owl-dot.active {
        width: 13px;
        height: 13px;
        background: #FF5800; }
  .excellence-meets__asterisktext {
    color: #fff;
    margin: 5px 0 0;
    line-height: normal; }

.slbOverlay {
  z-index: 999999 !important;
  opacity: 0.9 !important; }

.slbWrapOuter {
  z-index: 999999 !important; }

@media screen and (min-width: 767px) {
  .home-excellence {
    border-top: 1px solid #cbcbcb; } }

@media screen and (min-width: 767px) {
  .home-excellence .col-lg-4 {
    padding-top: 30px; } }

/* ANCHOR -Custom Review*/
.custom-review {
  position: relative; }
  .custom-review__spacing {
    padding: 30px 0 35px; }
    @media screen and (min-width: 992px) {
      .custom-review__spacing {
        padding: 60px 0 50px; } }
    .custom-review__spacing--light {
      padding: 35px 0; }
      @media screen and (min-width: 992px) {
        .custom-review__spacing--light {
          padding: 50px 0; } }
  .custom-review__tittleimg {
    display: block;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .custom-review__tittleimg {
        text-align: left; } }
  .custom-review__tittle {
    position: relative;
    font-family: 'Poppins';
    font-size: 25px;
    line-height: 35px;
    color: #262E36;
    padding-bottom: 20px;
    margin-bottom: 22px;
    font-weight: 700;
    text-align: center; }
    @media screen and (min-width: 770px) {
      .custom-review__tittle {
        margin-top: 10px; } }
    @media screen and (min-width: 992px) {
      .custom-review__tittle {
        text-align: left;
        font-size: 34px;
        line-height: 46px;
        margin-top: 25px;
        padding-bottom: 34px; } }
    .custom-review__tittle:after {
      position: absolute;
      content: "";
      width: 83px;
      height: 7px;
      background-color: #498DF9;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
      @media screen and (min-width: 992px) {
        .custom-review__tittle:after {
          right: inherit;
          margin: 0; } }
  .custom-review__subtittle {
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 27px;
    color: #333;
    font-weight: 600;
    margin: 0 0 15px;
    text-align: left; }
    @media screen and (min-width: 992px) {
      .custom-review__subtittle {
        font-size: 22px;
        line-height: 32px;
        text-align: left;
        padding: 0 20px 0 0; } }
  .custom-review__reviewarea {
    position: relative; }
    .custom-review__reviewarea .owl-nav {
      display: block !important;
      text-align: left;
      margin-bottom: 0;
      margin-top: 0; }
      @media screen and (min-width: 992px) {
        .custom-review__reviewarea .owl-nav {
          float: right;
          margin-bottom: 0;
          margin-top: 10px;
          text-align: center; } }
    .custom-review__reviewarea .splide__arrow--prev {
      font-size: 45px !important;
      color: #3cc065 !important;
      width: 30px;
      height: 30px;
      border: 1px solid #3cc065 !important;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300 !important;
      background: none; }
      @media screen and (min-width: 992px) {
        .custom-review__reviewarea .splide__arrow--prev {
          width: 50px;
          height: 50px; } }
      .custom-review__reviewarea .splide__arrow--prev:hover {
        color: #fff !important;
        background-color: #3cc065 !important; }
        .custom-review__reviewarea .splide__arrow--prev:hover svg path {
          fill: #fff; }
      .custom-review__reviewarea .splide__arrow--prev span {
        position: relative;
        top: -3px;
        left: -1px;
        height: 50px;
        line-height: 50px; }
    .custom-review__reviewarea .splide__arrow--next {
      font-size: 45px !important;
      color: #3cc065 !important;
      width: 30px;
      height: 30px;
      border: 1px solid #3cc065 !important;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300 !important;
      background: none; }
      @media screen and (min-width: 992px) {
        .custom-review__reviewarea .splide__arrow--next {
          width: 50px;
          height: 50px; } }
      .custom-review__reviewarea .splide__arrow--next:hover {
        color: #fff !important;
        background-color: #3cc065 !important; }
        .custom-review__reviewarea .splide__arrow--next:hover svg path {
          fill: #fff; }
      .custom-review__reviewarea .splide__arrow--next span {
        position: relative;
        top: -3px;
        right: -1px;
        height: 50px;
        line-height: 50px; }
  .custom-review__reviewitem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 18px; }
  .custom-review__reviewitems {
    max-width: 100%;
    width: 100%;
    min-height: 300px;
    height: auto;
    border: 1px solid #ddd;
    padding: 25px;
    margin-bottom: 5px;
    transition: all .5s ease-in-out;
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 1024px) {
      .custom-review__reviewitems {
        max-width: 277px;
        margin-bottom: 15px; } }
    @media screen and (min-width: 1120px) {
      .custom-review__reviewitems {
        max-width: 330px; } }
    @media screen and (min-width: 1300px) {
      .custom-review__reviewitems {
        max-width: 345px; } }
    .custom-review__reviewitems:hover {
      border: 1px solid #fff;
      box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.2); }
  .custom-review__quoteimg {
    display: block;
    margin-bottom: 10px; }
  .custom-review__quotecontent {
    font-family: 'Poppins';
    color: #333;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500; }
  .custom-review__authorarea {
    margin-top: 30px;
    display: flex; }
    @media screen and (min-width: 992px) {
      .custom-review__authorarea {
        margin-top: auto; } }
  .custom-review__authorimage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden; }
  .custom-review__authordetails {
    margin-left: 16px; }
  .custom-review__authorname {
    font-family: 'Poppins';
    font-size: 18px;
    line-height: normal;
    color: #333;
    font-weight: 600;
    margin: 0; }
  .custom-review__authordesignation {
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 20px;
    color: #333;
    margin: 0;
    font-weight: 600; }
  .custom-review__authorcompany {
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 20px;
    color: #898989;
    margin: 0;
    font-weight: 500; }
  .custom-review__btn {
    margin: 0 auto;
    max-width: 360px;
    width: auto;
    font-family: 'Poppins';
    font-size: 17px;
    font-weight: 600;
    padding: 17px 25px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .custom-review__btn {
        max-width: 333px;
        margin-left: 15px;
        width: 100%;
        margin: 0 0 0 14px; } }
    .custom-review__btn svg {
      margin-left: 10px; }
    .custom-review__btn:hover g {
      fill: #ff5800; }
  .custom-review__partner {
    column-count: 2;
    margin: 18px 0 25px; }
  .custom-review__partnerlist {
    min-height: 120px;
    margin: 0 20px 7px 0;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (min-width: 992px) {
      .custom-review__partnerlist {
        margin: 0 30px 10px 0; } }
    .custom-review__partnerlist:nth-last-child(-n+2) {
      margin: 0 30px 0 0; }
  .custom-review__rankribbon {
    position: relative;
    font-family: 'Poppins';
    color: #fff;
    font-size: 21px;
    line-height: 34px;
    font-weight: 700;
    max-width: 318px;
    width: 100%;
    text-align: center;
    margin: auto;
    background-color: #3CC065;
    padding: 5.5px;
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .custom-review__rankribbon {
        margin-bottom: 0; } }
    .custom-review__rankribbon:after {
      position: absolute;
      content: "";
      right: 0;
      bottom: -16px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 16px 30px 0 0;
      border-color: #4E6454 transparent transparent transparent;
      transform: rotate(0deg); }
  .custom-review .col-lg-8-rel {
    position: relative; }
  .custom-review .sk-fading-circle {
    margin: 0 auto;
    position: absolute;
    text-align: center;
    top: 120px;
    left: 0;
    right: 0; }
    @media screen and (min-width: 992px) {
      .custom-review .sk-fading-circle {
        top: 239px; } }
  .custom-review .cs-cont.invisible {
    height: 324px; }
    @media screen and (min-width: 992px) {
      .custom-review .cs-cont.invisible {
        height: 648px; } }
  .custom-review .splide__arrows {
    display: flex;
    position: absolute;
    bottom: -6px;
    right: 40px; }
    @media screen and (min-width: 992px) {
      .custom-review .splide__arrows {
        bottom: -30px;
        right: 53px; } }
  .custom-review .splide__arrow--prev {
    right: 8px;
    left: inherit; }
    @media screen and (min-width: 992px) {
      .custom-review .splide__arrow--prev {
        right: 20px; } }
    .custom-review .splide__arrow--prev svg {
      width: 18px; }
  .custom-review .splide__arrow--next {
    right: -40px;
    left: inherit; }
    .custom-review .splide__arrow--next svg {
      width: 18px; }

.trustpilot-widget-mod {
  top: 20px; }

.custom-review__reviewitem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  @media screen and (min-width: 992px) {
    .custom-review__reviewitem .slide {
      padding: 0 15px 0 15px; } }

.grt-slider-light .splide__arrows {
  display: none; }

/* ANCHOR -Appointment*/
.o-appoinrment-header {
  background-color: #0d2642;
  padding: 30px 0;
  min-height: auto; }
  @media screen and (min-width: 992px) {
    .o-appoinrment-header {
      min-height: 117px; } }
  .o-appoinrment-header__logopan {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    align-items: center;
    height: 100%;
    min-height: 57px; }
    @media screen and (min-width: 992px) {
      .o-appoinrment-header__logopan {
        justify-content: flex-start;
        margin-bottom: 0; } }
  .o-appoinrment-header__logoicon {
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid #a3c2d0;
    max-width: 48%; }
    @media screen and (min-width: 992px) {
      .o-appoinrment-header__logoicon {
        max-width: 160px;
        margin-right: 27px;
        padding-right: 27px; } }
    .o-appoinrment-header__logoicon:last-child {
      border: none;
      margin: 0;
      padding: 0; }
  .o-appoinrment-header__list {
    padding: 0 5px; }
    @media screen and (min-width: 992px) {
      .o-appoinrment-header__list {
        padding: 0 20px; } }
    .o-appoinrment-header__list:before {
      position: absolute;
      content: "";
      width: 18px;
      height: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      background: #0d2642;
      background: linear-gradient(270deg, rgba(13, 38, 66, 0) 0%, #0d2642 45%);
      z-index: 9999; }
      @media screen and (min-width: 992px) {
        .o-appoinrment-header__list:before {
          width: 30px; } }
    .o-appoinrment-header__list:after {
      position: absolute;
      content: "";
      width: 18px;
      height: 100%;
      right: 0;
      top: 0;
      bottom: 0;
      background: #0d2642;
      background: linear-gradient(90deg, rgba(13, 38, 66, 0) 0%, #0d2642 45%);
      z-index: 9999; }
      @media screen and (min-width: 992px) {
        .o-appoinrment-header__list:after {
          width: 30px; } }
  .o-appoinrment-header__listarea .o-appoinrment-header__item {
    width: auto !important; }
  .o-appoinrment-header__item {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 57px;
    padding: 0 10px; }
    @media screen and (min-width: 992px) {
      .o-appoinrment-header__item {
        padding: 0 13px; } }
  .o-appoinrment-header__itemcontent {
    margin-left: 14px;
    flex: 1; }
  .o-appoinrment-header__itemtittle {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
    margin: 0;
    color: #fff; }
    @media screen and (min-width: 992px) {
      .o-appoinrment-header__itemtittle {
        font-size: 20px;
        line-height: 24px; } }
  .o-appoinrment-header__itemsub {
    font-family: "Poppins";
    font-size: 13px;
    line-height: 18px;
    margin: 0;
    font-weight: 500;
    color: #fff; }
    @media screen and (min-width: 992px) {
      .o-appoinrment-header__itemsub {
        font-size: 15px;
        line-height: 20px; } }

.o-appointment {
  padding-bottom: 22px;
  border-bottom: 1px solid #a3c2d0;
  position: relative;
  background: #e3f3fa;
  background: linear-gradient(180deg, #e3f3fa 0%, rgba(235, 246, 251, 0.6) 66%, white 100%); }
  @media screen and (min-width: 992px) {
    .o-appointment {
      background: transparent; } }
  .o-appointment:before {
    position: absolute;
    content: "";
    width: 50%;
    height: 100%;
    background: #e3f3fa;
    background: linear-gradient(180deg, #e3f3fa 0%, rgba(235, 246, 251, 0.6) 66%, white 100%);
    left: 0;
    top: 0;
    bottom: 0;
    display: none; }
    @media screen and (min-width: 992px) {
      .o-appointment:before {
        display: block; } }
  .o-appointment:after {
    opacity: 0;
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
    animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease 5s;
    -moz-animation: fadeIn ease 5s;
    -o-animation: fadeIn ease 5s;
    -ms-animation: fadeIn ease 5s; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes fadeIn {
  .o-appointment 0% {
    opacity: 0; }
  .o-appointment 100% {
    opacity: 1; } }
  .o-appointment.js-grayed-out:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #a3c2d0;
    background: linear-gradient(0deg, rgba(163, 194, 208, 0) 0%, rgba(163, 194, 208, 0.1) 35%, rgba(163, 194, 208, 0.6) 50%, rgba(163, 194, 208, 0.3) 70%, rgba(163, 194, 208, 0) 100%);
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    filter: grayscale(0);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    opacity: 1;
    animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease .5s;
    -moz-animation: fadeIn ease .5s;
    -o-animation: fadeIn ease .5s;
    -ms-animation: fadeIn ease .5s;
    filter: grayscale(1); }
    @media screen and (min-width: 992px) {
      .o-appointment.js-grayed-out:after {
        width: 50%;
        height: 86%; } }
  .o-appointment .right-border-transparent {
    position: relative; }
    .o-appointment .right-border-transparent:before {
      position: absolute;
      content: "";
      width: 1px;
      height: 100%;
      right: 0;
      top: 0;
      bottom: 0;
      background: #a3c2d0;
      background: linear-gradient(180deg, #a3c2d0 0%, rgba(163, 194, 208, 0.62) 70%, rgba(163, 194, 208, 0) 100%);
      display: none; }
      @media screen and (min-width: 992px) {
        .o-appointment .right-border-transparent:before {
          display: block; } }
  .o-appointment__ortext {
    position: absolute;
    font-family: "Poppins";
    font-size: 25px;
    font-weight: 600;
    color: #fff;
    width: 50px;
    height: 50px;
    background: #4492DC;
    border-radius: 50%;
    left: 0;
    right: 0;
    top: 52px;
    margin: auto;
    align-items: center;
    justify-content: center;
    z-index: 9;
    display: none; }
    @media screen and (min-width: 992px) {
      .o-appointment__ortext {
        display: flex; } }
  .o-appointment__formpanel {
    position: relative;
    width: 100%;
    height: auto;
    padding: 2em 0; }
    @media screen and (min-width: 992px) {
      .o-appointment__formpanel {
        padding: 52px 0 0;
        background: transparent; } }
  .o-appointment__tittle {
    position: relative;
    font-family: "Poppins";
    color: #262e36;
    font-size: 24px;
    line-height: 38px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 12px; }
    @media screen and (min-width: 992px) {
      .o-appointment__tittle {
        font-size: 28px;
        line-height: 46.5px;
        justify-content: flex-start; } }
    .o-appointment__tittle i {
      margin-right: 10px; }
  .o-appointment__subtittle {
    font-family: "Poppins";
    color: #262e36;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center; }
    .o-appointment__subtittle span {
      border-bottom: 1px solid #848484; }
    @media screen and (min-width: 992px) {
      .o-appointment__subtittle {
        font-size: 21px;
        line-height: 29px;
        text-align: left; } }
  .o-appointment--conversation-form {
    max-width: 100%;
    width: 100%;
    margin: auto; }
    @media screen and (min-width: 992px) {
      .o-appointment--conversation-form {
        padding-right: 3em; } }
    .o-appointment--conversation-form input {
      font-family: "Poppins";
      max-width: 100%;
      width: 100%;
      height: 69px;
      border: 1px solid #7fa0b0;
      margin: 0 0 20px 0;
      display: inline-flex;
      padding: 10px 0 10px 16px;
      font-size: 17px;
      font-weight: 500;
      color: #262e36;
      border-radius: 5px; }
      .o-appointment--conversation-form input[type=number] {
        -moz-appearance: textfield;
        -webkit-appearance: none;
        appearance: none; }
      .o-appointment--conversation-form input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .o-appointment--conversation-form input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .o-appointment--conversation-form input.fileattached-url-mod {
        height: auto;
        padding: 0;
        margin: 0; }
      .o-appointment--conversation-form input#phoneNumber {
        background: #fff !important; }
      .o-appointment--conversation-form input::-webkit-input-placeholder {
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 17px !important;
        color: #262e36 !important; }
      .o-appointment--conversation-form input:-moz-placeholder {
        /* Firefox 18- */
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 17px !important;
        color: #262e36 !important; }
      .o-appointment--conversation-form input::-moz-placeholder {
        /* Firefox 19+ */
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 17px !important;
        color: #262e36 !important; }
      .o-appointment--conversation-form input:-ms-input-placeholder {
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 17px !important;
        color: #262e36 !important; }
    .o-appointment--conversation-form textarea {
      font-family: "Poppins";
      max-width: 100%;
      width: 100%;
      height: 157px;
      border: 1px solid #7fa0b0;
      margin: 0 0 20px 0;
      float: left;
      padding: 20px 16px 16px;
      color: #262e36;
      font-size: 16px;
      font-weight: 500;
      resize: none;
      border-radius: 5px; }
      .o-appointment--conversation-form textarea::-webkit-input-placeholder {
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 16px !important;
        color: #262e36 !important; }
      .o-appointment--conversation-form textarea:-moz-placeholder {
        /* Firefox 18- */
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 16px !important;
        color: #262e36 !important; }
      .o-appointment--conversation-form textarea::-moz-placeholder {
        /* Firefox 19+ */
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 16px !important;
        color: #262e36 !important; }
      .o-appointment--conversation-form textarea:-ms-input-placeholder {
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 16px !important;
        color: #262e36 !important; }
  .o-appointment__formwrapper {
    position: relative;
    box-shadow: none !important; }
  .o-appointment__formwrapperinside {
    padding: 0;
    background: transparent;
    box-shadow: none;
    overflow: visible; }
    .o-appointment__formwrapperinside:before {
      display: none; }
    .o-appointment__formwrapperinside:after {
      display: none; }
    .o-appointment__formwrapperinside .o-contact-form__contact-form {
      padding: 0; }
  .o-appointment__calender {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 30px 0 15px;
    margin-top: 50px;
    z-index: 9; }
    @media screen and (min-width: 992px) {
      .o-appointment__calender {
        margin-top: inherit;
        padding: 52px 0 2em 3em; } }
    .o-appointment__calender:before {
      position: absolute;
      content: "Or";
      font-family: "Poppins";
      font-size: 25px;
      font-weight: 600;
      color: #fff;
      width: 50px;
      height: 50px;
      background: #4492DC;
      border-radius: 50%;
      left: 0;
      right: 0;
      top: -45px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1; }
      @media screen and (min-width: 992px) {
        .o-appointment__calender:before {
          display: none; } }
    .o-appointment__calender:after {
      display: block;
      position: absolute;
      content: "";
      background: #a3c2d0;
      background: linear-gradient(270deg, rgba(163, 194, 208, 0) 0%, rgba(163, 194, 208, 0.68) 50%, rgba(163, 194, 208, 0) 100%);
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      top: -20px;
      margin: auto; }
      @media screen and (min-width: 992px) {
        .o-appointment__calender:after {
          display: none; } }
  .o-appointment__attach {
    position: relative;
    background: url(/images/two-for-one/attach-icon-black.svg) no-repeat center;
    background-position: 0 5px !important;
    padding: 0 0 0 35px !important;
    display: flex;
    width: 100%;
    overflow: hidden;
    margin: 10px 0 0 !important; }
    @media screen and (min-width: 992px) {
      .o-appointment__attach {
        margin: 10px 0 !important; } }
    .o-appointment__attach .o-contact-form__attachement-text {
      font-family: "Poppins";
      color: #262e36;
      font-size: 16px;
      line-height: 30px; }
      .o-appointment__attach .o-contact-form__attachement-text label {
        text-decoration: none;
        font-weight: 600; }
  .o-appointment__calendercontainer #calendar {
    max-width: 375px;
    margin: auto; }
    .o-appointment__calendercontainer #calendar .ui-datepicker-header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0 5px; }
      .o-appointment__calendercontainer #calendar .ui-datepicker-header .ui-datepicker-prev {
        order: 1;
        cursor: pointer; }
        .o-appointment__calendercontainer #calendar .ui-datepicker-header .ui-datepicker-prev .ui-icon {
          font-size: 0;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #3cc065;
          border-radius: 50%;
          transition: .3s all ease-in-out; }
          .o-appointment__calendercontainer #calendar .ui-datepicker-header .ui-datepicker-prev .ui-icon:hover {
            background: #ff5800; }
          .o-appointment__calendercontainer #calendar .ui-datepicker-header .ui-datepicker-prev .ui-icon:after {
            content: "‹";
            font-size: 30px;
            line-height: 0;
            color: #fff;
            position: relative;
            top: -2px;
            left: -1px; }
        .o-appointment__calendercontainer #calendar .ui-datepicker-header .ui-datepicker-prev.ui-state-disabled {
          pointer-events: none;
          opacity: .4; }
          .o-appointment__calendercontainer #calendar .ui-datepicker-header .ui-datepicker-prev.ui-state-disabled .ui-icon {
            filter: grayscale(100%); }
      .o-appointment__calendercontainer #calendar .ui-datepicker-header .ui-datepicker-title {
        order: 2;
        font-family: "Poppins";
        margin: 0 15px;
        font-weight: 400;
        font-size: 16px; }
      .o-appointment__calendercontainer #calendar .ui-datepicker-header .ui-datepicker-next {
        order: 3;
        cursor: pointer; }
        .o-appointment__calendercontainer #calendar .ui-datepicker-header .ui-datepicker-next .ui-icon {
          font-size: 0;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #3cc065;
          border-radius: 50%;
          transition: .3s all ease-in-out; }
          .o-appointment__calendercontainer #calendar .ui-datepicker-header .ui-datepicker-next .ui-icon:hover {
            background: #ff5800; }
          .o-appointment__calendercontainer #calendar .ui-datepicker-header .ui-datepicker-next .ui-icon:after {
            content: "›";
            font-size: 30px;
            line-height: 0;
            color: #fff;
            position: relative;
            top: -2px;
            right: -1px; }
        .o-appointment__calendercontainer #calendar .ui-datepicker-header .ui-datepicker-next.ui-state-disabled {
          pointer-events: none;
          opacity: .4; }
          .o-appointment__calendercontainer #calendar .ui-datepicker-header .ui-datepicker-next.ui-state-disabled .ui-icon {
            filter: grayscale(100%); }
    .o-appointment__calendercontainer #calendar .ui-datepicker-calendar {
      width: 100%;
      font-family: "Poppins";
      font-size: 12px; }
      .o-appointment__calendercontainer #calendar .ui-datepicker-calendar th {
        font-weight: 400;
        text-align: center;
        height: 44px;
        width: 44px;
        color: #1a1a1a;
        text-transform: uppercase; }
      .o-appointment__calendercontainer #calendar .ui-datepicker-calendar td {
        font-size: 16px;
        text-align: center;
        height: 44px;
        width: 44px;
        color: rgba(26, 26, 26, 0.612); }
  .o-appointment__calendercontainer .ui-datepicker-week-end.ui-datepicker-today {
    background: none;
    pointer-events: none; }
    .o-appointment__calendercontainer .ui-datepicker-week-end.ui-datepicker-today a {
      background: none;
      color: inherit;
      font-weight: 500; }
      .o-appointment__calendercontainer .ui-datepicker-week-end.ui-datepicker-today a::after {
        display: none; }
  .o-appointment__calendertittle {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 32px;
    color: #333;
    text-align: center; }
  .o-appointment__emailtext {
    position: relative;
    font-family: "Poppins";
    font-size: 20px;
    line-height: 32px;
    color: #333;
    font-weight: 600;
    margin-top: 25px; }
    @media screen and (min-width: 992px) {
      .o-appointment__emailtext {
        font-size: 22px;
        line-height: 36px;
        margin-top: 50px;
        z-index: 3; } }
  .o-appointment__emailanchor {
    color: #4a90e2;
    text-decoration: underline; }
  .o-appointment .c-btn {
    font-family: "Poppins";
    max-width: 100% !important;
    width: 100%;
    margin: 20px 0 0;
    text-transform: capitalize;
    font-size: 17px;
    padding: 15px;
    float: none;
    z-index: 2; }
    @media screen and (min-width: 992px) {
      .o-appointment .c-btn {
        max-width: 250px !important;
        float: right;
        margin: 0 0 0; } }
    .o-appointment .c-btn svg {
      margin-left: 10px; }
    .o-appointment .c-btn:hover {
      background: #fff;
      color: #ff5800; }
      .o-appointment .c-btn:hover g {
        fill: #ff5800; }
  .o-appointment__flipform {
    max-width: 400px;
    width: 100%;
    margin: 30px auto 0; }
    .o-appointment__flipform input {
      font-family: "Poppins";
      max-width: 100%;
      width: 100%;
      height: 69px;
      border: 1px solid #7fa0b0;
      margin: 0 0 20px 0;
      display: inline-flex;
      padding: 20px 0 20px 16px;
      font-size: 17px;
      font-weight: 500;
      color: #262e36;
      border-radius: 5px; }
      .o-appointment__flipform input::-webkit-input-placeholder {
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 17px !important;
        color: #262e36 !important; }
      .o-appointment__flipform input:-moz-placeholder {
        /* Firefox 18- */
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 17px !important;
        color: #262e36 !important; }
      .o-appointment__flipform input::-moz-placeholder {
        /* Firefox 19+ */
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 17px !important;
        color: #262e36 !important; }
      .o-appointment__flipform input:-ms-input-placeholder {
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 17px !important;
        color: #262e36 !important; }
    .o-appointment__flipform .c-btn {
      border-radius: 5px;
      font-weight: 600;
      float: none !important;
      margin: 15px auto 0 !important;
      max-width: 100% !important; }
      .o-appointment__flipform .c-btn:disabled {
        pointer-events: none;
        background-color: #f2b493;
        border-color: #f2b493; }

.ui-datepicker-current a {
  position: relative;
  background-color: rgba(0, 105, 255, 0.067);
  color: #0060e6;
  font-weight: 700;
  width: 44px;
  height: 100%;
  border-radius: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center; }
  .ui-datepicker-current a:hover {
    background-color: rgba(0, 105, 255, 0.149); }
  .ui-datepicker-current a:after {
    content: ".";
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    margin: auto;
    font-weight: bold;
    font-size: 25px; }

.ui-datepicker-noneworking a {
  background-color: rgba(0, 105, 255, 0.067);
  color: #0060e6;
  font-weight: 700;
  width: 44px;
  height: 100%;
  border-radius: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center; }
  .ui-datepicker-noneworking a:hover {
    background-color: rgba(0, 105, 255, 0.149); }

.flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
  border-radius: 5px;
  border: 1px solid #a3c2d0;
  border-top: 5px solid #3cc065;
  position: relative;
  overflow: hidden; }

.flip-container.hover .flipper {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg); }

.flip-container,
.front,
.back {
  width: 350px;
  height: 430px;
  margin: auto; }
  @media screen and (min-width: 767px) {
    .flip-container,
    .front,
    .back {
      width: 400px; } }
  @media screen and (min-width: 992px) {
    .flip-container,
    .front,
    .back {
      margin: 0; } }
  @media screen and (min-width: 1025px) {
    .flip-container,
    .front,
    .back {
      width: 517px; } }

.flipper {
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative; }

.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 15px; }
  .front .back-logo,
  .back .back-logo {
    position: relative;
    margin: 0 auto 25px;
    max-width: 428px; }
  .front .back-anchore,
  .back .back-anchore {
    font-family: "Poppins";
    position: relative;
    font-size: 20px;
    line-height: 26px;
    color: #262e36;
    font-weight: 600; }
    .front .back-anchore svg,
    .back .back-anchore svg {
      position: relative;
      margin-right: 4px;
      margin-top: -2px;
      transition: 1s all ease-in-out; }
    .front .back-anchore:hover,
    .back .back-anchore:hover {
      color: #ff5800; }
      .front .back-anchore:hover svg,
      .back .back-anchore:hover svg {
        transform: translate(-10px, 0); }
        .front .back-anchore:hover svg g,
        .back .back-anchore:hover svg g {
          stroke: #ff5800; }

.front {
  z-index: 2; }

.back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background: #F5F9FA;
  z-index: 3; }

body.js-mobilebodygray {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative; }
  body.js-mobilebodygray .mobile-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(163, 194, 208, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s; }
  body.js-mobilebodygray .flip-container {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 999;
    background: #fff; }
  body.js-mobilebodygray .o-appointment {
    position: initial; }
  body.js-mobilebodygray .o-appointment__calender {
    position: initial; }

.js-conversation-error {
  text-align: left; }
  @media screen and (min-width: 1180px) {
    .js-conversation-error {
      position: absolute;
      left: 0;
      bottom: -78px; } }

/* ANCHOR -New Contact*/
.contact-new {
  background: url(/images/new-contact/mobile-bg.jpg) no-repeat center center/cover;
  overflow: visible;
  padding: 85px 0 0 !important;
  height: 860px; }
  @media screen and (min-width: 992px) {
    .contact-new {
      background: url(/images/new-contact/banner-bg.jpg) no-repeat center center/cover; } }
  @media screen and (min-width: 992px) {
    .contact-new {
      padding: 120px 20px 0 !important;
      height: auto; } }
  .contact-new .o-contact-us {
    padding-bottom: 0; }
  .contact-new .o-contact-us-new h1 {
    font-family: 'Poppins';
    font-size: 31px !important;
    line-height: 38px !important;
    font-weight: 700;
    position: relative;
    margin-bottom: 30px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .contact-new .o-contact-us-new h1 {
        font-size: 40px !important;
        line-height: 46px !important;
        text-align: left;
        padding-bottom: 30px; } }
    .contact-new .o-contact-us-new h1::before {
      content: '';
      background: #fff;
      position: absolute;
      top: inherit;
      bottom: 0;
      left: 0;
      width: 83px;
      height: 7px;
      display: none; }
      @media screen and (min-width: 992px) {
        .contact-new .o-contact-us-new h1::before {
          display: block; } }
    .contact-new .o-contact-us-new h1 span {
      color: #3CC065; }
  .contact-new .o-contact-us-new h3 {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 30px; }
  .contact-new__banner-logo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 30px 0; }
  .contact-new__logo-img {
    flex: 0 0 50%; }
  .contact-new .o-contact-form__contact-form {
    padding: 0 15px 20px !important; }
    @media screen and (min-width: 992px) {
      .contact-new .o-contact-form__contact-form {
        padding: 20px 40px 40px !important; } }
    .contact-new .o-contact-form__contact-form input {
      border: 1px solid #CFCFCF;
      border-radius: 3px;
      background-image: none;
      padding: 17px 15px;
      margin-bottom: 20px;
      width: 100%; }
      .contact-new .o-contact-form__contact-form input[type=number] {
        -moz-appearance: textfield;
        -webkit-appearance: none;
        appearance: none; }
      .contact-new .o-contact-form__contact-form input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .contact-new .o-contact-form__contact-form input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .contact-new .o-contact-form__contact-form input#phoneNumber {
        background-image: none; }
      .contact-new .o-contact-form__contact-form input::placeholder {
        font-size: 16px;
        font-weight: 400 !important;
        color: #333 !important; }
    .contact-new .o-contact-form__contact-form textarea {
      border: 1px solid #CFCFCF;
      border-radius: 3px;
      background-image: none;
      padding: 15px;
      width: 100%;
      height: 110px; }
      .contact-new .o-contact-form__contact-form textarea::placeholder {
        font-size: 16px;
        font-weight: 400 !important;
        color: #333 !important; }
    .contact-new .o-contact-form__contact-form .u-message {
      border: 0; }
    .contact-new .o-contact-form__contact-form h2 {
      font-family: 'Poppins';
      font-size: 31px !important;
      font-weight: 600;
      color: #262E36;
      text-align: left !important;
      display: block !important; }
      @media screen and (min-width: 992px) {
        .contact-new .o-contact-form__contact-form h2 {
          font-size: 51px !important; } }
    @media screen and (min-width: 992px) {
      .contact-new .o-contact-form__contact-form .confidential-text-wrap {
        display: flex;
        align-items: center; } }
    .contact-new .o-contact-form__contact-form .u-submit.s-btn-modifier {
      font-family: 'Poppins';
      max-width: 255px;
      font-size: 17px;
      font-weight: 600;
      text-transform: capitalize;
      padding: 16px 0; }
      .contact-new .o-contact-form__contact-form .u-submit.s-btn-modifier:hover {
        background-color: #fff;
        color: #ff5800; }
        .contact-new .o-contact-form__contact-form .u-submit.s-btn-modifier:hover svg path {
          fill: #ff5800; }
  @media screen and (min-width: 992px) {
    .contact-new__col-left {
      flex: 1; } }
  @media screen and (min-width: 992px) {
    .contact-new__col-right {
      width: 61%; } }
  .contact-new .o-contact-form__attachement-text {
    font-family: 'Poppins';
    font-size: 15px;
    color: #333; }
  .contact-new .attach {
    background-position: 0 0; }
  .contact-new .u-message {
    margin-bottom: 20px; }
    .contact-new .u-message span#requirements_feedback {
      right: 40px; }
  .contact-new .confidential-text-wrap__inverse ul {
    flex: 1;
    justify-content: flex-start; }
  .contact-new .confidential-text-wrap__inverse li {
    font-family: 'Poppins';
    font-size: 16px;
    color: #333; }
    .contact-new .confidential-text-wrap__inverse li:first-child {
      padding-left: 0; }
    .contact-new .confidential-text-wrap__inverse li img {
      width: 15px;
      height: 15px; }
  .contact-new .o-contact-form {
    overflow: visible; }
  .contact-new .o-contact-us-new .cn-form {
    overflow: visible;
    border-radius: 0;
    padding: 20px 0 15px !important;
    position: relative;
    top: -10px;
    border: none; }
    @media screen and (min-width: 992px) {
      .contact-new .o-contact-us-new .cn-form {
        position: static;
        padding: 20px 0 65px !important;
        border-bottom: 1px solid #ebebeb; } }
    .contact-new .o-contact-us-new .cn-form:after {
      background: url(/images/new-contact/same-day-response-badge.svg) no-repeat 0 0;
      top: -44px;
      right: 20px;
      bottom: inherit;
      left: inherit;
      width: 186px;
      height: 170px; }
  .contact-new__more {
    font-family: 'Poppins';
    margin-top: 15px;
    font-size: 16px;
    font-weight: 300;
    color: #FFFFFF;
    display: inline-block; }
  .contact-new .contact-require-message {
    position: absolute;
    bottom: 0;
    left: 12px; }
  .contact-new .u-message .contact-require-message {
    left: 0;
    bottom: -14px; }

.contact-meets {
  position: relative;
  border-bottom: 1px solid #CBCBCB; }
  @media screen and (min-width: 992px) {
    .contact-meets {
      padding-bottom: 50px; } }
  .contact-meets:before {
    display: none; }
    @media screen and (min-width: 992px) {
      .contact-meets:before {
        display: block;
        content: '';
        position: absolute;
        background: #355BBF;
        top: 0;
        right: 0;
        bottom: 0;
        width: 58%;
        height: 100%; } }
  .contact-meets .excellence-meets__tittle p {
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    color: #333 !important; }
  .contact-meets h3 {
    font-family: 'Poppins';
    color: #fff;
    font-size: 31px;
    font-weight: 700; }
    @media screen and (min-width: 992px) {
      .contact-meets h3 {
        font-size: 51px; } }
  .contact-meets__wrapper {
    position: relative; }
    @media screen and (min-width: 992px) {
      .contact-meets__wrapper {
        display: flex; } }
  .contact-meets__left {
    flex: 0 0 40%;
    padding-right: 0;
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .contact-meets__left {
        padding-right: 0;
        padding-bottom: 0;
        margin-bottom: 0; } }
    .contact-meets__left:before {
      content: '';
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      background: #6385DA;
      width: 100%;
      height: 1px; }
      @media screen and (min-width: 992px) {
        .contact-meets__left:before {
          content: '';
          position: absolute;
          left: inherit;
          right: 0;
          top: 0;
          bottom: inherit;
          background: #6385DA;
          width: 1px;
          height: 100%; } }
  .contact-meets__left-card {
    padding-right: 30px; }
    .contact-meets__left-card--gurgaon {
      margin-top: 20px;
      border-top: 1px solid #6385da;
      padding-top: 20px; }
  .contact-meets__left-top {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .contact-meets__left-top span {
      font-family: 'Poppins';
      font-size: 25px;
      color: #fff;
      font-weight: 600;
      margin-left: 15px;
      display: inline-block; }
  .contact-meets__rightbox p {
    margin-bottom: 7px !important; }
  @media screen and (min-width: 992px) {
    .contact-meets__rightbox {
      padding-left: 50px;
      padding-right: 10px; } }
  .contact-meets__rightbox:first-child, .contact-meets__rightbox:nth-child(2) {
    margin-bottom: 20px;
    padding-bottom: 30px;
    border-bottom: 1px solid #6385da; }
    @media screen and (min-width: 992px) {
      .contact-meets__rightbox:first-child, .contact-meets__rightbox:nth-child(2) {
        margin-bottom: 18px;
        padding-bottom: 20px; } }
  .contact-meets__right-top {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .contact-meets__right-top span {
      font-family: 'Poppins';
      font-size: 25px;
      color: #fff;
      font-weight: 600;
      margin-left: 15px;
      display: inline-block; }
  .contact-meets .owl-dots {
    padding: 0px 0 20px;
    height: 30px;
    margin: 0; }
  .contact-meets .contact-location-spacing-new {
    background: #355BBF;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 45px; }
    @media screen and (min-width: 992px) {
      .contact-meets .contact-location-spacing-new {
        background: none;
        padding-left: 60px;
        padding-top: 45px;
        padding-bottom: 0;
        margin-top: 0; } }
    .contact-meets .contact-location-spacing-new h3 {
      margin-bottom: 20px;
      font-weight: 600; }
      @media screen and (min-width: 992px) {
        .contact-meets .contact-location-spacing-new h3 {
          margin-bottom: 35px;
          font-size: 42px;
          line-height: 50px; } }
    .contact-meets .contact-location-spacing-new p {
      font-family: 'Poppins';
      color: #fff;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px; }
    .contact-meets .contact-location-spacing-new a {
      font-family: 'Poppins';
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      text-decoration: underline; }
    .contact-meets .contact-location-spacing-new h4 {
      font-family: 'Poppins';
      color: #fff;
      font-size: 21px; }
  .contact-meets .col-lg-5 {
    padding-right: 15px; }
    @media screen and (min-width: 992px) {
      .contact-meets .col-lg-5 {
        padding-right: 50px;
        padding-top: 30px; } }
    @media screen and (min-width: 1600px) {
      .contact-meets .col-lg-5 {
        padding-right: 65px; } }
  .contact-meets .excellence-meets__clientlist {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-count: inherit;
    column-gap: inherit; }
  .contact-meets .excellence-meets__clientitem {
    display: inherit;
    flex: 0 0 28%; }
    @media screen and (min-width: 992px) {
      .contact-meets .excellence-meets__clientitem {
        margin-bottom: 30px;
        margin-right: 13px; } }

.team-fortune {
  background: none !important;
  padding: 40px 0 50px; }
  @media screen and (min-width: 992px) {
    .team-fortune {
      padding: 60px 0; } }
  .team-fortune h2 {
    font-family: 'Poppins';
    font-size: 25px;
    line-height: 38px;
    font-weight: 600;
    color: #262E36;
    text-align: left;
    position: relative;
    text-transform: none !important;
    padding-top: 80px;
    margin-bottom: 50px; }
    @media screen and (min-width: 992px) {
      .team-fortune h2 {
        font-size: 36px;
        line-height: 50px; } }
    .team-fortune h2 span {
      position: absolute;
      top: 0;
      left: 0; }
  .team-fortune ul {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; }
    @media screen and (min-width: 992px) {
      .team-fortune ul {
        gap: 0; } }
    .team-fortune ul li {
      display: flex;
      padding: 10px;
      flex: 0 0 48%;
      position: relative; }
      @media screen and (min-width: 992px) {
        .team-fortune ul li {
          border-bottom: 1px solid #DBDFE7;
          border-right: 1px solid #DBDFE7;
          padding: 25px 15px;
          flex: 0 0 25%; } }
      @media screen and (min-width: 1140px) {
        .team-fortune ul li {
          flex: 0 0 20%; } }
      @media screen and (min-width: 992px) {
        .team-fortune ul li:nth-child(4n+4) {
          border-right: 0; } }
      @media screen and (min-width: 1140px) {
        .team-fortune ul li:nth-child(4n+4) {
          border-right: 1px solid #DBDFE7; } }
      @media screen and (min-width: 1140px) {
        .team-fortune ul li:nth-child(5n+5) {
          border-right: 0; } }
      .team-fortune ul li:nth-child(6) .ywg__info span {
        font-family: 'Poppins';
        font-size: 24px;
        line-height: 27px; }
      .team-fortune ul li:nth-child(7) .ywg__info span {
        font-family: 'Poppins';
        font-size: 24px;
        line-height: 27px; }
      @media screen and (min-width: 1140px) {
        .team-fortune ul li:nth-last-child(-n+4) {
          border-bottom: none; } }
      @media screen and (min-width: 1140px) {
        .team-fortune ul li:nth-last-child(-n+3) {
          border-bottom: none; } }
      @media screen and (min-width: 992px) {
        .team-fortune ul li:nth-last-child(-n+2) {
          border-bottom: none; } }
      .team-fortune ul li:last-child {
        flex: 1;
        border-right: 0;
        border-bottom: 0; }
        .team-fortune ul li:last-child .ywg__info span {
          font-family: 'Poppins';
          font-size: 24px;
          line-height: 27px; }
      @media screen and (min-width: 992px) {
        .team-fortune ul li:before {
          content: '';
          position: absolute;
          bottom: -8px;
          right: -17px;
          background: #fff;
          width: 32px;
          height: 15px;
          z-index: 9; } }
      .team-fortune ul li i {
        flex: 0 0 auto; }
    .team-fortune ul .ywg__info {
      margin-left: 5px; }
      @media screen and (min-width: 992px) {
        .team-fortune ul .ywg__info {
          margin-left: 15px; } }
      .team-fortune ul .ywg__info span {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 23px;
        line-height: 18px; }
        @media screen and (min-width: 992px) {
          .team-fortune ul .ywg__info span {
            font-size: 26px; } }
      .team-fortune ul .ywg__info small {
        font-family: 'Poppins';
        font-size: 13px;
        line-height: 16px;
        display: block;
        font-weight: 500; }
        @media screen and (min-width: 992px) {
          .team-fortune ul .ywg__info small {
            font-size: 15px;
            line-height: 18px; } }
  .team-fortune .owl-dots {
    margin-bottom: 0; }
    .team-fortune .owl-dots .owl-dot.active {
      background: #FF5800; }
  .team-fortune .owl-stage-outer {
    position: relative; }
    .team-fortune .owl-stage-outer::before {
      content: '';
      position: absolute;
      right: 0;
      left: 0;
      margin: 0 auto;
      top: 0;
      background: #DBDFE7;
      height: 100%;
      width: 1px; }
  .team-fortune .splide__track::before {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    top: 0;
    background: #DBDFE7;
    height: 100%;
    width: 1px; }
  .team-fortune .splide__pagination {
    gap: 0; }
    .team-fortune .splide__pagination li {
      display: inline-block;
      padding: 0;
      flex: 0 0 auto !important; }
  .team-fortune__asterisktext {
    margin: 0;
    line-height: normal; }

.email-info {
  padding: 315px 0 50px;
  position: relative;
  border-bottom: 1px solid #CBCBCB; }
  @media screen and (min-width: 992px) {
    .email-info {
      padding: 60px 0; } }
  .email-info:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #E0E0E0;
    height: 1px;
    width: 88%;
    margin: 0 auto;
    display: block; }
    @media screen and (min-width: 992px) {
      .email-info:before {
        display: none; } }
  .email-info__wrapper {
    text-align: center; }
    @media screen and (min-width: 992px) {
      .email-info__wrapper {
        display: flex;
        max-width: 960px;
        margin: 0 auto;
        text-align: left; } }
  .email-info__left {
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .email-info__left {
        margin: 0; } }
    .email-info__left span {
      font-family: 'Poppins';
      font-size: 16px;
      line-height: 27px;
      color: #333; }
    .email-info__left a {
      font-family: 'Poppins';
      display: block;
      font-size: 20px;
      color: #4492DC;
      font-weight: 700;
      text-decoration: underline; }
      @media screen and (min-width: 992px) {
        .email-info__left a {
          font-size: 25px; } }
  @media screen and (min-width: 992px) {
    .email-info__right {
      padding-left: 180px; } }
  .email-info__right span {
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 27px;
    color: #333; }
  .email-info__right a {
    font-family: 'Poppins';
    display: block;
    font-size: 20px;
    color: #4492DC;
    font-weight: 700;
    text-decoration: underline; }
    @media screen and (min-width: 992px) {
      .email-info__right a {
        font-size: 25px; } }

.office-inside {
  position: relative;
  margin-top: 20px;
  padding: 0 15px; }
  @media screen and (min-width: 992px) {
    .office-inside {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 58%;
      margin-top: 0;
      padding: 0; } }
  .office-inside-images {
    height: 142px; }
    .office-inside-images img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.contact-dropdown {
  position: relative;
  margin-bottom: 20px;
  margin-bottom: 20px;
  text-align: left; }
  .contact-dropdown.open .dropdown-label {
    background: #f5f5f5; }
  .contact-dropdown .dropdown-list {
    padding: 25px 15px;
    background: #F5F5F5;
    position: absolute;
    top: 58px;
    left: 0;
    right: 0;
    border: 1px solid #CFCFCF;
    display: none;
    z-index: 10; }
    @media screen and (min-width: 992px) {
      .contact-dropdown .dropdown-list {
        padding: 25px 20px 5px; } }
  .contact-dropdown .checkbox {
    opacity: 0;
    transition: opacity .2s; }
  .contact-dropdown .dropdown-label {
    font-family: 'Poppins';
    display: block;
    height: 60px;
    font-size: 16px;
    line-height: 60px;
    color: #333;
    border: 1px solid #CFCFCF;
    padding: 0 40px 0 20px;
    cursor: pointer;
    position: relative;
    background: #ffffff;
    border-radius: 3px; }
    .contact-dropdown .dropdown-label:before {
      content: '';
      background: url(/images/new-contact/bottom-arrow.svg) no-repeat 0 0;
      width: 15px;
      height: 8px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      transition: transform .25s;
      transform-origin: center center; }
  .contact-dropdown.open .dropdown-list {
    display: block; }
    @media screen and (min-width: 992px) {
      .contact-dropdown.open .dropdown-list {
        display: flex;
        flex-wrap: wrap; } }
  .contact-dropdown.open .checkbox {
    transition: 2s opacity 2s;
    opacity: 1; }
  .contact-dropdown.open .dropdown-label:before {
    transform: translateY(-50%) rotate(-180deg); }

.contact-checkbox {
  margin-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .contact-checkbox {
      margin-bottom: 17px;
      flex: 0 0 45%;
      width: 45%; } }
  @media screen and (min-width: 992px) {
    .contact-checkbox:nth-child(odd) {
      flex: auto; } }
  .contact-checkbox:last-child {
    margin-bottom: 0; }
  .contact-checkbox .checkbox-custom {
    display: none; }
  .contact-checkbox .checkbox-custom-label {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer; }
  .contact-checkbox .checkbox-custom + .checkbox-custom-label:before {
    content: '';
    background: transparent;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    text-align: center;
    width: 16px;
    height: 16px;
    border: 1px solid #B6B2B2;
    border-radius: 3px;
    margin-top: -2px; }
  .contact-checkbox .checkbox-custom:checked + .checkbox-custom-label:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 5px;
    height: 12px;
    padding: 2px;
    transform: rotate(36deg);
    text-align: center;
    border: 1px solid #000;
    border-width: 0px 2px 2px 0;
    width: 6px; }
  .contact-checkbox .checkbox-custom-label {
    font-family: 'Poppins';
    line-height: 15px;
    font-size: 15px;
    margin-right: 0;
    margin-left: 0;
    color: #333; }
    @media screen and (min-width: 992px) {
      .contact-checkbox .checkbox-custom-label {
        font-size: 16px; } }

/* ANCHOR -Engagement Model*/
.o-engagement-banner {
  position: relative;
  background-color: #052C58; }
  .o-engagement-banner:before {
    position: absolute;
    content: "";
    width: 70%;
    height: 93%;
    background: #060a25;
    background: radial-gradient(circle, rgba(6, 10, 37, 0.7) 0, rgba(5, 44, 88, 0) 40%);
    border-radius: 50%;
    left: 0;
    top: 0; }
  .o-engagement-banner:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url(/images/new-home-banner/banner-gradient-inverse.png), url(/images/new-home-banner/banner-gradient.png);
    background-repeat: no-repeat;
    background-position: 100% 0,0 0;
    top: 0;
    left: 0;
    right: 0; }
  .o-engagement-banner .z-1 {
    z-index: 1; }
  .o-engagement-banner .col-55 {
    width: 100%;
    max-width: 100%; }
    @media screen and (min-width: 992px) {
      .o-engagement-banner .col-55 {
        flex: 0 0 auto;
        width: 55%; } }
  .o-engagement-banner .col-45 {
    width: 100%;
    max-width: 100%; }
    @media screen and (min-width: 992px) {
      .o-engagement-banner .col-45 {
        flex: 0 0 auto;
        width: 45%; } }
  .o-engagement-banner .col-52 {
    width: 100%;
    max-width: 100%; }
    @media screen and (min-width: 992px) {
      .o-engagement-banner .col-52 {
        flex: 0 0 auto;
        width: 52%;
        padding-right: 0; } }
  .o-engagement-banner .col-48 {
    width: 100%;
    max-width: 100%; }
    @media screen and (min-width: 992px) {
      .o-engagement-banner .col-48 {
        flex: 0 0 auto;
        width: 48%;
        padding-left: 0; } }
  .o-engagement-banner__spacing {
    padding: 110px 0 30px; }
    @media screen and (min-width: 992px) {
      .o-engagement-banner__spacing {
        padding: 150px 0 40px; } }
    @media screen and (min-width: 992px) {
      .o-engagement-banner__spacing--how-work {
        padding: 150px 0 60px; } }
    @media screen and (min-width: 992px) {
      .o-engagement-banner__spacing--dedicated {
        padding: 150px 0 0; } }
  .o-engagement-banner__title {
    font-family: 'Poppins';
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 15px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-engagement-banner__title {
        font-size: 51px;
        line-height: 60px;
        text-align: left; } }
  .o-engagement-banner__subtitle {
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    color: #3CC065;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-engagement-banner__subtitle {
        font-size: 31px;
        line-height: 64px;
        text-align: left; } }
    .o-engagement-banner__subtitle--how-we {
      line-height: 40px; }
    .o-engagement-banner__subtitle--dedicated {
      margin-bottom: 20px;
      font-weight: 600;
      color: #fff; }
      @media screen and (min-width: 992px) {
        .o-engagement-banner__subtitle--dedicated {
          font-size: 31px;
          line-height: 38px;
          margin-bottom: 40px; } }
  .o-engagement-banner__subheading {
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin-bottom: 12px; }
    @media screen and (min-width: 992px) {
      .o-engagement-banner__subheading {
        font-size: 31px;
        line-height: 40px;
        text-align: left; } }
  .o-engagement-banner__subcontent {
    font-family: 'Poppins';
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #fff;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-engagement-banner__subcontent {
        font-size: 18px;
        line-height: 26px;
        text-align: left; } }
  .o-engagement-banner__bannerimage {
    margin: 0 auto 30px;
    display: block; }
    @media screen and (min-width: 992px) {
      .o-engagement-banner__bannerimage {
        margin: 0 auto; } }
  .o-engagement-banner__reviewicon {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url(/images/new-home-banner/banner-left-arrow.svg), url(/images/new-home-banner/banner-right-arrow.svg);
    background-repeat: no-repeat;
    background-position: 0 0,100% 0;
    background-size: auto 100%;
    padding: 30px 25px 20px;
    margin-bottom: 37px; }
  .o-engagement-banner__icontitle {
    font-family: 'Poppins';
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
    color: #fff; }
    @media screen and (min-width: 992px) {
      .o-engagement-banner__icontitle {
        font-size: 21px;
        line-height: 31px; } }
  .o-engagement-banner__iconlist {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .o-engagement-banner__iconitem {
    padding: 10px;
    max-width: 50%; }
    @media screen and (min-width: 992px) {
      .o-engagement-banner__iconitem {
        max-width: 25%; } }
  .o-engagement-banner__btn {
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 15px; }
    @media screen and (min-width: 992px) {
      .o-engagement-banner__btn {
        max-width: 280px; } }
    @media screen and (min-width: 992px) {
      .o-engagement-banner__btn--autowidth {
        max-width: fit-content;
        padding: 15px 30px;
        text-transform: capitalize;
        margin-bottom: 80px; } }

.o-core-engagement {
  position: relative; }
  .o-core-engagement:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 7%;
    left: 0;
    top: 0;
    right: 0;
    background-color: #052c58; }
  .o-core-engagement__wrapper {
    position: relative;
    padding: 25px 10px 10px;
    background-color: #fff;
    position: relative;
    width: 100%;
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 100%;
    border-top: 1px solid #b2bac7; }
    @media screen and (min-width: 992px) {
      .o-core-engagement__wrapper {
        padding: 35px 20px 20px; } }
    @media screen and (min-width: 1180px) {
      .o-core-engagement__wrapper {
        padding: 65px 80px 30px; } }
    .o-core-engagement__wrapper:before {
      position: absolute;
      content: "";
      width: 1px;
      height: 25%;
      background: white;
      background: linear-gradient(0deg, white 0%, #b2bac7 100%);
      left: 0;
      top: 5px; }
    .o-core-engagement__wrapper:after {
      position: absolute;
      content: "";
      width: 1px;
      height: 25%;
      background: white;
      background: linear-gradient(0deg, white 0%, #b2bac7 100%);
      right: 0;
      top: 5px; }
  .o-core-engagement__largetitle {
    font-family: 'Poppins';
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
    color: #262E36;
    margin-bottom: 20px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-core-engagement__largetitle {
        font-size: 46px;
        line-height: 60px;
        margin-bottom: 35px; } }
  .o-core-engagement__smalltitle {
    font-family: 'Poppins';
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
    color: #262E36;
    margin-bottom: 20px;
    margin-top: 25px;
    text-align: center; }
    @media screen and (min-width: 768px) {
      .o-core-engagement__smalltitle {
        margin-top: 60px;
        margin-bottom: 30px; } }
    @media screen and (min-width: 992px) {
      .o-core-engagement__smalltitle {
        font-size: 34px;
        line-height: 48px;
        margin-bottom: 44px;
        margin-top: 100px; } }
  .o-core-engagement__corewrapper {
    border-radius: 10px;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.12);
    width: 100%;
    height: auto;
    margin-bottom: 20px; }
    @media screen and (min-width: 768px) {
      .o-core-engagement__corewrapper {
        height: 100%;
        margin-bottom: 0; } }
  .o-core-engagement__coreheading {
    position: relative;
    padding: 30px 18px 20px;
    display: flex;
    flex-direction: row;
    background: #355BBF;
    justify-content: flex-start;
    border-radius: 5px 5px 0 0; }
    @media screen and (min-width: 1024px) {
      .o-core-engagement__coreheading {
        padding: 26px 25px 26px; } }
    .o-core-engagement__coreheading i {
      width: 50px;
      flex: 0 0 50px; }
      @media screen and (min-width: 1024px) {
        .o-core-engagement__coreheading i {
          display: flex;
          align-items: center; } }
  .o-core-engagement__popular {
    position: absolute;
    left: 25px;
    top: -15px;
    background: #3CC065;
    font-family: 'Poppins';
    color: #fff;
    font-size: 12px;
    line-height: 30px;
    font-weight: 600;
    padding: 0 19px;
    text-transform: uppercase; }
  .o-core-engagement__corecontent {
    padding: 16px 30px 30px;
    display: block; }
    @media screen and (min-width: 768px) {
      .o-core-engagement__corecontent {
        display: flex;
        flex-direction: column;
        height: 87%; } }
    @media screen and (min-width: 1024px) {
      .o-core-engagement__corecontent {
        height: 80%; } }
    @media screen and (min-width: 1180px) {
      .o-core-engagement__corecontent {
        display: block;
        height: auto; } }
  .o-core-engagement__coretitle {
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    font-weight: 700;
    margin-left: 11px;
    margin-bottom: 0;
    flex: 0 0 1; }
    @media screen and (min-width: 992px) {
      .o-core-engagement__coretitle {
        font-size: 31px;
        line-height: 40px;
        margin-left: 20px; } }
  .o-core-engagement__innertitle {
    font-family: 'Poppins';
    color: #333;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 10px;
    display: flex;
    align-items: center; }
    .o-core-engagement__innertitle i {
      margin-right: 16px; }
  .o-core-engagement__innercontent {
    font-family: 'Poppins';
    color: #333;
    font-size: 16px;
    line-height: 27px;
    font-weight: 500;
    margin-bottom: 25px; }
    .o-core-engagement__innercontent:nth-child(6) {
      margin-bottom: 35px; }
  .o-core-engagement__innerbtn {
    text-transform: capitalize;
    font-size: 19px;
    max-width: 100%;
    padding: 15px;
    margin-top: auto; }
  .o-core-engagement__moreitem {
    margin-bottom: 40px;
    padding-bottom: 40px;
    background-image: linear-gradient(to right, #b2bac7 40%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 12px 1px;
    background-repeat: repeat-x;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-core-engagement__moreitem {
        text-align: left; } }
    .o-core-engagement__moreitem:last-child {
      background-image: none;
      padding: 0; }
  .o-core-engagement__itemtitle {
    font-family: 'Poppins';
    color: #333;
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-top: 20px; }
    .o-core-engagement__itemtitle:after {
      position: absolute;
      content: "";
      width: 64px;
      height: 4px;
      background: #3CC065;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
      @media screen and (min-width: 992px) {
        .o-core-engagement__itemtitle:after {
          right: inherit; } }
  .o-core-engagement__itemcontent {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    margin-bottom: 5px; }
  .o-core-engagement__btn-gradient {
    position: relative;
    display: inline-flex;
    padding: 2px;
    border-radius: 7px;
    margin: 25px auto 0;
    overflow: hidden;
    cursor: pointer; }
    .o-core-engagement__btn-gradient:after {
      content: '';
      position: absolute;
      top: -200px;
      right: 0;
      bottom: 0;
      left: -50px;
      background: #ff5800;
      width: 500px;
      height: 500px;
      -webkit-transition: .3s all ease;
      transition: .3s all ease; }
    .o-core-engagement__btn-gradient:hover:after {
      animation: rotation2 3s linear infinite;
      background: -webkit-gradient(linear, left top, left bottom, from(#88deb1), color-stop(20%, #1ab864), color-stop(52%, rgba(35, 116, 212, 0.62)), to(rgba(6, 87, 181, 0.62)));
      background: linear-gradient(180deg, #88deb1 0, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%); }
  .o-core-engagement__itembtn {
    position: relative;
    max-width: 100%;
    width: 100%;
    font-size: 15px;
    text-transform: capitalize;
    color: #333;
    padding: 10px 20px;
    background-color: #fff;
    z-index: 1; }
    @media screen and (min-width: 768px) {
      .o-core-engagement__itembtn {
        font-size: 17px;
        max-width: fit-content;
        width: auto;
        padding: 10px 30px; } }

.o-compare-sheet {
  background-color: #F6F6F6; }
  .o-compare-sheet__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-compare-sheet__spacing {
        padding: 60px 0; } }
  .o-compare-sheet__heading {
    font-family: 'Poppins';
    font-size: 34px;
    line-height: 42px;
    font-weight: 700;
    color: #262E36;
    margin-bottom: 20px;
    text-align: center;
    text-transform: capitalize; }
    @media screen and (min-width: 992px) {
      .o-compare-sheet__heading {
        font-size: 51px;
        line-height: 60px;
        margin-bottom: 30px; } }
  .o-compare-sheet__table {
    position: relative;
    width: 100%;
    margin-bottom: 68px; }
  .o-compare-sheet__thead {
    margin-bottom: 20px;
    border: 1px solid #bbc6d4;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.1); }
    @media screen and (min-width: 768px) {
      .o-compare-sheet__thead {
        margin-bottom: 0;
        border: none;
        border-radius: none;
        box-shadow: none; } }
  .o-compare-sheet__tbody .o-compare-sheet__tr {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #bbc6d4;
    border-bottom: none;
    box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.1); }
  @media screen and (min-width: 768px) {
    .o-compare-sheet__tbody {
      box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      border-radius: 0 0 10px 10px; }
      .o-compare-sheet__tbody .o-compare-sheet__tr {
        margin-bottom: 0;
        border-radius: 0;
        overflow: visible;
        border: none;
        box-shadow: none; }
        .o-compare-sheet__tbody .o-compare-sheet__tr:first-child .o-compare-sheet__td:first-child {
          border-radius: 10px 0 0 0; }
        .o-compare-sheet__tbody .o-compare-sheet__tr:last-child .o-compare-sheet__td:first-child {
          border-radius: 0 0 0 10px;
          overflow: hidden; }
        .o-compare-sheet__tbody .o-compare-sheet__tr:last-child .o-compare-sheet__td:last-child {
          border-radius: 0 0 10px 0;
          overflow: hidden; } }
  .o-compare-sheet__tr {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%; }
    @media screen and (min-width: 768px) {
      .o-compare-sheet__tr {
        flex-direction: row; } }
  .o-compare-sheet__th {
    font-family: 'Poppins';
    width: 100%;
    padding: 15px 10px 10px;
    background-color: #F3F4F9;
    font-size: 17px;
    line-height: 25px;
    color: #262E36;
    font-weight: 600;
    border-bottom: 1px solid #bbc6d4;
    text-align: center; }
    @media screen and (min-width: 768px) {
      .o-compare-sheet__th {
        width: 19.5%;
        border-right: 1px solid #bbc6d4;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
        text-align: center; } }
    @media screen and (min-width: 1180px) {
      .o-compare-sheet__th {
        width: 19%;
        font-size: 21px;
        line-height: 30px;
        padding: 25px 25px 15px; } }
    .o-compare-sheet__th:first-child {
      width: 100%;
      background-color: transparent;
      padding: 0;
      font-size: 20px;
      line-height: 30px;
      padding: 10px;
      border-right: none;
      box-shadow: none;
      border-bottom: none;
      text-align: center; }
      @media screen and (min-width: 768px) {
        .o-compare-sheet__th:first-child {
          width: 22%;
          padding: 10px 10px 0 0;
          text-align: left; } }
      @media screen and (min-width: 1180px) {
        .o-compare-sheet__th:first-child {
          width: 24%;
          font-size: 26px;
          line-height: 38px; } }
    .o-compare-sheet__th:nth-child(2) {
      border-radius: 10px 0 0 0; }
    .o-compare-sheet__th:last-child {
      border-radius: 0 10px 0 0;
      border-right: none;
      border-bottom: none; }
      @media screen and (min-width: 768px) {
        .o-compare-sheet__th:last-child {
          border-bottom: 1px solid #bbc6d4; } }
    .o-compare-sheet__th i {
      display: block;
      margin-bottom: 7px; }
  .o-compare-sheet__td {
    font-family: 'Poppins';
    color: #333;
    font-size: 16px;
    line-height: 22px;
    padding: 10px;
    font-weight: 500;
    width: 100%;
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #fff;
    border-bottom: 1px solid #bbc6d4; }
    @media screen and (min-width: 768px) {
      .o-compare-sheet__td {
        font-size: 15px;
        width: 19.5%;
        border-right: 1px solid #bbc6d4; } }
    @media screen and (min-width: 1180px) {
      .o-compare-sheet__td {
        font-size: 16px;
        line-height: 27px;
        padding: 25px;
        width: 19%; } }
    .o-compare-sheet__td:first-child {
      width: 100%;
      background: #355BBF;
      color: #fff;
      font-weight: 600;
      border-bottom: 1px solid #2746a0; }
      @media screen and (min-width: 768px) {
        .o-compare-sheet__td:first-child {
          width: 22%; } }
      @media screen and (min-width: 1180px) {
        .o-compare-sheet__td:first-child {
          width: 24%; } }
    .o-compare-sheet__td i {
      margin-right: 7px; }
  .o-compare-sheet__moretitle {
    font-family: 'Poppins';
    color: #333;
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 23px; }
  .o-compare-sheet__freebtn {
    max-width: 409px;
    width: 100%;
    padding: 15px;
    text-transform: capitalize; }

.o-idea-transform {
  background-color: #052c58;
  background-image: url(/images/how-we-help-you/how-we-help-graphic.png);
  background-repeat: no-repeat;
  background-position: top left; }
  .o-idea-transform__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-idea-transform__spacing {
        padding: 60px 0; } }
  .o-idea-transform__wrapper {
    max-width: 860px;
    width: 100%;
    margin: auto;
    text-align: center; }
  .o-idea-transform__heading {
    font-family: 'Poppins';
    font-size: 34px;
    line-height: 42px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 20px;
    margin-top: 28px;
    text-align: center;
    text-transform: capitalize; }
    @media screen and (min-width: 992px) {
      .o-idea-transform__heading {
        font-size: 51px;
        line-height: 60px;
        margin-bottom: 30px; } }
    .o-idea-transform__heading--black {
      color: #262E36; }
  .o-idea-transform__content {
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 40px;
    text-align: center; }
    .o-idea-transform__content--black {
      color: #262E36; }
  .o-idea-transform__btnarea {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    @media screen and (min-width: 992px) {
      .o-idea-transform__btnarea {
        flex-direction: row; } }
  .o-idea-transform__btn {
    text-transform: capitalize;
    font-size: 17px;
    padding: 15px;
    margin: 10px 0;
    width: 100%; }
    @media screen and (min-width: 768px) {
      .o-idea-transform__btn {
        width: 333px; } }
    @media screen and (min-width: 1180px) {
      .o-idea-transform__btn {
        margin: 0 8px; } }
    .o-idea-transform__btn--white {
      color: #fff;
      border-color: #fff; }
      .o-idea-transform__btn--white:hover {
        background: #fff; }
        .o-idea-transform__btn--white:hover svg g {
          fill: #333; }
    .o-idea-transform__btn--black {
      border-color: #FF5800; }
      .o-idea-transform__btn--black:hover {
        color: #FF5800;
        background: #fff; }
        .o-idea-transform__btn--black:hover svg g {
          fill: #FF5800; }
  .o-idea-transform--no-background {
    background-color: #f6f6f6;
    background-image: none; }

/* ANCHOR -New Referral */
.new-clean-banner {
  position: relative;
  background: #052C58;
  width: 100%;
  overflow: hidden;
  padding: 120px 0px 40px; }
  @media (min-width: 767px) {
    .new-clean-banner {
      padding: 150px 0px 60px; } }
  .new-clean-banner:before {
    position: absolute;
    content: "";
    width: 410px;
    height: 245px;
    background: url(/images/two-for-one/banner-top-gradient.png) no-repeat top left;
    background-size: 100%;
    top: 65px;
    left: 0; }
  .new-clean-banner__heading {
    font-size: 31px;
    line-height: 41px;
    margin-bottom: 12px;
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Poppins' !important; }
    @media screen and (min-width: 992px) {
      .new-clean-banner__heading {
        text-align: left; } }
    @media (min-width: 1024px) {
      .new-clean-banner__heading {
        font-size: 41px;
        line-height: 51px; } }
    @media (min-width: 1200px) {
      .new-clean-banner__heading {
        font-size: 51px;
        line-height: 62px; } }
  .new-clean-banner__heading-span {
    color: #14DF7D; }
  .new-clean-banner__subheading {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    color: #fff;
    margin-right: 0px;
    text-transform: capitalize;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Poppins' !important; }
    @media (min-width: 767px) {
      .new-clean-banner__subheading {
        margin-right: 140px;
        margin-bottom: 30px; } }
    @media screen and (min-width: 992px) {
      .new-clean-banner__subheading {
        text-align: left; } }
    @media (min-width: 1200px) {
      .new-clean-banner__subheading {
        font-size: 30px;
        line-height: 40px; } }
  .new-clean-banner__cta-area {
    display: initial; }
    @media (min-width: 767px) {
      .new-clean-banner__cta-area {
        gap: 15px;
        display: flex; } }
    @media (min-width: 1024px) {
      .new-clean-banner__cta-area {
        gap: 0px; } }
  .new-clean-banner__bnner-image {
    margin: 0 auto 20px;
    display: block; }
    @media (min-width: 767px) {
      .new-clean-banner__bnner-image {
        margin: 20px auto 0; } }
    @media (min-width: 1024px) {
      .new-clean-banner__bnner-image {
        margin: 0 auto; } }

.highest-rated {
  background-color: #081C36;
  padding: 30px 0px 20px; }
  @media (min-width: 1024px) {
    .highest-rated {
      padding: 40px 0px; } }
  .highest-rated__content-area {
    display: block;
    align-items: center;
    justify-content: center; }
    @media (min-width: 1024px) {
      .highest-rated__content-area {
        display: flex; } }
  .highest-rated__bracket {
    display: none; }
    @media (min-width: 1024px) {
      .highest-rated__bracket {
        display: block; } }
  .highest-rated__heading {
    font-size: 24px;
    color: #fff;
    line-height: 31px;
    font-weight: 700;
    margin-right: 25px;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Poppins' !important; }
    @media (min-width: 1024px) {
      .highest-rated__heading {
        margin-bottom: 0px;
        text-align: initial; } }
  .highest-rated__heading-br {
    display: none; }
    @media (min-width: 1024px) {
      .highest-rated__heading-br {
        display: block; } }
  .highest-rated__image-area {
    display: flex;
    gap: 25px;
    justify-content: center; }
    @media (min-width: 1024px) {
      .highest-rated__image-area {
        gap: 15px;
        justify-content: initial; } }
  .highest-rated__ratings {
    width: auto;
    height: 60px; }
    @media (min-width: 1024px) {
      .highest-rated__ratings {
        height: 80px; } }

.earning-commission {
  padding: 30px 0px;
  background-color: #F9F9F9;
  position: relative; }
  @media screen and (min-width: 992px) {
    .earning-commission {
      padding: 60px 0px; } }
  .earning-commission__heading {
    font-size: 31px;
    line-height: 45px;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Poppins' !important; }
    @media (min-width: 767px) {
      .earning-commission__heading {
        font-size: 41px;
        line-height: 55px;
        margin-bottom: 35px; } }
    @media (min-width: 1024px) {
      .earning-commission__heading {
        font-size: 51px;
        line-height: 65px; } }
  .earning-commission__heading-br {
    display: none; }
    @media (min-width: 1024px) {
      .earning-commission__heading-br {
        display: block; } }
  .earning-commission__heading-span {
    color: #476FD4; }
  .earning-commission__card-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin-top: 20px; }
    @media (min-width: 767px) {
      .earning-commission__card-list {
        grid-template-columns: repeat(2, 1fr); } }
    @media (min-width: 1024px) {
      .earning-commission__card-list {
        grid-template-columns: repeat(4, 1fr); } }

.commission-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  position: relative;
  z-index: 1;
  transition: all .5s ease-in-out;
  padding: 20px 15px; }
  @media screen and (min-width: 992px) {
    .commission-card {
      padding: 40px 24px; } }
  .commission-card__icon-area {
    margin-bottom: 15px; }
  .commission-card__icon {
    width: 50px; }
    .commission-card__icon--lg {
      width: 50px; }
      @media (min-width: 767px) {
        .commission-card__icon--lg {
          width: 70px; } }
  .commission-card__heading {
    font-size: 21px;
    line-height: 28px;
    font-weight: 600;
    font-family: 'Poppins' !important; }
  .commission-card__heading-lg {
    font-family: 'Poppins' !important;
    font-size: 21px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 15px; }
    @media (min-width: 767px) {
      .commission-card__heading-lg {
        font-size: 34px;
        line-height: 31px;
        font-weight: 700; } }
  .commission-card__description-lg {
    font-family: 'Poppins' !important;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .commission-card__description-lg {
        font-size: 25px;
        line-height: 38px;
        margin-bottom: 25px; } }
  .commission-card__description {
    font-family: 'Poppins' !important;
    font-size: 15px;
    line-height: 24px; }
  .commission-card--lg {
    padding: 20px 15px; }
    @media screen and (min-width: 992px) {
      .commission-card--lg {
        padding: 40px 68px; } }
  @media (min-width: 1024px) {
    .commission-card:hover {
      -webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1); } }

.referral-program-top {
  height: 30px; }
  @media screen and (min-width: 992px) {
    .referral-program-top {
      height: 60px; } }

.referral-program {
  background-color: #052C58;
  padding: 40px 35px;
  margin-bottom: 40px; }
  @media (min-width: 767px) {
    .referral-program {
      padding: 55px 30px; } }
  @media (min-width: 1024px) {
    .referral-program {
      padding: 55px 48px; } }
  @media (min-width: 1200px) {
    .referral-program {
      padding: 75px 68px; } }
  .referral-program__header {
    color: #fff;
    font-size: 28px;
    line-height: 34px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 50px;
    font-family: 'Poppins' !important; }
    @media (min-width: 767px) {
      .referral-program__header {
        font-size: 34px;
        line-height: 38px;
        font-weight: 700; } }
  .referral-program__steps-area {
    display: block; }
    @media (min-width: 767px) {
      .referral-program__steps-area {
        display: flex; } }
  .referral-program__card {
    background-color: #fff;
    padding: 40px 20px 30px;
    text-align: center;
    flex-grow: 1;
    position: relative;
    margin-bottom: 1px; }
    @media (min-width: 992px) {
      .referral-program__card {
        margin-bottom: 0px;
        padding: 40px 20px 20px; }
        .referral-program__card:after {
          position: absolute;
          content: "";
          width: 31px;
          height: 108px;
          background: url(/images/new-referral/arrow-right.svg) no-repeat top left;
          background-size: 100%;
          top: 25px;
          right: -15px;
          z-index: 1; }
        .referral-program__card:last-child::after {
          display: none; } }
  .referral-program__step-count {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #052C58;
    position: absolute;
    top: -25px;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%); }
    @media screen and (min-width: 992px) {
      .referral-program__step-count {
        left: 42%;
        transform: none; } }
  .referral-program__circle {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    border: 2px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    font-size: 20px; }
  .referral-program__card-icon {
    margin-bottom: 10px;
    width: 50px;
    height: 50px; }
  .referral-program__subtitle {
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    font-family: 'Poppins' !important; }
    @media (min-width: 767px) {
      .referral-program__subtitle {
        font-size: 20px;
        line-height: 31px; } }

.referral-reasons {
  padding: 30px 0px; }
  @media screen and (min-width: 992px) {
    .referral-reasons {
      padding: 60px 0px; } }
  .referral-reasons__heading {
    font-size: 31px;
    line-height: 38px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: 'Poppins' !important; }
    @media screen and (min-width: 992px) {
      .referral-reasons__heading {
        font-size: 41px;
        line-height: 55px;
        margin-bottom: 40px; } }
    @media (min-width: 1024px) {
      .referral-reasons__heading {
        font-size: 51px;
        line-height: 65px; } }
  .referral-reasons__heading-br {
    display: none; }
    @media (min-width: 1200px) {
      .referral-reasons__heading-br {
        display: block; } }
  .referral-reasons__card-area {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-bottom: 40px; }
    @media (min-width: 767px) {
      .referral-reasons__card-area {
        gap: 30px; } }
    @media (min-width: 1024px) {
      .referral-reasons__card-area {
        gap: 40px; } }
  .referral-reasons__card {
    flex-basis: 200px;
    border: 1px solid #898989;
    padding: 15px;
    flex-grow: 1; }
    @media (min-width: 991px) {
      .referral-reasons__card {
        flex-basis: 250px;
        min-height: 320px;
        padding: 20px; } }
    @media (min-width: 1024px) {
      .referral-reasons__card {
        flex-basis: 340px;
        flex-grow: 0;
        padding: 30px; } }
  .referral-reasons__card-icon {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-bottom: 15px; }
  .referral-reasons__card-subtitle {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px;
    font-family: 'Poppins' !important; }
    @media (min-width: 991px) {
      .referral-reasons__card-subtitle {
        font-size: 24px;
        line-height: 31px; } }
  .referral-reasons__card-description {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 0px; }

.ready-join {
  padding: 30px 0;
  background: url(/images/new-referral/ready-to-join-bg.jpg) no-repeat center center/cover; }
  @media screen and (min-width: 992px) {
    .ready-join {
      padding: 60px 0; } }
  .ready-join h2 {
    font-family: 'Poppins';
    font-size: 30px;
    line-height: 44px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .ready-join h2 {
        font-size: 51px;
        line-height: 60px;
        margin-bottom: 30px;
        text-align: left; } }
  .ready-join p {
    font-family: 'Poppins';
    color: #fff;
    font-size: 19px;
    line-height: 30px;
    font-weight: 600;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .ready-join p {
        text-align: left;
        font-size: 25px;
        line-height: 36px; } }
  .ready-join__btn-wrapper {
    text-align: center; }
    @media screen and (min-width: 992px) {
      .ready-join__btn-wrapper {
        display: flex;
        align-items: center;
        text-align: inherit; } }
  .ready-join__btn {
    font-family: 'Poppins';
    display: flex;
    background: #FF5800;
    border: 2px solid #FF5800;
    color: #fff;
    padding: 16px 40px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 600;
    align-items: center;
    text-align: center;
    justify-content: center;
    transition: .3s all ease-in-out; }
    .ready-join__btn svg {
      transition: .3s all ease-in-out;
      margin-left: 15px; }
    .ready-join__btn:hover {
      background: #fff;
      color: #FF5800; }
      .ready-join__btn:hover svg {
        transform: translateX(15px); }
      .ready-join__btn:hover path {
        fill: #FF5800; }
  .ready-join__link {
    font-family: 'Poppins';
    margin-top: 20px;
    font-size: 17px;
    font-weight: 400;
    color: #fff;
    text-decoration: underline;
    display: inline-block; }
    .ready-join__link:hover {
      color: #00b54a; }
    @media screen and (min-width: 992px) {
      .ready-join__link {
        margin-top: 0;
        margin-left: 25px;
        display: inherit; } }

.referral-p .nw-client {
  background-color: #F9F9F9;
  border-top: 1px solid #D6D6D6;
  border-bottom: 1px solid #D6D6D6; }

/* ANCHOR -New FAQ*/
.new-faq {
  font-family: 'Poppins';
  font-size: 51px;
  font-weight: 700;
  color: #262E36;
  background: #DDF0F8;
  padding-bottom: 50px;
  padding-top: 30px !important; }
  @media screen and (min-width: 992px) {
    .new-faq {
      padding: 60px 0 60px !important; } }
  .new-faq__wrapper {
    height: 335px;
    overflow: hidden;
    position: relative;
    max-width: 830px;
    margin: 0 auto; }
    @media screen and (min-width: 992px) {
      .new-faq__wrapper {
        height: 275px; } }
  .new-faq__blur--active {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50%;
    background: #ddf0f8;
    background: -moz-linear-gradient(180deg, rgba(221, 240, 248, 0) 0, #ddf0f8 100%);
    background: -webkit-linear-gradient(180deg, rgba(221, 240, 248, 0) 0, #ddf0f8 100%);
    background: linear-gradient(180deg, rgba(221, 240, 248, 0) 0, #ddf0f8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ddf0f8",endColorstr="#ddf0f8",GradientType=1);
    z-index: 99; }
    @media screen and (min-width: 992px) {
      .new-faq__blur--active {
        height: 52%; } }
    .new-faq__blur--active:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(0.1rem); }
  @media screen and (min-width: 992px) {
    .new-faq__blur--active-dita {
      height: 65%; } }
  .new-faq .faq-holder {
    background: none;
    border-bottom: 1px solid #333333;
    box-shadow: none;
    margin-bottom: 0; }
    .new-faq .faq-holder .accordion1 {
      font-family: 'Poppins';
      font-size: 17px;
      line-height: 25px;
      color: #262E36;
      font-weight: 600;
      background: none;
      padding: 15px 50px 15px 0; }
      @media screen and (min-width: 992px) {
        .new-faq .faq-holder .accordion1 {
          font-size: 17px;
          line-height: 20px;
          padding: 20px 0; } }
      .new-faq .faq-holder .accordion1:after {
        font-size: 36px;
        position: absolute;
        right: 2px;
        width: 37px;
        height: 37px;
        line-height: 37px;
        color: #333;
        border: 1px solid #333;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center; }
    .new-faq .faq-holder .panel1 {
      font-family: 'Poppins';
      padding: 0;
      background: none;
      font-size: 16px;
      line-height: 26px;
      font-weight: 400; }
      .new-faq .faq-holder .panel1 p {
        font-family: 'Poppins' !important;
        font-size: 16px;
        line-height: 26px;
        font-weight: 400; }
      .new-faq .faq-holder .panel1 li {
        font-family: 'Poppins' !important;
        font-size: 16px;
        line-height: 26px;
        font-weight: 400; }
  .new-faq .click-to-btn {
    text-align: center;
    position: absolute;
    bottom: -60px;
    z-index: 99; }
    .new-faq .click-to-btn span {
      font-family: 'Poppins';
      font-size: 17px;
      font-weight: 600;
      background: #fff;
      color: #333;
      max-width: 230px;
      display: block;
      border: 2px solid #333;
      border-radius: 5px;
      padding: 15px 0;
      margin: 0 auto;
      cursor: pointer; }
    .new-faq .click-to-btn svg {
      position: relative;
      top: -10px; }
  .new-faq h2 {
    font-weight: 700; }
  .new-faq .subheading {
    font-family: 'Poppins';
    font-size: 25px;
    line-height: 34px;
    font-weight: 600;
    color: #333;
    display: block;
    max-width: 810px;
    margin: 0 auto 35px;
    text-align: center; }

/* ANCHOR -MWC new*/
.mwc-header {
  background-color: #102e6e;
  background-size: 101% 105%;
  max-width: 1146px;
  margin: 0 auto;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  padding: 5px 0;
  border-bottom: 1px solid #1d4c88; }
  @media screen and (min-width: 992px) {
    .mwc-header {
      top: 15px;
      padding: 0;
      border: 1px solid #1d4c88;
      background: url(../images/mwc-new/banner/header-bg.jpg) no-repeat center center; } }
  .mwc-header__wrapper {
    align-items: center; }
    @media screen and (min-width: 992px) {
      .mwc-header__wrapper {
        display: flex; } }
  .mwc-header__logo {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 10px; }
    @media screen and (min-width: 992px) {
      .mwc-header__logo {
        flex: 0 0 50%;
        max-width: 50%;
        display: block;
        padding: 13px 3px 13px 35px;
        margin-bottom: 0; } }
    .mwc-header__logo::before {
      display: block;
      content: "";
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
      background: #385f85;
      height: 1px;
      width: 90%;
      margin: 0 auto; }
      @media screen and (min-width: 992px) {
        .mwc-header__logo::before {
          display: none; } }
    .mwc-header__logo svg {
      margin: 0 0 0 15px; }
  .mwc-header .gsma-logo {
    display: inline-block; }
    @media screen and (min-width: 992px) {
      .mwc-header .gsma-logo {
        position: relative;
        left: -12px; } }
  .mwc-header .leap-logo {
    display: inline-block; }
    @media screen and (min-width: 992px) {
      .mwc-header .leap-logo {
        position: relative;
        margin-left: 12px; } }
  .mwc-header__information {
    text-align: center;
    margin-top: 10px; }
    @media screen and (min-width: 992px) {
      .mwc-header__information {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        padding-left: 58px;
        text-align: left;
        margin-top: 0;
        position: relative; } }
    @media screen and (min-width: 992px) {
      .mwc-header__information::before {
        content: "";
        position: absolute;
        left: 3px;
        top: -3px;
        width: 1px;
        height: 72px;
        background: #385f85; } }
    .mwc-header__information p {
      margin-bottom: 0;
      font-family: "Poppins";
      font-size: 16px;
      line-height: 24px;
      font-weight: 600; }
      @media screen and (min-width: 992px) {
        .mwc-header__information p {
          font-size: 22px;
          line-height: 33px; } }
    .mwc-header__information .line1 {
      color: #fff; }
    .mwc-header__information .line2 {
      color: #3cc065; }
    @media screen and (min-width: 992px) {
      .mwc-header__information--leap {
        padding: 13px 0 13px 60px; } }
    @media screen and (min-width: 992px) {
      .mwc-header__information--leap:before {
        top: 13px;
        left: 13px;
        width: 1px;
        height: 84px; } }
    @media screen and (min-width: 992px) {
      .mwc-header__information--leap p {
        font-size: 20px;
        line-height: 28px; } }

.mwc-main-banner-wrap {
  background: #061d3a;
  padding-top: 15px; }
  @media screen and (min-width: 992px) {
    .mwc-main-banner-wrap {
      background: url(/images/mwc-new/banner/bg-graphic.jpg) no-repeat center center/cover; } }

.mwc-banner {
  padding: 0 0 50px !important;
  position: relative; }
  @media screen and (min-width: 992px) {
    .mwc-banner {
      padding: 45px 0 80px !important; } }
  .mwc-banner--leap {
    padding: 50px 0 50px !important; }
    @media screen and (min-width: 992px) {
      .mwc-banner--leap {
        padding: 45px 0 80px !important; } }
  .mwc-banner__top {
    margin-top: 30px; }
    @media screen and (min-width: 992px) {
      .mwc-banner__top {
        margin-top: 0; } }
  .mwc-banner__left h1 {
    font-family: "Poppins";
    font-size: 32px;
    line-height: 42px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px; }
    @media screen and (min-width: 992px) {
      .mwc-banner__left h1 {
        font-size: 49px;
        line-height: 60px;
        text-align: left;
        margin-bottom: 15px; } }
    @media screen and (min-width: 1300px) {
      .mwc-banner__left h1 {
        font-size: 51px; } }
  .mwc-banner__left h3 {
    font-family: Poppins;
    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
    color: #3cc065;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .mwc-banner__left h3 {
        font-size: 31px;
        line-height: 47px;
        text-align: left; } }
  .mwc-banner__left p {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 34px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .mwc-banner__left p {
        font-size: 25px;
        line-height: 38px;
        text-align: left;
        padding-right: 115px; } }
    .mwc-banner__left p.long-text {
      font-weight: 400; }
      @media screen and (min-width: 992px) {
        .mwc-banner__left p.long-text {
          font-size: 24px;
          line-height: 32px; } }
      .mwc-banner__left p.long-text strong {
        font-weight: 600; }
    .mwc-banner__left p a {
      position: relative;
      display: inline-block;
      color: #fff; }
      .mwc-banner__left p a:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px; }
      .mwc-banner__left p a.bottom-line1:before {
        background: #64b9f4; }
      .mwc-banner__left p a.bottom-line2:before {
        background: #e26826; }
      .mwc-banner__left p a.bottom-line3:before {
        background: #8ac640; }
      .mwc-banner__left p a.bottom-line4:before {
        background: #fbba14; }
      .mwc-banner__left p a.bottom-line5:before {
        background: #804b8b; }
      .mwc-banner__left p a.bottom-line6:before {
        background: #64b9f4; }
      .mwc-banner__left p a.bottom-line7:before {
        background: #e98e5c; }
  @media screen and (min-width: 992px) {
    .mwc-banner__left--post h1 {
      font-size: 38px;
      line-height: 50px; } }
  .mwc-banner__left--post p {
    font-weight: 400; }
    @media screen and (min-width: 992px) {
      .mwc-banner__left--post p {
        font-size: 21px;
        line-height: 32px; } }
    .mwc-banner__left--post p strong {
      font-weight: 600; }
  .mwc-banner__right {
    text-align: center;
    flex: 0 0 100%; }
    @media screen and (min-width: 992px) {
      .mwc-banner__right {
        flex: 1;
        text-align: right; } }
  .mwc-banner__bracket {
    height: 115px; }
    @media screen and (min-width: 992px) {
      .mwc-banner__bracket {
        height: 165px; } }
    .mwc-banner__bracket img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .mwc-banner__ratting {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .mwc-banner__ratting {
        justify-content: flex-start; } }
  .mwc-banner__animated {
    position: relative;
    width: 150px;
    height: 150px;
    pointer-events: none;
    position: relative;
    top: -15px; }
    @media screen and (min-width: 992px) {
      .mwc-banner__animated {
        width: 200px;
        height: 200px;
        position: static; } }
    .mwc-banner__animated img {
      position: relative;
      transform: rotate(270deg); }
    .mwc-banner__animated .animated1 {
      top: 18px;
      left: 12px;
      width: 140px; }
      @media screen and (min-width: 992px) {
        .mwc-banner__animated .animated1 {
          top: 40px;
          left: 35px; } }
    .mwc-banner__animated .animated2 {
      top: 22px;
      left: 5px; }
      @media screen and (min-width: 992px) {
        .mwc-banner__animated .animated2 {
          top: 47px;
          left: 24px; } }
    .mwc-banner__animated .animated3 {
      top: 15px;
      left: 6px; }
      @media screen and (min-width: 992px) {
        .mwc-banner__animated .animated3 {
          top: 40px;
          left: 29px; } }
    .mwc-banner__animated .animated4 {
      top: 32px;
      left: 7px; }
      @media screen and (min-width: 992px) {
        .mwc-banner__animated .animated4 {
          top: 56px;
          left: 30px; } }
  .mwc-banner__bottom {
    position: relative;
    margin-top: 15px; }
    @media screen and (min-width: 992px) {
      .mwc-banner__bottom {
        margin-top: 60px; } }
    .mwc-banner__bottom h2 {
      font-family: "Poppins";
      position: absolute;
      top: -25px;
      left: 0;
      right: 0;
      color: #000;
      font-weight: 700;
      font-size: 20px;
      border-top-left-radius: 20px;
      border-bottom-right-radius: 20px;
      text-align: center;
      background: #fcc900;
      padding: 5px 0;
      width: 250px;
      z-index: 99;
      line-height: 31px;
      margin: 0 auto; }
      @media screen and (min-width: 992px) {
        .mwc-banner__bottom h2 {
          font-size: 25px;
          width: 300px;
          padding: 11px 0;
          top: -35px;
          left: 0;
          right: inherit;
          margin: 0; } }
      .mwc-banner__bottom h2::before {
        content: "";
        position: absolute;
        top: -24px;
        left: 39%;
        background: url(/images/mwc-new/banner/touble-triangle.svg) no-repeat center center;
        width: 45px;
        height: 37px; }
  .mwc-banner__gradient {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    padding: 5px; }
    .mwc-banner__gradient:before {
      content: "";
      position: absolute;
      top: -307px;
      left: -113px;
      width: 1400px;
      height: 1400px;
      animation: rotation2 3s linear infinite;
      background: linear-gradient(180deg, #88deb1 0, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%); }
    .mwc-banner__gradient:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #88deb1 0, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      opacity: 0.5; }
  .mwc-banner__offer {
    padding: 30px 20px 20px;
    position: relative;
    background: #061833;
    z-index: 9;
    border-radius: 10px; }
    @media screen and (min-width: 992px) {
      .mwc-banner__offer {
        display: flex;
        gap: 15px;
        padding: 30px 20px; } }
  .mwc-banner__offer1 {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background: #12b746;
    background: -moz-linear-gradient(142deg, #12b746 0%, #009a30 100%);
    background: -webkit-linear-gradient(142deg, #12b746 0%, #009a30 100%);
    background: linear-gradient(142deg, #12b746 0%, #009a30 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#12b746",endColorstr="#009a30",GradientType=1);
    margin-bottom: 15px; }
    @media screen and (min-width: 992px) {
      .mwc-banner__offer1 {
        margin-bottom: 0; } }
  .mwc-banner__offer2 {
    flex: 1;
    display: flex;
    align-items: center;
    background: #3296f3;
    padding: 10px;
    border-radius: 5px;
    background: -moz-linear-gradient(142deg, #3296f3 0%, #1f5cd2 100%);
    background: -webkit-linear-gradient(142deg, #3296f3 0%, #1f5cd2 100%);
    background: linear-gradient(142deg, #3296f3 0%, #1f5cd2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3296f3",endColorstr="#1f5cd2",GradientType=1); }
  .mwc-banner .shine-effect {
    position: relative;
    overflow: hidden; }
    .mwc-banner .shine-effect:before {
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
      content: "";
      display: block;
      height: 100%;
      left: -75%;
      position: absolute;
      top: 0;
      transform: skewX(-25deg);
      width: 50%;
      z-index: 2; }
    .mwc-banner .shine-effect:hover:before, .mwc-banner .shine-effect:focus:before {
      animation: shine 0.85s linear; }
  .mwc-banner__offer-img {
    flex: 0 0 100px;
    max-width: 100px;
    height: 90px;
    overflow: hidden;
    border-radius: 4px; }
    @media screen and (min-width: 992px) {
      .mwc-banner__offer-img {
        flex: 0 0 147px;
        max-width: 147px;
        height: 138px; } }
    .mwc-banner__offer-img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .mwc-banner__offer-info {
    margin-left: 10px; }
    @media screen and (min-width: 992px) {
      .mwc-banner__offer-info {
        margin-left: 25px; } }
    .mwc-banner__offer-info h3 {
      font-family: "Poppins";
      color: #fff;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      margin-bottom: 5px; }
      @media screen and (min-width: 992px) {
        .mwc-banner__offer-info h3 {
          font-size: 31px;
          line-height: 36px;
          font-weight: 700;
          margin-bottom: 0.5rem; } }
    .mwc-banner__offer-info span {
      font-family: "Poppins";
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      position: relative;
      display: block;
      width: 90px; }
      @media screen and (min-width: 992px) {
        .mwc-banner__offer-info span {
          font-size: 16px;
          width: 109px; } }
      .mwc-banner__offer-info span::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #fff; }
  .mwc-banner .owl-carousel-vertical {
    transform: rotate3d(0, 0, 1, 90deg);
    height: 100%; }
    .mwc-banner .owl-carousel-vertical .owl-stage-outer {
      height: 100%; }
  .mwc-banner .owl-carousel-vertical .item {
    transform: rotate3d(0, 0, 1, -90deg); }
  .mwc-banner__meeticons {
    position: relative;
    padding: 0 0 20px; }
    @media screen and (min-width: 570px) {
      .mwc-banner__meeticons {
        padding: 20px 0; } }
    @media screen and (min-width: 768px) {
      .mwc-banner__meeticons {
        padding: 0;
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px; } }
    @media screen and (min-width: 1024px) {
      .mwc-banner__meeticons {
        margin-bottom: 0; } }
    .mwc-banner__meeticons:after {
      display: block;
      position: absolute;
      content: "";
      width: 1px;
      height: 70px;
      background-color: #fff;
      left: 0;
      right: 0;
      top: 5px;
      margin: auto; }
      @media screen and (min-width: 570px) {
        .mwc-banner__meeticons:after {
          height: 60px;
          top: 20px; } }
      @media screen and (min-width: 770px) {
        .mwc-banner__meeticons:after {
          display: none; } }
  .mwc-banner__meetsitem {
    position: relative;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px; }
    @media screen and (min-width: 770px) {
      .mwc-banner__meetsitem {
        width: 33%;
        padding: 10px; } }
    @media screen and (min-width: 992px) {
      .mwc-banner__meetsitem {
        width: 16.66%;
        padding-bottom: 30px;
        flex-direction: row;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        margin: 0;
        justify-content: flex-start; } }
    .mwc-banner__meetsitem:before {
      display: none; }
      @media screen and (min-width: 770px) {
        .mwc-banner__meetsitem:before {
          display: block;
          position: absolute;
          content: "";
          width: 93%;
          height: 1px;
          background-color: #5b81e1;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto; } }
    .mwc-banner__meetsitem:after {
      display: none; }
      @media screen and (min-width: 770px) {
        .mwc-banner__meetsitem:after {
          display: block;
          position: absolute;
          content: "";
          width: 1px;
          height: 85%;
          background-color: #5b81e1;
          top: 49%;
          right: -1px;
          transform: translateY(-50%); } }
    .mwc-banner__meetsitem:nth-child(3n + 3):after {
      display: none; }
      @media screen and (min-width: 992px) {
        .mwc-banner__meetsitem:nth-child(3n + 3):after {
          display: block; } }
    @media screen and (min-width: 992px) {
      .mwc-banner__meetsitem:nth-child(6n + 6) {
        padding-left: 10px; }
        .mwc-banner__meetsitem:nth-child(6n + 6):after {
          display: none; } }
    @media screen and (min-width: 992px) {
      .mwc-banner__meetsitem:nth-last-child(-n + 6) {
        padding-bottom: 5px; }
        .mwc-banner__meetsitem:nth-last-child(-n + 6):before {
          display: none; } }
    @media screen and (min-width: 992px) {
      .mwc-banner__meetsitem:nth-last-child(-n + 5) {
        padding-bottom: 5px; }
        .mwc-banner__meetsitem:nth-last-child(-n + 5):before {
          display: none; } }
    @media screen and (min-width: 992px) {
      .mwc-banner__meetsitem:nth-last-child(-n + 4) {
        padding-bottom: 5px; }
        .mwc-banner__meetsitem:nth-last-child(-n + 4):before {
          display: none; } }
    @media screen and (min-width: 770px) {
      .mwc-banner__meetsitem:nth-last-child(-n + 3):before {
        display: none; } }
    @media screen and (min-width: 992px) {
      .mwc-banner__meetsitem:nth-last-child(-n + 3) {
        padding-bottom: 5px; }
        .mwc-banner__meetsitem:nth-last-child(-n + 3):before {
          display: none; } }
    @media screen and (min-width: 770px) {
      .mwc-banner__meetsitem:nth-last-child(-n + 2):before {
        display: none; } }
    @media screen and (min-width: 992px) {
      .mwc-banner__meetsitem:nth-last-child(-n + 2) {
        padding-bottom: 5px; } }
    .mwc-banner__meetsitem img {
      margin-right: 10px; }
      @media screen and (min-width: 992px) {
        .mwc-banner__meetsitem img {
          margin-right: 0; } }
  .mwc-banner__listcontent {
    margin-top: 5px;
    margin-left: 0;
    text-align: left; }
    @media screen and (min-width: 992px) {
      .mwc-banner__listcontent {
        margin-top: 0;
        margin-left: 10px; } }
  .mwc-banner__contenttittle {
    font-family: "Poppins";
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    color: #358acb;
    margin: 0; }
    @media screen and (min-width: 992px) {
      .mwc-banner__contenttittle {
        font-size: 17px;
        line-height: 22px; } }
  .mwc-banner__contenttext {
    font-family: "Poppins";
    color: #fff;
    font-size: 12px !important;
    line-height: 16px !important;
    font-weight: 500 !important;
    margin: 0 !important;
    padding: 0 !important; }
  .mwc-banner__container {
    position: relative; }
    @media screen and (min-width: 992px) {
      .mwc-banner__container {
        display: flex;
        justify-content: flex-end; } }
  .mwc-banner .highest-rated {
    background: none;
    padding-bottom: 0; }
  .mwc-banner .highest-rated__content-area {
    justify-content: space-between; }
    @media screen and (min-width: 992px) {
      .mwc-banner .highest-rated__content-area {
        max-width: 600px; } }
  @media screen and (min-width: 992px) {
    .mwc-banner .highest-rated__image-area {
      gap: 25px; } }
  .mwc-banner .highest-rated__ratings {
    height: auto; }

@keyframes shine {
  100% {
    left: 125%; } }

@keyframes rotation2 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes horizontal-shaking {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(5deg); }
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(-5deg); }
  100% {
    transform: rotate(0deg); } }

.shake-animation {
  animation: horizontal-shaking 0.2s; }

.trusted {
  padding: 40px 0 50px; }
  @media screen and (min-width: 992px) {
    .trusted {
      padding: 80px 0; } }
  .trusted__container {
    position: relative; }
    @media screen and (min-width: 992px) {
      .trusted__container {
        display: flex;
        justify-content: flex-end; } }
  .trusted h2 {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #262e36;
    margin-bottom: 15px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .trusted h2 {
        text-align: left;
        font-size: 25px;
        line-height: 29px;
        margin-bottom: 30px; } }
  .trusted .good-company__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px 20px;
    margin-bottom: 25px;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .trusted .good-company__list {
        display: flex;
        justify-content: inherit;
        gap: 30px 20px;
        min-height: 200px; } }
    .trusted .good-company__list li {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: auto;
      flex: 0 0 31%; }
      @media screen and (min-width: 992px) {
        .trusted .good-company__list li {
          flex: auto;
          justify-content: inherit; } }
  .trusted .owl-carousel .owl-stage {
    display: flex;
    align-items: center; }
  .trusted .m-good-company-slider .good-company__list li {
    flex: 0 0 47%;
    max-width: 47%; }
    @media screen and (min-width: 992px) {
      .trusted .m-good-company-slider .good-company__list li {
        height: 120px; } }
  .trusted .owl-dots .owl-dot {
    background: #e1e1e1; }
    .trusted .owl-dots .owl-dot.active {
      background: #ff5800; }
  @media screen and (min-width: 992px) {
    .trusted__wrapper-top {
      margin-bottom: 25px; } }
  .trusted__slider-wrapper {
    margin-bottom: 25px; }

.book-an-appointment-toggle {
  position: fixed;
  bottom: 15px;
  background: #4492dc;
  z-index: 9999999;
  width: 50px;
  height: 50px;
  right: 15px;
  border-radius: 50%;
  align-items: center;
  justify-content: center; }
  .book-an-appointment-toggle img {
    width: 30px; }
  .book-an-appointment-toggle.close {
    top: 13px;
    bottom: inherit;
    opacity: 1;
    width: 30px;
    height: 30px; }
    .book-an-appointment-toggle.close::before {
      content: "✕";
      position: absolute;
      font-size: 16px;
      color: #fff; }
    .book-an-appointment-toggle.close img {
      visibility: hidden; }

@media screen and (min-width: 992px) {
  .super-sticky {
    position: absolute;
    z-index: 9999;
    padding-top: 15px; } }

@media screen and (min-width: 992px) {
  .super-sticky.sticky-active {
    position: fixed;
    top: 0; } }

.mwc-appointment-form {
  background: none;
  position: relative;
  width: 100%;
  padding: 0;
  box-shadow: 1px 10px 9px 10px rgba(0, 0, 0, 0.05);
  z-index: 9999; }
  @media screen and (min-width: 992px) {
    .mwc-appointment-form {
      display: block;
      right: inherit;
      left: inherit;
      width: 440px; } }
  .mwc-appointment-form.show {
    display: block;
    height: 100vh;
    background: #fff; }
  .mwc-appointment-form .form-panel {
    float: none;
    width: 100%;
    padding: 20px 0;
    background: #fff; }
    @media screen and (min-width: 992px) {
      .mwc-appointment-form .form-panel {
        padding: 25px; } }
  .mwc-appointment-form .common-input {
    font-family: "Poppins";
    padding: 10px 15px 10px 15px;
    background: none;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    font-size: 14px;
    color: #333;
    margin-bottom: 15px; }
    @media screen and (min-width: 992px) {
      .mwc-appointment-form .common-input {
        padding: 10px 15px 10px 15px; } }
    .mwc-appointment-form .common-input::placeholder {
      color: #333 !important;
      font-size: 14px !important; }
    .mwc-appointment-form .common-input.u-message {
      height: 85px;
      margin-bottom: 5px !important; }
      @media screen and (min-width: 992px) {
        .mwc-appointment-form .common-input.u-message {
          height: 75px; } }
  .mwc-appointment-form .u-submit {
    font-family: "Poppins";
    padding: 12px 0;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    text-transform: uppercase;
    margin: 15px 0 0 0;
    max-width: 100%; }
  .mwc-appointment-form .book-slot-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0 10px; }
    @media screen and (min-width: 992px) {
      .mwc-appointment-form .book-slot-wrapper {
        flex-wrap: inherit; } }
    .mwc-appointment-form .book-slot-wrapper .date-slot {
      flex: 1;
      padding: 10px 10px 10px 10px;
      margin-bottom: 0; }
      @media screen and (min-width: 992px) {
        .mwc-appointment-form .book-slot-wrapper .date-slot {
          padding: 10px 10px 10px 10px;
          flex: 0 0 43%; } }
    .mwc-appointment-form .book-slot-wrapper .time-slot {
      flex: 1;
      padding: 10px 10px 10px 10px;
      margin-bottom: 0; }
      @media screen and (min-width: 992px) {
        .mwc-appointment-form .book-slot-wrapper .time-slot {
          padding: 10px 10px 10px 10px;
          flex: 0 0 30%; } }
  .mwc-appointment-form .book-a-slot-label {
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 1;
    font-size: 0;
    position: absolute; }
  .mwc-appointment-form .book-a-slot-heading {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    color: #163996;
    flex: 0 0 100%;
    margin-bottom: 15px; }
    @media screen and (min-width: 992px) {
      .mwc-appointment-form .book-a-slot-heading {
        flex: auto;
        margin-bottom: 0; } }
  .mwc-appointment-form .slot-radio-wrapper {
    display: flex;
    gap: 15px;
    margin-bottom: 20px; }
  .mwc-appointment-form .slot-radio {
    height: 42px;
    position: relative;
    margin-top: 15px;
    flex: 1; }
  .mwc-appointment-form .slot-radio label,
  .mwc-appointment-form .slot-radio input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .mwc-appointment-form .slot-radio input[type="radio"] {
    opacity: 0.011;
    z-index: 100;
    cursor: pointer; }
  .mwc-appointment-form .slot-radio input[type="radio"]:checked + label {
    border-radius: 4px;
    font-weight: 600;
    border: 1px solid #757575;
    transition: 0.3s all ease; }
  .mwc-appointment-form .slot-radio label {
    cursor: pointer;
    z-index: 90;
    line-height: 1.8em;
    font-family: "Poppins";
    font-size: 15px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dbdbdb;
    border-radius: 3px; }
  .mwc-appointment-form .mwc-appointment-form-top {
    background: #163996;
    padding: 15px 20px 20px 20px;
    position: relative; }
    @media screen and (min-width: 992px) {
      .mwc-appointment-form .mwc-appointment-form-top {
        padding: 25px 20px 30px; } }
    .mwc-appointment-form .mwc-appointment-form-top::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 5px;
      background: url(/images/mwc-new/trusted/stripe-bg.jpg) repeat-x;
      background-position: 15px 0px; }
    .mwc-appointment-form .mwc-appointment-form-top h3 {
      font-family: "Poppins";
      color: #fff;
      text-align: center;
      font-size: 19px;
      line-height: 26px;
      font-weight: 700;
      margin-bottom: 0; }
      .mwc-appointment-form .mwc-appointment-form-top h3 .light-text {
        font-family: "Poppins";
        font-size: 20px; }
      .mwc-appointment-form .mwc-appointment-form-top h3 .hall-info {
        font-family: "Poppins";
        font-size: 20px;
        color: #53d094;
        position: relative; }
        .mwc-appointment-form .mwc-appointment-form-top h3 .hall-info::before {
          content: "";
          position: absolute;
          left: 7px;
          bottom: 0;
          height: 1px;
          width: 96%;
          background: #53d094; }

.common-left-side-wrapper {
  max-width: 100%; }
  @media screen and (min-width: 992px) {
    .common-left-side-wrapper {
      max-width: 690px; } }

.common-bottom-area {
  max-width: 320px;
  margin: 0 auto; }
  @media screen and (min-width: 992px) {
    .common-bottom-area {
      display: flex;
      align-items: center;
      max-width: 100%;
      margin: 0; } }
  .common-bottom-area .common-orange-btn {
    font-family: "Poppins";
    border: 2px solid #ff5800;
    border-radius: 5px;
    background: #ff5800;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    padding: 17px;
    display: block;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize; }
    @media screen and (min-width: 992px) {
      .common-bottom-area .common-orange-btn {
        flex: 0 0 340px;
        max-width: 340px;
        transition: 0.3s all ease-in; } }
    .common-bottom-area .common-orange-btn .common-arrow {
      margin-left: 15px;
      transition: 0.3s all ease-in; }
    .common-bottom-area .common-orange-btn:hover {
      background: none;
      color: #ff5800; }
      .common-bottom-area .common-orange-btn:hover .common-arrow {
        transform: translate(15px, 0); }
      .common-bottom-area .common-orange-btn:hover path {
        fill: #ff5800; }
  .common-bottom-area .email-line {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    color: #898989;
    margin-left: 0;
    margin-top: 15px;
    text-align: center;
    display: block;
    flex: 1; }
    @media screen and (min-width: 992px) {
      .common-bottom-area .email-line {
        text-align: left;
        margin-top: 0;
        margin-left: 25px;
        font-size: 18px;
        line-height: 28px;
        display: inline;
        flex: inherit; } }
    .common-bottom-area .email-line a {
      font-family: "Poppins";
      font-size: 18px;
      font-weight: 700;
      color: #4492dc;
      position: relative;
      max-width: 252px;
      margin: 0 auto; }
      @media screen and (min-width: 992px) {
        .common-bottom-area .email-line a {
          max-width: 308px;
          max-width: inherit;
          margin: 0; } }
      .common-bottom-area .email-line a::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #4492dc; }

.fortune-information {
  padding: 50px 0;
  background: #0d2642; }
  @media screen and (min-width: 992px) {
    .fortune-information {
      padding: 80px 0; } }
  .fortune-information h2 {
    font-family: "Poppins";
    color: #fff;
    font-size: 21px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 15px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .fortune-information h2 {
        text-align: left;
        padding-right: 50px;
        font-size: 24px;
        line-height: 34px;
        font-weight: 700;
        margin-bottom: 30px; } }
    .fortune-information h2 span {
      position: relative;
      cursor: pointer; }
      .fortune-information h2 span:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff; }
      .fortune-information h2 span:nth-child(1):after {
        background: #64b9f4; }
      .fortune-information h2 span:nth-child(2):after {
        background: #e26826; }
      .fortune-information h2 span:nth-child(3):after {
        background: #8ac640; }
      .fortune-information h2 span:nth-child(4):after {
        background: #fbba14; }
      .fortune-information h2 span:nth-child(5):after {
        background: #64b9f4; }
      .fortune-information h2 span:nth-child(6):after {
        background: #804b8b; }
  .fortune-information__meetsarea {
    position: relative;
    max-width: 660px; }
    @media screen and (min-width: 992px) {
      .fortune-information__meetsarea {
        max-width: 660px;
        margin-bottom: 30px; } }
    .fortune-information__meetsarea::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 29px;
      margin: 0 auto;
      width: 1px;
      height: 41%;
      background: #5b81e1;
      display: block; }
      @media screen and (min-width: 992px) {
        .fortune-information__meetsarea::before {
          display: none;
          height: 76%; } }
    .fortune-information__meetsarea .owl-dots {
      background: #0d2642; }
      .fortune-information__meetsarea .owl-dots .owl-dot {
        background: #e1e1e1; }
        .fortune-information__meetsarea .owl-dots .owl-dot.active {
          background: #ff5800; }
  .fortune-information__meetslist {
    display: flex; }
    @media screen and (min-width: 992px) {
      .fortune-information__meetslist {
        flex-wrap: wrap; } }
  .fortune-information__meetsitem {
    position: relative;
    width: 100%;
    padding: 0;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center; }
    @media screen and (min-width: 992px) {
      .fortune-information__meetsitem {
        width: 33.33%;
        padding-bottom: 30px;
        align-items: flex-start;
        flex-direction: row;
        border-right: 1px solid #5b81e1;
        border-bottom: 1px solid #5b81e1; } }
    .fortune-information__meetsitem:after {
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #0d2642;
      bottom: -7.5px;
      right: -7.5px;
      z-index: 1; }
    .fortune-information__meetsitem:nth-child(2n + 2) {
      border-right: none; }
      .fortune-information__meetsitem:nth-child(2n + 2):after {
        display: none; }
        @media screen and (min-width: 992px) {
          .fortune-information__meetsitem:nth-child(2n + 2):after {
            display: block; } }
      @media screen and (min-width: 992px) {
        .fortune-information__meetsitem:nth-child(2n + 2) {
          border-right: 1px solid #5b81e1; } }
    @media screen and (min-width: 992px) {
      .fortune-information__meetsitem:nth-child(3n + 3) {
        border-right: none;
        padding-left: 20px; }
        .fortune-information__meetsitem:nth-child(3n + 3):after {
          display: none; } }
    @media screen and (min-width: 992px) {
      .fortune-information__meetsitem:nth-last-child(-n + 3) {
        border-bottom: none;
        padding-top: 30px;
        padding-bottom: 10px; }
        .fortune-information__meetsitem:nth-last-child(-n + 3):after {
          display: none; } }
    .fortune-information__meetsitem:nth-last-child(-n + 2) {
      border-bottom: none; }
    .fortune-information__meetsitem:nth-child(2n) {
      justify-content: center; }
      .fortune-information__meetsitem:nth-child(2n) .excellence-meets__listcontent {
        max-width: 100px; }
    .fortune-information__meetsitem:nth-child(5n) {
      justify-content: center; }
      .fortune-information__meetsitem:nth-child(5n) .excellence-meets__listcontent {
        margin: 0;
        max-width: 120px; }
  .fortune-information__listcontent {
    margin-top: 20px;
    margin-left: 10px;
    max-width: 100%;
    text-align: left; }
    @media screen and (min-width: 992px) {
      .fortune-information__listcontent {
        margin-top: 0;
        margin-left: 20px;
        max-width: 162px; } }
  .fortune-information__contenttittle {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    color: #fff;
    margin-top: 5px; }
    @media screen and (min-width: 992px) {
      .fortune-information__contenttittle {
        font-size: 23px;
        line-height: 25px;
        margin-top: 10px; } }
  .fortune-information__contenttext {
    font-family: "Poppins";
    color: #fff;
    font-size: 15px;
    line-height: 21px;
    font-weight: 500;
    margin: 0; }
    @media screen and (min-width: 992px) {
      .fortune-information__contenttext {
        font-size: 15px;
        line-height: 18px; } }
  .fortune-information--new .fortune-information__meetsarea::before {
    top: 15px; }
  .fortune-information--new .fortune-information__contenttittle {
    font-size: 18px;
    line-height: 20px;
    color: #358acb; }
    @media screen and (min-width: 992px) {
      .fortune-information--new .fortune-information__contenttittle {
        font-size: 23px;
        line-height: 25px;
        margin-top: 5px; } }
  @media screen and (min-width: 992px) {
    .fortune-information--new .fortune-information__meetsitem {
      padding-bottom: 20px; } }
  .fortune-information--new .fortune-information__meetsitem:nth-child(2n) {
    justify-content: flex-start; }
  .fortune-information--new .fortune-information__meetsitem::after {
    display: none; }
    @media screen and (min-width: 992px) {
      .fortune-information--new .fortune-information__meetsitem::after {
        display: block; } }
  @media screen and (min-width: 992px) {
    .fortune-information--new .fortune-information__meetsitem:nth-last-child(-n + 3) {
      padding-top: 20px; } }
  .fortune-information .common-bottom-area {
    margin-top: 20px; }
    @media screen and (min-width: 992px) {
      .fortune-information .common-bottom-area {
        margin-top: 0; } }

.limited-time {
  padding: 40px 0 50px;
  border-bottom: 1px solid #e6e6e6; }
  @media screen and (min-width: 992px) {
    .limited-time {
      padding: 100px 0; } }
  .limited-time__wrapper {
    max-width: 660px; }
  .limited-time h2 {
    font-family: "Poppins";
    font-size: 32px;
    line-height: 40px;
    color: #262e36;
    margin-bottom: 15px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .limited-time h2 {
        font-size: 51px;
        line-height: 40px;
        text-align: left;
        margin-bottom: 60px; } }
    .limited-time h2 .mwc-offer-black {
      color: #000;
      display: block;
      margin-top: 0; }
      @media screen and (min-width: 992px) {
        .limited-time h2 .mwc-offer-black {
          display: inline-block;
          margin-top: 0;
          margin-top: 24px; } }
    .limited-time h2 .top-graphic {
      position: relative; }
      .limited-time h2 .top-graphic:after {
        content: "";
        position: absolute;
        top: -9px;
        right: 0;
        left: 0;
        margin: auto;
        background: url(/images/mwc-new/banner/touble-triangle.svg) no-repeat center center;
        width: 18px;
        height: 18px;
        background-size: 100%; }
        @media screen and (min-width: 992px) {
          .limited-time h2 .top-graphic:after {
            top: -32px;
            width: 45px;
            height: 37px;
            background-size: inherit; } }
  .limited-time__card-top {
    height: 1px; }
  .limited-time__card {
    position: relative;
    margin-top: 13px;
    margin-bottom: 30px;
    padding: 30px 15px;
    border: 1px solid;
    border-top: 8px solid;
    border-color: #3cc065;
    background: #fff;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05); }
    @media screen and (min-width: 992px) {
      .limited-time__card {
        padding: 45px 39px;
        margin-bottom: 43px; } }
    .limited-time__card h3 {
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 600;
      position: absolute;
      left: 15px;
      top: -24px;
      padding: 10px 10px;
      color: #000;
      background: #fcc900;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px; }
      @media screen and (min-width: 992px) {
        .limited-time__card h3 {
          top: -23px;
          left: 39px;
          font-size: 19px;
          padding: 8px 14px; } }
      .limited-time__card h3:before {
        position: absolute;
        content: "";
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 16px 12px 0 0;
        border-color: #fcc900 transparent transparent transparent;
        transform: rotate(0deg);
        right: -12px;
        top: 0; }
      .limited-time__card h3:after {
        position: absolute;
        content: "";
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 16px 0 0 12px;
        border-color: transparent transparent transparent #fcc900;
        transform: rotate(0deg);
        right: -12px;
        bottom: 0; }
    .limited-time__card h4 {
      font-family: "Poppins";
      font-size: 21px;
      line-height: 30px;
      color: #262e36;
      margin-bottom: 16px;
      font-weight: 700; }
      @media screen and (min-width: 992px) {
        .limited-time__card h4 {
          font-size: 31px; } }
    .limited-time__card ul {
      padding-left: 30px;
      margin-bottom: 20px; }
      @media screen and (min-width: 992px) {
        .limited-time__card ul {
          margin-bottom: 28px; } }
      .limited-time__card ul li {
        font-family: "Poppins";
        position: relative;
        color: #262e36;
        font-size: 16px;
        line-height: 28px; }
        @media screen and (min-width: 992px) {
          .limited-time__card ul li {
            font-size: 17px; } }
        .limited-time__card ul li:before {
          position: absolute;
          content: "";
          left: -30px;
          top: 5px;
          width: 16px;
          height: 16px;
          background: url(/images/mwc-new/icon/green-tick.svg) no-repeat center center;
          background-size: 100% 100%; }
    .limited-time__card .btn {
      font-family: "Poppins";
      text-transform: capitalize;
      margin: 0;
      border: 2px solid #333;
      font-weight: 600;
      padding: 10px;
      width: 214px;
      font-size: 15px;
      font-weight: 700;
      color: #333;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s all ease-in; }
      .limited-time__card .btn .far-arrow {
        margin-left: 15px;
        transition: 0.3s all ease-in; }
      .limited-time__card .btn:hover {
        background: none;
        color: #ff5800;
        border-color: #ff5800; }
        .limited-time__card .btn:hover .far-arrow {
          transform: translate(15px, 0); }
        .limited-time__card .btn:hover path {
          fill: #ff5800; }
    .limited-time__card--blue {
      border-color: #4492dc; }
      .limited-time__card--blue ul li:before {
        background: url(/images/mwc-new/icon/blue-tick.svg) no-repeat center center;
        background-size: 100% 100%; }
  .limited-time__lets-meet {
    position: relative; }
    .limited-time__lets-meet h5 {
      font-family: "Poppins";
      font-size: 26px;
      line-height: 36px;
      color: #262e36;
      font-weight: 600;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .limited-time__lets-meet h5 {
          font-size: 36px;
          text-align: left; } }
    .limited-time__lets-meet p {
      font-family: "Poppins";
      font-size: 20px;
      line-height: 30px;
      color: #262e36;
      font-weight: 500;
      margin-bottom: 18px;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .limited-time__lets-meet p {
          font-size: 24px;
          line-height: 36px;
          text-align: left; } }
      .limited-time__lets-meet p span {
        background-color: #7feba1;
        padding: 0 5px;
        border-radius: 10px;
        font-weight: 600;
        display: block;
        max-width: 300px;
        margin: 0 auto; }
        @media screen and (min-width: 992px) {
          .limited-time__lets-meet p span {
            display: inline-block;
            max-width: inherit;
            margin: 0; } }
    @media screen and (min-width: 992px) {
      .limited-time__lets-meet--post h5 {
        font-size: 29px; } }
    .limited-time__lets-meet--post p {
      font-size: 16px;
      line-height: 24px; }
      @media screen and (min-width: 992px) {
        .limited-time__lets-meet--post p {
          font-size: 18px;
          line-height: 30px; } }
  .limited-time .common-bottom-area .email-line a::before {
    width: 100%; }

.mwc-reviews {
  position: relative;
  background-color: #f3f3f3;
  padding: 30px 0 80px; }
  @media screen and (min-width: 992px) {
    .mwc-reviews {
      padding: 76px 0 98px; } }
  .mwc-reviews__star {
    margin-bottom: 11px;
    display: flex;
    justify-content: flex-start; }
    .mwc-reviews__star li {
      margin-left: 1px;
      margin-right: 1px; }
      @media screen and (min-width: 992px) {
        .mwc-reviews__star li {
          margin-left: inherit;
          margin-right: 3px; } }
  .mwc-reviews h2 {
    font-family: "Poppins";
    font-size: 26px;
    line-height: 36px;
    color: #262e36;
    padding-bottom: 34px;
    margin-bottom: 22px;
    text-align: left; }
    @media screen and (min-width: 992px) {
      .mwc-reviews h2 {
        font-size: 42px;
        line-height: 46px;
        margin-bottom: 35px; } }
    .mwc-reviews h2:after {
      position: absolute;
      content: "";
      width: 83px;
      height: 7px;
      background-color: #498df9;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0; }
      @media screen and (min-width: 992px) {
        .mwc-reviews h2:after {
          right: inherit; } }
  @media screen and (min-width: 992px) {
    .mwc-reviews__reviewarea {
      padding-bottom: 30px;
      max-width: 660px; } }
  .mwc-reviews__reviewarea .owl-nav {
    margin-bottom: 0;
    margin-top: 10px;
    width: auto;
    text-align: left; }
    @media screen and (min-width: 992px) {
      .mwc-reviews__reviewarea .owl-nav {
        float: right;
        margin-bottom: 0;
        margin-top: 40px;
        margin-right: -5px;
        margin-bottom: 15px; } }
    .mwc-reviews__reviewarea .owl-nav .owl-prev {
      font-size: 30px !important;
      color: #3cc065 !important;
      width: 36px;
      height: 36px;
      border: 1px solid #3cc065 !important;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300 !important; }
      @media screen and (min-width: 992px) {
        .mwc-reviews__reviewarea .owl-nav .owl-prev {
          font-size: 45px !important;
          width: 50px;
          height: 50px; } }
      .mwc-reviews__reviewarea .owl-nav .owl-prev span {
        position: relative;
        top: -1px;
        left: -1px;
        height: 50px;
        line-height: 50px; }
        @media screen and (min-width: 992px) {
          .mwc-reviews__reviewarea .owl-nav .owl-prev span {
            top: -3px; } }
      .mwc-reviews__reviewarea .owl-nav .owl-prev:hover {
        color: #fff !important;
        background-color: #3cc065 !important; }
    .mwc-reviews__reviewarea .owl-nav .owl-next {
      font-size: 30px !important;
      color: #3cc065 !important;
      width: 36px;
      height: 36px;
      border: 1px solid #3cc065 !important;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300 !important; }
      @media screen and (min-width: 992px) {
        .mwc-reviews__reviewarea .owl-nav .owl-next {
          font-size: 45px !important;
          width: 50px;
          height: 50px; } }
      .mwc-reviews__reviewarea .owl-nav .owl-next span {
        position: relative;
        top: -1px;
        right: -1px;
        height: 50px;
        line-height: 50px; }
        @media screen and (min-width: 992px) {
          .mwc-reviews__reviewarea .owl-nav .owl-next span {
            top: -3px; } }
      .mwc-reviews__reviewarea .owl-nav .owl-next:hover {
        color: #fff !important;
        background-color: #3cc065 !important; }
  .mwc-reviews__reviewitems {
    max-width: 100%;
    height: 100%;
    width: 100%;
    border: 1px solid #ddd;
    background-color: #fff;
    padding: 20px 20px 20px 20px;
    margin: 0 0 20px;
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 992px) {
      .mwc-reviews__reviewitems {
        height: auto;
        max-width: 345px;
        margin: 0 0 30px;
        min-height: 380px;
        padding: 38px 30px 50px 43px; } }
    .mwc-reviews__reviewitems:hover {
      border-color: #fff;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2); }
  .mwc-reviews__quoteimg {
    margin-bottom: 20px; }
  .mwc-reviews__quotecontent {
    font-family: "Poppins";
    color: #333;
    font-size: 20px;
    line-height: 29px;
    font-weight: 500; }
  .mwc-reviews__authorarea {
    display: flex;
    margin-top: 15px; }
    @media screen and (min-width: 992px) {
      .mwc-reviews__authorarea {
        margin-top: auto; } }
  .mwc-reviews__authorimage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden; }
  .mwc-reviews__authordetails {
    flex: 1;
    margin-left: 15px; }
  .mwc-reviews__authorname {
    font-family: "Poppins";
    font-size: 18px;
    line-height: normal;
    color: #333;
    font-weight: 600;
    margin: 0; }
  .mwc-reviews__authordesignation {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    color: #333;
    margin: 0;
    font-weight: 600; }
  .mwc-reviews__authorcompany {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    color: #898989;
    margin: 0;
    font-weight: 500; }
  .mwc-reviews .splide__arrows {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: -80px; }
  .mwc-reviews .splide__arrow {
    position: static;
    width: 50px;
    height: 50px;
    background: none;
    border: 1px solid #3cc065;
    transition: .3s all ease-in-out; }
    .mwc-reviews .splide__arrow svg path {
      fill: #3cc065; }
    .mwc-reviews .splide__arrow:hover {
      background: #3cc065; }
      .mwc-reviews .splide__arrow:hover svg path {
        fill: #fff; }
  .mwc-reviews .splide__arrow--prev {
    margin-right: 10px; }

.mwc-footer {
  padding: 50px 0;
  background-color: #04254a;
  font-family: "Poppins"; }
  @media screen and (min-width: 992px) {
    .mwc-footer {
      padding: 79px 0 82px; } }
  .mwc-footer__top {
    padding-bottom: 40px;
    margin-bottom: 20px;
    border-bottom: 1px solid #244376;
    text-align: left; }
    @media screen and (min-width: 992px) {
      .mwc-footer__top {
        max-width: 660px; } }
  .mwc-footer__paragraph {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 20px;
    color: #aec7da;
    margin: 20px 0 0; }
    @media screen and (min-width: 992px) {
      .mwc-footer__paragraph {
        padding-right: 30px; } }
  .mwc-footer__bottom {
    text-align: left; }
    .mwc-footer__bottom p {
      font-family: "Poppins";
      color: #fff;
      font-size: 14px;
      line-height: 24px; }
      @media screen and (min-width: 992px) {
        .mwc-footer__bottom p {
          line-height: 15px; } }
  .mwc-footer__copyright {
    font-family: "Poppins";
    margin-bottom: 15px; }

.datepicker-wrapper {
  position: relative;
  display: flex;
  border: 1px solid #dbdbdb;
  width: 100%;
  align-items: center;
  justify-content: center; }
  .datepicker-wrapper input {
    font-family: "Poppins";
    border: 0;
    font-weight: 400;
    font-size: 15px;
    flex: 1;
    text-align: center;
    padding: 10px 15px;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb; }
  .datepicker-wrapper #prevBtn {
    flex: 0 0 40px;
    max-width: 40px;
    font-size: 24px; }
  .datepicker-wrapper #nextBtn {
    flex: 0 0 40px;
    max-width: 40px;
    font-size: 24px; }

.mwc-gain {
  margin: 40px 0 0; }
  .mwc-gain .btn {
    font-family: Poppins;
    border: 2px solid #ff5800;
    border-radius: 5px;
    background: #ff5800;
    max-width: fit-content;
    width: 100%;
    padding: 17px;
    display: block;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize; }
    .mwc-gain .btn .common-arrow {
      margin-left: 15px;
      transition: .3s all ease-in; }
    .mwc-gain .btn:hover .common-arrow {
      transform: translate(10px, 0); }
  .mwc-gain p {
    font-family: Poppins;
    position: relative;
    color: #262e36;
    font-size: 17px;
    line-height: 28px;
    margin: 10px 0 20px; }

.custom-day {
  font-weight: 700; }

.mwc-casestudy-wrap {
  width: 100%; }
  @media screen and (min-width: 992px) {
    .mwc-casestudy-wrap {
      max-width: 660px; } }
  .mwc-casestudy-wrap .o-testimonial__heading {
    font-family: Poppins;
    font-size: 26px !important;
    line-height: 36px !important;
    color: #262e36;
    padding-bottom: 34px;
    text-align: left;
    font-weight: 800 !important;
    margin: 0 !important; }
    @media screen and (min-width: 992px) {
      .mwc-casestudy-wrap .o-testimonial__heading {
        font-size: 42px !important;
        line-height: 46px !important;
        margin-bottom: 35px; } }
  .mwc-casestudy-wrap .o-testimonial__more--splidebtn {
    margin: 20px 0 0 !important;
    position: relative;
    text-decoration: none;
    font-family: Poppins;
    text-transform: capitalize;
    margin: 0;
    border-radius: 5px;
    border: 2px solid #ff5800;
    font-weight: 600;
    padding: 10px;
    width: 250px;
    font-size: 17px;
    font-weight: 600;
    padding: 17px;
    color: #ff5800;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: .3s all ease-in;
    transition: .3s all ease-in; }
    .mwc-casestudy-wrap .o-testimonial__more--splidebtn:hover {
      text-decoration: none;
      background: #ff5800;
      color: #fff; }
  .mwc-casestudy-wrap .o-testimonial__item {
    margin: 0;
    background: transparent;
    box-shadow: none; }
  .mwc-casestudy-wrap .o-testimonial__itemimage {
    height: 200px !important; }
  .mwc-casestudy-wrap .o-testimonial__itemtitle {
    font-size: 18px !important;
    line-height: 26px !important; }
  .mwc-casestudy-wrap .splide__arrows {
    display: flex !important;
    justify-content: flex-end;
    position: absolute;
    bottom: -80px;
    width: 100%; }
  .mwc-casestudy-wrap .splide__arrow {
    position: static !important;
    width: 50px;
    height: 50px;
    background: 0 0;
    border: 1px solid #3cc065;
    transform: none; }
    .mwc-casestudy-wrap .splide__arrow--prev {
      margin-right: 10px; }
    .mwc-casestudy-wrap .splide__arrow svg {
      fill: #3cc065; }

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  z-index: 9999; }

.preloader.hidden {
  display: none; }

@media screen and (min-width: 992px) {
  .mwc-banner-digital {
    padding: 45px 0 50px !important; } }

@media screen and (min-width: 992px) {
  .mwc-banner-digital .mwc-banner__left p {
    padding-right: 50px; } }

@media screen and (min-width: 992px) {
  .mwc-banner-digital .mwc-banner__left p.long-text {
    padding-right: 40px; } }

/* ANCHOR -New How we Work*/
.o-how-it-work {
  background-color: #f9f9f9; }
  .o-how-it-work__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-how-it-work__spacing {
        padding: 60px 0; } }
  .o-how-it-work__heading {
    text-align: center;
    font-family: 'Poppins';
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    color: #262E36;
    margin-bottom: 45px; }
    @media screen and (min-width: 992px) {
      .o-how-it-work__heading {
        font-size: 51px;
        line-height: 53px;
        margin-bottom: 60px; } }
  .o-how-it-work__list {
    position: relative;
    width: 100%;
    margin-bottom: 60px; }
  .o-how-it-work__item {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: inherit;
    margin-bottom: 50px; }
    @media screen and (min-width: 992px) {
      .o-how-it-work__item {
        margin-bottom: inherit;
        margin-top: -30px; } }
    .o-how-it-work__item:nth-child(2n+2) {
      justify-content: flex-end; }
      @media screen and (min-width: 992px) {
        .o-how-it-work__item:nth-child(2n+2) .o-how-it-work__itembox:before {
          left: -36px;
          border-color: transparent #bbc6d4 transparent transparent; } }
      @media screen and (min-width: 992px) {
        .o-how-it-work__item:nth-child(2n+2) .o-how-it-work__itembox:after {
          left: -32px;
          border-color: transparent #fff transparent transparent; } }
      @media screen and (min-width: 992px) {
        .o-how-it-work__item:nth-child(2n+2) .o-how-it-work__itemnumber {
          left: inherit;
          right: 35px; } }
  .o-how-it-work__itembox {
    position: relative;
    max-width: 100%;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #bbc6d4;
    padding: 35px;
    background: #fff;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-how-it-work__itembox {
        text-align: left; } }
    .o-how-it-work__itembox:before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      top: inherit;
      left: 0;
      right: 0;
      bottom: -36px;
      border-style: solid;
      border-color: #bbc6d4 transparent transparent transparent;
      border-width: 18px;
      margin: auto; }
      @media screen and (min-width: 992px) {
        .o-how-it-work__itembox:before {
          top: 28px;
          left: 100%;
          right: inherit;
          bottom: inherit;
          border-color: transparent transparent transparent #bbc6d4; } }
    .o-how-it-work__itembox:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 0;
      height: 0;
      border-style: solid;
      top: inherit;
      bottom: -32px;
      border-color: #fff transparent transparent transparent;
      border-width: 16px; }
      @media screen and (min-width: 992px) {
        .o-how-it-work__itembox:after {
          top: 30px;
          left: 100%;
          right: inherit;
          bottom: inherit;
          border-color: transparent transparent transparent #fff; } }
    @media screen and (min-width: 992px) {
      .o-how-it-work__itembox {
        max-width: 510px;
        width: 40%; } }
  .o-how-it-work__itemnumber {
    position: absolute;
    width: 49px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4492DC;
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 600;
    left: 35px;
    top: -25px;
    border: 1px solid #4492dc;
    border-radius: 50%;
    background: #fff;
    z-index: 1; }
  .o-how-it-work__itemtitle {
    font-family: 'Poppins';
    color: #333;
    font-size: 21px;
    line-height: 40px;
    margin-bottom: 14px; }
  .o-how-it-work__itemcontent {
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 0; }
  .o-how-it-work__imagemarker {
    position: absolute;
    width: 84px;
    height: 84px;
    border: 1px solid #7FEBA1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    left: 0;
    right: 0;
    top: 10px;
    margin: auto;
    padding: 15px; }
    .o-how-it-work__imagemarker:after {
      position: absolute;
      content: "";
      width: 11px;
      height: 48px;
      background: url(/images/two-for-one/how-it-work-downarrow.svg) no-repeat;
      background-position: center;
      background-size: auto 100%;
      left: 0;
      right: 0;
      bottom: -60px;
      margin: auto; }
  .o-how-it-work__itempointitem {
    position: relative;
    padding-left: 30px;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 5px;
    color: #333;
    font-weight: 500;
    text-align: left; }
    .o-how-it-work__itempointitem:last-child {
      margin-bottom: 0; }
    .o-how-it-work__itempointitem:before {
      position: absolute;
      content: "";
      left: 0;
      top: 4px;
      width: 20px;
      height: 20px;
      background: url(/images/two-for-one/how-it-work-list-icon.svg) no-repeat;
      background-position: center;
      background-size: 100% 100%; }
  .o-how-it-work__btn {
    width: auto;
    max-width: fit-content;
    font-size: 19px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 15px 30px; }

.o-tailored {
  background: #ffffff;
  border-top: 1px solid #E0E0E0; }
  .o-tailored--company-profile {
    background: #f9f9f9;
    border-top: none; }
    @media screen and (min-width: 1200px) {
      .o-tailored--company-profile .container {
        max-width: 1050px; } }
  .o-tailored__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-tailored__spacing {
        padding: 60px 0; } }
  .o-tailored__heading {
    text-align: center;
    font-family: 'Poppins';
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    color: #262E36;
    margin-bottom: 25px;
    position: relative; }
    .o-tailored__heading:after {
      position: absolute;
      content: "";
      width: 83px;
      height: 7px;
      left: 0;
      right: 0;
      bottom: -22px;
      margin: auto;
      background-color: #3CC065; }
    @media screen and (min-width: 992px) {
      .o-tailored__heading--company-profile {
        line-height: 65px !important; } }
    .o-tailored__heading--company-profile:after {
      display: none; }
    @media screen and (min-width: 992px) {
      .o-tailored__heading {
        font-size: 51px;
        line-height: 53px;
        margin-bottom: 45px; } }
    @media screen and (min-width: 992px) {
      .o-tailored__heading--company-profile-dita {
        margin-bottom: 20px; } }
  .o-tailored__subheading {
    text-align: center;
    font-family: 'Poppins';
    color: #474747;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 35px; }
    @media screen and (min-width: 992px) {
      .o-tailored__subheading {
        font-size: 25px;
        line-height: 34px; } }
  .o-tailored__box {
    padding: 25px;
    border: 1px solid #898989;
    height: 100%;
    min-height: auto;
    width: 100%;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-tailored__box {
        min-height: 193px;
        text-align: left;
        padding: 26px 18px 18px 22px; } }
    @media screen and (min-width: 992px) {
      .o-tailored__box--company-profile {
        min-height: 294px;
        padding: 40px 18px 15px 30px; } }
    .o-tailored__box--company-profile i {
      display: inline-block;
      height: 46px; }
    .o-tailored__box--company-profile .o-tailored__boxheading {
      font-size: 21px;
      line-height: 31px;
      margin-bottom: 15px; }
    .o-tailored__box--company-profile .o-tailored__boxcontent {
      font-size: 16px;
      line-height: 25px; }
  .o-tailored .mb-30 {
    margin-bottom: 30px; }
  .o-tailored__boxheading {
    font-family: 'Poppins';
    color: #333;
    font-size: 20px;
    line-height: 31px;
    margin: 11px 0 7px;
    font-weight: 600; }
  .o-tailored__boxcontent {
    font-family: 'Poppins';
    color: #727272;
    font-size: 14px;
    line-height: 22px;
    margin: 0; }
  .o-tailored__bottomtittle {
    font-family: 'Poppins';
    color: #333;
    font-size: 25px;
    line-height: 40px;
    margin: 18px 0 16px;
    font-weight: 700;
    text-align: center; }
  .o-tailored__btn {
    max-width: 100%;
    width: 100%;
    text-transform: capitalize;
    font-size: 19px;
    padding: 15px; }
    @media screen and (min-width: 992px) {
      .o-tailored__btn {
        max-width: 313px; } }
  .o-tailored--company-profile-dita {
    border-bottom: 1px solid #E6E6E6;
    background: #fff !important; }
    @media screen and (min-width: 1280px) {
      .o-tailored--company-profile-dita .container {
        max-width: 1170px; } }
    .o-tailored--company-profile-dita .o-tailored__box--company-profile {
      border: 1px solid #DDDDDD;
      box-shadow: 0px 1px 22px 10px rgba(0, 0, 0, 0.05); }
      @media screen and (min-width: 992px) {
        .o-tailored--company-profile-dita .o-tailored__box--company-profile {
          min-height: 250px; } }
      .o-tailored--company-profile-dita .o-tailored__box--company-profile i {
        height: 50px; }
    .o-tailored--company-profile-dita .o-tailored__boxheading {
      margin-top: 20px;
      margin-bottom: 5px; }
      @media screen and (min-width: 992px) {
        .o-tailored--company-profile-dita .o-tailored__boxheading {
          font-size: 21px;
          line-height: 25px;
          font-weight: 700; } }
    .o-tailored--company-profile-dita .o-tailored__boxcontent {
      font-size: 15px;
      line-height: 25px;
      color: #333; }
    .o-tailored--company-profile-dita .btn-orange {
      font-size: 16px; }
      @media screen and (min-width: 992px) {
        .o-tailored--company-profile-dita .btn-orange {
          width: 280px;
          margin-top: 10px; } }
  .o-tailored__para {
    text-align: center;
    font-family: 'Poppins';
    color: #333333;
    font-weight: 500; }
    @media screen and (min-width: 992px) {
      .o-tailored__para {
        font-size: 22px;
        line-height: 29px;
        margin-bottom: 40px; } }

.o-how-excellence {
  background-color: #355BBF; }
  .o-how-excellence__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-how-excellence__spacing {
        padding: 60px 0; } }
  .o-how-excellence__heading {
    text-align: center;
    font-family: 'Poppins';
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    max-width: 100%;
    margin: 0 auto 45px; }
    @media screen and (min-width: 992px) {
      .o-how-excellence__heading {
        max-width: 85%; } }
  .o-how-excellence__list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; }
    @media screen and (min-width: 992px) {
      .o-how-excellence__list {
        gap: 0; } }
  .o-how-excellence__item {
    position: relative;
    max-width: 48%;
    width: 48%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .o-how-excellence__item {
        max-width: 25%;
        width: 25%;
        border-right: 1px solid #5B81E1;
        border-bottom: 1px solid #5B81E1; } }
    @media screen and (min-width: 1140px) {
      .o-how-excellence__item {
        max-width: 16.5%;
        width: 16.5%; } }
    .o-how-excellence__item:after {
      display: none; }
      @media screen and (min-width: 992px) {
        .o-how-excellence__item:after {
          display: block;
          position: absolute;
          content: "";
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #355BBF;
          bottom: -7.5px;
          right: -7.5px;
          z-index: 1; } }
    @media screen and (min-width: 992px) {
      .o-how-excellence__item:first-child {
        justify-content: flex-start; } }
    .o-how-excellence__item:nth-child(2n+2) {
      border-right: none; }
      @media screen and (min-width: 992px) {
        .o-how-excellence__item:nth-child(2n+2) {
          border-right: 1px solid #5B81E1; } }
    @media screen and (min-width: 992px) {
      .o-how-excellence__item:nth-child(4n+4) {
        border-right: none; } }
    @media screen and (min-width: 1140px) {
      .o-how-excellence__item:nth-child(4n+4) {
        border-right: 1px solid #5B81E1; } }
    @media screen and (min-width: 1140px) {
      .o-how-excellence__item:nth-child(6n+6) {
        border-right: none; } }
    .o-how-excellence__item:last-child {
      border-right: none;
      border-bottom: none; }
    .o-how-excellence__item:nth-last-child(-n+2) {
      border-bottom: none; }
    @media screen and (min-width: 992px) {
      .o-how-excellence__item:nth-last-child(-n+3) {
        border-bottom: none; } }
    @media screen and (min-width: 992px) {
      .o-how-excellence__item:nth-last-child(-n+4) {
        border-bottom: none; } }
    @media screen and (min-width: 1140px) {
      .o-how-excellence__item:nth-last-child(-n+5) {
        border-bottom: none; } }
    @media screen and (min-width: 1140px) {
      .o-how-excellence__item:nth-last-child(-n+6) {
        border-bottom: none; } }
  .o-how-excellence__items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 85%;
    width: 100%; }
    @media screen and (min-width: 992px) {
      .o-how-excellence__items {
        max-width: 178px; } }
    .o-how-excellence__items i {
      flex: 0 0 auto;
      margin-right: 10px; }
      @media screen and (min-width: 992px) {
        .o-how-excellence__items i {
          flex: 0 0 40px;
          margin-right: 9px; } }
      @media screen and (min-width: 1180px) {
        .o-how-excellence__items i {
          margin-left: 10px;
          margin-right: 15px; } }
  .o-how-excellence .owl-stage-outer:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 1px;
    height: 100%;
    background: #5b81e1; }
  .o-how-excellence__itemtitle {
    font-family: 'Poppins';
    color: #fff;
    font-size: 18px !important;
    line-height: 20px !important;
    font-weight: 700;
    margin: 0; }
    @media screen and (min-width: 992px) {
      .o-how-excellence__itemtitle {
        font-size: 20px !important;
        line-height: 24px !important; } }
    .o-how-excellence__itemtitle--f21 {
      font-size: 16px !important; }
      @media screen and (min-width: 992px) {
        .o-how-excellence__itemtitle--f21 {
          font-size: 20px !important; } }
  .o-how-excellence__itemsub {
    font-family: 'Poppins';
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500; }
    @media screen and (min-width: 992px) {
      .o-how-excellence__itemsub {
        font-size: 14px;
        line-height: 20px; } }
  .o-how-excellence__asterisktext {
    margin: 5px 0 0;
    color: #fff;
    line-height: normal; }

/* ANCHOR - new technologies */
.banner-technologies {
  padding: 40px 0;
  background-image: url(/images/new-home-banner/banner-gradient.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  position: relative;
  background-color: #0d2642; }
  .banner-technologies__subtitle {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
    color: #3CC065; }
    @media (min-width: 767px) {
      .banner-technologies__subtitle {
        font-size: 25px;
        line-height: 40px;
        font-weight: 700; } }
    @media (min-width: 1024px) {
      .banner-technologies__subtitle {
        font-size: 31px;
        line-height: 47px; } }
  .banner-technologies__header-br {
    display: none; }
    @media (min-width: 1024px) {
      .banner-technologies__header-br {
        display: block; } }
  @media (min-width: 1024px) {
    .banner-technologies {
      padding: 60px 0; } }
  @media (min-width: 1024px) {
    .banner-technologies {
      padding: 80px 0; } }
  .banner-technologies__heading {
    font-size: 31px;
    line-height: 36px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin-bottom: 25px;
    font-family: 'Poppins' !important; }
    @media (min-width: 767px) {
      .banner-technologies__heading {
        font-size: 41px;
        line-height: 46px;
        margin-bottom: 30px; } }
    @media (min-width: 1024px) {
      .banner-technologies__heading {
        font-size: 51px;
        line-height: 56px;
        margin-bottom: 40px; } }
  .banner-technologies__list-items {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    gap: 5px !important;
    border: 1px solid transparent !important; }
    @media (min-width: 767px) {
      .banner-technologies__list-items {
        gap: 10px !important; } }
  .banner-technologies__list-item {
    flex: 0 0 auto;
    white-space: nowrap;
    width: auto;
    padding: 6px 16px;
    color: #fff;
    border: none !important;
    border-radius: 50px;
    font-family: 'Poppins' !important; }
    .banner-technologies__list-item:before {
      width: 500px !important;
      height: 500px !important;
      top: -250px !important;
      left: -20% !important;
      right: inherit;
      bottom: inherit; }
    @media (min-width: 767px) {
      .banner-technologies__list-item {
        padding: 6px 20px; } }
    @media (min-width: 992px) {
      .banner-technologies__list-item {
        flex-direction: row !important;
        width: auto !important; } }
    @media (min-width: 1024px) {
      .banner-technologies__list-item {
        padding: 8px 24px; } }
  .banner-technologies__list-link {
    color: #fff;
    font-size: 15px !important;
    font-weight: 500 !important; }
    @media (min-width: 767px) {
      .banner-technologies__list-link {
        font-size: 18px !important;
        font-weight: 500 !important; } }
    @media (min-width: 1024px) {
      .banner-technologies__list-link {
        font-size: 21px !important;
        font-weight: 500 !important; } }
  .banner-technologies .no-href {
    font-family: 'Poppins' !important;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0 5px;
    background: #0d2642;
    border-radius: 30px;
    padding: 5px 15px;
    position: relative;
    z-index: 9;
    -webkit-appearance: none;
    appearance: none; }
    @media screen and (min-width: 992px) {
      .banner-technologies .no-href {
        font-size: 26px;
        padding: 5px 25px; } }
    .banner-technologies .no-href svg {
      transition: .3s all ease-in-out; }
  .banner-technologies .green-blue:hover:before {
    background: -moz-linear-gradient(180deg, rgba(6, 87, 181, 0.62) 0%, rgba(35, 116, 212, 0.62) 20%, #1ab864 52%, #88deb1 100%);
    background: -webkit-linear-gradient(180deg, rgba(6, 87, 181, 0.62) 0%, rgba(35, 116, 212, 0.62) 20%, #1ab864 52%, #88deb1 100%);
    background: linear-gradient(180deg, rgba(6, 87, 181, 0.62) 0%, rgba(35, 116, 212, 0.62) 20%, #1ab864 52%, #88deb1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0657b5",endColorstr="#88deb1",GradientType=1); }

.technologies-tab {
  position: relative;
  overflow: hidden;
  padding: 30px 0px; }
  @media (min-width: 992px) {
    .technologies-tab {
      padding: 60px 0;
      padding-bottom: 0px; } }
  .technologies-tab--accorbtn .o-accordion__tab {
    font-size: 22px !important; }
  .technologies-tab__heading {
    font-size: 31px;
    line-height: 45px;
    font-family: 'Poppins' !important;
    color: #000;
    text-align: center; }
    @media (min-width: 767px) {
      .technologies-tab__heading {
        font-size: 41px;
        line-height: 45px; } }
    @media (min-width: 1024px) {
      .technologies-tab__heading {
        font-size: 51px;
        line-height: 55px; } }
  @media (min-width: 1024px) {
    .technologies-tab__heading-br {
      display: block; } }
  .technologies-tab__area {
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 30px; }
    @media (min-width: 770px) {
      .technologies-tab__area {
        display: flex; } }
    @media (min-width: 1024px) {
      .technologies-tab__area {
        display: grid;
        grid-template-columns: 3fr 4fr;
        padding-top: 70px; } }
  .technologies-tab__area-left {
    width: 100%; }
    @media (min-width: 770px) {
      .technologies-tab__area-left {
        width: 50%; } }
    @media (min-width: 992px) {
      .technologies-tab__area-left {
        width: auto; } }
  .technologies-tab__area-right {
    position: relative;
    display: none; }
    @media (min-width: 770px) {
      .technologies-tab__area-right {
        display: block;
        width: 50%; } }
    @media (min-width: 992px) {
      .technologies-tab__area-right {
        width: auto; } }

.o-accordion {
  margin-bottom: 0px; }
  @media (min-width: 1024px) {
    .o-accordion {
      margin-bottom: 80px; } }
  .o-accordion__header {
    line-height: normal !important; }
  .o-accordion__button {
    background-color: transparent !important;
    padding: 0px 26px 0px;
    background-color: transparent !important;
    padding-left: 20px;
    color: #000 !important;
    font-size: 20px;
    font-weight: 600;
    line-height: auto;
    font-family: 'Poppins' !important;
    text-transform: capitalize;
    box-shadow: none;
    margin-top: 10px;
    padding-top: 10px; }
    @media (min-width: 767px) {
      .o-accordion__button {
        padding-left: 26px;
        font-size: 24px;
        font-weight: 700; } }
    .o-accordion__button:focus {
      outline: none;
      box-shadow: none; }
    .o-accordion__button:after {
      position: absolute;
      left: 0;
      color: #000; }
      @media (max-width: 767px) {
        .o-accordion__button:after {
          width: 14px;
          height: 14px;
          background-size: 14px; } }
    .o-accordion__button:not(.collapsed)::after {
      color: #000; }
  .o-accordion__item {
    border: none; }
  .o-accordion__body {
    padding: 0px 5px 0px;
    margin-top: 10px; }
    @media (min-width: 767px) {
      .o-accordion__body {
        padding: 0px 28px 0px; } }
  .o-accordion__tab-icon {
    opacity: 0;
    margin-right: 10px;
    transition: .3s all ease-in-out; }
    @media (max-width: 767px) {
      .o-accordion__tab-icon {
        transform: rotate(90deg);
        width: 20px;
        margin-right: 0px; } }
  .o-accordion__tab {
    text-align: left;
    text-transform: inherit;
    font-size: 16px;
    line-height: 35px;
    font-weight: 500;
    border-bottom: 1px solid transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .3s all ease-in-out; }
    @media (min-width: 767px) {
      .o-accordion__tab {
        font-size: 19px;
        line-height: 45px; } }
    .o-accordion__tab:hover {
      border-bottom: 1px solid #4492dc;
      color: #4492dc; }
      .o-accordion__tab:hover .o-accordion__tab-icon {
        opacity: 1;
        margin-right: 0px; }
  .o-accordion__btn {
    max-width: fit-content;
    padding: 15px 30px;
    margin: 10px auto 0;
    text-transform: capitalize;
    width: 100%; }
    @media (min-width: 767px) {
      .o-accordion__btn {
        padding: 15px 30px;
        margin: 50px 0 0; } }
  .o-accordion__nav {
    width: 100%;
    padding-left: 14px; }
    .o-accordion__nav .active {
      border-bottom: 1px solid #4492dc;
      color: #4492dc; }
      .o-accordion__nav .active .o-accordion__tab-icon {
        opacity: 1;
        margin-right: 0px; }
  .o-accordion__content-background {
    display: none;
    position: absolute;
    background: #F9F9F9;
    width: 200%;
    height: 100%;
    padding: 40px; }
    @media (min-width: 767px) {
      .o-accordion__content-background {
        display: block; } }
  .o-accordion__content {
    background: #F9F9F9;
    padding: 30px 15px;
    z-index: 1;
    position: relative; }
    @media (min-width: 991px) {
      .o-accordion__content {
        padding: 40px 25px; } }
    @media (min-width: 1024px) {
      .o-accordion__content {
        padding: 40px 35px; } }
    @media (min-width: 1200px) {
      .o-accordion__content {
        padding: 40px 0 55px 55px; } }
  .o-accordion__content-title {
    font-size: 22px;
    line-height: 28px;
    font-weight: 700;
    font-family: 'Poppins' !important;
    margin-bottom: 10px;
    padding-top: 15px;
    color: #333; }
    @media (min-width: 767px) {
      .o-accordion__content-title {
        margin-bottom: 15px;
        margin-top: 0px;
        font-size: 26px;
        line-height: 32px; } }
    @media (min-width: 1024px) {
      .o-accordion__content-title {
        font-size: 36px;
        line-height: 42px; } }
  .o-accordion__content-description {
    font-size: 14px;
    line-height: 25px;
    color: #333;
    font-family: 'Poppins' !important;
    margin-bottom: 15px !important; }
    @media (min-width: 767px) {
      .o-accordion__content-description {
        font-size: 16px;
        line-height: 27px; } }
  .o-accordion__pool-section {
    padding: 0px; }
    @media (max-width: 767px) {
      .o-accordion__pool-section {
        padding-top: 0px !important;
        border: none !important; } }
    @media (min-width: 992px) {
      .o-accordion__pool-section {
        padding: 0px !important; } }
  .o-accordion__pool-box {
    justify-content: left !important;
    gap: 0 10px; }
    @media (max-width: 767px) {
      .o-accordion__pool-box {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        gap: 0; } }
  .o-accordion__pool-box-list:before {
    width: 600px !important;
    height: 600px !important;
    top: -300px !important;
    left: -30% !important; }
  @media (max-width: 767px) {
    .o-accordion__pool-box-list {
      height: 65px !important; } }
  @media (max-width: 767px) {
    .o-accordion__pool-box-list a small {
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      text-align: left;
      margin-left: 10px !important; } }
  .o-accordion .accordion-collapse {
    transition: height .35s ease; }
  .o-accordion .collapse.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease; }
  .o-accordion .show.collapsing {
    height: auto;
    overflow: hidden;
    transition: height 0s ease; }

.accordion-button {
  box-shadow: none !important; }

.hidden {
  display: none; }

.hidden-tab {
  display: none; }

.technology-faq__subtitle {
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  margin-top: -15px; }
  @media (min-width: 767px) {
    .technology-faq__subtitle {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 1024px) {
    .technology-faq__subtitle {
      font-size: 25px;
      line-height: 34px; } }

.technology-faq__subtitle-br {
  display: none; }
  @media (min-width: 1024px) {
    .technology-faq__subtitle-br {
      display: block; } }

.augmentation-banner {
  padding: 40px 0;
  background-image: url(/images/new-home-banner/agile-pod-banner.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  position: relative;
  background-size: cover;
  background-color: #070550; }
  @media (min-width: 992px) {
    .augmentation-banner {
      padding: 80px 0; } }
  .augmentation-banner--fixedcost {
    background: #070550 url(/images/new-home-banner/agile-fixed-cost-banner.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
    @media (min-width: 992px) {
      .augmentation-banner--fixedcost {
        padding: 60px 0; } }
  .augmentation-banner--stuff-augmentation {
    background: #070550 url(/images/staff-augmentation/banner-bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
    @media (min-width: 992px) {
      .augmentation-banner--stuff-augmentation {
        padding: 110px 0; } }
    .augmentation-banner--stuff-augmentation .augmentation-banner__heading {
      color: #3CC065; }
    .augmentation-banner--stuff-augmentation .augmentation-banner__btn {
      background: #ff5800; }
      .augmentation-banner--stuff-augmentation .augmentation-banner__btn:hover {
        background: none; }
    @media screen and (min-width: 992px) {
      .augmentation-banner--stuff-augmentation .augmentation-banner__bannertitle {
        font-size: 28px;
        line-height: 40px;
        font-weight: 500; } }
  .augmentation-banner__heading {
    font-family: "Poppins";
    color: #20DEA5;
    font-size: 17px;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    margin: 0; }
    @media (min-width: 767px) {
      .augmentation-banner__heading {
        text-align: left;
        font-size: 21px; } }
  .augmentation-banner__subheading {
    font-family: "Poppins";
    font-size: 32px;
    line-height: 40px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin: 12px 0 18px; }
    @media (min-width: 767px) {
      .augmentation-banner__subheading {
        text-align: left;
        font-size: 50px;
        line-height: 60px; } }
  .augmentation-banner__bannertitle {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 23px;
    font-weight: 600;
    text-align: center;
    margin: 0 0 15px;
    color: #fff; }
    @media (min-width: 767px) {
      .augmentation-banner__bannertitle {
        text-align: left;
        font-size: 21px;
        line-height: 26px; } }
  .augmentation-banner__bannercontent {
    font-family: "Poppins";
    font-size: 17px;
    color: #fff;
    line-height: 26px;
    text-align: center;
    margin: 0; }
    @media (min-width: 767px) {
      .augmentation-banner__bannercontent {
        text-align: left;
        font-size: 18px; } }
  .augmentation-banner__btn {
    border: 2px solid #FF5800;
    margin: 20px auto 0;
    font-family: "Poppins";
    color: #fff;
    max-width: 100%;
    font-size: 17px;
    font-weight: 600;
    text-transform: inherit;
    padding: 17.5px;
    width: 100%; }
    @media (min-width: 767px) {
      .augmentation-banner__btn {
        width: 340px; } }
    .augmentation-banner__btn svg {
      margin-left: 3px; }
    .augmentation-banner__btn:hover {
      background-color: #FF5800;
      color: #fff; }
    @media (min-width: 767px) {
      .augmentation-banner__btn {
        max-width: 340px;
        margin: 35px 0 0; } }

.development-company {
  background-color: #F9F9F9;
  padding: 35px 0; }
  .development-company__content {
    justify-content: center; }
    @media (min-width: 767px) {
      .development-company__content {
        justify-content: flex-start; } }
    .development-company__content svg {
      display: none; }
      @media (min-width: 992px) {
        .development-company__content svg {
          display: block; } }
  .development-company__title {
    font-family: "Poppins";
    font-size: 21px;
    font-weight: 500;
    line-height: 32px;
    color: #333;
    margin: 0 0 15px;
    width: 100%;
    text-align: center; }
    @media (min-width: 992px) {
      .development-company__title {
        width: 90%;
        text-align: left;
        margin: 0; } }
  .development-company__list {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
    @media (min-width: 1024px) {
      .development-company__list {
        justify-content: flex-start;
        flex-wrap: nowrap; } }
    .development-company__list li {
      width: 47%; }
      @media (min-width: 767px) {
        .development-company__list li {
          width: auto; } }

.what-are {
  border-top: 1px solid #DDDADA;
  border-bottom: 1px solid #DDDADA;
  padding: 40px 0; }
  @media (min-width: 992px) {
    .what-are {
      padding: 80px 0; } }
  .what-are__title {
    font-family: "Poppins";
    color: #262E36;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin: 0 0 20px;
    font-weight: 500; }
    @media (min-width: 767px) {
      .what-are__title {
        text-align: left;
        margin: 0;
        font-size: 50px;
        line-height: 60px; } }
  .what-are__content {
    font-family: "Poppins";
    color: #262E36;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    margin: 0; }
    @media (min-width: 767px) {
      .what-are__content {
        text-align: left;
        font-size: 21px;
        line-height: 35px; } }

.why-choose {
  background-color: #F9F9F9;
  padding: 40px 0;
  border-bottom: 1px solid #DDDADA; }
  @media (min-width: 992px) {
    .why-choose {
      padding: 80px 0; } }
  .why-choose--fixed-cost .why-choose__list {
    flex-wrap: wrap; }
  .why-choose--fixed-cost .why-choose__item {
    width: 100%;
    border: 1px solid #D0E3F5;
    background-color: #fff; }
    .why-choose--fixed-cost .why-choose__item:before {
      display: none; }
    @media (min-width: 767px) {
      .why-choose--fixed-cost .why-choose__item {
        max-width: 370px;
        height: 300px; } }
  .why-choose--fixed-cost .why-choose__btn {
    text-transform: inherit;
    width: 100%;
    max-width: 100%;
    margin: 30px auto 0; }
    @media (min-width: 767px) {
      .why-choose--fixed-cost .why-choose__btn {
        max-width: 310px; } }
  .why-choose__heading {
    font-family: "Poppins";
    color: #262E36;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 35px;
    font-weight: 600;
    text-align: center; }
    @media (min-width: 767px) {
      .why-choose__heading {
        font-size: 50px;
        line-height: 60px;
        margin-bottom: 50px; } }
  .why-choose__list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
    flex-wrap: wrap; }
    @media (min-width: 992px) {
      .why-choose__list {
        flex-wrap: nowrap; } }
  .why-choose__item {
    position: relative;
    cursor: default;
    overflow: hidden;
    padding: 2px 1.4px 1px;
    border: 0;
    max-width: 415px;
    width: 100%;
    height: auto; }
    @media (min-width: 992px) {
      .why-choose__item {
        height: 377px; } }
    .why-choose__item:before {
      content: '';
      position: absolute;
      top: -120px;
      right: 0;
      bottom: 0;
      left: -120px;
      background: #898989;
      width: 600px;
      height: 600px;
      -webkit-transition: .3s all ease;
      transition: .3s all ease; }
    .why-choose__item:hover:before {
      animation: rotation2 3s linear infinite;
      background: -webkit-gradient(linear, left top, left bottom, from(#88deb1), color-stop(20%, #1ab864), color-stop(52%, rgba(35, 116, 212, 0.62)), to(rgba(6, 87, 181, 0.62)));
      background: linear-gradient(180deg, #88deb1 0, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%); }
  .why-choose__items {
    position: relative;
    background-color: #F9F9F9;
    width: 100%;
    height: 100%;
    display: block;
    padding: 30px 28px; }
    .why-choose__items--stuff-aug {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap; }
      @media (min-width: 992px) {
        .why-choose__items--stuff-aug {
          flex-wrap: nowrap;
          padding: 45px 30px; } }
  .why-choose__itemscontent {
    padding-top: 20px; }
    @media (min-width: 992px) {
      .why-choose__itemscontent {
        padding-top: 0;
        padding-left: 40px; } }
    @media (min-width: 1200px) {
      .why-choose__itemscontent {
        padding-right: 40px; } }
  .why-choose__title {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 24px;
    margin: 20px 0 15px;
    font-weight: 600;
    color: #262E36; }
    @media (min-width: 992px) {
      .why-choose__title {
        font-size: 24px;
        line-height: 27px;
        margin: 30px 0 20px; } }
    .why-choose__title--stuff-aug {
      margin: 0 0 10px; }
      @media (min-width: 992px) {
        .why-choose__title--stuff-aug {
          margin: 0 0 5px; } }
  .why-choose__content {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    margin: 0;
    color: #262E36; }

.sample-pods {
  padding: 40px 0;
  background-color: #fff; }
  @media (min-width: 992px) {
    .sample-pods {
      padding: 80px 0; } }
  .sample-pods__heading {
    font-family: "Poppins";
    color: #262E36;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 30px;
    font-weight: 500;
    text-align: center; }
    @media (min-width: 992px) {
      .sample-pods__heading {
        font-size: 50px;
        line-height: 60px;
        margin-bottom: 50px; } }
  .sample-pods__list {
    display: flex;
    max-width: 1167px;
    flex-direction: column; }
  .sample-pods__item {
    width: 100%;
    height: auto;
    border: 1px solid #D7D6D6;
    margin-bottom: 28px;
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 767px) {
      .sample-pods__item {
        flex-wrap: nowrap; } }
    @media (min-width: 992px) {
      .sample-pods__item {
        height: 328px;
        margin-bottom: 45px; } }
    .sample-pods__item:last-child {
      margin-bottom: 0; }
  .sample-pods__itemimage {
    max-width: 359px;
    width: 100%;
    height: 100%; }
    .sample-pods__itemimage img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .sample-pods__itemcontent {
    width: auto;
    padding: 25px;
    flex-grow: 1; }
    @media (min-width: 992px) {
      .sample-pods__itemcontent {
        padding: 50px; } }
  .sample-pods__itemtitle {
    font-family: "Poppins";
    font-size: 20px;
    color: #0C2848;
    margin: 0 0 10px;
    font-weight: 600; }
    @media (min-width: 992px) {
      .sample-pods__itemtitle {
        font-size: 30px; } }
  .sample-pods__itemparagraph {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 27px;
    margin: 0 0 20px;
    color: #0C2848; }
  .sample-pods__ulheading {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    font-weight: 600;
    margin: 0 0 10px; }
  .sample-pods__ul {
    display: flex;
    gap: 8px 5px;
    flex-wrap: wrap; }
  .sample-pods__li {
    display: inline-flex; }
    .sample-pods__li span {
      font-family: "Poppins";
      font-size: 16px;
      line-height: 22px;
      color: #333333;
      padding: 8px 19px 6px;
      border-radius: 20px;
      border: 1px solid;
      transition: .3s all ease-in-out;
      cursor: default; }
      .sample-pods__li span.blue {
        border-color: #0A9DEF; }
        .sample-pods__li span.blue:hover {
          background-color: #0A9DEF;
          color: #fff; }
      .sample-pods__li span.green {
        border-color: #20DEA5; }
        .sample-pods__li span.green:hover {
          background-color: #20DEA5;
          color: #fff; }
      .sample-pods__li span.yellow {
        border-color: #FFC327; }
        .sample-pods__li span.yellow:hover {
          background-color: #FFC327;
          color: #fff; }
      .sample-pods__li span.bergendy {
        border-color: #A66BD4; }
        .sample-pods__li span.bergendy:hover {
          background-color: #A66BD4;
          color: #fff; }
      .sample-pods__li span.deepgreen {
        border-color: #4FA941; }
        .sample-pods__li span.deepgreen:hover {
          background-color: #4FA941;
          color: #fff; }
  .sample-pods__btn {
    text-transform: inherit;
    max-width: 100%;
    font-size: 17px;
    padding: 17px;
    width: 100%;
    margin: 45px auto 0; }
    @media (min-width: 767px) {
      .sample-pods__btn {
        max-width: 340px; } }

.pods-billing {
  padding: 40px 0;
  background-image: url(/images/new-home-banner/agile-prods/billing-background.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  position: relative;
  background-size: cover;
  background-color: #052C58; }
  @media (min-width: 992px) {
    .pods-billing {
      padding: 80px 0; } }
  .pods-billing--fixed-cost .pods-billing__item {
    flex-wrap: wrap; }
    @media (min-width: 992px) {
      .pods-billing--fixed-cost .pods-billing__item {
        min-height: 660px; } }
  .pods-billing--staff-augmentation .pods-billing__sub-heading {
    font-family: "Poppins";
    color: #fff;
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 60px; }
  .pods-billing--staff-augmentation .pods-billing__big-card {
    background-color: #fff;
    padding: 25px;
    border-radius: 20px;
    margin-bottom: 70px; }
    @media (min-width: 1024px) {
      .pods-billing--staff-augmentation .pods-billing__big-card {
        padding: 50px; } }
  .pods-billing__heading {
    font-family: "Poppins";
    color: #fff;
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    text-align: center;
    margin: 0 0 25px; }
    @media (min-width: 992px) {
      .pods-billing__heading {
        font-size: 50px;
        line-height: 60px;
        margin: 0 0 40px; } }
  .pods-billing__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start; }
  .pods-billing__item {
    position: relative;
    max-width: 100%;
    width: 100%;
    padding: 25px 15px;
    border-bottom: 8px solid;
    background-color: #fff;
    text-align: center;
    margin: 0 0 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column; }
    @media (min-width: 767px) {
      .pods-billing__item {
        max-width: 494px; } }
    @media (min-width: 992px) {
      .pods-billing__item {
        margin: 0 15px;
        min-height: 732px;
        padding: 40px 30px; } }
    @media (min-width: 1100px) {
      .pods-billing__item {
        margin: 0 30px; } }
    .pods-billing__item--blue {
      border-color: #5250A7; }
      .pods-billing__item--blue .pods-billing__listheading {
        color: #355BBF; }
      .pods-billing__item--blue .pods-billing__itemworks {
        border-color: #0A9DEF; }
        .pods-billing__item--blue .pods-billing__itemworks h5 {
          background-color: #355BBF; }
    .pods-billing__item--green {
      border-color: #0EB885; }
      .pods-billing__item--green .pods-billing__listheading {
        color: #0EB885; }
      .pods-billing__item--green .pods-billing__itemworks {
        border-color: #0EB885; }
        .pods-billing__item--green .pods-billing__itemworks h5 {
          background-color: #0EB885; }
  .pods-billing__listheading {
    font-family: "Poppins";
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
    margin: 0 0 20px; }
    @media (min-width: 992px) {
      .pods-billing__listheading {
        font-size: 36px;
        line-height: 42px;
        margin: 0 0 30px; } }
  .pods-billing__itemworks {
    position: relative;
    width: 100%;
    border-radius: 6px;
    border: 1px solid;
    padding: 20px 15px 15px;
    text-align: center;
    margin-bottom: 25px; }
    @media (min-width: 992px) {
      .pods-billing__itemworks {
        padding: 25px 23px 20px;
        margin-bottom: 35px; } }
    .pods-billing__itemworks h5 {
      font-family: "Poppins";
      font-size: 14px;
      background-color: #ccc;
      color: #fff;
      padding: 3px 10px;
      border-radius: 4px;
      text-transform: uppercase;
      font-weight: 600;
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      top: -10px;
      margin: auto;
      width: 139px; }
    .pods-billing__itemworks p {
      font-family: "Poppins";
      font-size: 16px;
      line-height: 22px;
      color: #0C2848;
      margin: 0 !important; }
  .pods-billing__listbartitle {
    position: relative;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    color: #0C2848;
    margin-bottom: 18px; }
    .pods-billing__listbartitle::after {
      position: absolute;
      content: "";
      width: 49px;
      height: 3px;
      border-radius: 4px;
      background-color: #0C2848;
      left: 0;
      right: 0;
      bottom: -8px;
      margin: auto; }
  .pods-billing p {
    font-family: "Poppins";
    color: #0C2848;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px; }
  .pods-billing__listitle {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    color: #0C2848;
    margin: 0 0 7px !important; }
  .pods-billing__btn {
    text-transform: inherit;
    max-width: 100%;
    width: 100%;
    margin-top: auto;
    font-size: 17px;
    padding: 17px; }
    .pods-billing__btn svg {
      margin-left: 5px; }
  .pods-billing__big-card-list {
    display: flex;
    flex-wrap: wrap; }
  .pods-billing__big-card-item {
    width: 100%;
    padding: 25px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px dashed #D9D9D9; }
    @media (min-width: 1024px) {
      .pods-billing__big-card-item {
        width: 50%;
        border-right: 1px dashed #D9D9D9;
        min-height: 250px;
        padding-right: 70px; } }
    .pods-billing__big-card-item:nth-child(1) {
      justify-content: flex-start; }
    .pods-billing__big-card-item:nth-child(2) {
      justify-content: flex-start; }
    .pods-billing__big-card-item:nth-child(5) {
      justify-content: flex-start; }
      @media (min-width: 1024px) {
        .pods-billing__big-card-item:nth-child(5) {
          padding-top: 70px;
          border-bottom: 0px; } }
    .pods-billing__big-card-item:nth-child(6) {
      justify-content: flex-start;
      padding-top: 0px;
      border-bottom: 0px; }
      @media (min-width: 1024px) {
        .pods-billing__big-card-item:nth-child(6) {
          padding-top: 70px; } }
    .pods-billing__big-card-item:nth-child(2n+2) {
      padding-left: 0px;
      border-right: 0px; }
      @media (min-width: 1024px) {
        .pods-billing__big-card-item:nth-child(2n+2) {
          padding-left: 50px; } }
    .pods-billing__big-card-item li {
      font-family: "Poppins";
      font-size: 16px;
      line-height: 22px;
      color: #262E36;
      margin-bottom: 8px;
      display: flex;
      align-items: flex-start; }
      .pods-billing__big-card-item li svg {
        margin-right: 12px;
        margin-top: 6px;
        flex: 0 0 18px; }
  .pods-billing__big-card-item-top {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .pods-billing__big-card-item-top i {
      margin-right: 12px; }
    .pods-billing__big-card-item-top h3 {
      font-family: "Poppins";
      color: #0C2848;
      font-size: 24px;
      line-height: 30px;
      font-weight: 600; }
  .pods-billing__ready-bottom-text {
    font-family: "Poppins";
    font-size: 35px;
    line-height: 46px;
    color: #fff;
    margin-left: 14px;
    text-align: center;
    font-weight: 600; }
  .pods-billing__ready-bottom-sub-text {
    font-family: "Poppins";
    font-size: 24px;
    line-height: 33px;
    color: #fff;
    text-align: center;
    font-weight: 500;
    margin-bottom: 35px; }
  .pods-billing__ready-bottom-ul {
    max-width: 562px;
    margin: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
    .pods-billing__ready-bottom-ul li {
      font-family: "Poppins";
      font-size: 16px;
      line-height: 22px;
      color: #fff;
      margin: 5px 0;
      width: 100%; }
      @media (min-width: 992px) {
        .pods-billing__ready-bottom-ul li {
          width: 50%; } }

.compare-agile {
  padding: 40px 0;
  background-color: #fff;
  border-bottom: 1px solid #DDDADA; }
  @media (min-width: 992px) {
    .compare-agile {
      padding: 80px 0; } }
  .compare-agile--fixed-cost .compare-agile__heading {
    margin-bottom: 15px; }
  @media (min-width: 992px) {
    .compare-agile--fixed-cost .compare-agile__tableitems li {
      height: 120px; } }
  .compare-agile--fixed-cost .compare-agile__tableitems li:first-child {
    display: flex; }
  @media (min-width: 992px) {
    .compare-agile--fixed-cost .compare-agile__tableitems:last-child li:nth-child(3) {
      height: 150px; } }
  .compare-agile--fixed-cost .compare-agile__btn {
    text-transform: inherit;
    width: 100%;
    padding: 17px;
    margin: 45px auto 0; }
    @media (min-width: 992px) {
      .compare-agile--fixed-cost .compare-agile__btn {
        max-width: 431px; } }
  .compare-agile--stuff-aug .compare-agile__heading {
    margin-bottom: 20px; }
  @media (min-width: 992px) {
    .compare-agile--stuff-aug .compare-agile__tableitems li {
      height: 150px; } }
  .compare-agile--stuff-aug .compare-agile__tableitems li:first-child {
    display: flex; }
  @media (min-width: 992px) {
    .compare-agile--stuff-aug .compare-agile__tableitems:last-child li:nth-child(3) {
      height: 165px; } }
  .compare-agile--stuff-aug .compare-agile__btn {
    text-transform: inherit;
    width: 100%;
    padding: 17px;
    margin: 45px auto 0; }
    @media (min-width: 992px) {
      .compare-agile--stuff-aug .compare-agile__btn {
        max-width: 431px; } }
  .compare-agile__heading {
    font-family: "Poppins";
    color: #262E36;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 35px;
    font-weight: 500;
    text-align: center; }
    @media (min-width: 992px) {
      .compare-agile__heading {
        font-size: 50px;
        line-height: 60px;
        margin-bottom: 50px; } }
  .compare-agile__subheading {
    font-family: "Poppins";
    color: #474747;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 35px;
    font-weight: 600;
    text-align: center; }
    @media (min-width: 992px) {
      .compare-agile__subheading {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 50px; } }
  .compare-agile__tablewrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center; }
  .compare-agile__topheading {
    display: block;
    font-family: "Poppins";
    color: #262E36;
    font-size: 18px;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .compare-agile__topheading {
        display: none; } }
  .compare-agile__tabletitle {
    display: none;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%; }
    @media (min-width: 992px) {
      .compare-agile__tabletitle {
        display: flex; } }
    .compare-agile__tabletitle li {
      font-family: "Poppins";
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      line-height: 30px;
      font-weight: 600;
      color: #262E36;
      background-color: #F7F9FB;
      width: 100%;
      height: 110px;
      border-top: 1px solid #B6BECD;
      border-bottom: 1px solid #B6BECD;
      border-left: 1px solid #B6BECD; }
      @media (min-width: 992px) {
        .compare-agile__tabletitle li {
          width: 25%; } }
      .compare-agile__tabletitle li:first-child {
        justify-content: flex-start;
        padding: 20px 55px; }
      .compare-agile__tabletitle li:last-child {
        border-right: 1px solid #B6BECD; }
        .compare-agile__tabletitle li:last-child::after {
          position: absolute;
          content: "";
          width: 41px;
          height: 6px;
          background-color: #A66BD4;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto; }
      .compare-agile__tabletitle li:nth-child(2) {
        height: 134px;
        border-radius: 9px 9px 0 0;
        border-top: 3px solid #A5B8DC;
        border-left: 3px solid #A5B8DC;
        border-right: 3px solid #A5B8DC; }
        .compare-agile__tabletitle li:nth-child(2)::after {
          position: absolute;
          content: "";
          width: 41px;
          height: 6px;
          background-color: #20DEA5;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto; }
      .compare-agile__tabletitle li:nth-child(3) {
        background-color: #EAEFF5;
        border-left: 0px; }
        .compare-agile__tabletitle li:nth-child(3)::after {
          position: absolute;
          content: "";
          width: 41px;
          height: 6px;
          background-color: #FFC327;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto; }
  .compare-agile__tableitems {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 20px;
    border-radius: 0 0 4px 4px; }
    @media (min-width: 992px) {
      .compare-agile__tableitems {
        margin: 0;
        border-radius: 0; } }
    .compare-agile__tableitems li {
      font-family: "Poppins";
      position: relative;
      color: #333333;
      padding: 20px 25px;
      text-align: left;
      font-size: 15px;
      line-height: 25px;
      font-weight: 500;
      border-bottom: 1px solid #B6BECD;
      border-left: 1px solid #B6BECD;
      border-right: 1px solid #B6BECD;
      width: 100%;
      display: flex;
      align-items: center; }
      .compare-agile__tableitems li.text-center {
        background-color: #EAEFF5; }
      .compare-agile__tableitems li h6 {
        font-family: "Poppins";
        font-size: 18px;
        font-weight: 600; }
      @media (min-width: 992px) {
        .compare-agile__tableitems li {
          width: 25%;
          height: 100px;
          border-right: 0;
          border-bottom: 1px solid #B6BECD;
          border-left: 1px solid #B6BECD; } }
      .compare-agile__tableitems li b {
        font-weight: 600; }
      .compare-agile__tableitems li:last-child {
        border-right: 1px solid #B6BECD; }
      .compare-agile__tableitems li:first-child {
        background-color: #355BBF;
        font-size: 19px;
        font-weight: 600;
        color: #fff;
        border-left: 0px;
        border-bottom: 1px solid #1D3D70; }
        .compare-agile__tableitems li:first-child svg {
          margin-right: 12px; }
      @media (min-width: 992px) {
        .compare-agile__tableitems li:nth-child(3) {
          border-left: 3px solid #A5B8DC;
          border-right: 3px solid #A5B8DC; } }
      @media (min-width: 992px) {
        .compare-agile__tableitems li:nth-child(5) {
          border-left: 0px;
          background-color: #EAEFF5; } }
    .compare-agile__tableitems:last-child li:first-child {
      border-bottom: 0px; }
    @media (min-width: 992px) {
      .compare-agile__tableitems:last-child li:nth-child(3) {
        height: 124px;
        border-radius: 0 0 9px 9px;
        border-bottom: 3px solid #A5B8DC; } }

.our-approach {
  background-color: #F9F9F9; }
  .our-approach .o-development-process__spacing {
    padding: 40px 0; }
    @media (min-width: 992px) {
      .our-approach .o-development-process__spacing {
        padding: 80px 0; } }
  .our-approach__heading {
    font-family: "Poppins";
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    margin: 0;
    text-align: center; }
    @media (min-width: 992px) {
      .our-approach__heading {
        font-size: 50px;
        line-height: 60px;
        margin: 0 0 30px; } }
  .our-approach .o-development-process__items {
    border: 1px solid #D0E3F5;
    box-shadow: 0px 15px 20px 0px rgba(12, 40, 72, 0.07);
    padding: 0 15px 20px;
    margin: 60px 0 0; }
    @media (min-width: 992px) {
      .our-approach .o-development-process__items {
        min-height: 335px;
        margin: 52px 0 0;
        padding: 0 28px 15px; } }
    .our-approach .o-development-process__items.blue {
      border-bottom: 5px solid #64B9F4; }
    .our-approach .o-development-process__items.lgreen {
      border-bottom: 5px solid #20DEA5; }
    .our-approach .o-development-process__items.dyellow {
      border-bottom: 5px solid #F29906; }
    .our-approach .o-development-process__items.lyellow {
      border-bottom: 5px solid #FFC327; }
    .our-approach .o-development-process__items.purple {
      border-bottom: 5px solid #A15FAE; }
    .our-approach .o-development-process__items.dgreen {
      border-bottom: 5px solid #4FA941; }
    .our-approach .o-development-process__items::after {
      display: none; }
  .our-approach .o-development-process__itemtitle {
    font-size: 24px;
    line-height: 30px;
    color: #0C2848;
    margin: 0 0 5px; }
  .our-approach .o-development-process__itemcontent {
    font-size: 16px;
    line-height: 27px;
    color: #262E36; }
  .our-approach__btn {
    max-width: 340px !important;
    padding: 17px !important; }

.get-fortress {
  border-top: 1px solid #D8D8D8;
  background-color: #fff;
  padding: 40px 0; }
  @media (min-width: 992px) {
    .get-fortress {
      padding: 80px 0; } }
  .get-fortress--fixed-cost .get-fortress__btn {
    width: 100%;
    max-width: 100%;
    margin: 35px auto 0; }
    @media (min-width: 767px) {
      .get-fortress--fixed-cost .get-fortress__btn {
        max-width: 366px; } }
  .get-fortress__heading {
    font-family: "Poppins";
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    margin: 0 0 10px; }
    @media (min-width: 992px) {
      .get-fortress__heading {
        font-size: 50px;
        line-height: 60px; } }
  .get-fortress__content {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 30px;
    color: #262E36;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .get-fortress__content {
        font-size: 21px;
        line-height: 35px;
        margin-bottom: 25px; } }
    @media (min-width: 992px) {
      .get-fortress__content--mb50 {
        margin-bottom: 50px; } }
    .get-fortress__content.medium-0 {
      font-weight: 500;
      margin-bottom: 10px; }
  .get-fortress__robust {
    padding: 25px 0;
    background: #052C58 url(/images/new-home-banner/agile-prods/get-fortress.jpg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
    margin-bottom: 40px; }
    @media (min-width: 992px) {
      .get-fortress__robust {
        padding: 45px 0;
        margin-bottom: 80px; } }
  .get-fortress__robustul {
    display: flex;
    flex-direction: column; }
    .get-fortress__robustul li {
      background-color: #fff;
      padding: 25px;
      border-top: 5px solid;
      margin-bottom: 9px; }
      @media (min-width: 992px) {
        .get-fortress__robustul li {
          margin-bottom: 9px;
          padding: 38px 42px; } }
      .get-fortress__robustul li:last-child {
        margin-bottom: 0; }
      .get-fortress__robustul li.blue {
        border-color: #0A9DEF; }
      .get-fortress__robustul li.green {
        border-color: #20DEA5; }
      .get-fortress__robustul li.yellow {
        border-color: #FFC327; }
      .get-fortress__robustul li h4 {
        font-family: "Poppins";
        font-size: 20px;
        line-height: 24px;
        color: #0C2848;
        margin-bottom: 5px;
        font-weight: 600; }
        @media (min-width: 992px) {
          .get-fortress__robustul li h4 {
            font-size: 24px;
            line-height: 27px; } }
      .get-fortress__robustul li p {
        font-family: "Poppins";
        font-size: 16px;
        line-height: 25px;
        margin: 0; }
  .get-fortress__btn {
    text-transform: inherit;
    max-width: 100%;
    padding: 17px;
    margin: auto; }
    @media (min-width: 767px) {
      .get-fortress__btn {
        max-width: 284px; } }

.pods-benefits {
  border-top: 1px solid #D8D8D8;
  background-color: #F9F9F9;
  padding: 40px 0; }
  @media (min-width: 992px) {
    .pods-benefits {
      padding: 80px 0; } }
  .pods-benefits--fixed-cost .pods-benefits__item {
    width: 100%;
    padding: 20px; }
    @media (min-width: 767px) {
      .pods-benefits--fixed-cost .pods-benefits__item {
        width: 240px;
        padding: 30px 25px; } }
    @media (min-width: 992px) {
      .pods-benefits--fixed-cost .pods-benefits__item {
        height: 176px; } }
    .pods-benefits--fixed-cost .pods-benefits__item::before {
      position: absolute;
      content: "";
      width: 41px;
      height: 4px;
      left: 25px;
      top: 0; }
  .pods-benefits__heading {
    font-family: "Poppins";
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    margin: 0 0 30px;
    text-align: center; }
    @media (min-width: 992px) {
      .pods-benefits__heading {
        font-size: 50px;
        line-height: 60px;
        margin: 0 0 50px; } }
  .pods-benefits__list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap; }
    @media (min-width: 992px) {
      .pods-benefits__list {
        gap: 25px;
        flex-wrap: nowrap; } }
  .pods-benefits__item {
    position: relative;
    width: 100%;
    background-color: #fff;
    padding: 30px;
    border: 1px solid #DDE2EA; }
    @media (min-width: 767px) {
      .pods-benefits__item {
        width: 300px;
        height: 275px; } }
    .pods-benefits__item::before {
      position: absolute;
      content: "";
      width: 4px;
      height: 41px;
      left: 0;
      top: 40px; }
    .pods-benefits__item.blue:before {
      background-color: #0A9DEF; }
    .pods-benefits__item.purple:before {
      background-color: #A66BD4; }
    .pods-benefits__item.green:before {
      background-color: #20DEA5; }
    .pods-benefits__item.yellow:before {
      background-color: #FFC327; }
    .pods-benefits__item.lgreen:before {
      background-color: #4FA941; }
  .pods-benefits__itemheading {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 24px;
    color: #0C2848;
    font-weight: 600;
    margin: 0 0 5px; }
    @media (min-width: 992px) {
      .pods-benefits__itemheading {
        font-size: 20px;
        line-height: 27px; } }
  .pods-benefits__itemcontent {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 26px;
    color: #0C2848;
    margin: 0; }

.get-experience {
  background: #052C58 url(/images/new-home-banner/agile-prods/ready-experience-background.svg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  padding: 40px 0; }
  @media (min-width: 992px) {
    .get-experience {
      padding: 80px 0; } }
  @media (min-width: 767px) {
    .get-experience--fixed-cost .get-experience__btn {
      max-width: 408px; } }
  .get-experience--staff-augmentation .get-experience__content {
    font-family: "Poppins";
    color: #fff;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 20px;
    font-weight: 500; }
  .get-experience--staff-augmentation .get-experience__btn {
    margin: 0; }
    @media (min-width: 767px) {
      .get-experience--staff-augmentation .get-experience__btn {
        max-width: 259px; } }
  .get-experience--staff-augmentation .get-experience__btn1 {
    margin: 0;
    text-transform: inherit;
    padding: 17px;
    color: #fff;
    border-color: #fff;
    max-width: 100%;
    width: 100%; }
    @media (min-width: 767px) {
      .get-experience--staff-augmentation .get-experience__btn1 {
        max-width: 259px; } }
    .get-experience--staff-augmentation .get-experience__btn1:hover {
      background-color: #fff;
      color: #ff5800; }
  .get-experience__heading {
    font-family: "Poppins";
    color: #fff;
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    margin: 0 0 10px; }
    @media (min-width: 992px) {
      .get-experience__heading {
        font-size: 40px;
        line-height: 46px; } }
  .get-experience__content {
    font-family: "Poppins";
    color: #fff;
    font-size: 16px;
    line-height: 28px; }
  .get-experience__list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    margin-bottom: 25px; }
  .get-experience__items {
    font-family: "Poppins";
    color: #B0C0E9;
    font-size: 14px;
    font-weight: 600; }
  .get-experience__btn {
    text-transform: inherit;
    font-size: 17px;
    padding: 17px;
    max-width: 100%;
    margin: 40px auto 0;
    width: 100%; }
    @media (min-width: 767px) {
      .get-experience__btn {
        max-width: 299px; } }
  .get-experience__bottomnote {
    font-family: "Poppins";
    color: #fff;
    font-size: 16px;
    line-height: 27px;
    font-weight: 500;
    margin: 20px 0 0; }
  .get-experience__pair {
    max-width: 684px;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin: auto;
    margin-bottom: 25px;
    flex-wrap: wrap; }
    @media (min-width: 992px) {
      .get-experience__pair {
        flex-wrap: nowrap; } }
  .get-experience__infomail {
    font-family: "Poppins";
    text-align: center;
    font-size: 17px;
    color: #B0C0E9; }
    .get-experience__infomail a {
      display: block;
      font-family: "Poppins";
      color: #fff;
      font-size: 17px;
      font-weight: 600; }
      .get-experience__infomail a:hover {
        color: #ff5800; }

.how-it-agile {
  background-color: #F9F9F9;
  padding: 40px 0;
  border-bottom: 1px solid #DDDADA; }
  @media (min-width: 992px) {
    .how-it-agile {
      padding: 80px 0; } }
  .how-it-agile__heading {
    font-family: "Poppins";
    color: #262E36;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 35px;
    font-weight: 500;
    text-align: center; }
    @media (min-width: 767px) {
      .how-it-agile__heading {
        font-size: 50px;
        line-height: 60px;
        margin-bottom: 43px; } }
  .how-it-agile__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    gap: 20px; }
    @media (min-width: 1200px) {
      .how-it-agile__list {
        gap: 0px;
        justify-content: space-between;
        flex-direction: column;
        height: 613px;
        align-items: center; } }
  .how-it-agile__items {
    position: relative;
    max-width: 305px;
    width: 100%;
    height: 284px;
    border-radius: 6px;
    border: 1px solid #D0E3F5;
    background-color: #fff;
    box-shadow: 0px 20px 30px 0 rgba(0, 0, 0, 0.06);
    padding: 40px 20px 30px; }
    @media (min-width: 1200px) {
      .how-it-agile__items:nth-child(2) {
        left: 190px; } }
    @media (min-width: 1200px) {
      .how-it-agile__items:nth-child(4) {
        left: 190px; } }
    .how-it-agile__items.blue:after {
      position: absolute;
      content: "";
      width: 52px;
      height: 253px;
      background-image: url(/images/new-home-banner/agile-fixed-cost/blue-arrow.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% 100%;
      left: 0;
      right: 0;
      bottom: -253px;
      margin: auto;
      display: none; }
      @media (min-width: 1200px) {
        .how-it-agile__items.blue:after {
          display: block; } }
      @media (min-width: 1230px) {
        .how-it-agile__items.blue:after {
          left: 25px; } }
    .how-it-agile__items.green:after {
      position: absolute;
      content: "";
      width: 52px;
      height: 253px;
      background-image: url(/images/new-home-banner/agile-fixed-cost/arrow-green.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% 100%;
      left: 0;
      right: 0;
      top: -253px;
      margin: auto;
      display: none; }
      @media (min-width: 1200px) {
        .how-it-agile__items.green:after {
          display: block; } }
      @media (min-width: 1230px) {
        .how-it-agile__items.green:after {
          left: 25px; } }
    .how-it-agile__items.purple:after {
      position: absolute;
      content: "";
      width: 52px;
      height: 173px;
      background-image: url(/images/new-home-banner/agile-fixed-cost/arrow-purple.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% 100%;
      left: 0;
      right: 0;
      bottom: -173px;
      margin: auto;
      display: none; }
      @media (min-width: 1200px) {
        .how-it-agile__items.purple:after {
          display: block; } }
      @media (min-width: 1230px) {
        .how-it-agile__items.purple:after {
          left: 25px; } }
    .how-it-agile__items.yellow:after {
      position: absolute;
      content: "";
      width: 52px;
      height: 173px;
      background-image: url(/images/new-home-banner/agile-fixed-cost/arrow-yellow.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% 100%;
      left: 0;
      right: 0;
      top: -173px;
      margin: auto;
      display: none; }
      @media (min-width: 1200px) {
        .how-it-agile__items.yellow:after {
          display: block; } }
      @media (min-width: 1230px) {
        .how-it-agile__items.yellow:after {
          left: 25px; } }
  .how-it-agile__titlearea {
    margin-bottom: 12px;
    text-align: center; }
    @media (min-width: 992px) {
      .how-it-agile__titlearea {
        text-align: left; } }
  .how-it-agile__itemtitle {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 27px;
    font-weight: 600;
    margin: 20px 0 0;
    color: #0C2848; }
  .how-it-agile__itemcontent {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    color: #262E36;
    font-weight: 400;
    margin: 0; }
  .how-it-agile__number {
    position: absolute;
    font-family: "Poppins";
    font-size: 54px;
    color: #f2f2f2;
    right: 10px;
    top: 10px; }
  .how-it-agile__btn {
    text-transform: inherit;
    padding: 17px;
    max-width: 100%;
    width: 100%;
    margin: 30px auto 0; }
    @media (min-width: 767px) {
      .how-it-agile__btn {
        margin: 50px auto 0;
        max-width: 341px; } }

.how-stuff-augmentation {
  background-color: #fff; }
  .how-stuff-augmentation .o-development-process__spacing {
    padding: 40px 0; }
    @media (min-width: 992px) {
      .how-stuff-augmentation .o-development-process__spacing {
        padding: 80px 0; } }
  .how-stuff-augmentation .o-development-process__items {
    width: 100%;
    border: 1px solid #D0E3F5;
    box-shadow: 0px 15px 20px 0 rgba(12, 40, 72, 0.07);
    background-color: #fff;
    margin: 50px 0 0; }
    @media (min-width: 767px) {
      .how-stuff-augmentation .o-development-process__items {
        width: 260px;
        height: 280px; } }
    .how-stuff-augmentation .o-development-process__items.blue:after {
      background-image: url(/images/new-home-banner/staff-augmentation/blue-arrow.svg);
      width: 26px;
      height: 18px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      right: -28px;
      display: none; }
      @media (min-width: 992px) {
        .how-stuff-augmentation .o-development-process__items.blue:after {
          display: block; } }
    .how-stuff-augmentation .o-development-process__items.green:after {
      background-image: url(/images/new-home-banner/staff-augmentation/green-arrow.svg);
      width: 26px;
      height: 18px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      right: -28px;
      display: none; }
      @media (min-width: 992px) {
        .how-stuff-augmentation .o-development-process__items.green:after {
          display: block; } }
    .how-stuff-augmentation .o-development-process__items.yellow:after {
      background-image: url(/images/new-home-banner/staff-augmentation/yellow-arrow.svg) !important;
      width: 26px !important;
      height: 18px !important;
      background-repeat: no-repeat !important;
      background-size: 100% 100% !important;
      right: -28px;
      bottom: inherit;
      top: 50%;
      display: none; }
      @media (min-width: 992px) {
        .how-stuff-augmentation .o-development-process__items.yellow:after {
          display: block; } }
  .how-stuff-augmentation .o-development-process__itemtitle {
    font-family: "Poppins";
    font-size: 24px;
    line-height: 30px;
    color: #0C2848;
    font-weight: 600;
    margin: 0 0 10px; }
  .how-stuff-augmentation .o-development-process__itemcontent {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    color: #262E36; }
  .how-stuff-augmentation .o-development-process__bottom-text {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 50px;
    margin: 50px 0 15px;
    font-weight: 500; }
  .how-stuff-augmentation .o-development-process__list {
    justify-content: center;
    gap: 20px; }
    @media (min-width: 1024px) {
      .how-stuff-augmentation .o-development-process__list {
        gap: 0px;
        justify-content: space-between; } }
  .how-stuff-augmentation__btn {
    text-transform: inherit;
    max-width: 100%;
    width: 100%; }
    @media (min-width: 767px) {
      .how-stuff-augmentation__btn {
        max-width: 263px; } }

.stuff-augmentation-technologies {
  padding: 40px 0;
  padding-bottom: 0; }
  @media (min-width: 992px) {
    .stuff-augmentation-technologies {
      padding: 80px 0; } }
  .stuff-augmentation-technologies .technologies-tab__heading {
    font-weight: 500; }
  .stuff-augmentation-technologies .o-accordion__button {
    font-weight: 600; }
  .stuff-augmentation-technologies .o-accordion__content-title {
    font-weight: 600; }

.custom-dev-tech .technologies-tab__heading {
  font-weight: 500; }

.custom-dev-tech .custom-solution__sub-heading {
  font-weight: 400 !important; }

/* ANCHOR - new home banner */
.new-home-banner {
  position: relative;
  background-color: #0d2642;
  padding: 25px 0 40px; }
  @media screen and (min-width: 992px) {
    .new-home-banner {
      background-image: url(/images/new-home-banner/banner-gradient.png);
      background-repeat: no-repeat;
      background-position: 0 0;
      padding: 60px 0 160px; } }
  @media screen and (min-width: 992px) {
    .new-home-banner:after {
      content: "";
      width: 453px;
      height: 199px;
      background-image: url(/images/new-home-banner/banner-gradient2.png);
      background-repeat: no-repeat;
      background-position: 0 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0; } }
  .new-home-banner__wrapper {
    position: relative; }
    @media screen and (min-width: 992px) {
      .new-home-banner__wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap; } }
    @media screen and (min-width: 992px) {
      .new-home-banner__wrapper--out {
        align-items: center; } }
    .new-home-banner__wrapper--leap-mwc:before {
      content: '';
      position: absolute;
      background-image: url(/images/new-home-banner/leap-mwc-gradiant.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100%;
      left: 140px;
      bottom: -235px;
      width: 854px;
      height: 685px;
      display: none; }
      @media screen and (min-width: 992px) {
        .new-home-banner__wrapper--leap-mwc:before {
          display: block; } }
  .new-home-banner__left {
    flex: 0 0 69%;
    position: relative; }
    @media screen and (min-width: 992px) {
      .new-home-banner__left {
        flex: 0 0 65%;
        padding-right: 80px; } }
    @media screen and (min-width: 1025px) {
      .new-home-banner__left {
        flex: 0 0 69%; } }
    .new-home-banner__left::before {
      display: none; }
      @media screen and (min-width: 992px) {
        .new-home-banner__left::before {
          content: "";
          position: absolute;
          top: 5px;
          right: 5px;
          width: 2px;
          height: 565px;
          background: url(/images/new-home-banner/banner-devider2.png) no-repeat 0 0;
          display: block; } }
    @media screen and (min-width: 992px) {
      .new-home-banner__left--leap-mwc {
        padding-top: 20px; } }
    .new-home-banner__left--leap-mwc:before {
      display: none; }
    @media screen and (min-width: 992px) {
      .new-home-banner__left--leap-mwc {
        flex: 0 0 58%;
        padding-right: 25px; } }
    @media screen and (min-width: 1025px) {
      .new-home-banner__left--leap-mwc {
        flex: 0 0 60%; } }
    .new-home-banner__left--leap-mwc .new-home-banner__sub-heading-white {
      margin-top: 15px; }
      @media screen and (min-width: 992px) {
        .new-home-banner__left--leap-mwc .new-home-banner__sub-heading-white {
          font-size: 58px;
          line-height: 68px;
          margin-top: 0;
          margin-bottom: 25px; } }
      .new-home-banner__left--leap-mwc .new-home-banner__sub-heading-white .new-home-banner__sub-heading-green {
        color: #3CC065; }
    .new-home-banner__left--leap-mwc .new-home-banner__blue-para {
      color: #97B3CE;
      font-weight: 500;
      font-family: Poppins;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .new-home-banner__left--leap-mwc .new-home-banner__blue-para {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 35px;
          text-align: left; } }
    .new-home-banner__left--leap-mwc .new-home-banner__btn {
      margin-left: 0; }
    @media screen and (min-width: 992px) {
      .new-home-banner__left--out {
        flex: 1; } }
    .new-home-banner__left--out:before {
      display: none; }
  .new-home-banner__right {
    flex: 1; }
    @media screen and (min-width: 992px) {
      .new-home-banner__right {
        padding-left: 65px; } }
    .new-home-banner__right--out {
      padding: 20px 15px;
      border: 1px solid #355BBF;
      background: #04254A;
      text-align: center;
      margin-top: 20px; }
      @media screen and (min-width: 992px) {
        .new-home-banner__right--out {
          padding: 50px;
          flex: 0 0 482px;
          text-align: left;
          margin-top: 0; } }
    .new-home-banner__right--leap-mwc {
      padding: 25px 15px;
      margin-top: 20px;
      position: relative; }
      @media screen and (min-width: 992px) {
        .new-home-banner__right--leap-mwc {
          padding: 15px 15px;
          position: relative;
          margin-top: 0; } }
      .new-home-banner__right--leap-mwc .new-home-banner__right-deatils {
        top: 0; }
      .new-home-banner__right--leap-mwc .new-home-banner__right-deatils {
        font-weight: 700; }
    .new-home-banner__right--leap-mwc-top {
      background: rgba(0, 0, 0, 0.7);
      padding: 25px;
      margin-bottom: 20px; }
      .new-home-banner__right--leap-mwc-top p {
        margin-bottom: 0; }
      .new-home-banner__right--leap-mwc-top .date {
        font-family: Poppins;
        background: #DE002B;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: #fff;
        display: block;
        width: 100%;
        text-align: center;
        border-top: 1px solid #fff; }
    .new-home-banner__right--leap-mwc-top-box {
      display: flex;
      flex-direction: column;
      border: 2px solid #fff; }
    .new-home-banner__right--leap-mwc-bottom-box {
      display: flex;
      flex-direction: column;
      border: 2px solid #fff; }
    .new-home-banner__right--leap-mwc-bottom {
      background: rgba(0, 0, 0, 0.7);
      padding: 25px; }
      .new-home-banner__right--leap-mwc-bottom p {
        margin-top: 10px;
        margin-bottom: 0;
        font-family: Poppins;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        color: #fff; }
      .new-home-banner__right--leap-mwc-bottom .date {
        font-family: Poppins;
        background: #007D9F;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: #fff;
        display: block;
        width: 100%;
        text-align: center;
        border-top: 1px solid #fff; }
    .new-home-banner__right--leap-mwc-top {
      background: rgba(0, 0, 0, 0.7);
      border: 1px solid rgba(101, 115, 132, 0.62);
      padding: 25px 25px 20px; }
      .new-home-banner__right--leap-mwc-top p {
        margin-top: 10px;
        margin-bottom: 0;
        font-family: Poppins;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        color: #fff;
        text-align: center; }
        @media screen and (min-width: 992px) {
          .new-home-banner__right--leap-mwc-top p {
            text-align: left;
            font-size: 18px; } }
    .new-home-banner__right--leap-mwc-top-box-leap {
      width: 100%;
      display: flex;
      align-items: center; }
    .new-home-banner__right--leap-mwc-top-box-leap-left {
      flex: 1;
      position: relative;
      background-color: #fff; }
      .new-home-banner__right--leap-mwc-top-box-leap-left:before {
        position: absolute;
        content: "";
        width: 80%;
        height: 80%;
        background-color: #000;
        left: 0;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        margin: auto; }
      .new-home-banner__right--leap-mwc-top-box-leap-left img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .new-home-banner__right--leap-mwc-top-box-leap-right {
      flex: 1;
      background: #0C0C68;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center; }
      .new-home-banner__right--leap-mwc-top-box-leap-right span {
        font-family: Poppins;
        font-weight: 800;
        color: #fff; }
        @media screen and (min-width: 992px) {
          .new-home-banner__right--leap-mwc-top-box-leap-right span {
            font-size: 34px; } }
    .new-home-banner__right--leap-mwc-bottom {
      background: rgba(0, 0, 0, 0.7);
      padding: 25px 25px 20px;
      border: 1px solid rgba(101, 115, 132, 0.62); }
      .new-home-banner__right--leap-mwc-bottom p {
        margin-top: 10px;
        margin-bottom: 0;
        font-family: Poppins;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        color: #fff;
        text-align: center; }
        @media screen and (min-width: 992px) {
          .new-home-banner__right--leap-mwc-bottom p {
            text-align: left;
            font-size: 18px; } }
    .new-home-banner__right--leap-mwc-bottom-box-leap {
      width: 100%;
      display: flex;
      align-items: center; }
    .new-home-banner__right--leap-mwc-bottom-box-leap-left {
      position: relative;
      flex: 1;
      background-color: #fff; }
      .new-home-banner__right--leap-mwc-bottom-box-leap-left:before {
        position: absolute;
        content: "";
        width: 80%;
        height: 80%;
        background-color: #000;
        left: 0;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        margin: auto; }
      .new-home-banner__right--leap-mwc-bottom-box-leap-left img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .new-home-banner__right--leap-mwc-bottom-box-leap-right {
      flex: 1;
      background: #22569D;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center; }
      .new-home-banner__right--leap-mwc-bottom-box-leap-right span {
        font-family: Poppins;
        font-weight: 800;
        color: #fff;
        text-align: center; }
        @media screen and (min-width: 992px) {
          .new-home-banner__right--leap-mwc-bottom-box-leap-right span {
            font-size: 24px; } }
        .new-home-banner__right--leap-mwc-bottom-box-leap-right span small {
          font-size: 19px;
          display: block; }
    .new-home-banner__right--convergence-gitex {
      position: relative; }
      .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-top-box-leap-left {
        padding: 12px 17px; }
        .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-top-box-leap-left::before {
          display: none; }
        .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-top-box-leap-left img {
          width: inherit;
          height: inherit;
          object-fit: initial; }
      .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-right {
        background: #0E1488; }
        .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-right.bg-darkgreen {
          background: #00666C; }
      .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-top .date {
        background: #F70026;
        padding: 6px 0;
        font-weight: 700; }
        @media screen and (min-width: 992px) {
          .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-top .date {
            font-size: 21px; } }
      .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-right span {
        font-size: 20px;
        font-weight: 600; }
        @media screen and (min-width: 992px) {
          .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-right span {
            font-size: 28px;
            line-height: 30px;
            font-weight: 800; } }
        .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-right span small {
          display: block;
          font-size: 16px;
          font-weight: 600; }
          @media screen and (min-width: 992px) {
            .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-right span small {
              font-size: 20px;
              font-weight: 800; } }
      .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-left {
        padding: 10px 0; }
        .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-left::before {
          display: none; }
        .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom-box-leap-left img {
          width: inherit;
          height: inherit;
          object-fit: initial; }
      .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom .date {
        padding: 6px 0;
        font-weight: 700; }
        @media screen and (min-width: 992px) {
          .new-home-banner__right--convergence-gitex .new-home-banner__right--leap-mwc-bottom .date {
            font-size: 21px; } }
  .new-home-banner__heading {
    color: #fff;
    font-weight: 700;
    font-family: "Poppins" !important;
    text-align: center;
    font-size: 30px;
    line-height: 38px;
    position: relative;
    padding-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .new-home-banner__heading {
        font-size: 56px;
        line-height: 68px;
        text-align: left;
        padding-bottom: 30px; } }
    @media screen and (min-width: 992px) {
      .new-home-banner__heading:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background: #91accd;
        height: 2px;
        width: 265px; } }
    .new-home-banner__heading--leap-mwc {
      padding-bottom: 0;
      font-size: 26px;
      line-height: normal;
      margin-bottom: 0; }
      @media screen and (min-width: 992px) {
        .new-home-banner__heading--leap-mwc {
          font-size: 34px;
          line-height: 50px;
          font-weight: 600;
          margin-bottom: 10px; } }
      .new-home-banner__heading--leap-mwc:before {
        content: '';
        position: absolute;
        background: #84A5F9;
        width: 168px;
        height: 5px;
        display: none; }
        @media screen and (min-width: 992px) {
          .new-home-banner__heading--leap-mwc:before {
            display: block; } }
    .new-home-banner__heading--leap-mwc:before {
      display: none; }
    .new-home-banner__heading--out {
      position: relative; }
      @media screen and (min-width: 992px) {
        .new-home-banner__heading--out {
          font-size: 31px;
          line-height: 40px;
          font-weight: 700; } }
      .new-home-banner__heading--out:before {
        content: '';
        position: absolute;
        background: #84A5F9;
        width: 168px;
        height: 5px;
        display: none; }
        @media screen and (min-width: 992px) {
          .new-home-banner__heading--out:before {
            display: block; } }
    .new-home-banner__heading--green-out {
      color: #2DBE70; }
  .new-home-banner__arrow {
    position: absolute;
    top: -86px;
    right: -76px; }
  .new-home-banner__sub-heading {
    color: #3cc065;
    font-family: "Poppins" !important; }
    @media screen and (min-width: 992px) {
      .new-home-banner__sub-heading {
        font-size: 31px;
        line-height: 40px;
        font-weight: 600; } }
  .new-home-banner__sub-heading-white {
    color: #fff;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .new-home-banner__sub-heading-white {
        font-size: 60px;
        line-height: 68px;
        font-weight: 700;
        margin-top: 35px;
        text-align: left; } }
  .new-home-banner__right-heading {
    font-family: "Poppins" !important;
    font-size: 17px;
    line-height: 24px;
    font-weight: 600;
    color: #3cc065;
    margin-top: 25px;
    margin-bottom: 20px;
    text-align: center;
    display: block; }
    @media screen and (min-width: 992px) {
      .new-home-banner__right-heading {
        text-align: left;
        font-size: 26px;
        line-height: 42px;
        color: #fff;
        margin-top: 0; } }
    .new-home-banner__right-heading .banner-line-break li {
      display: inline-block;
      margin-right: 4px; }
      .new-home-banner__right-heading .banner-line-break li:last-child {
        margin-right: 0px; }
      @media screen and (min-width: 992px) {
        .new-home-banner__right-heading .banner-line-break li {
          display: block;
          margin-right: 0px; } }
    .new-home-banner__right-heading a {
      color: #3cc065;
      display: inline-block;
      margin-right: 4px; }
      @media screen and (min-width: 992px) {
        .new-home-banner__right-heading a {
          margin-right: 6px;
          color: #fff; } }
      @media screen and (min-width: 992px) {
        .new-home-banner__right-heading a span {
          background: linear-gradient(#3cc065) left no-repeat, #fff;
          background-size: 0% 100%;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          transition: 0.2s all ease-in-out;
          display: inline-block; } }
      @media screen and (min-width: 992px) {
        .new-home-banner__right-heading a:hover span {
          background-size: 100% 100%; } }
  .new-home-banner__right-bottom-bar {
    position: relative;
    display: inline-block; }
    .new-home-banner__right-bottom-bar:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      border-radius: 10px;
      animation: 4s linear animate infinite; }
      @media screen and (min-width: 992px) {
        .new-home-banner__right-bottom-bar:before {
          height: 4px;
          border-radius: 30px; } }
    .new-home-banner__right-bottom-bar--first:before {
      background: -moz-linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
      background: -webkit-linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
      background: linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
      background-size: 200%; }
    .new-home-banner__right-bottom-bar--second:before {
      background: -moz-linear-gradient(90deg, #ffe70e, #78e6ff, #15fe8a, #ffe70e);
      background: -webkit-linear-gradient(90deg, #ffe70e, #78e6ff, #15fe8a, #ffe70e);
      background: linear-gradient(90deg, #ffe70e, #78e6ff, #15fe8a, #ffe70e);
      background-size: 200%; }
  .new-home-banner__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list {
        flex-direction: column;
        flex-wrap: inherit;
        align-items: flex-start;
        justify-content: inherit; } }
  .new-home-banner__list-item {
    border-width: 1px;
    border-style: solid;
    border-radius: 26px;
    padding: 1px;
    transition: 0.3s all ease-in-out;
    transition: width 0.3s ease;
    position: relative;
    overflow: hidden;
    margin: 0 2.5px 5px; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item {
        border-radius: 30px;
        transform: translateZ(0);
        border: none;
        padding: 1.5px;
        margin: 0 0 5px; } }
    .new-home-banner__list-item path {
      transition: 0.3s all ease-in-out; }
    .new-home-banner__list-item:hover svg {
      transform: translate(30px, 0); }
    .new-home-banner__list-item:before {
      content: "";
      position: absolute;
      top: -80px;
      right: 0;
      bottom: 0;
      left: -13px;
      width: 250px;
      height: 250px;
      transition: 0.3s all ease; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color1 {
        width: 155px; } }
    .new-home-banner__list-item--border-color1::before {
      background: #64b9f4; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color1:hover {
        width: 181px; } }
    .new-home-banner__list-item--border-color1:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color1:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color2 {
        width: 155px; } }
    .new-home-banner__list-item--border-color2:before {
      background: #e26826; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color2:hover {
        width: 185px; } }
    .new-home-banner__list-item--border-color2:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color2:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color3 {
        width: 195px; } }
    .new-home-banner__list-item--border-color3:before {
      background: #fbba14; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color3:hover {
        width: 225px; } }
    .new-home-banner__list-item--border-color3:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color3:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color4 {
        width: 155px; } }
    .new-home-banner__list-item--border-color4:before {
      background: #648ff4;
      width: 310px;
      height: 310px;
      left: -20px;
      top: -120px; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color4:hover {
        width: 180px; } }
    .new-home-banner__list-item--border-color4:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color4:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color5 {
        width: 260px; } }
    .new-home-banner__list-item--border-color5:before {
      background: #804b8b;
      width: 310px;
      height: 310px;
      left: -20px;
      top: -120px; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color5:hover {
        width: 275px; } }
    .new-home-banner__list-item--border-color5:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color5:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color6 {
        width: 165px; } }
    .new-home-banner__list-item--border-color6:before {
      background: #3b95d3; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color6:hover {
        width: 195px; } }
    .new-home-banner__list-item--border-color6:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color6:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color7 {
        width: 155px; } }
    .new-home-banner__list-item--border-color7:before {
      background: #e98e5c; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color7:hover {
        width: 185px; } }
    .new-home-banner__list-item--border-color7:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color7:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color8 {
        width: 115px; } }
    .new-home-banner__list-item--border-color8:before {
      background: #3cc065; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color8:hover {
        width: 145px; } }
    .new-home-banner__list-item--border-color8:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color8:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color9 {
        width: 270px; } }
    .new-home-banner__list-item--border-color9:before {
      background: #E98E5C;
      width: 310px;
      height: 310px;
      left: -20px;
      top: -120px; }
    @media screen and (min-width: 992px) {
      .new-home-banner__list-item--border-color9:hover {
        width: 285px; } }
    .new-home-banner__list-item--border-color9:hover path {
      fill: #fff; }
    .new-home-banner__list-item--border-color9:hover:before {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    .new-home-banner__list-item a {
      font-family: "Poppins" !important;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 0 5px;
      background: #0d2642;
      border-radius: 30px;
      padding: 5px 15px;
      position: relative;
      z-index: 9; }
      @media screen and (min-width: 992px) {
        .new-home-banner__list-item a {
          font-size: 26px;
          padding: 5px 25px; } }
      .new-home-banner__list-item a svg {
        transition: 0.3s all ease-in-out; }
  .new-home-banner__left-arrow {
    position: absolute;
    left: 0;
    top: -15px; }
  .new-home-banner__right-arrow {
    position: absolute;
    right: 0;
    top: -15px; }
  .new-home-banner__right-deatils {
    font-family: "Poppins" !important;
    color: #fff;
    font-weight: 400; }
    @media screen and (min-width: 992px) {
      .new-home-banner__right-deatils {
        position: relative;
        top: -25px;
        font-size: 31px;
        line-height: 40px; } }
  .new-home-banner__right-deatils-green {
    color: #3CC065;
    font-weight: 700; }
  @media screen and (min-width: 992px) {
    .new-home-banner__rating {
      margin: 20px auto 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      padding: 0 50px 0 0;
      position: relative; } }
  .new-home-banner__rated-text {
    flex: 0 0 49%;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .new-home-banner__rated-text {
        text-align: left; } }
  .new-home-banner__rated-heading {
    font-family: "Poppins" !important;
    color: #fff;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700; }
  .new-home-banner__rated-sub-heading {
    font-family: "Poppins" !important;
    color: #3cc065;
    font-size: 21px;
    line-height: 27px;
    font-weight: 500; }
  .new-home-banner__rated-icon {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between; }
    @media screen and (min-width: 992px) {
      .new-home-banner__rated-icon {
        margin-left: 40px; } }
  .new-home-banner__icon-item {
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    padding: 5px 0; }
  .new-home-banner__btn-wrapper {
    margin-top: 25px; }
    @media screen and (min-width: 992px) {
      .new-home-banner__btn-wrapper {
        margin-bottom: 40px;
        position: absolute;
        bottom: -135px;
        left: 0;
        margin-top: 0; } }
    @media screen and (min-width: 1025px) {
      .new-home-banner__btn-wrapper {
        bottom: -85px; } }
  .new-home-banner__btn {
    border-color: #ff5800;
    color: #fff;
    border-width: 2px;
    background: #ff5800 !important; }
    @media screen and (min-width: 992px) {
      .new-home-banner__btn {
        width: 380px;
        background: none !important; }
        .new-home-banner__btn:hover {
          background: none;
          color: #fff;
          transition: 0.3s all ease-in-out; } }
    .new-home-banner__btn path {
      fill: #fff; }
      @media screen and (min-width: 992px) {
        .new-home-banner__btn path {
          fill: #ff5800; } }
  .new-home-banner__btn-arrow {
    margin-left: 10px; }
  .new-home-banner__leftlist {
    width: 100%;
    max-width: 510px;
    margin: auto; }
    @media screen and (min-width: 1024px) {
      .new-home-banner__leftlist {
        max-width: inherit;
        margin: 0 0 20px; } }
    .new-home-banner__leftlist li {
      font-size: 18px;
      line-height: normal;
      font-weight: 500;
      color: #8FC4F5;
      background: url(/images/new-home-banner/new-banner-sky-tick.svg) no-repeat 0 0;
      background-size: 20px 20px;
      padding-left: 28px;
      margin: 12px 0; }
      @media screen and (min-width: 992px) {
        .new-home-banner__leftlist li {
          font-size: 21px;
          line-height: 36px;
          background: url(/images/new-home-banner/new-banner-sky-tick.svg) no-repeat center left;
          background-size: 23px 23px;
          margin: 0;
          padding-left: 32px; } }
  .new-home-banner .new-banner-img {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    transform: scale(1);
    transition: all 0.8s ease-in-out; }
    @media screen and (min-width: 1266px) {
      .new-home-banner .new-banner-img {
        height: auto; } }
    @media screen and (min-width: 1367px) {
      .new-home-banner .new-banner-img {
        height: 90%; } }
    .new-home-banner .new-banner-img.desktop-banner-img {
      display: none; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-banner-img.desktop-banner-img {
          display: block; } }
    .new-home-banner .new-banner-img.mobile-banner-img {
      display: block; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-banner-img.mobile-banner-img {
          display: none; } }
  .new-home-banner .new-home-banner-content {
    padding: 60px 0;
    position: relative; }
    @media screen and (min-width: 992px) {
      .new-home-banner .new-home-banner-content {
        padding: 125px 0 195px; } }
    @media screen and (min-width: 1089px) {
      .new-home-banner .new-home-banner-content {
        padding: 100px 0 220px; } }
    @media screen and (min-width: 1367px) {
      .new-home-banner .new-home-banner-content {
        padding: 100px 0 220px; } }
    @media screen and (min-width: 1600px) {
      .new-home-banner .new-home-banner-content {
        padding: 100px 0 215px; } }
    @media screen and (min-width: 1700px) {
      .new-home-banner .new-home-banner-content {
        padding: 100px 0 215px; } }
    @media screen and (min-width: 1813px) {
      .new-home-banner .new-home-banner-content {
        padding: 115px 0 232px; } }
    @media screen and (min-width: 2040px) {
      .new-home-banner .new-home-banner-content {
        padding: 170px 0 275px; } }
    @media screen and (min-width: 2720px) {
      .new-home-banner .new-home-banner-content {
        padding: 190px 0 400px; } }
    @media screen and (min-width: 4080px) {
      .new-home-banner .new-home-banner-content {
        padding: 86px 0 550px; } }
    .new-home-banner .new-home-banner-content .new-home-banner-text-order-1 {
      order: 2; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-home-banner-content .new-home-banner-text-order-1 {
          order: 1; } }
    .new-home-banner .new-home-banner-content .new-home-banner-text-order-2 {
      order: 1; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-home-banner-content .new-home-banner-text-order-2 {
          order: 2; } }
    .new-home-banner .new-home-banner-content .banner-main-heading-text {
      color: #fff;
      font-weight: 700;
      margin-bottom: 15px;
      font-size: 26px;
      line-height: 44px;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-home-banner-content .banner-main-heading-text {
          font-weight: 700;
          margin-bottom: 20px;
          font-size: 49px;
          line-height: 61px;
          text-align: left; } }
      @media screen and (min-width: 1280px) {
        .new-home-banner .new-home-banner-content .banner-main-heading-text {
          font-size: 51px;
          line-height: 62px; } }
    .new-home-banner .new-home-banner-content .on-demand-p {
      color: #fff;
      font-size: 22px;
      margin: 25px 0 20px; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-home-banner-content .on-demand-p {
          font-size: 24px;
          margin: 25px 0 35px; } }
    .new-home-banner .new-home-banner-content .new-banner-text-area .new-banner-text {
      margin-bottom: 0;
      padding-left: 0;
      position: relative;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-home-banner-content .new-banner-text-area .new-banner-text {
          padding-left: 40px;
          text-align: left; }
          .new-home-banner .new-home-banner-content .new-banner-text-area .new-banner-text::before {
            content: "";
            width: 2px;
            left: 0;
            top: 0;
            bottom: 0;
            position: absolute;
            background-color: #d8d8d8; } }
      .new-home-banner .new-home-banner-content .new-banner-text-area .new-banner-text .new-banner-text-li {
        margin-bottom: 10px;
        font-size: 22px;
        font-weight: 600;
        line-height: 22px;
        color: #46df75; }
        @media screen and (min-width: 992px) {
          .new-home-banner .new-home-banner-content .new-banner-text-area .new-banner-text .new-banner-text-li {
            font-size: 30px;
            font-weight: 600;
            line-height: 30px;
            margin-bottom: 20px; } }
    .new-home-banner .new-home-banner-content .btn-orange.banner-btn-new {
      margin: 0 auto; }
    .new-home-banner .new-home-banner-content .bottom-form-link {
      text-align: center; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-home-banner-content .bottom-form-link {
          text-align: left; } }
  .new-home-banner .new-banner-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .new-home-banner .new-banner-list {
        justify-content: space-between;
        flex-wrap: nowrap; } }
    .new-home-banner .new-banner-list li {
      color: #3cc065;
      position: relative;
      width: 100%;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      font-size: 20px;
      padding: 5px 0; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-banner-list li {
          font-size: 18px;
          line-height: 24px;
          text-transform: capitalize;
          margin: 0 15px;
          width: auto; } }
      .new-home-banner .new-banner-list li:before {
        content: "";
        position: absolute;
        background: url(/images/new-home-banner/banner-bullet.png) no-repeat 0 0;
        top: 13px;
        right: -65px;
        height: 40px;
        width: 40px;
        background-size: 100%;
        background-position-y: 50%;
        display: none; }
        @media screen and (min-width: 992px) {
          .new-home-banner .new-banner-list li:before {
            display: block;
            top: 10px;
            right: -25px;
            width: 20px; } }
      .new-home-banner .new-banner-list li:last-child::before {
        display: none; }
  .new-home-banner .new-banner-rated-area {
    background: #061226;
    position: relative; }
    .new-home-banner .new-banner-rated-area.m-height {
      height: 110px; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-banner-rated-area.m-height {
          height: auto; } }
    .new-home-banner .new-banner-rated-area p {
      margin-bottom: 0; }
    .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box,
    .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod {
      flex-wrap: wrap; }
      @media screen and (min-width: 992px) {
        .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box,
        .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod {
          display: flex;
          flex-wrap: nowrap;
          justify-content: center; } }
      .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box,
      .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box {
        display: flex;
        align-items: center;
        padding: 18px 50px;
        border-right: 0;
        justify-content: center;
        position: relative; }
        @media screen and (min-width: 992px) {
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box,
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box {
            padding: 18px 25px;
            justify-content: left; } }
        @media screen and (min-width: 1200px) {
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box,
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box {
            padding: 14px 40px; } }
        @media screen and (min-width: 1280px) {
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box,
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box {
            padding: 14px 44px; } }
        .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box:before,
        .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box:before {
          content: "";
          position: absolute;
          background: url(/images/new-home-banner/banner-devider.png) no-repeat 0 0;
          width: 1px;
          height: 100%;
          top: 0;
          right: 0; }
        .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box:last-child:before,
        .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box:last-child:before {
          display: none; }
        .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box .rated-left-area,
        .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box .rated-left-area {
          text-align: center;
          padding-right: 20px; }
          @media screen and (min-width: 992px) {
            .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box .rated-left-area,
            .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box .rated-left-area {
              padding-right: 20px; } }
          @media screen and (min-width: 1200px) {
            .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box .rated-left-area,
            .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box .rated-left-area {
              padding-right: 38px; } }
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box .rated-left-area .rated-text,
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box .rated-left-area .rated-text {
            color: #8d9eb2;
            font-size: 13px; }
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box .rated-left-area .rated-percentage,
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box .rated-left-area .rated-percentage {
            color: #81baff;
            font-weight: 500;
            font-size: 36px;
            padding: 0px 0 5px; }
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .new-banner-rated-area-box .rated-left-area .rated-star i,
          .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .new-banner-rated-area-box .rated-left-area .rated-star i {
            color: #f7b500;
            font-size: 10px; }
      .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box .slick-dots,
      .new-home-banner .new-banner-rated-area .new-banner-rated-area-main-box-mod .slick-dots {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 20px; }
  .new-home-banner .splide__arrows {
    display: none; }
  .new-home-banner--time-business-award {
    position: relative; }
    .new-home-banner--time-business-award:after {
      background: url(/images/new-home-banner/green-gradiant-tr.png) no-repeat 0 0;
      width: 710px;
      height: 486px;
      top: 0;
      right: 0;
      left: inherit;
      transform: none;
      z-index: 0; }
    @media screen and (min-width: 992px) {
      .new-home-banner--time-business-award {
        padding: 0; } }
    .new-home-banner--time-business-award .new-home-banner__left:before {
      display: none; }
    @media screen and (min-width: 992px) {
      .new-home-banner--time-business-award .new-home-banner__left {
        padding: 120px 0 0; } }
    .new-home-banner--time-business-award .new-home-banner__heading {
      font-size: 31px;
      line-height: 38px;
      font-weight: 700; }
      @media screen and (min-width: 992px) {
        .new-home-banner--time-business-award .new-home-banner__heading {
          font-size: 44px;
          line-height: 53px; } }
      .new-home-banner--time-business-award .new-home-banner__heading:before {
        display: none; }
    .new-home-banner--time-business-award .new-home-banner__heading-quote {
      color: #3CC065; }
    .new-home-banner--time-business-award .new-home-banner__blue-para {
      font-family: "Poppins";
      font-size: 17px;
      line-height: 23px;
      font-weight: 600;
      color: #8FC4F5;
      text-align: center;
      margin-top: 20px; }
      @media screen and (min-width: 992px) {
        .new-home-banner--time-business-award .new-home-banner__blue-para {
          padding-right: 290px;
          font-size: 21px;
          line-height: 27px;
          text-align: left;
          margin-top: 0; } }
    .new-home-banner--time-business-award .new-home-banner__btn-wrapper {
      position: relative;
      top: 0; }
      @media screen and (min-width: 992px) {
        .new-home-banner--time-business-award .new-home-banner__btn-wrapper {
          margin-top: 27px; } }
    @media screen and (min-width: 992px) {
      .new-home-banner--time-business-award .new-home-banner__award-img {
        position: absolute;
        top: 35px;
        right: 0;
        z-index: 999; } }
    @media screen and (min-width: 992px) {
      .new-home-banner--time-business-award .new-home-banner__award-img img {
        width: 94%;
        margin-left: auto;
        display: block; } }
  @media screen and (min-width: 992px) {
    .new-home-banner--et-award {
      padding: 60px 0 80px 0;
      background: url(/images/new-home-banner/et-award-slider.jpg) no-repeat center center/cover; } }
  .new-home-banner--et-award:after {
    display: none; }
  .new-home-banner--et-award .new-home-banner__left:before {
    display: none; }
  .new-home-banner--et-award .new-home-banner__heading:before {
    display: none; }
  .new-home-banner--et-award .new-home-banner__heading {
    font-family: "Poppins";
    font-weight: 700;
    margin-top: 20px; }
    @media screen and (min-width: 992px) {
      .new-home-banner--et-award .new-home-banner__heading {
        font-size: 44px;
        line-height: 50px;
        padding-bottom: 0;
        margin-bottom: 25px;
        margin-top: 0; } }
  .new-home-banner--et-award .new-home-banner__blue-para {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #97B3CE;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .new-home-banner--et-award .new-home-banner__blue-para {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 55px;
        text-align: left; } }
  .new-home-banner--et-award .new-home-banner__heading-quote {
    color: #3CC065; }
  @media screen and (min-width: 992px) {
    .new-home-banner--et-award .new-home-banner__left {
      padding-top: 100px; } }
  @media screen and (min-width: 1025px) {
    .new-home-banner--et-award .new-home-banner__left {
      flex: 0 0 55%; } }
  @media screen and (min-width: 992px) {
    .new-home-banner--et-excellence {
      padding: 80px 0;
      background: url(/images/new-home-banner/et-excellence-slider-new2.jpg) no-repeat center right;
      background-size: 100% 100%; } }
  @media screen and (min-width: 1600px) {
    .new-home-banner--et-excellence {
      background-size: cover; } }
  .new-home-banner--et-excellence:after {
    display: none; }
  .new-home-banner--et-excellence .new-home-banner__left {
    padding-right: 0;
    flex: 0 0 49%; }
    .new-home-banner--et-excellence .new-home-banner__left:before {
      display: none; }
  .new-home-banner--et-excellence .new-home-banner__right {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .new-home-banner--et-excellence .new-home-banner__right {
        padding: 0; } }
  @media screen and (min-width: 992px) {
    .new-home-banner--et-excellence .new-home-banner__heading {
      font-size: 44px;
      line-height: 58px;
      margin: 0; } }
  .new-home-banner--et-excellence .new-home-banner__heading:before {
    display: none; }
  .new-home-banner--et-excellence .new-home-banner__blue-para {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #fff;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .new-home-banner--et-excellence .new-home-banner__blue-para {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 55px;
        text-align: left; } }
  .new-home-banner--et-excellence .new-home-banner__btn-wrapper {
    margin-top: 35px; }
  @media screen and (min-width: 992px) {
    .new-home-banner--outsoucing-success-blueprint {
      padding: 60px 0 80px 0;
      background: url(/images/new-home-banner/outsourcing-bg.jpg) no-repeat center center/cover; } }
  .new-home-banner--outsoucing-success-blueprint:after {
    display: none; }
  .new-home-banner--outsoucing-success-blueprint:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 386px;
    height: 360px;
    background: url(/images/new-home-banner/banner-gradient.png) no-repeat 0 0;
    display: none; }
    @media screen and (min-width: 992px) {
      .new-home-banner--outsoucing-success-blueprint:before {
        display: block; } }
  .new-home-banner--outsoucing-success-blueprint .new-home-banner__btn-wrapper {
    position: relative;
    left: 0;
    bottom: 0;
    margin-bottom: 15px; }
  .new-home-banner--outsoucing-success-blueprint .new-home-banner__btn {
    background: #FF5800 !important; }
    .new-home-banner--outsoucing-success-blueprint .new-home-banner__btn svg path {
      fill: #fff; }
    .new-home-banner--outsoucing-success-blueprint .new-home-banner__btn:hover {
      background: #FFFFFF !important; }
      .new-home-banner--outsoucing-success-blueprint .new-home-banner__btn:hover svg path {
        fill: #FF5800; }
  @media screen and (min-width: 992px) {
    .new-home-banner--leap-mwc {
      padding: 80px 0 50px 0;
      background: url(/images/new-home-banner/leap-mwc-bg.jpg) no-repeat center center/cover; } }
  .new-home-banner--leap-mwc:after {
    display: none; }
  .new-home-banner--leap-mwc .new-home-banner__btn-wrapper {
    position: static;
    margin-bottom: 0; }
  .new-home-banner--leap-mwc .btn-orange {
    background: #ff5800 !important;
    font-size: 17px; }
    @media screen and (min-width: 992px) {
      .new-home-banner--leap-mwc .btn-orange {
        max-width: 350px;
        width: 350px; } }
    .new-home-banner--leap-mwc .btn-orange svg path {
      fill: #fff !important; }
    .new-home-banner--leap-mwc .btn-orange:hover {
      background: none !important;
      color: #fff; }
  .new-home-banner--leap-mwc .new-home-banner__right-items {
    color: #fff;
    font-size: 21px;
    font-weight: 500;
    margin: 8px 0;
    display: flex;
    align-items: center; }
    .new-home-banner--leap-mwc .new-home-banner__right-items svg {
      margin-right: 15px; }
  .new-home-banner--leap-mwc .new-home-banner__right-list {
    margin: 25px 0 35px; }

@keyframes animate {
  0% {
    background-position: 400%; }
  100% {
    background-position: 0%; } }

.text-left-mod {
  text-align: left !important; }

.margin-unset {
  margin-bottom: 0; }

.num-color {
  color: #43bf69; }

@media screen and (min-width: 992px) {
  .hm-about-section .about-details-para {
    width: 92%; } }

.hm-about-section .font-size-42 {
  font-size: 24px;
  line-height: 36px; }
  @media screen and (min-width: 992px) {
    .hm-about-section .font-size-42 {
      font-size: 41px;
      line-height: 52px; } }
  @media screen and (min-width: 1280px) {
    .hm-about-section .font-size-42 {
      font-size: 42px;
      line-height: 52px; } }

.new-home-banner-wrapper .splide__arrows {
  display: none; }

.new-home-banner-wrapper .custom-pagination-wrapper {
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  bottom: 0;
  height: 60px; }

.new-home-banner-wrapper .custom-pagination {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  max-width: 1150px;
  margin: 0 auto;
  align-items: center;
  padding: 0 15px; }
  @media screen and (min-width: 992px) {
    .new-home-banner-wrapper .custom-pagination {
      padding: 0; } }

.new-home-banner-wrapper .pagination-dot {
  position: relative;
  background: linear-gradient(to right, #ffffff 50%, #acacac 50%);
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  color: transparent;
  /* Hide the original text color */
  border: none;
  padding: 0;
  margin: 0 30px 0 0;
  cursor: pointer;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 300;
  width: auto;
  text-transform: capitalize;
  background-position: 100% 0%; }

.new-home-banner-wrapper .pagination-dot.is-active {
  background-position: 0% 0%; }

.new-home-banner-wrapper .pay-pause-wrapper {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  text-align: right; }

.new-home-banner-wrapper .pagination-pay-pause {
  width: 30px;
  height: 30px;
  color: #acacac;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 0;
  padding: 0;
  border: 2px solid #fff;
  border-radius: 50%;
  background: url(/images/new-home-banner/pause-white.svg) no-repeat 55% 50%/60%;
  transition: .3s all ease-in-out;
  opacity: 0.5; }
  .new-home-banner-wrapper .pagination-pay-pause.paused {
    border: 2px solid #fff;
    border-radius: 50%;
    background: url(/images/new-home-banner/play-white.svg) no-repeat 62% 50%/60%; }
  .new-home-banner-wrapper .pagination-pay-pause:hover {
    transform: scale(1.1); }

.new-home-banner-wrapper .splide__pagination {
  position: relative;
  bottom: 25px; }
  @media screen and (min-width: 992px) {
    .new-home-banner-wrapper .splide__pagination {
      display: none; } }

.new-home-banner-wrapper .splide__progress {
  position: absolute;
  top: 0;
  width: 100%; }

.new-home-banner-wrapper .splide__progress__bar {
  background: #a8d04d;
  height: 4px;
  animation: 4s linear animate infinite;
  background: -moz-linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
  background: -webkit-linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
  background: linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
  background-size: 200%; }

.new-home-banner-wrapper {
  background: #0d2642; }

@keyframes fillLine {
  from {
    width: 0%; }
  to {
    width: 100%; } }

@media screen and (min-width: 992px) {
  .convergence-gitx-left {
    flex: 0 0 60%;
    padding-right: 20px; } }

@media screen and (min-width: 1025px) {
  .convergence-gitx-left {
    flex: 0 0 63%; } }

@media screen and (min-width: 992px) {
  .convergence-gitx-left .new-home-banner__sub-heading-white {
    font-family: Poppins;
    font-size: 50px;
    line-height: 60px; } }

/* ANCHOR -Home Skill */
/* ANCHOR -Fixed Cost */
.fixed-cost-banner {
  text-align: center;
  padding-bottom: 15px; }
  @media screen and (min-width: 992px) {
    .fixed-cost-banner {
      text-align: inherit;
      padding-bottom: 0; } }
  .fixed-cost-banner:before {
    display: none; }
    @media screen and (min-width: 992px) {
      .fixed-cost-banner:before {
        display: block; } }
  @media screen and (min-width: 992px) {
    .fixed-cost-banner__spacing {
      padding: 120px 0 40px; } }
  @media screen and (min-width: 992px) {
    .fixed-cost-banner__subtitle {
      line-height: 37px;
      color: #fff; } }
  @media screen and (min-width: 992px) {
    .fixed-cost-banner__subtitle .highlight {
      color: #53d094; } }
  .fixed-cost-banner__btn {
    padding: 15px 15px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 600;
    transition: 0.3s all ease;
    margin-top: 30px;
    display: inline-block; }
    @media screen and (min-width: 992px) {
      .fixed-cost-banner__btn {
        padding: 15px 30px; } }
    .fixed-cost-banner__btn svg {
      margin-left: 5px;
      transition: 0.3s all ease; }
    .fixed-cost-banner__btn:hover svg {
      transform: translateX(10px); }
    .fixed-cost-banner__btn:hover path {
      fill: #ff5800; }
  .fixed-cost-banner .o-engagement-banner__bannerimage {
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .fixed-cost-banner .o-engagement-banner__bannerimage {
        margin-bottom: 0; } }

.fixed-core-engagement {
  padding-bottom: 0; }
  @media screen and (min-width: 992px) {
    .fixed-core-engagement {
      padding-bottom: 0; } }
  .fixed-core-engagement:before {
    height: 20%; }
  .fixed-core-engagement__wrapper {
    padding: 25px 10px 0; }
    @media screen and (min-width: 1180px) {
      .fixed-core-engagement__wrapper {
        padding: 65px 80px 0; } }
    .fixed-core-engagement__wrapper:before, .fixed-core-engagement__wrapper:after {
      display: none; }
  .fixed-core-engagement__title {
    margin-bottom: 10px; }
  .fixed-core-engagement__sub-heading {
    text-align: center;
    color: #474747;
    font-weight: 600; }
    @media screen and (min-width: 992px) {
      .fixed-core-engagement__sub-heading {
        font-size: 25px;
        line-height: 34px;
        margin-bottom: 35px; } }
  .fixed-core-engagement__corewrapper {
    padding: 20px;
    text-align: center;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
    @media screen and (min-width: 992px) {
      .fixed-core-engagement__corewrapper {
        padding: 50px 70px; } }
    .fixed-core-engagement__corewrapper i {
      display: block;
      margin: 0 auto;
      height: 50px; }
    .fixed-core-engagement__corewrapper h3 {
      font-family: "Poppins";
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      color: #333333;
      margin-top: 15px;
      margin-bottom: 10px; }
      @media screen and (min-width: 992px) {
        .fixed-core-engagement__corewrapper h3 {
          font-size: 31px;
          line-height: 38px;
          margin-top: 25px;
          margin-bottom: 15px; } }
    .fixed-core-engagement__corewrapper p {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 15px; }
      @media screen and (min-width: 992px) {
        .fixed-core-engagement__corewrapper p {
          font-size: 21px;
          line-height: 31px;
          margin-bottom: 30px; } }
    .fixed-core-engagement__corewrapper a {
      font-family: "Poppins";
      background: #ff5800;
      color: #fff;
      border: 2px solid #ff5800;
      padding: 15px 30px;
      border-radius: 5px;
      font-size: 17px;
      font-weight: 600;
      transition: 0.3s all ease;
      display: block;
      max-width: 250px;
      margin: 0 auto; }
      .fixed-core-engagement__corewrapper a svg {
        margin-left: 5px;
        transition: 0.3s all ease; }
      .fixed-core-engagement__corewrapper a:hover {
        background: transparent;
        color: #ff5800; }
        .fixed-core-engagement__corewrapper a:hover svg {
          transform: translateX(10px); }
        .fixed-core-engagement__corewrapper a:hover path {
          fill: #ff5800; }
    .fixed-core-engagement__corewrapper--blue {
      border-bottom: 8px solid #4492dc; }
    .fixed-core-engagement__corewrapper--green {
      border-bottom: 8px solid #3cc065; }
  .fixed-core-engagement__need-help {
    text-align: center; }
    .fixed-core-engagement__need-help h4 {
      font-family: "Poppins";
      margin-bottom: 15px; }
      @media screen and (min-width: 992px) {
        .fixed-core-engagement__need-help h4 {
          margin-top: 50px;
          font-size: 31px; } }
    .fixed-core-engagement__need-help a {
      font-family: "Poppins";
      font-size: 17px;
      font-weight: 600;
      color: #75a3f0; }
      .fixed-core-engagement__need-help a svg {
        margin-left: 10px; }
      .fixed-core-engagement__need-help a:hover {
        color: #3cc065; }
        .fixed-core-engagement__need-help a:hover svg {
          transform: translateX(10px); }
        .fixed-core-engagement__need-help a:hover path {
          fill: #3cc065; }
        .fixed-core-engagement__need-help a:hover span:before {
          background: #3cc065; }
      .fixed-core-engagement__need-help a span {
        position: relative; }
        .fixed-core-engagement__need-help a span:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: #75a3f0; }

.fixed-cost-model {
  padding: 0;
  background: #f9f9f9; }
  @media screen and (min-width: 992px) {
    .fixed-cost-model {
      padding: 0; } }
  .fixed-cost-model__heading {
    font-family: "Poppins";
    text-align: center;
    color: #262e36; }
    @media screen and (min-width: 992px) {
      .fixed-cost-model__heading {
        font-size: 44px;
        line-height: 60px; } }
  .fixed-cost-model__subheading {
    font-family: "Poppins";
    text-align: center;
    color: #262e36;
    font-weight: 700;
    padding-bottom: 20px;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .fixed-cost-model__subheading {
        font-size: 40px;
        line-height: 58px;
        padding-bottom: 20px;
        margin-bottom: 20px; } }
    .fixed-cost-model__subheading:before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      width: 201px;
      height: 4px;
      background: #3cc065; }
  .fixed-cost-model__subtitle {
    font-family: "Poppins";
    text-align: center;
    color: #474747;
    font-weight: 600;
    position: relative; }
    @media screen and (min-width: 992px) {
      .fixed-cost-model__subtitle {
        font-size: 22px;
        line-height: 34px; } }
  .fixed-cost-model__sub-heading {
    font-family: "Poppins";
    color: #262e36;
    font-size: 24px;
    font-weight: 700;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .fixed-cost-model__sub-heading {
        font-size: 31px; } }
  .fixed-cost-model__wrapper {
    position: relative; }
    @media screen and (min-width: 992px) {
      .fixed-cost-model__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; } }
    .fixed-cost-model__wrapper:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      height: 2px;
      width: 100%;
      background: url(/images/fixed-cost/dashed-img.png) repeat-x 0 0;
      display: none; }
      @media screen and (min-width: 992px) {
        .fixed-cost-model__wrapper:before {
          display: block;
          width: 87%; } }
      @media screen and (min-width: 1200px) {
        .fixed-cost-model__wrapper:before {
          width: 87%; } }
      @media screen and (min-width: 1280px) {
        .fixed-cost-model__wrapper:before {
          width: 100%; } }
  .fixed-cost-model__card {
    border: 1px solid #898989;
    padding: 30px 30px 30px 25px;
    position: relative;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .fixed-cost-model__card {
        margin: 33px 0;
        padding: 30px 30px 30px 25px;
        flex: 0 0 24.4%; } }
    @media screen and (min-width: 1200px) {
      .fixed-cost-model__card {
        flex: 0 0 25.4%; } }
    @media screen and (min-width: 1280px) {
      .fixed-cost-model__card {
        flex: 0 0 29.4%; } }
    @media screen and (min-width: 992px) {
      .fixed-cost-model__card:nth-child(2), .fixed-cost-model__card:nth-child(5) {
        margin: 33px 66px; } }
    .fixed-cost-model__card:last-child {
      margin-right: 0; }
    @media screen and (min-width: 992px) {
      .fixed-cost-model__card--right-arrow::before {
        content: "";
        position: absolute;
        background: url(/images/fixed-cost/arrow-right.svg) no-repeat 0 0;
        width: 57px;
        height: 17px;
        right: -59px;
        top: 94px; } }
  .fixed-cost-model__card-icon {
    display: inline-block;
    height: 50px;
    margin-bottom: 15px; }
  .fixed-cost-model__card-heading {
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 600;
    color: #333; }
  .fixed-cost-model__card-para {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 22px;
    color: #333;
    margin-bottom: 0; }
  .fixed-cost-model__numerics {
    font-family: "Poppins";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    background: #355bbf;
    color: #fff;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 600;
    border: 11px solid #ebeffa; }
  .fixed-cost-model__curve-arrow-right {
    position: absolute;
    top: 141px;
    right: -48px;
    display: none; }
    @media screen and (min-width: 992px) {
      .fixed-cost-model__curve-arrow-right {
        top: 163px;
        right: 10px;
        display: block; } }
    @media screen and (min-width: 1200px) {
      .fixed-cost-model__curve-arrow-right {
        top: 141px;
        right: 17px; } }
    @media screen and (min-width: 1280px) {
      .fixed-cost-model__curve-arrow-right {
        right: -48px; } }
  .fixed-cost-model__curve-arrow-left {
    position: absolute;
    left: -49px;
    bottom: 128px;
    display: none; }
    @media screen and (min-width: 992px) {
      .fixed-cost-model__curve-arrow-left {
        left: 10px;
        bottom: 150px;
        display: block; } }
    @media screen and (min-width: 1200px) {
      .fixed-cost-model__curve-arrow-left {
        bottom: 128px;
        left: 17px; } }
    @media screen and (min-width: 1280px) {
      .fixed-cost-model__curve-arrow-left {
        left: -49px; } }

.fixed-cost-benefits {
  margin-top: 20px; }
  @media screen and (min-width: 992px) {
    .fixed-cost-benefits {
      margin-top: 33px; } }
  .fixed-cost-benefits h4 {
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 700;
    color: #262e36;
    text-align: center;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .fixed-cost-benefits h4 {
        font-size: 31px;
        margin-bottom: 35px; } }
  .fixed-cost-benefits__card {
    padding: 30px 30px 30px 25px;
    border: 1px solid #dddddd;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .fixed-cost-benefits__card {
        padding: 30px 30px 30px 25px;
        height: 100%;
        margin-bottom: 0; } }
  .fixed-cost-benefits__card-icon {
    display: inline-block;
    height: 50px;
    margin-bottom: 15px; }
  .fixed-cost-benefits__card-heading {
    font-family: "Poppins";
    font-size: 21px;
    line-height: 32px;
    font-weight: 600;
    color: #333; }
  .fixed-cost-benefits__card-para {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    color: #333;
    margin-bottom: 0; }
  .fixed-cost-benefits__btn {
    font-family: "Poppins";
    background: #ff5800;
    color: #fff;
    border: 2px solid #ff5800;
    padding: 15px 15px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 600;
    transition: 0.3s all ease;
    display: inline-block;
    margin: 15px 0 0; }
    @media screen and (min-width: 992px) {
      .fixed-cost-benefits__btn {
        margin: 35px 0 0;
        padding: 15px 30px; } }
    .fixed-cost-benefits__btn svg {
      margin-left: 5px;
      transition: 0.3s all ease; }
    .fixed-cost-benefits__btn:hover {
      background: transparent;
      color: #ff5800; }
      .fixed-cost-benefits__btn:hover svg {
        transform: translateX(10px); }
      .fixed-cost-benefits__btn:hover path {
        fill: #ff5800; }

@media screen and (min-width: 992px) {
  .v-gap {
    height: 60px; } }

@media screen and (min-width: 992px) {
  .custom-solution--fixed {
    padding: 60px 0 60px !important; } }

@media screen and (min-width: 992px) {
  .custom-solution--fixed .custom-solution__heading {
    margin-bottom: 40px; } }

@media screen and (min-width: 992px) {
  .custom-solution--fixed .btn-wrapper-v2--pair .btn-v2 {
    margin: 25px 5px 20px; } }

.new-faq--fixed .faq-sub-title {
  font-family: "Poppins";
  font-size: 19px;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 25px; }
  @media screen and (min-width: 992px) {
    .new-faq--fixed .faq-sub-title {
      font-size: 25px;
      line-height: 34px;
      margin-bottom: 30px; } }
  @media screen and (min-width: 992px) and (min-width: 992px) {
    .new-faq--fixed .faq-sub-title {
      margin-bottom: 50px; } }

.new-faq--fixed h2 {
  font-weight: 700; }
  @media screen and (min-width: 992px) {
    .new-faq--fixed h2 {
      padding-bottom: 15px; } }

.agile-process {
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  padding: 0 0 40px; }
  @media screen and (min-width: 992px) {
    .agile-process {
      padding: 0 0 60px; } }
  .agile-process h3 {
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .agile-process h3 {
        margin-bottom: 0; } }
  .agile-process .fixed-cost-model__card {
    border: 1px solid #dddddd; }
    @media screen and (min-width: 992px) {
      .agile-process .fixed-cost-model__card {
        flex: 0 0 28%;
        padding: 30px 25px 30px 25px;
        box-shadow: 5px -4px 20px -7px rgba(0, 0, 0, 0.1); } }
    @media screen and (min-width: 1200px) {
      .agile-process .fixed-cost-model__card {
        flex: 0 0 24%; } }
  .agile-process .fixed-cost-model__wrapper:before {
    width: 68%;
    top: 49.5%;
    right: inherit;
    left: 23.5%; }
    @media screen and (min-width: 992px) {
      .agile-process .fixed-cost-model__wrapper:before {
        width: 80%;
        top: 49.5%;
        right: inherit;
        left: 19%; } }
    @media screen and (min-width: 1200px) {
      .agile-process .fixed-cost-model__wrapper:before {
        width: 69%;
        top: 49.5%;
        right: inherit;
        left: 23%; } }
    @media screen and (min-width: 1280px) {
      .agile-process .fixed-cost-model__wrapper:before {
        width: 68%;
        top: 49.5%;
        right: inherit;
        left: 23.5%; } }
  .agile-process .fixed-cost-model__curve-arrow-right {
    right: 46px;
    top: 137px; }
    @media screen and (min-width: 992px) {
      .agile-process .fixed-cost-model__curve-arrow-right {
        right: -41px;
        top: 135px; } }
    @media screen and (min-width: 1200px) {
      .agile-process .fixed-cost-model__curve-arrow-right {
        right: 40px;
        top: 137px; } }
    @media screen and (min-width: 1280px) {
      .agile-process .fixed-cost-model__curve-arrow-right {
        right: 46px;
        top: 137px; } }
  .agile-process .fixed-cost-model__curve-arrow-left {
    left: 215px;
    bottom: 134px; }
    @media screen and (min-width: 992px) {
      .agile-process .fixed-cost-model__curve-arrow-left {
        left: 122px;
        bottom: 132px; } }
    @media screen and (min-width: 1200px) {
      .agile-process .fixed-cost-model__curve-arrow-left {
        left: 207px;
        bottom: 133px; } }
    @media screen and (min-width: 1280px) {
      .agile-process .fixed-cost-model__curve-arrow-left {
        left: 215px;
        bottom: 134px; } }
  .agile-process .fixed-cost-model__card--mr0 {
    margin-right: 0; }
  .agile-process .fixed-cost-model__card-para {
    font-size: 16px; }
  .agile-process .fixed-cost-model__card-heading {
    font-size: 18px; }
    @media screen and (min-width: 1200px) {
      .agile-process .fixed-cost-model__card-heading {
        font-size: 19px; } }
  @media screen and (min-width: 992px) {
    .agile-process .fixed-cost-model__card--right-arrow::before {
      top: 100px; } }

.fixed-cost .custom-review {
  background: #f9f9f9;
  border-top: 1px solid #e0e0e0; }

.scroll-to-box {
  height: 40px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 30px;
  background: #fff; }
  @media screen and (min-width: 992px) {
    .scroll-to-box {
      height: 72px;
      margin-bottom: 60px; } }

.scroll-to-box-agile {
  height: 40px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 30px;
  background: #f9f9f9; }
  @media screen and (min-width: 992px) {
    .scroll-to-box-agile {
      height: 71px;
      margin-bottom: 60px; } }

/* ANCHOR - Custom Solutions */
.custom-solution {
  background-color: #F9F9F9;
  padding: 30px 0 40px; }
  @media screen and (min-width: 992px) {
    .custom-solution {
      padding: 60px 0; } }
  .custom-solution__heading {
    color: #262E36;
    font-weight: 700;
    position: relative;
    padding-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .custom-solution__heading {
        padding-bottom: 0; } }
  .custom-solution__sub-heading {
    font-family: 'Poppins' !important;
    font-size: 25px;
    line-height: 34px;
    font-weight: 600;
    color: #474747;
    margin-top: 15px; }
    .custom-solution__sub-heading--home-sub {
      padding: 10px 0 30px; }
    .custom-solution__sub-heading--small {
      font-size: 21px;
      line-height: 32px;
      margin: 25px 0 0; }
  .custom-solution__card-gradient {
    margin-bottom: 15px;
    border: 1px solid #898989;
    display: block;
    position: relative; }
    @media screen and (min-width: 992px) {
      .custom-solution__card-gradient {
        cursor: pointer;
        overflow: hidden;
        padding: 2px;
        border: 0;
        margin-bottom: 25px; } }
    .custom-solution__card-gradient:before {
      content: '+';
      position: absolute;
      top: 20px;
      right: 10px;
      font-size: 36px;
      line-height: 0;
      font-weight: 300;
      color: #898989;
      z-index: 99;
      display: none; }
      @media screen and (min-width: 992px) {
        .custom-solution__card-gradient:before {
          display: block; } }
    .custom-solution__card-gradient:after {
      content: '';
      position: absolute;
      top: -106px;
      right: 0;
      bottom: 0;
      left: -31px;
      background: #898989;
      width: 430px;
      height: 430px;
      transition: .3s all ease;
      display: none; }
      @media screen and (min-width: 992px) {
        .custom-solution__card-gradient:after {
          display: block; } }
    .custom-solution__card-gradient:hover:after {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
      display: none; }
      @media screen and (min-width: 992px) {
        .custom-solution__card-gradient:hover:after {
          display: block; } }
    .custom-solution__card-gradient:hover:before {
      color: #35b15c; }
  @media screen and (min-width: 992px) {
    .custom-solution__card-gradient--active {
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); } }
  .custom-solution__card-gradient--active:before {
    content: '-';
    top: 16px;
    font-size: 44px;
    color: #35b15c; }
  @media screen and (min-width: 992px) {
    .custom-solution__card-gradient--active:after {
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); } }
  .custom-solution__card {
    background: #F9F9F9;
    page-break-inside: avoid;
    padding: 20px 40px 20px 15px;
    position: relative;
    z-index: 9; }
    @media screen and (min-width: 992px) {
      .custom-solution__card {
        padding: 20px 20px 20px 20px; } }
  .custom-solution__collapse {
    display: flex;
    align-items: center; }
  .custom-solution__icon {
    display: flex;
    align-items: center;
    height: 50px;
    margin-right: 15px; }
    .custom-solution__icon--fixed-flex {
      flex: 0 0 45px;
      max-width: 45px; }
  .custom-solution__card-heading {
    font-family: 'Poppins' !important;
    font-size: 19px;
    line-height: 31px;
    font-weight: 600;
    margin-bottom: 0; }
  @media screen and (min-width: 992px) {
    .custom-solution__card-description-wrapper {
      display: none;
      margin-top: 15px; } }
  .custom-solution__card-description {
    font-family: 'Poppins' !important;
    font-size: 14px;
    line-height: 22px;
    color: #727272;
    display: none; }
    @media screen and (min-width: 992px) {
      .custom-solution__card-description {
        display: block; } }
  .custom-solution__card-link {
    font-family: 'Poppins' !important;
    font-size: 0;
    font-weight: 600;
    color: #333;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    @media screen and (min-width: 992px) {
      .custom-solution__card-link {
        font-size: 14px;
        position: static; } }
    .custom-solution__card-link::before {
      content: '';
      position: absolute;
      background: url(/images/custom-solutions/r-arrow.png) no-repeat 0 0;
      width: 22px;
      height: 12px;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      display: block; }
      @media screen and (min-width: 992px) {
        .custom-solution__card-link::before {
          display: none; } }
    .custom-solution__card-link svg {
      margin-left: 10px;
      display: none; }
      @media screen and (min-width: 992px) {
        .custom-solution__card-link svg {
          display: inherit; } }
  .custom-solution .btn-wrapper-v2--pair .btn-v2 {
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .custom-solution .btn-wrapper-v2--pair .btn-v2 {
        margin-top: 25px; } }

@keyframes rotation2 {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

/* ANCHOR - New dedicated */
.o-scale-dedicated {
  background-color: #f9f9f9; }
  .o-scale-dedicated__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-scale-dedicated__spacing {
        padding: 60px 0; } }
  .o-scale-dedicated__heading {
    font-family: 'Poppins';
    font-size: 28px;
    line-height: 30px;
    font-weight: 700;
    color: #262E36;
    margin: 0 0 20px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-scale-dedicated__heading {
        font-size: 51px;
        line-height: 30px;
        margin: 0 0 45px; } }
    .o-scale-dedicated__heading--second {
      margin: 30px 0 20px; }
      @media screen and (min-width: 992px) {
        .o-scale-dedicated__heading--second {
          line-height: 65px;
          margin: 30px 0 20px; } }
  .o-scale-dedicated__box {
    width: 100%;
    height: auto;
    background-color: #fff;
    padding: 18px 18px 22px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-scale-dedicated__box {
        height: 100%;
        min-height: 225px;
        padding: 29px 30px 40px 45px;
        text-align: left; } }
  .o-scale-dedicated__boxtitle {
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    color: #333;
    margin: 10px 0 10px; }
    @media screen and (min-width: 992px) {
      .o-scale-dedicated__boxtitle {
        font-size: 21px;
        line-height: 32px; } }
  .o-scale-dedicated__boxcontent {
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 27px;
    font-weight: 500;
    color: #333;
    margin: 0; }
  .o-scale-dedicated .mb-30 {
    margin-bottom: 30px; }
  .o-scale-dedicated__list {
    max-width: 942px;
    margin: 20px auto 0; }
  .o-scale-dedicated__item {
    display: flex;
    padding: 18px;
    flex-wrap: wrap;
    background-image: linear-gradient(to right, #898989 40%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x;
    justify-content: center;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-scale-dedicated__item {
        padding: 34px 0 44px;
        justify-content: flex-start;
        text-align: left; } }
    .o-scale-dedicated__item i {
      flex: 0 0 54px;
      margin-right: 18px;
      height: auto !important; }
  .o-scale-dedicated i {
    display: inline-block;
    height: 52px; }
  .o-scale-dedicated__itemcontent {
    flex: 0 0 90%; }
  .o-scale-dedicated__itemtitle {
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    color: #333;
    margin: 12px 0; }
    @media screen and (min-width: 992px) {
      .o-scale-dedicated__itemtitle {
        font-size: 21px;
        line-height: 32px;
        margin: 0 0 10px; } }
  .o-scale-dedicated__itemsub {
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #333;
    margin: 0; }
  .o-scale-dedicated__headingbottom {
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    color: #262E36;
    text-align: center;
    margin: 40px 0 30px; }
    @media screen and (min-width: 992px) {
      .o-scale-dedicated__headingbottom {
        font-size: 31px;
        line-height: 40px; } }
  .o-scale-dedicated__btn {
    margin: auto;
    text-transform: capitalize; }

.o-how-dedicated {
  background-color: #355BBF; }
  .o-how-dedicated__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-how-dedicated__spacing {
        padding: 60px 0; } }
  .o-how-dedicated__heading {
    font-family: 'Poppins';
    font-size: 28px;
    line-height: 30px;
    font-weight: 700;
    color: #fff;
    margin: 0 0 15px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-how-dedicated__heading {
        font-size: 51px;
        line-height: 60px; } }
  .o-how-dedicated__subheading {
    font-family: 'Poppins';
    font-size: 21px;
    line-height: 27px;
    text-align: center;
    color: #fff;
    margin-bottom: 45px; }
  .o-how-dedicated__list {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
    flex-direction: column; }
    @media screen and (min-width: 992px) {
      .o-how-dedicated__list {
        flex-direction: row;
        margin-bottom: 50px; } }
  .o-how-dedicated__item {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 28px 15px 20px 22px;
    max-width: 254px;
    margin: 0 auto 60px;
    width: 100%;
    min-height: 220px;
    position: relative; }
    @media screen and (min-width: 992px) {
      .o-how-dedicated__item {
        width: 22%;
        margin: 0; } }
    .o-how-dedicated__item:after {
      position: absolute;
      content: "";
      background: url(/images/two-for-one/dedicated-right-arrow.svg) no-repeat center;
      background-size: 100%;
      height: 10px;
      width: 35px;
      top: inherit;
      right: 0;
      left: 0;
      bottom: -35px;
      margin: auto;
      transform: rotate(90deg); }
      @media screen and (min-width: 992px) {
        .o-how-dedicated__item:after {
          top: 50%;
          right: -35px;
          bottom: inherit;
          left: inherit;
          transform: none; } }
    .o-how-dedicated__item:last-child {
      margin: 0 auto; }
      @media screen and (min-width: 992px) {
        .o-how-dedicated__item:last-child {
          margin: 0; } }
      .o-how-dedicated__item:last-child:after {
        display: none; }
    .o-how-dedicated__item i {
      display: inline-block;
      height: 52px; }
  .o-how-dedicated__itemtitle {
    font-family: 'Poppins';
    font-size: 21px;
    line-height: 31px;
    color: #333;
    font-weight: 600;
    margin: 18px 0 8px; }
  .o-how-dedicated__itemcontent {
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 22px;
    color: #333;
    margin: 0; }
    .o-how-dedicated__itemcontent a {
      color: #ff5800;
      font-weight: 500; }
      .o-how-dedicated__itemcontent a:hover {
        text-decoration: underline;
        position: relative; }
  .o-how-dedicated__number {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    width: 58px;
    height: 58px;
    background-color: #355BBF;
    border-radius: 50%;
    border: 11px solid #ebeff9;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 9px; }

.new-home-banner__btn-arrow {
  margin-left: 10px; }

.faq-subform {
  font-family: 'Poppins';
  font-size: 25px;
  line-height: 34px;
  margin-bottom: 35px;
  color: #333; }

.pool-dedicated-btn {
  margin: 15px auto 0 !important; }
  @media screen and (min-width: 992px) {
    .pool-dedicated-btn {
      margin: 50px 5px 0 !important; } }

/* ANCHOR - Our-services */
.banner-services__subtitle {
  font-family: "Poppins";
  font-size: 20px;
  line-height: 40px;
  font-weight: 600;
  color: #3CC065; }
  @media (min-width: 767px) {
    .banner-services__subtitle {
      font-size: 25px;
      line-height: 40px;
      font-weight: 700; } }
  @media (min-width: 1024px) {
    .banner-services__subtitle {
      font-size: 31px;
      line-height: 47px; } }

.banner-services__header-br {
  display: none; }
  @media (min-width: 1024px) {
    .banner-services__header-br {
      display: block; } }

@media screen and (min-width: 992px) {
  .services-tab {
    overflow: visible; } }

.services-tab__heading {
  margin-bottom: 20px; }

.services-tab__description {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600px;
  text-align: center;
  font-family: 'Poppins' !important;
  margin-bottom: 0px; }
  @media (min-width: 767px) {
    .services-tab__description {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 1024px) {
    .services-tab__description {
      font-size: 25px;
      line-height: 34px; } }

.services-tab .o-accordion__nav .active {
  color: #4492DC; }

.services-tab .o-accordion__tab:hover {
  color: #4492DC; }

@media screen and (min-width: 992px) {
  .o-accordion__content-title--service {
    padding-top: 0;
    margin-bottom: 0; } }

@media screen and (min-width: 992px) {
  .o-accordion__content-area--service {
    position: static; } }

@media screen and (min-width: 992px) {
  .o-accordion__content--service {
    padding: 40px 0 55px 55px; } }

.o-accordion__content-head {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 25px; }
  @media screen and (min-width: 992px) {
    .o-accordion__content-head {
      padding-right: 50px;
      padding-bottom: 15px; } }
  .o-accordion__content-head:before {
    content: '';
    position: absolute;
    background: #4492DC;
    width: 49px;
    height: 4px;
    bottom: 0;
    left: 0; }
  .o-accordion__content-head i {
    display: inline-block;
    margin-right: 15px;
    flex: 0 0 55px;
    height: 60px;
    width: 50px;
    display: flex;
    align-items: center; }

.o-accordion__content-items {
  padding-left: 40px;
  margin-bottom: 10px;
  position: relative; }
  .o-accordion__content-items:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: url(../images/new-services/tick-circle.png) no-repeat 0 0;
    width: 25px;
    height: 25px; }
  .o-accordion__content-items h4 {
    font-family: "Poppins";
    font-size: 21px; }
  .o-accordion__content-items p {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px; }

.o-accordion__content-btn {
  font-family: "Poppins";
  display: inline-block;
  padding: 15px 24px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background: #FF5800;
  border: 1px solid #FF5800;
  transition: .3s all ease;
  position: relative;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .o-accordion__content-btn {
      font-size: 15px; } }
  .o-accordion__content-btn svg {
    margin-left: 7px;
    width: 20px; }
    @media screen and (min-width: 992px) {
      .o-accordion__content-btn svg {
        width: 32px;
        margin-left: 10px; } }
  .o-accordion__content-btn:hover {
    color: #FF5800;
    background: transparent; }
    .o-accordion__content-btn:hover svg {
      transform: translateX(10px); }
    .o-accordion__content-btn:hover path {
      fill: #FF5800; }

/* ANCHOR - UI-UX Core*/
.c-core-element-wrap {
  border-bottom: 1px solid #e0e0e0; }
  .c-core-element-wrap__subheading {
    font-family: Poppins;
    font-size: 18px;
    line-height: 27px;
    color: #333;
    font-weight: 500;
    text-align: center;
    margin: 0 0 15px; }
    @media screen and (min-width: 992px) {
      .c-core-element-wrap__subheading {
        font-size: 21px;
        line-height: 31px; } }
    .c-core-element-wrap__subheading--stext {
      font-size: 15px;
      margin: 0;
      line-height: normal;
      text-align: left; }
  .c-core-element-wrap__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }
  .c-core-element-wrap__item {
    width: 100%;
    padding: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    border: 1px solid #ddd;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .c-core-element-wrap__item {
        width: 20%;
        border: none;
        margin-bottom: 30px;
        justify-content: center; } }
  .c-core-element-wrap__border-bottom {
    position: relative; }
    @media screen and (min-width: 992px) {
      .c-core-element-wrap__border-bottom:after {
        content: "";
        position: absolute;
        width: 80%;
        height: 1px;
        background-color: #ddd;
        bottom: -15px;
        left: 0;
        right: 0;
        margin: 0 auto; } }
  @media screen and (min-width: 992px) {
    .c-core-element-wrap__border-right {
      border-right: 1px solid #ddd; } }

.sample-work-wrap h2 {
  font-family: Poppins;
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
  color: #262e36;
  margin-bottom: 15px; }
  @media screen and (min-width: 992px) {
    .sample-work-wrap h2 {
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 40px; } }

.sample-work-wrap h5 {
  font-family: Poppins; }

@media screen and (min-width: 767px) {
  .o-development-process.o-skill-service .btn-auto-width {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content; } }

/* New Company profile*/
.common-padding {
  padding: 30px 0 40px; }
  @media screen and (min-width: 992px) {
    .common-padding {
      padding: 45px 0 60px; } }

.about-us__topheading {
  font-family: "Poppins";
  font-size: 20px;
  line-height: 34px;
  font-weight: 700;
  color: #3cc065;
  text-align: center;
  margin-bottom: 10px; }
  @media screen and (min-width: 992px) {
    .about-us__topheading {
      font-size: 31px;
      line-height: 54px; } }

.about-us__heading {
  font-family: "Poppins";
  color: #262e36;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .about-us__heading {
      font-size: 46px;
      line-height: 60px; } }

.company-banner {
  position: relative;
  background: #0d2642 url(/images/new-company-profile/company-profile-banner.png) no-repeat;
  background-position: 27% 0;
  background-size: 200%;
  margin-top: 68px;
  overflow: hidden; }
  @media screen and (min-width: 992px) {
    .company-banner {
      background: #0d2642 url(/images/new-company-profile/company-profile-banner.png) no-repeat;
      background-position: top right;
      background-size: 80%; } }
  .company-banner:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url(/images/new-home-banner/banner-gradient.png);
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 20%;
    top: 0;
    left: 0; }
  .company-banner__spacing {
    padding: 60px 0; }
    @media screen and (min-width: 992px) {
      .company-banner__spacing {
        padding: 110px 0; } }
    @media screen and (min-width: 1540px) {
      .company-banner__spacing {
        padding: 159px 0; } }
  .company-banner__heading {
    font-family: "Poppins";
    color: #fff;
    font-size: 31px;
    line-height: 38px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .company-banner__heading {
        font-size: 51px;
        line-height: 54px; } }
  .company-banner__subheading {
    font-family: "Poppins";
    color: #fff;
    font-size: 21px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 25px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .company-banner__subheading {
        font-size: 31px;
        line-height: 38px; } }
  .company-banner__btn {
    width: 100%;
    max-width: 100%;
    padding: 15px;
    text-transform: capitalize; }
    @media screen and (min-width: 992px) {
      .company-banner__btn {
        max-width: 333px; } }

.o-our-story {
  position: relative;
  background-color: #f9f9f9; }
  @media screen and (min-width: 1200px) {
    .o-our-story .container {
      max-width: 1000px; } }
  .o-our-story .about-us__heading {
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .o-our-story .about-us__heading {
        margin-bottom: 60px; } }
  .o-our-story__content {
    font-family: "Poppins";
    color: #333;
    font-size: 16px;
    line-height: 27px;
    margin: 0 auto 15px;
    width: 100%;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-our-story__content {
        margin: 0 auto 20px;
        text-align: left; } }
    @media screen and (min-width: 1180px) {
      .o-our-story__content {
        width: 90%; } }
  .o-our-story__btn {
    width: 100%;
    max-width: 100%;
    padding: 15px;
    text-transform: capitalize;
    margin: 15px auto 0; }
    @media screen and (min-width: 992px) {
      .o-our-story__btn {
        max-width: 292px;
        margin: 40px auto 0; } }

.o-our-evolution .about-us__heading {
  margin-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .o-our-evolution .about-us__heading {
      margin-bottom: 10px; } }

.o-our-evolution__bottomtext {
  font-family: "Poppins";
  color: #333;
  text-align: center;
  font-size: 20px;
  line-height: 25px;
  font-weight: 500; }
  @media screen and (min-width: 992px) {
    .o-our-evolution__bottomtext {
      font-size: 25px;
      line-height: 27px; } }

.o-our-evolution__timeline {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 30px 0 5px;
  width: 100%;
  overflow: hidden; }
  @media screen and (min-width: 992px) {
    .o-our-evolution__timeline {
      margin: 66px 0 10px;
      flex-direction: row; } }
  .o-our-evolution__timeline .splide__arrows {
    display: block; }
  .o-our-evolution__timeline .splide__arrow--prev {
    top: 28px; }
  .o-our-evolution__timeline .splide__arrow--next {
    top: 28px; }

.o-our-evolution__year {
  width: 100%;
  display: none; }
  @media screen and (min-width: 800px) {
    .o-our-evolution__year {
      width: 20%;
      display: block;
      padding-left: 35px; } }
  .o-our-evolution__year li {
    position: relative; }
    .o-our-evolution__year li:after {
      position: absolute;
      content: "";
      width: 2px;
      height: 100%;
      top: 27px;
      background-color: #c4bbc5;
      left: -23px;
      z-index: 1;
      display: none; }
      @media screen and (min-width: 992px) {
        .o-our-evolution__year li:after {
          display: block; } }
    .o-our-evolution__year li a {
      display: block;
      width: auto;
      height: auto;
      text-align: center;
      opacity: 1;
      background: 0 0;
      color: #6e6e71;
      margin: 15px 0;
      position: relative;
      font-size: 21px;
      font-weight: 600;
      display: inline-block; }
      .o-our-evolution__year li a:hover {
        color: #4492dc;
        text-indent: 2px; }
        .o-our-evolution__year li a:hover:before {
          border-color: #4492dc; }
      @media screen and (min-width: 992px) {
        .o-our-evolution__year li a {
          text-align: left; } }
      .o-our-evolution__year li a:before {
        content: "";
        position: absolute;
        top: 6px;
        left: -32.5px;
        width: 21px;
        height: 21px;
        border-radius: 100%;
        background-color: #fff;
        border: 2px solid #898989;
        transition: 0.2s;
        z-index: 2;
        display: none; }
        @media screen and (min-width: 992px) {
          .o-our-evolution__year li a:before {
            display: block; } }
      .o-our-evolution__year li a.active {
        color: #4492dc; }
        .o-our-evolution__year li a.active:hover {
          text-indent: 0; }
        .o-our-evolution__year li a.active:before {
          background-color: #4492dc;
          border: 2px solid #4492dc; }
    @media screen and (min-width: 992px) {
      .o-our-evolution__year li:first-child a {
        margin: 0 0 15px; } }
    @media screen and (min-width: 992px) {
      .o-our-evolution__year li:last-child a {
        margin: 15px 0 0; } }
    .o-our-evolution__year li:last-child:after {
      display: none; }

.o-our-evolution__yearcontent {
  width: 100%;
  height: 400px;
  overflow: hidden; }
  @media screen and (min-width: 800px) {
    .o-our-evolution__yearcontent {
      width: 40%;
      padding: 0 10px; } }

.o-our-evolution .owl-item {
  animation-duration: 30ms; }

.o-our-evolution .owl-nav {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%; }

.o-our-evolution .owl-prev {
  font-size: 45px !important;
  color: #000 !important;
  background-color: #fff !important;
  width: 50px;
  height: 50px;
  border: 1px solid #3cc065 !important;
  line-height: 0;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 5px;
  font-weight: 300 !important; }
  .o-our-evolution .owl-prev span {
    position: relative;
    top: -3px;
    left: -1px;
    height: 50px;
    line-height: 50px; }
  .o-our-evolution .owl-prev:hover {
    color: #fff !important;
    background-color: #3cc065 !important; }

.o-our-evolution .owl-next {
  font-size: 45px !important;
  color: #000 !important;
  background-color: #fff !important;
  width: 50px;
  height: 50px;
  border: 1px solid #3cc065 !important;
  line-height: 0;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 5px;
  font-weight: 300 !important; }
  .o-our-evolution .owl-next span {
    position: relative;
    top: -3px;
    right: -1px;
    height: 50px;
    line-height: 50px; }
  .o-our-evolution .owl-next:hover {
    color: #fff !important;
    background-color: #3cc065 !important; }

.o-our-evolution .ele {
  margin-bottom: 50px; }
  .o-our-evolution .ele h6 {
    font-family: "Poppins";
    color: #4492dc;
    display: block;
    font-size: 30px;
    margin: 10px 0 30px;
    font-weight: 700;
    opacity: 1;
    transition: 0.2s ease 0.4s;
    text-align: center; }
    @media screen and (min-width: 800px) {
      .o-our-evolution .ele h6 {
        opacity: 0.2;
        font-size: 24px;
        color: #333; } }
    @media screen and (min-width: 992px) {
      .o-our-evolution .ele h6 {
        font-size: 31px;
        margin: 0 0 15px;
        text-align: left; } }
  .o-our-evolution .ele p {
    font-family: "Poppins";
    color: #333;
    font-size: 18px;
    line-height: 26px;
    color: #333;
    font-weight: 500;
    opacity: 1;
    transition: 0.2s ease 0.6s;
    margin: 0;
    text-align: center; }
    @media screen and (min-width: 800px) {
      .o-our-evolution .ele p {
        opacity: 0.2; } }
    @media screen and (min-width: 992px) {
      .o-our-evolution .ele p {
        font-size: 21px;
        line-height: 35px;
        text-align: left; } }
  .o-our-evolution .ele .mobile-timeline-image {
    margin-top: 15px;
    display: block; }
    @media screen and (min-width: 800px) {
      .o-our-evolution .ele .mobile-timeline-image {
        display: none; } }
  .o-our-evolution .ele.active h6 {
    opacity: 1;
    transition: 0.4s ease 0.8s; }
  .o-our-evolution .ele.active p {
    opacity: 1;
    transition: 0.4s ease 1s; }

.o-our-evolution .timeline-image {
  width: 100%;
  padding-left: 0;
  margin-top: 15px;
  display: none;
  position: relative; }
  @media screen and (min-width: 800px) {
    .o-our-evolution .timeline-image {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 36%;
      margin-left: 4%;
      margin-top: 0; } }

.o-our-evolution .time-image {
  position: absolute;
  opacity: 0;
  transform: translate3d(0, 0, 0);
  transition: 0.2s ease 0.8s;
  transform: translate3d(20px, 0, 0);
  left: 0;
  top: 0;
  right: 0;
  width: auto;
  margin: auto; }
  .o-our-evolution .time-image.active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: 0.6s ease 1.4s; }

@media screen and (min-width: 1200px) {
  .o-partner-need .container {
    max-width: 1000px; } }

@media screen and (min-width: 992px) {
  .o-partner-need .about-us__heading {
    margin-bottom: 50px; } }

.o-partner-need__box {
  background-color: #fff;
  border: 1px solid #e4e6e9;
  border-radius: 6px 6px 0 0;
  box-shadow: 0px 4px 40px 0 rgba(0, 0, 0, 0.12);
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 25px; }
  @media screen and (min-width: 992px) {
    .o-partner-need__box {
      padding: 35px 25px 20px; } }
  @media screen and (min-width: 1180px) {
    .o-partner-need__box {
      padding: 43px 50px 50px;
      display: flex;
      flex-direction: column; } }
  .o-partner-need__box i {
    display: inline-block;
    height: 50px; }
  .o-partner-need__box--green {
    border-bottom: 8px solid #3cc065; }
  .o-partner-need__box--blue {
    border-bottom: 8px solid #4492dc; }

.o-partner-need__boxheading {
  font-family: "Poppins";
  font-size: 28px;
  line-height: 33px;
  font-weight: 700;
  text-align: center;
  margin: 30px 0 15px;
  text-align: center;
  color: #333; }
  @media screen and (min-width: 992px) {
    .o-partner-need__boxheading {
      font-size: 31px;
      line-height: 36px; } }

.o-partner-need__boxcontent {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 27px;
  color: #333; }

.o-partner-need__boxbtn {
  max-width: 100%;
  width: 100%;
  padding: 15px;
  margin-top: auto;
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 600;
  border: 2px solid #ff5800; }
  @media screen and (min-width: 992px) {
    .o-partner-need__boxbtn {
      max-width: 250px; } }

.o-partner-need__btn {
  width: 100%;
  max-width: 100%;
  margin: 27px auto 0;
  text-transform: capitalize; }
  @media screen and (min-width: 992px) {
    .o-partner-need__btn {
      max-width: 275px; } }

.mb-30 {
  margin-bottom: 15px !important; }
  @media screen and (min-width: 768px) {
    .mb-30 {
      margin-bottom: 30px !important; } }

.o-why-capital {
  position: relative;
  background-color: #355bbf; }
  .o-why-capital .about-us__heading {
    color: #fff;
    margin-bottom: 15px;
    text-align: center; }
  .o-why-capital__content {
    color: #fff;
    font-size: 21px;
    line-height: 27px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 45px; }
  .o-why-capital__box {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 25px 22px;
    text-align: center; }
    .o-why-capital__box i {
      display: inline-block;
      height: 50px; }
    @media screen and (min-width: 992px) {
      .o-why-capital__box {
        text-align: left; } }
  .o-why-capital__title {
    font-family: "Poppins";
    font-size: 21px;
    line-height: 26px;
    color: #333;
    margin: 18px 0 7px;
    font-weight: 600; }
  .o-why-capital__boxcontent {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 22px;
    color: #333;
    margin: 0; }
  .o-why-capital__btn {
    max-width: 100%;
    width: 100%;
    text-transform: capitalize;
    padding: 15px;
    margin: 11px auto 0; }
    @media screen and (min-width: 992px) {
      .o-why-capital__btn {
        max-width: 358px; } }

.o-people__topcontent {
  font-family: "Poppins";
  font-size: 20px;
  line-height: 30px;
  color: #333;
  margin: 25px auto;
  font-weight: 500;
  text-align: center;
  width: 100%; }
  @media screen and (min-width: 992px) {
    .o-people__topcontent {
      width: 92%;
      font-size: 25px;
      line-height: 37px; } }

.o-people__btn {
  padding: 15px;
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 600;
  max-width: 100%;
  width: 100%;
  margin: auto; }
  @media screen and (min-width: 992px) {
    .o-people__btn {
      max-width: 311px;
      margin: 30px auto 0; } }

.o-people .team {
  position: relative;
  border-color: #898989 !important;
  border-radius: 0 !important;
  font-family: "Poppins";
  font-size: 15px;
  min-height: 348px; }
  .o-people .team:hover {
    border-color: #fff !important; }
  .o-people .team span {
    font-size: 22px;
    font-weight: 500; }

.o-people .owl-dots {
  margin-top: 30px; }

.o-people .splide__pagination {
  position: relative;
  margin: 30px 0 20px; }
  .o-people .splide__pagination .splide__pagination__page.is-active {
    background: #ff5800; }

.o-testimonial {
  background-color: #f9f9f9; }
  .o-testimonial__heading {
    font-family: "Poppins";
    font-size: 31px;
    line-height: 38px;
    color: #262e36;
    text-align: center;
    font-weight: 700;
    margin-bottom: 35px; }
    @media screen and (min-width: 992px) {
      .o-testimonial__heading {
        font-size: 51px;
        line-height: 56px; } }
  .o-testimonial .splide__arrows {
    display: block; }
  .o-testimonial .splide__arrow--prev {
    font-size: 45px !important;
    color: #000 !important;
    background-color: #fff !important;
    width: 50px;
    height: 50px;
    border: 1px solid #3cc065 !important;
    line-height: 0;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 5px;
    font-weight: 300 !important;
    bottom: -125px;
    top: inherit;
    left: 35%; }
    @media screen and (min-width: 992px) {
      .o-testimonial .splide__arrow--prev {
        left: 40%; } }
    @media screen and (min-width: 1100px) {
      .o-testimonial .splide__arrow--prev {
        left: 45%; } }
    .o-testimonial .splide__arrow--prev svg {
      position: relative;
      top: 0px;
      left: 0px;
      height: 20px;
      line-height: 0;
      width: 20px; }
    .o-testimonial .splide__arrow--prev:hover {
      color: #fff !important;
      background-color: #3cc065 !important; }
      .o-testimonial .splide__arrow--prev:hover svg path {
        fill: #fff; }
  .o-testimonial .splide__arrow--next {
    font-size: 45px !important;
    color: #000 !important;
    background-color: #fff !important;
    width: 50px;
    height: 50px;
    border: 1px solid #3cc065 !important;
    line-height: 0;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 5px;
    font-weight: 300 !important;
    bottom: -125px;
    top: inherit;
    right: 35%; }
    @media screen and (min-width: 992px) {
      .o-testimonial .splide__arrow--next {
        right: 40%; } }
    @media screen and (min-width: 1100px) {
      .o-testimonial .splide__arrow--next {
        right: 45%; } }
    .o-testimonial .splide__arrow--next svg {
      position: relative;
      top: 0px;
      right: 0px;
      height: 20px;
      line-height: 0;
      width: 20px; }
    .o-testimonial .splide__arrow--next:hover {
      color: #fff !important;
      background-color: #3cc065 !important; }
      .o-testimonial .splide__arrow--next:hover svg path {
        fill: #fff; }
  .o-testimonial__list .owl-nav {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-top: 20px; }
    @media screen and (min-width: 992px) {
      .o-testimonial__list .owl-nav {
        margin-top: 60px; } }
  .o-testimonial__list .owl-prev {
    font-size: 45px !important;
    color: #000 !important;
    background-color: #fff !important;
    width: 50px;
    height: 50px;
    border: 1px solid #3cc065 !important;
    line-height: 0;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 5px;
    font-weight: 300 !important; }
    .o-testimonial__list .owl-prev span {
      position: relative;
      top: -3px;
      left: -1px;
      height: 50px;
      line-height: 50px; }
    .o-testimonial__list .owl-prev:hover {
      color: #fff !important;
      background-color: #3cc065 !important; }
  .o-testimonial__list .owl-next {
    font-size: 45px !important;
    color: #000 !important;
    background-color: #fff !important;
    width: 50px;
    height: 50px;
    border: 1px solid #3cc065 !important;
    line-height: 0;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 5px;
    font-weight: 300 !important; }
    .o-testimonial__list .owl-next span {
      position: relative;
      top: -3px;
      right: -1px;
      height: 50px;
      line-height: 50px; }
    .o-testimonial__list .owl-next:hover {
      color: #fff !important;
      background-color: #3cc065 !important; }
  .o-testimonial__item {
    max-width: 620px;
    width: 95%;
    margin: 0; }
    @media screen and (min-width: 992px) {
      .o-testimonial__item {
        margin: auto;
        background-color: #fff;
        box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.07); } }
  .o-testimonial__itemimage {
    width: 100%;
    display: block;
    height: 300px; }
    @media screen and (min-width: 992px) {
      .o-testimonial__itemimage {
        height: 435px; } }
    .o-testimonial__itemimage img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover; }
  .o-testimonial__itemcontent {
    padding: 20px;
    min-height: auto;
    border: 1px solid #e1e1e1;
    border-top: none;
    display: flex;
    flex-direction: column;
    height: 100%; }
    @media screen and (min-width: 992px) {
      .o-testimonial__itemcontent {
        padding: 35px 25px 35px 42px;
        height: auto;
        min-height: 317px; } }
  .o-testimonial__itemtitle {
    font-family: "Poppins";
    font-size: 25px;
    line-height: 32px;
    color: #333;
    font-weight: 700;
    margin-bottom: 15px; }
    @media screen and (min-width: 992px) {
      .o-testimonial__itemtitle {
        font-size: 30px;
        line-height: 40px; } }
  .o-testimonial__itemtech {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 26px;
    color: #333;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .o-testimonial__itemtech {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 25px; } }
    .o-testimonial__itemtech span {
      color: #4492dc; }
  .o-testimonial__itembtn {
    font-family: "Poppins";
    color: #4492dc;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    margin-top: auto; }
    .o-testimonial__itembtn:hover {
      color: #ff5800; }
      .o-testimonial__itembtn:hover svg {
        transform: translate(5px, 0); }
        .o-testimonial__itembtn:hover svg g {
          fill: #ff5800; }
  .o-testimonial__more {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 26px;
    color: #333;
    font-weight: 500;
    text-decoration: underline;
    margin-top: 40px;
    display: inline-block; }
    .o-testimonial__more:hover {
      color: #ff5800;
      text-decoration: underline; }
    .o-testimonial__more--splidebtn {
      margin-top: 130px; }
  .o-testimonial__more--about {
    margin-top: 140px; }

.bt-ass {
  border-top: 1px solid #e6e6e6; }

.o-company-awards__heading {
  font-family: "Poppins";
  font-size: 31px;
  line-height: 38px;
  color: #262e36;
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .o-company-awards__heading {
      font-size: 51px;
      line-height: 56px;
      margin-bottom: 35px; } }

.o-company-awards__navtab {
  border: none;
  justify-content: center;
  align-items: center;
  margin-bottom: 27px; }
  @media screen and (min-width: 992px) {
    .o-company-awards__navtab {
      margin-bottom: 39px; } }
  .o-company-awards__navtab li {
    position: relative;
    max-width: 100px;
    width: 100%; }
    @media screen and (min-width: 992px) {
      .o-company-awards__navtab li {
        max-width: 130px; } }
    .o-company-awards__navtab li a {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 30px;
      color: #898989;
      padding: 0 10px 20px;
      border-bottom: 1px solid #d9d9d9;
      display: block;
      width: 100%;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .o-company-awards__navtab li a {
          font-size: 21px;
          line-height: 40px; } }
    .o-company-awards__navtab li.active a {
      color: #4492dc; }
    .o-company-awards__navtab li.active:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 5px;
      background-color: #4492dc;
      bottom: 0;
      left: 0;
      right: 0; }

.o-company-awards__tab-content {
  min-height: 395px; }

.o-company-awards .o-company-awards__list:before {
  position: absolute;
  content: "";
  width: 25%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  background: linear-gradient(90deg, white 30%, rgba(255, 255, 255, 0) 100%);
  z-index: 9;
  display: none; }
  @media screen and (min-width: 992px) {
    .o-company-awards .o-company-awards__list:before {
      width: 10%;
      display: block; } }
  @media screen and (min-width: 1600px) {
    .o-company-awards .o-company-awards__list:before {
      width: 15%; } }
  @media screen and (min-width: 1800px) {
    .o-company-awards .o-company-awards__list:before {
      width: 25%; } }

.o-company-awards .o-company-awards__list:after {
  position: absolute;
  content: "";
  width: 25%;
  height: 100%;
  right: 0;
  top: 0;
  background: white;
  background: linear-gradient(270deg, white 30%, rgba(255, 255, 255, 0) 100%);
  z-index: 9;
  display: none; }
  @media screen and (min-width: 992px) {
    .o-company-awards .o-company-awards__list:after {
      width: 10%;
      display: block; } }
  @media screen and (min-width: 1600px) {
    .o-company-awards .o-company-awards__list:after {
      width: 15%; } }
  @media screen and (min-width: 1800px) {
    .o-company-awards .o-company-awards__list:after {
      width: 25%; } }

.o-company-awards__list {
  position: relative; }
  .o-company-awards__list .splide__arrows {
    display: block !important;
    align-items: center;
    justify-content: center;
    margin: 32px 0 5px;
    position: absolute;
    bottom: -42px;
    width: 100%; }
    .o-company-awards__list .splide__arrows svg {
      display: none; }
  .o-company-awards__list .splide__arrow--prev {
    opacity: 1;
    position: absolute;
    bottom: 0;
    left: 36%;
    width: 31px;
    height: 12px;
    background: url('data:image/svg+xml,<svg width="31px" height="12px" viewBox="0 0 31 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Vector-279" fill="%23D9D9D9" fill-rule="nonzero"><path d="M0.21966975,4.99264075 C-0.07322325,5.28553075 -0.07322325,5.76041075 0.21966975,6.05330075 L4.99263975,10.8262708 C5.28552975,11.1191708 5.76040975,11.1191708 6.05329975,10.8262708 C6.34618975,10.5333708 6.34618975,10.0584708 6.05329975,9.76557075 L1.81065975,5.52297075 L6.05329975,1.28033075 C6.34618975,0.98744075 6.34618975,0.51256275 6.05329975,0.21966975 C5.76040975,-0.07322325 5.28552975,-0.07322325 4.99263975,0.21966975 L0.21966975,4.99264075 Z M0.74999975,6.27297075 L30.7499998,6.27297075 L30.7499998,4.77297075 L0.74999975,4.77297075 L0.74999975,6.27297075 Z" id="Shape"></path></g></g></svg>') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 100% !important;
    margin: 0 13px; }
    @media screen and (min-width: 992px) {
      .o-company-awards__list .splide__arrow--prev {
        left: 46%; } }
    @media screen and (min-width: 1300px) {
      .o-company-awards__list .splide__arrow--prev {
        left: 46.5%; } }
    .o-company-awards__list .splide__arrow--prev:hover {
      background: url('data:image/svg+xml,<svg width="31px" height="12px" viewBox="0 0 31 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Vector-279" fill="%23333333" fill-rule="nonzero"><path d="M0.21966975,4.99264075 C-0.07322325,5.28553075 -0.07322325,5.76041075 0.21966975,6.05330075 L4.99263975,10.8262708 C5.28552975,11.1191708 5.76040975,11.1191708 6.05329975,10.8262708 C6.34618975,10.5333708 6.34618975,10.0584708 6.05329975,9.76557075 L1.81065975,5.52297075 L6.05329975,1.28033075 C6.34618975,0.98744075 6.34618975,0.51256275 6.05329975,0.21966975 C5.76040975,-0.07322325 5.28552975,-0.07322325 4.99263975,0.21966975 L0.21966975,4.99264075 Z M0.74999975,6.27297075 L30.7499998,6.27297075 L30.7499998,4.77297075 L0.74999975,4.77297075 L0.74999975,6.27297075 Z" id="Shape"></path></g></g></svg>') !important; }
  .o-company-awards__list .splide__arrow--next {
    opacity: 1;
    position: absolute;
    bottom: 0;
    right: 36%;
    width: 31px;
    height: 12px;
    background: url('data:image/svg+xml,<svg width="31px" height="12px" viewBox="0 0 31 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Vector-280" transform="translate(0.000000, 0.477029)" fill="%23D9D9D9" fill-rule="nonzero"><path d="M30.5303,6.05330075 C30.8232,5.76041075 30.8232,5.28553075 30.5303,4.99264075 L25.7574,0.21966975 C25.4645,-0.07322325 24.9896,-0.07322325 24.6967,0.21966975 C24.4038,0.51256375 24.4038,0.98744075 24.6967,1.28033075 L28.9393,5.52297075 L24.6967,9.76557075 C24.4038,10.0584708 24.4038,10.5333708 24.6967,10.8262708 C24.9896,11.1191708 25.4645,11.1191708 25.7574,10.8262708 L30.5303,6.05330075 Z M0,6.27297075 L30,6.27297075 L30,4.77297075 L0,4.77297075 L0,6.27297075 Z" id="Shape"></path></g></g></svg>') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 100% !important;
    margin: 0 13px; }
    @media screen and (min-width: 992px) {
      .o-company-awards__list .splide__arrow--next {
        right: 46%; } }
    @media screen and (min-width: 1300px) {
      .o-company-awards__list .splide__arrow--next {
        right: 46.5%; } }
    .o-company-awards__list .splide__arrow--next:hover {
      background: url('data:image/svg+xml,<svg width="31px" height="12px" viewBox="0 0 31 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Vector-280" transform="translate(0.000000, 0.477029)" fill="%23333333" fill-rule="nonzero"><path d="M30.5303,6.05330075 C30.8232,5.76041075 30.8232,5.28553075 30.5303,4.99264075 L25.7574,0.21966975 C25.4645,-0.07322325 24.9896,-0.07322325 24.6967,0.21966975 C24.4038,0.51256375 24.4038,0.98744075 24.6967,1.28033075 L28.9393,5.52297075 L24.6967,9.76557075 C24.4038,10.0584708 24.4038,10.5333708 24.6967,10.8262708 C24.9896,11.1191708 25.4645,11.1191708 25.7574,10.8262708 L30.5303,6.05330075 Z M0,6.27297075 L30,6.27297075 L30,4.77297075 L0,4.77297075 L0,6.27297075 Z" id="Shape"></path></g></g></svg>') !important; }

.o-company-awards__item {
  max-width: 375px;
  height: 351px;
  border: 1px solid #f6c7a3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 5px;
  flex-direction: column;
  background-color: #fff;
  transition: 0.3s all ease-in-out; }
  .o-company-awards__item i {
    height: 144px;
    display: inline-flex;
    margin-bottom: 20px;
    min-width: 120px;
    width: auto;
    align-items: center;
    justify-content: center; }
    .o-company-awards__item i img {
      width: auto;
      max-height: 100%; }
  .o-company-awards__item p {
    font-family: "Poppins";
    font-size: 21px;
    line-height: 28px;
    font-weight: 500;
    margin: 0;
    text-align: center; }
  .o-company-awards__item:hover {
    border-color: #e1263b; }

.o-company-awards__btn {
  width: 100%;
  max-width: 100%;
  padding: 15px;
  margin: 20px auto 0;
  text-transform: capitalize; }
  @media screen and (min-width: 992px) {
    .o-company-awards__btn {
      max-width: 363px;
      margin: 30px auto 0; } }

.o-social-responsible {
  position: relative;
  background: #001a36;
  background-size: auto 100%;
  overflow: hidden;
  width: 100%; }
  @media screen and (min-width: 992px) {
    .o-social-responsible {
      background: #001a36 url(/images/new-company-profile/social-background.png) no-repeat right bottom; } }
  .o-social-responsible:before {
    position: absolute;
    content: "";
    width: 420px;
    height: 400px;
    background: url(/images/new-company-profile/left-blue.svg) no-repeat bottom left;
    background-size: 100%;
    bottom: 0;
    left: 0;
    display: none; }
    @media screen and (min-width: 992px) {
      .o-social-responsible:before {
        display: block; } }
  .o-social-responsible__topgreen {
    position: relative; }
    .o-social-responsible__topgreen:before {
      position: absolute;
      content: "";
      width: 450px;
      height: 460px;
      background: url(/images/new-company-profile/top-green.svg) no-repeat top left;
      background-size: 100%;
      top: -60px;
      left: -10%;
      display: none; }
      @media screen and (min-width: 992px) {
        .o-social-responsible__topgreen:before {
          display: block; } }
  .o-social-responsible__heading {
    font-family: "Poppins";
    color: #fff;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-social-responsible__heading {
        font-size: 40px;
        line-height: 50px;
        text-align: left; } }
  .o-social-responsible__content {
    font-family: "Poppins";
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 25px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-social-responsible__content {
        text-align: left;
        font-size: 25px;
        line-height: 37px; } }
    .o-social-responsible__content span {
      color: #3cc065; }
  .o-social-responsible__btn {
    margin: auto;
    text-transform: capitalize;
    font-size: 17px;
    padding: 15px;
    max-width: 100%;
    width: 100%; }
    @media screen and (min-width: 992px) {
      .o-social-responsible__btn {
        max-width: 290px;
        margin: 0; } }

.btn-center-block1 {
  width: 100%;
  margin: auto;
  padding: 0 15px; }
  @media screen and (min-width: 768px) {
    .btn-center-block1 {
      max-width: 363px; } }

/* Section Footer */
/* ANCHOR - Section Footer */
.section-footer {
  background-color: #04254a;
  padding: 40px 0px 30px;
  overflow: hidden;
  position: relative; }
  @media screen and (min-width: 992px) {
    .section-footer {
      padding: 50px 0 30px; } }
  .section-footer__navarea {
    padding-bottom: 30px; }
    @media (min-width: 767px) {
      .section-footer__navarea {
        padding-bottom: 40px; } }
  .section-footer__navbar-heading {
    color: #fff;
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    font-family: "Poppins";
    margin-bottom: 15px; }
    .section-footer__navbar-heading::after {
      content: " ";
      display: block;
      background: #3cc065;
      height: 2px;
      width: 31px;
      position: relative;
      margin-top: 5px; }
  .section-footer__two-navbar-list {
    display: grid;
    grid-template-columns: 1fr 1fr; }
  .section-footer__a-tag {
    color: #c4e0f5;
    font-size: 15px;
    font-weight: 400;
    font-family: "Poppins";
    line-height: 28px;
    transition: 0.3s all ease-in-out; }
    .section-footer__a-tag:hover {
      color: #fff; }
  .section-footer__divider {
    background-color: #15467e;
    height: 1px;
    width: 100%;
    margin-bottom: 35px; }
  .section-footer__copy-right-details {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #aec7da;
    font-family: "Poppins";
    margin-bottom: 0px;
    margin-bottom: 15px; }
    @media (min-width: 767px) {
      .section-footer__copy-right-details {
        margin-bottom: 0px; } }
    @media screen and (min-width: 992px) {
      .section-footer__copy-right-details {
        margin-bottom: 30px; } }
  .section-footer__social-list {
    display: flex;
    gap: 5px;
    position: relative;
    z-index: 1; }
  .section-footer__align-cener {
    display: flex;
    align-items: center; }

/* ANCHOR - New thank you */
.thank-contact {
  text-align: center;
  background: #EDFAFF;
  padding: 40px 0; }
  @media screen and (min-width: 992px) {
    .thank-contact {
      padding: 60px 0; } }
  .thank-contact__heading {
    font-family: "Poppins";
    font-weight: 700;
    color: #000; }
    @media screen and (min-width: 992px) {
      .thank-contact__heading {
        font-size: 40px;
        line-height: 55px; } }
  .thank-contact__sub-heading {
    font-family: "Poppins";
    font-weight: 600;
    color: #4492DC;
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .thank-contact__sub-heading {
        font-size: 25px;
        line-height: 29px; } }

.discover-services {
  position: relative;
  padding-bottom: 25px; }
  .discover-services:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: #EDFAFF;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #C2D7DF; }
  .discover-services__wrapper {
    border: 3px solid #84A5F9;
    border-radius: 10px;
    padding: 30px 15px;
    position: relative;
    background: #fff;
    z-index: 99; }
    @media screen and (min-width: 992px) {
      .discover-services__wrapper {
        padding: 60px 0 50px; } }
  .discover-services__heading {
    font-family: "Poppins";
    font-weight: 700;
    color: #000;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .discover-services__heading {
        font-size: 31px;
        line-height: 33px; } }
  .discover-services__sub-heading {
    font-family: "Poppins";
    font-weight: 500;
    color: #333;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .discover-services__sub-heading {
        font-size: 21px;
        line-height: 27px; } }
  .discover-services__btn-list {
    margin-top: 25px; }
    @media screen and (min-width: 992px) {
      .discover-services__btn-list {
        margin-top: 35px; } }
    .discover-services__btn-list .banner-technologies__list-link {
      font-family: "Poppins";
      z-index: 1;
      position: relative;
      background: #fff;
      border-radius: 30px;
      color: #333;
      display: inline-block;
      padding: 8px 10px;
      font-size: 16px !important;
      font-weight: 600 !important; }
      @media screen and (min-width: 992px) {
        .discover-services__btn-list .banner-technologies__list-link {
          font-size: 26px !important;
          padding: 8px 30px; } }
    .discover-services__btn-list .new-home-banner__list-item {
      margin: 0 1px 3px; }
      @media screen and (min-width: 992px) {
        .discover-services__btn-list .new-home-banner__list-item {
          margin: 0 3px 10px; } }
      @media screen and (min-width: 992px) {
        .discover-services__btn-list .new-home-banner__list-item:hover svg {
          transform: translateX(10px); } }
      .discover-services__btn-list .new-home-banner__list-item:hover path {
        transform: none;
        fill: #35B15C; }
      .discover-services__btn-list .new-home-banner__list-item:hover .banner-technologies__list-link {
        color: #35B15C; }

.story {
  padding-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .story {
      padding-bottom: 40px; } }
  .story__dive {
    background: #355BBF;
    padding: 30px 20px;
    border-radius: 10px; }
    @media screen and (min-width: 992px) {
      .story__dive {
        padding: 60px 50px;
        height: 100%; } }
    .story__dive h2 {
      font-family: "Poppins";
      color: #fff;
      font-weight: 700;
      margin-bottom: 15px; }
      @media screen and (min-width: 992px) {
        .story__dive h2 {
          font-size: 31px;
          line-height: 33px; } }
    .story__dive p {
      font-family: "Poppins";
      color: #fff;
      font-weight: 500; }
      @media screen and (min-width: 992px) {
        .story__dive p {
          font-size: 21px;
          line-height: 30px; } }
  .story__btn {
    font-family: "Poppins";
    background: transparent;
    border: 2px solid #fff;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    padding: 15px 30px;
    border-radius: 5px;
    display: inline-block;
    margin-top: 25px; }
    .story__btn svg {
      margin-left: 10px; }
    .story__btn:hover {
      color: #fff;
      border-color: #FF5800;
      background: #FF5800; }
      .story__btn:hover svg {
        transform: translateX(10px); }
    .story__btn--reverse {
      border-color: #FF5800;
      color: #333; }
      .story__btn--reverse:hover path {
        fill: #fff; }
    .story__btn--white {
      color: #fff; }
  .story__uncover {
    padding: 30px 20px;
    border: 1px solid #84A5F9;
    border-radius: 10px;
    height: auto; }
    @media screen and (min-width: 992px) {
      .story__uncover {
        height: 100%;
        padding: 60px 50px;
        margin-top: 0; } }
    .story__uncover h2 {
      font-family: "Poppins";
      color: #000;
      font-weight: 700;
      margin-bottom: 15px; }
      @media screen and (min-width: 992px) {
        .story__uncover h2 {
          font-size: 31px;
          line-height: 36px; } }
    .story__uncover p {
      font-family: "Poppins";
      font-size: 21px;
      line-height: 30px;
      font-weight: 500;
      color: #333;
      margin-bottom: 0; }
  @media screen and (min-width: 992px) {
    .story__box-head {
      display: flex;
      align-items: flex-start; } }
  .story__box-head i {
    display: inline-block;
    flex: 0 0 auto;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .story__box-head i {
        margin-right: 30px;
        margin-bottom: 0; } }
  .story__insights {
    padding: 30px 20px;
    border: 1px solid #84A5F9;
    border-radius: 10px;
    height: auto; }
    @media screen and (min-width: 992px) {
      .story__insights {
        height: 100%;
        padding: 60px 50px;
        display: flex;
        align-items: flex-start;
        margin-top: 0; } }
    .story__insights i {
      flex: 0 0 auto;
      margin-right: 30px;
      margin-bottom: 20px; }
      @media screen and (min-width: 992px) {
        .story__insights i {
          margin-bottom: 0; } }
    .story__insights h2 {
      color: #000;
      font-weight: 700;
      margin-bottom: 15px; }
      @media screen and (min-width: 992px) {
        .story__insights h2 {
          font-size: 31px;
          line-height: 36px; } }
    .story__insights p {
      font-family: "Poppins";
      font-size: 21px;
      line-height: 30px;
      font-weight: 500;
      color: #333;
      margin-bottom: 0; }
    .story__insights .story__box-head {
      display: block; }
  .story__business {
    background: url(/images/new-thank-you/business.jpg) no-repeat 0 0/cover;
    border-radius: 10px;
    padding: 30px 20px; }
    @media screen and (min-width: 992px) {
      .story__business {
        padding: 60px 50px;
        height: 100%;
        margin-top: 0; } }
    .story__business h2 {
      font-family: "Poppins";
      color: #fff;
      font-size: 31px;
      line-height: 38px;
      font-weight: 700; }
    .story__business p {
      font-family: "Poppins";
      font-size: 21px;
      line-height: 30px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 0; }
  .story__wrapper {
    text-align: center; }
    @media screen and (min-width: 992px) {
      .story__wrapper {
        text-align: left; } }

.new-thank-you-page .slbWrapOuter {
  height: auto; }

/* ANCHOR - new-case-study */
.scrollable-wrapper {
  margin-top: 70px; }
  .scrollable-wrapper__flickbutton {
    position: fixed;
    right: 10px;
    bottom: 20px;
    z-index: 9999;
    background-color: #fff;
    width: 69px;
    height: 69px;
    border: 1px solid #ff5826;
    border-radius: 50%;
    font-weight: 600;
    font-size: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.2s; }
    @media screen and (min-width: 992px) {
      .scrollable-wrapper__flickbutton {
        display: none; } }
    .scrollable-wrapper__flickbutton.rotate {
      background-color: #ff5826;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); }
      .scrollable-wrapper__flickbutton.rotate .icon-fill {
        fill: #fff; }

body.case-studies-details #myModal1 .modal-dialog {
  background: rgba(0, 0, 0, 0.5); }

.regular {
  position: relative; }
  .regular__spacing {
    padding: 20px 0; }
    @media screen and (min-width: 992px) {
      .regular__spacing {
        padding: 40px 0; } }
  .regular__regular-tittle {
    position: relative;
    font-family: "Poppins";
    font-size: 28px;
    line-height: 44px;
    font-weight: 700;
    padding-bottom: 10px;
    color: #262e36; }
    @media screen and (min-width: 992px) {
      .regular__regular-tittle {
        font-size: 41px;
        line-height: 55px; } }
    .regular__regular-tittle:after {
      position: absolute;
      content: "";
      width: 113px;
      height: 4px;
      background-color: #24cd7c;
      border-radius: 4px;
      bottom: 0;
      left: 0; }
    .regular__regular-tittle--has-secondary-heading {
      margin-bottom: 25px; }
  .regular__deliverable-list li {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px; }
    .regular__deliverable-list li strong {
      font-weight: 600; }
  .regular__deliverable-child-strong {
    display: inline-block;
    margin-bottom: 15px; }
  .regular__deliverable-child-list li {
    position: relative; }
    .regular__deliverable-child-list li:before {
      content: "";
      position: absolute;
      top: 9px;
      left: -15px;
      background: #24cd7c;
      width: 7px;
      height: 7px;
      border-radius: 100%; }
  .regular__child-title {
    font-family: "Poppins";
    font-size: 20px; }
  .regular__deliverable-child-list {
    padding-left: 25px; }
  .regular__regular-content {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    font-weight: 400;
    color: #333333; }
    .regular__regular-content:last-child {
      margin: 0; }
    .regular__regular-content--child-content {
      position: relative;
      padding-left: 20px;
      margin-bottom: 15px; }
      .regular__regular-content--child-content::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        top: 7px;
        left: 0px;
        background: url(/images/new-free-trial/list-icon-small.svg) no-repeat; }
    .regular__regular-content--has-top-list-content {
      margin-top: 20px !important; }
  .regular__regular-list {
    list-style: disc;
    padding-left: 20px; }
  .regular__regular-item {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    font-weight: 400;
    color: #333333; }
  .regular__img {
    margin: 30px 0;
    border-radius: 15px; }
  .regular__slider {
    position: relative;
    margin-top: 35px; }
    .regular__slider .owl-nav {
      position: absolute;
      display: flex !important;
      align-items: center;
      justify-content: flex-end;
      top: -85px;
      right: 0; }
    .regular__slider .owl-prev {
      font-size: 45px !important;
      color: #1c2958 !important;
      background-color: #e1e1e1 !important;
      width: 45px;
      height: 45px;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300 !important; }
      .regular__slider .owl-prev span {
        position: relative;
        height: 45px;
        line-height: 35px;
        left: -1px; }
      .regular__slider .owl-prev:hover {
        background-color: #7feba1 !important; }
      .regular__slider .owl-prev.disabled {
        cursor: auto;
        color: #fff !important;
        background: #f2f2f2 !important; }
    .regular__slider .owl-next {
      font-size: 45px !important;
      color: #1c2958 !important;
      background-color: #e1e1e1 !important;
      width: 45px;
      height: 45px;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300 !important; }
      .regular__slider .owl-next span {
        position: relative;
        height: 45px;
        line-height: 35px;
        left: 2px; }
      .regular__slider .owl-next:hover {
        background-color: #7feba1 !important; }
      .regular__slider .owl-next.disabled {
        cursor: auto;
        color: #fff !important;
        background: #f2f2f2 !important; }
    .regular__slider .splide__arrows {
      display: block;
      position: absolute;
      top: -60px;
      right: 40px; }
      @media screen and (min-width: 992px) {
        .regular__slider .splide__arrows {
          top: -70px; } }
    .regular__slider .splide__arrow {
      position: absolute;
      right: 0;
      width: 45px;
      height: 45px; }
    .regular__slider .splide__arrow--prev {
      right: 15px;
      left: inherit; }
    .regular__slider .splide__arrow--next {
      right: -40px;
      left: inherit; }
  .regular__slider-mod {
    position: relative;
    margin-top: 35px; }
    .regular__slider-mod .splide__arrows {
      display: block;
      position: absolute;
      top: -60px;
      right: 40px; }
      @media screen and (min-width: 992px) {
        .regular__slider-mod .splide__arrows {
          top: -70px; } }
    .regular__slider-mod .splide__arrow {
      position: absolute;
      right: 0;
      width: 45px;
      height: 45px; }
    .regular__slider-mod .splide__arrow--prev {
      right: 15px;
      left: inherit; }
    .regular__slider-mod .splide__arrow--next {
      right: -40px;
      left: inherit; }

.case-study-banner {
  background-color: #05264a;
  position: relative; }
  .case-study-banner:before {
    position: absolute;
    content: "";
    width: 178px;
    height: 148px;
    background-image: url(/images/case-studies-details/case-study-common/banner-left-circle.png);
    background-repeat: no-repeat;
    background-position: 100%;
    left: 0;
    bottom: 0; }
  .case-study-banner:after {
    position: absolute;
    content: "";
    width: 214px;
    height: 185px;
    background-image: url(/images/case-studies-details/case-study-common/banner-right-circle.png);
    background-repeat: no-repeat;
    background-position: 100%;
    right: 0;
    top: 0; }
  .case-study-banner__container {
    position: relative; }
  .case-study-banner__wrapper {
    position: absolute;
    top: 120px;
    width: 320px; }
  .case-study-banner__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .case-study-banner__spacing {
        padding: 57px 0 38px; } }
  .case-study-banner__bannertag {
    font-family: "Poppins";
    color: #fff;
    text-align: center;
    font-size: 15px;
    line-height: 34px;
    text-indent: -5px;
    font-weight: 600;
    background: url(/images/case-studies-details/case-study-common/ribbon.svg);
    text-transform: uppercase;
    position: relative;
    display: block;
    width: 149px;
    height: 34px;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%; }
    @media screen and (min-width: 992px) {
      .case-study-banner__bannertag {
        margin: 0;
        font-size: 16px; } }
    @media screen and (min-width: 992px) {
      .case-study-banner__bannertag {
        font-size: 18px; } }
  .case-study-banner__bannertittle {
    font-family: "Poppins";
    color: #fff;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    margin: 15px 0 0;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .case-study-banner__bannertittle {
        text-align: left;
        font-size: 40px;
        line-height: 50px; } }
  .case-study-banner__btnarea {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px; }
    @media screen and (min-width: 1590px) {
      .case-study-banner__btnarea {
        display: none; } }
    .case-study-banner__btnarea .case-study-banner__stickybtn {
      color: #fff;
      width: 100%;
      display: flex;
      margin: 0 0 15px;
      background-color: #ff5800; }
      @media screen and (min-width: 767px) {
        .case-study-banner__btnarea .case-study-banner__stickybtn {
          width: 48%;
          margin: 0; } }
      .case-study-banner__btnarea .case-study-banner__stickybtn:hover {
        background-color: #fff; }
    .case-study-banner__btnarea .case-study-banner__discussbtn {
      max-width: 100%;
      font-size: 16px;
      padding: 15px; }
      @media screen and (min-width: 767px) {
        .case-study-banner__btnarea .case-study-banner__discussbtn {
          max-width: 48%;
          font-size: 18px; } }
  .case-study-banner__sticky {
    position: absolute;
    max-width: 320px;
    width: 100%;
    background: #fff;
    padding: 12px 10px 12px;
    border-radius: 10px;
    border: 1px solid #a2c3ee;
    box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.01);
    z-index: 99; }
  .case-study-banner__stickylist {
    position: relative; }
  .case-study-banner__stickylistpadding {
    width: 100%;
    padding: 0 26px 0; }
  .case-study-banner__listtittle {
    font-family: "Poppins";
    color: #252424;
    font-size: 21px;
    line-height: 50px;
    font-weight: 600;
    border-bottom: 1px solid #bebebe; }
  .case-study-banner__listul {
    list-style: disc;
    padding-left: 20px;
    margin-top: 17px; }
  .case-study-banner__listitem {
    margin-bottom: 10px;
    line-height: 20px; }
    .case-study-banner__listitem::marker {
      color: #4492dc;
      font-size: 22px; }
  .case-study-banner__stickyitem {
    font-family: "Poppins";
    color: #333333;
    font-size: 15px;
    line-height: 25px;
    position: relative;
    top: -3px; }
    .case-study-banner__stickyitem:hover {
      color: #4492dc;
      font-weight: 600; }
    .case-study-banner__stickyitem.active {
      color: #4492dc;
      font-weight: 600; }
  .case-study-banner__stickybtn {
    border-radius: 5px;
    border: 1.5px solid #ff5800;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    display: none;
    margin: 0; }
    @media screen and (min-width: 1590px) {
      .case-study-banner__stickybtn {
        display: block; } }
    .case-study-banner__stickybtn i {
      margin-right: 7px; }
    .case-study-banner__stickybtn:hover {
      color: #ff5800; }
  .case-study-banner__stickydiscuss {
    background: #15a241;
    border-radius: 8px;
    padding: 30px 27px;
    position: relative;
    display: none;
    margin-top: 15px; }
    @media screen and (min-width: 1590px) {
      .case-study-banner__stickydiscuss {
        display: block; } }
    .case-study-banner__stickydiscuss:after {
      position: absolute;
      content: "";
      width: 93px;
      height: 93px;
      background-image: url(/images/case-studies-details/case-study-common/require-circle.png);
      background-repeat: no-repeat;
      background-position: 100%;
      right: 0;
      bottom: 0; }
  .case-study-banner__discusstittle {
    font-family: "Poppins";
    color: #fff;
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    margin-bottom: 10px; }
  .case-study-banner__discussubtittle {
    font-family: "Poppins";
    color: #fff;
    font-size: 13px;
    line-height: 19px;
    font-weight: 400;
    margin-bottom: 30px; }
  .case-study-banner__discussbtn {
    font-family: "Plus Jakarta Sans", sans-serif;
    display: block;
    border-radius: 5px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    border: 2px solid;
    padding: 7px;
    max-width: 210px;
    width: 100%; }
    .case-study-banner__discussbtn:hover {
      color: #ff5800;
      background-color: #fff; }
      .case-study-banner__discussbtn:hover path {
        fill: #ff5800; }

.case-study-client {
  background-color: #e3efff; }
  .case-study-client__spacing {
    padding: 30px 0;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .case-study-client__spacing {
        padding: 60px 0 28px;
        margin-bottom: 0; } }
  .case-study-client__tittle {
    font-family: "Poppins";
    font-size: 28px;
    line-height: 44px;
    font-weight: 700;
    color: #262e36;
    padding-bottom: 10px; }
    @media screen and (min-width: 992px) {
      .case-study-client__tittle {
        font-size: 35px;
        line-height: 55px; } }
    .case-study-client__tittle:after {
      position: absolute;
      content: "";
      width: 113px;
      height: 4px;
      background-color: #24cd7c;
      border-radius: 4px;
      bottom: 0;
      left: 0; }
  .case-study-client__content {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    color: #262e36; }
  .case-study-client__techbox {
    margin-bottom: 20px; }
  .case-study-client__techtittle {
    position: relative;
    font-family: "Poppins";
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
    color: #262e36; }
    .case-study-client__techtittle:after {
      position: absolute;
      content: "";
      width: 30px;
      height: 2px;
      background-color: #24cd7c;
      border-radius: 4px;
      bottom: 0;
      left: 0; }
  .case-study-client__techcontent {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    color: #262e36;
    margin: 0; }

.service-section {
  background-color: #3766b0;
  color: #fff;
  padding: 20px;
  border-radius: 20px; }
  @media screen and (min-width: 767px) {
    .service-section {
      padding: 20px 30px;
      border-radius: 40px; } }
  @media screen and (min-width: 992px) {
    .service-section {
      padding: 40px 60px; } }
  .service-section__tittle {
    position: relative;
    font-family: "Poppins";
    font-size: 25px;
    line-height: 35px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .service-section__tittle {
        font-size: 30px;
        line-height: 55px; } }
    .service-section__tittle:after {
      position: absolute;
      content: "";
      width: 113px;
      height: 4px;
      background-color: #24cd7c;
      border-radius: 4px;
      bottom: 0;
      left: 0; }
  .service-section__anchor {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px; }
    .service-section__anchor i {
      margin-right: 10px; }
    .service-section__anchor a {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      text-decoration: underline;
      color: #fff; }
      @media screen and (min-width: 992px) {
        .service-section__anchor a {
          font-size: 24px;
          line-height: 25px; } }
      .service-section__anchor a:hover {
        color: #24cd7c; }

.technology-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px; }
  .technology-list__items {
    margin: 10px;
    text-align: center;
    max-width: 150px; }
    @media screen and (min-width: 767px) {
      .technology-list__items {
        margin-right: 25px;
        margin-bottom: 12px; } }
    .technology-list__items span {
      font-family: "Poppins";
      font-size: 15px;
      line-height: 22px;
      color: #333333; }
    .technology-list__items:hover .technology-list__item {
      box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.1); }
  .technology-list__item {
    width: 150px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d6e3f0;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: #fff;
    box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.07);
    transition: all 0.5s; }

.result-block {
  position: relative;
  width: 100%;
  display: flex;
  margin-bottom: 30px; }
  .result-block:last-child {
    margin: 0; }
  .result-block i {
    max-width: 43px;
    margin-right: 17px;
    width: 100%; }
  .result-block h4 {
    font-family: "Poppins";
    font-size: 24px;
    line-height: 30px;
    color: #333333; }
  .result-block p {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    color: #333333;
    margin: 0; }

.bottom-download {
  background-color: #f3f6fa;
  text-align: center; }
  @media screen and (min-width: 767px) {
    .bottom-download {
      text-align: left; } }
  .bottom-download__spacing {
    padding: 42px 0; }
  .bottom-download h4 {
    font-family: "Poppins";
    font-size: 25px;
    line-height: 35px;
    color: #262e36;
    font-weight: 700;
    margin: 15px 0;
    display: block; }
    @media screen and (min-width: 767px) {
      .bottom-download h4 {
        font-size: 31px;
        line-height: 44px;
        margin: 0; } }
  .bottom-download__btn {
    font-family: "Poppins";
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff5800;
    width: 252px;
    height: 60px;
    border: 1px solid #ff5800;
    border-radius: 5px;
    font-size: 17px;
    color: #fff;
    font-weight: 600;
    margin: auto; }
    @media screen and (min-width: 767px) {
      .bottom-download__btn {
        margin: 0; } }
    .bottom-download__btn svg {
      margin-left: 10px; }
    .bottom-download__btn:hover {
      color: #ff5800;
      background-color: #f3f6fa; }
      .bottom-download__btn:hover path {
        fill: #ff5800; }

.more-case-study {
  background-color: #0a1d4d; }
  .more-case-study__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .more-case-study__spacing {
        padding: 60px 0; } }
  .more-case-study__tittle {
    position: relative;
    font-family: "Poppins";
    font-size: 41px;
    line-height: 55px;
    font-weight: 700;
    padding-bottom: 15px;
    margin-bottom: 50px;
    color: #ffffff;
    text-align: center; }
    .more-case-study__tittle:after {
      position: absolute;
      content: "";
      width: 113px;
      height: 4px;
      background-color: #24cd7c;
      border-radius: 4px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
  .more-case-study__list {
    margin-bottom: 20px; }
    .more-case-study__list .owl-nav {
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-top: 42px; }
    .more-case-study__list .owl-prev {
      font-size: 45px !important;
      color: #1c2958 !important;
      background-color: #ffffff !important;
      width: 45px;
      height: 45px;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300 !important; }
      .more-case-study__list .owl-prev span {
        position: relative;
        height: 45px;
        line-height: 35px;
        left: -1px; }
      .more-case-study__list .owl-prev:hover {
        background-color: #3cc065 !important;
        color: #fff !important; }
    .more-case-study__list .owl-next {
      font-size: 45px !important;
      color: #1c2958 !important;
      background-color: #ffffff !important;
      width: 45px;
      height: 45px;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300 !important; }
      .more-case-study__list .owl-next span {
        position: relative;
        height: 45px;
        line-height: 35px;
        left: 2px; }
      .more-case-study__list .owl-next:hover {
        background-color: #3cc065 !important;
        color: #fff !important; }
  .more-case-study__item {
    background: #fff; }
    .more-case-study__item a img {
      height: 259px; }
      @media screen and (min-width: 992px) {
        .more-case-study__item a img {
          width: 100%;
          object-fit: cover; } }
  .more-case-study__itemcontent {
    position: relative;
    padding: 20px 25px 35px 25px;
    min-height: 250px;
    background-color: #fff; }
  .more-case-study__itemtitle {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 10px;
    min-height: 81px; }
  .more-case-study__itemtech {
    font-family: "Poppins";
    font-size: 15px;
    line-height: 22px;
    color: #333333;
    margin-bottom: 10px;
    font-weight: 400; }
    .more-case-study__itemtech strong {
      color: #4492dc;
      font-weight: 700; }
  .more-case-study__link {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 26px;
    font-weight: 600; }
    .more-case-study__link svg {
      margin-left: 5px; }
  .more-case-study__more {
    font-family: "Poppins";
    color: #84a5f9;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    text-decoration: underline;
    margin-top: 25px;
    display: inline-block; }
    @media screen and (min-width: 992px) {
      .more-case-study__more {
        margin-top: 100px; } }
  .more-case-study .splide__arrows {
    display: none;
    justify-content: center;
    position: absolute;
    bottom: -105px;
    width: 100%; }
    @media screen and (min-width: 992px) {
      .more-case-study .splide__arrows {
        display: flex; } }
  .more-case-study .splide__arrow {
    position: static;
    margin: 0 5px;
    width: 45px;
    height: 45px;
    opacity: 1;
    background: #fff; }

.scrollable-wrapper__sidebar {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0%;
  right: -120%;
  transition: all 0.5s; }
  @media screen and (min-width: 992px) {
    .scrollable-wrapper__sidebar {
      position: inherit;
      right: inherit;
      top: inherit; } }
  .scrollable-wrapper__sidebar.fly-open {
    right: 0;
    transition: all 0.5s;
    z-index: 999; }

/* ANCHOR - Dita */
.new-service-section-dita {
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .new-service-section-dita {
      padding: 60px 0; } }
  .new-service-section-dita__heading {
    font-family: "Poppins";
    font-weight: 700;
    margin-bottom: 20px !important; }
    @media screen and (min-width: 992px) {
      .new-service-section-dita__heading {
        font-size: 51px;
        line-height: 60px; } }
  .new-service-section-dita__para {
    font-family: "Poppins";
    font-weight: 500;
    color: #333;
    text-align: center;
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .new-service-section-dita__para {
        font-size: 21px;
        line-height: 29px;
        margin-bottom: 50px; } }
  .new-service-section-dita .btn-orange {
    font-size: 16px; }
    @media screen and (min-width: 992px) {
      .new-service-section-dita .btn-orange {
        width: 280px;
        max-width: 280px !important;
        margin: 20px 5px 0; } }
  .new-service-section-dita .new-service-section-wrapper {
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .new-service-section-dita .new-service-section-wrapper {
        justify-content: inherit; } }

@media screen and (min-width: 992px) {
  .conversion-exp-dita .about-us__heading {
    font-size: 51px;
    line-height: 55px; } }

@media screen and (min-width: 992px) {
  .conversion-exp-dita .o-why-capital__content {
    padding: 0 120px; } }

.conversion-exp-dita .o-why-capital__box {
  padding: 25px 25px; }

.conversion-exp-dita .o-why-capital__title {
  font-weight: 700; }

.conversion-exp-dita .btn-orange {
  font-size: 16px; }
  @media screen and (min-width: 992px) {
    .conversion-exp-dita .btn-orange {
      width: 280px; } }

.conversion-solutuion {
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .conversion-solutuion {
      padding: 60px 0; } }
  .conversion-solutuion__heading {
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .conversion-solutuion__heading {
        font-size: 51px;
        line-height: 60px;
        margin-bottom: 40px; } }
  .conversion-solutuion .btn-orange {
    text-transform: capitalize;
    font-size: 16px; }
    @media screen and (min-width: 992px) {
      .conversion-solutuion .btn-orange {
        width: 280px;
        max-width: 280px !important; } }

@media screen and (min-width: 992px) {
  .unlock-power {
    background: url(/images/dita/power-unlock-bg.jpg) no-repeat center center/cover; } }

.unlock-power__subtitle {
  font-family: "Poppins";
  font-weight: 500;
  color: #CAE7FF;
  font-size: 16px;
  line-height: 20px;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .unlock-power__subtitle {
      padding-right: 120px;
      text-align: left; } }

@media screen and (min-width: 992px) {
  .unlock-power .o-discuss-project__spacing {
    padding: 70px 0; } }

/*How We Help You*/
.how-help-you {
  background-color: #052C58;
  padding: 25px 0 40px; }
  @media screen and (min-width: 992px) {
    .how-help-you {
      padding: 60px 0 80px;
      background-repeat: no-repeat;
      background-position: top left; } }
  @media screen and (min-width: 992px) {
    .how-help-you__card {
      display: flex;
      justify-content: center;
      max-width: 100%;
      margin: 0 auto; } }
  .how-help-you__card-items {
    flex: 1;
    background: #fff;
    padding: 30px 20px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    position: relative;
    flex-wrap: wrap;
    margin: 0; }
    @media screen and (min-width: 770px) {
      .how-help-you__card-items {
        margin: 15px 0;
        height: 380px; } }
    @media screen and (min-width: 992px) {
      .how-help-you__card-items {
        padding: 30px 25px 30px;
        flex-direction: column;
        flex-wrap: inherit;
        height: inherit;
        margin: 0 7px !important; } }
    .how-help-you__card-items a svg {
      transition: .3s all ease-in-out; }
    .how-help-you__card-items a:hover svg {
      transform: translateX(10px); }
    .how-help-you__card-items a:hover path {
      fill: #FF5800; }
  .how-help-you__card-items--border-blue {
    border-bottom: 8px solid #4492DC; }
  .how-help-you__card-items--border-green {
    border-bottom: 8px solid #3CC065; }
  .how-help-you__label {
    font-family: 'Poppins' !important;
    display: block;
    position: absolute;
    top: 15px;
    left: 15px;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .how-help-you__label:hover .how-help-you__radio ~ .how-help-you__checkmark {
      background-color: #333; }
  .how-help-you__radio {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .how-help-you__radio:checked ~ .how-help-you__checkmark {
      background-color: #fff;
      border: 2px solid #333; }
    .how-help-you__radio:checked ~ .how-help-you__checkmark:after {
      display: block; }
  .how-help-you__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #fff;
    border: 2px solid #333;
    border-radius: 50%; }
    .how-help-you__checkmark:after {
      content: "";
      position: absolute;
      display: none;
      top: 3px;
      left: 3px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #333333; }
  .how-help-you__icon {
    height: auto;
    display: flex;
    flex: 0 0 100%;
    text-align: center;
    justify-content: center;
    align-items: center; }
    @media screen and (min-width: 992px) {
      .how-help-you__icon {
        flex: inherit;
        justify-content: inherit; } }
  .how-help-you__heading {
    font-family: 'Poppins' !important;
    font-size: 25px;
    line-height: 38px;
    font-weight: 700;
    color: #fff; }
    @media screen and (min-width: 992px) {
      .how-help-you__heading {
        font-size: 51px;
        line-height: 64px; } }
  .how-help-you__sub-heading {
    font-family: 'Poppins' !important;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    color: #35B15C;
    margin-bottom: 35px; }
    @media screen and (min-width: 992px) {
      .how-help-you__sub-heading {
        font-size: 25px;
        line-height: 34px;
        margin-bottom: 25px; } }
  .how-help-you__card-heading {
    font-family: 'Poppins' !important;
    font-size: 21px;
    line-height: 30px;
    font-weight: 700;
    margin: 0 0 15px;
    flex: 0 0 100%; }
    @media screen and (min-width: 992px) {
      .how-help-you__card-heading {
        font-size: 31px;
        line-height: 36px;
        flex: inherit; } }
  .how-help-you__card-sub-heading {
    font-weight: 600;
    color: #333333;
    font-size: 15px;
    line-height: 21px;
    margin: 0; }
    @media screen and (min-width: 992px) {
      .how-help-you__card-sub-heading {
        margin-bottom: 0; } }
  .how-help-you__badge {
    font-family: 'Poppins' !important;
    position: absolute;
    top: -5px;
    right: 15px;
    background: #3CC065;
    color: #000;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 37px; }
  .how-help-you .how-we-help-buttons,
  .how-help-you .let-help__btn {
    font-family: 'Poppins' !important;
    display: block;
    margin: 0 auto;
    width: 300px;
    background: #FF5800;
    text-align: center;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    padding: 16px 0;
    border-radius: 5px;
    border: 1px solid #FF5800;
    position: relative;
    z-index: 99;
    margin-top: 20px; }
    .how-help-you .how-we-help-buttons:hover,
    .how-help-you .let-help__btn:hover {
      color: #FF5800;
      background: #fff; }
  .how-help-you .owl-dots {
    display: none; }
  .how-help-you__details {
    margin: 20px 0; }
  .how-help-you__link {
    text-align: center; }
  .how-help-you__anchor {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 600;
    color: #1D52C7;
    display: inline-block;
    margin-top: 0;
    text-decoration: underline;
    background: none;
    width: auto;
    max-width: inherit; }
    .how-help-you__anchor:hover {
      color: #3CC065; }

.let-help {
  max-width: 100%;
  position: relative;
  text-align: center;
  margin-top: 25px; }
  @media screen and (min-width: 992px) {
    .let-help {
      max-width: 1050px;
      margin: 60px auto 0;
      padding: 0 20px 20px 20px; } }
  .let-help:before {
    content: '';
    position: absolute;
    border: 1px solid #5787be;
    top: 14px;
    left: 0;
    right: 0;
    height: 100%;
    border-radius: 5px;
    display: none; }
    @media screen and (min-width: 992px) {
      .let-help:before {
        display: block; } }
  .let-help__heading {
    font-family: 'Poppins' !important;
    font-size: 23px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    display: inline-block;
    background-color: #052C58; }
    @media screen and (min-width: 992px) {
      .let-help__heading {
        font-size: 25px;
        padding: 0 15px; } }
  .let-help__sub-heading {
    font-family: 'Poppins' !important;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .let-help__sub-heading {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 30px; } }
  .let-help__checkbox-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    @media screen and (min-width: 992px) {
      .let-help__checkbox-list {
        flex-wrap: inherit;
        justify-content: center; } }
  .let-help__checkbox-items {
    flex: 0 47%;
    margin: 10px; }
    @media screen and (min-width: 992px) {
      .let-help__checkbox-items {
        flex: inherit;
        overflow: hidden;
        border-radius: 5px;
        padding: 1px;
        background: #1e8e41;
        background: -moz-linear-gradient(180deg, #1e8e41 0%, #274dad 100%);
        background: -webkit-linear-gradient(180deg, #1e8e41 0%, #274dad 100%);
        background: linear-gradient(180deg, #1e8e41 0%, #274dad 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1e8e41",endColorstr="#274dad",GradientType=1); } }
  @media screen and (min-width: 992px) {
    .let-help__inner-wrapper {
      border-radius: 5px;
      background-color: #05284D;
      padding: 20px 15px 20px 15px; } }
  .let-help__checkbox-label {
    font-family: 'Poppins' !important;
    text-align: left;
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    @media screen and (min-width: 992px) {
      .let-help__checkbox-label {
        font-size: 14px;
        line-height: 21px; } }
    .let-help__checkbox-label:hover__checkbox-input ~ .let-help__checkbox-label:hover__checkmark {
      background-color: #ccc; }
      .let-help__checkbox-label:hover__checkbox-input ~ .let-help__checkbox-label:hover__checkmark:after {
        display: block; }
  .let-help__checkbox-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .let-help__checkbox-input:checked ~ .let-help__checkmark {
      background-color: transparent; }
      .let-help__checkbox-input:checked ~ .let-help__checkmark:after {
        content: "";
        position: absolute;
        display: block;
        left: 2px;
        top: 2px;
        width: 5px;
        height: 11px;
        border: solid #000;
        width: 12px;
        height: 12px;
        border: 0;
        background: #3CC065;
        border-radius: 50%; }
  .let-help__checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    border: 1px solid #3CC065;
    background-color: transparent;
    border-radius: 50%;
    transform: translateY(-50%); }
    @media screen and (min-width: 992px) {
      .let-help__checkmark {
        top: 2px;
        transform: inherit; } }
  .let-help__btn {
    font-family: 'Poppins' !important;
    background-color: #FF5800;
    border: 1px solid #FF5800;
    border-radius: 5px;
    padding: 18px 15px;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    width: 360px;
    margin: 30px auto 0;
    transition: .3s all ease-in-out;
    text-transform: capitalize;
    margin-bottom: 0; }
    .let-help__btn svg {
      margin-left: 10px; }
    .let-help__btn:hover {
      background-color: #fff;
      color: #FF5800; }
      .let-help__btn:hover path {
        fill: #FF5800; }
  .let-help .compare-engage-link {
    font-size: 13px;
    line-height: 20px;
    text-decoration: underline;
    position: relative;
    z-index: 99;
    margin-top: 10px;
    font-weight: 700; }
    .let-help .compare-engage-link:hover {
      color: #00b54a !important; }

.link {
  background: none !important;
  padding: 0 !important;
  width: auto !important;
  border: none !important;
  display: block !important;
  margin: 10px auto;
  color: #fff; }

.custom-dev-help .how-help-you {
  background: url(/images/how-we-help-you/custom-dev-how-we-bg.jpg) no-repeat bottom right/cover; }

.custom-dev-help .let-help__heading {
  background: none; }

.custom-dev-help .how-help-you__heading {
  font-weight: 500; }

@media screen and (min-width: 992px) {
  .custom-dev-help .how-help-you__card-heading {
    font-size: 24px;
    line-height: 29px; } }

.custom-dev-help .how-help-you__card-items {
  border-bottom: none; }

.custom-dev-help .how-we-help-buttons {
  background: none;
  color: #333;
  transition: .3s all ease-in-out; }
  .custom-dev-help .how-we-help-buttons svg path {
    fill: #333; }
  .custom-dev-help .how-we-help-buttons:hover {
    background: #FF5800;
    color: #fff; }
    .custom-dev-help .how-we-help-buttons:hover svg path {
      fill: #fff; }

.custom-dev-help .how-we-help-knowmore {
  color: #0D65CA; }

.custom-dev-help .how-help-you__card-sub-heading {
  margin: 0; }
  @media screen and (min-width: 992px) {
    .custom-dev-help .how-help-you__card-sub-heading {
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      margin: 0 0 25px; } }

/* ANCHOR - Portfolio */
.portfolio-banner {
  position: relative;
  background: #04254a url(/images/new-portfolio/banner-left-graphic.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  padding: 30px 0 25px;
  width: 100%;
  overflow: hidden; }
  @media screen and (min-width: 992px) {
    .portfolio-banner {
      padding: 36px 0 36px; } }
  .portfolio-banner:after {
    position: absolute;
    content: "";
    width: 289px;
    height: 196px;
    background: url(/images/new-portfolio/banner-right-graphic.png) no-repeat bottom center;
    background-size: 100%;
    bottom: 0;
    right: 0; }
  .portfolio-banner__heading-text {
    font-family: Poppins;
    color: #fff;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700; }
    @media screen and (min-width: 992px) {
      .portfolio-banner__heading-text {
        margin-bottom: 0; } }
  .portfolio-banner__assets {
    position: relative; }
    .portfolio-banner__assets:before {
      position: absolute;
      content: "";
      width: 690px;
      height: 345px;
      background: url(/images/new-portfolio/banner-assets-background.svg) no-repeat center;
      background-size: 100% 100%;
      top: -35px;
      left: -80px;
      display: none; }
      @media screen and (min-width: 992px) {
        .portfolio-banner__assets:before {
          display: block; } }
  .portfolio-banner__middle {
    position: relative;
    max-width: 430px;
    width: 100%;
    margin: auto;
    z-index: 1; }
  .portfolio-banner__asset-wrapper {
    display: flex;
    flex-wrap: wrap; }
  .portfolio-banner__asset-heading {
    font-family: Poppins;
    color: #3cc065;
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    width: 300px;
    margin-bottom: 22px; }
  .portfolio-banner__asset-box {
    flex: 0 0 50%;
    display: inline-flex;
    margin-bottom: 26px; }
    @media screen and (min-width: 992px) {
      .portfolio-banner__asset-box:nth-child(3), .portfolio-banner__asset-box:nth-child(4) {
        margin-bottom: 16px; } }
  .portfolio-banner__asset-icon {
    width: 50px;
    text-align: center;
    margin-right: 15px; }
  .portfolio-banner__asset-text {
    max-width: 105px; }
    .portfolio-banner__asset-text h3 {
      font-family: Poppins;
      font-size: 27px;
      font-weight: 700;
      color: #fff;
      margin: 0; }
    .portfolio-banner__asset-text p {
      font-family: Poppins;
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
      color: #fff;
      margin: 0; }

.portfolio-view__spacing {
  padding: 15px 0 0; }
  @media screen and (min-width: 992px) {
    .portfolio-view__spacing {
      padding: 40px 0 0;
      display: block; } }

@media screen and (min-width: 992px) {
  .portfolio-view__leftpart {
    border-right: 1px solid #e0e0e0;
    position: static;
    background: inherit;
    height: 100%; } }

.portfolio-view__leftpart .portfolio-view__spacing {
  display: flex; }
  @media screen and (min-width: 992px) {
    .portfolio-view__leftpart .portfolio-view__spacing {
      display: block; } }

.portfolio-view__leftpart .sk-fading-circle {
  margin: 15px auto 0;
  width: 40px;
  height: 40px;
  position: relative; }

.portfolio-view__filterwrapper {
  padding-right: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
  position: relative; }
  @media screen and (min-width: 992px) {
    .portfolio-view__filterwrapper {
      margin-bottom: 30px; } }
  .portfolio-view__filterwrapper:before {
    content: "OR";
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 600;
    color: #333;
    padding: 0 5px;
    width: 30px;
    background: #ccc;
    height: 30px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: none; }
    @media screen and (min-width: 992px) {
      .portfolio-view__filterwrapper:before {
        display: flex; } }
  .portfolio-view__filterwrapper--country {
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .portfolio-view__filterwrapper--country {
        margin-bottom: 30px; } }
    .portfolio-view__filterwrapper--country::before {
      display: none; }

.portfolio-view__filtertittle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  border-radius: 15px 0 15px 0;
  margin-bottom: 18px; }
  .portfolio-view__filtertittle--blue {
    background-color: #dee7ff; }
  .portfolio-view__filtertittle--green {
    background-color: #d4ffe1; }
  .portfolio-view__filtertittle--orange {
    background-color: #ffeadf; }
  .portfolio-view__filtertittle i {
    margin-right: 8px; }
  .portfolio-view__filtertittle h4 {
    font-family: Poppins;
    font-size: 16px;
    color: #333333;
    line-height: 33px;
    font-weight: 600;
    margin: 0; }

.portfolio-view__filterlist {
  position: relative; }

.portfolio-view__filteritem {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .portfolio-view__filteritem input {
    margin-right: 12px;
    color: #898989;
    border-color: #898989;
    width: 16px;
    height: 16px; }
  .portfolio-view__filteritem img {
    margin-right: 10px; }
  .portfolio-view__filteritem label {
    font-family: Poppins;
    font-size: 15px;
    line-height: 33px;
    color: #333333;
    font-weight: 500; }

.portfolio-view__filtermore {
  font-family: Poppins;
  color: #4492dc;
  font-size: 15px;
  line-height: 33px;
  font-weight: 500;
  margin-left: 27px;
  display: inline-block;
  margin-top: 6px;
  cursor: pointer; }

.portfolio-view__rightpart {
  position: relative;
  padding-bottom: 30px; }
  @media screen and (min-width: 992px) {
    .portfolio-view__rightpart {
      padding-bottom: 60px; } }
  .portfolio-view__rightpart .sticky-bottom {
    width: 100%;
    color: white;
    text-align: center;
    padding: 5px 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media screen and (min-width: 992px) {
      .portfolio-view__rightpart .sticky-bottom {
        padding: 15px 0; } }
    .portfolio-view__rightpart .sticky-bottom.fixed {
      position: fixed;
      top: 71px;
      left: 0;
      right: 0;
      z-index: 1000;
      padding: 5px 12px;
      background: #fff;
      box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.05);
      border-bottom: 1px solid #f5f5f5; }
    .portfolio-view__rightpart .sticky-bottom .filter-icon {
      font-size: 16px;
      font-weight: 500;
      font-family: Poppins;
      color: #000;
      display: flex;
      align-items: center;
      cursor: pointer; }
      .portfolio-view__rightpart .sticky-bottom .filter-icon i {
        display: inline-block;
        margin-left: 10px; }
  .portfolio-view__rightpart .icon-container {
    display: flex;
    justify-content: space-around;
    align-items: center; }
  .portfolio-view__rightpart .sticky-icon {
    width: 30px;
    height: 30px;
    cursor: pointer; }

.portfolio-view__rightittle {
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  @media screen and (min-width: 992px) {
    .portfolio-view__rightittle {
      display: flex;
      flex-direction: inherit;
      align-items: center; } }

.portfolio-view__rightittlearea {
  margin-bottom: 15px; }
  @media screen and (min-width: 992px) {
    .portfolio-view__rightittlearea {
      margin-bottom: 0; } }
  .portfolio-view__rightittlearea h4 {
    font-family: Poppins;
    font-size: 24px;
    line-height: 27px;
    font-weight: 600;
    color: #000;
    margin: 0; }
  .portfolio-view__rightittlearea p {
    font-family: Poppins;
    font-size: 16px;
    line-height: 27px;
    color: #000;
    margin: 0; }

.portfolio-view__select {
  border: 1px solid #d9d9d9;
  padding: 10px;
  width: 183px;
  border-radius: 2px;
  font-family: Poppins;
  font-size: 16px;
  line-height: 27px;
  color: #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(/images/new-portfolio/select-arrow.svg) 93%/15% no-repeat #fff;
  background-size: 13px; }

.portfolio-view__masonry {
  position: relative;
  margin-top: 20px; }
  @media screen and (min-width: 992px) {
    .portfolio-view__masonry {
      column-gap: 30px;
      column-count: 2;
      orphans: 1;
      widows: 1; } }
  @media screen and (min-width: 992px) {
    .portfolio-view__masonry.orphan3 {
      orphans: 2; } }
  .portfolio-view__masonry .no-data {
    width: 100%;
    font-size: 30px;
    font-weight: 600;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .portfolio-view__masonry .no-data {
        top: 50%;
        height: 100vh;
        margin-top: 60px;
        padding-bottom: 30px;
        position: absolute;
        font-size: 40px; } }

.portfolio-view__masonryitem {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 12px 13px;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .portfolio-view__masonryitem {
      margin-bottom: 30px; } }

.portfolio-view__itemimage {
  margin-bottom: 24px; }
  .portfolio-view__itemimage img {
    border-radius: 10px;
    width: 100%; }

.portfolio-view__itemtitlearea {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px; }

.portfolio-view__titlelink {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex: 1; }
  @media screen and (min-width: 992px) {
    .portfolio-view__titlelink {
      flex: inherit;
      flex-wrap: wrap;
      gap: 5px; } }
  .portfolio-view__titlelink .portfolio-tittle {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 22px;
    line-height: 33px;
    font-weight: 700;
    color: #000;
    padding-right: 15px;
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .portfolio-view__titlelink .portfolio-tittle {
        margin: 0 15px 0 0; } }
  .portfolio-view__titlelink .d-flex.justify-content-center.align-items-center .portfolio-link {
    display: none; }
  .portfolio-view__titlelink .portfolio-link {
    font-family: "Plus Jakarta Sans", sans-serif;
    color: #4492dc;
    font-size: 14px;
    line-height: 33px; }
    .portfolio-view__titlelink .portfolio-link img {
      margin-right: 10px; }

.portfolio-view__itemlink {
  width: 10%; }

.portfolio-view__itemdesc {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  color: #333;
  margin-bottom: 15px; }

.portfolio-view__filter-value {
  font-size: 0;
  line-height: 0;
  visibility: hidden;
  display: none;
  text-indent: 99999; }

.portfolio-view__techstack {
  display: flex;
  flex-wrap: wrap; }
  .portfolio-view__techstack li {
    margin: 3px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px; }
    .portfolio-view__techstack li:nth-child(1) {
      border: 1px solid #64b9f4; }
    .portfolio-view__techstack li:nth-child(2) {
      border: 1px solid #e26826; }
    .portfolio-view__techstack li:nth-child(3) {
      border: 1px solid #fbba14; }
    .portfolio-view__techstack li:nth-child(4) {
      border: 1px solid #8ac640; }
    .portfolio-view__techstack li:nth-child(5) {
      border: 1px solid #804b8b; }
    .portfolio-view__techstack li:nth-child(6) {
      border: 1px solid #64b9f4; }
    .portfolio-view__techstack li:nth-child(7) {
      border: 1px solid #e26826; }
    .portfolio-view__techstack li:nth-child(8) {
      border: 1px solid #fbba14; }
    .portfolio-view__techstack li:nth-child(9) {
      border: 1px solid #8ac640; }
    .portfolio-view__techstack li:nth-child(10) {
      border: 1px solid #804b8b; }
    .portfolio-view__techstack li a {
      color: #030303;
      font-weight: 700; }

.portfolio-view__paggination {
  display: flex; }
  .portfolio-view__paggination li .flex-center {
    width: 51px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 16px;
    color: #000;
    border: 1px solid #e1e1e1;
    margin-right: 13px;
    border-radius: 50%;
    transition: 0.3s all ease-in-out;
    cursor: pointer; }
    .portfolio-view__paggination li .flex-center.active-page {
      background-color: #3cc065;
      border-color: #3cc065;
      font-weight: 600;
      color: #fff; }
  .portfolio-view__paggination li:hover .flex-center {
    border-color: #3cc065; }
  .portfolio-view__paggination li:first-child .flex-center, .portfolio-view__paggination li:last-child .flex-center {
    background-color: #f4f4f4; }
    .portfolio-view__paggination li:first-child .flex-center.active-page, .portfolio-view__paggination li:last-child .flex-center.active-page {
      background-color: #3cc065; }
  .portfolio-view__paggination li:nth-last-child(2) {
    display: inline-block !important; }
    .portfolio-view__paggination li:nth-last-child(2):before {
      content: "";
      background: url(/images/portfolio/three-dot.png) no-repeat 18px 23px;
      float: left;
      margin: 0 0px 0 13px;
      border: 1px solid #ccc;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #000;
      line-height: 0;
      position: relative;
      left: -13px; }
  .portfolio-view__paggination.midShep li:nth-child(2) {
    display: inline-block !important; }
    .portfolio-view__paggination.midShep li:nth-child(2):before {
      content: "";
      background: url(/images/portfolio/three-dot.png) no-repeat 17px 23px;
      float: right;
      margin: 0 13px 0 13px;
      border: 1px solid #ccc;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background-size: 14px; }

.discover {
  background-color: #0f123e; }
  .discover__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .discover__spacing {
        padding: 60px 0; } }
  .discover__title {
    font-family: Poppins;
    font-size: 31px;
    line-height: 33px;
    color: #fff;
    font-weight: 700;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .discover__title {
        text-align: left; } }
  .discover__desc {
    font-family: Poppins;
    font-size: 21px;
    line-height: 27px;
    color: #fff;
    font-weight: 500;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .discover__desc {
        text-align: left; } }
  .discover__btn {
    max-width: 333px;
    border-color: #ff5800;
    margin: 10px auto 15px;
    padding: 16px;
    color: #fff;
    font-family: Poppins;
    font-size: 17px;
    font-weight: 600;
    text-transform: capitalize; }
    @media screen and (min-width: 992px) {
      .discover__btn {
        margin: 10px 0 0; } }
    .discover__btn img {
      margin-left: 10px; }
    .discover__btn:hover {
      color: #ff5800; }
  .discover__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .discover__list li {
      width: auto !important;
      margin: 10px 5px; }
      .discover__list li a {
        padding: 1px 20px; }

.portfolio-we-do .how-help-you {
  background: #052c58 url(/images/new-portfolio/banner-left-graphic.png);
  background-repeat: no-repeat;
  background-position: top left; }

.portfolio-loader {
  display: none;
  /* Hide loader by default */
  position: fixed;
  /* Position loader fixed to the screen */
  top: 50%;
  /* Center loader vertically */
  left: 50%;
  /* Center loader horizontally */
  transform: translate(-50%, -50%);
  /* Center loader precisely */
  z-index: 99999999;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(255, 255, 255, 0.8); }

.portfolio-loader-inside {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }
  .portfolio-loader-inside:before {
    content: "";
    box-sizing: border-box;
    display: inline-block;
    width: 40px;
    /* Adjust loader size */
    height: 40px;
    /* Adjust loader size */
    margin: 0 auto;
    border: 4px solid #3498db;
    /* Loader color */
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
    /* Apply rotation animation */ }

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media screen and (min-width: 992px) {
    .pagination-container {
      justify-content: inherit; } }
  .pagination-container a {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 13px;
    color: #000;
    border: 1px solid #e1e1e1;
    margin-right: 10px;
    border-radius: 50%;
    transition: 0.3s all ease-in-out;
    cursor: pointer; }
    @media screen and (min-width: 992px) {
      .pagination-container a {
        width: 50px;
        height: 50px;
        margin-right: 13px;
        font-size: 16px; } }
    .pagination-container a.active {
      background-color: #3cc065;
      border-color: #3cc065;
      font-weight: 600;
      color: #fff; }
    .pagination-container a:hover {
      border-color: #3cc065; }
    .pagination-container a.pagination-prev, .pagination-container a.pagination-next {
      background-color: #f4f4f4; }

#filterModal {
  display: none;
  position: fixed;
  z-index: 9999999 !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 0;
  border-radius: 0;
  align-items: flex-end; }
  #filterModal .modal-content {
    background-color: #fefefe;
    margin: 0;
    padding: 20px 0 0;
    border: 0;
    border-radius: 0;
    width: 100%;
    height: 100%;
    height: 540px; }
  #filterModal .modal-body {
    flex: inherit;
    height: 100vh;
    overflow: scroll; }
  #filterModal .close:hover,
  #filterModal .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer; }
  #filterModal .close {
    color: #000000;
    float: right;
    font-size: 44px;
    font-weight: 500;
    opacity: 1;
    text-align: right;
    position: absolute;
    right: 10px;
    top: -3px;
    z-index: 99; }
  #filterModal .modal-footer {
    display: flex;
    width: 100%;
    margin: 0 auto;
    border: 0 !important;
    padding: 15px 15px; }
    #filterModal .modal-footer .warning {
      color: red;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      flex: 1; }
    #filterModal .modal-footer button:disabled {
      filter: grayscale(100%); }
  #filterModal #clearFiltersBtn {
    border: 1px solid #000;
    color: #000;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 20px;
    flex: 1;
    margin: 0 7px 0 0; }
  #filterModal #applyFiltersBtn {
    background: #3cc065;
    border: 1px solid #3cc065;
    color: #fff;
    font-size: 15px;
    padding: 10px 20px;
    flex: 1;
    font-weight: 600;
    margin: 0 0 0 7px; }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

/* ANCHOR - Skill page design */
.service-pages {
  font-family: Poppins !important; }

.o-skill-banner {
  background: #0d2642 url(/images/new-home-banner/banner-gradient.png) no-repeat;
  background-position: top left;
  padding: 135px 20px 27px;
  overflow: hidden; }
  .o-skill-banner__bannertitle {
    font-family: Poppins;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 15px;
    text-align: center; }
    @media screen and (min-width: 767px) {
      .o-skill-banner__bannertitle {
        font-size: 41px;
        line-height: 45px;
        text-align: left;
        margin-bottom: 30px; } }
    @media screen and (min-width: 992px) {
      .o-skill-banner__bannertitle {
        font-size: 51px;
        line-height: 60px; } }
    @media screen and (min-width: 992px) {
      .o-skill-banner__bannertitle--dita {
        font-size: 44px;
        line-height: 56px; } }
    @media screen and (min-width: 992px) {
      .o-skill-banner__bannertitle--salesforce {
        font-size: 42px !important;
        line-height: 47px !important; } }
  .o-skill-banner__bannersubtitle {
    font-family: "Poppins";
    font-size: 25px;
    line-height: 40px;
    font-weight: 700;
    color: #fff;
    text-align: center; }
    @media screen and (min-width: 767px) {
      .o-skill-banner__bannersubtitle {
        font-size: 31px;
        line-height: 44px;
        text-align: left; } }
    @media screen and (min-width: 767px) {
      .o-skill-banner__bannersubtitle--ver-2 {
        font-size: 28px;
        line-height: 40px; } }
    @media screen and (min-width: 767px) {
      .o-skill-banner__bannersubtitle--ver-3 {
        font-size: 20px;
        line-height: 27px;
        font-weight: 500; } }
    @media screen and (min-width: 767px) {
      .o-skill-banner__bannersubtitle--custom-soft {
        font-size: 27px;
        line-height: 38px; } }
    .o-skill-banner__bannersubtitle--green {
      color: #3cc065; }
      @media screen and (min-width: 767px) {
        .o-skill-banner__bannersubtitle--green {
          font-size: 24px;
          line-height: 31px; } }
  .o-skill-banner__bannerpara {
    color: #fff;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-skill-banner__bannerpara {
        text-align: left; } }
  .o-skill-banner__subtitlelink {
    color: #fff; }
    .o-skill-banner__subtitlelink:hover span {
      background-size: 100% 100%; }
    .o-skill-banner__subtitlelink span {
      position: relative;
      background: -webkit-gradient(linear, left top, left bottom, from(#3cc065)) left no-repeat, #fff;
      background: linear-gradient(#3cc065) left no-repeat, #fff;
      background-size: 0 100%;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-transition: 0.2s all ease-in-out;
      transition: 0.2s all ease-in-out;
      display: inline-block; }
      .o-skill-banner__subtitlelink span:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        -webkit-animation: 4s linear animate infinite;
        animation: 4s linear animate infinite; }
      .o-skill-banner__subtitlelink span.link-color1:before {
        background: -webkit-gradient(linear, left top, right top, from(#03a9f4), color-stop(#ff5800), color-stop(#fbba14), to(#03a9f4));
        background: linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
        background-size: 200%; }
      .o-skill-banner__subtitlelink span.link-color2:before {
        background: -webkit-gradient(linear, left top, right top, from(#ffe70e), color-stop(#78e6ff), color-stop(#15fe8a), to(#ffe70e));
        background: linear-gradient(90deg, #ffe70e, #78e6ff, #15fe8a, #ffe70e);
        background-size: 200%; }
  .o-skill-banner__reviewarea {
    position: relative;
    padding: 20px 30px;
    border-radius: 10px;
    border: 1px solid #495fcd;
    margin-top: 50px; }
    .o-skill-banner__reviewarea:before {
      position: absolute;
      content: "";
      width: 3px;
      height: 70%;
      left: -2px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #0d2642; }
      @media screen and (min-width: 767px) {
        .o-skill-banner__reviewarea:before {
          width: 2px;
          left: -1px; } }
    .o-skill-banner__reviewarea:after {
      position: absolute;
      content: "";
      width: 3px;
      height: 70%;
      right: -2px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #0d2642; }
      @media screen and (min-width: 767px) {
        .o-skill-banner__reviewarea:after {
          width: 2px;
          right: -1px; } }
  .o-skill-banner__reviewtitle {
    position: relative;
    font-family: Poppins;
    font-size: 15px;
    line-height: 23px;
    color: #fff;
    font-weight: 500;
    background: #0d2642;
    padding: 0 5px;
    display: inline-block;
    margin: 0;
    top: -30px; }
    @media screen and (min-width: 767px) {
      .o-skill-banner__reviewtitle {
        font-size: 18px; } }
  .o-skill-banner__reviewlist {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end; }
  .o-skill-banner__reviewitem {
    padding: 0; }
    @media screen and (min-width: 767px) {
      .o-skill-banner__reviewitem {
        width: 20%; } }
  .o-skill-banner__rightoverlay {
    position: relative;
    margin-top: 70px; }
    @media screen and (min-width: 992px) {
      .o-skill-banner__rightoverlay {
        margin: 0; } }
    .o-skill-banner__rightoverlay:before {
      position: absolute;
      content: "";
      width: 570px;
      height: 1000px;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-47%);
      background: #3cc065;
      background: radial-gradient(circle, rgba(60, 192, 101, 0.4) 0%, rgba(255, 255, 255, 0) 50%); }
  .o-skill-banner__reviewtitle {
    top: -33px; }
  .o-skill-banner__reviewlist {
    position: relative;
    top: -7px; }
    @media screen and (min-width: 992px) {
      .o-skill-banner__reviewlist {
        position: relative;
        top: -6px; } }
  .o-skill-banner__reviewitem {
    display: flex;
    justify-content: space-around; }
    .o-skill-banner__reviewitem:last-child .o-skill-banner__reviewitem-star {
      position: relative;
      top: -3px; }
  .o-skill-banner__reviewitem-left {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
  .o-skill-banner__reviewitem-right {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column; }
  @media screen and (min-width: 992px) {
    .o-skill-banner__reviewitem-icon img {
      height: inherit; } }
  .o-skill-banner__reviewitem-star img {
    height: 20px; }
    @media screen and (min-width: 992px) {
      .o-skill-banner__reviewitem-star img {
        height: inherit; } }
  .o-skill-banner__reviewitem-name {
    font-size: 13px;
    font-weight: 500;
    color: #fff; }
    @media screen and (min-width: 992px) {
      .o-skill-banner__reviewitem-name {
        font-size: 15px; } }
  .o-skill-banner__reviewitem-number {
    font-size: 22px;
    font-weight: 500;
    color: #fff;
    margin-top: 5px;
    display: inline-block; }
    @media screen and (min-width: 992px) {
      .o-skill-banner__reviewitem-number {
        font-size: 28px; } }
  .o-skill-banner__rightbox {
    position: relative;
    background-color: #0c315b;
    max-width: 429px;
    width: 100%;
    display: inline-block;
    border: 2px solid #3cc065;
    padding: 15px; }
    @media screen and (min-width: 767px) {
      .o-skill-banner__rightbox {
        padding: 29px; } }
    .o-skill-banner__rightbox:before {
      position: absolute;
      content: "";
      width: 35%;
      height: 2px;
      background-color: #0c315b;
      left: 0;
      right: 0;
      top: -2px;
      margin: auto; }
    .o-skill-banner__rightbox--ver-1:before {
      width: 83%; }
    @media screen and (min-width: 767px) {
      .o-skill-banner__rightbox--ver-2 {
        padding: 29px 20px 20px;
        margin-top: 12px; } }
    @media screen and (min-width: 767px) {
      .o-skill-banner__rightbox--salesforce {
        padding: 29px 20px 40px; } }
  .o-skill-banner__rightboxtop {
    text-align: center;
    padding-top: 30px; }
    @media screen and (min-width: 767px) {
      .o-skill-banner__rightboxtop {
        padding-top: 20px; } }
    .o-skill-banner__rightboxtop .btn {
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 600;
      max-width: 310px !important;
      padding: 12px; }
      .o-skill-banner__rightboxtop .btn svg {
        margin-left: 7px; }
      @media screen and (min-width: 767px) {
        .o-skill-banner__rightboxtop .btn {
          font-size: 16px;
          padding: 14px; } }

@keyframes gradient-spin {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }
    .o-skill-banner__rightboxtop h5 {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      width: 100%;
      font-size: 14px;
      background: linear-gradient(45deg, #ffe70e, #78e6ff, #15fe8a, #ffe70e);
      background-size: 300% 300%;
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      animation: gradient-spin 4s infinite linear; }
      @media screen and (min-width: 767px) {
        .o-skill-banner__rightboxtop h5 {
          font-size: 20px; } }
    .o-skill-banner__rightboxtop--no-padding {
      padding-top: 0; }
  .o-skill-banner__rightboxtopimg {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    margin: auto; }
  .o-skill-banner__rightboxtitle {
    font-family: Poppins;
    font-size: 20px;
    line-height: 27px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 20px; }
    @media screen and (min-width: 767px) {
      .o-skill-banner__rightboxtitle {
        font-size: 25px;
        line-height: 32px; } }
    .o-skill-banner__rightboxtitle--24 {
      font-size: 24px; }
    .o-skill-banner__rightboxtitle a {
      color: #fff; }
      .o-skill-banner__rightboxtitle a span {
        position: relative;
        display: inline-block; }
        .o-skill-banner__rightboxtitle a span:before {
          content: "";
          position: absolute;
          bottom: -2px;
          left: 0;
          height: 2px;
          width: 100%;
          border-radius: 10px;
          -webkit-animation: 4s linear animate infinite;
          animation: 4s linear animate infinite; }
        .o-skill-banner__rightboxtitle a span.link-color1:before {
          background: -webkit-gradient(linear, left top, right top, from(#03a9f4), color-stop(#ff5800), color-stop(#fbba14), to(#03a9f4));
          background: linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
          background-size: 200%; }
        .o-skill-banner__rightboxtitle a span.link-color2:before {
          background: -webkit-gradient(linear, left top, right top, from(#ffe70e), color-stop(#78e6ff), color-stop(#15fe8a), to(#ffe70e));
          background: linear-gradient(90deg, #ffe70e, #78e6ff, #15fe8a, #ffe70e);
          background-size: 200%; }
  .o-skill-banner__rightboxbottom {
    background-color: #194b83;
    padding: 12px;
    margin-top: 18px;
    border-radius: 4px; }
    @media screen and (min-width: 767px) {
      .o-skill-banner__rightboxbottom {
        padding: 25px;
        margin-top: 26px; } }
    .o-skill-banner__rightboxbottom--pad-20 {
      padding: 12px; }
      @media screen and (min-width: 767px) {
        .o-skill-banner__rightboxbottom--pad-20 {
          padding: 20px; } }
    @media screen and (min-width: 767px) {
      .o-skill-banner__rightboxbottom--salesforce {
        margin-top: 40px; } }
  .o-skill-banner__rightboxbottomtitle {
    font-family: Poppins;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 12px;
    text-align: center; }
    @media screen and (min-width: 767px) {
      .o-skill-banner__rightboxbottomtitle {
        font-size: 17px;
        line-height: 29px; } }
    .o-skill-banner__rightboxbottomtitle--font-18 {
      font-size: 12px; }
      @media screen and (min-width: 767px) {
        .o-skill-banner__rightboxbottomtitle--font-18 {
          font-size: 19px;
          line-height: 29px; } }
  .o-skill-banner__rightboxbottomtext {
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    font-family: Poppins;
    margin-bottom: 0; }
    @media screen and (min-width: 767px) {
      .o-skill-banner__rightboxbottomtext {
        font-size: 15px;
        line-height: 25px; } }
    .o-skill-banner__rightboxbottomtext--font-17 {
      font-size: 11px; }
      @media screen and (min-width: 767px) {
        .o-skill-banner__rightboxbottomtext--font-17 {
          font-size: 17px;
          line-height: 27px; } }
  .o-skill-banner__rightboxbottomlist {
    position: relative;
    counter-reset: counter; }
  .o-skill-banner__rightboxbottomitem {
    font-family: Poppins;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 8px;
    color: #fff;
    text-align: left; }
    @media screen and (min-width: 767px) {
      .o-skill-banner__rightboxbottomitem {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 12px; } }
    .o-skill-banner__rightboxbottomitem:last-child {
      margin-bottom: 0; }
    .o-skill-banner__rightboxbottomitem:before {
      content: counter(counter);
      counter-increment: counter;
      color: #fff;
      background-color: #3cc065;
      display: inline-flex;
      width: 27px;
      height: 27px;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      margin-top: 4px;
      border-radius: 50%;
      float: left; }
    .o-skill-banner__rightboxbottomitem-14 {
      font-size: 11px; }
      @media screen and (min-width: 767px) {
        .o-skill-banner__rightboxbottomitem-14 {
          font-size: 14px; } }
  @media screen and (min-width: 992px) {
    .o-skill-banner__with-logo-wrapper {
      display: flex;
      align-items: center; } }
  .o-skill-banner__with-logo {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media screen and (min-width: 992px) {
      .o-skill-banner__with-logo {
        flex: 0 0 140px;
        max-width: 140px;
        margin-right: 45px;
        display: inherit; } }
  .o-skill-banner__with-logo-partner {
    font-family: Poppins;
    font-size: 22px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 20px;
    display: inline-block; }
    @media screen and (min-width: 992px) {
      .o-skill-banner__with-logo-partner {
        font-size: 31px;
        margin-bottom: 0; } }
  @media screen and (min-width: 992px) {
    .o-skill-banner--hire-salesforce .o-skill-banner__bannertitle {
      font-size: 38px;
      line-height: 48px; } }
  @media screen and (min-width: 992px) {
    .o-skill-banner .o-skill-banner__bannersubtitle {
      font-size: 18px;
      line-height: 32px;
      font-weight: 600; } }
  @media screen and (min-width: 992px) {
    .o-skill-banner .o-skill-banner__rightboxtop .btn {
      max-width: 340px !important;
      padding: 14px 10px;
      font-size: 15px; } }

.o-service-banner--microsoft-dynamics-365-customer-service-integration .o-skill-banner__rightboxtop .btn {
  max-width: 375px !important; }

.o-service-banner--microsoft-dynamics-365-consulting-companies .o-skill-banner__rightboxtop .btn {
  max-width: 375px !important; }

.o-skill-service {
  background-color: #f9f9f9;
  border-bottom: 1px solid #e0e0e0; }
  .o-skill-service .custom-solution__heading {
    font-family: Poppins;
    font-size: 30px;
    line-height: 35px;
    font-weight: 700;
    color: #262e36;
    margin-bottom: 15px; }
    @media screen and (min-width: 767px) {
      .o-skill-service .custom-solution__heading {
        font-size: 38px;
        line-height: 46px; } }
    @media screen and (min-width: 992px) {
      .o-skill-service .custom-solution__heading {
        font-size: 48px;
        line-height: 58px; } }
    .o-skill-service .custom-solution__heading--nosub {
      margin: 0 0 30px; }
      @media screen and (min-width: 992px) {
        .o-skill-service .custom-solution__heading--nosub {
          margin: 0 0 45px; } }
  .o-skill-service .custom-solution__sub-heading {
    font-family: Poppins;
    font-size: 18px;
    line-height: 27px;
    color: #474747;
    margin: 0 0 30px;
    font-weight: 500;
    display: block; }
    @media screen and (min-width: 992px) {
      .o-skill-service .custom-solution__sub-heading {
        font-size: 21px;
        line-height: 31px;
        margin: 0 0 45px; } }
  .o-skill-service .custom-solution__card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap; }
    @media screen and (min-width: 992px) {
      .o-skill-service .custom-solution__card {
        display: inherit; } }
  .o-skill-service .custom-solution__card-gradient:before {
    display: block; }
  .o-skill-service .custom-solution__card-heading {
    font-family: Poppins;
    font-size: 20px;
    line-height: 25px; }
    .o-skill-service .custom-solution__card-heading--smaller {
      font-size: 17px; }
  .o-skill-service .custom-solution__card-description {
    font-family: Poppins;
    font-size: 15px;
    line-height: 25px;
    color: #333;
    margin: 0 0 15px; }
    .o-skill-service .custom-solution__card-description a {
      font-family: Poppins;
      color: #3cc065;
      font-weight: 500; }
  .o-skill-service .custom-solution__card-description-wrapper {
    margin-top: 20px;
    display: none; }
  .o-skill-service .card-list-bulleted {
    list-style: disc;
    padding-left: 15px; }
    .o-skill-service .card-list-bulleted li::marker {
      color: #102355; }
  .o-skill-service .custom-solution__card-gradient1 {
    display: block;
    position: relative;
    margin-bottom: 26px;
    cursor: pointer;
    overflow: hidden;
    padding: 2px;
    border: 0; }
    .o-skill-service .custom-solution__card-gradient1:before {
      content: "+";
      position: absolute;
      top: 20px;
      right: 10px;
      font-size: 36px;
      line-height: 0;
      font-weight: 300;
      color: #898989;
      z-index: 99; }
    .o-skill-service .custom-solution__card-gradient1:after {
      content: "";
      position: absolute;
      top: -200px;
      right: 0;
      bottom: 0;
      left: -150px;
      background: #898989;
      width: 950px;
      height: 950px;
      -webkit-transition: 0.3s all ease;
      transition: 0.3s all ease; }
    .o-skill-service .custom-solution__card-gradient1:hover:after {
      -webkit-animation: rotation2 3s linear infinite;
      animation: rotation2 3s linear infinite;
      background: -webkit-gradient(linear, left top, left bottom, from(#88deb1), color-stop(20%, #1ab864), color-stop(52%, rgba(35, 116, 212, 0.62)), to(rgba(6, 87, 181, 0.62)));
      background: linear-gradient(180deg, #88deb1 0, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%); }
  .o-skill-service .custom-solution__card-gradient1--active:before {
    content: "-";
    top: 16px;
    font-size: 44px;
    color: #568fe0; }
  .o-skill-service .custom-solution__card-gradient1--active:after {
    -webkit-animation: rotation2 3s linear infinite;
    animation: rotation2 3s linear infinite;
    background: -webkit-gradient(linear, left top, left bottom, from(#88deb1), color-stop(20%, #1ab864), color-stop(52%, rgba(35, 116, 212, 0.62)), to(rgba(6, 87, 181, 0.62)));
    background: linear-gradient(180deg, #88deb1 0, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%); }
  .o-skill-service .custom-solution__card-description {
    display: block; }
  .o-skill-service .btn-v2 {
    width: 100%;
    max-width: 434px;
    font-size: 16px; }
    @media screen and (min-width: 767px) {
      .o-skill-service .btn-v2 {
        flex: 0 0 434px;
        font-size: 17px; } }
  .o-skill-service .btn-v2-small {
    max-width: 310px; }
    @media screen and (min-width: 767px) {
      .o-skill-service .btn-v2-small {
        flex: auto;
        font-size: 17px; } }

.skill-technologies-tab__heading {
  font-family: Poppins;
  font-size: 26px;
  line-height: 35px;
  color: #262e36;
  margin-bottom: 15px; }
  @media screen and (min-width: 767px) {
    .skill-technologies-tab__heading {
      font-size: 38px;
      line-height: 45px; } }
  @media screen and (min-width: 992px) {
    .skill-technologies-tab__heading {
      font-size: 48px;
      line-height: 58px; } }

.skill-technologies-tab__subheading {
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #3cc065;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .skill-technologies-tab__subheading {
      font-size: 25px;
      line-height: 34px; } }

.skill-technologies-tab .o-accordion__tab {
  font-family: Poppins;
  font-size: 24px;
  line-height: 55px;
  color: #262e36;
  font-weight: 700;
  padding: 15px 0;
  border: none;
  flex-shrink: 0; }
  .skill-technologies-tab .o-accordion__tab--small {
    font-size: 22px; }
  .skill-technologies-tab .o-accordion__tab--short {
    font-size: 23px; }
  @media screen and (min-width: 992px) {
    .skill-technologies-tab .o-accordion__tab {
      padding: 0; } }
  .skill-technologies-tab .o-accordion__tab:hover {
    color: #355bbf; }
    @media screen and (min-width: 992px) {
      .skill-technologies-tab .o-accordion__tab:hover {
        border-color: #e1e1e1; } }
  .skill-technologies-tab .o-accordion__tab.active {
    color: #355bbf; }
    @media screen and (min-width: 992px) {
      .skill-technologies-tab .o-accordion__tab.active {
        border-color: #e1e1e1; } }

@media screen and (min-width: 992px) {
  .skill-technologies-tab .o-accordion__content {
    background: #355bbf; } }

@media screen and (min-width: 992px) {
  .skill-technologies-tab .o-accordion__content-background {
    background: #355bbf; } }

.skill-technologies-tab .pool-section {
  padding: 0;
  border: none; }

.skill-technologies-tab .o-accordion__content-title {
  font-family: Poppins;
  font-size: 28px;
  line-height: 40px;
  font-weight: 700; }
  @media screen and (min-width: 992px) {
    .skill-technologies-tab .o-accordion__content-title {
      font-size: 36px;
      line-height: 42px;
      color: #fff; } }

.skill-technologies-tab .o-accordion__btn {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  margin: 50px auto 0;
  max-width: 100%; }
  @media screen and (min-width: 767px) {
    .skill-technologies-tab .o-accordion__btn {
      max-width: fit-content; } }
  @media screen and (min-width: 992px) {
    .skill-technologies-tab .o-accordion__btn {
      margin: 50px 0 0;
      font-size: 19px; } }

.skill-technologies-tab .technologies-tab__area {
  padding-top: 0; }
  @media screen and (min-width: 992px) {
    .skill-technologies-tab .technologies-tab__area {
      padding-top: 50px; } }

.skill-technologies-tab .o-accordion__item {
  padding-top: 30px; }

.skill-technologies-tab__bottom-link {
  font-family: Poppins;
  font-size: 14px;
  line-height: 26px;
  color: #355bbf;
  text-decoration: underline;
  font-weight: 500;
  margin-top: 10px;
  display: block;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .skill-technologies-tab__bottom-link {
      display: inline-block;
      color: #bed7ee; } }
  .skill-technologies-tab__bottom-link:hover {
    color: #000; }
    @media screen and (min-width: 992px) {
      .skill-technologies-tab__bottom-link:hover {
        color: #fff; } }

.ai-technologies-tab .o-accordion__tab {
  line-height: 35px;
  margin-bottom: 25px; }
  .ai-technologies-tab .o-accordion__tab span {
    padding-right: 10px; }

.skill-logo .o-clients {
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0; }

.skill-logo h2 {
  font-family: Poppins;
  color: #000;
  font-size: 25px;
  line-height: 29px;
  font-weight: 600;
  margin-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .skill-logo h2 {
      margin-bottom: 10px; } }

@media screen and (min-width: 992px) {
  .o-core-competencies--servicenow .btn-orange {
    max-width: 260px; } }

.o-core-competencies--border-box1 {
  border: 1px solid #20dea5 !important; }

.o-core-competencies--border-box2 {
  border: 1px solid #f29906 !important; }

.o-core-competencies--border-box3 {
  border: 1px solid #64b9f4 !important; }

.o-core-competencies--border-box4 {
  border: 1px solid #4fa941 !important; }

.o-core-competencies--border-box5 {
  border: 1px solid #355bbf !important; }

.o-core-competencies--border-box6 {
  border: 1px solid #fbba14 !important; }

.o-core-competencies--hover-box1:hover {
  border: 1px solid #20dea5; }

.o-core-competencies--hover-box2:hover {
  border: 1px solid #f29906; }

.o-core-competencies--hover-box3:hover {
  border: 1px solid #64b9f4; }

.o-core-competencies--hover-box4:hover {
  border: 1px solid #4fa941; }

.o-core-competencies--hover-box5:hover {
  border: 1px solid #fbba14; }

.o-core-competencies--hover-box6:hover {
  border: 1px solid #355bbf; }

.o-core-competencies--leftlist .o-core-competencies__box {
  text-align: left;
  padding: 16px 30px 30px;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-out; }
  .o-core-competencies--leftlist .o-core-competencies__box:hover {
    background: #04254a;
    border-color: #04254a; }
    .o-core-competencies--leftlist .o-core-competencies__box:hover .o-core-competencies__heading {
      color: #fff; }
    .o-core-competencies--leftlist .o-core-competencies__box:hover .o-core-competencies__content {
      color: #fff; }
    .o-core-competencies--leftlist .o-core-competencies__box:hover .o-core-competencies__item {
      color: #fff; }
      .o-core-competencies--leftlist .o-core-competencies__box:hover .o-core-competencies__item:before {
        background: url(/images/new-skill-section/icons/white-circle-icon.png) no-repeat;
        background-size: 100% 100%; }

.o-core-competencies--leftlist i {
  padding: 10px;
  background: #fff;
  border-radius: 12px;
  width: 85px;
  height: 85px;
  display: inline-flex !important;
  align-items: center;
  justify-content: center; }

.o-core-competencies--leftlist .o-core-competencies__list {
  margin-top: 15px; }

.o-core-competencies--leftlist .o-core-competencies__item {
  position: relative;
  font-family: Poppins;
  font-size: 15px;
  line-height: 25px;
  color: #333;
  margin: 0;
  padding-left: 30px;
  margin-bottom: 8px;
  transition: all 0.5s ease-out; }
  .o-core-competencies--leftlist .o-core-competencies__item:last-child {
    margin-bottom: 0; }
  .o-core-competencies--leftlist .o-core-competencies__item:before {
    content: "";
    position: absolute;
    background: url(/images/e-commerce/black-circle-icon.png) no-repeat;
    background-size: 100% 100%;
    width: 20px;
    height: 20px;
    top: 2px;
    left: 0; }

.o-core-competencies--leftlist .o-core-competencies__btn {
  text-transform: inherit; }

.o-core-competencies--noboximage .o-core-competencies__box {
  min-height: auto; }

.o-core-competencies--noheight .o-core-competencies__box {
  min-height: auto;
  padding: 25px;
  text-align: left; }

.o-core-competencies--noheight i {
  margin-bottom: 0; }

.o-core-competencies--noheight .o-core-competencies__heading {
  font-size: 18px;
  margin: 7px 0 12px; }

.o-core-competencies__spacing {
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .o-core-competencies__spacing {
      padding: 60px 0; } }

.o-core-competencies--four-col .o-core-competencies__box {
  padding: 20px 20px; }

.o-core-competencies--icon-has-no-margin .custom-solution__icon {
  margin-right: 0; }

.o-core-competencies__title {
  font-family: Poppins;
  font-size: 30px;
  line-height: 35px;
  color: #262e36;
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px; }
  @media screen and (min-width: 767px) {
    .o-core-competencies__title {
      font-size: 38px;
      line-height: 45px; } }
  @media screen and (min-width: 992px) {
    .o-core-competencies__title {
      font-size: 48px;
      line-height: 58px; } }

.o-core-competencies__subtitle {
  font-family: Poppins;
  font-size: 21px;
  line-height: 31px;
  color: #333;
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px; }
  @media screen and (min-width: 992px) {
    .o-core-competencies__subtitle {
      margin-bottom: 50px; } }
  .o-core-competencies__subtitle-small {
    font-size: 15px;
    margin-bottom: 10px; }
  .o-core-competencies__subtitle-smallfaq {
    font-size: 15px;
    margin-bottom: 10px;
    margin-top: 40px; }

.o-core-competencies__subtitle-has-h3 {
  margin-bottom: 35px; }

.o-core-competencies__title-h3 {
  margin-bottom: 25px; }
  @media screen and (min-width: 992px) {
    .o-core-competencies__title-h3 {
      margin-bottom: 40px; } }

.o-core-competencies__box {
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  width: 100%;
  min-height: auto;
  height: 100%;
  text-align: center;
  padding: 30px 25px; }
  @media screen and (min-width: 992px) {
    .o-core-competencies__box {
      min-height: 469px;
      padding: 40px 35px; } }
  @media screen and (min-width: 992px) {
    .o-core-competencies__box--little {
      min-height: 350px; } }
  .o-core-competencies__box i {
    margin-bottom: 15px;
    height: 70px;
    display: inline-flex;
    align-items: center; }
  @media screen and (min-width: 992px) {
    .o-core-competencies__box--no-fix {
      min-height: auto; } }
  @media screen and (min-width: 992px) {
    .o-core-competencies__box--ai {
      min-height: auto; } }

.o-core-competencies__heading {
  font-family: Poppins;
  color: #333333;
  font-size: 21px;
  line-height: 25px;
  font-weight: 700;
  margin-bottom: 20px;
  transition: all 0.2s ease-out; }

.o-core-competencies__content {
  font-family: Poppins;
  font-size: 15px;
  line-height: 25px;
  color: #333333;
  margin: 0;
  transition: all 0.2s ease-out; }
  .o-core-competencies__content a {
    color: #3cc065;
    font-weight: 500;
    transition: all 0.5s ease-in-out; }

.o-core-competencies__btn {
  font-size: 16px;
  max-width: 384px;
  text-transform: capitalize;
  margin: 30px auto 0;
  width: 100%;
  padding: 16px; }
  @media screen and (min-width: 767px) {
    .o-core-competencies__btn {
      font-size: 17px; } }
  @media screen and (min-width: 992px) {
    .o-core-competencies__btn {
      margin: 20px auto 0; } }
  .o-core-competencies__btn--notcap {
    text-transform: inherit; }

.o-skill-flow {
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 30px 0;
  overflow: hidden; }
  @media screen and (min-width: 992px) {
    .o-skill-flow {
      padding: 60px 0; } }
  .o-skill-flow__title {
    font-family: Poppins;
    font-size: 30px !important;
    line-height: 35px !important;
    font-weight: 700 !important;
    color: #262e36;
    margin: 0; }
    @media screen and (min-width: 767px) {
      .o-skill-flow__title {
        font-size: 38px !important;
        line-height: 45px !important; } }
    @media screen and (min-width: 992px) {
      .o-skill-flow__title {
        font-size: 48px !important;
        line-height: 58px !important;
        margin-bottom: 50px; } }
  .o-skill-flow__block-label {
    text-align: center;
    margin: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-skill-flow__block-label {
        margin: 0 0 40px; } }
    .o-skill-flow__block-label span {
      font-family: Poppins;
      position: relative;
      display: inline-block;
      font-size: 25px;
      line-height: 25px;
      color: #333333;
      font-weight: 600; }
      .o-skill-flow__block-label span:before {
        position: absolute;
        content: "";
        width: 44px;
        height: 1px;
        background-color: #898989;
        left: -60px;
        top: 50%; }
      .o-skill-flow__block-label span:after {
        position: absolute;
        content: "";
        width: 44px;
        height: 1px;
        background-color: #898989;
        right: -60px;
        top: 50%; }
  .o-skill-flow .faq-holder {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: none;
    background-color: transparent; }
    .o-skill-flow .faq-holder.d-active {
      background-color: #fff;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1); }
      .o-skill-flow .faq-holder.d-active .o-skill-flow__panel {
        max-height: fit-content; }
  .o-skill-flow .accordion1:after {
    color: #4492dc !important; }
  .o-skill-flow__btn-title {
    font-family: Poppins;
    font-weight: 600;
    color: #262e36;
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 8px; }
  .o-skill-flow__panel {
    padding: 0 18px;
    background-color: #fff;
    overflow: hidden;
    -webkit-transition: max-height 0.2s ease-out;
    transition: max-height 0.2s ease-out;
    max-height: 0; }
    .o-skill-flow__panel p {
      font-family: Poppins;
      font-size: 20px;
      line-height: 30px;
      color: #262e36;
      margin-bottom: 12px; }
  .o-skill-flow__tool-heading {
    color: #4492dc !important;
    font-size: 18px !important;
    line-height: 25px !important;
    margin: 0 0 8px !important; }
  .o-skill-flow__small-p {
    font-family: Poppins;
    font-size: 15px !important;
    line-height: 25px !important;
    margin: 0; }
  .o-skill-flow__btn {
    font-family: Poppins;
    font-size: 17px;
    max-width: 384px;
    text-transform: capitalize;
    margin: 30px auto 0;
    width: 100%;
    padding: 16px; }

.o-easy-step__spacing {
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .o-easy-step__spacing {
      padding: 60px 0; } }

.o-easy-step__title {
  font-family: Poppins;
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
  color: #262e36;
  text-align: center; }
  @media screen and (min-width: 767px) {
    .o-easy-step__title {
      font-size: 38px;
      line-height: 45px; } }
  @media screen and (min-width: 992px) {
    .o-easy-step__title {
      font-size: 48px;
      line-height: 58px; } }

.o-easy-step__subtitle {
  font-family: Poppins;
  font-size: 18px;
  line-height: 27px;
  color: #333;
  text-align: center;
  font-weight: 500; }
  @media screen and (min-width: 767px) {
    .o-easy-step__subtitle {
      font-size: 21px;
      line-height: 31px; } }

.o-easy-step__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 75px; }
  @media screen and (min-width: 992px) {
    .o-easy-step__list {
      justify-content: space-between;
      align-items: stretch;
      flex-direction: row;
      margin-top: 80px; } }

.o-easy-step__items {
  position: relative;
  max-width: 330px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dddddd;
  text-align: center;
  padding: 0 32px 35px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 120px; }
  @media screen and (min-width: 767px) {
    .o-easy-step__items {
      margin-bottom: 100px; } }
  .o-easy-step__items:last-child {
    margin-bottom: 0; }
  @media screen and (min-width: 992px) {
    .o-easy-step__items {
      width: 28%;
      margin-bottom: 0; } }
  @media screen and (min-width: 1200px) {
    .o-easy-step__items {
      width: 30%; } }
  .o-easy-step__items:after {
    position: absolute;
    content: "";
    width: 55px;
    height: 20px;
    background: url(/images/new-skill-section/icons/step-arrow.svg) no-repeat center;
    background-size: 100%;
    top: inherit;
    transform: rotate(90deg);
    right: 0;
    left: 0;
    bottom: -40px;
    margin: auto; }
    @media screen and (min-width: 992px) {
      .o-easy-step__items:after {
        transform: translateY(-50%);
        left: inherit;
        top: 50%;
        bottom: inherit;
        transform: translateY(-50%);
        right: -55px;
        margin: 0; } }
  .o-easy-step__items:last-child:after {
    display: none; }

.o-easy-step__counter {
  position: relative;
  width: 116px;
  height: 116px;
  border-radius: 50%;
  background-color: rgba(53, 91, 191, 0.1);
  font-family: Poppins;
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -50px auto 20px; }
  .o-easy-step__counter span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: #355bbf; }

.o-easy-step__heading {
  font-family: Poppins;
  color: #333333;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 20px; }

.o-easy-step__content {
  font-family: Poppins;
  color: #333333;
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 20px; }

.o-easy-step__contentbold {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin: 0; }

.o-easy-step__link {
  font-family: Poppins;
  color: #4492dc;
  font-size: 14px;
  line-height: 22px; }

.o-easy-step__btn {
  width: 100%;
  max-width: 100%;
  font-size: 19px;
  font-weight: 600;
  margin: 50px auto 0;
  text-transform: capitalize;
  padding: 16px;
  max-width: 100%; }
  @media screen and (min-width: 767px) {
    .o-easy-step__btn {
      max-width: 330px; } }

.o-journey {
  background-color: #f9f9f9;
  border-top: 1px solid #e4e4e4; }
  .o-journey__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-journey__spacing {
        padding: 35px 0 45px; } }
  .o-journey__title {
    font-family: Poppins;
    color: #0e2743;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    text-align: center;
    margin: 0 0 10px; }
    @media screen and (min-width: 767px) {
      .o-journey__title {
        font-size: 21px;
        line-height: 38px; } }
  .o-journey__list {
    display: flex;
    align-items: center;
    justify-content: center; }
  .o-journey__item {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (min-width: 992px) {
      .o-journey__item {
        margin: 10px 15px !important;
        display: inherit;
        align-items: flex-start; } }
  .o-journey .owl-stage {
    display: flex;
    align-items: center; }
  .o-journey .owl-carousel .owl-item img {
    height: 94%;
    object-fit: contain; }
    @media screen and (min-width: 992px) {
      .o-journey .owl-carousel .owl-item img {
        height: inherit;
        object-fit: inherit; } }

.o-skill-how-help {
  background: #052c58 url(/images/new-home-banner/banner-gradient.png) no-repeat; }
  .o-skill-how-help .how-help-you__heading {
    font-size: 25px;
    line-height: 38px;
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .o-skill-how-help .how-help-you__heading {
        font-size: 48px;
        line-height: 62px;
        margin-bottom: 45px; } }
  @media screen and (min-width: 992px) {
    .o-skill-how-help .how-help-you__card-heading {
      line-height: 46px; } }
  .o-skill-how-help .how-we-help-buttons {
    padding: 15px 0;
    height: 58px;
    font-size: 14px; }
    @media screen and (min-width: 992px) {
      .o-skill-how-help .how-we-help-buttons {
        font-size: 17px; } }
  .o-skill-how-help .how-we-help-knowmore {
    font-family: Poppins;
    text-align: center;
    font-size: 14px;
    margin: 15px auto 0;
    text-decoration: underline;
    font-weight: 600;
    display: inline-block;
    color: #4492dc; }
    .o-skill-how-help .how-we-help-knowmore:hover {
      color: #ff5800; }
  @media screen and (min-width: 992px) {
    .o-skill-how-help .let-help {
      padding: 0 18px 20px 18px; } }
  @media screen and (min-width: 1600px) {
    .o-skill-how-help .let-help {
      padding: 0 19px 20px 19px; } }
  @media screen and (min-width: 1705px) {
    .o-skill-how-help .let-help {
      padding: 0 18px 20px 18px; } }
  @media screen and (min-width: 1920px) {
    .o-skill-how-help .let-help {
      max-width: 1075px;
      padding: 0 18px 20px 18px; } }
  .o-skill-how-help .let-help:before {
    border: none; }
  @media screen and (min-width: 1600px) {
    .o-skill-how-help .let-help__inner-wrapper {
      padding: 17px 14px 17px 14px; } }
  @media screen and (min-width: 1705px) {
    .o-skill-how-help .let-help__inner-wrapper {
      padding: 17px 12px 17px 12px; } }
  @media screen and (min-width: 1920px) {
    .o-skill-how-help .let-help__inner-wrapper {
      padding: 17px 12px 17px 15px; } }
  .o-skill-how-help .let-help__checkbox-label {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500; }
  .o-skill-how-help .let-help__btn {
    width: 360px;
    font-size: 14px;
    font-weight: 600;
    padding: 17px 12px; }
    @media screen and (min-width: 992px) {
      .o-skill-how-help .let-help__btn {
        font-size: 17px; } }

.skill-review .custom-review__partner {
  display: none; }

.o-why-hire {
  background-color: #f9f9f9;
  border-top: 1px solid #e4e4e4; }
  .o-why-hire__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-why-hire__spacing {
        padding: 60px 0; } }
  .o-why-hire__title {
    font-family: Poppins;
    font-size: 30px;
    line-height: 35px;
    color: #262e36;
    text-align: center;
    font-weight: 700;
    margin-bottom: 30px; }
    @media screen and (min-width: 767px) {
      .o-why-hire__title {
        font-size: 38px;
        line-height: 45px; } }
    @media screen and (min-width: 992px) {
      .o-why-hire__title {
        font-size: 48px;
        line-height: 58px;
        margin-bottom: 50px; } }
  .o-why-hire__subheading {
    font-family: Poppins;
    font-size: 18px;
    line-height: 28px;
    color: #333333;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .o-why-hire__subheading {
        font-size: 21px;
        line-height: 31px;
        margin-bottom: 60px; } }
  .o-why-hire__list {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #dae9fb; }
    .o-why-hire__list--massonary {
      display: inherit;
      column-count: auto;
      column-gap: 0; }
      @media screen and (min-width: 767px) {
        .o-why-hire__list--massonary {
          column-count: 2; } }
      .o-why-hire__list--massonary .o-why-hire__items {
        page-break-inside: avoid;
        width: 100%; }
        @media screen and (min-width: 767px) {
          .o-why-hire__list--massonary .o-why-hire__items:nth-child(even) {
            border-right: 1px solid #dae9fb;
            padding-left: 0; } }
        @media screen and (min-width: 767px) {
          .o-why-hire__list--massonary .o-why-hire__items:first-child, .o-why-hire__list--massonary .o-why-hire__items:nth-child(2), .o-why-hire__list--massonary .o-why-hire__items:nth-child(3), .o-why-hire__list--massonary .o-why-hire__items:nth-child(4), .o-why-hire__list--massonary .o-why-hire__items:nth-child(5) {
            padding-right: 30px;
            border-right: none; } }
        @media screen and (min-width: 767px) {
          .o-why-hire__list--massonary .o-why-hire__items:nth-child(6), .o-why-hire__list--massonary .o-why-hire__items:nth-child(7), .o-why-hire__list--massonary .o-why-hire__items:nth-child(8), .o-why-hire__list--massonary .o-why-hire__items:nth-child(9), .o-why-hire__list--massonary .o-why-hire__items:nth-child(10), .o-why-hire__list--massonary .o-why-hire__items:nth-child(11) {
            border-right: none;
            border-left: 1px solid #dae9fb;
            padding-right: 0; } }
        @media screen and (min-width: 767px) {
          .o-why-hire__list--massonary .o-why-hire__items:nth-child(6), .o-why-hire__list--massonary .o-why-hire__items:nth-child(7), .o-why-hire__list--massonary .o-why-hire__items:nth-child(8), .o-why-hire__list--massonary .o-why-hire__items:nth-child(9), .o-why-hire__list--massonary .o-why-hire__items:nth-child(10), .o-why-hire__list--massonary .o-why-hire__items:nth-child(11) {
            padding-left: 30px; } }
        .o-why-hire__list--massonary .o-why-hire__items .content-part-side ol {
          padding-left: 18px; }
          .o-why-hire__list--massonary .o-why-hire__items .content-part-side ol li {
            font-family: Poppins;
            font-size: 15px;
            line-height: 25px;
            color: #262e36;
            margin: 0; }
  .o-why-hire__items {
    width: 100%;
    padding: 20px;
    border: 1px solid #dae9fb;
    border-bottom: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; }
    @media screen and (min-width: 767px) {
      .o-why-hire__items {
        width: 50%;
        border-left: none;
        border-bottom: none; } }
    @media screen and (min-width: 992px) {
      .o-why-hire__items {
        padding: 40px 0;
        flex-direction: row; } }
    .o-why-hire__items .content-part-side {
      margin-left: 0;
      margin-top: 20px; }
      @media screen and (min-width: 992px) {
        .o-why-hire__items .content-part-side {
          margin-left: 10px;
          margin-top: 0; } }
      .o-why-hire__items .content-part-side h5 {
        font-family: Poppins;
        font-size: 22px;
        line-height: 27px;
        font-weight: 700;
        margin-bottom: 5px;
        color: #262e36; }
      .o-why-hire__items .content-part-side p {
        font-family: Poppins;
        font-size: 15px;
        line-height: 25px;
        color: #262e36;
        margin: 0; }
    @media screen and (min-width: 767px) {
      .o-why-hire__items:nth-child(even) {
        padding: 40px 0 40px 30px;
        border-right: none; } }
    @media screen and (min-width: 767px) {
      .o-why-hire__items:nth-child(odd) {
        padding: 40px 30px 40px 0; } }
  .o-why-hire__btn {
    font-size: 17px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 40px auto 0;
    max-width: 100%;
    width: 100%;
    padding: 16px; }
    @media screen and (min-width: 767px) {
      .o-why-hire__btn {
        max-width: 305px; } }
    .o-why-hire__btn--django {
      max-width: 335px; }
  .o-why-hire .btn--lg {
    max-width: 345px; }
  @media screen and (min-width: 992px) {
    .o-why-hire--hire-salesforce .o-why-hire__title {
      margin-bottom: 25px; } }

.skill-bottom-form .new-bottom-form {
  background: #052c58 url(/images/new-home-banner/banner-gradient.png) no-repeat !important;
  background-position: 0 0; }

@media screen and (min-width: 992px) {
  .skill-bottom-form--dynamic-supply-chain .new-bottom-form__btn-wrapper button {
    width: 320px; } }

.o-bottom-work {
  background: #081c36 url(/images/new-skill-section/left-top-courner.png) no-repeat; }
  .o-bottom-work__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-bottom-work__spacing {
        padding: 45px 0; } }
  .o-bottom-work__list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: 830px;
    width: 100%;
    margin: auto;
    border: 1px solid #63ff93;
    flex-direction: column; }
    @media screen and (min-width: 992px) {
      .o-bottom-work__list {
        flex-direction: row; } }
    .o-bottom-work__list:before {
      position: absolute;
      content: "";
      width: 50%;
      height: 101%;
      background: #081c36;
      background: linear-gradient(90deg, rgba(8, 28, 54, 0.95) 0%, rgba(8, 28, 54, 0.7) 70%, rgba(8, 28, 54, 0.1) 100%);
      left: -1px;
      top: -1px; }
    .o-bottom-work__list:after {
      position: absolute;
      content: "";
      width: 50%;
      height: 101%;
      background: #081c36;
      background: linear-gradient(270deg, rgba(8, 28, 54, 0.95) 0%, rgba(8, 28, 54, 0.7) 70%, rgba(8, 28, 54, 0.1) 100%);
      right: -1px;
      top: -1px; }
  .o-bottom-work__items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #63ff93;
    padding: 35px;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    @media screen and (min-width: 767px) {
      .o-bottom-work__items {
        flex-direction: row;
        justify-content: space-between; } }
    @media screen and (min-width: 992px) {
      .o-bottom-work__items {
        width: 50%;
        border-bottom: none;
        border-right: 1px solid #63ff93; } }
    .o-bottom-work__items:first-child .o-bottom-work__images {
      margin-left: 0; }
      @media screen and (min-width: 992px) {
        .o-bottom-work__items:first-child .o-bottom-work__images {
          margin-left: -115px; } }
    .o-bottom-work__items:last-child {
      border-right: none;
      flex-direction: column-reverse; }
      @media screen and (min-width: 767px) {
        .o-bottom-work__items:last-child {
          flex-direction: row-reverse; } }
      @media screen and (min-width: 992px) {
        .o-bottom-work__items:last-child {
          flex-direction: row; } }
      .o-bottom-work__items:last-child .o-bottom-work__images {
        margin-right: 0;
        background-color: #081c36; }
        @media screen and (min-width: 992px) {
          .o-bottom-work__items:last-child .o-bottom-work__images {
            margin-right: -90px; } }
      .o-bottom-work__items:last-child .o-bottom-work__text-wrap {
        flex: 0 0 68%; }
        @media screen and (min-width: 992px) {
          .o-bottom-work__items:last-child .o-bottom-work__text-wrap {
            flex: 0 0 72%; } }
  .o-bottom-work__images {
    position: relative;
    z-index: 1; }
  .o-bottom-work__text-wrap {
    flex: 0 0 68%;
    position: relative;
    z-index: 1; }
    @media screen and (min-width: 992px) {
      .o-bottom-work__text-wrap {
        flex: 0 0 77%; } }
  .o-bottom-work__heading-text {
    font-family: Poppins;
    font-size: 26px;
    line-height: 32px;
    font-weight: 600;
    color: #fff; }
    @media screen and (min-width: 992px) {
      .o-bottom-work__heading-text {
        font-size: 31px;
        line-height: 38px; } }
  .o-bottom-work__heigh-text {
    color: #36e079; }
  .o-bottom-work__content-text {
    font-family: Poppins;
    color: #36e079;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0; }
    @media screen and (min-width: 992px) {
      .o-bottom-work__content-text {
        font-size: 19px; } }

.for-tablet {
  display: block; }
  @media screen and (min-width: 1024px) {
    .for-tablet {
      display: none; } }

.o-skill-faq h2 {
  padding-bottom: 15px;
  margin: 0; }
  @media screen and (min-width: 992px) {
    .o-skill-faq h2 {
      line-height: 58px; } }

.o-skill-faq__sub-heading {
  font-family: Poppins;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #333;
  font-weight: 600;
  margin-bottom: 30px; }
  @media screen and (min-width: 992px) {
    .o-skill-faq__sub-heading {
      font-size: 25px;
      line-height: 34px; } }

.o-skill-faq .accordion1 span {
  width: 95%;
  line-height: 28px; }

.o-skill-testimonial .o-testimonial__heading {
  font-size: 32px;
  line-height: 40px; }
  @media screen and (min-width: 767px) {
    .o-skill-testimonial .o-testimonial__heading {
      font-size: 38px;
      line-height: 45px; } }
  @media screen and (min-width: 992px) {
    .o-skill-testimonial .o-testimonial__heading {
      font-size: 48px;
      line-height: 58px; } }

.o-skill-testimonial .o-testimonial__itemimage {
  height: 259px; }

.o-skill-testimonial .o-testimonial__itemcontent {
  padding: 20px 25px 35px 25px;
  min-height: auto;
  height: auto;
  background-color: #fff; }
  @media screen and (min-width: 992px) {
    .o-skill-testimonial .o-testimonial__itemcontent {
      min-height: 276px; } }

.o-skill-testimonial .o-testimonial__itemtitle {
  font-family: Poppins;
  font-size: 20px;
  line-height: 27px;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px; }

.o-skill-testimonial .o-testimonial__itemtech {
  font-family: Poppins;
  font-size: 15px;
  line-height: 22px;
  color: #333;
  margin-bottom: 10px;
  font-weight: 400; }

.o-skill-testimonial .o-testimonial__itembtn {
  font-family: Poppins;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600; }

.o-skill-testimonial .o-testimonial__list .owl-nav {
  margin-top: 40px; }

.o-key-diff-modify {
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0;
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .o-key-diff-modify {
      padding: 60px 0; } }
  .o-key-diff-modify h2 {
    font-family: Poppins;
    font-size: 30px;
    line-height: 35px;
    font-weight: 700;
    color: #262e36;
    margin-bottom: 15px; }
    @media screen and (min-width: 767px) {
      .o-key-diff-modify h2 {
        font-size: 38px;
        line-height: 46px; } }
    @media screen and (min-width: 992px) {
      .o-key-diff-modify h2 {
        font-size: 48px;
        line-height: 58px; } }
  .o-key-diff-modify .cmn-heading-para {
    font-family: Poppins;
    font-size: 16px;
    line-height: 26px;
    color: #333;
    text-align: center !important;
    font-weight: 500 !important; }
    @media screen and (min-width: 992px) {
      .o-key-diff-modify .cmn-heading-para {
        font-size: 21px;
        line-height: 31px; } }
  .o-key-diff-modify .clode-computing-right-side-text h4 {
    font-family: Poppins;
    font-weight: 700; }
  .o-key-diff-modify .clode-computing-right-side-text p {
    font-family: Poppins; }

.leverage-section__modify {
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .leverage-section__modify {
      padding: 60px 0; } }
  .leverage-section__modify h2 {
    font-family: Poppins;
    font-size: 30px;
    line-height: 35px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 15px; }
    @media screen and (min-width: 767px) {
      .leverage-section__modify h2 {
        font-size: 38px;
        line-height: 46px; } }
    @media screen and (min-width: 992px) {
      .leverage-section__modify h2 {
        font-size: 48px;
        line-height: 58px; } }
  .leverage-section__modify .o-key-diff-box h4 {
    font-family: Poppins;
    font-weight: 700; }
  .leverage-section__modify .o-key-diff-box p {
    font-family: Poppins; }

.iot-case__modify h2 {
  font-family: Poppins;
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
  color: #262e36;
  margin-bottom: 15px; }
  @media screen and (min-width: 767px) {
    .iot-case__modify h2 {
      font-size: 38px;
      line-height: 46px; } }
  @media screen and (min-width: 992px) {
    .iot-case__modify h2 {
      font-size: 48px;
      line-height: 58px; } }

.iot-case__modify .iot-case-wrapper-box h3 {
  font-family: Poppins;
  font-weight: 700;
  margin: 20px 0; }

.iot-case__modify .iot-case-wrapper-box li {
  font-family: Poppins;
  font-size: 16px;
  line-height: 26px; }

.what-test-section--servicenow .btn-orange {
  font-size: 17px;
  max-width: 260px; }

.what-test-section__modify {
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .what-test-section__modify {
      padding: 60px 0; } }
  .what-test-section__modify h2 {
    font-family: Poppins;
    font-size: 30px;
    line-height: 35px;
    font-weight: 700;
    color: #262e36;
    margin-bottom: 30px; }
    @media screen and (min-width: 767px) {
      .what-test-section__modify h2 {
        font-size: 38px;
        line-height: 46px; } }
    @media screen and (min-width: 992px) {
      .what-test-section__modify h2 {
        font-size: 48px;
        line-height: 58px; } }
  .what-test-section__modify li {
    font-family: Poppins; }

.mobile-app {
  font-family: Poppins !important; }
  .mobile-app h1,
  .mobile-app h2,
  .mobile-app h3,
  .mobile-app h4,
  .mobile-app h5,
  .mobile-app h6,
  .mobile-app p {
    font-family: Poppins !important; }
  .mobile-app .spiral-col-box p {
    margin-bottom: 0; }

.no-border {
  border: none !important; }

.normal-text-button {
  text-transform: inherit; }

.light-text-button {
  text-transform: initial;
  height: 62px;
  padding: 16px; }

.skill-technologies-tab .pool-section .pool-box-list {
  height: 77px; }
  .skill-technologies-tab .pool-section .pool-box-list img {
    max-height: 38px; }

.title-margin-bottom {
  margin: 0 0 20px !important; }
  @media screen and (min-width: 992px) {
    .title-margin-bottom {
      margin: 0 0 35px !important; } }

.o-skill-service .padding-bottom-30 {
  padding-bottom: 5px; }
  @media screen and (min-width: 992px) {
    .o-skill-service .padding-bottom-30 {
      padding-bottom: 30px; } }

.o-skill-service .experience-list ul li a {
  position: relative;
  padding: 13px 15px;
  display: flex;
  z-index: 1;
  height: 100%;
  align-items: center;
  justify-content: center; }
  @media screen and (min-width: 992px) {
    .o-skill-service .experience-list ul li a {
      padding: 30px 15px; } }

@media screen and (min-width: 992px) {
  .mb-75 {
    margin-bottom: 75px !important; } }

@media screen and (min-width: 992px) {
  .mb-55 {
    margin-bottom: 55px !important; } }

@media screen and (min-width: 992px) {
  .mobile-app-serv .custom-solution__card-heading {
    font-size: 19px; } }

span.underline {
  position: relative;
  display: inline-block; }
  span.underline:before {
    position: absolute;
    left: 0;
    bottom: -2px;
    content: "";
    background-color: #fff;
    height: 2px;
    width: 100%; }

@media screen and (min-width: 992px) {
  .dita-engagement .how-help-you__card-heading {
    font-size: 29px;
    line-height: 42px; } }

.btn--notcap {
  text-transform: initial; }

.what-are--staff-aug .what-are__title {
  font-weight: 500; }

.why-choose--staff-aug .why-choose__heading {
  font-weight: 500; }
  @media screen and (min-width: 992px) {
    .why-choose--staff-aug .why-choose__heading {
      margin-bottom: 60px; } }

@media screen and (min-width: 992px) {
  .why-choose--staff-aug .why-choose__list {
    flex-wrap: wrap;
    justify-content: center; } }

.why-choose--staff-aug .why-choose__item {
  max-width: inherit;
  background: #fff;
  border: 1px solid #d0e3f5; }
  @media screen and (min-width: 992px) {
    .why-choose--staff-aug .why-choose__item {
      flex: 0 0 49%;
      height: 230px; } }
  .why-choose--staff-aug .why-choose__item:before {
    display: none; }

.why-choose--staff-aug .why-choose__items {
  background: #fff; }

.why-choose--staff-aug .why-choose__items-top {
  display: flex;
  align-items: center; }
  .why-choose--staff-aug .why-choose__items-top i {
    margin-right: 15px;
    display: inline-block; }

.why-choose--staff-aug .why-choose__bottom-text {
  font-family: Poppins;
  font-size: 30px;
  line-height: 40px;
  color: #262e36;
  margin-top: 35px;
  font-weight: 500; }

.development-banner {
  background: #0d2642; }
  .development-banner .o-skill-banner__rightbox::before {
    display: none; }

.dev-service .custom-solution__heading {
  font-weight: 500; }

.dev-service .custom-solution__sub-heading {
  font-weight: 500; }

@media screen and (min-width: 992px) {
  .dev-service .custom-solution__card-description-wrapper {
    margin: 0;
    padding: 0 20px; } }

.dev-service .custom-solution__card-gradient1 {
  padding: 0;
  border: 1px solid #d7d6d6;
  margin-bottom: 15px; }
  @media screen and (min-width: 992px) {
    .dev-service .custom-solution__card-gradient1 {
      margin-bottom: 30px; } }
  .dev-service .custom-solution__card-gradient1:after {
    display: none; }
  .dev-service .custom-solution__card-gradient1:before {
    font-family: "Montserrat", sans-serif;
    font-weight: 400; }
    @media screen and (min-width: 992px) {
      .dev-service .custom-solution__card-gradient1:before {
        top: 50px;
        right: 25px; } }
  .dev-service .custom-solution__card-gradient1.custom-solution__card-gradient1-color1 {
    border-bottom: 4px solid #64b9f4; }
  .dev-service .custom-solution__card-gradient1.custom-solution__card-gradient1-color2 {
    border-bottom: 4px solid #ffc327; }
  .dev-service .custom-solution__card-gradient1.custom-solution__card-gradient1-color3 {
    border-bottom: 4px solid #20dea5; }
  .dev-service .custom-solution__card-gradient1.custom-solution__card-gradient1-color4 {
    border-bottom: 4px solid #a15fae; }
  .dev-service .custom-solution__card-gradient1.custom-solution__card-gradient1-color5 {
    border-bottom: 4px solid #fda474; }

@media screen and (min-width: 992px) {
  .dev-service .custom-solution__collapse-heading {
    min-height: 100px;
    display: flex;
    align-items: center;
    padding: 0 20px; } }

@media screen and (min-width: 992px) {
  .dev-service .custom-solution__card-heading {
    font-size: 22px;
    line-height: 25px; } }

.dev-service .custom-solution__card {
  background: none; }
  @media screen and (min-width: 992px) {
    .dev-service .custom-solution__card {
      padding: 24px 20px 24px 20px;
      padding: 0; } }

.banner.development-banner {
  background: #0f1858 url(/images/custom-dev/banner-pattern.jpg) no-repeat 0 0/cover;
  position: relative;
  padding: 70px 20px 30px; }
  @media screen and (min-width: 992px) {
    .banner.development-banner {
      padding: 150px 20px 65px; } }
  .banner.development-banner .o-skill-banner__rightbox {
    background: #1a2979;
    border-color: #5158d6; }

@media screen and (min-width: 992px) {
  .banner .o-skill-banner__rightbox--ver-2 {
    padding: 70px 50px 70px;
    margin-top: 0; } }

.banner .o-skill-banner__rightoverlay {
  margin-top: 20px; }
  @media screen and (min-width: 992px) {
    .banner .o-skill-banner__rightoverlay {
      margin-top: 0; } }

.banner .o-skill-banner__rightboxtop {
  padding-top: 0; }

@media screen and (min-width: 992px) {
  .banner .o-skill-banner__rightboxtitle {
    font-size: 28px;
    line-height: 42px;
    font-weight: 600;
    margin-bottom: 30px; } }

.banner .o-skill-banner__rightboxtitle a span:before {
  height: 4px; }

.banner__top-rated {
  margin-top: 25px; }
  @media screen and (min-width: 992px) {
    .banner__top-rated {
      max-width: 466px;
      display: flex;
      border: 1px solid #5158d6;
      border-radius: 5px;
      justify-content: flex-end;
      align-items: center;
      overflow: hidden;
      margin-top: 0;
      margin-bottom: 40px;
      background: #1a2979; } }

.banner__top-rated-text {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  font-weight: 500;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .banner__top-rated-text {
      padding: 14px 15px;
      text-align: left; } }

.banner__top-rated-highlight {
  color: #84a5f9;
  font-weight: 700; }

.banner__top-rating {
  margin: 20px auto;
  flex: 0 0 183px;
  max-width: 183px;
  border-radius: 5px;
  /* Style for the Splide container */
  /* Style for each slide */
  /* Add any additional styles if needed */ }
  @media screen and (min-width: 992px) {
    .banner__top-rating {
      margin: 0;
      border-radius: 0; } }
  .banner__top-rating .splide__track {
    overflow: hidden; }
  .banner__top-rating .splide__slide {
    display: flex;
    align-items: center;
    justify-content: center; }

.banner__top-rating-wrapper {
  display: flex;
  align-items: center;
  padding: 10px 0;
  justify-content: center; }

.banner__top-rating-left {
  padding-right: 10px; }

.banner__top-rating-right {
  flex-direction: column;
  display: flex;
  position: relative;
  padding-left: 13px; }
  .banner__top-rating-right:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    background: #d7dfe6;
    opacity: 0.5;
    width: 1px;
    height: 29px;
    text-align: left; }

.banner__top-rating-number {
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  font-weight: 600;
  text-align: left; }

.banner__top-rating-review {
  font-size: 12px;
  color: #ffffff;
  font-weight: 600; }

@media screen and (min-width: 992px) {
  .banner .o-skill-banner__bannertitle {
    margin-bottom: 20px; } }

.banner .o-skill-banner__bannersubtitle {
  color: #cee8ff;
  font-weight: 500; }
  @media screen and (min-width: 992px) {
    .banner .o-skill-banner__bannersubtitle {
      font-size: 22px;
      line-height: 32px; } }

.dev-service-benefit h2 {
  font-weight: 500 !important; }
  @media screen and (min-width: 992px) {
    .dev-service-benefit h2 {
      margin-bottom: 20px !important; } }

.dev-service-benefit .service-card {
  position: relative; }
  .dev-service-benefit .service-card::before {
    content: "";
    position: absolute;
    top: -3px;
    left: 30px;
    width: 68px;
    height: 6px;
    background: #000; }

.dev-service-benefit .service-card-color1::before {
  background: #64b9f4; }

.dev-service-benefit .service-card-color2::before {
  background: #f29906; }

.dev-service-benefit .service-card-color3::before {
  background: #20dea5; }

.dev-service-benefit .service-card-color4::before {
  background: #4fa941; }

.dev-service-benefit .service-card-color5::before {
  background: #fbba14; }

.dev-service-benefit .service-card-color6::before {
  background: #a15fae; }

.custom-dev-tech {
  border-bottom: 1px solid #d8d8d8; }
  .custom-dev-tech .o-accordion__content-background {
    background: #f7f9fb;
    border: 1px solid #d8d8d8;
    border-bottom: none; }
  .custom-dev-tech .o-accordion__content-title {
    color: #333333;
    font-weight: 600; }
  .custom-dev-tech .o-accordion__content {
    background: #f7f9fb; }
    @media screen and (min-width: 992px) {
      .custom-dev-tech .o-accordion__content {
        border: 1px solid #d8d8d8;
        border-bottom: none;
        border-right: none; } }
  .custom-dev-tech .o-accordion__button {
    font-weight: 600; }
  .custom-dev-tech .skill-technologies-tab__bottom-link {
    color: #355bbf; }
  .custom-dev-tech .o-accordion__tab {
    font-weight: 600; }
    @media screen and (min-width: 992px) {
      .custom-dev-tech .o-accordion__tab {
        line-height: 50px; } }
  .custom-dev-tech .o-accordion__tab.active {
    border-bottom: 1px solid #dde2ea; }
  @media screen and (min-width: 992px) {
    .custom-dev-tech .pool-section .pool-box-list small {
      font-size: 16px;
      font-weight: 400; } }
  @media screen and (min-width: 992px) {
    .custom-dev-tech .o-accordion__btn {
      margin-top: 35px; } }
  .custom-dev-tech .tab-pane:last-child .o-accordion__content {
    border-bottom: none; }

.custom-dev-method {
  background: #fff; }
  @media screen and (min-width: 992px) {
    .custom-dev-method .custom-solution__sub-heading {
      margin: 0 0 30px; } }
  .custom-dev-method .o-we-develop__box {
    border-radius: 5px;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    box-shadow: none; }
  .custom-dev-method .o-we-develop__box-color1 {
    border-color: #64b9f4; }
  .custom-dev-method .o-we-develop__box-color2 {
    border-color: #20dea5; }
  .custom-dev-method .o-we-develop__box-color3 {
    border-color: #fda474; }
  .custom-dev-method .o-we-develop__box-color4 {
    border-color: #a15fae; }
  .custom-dev-method .o-we-develop__boxcontent {
    font-size: 16px; }

.custom-dev-journey {
  border-top: none; }

.o-testimonial__heading .o-testimonial__heading {
  font-weight: 500; }

.custom-dev-case-study .o-testimonial__heading {
  font-weight: 500; }

.custom-dev-case-study .o-testimonial__item {
  width: 100%; }

.custom-dev-case-study .o-testimonial__itemtitle {
  font-weight: 600;
  font-size: 21px; }

@media screen and (min-width: 992px) {
  .custom-dev-case-study--staff-aug-it .o-testimonial__item {
    margin: 0; } }

.custom-dev-case-study--staff-aug-it .o-testimonial__itemcontent {
  min-height: inherit;
  height: inherit;
  border: none; }

.custom-dev-faq h2 {
  font-weight: 500; }

.splide__list--data-eng {
  height: auto !important; }

.splide__list--auto-height {
  height: auto !important; }

.static-image {
  display: block;
  /* Show by default */ }

.js-banner-review-slider {
  display: none;
  /* Hide slider initially */ }

@media screen and (min-width: 992px) {
  .o-service-banner--dynamics-365-supply-chain-manufacturing-consulting .o-skill-banner__rightboxtop .btn {
    max-width: 350px !important; } }

/* ANCHOR - Service Individual page design */
.o-service-banner .o-skill-banner__bannertitle {
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 20px; }
  @media screen and (min-width: 767px) {
    .o-service-banner .o-skill-banner__bannertitle {
      font-size: 41px;
      line-height: 45px;
      text-align: left; } }
  @media screen and (min-width: 992px) {
    .o-service-banner .o-skill-banner__bannertitle {
      font-size: 50px;
      line-height: 64px;
      margin-bottom: 30px; } }

@media screen and (min-width: 992px) {
  .o-service-banner--ai-development .o-skill-banner__bannertitle {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 20px; } }

@media screen and (min-width: 992px) {
  .o-service-banner--ai-development .o-skill-banner__bannersubtitle {
    font-size: 20px;
    line-height: 28px; } }

@media screen and (min-width: 992px) {
  .o-service-banner--ai-development .banner__top-rated {
    margin-bottom: 25px; } }

@media screen and (min-width: 992px) {
  .o-service-banner--microsoft-dynamics-365-consultant .o-skill-banner__bannertitle {
    font-size: 44px;
    line-height: 56px; } }

@media screen and (min-width: 992px) {
  .o-service-banner--microsoft-dynamic-365-consulting-services .o-skill-banner__bannertitle {
    font-size: 37px;
    line-height: 50px; } }

@media screen and (min-width: 992px) {
  .o-service-banner--dynamics-365-supply-chain-manufacturing-consulting .o-skill-banner__bannertitle {
    font-size: 40px;
    line-height: 52px; } }

@media screen and (min-width: 992px) {
  .o-service-banner--microsoft-dynamics-365-customer-service-integration .o-skill-banner__bannertitle {
    font-size: 40px;
    line-height: 52px; } }

@media screen and (min-width: 992px) {
  .o-service-banner--microsoft-dynamics-365-consulting-companies .o-skill-banner__bannertitle {
    font-size: 40px;
    line-height: 52px; } }

.o-we-develop__spacing {
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .o-we-develop__spacing {
      padding: 60px 0; } }

.o-we-develop__title {
  font-family: Poppins;
  font-size: 30px;
  line-height: 35px;
  color: #262E36;
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px; }
  @media screen and (min-width: 767px) {
    .o-we-develop__title {
      font-size: 38px;
      line-height: 46px; } }
  @media screen and (min-width: 992px) {
    .o-we-develop__title {
      font-size: 48px;
      line-height: 58px; } }
  @media screen and (min-width: 992px) {
    .o-we-develop__title--dita {
      font-size: 51px;
      line-height: 60px; } }

.o-we-develop__subtitle {
  font-family: Poppins;
  font-size: 21px;
  line-height: 30px;
  color: #333333;
  text-align: center;
  font-weight: 500; }
  .o-we-develop__subtitle--dita {
    margin-bottom: 20px;
    line-height: 31px; }
    @media screen and (min-width: 992px) {
      .o-we-develop__subtitle--dita {
        margin-bottom: 30px; } }
  .o-we-develop__subtitle--merginbtm {
    margin-bottom: 20px;
    line-height: 31px; }
    @media screen and (min-width: 992px) {
      .o-we-develop__subtitle--merginbtm {
        margin-bottom: 50px; } }

.o-we-develop__box {
  background-color: #fff;
  border: 1px solid #DDDDDD;
  padding: 30px;
  box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.08);
  height: 100%; }
  .o-we-develop__box--color {
    position: relative; }
    @media screen and (min-width: 992px) {
      .o-we-develop__box--color {
        padding: 30px 30px 30px 30px; } }
    .o-we-develop__box--color:before {
      content: '';
      position: absolute;
      top: 0;
      left: 30px;
      width: 89px;
      height: 5px; }
  .o-we-develop__box--color1:before {
    background: #64B9F4; }
  .o-we-develop__box--color2:before {
    background: #FBBA14; }
  .o-we-develop__box--color3:before {
    background: #A15FAE; }
  .o-we-develop__box--color4:before {
    background: #3CC065; }
  .o-we-develop__box--custom::before {
    content: '';
    position: absolute;
    top: -3px;
    left: 30px;
    width: 68px;
    height: 6px;
    background: #000; }
  .o-we-develop__box--custom-color1::before {
    background: #64B9F4; }
  .o-we-develop__box--custom-color2::before {
    background: #F29906; }
  .o-we-develop__box--custom-color3::before {
    background: #20DEA5; }
  .o-we-develop__box--custom-color4::before {
    background: #4FA941; }
  .o-we-develop__box--custom-color5::before {
    background: #FBBA14; }
  .o-we-develop__box--custom-color6::before {
    background: #A15FAE; }

.o-we-develop__topicon {
  display: inline-flex;
  height: 70px;
  align-items: center;
  margin-bottom: 11px; }

.o-we-develop__boxtitle {
  font-family: Poppins;
  color: #333333;
  font-size: 21px;
  line-height: 25px;
  font-weight: 700; }

.o-we-develop__boxtitle--custom {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .o-we-develop__boxtitle--custom i {
    display: inline-block;
    margin-right: 10px; }

.o-we-develop__boxcontent {
  font-family: Poppins;
  color: #333333;
  font-size: 15px;
  line-height: 25px;
  margin: 0; }
  .o-we-develop__boxcontent--list {
    list-style: disc;
    padding: 10px 0 0 20px; }

.o-we-develop .btn {
  text-transform: capitalize;
  max-width: 352px;
  font-size: 14px;
  margin: 18px auto 0;
  padding: 16px;
  width: 100%; }
  @media screen and (min-width: 767px) {
    .o-we-develop .btn {
      font-size: 17px; } }

.o-we-develop .btn-orange--dita {
  font-size: 16px; }
  @media screen and (min-width: 992px) {
    .o-we-develop .btn-orange--dita {
      width: 280px;
      margin-top: 10px; } }

.o-we-develop .o-key-diff-box h4 {
  font-family: Poppins; }

.o-we-develop .o-key-diff-box p {
  font-family: Poppins; }

@media screen and (min-width: 992px) {
  .o-we-develop--hire-salesforce .btn {
    max-width: 220px; } }

@media screen and (min-width: 992px) {
  .o-we-develop--microsoft-dynamics-365-implementation-support-services .btn {
    max-width: 700px; } }

.o-discuss-project {
  background-color: #102355; }
  .o-discuss-project__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-discuss-project__spacing {
        padding: 60px 0; } }
  .o-discuss-project__title {
    font-family: Poppins;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 12px;
    text-align: center;
    margin-top: 20px; }
    @media screen and (min-width: 767px) {
      .o-discuss-project__title {
        margin-top: 0;
        margin-bottom: 15px;
        font-size: 34px;
        line-height: 45px;
        text-align: left; } }
    @media screen and (min-width: 992px) {
      .o-discuss-project__title {
        font-size: 41px;
        line-height: 55px; } }
  .o-discuss-project__list {
    position: relative;
    counter-reset: counter; }
  .o-discuss-project__items {
    font-family: Poppins;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 18px;
    font-weight: 500;
    color: #CAE7FF; }
    .o-discuss-project__items:before {
      content: counter(counter);
      counter-increment: counter;
      color: #fff;
      background-color: #4492DC;
      display: inline-flex;
      width: 27px;
      height: 27px;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      margin-top: 1px;
      border-radius: 50%;
      float: left; }
      @media screen and (min-width: 767px) {
        .o-discuss-project__items:before {
          margin-top: -4px; } }
    .o-discuss-project__items:last-child {
      margin-bottom: 0; }
  .o-discuss-project .btn {
    margin: 30px 0 0;
    text-transform: capitalize;
    font-size: 14px;
    max-width: 100%;
    width: 100%;
    padding: 16px; }
    @media screen and (min-width: 767px) {
      .o-discuss-project .btn {
        font-size: 17px;
        margin: 30px 0 0;
        max-width: 350px; } }
  @media screen and (min-width: 767px) {
    .o-discuss-project .btn-web-development {
      font-size: 17px;
      max-width: 480px; } }
  @media screen and (min-width: 767px) {
    .o-discuss-project .btn-lets-discuss-custom {
      font-size: 17px;
      max-width: 400px; } }
  @media screen and (min-width: 767px) {
    .o-discuss-project .btn-lets-discuss-salesforce {
      font-size: 17px;
      max-width: 420px; } }
  .o-discuss-project--dita .o-discuss-project__para--dita {
    font-family: "Poppins";
    font-weight: 500;
    color: #CAE7FF;
    font-size: 16px;
    line-height: 20px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-discuss-project--dita .o-discuss-project__para--dita {
        padding-right: 120px;
        text-align: left; } }
  .o-discuss-project--ai-edvelopment {
    background: url(/images/lets-discuss/custom-software-discuss-bg.jpg) center center/cover no-repeat; }
    @media screen and (min-width: 992px) {
      .o-discuss-project--ai-edvelopment .o-discuss-project__title {
        font-size: 36px;
        line-height: 50px; } }
    .o-discuss-project--ai-edvelopment .o-discuss-project__sub-heading {
      font-family: Poppins;
      color: #fff;
      text-align: left; }
      @media screen and (min-width: 992px) {
        .o-discuss-project--ai-edvelopment .o-discuss-project__sub-heading {
          font-size: 16px;
          line-height: 26px;
          margin-bottom: 0; } }
  @media screen and (min-width: 992px) {
    .o-discuss-project--ai-developer .o-discuss-project__title {
      font-size: 35px;
      line-height: 50px; } }
  .o-discuss-project--ai-developer .o-discuss-project__sub-heading {
    font-family: Poppins;
    color: #fff;
    text-align: left; }
    @media screen and (min-width: 992px) {
      .o-discuss-project--ai-developer .o-discuss-project__sub-heading {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 0; } }

.o-development-process__spacing {
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .o-development-process__spacing {
      padding: 60px 0; } }

@media screen and (min-width: 992px) {
  .o-development-process--multipleitems .o-development-process__list {
    justify-content: center; } }

@media screen and (min-width: 992px) {
  .o-development-process--multipleitems .o-development-process__items {
    margin: 80px 28px 0; }
    .o-development-process--multipleitems .o-development-process__items:nth-child(1) {
      margin-left: 0; }
    .o-development-process--multipleitems .o-development-process__items:nth-child(3n+4) {
      margin-left: 0; }
    .o-development-process--multipleitems .o-development-process__items:nth-child(3n+3) {
      margin-right: 0; }
    .o-development-process--multipleitems .o-development-process__items:nth-child(6):after {
      width: 506px; } }
    @media screen and (min-width: 992px) and (min-width: 1181px) {
      .o-development-process--multipleitems .o-development-process__items:nth-child(6):after {
        width: 636px; } }

@media screen and (min-width: 1181px) {
  .o-development-process--multipleitems .o-development-process__items {
    width: 29%; } }

@media screen and (min-width: 1300px) {
  .o-development-process--multipleitems .o-development-process__items {
    width: 30%; } }

@media screen and (min-width: 992px) {
  .o-development-process--multipleitemsone .o-development-process__list {
    justify-content: center; } }

@media screen and (min-width: 992px) {
  .o-development-process--multipleitemsone .o-development-process__items {
    margin: 80px 28px 0; }
    .o-development-process--multipleitemsone .o-development-process__items:nth-child(1) {
      margin-left: 0; }
    .o-development-process--multipleitemsone .o-development-process__items:nth-child(3n+4) {
      margin-left: 0; }
    .o-development-process--multipleitemsone .o-development-process__items:nth-child(3n+3) {
      margin-right: 0; }
    .o-development-process--multipleitemsone .o-development-process__items:nth-child(6):after {
      width: 330px; } }
    @media screen and (min-width: 992px) and (min-width: 1181px) {
      .o-development-process--multipleitemsone .o-development-process__items:nth-child(6):after {
        width: 480px; } }

@media screen and (min-width: 992px) {
    .o-development-process--multipleitemsone .o-development-process__items:last-child {
      margin-right: 0; } }

@media screen and (min-width: 1181px) {
  .o-development-process--multipleitemsone .o-development-process__items {
    width: 29%; } }

@media screen and (min-width: 1300px) {
  .o-development-process--multipleitemsone .o-development-process__items {
    width: 30%; } }

.o-development-process--process1 .btn {
  max-width: 350px !important; }

.o-development-process__title {
  font-family: Poppins;
  font-size: 30px;
  line-height: 35px;
  color: #262E36;
  font-weight: 700;
  text-align: center; }
  @media screen and (min-width: 767px) {
    .o-development-process__title {
      font-size: 35px;
      line-height: 42px; } }
  @media screen and (min-width: 992px) {
    .o-development-process__title {
      font-size: 46px;
      line-height: 60px; } }
  .o-development-process__title--dita {
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .o-development-process__title--dita {
        font-size: 51px;
        line-height: 60px;
        margin-bottom: 40px; } }

.o-development-process__subtitle {
  font-family: Poppins;
  font-size: 21px;
  line-height: 31px;
  color: #333;
  font-weight: 500;
  text-align: center;
  margin: 0; }
  @media screen and (min-width: 992px) {
    .o-development-process__subtitle {
      margin-bottom: 15px; } }

.o-development-process__list {
  display: flex;
  flex-wrap: wrap; }
  @media screen and (min-width: 992px) {
    .o-development-process__list {
      justify-content: space-between; } }

.o-development-process__items {
  position: relative;
  border: 1px solid #898989;
  padding: 0 20px 15px;
  width: 100%;
  margin: 40px 0 0; }
  @media screen and (min-width: 767px) {
    .o-development-process__items {
      margin: 64px 0 0;
      min-height: 193px; } }
  @media screen and (min-width: 992px) {
    .o-development-process__items {
      width: 28%;
      margin: 80px 0 0; } }
  @media screen and (min-width: 1181px) {
    .o-development-process__items {
      width: 30%; } }
  .o-development-process__items:after {
    position: absolute;
    content: "";
    width: 32px;
    height: 10px;
    background: url(/images/new-skill-section/icons/step-arrow.svg) no-repeat center;
    background-size: 100%;
    top: inherit;
    transform: rotate(90deg);
    right: 0;
    left: 0;
    bottom: -25px;
    margin: auto; }
    @media screen and (min-width: 767px) {
      .o-development-process__items:after {
        width: 55px;
        height: 20px;
        bottom: -40px; } }
    @media screen and (min-width: 992px) {
      .o-development-process__items:after {
        transform: translateY(-50%);
        left: inherit;
        top: 50%;
        bottom: inherit;
        transform: translateY(-50%);
        right: -55px;
        margin: 0; } }
  .o-development-process__items:nth-child(3n+3):after {
    position: absolute;
    content: "";
    width: 32px;
    height: 10px;
    background: url(/images/new-skill-section/icons/step-arrow.svg) no-repeat center;
    background-size: 100%;
    top: inherit;
    transform: rotate(90deg);
    right: 0;
    left: 0;
    bottom: -25px;
    margin: auto; }
    @media screen and (min-width: 767px) {
      .o-development-process__items:nth-child(3n+3):after {
        width: 55px;
        height: 20px;
        bottom: -40px; } }
    @media screen and (min-width: 992px) {
      .o-development-process__items:nth-child(3n+3):after {
        position: absolute;
        content: "";
        width: 676px;
        height: 62px;
        background: url(/images/new-skill-section/development-process-arrow.svg) no-repeat center;
        background-size: 100%;
        top: inherit;
        transform: none;
        right: 50%;
        left: inherit;
        bottom: -72px; } }
    @media screen and (min-width: 1181px) {
      .o-development-process__items:nth-child(3n+3):after {
        width: 836px; } }
  .o-development-process__items:last-child:after {
    display: none; }
  @media screen and (min-width: 992px) {
    .o-development-process__items--short-arrow:after {
      width: 450px !important;
      background: url(/images/new-skill-section/short-dashed-arrow.png) no-repeat center !important; } }
  .o-development-process__items--mr-0 {
    margin-right: 0 !important; }
  .o-development-process__items--dita {
    padding: 1px;
    cursor: pointer;
    overflow: hidden; }
    @media screen and (min-width: 992px) {
      .o-development-process__items--dita {
        margin: 0 15px 30px;
        max-width: 360px;
        width: 32%; } }
    .o-development-process__items--dita::after {
      display: none; }
    .o-development-process__items--dita:before {
      content: '';
      position: absolute;
      top: -200px;
      right: 0;
      bottom: 0;
      left: -150px;
      background: #898989;
      width: 950px;
      height: 950px;
      -webkit-transition: .3s all ease;
      transition: .3s all ease; }
    .o-development-process__items--dita:hover:before {
      -webkit-animation: rotation2 3s linear infinite;
      animation: rotation2 3s linear infinite;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #88deb1), color-stop(20%, #1ab864), color-stop(52%, rgba(35, 116, 212, 0.62)), to(rgba(6, 87, 181, 0.62)));
      background: linear-gradient(180deg, #88deb1 0, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%); }
    @media screen and (min-width: 992px) {
      .o-development-process__items--dita:nth-child(1), .o-development-process__items--dita:nth-child(4) {
        margin-left: 0; } }
    @media screen and (min-width: 992px) {
      .o-development-process__items--dita:nth-child(3), .o-development-process__items--dita:nth-child(6) {
        margin-right: 0; } }

.o-development-process__box {
  background: #fff;
  height: 100%;
  position: relative;
  padding: 15px; }
  @media screen and (min-width: 992px) {
    .o-development-process__box {
      padding: 20px 40px 15px 20px; } }

.o-development-process__number {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: rgba(53, 91, 191, 0.1);
  color: #fff;
  width: 73px;
  height: 73px;
  border-radius: 50%;
  margin-bottom: 16px;
  margin-top: -35px; }
  .o-development-process__number strong {
    position: relative;
    width: 45px;
    height: 45px;
    background-color: #355BBF;
    border-radius: 50%;
    margin: auto;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center; }

.o-development-process__titlearea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px; }
  .o-development-process__titlearea i {
    margin-right: 13px; }

.o-development-process__itemtitle {
  font-family: Poppins;
  font-size: 20px;
  line-height: 31px;
  color: #333333;
  font-weight: 600;
  margin: 0; }

.o-development-process__itemcontent {
  font-family: Poppins;
  font-size: 15px;
  line-height: 22px;
  color: #333333;
  margin: 0; }

.o-development-process .btn {
  text-transform: capitalize;
  padding: 16px;
  font-size: 14px;
  max-width: 100%;
  width: 100%;
  margin: 30px auto 0; }
  @media screen and (min-width: 767px) {
    .o-development-process .btn {
      max-width: 306px; } }
  @media screen and (min-width: 992px) {
    .o-development-process .btn {
      font-size: 17px;
      margin: 34px auto 0; } }

@media screen and (min-width: 768px) {
  .o-development-process--microsoft-dynamics-functional-consultant-services .btn {
    max-width: 340px; } }

@media screen and (min-width: 768px) {
  .o-development-process--ms-dynamics-ax-consultant-services .btn {
    max-width: 415px; } }

@media screen and (min-width: 992px) {
  .o-development-process--servicenow .btn-orange {
    max-width: 360px; } }

@media screen and (min-width: 992px) {
  .o-development-process--ai .o-development-process__items {
    min-height: 385px; } }

@media screen and (min-width: 1150px) {
  .o-development-process--ai .o-development-process__items {
    min-height: 380px; } }

@media screen and (min-width: 1200px) {
  .o-development-process--ai .o-development-process__items {
    min-height: 290px; } }

@media screen and (min-width: 992px) {
  .o-development-process--custom-software .o-development-process__items {
    min-height: 215px; } }

@media screen and (min-width: 1150px) {
  .o-development-process--custom-software .o-development-process__items {
    min-height: 215px; } }

@media screen and (min-width: 1200px) {
  .o-development-process--custom-software .o-development-process__items {
    min-height: 200px; } }

@media screen and (min-width: 767px) {
  .o-development-process .btn-350 {
    max-width: 350px; } }

.o-development-process--dita {
  border-bottom: 1px solid #E6E6E6; }
  @media screen and (min-width: 992px) {
    .o-development-process--dita .btn-orange {
      width: 280px;
      max-width: 280px !important;
      margin-top: 10px; } }
  .o-development-process--dita .o-development-process__titlearea i {
    width: 50px;
    flex: 0 0 50px;
    display: inline-block; }

@media screen and (min-width: 992px) {
  .o-development-process--ai-development .o-development-process__items:nth-child(3n+3):after {
    width: 630px; } }

.o-api-integration {
  background-color: #F9F9F9;
  border-bottom: 1px solid #E0E0E0; }
  .o-api-integration__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-api-integration__spacing {
        padding: 60px 0; } }
  .o-api-integration__heading {
    font-family: Poppins;
    font-size: 32px;
    line-height: 40px;
    color: #262E36;
    font-weight: 700;
    text-align: center;
    margin-bottom: 15px; }
    @media screen and (min-width: 767px) {
      .o-api-integration__heading {
        font-size: 38px;
        line-height: 45px; } }
    @media screen and (min-width: 992px) {
      .o-api-integration__heading {
        font-size: 48px;
        line-height: 58px; } }
  .o-api-integration__sub-heading {
    font-family: Poppins;
    color: #333;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px; }
    @media screen and (min-width: 992px) {
      .o-api-integration__sub-heading {
        font-size: 21px;
        line-height: 31px;
        margin-bottom: 10px; } }
  .o-api-integration .custom-solution__sub-heading {
    margin: 0 0 5px; }
  .o-api-integration--servicenow {
    border-bottom: 0; }
    .o-api-integration--servicenow .btn-orange {
      text-transform: capitalize; }
  .o-api-integration__box {
    position: relative;
    margin-top: 30px; }
    @media screen and (min-width: 992px) {
      .o-api-integration__box {
        margin-top: 40px; } }
  .o-api-integration__single-icon {
    display: inline-block;
    margin-bottom: 20px; }
  .o-api-integration__imagewrap {
    background-color: #fff;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .o-api-integration__imagewrap {
        min-height: 180px; } }
  .o-api-integration__imageitem {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 20px;
    border-right: 1px solid #E1E1E1;
    width: 50%;
    max-height: 82px;
    height: auto; }
    .o-api-integration__imageitem:last-child {
      border-right: none; }
  .o-api-integration__boxtitle {
    font-family: Poppins;
    font-size: 18px;
    line-height: 24px;
    color: #333333;
    font-weight: 700;
    margin-bottom: 15px; }
    @media screen and (min-width: 992px) {
      .o-api-integration__boxtitle {
        font-size: 21px;
        line-height: 25px; } }
  .o-api-integration__boxcontent {
    font-family: Poppins;
    font-size: 15px;
    line-height: 25px;
    color: #333;
    margin: 0; }
  .o-api-integration .btn {
    max-width: 100%;
    padding: 16px;
    font-size: 17px;
    margin: 30px auto 0;
    width: 100%;
    text-transform: capitalize; }
    @media screen and (min-width: 767px) {
      .o-api-integration .btn {
        max-width: 368px; } }

.o-api-benefit__spacing {
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .o-api-benefit__spacing {
      padding: 60px 0; } }

.o-api-benefit__heading {
  font-family: Poppins;
  font-size: 32px;
  line-height: 40px;
  color: #262E36;
  font-weight: 700;
  text-align: center; }
  @media screen and (min-width: 767px) {
    .o-api-benefit__heading {
      font-size: 38px;
      line-height: 45px; } }
  @media screen and (min-width: 992px) {
    .o-api-benefit__heading {
      font-size: 48px;
      line-height: 58px; } }

.o-api-benefit__subheading {
  font-family: Poppins;
  font-size: 18px;
  line-height: 27px;
  color: #333;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px; }
  @media screen and (min-width: 767px) {
    .o-api-benefit__subheading {
      font-size: 21px;
      line-height: 31px;
      margin-bottom: 50px; } }

.o-api-benefit__box {
  position: relative;
  border: 1px solid #DDDDDD;
  height: 100%;
  border-radius: 5px;
  padding: 25px; }
  @media screen and (min-width: 992px) {
    .o-api-benefit__box {
      min-height: 270px;
      padding: 28px; } }
  .o-api-benefit__box:before {
    position: absolute;
    content: "";
    width: 74px;
    height: 7px;
    left: 28px;
    top: -4px; }
  .o-api-benefit__box--sky:before {
    background-color: #64B9F4; }
  .o-api-benefit__box--dark-blue:before {
    background-color: #052c58; }
  .o-api-benefit__box--yellow:before {
    background-color: #FBBA14; }
  .o-api-benefit__box--purple:before {
    background-color: #A15FAE; }
  .o-api-benefit__box--green:before {
    background-color: #3CC065; }

.o-api-benefit__boxcontent {
  font-family: Poppins;
  font-size: 19px;
  line-height: 28px;
  color: #262E36;
  font-weight: 500;
  margin: 0; }
  @media screen and (min-width: 992px) {
    .o-api-benefit__boxcontent {
      font-size: 21px;
      line-height: 31px; } }

.o-api-benefit .btn {
  text-transform: capitalize;
  padding: 16px;
  font-size: 14px;
  margin: 10px auto 0;
  width: 100%;
  max-width: 100%; }
  @media screen and (min-width: 767px) {
    .o-api-benefit .btn {
      font-size: 17px;
      max-width: 350px; } }

.o-releted-service {
  background-color: #f6f6f6; }
  .o-releted-service.bg-white .o-releted-service__link {
    background-color: #fff; }
  .o-releted-service__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-releted-service__spacing {
        padding: 60px 0; } }
  .o-releted-service__heading {
    font-family: Poppins;
    font-size: 32px;
    line-height: 40px;
    color: #262E36;
    font-weight: 700;
    text-align: center;
    margin-bottom: 15px; }
    @media screen and (min-width: 767px) {
      .o-releted-service__heading {
        font-size: 38px;
        line-height: 45px; } }
    @media screen and (min-width: 992px) {
      .o-releted-service__heading {
        font-size: 48px;
        line-height: 58px; } }
  .o-releted-service__subheading {
    font-family: Poppins;
    font-size: 18px;
    line-height: 28px;
    color: #333333;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .o-releted-service__subheading {
        font-size: 21px;
        line-height: 31px;
        margin-bottom: 60px; } }
  .o-releted-service__gradient {
    display: block;
    position: relative;
    margin-bottom: 26px;
    cursor: pointer;
    overflow: hidden;
    padding: 2px;
    border: 0;
    border-radius: 110px; }
    .o-releted-service__gradient:after {
      content: '';
      position: absolute;
      top: -200px;
      right: 0;
      bottom: 0;
      left: -150px;
      background: #898989;
      width: 950px;
      height: 950px;
      -webkit-transition: .3s all ease;
      transition: .3s all ease; }
    .o-releted-service__gradient--sky:after {
      background: #64B9F4; }
    .o-releted-service__gradient--orange:after {
      background: #E26826; }
    .o-releted-service__gradient--green:after {
      background: #8AC640; }
    .o-releted-service__gradient--color4:after {
      background: #A15FAE; }
    .o-releted-service__gradient--color5:after {
      background: #3B5085; }
    .o-releted-service__gradient--color6:after {
      background: #FBBA14; }
    .o-releted-service__gradient--color7:after {
      background: #00B67A; }
    .o-releted-service__gradient:hover:after {
      -webkit-animation: rotation2 3s linear infinite;
      animation: rotation2 3s linear infinite;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #88deb1), color-stop(20%, #1ab864), color-stop(52%, rgba(35, 116, 212, 0.62)), to(rgba(6, 87, 181, 0.62)));
      background: linear-gradient(180deg, #88deb1 0, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%); }
  .o-releted-service__link {
    position: relative;
    background-color: #f6f6f6;
    border-radius: 110px;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    height: 80px; }
  .o-releted-service__linkcontent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 235px; }
    .o-releted-service__linkcontent i {
      margin-right: 15px;
      flex: 0 0 40px; }
    .o-releted-service__linkcontent span {
      font-family: Poppins;
      font-size: 20px;
      line-height: 25px;
      font-weight: 600;
      color: #333333; }
    .o-releted-service__linkcontent--small span {
      font-size: 19px; }

.o-latest-blog {
  border-top: 1px solid #E8E8E8; }
  .o-latest-blog__spacing {
    padding: 30px 0; }
    @media screen and (min-width: 992px) {
      .o-latest-blog__spacing {
        padding: 60px 0; } }
  .o-latest-blog__heading {
    font-family: Poppins;
    font-size: 32px;
    line-height: 40px;
    color: #262E36;
    font-weight: 700;
    margin: 0 0 10px;
    text-align: center; }
    @media screen and (min-width: 767px) {
      .o-latest-blog__heading {
        font-size: 38px;
        line-height: 45px;
        text-align: left; } }
    @media screen and (min-width: 992px) {
      .o-latest-blog__heading {
        font-size: 48px;
        line-height: 58px; } }
  .o-latest-blog__subheading {
    font-family: Poppins;
    font-size: 22px;
    line-height: 30px;
    color: #35B15C;
    font-weight: 600;
    text-align: center; }
    @media screen and (min-width: 767px) {
      .o-latest-blog__subheading {
        text-align: left; } }
    @media screen and (min-width: 992px) {
      .o-latest-blog__subheading {
        font-size: 25px;
        line-height: 34px; } }
  .o-latest-blog__list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap; }
    @media screen and (min-width: 992px) {
      .o-latest-blog__list--three .o-latest-blog__item {
        width: 32%;
        margin-right: 1%;
        margin-bottom: 0; } }
    @media screen and (min-width: 767px) {
      .o-latest-blog__list--three .o-latest-blog__blogtitle {
        font-size: 18px;
        line-height: 24px; } }
    @media screen and (min-width: 992px) {
      .o-latest-blog__list--three .o-latest-blog__blogtitle {
        font-size: 20px;
        line-height: 26px; } }
  .o-latest-blog__item {
    max-width: 550px;
    width: 100%;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .o-latest-blog__item {
        width: 47%;
        margin-right: 6%;
        margin-bottom: 0; } }
    .o-latest-blog__item:last-child {
      margin-right: 0; }
  .o-latest-blog__link:hover .o-latest-blog__blogtitle {
    color: #35b15c; }
  .o-latest-blog__imagewrap {
    width: 100%;
    height: auto;
    margin-bottom: 15px; }
    @media screen and (min-width: 992px) {
      .o-latest-blog__imagewrap {
        max-height: 450px;
        margin-bottom: 35px; } }
    .o-latest-blog__imagewrap img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .o-latest-blog__blogtitle {
    font-family: Poppins;
    font-size: 18px;
    line-height: 24px;
    color: #333;
    font-weight: 600;
    margin-bottom: 10px;
    transition: .3s all ease-in-out; }
    @media screen and (min-width: 767px) {
      .o-latest-blog__blogtitle {
        font-size: 22px;
        line-height: 30px; } }
    @media screen and (min-width: 992px) {
      .o-latest-blog__blogtitle {
        font-size: 31px;
        line-height: 34px; } }
  .o-latest-blog__blogauthor {
    font-family: Poppins;
    font-size: 13px;
    line-height: 30px;
    color: #000; }
    .o-latest-blog__blogauthor span {
      color: #898989; }
    .o-latest-blog__blogauthor strong {
      font-weight: 600; }
  .o-latest-blog .btn {
    text-transform: capitalize;
    padding: 16px;
    font-size: 14px;
    margin: 20px auto 0;
    width: 100%;
    max-width: 100%; }
    @media screen and (min-width: 767px) {
      .o-latest-blog .btn {
        max-width: 350px;
        font-size: 17px; } }

.bg-white {
  background-color: #fff; }

.border-bottom-gray {
  border-bottom: 1px solid #e4e4e4; }

.btn-auto-width {
  max-width: 100% !important;
  padding: 16px !important;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media screen and (min-width: 767px) {
    .btn-auto-width {
      padding: 16px 25px !important;
      max-width: fit-content !important; } }
  .btn-auto-width svg {
    margin-left: 7px; }

.latest-technologies__spacing {
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .latest-technologies__spacing {
      padding: 60px 0; } }

.latest-technologies__heading {
  font-family: Poppins;
  font-size: 30px;
  line-height: 35px;
  color: #262E36;
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px; }
  @media screen and (min-width: 767px) {
    .latest-technologies__heading {
      font-size: 38px;
      line-height: 46px; } }
  @media screen and (min-width: 992px) {
    .latest-technologies__heading {
      font-size: 48px;
      line-height: 58px; } }

.latest-technologies__navbar {
  margin-top: 45px; }
  .latest-technologies__navbar .nav {
    display: flex;
    align-items: center;
    justify-content: center; }
  .latest-technologies__navbar .nav-link {
    position: relative;
    display: inline-flex;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    width: 50%;
    color: #355bbf;
    padding: 15px;
    top: 1px;
    border: none;
    border-color: none;
    text-transform: capitalize;
    border-bottom: 3px solid transparent; }
    .latest-technologies__navbar .nav-link:after {
      position: absolute;
      content: "";
      width: 1px;
      height: 47%;
      background-color: #d4e0ed;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: none; }
      @media screen and (min-width: 767px) {
        .latest-technologies__navbar .nav-link:after {
          display: block; } }
    .latest-technologies__navbar .nav-link:last-child:after {
      display: none; }
    @media screen and (min-width: 767px) {
      .latest-technologies__navbar .nav-link {
        width: 25%; } }
    @media screen and (min-width: 992px) {
      .latest-technologies__navbar .nav-link {
        width: auto; } }
    .latest-technologies__navbar .nav-link.active {
      border: 1px solid #e0e0e0;
      background-color: #d4e0ed;
      border-radius: 5px; }
      @media screen and (min-width: 992px) {
        .latest-technologies__navbar .nav-link.active {
          border-radius: 0;
          background-color: transparent;
          border: none;
          border-bottom: 3px solid #355bbf; } }

.latest-technologies .tab-content {
  border-top: 1px solid #d4e0ed;
  background-color: transparent;
  padding: 30px; }

.latest-technologies .tab-pane i {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 70px; }

.latest-technologies .tab-pane h5 {
  font-family: Poppins;
  font-size: 22px;
  line-height: 32px;
  color: #262e36; }

.latest-technologies .tab-pane p {
  font-family: Poppins;
  font-size: 18px;
  line-height: 32px;
  color: #000;
  margin: 0; }

.latest-technologies .btn {
  text-transform: capitalize;
  margin: 30px auto 0;
  font-size: 17px;
  padding: 16px;
  width: 100%; }
  @media screen and (min-width: 992px) {
    .latest-technologies .btn {
      width: 100%; } }

.latest-technologies .tittle-bar-area {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }
  .latest-technologies .tittle-bar-area i {
    margin-right: 15px; }

.btn-center-block {
  width: 100%; }
  @media screen and (min-width: 767px) {
    .btn-center-block {
      width: 310px; } }

.btn_wrapper.btn-center-block {
  width: 100%; }

.o-technologies-service .experience-list li {
  position: relative;
  padding: 2px;
  overflow: hidden;
  transform: translateZ(0);
  border: none;
  border-radius: 10px; }
  .o-technologies-service .experience-list li:before {
    display: block;
    content: "";
    position: absolute;
    top: -140px;
    right: 0;
    bottom: 0;
    left: -50px;
    background: #d6e3f0;
    width: 450px;
    height: 450px;
    -webkit-transition: .5s all ease-in-out;
    transition: .5s all ease-in-out; }
  .o-technologies-service .experience-list li:hover:before {
    animation: rotation2 3s linear infinite;
    background: linear-gradient(180deg, #88deb1 0, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%); }
  .o-technologies-service .experience-list li a {
    border-radius: 10px;
    background-color: #fff; }
    .o-technologies-service .experience-list li a:before {
      display: none; }

.common-btn-root .btn-auto {
  max-width: inherit;
  width: auto;
  padding: 16px 25px;
  flex: 0 0 auto; }
  .common-btn-root .btn-auto--process {
    width: 306px; }
  .common-btn-root .btn-auto--expertise {
    width: 352px; }

.we-serve__subtitle {
  font-family: Poppins;
  color: #333;
  text-align: center;
  font-weight: 500; }
  @media screen and (min-width: 992px) {
    .we-serve__subtitle {
      font-size: 21px;
      line-height: 30px;
      margin-bottom: 30px; } }

.dev-discuss {
  text-align: center;
  background: url(/images/lets-discuss/custom-software-discuss-bg.jpg) no-repeat center center/cover; }
  .dev-discuss .btn-orange {
    margin: 30px auto 0; }
  .dev-discuss p {
    font-size: 21px;
    line-height: 32px;
    color: #DBE4FB;
    font-weight: 500; }
  .dev-discuss .o-discuss-project__title {
    font-weight: 600; }
    @media screen and (min-width: 992px) {
      .dev-discuss .o-discuss-project__title {
        font-size: 35px; } }

.custom-dev-process {
  background: #F9F9F9; }
  .custom-dev-process .o-skill-flow__title {
    font-weight: 500 !important;
    margin-bottom: 20px; }
  .custom-dev-process .o-development-process__subtitle {
    font-weight: 400; }
  @media screen and (min-width: 992px) {
    .custom-dev-process .btn-orange {
      margin-top: 50px; } }
  .custom-dev-process .o-development-process__items {
    box-shadow: 0 15px 20px 0 rgba(12, 40, 72, 0.07);
    background: #fff;
    border: 1px solid #D0E3F5;
    margin-top: 50px; }
    @media screen and (min-width: 768px) {
      .custom-dev-process .o-development-process__items {
        min-height: 220px; } }
    @media screen and (min-width: 992px) {
      .custom-dev-process .o-development-process__items {
        margin: 70px 10px 0;
        padding: 0 30px 15px; } }
    @media screen and (min-width: 1300px) {
      .custom-dev-process .o-development-process__items {
        width: 32%; } }
  .custom-dev-process .o-development-process__itemcontent {
    font-size: 16px;
    line-height: 24px; }
  .custom-dev-process .o-development-process__number-color1 {
    background: rgba(100, 185, 244, 0.1); }
    .custom-dev-process .o-development-process__number-color1 strong {
      background: #64B9F4; }
  .custom-dev-process .o-development-process__number-color2 {
    background: rgba(32, 222, 165, 0.1); }
    .custom-dev-process .o-development-process__number-color2 strong {
      background: #20DEA5; }
  .custom-dev-process .o-development-process__number-color3 {
    background: rgba(253, 164, 116, 0.1); }
    .custom-dev-process .o-development-process__number-color3 strong {
      background: #FDA474; }
  .custom-dev-process .o-development-process__number-color4 {
    background: rgba(161, 95, 174, 0.1); }
    .custom-dev-process .o-development-process__number-color4 strong {
      background: #A15FAE; }
  .custom-dev-process .o-development-process__items {
    border-radius: 5px;
    border: 1px solid #D0E3F5; }
    @media screen and (min-width: 992px) {
      .custom-dev-process .o-development-process__items {
        margin: 100px 30px 0; } }
    @media screen and (min-width: 1300px) {
      .custom-dev-process .o-development-process__items {
        width: 29.7%; } }
    .custom-dev-process .o-development-process__items:after {
      background-image: url(/images/new-skill-section/custom-software/step-arrow.svg); }
      @media screen and (min-width: 992px) {
        .custom-dev-process .o-development-process__items:after {
          right: -51px;
          width: 41px; } }
    @media screen and (min-width: 992px) {
      .custom-dev-process .o-development-process__items:nth-child(3n+3):after {
        height: 66px;
        width: 676px;
        right: 50%;
        background-image: url(/images/new-skill-section/custom-software/development-process-arrow.svg);
        bottom: -85px; } }
    @media screen and (min-width: 1181px) {
      .custom-dev-process .o-development-process__items:nth-child(3n+3):after {
        width: 836px; } }
    .custom-dev-process .o-development-process__items:nth-child(1), .custom-dev-process .o-development-process__items:nth-child(2), .custom-dev-process .o-development-process__items:nth-child(3) {
      margin-top: 60px; }
  @media screen and (min-width: 767px) {
    .custom-dev-process .o-development-process__listmultiple .o-development-process__items:nth-child(6):after {
      width: 506px; } }
  @media screen and (min-width: 1181px) {
    .custom-dev-process .o-development-process__listmultiple .o-development-process__items:nth-child(6):after {
      width: 636px; } }
  .custom-dev-process .o-development-process__itemtitle {
    color: #0C2848; }
    @media screen and (min-width: 992px) {
      .custom-dev-process .o-development-process__itemtitle {
        font-size: 24px;
        line-height: 27px; } }
  .custom-dev-process .o-development-process__itemcontent {
    line-height: 27px; }
  .custom-dev-process .o-development-process__titlearea {
    margin-bottom: 15px; }
  .custom-dev-process .o-development-process__number {
    margin-bottom: 25px; }

.o-development-process--365-service {
  background: #fff; }

.o-development-process--ai-developer .o-skill-flow__title {
  font-weight: 700 !important; }

@media screen and (min-width: 992px) {
  .o-development-process--ai-developer .o-development-process__items:nth-child(3n+3):after {
    width: 630px; } }

.ai-partner {
  padding: 40px 0; }
  @media screen and (min-width: 992px) {
    .ai-partner {
      padding: 50px 0; } }
  .ai-partner h4 {
    font-size: 32px;
    line-height: 48px; }
    .ai-partner h4 strong {
      color: #00B67A; }

.custom-dev-we-develop {
  border-bottom: 1px solid #DDDADA;
  border-top: 1px solid #DDDADA;
  background: #F9F9F9; }
  .custom-dev-we-develop .o-we-develop__box {
    position: relative; }
  .custom-dev-we-develop .o-we-develop__title {
    font-weight: 500 !important; }
  .custom-dev-we-develop .o-we-develop__subtitle {
    font-weight: 400; }
    @media screen and (min-width: 992px) {
      .custom-dev-we-develop .o-we-develop__subtitle {
        margin-bottom: 60px; } }
  .custom-dev-we-develop .o-we-develop__boxtitle {
    font-weight: 600;
    color: #0C2848; }
    @media screen and (min-width: 992px) {
      .custom-dev-we-develop .o-we-develop__boxtitle {
        line-height: 27px;
        margin-bottom: 15px; } }
  .custom-dev-we-develop .o-we-develop__boxcontent {
    color: #0C2848; }
    @media screen and (min-width: 992px) {
      .custom-dev-we-develop .o-we-develop__boxcontent {
        line-height: 27px; } }

@media screen and (min-width: 992px) {
  .ai-in-action .o-we-develop__boxcontent {
    font-size: 16px;
    line-height: 25px; } }

@media screen and (min-width: 992px) {
  .ai-in-action .o-we-develop__box {
    padding: 25px 20px; } }

.ai-in-action .o-we-develop__box--custom:before {
  left: 25px; }

.o-core-competencies--ai-development .btn {
  text-transform: none; }

.how-we-help--ai-development .how-help-you__heading {
  display: none; }

.how-we-help--ai-development .how-help-you__card {
  display: none; }

.how-we-help--ai-development .let-help {
  padding: 0;
  margin: 0; }

@media screen and (min-width: 992px) {
  .o-we-develop--hire-salesforce .o-we-develop__title {
    font-weight: 700 !important; } }

@media screen and (min-width: 992px) {
  .o-we-develop--hire-salesforce .o-we-develop__subtitle {
    font-weight: 500; } }

@media screen and (min-width: 992px) {
  .o-we-develop--hire-salesforce .btn-wrapper-v2--pair .btn-v2 {
    font-size: 17px; } }

.custom-dev-related .o-releted-service__gradient {
  background: #D0E3F5;
  padding: 0;
  border: 1px solid #D0E3F5;
  overflow: visible;
  height: 75px;
  box-shadow: 0 10px 15px 0 rgba(12, 40, 72, 0.06); }
  .custom-dev-related .o-releted-service__gradient:after {
    display: none; }

.custom-dev-related .o-releted-service__link {
  background: #fff;
  height: 100%; }

.custom-dev-related .o-releted-service__heading {
  font-weight: 500; }

.custom-dev-method .o-we-develop__title {
  font-weight: 500 !important; }

.custom-dev-method .custom-solution__sub-heading {
  font-weight: 400; }

/* ANCHOR - Staff Aug*/
.how-stuff-augmentation {
  background: #F5F6F8;
  border-bottom: 1px solid #D6E3F0; }
  .how-stuff-augmentation .our-approach__heading {
    font-weight: 700;
    font-family: "Poppins"; }
    @media screen and (min-width: 992px) {
      .how-stuff-augmentation .our-approach__heading {
        font-size: 45px; } }
  .how-stuff-augmentation .o-development-process__items {
    border: none;
    box-shadow: none;
    height: auto;
    background: none;
    text-align: center;
    margin-top: 20px; }
    @media screen and (min-width: 992px) {
      .how-stuff-augmentation .o-development-process__items {
        text-align: left;
        padding: 0 35px 0 60px;
        margin-top: 50px; } }
    .how-stuff-augmentation .o-development-process__items::after {
      content: '';
      background: url(/images/staff-augmentation/how-it-works-v-line.svg) no-repeat center;
      width: 4px;
      height: 148px;
      z-index: 9;
      right: inherit;
      left: 0;
      top: 37px;
      bottom: inherit;
      display: none; }
      @media screen and (min-width: 992px) {
        .how-stuff-augmentation .o-development-process__items::after {
          display: block; } }
    @media screen and (min-width: 992px) {
      .how-stuff-augmentation .o-development-process__items:nth-child(1) {
        padding: 0 35px 0 50px; } }
    @media screen and (min-width: 992px) {
      .how-stuff-augmentation .o-development-process__items:nth-child(2) {
        padding: 0 35px 0 60px; } }
    @media screen and (min-width: 992px) {
      .how-stuff-augmentation .o-development-process__items:nth-child(3) {
        padding: 0 15px 0 60px; } }
    .how-stuff-augmentation .o-development-process__items:nth-child(3):after {
      transform: rotate(0); }
      @media screen and (min-width: 992px) {
        .how-stuff-augmentation .o-development-process__items:nth-child(3):after {
          top: -40px; } }
    @media screen and (min-width: 992px) {
      .how-stuff-augmentation .o-development-process__items:nth-child(4) {
        padding: 0 0 0 60px; } }
    @media screen and (min-width: 992px) {
      .how-stuff-augmentation .o-development-process__items:nth-child(4)::after {
        content: '';
        position: absolute;
        top: 16px;
        left: -37px;
        background: url(/images/staff-augmentation/how-it-works-thunder.svg) no-repeat center;
        width: 92px;
        height: 179px;
        display: block; } }
    .how-stuff-augmentation .o-development-process__items .o-development-process__number {
      background: #FFFFFF;
      margin: 0 auto 15px; }
      @media screen and (min-width: 992px) {
        .how-stuff-augmentation .o-development-process__items .o-development-process__number {
          position: absolute;
          top: -21px;
          left: -35px;
          z-index: 99;
          margin: 0; } }
      .how-stuff-augmentation .o-development-process__items .o-development-process__number strong {
        background: #0A9DEF; }
    .how-stuff-augmentation .o-development-process__items .o-development-process__titlearea {
      justify-content: center; }
      @media screen and (min-width: 992px) {
        .how-stuff-augmentation .o-development-process__items .o-development-process__titlearea {
          justify-content: flex-start; } }
  .how-stuff-augmentation .btn {
    margin-top: 25px;
    height: 62px;
    padding: 16px 20px; }
    @media screen and (min-width: 992px) {
      .how-stuff-augmentation .btn {
        max-width: 340px;
        margin-top: 60px; } }

.more-reason {
  background: #F5F6F8;
  padding: 30px 0;
  border-bottom: 1px solid #d6e3f0; }
  @media screen and (min-width: 768px) {
    .more-reason {
      padding: 60px 0; } }
  .more-reason h2 {
    font-family: "Poppins";
    margin-bottom: 25px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .more-reason h2 {
        margin-bottom: 50px; } }
  .more-reason__wrapper {
    position: relative; }
    @media screen and (min-width: 768px) {
      .more-reason__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; } }
  .more-reason__item {
    flex: 0 0 33%;
    position: relative;
    border-bottom: 1px solid #E1E1E1;
    text-align: center; }
    @media screen and (min-width: 768px) {
      .more-reason__item {
        border: none;
        text-align: left; } }
    .more-reason__item::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      background: #E1E1E1;
      width: 1px;
      height: 100%;
      display: none; }
      @media screen and (min-width: 992px) {
        .more-reason__item::before {
          display: block; } }
    .more-reason__item:nth-child(1), .more-reason__item:nth-child(2), .more-reason__item:nth-child(3) {
      border-bottom: 1px solid #E1E1E1; }
    .more-reason__item:nth-child(1) {
      padding: 15px 0; }
      @media screen and (min-width: 768px) {
        .more-reason__item:nth-child(1) {
          padding: 0 60px 30px 0; } }
      .more-reason__item:nth-child(1):after {
        content: '';
        position: absolute;
        right: -15px;
        bottom: -26px;
        background: #F5F6F8;
        width: 30px;
        height: 50px;
        display: none;
        z-index: 1; }
        @media screen and (min-width: 992px) {
          .more-reason__item:nth-child(1):after {
            display: block; } }
    .more-reason__item:nth-child(2) {
      padding: 15px 0; }
      @media screen and (min-width: 768px) {
        .more-reason__item:nth-child(2) {
          padding: 0 60px 30px 60px; } }
      .more-reason__item:nth-child(2):after {
        content: '';
        position: absolute;
        right: -15px;
        bottom: -26px;
        background: #F5F6F8;
        width: 30px;
        height: 50px;
        display: none;
        z-index: 1; }
        @media screen and (min-width: 992px) {
          .more-reason__item:nth-child(2):after {
            display: block; } }
    .more-reason__item:nth-child(3) {
      padding: 15px 0; }
      @media screen and (min-width: 768px) {
        .more-reason__item:nth-child(3) {
          padding: 0 60px 30px 60px; } }
    .more-reason__item:nth-child(4) {
      padding: 15px 0; }
      @media screen and (min-width: 768px) {
        .more-reason__item:nth-child(4) {
          padding: 30px 60px 0 0; } }
    .more-reason__item:nth-child(5) {
      padding: 15px 0; }
      @media screen and (min-width: 768px) {
        .more-reason__item:nth-child(5) {
          padding: 30px 60px 0 60px; } }
    .more-reason__item:nth-child(6) {
      border: none;
      padding: 15px 0; }
      @media screen and (min-width: 768px) {
        .more-reason__item:nth-child(6) {
          padding: 30px 60px 0 60px; } }
    .more-reason__item:nth-child(3)::before, .more-reason__item:nth-child(6)::before {
      display: none; }
  .more-reason__icon {
    display: flex;
    margin-bottom: 10px;
    height: 60px;
    justify-content: center; }
    @media screen and (min-width: 768px) {
      .more-reason__icon {
        justify-content: flex-start; } }
  .more-reason__card-title {
    font-size: 24px;
    line-height: 27px;
    font-weight: 700;
    color: #2C2C2C;
    font-family: "Poppins"; }
  .more-reason__card-para {
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    color: #2C2C2C;
    font-family: "Poppins";
    margin-bottom: 0; }
  .more-reason .btn {
    margin-top: 25px;
    height: 62px;
    padding: 16px 20px; }
    @media screen and (min-width: 992px) {
      .more-reason .btn {
        max-width: 340px;
        margin-top: 60px; } }

.industry-empower {
  padding: 30px 0;
  border-bottom: 1px solid #D6E3F0; }
  @media screen and (min-width: 768px) {
    .industry-empower {
      padding: 60px 0; } }
  .industry-empower h2 {
    font-family: "Poppins";
    text-align: center;
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .industry-empower h2 {
        margin-bottom: 40px;
        font-size: 45px;
        line-height: 50px; } }
  .industry-empower .col-lg-4 {
    margin-bottom: 26px; }
  .industry-empower__box {
    text-align: center;
    border: 1px solid #D6E3F0;
    border-radius: 10px;
    padding: 15px 15px 30px 15px; }
  .industry-empower__image {
    border-radius: 10px; }
  .industry-empower__image1 {
    background: #EFF4F8; }
  .industry-empower__image2 {
    background: #F6EEE6; }
  .industry-empower__image3 {
    background: #D7EDF9; }
  .industry-empower__image4 {
    background: #E6E7F9; }
  .industry-empower__image5 {
    background: #EEF4EC; }
  .industry-empower__image6 {
    background: #FEF1EF; }
  .industry-empower h3 {
    font-family: "Poppins";
    margin-top: 25px;
    margin-bottom: 0;
    color: #333333;
    font-size: 24px;
    font-weight: 600; }
  .industry-empower .btn {
    padding: 16px 20px; }
    @media screen and (min-width: 992px) {
      .industry-empower .btn {
        max-width: 340px;
        margin-top: 20px; } }
  .industry-empower__sub-heading {
    font-family: Poppins;
    text-align: center;
    font-weight: 500; }
    @media (min-width: 992px) {
      .industry-empower__sub-heading {
        font-size: 19px;
        line-height: 32px;
        margin: 0 auto 60px; } }
  .industry-empower--it h2 {
    margin-bottom: 20px; }
  .industry-empower--it .industry-empower__box {
    background: #fff; }

.dev-center {
  padding: 30px 0;
  border-bottom: 1px solid #D6E3F0; }
  @media screen and (min-width: 768px) {
    .dev-center {
      padding: 60px 0 10px; } }
  .dev-center h2 {
    font-family: "Poppins";
    text-align: center;
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .dev-center h2 {
        margin-bottom: 50px; } }
  .dev-center .dev-center__icon {
    overflow: hidden;
    border-radius: 10px;
    height: 100%; }
    .dev-center .dev-center__icon img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .dev-center__wrapper-gap {
    margin-bottom: 25px; }

.how-help-you--staff-aug {
  background: url(/images/staff-augmentation/engagement-bg.jpg) no-repeat center/cover; }
  .how-help-you--staff-aug .how-help-you__card-heading {
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    color: #333333; }
  .how-help-you--staff-aug .how-help-you__card-sub-heading {
    font-size: 18px;
    line-height: 25px; }
    @media screen and (min-width: 992px) {
      .how-help-you--staff-aug .how-help-you__card-sub-heading {
        padding: 0 14px; } }
  .how-help-you--staff-aug .how-help-you__card-items {
    display: flex;
    justify-content: flex-start; }
    @media screen and (min-width: 992px) {
      .how-help-you--staff-aug .how-help-you__card-items {
        padding: 40px 25px 40px; } }
  .how-help-you--staff-aug .how-help-you__icon {
    margin: 0 auto;
    display: block;
    height: 70px; }
    @media screen and (min-width: 992px) {
      .how-help-you--staff-aug .how-help-you__icon {
        margin: 0 auto 35px; } }
  .how-help-you--staff-aug .how-help-you__links {
    margin-top: auto; }
  @media screen and (min-width: 992px) {
    .how-help-you--staff-aug .how-help-you__heading {
      font-size: 45px;
      line-height: 50px; } }

.stuff-augmentation-technologies--new {
  border-bottom: 1px solid #D6E3F0;
  padding-bottom: 30px; }
  @media screen and (min-width: 992px) {
    .stuff-augmentation-technologies--new {
      padding-bottom: 0; } }
  .stuff-augmentation-technologies--new .technologies-tab__heading {
    font-weight: 700; }
    @media screen and (min-width: 992px) {
      .stuff-augmentation-technologies--new .technologies-tab__heading {
        font-size: 45px;
        line-height: 55px; } }
  .stuff-augmentation-technologies--new .o-accordion__content-background {
    background: #EFF4F8; }
  .stuff-augmentation-technologies--new .o-accordion__content {
    background: #EFF4F8; }
  .stuff-augmentation-technologies--new .o-accordion__button {
    font-weight: 700; }
  .stuff-augmentation-technologies--new .accordion-button::after {
    background: url(/images/staff-augmentation/collapse.png) no-repeat center; }
  .stuff-augmentation-technologies--new .accordion-button:not(.collapsed)::after {
    transform: rotate(90deg); }
  @media screen and (min-width: 992px) {
    .stuff-augmentation-technologies--new .o-accordion__nav {
      padding-left: 0; } }
  @media screen and (min-width: 992px) {
    .stuff-augmentation-technologies--new .o-accordion__tab {
      padding-left: 0; } }
  .stuff-augmentation-technologies--new .pool-box-list {
    box-shadow: 5px 0px 10px 2px rgba(0, 0, 0, 0.1); }
    @media screen and (min-width: 992px) {
      .stuff-augmentation-technologies--new .pool-box-list a {
        padding: 14px 20px; } }
    .stuff-augmentation-technologies--new .pool-box-list small {
      font-size: 16px;
      font-weight: 400; }
  .stuff-augmentation-technologies--new .o-accordion__content-title {
    font-weight: 700; }
    @media screen and (min-width: 992px) {
      .stuff-augmentation-technologies--new .o-accordion__content-title {
        font-size: 36px;
        line-height: 42px; } }

.skill-review-staff-aug {
  padding-bottom: 90px; }
  @media screen and (min-width: 992px) {
    .skill-review-staff-aug {
      padding: 0 0 110px; } }
  .skill-review-staff-aug h2 {
    text-align: center;
    font-family: "Poppins"; }
    @media screen and (min-width: 992px) {
      .skill-review-staff-aug h2 {
        font-size: 45px;
        font-weight: 700;
        line-height: 56px;
        margin-top: 25px; } }
  .skill-review-staff-aug .b2b-text {
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: #355BBF;
    display: block;
    text-align: center;
    margin-top: 25px; }
  .skill-review-staff-aug .custom-review__reviewitems {
    max-width: inherit;
    height: 100%;
    border: 1px solid #D0E3F5;
    padding: 40px 15px 40px 40px; }
  .skill-review-staff-aug .custom-review__quotecontent {
    font-size: 24px;
    line-height: 34px;
    font-weight: 500; }
  .skill-review-staff-aug .custom-review__authordesignation {
    display: inline-block; }
  .skill-review-staff-aug .custom-review__authorimage {
    width: 50px;
    height: 50px;
    flex: 0 0 50px; }
    .skill-review-staff-aug .custom-review__authorimage picture {
      width: 50px;
      height: 50px;
      object-fit: cover;
      display: inline-block; }
      .skill-review-staff-aug .custom-review__authorimage picture img,
      .skill-review-staff-aug .custom-review__authorimage picture source {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .skill-review-staff-aug .custom-review__quoteimg {
    margin-bottom: 30px; }
  .skill-review-staff-aug .custom-review .splide__arrows {
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    bottom: -110px; }
  .skill-review-staff-aug .custom-review .splide__arrow {
    margin: 0 5px;
    width: 50px;
    height: 50px;
    opacity: 1;
    border: 1px solid #AEC6DC;
    background-color: transparent; }
    .skill-review-staff-aug .custom-review .splide__arrow svg {
      fill: #AEC6DC;
      font-size: 40px;
      font-weight: 500; }
    .skill-review-staff-aug .custom-review .splide__arrow:hover {
      background: #0D65CA; }
      .skill-review-staff-aug .custom-review .splide__arrow:hover svg {
        fill: #fff; }
    .skill-review-staff-aug .custom-review .splide__arrow:disabled {
      opacity: 1;
      border: 1px solid #AEC6DC;
      background-color: transparent; }
      .skill-review-staff-aug .custom-review .splide__arrow:disabled svg {
        fill: #AEC6DC; }
      .skill-review-staff-aug .custom-review .splide__arrow:disabled:hover {
        background: #0D65CA; }
        .skill-review-staff-aug .custom-review .splide__arrow:disabled:hover svg {
          fill: #fff; }
  .skill-review-staff-aug .custom-review .splide__arrow--prev {
    position: static; }
  .skill-review-staff-aug .custom-review .splide__arrow--next {
    position: static; }
  .skill-review-staff-aug .recognize-reviews {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 25px; }
    @media screen and (min-width: 992px) {
      .skill-review-staff-aug .recognize-reviews {
        justify-content: center;
        margin-bottom: 50px; } }
    @media screen and (min-width: 992px) {
      .skill-review-staff-aug .recognize-reviews li {
        margin: 0 30px; } }
    .skill-review-staff-aug .recognize-reviews li img {
      width: 70px; }
      @media screen and (min-width: 768px) {
        .skill-review-staff-aug .recognize-reviews li img {
          width: auto; } }
  .skill-review-staff-aug .splide__slide:before {
    content: '';
    position: absolute;
    top: 0;
    left: 40px;
    background: #ccc;
    width: 38px;
    height: 4px; }
  .skill-review-staff-aug .splide__slide:nth-child(1):before {
    background: #20DEA5; }
  .skill-review-staff-aug .splide__slide:nth-child(2):before {
    background: #FFC327; }
  .skill-review-staff-aug .splide__slide:nth-child(3):before {
    background: #A66BD4; }
  .skill-review-staff-aug .splide__slide:nth-child(4):before {
    background: #4a5806; }
  .skill-review-staff-aug .splide__slide:nth-child(5):before {
    background: #EF8D1A; }
  .skill-review-staff-aug .splide__slide:nth-child(6):before {
    background: #0A9DEF; }
  .skill-review-staff-aug .splide__slide:nth-child(7):before {
    background: #f280df; }
  .skill-review-staff-aug .splide__slide:nth-child(8):before {
    background: #ff9d09; }

.o-development-process--it-staff-aug .o-skill-flow__title {
  font-weight: 700 !important;
  font-size: 28px !important;
  line-height: 40px !important; }
  @media screen and (min-width: 992px) {
    .o-development-process--it-staff-aug .o-skill-flow__title {
      font-size: 45px !important;
      line-height: 60px !important; } }

@media screen and (min-width: 992px) {
  .o-development-process--it-staff-aug .o-development-process__list .o-development-process__items {
    width: 46%;
    margin-top: 60px; } }

@media screen and (min-width: 992px) {
  .o-development-process--it-staff-aug .o-development-process__list .o-development-process__items:nth-child(3) {
    margin-left: 0;
    margin-right: 30px;
    margin-top: 60px; } }

.o-development-process--it-staff-aug .o-development-process__list .o-development-process__items:nth-child(3):after {
  display: none; }

.o-development-process--it-staff-aug .o-development-process__list .o-development-process__items:nth-child(2) {
  margin-right: 0; }
  .o-development-process--it-staff-aug .o-development-process__list .o-development-process__items:nth-child(2):after {
    display: none; }

.o-development-process--it-staff-aug .o-development-process__list .o-development-process__items:nth-child(4) {
  margin-right: 0; }
  @media screen and (min-width: 992px) {
    .o-development-process--it-staff-aug .o-development-process__list .o-development-process__items:nth-child(4) {
      margin-left: 30px; } }
  .o-development-process--it-staff-aug .o-development-process__list .o-development-process__items:nth-child(4):after {
    display: none; }

.o-development-process--it-staff-aug .o-development-process__list .o-development-process__items:after {
  display: none; }

@media screen and (min-width: 992px) {
  .o-core-competencies--it-staff-aug .o-core-competencies__box {
    padding: 30px 30px 0;
    min-height: 230px; } }

.o-core-competencies--it-staff-aug .o-core-competencies__box {
  text-align: left;
  position: relative; }
  .o-core-competencies--it-staff-aug .o-core-competencies__box:before {
    content: '';
    position: absolute;
    background: #000;
    width: 38px;
    height: 4px;
    top: -2px;
    left: 30px; }
  .o-core-competencies--it-staff-aug .o-core-competencies__box--color1:before {
    background: #0a9def; }
  .o-core-competencies--it-staff-aug .o-core-competencies__box--color2:before {
    background: #ffbd00; }
  .o-core-competencies--it-staff-aug .o-core-competencies__box--color3:before {
    background: #20dea5; }
  .o-core-competencies--it-staff-aug .o-core-competencies__box--color4:before {
    background: #20dea5; }
  .o-core-competencies--it-staff-aug .o-core-competencies__box--color5:before {
    background: #0a9def; }
  .o-core-competencies--it-staff-aug .o-core-competencies__box--color6:before {
    background: #ef8d1a; }
  .o-core-competencies--it-staff-aug .o-core-competencies__box--color7:before {
    background: #fe2f05; }
  .o-core-competencies--it-staff-aug .o-core-competencies__box--color8:before {
    background: #ee16c4; }
  .o-core-competencies--it-staff-aug .o-core-competencies__box--color9:before {
    background: #2aa7b3; }

.skill-review-staff-aug--it h2 {
  margin-top: 0; }

.custom-dev-case-study--staff-aug-it .o-testimonial__heading {
  font-weight: 700; }
  @media screen and (min-width: 992px) {
    .custom-dev-case-study--staff-aug-it .o-testimonial__heading {
      font-size: 45px;
      line-height: 60px;
      margin-bottom: 15px; } }

.custom-dev-case-study--staff-aug-it .o-testimonial__sub-heading {
  font-family: Poppins;
  text-align: center;
  font-weight: 500; }
  @media (min-width: 992px) {
    .custom-dev-case-study--staff-aug-it .o-testimonial__sub-heading {
      font-size: 19px;
      line-height: 32px;
      margin: 0 auto 60px;
      margin-bottom: 45px; } }

.banner--staff-aug {
  position: relative;
  text-align: center;
  background-color: #081c36;
  background-image: none; }
  @media (min-width: 992px) {
    .banner--staff-aug {
      background-image: url(/images/staff-augmentation/staff-banner-bg.png);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover; } }
  @media (min-width: 1024px) {
    .banner--staff-aug {
      background-size: 100% 100%; } }
  .banner--staff-aug .banner__top-rated {
    display: inline-flex;
    background: transparent;
    border-color: #a3a8b0;
    position: relative;
    z-index: 1;
    flex-wrap: wrap; }
    @media (min-width: 992px) {
      .banner--staff-aug .banner__top-rated {
        flex-wrap: nowrap; } }
  .banner--staff-aug .banner__top-rating {
    background: #fff;
    height: 68px;
    align-items: center;
    justify-content: center;
    display: flex; }
  .banner--staff-aug .banner__top-rating-number {
    color: #515050;
    font-family: "Poppins"; }
  .banner--staff-aug .banner__top-rated-text {
    font-family: "Poppins"; }
  .banner--staff-aug .banner__top-rating-review {
    color: #515050;
    font-family: "Poppins"; }
  .banner--staff-aug .banner-technologies__heading {
    position: relative;
    font-size: 48px;
    margin: 25px 0; }
    @media (min-width: 1024px) {
      .banner--staff-aug .banner-technologies__heading {
        font-size: 105px;
        font-weight: 700;
        margin: 95px 0 45px; } }
    .banner--staff-aug .banner-technologies__heading:before {
      display: none;
      position: absolute;
      content: "";
      width: 410px;
      height: 693px;
      margin: auto;
      top: -304px;
      left: 0;
      right: 0;
      background: url(/images/staff-augmentation/staff-aug-text-background.png) no-repeat center;
      background-size: auto 100%; }
      @media (min-width: 992px) {
        .banner--staff-aug .banner-technologies__heading:before {
          display: block; } }
    .banner--staff-aug .banner-technologies__heading span {
      position: relative;
      z-index: 1; }
  .banner--staff-aug .banner-technologies__sub-heading {
    color: #fff;
    margin: 0 0 30px;
    font-family: "Poppins"; }
    @media (min-width: 1024px) {
      .banner--staff-aug .banner-technologies__sub-heading {
        font-size: 45px;
        line-height: 56px;
        font-weight: 700;
        margin: 0 0 45px; } }
  .banner--staff-aug .new-home-banner__list {
    max-width: 950px;
    width: 100%;
    margin: auto; }
  .banner--staff-aug .how-stuff-augmentation__btn {
    max-width: 312px;
    margin: 25px auto 0;
    padding: 17px 22px; }
    @media (min-width: 1024px) {
      .banner--staff-aug .how-stuff-augmentation__btn {
        margin: 40px auto 0; } }

.why-choose--staff-aug {
  background-color: #fff; }
  .why-choose--staff-aug .why-choose__heading {
    font-weight: 700; }
    @media (min-width: 1024px) {
      .why-choose--staff-aug .why-choose__heading {
        font-size: 45px;
        line-height: 60px;
        max-width: 950px;
        margin: 0 auto 60px; } }
  .why-choose--staff-aug .why-choose__item {
    border: 1px solid #dde2e9;
    border-radius: 5px; }
    @media (min-width: 992px) {
      .why-choose--staff-aug .why-choose__item {
        flex: 0 0 32%;
        height: 200px; } }
    .why-choose--staff-aug .why-choose__item:after {
      position: absolute;
      content: "";
      width: 38px;
      height: 4px;
      left: 20px;
      bottom: 0;
      background-color: #0a9def; }
    .why-choose--staff-aug .why-choose__item--blue:after {
      background-color: #0a9def; }
    .why-choose--staff-aug .why-choose__item--yellow:after {
      background-color: #ffbd00; }
    .why-choose--staff-aug .why-choose__item--green:after {
      background-color: #20dea5; }
    .why-choose--staff-aug .why-choose__item--gold:after {
      background-color: #ef8d1a; }
    @media (min-width: 992px) {
      .why-choose--staff-aug .why-choose__item:nth-child(5) .why-choose__itemscontent {
        padding-right: 10px; } }
  @media (min-width: 992px) {
    .why-choose--staff-aug .why-choose__items--stuff-aug {
      padding: 30px 20px; } }
  @media (min-width: 992px) {
    .why-choose--staff-aug .why-choose__itemscontent {
      padding-top: 0;
      padding-left: 20px; } }
  @media (min-width: 1200px) {
    .why-choose--staff-aug .why-choose__itemscontent {
      padding-right: 20px; } }
  .why-choose--staff-aug .why-choose__title {
    color: #0c2848;
    font-weight: 600; }
  .why-choose--staff-aug .why-choose__content {
    color: #0c2848;
    font-weight: 500;
    line-height: 25px; }
  .why-choose--staff-aug .why-choose__more {
    text-align: center;
    margin: 30px 0 0; }
    @media (min-width: 1024px) {
      .why-choose--staff-aug .why-choose__more {
        margin: 80px 0 0; } }
    .why-choose--staff-aug .why-choose__more h3 {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 20px;
      line-height: normal;
      color: #262e36; }
      @media (min-width: 1024px) {
        .why-choose--staff-aug .why-choose__more h3 {
          font-size: 30px; } }
  .why-choose--staff-aug .why-choose__more-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center; }
    @media (min-width: 1024px) {
      .why-choose--staff-aug .why-choose__more-wrapper {
        flex-wrap: nowrap;
        justify-content: space-between; } }
  .why-choose--staff-aug .why-choose__more-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    flex-direction: column;
    max-width: 200px; }
    @media (min-width: 1024px) {
      .why-choose--staff-aug .why-choose__more-item {
        align-items: flex-start;
        justify-content: flex-start; } }
    .why-choose--staff-aug .why-choose__more-item i {
      height: 60px; }
    .why-choose--staff-aug .why-choose__more-item h4 {
      font-family: Poppins;
      font-size: 24px;
      line-height: 27px;
      color: #0c2848;
      font-weight: 600;
      margin: 0 0 5px;
      text-align: left; }
    .why-choose--staff-aug .why-choose__more-item p {
      font-family: Poppins;
      font-size: 16px;
      line-height: 27px;
      color: #0c2848;
      font-weight: 500;
      margin: 0;
      text-align: left; }
  .why-choose--staff-aug .sample-pods__btn {
    max-width: 340px;
    padding: 17px 22px; }
    .why-choose--staff-aug .sample-pods__btn svg {
      margin-left: 10px; }

.why-choose--staff-aug-it .why-choose__heading {
  margin: 0 auto 20px; }

.why-choose--staff-aug-it__sub-heading {
  font-family: Poppins;
  text-align: center;
  font-weight: 500; }
  @media (min-width: 992px) {
    .why-choose--staff-aug-it__sub-heading {
      font-size: 19px;
      line-height: 32px;
      margin: 0 auto 60px; } }

.banner--staff-aug-it .banner-technologies__heading {
  font-size: 30px; }
  @media (min-width: 992px) {
    .banner--staff-aug-it .banner-technologies__heading {
      font-size: 50px;
      line-height: 64px;
      margin: 0 0 25px; } }
  .banner--staff-aug-it .banner-technologies__heading:before {
    display: none; }

.banner--staff-aug-it__sub-heading {
  color: #fff;
  font-weight: 500;
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 34px; }
  @media (min-width: 992px) {
    .banner--staff-aug-it__sub-heading {
      font-size: 22px;
      line-height: 36px; } }
  .banner--staff-aug-it__sub-heading .highlight-color1 {
    position: relative;
    display: inline-block; }
    .banner--staff-aug-it__sub-heading .highlight-color1::before {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      -webkit-animation: 4s linear animate infinite;
      animation: 4s linear animate infinite;
      background: -webkit-gradient(linear, left top, right top, from(#03a9f4), color-stop(#ff5800), color-stop(#fbba14), to(#03a9f4));
      background: linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
      background-size: 200%; }

/* ANCHOR - Portfolio2 */
.portfolio2-view .portfolio-view__rightittle {
  align-items: flex-start; }

.portfolio2-view .portfolio-view__rightittlearea {
  flex: 1;
  display: flex; }
  .portfolio2-view .portfolio-view__rightittlearea h4 {
    font-size: 16px;
    font-weight: 500; }

.portfolio2-view .portfolio-view__filterlist {
  max-height: 0;
  width: 235px;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  transition: max-height 0.2s ease-out;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 9;
  top: 60px; }

.portfolio2-view .portfolio-view__filterlist.show {
  max-height: 400px;
  transition: max-height 0.5s ease-in;
  background: #fbfbfb;
  opacity: 1;
  visibility: visible;
  padding: 5px 10px;
  border: 1px solid #ccc; }

.portfolio2-view .filter-arrow {
  transition: transform 0.5s;
  margin-left: 10px; }

.portfolio2-view .filter-arrow.down {
  transform: rotate(180deg); }

.portfolio2-view .filter-arrow.up {
  transform: rotate(0deg); }

.portfolio2-view .portfolio-view__filtertittle {
  cursor: pointer;
  border-radius: 25px;
  margin-bottom: 0;
  background: none;
  padding: 10px 20px; }
  .portfolio2-view .portfolio-view__filtertittle--blue {
    border: 1px solid #85a7f9; }
  .portfolio2-view .portfolio-view__filtertittle--green {
    border: 1px solid #39c165; }
  .portfolio2-view .portfolio-view__filtertittle--orange {
    border: 1px solid #ff7d34; }
  .portfolio2-view .portfolio-view__filtertittle h4 {
    color: #333;
    margin-right: 10px; }

.portfolio2-view .portfolio-view__rightpart {
  position: relative; }

.portfolio2-view .portfolio-view__spacing {
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada; }
  @media screen and (min-width: 992px) {
    .portfolio2-view .portfolio-view__spacing {
      padding: 20px 0 0;
      margin-bottom: 40px; } }
  .portfolio2-view .portfolio-view__spacing.fixed {
    position: sticky;
    top: -10px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
    background: #f6f3f3;
    border-top: 1px solid #ccc;
    margin-bottom: 0; }

@media screen and (min-width: 992px) {
  .portfolio2-view .no-data {
    position: absolute;
    top: -15px;
    height: auto;
    font-size: 24px;
    margin-top: 0; } }

.portfolio2-view .portfolio-view__filterwrapper {
  padding-left: 0;
  margin-left: 40px;
  border-bottom: 0;
  margin-bottom: 0;
  padding-right: 10px; }
  .portfolio2-view .portfolio-view__filterwrapper:before {
    content: "Or";
    bottom: inherit;
    left: inherit;
    right: -30px;
    top: 10px;
    background: none;
    border: 1px solid #c8d3ef;
    color: #7a89af; }
  .portfolio2-view .portfolio-view__filterwrapper--industry {
    margin-left: 0; }

@media screen and (min-width: 992px) {
  .portfolio2-view .portfolio-view__masonry {
    column-count: 3; } }

.portfolio2-view .portfolio-view__filteritem label {
  font-size: 14px;
  line-height: 31px; }

.portfolio2-view .portfolio-view__titlelink .portfolio-tittle {
  font-size: 20px;
  line-height: 30px;
  padding-right: 3px;
  margin: 0; }

.portfolio2-view .portfolio-view__titlelink .portfolio-link img {
  margin-right: 5px; }

.portfolio2-view .portfolio-view__titlelink .portfolio-view__itemlink {
  width: auto; }

.portfolio2-view .portfolio-view__masonry.orphan3 {
  orphans: 1; }

.portfolio2-view .pagination-container {
  margin-top: 20px;
  justify-content: center; }
  .portfolio2-view .pagination-container span {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 600; }
    @media screen and (min-width: 992px) {
      .portfolio2-view .pagination-container span {
        margin-right: 13px;
        font-size: 20px; } }
  .portfolio2-view .pagination-container .pagination-next {
    margin-right: 0; }

.portfolio-banner2 {
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .portfolio-banner2 {
      padding: 100px 0; } }
  .portfolio-banner2 .portfolio-banner__assets:before {
    display: none; }
  .portfolio-banner2 .portfolio-banner__heading-text {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 15px; }
    @media screen and (min-width: 992px) {
      .portfolio-banner2 .portfolio-banner__heading-text {
        font-size: 48px;
        line-height: 50px; } }
  .portfolio-banner2 .portfolio-banner__middle {
    max-width: 100%;
    position: relative;
    padding: 10px 0 0; }
    @media screen and (min-width: 992px) {
      .portfolio-banner2 .portfolio-banner__middle {
        padding: 40px 25px 30px; } }
    .portfolio-banner2 .portfolio-banner__middle:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 21px;
      width: 100%;
      background: url(/images/new-portfolio/banner-rating/top-bracket.png) no-repeat 0 0/100%;
      display: none; }
      @media screen and (min-width: 992px) {
        .portfolio-banner2 .portfolio-banner__middle:before {
          display: block; } }
    .portfolio-banner2 .portfolio-banner__middle:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 22px;
      width: 100%;
      background: url(/images/new-portfolio/banner-rating/bottom-bracket.png) no-repeat 0 0/100%;
      display: none; }
      @media screen and (min-width: 992px) {
        .portfolio-banner2 .portfolio-banner__middle:after {
          display: block; } }
  .portfolio-banner2 .portfolio-banner__asset-heading {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    width: auto;
    margin-bottom: 0;
    display: inline-block;
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .portfolio-banner2 .portfolio-banner__asset-heading {
        position: absolute;
        top: -14px;
        left: 39px; } }
  .portfolio-banner2 .portfolio-banner__sub-heading {
    font-size: 24px;
    line-height: 32px;
    color: #fff;
    display: inline-block; }
    @media screen and (min-width: 992px) {
      .portfolio-banner2 .portfolio-banner__sub-heading {
        font-size: 32px;
        line-height: 40px;
        padding-right: 60px; } }
  .portfolio-banner2 .portfolio-banner__rating-items {
    display: flex;
    flex: 0 0 50%;
    margin: 20px 0;
    justify-content: center; }
    @media screen and (min-width: 992px) {
      .portfolio-banner2 .portfolio-banner__rating-items {
        flex: inherit;
        margin: 0;
        justify-content: inherit; } }
    .portfolio-banner2 .portfolio-banner__rating-items:nth-child(3) .portfolio-banner__rating-right {
      margin-left: 10px; }
    .portfolio-banner2 .portfolio-banner__rating-items .rating-img {
      width: 75%; }
      @media screen and (min-width: 992px) {
        .portfolio-banner2 .portfolio-banner__rating-items .rating-img {
          width: 85%; } }
  .portfolio-banner2 .portfolio-banner__rating-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
  .portfolio-banner2 .portfolio-banner__rating-left {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; }
    .portfolio-banner2 .portfolio-banner__rating-left .portfolio-banner__rating-icon-name {
      color: #fff;
      font-size: 15px;
      font-weight: 500; }
  .portfolio-banner2 .portfolio-banner__rating-right {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; }
    .portfolio-banner2 .portfolio-banner__rating-right .portfolio-banner__rating-number {
      color: #fff;
      font-size: 28px;
      font-weight: 500; }

.service-rating-img {
  width: 75%; }
  @media screen and (min-width: 992px) {
    .service-rating-img {
      width: 100%; } }

/* ANCHOR - Platforms */
.platform-review .custom-review__partner {
  display: none; }

@media screen and (min-width: 992px) {
  .platform-review .custom-review__tittle {
    font-size: 51px;
    line-height: 55px;
    font-weight: 700; } }

@media screen and (min-width: 992px) {
  .platform-review .custom-review__subtittle {
    font-size: 25px;
    line-height: 34px;
    font-weight: 600; } }

@media screen and (min-width: 992px) {
  .platform-review .cs-cont.invisible {
    height: 200px; } }

@media screen and (min-width: 992px) {
  .platform-review .custom-review {
    padding: 80px 0; } }

@media screen and (min-width: 992px) {
  .platform-review .custom-review__spacing {
    padding: 0; } }

@media screen and (min-width: 992px) {
  .platform-bottom-form {
    padding: 0 0 60px; } }

@media screen and (min-width: 992px) {
  .platform-bottom-form .new-bottom-form__left {
    padding-top: 80px; } }

@media screen and (min-width: 992px) {
  .platform-bottom-form .new-bottom-form__email {
    padding-top: 80px; } }

@media screen and (min-width: 992px) {
  .platform-bottom-form .new-bottom-form__trust {
    padding: 50px 40px 70px; } }

@media screen and (min-width: 992px) {
  .platform-mod .banner-technologies__heading {
    line-height: 68px; } }

@media screen and (min-width: 992px) {
  .platform-mod .banner-technologies {
    padding: 60px 0 80px; } }

@media screen and (min-width: 992px) {
  .platform-invite {
    padding-bottom: 80px;
    padding-top: 80px; } }

@media screen and (min-width: 992px) {
  .platform-invite .btn-orange.custom-review__btn {
    margin-left: 0; } }

.platform-invite .section-invite__item-title {
  font-weight: 700; }
  .platform-invite .section-invite__item-title span {
    font-weight: 600; }

.o-case-studies-new .faq-para {
  color: #333;
  font-size: 25px;
  line-height: 34px;
  font-weight: 600;
  text-align: center; }

.platform-work-with {
  padding: 40px 0;
  background: #F4F4F4; }
  @media screen and (min-width: 992px) {
    .platform-work-with {
      padding: 80px 0; } }
  .platform-work-with__wrapper {
    margin-top: 15px; }
    @media screen and (min-width: 992px) {
      .platform-work-with__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 30px; } }
  .platform-work-with__box-sub {
    background: #fff;
    position: relative;
    z-index: 9;
    padding: 20px 20px 20px 20px; }
    @media screen and (min-width: 992px) {
      .platform-work-with__box-sub {
        padding: 30px 35px 40px 35px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center; } }
  .platform-work-with__box {
    margin-bottom: 15px;
    border: 1px solid #898989;
    display: block;
    position: relative;
    overflow: hidden; }
    @media screen and (min-width: 992px) {
      .platform-work-with__box {
        overflow: hidden;
        padding: 2px;
        border: 0;
        margin-bottom: 25px;
        flex: 0 0 45%;
        max-width: 45%;
        margin: 20px 20px; } }
    .platform-work-with__box:after {
      content: '';
      position: absolute;
      top: -108px;
      right: 0;
      bottom: 0;
      left: -136px;
      width: 780px;
      height: 780px;
      transition: .3s all ease;
      display: none;
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
      display: none; }
      @media screen and (min-width: 992px) {
        .platform-work-with__box:after {
          display: block; } }
  @media screen and (min-width: 992px) {
    .platform-work-with__icon {
      display: inline-flex;
      height: 120px;
      align-items: center; } }
  .platform-work-with h3 {
    font-family: 'Poppins';
    font-size: 26px;
    line-height: 31px;
    font-weight: 600;
    color: #333333;
    margin-top: 10px; }
  .platform-work-with p {
    font-family: 'Poppins';
    color: #222222;
    font-size: 17px;
    line-height: 28px;
    font-weight: 400; }
  .platform-work-with__anchor {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    transition: .3s all ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px; }
    @media screen and (min-width: 992px) {
      .platform-work-with__anchor {
        margin-top: auto; } }
    .platform-work-with__anchor:hover {
      color: #00A1E0; }
      .platform-work-with__anchor:hover svg {
        transform: translateX(10px); }
        .platform-work-with__anchor:hover svg path {
          fill: #00A1E0; }
  .platform-work-with__arrow {
    margin-left: 10px; }
    .platform-work-with__arrow path {
      fill: #333333; }
  .platform-work-with .btn-wrapper-v2--pair .btn-v2 {
    margin-bottom: 0; }

@media screen and (min-width: 992px) {
  .platform-faq {
    padding: 70px 0 90px !important; } }

@media screen and (min-width: 992px) {
  .platform-faq h2 {
    padding-bottom: 10px; } }

@media screen and (min-width: 992px) {
  .platform-footer {
    padding: 80px 0 50px; } }

/* ANCHOR - Investor */
.investor-bnr {
  padding: 40px 0;
  background-image: none;
  min-height: auto; }
  @media screen and (min-width: 992px) {
    .investor-bnr {
      padding: 100px 0; } }
  .investor-bnr h1 {
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .investor-bnr h1 {
        font-size: 50px; } }

.investor-pdf {
  padding: 40px 0 40px; }
  @media screen and (min-width: 992px) {
    .investor-pdf {
      padding: 60px 0 70px; } }
  .investor-pdf h2 {
    font-size: 24px;
    line-height: 32px;
    text-align: center; }
  .investor-pdf__wrapper {
    margin-top: 25px; }
    @media screen and (min-width: 992px) {
      .investor-pdf__wrapper {
        margin-top: 0; } }
    .investor-pdf__wrapper--small {
      max-width: 800px;
      margin: 0 auto; }
    @media screen and (min-width: 992px) {
      .investor-pdf__wrapper--multi {
        margin-top: 40px; } }
  .investor-pdf__list {
    margin-top: 20px; }
  .investor-pdf__items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px 15px;
    border: 1px solid #333;
    border-bottom: 0; }
    .investor-pdf__items:last-child {
      border-bottom: 1px solid #333; }
  .investor-pdf__name {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .investor-pdf__name a:hover p {
      color: #355bbf; }
  .investor-pdf p {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    transition: .3s all ease-in-out; }
  .investor-pdf__icon {
    flex: 0 0 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .investor-pdf__icon a {
      display: inline-block; }
      .investor-pdf__icon a img {
        width: 30px; }

.investor-table {
  margin-bottom: 20px; }

.investor-table th {
  padding: 15px 0;
  background-color: #ddf0f8;
  color: #000;
  font-size: 18px;
  width: 33.3%; }

.investor-table td {
  padding: 14px 0 !important;
  font-size: 14px;
  color: #000;
  font-weight: 500;
  width: 33.3%; }
  @media screen and (min-width: 992px) {
    .investor-table td {
      font-size: 16px; } }

.ipo-heading {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 15px; }
  @media screen and (min-width: 992px) {
    .ipo-heading {
      font-size: 30px;
      line-height: 40px; } }

.ipo-block-separator {
  margin-bottom: 25px; }
  @media screen and (min-width: 992px) {
    .ipo-block-separator {
      margin-bottom: 35px; } }

/* ANCHOR - Guides */
.guide-home-banner__image {
  background: url(/images/guides/banner-bg.jpg) no-repeat center center/cover;
  position: relative;
  padding: 30px 0 30px; }
  @media screen and (min-width: 992px) {
    .guide-home-banner__image {
      min-height: 543px;
      padding: 100px 0 70px; } }
  .guide-home-banner__image:before {
    content: '';
    position: absolute;
    background: url(/images/guides/banner-gradient-tl.png) no-repeat 0 0;
    top: 0;
    left: 0;
    width: 668px;
    height: 486px;
    display: none; }
    @media screen and (min-width: 992px) {
      .guide-home-banner__image:before {
        display: block; } }

@media screen and (min-width: 992px) {
  .guide-home-banner__left {
    max-width: 55%; } }

.guide-home-banner__heading {
  font-family: "Poppins";
  font-size: 31px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .guide-home-banner__heading {
      text-align: left; } }

.guide-home-banner__details-green {
  color: #2DBE70; }

.guide-home-banner__sub-heading {
  font-family: "Poppins";
  font-weight: 700;
  color: #fff;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .guide-home-banner__sub-heading {
      text-align: left;
      font-size: 50px;
      line-height: 54px; } }

.guide-home-banner__download {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center; }
  @media screen and (min-width: 992px) {
    .guide-home-banner__download {
      padding-right: 65px;
      margin-top: 35px;
      flex-direction: row;
      align-items: flex-start; } }

.guide-home-banner__download-icon {
  flex: 0 0 45px;
  width: 45px;
  margin-right: 15px; }

.guide-home-banner__download-free {
  flex: 1;
  font-family: "Poppins";
  font-weight: 400;
  color: #fff;
  position: relative;
  text-align: center;
  font-size: 19px;
  line-height: 27px; }
  @media screen and (min-width: 992px) {
    .guide-home-banner__download-free {
      text-align: left;
      font-size: 31px;
      line-height: 36px; } }

.guide-home-banner__download-free-green {
  color: #2DBE70;
  font-weight: 700; }

.guide-home-banner__wrapper {
  position: inherit;
  display: flex;
  justify-content: flex-end; }

.guide-home-banner__right {
  position: relative;
  top: -35px; }
  @media screen and (min-width: 992px) {
    .guide-home-banner__right {
      position: absolute;
      top: 140px;
      max-width: 510px; } }

.guide-home-banner__form-wrapper {
  background: url(/images/guides/form-strip.png) repeat-x 0 0;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
  position: relative; }
  @media screen and (min-width: 992px) {
    .guide-home-banner__form-wrapper {
      padding: 40px; } }

.guide-home-banner__form-heading {
  font-family: "Poppins";
  font-weight: 700;
  color: #262E36;
  margin-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .guide-home-banner__form-heading {
      font-size: 31px;
      line-height: 37px;
      margin-bottom: 25px; } }

.guide-home-banner__input {
  font-family: "Poppins";
  padding: 18px 15px;
  width: 100%;
  border: 1px solid #CFCFCF;
  border-radius: 3px;
  color: #333;
  font-size: 15px; }
  .guide-home-banner__input::placeholder {
    color: #333;
    opacity: 1; }

.guide-home-banner__input-name {
  margin-bottom: 20px; }

.guide-home-banner__select {
  font-family: "Poppins";
  padding: 18px 15px;
  width: 100%;
  border: 1px solid #CFCFCF;
  border-radius: 3px;
  color: #333;
  font-size: 15px;
  margin-top: 20px;
  appearance: none;
  background: url(/images/guides/option-arrow.png) no-repeat 97% 50%; }

.guide-home-banner__form-para {
  font-family: "Poppins";
  color: #333;
  margin-top: 15px; }
  @media screen and (min-width: 992px) {
    .guide-home-banner__form-para {
      font-size: 15px;
      line-height: 23px;
      margin-top: 15px;
      margin-bottom: 15px; } }

.guide-home-banner__privacy {
  display: flex;
  align-items: flex-start;
  font-family: "Poppins";
  color: #333; }
  @media screen and (min-width: 992px) {
    .guide-home-banner__privacy {
      font-size: 14px;
      line-height: 20px;
      padding-right: 50px; } }

.guide-home-banner__privacy-icon {
  flex: 0 0 30px;
  width: 30px;
  margin-right: 10px; }

.guide-home-banner__review-splide {
  background: #355BBF;
  border-radius: 10px;
  padding: 20px 20px 20px 20px;
  margin-top: 20px; }
  @media screen and (min-width: 992px) {
    .guide-home-banner__review-splide {
      padding: 50px;
      margin-top: 60px; } }

.guide-home-banner__review-para {
  font-family: "Poppins";
  color: #fff;
  font-weight: 500;
  position: relative;
  padding-top: 35px;
  margin-bottom: 10px; }
  @media screen and (min-width: 992px) {
    .guide-home-banner__review-para {
      font-size: 24px;
      line-height: 35px;
      padding-top: 50px;
      margin-bottom: 25px; } }
  .guide-home-banner__review-para:before {
    content: '';
    position: absolute;
    background: url(/images/guides/double-quote.png) no-repeat 0 0;
    width: 32px;
    height: 26px;
    top: 0;
    left: 0; }

.guide-home-banner__review-name {
  font-family: "Poppins";
  color: #fff;
  font-weight: 500;
  font-size: 18px; }

.guide-home-banner__review-company {
  font-family: "Poppins";
  color: #fff;
  font-weight: 600;
  font-size: 14px; }

@media screen and (min-width: 992px) {
  .guide-home-banner__arrow-icon {
    position: absolute;
    top: -18px;
    right: -58px; } }

.guide-scrollable__radio {
  display: inline-flex;
  position: relative;
  padding-left: 27px;
  margin-right: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 15px;
  color: #333;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.guide-scrollable .guide-scrollable__radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer; }

.guide-scrollable .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #CFCFCF;
  border-radius: 50%; }

.guide-scrollable .guide-scrollable__radio:hover input ~ .checkmark {
  background-color: #ccc; }

.guide-scrollable .guide-scrollable__radio input:checked ~ .checkmark {
  background-color: #2196F3;
  border: none; }

.guide-scrollable .checkmark:after {
  content: "";
  position: absolute;
  display: none; }

.guide-scrollable .guide-scrollable__radio input:checked ~ .checkmark:after {
  display: block; }

.guide-scrollable .guide-scrollable__radio .checkmark:after {
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white; }

.guide-scrollable .btn-orange {
  margin: 15px 0 15px 0;
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 600;
  font-family: "Poppins";
  width: 275px;
  padding: 16px 20px; }

.rewrite {
  padding: 0 0 30px; }
  @media screen and (min-width: 992px) {
    .rewrite {
      padding: 80px 0 60px; } }
  @media screen and (min-width: 992px) {
    .rewrite__wrapper {
      max-width: 50%;
      margin-right: auto;
      margin-left: 0; } }
  .rewrite__heading {
    font-family: "Poppins";
    font-weight: 600;
    color: #333;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .rewrite__heading {
        font-size: 41px;
        line-height: 50px;
        text-align: left; } }
  .rewrite__sub-heading {
    font-family: "Poppins";
    font-weight: 500;
    color: #333;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .rewrite__sub-heading {
        font-size: 21px;
        line-height: 30px;
        text-align: left; } }
  @media screen and (min-width: 992px) {
    .rewrite__list {
      margin-bottom: 35px; } }
  .rewrite__items {
    display: flex;
    align-items: flex-start; }
  .rewrite__icon {
    display: flex;
    flex: 0 0 60px;
    width: 60px;
    margin-right: 15px; }
  .rewrite__details {
    flex: 1; }
  .rewrite__details-heading {
    font-family: "Poppins";
    font-weight: 700;
    color: #333;
    font-size: 19px;
    line-height: 27px; }
    @media screen and (min-width: 992px) {
      .rewrite__details-heading {
        font-size: 24px;
        line-height: 32px; } }
  .rewrite__details-para {
    font-family: "Poppins";
    font-weight: 400;
    color: #333; }
    @media screen and (min-width: 992px) {
      .rewrite__details-para {
        font-size: 15px;
        line-height: 25px; } }

.download-free-guide {
  background: #04254A;
  text-align: center;
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .download-free-guide {
      padding: 60px 0; } }
  .download-free-guide--s1000 {
    background: url(/images/guides/s1000d/download-free-guide-bg.jpg) no-repeat center center/cover; }
    .download-free-guide--s1000 .download-free-guide__heading--s1000 {
      margin-bottom: 10px; }
  .download-free-guide__heading {
    font-family: "Poppins";
    font-weight: 400;
    color: #fff;
    margin-bottom: 15px;
    position: relative; }
    @media screen and (min-width: 992px) {
      .download-free-guide__heading {
        font-size: 31px;
        line-height: 35px;
        margin-bottom: 25px; } }
    .download-free-guide__heading strong {
      color: #2DBE70; }
    .download-free-guide__heading--s1000 {
      font-weight: 700; }
      @media screen and (min-width: 992px) {
        .download-free-guide__heading--s1000 {
          font-size: 35px;
          line-height: 55px; } }
  .download-free-guide__sub-heading {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: #CAE7FF; }
  @media screen and (min-width: 992px) {
    .download-free-guide__btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around; } }
  .download-free-guide__btn-wrapper .btn-orange {
    margin: 10px auto 0; }
    @media screen and (min-width: 992px) {
      .download-free-guide__btn-wrapper .btn-orange {
        margin: 0; } }
  @media screen and (min-width: 992px) {
    .download-free-guide__btn-wrapper-left {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      margin-right: 10px; } }
  @media screen and (min-width: 992px) {
    .download-free-guide__btn-wrapper-right {
      display: flex;
      justify-content: flex-start;
      flex: 1;
      margin-left: 10px; } }
  .download-free-guide__btn-wrapper-right .btn-orange {
    background: none;
    color: #fff; }
    .download-free-guide__btn-wrapper-right .btn-orange:hover {
      color: #ff5800;
      border-color: #fff; }
      .download-free-guide__btn-wrapper-right .btn-orange:hover svg path {
        fill: #ff5800; }
  .download-free-guide__arrow {
    position: absolute;
    top: 45px;
    right: 145px; }
  .download-free-guide .btn-orange {
    text-transform: capitalize; }
    @media screen and (min-width: 992px) {
      .download-free-guide .btn-orange {
        max-width: 350px;
        padding: 15px 20px;
        font-size: 17px; } }
    .download-free-guide .btn-orange svg {
      margin-left: 10px; }

.guide-modal__download-form .guide-modal__input {
  font-family: "Poppins";
  padding: 18px 15px;
  width: 100%;
  border: 1px solid #CFCFCF;
  border-radius: 3px;
  color: #333;
  font-size: 15px; }
  .guide-modal__download-form .guide-modal__input::placeholder {
    color: #333;
    opacity: 1; }

.guide-modal__download-form .guide-modal__input-name {
  font-family: "Poppins";
  padding: 18px 15px;
  width: 100%;
  border: 1px solid #CFCFCF;
  border-radius: 3px;
  color: #333;
  font-size: 15px;
  margin-bottom: 15px; }
  .guide-modal__download-form .guide-modal__input-name::placeholder {
    color: #333;
    opacity: 1; }

.guide-modal__download-form p {
  padding-top: 0px; }

.guide-modal__download-form .guide-modal__radio {
  display: inline-flex;
  margin-bottom: 15px; }

.guide-modal__download-form .guide-home-banner__form {
  padding: 20px 15px 20px 15px; }
  @media screen and (min-width: 992px) {
    .guide-modal__download-form .guide-home-banner__form {
      padding: 20px 40px 20px 40px; } }

.guide-modal .guide-home-banner__select {
  margin-top: 5px;
  appearance: none;
  background: url(/images/guides/option-arrow.png) no-repeat 97% 50%; }

.o-banner-s1000d {
  position: relative; }
  .o-banner-s1000d__images {
    background: #091260 url(/images/guides/s1000d/banner-bg.jpg) no-repeat center center/cover;
    position: relative;
    padding: 85px 0 30px; }
    @media screen and (min-width: 992px) {
      .o-banner-s1000d__images {
        min-height: 543px;
        padding: 140px 0 70px; } }
  .o-banner-s1000d__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    margin-top: 20px; }
    @media screen and (min-width: 992px) {
      .o-banner-s1000d__wrapper {
        flex-direction: row;
        margin-top: 0; } }
  .o-banner-s1000d__leftside {
    width: 100%; }
    @media screen and (min-width: 992px) {
      .o-banner-s1000d__leftside {
        width: 60%; } }
  .o-banner-s1000d__rightside {
    width: 100%; }
    @media screen and (min-width: 992px) {
      .o-banner-s1000d__rightside {
        width: 40%; } }
  .o-banner-s1000d__bannerheading {
    font-family: "Poppins";
    font-size: 31px;
    line-height: 40px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin: 20px 0; }
    @media screen and (min-width: 992px) {
      .o-banner-s1000d__bannerheading {
        text-align: left;
        font-size: 50px;
        line-height: 60px;
        margin-top: 0; } }
  .o-banner-s1000d__bannercontent {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    text-align: center;
    color: #ABD2FF; }
    @media screen and (min-width: 992px) {
      .o-banner-s1000d__bannercontent {
        font-size: 24px;
        line-height: 35px;
        text-align: left; } }
  .o-banner-s1000d__contenthighlight {
    color: #fff;
    position: relative;
    padding: 0 0 5px; }
    .o-banner-s1000d__contenthighlight:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 4px;
      border-radius: 30px;
      bottom: 0;
      left: 0;
      animation: 4s linear animate infinite;
      background: -webkit-gradient(linear, left top, right top, from(#03a9f4), color-stop(#ff5800), color-stop(#fbba14), to(#03a9f4));
      background: linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
      background-size: 200%; }
  .o-banner-s1000d__formarea {
    background-color: #153995;
    max-width: 417px;
    margin: auto;
    padding: 20px 15px; }
    @media screen and (min-width: 992px) {
      .o-banner-s1000d__formarea {
        padding: 40px; } }
  .o-banner-s1000d__formtitle {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    text-align: center;
    color: #fff; }
    @media screen and (min-width: 992px) {
      .o-banner-s1000d__formtitle {
        font-size: 31px;
        line-height: 38px;
        text-align: left; } }
  .o-banner-s1000d__formsubtitle {
    position: relative;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    color: #ABD2FF; }
    @media screen and (min-width: 992px) {
      .o-banner-s1000d__formsubtitle {
        font-size: 15px;
        line-height: 20px;
        text-align: left; } }
    .o-banner-s1000d__formsubtitle:after {
      position: absolute;
      content: "";
      width: 80px;
      height: 89px;
      background: url(/images/guides/s1000d/form-arrow.svg) no-repeat center center/100%;
      right: -40px;
      bottom: -20px; }
  .o-banner-s1000d__input {
    width: 100%;
    height: 55px;
    margin-bottom: 12px;
    padding: 15px;
    border-radius: 2px;
    font-size: 14px;
    color: #000;
    border: 0px; }
    .o-banner-s1000d__input::placeholder {
      opacity: 1 !important;
      font-weight: 500 !important; }
  .o-banner-s1000d input::placeholder {
    opacity: 1 !important;
    font-weight: 500 !important; }
  .o-banner-s1000d .c-btn {
    width: 100%;
    text-transform: capitalize;
    font-size: 17px;
    font-weight: 600;
    border-radius: 5px; }
  .o-banner-s1000d .confidential-text-wrap li {
    color: #fff;
    font-size: 14px; }

.o-why-guide {
  padding: 30px 0;
  background: #F5F8FC;
  border-bottom: 1px solid #D3E2F0; }
  @media screen and (min-width: 992px) {
    .o-why-guide {
      padding: 80px 0; } }
  .o-why-guide__wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap; }
  .o-why-guide__imagearea {
    width: 100%;
    position: relative;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-why-guide__imagearea {
        width: 30%;
        text-align: inherit; } }
  @media screen and (min-width: 992px) {
    .o-why-guide__image {
      position: absolute;
      bottom: -40px;
      left: 0; } }
  .o-why-guide__textpart {
    width: 100%; }
    @media screen and (min-width: 992px) {
      .o-why-guide__textpart {
        width: 70%; } }
  .o-why-guide__heading {
    font-family: "Poppins";
    font-size: 35px;
    line-height: 50px;
    color: #262E36; }
  .o-why-guide__content {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 26px;
    color: #333;
    max-width: 656px;
    margin-bottom: 15px; }
    .o-why-guide__content b {
      font-weight: 600; }

.o-what-learn {
  padding: 40px 0; }
  .o-what-learn__heading {
    font-family: "Poppins";
    font-size: 35px;
    line-height: 50px;
    color: #262E36;
    margin-bottom: 60px;
    text-align: center; }
  .o-what-learn__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .o-what-learn__item {
    padding: 10px; }
  .o-what-learn__items {
    max-width: 548px;
    display: flex;
    min-height: 101px; }
    .o-what-learn__items i {
      margin-right: 17px; }
    .o-what-learn__items h5 {
      font-family: "Poppins";
      font-size: 24px;
      line-height: 27px;
      margin-bottom: 10px;
      color: #0C2848;
      font-weight: 600; }
    .o-what-learn__items p {
      font-family: "Poppins";
      font-size: 16px;
      line-height: 22px;
      margin: 0;
      color: #0C2848; }

.why-choose-guide {
  background: #F5F8FC;
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .why-choose-guide {
      padding: 60px 0; } }
  .why-choose-guide__heading {
    font-family: "Poppins";
    font-size: 35px;
    line-height: 50px;
    font-weight: 700;
    margin: 0;
    color: #262E36;
    text-align: center;
    margin-bottom: 35px; }
  .why-choose-guide__card {
    background: #fff;
    padding: 40px 30px;
    position: relative;
    border: 1px solid #DDDDDD;
    box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.05);
    height: 100%;
    height: 165px;
    display: flex; }
    .why-choose-guide__card:before {
      content: '';
      position: absolute;
      top: -3px;
      left: 30px;
      width: 90px;
      height: 5px;
      background: #ccc; }
    .why-choose-guide__card--color1:before {
      background: #64B9F4; }
    .why-choose-guide__card--color2:before {
      background: #3CC065; }
    .why-choose-guide__card--color3:before {
      background: #FBBA14; }
  .why-choose-guide__card-details {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 29px;
    font-weight: 500;
    color: #262E36;
    margin-bottom: 0;
    display: flex;
    align-items: center; }
  .why-choose-guide .btn-orange {
    max-width: 374px;
    margin-top: 35px;
    text-transform: capitalize;
    padding: 16px 20px; }

.section-footer--guide {
  background: #18192B; }
  .section-footer--guide .section-footer__copy-right-details {
    margin-bottom: 0; }
  .section-footer--guide .section-footer__social-list {
    justify-content: flex-end; }

.g-recaptcha-container {
  width: 100%;
  margin-bottom: 19px; }
  @media screen and (min-width: 992px) {
    .g-recaptcha-container div {
      transform: scale(1.037);
      transform-origin: top left;
      -webkit-transform: scale(1.037);
      -webkit-transform-origin: top left; } }
  .g-recaptcha-container .g-recaptcha {
    width: 100% !important;
    height: auto; }

/* ANCHOR - Events */
.o-skill-banner--event {
  background-image: url(/images/events/event-banner.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 90px 0 20px; }
  @media screen and (min-width: 992px) {
    .o-skill-banner--event {
      padding: 80px 0 8px; } }
  .o-skill-banner--event .o-skill-banner--event-bg {
    position: relative; }
    @media screen and (min-width: 992px) {
      .o-skill-banner--event .o-skill-banner--event-bg {
        min-height: 350px;
        display: flex;
        align-items: center; } }
  .o-skill-banner--event .o-skill-banner--event-img {
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-skill-banner--event .o-skill-banner--event-img {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 50%;
        text-align: left; } }
    @media screen and (min-width: 1340px) {
      .o-skill-banner--event .o-skill-banner--event-img {
        width: auto; } }
    @media screen and (min-width: 992px) {
      .o-skill-banner--event .o-skill-banner--event-img img {
        height: 100%;
        object-fit: contain;
        width: 100%; } }
    @media screen and (min-width: 1340px) {
      .o-skill-banner--event .o-skill-banner--event-img img {
        width: auto;
        object-fit: cover; } }
  .o-skill-banner--event .o-skill-banner--event-heading {
    font-family: "Poppins";
    font-weight: 700;
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    margin-top: 15px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-skill-banner--event .o-skill-banner--event-heading {
        margin-top: 0;
        text-align: left;
        padding-right: 250px; } }
    @media screen and (min-width: 1280px) {
      .o-skill-banner--event .o-skill-banner--event-heading {
        font-size: 40px;
        line-height: 52px;
        padding-right: 0; } }
    @media screen and (min-width: 1440px) {
      .o-skill-banner--event .o-skill-banner--event-heading {
        font-size: 44px;
        line-height: 54px; } }
    @media screen and (min-width: 1630px) {
      .o-skill-banner--event .o-skill-banner--event-heading {
        font-size: 51px;
        line-height: 60px; } }
    .o-skill-banner--event .o-skill-banner--event-heading span {
      color: #3cc065;
      position: relative; }
      .o-skill-banner--event .o-skill-banner--event-heading span::before {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 2px;
        background: #20dea5;
        background: linear-gradient(90deg, #20dea5 0%, #ffad40 74%, #ffad40 100%); }
        @media screen and (min-width: 992px) {
          .o-skill-banner--event .o-skill-banner--event-heading span::before {
            bottom: 5px;
            height: 4px; } }
  .o-skill-banner--event .o-skill-banner--event-sub {
    font-family: "Poppins";
    font-weight: 500;
    color: #cee8ff;
    margin-bottom: 0;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .o-skill-banner--event .o-skill-banner--event-sub {
        font-size: 19px;
        line-height: 30px;
        text-align: left;
        padding-right: 220px; } }
    @media screen and (min-width: 1440px) {
      .o-skill-banner--event .o-skill-banner--event-sub {
        font-size: 22px;
        line-height: 32px;
        padding-right: 0; } }

.event-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 999999; }
  .event-modal__content {
    background: #fff;
    border-radius: 10px;
    text-align: center;
    max-width: 500px;
    position: relative;
    width: 96%; }
    @media screen and (min-width: 992px) {
      .event-modal__content {
        width: auto; } }
  .event-modal__head {
    padding: 15px 20px 10px 20px;
    text-align: left;
    background: linear-gradient(135deg, #00b35c -22%, #0e5579 47%, #004475 100%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
    @media screen and (min-width: 992px) {
      .event-modal__head {
        padding: 15px 35px 10px 35px; } }
  .event-modal__heading {
    color: #fff;
    margin-bottom: 5px; }
    @media screen and (min-width: 992px) {
      .event-modal__heading {
        font-family: "Poppins";
        font-size: 32px;
        line-height: 36px; } }
  .event-modal__date {
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500; }
  .event-modal__location {
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500; }
  .event-modal__body {
    padding: 20px 20px 20px 20px; }
    @media screen and (min-width: 992px) {
      .event-modal__body {
        padding: 0 20px 0 20px; } }
  .event-modal__location {
    margin-bottom: 0; }
  .event-modal__close {
    position: absolute;
    top: -11px;
    right: -11px;
    font-size: 34px;
    cursor: pointer;
    z-index: 99;
    background: #fff;
    width: 40px;
    height: 40px;
    line-height: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #f5f5f5; }

.all-event {
  padding: 40px 0 15px; }
  @media screen and (min-width: 992px) {
    .all-event {
      padding: 60px 0 35px; } }
  .all-event__box-wrapper {
    position: relative;
    width: 100%;
    background: linear-gradient(-180deg, #88deb1 0, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
    padding: 2px;
    overflow: hidden; }
    .all-event__box-wrapper:before {
      display: block;
      content: "";
      position: absolute;
      top: -140px;
      right: 0;
      bottom: 0;
      left: -227px;
      background: #d6e3f0;
      width: 760px;
      height: 700px;
      transition: 0.5s all ease-in-out;
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: -webkit-linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      background: linear-gradient(180deg, #88deb1 0%, #1ab864 20%, rgba(35, 116, 212, 0.62) 52%, rgba(6, 87, 181, 0.62) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1); }
    .all-event__box-wrapper .all-event__box {
      border: none; }
  .all-event__box {
    border: 1px solid #d9d9d9;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
    width: 100%;
    padding-bottom: 20px;
    position: relative;
    background: #fff; }
    @media screen and (min-width: 992px) {
      .all-event__box {
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        min-height: 482px; } }
    .all-event__box--past-event {
      opacity: 0.7;
      pointer-events: none; }
      .all-event__box--past-event .all-event__box-btn {
        background: #d8d8d8;
        border-color: #d8d8d8;
        color: #999999; }
        .all-event__box--past-event .all-event__box-btn svg path {
          fill: #999999; }
  .all-event .col-lg-4 {
    margin-bottom: 25px; }
  .all-event__box-details {
    padding: 20px 20px 0 20px; }
    @media screen and (min-width: 992px) {
      .all-event__box-details {
        padding: 25px 30px 0 40px; } }
  .all-event__box-year {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    font-size: 0; }
  .all-event__box-img {
    height: 150px;
    position: relative; }
    @media screen and (min-width: 992px) {
      .all-event__box-img {
        height: 200px; } }
    .all-event__box-img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top; }
  .all-event__box-status-current {
    position: absolute;
    bottom: -12px;
    left: 0;
    background: #28a745;
    font-family: Poppins;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    padding: 0 10px; }
  .all-event__box-status-upcoming {
    position: absolute;
    bottom: -12px;
    left: 0;
    background: #fd7e14;
    font-family: Poppins;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    padding: 0 10px; }
  .all-event__box-name {
    font-family: "Poppins";
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    color: #333;
    margin-bottom: 5px; }
  .all-event__box-sub {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 26px; }
  .all-event__box-btn-wrapper {
    margin-left: 20px;
    margin-top: 20px; }
    @media screen and (min-width: 992px) {
      .all-event__box-btn-wrapper {
        margin-top: auto;
        margin-left: 40px; } }
  .all-event__box-btn {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 600;
    color: #333;
    border-radius: 5px;
    border: 1px solid #ff5800;
    width: 245px;
    padding: 15px 0;
    display: block;
    text-align: center;
    transition: 0.3s all ease-in-out; }
    .all-event__box-btn svg {
      transition: 0.3s all ease-in-out;
      margin-left: 15px; }
    .all-event__box-btn:hover {
      background: #ff5800;
      color: #fff; }
      .all-event__box-btn:hover svg {
        transform: translateX(10px); }
        .all-event__box-btn:hover svg path {
          fill: #fff; }
  .all-event__message {
    min-height: 300px;
    font-family: Poppins;
    font-size: 32px;
    color: #9d9d9d;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center; }

.event-form {
  position: relative;
  top: inherit;
  right: inherit;
  left: inherit;
  max-width: none;
  box-shadow: none;
  background: none; }
  .event-form .form-panel {
    padding: 0; }
    @media screen and (min-width: 992px) {
      .event-form .form-panel {
        padding: 20px 0; } }
  .event-form .o-contact-form__contact-form {
    padding: 0; }
    @media screen and (min-width: 992px) {
      .event-form .o-contact-form__contact-form {
        padding: 0 15px; } }

/* ANCHOR - Events */
.full-width {
  width: 100%; }

.ratings {
  position: relative;
  padding: 30px 0;
  border-bottom: 1px solid #dae9fb; }
  @media screen and (min-width: 992px) {
    .ratings {
      padding: 65px 0; } }
  .ratings__title {
    font-family: Poppins;
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .ratings__title {
        margin-bottom: 40px; } }
    .ratings__title--heading {
      font-family: Poppins;
      font-size: 33px;
      line-height: 42px;
      font-weight: 600;
      margin-bottom: 15px; }
    .ratings__title--para {
      font-family: Poppins;
      font-size: 22px;
      line-height: 29px;
      font-weight: 500;
      color: #1b3567;
      margin-bottom: 25px; }
  .ratings__wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto; }
    @media screen and (min-width: 992px) {
      .ratings__wrapper {
        flex-wrap: nowrap;
        max-width: 1070px; } }
    .ratings__wrapper--box {
      align-items: center;
      background-color: #fff;
      border: 1px solid #c7d6e3;
      border-radius: 8px;
      display: flex;
      margin: 10px;
      padding: 10px;
      width: 44%;
      height: auto; }
      @media screen and (min-width: 992px) {
        .ratings__wrapper--box {
          margin: 0 10px;
          padding: 20px;
          width: 195px;
          height: 180px; } }
      .ratings__wrapper--box span {
        font-family: Poppins;
        font-size: 30px;
        line-height: 45px;
        font-weight: 700; }
  .ratings .rating-logo {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center; }
  .ratings .star {
    margin: 15px 0 5px; }
    .ratings .star svg {
      fill: #ffc327;
      font-size: 20px; }

.client {
  padding: 30px 0;
  position: relative;
  width: 100%;
  overflow: hidden; }
  @media screen and (min-width: 992px) {
    .client {
      padding: 80px 0 120px;
      overflow: visible;
      width: inherit; } }
  .client__overlay {
    position: relative;
    z-index: 999;
    display: none; }
    @media screen and (min-width: 992px) {
      .client__overlay {
        display: block; } }
    .client__overlay::before {
      content: "";
      position: absolute;
      left: 0;
      top: 93px;
      width: 146px;
      height: 285px;
      background: linear-gradient(to right, rgba(198, 198, 198, 0.9) 0%, rgba(198, 198, 198, 0) 100%); }
    .client__overlay::after {
      content: '';
      position: absolute;
      right: 0;
      top: 93px;
      width: 146px;
      height: 285px;
      background: linear-gradient(to right, rgba(198, 198, 198, 0) 0%, rgba(198, 198, 198, 0.9) 100%); }
  .client__heading {
    font-family: Poppins;
    text-align: center;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 30px;
    color: #1b3567; }
  @media screen and (min-width: 992px) {
    .client__wrapper {
      display: flex !important;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center; } }
  .client__wrapper--box {
    flex: 1;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 15px 5px;
    height: 140px; }
    @media screen and (min-width: 992px) {
      .client__wrapper--box {
        margin: 0 7px;
        padding: 0;
        height: 280px;
        width: 260px; } }
    .client__wrapper--box .pimss-logo {
      width: 70px; }
      @media screen and (min-width: 992px) {
        .client__wrapper--box .pimss-logo {
          width: inherit; } }
    .client__wrapper--box .psychiatrist {
      width: 150px; }
      @media screen and (min-width: 992px) {
        .client__wrapper--box .psychiatrist {
          width: inherit; } }
    .client__wrapper--box .tipalti {
      width: 100px; }
      @media screen and (min-width: 992px) {
        .client__wrapper--box .tipalti {
          width: inherit; } }
    .client__wrapper--box .tovuti {
      width: 100px; }
      @media screen and (min-width: 992px) {
        .client__wrapper--box .tovuti {
          width: inherit; } }
    .client__wrapper--box .reuters {
      width: 100px; }
      @media screen and (min-width: 992px) {
        .client__wrapper--box .reuters {
          width: inherit; } }
    .client__wrapper--box .storecare {
      width: 125px; }
      @media screen and (min-width: 992px) {
        .client__wrapper--box .storecare {
          width: inherit; } }
    .client__wrapper--box .federated {
      width: 100px; }
      @media screen and (min-width: 992px) {
        .client__wrapper--box .federated {
          width: inherit; } }
    .client__wrapper--box .ideal-living {
      width: 140px; }
      @media screen and (min-width: 992px) {
        .client__wrapper--box .ideal-living {
          width: inherit; } }
  @media screen and (min-width: 992px) {
    .client__wrapper .color-box-big {
      height: 282px; } }
  .client__wrapper .color-box-medium {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    padding: 15px 0; }
    @media screen and (min-width: 992px) {
      .client__wrapper .color-box-medium {
        height: 165px;
        padding: 0; } }
  .client__wrapper .color-box-small {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    padding: 15px 0; }
    @media screen and (min-width: 992px) {
      .client__wrapper .color-box-small {
        height: 103px;
        padding: 0; } }
  .client__wrapper .color-box1 {
    background-color: #565A58; }
  .client__wrapper .color-box2 {
    background-color: #FC8019; }
  .client__wrapper .color-box3 {
    background-color: #EDF3F9; }
  .client__wrapper .color-box4 {
    background-color: #ECF1EA; }
  .client__wrapper .color-box5 {
    background-color: #FFBC00; }
  .client__wrapper .color-box6 {
    background-color: #00AE4D; }
  .client__wrapper .color-box7 {
    background-color: #29B7D4; }
  .client__wrapper .color-box8 {
    background-color: #2A2F88; }
  .client__wrapper .color-box9 {
    background-color: #F6F6F6; }
  .client__wrapper .color-box10 {
    background-color: #E98C34; }
  .client__wrapper .color-box11 {
    background-color: #F9F9F9; }
  .client__wrapper .color-box12 {
    background-color: #49FD7B; }
  .client__wrapper .color-box13 {
    background-color: #1D1D1B; }
  .client__wrapper .color-box14 {
    background-color: #F6F1ED; }
  .client__wrapper .color-box15 {
    background-color: #8300E6; }
  .client__wrapper .color-box16 {
    background-color: #DEE9FF; }
  .client__wrapper .color-box17 {
    background-color: #77A7AF; }
  .client__wrapper .color-box18 {
    background-color: #DC2940; }
  .client__wrapper .color-box19 {
    background-color: #E2F2FB; }
  .client__wrapper .color-box20 {
    background-color: #E2F2FB; }
  .client__wrapper .color-box21 {
    background-color: #A9E5EA; }
  .client__wrapper--logo {
    flex: 0 0 52px; }
  .client .splide__list {
    height: auto !important; }
  .client .slick-dots {
    bottom: -50px; }
    .client .slick-dots li {
      margin: 0; }
      .client .slick-dots li button {
        padding: 0; }
        .client .slick-dots li button:before {
          color: #D9D9D9;
          opacity: 1;
          font-size: 8px; }
        .client .slick-dots li button:hover:before {
          color: #ff5800; }
      .client .slick-dots li.slick-active button:before {
        color: #ff5800;
        font-size: 13px; }
  .client .slick-track {
    display: flex;
    align-items: center; }
    @media screen and (min-width: 992px) {
      .client .slick-track {
        align-items: inherit; } }
    .client .slick-track .slick-slide {
      margin: 0 5px; }
      @media screen and (min-width: 992px) {
        .client .slick-track .slick-slide {
          flex: 0 0 8%;
          margin: 0; } }
      @media screen and (min-width: 1024px) {
        .client .slick-track .slick-slide {
          flex: 0 0 7%; } }
      @media (min-width: 1600px) {
        .client .slick-track .slick-slide {
          flex: 0 0 6%; } }
      @media (min-width: 1800px) {
        .client .slick-track .slick-slide {
          flex: 0 0 5.8%; } }
      @media (min-width: 2000px) {
        .client .slick-track .slick-slide {
          flex: 0 0 5.1%; } }
      @media (min-width: 2200px) {
        .client .slick-track .slick-slide {
          flex: 0 0 5.1%; } }
      @media (min-width: 2400px) {
        .client .slick-track .slick-slide {
          flex: 0 0 4.3%; } }
      @media (min-width: 2600px) {
        .client .slick-track .slick-slide {
          flex: 0 0 3.9%; } }
      @media (min-width: 2800px) {
        .client .slick-track .slick-slide {
          flex: 0 0 3.6%; } }
  .client--type1 {
    padding: 80px 0; }
    .client--type1 .client__heading {
      font-size: 22px;
      line-height: 29px;
      color: #E98C34; }
    .client--type1 .client__overlay:before {
      top: 88px;
      width: 146px;
      height: 282px; }
    .client--type1 .client__overlay:after {
      top: 88px;
      width: 146px;
      height: 282px; }

.mt-14 {
  margin-top: 14px; }

.what-next {
  background-color: #04254a;
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .what-next {
      padding: 50px 0; } }
  .what-next .heading-h2 {
    font-family: Poppins;
    color: #fff;
    font-weight: 600;
    font-size: 33px;
    line-height: 42px;
    margin-bottom: 10px; }
    @media screen and (min-width: 992px) {
      .what-next .heading-h2 {
        margin-bottom: 15px; } }
  .what-next .common-para {
    font-family: Poppins;
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    line-height: 29px;
    margin-bottom: 25px; }
  .what-next__pm {
    align-items: center;
    display: flex;
    justify-content: center; }
  .what-next__box {
    position: relative;
    margin: 0 10px; }
    @media screen and (min-width: 992px) {
      .what-next__box {
        margin: 0 18px; } }
    .what-next__box span {
      font-family: Poppins;
      color: #fff;
      display: block;
      font-size: 16px;
      font-weight: 600; }
  .what-next__img {
    border-radius: 50%;
    display: inline-block;
    height: 50px;
    overflow: hidden;
    width: 50px; }
    @media screen and (min-width: 992px) {
      .what-next__img {
        width: 72px;
        height: 72px; } }
    .what-next__img img {
      height: 100%;
      object-fit: cover;
      width: 100%; }
  .what-next--hire-saleforce {
    background: #f5f5f5; }
    .what-next--hire-saleforce .heading-h2 {
      color: #333; }
    .what-next--hire-saleforce .common-para {
      color: #333; }
    .what-next--hire-saleforce .what-next__box span {
      color: #333; }
  .what-next--ai-development {
    background: url(/images/how-we-help-you/custom-dev-how-we-bg.jpg) no-repeat bottom right/cover; }

.salesforce-landing-reviews {
  background: #fff; }

.salesforce-reviews {
  max-width: 100%;
  width: 100%;
  margin: auto;
  padding: 15px 0; }
  .salesforce-reviews__items {
    max-width: 93%;
    border-radius: 10px;
    border: 1px solid #e7edf5;
    padding: 20px;
    box-shadow: 0 0 0.625rem 0.3125rem rgba(0, 0, 0, 0.05);
    margin: 10px auto;
    min-height: 325px; }
    @media screen and (min-width: 992px) {
      .salesforce-reviews__items {
        display: flex;
        flex-direction: column;
        padding: 30px 40px; } }
    .salesforce-reviews__items .mwc-reviews__quotecontent {
      font-family: Poppins;
      font-size: 22px;
      line-height: 30px;
      color: #0c2848;
      text-align: center;
      font-weight: 400; }
      @media screen and (min-width: 992px) {
        .salesforce-reviews__items .mwc-reviews__quotecontent {
          font-size: 30px;
          line-height: 42px;
          text-align: left; } }
  .salesforce-reviews .splide__arrows {
    top: 50%;
    transform: translateY(-50%);
    bottom: inherit;
    justify-content: space-between; }
  .salesforce-reviews .splide__arrow {
    position: relative;
    width: 65px;
    height: 65px;
    border: none;
    background: #e9eff8; }
    .salesforce-reviews .splide__arrow--prev {
      left: -45px; }
    .salesforce-reviews .splide__arrow--next {
      right: -45px; }
    .salesforce-reviews .splide__arrow:hover {
      background: #0c2848; }
    .salesforce-reviews .splide__arrow svg {
      width: 30px;
      height: 30px; }
      .salesforce-reviews .splide__arrow svg path {
        fill: #0c2848; }

.salesforce-partner {
  background-color: #f9f9f9; }
  .salesforce-partner .o-we-develop__box ul li {
    position: relative;
    font-family: Poppins;
    font-size: 15px;
    margin-bottom: 7px;
    padding-left: 25px; }
    .salesforce-partner .o-we-develop__box ul li .icon {
      position: absolute;
      left: 0;
      top: 3px; }
    .salesforce-partner .o-we-develop__box ul li:last-child {
      margin-bottom: 0; }
    .salesforce-partner .o-we-develop__box ul li strong {
      font-weight: 600; }

.salesforce-develop .o-we-develop__box {
  text-align: center; }

.why-hire {
  padding: 50px 0 50px;
  border-top: 1px solid #dddada; }
  @media screen and (min-width: 992px) {
    .why-hire {
      padding: 70px 0 80px; } }
  .why-hire__title {
    font-family: Poppins;
    font-size: 33px;
    line-height: 42px;
    font-weight: 600;
    text-transform: none; }
  .why-hire__subtitle {
    font-family: Poppins;
    font-size: 22px;
    line-height: 32px;
    font-weight: 400;
    text-transform: none;
    margin: 0 0 60px; }
  @media screen and (min-width: 992px) {
    .why-hire--wrapper {
      column-count: 2;
      column-gap: 125px; } }
  .why-hire--sectionbox {
    margin-bottom: 40px;
    page-break-inside: avoid; }
    @media screen and (min-width: 992px) {
      .why-hire--sectionbox {
        display: flex; } }
    .why-hire--sectionbox i {
      width: 50px;
      width: 100%;
      text-align: center;
      display: block; }
      @media screen and (min-width: 992px) {
        .why-hire--sectionbox i {
          width: auto;
          text-align: left;
          display: inline-block; } }
  .why-hire--sectioncontent {
    text-align: center; }
    @media screen and (min-width: 992px) {
      .why-hire--sectioncontent {
        padding-left: 10px;
        margin-left: 10px;
        flex: 1;
        text-align: left; } }
    .why-hire--sectioncontent h3 {
      font-family: Poppins;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 7px;
      margin-top: 10px; }
      @media (min-width: 992px) {
        .why-hire--sectioncontent h3 {
          margin-bottom: 15px;
          margin-top: 0; } }
    .why-hire--sectioncontent p {
      font-family: Poppins;
      font-size: 16px;
      line-height: 27px;
      font-weight: 400;
      margin-bottom: 5px; }
    .why-hire--sectioncontent a {
      font-family: Poppins;
      display: block;
      font-size: 18px;
      position: relative;
      width: 107px;
      -webkit-transition: .3s all ease-in-out;
      transition: .3s all ease-in-out; }
      .why-hire--sectioncontent a:before {
        content: "➞";
        position: absolute;
        right: -17px;
        top: 0;
        -webkit-transition: .3s all ease-in-out;
        transition: .3s all ease-in-out; }
      .why-hire--sectioncontent a:hover:before {
        right: -22px; }
  .why-hire .btn-wrapper {
    margin-top: 50px; }

.salesforcebottomform .confidential-text-wrap ul li {
  font-size: 13px; }

.salesforcebottomform .new-bottom-form__btn-wrapper button {
  width: 370px;
  max-width: 370px;
  font-size: 15px; }

.saleforce-partner-why-choose .o-we-develop__title {
  font-weight: 700 !important; }

.saleforce-partner-why-choose .o-we-develop__subtitle {
  font-weight: 500; }

.salesforce-partner-casestudy {
  position: relative;
  background-color: #f9f9f9;
  padding: 30px 0 50px; }
  @media screen and (min-width: 992px) {
    .salesforce-partner-casestudy {
      padding: 40px 0; } }
  .salesforce-partner-casestudy__heading {
    font-family: Poppins;
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 35px;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .salesforce-partner-casestudy__heading {
        font-size: 48px;
        line-height: 58px; } }
  .salesforce-partner-casestudy__casestudylist {
    position: relative; }
  .salesforce-partner-casestudy__casestudyitem {
    max-width: 435px;
    width: 95%;
    margin: 0; }
    @media screen and (min-width: 992px) {
      .salesforce-partner-casestudy__casestudyitem {
        margin: auto;
        background-color: #fff;
        box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.07); } }
  .salesforce-partner-casestudy__casestudyitemimage {
    width: 100%;
    height: 259px; }
    .salesforce-partner-casestudy__casestudyitemimage img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .salesforce-partner-casestudy__casestudyitemcontent {
    padding: 20px 25px 35px 25px;
    min-height: auto;
    height: auto;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    display: flex;
    flex-direction: column;
    border-top: none; }
    @media screen and (min-width: 992px) {
      .salesforce-partner-casestudy__casestudyitemcontent {
        min-height: 250px; } }
    .salesforce-partner-casestudy__casestudyitemcontent h4 {
      font-family: Poppins;
      font-weight: 600;
      font-size: 21px;
      line-height: 32px;
      color: #333;
      margin-bottom: 20px; }
    .salesforce-partner-casestudy__casestudyitemcontent h6 {
      font-family: Poppins;
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      color: #333;
      margin: 0;
      display: flex;
      margin-top: auto; }
      @media screen and (min-width: 992px) {
        .salesforce-partner-casestudy__casestudyitemcontent h6 {
          font-size: 17px; } }
      .salesforce-partner-casestudy__casestudyitemcontent h6 span {
        font-size: 18px;
        color: #4492dc;
        font-weight: 600;
        margin-right: 5px; }
  .salesforce-partner-casestudy .splide__arrows {
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-self: center; }
  .salesforce-partner-casestudy .splide__arrow {
    position: relative;
    width: 50px;
    height: 50px;
    border: 1px solid #3cc065;
    line-height: 0;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    transition: all .5s;
    background-color: #fff; }
    .salesforce-partner-casestudy .splide__arrow svg {
      width: 24px;
      height: 24px;
      fill: #000;
      transition: all .5s; }
    .salesforce-partner-casestudy .splide__arrow--prev {
      left: 0; }
      .salesforce-partner-casestudy .splide__arrow--prev svg {
        transform: scaleX(-1); }
    .salesforce-partner-casestudy .splide__arrow--next {
      right: 0; }
    .salesforce-partner-casestudy .splide__arrow:hover {
      background-color: #3cc065; }
      .salesforce-partner-casestudy .splide__arrow:hover path {
        fill: #fff; }
  .salesforce-partner-casestudy__buttombtn {
    font-family: Poppins;
    font-size: 20px;
    line-height: 26px;
    color: #333;
    font-weight: 500;
    text-decoration: underline;
    margin-top: 100px;
    display: inline-block; }
    @media screen and (min-width: 992px) {
      .salesforce-partner-casestudy__buttombtn {
        margin-top: 40px; } }

@media screen and (min-width: 992px) {
  .salesforce-partner-why .o-we-develop__boxcontent {
    font-size: 18px; } }

.why-finding {
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .why-finding {
      padding: 60px 0; } }
  .why-finding__heading {
    font-family: Poppins; }
    @media screen and (min-width: 992px) {
      .why-finding__heading {
        margin-bottom: 30px; } }
  .why-finding__sub-heading {
    font-family: Poppins;
    color: #333;
    text-align: center;
    font-weight: 500;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 30px; }

.strategic-partner {
  padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .strategic-partner {
      padding: 60px 0; } }
  .strategic-partner__content {
    text-align: center; }
    @media screen and (min-width: 992px) {
      .strategic-partner__content {
        padding-left: 50px;
        text-align: left; } }
  .strategic-partner__heading {
    font-family: Poppins;
    font-weight: 600; }
    @media screen and (min-width: 992px) {
      .strategic-partner__heading {
        font-size: 42px;
        line-height: 52px; } }
  .strategic-partner__sub-heading {
    font-family: Poppins;
    color: #333;
    font-weight: 400;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 28px; }

.skill-bottom-form--salesforce-developer .form-sub-heading {
  font-size: 22px; }

@media screen and (min-width: 992px) {
  .skill-bottom-form--salesforce-developer .new-bottom-form__btn-wrapper button {
    width: 310px;
    font-size: 15px; } }

.u-height--65 {
  height: 65px; }

.u-height--36 {
  height: 36px; }

.u-width--65 {
  width: 65px; }

.u-width--36-i {
  width: 36px !important; }

.u-object-fit--fill {
  object-fit: fill; }

.u-font-family--poppins {
  font-family: "Poppins"; }
