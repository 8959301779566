// GENEREAL STYLE
.full-width{
    width: 100%;
}
// RATING STYLE
.ratings{
    position: relative;
    padding: 30px 0;
    border-bottom: 1px solid #dae9fb;
    @include media-query(992px) {
        padding: 65px 0;
    }
    &__title{
        font-family: Poppins;
        margin-bottom: 20px;
        @include media-query(992px) {
            margin-bottom: 40px;
        }
        &--heading{
            font-family: Poppins;
            font-size: 33px;
            line-height: 42px;
            font-weight: 600;
            margin-bottom: 15px;
        }
        &--para{
            font-family: Poppins;
            font-size: 22px;
            line-height: 29px;
            font-weight: 500;
            color: #1b3567;
            margin-bottom: 25px;
        }
    }
    &__wrapper{
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        @include media-query(992px) {
            flex-wrap: nowrap;
            max-width: 1070px;
        }
        &--box{
            align-items: center;
            background-color: #fff;
            border: 1px solid #c7d6e3;
            border-radius: 8px;
            display: flex;
            margin: 10px;
            padding: 10px;
            width: 44%;
            height: auto;
            @include media-query(992px) {
                margin: 0 10px;
                padding: 20px;
                width: 195px;
                height: 180px;
            }
            span{
                font-family: Poppins;
                font-size: 30px;
                line-height: 45px;
                font-weight: 700;
            }
        }
    }
    .rating-logo{
        align-items: center;
        display: flex;
        height: 50px;
        justify-content: center;
    }
    .star{
        margin: 15px 0 5px;
        svg{
            fill: #ffc327;
            font-size: 20px;
        }
    }
}
// CLIENT STYLE
.client {
    padding: 30px 0;
    position: relative;
    width: 100%;
    overflow: hidden;
    @include media-query(992px) {
        padding: 80px 0 120px;
        overflow: visible;
        width: inherit;
    }
    &__overlay {
        position: relative;
        z-index: 999;
        display: none;
        @include media-query(992px) {
            display: block;
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 93px;
            width: 146px;
            height: 285px;
            background: linear-gradient(to right,  rgba(198,198,198,0.9) 0%,rgba(198,198,198,0) 100%);
        }
        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 93px;
            width: 146px;
            height: 285px;
            background: linear-gradient(to right,  rgba(198,198,198,0) 0%,rgba(198,198,198,0.9) 100%);
        }
    }
    &__heading {
        font-family: Poppins;
        text-align: center;
        font-size: 20px;
        line-height: 32px;
        font-weight: 500;
        margin-bottom: 30px;
        color: #1b3567;
    }
    &__wrapper {
        @include media-query(992px) {
            display: flex !important;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }
        &--box {
            flex: 1;
            border-radius: 20px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 15px 5px;
            height: 140px;
            @include media-query(992px) {
                margin: 0 7px;
                padding: 0;
                height: 280px;
                width: 260px;
            }
            .pimss-logo {
                width: 70px;
                @include media-query(992px) {
                    width: inherit;
                }
            }
            .psychiatrist {
                width: 150px;
                @include media-query(992px) {
                    width: inherit;
                }
            }
            .tipalti {
                width: 100px;
                @include media-query(992px) {
                    width: inherit;
                }
            }
            .tovuti {
                width: 100px;
                @include media-query(992px) {
                    width: inherit;
                }
            }
            .reuters {
                width: 100px;
                @include media-query(992px) {
                    width: inherit;
                }
            }
            .storecare {
                width: 125px;
                @include media-query(992px) {
                    width: inherit;
                }
            }
            .federated {
                width: 100px;
                @include media-query(992px) {
                    width: inherit;
                }
            }
            .ideal-living {
                width: 140px;
                @include media-query(992px) {
                    width: inherit;
                }
            }
        }
        .color-box-big {
            @include media-query(992px) {
                height: 282px;
            }
        }
        .color-box-medium {
            flex: 0 0 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            padding: 15px 0;
            @include media-query(992px) {
                height: 165px;
                padding: 0;
            }
        }
        .color-box-small {
            flex: 0 0 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            padding: 15px 0;
            @include media-query(992px) {
                height: 103px;
                padding: 0;
            }
        }
        .color-box1 {
            background-color: #565A58;
        }
        .color-box2 {
            background-color: #FC8019;
        }
        .color-box3 {
            background-color: #EDF3F9;
        }
        .color-box4 {
            background-color: #ECF1EA;
        }
        .color-box5 {
            background-color: #FFBC00;
        }
        .color-box6 {
            background-color: #00AE4D;
        }
        .color-box7 {
            background-color: #29B7D4;
        }
        .color-box8 {
            background-color: #2A2F88;
        }
        .color-box9 {
            background-color: #F6F6F6;
        }
        .color-box10 {
            background-color: #E98C34;
        }
        .color-box11 {
            background-color: #F9F9F9;
        }
        .color-box12 {
            background-color: #49FD7B;
        }
        .color-box13 {
            background-color: #1D1D1B;
        }
        .color-box14 {
            background-color: #F6F1ED;
        }
        .color-box15 {
            background-color: #8300E6;
        }
        .color-box16 {
            background-color: #DEE9FF;
        }
        .color-box17 {
            background-color: #77A7AF;
        }
        .color-box18 {
            background-color: #DC2940;
        }
        .color-box19 {
            background-color: #E2F2FB;
        }
        .color-box20 {
            background-color: #E2F2FB;
        }
        .color-box21 {
            background-color: #A9E5EA;
        }
        &--logo {
            flex: 0 0 52px;
        }
    }
    .splide__list{
        height: auto !important;
    }
    .slick-dots {
        bottom: -50px;
        li {
            margin: 0;
            button {
                padding: 0;
                &:before {
                    color: #D9D9D9;
                    opacity: 1;
                    font-size: 8px;
                }
                &:hover {
                    &:before {
                        color: #ff5800;
                    }
                }
            }
            &.slick-active {
                button {
                    &:before {
                        color: #ff5800;
                        font-size: 13px;
                    }
                }
            }
        }
    }
    // .slick-slide:not(.slick-active) {
    //     opacity: 0.3;
    //  }
    .slick-track {
        display: flex;
        align-items: center;
        @include media-query(992px) {
            align-items: inherit;
        }
        .slick-slide {
            margin: 0 5px;
            @include media-query(992px) {
                flex: 0 0 8%;
                margin: 0;
            }
            @include media-query(1024px) {
                flex: 0 0 7%;
            }
            @media (min-width: 1600px) {
                flex: 0 0 6%;
            }
            @media (min-width: 1800px) {
                flex: 0 0 5.8%;
            }
            @media (min-width: 2000px) {
                flex: 0 0 5.1%;
            }
            @media (min-width: 2200px) {
                flex: 0 0 5.1%;
            }
            @media (min-width: 2400px) {
                flex: 0 0 4.3%;
            }
            @media (min-width: 2600px) {
                flex: 0 0 3.9%;
            }
            @media (min-width: 2800px) {
                flex: 0 0 3.6%;
            }
        }
    }
    &--type1 {
        padding: 80px 0;
        .client__heading {
            font-size: 22px;
            line-height: 29px;
            color: #E98C34;
        }
        .client__overlay {
            &:before {
                top: 88px;
                width: 146px;
                height: 282px;
            }
            &:after {
                top: 88px;
                width: 146px;
                height: 282px;
            }
        }
    }
}
.mt-14 {
    margin-top: 14px;
}
// WHAT NEXT
.what-next{
    // background-color: #4492dc;
    background-color:#04254a;
    padding: 30px 0;
    @include media-query(992px) {
        padding: 50px 0;
    }
    .heading-h2{
        font-family: Poppins;
        color: #fff;
        font-weight: 600;
        font-size: 33px;
        line-height: 42px;
        margin-bottom: 10px;
        @include media-query(992px) {
            margin-bottom: 15px;
        }
    }
    .common-para{
        font-family: Poppins;
        color: #fff;
        font-size: 22px;
        font-weight: 500;
        line-height: 29px;
        margin-bottom: 25px;
    }
    &__pm{
        align-items: center;
        display: flex;
        justify-content: center;
    }
    &__box{
        position: relative;
        margin: 0 10px;
        @include media-query(992px) {
            margin: 0 18px;
        }
        span{
            font-family: Poppins;
            color: #fff;
            display: block;
            font-size: 16px;
            font-weight: 600;
        }
    }
    &__img{
        border-radius: 50%;
        display: inline-block;
        height: 50px;
        overflow: hidden;
        width: 50px;
        @include media-query(992px) {
            width: 72px;
            height: 72px;
        }
        img{
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
    &--hire-saleforce {
        background: #f5f5f5;
        .heading-h2 {
            color: #333;
        }
        .common-para {
            color: #333;
        }
        .what-next__box {
            span {
                color: #333;
            }
        }
    }
    &--ai-development {
        background: url(/images/how-we-help-you/custom-dev-how-we-bg.jpg) no-repeat bottom right / cover;
    }
}

// Review 
.salesforce-landing-reviews{
    background: #fff;
}
.salesforce-reviews{
    max-width: 100%;
    width: 100%;
    margin: auto;
    padding: 15px 0;
    &__items{
        max-width: 93%;
        border-radius: 10px;
        border: 1px solid #e7edf5;
        padding: 20px;
        box-shadow: 0 0 .625rem .3125rem rgba(0, 0, 0, .05);
        margin: 10px auto;
        min-height: 325px;
        @include media-query(992px) {
            display: flex;
            flex-direction: column;
            padding: 30px 40px;
        }
        .mwc-reviews__quotecontent{
            font-family: Poppins;
            font-size: 22px;
            line-height: 30px;
            color: #0c2848;
            text-align: center;
            font-weight: 400;
            @include media-query(992px) {
                font-size: 30px;
                line-height: 42px;
                text-align: left;
            }
        }
    }
    .splide__arrows{
        top: 50%;
        transform: translateY(-50%);
        bottom: inherit;
        justify-content: space-between;
    }
    .splide__arrow{
        position: relative;
        width: 65px;
        height: 65px;
        border:none;
        background: #e9eff8;
        &--prev{
            left: -45px;
        }
        &--next{
            right: -45px;
        }
        &:hover{
            background: #0c2848;
        }
        svg{
            width: 30px;
            height: 30px;
            path{
                fill:#0c2848;
            }
        }
    }
}
// PARTNER
.salesforce-partner{
    background-color: #f9f9f9;
    .o-we-develop__box{
        ul{
            li{
                position: relative;
                font-family: Poppins;
                font-size: 15px;
                margin-bottom: 7px; 
                padding-left: 25px;
                .icon{
                    position: absolute;
                    left: 0;
                    top: 3px;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                strong{
                    font-weight: 600;
                }
            }
        }
    }
}
.salesforce-develop{
    .o-we-develop__box{
        text-align: center;
    }
}
// WHY HIRE
.why-hire{
    padding: 50px 0 50px;
    border-top: 1px solid #dddada;
    @include media-query(992px) {
        padding: 70px 0 80px;
    }
    &__title {
        font-family: Poppins;
        font-size: 33px;
        line-height: 42px;
        font-weight: 600;
        text-transform: none;
    }
    &__subtitle{
        font-family: Poppins;
        font-size: 22px;
        line-height: 32px;
        font-weight: 400;
        text-transform: none;
        margin: 0 0 60px;
    }
    &--wrapper{
        @include media-query(992px) {
            column-count: 2;
            column-gap: 125px;
        }
    }
    &--sectionbox{
        margin-bottom: 40px;
        page-break-inside: avoid;
        @include media-query(992px) {
            display: flex;
        }
        i{
            width: 50px;
            width: 100%;
            text-align: center;
            display: block;
            @include media-query(992px) {
                width: auto;
                text-align: left;
                display: inline-block;
            }
        }
    }
    &--sectioncontent{
        text-align: center;
        @include media-query(992px) {
            padding-left: 10px;
            margin-left: 10px;
            flex: 1;
            text-align: left;
        }
        h3{
            //CLAM
            font-family: Poppins;
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 7px;
            margin-top: 10px;
            @media (min-width: 992px) {
                margin-bottom: 15px;
                margin-top: 0;
            }
        }
        p{
            //CLAM
            font-family: Poppins;
            font-size: 16px;
            line-height: 27px;
            font-weight: 400;
            margin-bottom: 5px;
        }
        a{
            font-family: Poppins;
            display: block;
            font-size: 18px;
            position: relative;
            width: 107px;
            -webkit-transition: .3s all ease-in-out;
            transition: .3s all ease-in-out;
            &:before{
                content: "➞";
                position: absolute;
                right: -17px;
                top: 0;
                -webkit-transition: .3s all ease-in-out;
                transition: .3s all ease-in-out;
            }
            &:hover{
                &:before{
                    right: -22px;
                }
            }
        }
    }
    .btn-wrapper {
        margin-top: 50px;
    }
}
// BOTTOM FORM
.salesforcebottomform{
    .confidential-text-wrap ul li{
        font-size: 13px;
    }
    .new-bottom-form__btn-wrapper button{
        width: 370px;
        max-width: 370px;
        font-size: 15px;
    }
}

.saleforce-partner-why-choose{
    .o-we-develop__title{
        font-weight: 700 !important;
    }
    .o-we-develop__subtitle{
        font-weight: 500;
    }
}

// SALESFORCE PARTNER
.salesforce-partner-casestudy{
    position: relative;
    background-color: #f9f9f9;
    padding:30px 0 50px;
    @include media-query(992px) {
        padding:40px 0;
    }
    &__heading{
        font-family: Poppins;
        font-size: 32px;
        line-height: 40px;
        font-weight: 600;
        margin-bottom: 35px;
        text-align: center;
        @include media-query(992px) {
            font-size: 48px;
            line-height: 58px;
        }
    }
    &__casestudylist{
        position: relative;
    }
    &__casestudyitem{
        max-width: 435px;
        width: 95%;
        margin: 0;
        @include media-query(992px) {
            margin: auto;
            background-color: #fff;
            box-shadow: 0 6px 20px 0 rgba(0, 0, 0, .07);
        }
    }
    &__casestudyitemimage{
        width: 100%;
        height: 259px;
        img{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__casestudyitemcontent{
        padding: 20px 25px 35px 25px;
        min-height: auto;
        height: auto;
        background-color: #fff;
        border: 1px solid #e1e1e1;
        display: flex;
        flex-direction: column;
        border-top: none;
        @include media-query(992px) {
            min-height: 250px;
        }
        h4{
            font-family: Poppins;
            font-weight: 600;
            font-size: 21px;
            line-height: 32px;
            color: #333;
            margin-bottom: 20px;
        }
        h6{
            font-family: Poppins;
            font-weight: 500;
            font-size: 16px;
            line-height: 27px;
            color: #333;
            margin: 0;
            display: flex;
            margin-top: auto;
            @include media-query(992px) {
                font-size: 17px;
            }
            span{
                font-size: 18px;
                color: #4492dc;
                font-weight: 600;
                margin-right: 5px;
            }
        }
    }
    .splide__arrows{
        position: absolute;
        bottom: -100px;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-self: center
    }
    .splide__arrow{
        position: relative;
        width: 50px;
        height: 50px;
        border: 1px solid #3cc065;
        line-height: 0;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        transition: all .5s;
        background-color: #fff;
        svg{
            width: 24px;
            height: 24px;
            fill: #000;
            transition: all .5s;
        }
        &--prev{
            left: 0;
            svg{
                transform: scaleX(-1);
            }
        }
        &--next{
            right: 0;
        }
        &:hover{
            background-color: #3cc065;
            path{
                fill: #fff;
            }
        }
    }
    &__buttombtn{
        font-family: Poppins;
        font-size: 20px;
        line-height: 26px;
        color: #333;
        font-weight: 500;
        text-decoration: underline;
        margin-top: 100px;
        display: inline-block;
        @include media-query(992px) {
            margin-top: 40px;
        }
    }
}
.salesforce-partner-why{
    .o-we-develop__boxcontent{
        @include media-query(992px) {
            font-size: 18px;
        }
    }
}

.why-finding {
    $why-finding: &;
    padding: 30px 0;
    @include media-query(992px) {
        padding: 60px 0;
    }
    &__heading {
        font-family: Poppins;
        @include media-query(992px) {
            margin-bottom: 30px;
        }
    }
    &__sub-heading {
        font-family: Poppins;
        color: #333;
        text-align: center;
        font-weight: 500;
        margin-bottom: 0;
        font-size: 18px;
        line-height: 30px;
    }
}

.strategic-partner {
    $why-finding: &;
    padding: 30px 0;
    @include media-query(992px) {
        padding: 60px 0;
    }
    &__content {
        text-align: center;
        @include media-query(992px) {
            padding-left: 50px;
            text-align: left;
        }
    }
    &__heading {
        font-family: Poppins;
        font-weight: 600;
        @include media-query(992px) {
            font-size: 42px;
            line-height: 52px;
        }
    }
    &__sub-heading {
        font-family: Poppins;
        color: #333;
        font-weight: 400;
        margin-bottom: 0;
        font-size: 18px;
        line-height: 28px;
    }
}

.skill-bottom-form--salesforce-developer {
    .form-sub-heading {
        font-size: 22px;
    }
    .new-bottom-form__btn-wrapper {
        button {
            @include media-query(992px) {
                width: 310px;
                font-size: 15px;
            }
        }
    }
}